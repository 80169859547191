import { gql } from '@apollo/client';

import BaseInspectionAssetModel from '../inspectionAsset/base';
import InspectionLogFindingModel from '../inspectionLogFinding';
import InspectionLogNoteAssetModel from '../inspectionLogNoteAsset';
import InspectionLogTestAssetModel from '../inspectionLogTestAsset';
import ItemModel from '../item';
import BaseOrganisationModel from '../organisation/base';
import BaseUserModel from '../user/base';

import BaseInspectionLogModel from './base';

export default class InspectionLogModel {
  static GRAPHQL_TYPE = BaseInspectionLogModel.GRAPHQL_TYPE;

  static fragmentName = 'InspectionLog_Fragment';
  static fragment = gql`
    fragment ${InspectionLogModel.fragmentName} on ${InspectionLogModel.GRAPHQL_TYPE} {
      ...${BaseInspectionLogModel.fragmentName}
      inspectionAsset {
        ...${BaseInspectionAssetModel.fragmentName}
      }
      inspectionLogFindingList {
        ...${InspectionLogFindingModel.fragmentName}
      }
      inspectionLogTestAssetList {
        ...${InspectionLogTestAssetModel.fragmentName}
      }
      inspectionLogNoteAssetList {
        ...${InspectionLogNoteAssetModel.fragmentName}
      }
      inspectionOrganisation {
        ...${BaseOrganisationModel.fragmentName}
      }
      inspector {
        ...${BaseUserModel.fragmentName}
      }
      item {
        ...${ItemModel.fragmentName}
      }
      organisation {
        ...${BaseOrganisationModel.fragmentName}
      }
      linkedOrder {
        REFID
      }
      site {
        OKID
        siteMediaAssetList {
          mediaAsset {
            imageData {
              imageURL
            }
          }
        }
      }
    }
    ${BaseInspectionLogModel.fragment}
    ${BaseInspectionAssetModel.fragment}
    ${InspectionLogFindingModel.fragment}
    ${InspectionLogTestAssetModel.fragment}
    ${InspectionLogNoteAssetModel.fragment}
    ${BaseOrganisationModel.fragment}
    ${BaseUserModel.fragment}
    ${ItemModel.fragment}
  `;

  static ASSET_SOURCE = {
    INSPECTION: 'INSPECTION',
    INSPECTOR: 'INSPECTOR',
  };

  static STAGE = {
    PENDING: 'PENDING',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
    REVIEWED: 'REVIEWED',
  };

  static VALIDATION_ERRORS = {
    ACTUAL_FIXED_COUNT_EXCEEDS_REPORTED_FIXED_COUNT:
      'LOG_VALIDATION_ERROR_MESSAGE_ACTUAL_FIXED_COUNT_EXCEEDS_REPORTED_FIXED_COUNT',
    ACTUAL_TOLERATED_COUNT_EXCEEDS_REPORTED_TOLERATED_COUNT:
      'LOG_VALIDATION_ERROR_MESSAGE_ACTUAL_TOLERATED_COUNT_EXCEEDS_REPORTED_TOLERATED_COUNT',
    ACTUAL_REJECTED_COUNT_EXCEEDS_REPORTED_REJECTED_COUNT:
      'LOG_VALIDATION_ERROR_MESSAGE_ACTUAL_REJECTED_COUNT_EXCEEDS_REPORTED_REJECTED_COUNT',
    ACTUAL_INSPECTED_SIZE_EXCEEDS_REPORTED_INSPECTED_SIZE:
      'LOG_VALIDATION_ERROR_MESSAGE_ACTUAL_INSPECTED_SIZE_EXCEEDS_REPORTED_INSPECTED_SIZE',
    LOT_SIZE_LESS_THAN_INSPECTED_SIZE: 'LOG_VALIDATION_ERROR_MESSAGE_LOT_SIZE_LESS_THAN_INSPECTED_SIZE',
    TEST_FIXED_COUNT_EXCEEDS_FAILED_COUNT: 'LOG_VALIDATION_ERROR_MESSAGE_TEST_FIXED_COUNT_EXCEEDS_FAILED_COUNT',
    TEST_FIXED_AND_TOLERATED_COUNT_EXCEEDS_FAILED_COUNT:
      'LOG_VALIDATION_ERROR_MESSAGE_TEST_FIXED_AND_TOLERATED_COUNT_EXCEEDS_FAILED_COUNT',
    TEST_TOLERATED_COUNT_EXCEEDS_FAILED_COUNT: 'LOG_VALIDATION_ERROR_MESSAGE_TEST_TOLERATED_COUNT_EXCEEDS_FAILED_COUNT',
  };

  static link = (inspectionLog, go = false) => {
    const baseLink = `/archive/log/${inspectionLog.REFID}`;

    if (go) {
      return `${baseLink}/go`;
    }

    return baseLink;
  };

  static validate = (inspectionLog) => {
    const {
      inspectedSize,
      lotSize,
      passedAsFixed,
      passedAsTolerated,
      rejected: logRejectedCount,
      sourceType,
    } = inspectionLog;
    const siteMode = sourceType === 'SITE';

    if (siteMode) {
      // Checks after this don't apply to site logs.
      return true;
    }

    // Ensure the reported inspected size does not exceed the lot size
    if (lotSize < inspectedSize) {
      return InspectionLogModel.VALIDATION_ERRORS.LOT_SIZE_LESS_THAN_INSPECTED_SIZE;
    }

    const minInspectedSize = inspectionLog.inspectionLogTestAssetList.reduce((max, test) => {
      const count = test.passed + test.failed;
      if (count > max) {
        return count;
      }

      return max;
    }, 0);

    // Ensure the minimum inspected size based on test counts does not exceed reported inspected size
    if (inspectedSize < minInspectedSize) {
      return InspectionLogModel.VALIDATION_ERRORS.ACTUAL_INSPECTED_SIZE_EXCEEDS_REPORTED_INSPECTED_SIZE;
    }

    for (let x = 0; x < inspectionLog.inspectionLogTestAssetList.length; x++) {
      const { failed, fixed, tolerated } = inspectionLog.inspectionLogTestAssetList[x];

      // Ensure each test's fixed count does not exceed the failed count
      if (fixed > failed) {
        return InspectionLogModel.VALIDATION_ERRORS.TEST_FIXED_COUNT_EXCEEDS_FAILED_COUNT;
      }

      // Ensure each test's tolerated count does not exceed the failed count
      if (tolerated > failed) {
        return InspectionLogModel.VALIDATION_ERRORS.TEST_TOLERATED_COUNT_EXCEEDS_FAILED_COUNT;
      }

      // Ensure the sum of each test's fixed and tolerated counts does not exceed the failed count
      if (fixed + tolerated > failed) {
        return InspectionLogModel.VALIDATION_ERRORS.TEST_FIXED_AND_TOLERATED_COUNT_EXCEEDS_FAILED_COUNT;
      }
    }

    const minFixedCount = inspectionLog.inspectionLogTestAssetList.reduce((max, test) => {
      const count = test.fixed;
      if (count > max) {
        return count;
      }

      return max;
    }, 0);

    // Ensure the minimum fixed count based on test counts does not exceed reported fixed count
    if (passedAsFixed < minFixedCount) {
      return InspectionLogModel.VALIDATION_ERRORS.ACTUAL_FIXED_COUNT_EXCEEDS_REPORTED_FIXED_COUNT;
    }

    const minToleratedCount = inspectionLog.inspectionLogTestAssetList.reduce((max, test) => {
      const count = test.tolerated;
      if (count > max) {
        return count;
      }

      return max;
    }, 0);

    // Ensure the minimum tolerated count based on test counts does not exceed reported tolerated count
    if (passedAsTolerated < minToleratedCount) {
      return InspectionLogModel.VALIDATION_ERRORS.ACTUAL_TOLERATED_COUNT_EXCEEDS_REPORTED_TOLERATED_COUNT;
    }

    const minRejectedCount = inspectionLog.inspectionLogTestAssetList.reduce((max, test) => {
      const count = test.rejected;
      if (count > max) {
        return count;
      }

      return max;
    }, 0);

    // Ensure the minimum rejected count based on test counts does not exceed reported rejected count
    if (logRejectedCount < minRejectedCount) {
      return InspectionLogModel.VALIDATION_ERRORS.ACTUAL_REJECTED_COUNT_EXCEEDS_REPORTED_REJECTED_COUNT;
    }

    return true;
  };
}
