import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  RemoveConditionForUnversionedTestMutation,
  removeHowToFindItFromInspectionLogTestAssetMutation,
  removeHowToFixItFromInspectionLogTestAssetMutation,
  removeWhatToLookForFromInspectionLogTestAssetMutation,
  reorderInspectionLogTestAssetMediaAssetBackwardMutation,
  reorderInspectionLogTestAssetMediaAssetForwardMutation,
  SetConditionForUnversionedTestMutation,
  setHowToFindItOnInspectionLogTestAssetMutation,
  setHowToFixItOnInspectionLogTestAssetMutation,
  SetIdentifiersEnabledForUnversionedTestMutation,
  SetNotesEnabledForUnversionedTestMutation,
  setSeverityLevelForInspectionLogTestAssetMutation,
  SetTimeTrackingEnabledForUnversionedTestMutation,
  setWhatToLookForOnInspectionLogTestAssetMutation,
  unlinkInspectionLogTestAssetMediaAssetMutation,
} from '.';

import InspectionLogTestAssetModel from 'OK/models/inspectionLogTestAsset';
import InspectionLogTestAssetMediaAssetModel from 'OK/models/inspectionLogTestAssetMediaAsset';
import TestAssetModel from 'OK/models/testAsset';

/* Hooks */

export function useRemoveConditionFromInspectionLogTestAPI() {
  const apolloClient = useApolloClient();
  const [removeConditionAPI] = useMutation(RemoveConditionForUnversionedTestMutation);

  const removeCondition = useCallback(
    (inspectionLogTestAssetId, conditionType) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragmentUnversionedConditions,
      });
      if (testAsset) {
        optimisticResponse = {
          inspectionLogTestAsset: {
            ...testAsset,
            unversionedTestAsset: {
              ...testAsset.unversionedTestAsset,
              testAssetConditionList: testAsset.unversionedTestAsset.testAssetConditionList.filter(
                (c) => c.type == conditionType
              ),
            },
          },
        };
      }

      // Call API
      return removeConditionAPI({
        variables: {
          inspectionLogTestAssetId,
          conditionType,
        },
        optimisticResponse,
      });
    },
    [apolloClient, removeConditionAPI]
  );

  return removeCondition;
}

export function useRemoveHowToFindItFromInspectionLogTestAPI() {
  const apolloClient = useApolloClient();
  const [removeHowToFindItAPI] = useMutation(removeHowToFindItFromInspectionLogTestAssetMutation);

  const removeHowToFindIt = useCallback(
    (inspectionLogTestAssetId, languageIso) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      const howToFindItMap = {
        ...testAsset.unversionedTestAsset.howToFindItMap,
      };
      delete howToFindItMap[languageIso];
      if (testAsset) {
        optimisticResponse = {
          inspectionLogTestAsset: {
            ...testAsset,
            unversionedTestAsset: {
              ...testAsset.unversionedTestAsset,
              howToFindItMap,
            },
          },
        };
      }

      // Call API
      return removeHowToFindItAPI({
        variables: {
          languageIso,
          inspectionLogTestAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, removeHowToFindItAPI]
  );

  return removeHowToFindIt;
}

export function useRemoveHowToFixItFromInspectionLogTestAPI() {
  const apolloClient = useApolloClient();
  const [removeHowToFixItAPI] = useMutation(removeHowToFixItFromInspectionLogTestAssetMutation);

  const removeHowToFixIt = useCallback(
    (inspectionLogTestAssetId, languageIso) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      const howToFixItMap = {
        ...testAsset.unversionedTestAsset.howToFixItMap,
      };
      delete howToFixItMap[languageIso];
      if (testAsset) {
        optimisticResponse = {
          inspectionLogTestAsset: {
            ...testAsset,
            unversionedTestAsset: {
              ...testAsset.unversionedTestAsset,
              howToFixItMap,
            },
          },
        };
      }

      // Call API
      return removeHowToFixItAPI({
        variables: {
          languageIso,
          inspectionLogTestAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, removeHowToFixItAPI]
  );

  return removeHowToFixIt;
}

export function useSetConditionForInspectionLogTestAPI() {
  const apolloClient = useApolloClient();
  const [setConditionAPI] = useMutation(SetConditionForUnversionedTestMutation);

  const setCondition = useCallback(
    (inspectionLogTestAssetId, conditionType, conditionValue) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragmentUnversionedConditions,
      });
      if (testAsset) {
        optimisticResponse = {
          inspectionLogTestAsset: {
            ...testAsset,
            unversionedTestAsset: {
              ...testAsset.unversionedTestAsset,
              testAssetConditionList: [
                ...testAsset.unversionedTestAsset.testAssetConditionList,
                {
                  type: conditionType,
                  value: conditionValue,
                  valueType: TestAssetModel.conditionValueTypeForConditionType(conditionType),
                },
              ],
            },
          },
        };
      }

      // Call API
      return setConditionAPI({
        variables: {
          inspectionLogTestAssetId,
          conditionType,
          conditionValue,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setConditionAPI]
  );

  return setCondition;
}

export function useSetIdentifiersEnabledForInspectionLogTestAPI() {
  const apolloClient = useApolloClient();
  const [setIdentifiersEnabledAPI] = useMutation(SetIdentifiersEnabledForUnversionedTestMutation);

  const setIdentifiersEnabled = useCallback(
    (inspectionLogTestAssetId, enableIdentifiers) => {
      // Optimistic response
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      if (testAsset) {
        optimisticResponse = {
          inspectionLogTestAsset: {
            ...testAsset,
            unversionedTestAsset: {
              ...testAsset.unversionedTestAsset,
              enableIdentifiers,
            },
          },
        };
      }

      // Call API
      return setIdentifiersEnabledAPI({
        variables: {
          inspectionLogTestAssetId,
          enableIdentifiers,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setIdentifiersEnabledAPI]
  );

  return setIdentifiersEnabled;
}

export function useSetNotesEnabledForInspectionLogTestAPI() {
  const apolloClient = useApolloClient();
  const [setNotesEnabledAPI] = useMutation(SetNotesEnabledForUnversionedTestMutation);

  const setNotesEnabled = useCallback(
    (inspectionLogTestAssetId, enableNotes) => {
      // Optimistic response
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      if (testAsset) {
        optimisticResponse = {
          inspectionLogTestAsset: {
            ...testAsset,
            unversionedTestAsset: {
              ...testAsset.unversionedTestAsset,
              enableNotes,
            },
          },
        };
      }

      // Call API
      return setNotesEnabledAPI({
        variables: {
          inspectionLogTestAssetId,
          enableNotes,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setNotesEnabledAPI]
  );

  return setNotesEnabled;
}

export function useSetTimeTrackingEnabledForInspectionLogTestAPI() {
  const apolloClient = useApolloClient();
  const [setTimeTrackingEnabledAPI] = useMutation(SetTimeTrackingEnabledForUnversionedTestMutation);

  const setTimeTrackingEnabled = useCallback(
    (inspectionLogTestAssetId, enableTimeTracking) => {
      // Optimistic response
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      if (testAsset) {
        optimisticResponse = {
          inspectionLogTestAsset: {
            ...testAsset,
            unversionedTestAsset: {
              ...testAsset.unversionedTestAsset,
              enableTimeTracking,
            },
          },
        };
      }

      // Call API
      return setTimeTrackingEnabledAPI({
        variables: {
          inspectionLogTestAssetId,
          enableTimeTracking,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setTimeTrackingEnabledAPI]
  );

  return setTimeTrackingEnabled;
}

export function useUnlinkInspectionLogTestAssetMediaAssetAPI() {
  const apolloClient = useApolloClient();
  const [unlinkInspectionLogTestAssetMediaAssetAPI] = useMutation(unlinkInspectionLogTestAssetMediaAssetMutation);

  const unlinkInspectionLogTestAssetMediaAsset = useCallback(
    (inspectionLogTestAssetId, inspectionLogTestAssetMediaAssetId) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const inspectionLogTestAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      if (inspectionLogTestAsset?.inspectionLogTestAssetMediaAssetList?.length) {
        const inspectionLogTestAssetMediaAsset = inspectionLogTestAsset.inspectionLogTestAssetMediaAssetList.find(
          (tma) => tma.id === inspectionLogTestAssetMediaAssetId
        );
        optimisticResponse = { inspectionLogTestAssetMediaAsset };
      }

      // Call API
      return unlinkInspectionLogTestAssetMediaAssetAPI({
        variables: {
          inspectionLogTestAssetId,
          inspectionLogTestAssetMediaAssetId,
        },
        optimisticResponse,
        update: (cache) => {
          // Remove media asset from test in cache
          cache.modify({
            id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
            fields: {
              inspectionLogTestAssetMediaAssetList: (currentList, { readField }) => {
                const indexOfMediaAsset = currentList.findIndex((ref) => {
                  const id = readField('id', ref);
                  return id === inspectionLogTestAssetMediaAssetId;
                });
                if (indexOfMediaAsset > -1) {
                  const updatedList = [...currentList];
                  updatedList.splice(indexOfMediaAsset, 1);
                  return updatedList;
                }

                return currentList;
              },
            },
          });
        },
      });
    },
    [apolloClient, unlinkInspectionLogTestAssetMediaAssetAPI]
  );

  return unlinkInspectionLogTestAssetMediaAsset;
}

export function useRemoveWhatToLookForFromInspectionLogTestAPI() {
  const apolloClient = useApolloClient();
  const [removeWhatToLookForAPI] = useMutation(removeWhatToLookForFromInspectionLogTestAssetMutation);

  const removeWhatToLookFor = useCallback(
    (inspectionLogTestAssetId, languageIso) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      const whatToLookForMap = {
        ...testAsset.unversionedTestAsset.whatToLookForMap,
      };
      delete whatToLookForMap[languageIso];
      if (testAsset) {
        optimisticResponse = {
          inspectionLogTestAsset: {
            ...testAsset,
            unversionedTestAsset: {
              ...testAsset.unversionedTestAsset,
              whatToLookForMap,
            },
          },
        };
      }

      // Call API
      return removeWhatToLookForAPI({
        variables: {
          languageIso,
          inspectionLogTestAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, removeWhatToLookForAPI]
  );

  return removeWhatToLookFor;
}

export function useReorderInspectionLogTestAssetMediaAssetBackwardAPI() {
  const apolloClient = useApolloClient();
  const [reorderMediaAssetBackwardAPI] = useMutation(reorderInspectionLogTestAssetMediaAssetBackwardMutation);

  const reorderMediaAssetBackward = useCallback(
    (inspectionLogTestAssetId, mediaAssetId) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const inspectionLogTestAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      const optimisticInspectionLogTestAssetMediaAsset =
        inspectionLogTestAsset?.inspectionLogTestAssetMediaAssetList?.find((tma) => tma.id === mediaAssetId);
      if (optimisticInspectionLogTestAssetMediaAsset) {
        optimisticResponse = {
          inspectionLogTestAssetMediaAsset: {
            ...optimisticInspectionLogTestAssetMediaAsset,
            order: optimisticInspectionLogTestAssetMediaAsset.order - 1,
          },
        };
      }

      // Call API
      return reorderMediaAssetBackwardAPI({
        variables: {
          inspectionLogTestAssetId,
          mediaAssetId,
        },
        optimisticResponse,
        update: (cache, result) => {
          // Re-order test asset's media in cache
          cache.modify({
            id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
            fields: {
              inspectionLogTestAssetMediaAssetList: (currentList, { readField }) => {
                const { inspectionLogTestAssetMediaAsset } = result.data;
                const oldOrder = inspectionLogTestAssetMediaAsset.order + 1;
                const originalIndexBeforeMovedMedia = oldOrder - 2;
                const updatedList = [...currentList];
                const [beforeMediaRef, movedMediaRef] = updatedList.splice(originalIndexBeforeMovedMedia, 2);
                const updatedMovedMediaRef = cache.writeFragment({
                  id: `${InspectionLogTestAssetMediaAssetModel.GRAPHQL_TYPE}:${readField('id', movedMediaRef)}`,
                  fragment: InspectionLogTestAssetMediaAssetModel.fragmentOrder,
                  data: {
                    order: inspectionLogTestAssetMediaAsset.order,
                  },
                });
                const updatedBeforeMediaRef = cache.writeFragment({
                  id: `${InspectionLogTestAssetMediaAssetModel.GRAPHQL_TYPE}:${readField('id', beforeMediaRef)}`,
                  fragment: InspectionLogTestAssetMediaAssetModel.fragmentOrder,
                  data: {
                    order: oldOrder,
                  },
                });
                updatedList.splice(originalIndexBeforeMovedMedia, 0, updatedMovedMediaRef, updatedBeforeMediaRef);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, reorderMediaAssetBackwardAPI]
  );

  return reorderMediaAssetBackward;
}

export function useReorderInspectionLogTestAssetMediaAssetForwardAPI() {
  const apolloClient = useApolloClient();
  const [reorderMediaAssetForwardAPI] = useMutation(reorderInspectionLogTestAssetMediaAssetForwardMutation);

  const reorderMediaAssetForward = useCallback(
    (inspectionLogTestAssetId, mediaAssetId) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const inspectionLogTestAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      const optimisticInspectionLogTestAssetMediaAsset =
        inspectionLogTestAsset?.inspectionLogTestAssetMediaAssetList?.find((tma) => tma.id === mediaAssetId);
      if (optimisticInspectionLogTestAssetMediaAsset) {
        optimisticResponse = {
          inspectionLogTestAssetMediaAsset: {
            ...optimisticInspectionLogTestAssetMediaAsset,
            order: optimisticInspectionLogTestAssetMediaAsset.order + 1,
          },
        };
      }

      // Call API
      return reorderMediaAssetForwardAPI({
        variables: {
          inspectionLogTestAssetId,
          mediaAssetId,
        },
        optimisticResponse,
        update: (cache, result) => {
          // Re-order test asset's media in cache
          cache.modify({
            id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
            fields: {
              inspectionLogTestAssetMediaAssetList: (currentList, { readField }) => {
                const { inspectionLogTestAssetMediaAsset } = result.data;
                const oldOrder = inspectionLogTestAssetMediaAsset.order - 1;
                const originalIndexOfMovedMedia = oldOrder - 1;
                const updatedList = [...currentList];
                const [movedMediaRef, afterMediaRef] = updatedList.splice(originalIndexOfMovedMedia, 2);
                const updatedMovedMediaRef = cache.writeFragment({
                  id: `${InspectionLogTestAssetMediaAssetModel.GRAPHQL_TYPE}:${readField('id', movedMediaRef)}`,
                  fragment: InspectionLogTestAssetMediaAssetModel.fragmentOrder,
                  data: {
                    order: inspectionLogTestAssetMediaAsset.order,
                  },
                });
                const updatedAfterMediaRef = cache.writeFragment({
                  id: `${InspectionLogTestAssetMediaAssetModel.GRAPHQL_TYPE}:${readField('id', afterMediaRef)}`,
                  fragment: InspectionLogTestAssetMediaAssetModel.fragmentOrder,
                  data: {
                    order: oldOrder,
                  },
                });
                updatedList.splice(originalIndexOfMovedMedia, 0, updatedAfterMediaRef, updatedMovedMediaRef);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, reorderMediaAssetForwardAPI]
  );

  return reorderMediaAssetForward;
}

export function useSetHowToFindItOnInspectionLogTestAPI() {
  const apolloClient = useApolloClient();
  const [setHowToFindItAPI] = useMutation(setHowToFindItOnInspectionLogTestAssetMutation);

  const setHowToFindIt = useCallback(
    (inspectionLogTestAssetId, languageIso, text) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      if (testAsset) {
        optimisticResponse = {
          inspectionLogTestAsset: {
            ...testAsset,
            unversionedTestAsset: {
              ...testAsset.unversionedTestAsset,
              howToFindItMap: {
                ...testAsset.unversionedTestAsset.howToFindItMap,
                [languageIso]: {
                  ...testAsset.unversionedTestAsset.howToFindItMap[languageIso],
                  text,
                },
              },
            },
          },
        };
      }

      // Call API
      return setHowToFindItAPI({
        variables: {
          languageIso,
          text,
          inspectionLogTestAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setHowToFindItAPI]
  );

  return setHowToFindIt;
}

export function useSetHowToFixItOnInspectionLogTestAPI() {
  const apolloClient = useApolloClient();
  const [setHowToFixItAPI] = useMutation(setHowToFixItOnInspectionLogTestAssetMutation);

  const setHowToFixIt = useCallback(
    (inspectionLogTestAssetId, languageIso, text) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      if (testAsset) {
        optimisticResponse = {
          inspectionLogTestAsset: {
            ...testAsset,
            unversionedTestAsset: {
              ...testAsset.unversionedTestAsset,
              howToFixItMap: {
                ...testAsset.unversionedTestAsset.howToFixItMap,
                [languageIso]: {
                  ...testAsset.unversionedTestAsset.howToFixItMap[languageIso],
                  text,
                },
              },
            },
          },
        };
      }

      // Call API
      return setHowToFixItAPI({
        variables: {
          languageIso,
          text,
          inspectionLogTestAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setHowToFixItAPI]
  );

  return setHowToFixIt;
}

export function useSetSeverityLevelForInspectionLogTestAPI() {
  const apolloClient = useApolloClient();
  const [setSeverityLevelAPI] = useMutation(setSeverityLevelForInspectionLogTestAssetMutation);

  const setSeverityLevel = useCallback(
    (inspectionLogTestAssetId, severityLevel) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      if (testAsset) {
        optimisticResponse = {
          inspectionLogTestAsset: {
            ...testAsset,
            unversionedTestAsset: {
              ...testAsset.unversionedTestAsset,
              testSeverityLevel: severityLevel,
            },
          },
        };
      }

      // Call API
      return setSeverityLevelAPI({
        variables: {
          inspectionLogTestAssetId,
          severityLevel,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setSeverityLevelAPI]
  );

  return setSeverityLevel;
}

export function useSetWhatToLookForOnInspectionLogTestAPI() {
  const apolloClient = useApolloClient();
  const [setWhatToLookForAPI] = useMutation(setWhatToLookForOnInspectionLogTestAssetMutation);

  const setWhatToLookFor = useCallback(
    (inspectionLogTestAssetId, languageIso, text) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
        fragment: InspectionLogTestAssetModel.fragment,
        fragmentName: InspectionLogTestAssetModel.fragmentName,
      });
      if (testAsset) {
        optimisticResponse = {
          inspectionLogTestAsset: {
            ...testAsset,
            unversionedTestAsset: {
              ...testAsset.unversionedTestAsset,
              whatToLookForMap: {
                ...testAsset.unversionedTestAsset.whatToLookForMap,
                [languageIso]: {
                  ...testAsset.unversionedTestAsset.whatToLookForMap[languageIso],
                  text,
                },
              },
            },
          },
        };
      }

      // Call API
      return setWhatToLookForAPI({
        variables: {
          languageIso,
          text,
          inspectionLogTestAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setWhatToLookForAPI]
  );

  return setWhatToLookFor;
}
