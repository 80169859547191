import round from 'lodash/round';
import PropTypes from 'prop-types';
import { useState } from 'react';

import styles from './styles.module.scss';

import { Footnote, Footnotes } from 'OK/components/footnotes';
import Tag from 'OK/components/tag';
import TestSeverityIcon from 'OK/components/test/severityIcon';
import Text from 'OK/components/text';
import TestAssetModel from 'OK/models/testAsset';
import {
  OK_QUALITY_AQL_CRITICAL_PERCENT,
  OK_QUALITY_AQL_MAJOR_PERCENT,
  OK_QUALITY_AQL_MINOR_PERCENT,
} from 'OK/util/constants';
import { formatNumber, formatPercentage } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * Render quality results of sample and final lot compared to AQL.
 *
 * @param {object} props
 * @param {number} props.finalLotSize The final lot size.
 * @param {number} props.lotCriticalFailure The number of critical test failures projected in the final lot.
 * @param {number} props.lotMajorFailure The number of major test failures projected in the final lot.
 * @param {number} props.lotMinorFailure The number of minor test failures projected in the final lot.
 * @param {number} props.sampleCriticalFailures The number of critical test failures in the sample.
 * @param {number} props.sampleMajorFailures The number of major test failures in the sample.
 * @param {number} props.sampleMinorFailures The number of minor test failures in the sample.
 * @param {object} props.sampleSize The sample size.
 */
export default function InspectionLogQualityLevelTable(props) {
  const {
    finalLotSize,
    lotCriticalFailure,
    lotMajorFailure,
    lotMinorFailure,
    sampleCriticalFailures,
    sampleMajorFailures,
    sampleMinorFailures,
    sampleSize,
  } = props;
  const { t, tHTML } = useI18n();

  const [showAsPercentage, setShowAsPercentage] = useState(true);

  let finalLotCritical;
  let finalLotMajor;
  let finalLotMinor;
  let sampleCritical;
  let sampleMajor;
  let sampleMinor;
  let sampleCriticalMetric;
  let sampleMajorMetric;
  let sampleMinorMetric;
  let criticalAQL;
  let majorAQL;
  let minorAQL;
  if (showAsPercentage) {
    sampleCritical = sampleSize ? sampleCriticalFailures / sampleSize : 0;
    sampleMajor = sampleSize ? sampleMajorFailures / sampleSize : 0;
    sampleMinor = sampleSize ? sampleMinorFailures / sampleSize : 0;
    finalLotCritical = finalLotSize ? lotCriticalFailure / finalLotSize : 0;
    finalLotMajor = finalLotSize ? lotMajorFailure / finalLotSize : 0;
    finalLotMinor = finalLotSize ? lotMinorFailure / finalLotSize : 0;
    criticalAQL = OK_QUALITY_AQL_CRITICAL_PERCENT;
    majorAQL = OK_QUALITY_AQL_MAJOR_PERCENT;
    minorAQL = OK_QUALITY_AQL_MINOR_PERCENT;
    sampleCriticalMetric = criticalAQL;
    sampleMajorMetric = majorAQL;
    sampleMinorMetric = minorAQL;
  } else {
    sampleCritical = sampleCriticalFailures;
    sampleMajor = sampleMajorFailures;
    sampleMinor = sampleMinorFailures;
    finalLotCritical = lotCriticalFailure;
    finalLotMajor = lotMajorFailure;
    finalLotMinor = lotMinorFailure;
    criticalAQL = round(OK_QUALITY_AQL_CRITICAL_PERCENT * finalLotSize);
    majorAQL = round(OK_QUALITY_AQL_MAJOR_PERCENT * finalLotSize);
    minorAQL = round(OK_QUALITY_AQL_MINOR_PERCENT * finalLotSize);
    sampleCriticalMetric = round(OK_QUALITY_AQL_CRITICAL_PERCENT * sampleSize);
    sampleMajorMetric = round(OK_QUALITY_AQL_MAJOR_PERCENT * sampleSize);
    sampleMinorMetric = round(OK_QUALITY_AQL_MINOR_PERCENT * sampleSize);
  }

  return (
    <div>
      <h4>{t('LOG_QUALITY_LEVEL')}</h4>
      <Text>{tHTML('LOG_QUALITY_LEVEL_DESCRIPTION')}</Text>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{t('LOG_PRIORITY')}</th>
            <th>
              <TestSeverityIcon level={TestAssetModel.SEVERITY_LEVEL.MINOR} />
            </th>
            <th>
              <TestSeverityIcon level={TestAssetModel.SEVERITY_LEVEL.MAJOR} />
            </th>
            <th>
              <TestSeverityIcon level={TestAssetModel.SEVERITY_LEVEL.CRITICAL} />
            </th>
          </tr>
        </thead>
        <tbody>
          <QualityRow
            critical={criticalAQL}
            major={majorAQL}
            minor={minorAQL}
            showAsPercentage={showAsPercentage}
            type={tHTML('LOG_PRIORITY_LIMIT')}
          />
          <QualityRow
            critical={sampleCritical}
            criticalMetric={sampleCriticalMetric}
            major={sampleMajor}
            majorMetric={sampleMajorMetric}
            minor={sampleMinor}
            minorMetric={sampleMinorMetric}
            showAsPercentage={showAsPercentage}
            type={t('LOG_SAMPLE')}
          />
          <QualityRow
            critical={finalLotCritical}
            criticalMetric={criticalAQL}
            major={finalLotMajor}
            majorMetric={majorAQL}
            minor={finalLotMinor}
            minorMetric={minorAQL}
            showAsPercentage={showAsPercentage}
            type={t('LOG_FINAL_LOT')}
          />
        </tbody>
      </table>
      <Tag
        className={`${styles.modeToggle} ${showAsPercentage ? styles.active : ''}`}
        onClick={() => setShowAsPercentage(true)}
        size='xs'
        tint={showAsPercentage ? 'navigation' : null}
      >
        {t('LOG_QUALITY_LEVEL_PERCENT')}
      </Tag>
      <Tag
        className={`${styles.modeToggle} ${showAsPercentage ? '' : styles.active}`}
        onClick={() => setShowAsPercentage(false)}
        size='xs'
        tint={showAsPercentage ? null : 'navigation'}
      >
        {t('LOG_QUALITY_LEVEL_QUANTITY')}
      </Tag>
      <Footnotes className={styles.footnotes}>
        <Footnote>{t('LOG_QUALITY_LEVEL_FOOTNOTE_1')}</Footnote>
        <Footnote>{t('LOG_QUALITY_LEVEL_FOOTNOTE_2')}</Footnote>
      </Footnotes>
    </div>
  );
}

InspectionLogQualityLevelTable.propTypes = {
  finalLotSize: PropTypes.number.isRequired,
  lotCriticalFailure: PropTypes.number.isRequired,
  lotMajorFailure: PropTypes.number.isRequired,
  lotMinorFailure: PropTypes.number.isRequired,
  sampleCriticalFailures: PropTypes.number.isRequired,
  sampleMajorFailures: PropTypes.number.isRequired,
  sampleMinorFailures: PropTypes.number.isRequired,
  sampleSize: PropTypes.number.isRequired,
};

function QualityRow(props) {
  const { critical, criticalMetric, major, majorMetric, minor, minorMetric, showAsPercentage, type } = props;

  let criticalTint;
  let criticalClarifier;
  if (criticalMetric !== undefined) {
    criticalTint = critical <= criticalMetric ? 'creation' : 'alert';

    if (critical && showAsPercentage) {
      if (critical > 0 && critical < 0.001) {
        criticalClarifier = '<';
      } else {
        const criticalDifference = Math.abs(criticalMetric * 10000 - critical * 10000); // Get difference in whole numbers due to machine rounding errors
        if (criticalDifference > 0 && criticalDifference <= 5) {
          // If difference is <= .0005, value displayed will match metric, so add clarifier
          criticalClarifier = critical > criticalMetric ? '>' : '<';
        }
      }
    }
  }
  let majorTint;
  let majorClarifier;
  if (majorMetric !== undefined) {
    majorTint = major <= majorMetric ? 'creation' : 'alert';

    if (major && showAsPercentage) {
      if (major > 0 && major < 0.001) {
        majorClarifier = '<';
      } else {
        const majorDifference = Math.abs(majorMetric * 10000 - major * 10000); // Get difference in whole numbers due to machine rounding errors
        if (majorDifference > 0 && majorDifference <= 5) {
          // If difference is <= .0005, value displayed will match metric, so add clarifier
          majorClarifier = major > majorMetric ? '>' : '<';
        }
      }
    }
  }
  let minorTint;
  let minorClarifier;
  if (minorMetric !== undefined) {
    minorTint = minor <= minorMetric ? 'creation' : 'alert';

    if (minor && showAsPercentage) {
      if (minor > 0 && minor < 0.001) {
        minorClarifier = '<';
      } else {
        const minorDifference = Math.abs(minorMetric * 10000 - minor * 10000); // Get difference in whole numbers due to machine rounding errors
        if (minorDifference > 0 && minorDifference <= 5) {
          // If difference is <= .0005, value displayed will match metric, so add clarifier
          minorClarifier = minor > minorMetric ? '>' : '<';
        }
      }
    }
  }

  return (
    <tr>
      <td>{type}</td>
      <td>
        <Text className={styles.data} tint={minorTint}>
          {minorClarifier}
          {showAsPercentage ? formatPercentage(minor) : formatNumber(minor)}
        </Text>
      </td>
      <td>
        <Text className={styles.data} tint={majorTint}>
          {majorClarifier}
          {showAsPercentage ? formatPercentage(major) : formatNumber(major)}
        </Text>
      </td>
      <td>
        <Text className={styles.data} tint={criticalTint}>
          {criticalClarifier}
          {showAsPercentage ? formatPercentage(critical) : formatNumber(critical)}
        </Text>
      </td>
    </tr>
  );
}

QualityRow.propTypes = {
  critical: PropTypes.number.isRequired,
  criticalMetric: PropTypes.number,
  major: PropTypes.number.isRequired,
  majorMetric: PropTypes.number,
  minor: PropTypes.number.isRequired,
  minorMetric: PropTypes.number,
  showAsPercentage: PropTypes.bool,
  type: PropTypes.node.isRequired,
};
