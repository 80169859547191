import { useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import ContentLayout from 'OK/components/layouts/content';
import Progressable from 'OK/components/progressable';
import Remark from 'OK/components/remark';
import RemarkLorealCard from 'OK/components/remark/lorealCard';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Text from 'OK/components/text';
import NoteModel from 'OK/models/note';
import BaseNoteModel from 'OK/models/note/base';
import SiteModel from 'OK/models/site';
import SiteNoteModel from 'OK/models/siteNote';
import { searchQuery } from 'OK/networking/search';
import {
  createSiteNoteMutation,
  publishSiteNoteMutation,
  unlinkSiteNoteMutation,
  unpublishSiteNoteMutation,
} from 'OK/networking/sites';
import { useLinkSiteNoteAPI } from 'OK/networking/sites/hooks';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import useI18n from 'OK/util/hooks/useI18n';

/* Helpers */

const indexOfSiteNoteInList = (noteAssetId, list, readField) => {
  return list.findIndex((pn) => {
    const listNoteAsset = readField('noteAsset', pn);
    const listNoteAssetId = readField('id', listNoteAsset);
    return listNoteAssetId === noteAssetId;
  });
};

export default function EditSitePageNotesSection(props) {
  /* Variables */
  const { editMode, siteNotes, siteId } = props;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const { t } = useI18n();

  // State
  const [creationError, setCreationError] = useState(null);
  const [isCreatingNewNote, setIsCreatingNewNote] = useState(false);
  const [newNote, setNewNote] = useState(null);
  const [searchNotesFocused, setSearchNotesFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Refs
  const notesSliderRef = useRef();

  /* API */

  const siteCacheId = `${SiteModel.GRAPHQL_TYPE}:${siteId}`;

  const [createSiteNoteAPI] = useMutation(createSiteNoteMutation, {
    onCompleted: () => {
      cancelCreatingNote();
    },
    update: (cache, result) => {
      if (result.data?.siteNote) {
        // Write NoteAsset to cache
        const noteAssetRef = cache.writeFragment({
          id: `${BaseNoteModel.GRAPHQL_TYPE}:${result.data.siteNote.noteAsset.id}`,
          fragment: BaseNoteModel.fragment,
          fragmentName: BaseNoteModel.fragmentName,
          data: result.data.siteNote.noteAsset,
        });

        // Add SiteNoteAsset to site's list
        const siteNote = {
          ...result.data.siteNote,
          noteAsset: noteAssetRef,
        };
        cache.modify({
          id: siteCacheId,
          fields: {
            siteNoteAssetList: (currentList) => {
              return [...currentList, siteNote];
            },
          },
        });

        setTimeout(() => {
          notesSliderRef.current.scrollToSlideAtIndex(siteNotes.length);
        }, 50);
      } else {
        okwarn('API did not return new site note');
      }
    },
  });

  const linkSiteNote = useLinkSiteNoteAPI();

  const [publishSiteNoteAPI] = useMutation(publishSiteNoteMutation, {
    update: (cache, result) => {
      if (result.data?.siteNote) {
        cache.modify({
          id: siteCacheId,
          fields: {
            siteNoteAssetList: (currentList, { readField }) => {
              const responseSiteNote = result.data.siteNote;
              const indexOfSiteNote = indexOfSiteNoteInList(responseSiteNote.noteAsset.id, currentList, readField);
              if (indexOfSiteNote > -1) {
                const siteNote = {
                  ...currentList[indexOfSiteNote],
                  publishStatus: responseSiteNote.publishStatus,
                };
                const updatedList = [...currentList];
                updatedList.splice(indexOfSiteNote, 1, siteNote);
                return updatedList;
              }

              okerror(
                `Site note ${responseSiteNote.noteAsset.id} cannot be published on site ${siteId} because it is not linked.`
              );
            },
          },
        });
      } else {
        okwarn('API did not return published site note');
      }
    },
  });

  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);

  const [unlinkSiteNoteAPI] = useMutation(unlinkSiteNoteMutation, {
    update: (cache, result) => {
      if (result.data?.siteNote) {
        cache.modify({
          id: siteCacheId,
          fields: {
            siteNoteAssetList: (currentList, { readField }) => {
              const { siteNote } = result.data;
              const indexOfSiteNote = indexOfSiteNoteInList(siteNote.noteAsset.id, currentList, readField);
              if (indexOfSiteNote > -1) {
                const updatedList = [...currentList];
                updatedList.splice(indexOfSiteNote, 1);
                return updatedList;
              }

              okerror(
                `Site note ${siteNote.noteAsset.id} cannot be unlinked from site ${siteId} because it is not linked.`
              );
            },
          },
        });
      } else {
        okwarn('API did not return unlinked site note');
      }
    },
  });

  const [unpublishSiteNoteAPI] = useMutation(unpublishSiteNoteMutation, {
    update: (cache, result) => {
      if (result.data?.siteNote) {
        cache.modify({
          id: siteCacheId,
          fields: {
            siteNoteAssetList: (currentList, { readField }) => {
              const responseSiteNote = result.data.siteNote;
              const indexOfSiteNote = indexOfSiteNoteInList(responseSiteNote.noteAsset.id, currentList, readField);
              if (indexOfSiteNote > -1) {
                const siteNote = {
                  ...currentList[indexOfSiteNote],
                  publishStatus: responseSiteNote.publishStatus,
                };
                const updatedList = [...currentList];
                updatedList.splice(indexOfSiteNote, 1, siteNote);
                return updatedList;
              }

              okerror(
                `Site note ${responseSiteNote.noteAsset.id} cannot be unpublished on site ${siteId} because it is not linked.`
              );
            },
          },
        });
      } else {
        okwarn('API did not return published site note');
      }
    },
  });

  /* Methods */

  const cancelCreatingNote = () => {
    setIsCreatingNewNote(false);
    setNewNote(null);
  };

  const showCreateNoteUI = () => {
    setIsCreatingNewNote(true);

    if (!newNote) {
      const blankNote = NoteModel.mock();
      blankNote.textContentMap.EN = { text: '' };
      setNewNote(blankNote);
    }

    // Scroll to new note slide
    // Timeout is required so it scrolls after the new note card is shown
    setTimeout(() => {
      notesSliderRef.current.scrollToSlideAtIndex(siteNotes.length);
    }, 0);
  };

  const publishNote = useCallback(
    (noteId) => {
      // Optimistic response
      const siteNote = siteNotes.find((pn) => pn.noteAsset.id === noteId);
      const optimisticResponse = {
        siteNote: {
          ...siteNote,
          publishStatus: PUBLISH_STATUS.PUBLISHED,
        },
      };

      // Call API
      publishSiteNoteAPI({
        variables: {
          noteId,
          siteId,
        },
        optimisticResponse,
      });
    },
    [siteId, siteNotes, publishSiteNoteAPI]
  );

  const searchDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'NOTE_ASSET',
                ignoreIdList: [...siteNotes.map((pn) => pn.noteAsset.id)],
              },
            ],
            searchPaginationDataByDataType: [
              {
                dataType: 'NOTE_ASSET',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [siteNotes, searchAPI]
  );

  const unlinkNote = useCallback(
    (noteId) => {
      // Optimistic response
      const siteNote = siteNotes.find((pn) => pn.noteAsset.id === noteId);
      const optimisticResponse = {
        siteNote,
      };

      // Call API
      unlinkSiteNoteAPI({
        variables: {
          noteId,
          siteId,
        },
        optimisticResponse,
      });
    },
    [siteId, siteNotes, unlinkSiteNoteAPI]
  );

  const unpublishNote = useCallback(
    (noteId) => {
      // Optimistic response
      const siteNote = siteNotes.find((pn) => pn.noteAsset.id === noteId);
      const optimisticResponse = {
        siteNote: {
          ...siteNote,
          publishStatus: PUBLISH_STATUS.UNPUBLISHED,
        },
      };

      // Call API
      unpublishSiteNoteAPI({
        variables: {
          noteId,
          siteId,
        },
        optimisticResponse,
      });
    },
    [siteId, siteNotes, unpublishSiteNoteAPI]
  );

  // Event handlers

  const onChangeNewNote = (_, __, newTextValues) => {
    const textContentMap = {};
    newTextValues.forEach((textValue) => {
      textContentMap[textValue.languageIso] = { text: textValue.value };
    });
    let updatedNote = {
      ...newNote,
      textContentMap,
    };
    setNewNote(updatedNote);
  };

  const onClickLinkNote = useCallback(
    (noteAssetId) => {
      setSearchTerm('');
      linkSiteNote(siteId, noteAssetId).then(() => {
        setTimeout(() => {
          notesSliderRef.current.scrollToSlideAtIndex(siteNotes.length);
        }, 50);
      });
    },
    [linkSiteNote, siteId, siteNotes.length]
  );

  const onClickUnlinkNote = useCallback(
    (noteId) => {
      unlinkNote(noteId);
    },
    [unlinkNote]
  );

  const onSaveNewNote = () => {
    setCreationError(null);

    // Get note text
    const text = Object.keys(newNote.textContentMap).map((languageIso) => {
      return {
        languageCode: languageIso.toUpperCase(),
        languageText: newNote.textContentMap[languageIso].text,
      };
    });

    // Get note published status
    const publishStatus = PUBLISH_STATUS.UNPUBLISHED;

    // Optimistic response
    const optimisticResponse = {
      siteNote: {
        noteAsset: newNote,
        publishStatus,
        __typename: SiteNoteModel.GRAPHQL_TYPE,
      },
    };

    // Call API
    setIsCreatingNewNote(false);
    createSiteNoteAPI({
      variables: {
        siteId,
        published: publishStatus,
        text,
      },
      optimisticResponse,
    }).catch(() => {
      setCreationError(t('ERROR_GENERIC'));
      setIsCreatingNewNote(true);
    });
  };

  const onSearch = useCallback(
    (e) => {
      const newSearchTerm = e.target.value;
      setSearchTerm(newSearchTerm);

      if (newSearchTerm.length > 1) {
        searchDebounced(newSearchTerm);
      }
    },
    [searchDebounced]
  );

  const onToggleNotePublished = useCallback(
    (noteId, published) => {
      if (published) {
        publishNote(noteId);
      } else {
        unpublishNote(noteId);
      }
    },
    [publishNote, unpublishNote]
  );

  /* Render */

  const searchSuggestions = useMemo(() => {
    if (searchNotesFocused && searchTerm.length > 1) {
      return (
        searchAPIResult.data?.search?.resultList?.map((r) => {
          const noteAsset = r.noteAssetData;

          // Highlight
          let highlightString;
          if (r.dataHighlights?.length) {
            const keys = r.dataHighlights[0].split('.');
            let data = noteAsset;
            for (let keyIndex in keys) {
              const key = keys[keyIndex];
              data = data[key];
            }
            highlightString = data;
          }

          return {
            highlightString,
            icon: ICONS.PENCIL.name,
            key: noteAsset.id,
            title: t('NOTE_CARD_HEADER'),
            subtitle: noteAsset.REFID,
          };
        }) ?? []
      );
    }

    return [];
  }, [searchAPIResult.data?.search?.resultList, searchNotesFocused, searchTerm.length, t]);

  const siteNoteCards = useMemo(
    () =>
      siteNotes.map((pn) => (
        <Slide key={pn.noteAsset.id} className={styles.noteSlide}>
          <Remark
            className={styles.note}
            onClickUnlink={onClickUnlinkNote}
            onPublishChange={onToggleNotePublished}
            published={pn.publishStatus === PUBLISH_STATUS.PUBLISHED}
            note={pn.noteAsset}
            unlinkButtonTitle={t('UNLINK_FROM_SITE')}
            fixedWidth={useDesktopLayout}
          />
        </Slide>
      )),
    [onClickUnlinkNote, onToggleNotePublished, siteNotes, t, useDesktopLayout]
  );

  return (
    <div>
      {editMode ? (
        <ContentLayout pageContent>
          <div className={styles.sectionIntro}>
            <p>{t('SITE_SECTION_NOTES_DESCRIPTION')}</p>
            <div className={styles.searchInputContainer}>
              <SearchInput
                className={styles.searchInput}
                loading={searchAPIResult.loading}
                onBlur={() => setSearchNotesFocused(false)}
                onChange={onSearch}
                onFocus={() => setSearchNotesFocused(true)}
                placeholder={t('NOTES_SEARCH_PLACEHOLDER')}
                value={searchTerm}
              />
              <Button className={styles.searchNewButton} linkStyle onClick={showCreateNoteUI} tint='creation'>
                {t('NEW')}
              </Button>
              <SearchSuggestions
                accessoryViewRender={(s) => {
                  const isSite = s.type === 'site';
                  const { hasRemarks = false } = s;
                  let buttonLabel, buttonIcon;
                  let buttonDisabled = false;
                  if (isSite) {
                    if (hasRemarks) {
                      buttonLabel = t('LINK_ALL');
                      buttonIcon = '/icons/link_all_blue.svg';
                    } else {
                      buttonLabel = t('LOG_ARCHIVE_PAGE_NO_NOTES');
                      buttonDisabled = true;
                    }
                  } else {
                    buttonLabel = t('LINK');
                    buttonIcon = '/icons/link_blue.svg';
                  }
                  return (
                    <Button className={styles.searchResultButton} disabled={buttonDisabled} icon={buttonIcon} linkStyle>
                      {buttonLabel}
                    </Button>
                  );
                }}
                className={styles.searchResults}
                highlightTerm={searchTerm}
                onSuggestionClick={onClickLinkNote}
                showMoreResultsMessage={
                  searchNotesFocused &&
                  searchAPIResult.data?.search?.searchPaginationResultDataByDataType?.NOTE_ASSET?.totalResults >
                    searchSuggestions.length
                }
                showNoResultsMessage={searchNotesFocused && searchTerm.length > 1 && searchSuggestions?.length === 0}
                subtitleClassName={styles.searchResultOKID}
                suggestions={searchSuggestions}
              />
            </div>
            {creationError && (
              <Text className={styles.error} size='sm' tint='notification'>
                {creationError}
              </Text>
            )}
          </div>
          <Progressable style={{ minHeight: '100px' }}>
            <Carousel
              className={styles.carouselContainer}
              fadeOutSides={useDesktopLayout}
              innerClassName={styles.carousel}
              ref={notesSliderRef}
            >
              {siteNoteCards}
              <Slide className={styles.noteSlide}>
                {isCreatingNewNote ? (
                  <Remark
                    className={styles.note}
                    disableAPI
                    onChange={onChangeNewNote}
                    onClickUnlink={cancelCreatingNote}
                    onSave={onSaveNewNote}
                    note={newNote}
                    unsaved
                    fixedWidth={useDesktopLayout}
                  />
                ) : (
                  <button className={styles.addSlideButton} onClick={showCreateNoteUI}>
                    <Icon className={styles.addSlideIcon} height={80} name={ICONS.PLUS_BIG_CIRCLE.name} width={80} />{' '}
                    {t('ADD_NEW_NOTE')}
                  </button>
                )}
              </Slide>
            </Carousel>
          </Progressable>
        </ContentLayout>
      ) : (
        <ContentLayout pageContent>
          <div style={{ paddingTop: 30 }}>
            {siteNotes?.length ? (
              <Carousel
                className={styles.carouselContainer}
                innerClassName={styles.carousel}
                fadeOutSides={useDesktopLayout}
              >
                {siteNotes.map((pn) => (
                  <Slide key={pn.noteAsset.id} className={styles.noteSlide}>
                    <RemarkLorealCard className={styles.note} fixedWidth={false} remark={pn.noteAsset} />
                  </Slide>
                ))}
              </Carousel>
            ) : (
              <Text>{t('LOG_ARCHIVE_PAGE_NO_NOTES')}</Text>
            )}
          </div>
        </ContentLayout>
      )}
    </div>
  );
}

EditSitePageNotesSection.propTypes = {
  editMode: PropTypes.bool,
  siteNotes: PropTypes.array.isRequired,
  siteId: PropTypes.string.isRequired,
};
