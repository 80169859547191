import { gql } from '@apollo/client';

export default class BaseSiteModel {
  static GRAPHQL_TYPE = 'Site';

  static fragmentName = 'Base_Site_Fragment';
  static fragment = gql`
    fragment ${BaseSiteModel.fragmentName} on ${BaseSiteModel.GRAPHQL_TYPE} {
      id
      createdBy
      createdDate
      identifier
      lastModifiedBy
      lastModifiedDate
      name
      OKID
      publishStatus
      status
      version
    }
  `;
}
