import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import ColumnCard from 'OK/components/archiveCard/ColumnCard';
import { Carousel, Slide } from 'OK/components/carousel';
import DocumentMinimalCard from 'OK/components/document/minimalCard';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import Text from 'OK/components/text';
import useI18n from 'OK/util/hooks/useI18n';

export default function InspectionLogFilesSection(props) {
  /* Variables */
  const { className, inspectionLog } = props;
  const { t } = useI18n();

  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const documentAssets = useMemo(() => {
    return inspectionLog.inspectionLogDocumentAssetList.map((d) => d.documentAsset);
  }, [inspectionLog.inspectionLogDocumentAssetList]);
  const alertAssets = useMemo(() => {
    const inspectionLogTestAssetList = inspectionLog.inspectionLogTestAssetList[0];

    if (inspectionLogTestAssetList) {
      return inspectionLog.inspectionLogTestAssetList[0].inspectionLogFindingList?.map((f) => f.unversionedMediaAsset);
    } else {
      return [];
    }
  }, [inspectionLog.inspectionLogTestAssetList]);

  /* Render */

  let classNames = styles.section;
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <ContentLayout className={classNames} pageContent>
      <TextLayout>
        <h3>{t('DOCUMENT_ARCHIVE_CARD_TITLE')}</h3>
        <Text className={styles.sectionDescription}>
          {t('LOG_ARCHIVE_PAGE_DOCUMENTATION_SECTION_DESCRIPTION')}
        </Text>
      </TextLayout>

      <Carousel className={styles.carouselContainer} innerClassName={styles.carousel} fadeOutSides={useDesktopLayout}>
        {documentAssets.map((d) => {
          return (
            <Slide className={styles.fileSlide} key={d.id}>
              <DocumentMinimalCard
                className={styles.fileCard}
                containerClassName={styles.fileContainer}
                document={d}
                linkContainerClassName={styles.fileLinkContainer}
                showAsCard
                showDownloadButton
              />
            </Slide>
          );
        })}
        {alertAssets.map((alert, index) => {
          return (
            <Slide style={{ marginRight: 20 }} key={index}>
              <ColumnCard asset={alert} assetType='alert' fixedColumn />
            </Slide>
          );
        })}
      </Carousel>
    </ContentLayout>
  );
}

InspectionLogFilesSection.propTypes = {
  className: PropTypes.string,
  inspectionLog: PropTypes.object.isRequired,
};
