import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import config from 'OK/config/app';
import CommentModel from 'OK/models/comment';
import BaseInspectionLogFindingModel from 'OK/models/inspectionLogFinding/base';
import InspectionLogTestAssetModel from 'OK/models/inspectionLogTestAsset';
import getStore from 'OK/state/store';
import { trackError } from 'OK/util/analytics';
import SessionManager from 'OK/util/session';

/* Busines logic links */

/** Add the Authorization header to operations. */
const AuthorizationHeaderLink = new ApolloLink((operation, forward) => {
  addAuthorizationHeaderToOperation(operation);
  return forward(operation);
});

/**
 * Handle errors.
 *
 * Currently only handles expired JWT errors by renewing the session and retrying the request.
 */
const ErrorHandlingLink = onError((e) => {
  if (e.networkError && e.networkError.statusCode === 401) {
    // Renew the user session and retry the operation
    // see https://www.apollographql.com/docs/react/data/error-handling/#retrying-operations
    return new Observable((observer) => {
      const { forward, operation } = e;
      // Renew user session
      return SessionManager.renewSession()
        .then(() => {
          // Retry operation
          return forward(operation).subscribe(observer);
        })
        .catch((renewError) => {
          okerror('Error renewing session after failed GraphQL request:', renewError);
          observer.error(renewError);
          trackError(e);
        });
    });
  } else {
    okdebug('Unhandled GraphQL error', e);
    trackError(e);
  }
});

/**
 * Wait for network connection before proceeding with request.
 *
 * Waits up to 10 seconds for a network connection. If the network doesn't re-connect within that timeframe,
 * the request fails.
 */
const WaitForNetworkConnectionLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    // Run request
    const runRequest = () => {
      forward(operation).subscribe(observer);
    };

    if (navigator.onLine) {
      runRequest();
    } else {
      let networkCheckTimeout;

      // Run request and remove network status listeners
      const resumeRequest = () => {
        window.removeEventListener('online', resumeRequest);
        clearTimeout(networkCheckTimeout);
        runRequest();
      };
      // Cancel request
      const cancelRequest = () => {
        window.removeEventListener('online', resumeRequest);
        okerror('No network connection for GraphQL request.');
        observer.error('No network connection.');
      };

      // Wait for network connection to run request
      okdebug('Waiting to run GraphQL request when network connection resumes...');
      window.addEventListener('online', resumeRequest);

      // Cancel request if no network connection after 10 seconds
      networkCheckTimeout = setTimeout(() => {
        cancelRequest();
      }, 10000);
    }
  });
});

/**
 * Wait for session restoration before proceeding with request.
 *
 * Can be disabled by setting `context.waitWhileRestoringSession` to `false`.
 */
const WaitForSessionLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    const { waitWhileRestoringSession = true } = operation.getContext();
    const isRestoringSession = getStore().getState().account.isRestoring;
    if (!isRestoringSession || !waitWhileRestoringSession) {
      return forward(operation).subscribe(observer);
    }

    const resume = () => {
      okdebug('Session restored, sending GraphQL request.');
      window.removeEventListener('SESSION_RESTORED', resume);
      forward(operation).subscribe(observer);
    };

    okdebug('Waiting for session to be restored before sending GraphQL request...');
    window.addEventListener('SESSION_RESTORED', resume);
  });
});

/* Compose Links */

const ServiceLink = new HttpLink({ uri: () => `${config.api_url}/graphql` });

/* Helpers */

/** Add Authorization header to an operation if there is a valid session. */
function addAuthorizationHeaderToOperation(operation) {
  const { accessToken, activeInspectionLogId, activeOrganisationId } = SessionManager;
  operation.setContext(({ headers = {}, addActiveOrganisationIdHeader = true }) => {
    const authorizationHeaders = {};
    if (accessToken) {
      authorizationHeaders.Authorization = `Bearer ${accessToken}`;
    }
    if (addActiveOrganisationIdHeader && activeOrganisationId) {
      authorizationHeaders['Authorization-Organization-Id'] = activeOrganisationId;
    }
    if (activeInspectionLogId) {
      authorizationHeaders['Authorization-Inspection-Log-Id'] = activeInspectionLogId;
    }
    return {
      headers: {
        ...headers,
        ...authorizationHeaders,
      },
    };
  });
}

/* Exports */

export default new ApolloClient({
  assumeImmutableResults: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
  cache: new InMemoryCache({
    typePolicies: {
      [BaseInspectionLogFindingModel.GRAPHQL_TYPE]: BaseInspectionLogFindingModel.FIELD_POLICIES,
      [CommentModel.GRAPHQL_TYPE]: CommentModel.FIELD_POLICIES,
      [InspectionLogTestAssetModel.GRAPHQL_TYPE]: InspectionLogTestAssetModel.FIELD_POLICIES,
      Query: {
        fields: {
          filterInspectionLogsUnderOrganisation: {
            keyArgs: [
              'endDateUTCString',
              'filterByInspectorIdList',
              'filterBySourceIdList',
              'includeInternalLogs',
              'organisationId',
              'startDateUTCString',
            ],
            merge: (existing, incoming, { readField }) => {
              const existingInspectionLogList = readField('inspectionLogList', existing) ?? [];
              const incomingInspectionLogList = readField('inspectionLogList', incoming) ?? [];

              // Determine whether incoming contains existing
              let incomingContainsExisting = false;
              for (const inspectionLog of incomingInspectionLogList) {
                const incomingId = readField('id', inspectionLog);
                const matchedIndex = existingInspectionLogList.findIndex((existingInspectionLog) => {
                  const existingId = readField('id', existingInspectionLog);
                  return existingId === incomingId;
                });

                if (matchedIndex > -1) {
                  incomingContainsExisting = true;
                  break;
                }
              }

              if (incomingContainsExisting) {
                // Replace value
                return incoming;
              }

              // Merge value
              return {
                ...incoming,
                inspectionLogList: [...existingInspectionLogList, ...incomingInspectionLogList],
              };
            },
          },
          // Field policy for comments on an asset
          getCommentPagedResult: {
            keyArgs: ['sourceDataType', 'sourceId'],
            merge: (existing, incoming, { readField }) => {
              const existingCommentList = readField('commentList', existing) ?? [];
              const incomingCommentList = readField('commentList', incoming) ?? [];
              return {
                ...incoming,
                commentList: [...existingCommentList, ...incomingCommentList],
              };
            },
          },
          search: {
            keyArgs: (args) => {
              const keyArgs = {
                ...args,
                searchPaginationDataByDataType: args.searchPaginationDataByDataType.map((pd) => pd.dataType),
              };
              return `search(${JSON.stringify(keyArgs)})`;
            },
            merge: (existing, incoming, { readField }) => {
              const existingResultList = readField('resultList', existing) ?? [];
              const incomingResultList = readField('resultList', incoming) ?? [];

              // Determine whether incoming contains existing
              let incomingContainsExisting = false;
              for (const result of incomingResultList) {
                const incomingDataType = readField('dataType', result);
                const incomingDataId = readField('dataId', result);
                const matchedIndex = existingResultList.findIndex((existingResult) => {
                  const existingDataType = readField('dataType', existingResult);
                  const existingDataId = readField('dataId', existingResult);
                  if (existingDataType === incomingDataType && existingDataId === incomingDataId) {
                    return true;
                  }

                  return false;
                });

                if (matchedIndex > -1) {
                  incomingContainsExisting = true;
                  break;
                }
              }

              if (incomingContainsExisting) {
                // Replace value
                return incoming;
              }

              // Merge value
              return {
                ...incoming,
                resultList: [...existingResultList, ...incomingResultList],
              };
            },
          },
        },
      },
    },
  }),
  link: from([
    WaitForNetworkConnectionLink,
    WaitForSessionLink,
    ErrorHandlingLink,
    AuthorizationHeaderLink,
    ServiceLink,
  ]),
  resolvers: {},
});
