import { gql } from '@apollo/client';

/* Articles */

export const getArticlesQuery = gql`
  query GetArticles {
    articles: getArticles {
      id
      articleReadingTime
      articleURL
      articleWriterList {
        imageURL
        jobTitle
        name
      }
      imageURL
      publishDate
      title
    }
  }
`;

export const getLatestArticleQuery = gql`
  query GetLatestArticle {
    article: getLatestArticle {
      id
      articleReadingTime
      articleURL
      articleWriterList {
        imageURL
        jobTitle
        name
      }
      imageURL
      publishDate
      title
    }
  }
`;

/* Press releases */

export const getPressReleasesQuery = gql`
  query GetPressReleases {
    pressReleases: getPressReleases {
      id
      articleURL
      imageURL
      publishDate
      title
    }
  }
`;

/* Webinars */

export const getLatestWebinarQuery = gql`
  query GetLatestWebinar {
    webinar: getUpcomingOrLatestWebinar {
      id
      endDate
      imageURL
      startDate
      status
      title
    }
  }
`;

export const getWebinarQuery = gql`
  query GetWebinar($webinarId: String) {
    webinar: getWebinar(webinarId: $webinarId) {
      id
      descriptionHTML
      endDate
      eventRecordingURL
      imageURL
      startDate
      status
      title
      webinarHostList {
        email
        imageURL
        jobTitle
        name
        organisation
        phone
        speakerBioHTML
      }
    }
  }
`;

export const getWebinarsQuery = gql`
  query GetWebinars {
    webinars: getWebinars {
      id
      endDate
      imageURL
      startDate
      status
      title
    }
  }
`;
