import 'OK/modules/entry';

import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';
import { withRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import PlausibleProvider from 'next-plausible';
import PropTypes from 'prop-types';
import { Component as ReactComponent } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import UAParser from 'ua-parser-js';

import 'normalize.css';
import 'OK/styles/global.scss';

import MainLayout from 'OK/components/layouts/main';
import appConfig from 'OK/config/app';
import AppContainer from 'OK/modules/app-container';
import { updateMiscAppStateAction } from 'OK/state/app/actions';
import configureStore from 'OK/state/store';
import { baseTheme } from 'OK/styles/theme';
import { trackError } from 'OK/util/analytics';
import { APP_TOP_MARKER_ELEMENT_ID } from 'OK/util/constants';

class App extends ReactComponent {
  constructor(props) {
    super(props);

    const isBrowser = typeof window !== 'undefined';

    // Hydrate i18n with the correct locale. Default to English if none.
    // This happens on the server.

    this.reduxStore = configureStore();
    this.userAgent = isBrowser ? UAParser(navigator.userAgent) : {};

    this.state = {
      isBrowser,
      isInstalledAsPWA: isBrowser ? getIsInstalledAsPWA() : false,
      isNavigatingBack: false,
      isServer: !isBrowser,
      locations: [],
      locationTrackingInterval: null,
      needsPop: false,
      screenSize: { height: 0, width: 0 },
    };
  }

  // Lifecycle

  componentDidMount() {
    if (this.state.isBrowser) {
      // Keep track of the screen size.
      window.addEventListener('resize', this.updateScreenSize);

      // Hide the keyboard when the background is tapped
      document.body.addEventListener('click', this.hideKeyboard);

      // Track history of locations so we can do pop transitions
      // Keep history of locations so we can determine whether the user is going to a previous page.
      // This is done by polling the current window location and storing it in an array if it differs
      // from the latest one in the stack.
      const locationTrackingInterval = setInterval(() => {
        const location = window.location.href;
        const lastLocation = this.getLastLocation(this.state.locations);
        if (this.state.needsPop) {
          // We need to pop the latest location from the history because the user triggered a back transition.
          // Once this is done, we can disable the back flag so transitions are returned to normal.
          this.state.locations.pop();
          this.setState({ isNavigatingBack: false, needsPop: false });
        } else if (location !== lastLocation) {
          // Track the new location
          this.state.locations.push(location);
        }
        // It's probably important that this interval is set to something longer than the push/pop transition duration
        // so we don't disable the back navigation flag before/during the back transition.
      }, 500);

      this.setState({ locationTrackingInterval, screenSize: this.detectScreenSize() });

      // This will be triggered whenever the user triggers a browser back/forward
      this.props.router.beforePopState((state) => {
        const { as } = state;
        const newLocation = `${window.location.origin}${as}`;
        const prevLocation = this.getPreviousLocation(this.state.locations);
        if (newLocation == prevLocation) {
          // We're returning to a previous location, so enable the back flag for a pop transition and mark that we need
          // to pop a location off the history stack. We don't do this immediately because we want the back transition
          // to occur first.
          this.setState({ isNavigatingBack: true, needsPop: true });
        }

        return true;
      });

      // Detect online / offline status
      this.handleOnlineStatusChange();
      window.addEventListener('online', this.handleOnlineStatusChange);
      window.addEventListener('offline', this.handleOnlineStatusChange);

      // Detect PWA installation changes
      this.handlePWAInstallationChange = (e) => {
        this.setState({ isInstalledAsPWA: e.matches });
      };
      try {
        // Browsers supporting current API
        window.matchMedia('(display-mode: standalone)').addEventListener('change', this.handlePWAInstallationChange);
      } catch {
        try {
          // Browsers supporting older API (Safari)
          window.matchMedia('(display-mode: standalone)').addListener(this.handlePWAInstallationChange);
        } catch (error) {
          okerror('Could not listen for PWA installation status changes.', error);
          trackError(error);
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.state.isBrowser) {
      window.removeEventListener('resize', this.updateScreenSize);
      document.body.removeEventListener('click', this.hideKeyboard);
      clearInterval(this.state.locationTrackingInterval);

      // Stop listening for online / offline status
      window.removeEventListener('online', this.handleOnlineStatusChange);
      window.removeEventListener('offline', this.handleOnlineStatusChange);

      // Stop listening for PWA installation changes
      try {
        // Browsers supporting current API
        window.matchMedia('(display-mode: standalone)').removeEventListener('change', this.handlePWAInstallationChange);
      } catch {
        try {
          // Browsers supporting older API (Safari)
          window.matchMedia('(display-mode: standalone)').removeListener(this.handlePWAInstallationChange);
        } catch (error) {
          okerror('Could not unlisten to PWA installation status changes', error);
          trackError(error);
        }
      }
    }
  }

  // Methods

  detectScreenSize() {
    if (this.state.isServer) {
      // Server
      return { height: 0, width: 0 };
    }

    return {
      height: window.innerHeight,
      width: window.innerWidth,
    };
  }

  getLastLocation(locations) {
    return locations.length ? locations[locations.length - 1] : null;
  }

  getPreviousLocation(locations) {
    return locations.length > 1 ? locations[locations.length - 2] : null;
  }

  handleOnlineStatusChange = () => {
    const isOnline = navigator.onLine;
    this.reduxStore.dispatch(updateMiscAppStateAction({ isOnline }));
  };

  hideKeyboard(e) {
    // Only handle if there is an active element
    if (document.activeElement) {
      const focusableElements = ['BUTTON', 'INPUT', 'SELECT'];
      // Hide the keyboard if the element tapped wasn't one that is focusable
      if (!focusableElements.includes(e.target.nodeName)) {
        okdebug('Blurring active element.');
        // document.activeElement.blur();
      }
    }
  }

  updateScreenSize = debounce(() => {
    okdebug('updating screen size');
    // Keep the screen size in state so it will be passed down in app context.
    this.setState({ screenSize: this.detectScreenSize() });
  }, 50);

  render() {
    const { Component, pageProps } = this.props;
    const { isBrowser, isInstalledAsPWA, isNavigatingBack, isServer, screenSize } = this.state;

    // Render

    // Let pages specify props to pass to MainLayout without needing to provide a getLayout function.
    const layoutProps = Component.layoutProps || {};
    // Render pages with MainLayout by default.
    const getLayout = Component.getLayout || ((page) => <MainLayout {...layoutProps}>{page}</MainLayout>);

    return (
      <>
        <PlausibleProvider domain={appConfig.domain}>
          <ReduxProvider store={this.reduxStore}>
            <AppContainer
              isBrowser={isBrowser}
              isInstalledAsPWA={isInstalledAsPWA}
              isNavigatingBack={isNavigatingBack}
              isServer={isServer}
              screenSize={screenSize}
              userAgent={this.userAgent}
            >
              <div
                id={APP_TOP_MARKER_ELEMENT_ID}
                style={{ position: 'absolute', top: baseTheme.sizing.mainMenuHeight, left: 0, width: 1, height: 1 }}
              />
              {getLayout(<Component {...pageProps} />)}
            </AppContainer>
          </ReduxProvider>
        </PlausibleProvider>
      </>
    );
  }
}

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape({
    lang: PropTypes.string,
  }),
};

export default Sentry.withProfiler(withRouter(appWithTranslation(App)));

/* Helpers */

function getIsInstalledAsPWA() {
  if (window.matchMedia('(display-mode: standalone)').matches || navigator.standalone) {
    return true;
  }

  return false;
}
