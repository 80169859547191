import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Bar as BarChart, Line as LineChart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import ButtonGroup from 'OK/components/buttonGroup';
import Chip from 'OK/components/chip';
import Grade from 'OK/components/grade';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInputAsButton, { DISPLAY_MODE } from 'OK/components/input/search/asButton';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import PageTitle from 'OK/components/pageTitle';
import Progressable from 'OK/components/progressable';
import SearchSuggestions from 'OK/components/searchSuggestions';
import { suggestionObjectForProduct } from 'OK/components/searchSuggestions/util/product';
import { suggestionObjectForUser } from 'OK/components/searchSuggestions/util/user';
import Separator from 'OK/components/separator';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import ProductModel from 'OK/models/product';
import TestAssetModel from 'OK/models/testAsset';
import ArchiveLinksSection from 'OK/modules/archiveLinksSection';
import EditTest from 'OK/modules/editTest';
import AddWorkPopup from 'OK/modules/popups/addWork';
import { useUnlinkInspectionTestAssetAPI } from 'OK/networking/inspectionAssets/hooks';
import {
  AggregatedInspectionLogDataForTestQuery,
  LatestInspectionLogsForTestAssetQuery,
} from 'OK/networking/inspectionLogs';
import { searchQuery } from 'OK/networking/search';
import { getTestAssetByRefIdQuery } from 'OK/networking/testAssets';
import {
  generateAreaChartConfig,
  generateBarChartConfig,
  generateDashedLineAnnotationConfig,
} from 'OK/util/chartjs/config';
import { formatChartDataForAggregatedLogs, formatChartDataForLatestLogs } from 'OK/util/chartjs/format';
import ChartJSHtmlLegendPlugin from 'OK/util/chartjs/htmlLegendPlugin';
import { LATEST_LOGS_CHART_TYPE } from 'OK/util/chartjs/types';
import {
  DEBOUNCE_TIMING_MS_SHORT,
  OK_QUALITY_AQL_CRITICAL_PERCENT,
  OK_QUALITY_AQL_MAJOR_PERCENT,
  OK_QUALITY_AQL_MINOR_PERCENT,
} from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import UIContext from 'OK/util/context/ui';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import { formatNumber, formatOkid, formatPercentage, formatPoints } from 'OK/util/formatting';
import isAuthorised from 'OK/util/functions/isAuthorised';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';

if (typeof window !== 'undefined') {
  Chart.register(...registerables, annotationPlugin, ChartJSHtmlLegendPlugin);
}

const AQL_CRITICAL = OK_QUALITY_AQL_CRITICAL_PERCENT * 100;
const AQL_MAJOR = OK_QUALITY_AQL_MAJOR_PERCENT * 100;
const AQL_MINOR = OK_QUALITY_AQL_MINOR_PERCENT * 100;
const CHART_HEIGHT = 200;

const CHART_MODE = {
  POINTS: 'POINTS',
  PERCENT: 'PERCENT',
  QUANTITY: 'QUANTITY',
};

function InsufficientPerformanceDataNoLogsMessage() {
  const { t } = useI18n();
  return (
    <Text bold tint='notification' style={{ margin: 0 }}>
      {t('INSUFFICIENT_DATA_FOR_LOG_PERFORMANCE_CHART')}
    </Text>
  );
}

function InsufficientPerformanceDataForOptionsMessage() {
  const { t } = useI18n();
  return (
    <Text bold tint='notification' style={{ margin: 0 }}>
      {t('NO_DATA_FOR_CONFIGURED_LOG_PERFORMANCE_CHART')}
    </Text>
  );
}

export default function EditTestPage(props) {
  /* Variables */

  const { REFIDasProp } = props;
  const { t, locale } = useI18n();

  // Essential data

  const [getTest, getTestResult] = useLazyQuery(getTestAssetByRefIdQuery);
  const testAsset = getTestResult.data?.testAsset;

  // Misc

  const [authenticated] = useAuthentication();
  const authorisationLevel = useAuthorisationLevel(testAsset);
  const authorised = isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.COWORKER);
  const router = useRouter();
  const REFID = router.query.refId || REFIDasProp;
  const theme = useContext(ThemeContext);
  const showInPopup = useSelector((state) => state.app.showInPopup);

  // State

  const [chartCoworkerHighlight, setChartCoworkerHighlight] = useState(null);
  const [chartDisplayType, setChartDisplayType] = useState(LATEST_LOGS_CHART_TYPE.POINTS);
  const [chartFilterIncludeInternalLogs, setChartFilterIncludeInternalLogs] = useState(false);
  const [chartMode, setChartMode] = useState(CHART_MODE.POINTS);
  const [chartOptionShowQuantity, setChartOptionShowQuantity] = useState(false);
  const [chartProductHighlight, setChartProductHighlight] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [deleteToastMessage, setDeleteToastMessage] = useState(null);
  const [searchCoworkersFocused, setSearchCoworkersFocused] = useState(false);
  const [searchCoworkersString, setSearchCoworkersString] = useState('');
  const [searchProductsFocused, setSearchProductsFocused] = useState(false);
  const [searchProductsString, setSearchProductsString] = useState('');
  const [showAddWorkPopup, setShowAddWorkPopup] = useState(false);
  const [unlinkError, setUnlinkError] = useState(null);

  const showDataHighlight = chartProductHighlight !== null || chartCoworkerHighlight !== null;

  // Refs

  const basicsSectionRef = useRef();
  const linksSectionRef = useRef();
  const searchCoworkersInputRef = useRef();
  const searchProductsInputRef = useRef();

  /* API */

  const [getAggregatedLogDataAPI, getAggregatedLogDataAPIResult] = useLazyQuery(
    AggregatedInspectionLogDataForTestQuery,
    { fetchPolicy: 'cache-and-network' }
  );
  const [getAggregatedLogDataForHighlightAPI, getAggregatedLogDataForHighlightAPIResult] = useLazyQuery(
    AggregatedInspectionLogDataForTestQuery,
    { fetchPolicy: 'cache-and-network' }
  );
  const [getLatestLogsAPI, getLatestLogsAPIResult] = useLazyQuery(LatestInspectionLogsForTestAssetQuery);
  const [searchCoworkersAPI, searchCoworkersAPIResult] = useLazyQuery(searchQuery);
  const [searchProductsAPI, searchProductsAPIResult] = useLazyQuery(searchQuery);
  const unlinkTestFromInspectionAPI = useUnlinkInspectionTestAssetAPI();

  const latestLogs = useMemo(
    () => getLatestLogsAPIResult.data?.inspectionLogs ?? [],
    [getLatestLogsAPIResult.data?.inspectionLogs]
  );

  /* Methods */

  const changeChartMode = useCallback(
    (newMode) => {
      setChartMode(newMode);

      if (newMode === CHART_MODE.POINTS) {
        setChartDisplayType(LATEST_LOGS_CHART_TYPE.POINTS);
        return;
      }

      if (newMode === CHART_MODE.QUANTITY) {
        setChartOptionShowQuantity(true);
      } else {
        setChartOptionShowQuantity(false);
      }

      switch (testAsset.testSeverityLevel) {
        case TestAssetModel.SEVERITY_LEVEL.MINOR:
          setChartDisplayType(LATEST_LOGS_CHART_TYPE.MINOR_AQL);
          break;
        case TestAssetModel.SEVERITY_LEVEL.MAJOR:
          setChartDisplayType(LATEST_LOGS_CHART_TYPE.MAJOR_AQL);
          break;
        case TestAssetModel.SEVERITY_LEVEL.CRITICAL:
          setChartDisplayType(LATEST_LOGS_CHART_TYPE.CRITICAL_AQL);
          break;
        default:
          break;
      }
    },
    [testAsset?.testSeverityLevel]
  );

  const filterByProduct = useCallback(
    (productId) => {
      const product = searchProductsAPIResult.data.search.resultList.find(
        (r) => r.productData.id === productId
      ).productData;
      setChartProductHighlight(product);
      setSearchProductsString('');
      searchProductsInputRef.current.setDisplayMode(DISPLAY_MODE.BUTTON);
    },
    [searchProductsAPIResult.data?.search?.resultList]
  );

  const filterByUser = useCallback(
    (userId) => {
      const user = searchCoworkersAPIResult.data.search.resultList.find((r) => r.userData.id === userId).userData;
      setChartCoworkerHighlight(user);
      setSearchCoworkersString('');
      searchCoworkersInputRef.current.setDisplayMode(DISPLAY_MODE.BUTTON);
    },
    [searchCoworkersAPIResult.data?.search?.resultList]
  );

  const searchCoworkersDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchCoworkersAPI({
          variables: {
            searchPaginationDataByDataType: [
              {
                dataType: 'USER',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchCoworkersAPI]
  );

  const searchProductsDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchProductsAPI({
          variables: {
            searchPaginationDataByDataType: [
              {
                dataType: 'PRODUCT',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchProductsAPI]
  );

  // Event handlers

  const onClickUnlinkInspection = useCallback(
    (inspectionAssetId) => {
      setUnlinkError(null);
      unlinkTestFromInspectionAPI(inspectionAssetId, testAsset.id).catch(() => {
        setUnlinkError(t('ERROR_GENERIC'));
      });
    },
    [t, testAsset?.id, unlinkTestFromInspectionAPI]
  );

  const onSearchCoworkers = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setSearchCoworkersString(newSearchString);

      if (newSearchString.length > 1) {
        searchCoworkersDebounced(newSearchString);
      }
    },
    [searchCoworkersDebounced]
  );

  const onSearchProducts = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setSearchProductsString(newSearchString);

      if (newSearchString.length > 1) {
        searchProductsDebounced(newSearchString);
      }
    },
    [searchProductsDebounced]
  );

  /* Effects */

  // Redirect if not authorised
  useEffect(() => {
    const apiCompleted =
      getTestResult.called &&
      (getTestResult.networkStatus === NetworkStatus.ready || getTestResult.networkStatus === NetworkStatus.error);
    if (apiCompleted && !(!!authorised || !!testAsset)) {
      router.replace('/404');
    }
  }, [authorised, getTestResult.called, getTestResult.networkStatus, router, testAsset]);

  // Load test data
  useEffect(() => {
    if (authenticated && REFID) {
      getTest({ variables: { refId: REFID } });
    }
  }, [REFID, authenticated, getTest]);

  // Get chart data
  useEffect(() => {
    if (authenticated && testAsset?.id) {
      getLatestLogsAPI({
        variables: {
          includeInternalLogs: chartFilterIncludeInternalLogs,
          testAssetId: testAsset.id,
        },
      });
      getAggregatedLogDataAPI({
        variables: {
          includeInternalLogs: chartFilterIncludeInternalLogs,
          testAssetId: testAsset.id,
        },
      });
      if (showDataHighlight) {
        let variables = {
          includeInternalLogs: chartFilterIncludeInternalLogs,
          testAssetId: testAsset.id,
        };
        if (chartCoworkerHighlight) {
          variables.filterByInspectorIdList = [chartCoworkerHighlight.id];
        }
        if (chartProductHighlight) {
          variables.filterBySourceIdList = [chartProductHighlight.id];
        }
        getAggregatedLogDataForHighlightAPI({ variables });
      }
    }
  }, [
    authenticated,
    chartCoworkerHighlight,
    chartFilterIncludeInternalLogs,
    chartProductHighlight,
    getAggregatedLogDataAPI,
    getAggregatedLogDataForHighlightAPI,
    getLatestLogsAPI,
    showDataHighlight,
    testAsset?.id,
  ]);

  /* Render */

  const disableActions = !testAsset;
  const loadingPerformanceChartData =
    !getLatestLogsAPIResult.called ||
    getLatestLogsAPIResult.loading ||
    !getAggregatedLogDataAPIResult.called ||
    getAggregatedLogDataAPIResult.loading ||
    (showDataHighlight && !getAggregatedLogDataForHighlightAPIResult.called) ||
    getAggregatedLogDataForHighlightAPIResult.loading;
  const renderChartsSection = getLatestLogsAPIResult.called && getAggregatedLogDataAPIResult.called;

  const pageSections = [
    { ref: basicsSectionRef, title: t('ARCHIVE_PAGE_SECTION_BASICS') },
    { ref: linksSectionRef, title: t('ARCHIVE_PAGE_SECTION_LINKS') },
  ];

  const pageSubmenu = (
    <div className={styles.submenu}>
      <Text className={styles.submenuTitle}>
        {t('STEP')}{' '}
        <Tag className={styles.submenuRefId} size='sm'>
          {REFID}
        </Tag>
      </Text>
      <div className={styles.submenuButtons}>
        <div className={`${styles.submenuButton} ${styles.submenuButtonContainer}`}>
          <FadeInOutTransition appear in={!!deleteToastMessage}>
            <Toast className={styles.shareToast}>{deleteToastMessage}</Toast>
          </FadeInOutTransition>
        </div>
      </div>
      <Grade
        className={styles.reliabilityScore}
        score={testAsset?.reliabilityPointForPublishedLogs}
        type='reliability'
      />
    </div>
  );

  const hasRecentLogsForProduct = useMemo(() => {
    if (!latestLogs.length || !chartProductHighlight) {
      return false;
    }

    let hasRecentLogs = false;
    for (let x = 0; x < latestLogs.length; x++) {
      const log = latestLogs[x];
      if (log.productId === chartProductHighlight.id) {
        hasRecentLogs = true;
        break;
      }
    }

    return hasRecentLogs;
  }, [chartProductHighlight, latestLogs]);

  const hasRecentLogsForCoworker = useMemo(() => {
    if (!latestLogs.length || !chartCoworkerHighlight) {
      return false;
    }

    let hasRecentLogs = false;
    for (let x = 0; x < latestLogs.length; x++) {
      const log = latestLogs[x];
      if (log.createdBy === chartCoworkerHighlight.id) {
        hasRecentLogs = true;
        break;
      }
    }

    return hasRecentLogs;
  }, [chartCoworkerHighlight, latestLogs]);

  const chartConfigs = useMemo(() => {
    if (
      latestLogs.length > 1 &&
      getAggregatedLogDataAPIResult.data?.aggregatedData &&
      (!showDataHighlight || getAggregatedLogDataForHighlightAPIResult.data?.aggregatedData)
    ) {
      const aggregatedLogData = getAggregatedLogDataAPIResult.data.aggregatedData;
      const aggregatedLogDataForHighlight = showDataHighlight
        ? getAggregatedLogDataForHighlightAPIResult.data?.aggregatedData
        : null;

      let annotations;
      let yValues;
      if (chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS) {
        yValues = [...latestLogs.map((l) => l.reliabilityPoint), aggregatedLogData.averageReliabilityPoint];
      } else {
        switch (chartDisplayType) {
          case LATEST_LOGS_CHART_TYPE.MINOR_AQL: {
            if (chartOptionShowQuantity) {
              yValues = [...latestLogs.map((l) => l.sampleMinorFailures), aggregatedLogData.sampleMinorAQL, 10];
            } else {
              annotations = {
                AQLLimitAnnotation: generateDashedLineAnnotationConfig(AQL_MINOR, { theme }),
              };
              yValues = [...latestLogs.map((l) => l.minorAQL), aggregatedLogData.sampleMinorAQL, AQL_MINOR];
            }
            break;
          }
          case LATEST_LOGS_CHART_TYPE.MAJOR_AQL: {
            if (chartOptionShowQuantity) {
              yValues = [...latestLogs.map((l) => l.sampleMajorFailures), aggregatedLogData.sampleMajorAQL, 10];
            } else {
              annotations = {
                AQLLimitAnnotation: generateDashedLineAnnotationConfig(AQL_MAJOR, { theme }),
              };
              yValues = [...latestLogs.map((l) => l.majorAQL), aggregatedLogData.sampleMajorAQL, AQL_MINOR];
            }
            break;
          }
          case LATEST_LOGS_CHART_TYPE.CRITICAL_AQL: {
            if (chartOptionShowQuantity) {
              yValues = [...latestLogs.map((l) => l.sampleCriticalFailures), aggregatedLogData.sampleCriticalAQL, 10];
            } else {
              annotations = {
                AQLLimitAnnotation: generateDashedLineAnnotationConfig(AQL_CRITICAL, { theme }),
              };
              yValues = [...latestLogs.map((l) => l.criticalAQL), aggregatedLogData.sampleCriticalAQL, AQL_MINOR];
            }
            break;
          }
          default:
            break;
        }
      }

      const maxYValue = Math.max(...yValues) * 1.05;

      let latestLogsChartData = formatChartDataForLatestLogs(latestLogs, t, {
        chartDisplayType,
        chartHeight: CHART_HEIGHT,
        dataBackgroundColorRGBA: 'rgba(80, 124, 133, 1)',
        highlightCoworkerId: chartCoworkerHighlight?.id,
        highlightProductId: chartProductHighlight?.id,
        showQuantity: chartOptionShowQuantity,
        theme,
      });
      let aggregatedChartData = formatChartDataForAggregatedLogs(aggregatedLogData, aggregatedLogDataForHighlight, t, {
        chartDisplayType,
        chartHeight: CHART_HEIGHT,
        dataBackgroundColorRGBA: 'rgba(80, 124, 133, 1)',
        showQuantity: chartOptionShowQuantity,
      });

      return {
        aggregatedChart: generateBarChartConfig(aggregatedChartData, {
          annotations,
          maxYValue,
          theme,
          tickFormatter: (tickValue) => {
            if (chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS) {
              return formatPoints(tickValue);
            }

            return chartOptionShowQuantity
              ? parseInt(tickValue, 10)
              : formatPercentage(tickValue, { multiplyBy100: false, showPercentSign: false });
          },
          tooltipLabelCallback: (tooltip) => {
            if (chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS || chartOptionShowQuantity) {
              return formatNumber(tooltip.parsed.y);
            }

            return formatPercentage(tooltip.parsed.y, { multiplyBy100: false });
          },
        }),
        latestLogsChart: generateAreaChartConfig(latestLogsChartData, {
          annotations,
          legendContainerId: 'chartLegendContainer',
          maxYValue,
          theme,
          tooltipLabelCallback: (tooltip) => {
            if (tooltip.dataset.hideTooltip) {
              return null;
            }

            if (typeof tooltip.parsed.y === 'undefined' || tooltip.parsed.y === null) {
              return t('NO_DATA');
            }

            if (chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS || chartOptionShowQuantity) {
              return formatNumber(tooltip.parsed.y);
            }

            return formatPercentage(tooltip.parsed.y, { multiplyBy100: false });
          },
        }),
      };
    }

    return null;
  }, [
    chartCoworkerHighlight,
    chartDisplayType,
    chartOptionShowQuantity,
    chartProductHighlight,
    getAggregatedLogDataAPIResult.data?.aggregatedData,
    getAggregatedLogDataForHighlightAPIResult.data?.aggregatedData,
    latestLogs,
    showDataHighlight,
    t,
    theme,
  ]);

  let chartSection;
  if (testAsset?.totalLogs === 0) {
    chartSection = <InsufficientPerformanceDataNoLogsMessage />;
  } else {
    if (chartConfigs && chartConfigs.latestLogsChart.data.datasets[0].data.length <= 2) {
      chartConfigs.latestLogsChart.options.scales.x.max = 1.1;
    }

    chartSection = (
      <>
        <div className={styles.chartFilters}>
          <div className={styles.chartFilterDropdowns}>
            <div className={styles.chartFilterSection}>
              <Text bold className={styles.chartFilterLabel}>
                {t('PRODUCTS_OPTIONAL_PLURAL')}
              </Text>
              <div className={styles.searchContainer} style={{ zIndex: 2 }}>
                <SearchInputAsButton
                  buttonProps={{
                    icon: <Icon className={styles.selectCaretIcon} name={ICONS.CARET.name} />,
                  }}
                  className={styles.searchInput}
                  inputProps={{
                    loading: searchProductsAPIResult.loading,
                    onBlur: () => setSearchProductsFocused(false),
                    onChange: onSearchProducts,
                    onFocus: () => setSearchProductsFocused(true),
                    value: searchProductsString,
                  }}
                  onClickClear={() => setChartProductHighlight(null)}
                  placeholder={t('FILTER_PRODUCTS_PLACEHOLDER')}
                  ref={searchProductsInputRef}
                  selection={
                    chartProductHighlight ? (
                      <>
                        <Tag size='sm'>{chartProductHighlight.REFID}</Tag>{' '}
                        {ProductModel.localizedNameForProduct(chartProductHighlight, locale)}
                      </>
                    ) : null
                  }
                />
                {searchProductsFocused && searchProductsString.length > 1 && (
                  <SearchSuggestions
                    className={styles.searchSuggestions}
                    onSuggestionClick={filterByProduct}
                    showMoreResultsMessage={
                      searchProductsFocused &&
                      searchProductsAPIResult.data?.search?.searchPaginationResultDataByDataType?.PRODUCT
                        ?.totalResults > searchProductsAPIResult.data?.search?.resultList.length
                    }
                    showNoResultsMessage={
                      searchProductsFocused && searchProductsAPIResult.data?.search?.resultList.length === 0
                    }
                    suggestions={searchProductsAPIResult.data?.search?.resultList?.map((r) =>
                      suggestionObjectForProduct(r.productData, locale)
                    )}
                  />
                )}
              </div>
              {chartProductHighlight && !hasRecentLogsForProduct && !loadingPerformanceChartData && (
                <Text className={styles.noRecentLogsMessage} size='sm' tint='notification'>
                  {t('NO_RECENT_LOGS_FOR_PRODUCT')}
                </Text>
              )}
            </div>
            <div className={styles.chartFilterSection}>
              <Text bold className={styles.chartFilterLabel}>
                {t('COWORKERS_OPTIONAL_PLURAL')}
              </Text>
              <div className={styles.searchContainer}>
                <SearchInputAsButton
                  buttonProps={{
                    icon: <Icon className={styles.selectCaretIcon} name={ICONS.CARET.name} />,
                  }}
                  className={styles.searchInput}
                  inputProps={{
                    loading: searchCoworkersAPIResult.loading,
                    onBlur: () => setSearchCoworkersFocused(false),
                    onChange: onSearchCoworkers,
                    onFocus: () => setSearchCoworkersFocused(true),
                    value: searchCoworkersString,
                  }}
                  onClickClear={() => setChartCoworkerHighlight(null)}
                  placeholder={t('FILTER_COWORKERS_PLACEHOLDER')}
                  ref={searchCoworkersInputRef}
                  selection={
                    chartCoworkerHighlight ? <Tag size='sm'>{formatOkid(chartCoworkerHighlight.OKID)}</Tag> : null
                  }
                />
                {searchCoworkersFocused && searchCoworkersString.length > 1 && (
                  <SearchSuggestions
                    className={styles.searchSuggestions}
                    onSuggestionClick={filterByUser}
                    showMoreResultsMessage={
                      searchCoworkersFocused &&
                      searchCoworkersAPIResult.data?.search?.searchPaginationResultDataByDataType?.USER?.totalResults >
                        searchCoworkersAPIResult.data?.search?.resultList.length
                    }
                    showNoResultsMessage={
                      searchCoworkersFocused && searchCoworkersAPIResult.data?.search?.resultList.length === 0
                    }
                    suggestions={searchCoworkersAPIResult.data?.search?.resultList?.map((r) =>
                      suggestionObjectForUser(r.userData, t)
                    )}
                  />
                )}
              </div>
              {chartCoworkerHighlight && !hasRecentLogsForCoworker && !loadingPerformanceChartData && (
                <Text className={styles.noRecentLogsMessage} size='sm' tint='notification'>
                  {t('NO_RECENT_LOGS_FOR_COWORKER')}
                </Text>
              )}
            </div>
          </div>
          <div>
            <div className={styles.chartFilterSection}>
              <Text bold>{t('INCLUDE')}</Text>
              <Chip
                className={styles.chartFilterChip}
                onChange={setChartFilterIncludeInternalLogs}
                selected={chartFilterIncludeInternalLogs}
              >
                {t('INTERNAL_LOGS')}
              </Chip>
            </div>
          </div>
        </div>
        {chartConfigs ? (
          <>
            <div className={styles.chartHeaders}>
              <Text bold className={styles.latestLogsChartHeader}>
                {latestLogs.length === 1
                  ? t('LATEST_LOGS_1')
                  : t('LATEST_LOGS_X', { data: { number: latestLogs.length } })}
              </Text>
              <Text bold>{t('TO_DATE')}</Text>
              {chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS ? (
                <Icon className={styles.chartScaleIcon} name={ICONS.RELIABILITY_GRADE.name} />
              ) : (
                <Text bold className={styles.chartScaleIcon}>
                  {chartOptionShowQuantity ? 'x' : '%'}
                </Text>
              )}
            </div>
            <div className={styles.chartsContainer}>
              <div className={styles.latestLogsChartContainer}>
                <LineChart data={chartConfigs.latestLogsChart.data} options={chartConfigs.latestLogsChart.options} />
              </div>
              <div className={styles.allLogsChartContainer}>
                <BarChart data={chartConfigs.aggregatedChart.data} options={chartConfigs.aggregatedChart.options} />
              </div>
            </div>
            <div id='chartLegendContainer' />
            <ButtonGroup buttonStyle='separate' className={styles.chartDisplayTypeButtons}>
              <button
                active={chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS}
                onClick={() => changeChartMode(CHART_MODE.POINTS)}
              >
                {t('LOG_ARCHIVE_PAGE_POINTS')}
              </button>
              <button active={chartMode === CHART_MODE.PERCENT} onClick={() => changeChartMode(CHART_MODE.PERCENT)}>
                {t('LOG_QUALITY_LEVEL_PERCENT')}
              </button>
              <button active={chartMode === CHART_MODE.QUANTITY} onClick={() => changeChartMode(CHART_MODE.QUANTITY)}>
                {t('LOG_QUALITY_LEVEL_QUANTITY')}
              </button>
            </ButtonGroup>
            {chartDisplayType !== LATEST_LOGS_CHART_TYPE.POINTS && !chartOptionShowQuantity && (
              <Text bold size='xs'>
                <sup>1</sup> {t('LOG_QUALITY_LEVEL_FOOTNOTE_2')}
              </Text>
            )}
          </>
        ) : loadingPerformanceChartData ? (
          <Icon height={40} name={ICONS.SPINNER.name} width={40} />
        ) : (
          <InsufficientPerformanceDataForOptionsMessage />
        )}
      </>
    );
  }

  let testContent;
  if (!getTestResult.called || getTestResult.loading) {
    testContent = (
      <TextLayout>
        <Icon height={40} name={ICONS.SPINNER.name} width={40} />
      </TextLayout>
    );
  } else if (authorised && testAsset) {
    testContent = <EditTest desktopColumnClassName={styles.testDetailsColumn} testRefId={REFID} />;
  }

  const localizedNameForTestAsset = testAsset && TestAssetModel.titleForTest(testAsset, locale);

  const pageMenu = (
    <PageMenu
      assetName={localizedNameForTestAsset}
      sections={pageSections}
      showBackButton
      smartTabs
      submenu={pageSubmenu}
      title=' '
    />
  );
  return (
    <>
      <PageTitle>{REFID ? `${t('STEP')} - ${REFID}` : t('STEP')}</PageTitle>
      {showInPopup ? pageMenu : <Menu>{pageMenu}</Menu>}
      <ContentLayout className={styles.basicsContainer} ref={basicsSectionRef} pageContent>
        <UIContext.Provider value='card'>
          <TextLayout>
            <h2 className={styles.header}>
              {t('STEP')} <Tag className={styles.refId}>{REFID}</Tag>
            </h2>
            <h3>{t('ARCHIVE_PAGE_SECTION_BASICS')}</h3>
            <h4>{t('PERFORMANCE')}</h4>
            <Progressable inProgress={!renderChartsSection} style={{ minHeight: !renderChartsSection ? 100 : 0 }}>
              {chartSection}
            </Progressable>
            <Separator type='section' />
          </TextLayout>
          {testContent}
        </UIContext.Provider>
      </ContentLayout>
      {testAsset && (
        <ArchiveLinksSection
          availableLinkTypes={[InspectionAssetModel.GRAPHQL_TYPE]}
          carouselError={unlinkError}
          disableActions={disableActions}
          linkedList={testAsset.linkedInspectionAssetList}
          linksType={InspectionAssetModel.GRAPHQL_TYPE}
          onClickUnlink={onClickUnlinkInspection}
          parentResource={testAsset}
          ref={linksSectionRef}
        />
      )}
      {showAddWorkPopup && (
        <AddWorkPopup
          dismiss={() => setShowAddWorkPopup(false)}
          data={testAsset}
          preSelectedData={testAsset}
          preSelectedDataType={'TEST_ASSET'}
        />
      )}
    </>
  );
}

EditTestPage.layoutProps = {
  contentClassName: styles.layoutContainer,
  contentTopPadding: false,
  padFooter: false,
};

EditTestPage.propTypes = {
  REFIDasProp: PropTypes.string,
};

export async function getServerSideProps({ locale }) {
  const i18nProps = await serverSideTranslations(locale, ['common']);
  return { props: { ...i18nProps } };
}
