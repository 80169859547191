import { request } from '..';
import { API_URL } from '../utils';

import getImageDimensionsFromFile from 'OK/util/functions/getImageDimensionsFromFile';

/* Requests */

export async function updateInspectionLogTestAssetImageMediaAssetRequest(
  inspectionLogTestAssetId,
  inspectionLogTestAssetMediaAssetId,
  file
) {
  const { height, width } = await getImageDimensionsFromFile(file);
  const metadata = {
    filesize: file.size,
    height,
    mimeType: file.type,
    width,
  };
  return request(
    `${API_URL}/utility/inspection-log/media/updateUnversionedInspectionLogTestAssetMediaAssetImageWithMetadata`,
    {
      contentType: 'multipart/form-data',
      method: 'POST',
      parameters: {
        image: file,
        inspectionLogTestAssetId,
        inspectionLogTestAssetMediaAssetId,
        metadataString: JSON.stringify(metadata),
      },
    }
  );
}
