import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { cloneElement, isValidElement, useContext } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import baseTint from './tints/base.module.scss';
import cardTint from './tints/card.module.scss';
import focusTint from './tints/focus.module.scss';
import midtoneTint from './tints/midtone.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import UIContext from 'OK/util/context/ui';

export function LinkNoRedux(props) {
  /* Variables */

  const {
    block = false,
    buttonStyle = false,
    children,
    className,
    disabled: disabledProp = false,
    disableIfOffline = true,
    download,
    href = '/',
    icon,
    iconPosition = 'right',
    isOnline = true,
    style,
    target,
    tint,
    withCaret = false,
    ...otherProps
  } = props;

  const uiContext = useContext(UIContext);

  const disabled = disabledProp || (!isOnline && disableIfOffline);

  let tintStyle;
  switch (uiContext) {
    case 'card':
      tintStyle = cardTint;
      break;
    case 'focus':
      tintStyle = focusTint;
      break;
    case 'midtone':
      tintStyle = midtoneTint;
      break;
    default:
      tintStyle = baseTint;
      break;
  }

  /* Render */

  // Classes

  let classNames = `${styles.all} ${className ? `${className}` : ''}`;

  if (block) {
    classNames = `${classNames} ${styles.block}`;
  }

  if (buttonStyle) {
    classNames = `${classNames} ${styles.button} ${tintStyle.button}`;
  } else {
    classNames = `${classNames} ${styles.link} ${tintStyle.link}`;
  }

  switch (tint) {
    case 'alert':
    case 'creation':
    case 'navigation':
    case 'notification':
    case 'secondary':
      classNames = `${classNames} ${tintStyle[tint]}`;
      break;
    default:
      break;
  }

  if (disabled) {
    classNames = `${classNames} ${styles.disabled}`;
  }

  // Icon

  let iconElement;
  let _iconPosition;
  if (withCaret) {
    _iconPosition = 'right';
    const iconClassNames = `${styles.icon} ${styles.right}`;
    iconElement = <Icon className={iconClassNames} name={ICONS.CARET.name} />;
  } else if (icon) {
    _iconPosition = iconPosition;
    let iconClassNames = `${styles.icon} ${_iconPosition === 'right' ? styles.right : styles.left}`;
    if (ICONS[icon] !== undefined) {
      iconElement = <Icon className={iconClassNames} name={icon} />;
    } else if (isValidElement(icon)) {
      if (icon.props.className) {
        iconClassNames = `${iconClassNames} ${icon.props.className}`;
      }
      iconElement = cloneElement(icon, { className: iconClassNames });
    } else {
      iconElement = <img alt='' className={iconClassNames} src={icon} />;
    }
  }

  const linkContents = (
    <>
      {_iconPosition === 'left' && iconElement}
      {children}
      {_iconPosition === 'right' && iconElement}
    </>
  );

  if (disabled) {
    return (
      <span className={classNames} style={style}>
        {linkContents}
      </span>
    );
  }

  return (
    <>
      <NextLink className={classNames} download={download} href={href} style={style} target={target} {...otherProps}>
        {linkContents}
      </NextLink>
    </>
  );
}

LinkNoRedux.propTypes = {
  block: PropTypes.bool,
  buttonStyle: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disableIfOffline: PropTypes.bool,
  download: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  href: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconPosition: PropTypes.oneOf(['left', 'right']),
  isOnline: PropTypes.bool,
  style: PropTypes.object,
  target: PropTypes.string,
  tint: PropTypes.string,
  withCaret: PropTypes.bool,
};

export default function Link(props) {
  const isOnline = useSelector((state) => state.app.isOnline);
  return <LinkNoRedux isOnline={isOnline} {...props} />;
}

Link.propTypes = {
  block: PropTypes.bool,
  buttonStyle: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disableIfOffline: PropTypes.bool,
  download: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  href: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconPosition: PropTypes.oneOf(['left', 'right']),
  style: PropTypes.object,
  target: PropTypes.string,
  tint: PropTypes.string,
  withCaret: PropTypes.bool,
};
