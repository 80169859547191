import { useQuery } from '@apollo/client';
import NextImage from 'next/legacy/image';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import Alert from 'OK/components/alert';
import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
  ArchiveCardTestResults,
  ArchiveCardTitle,
} from 'OK/components/archiveCard';
import ArchiveCardManagerOptions from 'OK/components/archiveCard/managerOptions';
import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import LinkToInspection from 'OK/components/link/linkToInspection';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import TestAssetModel from 'OK/models/testAsset';
import useAbortInspectionLogAPI from 'OK/networking/inspectionLogs/hooks';
import { getCurrentUserInProgressInspectionLogsQuery } from 'OK/networking/users';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import ThemeContext from 'OK/util/context/theme';
import { formatNumber, formatPercentage } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function InspectionArchiveCard(props) {
  /* Variables */

  const {
    cardClassName,
    className,
    inspectionAsset,
    linkToInspection = true,
    onClickUnlink,
    showUnlinkOption = false,
    starting = false,
    ...otherProps
  } = props;
  const {
    criticalAQL,
    inspectionTestAssetList,
    majorAQL,
    minorAQL,
    publishedLogs,
    REFID,
    reliabilityPointForPublishedLogs,
  } = inspectionAsset;

  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const router = useRouter();
  const theme = useContext(ThemeContext);
  const { t, tHTML, locale } = useI18n();
  const dispatch = useDispatch();
  const hideTestResults = criticalAQL == 0 && majorAQL == 0 && minorAQL == 0;

  // State

  const [abortingInspection, setAbortingInspection] = useState(false);
  const [showConfirmAbortAlert, setShowConfirmAbortAlert] = useState(false);

  /* API */

  const currentUserWithInProgressInspectionsAPIResult = useQuery(getCurrentUserInProgressInspectionLogsQuery);
  const inProgressInspectionLogs =
    currentUserWithInProgressInspectionsAPIResult.data?.user?.inprogressInspectionLogList ?? [];
  const inProgressInspectionLog = inProgressInspectionLogs.find((l) => l.inspectionAsset.id === inspectionAsset.id);

  const abortInspectionAPI = useAbortInspectionLogAPI();

  /* Methods */

  const abortInspection = useCallback(() => {
    setShowConfirmAbortAlert(false);
    if (inProgressInspectionLog) {
      setAbortingInspection(true);
      abortInspectionAPI(inProgressInspectionLog.id).finally(() => {
        setAbortingInspection(false);
      });
    }
  }, [abortInspectionAPI, inProgressInspectionLog]);

  const promptToConfirmAbortInspection = useCallback(() => {
    setShowConfirmAbortAlert(true);
  }, []);

  const resumeInspection = useCallback(() => {
    if (inProgressInspectionLog) {
      router.push(`/archive/log/${inProgressInspectionLog.REFID}/go`);
    }
  }, [inProgressInspectionLog, router]);

  /* Render */

  const isInProgess = !!inProgressInspectionLog;

  let cardTitle, titleTint;
  if (isInProgess) {
    cardTitle = t('WORKFLOW_ARCHIVE_CARD_TITLE_IN_PROGRESS');
    titleTint = 'creation';
  } else if (activeOrganisationId !== inspectionAsset.organisationId) {
    cardTitle = (
      <>
        {t('WORKFLOW_ARCHIVE_CARD_TITLE')}{' '}
        <Text className={styles.titleAccessoryText} tint='notification'>
          {t('WORKFLOW_ARCHIVE_CARD_TITLE_AS_3RD_PARTY')}
        </Text>
      </>
    );
  } else if (starting) {
    cardTitle = t('WORKFLOW_ARCHIVE_CARD_TITLE_STARTING');
    titleTint = 'creation';
  } else {
    cardTitle = t('WORKFLOW_ARCHIVE_CARD_TITLE');
  }

  // Limit to 12 media
  const canDisplayAllImages = inspectionTestAssetList.length <= 12;
  const testPreviewImageUrls = useMemo(() => {
    const testAssets = canDisplayAllImages ? inspectionTestAssetList : inspectionTestAssetList.slice(0, 11);
    return testAssets.map((t) => TestAssetModel.previewPhoto(t.testAsset) ?? `/img/empty_media_${theme.name}.svg`);
  }, [canDisplayAllImages, inspectionTestAssetList, theme.name]);

  // Number of logs
  const numberOfLogsString = useMemo(() => {
    if (publishedLogs === 0) {
      return null;
    }
    if (publishedLogs === 1) {
      return t('WORKFLOW_ARCHIVE_CARD_1_LOG');
    }
    return t('WORKFLOW_ARCHIVE_CARD_X_LOGS', { data: { number: formatNumber(publishedLogs) } });
  }, [publishedLogs, t]);

  // Number of steps
  const numberOfStepsString = useMemo(() => {
    if (inspectionTestAssetList.length === 0) {
      return null;
    }
    if (inspectionTestAssetList.length === 1) {
      return t('WORKFLOW_ARCHIVE_CARD_1_STEP');
    }
    return t('WORKFLOW_ARCHIVE_CARD_X_STEPS', { data: { number: formatNumber(inspectionTestAssetList.length) } });
  }, [inspectionTestAssetList.length, t]);

  // Classes
  let classNames = styles.container;
  if (linkToInspection) {
    classNames = `${classNames} ${styles.linked}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  const Container = linkToInspection && !isInProgess ? LinkToInspection : 'div';
  const containerProps = { className: classNames };
  if (linkToInspection && !isInProgess) {
    containerProps.inspectionAsset = inspectionAsset;
    containerProps.onClick = (e) => {
      e.preventDefault();
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'INSPECTION_ASSET' },
        null,
        `/archive/workflow/${REFID}/edit`
      );
      dispatch(setBrowserHistoryAction(history));
    };
  }

  // Name
  const name = useMemo(() => {
    return InspectionAssetModel.localizedNameForInspectionAsset(inspectionAsset, locale);
  }, [locale, inspectionAsset]);

  return (
    <Container {...containerProps}>
      <ArchiveCard
        className={cardClassName}
        onClick={linkToInspection && isInProgess ? resumeInspection : null}
        {...otherProps}
      >
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery placeholderIconName={ICONS.INSPECTION.name} refId={REFID} />
          <ArchiveCardMainContent className={styles.content}>
            <ArchiveCardHeader title={cardTitle} titleTint={titleTint} />
            <ArchiveCardTitle className={styles.nameText} lineLimit={false}>
              {name}
            </ArchiveCardTitle>
            {!isInProgess && (
              <div className={styles.miniGallery}>
                {testPreviewImageUrls.map((url, index) => (
                  <Image key={`${url}+${index}`} src={url} />
                ))}
                {!canDisplayAllImages && (
                  <Icon className={styles.miniGalleryImage} height={16} name={ICONS.MORE.name} width={16} />
                )}
              </div>
            )}
            {isInProgess && (
              <Button
                className={styles.abortButton}
                disabled={abortingInspection}
                icon={<Icon height={12} name={ICONS.X.name} width={12} />}
                loading={abortingInspection}
                onClick={promptToConfirmAbortInspection}
                tint='alert'
              >
                {t('WORKFLOW_ARCHIVE_CARD_ABORT_WORKFLOW')}
              </Button>
            )}
            <Text className={styles.logs} size='xs'>
              <Tag className={styles.logsTag}>{numberOfLogsString}</Tag>
            </Text>
            <ArchiveCardTestResults
              critical={formatPercentage(criticalAQL, {
                decimals: criticalAQL < 10 ? 1 : 0,
                multiplyBy100: false,
                showPercentSign: false,
              })}
              major={formatPercentage(majorAQL, {
                decimals: majorAQL < 10 ? 1 : 0,
                multiplyBy100: false,
                showPercentSign: false,
              })}
              minor={formatPercentage(minorAQL, {
                decimals: minorAQL < 10 ? 1 : 0,
                multiplyBy100: false,
                showPercentSign: false,
              })}
              hideTestResults={hideTestResults}
            />
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow reliabilityGrade={reliabilityPointForPublishedLogs}>
            {numberOfStepsString}
          </ArchiveCardFooterRow>
          <ArchiveCardFooterRow />
        </ArchiveCardFooter>
        {showUnlinkOption && (
          <ArchiveCardManagerOptions onClickUnlink={onClickUnlink} showUnlinkOption={showUnlinkOption} />
        )}
        {showConfirmAbortAlert && (
          <Alert
            title={t('WORKFLOW_ARCHIVE_CARD_CONFIRM_ABORT_TITLE')}
            message={tHTML('WORKFLOW_ARCHIVE_CARD_CONFIRM_ABORT_MESSAGE')}
            buttons={
              <>
                <Button block className={styles.abortInspectionButton} onClick={abortInspection} tint='alert'>
                  {t('WORKFLOW_ARCHIVE_CARD_CONFIRM_ABORT_CONFIRM')}
                </Button>
                <Button block linkStyle onClick={() => setShowConfirmAbortAlert(false)}>
                  {t('WORKFLOW_ARCHIVE_CARD_CONFIRM_ABORT_CANCEL')}
                </Button>
              </>
            }
          />
        )}
      </ArchiveCard>
    </Container>
  );
}

InspectionArchiveCard.propTypes = {
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  inspectionAsset: PropTypes.object.isRequired,
  linkToInspection: PropTypes.bool,
  onClickUnlink: PropTypes.func,
  showUnlinkOption: PropTypes.bool,
  starting: PropTypes.bool,
};

function Image(props) {
  const { src, ...otherProps } = props;
  return (
    <div className={styles.miniGalleryImage} {...otherProps}>
      <NextImage layout='fill' src={src} />
    </div>
  );
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
};
