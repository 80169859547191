import PropTypes from 'prop-types';
import { useContext } from 'react';

import TabsContext from '../tabContext';

/**
 * Content associated with a `Tab`. Will render when the parent `TabContextProvider`'s `activeTabId` matches its `tabId`.
 *
 * Note: must be a descendant of a `TabsContextProvider`.
 *
 * @param {object} props
 * @param {Node} [props.children]
 * @param {string} props.tabId A unique identifying string. Must match the associated `Tab`'s `tabId`.
 */
export default function TabSection(props) {
  const { children, tabId, ...otherProps } = props;
  const { activeTabId } = useContext(TabsContext);

  if (activeTabId === tabId) {
    return <div {...otherProps}>{children}</div>;
  }

  return null;
}

TabSection.propTypes = {
  children: PropTypes.node,
  tabId: PropTypes.string.isRequired,
};
