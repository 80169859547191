import { gql } from '@apollo/client';

import TestAssetModel from './testAsset';

export default class InspectionTestAssetModel {
  static GRAPHQL_TYPE = 'InspectionTestAsset';

  static fragmentName = 'Inspection_Test_Asset';
  static fragment = gql`
    fragment ${InspectionTestAssetModel.fragmentName} on ${InspectionTestAssetModel.GRAPHQL_TYPE} {
      order
      publishStatus
      testAsset {
        ...${TestAssetModel.fragmentName}
      }
    }
    ${TestAssetModel.fragment}
  `;
}
