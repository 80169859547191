import { gql } from '@apollo/client';
import { nanoid } from 'nanoid';

import BaseDocumentModel from '../document/base';
import InspectionAssetModel from '../inspectionAsset';
import BaseMediaAssetModel from '../mediaAsset/base';
import NoteModel from '../note';
import BaseOrganisationModel from '../organisation/base';
import ProductChildProductModel from '../productChildProduct';
import ProductParentProductModel from '../productParentProduct';

import BaseProductModel from './base';

import MediaAssetModel from 'OK/models/mediaAsset';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import STATUS from 'OK/util/enums/status';
import localizeObject from 'OK/util/functions/localizeObject';

export default class ProductModel {
  static GRAPHQL_TYPE = BaseProductModel.GRAPHQL_TYPE;

  static fragmentName = 'Product_Fragment';
  static fragment = gql`
    fragment ${ProductModel.fragmentName} on ${ProductModel.GRAPHQL_TYPE} {
      ...${BaseProductModel.fragmentName}
      organisation {
        ...${BaseOrganisationModel.fragmentName}
      }
    }
    ${BaseProductModel.fragment}
    ${BaseOrganisationModel.fragment}
  `;

  static fragmentNameFull = 'Product_Full_Fragment';
  static fragmentFull = gql`
    fragment ${ProductModel.fragmentNameFull} on ${ProductModel.GRAPHQL_TYPE} {
      ...${BaseProductModel.fragmentName}
      alternateIds
      assetAccessPermission 
          assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      organisationId
      organisation {
        ...${BaseOrganisationModel.fragmentName}
      }
      productChildProductList {
        ...${ProductChildProductModel.fragmentName}
      }
      productParentProductList {
        ...${ProductParentProductModel.fragmentName}

      }
      productDocumentAssetList {
        documentAsset {
          ...${BaseDocumentModel.fragmentName}
        }
        publishStatus
      }
      productInspectionAssetList {
        inspectionAsset {
          ...${InspectionAssetModel.fragmentName}
        }
        order
        publishStatus
      }
      productMediaAssetList {
        mediaAsset {
          ...${BaseMediaAssetModel.fragmentName}
        }
        order
        publishStatus
      }
      productNoteAssetList {
        noteAsset {
          ...${NoteModel.fragmentName}
        }
        publishStatus
      }
    }
    ${BaseProductModel.fragment}
    ${BaseOrganisationModel.fragment}
    ${ProductChildProductModel.fragment}
    ${ProductParentProductModel.fragment}
    ${BaseDocumentModel.fragment}
    ${InspectionAssetModel.fragment}
    ${BaseMediaAssetModel.fragment}
    ${NoteModel.fragment}
  `;

  static fragmentNameFullPublic = 'Product_FullPublic_Fragment';
  static fragmentFullPublic = gql`
    fragment ${ProductModel.fragmentNameFullPublic} on ${ProductModel.GRAPHQL_TYPE} {
      ...${BaseProductModel.fragmentName}
      assetAccessPermission
      assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      organisationId
      organisation {
        ...${BaseOrganisationModel.fragmentName}
      }
      productChildProductList {
        ...${ProductChildProductModel.fragmentName}
      }
      productParentProductList {
        parentProduct {
          id
        }
      }
      productDocumentAssetList {
        documentAsset {
          ...${BaseDocumentModel.fragmentName}
        }
        publishStatus
      }
      productInspectionAssetList {
        inspectionAsset {
          ...${InspectionAssetModel.fragmentName}
        }
        order
        publishStatus
      }
      productMediaAssetList {
        mediaAsset {
          ...${BaseMediaAssetModel.fragmentName}
        }
        order
        publishStatus
      }
      productNoteAssetList {
        noteAsset {
          ...${NoteModel.fragmentName}
        }
        publishStatus
      }
    }
    ${BaseProductModel.fragment}
    ${BaseOrganisationModel.fragment}
    ${ProductChildProductModel.fragment}


    ${BaseDocumentModel.fragment}
    ${InspectionAssetModel.fragment}
    ${BaseMediaAssetModel.fragment}
    ${NoteModel.fragment}
  `;

  static fragmentNameArchiveCard = 'Product_ArchiveCard_Fragment';
  static fragmentArchiveCard = gql`
    fragment ${ProductModel.fragmentNameArchiveCard} on ${ProductModel.GRAPHQL_TYPE} {
      id
      conformityPoint
      name
      organisation {
        id
        addressList {
          city
          countryCode
          line1
          line2
          latitude
          longitude
        }
        conformityPoint
        name
        reliabilityPointForPublishedLogs
      }
      productChildProductList {
        childProduct {
          id
          name
          organisation {
            addressList {
              city
              latitude
              longitude
            }
          }
          REFID
          SKUID
          productChildProductList {
            childProduct {
              id
              name
              organisation {
                addressList {
                  city
                  latitude
                  longitude
                }
              }
              REFID
              SKUID
              productChildProductList {
                childProduct {
                  id
                  name
                  organisation {
                    addressList {
                      city
                      latitude
                      longitude
                    }
                  }
                  REFID
                  SKUID
                  productChildProductList {
                    childProduct {
                      id
                      name
                      organisation {
                        addressList {
                          city
                          latitude
                          longitude
                        }
                      }
                      REFID
                      SKUID
                    }
                  }
                }
              }
            }
          }
        }
      }
      productMediaAssetList {
        mediaAsset {
          ...${MediaAssetModel.fragmentNameMediaGallery}
        }
      }
      productParentProductList {
        parentProduct {
          id
          name
          organisation {
            addressList {
              city
              latitude
              longitude
            }
          }
          REFID
          SKUID
          productParentProductList {
            parentProduct {
              id
              name
              organisation {
                addressList {
                  city
                  latitude
                  longitude
                }
              }
              REFID
              SKUID
              productParentProductList {
                parentProduct {
                  id
                  name
                  organisation {
                    addressList {
                      city
                      latitude
                      longitude
                    }
                  }
                  REFID
                  SKUID
                  productParentProductList {
                    parentProduct {
                      id
                      name
                      organisation {
                        addressList {
                          city
                          latitude
                          longitude
                        }
                      }
                      REFID
                      SKUID
                    }
                  }
                }
              }
            }
          }
        }
      }
      REFID
      reliabilityPointForPublishedLogs
      SKUID
    }
    ${MediaAssetModel.fragmentMediaGallery}
  `;

  static fragmentEditName = 'Product_Edit_Fragment';
  static fragmentEdit = gql`
    fragment ${ProductModel.fragmentEditName} on ${ProductModel.GRAPHQL_TYPE} {
      id
      assetAccessPermission
      conformityPoint
      name
      organisationId
      assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      productChildProductList {
        childProduct {
          ...${ProductModel.fragmentNameArchiveCard}
        }
        publishStatus
        quantity
        assetLinkStatus
      }
      productParentProductList {
        ...${ProductModel.fragmentNameArchiveCard}
      }
      productPendingParentProductRequestList {
        ...${ProductModel.fragmentNameArchiveCard}
      }
      productDocumentAssetList {
        documentAsset {
          id
          applicableMarketList
          coveredLanguageList
          documentName
          documentNumber
          documentStandardList
          documentType
          madeByOrganisation {
            id
            conformityPoint
            logoImageMediaAsset {
              logoImageURL
            }
            name
            OKID
            reliabilityPointForPublishedLogs
          }
          madeByOrganisationId
          REFID
          validFrom
          validTill
        }
        publishStatus
      }
      productInspectionAssetList {
        inspectionAsset {
          ...${InspectionAssetModel.fragmentNameEditCard}
        }
        order
        publishStatus
      }
      productMediaAssetList {
        mediaAsset {
          ...${MediaAssetModel.fragmentNameMediaGallery}
        }
        order
        publishStatus
      }
      productNoteAssetList {
        noteAsset {
          id
          REFID
          textContentMap
        }
        publishStatus
      }
      publishStatus
      REFID
      reliabilityPointForPublishedLogs
      SKUID
    }
    ${ProductModel.fragmentArchiveCard}
    ${InspectionAssetModel.fragmentEditCard}
    ${MediaAssetModel.fragmentMediaGallery}
  `;

  static fragmentNamePartsList = 'Product_PartsList_Fragment';
  static fragmentPartsList = gql`
  fragment ${ProductModel.fragmentNamePartsList} on ${ProductModel.GRAPHQL_TYPE} {
    id
    productChildProductList {
      childProduct {
        ...${ProductModel.fragmentNameArchiveCard}
      }
      
    }
  }
  ${ProductModel.fragmentArchiveCard}
`;

  static fragmentNameArchiveCardWithDocumentPublishStatus = 'Product_ArchiveCardWithDocumentPublishStatus_Fragment';
  static fragmentArchiveCardWithDocumentPublishStatus = gql`
    fragment ${ProductModel.fragmentNameArchiveCardWithDocumentPublishStatus} on ${ProductModel.GRAPHQL_TYPE} {
      ...${ProductModel.fragmentNameArchiveCard}
      productDocumentAssetList {
        documentAsset {
          id
        }
        publishStatus
      }
    }
    ${ProductModel.fragmentArchiveCard}
  `;

  static mock = {
    REFID: nanoid(6),
    SKUID: nanoid(8),
    createdBy: null,
    createdDate: `${new Date()}`,
    id: nanoid(),
    lastModifiedBy: null,
    lastModifiedDate: `${new Date()}`,
    name: {},
    organisation: {},
    productDocumentAssetList: [],
    productMediaAssetList: [],
    productNoteAssetList: [],
    publishStatus: PUBLISH_STATUS.UNPUBLISHED,
    status: STATUS.ACTIVE,
    version: 0,
    __typename: ProductModel.GRAPHQL_TYPE,
  };

  static link = (product, options = {}) => {
    const { edit = false, token } = options;

    const baseLink = `/archive/product/${product.REFID}`;
    let link = baseLink;

    if (edit) {
      // Link to edit page
      link = `${baseLink}/edit`;
    }

    if (token) {
      // Add token to link
      link = `${link}?token=${token}`;
    }

    return link;
  };

  static localizedNameForProduct = (product, locale) => {
    return localizeObject(product.name, locale, true)?.text ?? 'Unnamed';
  };

  static previewImageUrl = (product) => {
    if (!product.productMediaAssetList?.length) {
      return;
    }

    const imageMedia = product.productMediaAssetList.find((m) => typeof m.mediaAsset.imageData?.imageURL === 'string');
    return imageMedia?.mediaAsset.imageData.imageURL;
  };
}
