import { gql } from '@apollo/client';

import BaseOrganisationModel from '../organisation/base';

import BaseUserModel from './base';

import { formatOkid } from 'OK/util/formatting';

export default class UserModel {
  static GRAPHQL_TYPE = BaseUserModel.GRAPHQL_TYPE;

  static fragmentName = BaseUserModel.fragmentName;
  static fragment = BaseUserModel.fragment;

  static fragmentNameCurrentUserActiveOrganisationData = 'User_CurrentUserActiveOrganisationData_Fragment';
  static fragmentCurrentUserActiveOrganisationData = gql`
    fragment ${UserModel.fragmentNameCurrentUserActiveOrganisationData} on ${UserModel.GRAPHQL_TYPE} {
      id
      inprogressInspectionLogList {
        id
        REFID
        inspectionAsset {
          id
          REFID
        }
      }
    }
  `;

  static fragmentNameCurrentUser = 'User_CurrentUser_Fragment';
  static fragmentCurrentUser = gql`
    fragment ${UserModel.fragmentNameCurrentUser} on ${UserModel.GRAPHQL_TYPE} {
      ...${UserModel.fragmentName}
      joinRequestOrganisationList {
        ...${BaseOrganisationModel.fragmentName}
      }
      inprogressInspectionLogList {
        createdDate
        id
        REFID
        inspectionAsset {
          id
          REFID
        }
      }
      managedOrganisationList {
        ...${BaseOrganisationModel.fragmentName}
        permissionList
      }
      memberOrganisationList {
        ...${BaseOrganisationModel.fragmentName}
        permissionList
      }
      organisationInviteBlockedList {
        ...${BaseOrganisationModel.fragmentName}
      }
      organisationInviteList {
        ...${BaseOrganisationModel.fragmentName}
      }
      organisationList {
        ...${BaseOrganisationModel.fragmentName}
        permissionList
      }
      ownedOrganisationList {
        ...${BaseOrganisationModel.fragmentName}
        permissionList
      }
    }
    ${UserModel.fragment}
    ${BaseOrganisationModel.fragment}
  `;

  static fragmentNameCurrentUserInProgressInspectionLogs = 'User_CurrentUserInProgressInspectionLogs_Fragment';
  static fragmentCurrentUserInProgressInspectionLogs = gql`
    fragment ${UserModel.fragmentNameCurrentUserInProgressInspectionLogs} on ${UserModel.GRAPHQL_TYPE} {
      id
      inprogressInspectionLogList {
        id
        REFID
        inspectionAsset {
          id
          REFID
        }
      }
    }
  `;

  static fragmentNameArchiveCard = 'User_ArchiveCard_Fragment';
  static fragmentArchiveCard = gql`
    fragment ${UserModel.fragmentNameArchiveCard} on ${UserModel.GRAPHQL_TYPE} {
      id
      name
      OKID
      reliabilityPointForPublishedLogs
    }
  `;

  static link(userOrOKID) {
    let OKID;
    if (typeof userOrOKID === 'string') {
      OKID = userOrOKID;
    } else {
      OKID = userOrOKID.OKID;
    }

    return `/user/${formatOkid(OKID)}`;
  }
}
