import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';

import Input from '../';

import styles from './styles.module.scss';

import Select from 'OK/components/select';
import Tooltip from 'OK/components/tooltip';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import { baseTheme } from 'OK/styles/theme';

const InputWithDropdown = forwardRef((props, ref) => {
  // Variables
  const {
    className,
    disableEmptySelection = false,
    disabled = false,
    dropdownClassName,
    dropdownLabel,
    dropdownOptions,
    dropdownValue,
    error,
    hasError = false,
    inputClassName,
    onBlurDropdown,
    onDropdownChange,
    ...otherProps
  } = props;

  // State
  const [isHovering, setIsHovering] = useState(false);

  // Render

  const showTooltip = dropdownLabel && isHovering;

  let classNames = styles.container;
  if (className) {
    classNames += ` ${className}`;
  }

  let selectClassNames = styles.select;
  if (dropdownClassName) {
    selectClassNames = `${selectClassNames} ${dropdownClassName}`;
  }
  const selectElement = (
    <Select
      caretPosition='left'
      className={selectClassNames}
      disableEmptySelection={disableEmptySelection}
      disabled={disabled}
      error={error || hasError}
      onBlur={onBlurDropdown}
      onChange={(value) => onDropdownChange(value)}
      onMouseOut={() => setIsHovering(false)}
      onMouseOver={() => setIsHovering(true)}
      options={dropdownOptions}
      value={dropdownValue}
    />
  );

  return (
    <div className={classNames}>
      <FadeInOutTransition
        className={styles.tooltipTransition}
        fadeDuration={baseTheme.timing.timingInstantMs}
        in={showTooltip}
      >
        <Tooltip className={styles.labelTooltip}>{dropdownLabel}</Tooltip>
      </FadeInOutTransition>
      {selectElement}
      <Input
        className={`${styles.input} ${inputClassName || ''}`}
        disabled={disabled}
        error={error}
        hasError={hasError}
        ref={ref}
        {...otherProps}
      />
    </div>
  );
});

export default InputWithDropdown;

InputWithDropdown.propTypes = {
  className: PropTypes.string,
  disableEmptySelection: PropTypes.bool,
  disabled: PropTypes.bool,
  dropdownClassName: PropTypes.string,
  dropdownLabel: PropTypes.string,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      displayLabel: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  dropdownValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hasError: PropTypes.bool,
  inputClassName: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlurDropdown: PropTypes.func,
  onDropdownChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  withIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
