import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useState, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import { Popup, PopupCloseButton, PopupContent, PopupButtonsGroup } from 'OK/components/popup';
import Text from 'OK/components/text';
import { removeUserAvatarMutation, setUserAvatarMutation, userAvatarListQuery } from 'OK/networking/avatar';
import ThemeContext from 'OK/util/context/theme';
import useAuthentication from 'OK/util/hooks/useAuthentication';

export default function SetAvatarPopup(props) {
  const { dismiss } = props;
  const [, , currentUser] = useAuthentication(() => false);
  const theme = useContext(ThemeContext);
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);

  // Refs

  const currentAvatarRef = useRef();

  // APIs

  const getUserAvatarListAPIResult = useQuery(userAvatarListQuery);
  const avatarList = getUserAvatarListAPIResult.data?.avatar;
  const [setUserAvatarAPI] = useMutation(setUserAvatarMutation);
  const [removeUserAvatarAPI] = useMutation(removeUserAvatarMutation);

  // Extracting unique avatar names

  const uniqueNames = [];
  const avatarNames = avatarList?.filter((avatar) => {
    const isDuplicate = uniqueNames.includes(avatar.name);

    if (!isDuplicate) {
      uniqueNames.push(avatar.name);
      return true;
    }
    return false;
  });

  // States

  const [currentAvatar, setCurrentAvatar] = useState(currentUser?.avatar?.id);
  const [buttonFilter, setButtonFilter] = useState(currentUser?.avatar?.name || 'GREYBEAR');
  const [initalScrollCompleted, setInitialScrollCompleted] = useState(false);

  const avatarIsChanged = currentAvatar !== currentUser?.avatar?.id;
  const hasAvatar = currentUser.avatar;

  const filteredList = avatarList?.filter((avatar) => {
    return avatar.name == buttonFilter;
  });

  // Effects

  useEffect(() => {
    setTimeout(() => {
      const currentAvatarIndex = filteredList?.findIndex((avatar) => avatar?.id == currentUser?.avatar?.id);
      initalScrollCompleted == false &&
        currentAvatarIndex &&
        currentAvatarRef.current.scrollToSlideAtIndex(currentAvatarIndex);
    }, 0);
    setInitialScrollCompleted(true);
  }, [currentUser?.avatar?.id, filteredList, initalScrollCompleted]);

  // Methods

  const chooseAvatar = useCallback((avatar) => {
    setCurrentAvatar(avatar.target.id);
  }, []);

  const setAvatar = useCallback(() => {
    {
      currentAvatar &&
        setUserAvatarAPI({
          variables: {
            avatarId: currentAvatar,
          },
        }).then(() => dismiss());
    }
  }, [currentAvatar, dismiss, setUserAvatarAPI]);

  const removeAvatar = useCallback(() => {
    {
      currentAvatar && removeUserAvatarAPI().then(() => dismiss());
    }
  }, [currentAvatar, dismiss, removeUserAvatarAPI]);

  const onClickAnimal = (name) => {
    setButtonFilter(name);
  };

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.container}>
        <div className={styles.header}>
          <h3 className={styles.textAvatar}>Make avatar</h3>
          <div>
            <PopupCloseButton className={styles.buttonCancel} linkStyle tint='alert'>
              Cancel
            </PopupCloseButton>
            {avatarIsChanged && (
              <PopupCloseButton className={styles.buttonSave} linkStyle tint='navigation' onClick={setAvatar}>
                Save
              </PopupCloseButton>
            )}
          </div>
        </div>
        <div className={styles.buttonGroup}>
          {avatarNames &&
            avatarNames.map((avatar) => {
              return (
                <Button
                  key={avatar.name}
                  className={styles.actionButton}
                  tint={buttonFilter == avatar.name ? 'navigation' : 'secondary'}
                  onClick={() => onClickAnimal(avatar.name)}
                >
                  {avatar?.name[0] + avatar?.name.slice(1, avatar.name.length).toLowerCase()}
                </Button>
              );
            })}
        </div>
        <Carousel className={styles.carouselContainer} innerClassName={styles.carousel} ref={currentAvatarRef}>
          {avatarList &&
            buttonFilter &&
            filteredList.map((avatar) => {
              return (
                <Slide key={avatar.id} className={styles.linkSlide}>
                  <img
                    className={avatar.id == currentAvatar ? styles.selectedAvatarImage : styles.avatarImage}
                    src={theme.name == 'light' ? avatar.sourceLightURL : avatar.sourceDarkURL}
                    alt='No text'
                    onClick={(avatar) => chooseAvatar(avatar)}
                    id={avatar.id}
                  ></img>
                </Slide>
              );
            })}
        </Carousel>
        {!useDesktopLayout && (
          <Text className={styles.bottomText} bold>
            Swipe to see other versions
          </Text>
        )}
        {hasAvatar && (
          <Button tint='alert' linkStyle onClick={removeAvatar} style={{ marginBottom: '0' }}>
            Remove avatar
          </Button>
        )}
        <PopupButtonsGroup>
          <PopupCloseButton tint='alert'>Cancel</PopupCloseButton>
          {avatarIsChanged && (
            <PopupCloseButton tint='navigation' onClick={setAvatar}>
              Save
            </PopupCloseButton>
          )}
        </PopupButtonsGroup>
      </PopupContent>
    </Popup>
  );
}

SetAvatarPopup.propTypes = {
  dismiss: PropTypes.func,
};
