import { gql } from '@apollo/client';

import { AudioMediaAssetModel, ImageMediaAssetModel, VideoMediaAssetModel } from '../mediaAsset';

export default class VersionedMediaAssetModel {
  static GRAPHQL_TYPE = 'VersionedMediaAsset';

  static fragmentName = 'VersionedMediaAsset_Fragment';
  static fragment = gql`
    fragment ${VersionedMediaAssetModel.fragmentName} on ${VersionedMediaAssetModel.GRAPHQL_TYPE} {
      REFID
      audioData {
        ...${AudioMediaAssetModel.fragmentName}
      }
      createdBy
      createdDate
      id
      imageData {
        ...${ImageMediaAssetModel.fragmentName}
      }
      lastModifiedBy
      lastModifiedDate
      mediaAssetId
      mediaType
      organisationId
      version
      versionDate
      videoData {
        ...${VideoMediaAssetModel.fragmentName}
      }
    }
    ${AudioMediaAssetModel.fragment}
    ${ImageMediaAssetModel.fragment}
    ${VideoMediaAssetModel.fragment}
  `;
}
