import { gql } from '@apollo/client';

import { getDataURLFromFile } from 'OK/util/functions';

/* Audio */

export class AudioMediaAssetModel {
  constructor(dataOrFile) {
    let audioName;
    let audioURL;
    let metadata;

    if (dataOrFile.audioName) {
      // Data
      audioName = dataOrFile.audioName;
      audioURL = dataOrFile.audioURL;
      metadata = dataOrFile.metadata;
    } else {
      // File
      audioName = dataOrFile.name;
      audioURL = getDataURLFromFile(dataOrFile);
      metadata = {};
    }

    this.audioName = audioName;
    this.audioURL = audioURL;
    this.metadata = metadata;
    this.__typename = AudioMediaAssetModel.GRAPHQL_TYPE;
  }

  static GRAPHQL_TYPE = 'AudioMediaAsset';

  static fragmentName = 'AudioMediaAsset_Fragment';
  static fragment = gql`
    fragment ${AudioMediaAssetModel.fragmentName} on ${AudioMediaAssetModel.GRAPHQL_TYPE} {
      audioName
      audioURL
      metadata
    }
  `;
}

/* Image */

export class ImageMediaAssetModel {
  constructor(dataOrFile) {
    let baseImageURL;
    let imageName;
    let imageURL;
    let metadata;

    if (dataOrFile.imageName) {
      // Data
      baseImageURL = dataOrFile.baseImageURL;
      imageName = dataOrFile.imageName;
      imageURL = dataOrFile.imageURL;
      metadata = dataOrFile.metadata;
    } else {
      // File
      const dataUrl = getDataURLFromFile(dataOrFile);
      baseImageURL = dataUrl;
      imageName = dataOrFile.name;
      imageURL = dataUrl;
      metadata = {};
    }

    this.baseImageURL = baseImageURL;
    this.imageName = imageName;
    this.imageURL = imageURL;
    this.metadata = metadata;
    this.__typename = ImageMediaAssetModel.GRAPHQL_TYPE;
  }

  static GRAPHQL_TYPE = 'ImageMediaAsset';

  static fragmentName = 'ImageMediaAsset_Fragment';
  static fragment = gql`
    fragment ${ImageMediaAssetModel.fragmentName} on ${ImageMediaAssetModel.GRAPHQL_TYPE} {
      baseImageURL
      imageName
      imageURL
      metadata
    }
  `;
}

/* Video */

export class VideoMediaAssetModel {
  constructor(dataOrFile) {
    let metadata;
    let videoName;
    let videoURL;

    if (dataOrFile.videoName) {
      // Data
      metadata = dataOrFile.metadata;
      videoName = dataOrFile.videoName;
      videoURL = dataOrFile.videoURL;
    } else {
      // File
      metadata = {};
      videoName = dataOrFile.name;
      videoURL = getDataURLFromFile(dataOrFile);
    }

    this.metadata = metadata;
    this.videoName = videoName;
    this.videoURL = videoURL;
    this.__typename = VideoMediaAssetModel.GRAPHQL_TYPE;
  }

  static GRAPHQL_TYPE = 'VideoMediaAsset';

  static fragmentName = 'VideoMediaAsset_Fragment';
  static fragment = gql`
    fragment ${VideoMediaAssetModel.fragmentName} on ${VideoMediaAssetModel.GRAPHQL_TYPE} {
      metadata
      videoName
      videoURL
    }
  `;
}

/* General */

export default class BaseMediaAssetModel {
  static GRAPHQL_TYPE = 'MediaAsset';

  static fragmentName = 'BaseMediaAsset_Fragment';
  static fragment = gql`
    fragment ${BaseMediaAssetModel.fragmentName} on ${BaseMediaAssetModel.GRAPHQL_TYPE} {
      REFID
      audioData {
        ...${AudioMediaAssetModel.fragmentName}
      }
      conformityPoint
      createdBy
      createdDate
      id
      imageData {
        ...${ImageMediaAssetModel.fragmentName}
      }
      lastModifiedBy
      lastModifiedDate
      mediaType
      organisationId
      status
      version
      videoData {
        ...${VideoMediaAssetModel.fragmentName}
      }
    }
    ${AudioMediaAssetModel.fragment}
    ${ImageMediaAssetModel.fragment}
    ${VideoMediaAssetModel.fragment}
  `;
}
