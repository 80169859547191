import PropTypes from 'prop-types';
import { useContext } from 'react';

import TabsContext from '../tabContext';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import Link from 'OK/components/link';

/**
 * A tab associated with some content. Optionally use together with a `TabSection`.
 *
 * Note: must be a descendant of a `TabsContextProvider`.
 *
 * @param {object} props
 * @param {Node} props.children Tab content.
 * @param {string} [props.className] The class.
 * @param {string} props.tabId A unique identifying string. When used together with `TabSection`, they must match.
 */
export default function Tab(props) {
  /* Variables */

  const { children, className, tabId, ...otherProps } = props;
  const { activeTabId, renderLinks, setActiveTabId, tabStyle } = useContext(TabsContext);

  /* Events */

  const onClick = () => {
    setActiveTabId(tabId);
  };

  /* Render */

  let classNames = styles.tab;
  switch (tabStyle) {
    case 'button':
      classNames = `${classNames} ${styles.button}`;
      break;
    case 'caret':
      classNames = `${classNames} ${styles.caret}`;
      break;
    case 'tag':
      classNames = `${classNames} ${styles.tag}`;
      break;
    default:
      classNames = `${classNames} ${styles.underline}`;
      break;
  }
  if (activeTabId === tabId) {
    classNames = `${classNames} ${styles.active}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  const Component = renderLinks ? Link : 'div';
  const componentProps = { className: classNames };
  if (!renderLinks) {
    componentProps.onClick = onClick;
  }

  return (
    <Component id={tabId.toLowerCase()} {...componentProps} {...otherProps}>
      {children}
      {tabStyle === 'caret' && <Icon className={styles.caretIcon} name={ICONS.CARET.name} />}
    </Component>
  );
}

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tabId: PropTypes.string.isRequired,
};
