export default function getPopupLink(assetType, REFID, OKID) {
  switch (assetType) {
    case 'product':
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'PRODUCT', OKID: null },
        null,
        `/archive/product/${REFID}`
      );

      break;
    case 'site':
      history.pushState({ isPopup: true, refId: null, dataType: 'SITE', OKID: REFID }, null, `/archive/site/${REFID}`);

      break;
    case 'item':
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'ITEM', OKID: null },
        null,
        `/${REFID}`
      );

      break;
    case 'order':
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'ORDER', OKID: null },
        null,
        `/archive/order/${REFID}/edit`
      );

      break;
    case 'file':
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'FILE', OKID: null },
        null,
        `/archive?refId=${REFID}&dataType=FILE`
      );

      break;
    case 'media':
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'MEDIA_ASSET', OKID: null },
        null,
        `/archive?refId=${REFID}&dataType=MEDIA_ASSET`
      );

      break;
    case 'note':
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'NOTE_ASSET', OKID: null },
        null,
        `/archive?refId=${REFID}&dataType=NOTE_ASSET`
      );

      break;
    case 'label':
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'LABEL', OKID: null },
        null,
        `/archive?refId=${REFID}&dataType=LABEL`
      );

      break;
    case 'step':
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'TEST_ASSET', OKID: null },
        null,
        `/archive/step/${REFID}/edit`
      );

      break;
    case 'workflow':
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'INSPECTION_ASSET' },
        null,
        `/archive/workflow/${REFID}/edit`
      );

      break;
    case 'log':
      history.pushState({ isPopup: true, refId: REFID, dataType: 'INSPECTION_LOG' }, null, `/archive/log/${REFID}`);
  }
}
