import { gql } from '@apollo/client';
import { nanoid } from 'nanoid';

import InspectionLogFindingModel from '../inspectionLogFinding';
import InspectionLogTestAssetMediaAssetModel from '../inspectionLogTestAssetMediaAsset';
import MediaAssetModel from '../mediaAsset';
import TestAssetModel from '../testAsset';
import UnversionedTestAssetModel from '../unversionedTestAsset';
import VersionedTestAssetModel from '../versionedTestAsset';

import BaseInspectionLogTestAssetModel from './base';

export default class InspectionLogTestAssetModel {
  static GRAPHQL_TYPE = BaseInspectionLogTestAssetModel.GRAPHQL_TYPE;

  static METADATA_KEYS = {
    IDENTIFIERS: 'IDENTIFIERS',
    TIME_TRACKING: 'TIME_TRACKING',
  };

  static FIELD_POLICIES = {
    fields: {
      criticalAQL: {
        read(_, { readField }) {
          const sampleCriticalFailures = readField('sampleCriticalFailures');
          const sampleCriticalInspected = readField('sampleCriticalInspected');
          if (sampleCriticalInspected === 0) {
            return 0;
          }

          return (sampleCriticalFailures / sampleCriticalInspected) * 100;
        },
      },
      majorAQL: {
        read(_, { readField }) {
          const sampleMajorFailures = readField('sampleMajorFailures');
          const sampleMajorInspected = readField('sampleMajorInspected');
          if (sampleMajorInspected === 0) {
            return 0;
          }

          return (sampleMajorFailures / sampleMajorInspected) * 100;
        },
      },
      minorAQL: {
        read(_, { readField }) {
          const sampleMinorFailures = readField('sampleMinorFailures');
          const sampleMinorInspected = readField('sampleMinorInspected');
          if (sampleMinorInspected === 0) {
            return 0;
          }

          return (sampleMinorFailures / sampleMinorInspected) * 100;
        },
      },
      metadata: {
        merge(existing, incoming, { variables }) {
          if (typeof variables?.key === 'string') {
            // A specific key was updated, so only merge the update to that key.
            // This allows the TIME-TRACKING stopwatch to run uninterrupted by other metadata updates.
            const updatedKey = variables.key;
            const updated = {
              ...existing,
              [updatedKey]: incoming[updatedKey],
            };
            return updated;
          }

          // Merge full incoming update.
          return incoming;
        },
      },
    },
  };

  static fragmentName = 'InspectionLogTestAssetModel_Fragment';
  static fragment = gql`
    fragment ${InspectionLogTestAssetModel.fragmentName} on ${InspectionLogTestAssetModel.GRAPHQL_TYPE} {
      ...${BaseInspectionLogTestAssetModel.fragmentName}
      inspectionLogFindingList {
        ...${InspectionLogFindingModel.fragmentName}
      }
      inspectionLogTestAssetMediaAssetList {
        ...${InspectionLogTestAssetMediaAssetModel.fragmentName}
      }
      inspectionLogTestAssetValueList {
        passed
        timestamp
        value
        valueId
      }
      unversionedTestAsset {
        ...${UnversionedTestAssetModel.fragmentName}
      }
      versionedTestAsset {
        ...${VersionedTestAssetModel.fragmentName}
      }
    }
    ${BaseInspectionLogTestAssetModel.fragment}
    ${InspectionLogFindingModel.fragment}
    ${InspectionLogTestAssetMediaAssetModel.fragment}
    ${UnversionedTestAssetModel.fragment}
    ${VersionedTestAssetModel.fragment}
  `;

  static fragmentNameMetadata = 'InspectionLogTestAssetModel_Metadata_Fragment';
  static fragmentMetadata = gql`
    fragment ${InspectionLogTestAssetModel.fragmentNameMetadata} on ${InspectionLogTestAssetModel.GRAPHQL_TYPE} {
      id
      metadata
    }
  `;

  static fragmentNameUnversionedConditions = 'InspectionLogTestAssetModel_UnversionedConditions_Fragment';
  static fragmentUnversionedConditions = gql`
    fragment ${InspectionLogTestAssetModel.fragmentNameUnversionedConditions} on ${InspectionLogTestAssetModel.GRAPHQL_TYPE} {
      id
      unversionedTestAsset {
        requireAllConditionToPass
        testAssetConditionList {
          type
          value
          valueType
        }
      }
    }
  `;

  static fragmentNameResultCard = 'InspectionLogTestAssetModel_ResultCard_Fragment';
  static fragmentResultCard = gql`
    fragment ${InspectionLogTestAssetModel.fragmentNameResultCard} on ${InspectionLogTestAssetModel.GRAPHQL_TYPE} {
      failed
      fixed
      id
      inspectionLogFindingList {
        id
        inspectionLogTestAssetId
        unversionedMediaAsset {
          imageData {
            imageName
            imageURL
            metadata
          }
          videoData {
            videoName
            videoURL
            metadata
          }
        }
      }
      inspectionLogTestAssetMediaAssetList {
        versionedMediaAsset {
          imageData {
            imageURL
          }
          mediaType
        }
        unversionedMediaAsset {
          imageData {
            imageURL
          }
          mediaType
        }
      }
      lotFixableProjection
      lotFixableProjectionPercent
      lotFixedPercent
      lotFlawlessProjection
      lotFlawlessProjectionPercent
      lotRejectedPercent
      lotTolerableProjection
      lotTolerableProjectionPercent
      lotUnfixableProjection
      lotUnfixableProjectionPercent
      metadata
      passed
      samplePassedAsFixedPercent
      samplePassedAsFlawlessPercent
      samplePassedAsToleratedPercent
      sampleRejectedPercent
      tolerated
      unversionedTestAsset {
        enableIdentifiers
        enableNotes
        enableTimeTracking
        requireAllConditionToPass
        testAssetConditionList {
          type
          value
          valueType
        }
        testSeverityLevel
        whatToLookForMap
      }
      versionedTestAsset {
        enableIdentifiers
        enableNotes
        enableTimeTracking
        requireAllConditionToPass
        testAssetConditionList {
          type
          value
          valueType
        }
        testSeverityLevel
        whatToLookForMap
      }
    }
  `;

  static firstImageMediaAssetUrlForTest = (inspectionLogTestAsset) => {
    const inspectionLogTestAssetMediaAsset = inspectionLogTestAsset.inspectionLogTestAssetMediaAssetList.find(
      (tma) => (tma.versionedMediaAsset ?? tma.unversionedMediaAsset).mediaType === MediaAssetModel.MEDIA_TYPE.IMAGE
    );

    if (inspectionLogTestAssetMediaAsset) {
      return (
        inspectionLogTestAssetMediaAsset.versionedMediaAsset ?? inspectionLogTestAssetMediaAsset.unversionedMediaAsset
      ).imageData.imageURL;
    }

    return null;
  };

  /* Helpers */

  static generateNewTestAssetValue(value, conditions, requireAllConditionsToPass = true) {
    let passes = 0;
    const date = new Date();

    for (let condition of conditions) {
      let passed = false;
      let conditionValue;

      // Parse condition value
      switch (condition.valueType) {
        case TestAssetModel.CONDITION_VALUE_TYPES.NUMBER:
          conditionValue = parseFloat(condition.value);
          break;
        default:
          conditionValue = condition.value;
          break;
      }

      // Evaluate value and condition
      switch (condition.type) {
        case TestAssetModel.CONDITION_TYPES.MIN_VALUE:
          passed = value >= conditionValue;
          break;
        case TestAssetModel.CONDITION_TYPES.MAX_VALUE:
          passed = value <= conditionValue;
          break;
        default:
          break;
      }

      // If all conditions must pass, exit the loop after the first failure.
      if (requireAllConditionsToPass && !passed) {
        break;
      }

      // Increment the number of conditions passed.
      if (passed) {
        passes += 1;
      }
    }

    const valuePassed = requireAllConditionsToPass ? passes === conditions.length : passes > 0;

    return {
      passed: valuePassed,
      timestamp: date.getTime(),
      value,
      valueId: nanoid(),
    };
  }
}
