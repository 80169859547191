import PropTypes from 'prop-types';

import Link from '../';

export default function LinkToInspectionLog(props) {
  const { children, inspectionLogAsset, ...otherProps } = props;
  const linkUrl = `/archive/log/${inspectionLogAsset.REFID}/go`;

  return (
    <Link href={linkUrl} {...otherProps}>
      {children}
    </Link>
  );
}

LinkToInspectionLog.propTypes = {
  children: PropTypes.node,
  inspectionLogAsset: PropTypes.shape({
    REFID: PropTypes.string.isRequired,
  }).isRequired,
};
