import { useApolloClient, useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import Document from 'OK/components/document';
import DocumentArchiveCard from 'OK/components/document/archiveCard';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import ContentLayout from 'OK/components/layouts/content';
import LinkToFile from 'OK/components/link/linkToFile';
import MediaPicker from 'OK/components/mediaPicker';
import Notice from 'OK/components/notice';
import Progressable from 'OK/components/progressable';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Text from 'OK/components/text';
import appConfig from 'OK/config/app';
import DocumentModel from 'OK/models/document';
import SiteModel from 'OK/models/site';
import SiteDocumentModel from 'OK/models/siteDocument';
import EditFilePopup from 'OK/modules/popups/file/edit';
import { searchQuery } from 'OK/networking/search';
import { createSiteDocumentAssetRequest } from 'OK/networking/sites';
import {
  useLinkSiteDocumentAPI,
  usePublishSiteDocumentAPI,
  useUnlinkSiteDocumentAPI,
  useUnpublishSiteDocumentAPI,
} from 'OK/networking/sites/hooks';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import useI18n from 'OK/util/hooks/useI18n';

export default function EditSitePageDocumentationSection(props) {
  /* Variables */

  const { editMode, isAuthorisedToEdit, site } = props;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const apolloClient = useApolloClient();
  const { t, tHTML } = useI18n();
  const siteId = site.id;
  const siteDocuments = site.siteDocumentAssetList.filter((sd) => sd.documentAsset.REFID !== null);

  // Refs

  const documentsSliderRef = useRef();
  const mediaPickerRef = useRef();

  // State

  const [createDocumentError, setCreateDocumentError] = useState(null);
  const [isUploadingNewDocument, setIsUploadingNewDocument] = useState(false);
  const [newDocumentId, setNewDocumentId] = useState(null);
  const [siteDocumentsErrors, setSiteDocumentsErrors] = useState([]);
  const [searchDocumentsFocused, setSearchDocumentsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  /* API */

  const siteCacheId = `${SiteModel.GRAPHQL_TYPE}:${siteId}`;

  const linkDocumentAPI = useLinkSiteDocumentAPI();
  const publishDocumentAPI = usePublishSiteDocumentAPI();
  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);
  const unlinkDocumentAPI = useUnlinkSiteDocumentAPI();
  const unpublishDocumentAPI = useUnpublishSiteDocumentAPI();

  /* Methods */

  const addErrorForDocument = (documentId, errorKey, errorMessage) => {
    const indexOfErrorsForDocument = siteDocumentsErrors.findIndex((pde) => pde.documentId === documentId);
    let errorsForDocument;
    let updatedSiteDocumentsErrors;
    if (indexOfErrorsForDocument > -1) {
      errorsForDocument = siteDocumentsErrors[indexOfErrorsForDocument];
      errorsForDocument[errorKey] = errorMessage;
      updatedSiteDocumentsErrors = [...siteDocumentsErrors];
      updatedSiteDocumentsErrors.splice(indexOfErrorsForDocument, 1, errorsForDocument);
    } else {
      errorsForDocument = {
        documentId,
        [errorKey]: errorMessage,
      };
      updatedSiteDocumentsErrors = [...siteDocumentsErrors, errorsForDocument];
    }

    setSiteDocumentsErrors(updatedSiteDocumentsErrors);
  };

  const closeEditFilePopup = useCallback(() => {
    setNewDocumentId(null);
  }, []);

  const createDocument = async (files) => {
    if (files.length > 0) {
      const file = files[0];
      setIsUploadingNewDocument(true);
      setCreateDocumentError(null);
      try {
        const response = await createSiteDocumentAssetRequest(siteId, file, 'PRODUCT_FILE');
        if (response.success) {
          // Create DocumentAsset reference in cache
          const documentAsset = {
            ...response.responseData.documentAsset,
            REFID: response.responseData.documentAsset.refid,
            __typename: DocumentModel.GRAPHQL_TYPE,
          };
          const documentAssetRef = apolloClient.cache.writeFragment({
            id: apolloClient.cache.identify(documentAsset),
            fragment: DocumentModel.fragment,
            fragmentName: DocumentModel.fragmentName,
            data: documentAsset,
          });

          // Link new document to site in the cache
          const siteDocument = {
            ...response.responseData,
            documentAsset: documentAssetRef,
            __typename: SiteDocumentModel.GRAPHQL_TYPE,
          };
          apolloClient.cache.modify({
            id: siteCacheId,
            fields: {
              siteDocumentAssetList: (currentList = []) => {
                return [...currentList, siteDocument];
              },
            },
          });

          // Scroll to new document in slider
          setTimeout(() => {
            documentsSliderRef.current.scrollToSlideAtIndex(siteDocuments.length);
          }, 50);

          setNewDocumentId(documentAsset.id);
        } else {
          okerror('Error creating document.');
          setCreateDocumentError(t(response.error ?? 'ERROR_GENERIC'));
        }
      } catch (e) {
        okerror('Error creating document', e);
        setCreateDocumentError(t('ERROR_GENERIC'));
      } finally {
        setIsUploadingNewDocument(false);
      }
    }
  };

  const openFilePicker = () => {
    mediaPickerRef.current.open();
  };

  const publishDocument = (documentId) => {
    // Clear publish related errors
    removeErrorForDocument(documentId, 'publishError');

    // Call API
    publishDocumentAPI(site, documentId).catch(() => {
      addErrorForDocument(documentId, 'publishError', t('UPDATE_FAILED_CONNECTION'));
    });
  };

  const removeErrorForDocument = (documentId, errorKey) => {
    const indexOfErrorsForDocument = siteDocumentsErrors.findIndex((pde) => pde.documentId === documentId);
    if (indexOfErrorsForDocument > -1) {
      const errorsForDocument = {
        ...siteDocumentsErrors[indexOfErrorsForDocument],
      };
      delete errorsForDocument[errorKey];
      const updatedSiteDocumentsErrors = [...siteDocumentsErrors];
      updatedSiteDocumentsErrors.splice(indexOfErrorsForDocument, 1, errorsForDocument);
      setSiteDocumentsErrors(updatedSiteDocumentsErrors);
    }
  };

  const searchDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'DOCUMENT_ASSET',
                ignoreIdList: [...siteDocuments.map((pd) => pd.documentAsset.id)],
              },
            ],
            searchPaginationDataByDataType: [
              {
                dataType: 'DOCUMENT_ASSET',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [siteDocuments, searchAPI]
  );

  const unlinkDocument = (documentId) => {
    // Clear unlink related errors
    removeErrorForDocument(documentId, 'unlinkError');

    // Call API
    unlinkDocumentAPI(site, documentId).catch(() => {
      addErrorForDocument(documentId, 'unlinkError', t('UPDATE_FAILED_CONNECTION'));
    });
  };

  const unpublishDocument = (documentId) => {
    // Clear publish related errors
    removeErrorForDocument(documentId, 'publishError');

    // Call API
    unpublishDocumentAPI(site, documentId).catch(() => {
      addErrorForDocument(documentId, 'publishError', t('UPDATE_FAILED_CONNECTION'));
    });
  };

  // Event handlers

  const onChangeDocumentPublished = (documentId, published) => {
    if (published) {
      publishDocument(documentId);
    } else {
      unpublishDocument(documentId);
    }
  };

  const onClickLinkDocument = useCallback(
    (documentAssetId) => {
      setSearchTerm('');
      linkDocumentAPI(site.id, documentAssetId).then(() => {
        setTimeout(() => {
          documentsSliderRef.current.scrollToSlideAtIndex(siteDocuments.length);
        }, 50);
      });
    },
    [linkDocumentAPI, site.id, siteDocuments.length]
  );

  const onSearch = useCallback(
    (e) => {
      const searchString = e.target.value;
      setSearchTerm(searchString);

      if (searchString.length > 1) {
        searchDebounced(searchString);
      }
    },
    [searchDebounced]
  );

  /* Render */

  const documentsNotice = (
    <Notice className={styles.sectionNotice}>
      <p>{tHTML('SUPPORTED_DOCUMENT_FORMATS')}</p>
      <Text tint='notification'>
        {t('DOCUMENT_MAX_FILESIZE', { data: { number: appConfig.fileSizeLimitsMB.document } })}
      </Text>
    </Notice>
  );

  const searchSuggestions = useMemo(() => {
    if (searchDocumentsFocused && searchTerm.length > 1) {
      return (
        searchAPIResult.data?.search?.resultList?.map((r) => {
          const documentAsset = r.documentAssetData;
          return {
            icon: ICONS.DOCUMENT.name,
            key: documentAsset.id,
            title: documentAsset.documentName,
            subtitle: documentAsset.REFID,
          };
        }) ?? []
      );
    }

    return [];
  }, [searchAPIResult.data?.search?.resultList, searchDocumentsFocused, searchTerm.length]);

  return (
    <div>
      {editMode ? (
        <ContentLayout style={{ paddingTop: 30 }} pageContent>
          {useDesktopLayout && documentsNotice}
          <div className={styles.sectionIntro}>
            <p>{t('SITE_SECTION_DOCUMENTATION_DESCRIPTION')}</p>
            <div className={styles.searchInputContainer}>
              <SearchInput
                className={styles.searchInput}
                loading={searchAPIResult.loading}
                onBlur={() => setSearchDocumentsFocused(false)}
                onChange={onSearch}
                onFocus={() => setSearchDocumentsFocused(true)}
                placeholder={t('DOCUMENTATION_SEARCH_PLACEHOLDER')}
                value={searchTerm}
              />
              <Button
                className={styles.searchNewButton}
                disabled={isUploadingNewDocument}
                loading={isUploadingNewDocument}
                linkStyle
                onClick={openFilePicker}
                tint='creation'
              >
                {t('NEW')}
              </Button>
              <SearchSuggestions
                accessoryViewRender={() => (
                  <Button className={styles.searchResultButton} icon='/icons/link_blue.svg' linkStyle>
                    {t('LINK')}
                  </Button>
                )}
                className={styles.searchResults}
                highlightTerm={searchTerm}
                onSuggestionClick={onClickLinkDocument}
                showNoResultsMessage={searchDocumentsFocused && searchTerm.length > 1 && searchSuggestions.length === 0}
                showMoreResultsMessage={
                  searchDocumentsFocused &&
                  searchAPIResult?.data?.search?.searchPaginationResultDataByDataType?.DOCUMENT_ASSET?.totalResults >
                    searchSuggestions.length
                }
                subtitleClassName={styles.searchResultOKID}
                suggestions={searchSuggestions}
              />
              <MediaPicker invisible mediaTypes={['document']} onChange={createDocument} ref={mediaPickerRef} />
            </div>
            {createDocumentError && (
              <Text className={styles.errorMessage} size='sm' tint='alert'>
                {createDocumentError}
              </Text>
            )}
          </div>
          {!useDesktopLayout && documentsNotice}
          <Progressable
            // inProgress={uploadingDocument || linkDocumentResult.loading || unlinkDocumentResult.loading}
            style={{ minHeight: '100px' }}
          >
            <Carousel
              className={styles.carouselContainer}
              innerClassName={styles.carousel}
              fadeOutSides={useDesktopLayout}
              ref={documentsSliderRef}
            >
              {siteDocuments.map((pd) => {
                const errorsForDocument = siteDocumentsErrors.find((pde) => pde.documentId === pd.documentAsset.id);
                return (
                  <Slide className={styles.documentSlide} key={pd.documentAsset.id}>
                    <Document
                      className={styles.document}
                      isAuthorisedToEdit={isAuthorisedToEdit}
                      document={pd.documentAsset}
                      fixedWidth={false}
                      onClickUnlink={unlinkDocument}
                      onChangePublished={onChangeDocumentPublished}
                      publishError={errorsForDocument?.publishError}
                      published={pd.publishStatus === PUBLISH_STATUS.PUBLISHED}
                      unlinkButtonTitle={t('UNLINK_FROM_SITE')}
                      unlinkError={errorsForDocument?.unlinkError}
                    />
                  </Slide>
                );
              })}
              <Slide className={styles.documentSlide}>
                <button className={styles.addSlideButton} disabled={isUploadingNewDocument} onClick={openFilePicker}>
                  <Icon className={styles.addSlideIcon} height={80} name={ICONS.PLUS_BIG_CIRCLE.name} width={80} />{' '}
                  {t('ADD_NEW_DOCUMENTATION')}
                </button>
              </Slide>
            </Carousel>
          </Progressable>
        </ContentLayout>
      ) : (
        <ContentLayout style={{ paddingTop: 30 }} pageContent>
          {siteDocuments.length > 0 ? (
            <Carousel
              className={styles.documentCarouselSecondary}
              innerClassName={styles.documentCarouselInnerSecondary}
              fadeOutSides={useDesktopLayout}
            >
              {siteDocuments.map((pf) => {
                return (
                  <Slide key={pf.id} className={styles.documentSlideSecondary}>
                    <LinkToFile
                      className={styles.cardContainer}
                      key={pf.documentAsset.id}
                      file={pf.documentAsset}
                      linkToDownload
                    >
                      <DocumentArchiveCard
                        className={styles.resultCardContainerSecondary}
                        cardClassName={styles.resultCardSecondary}
                        documentAsset={pf.documentAsset}
                        fixedWidth={useDesktopLayout}
                        key={pf.id}
                        linkToArchivePage={false}
                      />
                    </LinkToFile>
                  </Slide>
                );
              })}
            </Carousel>
          ) : (
            <Text>{t('NO_DOCUMENTS')}</Text>
          )}
        </ContentLayout>
      )}

      {typeof newDocumentId === 'string' && <EditFilePopup dismiss={closeEditFilePopup} fileId={newDocumentId} />}
    </div>
  );
}

EditSitePageDocumentationSection.propTypes = {
  editMode: PropTypes.bool,
  isAuthorisedToEdit: PropTypes.bool,
  site: PropTypes.object.isRequired,
};
