import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setActiveSiteSection, setLastVisitedSectionAction } from 'OK/state/app/actions';

/** Top-level sections of the site which are indicated as active in the main menu. */
export const SITE_SECTIONS = {
  ARCHIVE: 'ARCHIVE',
  SOLUTIONS: 'SOLUTIONS',
};

/** URL path generators. */
export const ROUTES = {
  ARCHIVE: (section) => {
    if (section) {
      return `/archive?section=${section}`;
    }
    return '/archive';
  },
  AUTH: () => '/auth',
  HOME: () => '/',
  SOLUTIONS_FEATURES: () => '/solutions/features',
};

/**
 * Determine the site section for a given path.
 *
 * @param {string} path
 */
export function SectionForPath(path) {
  if (/^\/archive/.test(path) || path === '/[OKID]') {
    return SITE_SECTIONS.ARCHIVE;
  }
  if (/^\/solutions/.test(path)) {
    return SITE_SECTIONS.SOLUTIONS;
  }
}

/** Router component to handle various navigation logic.
 *
 * @param {object} props
 */
function OKRouter(props) {
  /* Variables */

  const dispatch = useDispatch();
  const router = useRouter();

  /* Effects */

  // Maintain the currently active site section and last visited path
  useEffect(() => {
    // Update the active section
    const newActiveSiteSection = SectionForPath(router.pathname);
    dispatch(setActiveSiteSection(newActiveSiteSection));

    if (newActiveSiteSection) {
      // Store last visited page within the section
      dispatch(setLastVisitedSectionAction(newActiveSiteSection, router.asPath));
    }
  }, [dispatch, router]);

  /* Render */

  return <>{props.children}</>;
}

OKRouter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OKRouter;
