import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import TextLayout from 'OK/components/layouts/content/text';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import Tag from 'OK/components/tag';
import EditTest, { EDIT_TEST_PAGE_MODE } from 'OK/modules/editTest';
import UIContext from 'OK/util/context/ui';
import useI18n from 'OK/util/hooks/useI18n';

export default function TestPopup(props) {
  /* Variables */

  const { dismiss, inspectionLogTestAssetId, mode = EDIT_TEST_PAGE_MODE.DEFAULT_MODE, testRefId } = props;
  const { t } = useI18n();

  /* Render */

  return (
    <UIContext.Provider value='base'>
      <Popup dismiss={dismiss}>
        <PopupContent className={styles.popupContent} reducedSidePadding>
          <TextLayout>
            <h3 className={styles.header}>{t('STEP')}</h3>
            <Tag className={styles.testRefId} invert size='sm'>
              {testRefId}
            </Tag>
            <PopupCloseButton className={styles.cancelButton} linkStyle tint='navigation'>
              {t('CLOSE')}
            </PopupCloseButton>
          </TextLayout>
          <EditTest inspectionLogTestAssetId={inspectionLogTestAssetId} mode={mode} testRefId={testRefId} />
        </PopupContent>
        <PopupButtonsGroup>
          <PopupCloseButton tint='navigation'>{t('CLOSE')}</PopupCloseButton>
        </PopupButtonsGroup>
      </Popup>
    </UIContext.Provider>
  );
}

TestPopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
  inspectionLogTestAssetId: PropTypes.string,
  mode: PropTypes.oneOf([EDIT_TEST_PAGE_MODE.VERSIONED_MODE, EDIT_TEST_PAGE_MODE.UNVERSIONED_MODE]),
  testRefId: PropTypes.string,
};
