import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
  ArchiveCardTitle,
} from 'OK/components/archiveCard';
import { ICONS } from 'OK/components/icon';
import Link from 'OK/components/link';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import LabelTemplateModel, { LabelTemplateFeaturesString, LabelTemplateName } from 'OK/models/labelTemplate';
import ProductModel from 'OK/models/product';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import { formatDateRelative } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function LabelArchiveCard(props) {
  const { cardClassName, className, label, linkToLabel = true, ...otherProps } = props;
  const { labelTemplate, itemList, product, REFID } = label;
  const { languageCode } = labelTemplate;
  const { t, locale } = useI18n();
  const dispatch = useDispatch();

  const Container = linkToLabel ? Link : 'div';

  const containerComponentProps = { className };
  if (linkToLabel) {
    containerComponentProps.href = `/archive?refId=${REFID}&dataType=LABEL`;
    containerComponentProps.onClick = (e) => {
      e.preventDefault();
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'LABEL', OKID: null },
        null,
        `/archive?refId=${REFID}&dataType=LABEL`
      );
      dispatch(setBrowserHistoryAction(history));
    };
  }

  return (
    <Container {...containerComponentProps}>
      <ArchiveCard className={cardClassName} {...otherProps}>
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery
            imageUrl={LabelTemplateModel.previewImageUrl(labelTemplate)}
            placeholderIconName={ICONS.TAG.name}
            refId={REFID}
          />
          <ArchiveCardMainContent>
            <ArchiveCardHeader type={itemList.length > 1 ? t('LABEL_SET') : t('LABEL')} />
            <ArchiveCardTitle className={styles.labelType}>
              <LabelTemplateName labelTemplate={labelTemplate} />
            </ArchiveCardTitle>
            <Tag block className={styles.timeSinceTag}>
              {formatDateRelative(new Date(label.createdDate), null, locale, { addSuffix: true })}
            </Tag>
            <div className={styles.productDetailsRow}>
              <Tag invert>{product.REFID}</Tag>
              <Text className={styles.productName} size='xs'>
                {ProductModel.localizedNameForProduct(product, locale)}
              </Text>
            </div>
            <Tag block>{languageCode}</Tag>
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow>
            {itemList.length === 1
              ? t('LABEL_ARCHIVE_CARD_1_OKID_SERIAL')
              : t('LABEL_ARCHIVE_CARD_X_OKID_SERIALS', { data: { number: itemList.length } })}
          </ArchiveCardFooterRow>
          <ArchiveCardFooterRow>
            <LabelTemplateFeaturesString labelTemplate={labelTemplate} />
          </ArchiveCardFooterRow>
        </ArchiveCardFooter>
      </ArchiveCard>
    </Container>
  );
}

LabelArchiveCard.propTypes = {
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.object.isRequired,
  linkToLabel: PropTypes.bool,
};
