import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';

import Text from 'OK/components/text';
import Tooltip from 'OK/components/tooltip';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import { baseTheme } from 'OK/styles/theme';
import { formatNumber } from 'OK/util/formatting';
import limitNumberToRange from 'OK/util/functions/limitNumberToRange';

/**
 * Component which allows the user to drag between a min and max value.
 *
 * @param {object} props
 * @param {string} [props.activeBarClassName] Add a class to the slider's active bar.
 * @param {string} [props.barClassName] Add a class to the slider's bar.
 * @param {string} [props.className] The component's class.
 * @param {string} [props.handleClassName] Add a class to the slider's handle.
 * @param {number} props.max The maximum value.
 * @param {number} props.min The minimum value.
 * @param {(newValue) => void} props.onChange Event handler for value changes.
 * @param {boolean} [props.persistValueTooltip=false] Always display the value tooltip.
 * @param {boolean} [props.showMinAndMaxValues=false] Show the min & max values below the slider.
 * @param {boolean} [props.showValueInTooltip=false] Show the value in a tooltip below the slider.
 * @param {number} [props.step=1] Allow selecting values in a specified increment.
 *
 * For example, if `min` is 10, `max` is 30, and
 * `step` is 10, the only selectable values will be 10, 20, and 30.
 * @param {(value) => Node} [props.tooltipValueRender] Custom render function for the value displayed in the tooltip.
 * Only applicable when `showValueInTooltip` is `true`. If not specified, the value will simply be rendered as-is.
 * @param {number} props.value The current value.
 */
export default function Slider(props) {
  /* Variables */

  const {
    activeBarClassName,
    barClassName,
    className,
    handleClassName,
    max,
    min,
    onChange,
    persistValueTooltip = false,
    showMinAndMaxValues = false,
    showValueInTooltip = false,
    step = 1,
    tooltipValueRender,
    value,
  } = props;

  const hideTooltipRef = useRef();
  const sliderRect = useRef();
  const sliderRef = useRef();

  /* State */

  const [listenToMouseEvents, setListenToMouseEvents] = useState(false);
  const [renderTooltip, setRenderTooltip] = useState(persistValueTooltip);

  /* Methods */

  /** Calculate new value based on x position of user's interaction */
  const calculateNewValue = useCallback(
  (xPosition) => {
    const newPercentFilled = limitNumberToRange(
      (xPosition - sliderRect.current.x) / sliderRect.current.width,
      0,
      1
    );
    const newValuePrecise = newPercentFilled * (max - min) + min; // Calculate value within the range (min to max)
    
    const remainder = (newValuePrecise - min) % step;
    let newValue;
    if (remainder !== 0) {
      // Limit value to increments defined by `step` prop.
      const roundUp = remainder / step >= 0.5;
      newValue = newValuePrecise - remainder + (roundUp ? step : 0);
    } else {
      newValue = newValuePrecise;
    }
    
    newValue = limitNumberToRange(newValue, min, max);
    onChange(newValue);
  },
  [max, min, onChange, step]
);

  const hideTooltip = useCallback(() => {
    if (renderTooltip && !persistValueTooltip) {
      hideTooltipRef.current = setTimeout(() => {
        setRenderTooltip(false);
      }, baseTheme.timing.timingLongMs);
    }
  }, [persistValueTooltip, renderTooltip]);

  const showTooltip = useCallback(() => {
    if (showValueInTooltip) {
      setRenderTooltip(true);
      if (hideTooltipRef.current) {
        clearTimeout(hideTooltipRef.current);
      }
    }
  }, [showValueInTooltip]);

  /* Event handlers */

  const onMouseDown = useCallback(
    (e) => {
      setListenToMouseEvents(true);
      calculateNewValue(e.clientX);
    },
    [calculateNewValue]
  );

  const onMouseLeave = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  const onMouseMove = useCallback(
    (e) => {
      calculateNewValue(e.clientX);
    },
    [calculateNewValue]
  );

  const onMouseOver = useCallback(() => {
    showTooltip();
  }, [showTooltip]);

  const onMouseUp = useCallback(() => {
    setListenToMouseEvents(false);
  }, []);

  const onTouchEnd = useCallback(() => {
    setListenToMouseEvents(false);
    hideTooltip();
  }, [hideTooltip]);

  const onTouchMove = useCallback(
    (e) => {
      const touch = e.targetTouches[0];
      calculateNewValue(touch.clientX);
    },
    [calculateNewValue]
  );

  const onTouchStart = useCallback(
    (e) => {
      setListenToMouseEvents(true);
      const touch = e.targetTouches[0];
      calculateNewValue(touch.clientX);
      showTooltip();
    },
    [calculateNewValue, showTooltip]
  );

  /* Effects */

  // Store updated value of slider size and position
  useEffect(() => {
    sliderRect.current = sliderRef.current.getBoundingClientRect();
  });

  // Listen & unlisten to mouse events
  useEffect(() => {
    const sliderElement = sliderRef.current;
    const scrollEventListenerOptions = { passive: true };
    sliderElement.addEventListener('mousedown', onMouseDown);
    sliderElement.addEventListener('touchend', onTouchEnd);
    sliderElement.addEventListener('touchstart', onTouchStart, scrollEventListenerOptions);

    if (listenToMouseEvents) {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      sliderElement.addEventListener('touchmove', onTouchMove, scrollEventListenerOptions);
      sliderElement.addEventListener('mouseleave', onMouseLeave);
      sliderElement.addEventListener('mouseover', onMouseOver);
    }

    return function cleanup() {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      sliderElement.removeEventListener('touchmove', onTouchMove);
      sliderElement.removeEventListener('mouseleave', onMouseLeave);
      sliderElement.removeEventListener('mouseover', onMouseOver);
      sliderElement.removeEventListener('mousedown', onMouseDown);
      sliderElement.removeEventListener('touchend', onTouchEnd);
      sliderElement.removeEventListener('touchstart', onTouchStart);
    };
  }, [
    listenToMouseEvents,
    onMouseDown,
    onMouseLeave,
    onMouseMove,
    onMouseOver,
    onMouseUp,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
  ]);

  /* Render */

  // Container classes
  let classNames = '';
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  // Bar classes
  let barClassNames = styles.barContainer;
  if (barClassName) {
    barClassNames = `${barClassNames} ${barClassName}`;
  }

  // Active bar classes
  let activeBarClassNames = styles.positionBar;
  if (activeBarClassName) {
    activeBarClassNames = `${activeBarClassNames} ${activeBarClassName}`;
  }

  // Handle classes
  let handleClassNames = styles.handle;
  if (handleClassName) {
    handleClassNames = `${handleClassNames} ${handleClassName}`;
  }

  const percentFilled = ((value-min) / (max-min)/step) * 100;

  return (
    <div className={classNames} ref={sliderRef}>
      <div className={barClassNames}>
        <div className={activeBarClassNames} style={{ width: `${percentFilled}%` }}>
          <div className={handleClassNames}>
            <FadeInOutTransition
              appear={persistValueTooltip}
              in={renderTooltip}
              fadeDuration={baseTheme.timing.timingInstantMs}
              timeout={baseTheme.timing.timingInstantMs}
            >
              <Tooltip className={styles.valueTooltip} position='below'>
                {tooltipValueRender ? tooltipValueRender(value) : formatNumber(value)}
              </Tooltip>
            </FadeInOutTransition>
          </div>
        </div>
      </div>
      {showMinAndMaxValues && (
        <div className={styles.minMaxValues}>
          <Text bold className={styles.minMaxValue}>
            {formatNumber(min)}
          </Text>
          <Text bold className={styles.minMaxValue}>
            {formatNumber(max)}
          </Text>
        </div>
      )}
    </div>
  );
}

Slider.propTypes = {
  activeBarClassName: PropTypes.string,
  barClassName: PropTypes.string,
  className: PropTypes.string,
  handleClassName: PropTypes.string,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  persistValueTooltip: PropTypes.bool,
  showMinAndMaxValues: PropTypes.bool,
  showValueInTooltip: PropTypes.bool,
  step: PropTypes.number,
  tooltipValueRender: PropTypes.func,
  value: PropTypes.number.isRequired,
};
