import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
  ArchiveCardTitle,
} from 'OK/components/archiveCard';
import ArchiveCardManagerOptions from 'OK/components/archiveCard/managerOptions';
import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import LinkToSite from 'OK/components/link/linkToSite';
import Stepper from 'OK/components/stepper';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import isAuthorised from 'OK/util/functions/isAuthorised';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';
import useLocalizedObject from 'OK/util/hooks/useLocalizedObject';

export default function SiteArchiveCard(props) {
  /* Variables */

  const {
    allowReorderLeft = false,
    allowReorderRight = false,
    cardClassName,
    className,
    onChangePublished,
    onChangeQuantity,
    onClickApprove,
    onClickLink,
    onClickReject,
    onClickReorder,
    onClickScan,
    onClickUnlink,
    linkPublished = false,
    linkToArchive = true,
    linkToEditPage = false,
    managerOptionsError,
    site,
    quantity,
    showApproveButton = false,
    showLinkButton = false,
    showPending = false,
    showPublishOption = false,
    showRejectButton = false,
    showScanItemButton = false,
    showUnlinkOption = false,
    ...otherProps
  } = props;
  const {
    conformityPoint,
    OKID,
    organisation,
    organisationId,
    reliabilityPointForPublishedLogs,
    siteChildSiteList,
    siteMediaAssetList,
  } = site;
  const { t } = useI18n();
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const authorisationLevel = useAuthorisationLevel(site);
  const isOwnedByActiveOrganisation = activeOrganisationId === organisationId;
  const dispatch = useDispatch();

  /* Render */

  // Tag
  let tag;
  if (isOwnedByActiveOrganisation) {
    tag = (
      <Tag block={false} invert>
        <Icon className={styles.myOrganisationTagIcon} height={12} inline name={ICONS.ORGANISATION.name} width={12} />
      </Tag>
    );
  } else if (organisation?.addressList?.length) {
    tag = <Tag block={false}>{organisation.addressList[0].countryCode}</Tag>;
  }

  // Media
  const flattenedMedia = useMemo(() => {
    return siteMediaAssetList?.map((sm) => sm.mediaAsset);
  }, [siteMediaAssetList]);

  // Name
  const name = useLocalizedObject(site.name, true)?.text;

  // Parts
  let partsString;
  if (siteChildSiteList?.length > 0) {
    partsString =
      siteChildSiteList.length === 1
        ? t('PRODUCT_ARCHIVE_CARD_1_PART')
        : t('PRODUCT_ARCHIVE_CARD_X_PARTS', { data: { number: siteChildSiteList.length } });
  }

  // Quantity options
  const showQuantity = typeof quantity !== 'undefined';
  const quantityEditable = typeof onChangeQuantity === 'function';
  const quantitySectionClassName = !quantityEditable ? styles.quantityFlex : null;

  const Container = linkToArchive ? LinkToSite : 'div';
  const containerProps = { className };
  if (linkToArchive) {
    containerProps.linkToEditPage = linkToEditPage && isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER);
    containerProps.site = site;
    containerProps.onClick = (e) => {
      e.preventDefault();
      history.pushState({ isPopup: true, refId: null, dataType: 'SITE', OKID: OKID }, null, `/archive/site/${OKID}`);
      dispatch(setBrowserHistoryAction(history));
    };
  }

  return (
    <Container {...containerProps}>
      <ArchiveCard className={cardClassName} {...otherProps}>
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery media={flattenedMedia} placeholderIconName={ICONS.PRODUCT.name} />
          <ArchiveCardMainContent>
            <ArchiveCardHeader tag={tag} type='Site' />
            <ArchiveCardTitle>{name}</ArchiveCardTitle>
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow conformityGrade={conformityPoint} reliabilityGrade={reliabilityPointForPublishedLogs}>
            {partsString}
          </ArchiveCardFooterRow>
          <ArchiveCardFooterRow innerClassName={styles.organisationFooterRow} okid={OKID} />
        </ArchiveCardFooter>
        {(allowReorderLeft ||
          allowReorderRight ||
          showQuantity ||
          showPublishOption ||
          showUnlinkOption ||
          showApproveButton ||
          showRejectButton ||
          showLinkButton ||
          showScanItemButton) && (
          <ArchiveCardManagerOptions
            allowReorderLeft={allowReorderLeft}
            allowReorderRight={allowReorderRight}
            error={managerOptionsError}
            onChangePublished={onChangePublished}
            onClickReorder={onClickReorder}
            onClickUnlink={onClickUnlink}
            published={linkPublished}
            showPublishOption={showPublishOption}
            showUnlinkOption={showUnlinkOption}
          >
            {showPending && (
              <div>
                <Text tint='notification'>{t('PRODUCT_ARCHIVE_CARD_AWAITING_CONFIRMATION')}</Text>
              </div>
            )}
            {showQuantity && (
              <div className={quantitySectionClassName}>
                <Text bold className={styles.quantityLabel}>
                  {t('PRODUCT_ARCHIVE_CARD_QUANTITY_LABEL')}
                </Text>
                {quantityEditable ? (
                  <Stepper className={styles.quantityInput} minValue={1} onChange={onChangeQuantity} value={quantity} />
                ) : (
                  <Text className={styles.quantityValue}>{quantity}</Text>
                )}
              </div>
            )}
            {showLinkButton && (
              <Button block className={styles.manageButton} onClick={onClickLink} tint='creation'>
                {t('PRODUCT_ARCHIVE_CARD_LINK')}
              </Button>
            )}
            {showApproveButton && (
              <Button block className={styles.manageButton} onClick={onClickApprove} tint='creation'>
                {t('PRODUCT_ARCHIVE_CARD_APPROVE')}
              </Button>
            )}
            {showRejectButton && (
              <Button block className={styles.manageButton} linkStyle onClick={onClickReject} tint='alert'>
                {t('PRODUCT_ARCHIVE_CARD_REJECT')}
              </Button>
            )}
            {showScanItemButton && (
              <Button
                block
                className={styles.manageButton}
                icon={ICONS.SCAN.name}
                onClick={onClickScan}
                tint='creation'
              >
                {t('PRODUCT_ARCHIVE_CARD_SCAN_ITEM_BUTTON')}
              </Button>
            )}
          </ArchiveCardManagerOptions>
        )}
      </ArchiveCard>
    </Container>
  );
}

SiteArchiveCard.propTypes = {
  allowReorderLeft: PropTypes.bool,
  allowReorderRight: PropTypes.bool,
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  linkPublished: PropTypes.bool,
  linkToArchive: PropTypes.bool,
  linkToEditPage: PropTypes.bool,
  managerOptionsError: PropTypes.string,
  onChangePublished: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  onClickApprove: PropTypes.func,
  onClickLink: PropTypes.func,
  onClickReject: PropTypes.func,
  onClickReorder: PropTypes.func,
  onClickScan: PropTypes.func,
  onClickUnlink: PropTypes.func,
  site: PropTypes.object,
  quantity: PropTypes.number,
  showApproveButton: PropTypes.bool,
  showLinkButton: PropTypes.bool,
  showPending: PropTypes.bool,
  showPublishOption: PropTypes.bool,
  showRejectButton: PropTypes.bool,
  showUnlinkOption: PropTypes.bool,
  showScanItemButton: PropTypes.bool,
};
