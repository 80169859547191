import { gql } from '@apollo/client';

export default class BaseUserDocumentModel {
  static GRAPHQL_TYPE = 'UserDocumentAsset';

  static fragmentName = 'UserDocument_Fragment';
  static fragment = gql`
    fragment ${BaseUserDocumentModel.fragmentName} on ${BaseUserDocumentModel.GRAPHQL_TYPE} {
      applicableMarketList
      coveredLanguageList
      createdBy
      createdDate
      documentName
      documentNumber
      documentType
      documentURL
      userDocumentStandardList
      id
      issueDate
      lastModifiedBy
      lastModifiedDate
      metadata
      madeByOrganisationId
      status
      version
      organisationPublishStatus
      publishStatus
      sourceId
      source
      validFrom
      validTill
    }
  `;

  static fragmentNamePublishError = 'UserDocument_PublishError_Fragment';
  static fragmentPublishError = gql`
    fragment ${BaseUserDocumentModel.fragmentNamePublishError} on ${BaseUserDocumentModel.GRAPHQL_TYPE} {
      publishError @client
    }
  `;
}
