// import { BarcodeScanner, EnumGrayscaleTransformationMode } from 'dynamsoft-javascript-barcode';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import FocusLayout from 'OK/components/layouts/focus';
import Toast from 'OK/components/toast';
import AppConfig from 'OK/config/app';
import { dismissScannerAction, scannedOKIDAction } from 'OK/state/app/actions';
import { baseTheme } from 'OK/styles/theme';
import { formatUrl } from 'OK/util/formatting';
import { isOrganisationOKID, isOKID, parseOKID, isCoworkerOKID } from 'OK/util/functions/OKID';
import { isOKPlatformURL } from 'OK/util/functions/url';
import useBrowserRenderOnly from 'OK/util/hooks/useBrowserRenderOnly';
import useI18n from 'OK/util/hooks/useI18n';
import { I18n } from 'OK/util/i18n';

let Dynamsoft;
async function loadDynamsoft() {
  if (!Dynamsoft) {
    Dynamsoft = await import('dynamsoft-javascript-barcode');
    Dynamsoft.BarcodeScanner.license = process.env.NEXT_PUBLIC_DYNAMSOFT_BARCODE_LICENSE_KEY;
    Dynamsoft.BarcodeScanner.engineResourcePath = `https://${AppConfig.domain}/scanEngine`;
    okdebug('loaded Dynamsoft async');
  }
  return Dynamsoft;
}

const menuHeight = 44;
const scanGuidelinesDimensions = 102;

export default function Scan(props) {
  const { instructions, onScan } = props;
  const render = props.in === true;

  // Variables
  const dispatch = useDispatch();
  const renderForBrowser = useBrowserRenderOnly();
  const router = useRouter();
  const screenSize = useSelector((state) => state.app.screenSize);
  const { t } = useI18n();

  // State
  const [availableCameras, setAvailableCameras] = useState([]);
  const [cameraId, _setCameraId] = useState(null);
  const [error, _setError] = useState(null);
  const [errorTimeout, setErrorTimeout] = useState(null);
  const [instructionsHeight, setInstructionsHeight] = useState(0);
  const [scannerHeightPx, setScannerHeightPx] = useState(0);
  const [scannerLeftPx, setScannerLeftPx] = useState(0);
  const [scannerTopPx, setScannerTopPx] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [toastDimensions, setToastDimensions] = useState({ height: 0, width: 0 });

  // Refs
  const instructionsRef = useRef(null);
  const qrReaderRef = useRef(null);
  const toastRef = useRef(null);
  const scannerRef = useRef();

  // Functions

  const closeScanner = () => dispatch(dismissScannerAction());

  const stopScanning = useCallback(() => {
    scannerRef?.current?.close();
    okdebug('destroyed scanner');
  }, []);

  const dismiss = useCallback(() => {
    stopScanning();
    dispatch(dismissScannerAction());
  }, [dispatch, stopScanning]);

  // const detectCameras = async () => {
  //   try {
  //     await navigator.mediaDevices.getUserMedia({ video: true });
  //     const devices = await navigator.mediaDevices.enumerateDevices();
  //     okdebug('Media devices: ', devices);
  //     const videoSources = devices.filter((d) => d.kind === 'videoinput');
  //     setAvailableCameras(videoSources);
  //     // Default to back camera if detected
  //     const backCamera = devices.find((d) => /back|environment/i.test(d.label));
  //     if (backCamera) {
  //       setCameraId(backCamera.deviceId);
  //     } else if (videoSources.length) {
  //       setCameraId(videoSources[0].deviceId);
  //     }
  //   } catch (e) {
  //     okerror('Error getting camera access.');
  //     okerror(e);
  //   }
  // };

  const setCameraId = useCallback((id) => {
    _setCameraId(id);
    // setPersistentValue(PERSISTENT_STORAGE_KEY.LAST_USED_CAMERA_DEVICE_ID, id);
  }, []);

  const setError = useCallback(
    (errorMessage) => {
      _setError(errorMessage);

      // Clear error automatically after 10 seconds
      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      const _errorTimeout = setTimeout(() => {
        _setError(null);
      }, 10000);
      setErrorTimeout(_errorTimeout);
    },
    [errorTimeout]
  );

  const handleScan = useCallback(
    (result) => {
      if (result) {
        okdebug('Scanned result:', result);
        let parsedResult;
        const OKID = parseOKID(result);
        if (OKID) {
          parsedResult = OKID;
        } else {
          parsedResult = result;
        }
        okdebug('Parsed result:', parsedResult);
        // dispatch(dismissScannerAction());
        if (onScan) {
          // Custom behavior
          onScan(parsedResult);
        } else {
          // Default behavior
          if (isOrganisationOKID(parsedResult)) {
            // Go to organisation page
            router.push(`/organisation/${parsedResult}`);
          } else if (isCoworkerOKID(parsedResult)) {
            // Go to coworker page
            router.push(`/user/${parsedResult}`);
          } else if (isOKPlatformURL(parsedResult)) {
            // Go to page
            const platformUrl = formatUrl(parsedResult, { showProtocol: true });
            router.push(platformUrl);
          } else {
            // Show scan results
            dispatch(scannedOKIDAction(parsedResult));
          }
          if (AppConfig.features.alertScannedCode) {
            alert(`Scanned "${parsedResult}"`);
          }
        }
        dismiss();
      }
    },
    [dismiss, dispatch, onScan, router]
  );

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const search = (e) => {
    e.preventDefault();

    if (isOKID(searchQuery)) {
      handleScan(searchQuery);
    } else {
      const url = `/search?q=${encodeURIComponent(searchQuery)}`;
      router.push({ pathname: url, query: { q: searchQuery } }, url);
      closeScanner();
    }
  };

  const toggleCamera = () => {
    if (availableCameras.length < 2) {
      return;
    }

    const currentCameraIndex = availableCameras.findIndex((c) => c.deviceId === cameraId);
    let newCameraIndex;
    if (currentCameraIndex === availableCameras.length - 1) {
      newCameraIndex = 0;
    } else {
      newCameraIndex = currentCameraIndex + 1;
    }

    const newCamera = availableCameras[newCameraIndex];
    setCameraId(newCamera.deviceId);
    scannerRef.current?.setCurrentCamera(newCamera.deviceId);
  };

  /* Effects */

  // Pre-load scanner
  useEffect(() => {
    loadDynamsoft()
      .then((Dynamsoft) => {
        Dynamsoft.BarcodeScanner.loadWasm().catch((e) => {
          okdebug('Error preloading scanner', e);
        });
      })
      .catch((e) => {
        okdebug('Error loading Dynamsoft.', e);
      });
  }, []);

  // Setup scanner
  useEffect(() => {
    let scanner;
    const setup = async () => {
      const Dynamsoft = await loadDynamsoft();
      scanner = await Dynamsoft.BarcodeScanner.createInstance();
      okdebug('setup scanner');
      scannerRef.current = scanner;
      scanner.onUniqueRead = handleScan;
      scanner.setUIElement(qrReaderRef.current);
      scanner.barcodeFillStyle = 'rgba(0, 134, 86, 0.7)';
      scanner.barcodeStrokeStyle = 'rgba(0, 134, 86, 1)';
      scanner.ifSaveLastUsedCamera = true;
      scanner.setVideoFit('cover');
      const runtimeSettings = await scanner.getRuntimeSettings();
      runtimeSettings.furtherModes.grayscaleTransformationModes[1] =
        Dynamsoft.EnumGrayscaleTransformationMode.GTM_INVERTED;
      scanner.updateRuntimeSettings(runtimeSettings);
      await scanner.show();
      scanner
        .getAllCameras()
        .then((detectedCameras) => {
          setAvailableCameras(detectedCameras);
        })
        .catch(() => {
          setError('Error detecting cameras.');
        });
      scanner
        .getCurrentCamera()
        .then((cam) => {
          _setCameraId(cam.deviceId);
        })
        .catch(() => {
          setError('Error detecting current camera.');
        });
    };
    const reset = () => {
      stopScanning();
    };

    if (render) {
      setup();
    }

    return function () {
      if (render) {
        reset();
      }
    };
  }, [handleScan, render, setError, stopScanning]);

  // Determine scanner preview size so it fits full screen minus other UI elements. Then convert that to padding top
  // and set it inline.
  // useLayoutEffect(() => {
  //   if (availableCameras.length) {
  //     const _instructionsHeight = instructionsRef.current.clientHeight;
  //     setInstructionsHeight(_instructionsHeight);
  //     // Full height minus top menu and instructions
  //     const _scannerHeightPx = screenSize.height - menuHeight - _instructionsHeight;
  //     setScannerHeightPx(_scannerHeightPx);

  //     if (toastRef.current) {
  //       // Keep toast dimensions updated for positioning calculations
  //       const dimensions = toastRef.current.getBoundingClientRect();
  //       setToastDimensions({ height: dimensions.height, width: dimensions.width });
  //     }
  //   }
  // }, [availableCameras, error, toastDimensions.height, toastDimensions.width, screenSize.height, screenSize.width]);

  /* Render */

  const hasCameras = availableCameras.length > 0;

  // If toast is taller than the space under the scan guidelines + required margin, then shift it to the side
  const hasSpaceForToastBelowScan =
    (scannerHeightPx - scanGuidelinesDimensions) / 2 > toastDimensions.height + baseTheme.spacing.contentMarginMd * 2;
  // If shifted, set toast max width to the available space to the right of the scan guidelines
  const toastMaxWidth = hasSpaceForToastBelowScan
    ? null
    : (screenSize.width - scanGuidelinesDimensions) / 2 - baseTheme.spacing.contentMarginMd * 2;
  // Set how far from the bottom the toast should be based on position
  const toastContainerStyle = hasSpaceForToastBelowScan
    ? { bottom: instructionsHeight + baseTheme.spacing.contentMarginMd } // Above instructions
    : { bottom: instructionsHeight + scannerHeightPx / 2 - toastDimensions.height / 2 }; // Midpoint of scanner

  const scanInstructions = instructions || (
    <p>
      <I18n i18nKey='SCAN_OR_SEARCH_OKID_ANY'>
        <strong>
          Scan (or{' '}
          <Button className={styles.searchLink} linkStyle onClick={() => setShowSearch(true)}>
            search
          </Button>{' '}
          for) the OK ID ™
        </strong>{' '}
        of an item, organisation, or coworker.
      </I18n>
    </p>
  );

  const headerCustomView = hasCameras && (
    <>
      {availableCameras.length > 1 && (
        <Button className={styles.cycleCamerasButton} linkStyle icon='/icons/camera_switch.svg' onClick={toggleCamera}>
          {t('CYCLE_CAMERA')}
        </Button>
      )}
      <Button className={styles.reportButton} linkStyle style={{ opacity: 0 }} tint='alert'>
        Report
      </Button>
    </>
  );

  if (!render) {
    return null;
  }

  return (
    <div className={styles.outerContainer}>
      <FocusLayout customHeaderView={headerCustomView} onBack={closeScanner} showBackButton showFooter={false}>
        <div className={styles.container}>
          <div className={styles.scannerContainer}>
            {renderForBrowser && (
              <div style={{ /* display: hasCameras ? 'block' : 'none' */ height: '100%' }}>
                <div
                  className={`scanner ${styles.scanner}`}
                  id='scanner'
                  ref={qrReaderRef}
                  style={{
                    left: scannerLeftPx,
                    top: scannerTopPx,
                  }}
                >
                  <div className='dce-video-container' />
                </div>
                {showSearch && (
                  <div className={styles.search}>
                    <form onSubmit={search}>
                      <SearchInput
                        className={styles.searchInput}
                        onCancel={() => setShowSearch(false)}
                        onChange={handleSearch}
                        onClickSearch={search}
                        placeholder={t('SEARCH_OKID_PLACEHOLDER')}
                        showCancelOnFocus
                        showClearButton={false}
                        value={searchQuery}
                      />
                    </form>
                  </div>
                )}
                {!showSearch && scannerRef.current && (
                  <img alt='' className={styles.scanGuidelines} src='/img/scan_guidelines.svg' />
                )}
              </div>
            )}
          </div>
          {hasCameras ? (
            <>
              <ContentLayout noPadding>
                <div className={styles.instructionsContainer} ref={instructionsRef}>
                  <div className={styles.instructionsIcon}>
                    <img alt={t('IMG_ALT_OKID_EXAMPLE')} height='44' src='/img/okid_example.png' width='44' />
                  </div>
                  <div className={styles.instructions}>{scanInstructions}</div>
                </div>
              </ContentLayout>
            </>
          ) : (
            <ContentLayout className={styles.noCameraContainer} contentClassName={styles.noCameraContent}>
              <TextLayout>
                {scannerRef.current ? (
                  <p>{t('ERROR_NO_CAMERA_ACCESS')}</p>
                ) : (
                  <Icon name={ICONS.SPINNER.name} height={64} width={64} />
                )}
              </TextLayout>
            </ContentLayout>
          )}
          {error && (
            <div className={styles.toastContainer} style={toastContainerStyle}>
              <Toast
                className={`${styles.toast} ${!hasSpaceForToastBelowScan ? styles.alignSide : ''}`}
                style={{ maxWidth: toastMaxWidth || 'none' }}
                ref={toastRef}
                tint='alert'
              >
                {error}
              </Toast>
            </div>
          )}
        </div>
      </FocusLayout>
    </div>
  );
}

Scan.propTypes = {
  in: PropTypes.bool.isRequired,
  instructions: PropTypes.node,
  onScan: PropTypes.func,
};

/** Resources to preload */
export const preload = (
  <>
    <link rel='prefetch' href='/icons/camera_switch.svg' as='image' type='image/svg+xml' />
    <link rel='prefetch' href='/img/okid_example.png' as='image' type='image/png' />
    <link rel='prefetch' href='/img/scan_guidelines.svg' as='image' type='image/svg+xml' />
    <link rel='prefetch' href='/scanEngine/dbr-9.6.11.full.wasm' as='fetch' type='wasm' />
    <link rel='prefetch' href='/scanEngine/dbr-9.6.11.full.wasm.js' as='script' type='text/javascript' />
    <link rel='prefetch' href='/scanEngine/dbr-9.6.11.browser.worker.js' as='worker' type='text/javascript' />
    <link rel='prefetch' href='/scanEngine/dbr.ui.html' as='document' type='text/html' />
  </>
);
