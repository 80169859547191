import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import { ResultRow, ResultTable } from 'OK/components/inspectionLog/resultTable';
import Text from 'OK/components/text';
import { roundAndFormatPercentages } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

function formatPercentage(percentage) {
  if (percentage === '0.0%') {
    return '-';
  }

  return percentage;
}

export default function InspectionLogSampleResults(props) {
  const {
    fixed,
    passed,
    percentFixed,
    percentFlawless,
    percentRejected,
    percentTolerated,
    rejected,
    sampleSize,
    tolerated,
    useConditionalUI = false,
    ...otherProps
  } = props;
  const { t } = useI18n();

  const [percentFlawlessRounded, percentFixedRounded, percentToleratedRounded, percentRejectedRounded] =
    roundAndFormatPercentages(percentFlawless, percentFixed, percentTolerated, percentRejected);

  return (
    <div {...otherProps}>
      <h4 className={styles.header}>{t('LOG_SAMPLED_ITEMS')}</h4>
      <Text>{t('LOG_SAMPLED_ITEMS_DESCRIPTION')}</Text>
      <ResultTable>
        <tbody>
          <ResultRow
            count={passed}
            percentage={formatPercentage(percentFlawlessRounded)}
            type={useConditionalUI ? 'OK' : 'FLAWLESS'}
          />
          {!useConditionalUI && (
            <>
              <ResultRow
                addBottomMargin
                count={fixed}
                percentage={formatPercentage(percentFixedRounded)}
                type='FIXED'
              />
              <ResultRow count={tolerated} percentage={formatPercentage(percentToleratedRounded)} type='TOLERATED' />
            </>
          )}
          <ResultRow
            addBottomMargin
            count={rejected}
            percentage={formatPercentage(percentRejectedRounded)}
            type={useConditionalUI ? 'NOT_OK' : 'REJECTED'}
          />
          <ResultRow bold count={sampleSize} type='TESTED' />
        </tbody>
      </ResultTable>
    </div>
  );
}

InspectionLogSampleResults.propTypes = {
  fixed: PropTypes.number.isRequired,
  passed: PropTypes.number.isRequired,
  percentFixed: PropTypes.number.isRequired,
  percentFlawless: PropTypes.number.isRequired,
  percentRejected: PropTypes.number.isRequired,
  percentTolerated: PropTypes.number.isRequired,
  rejected: PropTypes.number.isRequired,
  sampleSize: PropTypes.number.isRequired,
  tolerated: PropTypes.number.isRequired,
  useConditionalUI: PropTypes.bool,
};
