import { useEffect, useState } from 'react';

import DocumentModel from 'OK/models/document';
import useI18n from 'OK/util/hooks/useI18n';

export default function useDocumentStandards() {
  const { t } = useI18n();
  const [standards, setStandards] = useState([]);

  useEffect(() => {
    async function loadStandards() {
      DocumentModel.getStandards()
        .then((result) => {
          const translatedStandards = result.map((s) => {
            return {
              typeId: s.typeId,
              title: t(`DOCUMENT_STANDARD_${s.typeId}`),
            };
          });
          setStandards(translatedStandards);
        })
        .catch((e) => {
          okerror('Error loading document standards.', e);
        });
    }

    loadStandards();
  }, [t]);

  return standards;
}
