import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './styles.module.scss';

import { baseTheme } from 'OK/styles/theme';

/**
 * Transition between open and closed states.
 *
 * @param {object} props
 * @param {Node} props.children
 * @param {string} [props.className] The class for the content container.
 * @param {boolean} props.open Render the open state.
 */
export default function OpenCloseTransition(props) {
  /* Variables */

  const { children, className, closedHeight = 0, open, ...otherProps } = props;
  const contentRef = useRef();

  // State
  const [height, setHeight] = useState(open ? 'auto' : closedHeight);
  const [lastKnownHeight, setLastKnownHeight] = useState('auto');

  /* Methods */

  const getCurrentHeight = () => parseInt(getComputedStyle(contentRef.current).height, 10);

  // Event handlers

  const onEntered = () => {
    setHeight('auto');
  };

  const onExiting = () => {
    setHeight(closedHeight);
  };

  const onWillEnter = () => {
    setHeight(lastKnownHeight);
  };

  const onWillExit = () => {
    const currentHeight = getCurrentHeight();
    setHeight(currentHeight);
  };

  /* Effects */

  // Store last known height before closing so animation works
  useEffect(() => {
    if (!open) {
      const currentHeight = getCurrentHeight();
      setLastKnownHeight(currentHeight);
    }
  }, [open]);

  /* Render */

  // Content classes
  let contentClassNames = styles.contentContainer;
  if (className) {
    contentClassNames = `${contentClassNames} ${className}`;
  }

  return (
    <CSSTransition
      appear
      in={open}
      nodeRef={contentRef}
      onEnter={onWillEnter}
      onEntered={onEntered}
      onExit={onWillExit}
      onExiting={onExiting}
      timeout={baseTheme.timing.timingMediumMs}
      {...otherProps}
    >
      <div className={contentClassNames} ref={contentRef} style={{ height }}>
        {children}
      </div>
    </CSSTransition>
  );
}

OpenCloseTransition.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closedHeight: PropTypes.number,
  open: PropTypes.bool.isRequired,
};
