import { gql } from '@apollo/client';

export default class BaseDocumentModel {
  static GRAPHQL_TYPE = 'DocumentAsset';

  static fragmentName = 'Base_Document_Fragment';
  static fragment = gql`
    fragment ${BaseDocumentModel.fragmentName} on ${BaseDocumentModel.GRAPHQL_TYPE} {
      REFID
      applicableMarketList
      conformityPoint
      coveredLanguageList
      createdBy
      createdDate
      documentName
      documentType
      documentURL
      id
      lastModifiedBy
      lastModifiedDate
      metadata
      organisationId
      status
      version
    }
  `;
}
