import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import SearchInput from '..';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import useI18n from 'OK/util/hooks/useI18n';

export const DISPLAY_MODE = {
  BUTTON: 'BUTTON',
  INPUT: 'INPUT',
};

const SearchInputAsButton = forwardRef((props, forwardedRef) => {
  /* Variables */

  const { t } = useI18n();
  const {
    buttonProps,
    className,
    inputProps,
    onClickClear,
    placeholder = t('SEARCH'),
    selection,
    ...otherProps
  } = props;

  // State

  const [displayMode, setDisplayMode] = useState(DISPLAY_MODE.BUTTON);

  // Refs

  const inputRef = useRef();

  /* Methods */

  const renderAsButton = useCallback(() => {
    setDisplayMode(DISPLAY_MODE.BUTTON);
  }, []);

  const renderAsInput = useCallback(() => {
    setDisplayMode(DISPLAY_MODE.INPUT);
  }, []);

  /* Effects */

  // Focus input when mode changes
  useEffect(() => {
    if (displayMode === DISPLAY_MODE.INPUT) {
      inputRef.current.focus();
    }
  }, [displayMode]);

  /* Render */

  useImperativeHandle(forwardedRef, () => {
    return { setDisplayMode };
  });

  let classNames = styles.container;
  if (className) {
    classNames += ` ${className}`;
  }

  if (displayMode === DISPLAY_MODE.BUTTON) {
    return (
      <div className={classNames}>
        <Button block className={styles.component} invert onClick={renderAsInput} {...buttonProps} {...otherProps}>
          {selection || placeholder}
        </Button>
        {selection ? (
          <Button
            className={styles.clearSelectionButton}
            linkStyle
            onClick={onClickClear}
            style={{ right: buttonProps.icon ? 44 : 0 }}
          >
            {t('CLEAR')}
          </Button>
        ) : null}
      </div>
    );
  }

  return (
    <SearchInput
      className={classNames}
      onCancel={renderAsButton}
      placeholder={placeholder}
      ref={inputRef}
      showCancelOnFocus
      {...inputProps}
      {...otherProps}
    />
  );
});

SearchInputAsButton.propTypes = {
  buttonProps: PropTypes.object,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  onClickClear: PropTypes.func,
  placeholder: PropTypes.string,
  selection: PropTypes.node,
};

export default SearchInputAsButton;
