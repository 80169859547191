import { gql } from '@apollo/client';

export default class BaseTestAsset {
  static GRAPHQL_TYPE = 'TestAsset';

  static fragmentName = 'BaseTestAsset_Fragment';
  static fragment = gql`
    fragment ${BaseTestAsset.fragmentName} on ${BaseTestAsset.GRAPHQL_TYPE} {
      REFID
      createdBy
      createdDate
      enableIdentifiers
      enableNotes
      enableTimeTracking
      howToFindItMap
      howToFixItMap
      id
      lastModifiedBy
      lastModifiedDate
      organisationId
      status
      testSeverityLevel
      version
      whatToLookForMap
      criticalAQL
      criticalFailure
      criticalInspected
      majorAQL
      majorFailure
      majorInspected
      minorAQL
      minorFailure
      minorInspected
      publishedLogs
      reliabilityPointForAllLogs
      reliabilityPointForPublishedLogs
      totalLogs
    }
  `;
}
