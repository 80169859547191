import { gql } from '@apollo/client';

import { AudioMediaAssetModel, ImageMediaAssetModel, VideoMediaAssetModel } from 'OK/models/mediaAsset';

/* Queries */

export const LabelArchiveQuery = gql`
  query GetLabelByREFID($REFID: String!) {
    label: labelWithREFID(REFID: $REFID) {
      id
      assetAccessPermission
      assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      itemList {
        id
        OKID
      }
      labelTemplate {
        corners
        eyelet
        id
        languageCode
        material
        size
        type
      }
      createdDate
      organisationId
      product {
        id
        conformityPoint
        name
        organisation {
          addressList {
            countryCode
          }
          conformityPoint
          name
          reliabilityPointForPublishedLogs
          sustainabilityPoint
        }
        productChildProductList {
          publishStatus
        }
        productMediaAssetList {
          mediaAsset {
            id
            audioData {
              audioURL
            }
            imageData {
              baseImageURL
              imageName
              imageURL
            }
            mediaType
            videoData {
              videoURL
            }
          }
        }
        reliabilityPointForPublishedLogs
        REFID
        SKUID
      }
      REFID
    }
  }
`;

export const getLabelItemsQuery = gql`
  query GetLabelByREFID($REFID: String!) {
    label: labelWithREFID(REFID: $REFID) {
      createdDate
      id
      REFID
      itemList {
        id
        OKID
        reliabilityPointForPublishedLogs
        product {
          conformityPoint
          name
          organisation {
            addressList {
              countryCode
            }
            id
          }
          REFID
        }
      }
      labelTemplate {
        corners
        eyelet
        id
        languageCode
        material
        size
        type
      }
      product {
        name
        organisation {
          name
          OKID
        }
        REFID
        productMediaAssetList {
            mediaAsset {
              audioData {
                ...${AudioMediaAssetModel.fragmentName}
              }
              id
              imageData {
                ...${ImageMediaAssetModel.fragmentName}
              }
              mediaType
              videoData {
                ...${VideoMediaAssetModel.fragmentName}
              }
            }
          }
      }
    }
  }
  ${AudioMediaAssetModel.fragment}
  ${ImageMediaAssetModel.fragment}
  ${VideoMediaAssetModel.fragment}
`;

/* Mutations */

export const createLabelForItemMutation = gql`
  mutation CreateLabelForOKID($itemId: String!, $labelTemplateId: String!, $productId: String!) {
    label: createLabelForItem(itemId: $itemId, labelTemplateId: $labelTemplateId, productId: $productId) {
      id
      REFID
    }
  }
`;

export const createLabelFromExistingLabelMutation = gql`
  mutation CreateLabelFromExistingLabel($labelId: String!, $labelTemplateId: String!) {
    label: createLabelFromExistingLabel(labelId: $labelId, labelTemplateId: $labelTemplateId) {
      id
      REFID
    }
  }
`;

export const createLabelWithNewOKIDsMutation = gql`
  mutation CreateLabelWithNewOKIDs($labelTemplateId: String!, $numberOfOKID: Int!, $productId: String!) {
    label: createLabelForNewOKID(
      labelTemplateId: $labelTemplateId
      numberOfOKID: $numberOfOKID
      productId: $productId
    ) {
      id
      REFID
    }
  }
`;

export const setLabelTemplateMutation = gql`
  mutation SetLabelTemplate($labelId: String!, $labelTemplateId: String!) {
    setLabelTemplate(labelId: $labelId, labelTemplateId: $labelTemplateId) {
      id
      labelTemplate {
        corners
        eyelet
        id
        languageCode
        material
        size
        type
      }
    }
  }
`;
