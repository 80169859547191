import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles.module.scss';
import baseTint from './tints/base.module.scss';
import cardTint from './tints/card.module.scss';
import focusTint from './tints/focus.module.scss';
import midtoneTint from './tints/midtone.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import UIContext from 'OK/util/context/ui';
import withI18n, { removeI18nProps } from 'OK/util/hoc/withI18n';

/**
 * @typedef {Object} SelectProps
 * @prop {'left'|'right'} [caretPosition='right'] The position of the dropdown caret icon.
 * @prop {string} [className]
 * @prop {boolean} [disabled=false] Disable the component.
 * @prop {boolean} [disableEmptySelection=false] Don't add an empty option.
 * @prop {(selectedOption: object) => {}} [displayLabelRender] Custom render function for the display label.
 * @prop {string} [noValueLabel] Label for the empty option.
 * @prop {(newValue: string) => {}} [onChange] Event handler when the selected option changes.
 * @prop {{label: string, value: string}[]} [options] Options to render.
 * @prop {string} [value] Current value.
 */

/**
 * Component to select a value from multiple options in a list.
 *
 * @extends {React.Component<SelectProps, {}>}
 */
class Select extends React.Component {
  static propTypes = {
    caretTint: PropTypes.string,
    caretPosition: PropTypes.oneOf(['left', 'right']),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disableEmptySelection: PropTypes.bool,
    displayLabelRender: PropTypes.func,
    error: PropTypes.bool,
    noValueLabel: PropTypes.string,
    transparent: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    t: PropTypes.func,
    value: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.handleChange = this.handleChange.bind(this);
  }

  findOptionForValue(value) {
    return this.props.options.find((o) => o.value === value);
  }

  handleChange(e) {
    const { value } = e.target;
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    const {
      caretTint,
      caretPosition = 'right',
      className,
      disabled = false,
      disableEmptySelection,
      displayLabelRender,
      error = false,
      noValueLabel = this.props.t('SELECT_NO_VALUE_LABEL'),
      transparent,
      onChange,
      options,
      value,
      ...otherProps
    } = this.props;
    // Remove props not intended for select
    const selectProps = removeI18nProps(otherProps);

    const selectedOption = this.props.options.find((o) => o.value == value);

    // Display label
    let displayLabel;
    // Custom label rendering
    if (typeof displayLabelRender === 'function') {
      displayLabel = displayLabelRender(selectedOption);
    }
    // Default label logic if no custom render specified
    if (!displayLabel) {
      if (selectedOption) {
        displayLabel = selectedOption.displayLabel || selectedOption.label;
      } else {
        displayLabel = noValueLabel;
      }
    }

    const caretIcon = <Icon className={styles.caretIcon} name={ICONS.CARET.name} tint={caretTint} />;

    return (
      <UIContext.Consumer>
        {(uiContext) => {
          let tintStyle;
          switch (uiContext) {
            case 'card':
              tintStyle = cardTint;
              break;
            case 'focus':
              tintStyle = focusTint;
              break;
            case 'midtone':
              tintStyle = midtoneTint;
              break;
            default:
              tintStyle = baseTint;
              break;
          }
          let classNames = `${styles.container} ${tintStyle.container}`;
          if (className) {
            classNames += ` ${className}`;
          }
          if (error) {
            classNames += ` ${tintStyle.error}`;
          }

          return (
            <div className={classNames}>
              <select
                className={`${transparent && styles.transparentSelect}`}
                disabled={disabled}
                onChange={this.handleChange}
                value={value}
                {...selectProps}
              >
                {!this.props.disableEmptySelection && <option value=''>{noValueLabel}</option>}
                {this.props.options.map((o) => (
                  <option key={o.value} value={o.value}>
                    {o.label}
                  </option>
                ))}
              </select>
              <button
                className={`${styles[`caret_${caretPosition}`]}  ${transparent && styles.transparentButton}`}
                disabled={disabled}
              >
                {caretPosition === 'left' && caretIcon}
                <span className={styles.text}>{displayLabel}</span>
                {caretPosition === 'right' && caretIcon}
                {caretPosition === 'hide' && !caretIcon}
              </button>
            </div>
          );
        }}
      </UIContext.Consumer>
    );
  }
}

export default withI18n(Select);
