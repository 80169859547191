import { gql } from '@apollo/client';

/* Mutations */

export const RemoveSharedPermissionMutation = gql`
  mutation RemoveSharedPermission($assetAccessPermissionId: String!) {
    sharedPermission: removeSharedAccess(assetAccessPermissionId: $assetAccessPermissionId) {
      id
    }
  }
`;

export const ShareAssetMutation = gql`
  mutation ShareAsset(
    $assetOrganisationId: String!
    $currentAccessPermission: String
    $permissionLevel: EAssetAccessPermission!
    $assetId: String!
    $assetType: EDataType!
    $sharedToAssetId: String!
    $sharedToAssetType: EDataType!
  ) {
    sharedPermission: createSharedAccess(
      assetOrganisationId: $assetOrganisationId
      currentAccessPermission: $currentAccessPermission
      assetAccessPermission: $permissionLevel
      assetId: $assetId
      assetType: $assetType
      sharedToAssetId: $sharedToAssetId
      sharedToAssetType: $sharedToAssetType
    ) {
      assetAccessPermission
      assetId
      assetType
      id
      sharedToAssetId
      sharedToAssetType
      sharedToOrganisation {
        id
        OKID
        name
        logoImageMediaAsset {
          logoImageURL
        }
      }
      sharedToUser {
        id
        OKID
        avatar {
          sourceDarkURL
          sourceLightURL
        }
      }
    }
  }
`;

export const UpdateSharedPermissionLevelMutation = gql`
  mutation UpdateSharedPermissionLevel($permissionLevel: EAssetAccessPermission!, $assetAccessPermissionId: String!) {
    sharedPermission: updateSharedAccess(
      assetAccessPermission: $permissionLevel
      assetAccessPermissionId: $assetAccessPermissionId
    ) {
      assetAccessPermission
      id
    }
  }
`;
