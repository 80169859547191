import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import styles from './styles.module.scss';

import LoadingSpinner from 'OK/components/loadingSpinner';

/**
 * Indicate progress for child elements. When in progress, children will have reduced opacity.
 *
 * @param {object} props
 * @param {Node} props.children
 * @param {string} [props.className]
 * @param {boolean} props.inProgress Indicate that child elements are in progress.
 * @param {Node} [props.progressContent] Content to show when in progress. By default a `LoadingSpinner` will be displayed.
 */
export default function Progressable(props) {
  /* Variables */

  const { children, className, inProgress = false, progressContent, ...otherProps } = props;
  const containerRef = useRef();

  /* Render */

  // Classes
  let classNames = styles.container;
  if (inProgress) {
    classNames = `${classNames} ${styles.inProgress}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  // Progress content
  const _progressContent = progressContent || <LoadingSpinner className={styles.spinner} />;

  /* Effects */

  // Blur child when in progress
  useEffect(() => {
    if (inProgress) {
      const focusedElementIsChild = containerRef.current.contains(document.activeElement);
      if (focusedElementIsChild) {
        document.activeElement.blur();
      }
    }
  }, [inProgress]);

  return (
    <div className={classNames} ref={containerRef} {...otherProps}>
      {inProgress && <div className={styles.progressInfo}>{_progressContent}</div>}
      <div className={styles.content}>{children}</div>
    </div>
  );
}

Progressable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  inProgress: PropTypes.bool,
  progressContent: PropTypes.node,
};
