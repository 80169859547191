import { gql } from '@apollo/client';

import { request } from '..';
import { API_URL } from '../utils';

import ContactModel from 'OK/models/contact';
import BaseDocumentModel from 'OK/models/document/base';
import OrderDocumentModel from 'OK/models/orderDocument';

/* Queries */

export const GetOrderQuery = gql`
  query GetOrder($REFID: String!) {
    order: orderWithREFID(REFID: $REFID) {
      id
      organisation {
        id
      }
      assetAccessPermission
      contactList {
        ...${ContactModel.fragmentNameContacts}
      }
      linkedInspectionLogList {
        id
        endDate
        failed
        inspectedSize
        lastModifiedDate
        lotSize
        REFID
        reliabilityPoint
        inspectionLogNoteAssetList {
          id
        }
        inspector {
          OKID
          reliabilityPointForPublishedLogs
        }
        item {
          product {
            publishStatus
            REFID
            productMediaAssetList {
              mediaAsset {
                imageData {
                  imageURL
                }
              }
            }
          }
        }
        organisation {
          addressList {
            countryCode
          }
        }
        site {
          id
          OKID
          siteMediaAssetList {
            mediaAsset {
              imageData {
                imageURL
              }
            }
          }
        }
        startDate
      }
      name
      orderIdentifierList {
        id
        orderIdentifier
      }
      organisationId
      REFID
      orderSiteList {
        site {
          conformityPoint
          id
          identifier
          name
          OKID
          reliabilityPointForPublishedLogs
          organisationId
          organisation {
            id
            addressList {
              countryCode
            }
            conformityPoint
            name
            reliabilityPointForPublishedLogs
          }
          reliabilityPointForPublishedLogs
          siteMediaAssetList {
            mediaAsset {
              id
              mediaType
              REFID
              imageData {
                imageURL
                imageName
                baseImageURL
              }
            }
            order
            publishStatus
          }
        }
      }
      orderDocumentAssetList {
        documentAsset {
          documentNumber
          madeByOrganisation {
            name
          }
          validFrom
          validTill
          documentStandardList
          ...${BaseDocumentModel.fragmentName}
        }
        publishStatus
      }
    }
  }
  ${BaseDocumentModel.fragment}
  ${ContactModel.fragmentContacts}
`;

/* Mutations */

export const AddOrderIdentifierMutation = gql`
  mutation AddOrderIdentifier($identifier: String!, $orderId: String!) {
    identifier: createOrderIdentifier(orderIdentifierText: $identifier, orderId: $orderId) {
      id
      orderIdentifier
    }
  }
`;

export const CreateOrderMutation = gql`
  mutation CreateOrder {
    order: createOrder {
      id
      linkedInspectionLogList {
        id
      }
      name
      orderIdentifierList {
        id
        orderIdentifier
      }
      organisationId
      REFID
    }
  }
`;

export const DeleteOrderMutation = gql`
  mutation DeleteOrder($force: Boolean, $orderId: String!) {
    order: deleteOrder(deleteItem: $force, orderId: $orderId) {
      id
    }
  }
`;

export const RemoveOrderIdentifierMutation = gql`
  mutation RemoveOrderIdentifier($identifierId: String!) {
    identifier: removeOrderIdentifier(orderIdentifierId: $identifierId) {
      id
      orderIdentifier
    }
  }
`;

export const RemoveOrderNameMutation = gql`
  mutation RemoveOrderName($languageIso: ELanguageCode!, $orderId: String!) {
    order: removeOrderName(languageCode: $languageIso, orderId: $orderId) {
      id
      name
    }
  }
`;

export const SetOrderNameMutation = gql`
  mutation SetOrderName($languageIso: ELanguageCode!, $name: String!, $orderId: String!) {
    order: setOrderName(languageCode: $languageIso, languageText: $name, orderId: $orderId) {
      id
      name
    }
  }
`;

export const searchOrderSiteListQuery = gql`
  query SearchOrders(
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $searchString: String
    $ignoreIdListByDataType: [IgnoreIdListByDataTypeInput]
    $editingOrganisationIdList: [String]
  ) {
    search(
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchString: $searchString
      ignoreIdListByDataType: $ignoreIdListByDataType
      editingOrganisationIdList: $editingOrganisationIdList
    ) {
      resultList {
        siteData {
          id
          name
          OKID
        }
      }
      searchPaginationResultDataByDataType
    }
  }
`;

export const addOrderSiteMutation = gql`
  mutation AddOrderSite($orderId: String!, $siteId: String!) {
    site: addOrderSite(orderId: $orderId, siteId: $siteId) {
      site {
        id
      }
    }
  }
`;

export const removeOrderSiteMutation = gql`
  mutation RemoveOrderSite($orderId: String!, $siteId: String!) {
    site: removeOrderSite(orderId: $orderId, siteId: $siteId) {
      site {
        id
      }
    }
  }
`;
export const linkOrderDocumentMutation = gql`
  mutation LinkOrderDocument($documentId: String!, $orderId: String!) {
    orderDocument: linkOrderDocumentAsset(documentAssetId: $documentId, orderId: $orderId) {
      ...${OrderDocumentModel.fragmentName}
    }
  }
  ${OrderDocumentModel.fragment}
`;

export const unlinkOrderDocumentMutation = gql`
  mutation UnlinkOrderDocument($documentId: String!, $orderId: String!) {
    orderDocument: unlinkOrderDocumentAsset(documentAssetId: $documentId, orderId: $orderId) {
      ...${OrderDocumentModel.fragmentName}
    }
  }
  ${OrderDocumentModel.fragment}
`;

export const publishOrderDocumentMutation = gql`
  mutation PublishOrderDocument($documentId: String!, $orderId: String!) {
    orderDocument: publishOrderDocumentAsset(documentAssetId: $documentId, orderId: $orderId) {
      ...${OrderDocumentModel.fragmentName}
    }
  }
  ${OrderDocumentModel.fragment}
`;

export const unpublishOrderDocumentMutation = gql`
  mutation UnublishOrderDocument($documentId: String!, $orderId: String!) {
    orderDocument: unpublishOrderDocumentAsset(documentAssetId: $documentId, orderId: $orderId) {
      ...${OrderDocumentModel.fragmentName}
    }
  }
  ${OrderDocumentModel.fragment}
`;

export async function createOrderDocumentAssetRequest(orderId, file, documentType) {
  const metadata = {
    filesize: file.size,
  };
  const parameters = {
    documentFile: file,
    documentType,
    metadataString: JSON.stringify(metadata),
    orderId,
  };

  return request(`${API_URL}/utility/document/createOrderDocumentAsset`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}
