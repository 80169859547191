/**
 * Wait to render children until ready.
 *
 * @param {object} props
 * @param {boolean} props.wait Whether to wait to render children.
 */
export default function WaitLayout(props) {
  const { children, wait = false } = props;

  if (wait) {
    return null;
  }

  return children;
}
