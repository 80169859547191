/**
 * Add a pixel density suffix to a filename. Ex. "image.jpeg" -> "image@2x.jpeg"
 *
 * @param {string} originalFilename The original filename.
 * @param {'2x'} pixelDensity The pixel density to add.
 *
 * @returns {string} The modified filename with a pixel density suffix.
 */
export default function addPixelDensityToFilename(originalFilename, pixelDensity) {
  const pixelDensitySuffix = `@${pixelDensity}`;
  const fileExtensionMatch = originalFilename.match(/\.[a-zA-Z]+$/);

  if (fileExtensionMatch?.index) {
    // Add pixel density immediately before the file extension
    const filename = originalFilename.slice(0, fileExtensionMatch.index);
    const fileExtension = fileExtensionMatch[0];
    return `${filename}${pixelDensitySuffix}${fileExtension}`;
  }

  // Add pixel density at the end of the filename
  return `${originalFilename}${pixelDensitySuffix}`;
}
