import { useTranslation } from 'next-i18next';
import { isValidElement, useCallback } from 'react';
import ReactDOMServer from 'react-dom/server';

import localizeObject from 'OK/util/functions/localizeObject';
import { i18nImageSrc, setLocale } from 'OK/util/i18n';

// A React hook to inject the language and i18n translate function.
function useI18n() {
  const { i18n, t: _t } = useTranslation();
  const currentLocale = i18n.language;

  const _i18nImageSrc = useCallback(
    (src) => {
      return i18nImageSrc(src, currentLocale);
    },
    [currentLocale]
  );

  const _localizeObject = useCallback(
    (object, allowFallbacks) => {
      return localizeObject(object, currentLocale, allowFallbacks);
    },
    [currentLocale]
  );

  const _setLocale = useCallback(
    (newLocale) => {
      i18n.changeLanguage(newLocale);
      setLocale(newLocale, i18n);
    },
    [i18n]
  );

  const t = useCallback(
    (messageKey, options = {}) => {
      let tOptions;
      if (options.data) {
        // Backwards compatability for legacy method of interpolation
        const { data, ...otherOptions } = options;
        tOptions = {
          ...otherOptions,
          ...data,
        };
      } else {
        tOptions = options;
      }

      return _t(messageKey, tOptions);
    },
    [_t]
  );

  const tHTML = useCallback(
    (messageKey, options = {}) => {
      const { data = {}, fallback } = options;

      const parsedData = {};
      const keys = Object.keys(data);
      for (const keyIndex in keys) {
        const key = keys[keyIndex];
        const entry = data[key];
        let parsedEntry;
        if (isValidElement(entry)) {
          // Convert React elements to renderable HTML strings
          parsedEntry = ReactDOMServer.renderToString(entry);
        } else {
          parsedEntry = entry;
        }
        parsedData[key] = parsedEntry;
      }

      let message = _t(messageKey, parsedData);

      if (!message && fallback) {
        // Message doesn't exist, so return fallback message.
        message = _t(fallback);
      }

      return <span dangerouslySetInnerHTML={{ __html: message }} />;
    },
    [_t]
  );

  return {
    i18nImageSrc: _i18nImageSrc,
    locale: currentLocale,
    localizeObject: _localizeObject,
    setLocale: _setLocale,
    t,
    tHTML,
  };
}

export default useI18n;
