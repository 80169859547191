import PropTypes from 'prop-types';
import { isValidElement } from 'react';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import Link from 'OK/components/link';
import TextHighlighter from 'OK/components/textHighlighter';
import useI18n from 'OK/util/hooks/useI18n';

function SearchSuggestions(props) {
  /* Variables */

  const {
    accessoryViewRender,
    className,
    classNameContainerContent,
    highlightTerm,
    moreResultsMessage,
    onSuggestionClick,
    showMoreResultsMessage = false,
    showNoResultsMessage = false,
    subtitleClassName,
    suggestions = [],
    titleClassName,
    titleRender,
    ...otherProps
  } = props;
  const { t } = useI18n();

  /* Methods */

  // Event handlers

  const handleClick = (e, key) => {
    if (onSuggestionClick) {
      e.preventDefault();
      e.stopPropagation();

      onSuggestionClick(key);
    }
  };

  /* Render */

  // Don't render if there are no suggestions and no message for 0 results.
  if (!suggestions.length && !showNoResultsMessage) {
    return null;
  }

  let classNames = styles.container;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let classNamesContainerContent = styles.containerContent;
  if (classNameContainerContent) {
    classNamesContainerContent = `${classNamesContainerContent} ${classNameContainerContent}`;
  }

  return (
    <div className={classNames} {...otherProps}>
      <div className={classNamesContainerContent}>
        {suggestions.map((s) => {
          const { action, highlightString, icon, iconBackgroundImageUrl, subtitle, title } = s;

          // Title
          let titleClassNames = styles.title;
          if (titleClassName) {
            titleClassNames = `${titleClassNames} ${titleClassName}`;
          }

          let titleMarkup;
          if (isValidElement(title)) {
            titleMarkup = title;
          } else if (typeof titleRender === 'function') {
            titleMarkup = titleRender(s);
          } else if (typeof title === 'string') {
            titleMarkup = <TextHighlighter highlight={highlightTerm} highlightClassName={styles.mark} text={title} />;
          } else if (typeof title === 'function') {
            titleMarkup = title(s);
          }

          // Subtitle
          let subtitleMarkup;
          if (subtitle || highlightString) {
            let subtitleClassNames = styles.subtitle;
            if (subtitleClassName) {
              subtitleClassNames = `${subtitleClassNames} ${subtitleClassName}`;
            }

            let subtitleInnerMarkup;
            if (subtitle) {
              let subtitleInner;
              if (isValidElement(subtitle)) {
                subtitleInner = subtitle;
              } else if (typeof subtitle === 'function') {
                subtitleInner = subtitle(s);
              } else if (typeof subtitle === 'string') {
                subtitleInner = (
                  <TextHighlighter highlight={highlightTerm} highlightClassName={styles.mark} text={subtitle} />
                );
              }
              subtitleInnerMarkup = <div className={styles.subtitleContainer}>{subtitleInner}</div>;
            }

            let highlightMarkup;

            if (highlightString && highlightString[0]) {
              const highlightRegex = new RegExp(highlightTerm, 'i');
              const match = highlightString.match(highlightRegex);

              let matchText = '';
              if (match) {
                const charactersToShowAfterandBeforeMatch = 5;
                const matchLength = match[0].length;
                const startingIndexCalc = match.index - charactersToShowAfterandBeforeMatch;
                const startingIndex = startingIndexCalc < 0 ? 0 : startingIndexCalc;

                // If match is not at beginning of string, add ellipsis to show there is more.

                if (match.index !== 0 && startingIndexCalc > 0) {
                  matchText += '...';
                }

                // Show matched text + some characters after.
                matchText += `${highlightString.slice(
                  startingIndex,
                  match.index + matchLength + charactersToShowAfterandBeforeMatch
                )}`;

                // If match is not at end of string, add ellipsis to show there is more.
                if (match.index + matchLength + charactersToShowAfterandBeforeMatch + 1 <= highlightString.length) {
                  matchText += '...';
                }
              } else {
                // If no match, just show the highlighted string.
                matchText = highlightString;
              }

              highlightMarkup = (
                <div className={styles.highlightStringContainer}>
                  <Icon className={styles.highlightStringIcon} name={ICONS.SEARCH.name} />
                  <TextHighlighter
                    className={styles.highlightString}
                    highlight={highlightTerm}
                    highlightClassName={styles.mark}
                    rtlEscapeHatch
                    text={matchText}
                  />
                </div>
              );
            }

            subtitleMarkup = (
              <p className={subtitleClassNames}>
                {subtitleInnerMarkup}
                {highlightMarkup}
              </p>
            );
          }

          // Icon
          let iconMarkup;
          if (icon) {
            let iconInnerMarkup;
            if (typeof icon === 'string') {
              iconInnerMarkup = <Icon name={ICONS[icon].name} />;
            } else {
              iconInnerMarkup = icon;
            }

            iconMarkup = <div className={styles.iconInnerContainer}>{iconInnerMarkup}</div>;
          }

          // Action
          let actionMarkup;
          if (action) {
            actionMarkup = action;
          } else if (typeof accessoryViewRender === 'function') {
            actionMarkup = accessoryViewRender(s);
          }

          const suggestionContent = (
            <div className={styles.suggestionContent}>
              <div>
                {iconMarkup && (
                  <div className={styles.iconContainer}>
                    <div
                      className={styles.iconBackground}
                      style={{ backgroundImage: iconBackgroundImageUrl ? `url(${iconBackgroundImageUrl})` : null }}
                    />
                    {iconMarkup}
                  </div>
                )}
                <p className={titleClassNames}>{titleMarkup}</p>
                {subtitleMarkup}
              </div>
              {actionMarkup && <div className={styles.action}>{actionMarkup}</div>}
            </div>
          );

          if (s.url) {
            // Return suggestion as a link
            return (
              <Link className={styles.suggestion} key={s.key} href={s.url}>
                {suggestionContent}
              </Link>
            );
          }

          // Return suggestion as a clickable element
          return (
            <div className={styles.suggestion} key={s.key} onMouseDown={(e) => handleClick(e, s.key)}>
              {suggestionContent}
            </div>
          );
        })}
        {showMoreResultsMessage && (
          <div className={styles.message}>{moreResultsMessage || t('SEARCH_SUGGESTIONS_MORE_RESULTS_FOUND')}</div>
        )}
        {showNoResultsMessage && (
          <div className={`${styles.message} ${styles.noResults}`}>{t('SEARCH_SUGGESTIONS_NO_RESULTS_FOUND')}</div>
        )}
      </div>
    </div>
  );
}

SearchSuggestions.propTypes = {
  accessoryViewRender: PropTypes.func,
  className: PropTypes.string,
  classNameContainerContent: PropTypes.string,
  highlightTerm: PropTypes.string,
  moreResultsMessage: PropTypes.string,
  onSuggestionClick: PropTypes.func,
  showMoreResultsMessage: PropTypes.bool,
  showNoResultsMessage: PropTypes.bool,
  subtitleClassName: PropTypes.string,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.node,
      highlightString: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      iconBackgroundImageUrl: PropTypes.string,
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
    })
  ),
  titleClassName: PropTypes.string,
  titleRender: PropTypes.func,
};

export default SearchSuggestions;
