import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './styles.module.scss';

import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import Checkbox from 'OK/components/checkbox';
import Select from 'OK/components/select';
import Text from 'OK/components/text';
import LabelTemplateModel from 'OK/models/labelTemplate';
import { PERSISTENT_STORAGE_KEY } from 'OK/util/enums/persistentStorageKeys';
import useI18n from 'OK/util/hooks/useI18n';
import { getPersistentValue, setPersistentValue } from 'OK/util/storage';

export default function LabelTemplateConfigurator(props) {
  /* Util */

  const { t } = useI18n();
  const CORNERS_OPTIONS = useMemo(() => {
    return {
      '1mm': [
        { label: t('NO_CORNER_RADIUS'), value: LabelTemplateModel.CORNERS.NONE },
        { label: t('1MM_RADIUS'), value: LabelTemplateModel.CORNERS.MM_1 },
      ],
      '2mm': [
        { label: t('NO_CORNER_RADIUS'), value: LabelTemplateModel.CORNERS.NONE },
        { label: t('2MM_RADIUS'), value: LabelTemplateModel.CORNERS.MM_2 },
      ],
      none: [{ label: t('NO_CORNER_RADIUS'), value: LabelTemplateModel.CORNERS.NONE }],
    };
  }, [t]);
  const EYELET_OPTIONS = useMemo(() => {
    return {
      none: [{ label: t('NO_EYELET'), value: LabelTemplateModel.EYELET.NONE }],
      optional: [
        { label: t('NO_EYELET'), value: LabelTemplateModel.EYELET.NONE },
        { label: t('LABEL_EYELET_DETAILS'), value: LabelTemplateModel.EYELET.INCLUDE },
      ],
    };
  }, [t]);
  const MATERIAL_OPTIONS = useMemo(() => {
    return {
      none: [],
      ON_SURFACE: [{ label: t('THERMAL_TRANSFER'), value: LabelTemplateModel.MATERIAL.THERMAL_TRANSFER }],
      STICKER: [
        { label: t('CLEAR'), value: LabelTemplateModel.MATERIAL.CLEAR_STICKER },
        { label: t('METALLIC'), value: LabelTemplateModel.MATERIAL.METALLIC_STICKER },
        { label: t('PAPER'), value: LabelTemplateModel.MATERIAL.PAPER },
        { label: t('THERMAL_TRANSFER'), value: LabelTemplateModel.MATERIAL.THERMAL_TRANSFER },
      ],
      TAG: [
        { label: t('KRAFT_PAPER'), value: LabelTemplateModel.MATERIAL.KRAFT_PAPER },
        { label: t('PAPER'), value: LabelTemplateModel.MATERIAL.PAPER },
        { label: t('TEXTILE'), value: LabelTemplateModel.MATERIAL.TEXTILE },
      ],
    };
  }, [t]);
  const SIZE_OPTIONS = useMemo(() => {
    return {
      default: [
        { label: t('LABEL_TEMPLATE_SIZE_SMALL_DETAILS'), value: LabelTemplateModel.SIZE.SMALL },
        { label: t('LABEL_TEMPLATE_SIZE_MEDIUM_DETAILS'), value: LabelTemplateModel.SIZE.MEDIUM },
        { label: t('LABEL_TEMPLATE_SIZE_LARGE_DETAILS'), value: LabelTemplateModel.SIZE.LARGE },
      ],
    };
  }, [t]);
  const TYPE_OPTIONS = useMemo(() => {
    return {
      default: [
        { label: t('ON_SURFACE'), value: LabelTemplateModel.TYPE.ON_SURFACE },
        { label: t('STICKER'), value: LabelTemplateModel.TYPE.STICKER },
        { label: t('TAG'), value: LabelTemplateModel.TYPE.TAG },
      ],
    };
  }, [t]);

  const getCornerOptionsForSizeAndMaterial = useCallback(
    (size, material) => {
      switch (material) {
        case LabelTemplateModel.MATERIAL.KRAFT_PAPER:
        case LabelTemplateModel.MATERIAL.PAPER:
          switch (size) {
            case LabelTemplateModel.SIZE.SMALL:
              return CORNERS_OPTIONS['1mm'];
            case LabelTemplateModel.SIZE.MEDIUM:
            case LabelTemplateModel.SIZE.LARGE:
              return CORNERS_OPTIONS['2mm'];
            default:
              return CORNERS_OPTIONS.none;
          }
        default:
          return CORNERS_OPTIONS.none;
      }
    },
    [CORNERS_OPTIONS]
  );

  const getEyeletOptionsForMaterial = useCallback(
    (material, type) => {
      if (type !== LabelTemplateModel.TYPE.TAG) {
        return EYELET_OPTIONS.none;
      }

      switch (material) {
        case LabelTemplateModel.MATERIAL.KRAFT_PAPER:
        case LabelTemplateModel.MATERIAL.PAPER:
          return EYELET_OPTIONS.optional;
        default:
          return EYELET_OPTIONS.none;
      }
    },
    [EYELET_OPTIONS.none, EYELET_OPTIONS.optional]
  );

  const getMaterialOptionsForType = useCallback(
    (type) => {
      switch (type) {
        case LabelTemplateModel.TYPE.ON_SURFACE:
        case LabelTemplateModel.TYPE.STICKER:
        case LabelTemplateModel.TYPE.TAG:
          return MATERIAL_OPTIONS[type];
        default:
          return MATERIAL_OPTIONS.none;
      }
    },
    [MATERIAL_OPTIONS]
  );

  /* Variables */

  const { labelTemplate, onCancel, onSave: onSaveProp } = props;

  // State

  // Selections
  const [corners, setCorners] = useState(labelTemplate?.corners ?? LabelTemplateModel.CORNERS.NONE);
  const [eyelet, setEyelet] = useState(labelTemplate?.eyelet ?? LabelTemplateModel.EYELET.NONE);
  const [material, setMaterial] = useState(labelTemplate?.material ?? '');
  const [size, setSize] = useState(labelTemplate?.size ?? '');
  const [type, setType] = useState(labelTemplate?.type ?? '');

  // Options
  const [cornerOptions, setCornerOptions] = useState(getCornerOptionsForSizeAndMaterial(size, material));
  const [eyeletOptions, setEyeletOptions] = useState(getEyeletOptionsForMaterial(material, type));
  const [materialOptions, setMaterialOptions] = useState(getMaterialOptionsForType(type));
  const [showDestructiveChangeWarning, setShowDestructiveChangeWarning] = useState(false);

  // Errors
  const [materialError, setMaterialError] = useState(null);
  const [sizeError, setSizeError] = useState(null);
  const [typeError, setTypeError] = useState(null);

  const pendingUpdateRef = useRef();
  const warnBeforeDestructiveChangesRef = useRef(
    getPersistentValue(PERSISTENT_STORAGE_KEY.WARN_BEFORE_DESTRUCTIVE_LABEL_TEMPLATE_CHANGE) === '0' ? false : true
  );

  /* API */

  /* Methods */

  const changeWarnBeforeDestructiveChangeSetting = useCallback((e) => {
    const warn = !e.target.checked;
    setPersistentValue(PERSISTENT_STORAGE_KEY.WARN_BEFORE_DESTRUCTIVE_LABEL_TEMPLATE_CHANGE, warn ? '1' : '0');
    warnBeforeDestructiveChangesRef.current = warn;
  }, []);

  const clearPendingUpdate = useCallback(() => {
    pendingUpdateRef.current = null;
    setShowDestructiveChangeWarning(false);
  }, []);

  const commitPendingUpdate = useCallback(() => {
    if (typeof pendingUpdateRef.current === 'function') {
      pendingUpdateRef.current();
      pendingUpdateRef.current = null;
      setShowDestructiveChangeWarning(false);
    }
  }, []);

  const confirmDestructiveChange = useCallback((updateFunc) => {
    pendingUpdateRef.current = updateFunc;
    setShowDestructiveChangeWarning(true);
  }, []);

  const save = useCallback(() => {
    if (onSaveProp) {
      const updatedTemplate = {
        corners,
        eyelet,
        material,
        size,
        type,
      };
      okdebug('updatedTemplate', updatedTemplate);
      onSaveProp(updatedTemplate);
    }
  }, [corners, eyelet, material, onSaveProp, size, type]);

  const validate = useCallback(() => {
    let hasError = false;

    if (!type) {
      hasError = true;
      setTypeError(t('LABEL_MAKER_POPUP_PLEASE_SELECT_TYPE'));
    }

    if (!size) {
      hasError = true;
      setSizeError(t('LABEL_MAKER_POPUP_PLEASE_SELECT_SIZE'));
    }

    if (!material) {
      hasError = true;
      setMaterialError(t('LABEL_MAKER_POPUP_PLEASE_SELECT_MATERIAL'));
    }

    return !hasError;
  }, [material, size, t, type]);

  // Event handlers

  const onChangeMaterial = useCallback(
    (newMaterial) => {
      setMaterialError(null);

      // Get updated options
      const updatedCornerOptions = getCornerOptionsForSizeAndMaterial(size, newMaterial);
      const updatedEyeletOptions = getEyeletOptionsForMaterial(newMaterial, type);

      // Define update procedure
      const update = () => {
        // Update value
        setMaterial(newMaterial);

        // Update dependant options
        setCornerOptions(updatedCornerOptions);
        setCorners(LabelTemplateModel.CORNERS.NONE);
        setEyeletOptions(updatedEyeletOptions);
        setEyelet(LabelTemplateModel.EYELET.NONE);
      };

      // Check if we should warn about update, or simply commit the update
      const selectionWillChange =
        (corners && !updatedCornerOptions.find((o) => o.value === corners)) ||
        (eyelet && !updatedEyeletOptions.find((o) => o.value === eyelet));
      if (selectionWillChange && warnBeforeDestructiveChangesRef.current) {
        confirmDestructiveChange(update);
      } else {
        update();
      }
    },
    [
      confirmDestructiveChange,
      corners,
      eyelet,
      getCornerOptionsForSizeAndMaterial,
      getEyeletOptionsForMaterial,
      size,
      type,
    ]
  );

  const onChangeSize = useCallback(
    (newSize) => {
      setSizeError(null);

      // Get updated options
      const updatedCornerOptions = getCornerOptionsForSizeAndMaterial(newSize, material);

      // Define update procedure
      const update = () => {
        // Update value
        setSize(newSize);

        // Update dependant options
        setCornerOptions(updatedCornerOptions);
        setCorners(LabelTemplateModel.CORNERS.NONE);
      };

      // Check if we should warn about update, or simply commit the update
      const selectionWillChange = corners && !updatedCornerOptions.find((o) => o.value === corners);
      if (selectionWillChange && warnBeforeDestructiveChangesRef.current) {
        confirmDestructiveChange(update);
      } else {
        update();
      }
    },
    [confirmDestructiveChange, corners, getCornerOptionsForSizeAndMaterial, material]
  );

  const onChangeType = useCallback(
    (newType) => {
      setTypeError(null);

      // Get updated options
      const updatedMaterialOptions = getMaterialOptionsForType(newType);

      // Define update procedure
      const update = () => {
        // Update value
        setType(newType);

        setMaterialOptions(updatedMaterialOptions);
        setMaterial('');
      };

      // Check if we should warn about update, or simply commit the update
      const selectionWillChange = material && !updatedMaterialOptions.find((o) => o.value === material);
      if (selectionWillChange && warnBeforeDestructiveChangesRef.current) {
        confirmDestructiveChange(update);
      } else {
        update();
      }
    },
    [confirmDestructiveChange, getMaterialOptionsForType, material]
  );

  const onSave = useCallback(() => {
    const isValid = validate();
    if (isValid) {
      save();
    }
  }, [save, validate]);

  /* Effects */

  // Update preset values
  useEffect(() => {
    if (labelTemplate) {
      setCorners(labelTemplate.corners ?? LabelTemplateModel.CORNERS.NONE);
      setEyelet(labelTemplate.eyelet ?? LabelTemplateModel.EYELET.NONE);
      setMaterial(labelTemplate.material ?? '');
      setSize(labelTemplate.size ?? '');
      setType(labelTemplate.type ?? '');
      setCornerOptions(getCornerOptionsForSizeAndMaterial(labelTemplate.size, labelTemplate.material));
      setEyeletOptions(getEyeletOptionsForMaterial(labelTemplate.material, labelTemplate.type));
      setMaterialOptions(getMaterialOptionsForType(labelTemplate.type));
    } else {
      setCorners(LabelTemplateModel.CORNERS.NONE);
      setEyelet(LabelTemplateModel.EYELET.NONE);
      setMaterial('');
      setSize('');
      setType('');
      setCornerOptions(getCornerOptionsForSizeAndMaterial('', ''));
      setEyeletOptions(getEyeletOptionsForMaterial('', ''));
      setMaterialOptions(getMaterialOptionsForType(''));
    }
  }, [getCornerOptionsForSizeAndMaterial, getEyeletOptionsForMaterial, getMaterialOptionsForType, labelTemplate]);

  /* Render */

  return (
    <>
      <div>
        <h4>
          {t(labelTemplate?.id ? 'LABEL_MAKER_POPUP_HEADER_EDIT_TEMPLATE' : 'LABEL_MAKER_POPUP_HEADER_CREATE_TEMPLATE')}
        </h4>
        <label className={styles.label}>
          {t('LABEL_MAKER_POPUP_HEADER_TYPE')}
          <Select className={styles.select} onChange={onChangeType} options={TYPE_OPTIONS.default} value={type} />
          {typeError && (
            <Text className={styles.labelError} size='sm' tint='notification'>
              {typeError}
            </Text>
          )}
        </label>
        <label className={styles.label}>
          {t('LABEL_MAKER_POPUP_HEADER_SIZE')}
          <Select className={styles.select} onChange={onChangeSize} options={SIZE_OPTIONS.default} value={size} />
          {sizeError && (
            <Text className={styles.labelError} size='sm' tint='notification'>
              {sizeError}
            </Text>
          )}
        </label>
        <label className={styles.label}>
          {t('LABEL_MAKER_POPUP_HEADER_MATERIAL')}
          <Select className={styles.select} onChange={onChangeMaterial} options={materialOptions} value={material} />
          {materialError && (
            <Text className={styles.labelError} size='sm' tint='notification'>
              {materialError}
            </Text>
          )}
        </label>
        <label className={styles.label}>
          {t('LABEL_MAKER_POPUP_HEADER_CORNERS')}
          <Select
            className={styles.select}
            disableEmptySelection
            onChange={setCorners}
            options={cornerOptions}
            value={corners}
          />
        </label>
        <label className={styles.label}>
          {t('LABEL_MAKER_POPUP_HEADER_EYELET')}
          <Select
            className={styles.select}
            disableEmptySelection
            onChange={setEyelet}
            options={eyeletOptions}
            value={eyelet}
          />
        </label>
        <Button block className={styles.saveButton} onClick={onSave} tint={labelTemplate?.id ? 'navigation' : null}>
          {t('LABEL_MAKER_POPUP_BUTTON_SAVE_TEMPLATE')}
        </Button>
        <Button linkStyle onClick={onCancel} tint='navigation'>
          {t('LABEL_MAKER_POPUP_BUTTON_DISCARD_CHANGES')}
        </Button>
      </div>
      {showDestructiveChangeWarning && (
        <Alert
          title={t('LABEL_MAKER_POPUP_CONFIRM_TEMPLATE_CHANGE_TITLE')}
          message={
            <>
              <Text>{t('LABEL_MAKER_POPUP_CONFIRM_TEMPLATE_CHANGE_MESSAGE')}</Text>
              <label className={styles.checkboxContainer}>
                <Checkbox className={styles.checkbox} onChange={changeWarnBeforeDestructiveChangeSetting} />
                {t('LABEL_MAKER_POPUP_CONFIRM_TEMPLATE_CHANGE_DONT_SHOW_AGAIN')}
              </label>
            </>
          }
          buttons={
            <>
              <Button block className={styles.confirmChangeButton} onClick={commitPendingUpdate} tint='creation'>
                {t('LABEL_MAKER_POPUP_CONFIRM_TEMPLATE_CHANGE_CONFIRM')}
              </Button>
              <Button block linkStyle onClick={clearPendingUpdate}>
                {t('LABEL_MAKER_POPUP_CONFIRM_TEMPLATE_CHANGE_CANCEL')}
              </Button>
            </>
          }
        />
      )}
    </>
  );
}

LabelTemplateConfigurator.propTypes = {
  labelTemplate: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};
