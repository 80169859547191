import { useMemo } from 'react';

import useI18n from 'OK/util/hooks/useI18n';

/**
 * React hook that resolves objects containing localized info to a new object that only contains the value for the
 * current locale.
 *
 * @see localizeObject
 * */
export default function useLocalizedObject(obj, allowFallbacks) {
  const { localizeObject, locale } = useI18n();

  const localizedObject = useMemo(
    () => localizeObject(obj, locale, allowFallbacks),
    [allowFallbacks, locale, localizeObject, obj]
  );
  return localizedObject;
}
