import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

const ContentLayout = forwardRef((props, forwardedRef) => {
  const {
    alignContent = 'left',
    allowContentOverflowSides = false,
    backgroundImage,
    backgroundImage2x,
    backgroundPosition = 'center',
    backgroundSize = 'cover',
    className,
    contentClassName,
    children,
    flex = false,
    noBackgroundColor = false,
    noPadding = false,
    padTop = false,
    pageContent,
    style,
    ...otherProps
  } = props;

  const renderAsPopup = useSelector((state) => state.app.showPopup);

  let combinedContentClassName;
  if (contentClassName) {
    combinedContentClassName = `${styles.contentContainer} ${contentClassName}`;
  } else {
    combinedContentClassName = styles.contentContainer;
  }

  // Content padding customizations
  if (allowContentOverflowSides) {
    combinedContentClassName = `${combinedContentClassName} ${styles.edgeOverflow}`;
  } else if (noPadding) {
    combinedContentClassName = `${combinedContentClassName} ${styles.noPadding}`;
  }

  // Content alignment
  switch (alignContent) {
    case 'center':
      combinedContentClassName = `${combinedContentClassName} ${styles.alignCenter}`;
      break;
    case 'right':
      combinedContentClassName = `${combinedContentClassName} ${styles.alignRight}`;
      break;
    default:
      break;
  }

  // Enable flexbox
  if (flex) {
    combinedContentClassName = `${combinedContentClassName} ${styles.flex}`;
  }

  // Page content
  if (!renderAsPopup && pageContent) {
    combinedContentClassName = `${combinedContentClassName} ${styles.pageContent}`;
  }

  // Container customizations
  let combinedClassName = styles.container;
  if (noBackgroundColor) {
    combinedClassName = `${combinedClassName} ${styles.noBackground}`;
  }
  if (padTop) {
    combinedClassName = `${combinedClassName} ${styles.padTop}`;
  }
  if (className) {
    combinedClassName = `${combinedClassName} ${className}`;
  }

  let backgroundStyles = '';
  let background2xStyles = '';
  if (backgroundImage) {
    backgroundStyles = `
      background-image: url(${backgroundImage});
      background-position: ${backgroundPosition};
      background-size: ${backgroundSize};
    `;

    if (backgroundImage2x) {
      background2xStyles = `
        background-image: url(${backgroundImage2x});
      `;
    }
  }

  return (
    <>
      <div className={combinedClassName} ref={forwardedRef} style={style}>
        <div className={combinedContentClassName} {...otherProps}>
          {children}
        </div>
      </div>
      <style jsx>{`
        .${styles.container} {
          ${backgroundStyles}
        }

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          .${styles.container} {
            ${background2xStyles}
          }
        }
      `}</style>
    </>
  );
});

export default ContentLayout;

ContentLayout.propTypes = {
  alignContent: PropTypes.oneOf(['left', 'right', 'center']),
  allowContentOverflowSides: PropTypes.bool,
  backgroundImage: PropTypes.string,
  backgroundImage2x: PropTypes.string,
  backgroundPosition: PropTypes.string,
  backgroundSize: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  flex: PropTypes.bool,
  noBackgroundColor: PropTypes.bool,
  noPadding: PropTypes.bool,
  padTop: PropTypes.bool,
  pageContent: PropTypes.bool,
  style: PropTypes.object,
};
