import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import styles from './styles.module.scss';
import baseStyles from './tints/base.module.scss';
import cardStyles from './tints/card.module.scss';
import focusStyles from './tints/focus.module.scss';
import midtoneStyles from './tints/midtone.module.scss';

import UIContext from 'OK/util/context/ui';

/**
 * Render a group of buttons.
 *
 * Expects children to be standard \<button> components. To indicate a button is active, pass `true` for its `active` prop.
 *
 * @param {object} props
 * @param {'default'|'separate'} props.buttonStyle
 * @param {Node} props.children
 * @param {string} [props.className]
 */
export default function ButtonGroup(props) {
  /* Variables */

  const { buttonStyle = 'default', children, className, ...otherProps } = props;
  const uiContext = useContext(UIContext);

  /* Render */

  // UI context styles
  let uiContextStyles;
  switch (uiContext) {
    case 'card':
      uiContextStyles = cardStyles;
      break;
    case 'focus':
      uiContextStyles = focusStyles;
      break;
    case 'midtone':
      uiContextStyles = midtoneStyles;
      break;
    default:
      uiContextStyles = baseStyles;
      break;
  }

  // Inject component styles to buttons
  const buttons = React.Children.map(children, (child) => {
    if (!child) {
      return null;
    }

    const { active = false, ...otherChildProps } = child.props;

    // Button classes
    let buttonClassNames = `${styles.button} ${uiContextStyles.button}`;
    if (active) {
      buttonClassNames = `${buttonClassNames} ${uiContextStyles.active}`;
    }
    if (child.props.className) {
      buttonClassNames = `${buttonClassNames} ${child.props.className}`;
    }

    return React.cloneElement(child, { active: null, ...otherChildProps, className: buttonClassNames });
  });

  // Component classes
  let classNames = styles.group;
  switch (buttonStyle) {
    case 'separate':
      classNames = `${classNames} ${styles.separate} ${uiContextStyles.separate}`;
      break;
    case 'separate-outline':
      classNames = `${classNames} ${styles.separate} ${uiContextStyles.separateOutline}`;
      break;
    default:
      classNames = `${classNames} ${styles.default} ${uiContextStyles.default}`;
      break;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <div className={classNames} {...otherProps}>
      {buttons}
    </div>
  );
}

ButtonGroup.propTypes = {
  buttonStyle: PropTypes.oneOf(['default', 'separate']),
  children: PropTypes.node,
  className: PropTypes.string,
};
