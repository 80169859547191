import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { ICONS } from 'OK/components/icon';
import Link, { LinkNoRedux } from 'OK/components/link';
import { PopupButtonsGroup, PopupCloseButton } from 'OK/components/popup';
import Text from 'OK/components/text';
import useI18n from 'OK/util/hooks/useI18n';

export default function WebinarRegistrationComplete(props) {
  const { email, onClickRegisterAgain, onClickTrial } = props;

  const { t, tHTML } = useI18n();

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.header}>
          <h3>See you soon!</h3>
          <PopupCloseButton linkStyle tint='navigation'>
            Close
          </PopupCloseButton>
        </div>
        <Text style={{ margin: 0 }}>The webinar link has been sent to</Text>
        <Text bold className={styles.email}>
          {email}
        </Text>
        <Button className={styles.registerAgainButton} linkStyle onClick={onClickRegisterAgain} tint='navigation'>
          Register another email
        </Button>

        <PopupButtonsGroup>
          <PopupCloseButton tint='navigation'>Close</PopupCloseButton>
        </PopupButtonsGroup>
      </div>
      <div className={styles.registerContainer}>
        <div className={styles.card}>
          <div className={styles.header}>
            <h3>Try OK+ for free</h3>
          </div>
          <p className={styles.welcomeMessage}>
            Get the most out of the webinar. Try all OK+ features for 14 days for your organisation, commitment-free.
          </p>
          <Text style={{ marginBottom: 30 }} size='xs' bold>
            No payment information required <br /> No auto-renewal
          </Text>
        </div>
        <div>
          <Button style={{ marginBottom: 0 }} block tint='creation' icon={ICONS.ACCESS.name} onClick={onClickTrial}>
            Try for free
          </Button>
        </div>
        <Link
          style={{ justifyContent: 'space-between', width: '75%' }}
          className={styles.actionButton}
          href='/solutions/contact'
          withCaret
        >
          {t('REQUEST_ASSISTANCE')}
        </Link>
        <Text className={styles.fineprint} size='xs'>
          {tHTML('PROCEEDING_AGREE_TO_TERMS_PRIVACY_COOKIE', {
            data: {
              tos: (
                <strong>
                  <LinkNoRedux className={styles.inlineLink} href='/solutions/terms' target='_blank'>
                    {t('TERMS_OF_SERVICE_LOWERCASE')}
                  </LinkNoRedux>
                </strong>
              ),
              privacy: (
                <strong>
                  <LinkNoRedux className={styles.inlineLink} href='/solutions/terms' target='_blank'>
                    {t('PRIVACY_POLICY_LOWERCASE')}
                  </LinkNoRedux>
                </strong>
              ),
              cookie: (
                <strong>
                  <LinkNoRedux className={styles.inlineLink} href='/solutions/terms' target='_blank'>
                    {t('COOKIES_LOWERCASE')}
                  </LinkNoRedux>
                </strong>
              ),
            },
          })}
        </Text>
      </div>
    </div>
  );
}

WebinarRegistrationComplete.propTypes = {
  email: PropTypes.string.isRequired,
  onClickRegisterAgain: PropTypes.func,
  onClickTrial: PropTypes.func,
};
