import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../button';
import Link from '../link';
import Text from '../text';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import TestAssetModel from 'OK/models/testAsset';
import EditFilePopup from 'OK/modules/popups/file/edit';
import { setBrowserHistoryAction, updateMiscAppStateAction } from 'OK/state/app/actions';
import ThemeContext from 'OK/util/context/theme';
import {
  formatDateRelative,
  formatDuration,
  formatFilesize,
  formatNumber,
  getArchiveAssetAttributeMappings,
} from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';
import getPopupLink from 'OK/util/popupLink';
import { Popup, PopupContent } from 'OK/components/popup';
import VideoPlayerPopup from 'OK/modules/popups/vimeoVideoPlayer';


export default function ColumnCard(props) {
  const {
    asset,
    assetType,
    downloadButtonLink,
    editMode,
    fixedColumn,
    onChangeDocumentPublished,
    onClickEdit,
    onClickUnlink,
    noLink,
    partsError,
    published,
  } = props;

  const currentColumnCardWidth = useSelector((state) => state.app.archiveColumnCardWidth);
  const dispatch = useDispatch();

  const columnCardRef = useRef(null);
  const [columnCardWidth, setColumnCardWidth] = useState(currentColumnCardWidth);
  const [isPublished, setIsPublished] = useState(published);
  const [renderEditFilePopup, setRenderEditFilePopup] = useState(false);
  const [showVideoPopup, setShowVideoPopup] = useState(false);

  const theme = useContext(ThemeContext);
  const { t, locale } = useI18n();

  useEffect(() => {
    if (columnCardRef.current) {
      setColumnCardWidth(columnCardRef.current.offsetWidth);
      dispatch(updateMiscAppStateAction({ archiveColumnCardWidth: columnCardRef.current.offsetWidth }));
    }
  }, [columnCardRef?.current?.offsetWidth, dispatch]);

  if (!asset) {
    return;
  }

  const assetAttributeMappings = getArchiveAssetAttributeMappings(asset, assetType, locale);

  const hasCreatedByUser = assetAttributeMappings?.createdByUser?.OKID;
  const hasMedia = assetAttributeMappings.mediaAssetList?.length > 0 || assetAttributeMappings?.image;
  const isAlertType = assetType === 'alert';
  const isMediaAssetType = assetType === 'media';
  const isItemAssetType = assetType === 'item';
  const isLabelAssetType = assetType === 'label';
  const isStepAssetType = assetType === 'step';
  const isWorkflowAssetType = assetType === 'workflow';
  const isEditLinkAssetType =
    assetType === 'order' ||
    assetType === 'media' ||
    assetType === 'file' ||
    assetType === 'note' ||
    assetType === 'label' ||
    assetType === 'step' ||
    assetType === 'workflow';
  const showOwnerContext = assetAttributeMappings.organisation?.name || assetAttributeMappings.createdByUser;
  const onlyPopupAssetType =
    assetType === 'media' || assetType === 'file' || assetType === 'note' || assetType === 'label';

  const numberOfStepsString =
    isWorkflowAssetType && assetAttributeMappings.inspectionTestAssetList.length === 1
      ? t('WORKFLOW_ARCHIVE_CARD_1_STEP')
      : isWorkflowAssetType && assetAttributeMappings.inspectionTestAssetList.length > 1
        ? t('WORKFLOW_ARCHIVE_CARD_X_STEPS', {
          data: { number: formatNumber(assetAttributeMappings.inspectionTestAssetList.length) },
        })
        : null;

  const assetTypeLabel = isMediaAssetType
    ? capitalize(assetAttributeMappings.mediaType.toLowerCase())
    : t(assetType.toUpperCase());

  const additionalAssetInformation = isMediaAssetType ? assetAttributeMappings.mediaMetadata : {};
  const hasAdditionalAssetInformation = Object.keys(additionalAssetInformation).length > 0;

  const cardLink = onlyPopupAssetType
    ? `/archive?refId=${assetAttributeMappings.REFID}&dataType=${assetType}`
    : isItemAssetType
      ? `/${assetAttributeMappings.REFID}` : isAlertType ? `${assetAttributeMappings.downloadLink}`
        : isEditLinkAssetType
          ? `/archive/${assetType}/${assetAttributeMappings.REFID}/edit`
          : `/archive/${assetType}/${assetAttributeMappings.REFID}`;

  const organisationLink = hasCreatedByUser
    ? `/user/${assetAttributeMappings.createdByUser?.OKID}`
    : `/organisation/${assetAttributeMappings.organisation?.OKID}`;

  //Methods

  const onClickCard = (e) => {
    e.preventDefault();
    
    if (isAlertType) {
      setShowVideoPopup(true);
      return;
    }
    getPopupLink(assetType, assetAttributeMappings.REFID, assetAttributeMappings.OKID);
    dispatch(setBrowserHistoryAction(history));
  };

  const onClickPublish = (e) => {
    e.preventDefault();

    if (onChangeDocumentPublished) {
      onChangeDocumentPublished(asset.id, published);
      setIsPublished(!published);
    }
  };

  const onUnlinkClicked = () => {
    if (onClickUnlink) {
      onClickUnlink(asset.id);
    }
  };

  // Render

  let cardContent;

  cardContent = (
    <>
      <div className={styles.columnCard} ref={columnCardRef} style={{ height: columnCardWidth * 2 }}>
        <div className={styles.columnCardAssetID}>{assetAttributeMappings?.REFID}</div>
        <div
          className={styles.columnCardImage}
          style={{
            backgroundImage:
              isMediaAssetType && assetTypeLabel === 'Image' ? `url(${additionalAssetInformation?.imageURL})` : '',
            backgroundColor: theme.name === 'dark' ? '#454646' : !hasMedia ? '#e2e2e2' : 'transparent',
            height: columnCardWidth,
          }}
        >
          {!hasMedia ? (
            <div
              style={{
                width: '100%',
                height: columnCardWidth,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  isMediaAssetType && assetTypeLabel === 'Image' ? 'rgba(255,255, 255, 0.5)' : 'transparent',
              }}
            >
              <Icon name={assetAttributeMappings?.icon} height={40} width={40} />
            </div>
          ) : (
            <img
              src={
                isLabelAssetType || isAlertType
                  ? assetAttributeMappings?.image
                  : assetAttributeMappings?.mediaAssetList[0]?.imageData?.imageURL
              }
              alt='test icon'
              width='100%'
            />
          )}
        </div>
        <div className={styles.columnCardInformation}>
          <div style={{ display: 'inline' }}>
            <div className={styles.columnCardAssetType}>{assetTypeLabel}</div>
            <div className={styles.columnCardTitle}>
              {isStepAssetType ? TestAssetModel.titleForTest(asset, locale) : assetAttributeMappings?.name}
            </div>
            {assetTypeLabel === 'Workflow' ? (
              <div className={styles.columnCardSubtitle}>{numberOfStepsString}</div>
            ) : null}
            {assetTypeLabel === 'Step' ? (
              <div className={styles.columnCardSubtitle}>{assetAttributeMappings?.testSeverityLevel}</div>
            ) : null}
            {assetTypeLabel === 'Label' ? (
              <div className={styles.columnCardSubtitle}>
                {formatDateRelative(new Date(assetAttributeMappings?.createdDate), null, locale, {
                  addSuffix: true,
                })}
              </div>
            ) : null}
            {hasAdditionalAssetInformation && assetTypeLabel === 'Image' ? (
              <>
                <div className={styles.columnCardSubtitle}>
                  {`${additionalAssetInformation.metadata.width} x ${additionalAssetInformation.metadata.height} px`}
                </div>
                <div className={styles.columnCardSubtitle}>
                  {formatFilesize(additionalAssetInformation.metadata.filesize)}
                </div>
              </>
            ) : null}
            {hasAdditionalAssetInformation && assetTypeLabel === 'Video' ? (
              <div className={styles.columnCardSubtitle}>
                {formatDuration(additionalAssetInformation.metadata.duration)}
              </div>
            ) : null}
            {hasAdditionalAssetInformation && assetTypeLabel === 'Audio' ? (
              <div className={styles.columnCardSubtitle}>
                {formatDuration(additionalAssetInformation.metadata.duration)}
              </div>
            ) : null}
          </div>
          {showOwnerContext ? (
            <div style={{ position: 'relative' }}>
              <Link className={styles.columnCardOrganisationLink} href={organisationLink}>
                {hasCreatedByUser
                  ? assetAttributeMappings.createdByUser?.name
                  : assetAttributeMappings.organisation?.name}
              </Link>
              <div
                className={styles.columnCardOrganisationImage}
                style={{ borderRadius: hasCreatedByUser ? '50%' : '' }}
              >
                {assetAttributeMappings.organisation?.logoImageMediaAsset?.logoImageURL ? (
                  <img
                    className={styles.cardOrganisationImageAsset}
                    src={assetAttributeMappings.organisation?.logoImageMediaAsset?.logoImageURL}
                    style={{ color: 'black' }}
                    alt="The product's organisation's logo."
                  />
                ) : hasCreatedByUser ? (
                  <img src={`img/empty_user_${theme.name}.svg`} alt='user placeholder icon.' height={22} width={22} />
                ) : (
                  <Icon name={ICONS.ORGANISATION.name} height={16} width={16} />
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {!isAlertType && <div className={styles.columnCardBottomContainer} style={{ width: columnCardWidth }}>
        <div className={styles.columnCardBottomContainerRow} style={{ marginTop: 5 }}>
          <div className={styles.columnCardBottomContainerItem}>{assetAttributeMappings?.SKUID}</div>
        </div>
        {editMode ? (
          <div className={styles.columnCardBottomContainerRow} style={{ justifyContent: 'flex-end' }}>
            {onChangeDocumentPublished && <Button linkStyle onClick={onClickPublish}>
              {isPublished ? t('UNPUBLISH') : t('PUBLISH')}
            </Button>}
            {onClickEdit && (
              <Button linkStyle onClick={() => setRenderEditFilePopup(true)}>
                {t('EDIT')}
              </Button>
            )}
            {onClickUnlink && (
              <Button linkStyle onClick={onUnlinkClicked} tint='alert'>
                {t('PRODUCT_ARCHIVE_CARD_UNLINK')}
              </Button>
            )}
          </div>
        ) : null}
        <div className={styles.columnCardBottomContainerRow}>
          {partsError && (
            <Text className={styles.errorMessage} size='sm' tint='alert'>
              {partsError}
            </Text>
          )}
        </div>
      </div>}
    </>
  );

  return (
    <>
      {downloadButtonLink && <a href={downloadButtonLink} target='_blank' style={{ padding: 15, zIndex: 100, position: "absolute", bottom: 120 }}><Icon name={ICONS.DOWNLOAD.name} /></a>}
      <div className={styles.columnCardContainer} style={{ width: fixedColumn ? currentColumnCardWidth : 'auto' }}>
        {noLink ? (
          cardContent
        ) : (
          <Link href={cardLink} style={{ width: '100%' }} onClick={onClickCard}>
            {cardContent}
          </Link>
        )}
      </div>
      {onClickEdit && renderEditFilePopup && (
        <EditFilePopup dismiss={() => setRenderEditFilePopup(false)} fileId={asset.id} />
      )}
      {showVideoPopup && <Popup dismiss={() => setShowVideoPopup(false)} id="alertVideo">
        <PopupContent>
        {cardLink.slice(-3) === "mp4" ? <video controls id="alertVideo" style={{ width: '100%' }}>
          <source src={cardLink} type="video/mp4"/>
            Your browser does not support the video tag.
        </video> : <img src={cardLink} width="100%" />}
        </PopupContent>
      </Popup>}
    </>
  );
}

ColumnCard.propTypes = {
  asset: PropTypes.any,
  assetType: PropTypes.string,
  editMode: PropTypes.bool,
  downloadButtonLink: PropTypes.string,
  fixedColumn: PropTypes.bool,
  onChangeDocumentPublished: PropTypes.func,
  onClickEdit: PropTypes.any,
  onClickUnlink: PropTypes.any,
  noLink: PropTypes.bool,
  partsError: PropTypes.string,
  published: PropTypes.bool,
};
