import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  linkMediaAssetToTestAssetMutation,
  RemoveConditionForTestMutation,
  removeHowToFindItFromTestAssetMutation,
  removeHowToFixItFromTestAssetMutation,
  removeWhatToLookForFromTestAssetMutation,
  reorderTestAssetMediaAssetBackwardMutation,
  reorderTestAssetMediaAssetForwardMutation,
  SetConditionForTestMutation,
  setHowToFindItOnTestAssetMutation,
  setHowToFixItOnTestAssetMutation,
  SetIdentifiersEnabledForTestMutation,
  SetNotesEnabledForTestMutation,
  setSeverityLevelForTestMutation,
  SetTimeTrackingEnabledForTestMutation,
  setWhatToLookForOnTestAssetMutation,
  unlinkTestAssetMediaAssetMutation,
} from '.';

import BaseMediaAssetModel from 'OK/models/mediaAsset/base';
import TestAssetModel from 'OK/models/testAsset';

/* Helpers */

function indexOfMediaAssetInTestAssetMediaAssetList(list, mediaAssetId, readField) {
  return list.findIndex((tma) => {
    const listMediaAsset = readField('mediaAsset', tma);
    const listMediaAssetId = readField('id', listMediaAsset);
    return listMediaAssetId === mediaAssetId;
  });
}

/* Hooks */

export function useLinkMediaAssetAPI() {
  const [linkMediaAssetAPI] = useMutation(linkMediaAssetToTestAssetMutation);

  const linkMediaAsset = useCallback(
    (testAssetId, mediaAssetId) => {
      return linkMediaAssetAPI({
        variables: {
          testAssetId,
          mediaAssetId,
        },
        update: (cache, result) => {
          if (result.data?.testAssetMediaAsset) {
            // Write MediaAsset to cache
            const mediaAssetRef = cache.writeFragment({
              id: `${BaseMediaAssetModel.GRAPHQL_TYPE}:${result.data.testAssetMediaAsset.mediaAsset.id}`,
              fragment: BaseMediaAssetModel.fragment,
              fragmentName: BaseMediaAssetModel.fragmentName,
              data: result.data.testAssetMediaAsset.mediaAsset,
            });

            // Add TestAssetMediaAsset to TestAsset's list
            cache.modify({
              id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
              fields: {
                testAssetMediaAssetList: (currentList) => {
                  const testAssetMediaAsset = {
                    ...result.data.testAssetMediaAsset,
                    mediaAsset: mediaAssetRef,
                  };
                  return [...currentList, testAssetMediaAsset];
                },
              },
            });
          }
        },
      });
    },
    [linkMediaAssetAPI]
  );

  return linkMediaAsset;
}

export function useRemoveConditionForTestAPI() {
  const apolloClient = useApolloClient();
  const [removeConditionAPI] = useMutation(RemoveConditionForTestMutation);

  const removeCondition = useCallback(
    (testAssetId, conditionType) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
        fragment: TestAssetModel.fragmentConditions,
      });
      if (testAsset) {
        optimisticResponse = {
          testAsset: {
            ...testAsset,
            testAssetConditionList: testAsset.testAssetConditionList.filter((c) => c.type !== conditionType),
          },
        };
      }

      return removeConditionAPI({
        variables: {
          conditionType,
          testAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, removeConditionAPI]
  );

  return removeCondition;
}

export function useRemoveHowToFindItFromTestAPI() {
  const apolloClient = useApolloClient();
  const [removeHowToFindItAPI] = useMutation(removeHowToFindItFromTestAssetMutation);

  const removeHowToFindIt = useCallback(
    (testAssetId, languageIso) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
        fragment: TestAssetModel.fragment,
        fragmentName: TestAssetModel.fragmentName,
      });
      const howToFindItMap = {
        ...testAsset.howToFindItMap,
      };
      delete howToFindItMap[languageIso];
      if (testAsset) {
        optimisticResponse = {
          testAsset: {
            ...testAsset,
            howToFindItMap,
          },
        };
      }

      // Call API
      return removeHowToFindItAPI({
        variables: {
          languageIso,
          testAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, removeHowToFindItAPI]
  );

  return removeHowToFindIt;
}

export function useRemoveHowToFixItFromTestAPI() {
  const apolloClient = useApolloClient();
  const [removeHowToFixItAPI] = useMutation(removeHowToFixItFromTestAssetMutation);

  const removeHowToFixIt = useCallback(
    (testAssetId, languageIso) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
        fragment: TestAssetModel.fragment,
        fragmentName: TestAssetModel.fragmentName,
      });
      const howToFixItMap = {
        ...testAsset.howToFixItMap,
      };
      delete howToFixItMap[languageIso];
      if (testAsset) {
        optimisticResponse = {
          testAsset: {
            ...testAsset,
            howToFixItMap,
          },
        };
      }

      // Call API
      return removeHowToFixItAPI({
        variables: {
          languageIso,
          testAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, removeHowToFixItAPI]
  );

  return removeHowToFixIt;
}

export function useRemoveWhatToLookForFromTestAPI() {
  const apolloClient = useApolloClient();
  const [removeWhatToLookForAPI] = useMutation(removeWhatToLookForFromTestAssetMutation);

  const removeWhatToLookFor = useCallback(
    (testAssetId, languageIso) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
        fragment: TestAssetModel.fragment,
        fragmentName: TestAssetModel.fragmentName,
      });
      const whatToLookForMap = {
        ...testAsset.whatToLookForMap,
      };
      delete whatToLookForMap[languageIso];
      if (testAsset) {
        optimisticResponse = {
          testAsset: {
            ...testAsset,
            whatToLookForMap,
          },
        };
      }

      // Call API
      return removeWhatToLookForAPI({
        variables: {
          languageIso,
          testAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, removeWhatToLookForAPI]
  );

  return removeWhatToLookFor;
}

export function useReorderMediaAssetBackwardAPI() {
  const apolloClient = useApolloClient();
  const [reorderMediaAssetBackwardAPI] = useMutation(reorderTestAssetMediaAssetBackwardMutation);

  const reorderMediaAssetBackward = useCallback(
    (testAssetId, mediaAssetId) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
        fragment: TestAssetModel.fragment,
        fragmentName: TestAssetModel.fragmentName,
      });
      const testAssetMediaAsset = testAsset?.testAssetMediaAssetList?.find((tma) => tma.mediaAsset.id === mediaAssetId);
      if (testAssetMediaAsset) {
        optimisticResponse = {
          testAssetMediaAsset: {
            ...testAssetMediaAsset,
            order: testAssetMediaAsset.order - 1,
          },
        };
      }

      // Call API
      return reorderMediaAssetBackwardAPI({
        variables: {
          testAssetId,
          mediaAssetId,
        },
        optimisticResponse,
        update: (cache, result) => {
          // Re-order test asset's media in cache
          cache.modify({
            id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
            fields: {
              testAssetMediaAssetList: (currentList) => {
                const { testAssetMediaAsset } = result.data;
                const oldOrder = testAssetMediaAsset.order + 1;
                const originalIndexBeforeMovedMedia = oldOrder - 2;
                const updatedList = [...currentList];
                const [beforeMedia, movedMedia] = updatedList.splice(originalIndexBeforeMovedMedia, 2);
                const updatedMovedMedia = {
                  ...movedMedia,
                  order: testAssetMediaAsset.order,
                };
                const updatedBeforeMedia = {
                  ...beforeMedia,
                  order: oldOrder,
                };
                updatedList.splice(originalIndexBeforeMovedMedia, 0, updatedMovedMedia, updatedBeforeMedia);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, reorderMediaAssetBackwardAPI]
  );

  return reorderMediaAssetBackward;
}

export function useReorderMediaAssetForwardAPI() {
  const apolloClient = useApolloClient();
  const [reorderMediaAssetForwardAPI] = useMutation(reorderTestAssetMediaAssetForwardMutation);

  const reorderMediaAssetForward = useCallback(
    (testAssetId, mediaAssetId) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
        fragment: TestAssetModel.fragment,
        fragmentName: TestAssetModel.fragmentName,
      });
      const testAssetMediaAsset = testAsset?.testAssetMediaAssetList?.find((tma) => tma.mediaAsset.id === mediaAssetId);
      if (testAssetMediaAsset) {
        optimisticResponse = {
          testAssetMediaAsset: {
            ...testAssetMediaAsset,
            order: testAssetMediaAsset.order + 1,
          },
        };
      }

      // Call API
      return reorderMediaAssetForwardAPI({
        variables: {
          testAssetId,
          mediaAssetId,
        },
        optimisticResponse,
        update: (cache, result) => {
          // Re-order test asset's media in cache
          cache.modify({
            id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
            fields: {
              testAssetMediaAssetList: (currentList) => {
                const { testAssetMediaAsset } = result.data;
                const oldOrder = testAssetMediaAsset.order - 1;
                const originalIndexOfMovedMedia = oldOrder - 1;
                const updatedList = [...currentList];
                const [movedMedia, afterMedia] = updatedList.splice(originalIndexOfMovedMedia, 2);
                const updatedMovedMedia = {
                  ...movedMedia,
                  order: testAssetMediaAsset.order,
                };
                const updatedAfterMedia = {
                  ...afterMedia,
                  order: oldOrder,
                };
                updatedList.splice(originalIndexOfMovedMedia, 0, updatedAfterMedia, updatedMovedMedia);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, reorderMediaAssetForwardAPI]
  );

  return reorderMediaAssetForward;
}

export function useSetConditionForTestAPI() {
  const apolloClient = useApolloClient();
  const [setConditionAPI] = useMutation(SetConditionForTestMutation);

  const setCondition = useCallback(
    (testAssetId, conditionType, conditionValue) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
        fragment: TestAssetModel.fragmentConditions,
      });
      if (testAsset) {
        optimisticResponse = {
          testAsset: {
            ...testAsset,
            testAssetConditionList: [
              ...testAsset.testAssetConditionList,
              {
                type: conditionType,
                value: conditionValue,
                valueType: TestAssetModel.conditionValueTypeForConditionType(conditionType),
              },
            ],
          },
        };
      }

      return setConditionAPI({
        variables: {
          testAssetId,
          conditionType,
          conditionValue,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setConditionAPI]
  );

  return setCondition;
}

export function useSetHowToFindItOnTestAPI() {
  const apolloClient = useApolloClient();
  const [setHowToFindItAPI] = useMutation(setHowToFindItOnTestAssetMutation);

  const setHowToFindIt = useCallback(
    (testAssetId, languageIso, text) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
        fragment: TestAssetModel.fragment,
        fragmentName: TestAssetModel.fragmentName,
      });
      if (testAsset) {
        optimisticResponse = {
          testAsset: {
            ...testAsset,
            howToFindItMap: {
              ...testAsset.howToFindItMap,
              [languageIso]: {
                ...testAsset.howToFindItMap[languageIso],
                text,
              },
            },
          },
        };
      }

      // Call API
      return setHowToFindItAPI({
        variables: {
          languageIso,
          text,
          testAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setHowToFindItAPI]
  );

  return setHowToFindIt;
}

export function useSetHowToFixItOnTestAPI() {
  const apolloClient = useApolloClient();
  const [setHowToFixItAPI] = useMutation(setHowToFixItOnTestAssetMutation);

  const setHowToFixIt = useCallback(
    (testAssetId, languageIso, text) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
        fragment: TestAssetModel.fragment,
        fragmentName: TestAssetModel.fragmentName,
      });
      if (testAsset) {
        optimisticResponse = {
          testAsset: {
            ...testAsset,
            howToFixItMap: {
              ...testAsset.howToFixItMap,
              [languageIso]: {
                ...testAsset.howToFixItMap[languageIso],
                text,
              },
            },
          },
        };
      }

      // Call API
      return setHowToFixItAPI({
        variables: {
          languageIso,
          text,
          testAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setHowToFixItAPI]
  );

  return setHowToFixIt;
}

export function useSetIdentifiersEnabledForTestAPI() {
  const [setIdentifiersEnabledAPI] = useMutation(SetIdentifiersEnabledForTestMutation);

  const setIdentifiersEnabled = useCallback(
    (testAssetId, enableIdentifiers) => {
      // Optimistic response
      const optimisticResponse = {
        testAsset: {
          id: testAssetId,
          enableIdentifiers,
          __typename: TestAssetModel.GRAPHQL_TYPE,
        },
      };

      // Call API
      return setIdentifiersEnabledAPI({
        variables: {
          testAssetId,
          enableIdentifiers,
        },
        optimisticResponse,
      });
    },
    [setIdentifiersEnabledAPI]
  );

  return setIdentifiersEnabled;
}

export function useSetNotesEnabledForTestAPI() {
  const [setNotesEnabledAPI] = useMutation(SetNotesEnabledForTestMutation);

  const setNotesEnabled = useCallback(
    (testAssetId, enableNotes) => {
      // Optimistic response
      const optimisticResponse = {
        testAsset: {
          id: testAssetId,
          enableNotes,
          __typename: TestAssetModel.GRAPHQL_TYPE,
        },
      };

      // Call API
      return setNotesEnabledAPI({
        variables: {
          testAssetId,
          enableNotes,
        },
        optimisticResponse,
      });
    },
    [setNotesEnabledAPI]
  );

  return setNotesEnabled;
}

export function useSetTimeTrackingEnabledForTestAPI() {
  const [setTimeTrackingEnabledAPI] = useMutation(SetTimeTrackingEnabledForTestMutation);

  const setTimeTrackingEnabled = useCallback(
    (testAssetId, enableTimeTracking) => {
      // Optimistic response
      const optimisticResponse = {
        testAsset: {
          id: testAssetId,
          enableTimeTracking,
          __typename: TestAssetModel.GRAPHQL_TYPE,
        },
      };

      // Call API
      return setTimeTrackingEnabledAPI({
        variables: {
          testAssetId,
          enableTimeTracking,
        },
        optimisticResponse,
      });
    },
    [setTimeTrackingEnabledAPI]
  );

  return setTimeTrackingEnabled;
}

export function useSetSeverityLevelForTestAPI() {
  const apolloClient = useApolloClient();
  const [setSeverityLevelAPI] = useMutation(setSeverityLevelForTestMutation);

  const setSeverityLevel = useCallback(
    (testAssetId, severityLevel) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
        fragment: TestAssetModel.fragment,
        fragmentName: TestAssetModel.fragmentName,
      });
      if (testAsset) {
        optimisticResponse = {
          testAsset: {
            ...testAsset,
            testSeverityLevel: severityLevel,
          },
        };
      }

      // Call API
      return setSeverityLevelAPI({
        variables: {
          testAssetId,
          severityLevel,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setSeverityLevelAPI]
  );

  return setSeverityLevel;
}

export function useSetWhatToLookForOnTestAPI() {
  const apolloClient = useApolloClient();
  const [setWhatToLookForAPI] = useMutation(setWhatToLookForOnTestAssetMutation);

  const setWhatToLookFor = useCallback(
    (testAssetId, languageIso, text) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
        fragment: TestAssetModel.fragment,
        fragmentName: TestAssetModel.fragmentName,
      });
      if (testAsset) {
        optimisticResponse = {
          testAsset: {
            ...testAsset,
            whatToLookForMap: {
              ...testAsset.whatToLookForMap,
              [languageIso]: {
                ...testAsset.whatToLookForMap[languageIso],
                text,
              },
            },
          },
        };
      }

      // Call API
      return setWhatToLookForAPI({
        variables: {
          languageIso,
          text,
          testAssetId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, setWhatToLookForAPI]
  );

  return setWhatToLookFor;
}

export function useUnlinkTestAssetMediaAssetAPI() {
  const apolloClient = useApolloClient();
  const [unlinkTestAssetMediaAssetAPI] = useMutation(unlinkTestAssetMediaAssetMutation);

  const unlinkTestAssetMediaAsset = useCallback(
    (testAssetId, mediaAssetId) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const testAsset = apolloClient.readFragment({
        id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
        fragment: TestAssetModel.fragment,
        fragmentName: TestAssetModel.fragmentName,
      });
      if (testAsset?.testAssetMediaAssetList?.length) {
        const testAssetMediaAsset = testAsset.testAssetMediaAssetList.find((tma) => tma.mediaAsset.id === mediaAssetId);
        optimisticResponse = { testAssetMediaAsset };
      }

      // Call API
      return unlinkTestAssetMediaAssetAPI({
        variables: {
          testAssetId,
          mediaAssetId,
        },
        optimisticResponse,
        update: (cache) => {
          // Remove media asset from test in cache
          cache.modify({
            id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
            fields: {
              testAssetMediaAssetList: (currentList, { readField }) => {
                const indexOfMediaAsset = indexOfMediaAssetInTestAssetMediaAssetList(
                  currentList,
                  mediaAssetId,
                  readField
                );
                if (indexOfMediaAsset > -1) {
                  const updatedList = [...currentList];
                  updatedList.splice(indexOfMediaAsset, 1);
                  return updatedList;
                }

                return currentList;
              },
            },
          });
        },
      });
    },
    [apolloClient, unlinkTestAssetMediaAssetAPI]
  );

  return unlinkTestAssetMediaAsset;
}
