import { getDataURLFromFile, releaseDataURL } from './getDataURLFromFile';

export default function getVideoMetadataFromFile(file) {
  return new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video');
      const videoDataUrl = getDataURLFromFile(file);
      video.addEventListener('loadedmetadata', () => {
        resolve({ duration: video.duration, height: video.videoHeight, width: video.videoWidth });
        releaseDataURL(videoDataUrl);
      });
      video.src = videoDataUrl;
    } catch (e) {
      reject(e);
    }
  });
}
