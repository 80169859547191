import debounce from 'lodash/debounce';
import dynamic from 'next/dynamic';
// import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import {
  Children,
  cloneElement,
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';

import 'overlayscrollbars/overlayscrollbars.css';
import styles from './styles.module.scss';

import { DEBOUNCE_TIMING_MS_INSTANT, DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';

const DynamicOverlayScrollbarsComponent = dynamic(() => {
  return import('overlayscrollbars-react').then((mod) => mod.OverlayScrollbarsComponent);
});
const OverlayScrollbarsComponent = forwardRef((props, ref) => {
  return <DynamicOverlayScrollbarsComponent {...props} forwardedRef={ref} />;
});

/**
 * @typedef {object} CarouselProps
 * @prop {string} className The class for the container element.
 * @prop {boolean} [debugMode=false] Enable debug logging for this carousel.
 * @prop {boolean} [fadeOutSides=false] Add a fade-out effect to the left and right edges of the carousel.
 * @prop {string} innerClassName The class for the inner carousel element.
 * @prop {(visibleSlideIndexes: string[]) => {}} onChangeVisibleSlides Event handler for when the visible slides change.
 * @prop {'mobileOnly'|boolean} [snapToCenter='mobileOnly'] Snap to center of slide when `snapToSlides` is enabled.
 * @prop {'mobileOnly'|boolean} [snapToSlides='mobileOnly'] Snap to most visible slide when scrolling ends.
 * @prop {number} [visibilityDivider = 1] Determines how much of a slide needs to be on screen to be considered
 * visible. This modifies the behavior of `onChangeVisibleSlides`.
 *
 * Default value is 1, meaning a slide will only be considered visible when it is fully on screen. If the
 * number is greater, then slides will be considered visible even if only partially on screen (ex. a
 * `visibilityDivider` of 2 will consider any slide at least half on-screen as visible).
 */

/**
 * A component that renders children in a horizontal layout to scroll between.
 *
 * @type {React.FC<CarouselProps>}
 */
export const Carousel = forwardRef((props, forwardedRef) => {
  /* Variables */

  const {
    children,
    className,
    debugMode = false,
    fadeOutSides = false,
    innerClassName,
    onChangeVisibleSlides,
    snapToCenter = 'mobileOnly',
    snapToSlides = 'mobileOnly',
    visibilityDivider = 1,
    ...otherProps
  } = props;
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const shouldSnapToCenter = useMemo(() => {
    switch (snapToCenter) {
      case 'mobileOnly':
        return useMobileLayout;
      default:
        return snapToCenter;
    }
  }, [snapToCenter, useMobileLayout]);
  const shouldSnapToSlides = useMemo(() => {
    switch (snapToSlides) {
      case 'mobileOnly':
        return useMobileLayout;
      default:
        return snapToSlides;
    }
  }, [snapToSlides, useMobileLayout]);

  // Refs

  const carouselRef = useRef();
  const childRefs = useRef([]);
  const ignoreScrollEventRef = useRef(false);
  const needsSnapRef = useRef(false);
  const overlayScrollbarsRef = useRef();
  const scrolledRef = useRef(false);
  const touchingRef = useRef(false);

  /* Methods */

  const debug = useCallback(
    (...message) => {
      if (debugMode) {
        okdebug(...message);
      }
    },
    [debugMode]
  );

  const determineVisibleSlides = useCallback(() => {
    let visibleSlideIndexes = [];
    childRefs.current.forEach((slideRef, index) => {
      const slide = slideRef.current;
      const viewport = overlayScrollbarsRef.current?.osInstance()?.getElements()?.viewport;
      if (!slide || !viewport) {
        return;
      }
      debug(`Determining if slide ${index} is visible`);
      debug(
        `${viewport.scrollLeft} (viewport.scrollLeft) <= ${slide.offsetLeft} (slide.offsetLeft) = ${
          viewport.scrollLeft <= slide.offsetLeft
        }`
      );
      debug(
        `${viewport.scrollLeft} (viewport.scrollLeft) + ${viewport.clientWidth} (viewport.clientWidth) > ${
          slide.offsetLeft
        } (slide.offsetLeft) + ${slide.clientWidth} (slide.clientWidth) = ${
          viewport.scrollLeft + viewport.clientWidth > slide.offsetLeft + slide.clientWidth
        }`
      );
      const slideIsVisible =
        viewport.scrollLeft <= slide.offsetLeft &&
        viewport.scrollLeft + viewport.clientWidth >=
          slide.offsetLeft + slide.clientWidth / (visibilityDivider < 0 ? 1 : visibilityDivider);
      debug(`slide ${index} is visible:`, slideIsVisible);
      if (slideIsVisible) {
        visibleSlideIndexes.push(index);
      }
    });

    if (visibleSlideIndexes.length && typeof onChangeVisibleSlides === 'function') {
      onChangeVisibleSlides(visibleSlideIndexes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debug, onChangeVisibleSlides]);
  const determineVisibleSlidesDebounced = useMemo(
    () =>
      debounce(() => {
        determineVisibleSlides();
      }, DEBOUNCE_TIMING_MS_INSTANT),
    [determineVisibleSlides]
  );

  const resetScrolled = useCallback(() => {
    scrolledRef.current = false;
  }, []);
  const resetScrolledDebounced = useMemo(
    () =>
      debounce(() => {
        resetScrolled();
      }, DEBOUNCE_TIMING_MS_SHORT),
    [resetScrolled]
  );

  const scrollToSlideAtIndex = useCallback(
    (index, immediate = false) => {
      const viewport = overlayScrollbarsRef.current?.osInstance()?.getElements()?.viewport;
      const slide = childRefs.current[index]?.current;
      if (slide && viewport) {
        let newScrollPos;
        if (shouldSnapToCenter) {
          // Align to center of slide
          newScrollPos = slide.offsetLeft + slide.clientWidth / 2 - viewport.clientWidth / 2;
        } else {
          // Align to left of slide
          newScrollPos = slide.offsetLeft;

          // Adjust for carousel padding
          const carouselStyles = getComputedStyle(carouselRef.current);
          newScrollPos -= parseInt(carouselStyles.paddingLeft, 10);

          // Adjust for slide margin
          const slideStyles = getComputedStyle(slide);
          const slideLeftMargin = parseInt(slideStyles.marginLeft, 10);
          if (slideLeftMargin) {
            newScrollPos -= slideLeftMargin;
          }
        }

        ignoreScrollEventRef.current = true;
        viewport.scrollTo({
          left: newScrollPos,
          behavior: immediate ? 'auto' : 'smooth',
        });
      }
    },
    [shouldSnapToCenter]
  );

  const snap = useCallback(() => {
    needsSnapRef.current = false;
    const viewport = overlayScrollbarsRef.current?.osInstance()?.getElements()?.viewport;
    if (viewport) {
      // Check closest slide to left scroll position
      const carouselStyles = getComputedStyle(carouselRef.current);
      const carouselPaddingLeft = parseInt(carouselStyles.paddingLeft, 10);
      const carouselLeftScrollPos = viewport.scrollLeft;
      let nearestSlideIndex, nearestSlideLeftPosDistance;
      childRefs.current.forEach((slideRef, index) => {
        const slide = slideRef.current;
        if (!slide) {
          return;
        }
        const slideStyles = getComputedStyle(slide);
        const slideLeftMargin = parseInt(slideStyles.marginLeft, 10);
        const slideLeftPos = slide.offsetLeft - slideLeftMargin - carouselPaddingLeft;
        const distance = Math.abs(carouselLeftScrollPos - slideLeftPos);
        if (typeof nearestSlideLeftPosDistance === 'undefined' || distance < nearestSlideLeftPosDistance) {
          nearestSlideIndex = index;
          nearestSlideLeftPosDistance = distance;
        }
      });

      // Check if last slide is closer to right scroll position
      const carouselPaddingRight = parseInt(carouselStyles.paddingRight, 10);
      const carouselRightScrollPos = carouselLeftScrollPos + viewport.clientWidth - carouselPaddingRight;
      const lastSlide = childRefs.current[childRefs.current.lastIndex]?.current;
      let lastSlideRightPos, lastSlideRightPosDistance;
      if (lastSlide) {
        lastSlideRightPos = lastSlide.offsetLeft + lastSlide.clientWidth;
        lastSlideRightPosDistance = Math.abs(carouselRightScrollPos - lastSlideRightPos);
      }
      if (lastSlideRightPosDistance < nearestSlideLeftPosDistance) {
        scrollToSlideAtIndex(childRefs.current.lastIndex);
      } else {
        scrollToSlideAtIndex(nearestSlideIndex);
      }
    }
  }, [scrollToSlideAtIndex]);
  const snapDebounced = useMemo(
    () =>
      debounce(() => {
        snap();
      }, DEBOUNCE_TIMING_MS_INSTANT),
    [snap]
  );

  const resetIgnoreScrollEvent = useCallback(() => {
    ignoreScrollEventRef.current = false;
  }, []);
  const resetIgnoreScrollEventDebounced = useMemo(
    () =>
      debounce(() => {
        resetIgnoreScrollEvent();
        snap();
      }, DEBOUNCE_TIMING_MS_SHORT),
    [resetIgnoreScrollEvent, snap]
  );

  /* Effects */

  // Snap to slides
  useEffect(() => {
    const elements = overlayScrollbarsRef.current?.osInstance()?.getElements();
    const handle = elements?.scrollbarHorizontal?.handle;
    const viewport = elements?.viewport;

    const onMouseDown = () => {
      touchingRef.current = true;

      resetIgnoreScrollEventDebounced.cancel();
      ignoreScrollEventRef.current = true;

      if (shouldSnapToSlides) {
        // Mark as needing to snap to slide
        needsSnapRef.current = true;
      }
    };
    const onMouseUp = () => {
      touchingRef.current = false;

      scrolledRef.current = false;
      setTimeout(() => {
        // If the user released and no further scroll has been triggered, snap to slide.
        if (touchingRef.current === false && scrolledRef.current === false) {
          snap();
          ignoreScrollEventRef.current = false;
        }
      }, DEBOUNCE_TIMING_MS_INSTANT);
    };
    const onScroll = () => {
      scrolledRef.current = true;
      resetScrolledDebounced();

      if (shouldSnapToSlides && ignoreScrollEventRef.current === false) {
        snapDebounced();
      }

      if (touchingRef.current === false) {
        resetIgnoreScrollEventDebounced();
      }

      if (typeof onChangeVisibleSlides === 'function') {
        // Only trigger visible slide check if the parent component implements the event hander.
        determineVisibleSlidesDebounced();
      }
    };

    if (handle && viewport) {
      const eventListenerOptions = { passive: true };

      // Register necessary event listeners
      handle.addEventListener('mousedown', onMouseDown, eventListenerOptions);
      handle.addEventListener('touchstart', onMouseDown, eventListenerOptions);
      viewport.addEventListener('mousedown', onMouseDown, eventListenerOptions);
      viewport.addEventListener('touchstart', onMouseDown, eventListenerOptions);
      document.body.addEventListener('mouseup', onMouseUp, eventListenerOptions);
      document.body.addEventListener('touchend', onMouseUp, eventListenerOptions);
      viewport.addEventListener('wheel', onScroll, eventListenerOptions);
      viewport.addEventListener('scroll', onScroll, eventListenerOptions);
    }

    return function () {
      if (handle && viewport) {
        // Unregister event listeners
        handle.removeEventListener('mousedown', onMouseDown);
        handle.removeEventListener('touchstart', onMouseDown);
        viewport.removeEventListener('mousedown', onMouseDown);
        viewport.removeEventListener('touchstart', onMouseDown);
        document.body.removeEventListener('mouseup', onMouseUp);
        document.body.removeEventListener('touchend', onMouseUp);
        viewport.removeEventListener('wheel', onScroll);
        viewport.removeEventListener('scroll', onScroll);
      }
    };
  }, [
    determineVisibleSlidesDebounced,
    onChangeVisibleSlides,
    resetIgnoreScrollEventDebounced,
    resetScrolledDebounced,
    shouldSnapToSlides,
    snap,
    snapDebounced,
  ]);

  // Remove refs to children that no longer exist
  useEffect(() => {
    const numberOfChildren = Children.count(children);
    while (numberOfChildren < childRefs.current.length) {
      const removedRef = childRefs.current.pop();
      removedRef.current = null;
    }
  });

  /* Render */

  useImperativeHandle(
    forwardedRef,
    () => ({
      scrollToSlideAtIndex,
    }),
    [scrollToSlideAtIndex]
  );

  const scrollbarState = overlayScrollbarsRef.current?.osInstance()?.getState();

  let classNames = styles.container;
  if (scrollbarState?.hasOverflow.x) {
    classNames = `${classNames} ${styles.withOverflow}`;
  }
  if (fadeOutSides) {
    classNames = `${classNames} ${styles.fadeOutSides}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let innerClassNames = styles.carousel;
  if (innerClassName) {
    innerClassNames = `${innerClassNames} ${innerClassName}`;
  }

  return (
    <div className={classNames} {...otherProps}>
      <OverlayScrollbarsComponent
        className={styles.customScrollbarContainer}
        options={{ overflow: { y: 'hidden' }, paddingAbsolute: true }}
        ref={overlayScrollbarsRef}
      >
        <div className={innerClassNames} ref={carouselRef}>
          {Children.map(children, (child, index) => {
            const ref = createRef();
            childRefs.current[index] = ref;
            if (!child) {
              return null;
            }
            return cloneElement(child, {
              ref,
            });
          })}
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
});

Carousel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  debugMode: PropTypes.bool,
  fadeOutSides: PropTypes.bool,
  innerClassName: PropTypes.string,
  onChangeVisibleSlides: PropTypes.func,
  snapToCenter: PropTypes.oneOf(['mobileOnly', true, false]),
  snapToSlides: PropTypes.oneOf(['mobileOnly', true, false]),
  visibilityDivider: PropTypes.number,
};

/**
 * @typedef {object} SlideProps
 * @prop {string} className The class for the slide element.
 */

/**
 * Slide container component for use inside \<Carousel> components.
 *
 * @type {React.FC<SlideProps>}
 */
export const Slide = forwardRef((props, forwardedRef) => {
  const { children, className, ...otherProps } = props;

  let classNames = styles.slide;
  if (className) {
    classNames = `${classNames} ${className}`;
  }
  return (
    <div className={classNames} ref={forwardedRef} {...otherProps}>
      {children}
    </div>
  );
});

Slide.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
