import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Link from '..';

import { setBrowserHistoryAction } from 'OK/state/app/actions';

export default function LinkToOrganisation(props) {
  const { children, organisation, ...otherProps } = props;
  const { OKID } = organisation;

  const dispatch = useDispatch();

  const onClick = (e) => {
    e.preventDefault();
    history.pushState(
      { isPopup: true, refId: null, dataType: 'ORGANISATION', OKID: OKID },
      null,
      `/organisation/${OKID}`
    );
    dispatch(setBrowserHistoryAction(history));
  };

  return (
    <Link href={`/organisation/${OKID}`} onClick={onClick} {...otherProps}>
      {children}
    </Link>
  );
}

LinkToOrganisation.propTypes = {
  children: PropTypes.node,
  organisation: PropTypes.object.isRequired,
};
