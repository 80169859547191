import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import Text from 'OK/components/text';
import { setNativeValue } from 'OK/util/functions';
import useI18n from 'OK/util/hooks/useI18n';

export default function Stepper(props) {
  /* Variables */

  const { className, disableAdd = false, disableSubtract = false, maxValue, minValue = 0, onChange, value } = props;

  const { t } = useI18n();
  const inputRef = useRef();

  const disabled = disableAdd && disableSubtract;
  const enforceMaxValue = typeof maxValue === 'number';
  const enforceMinValue = typeof minValue === 'number';

  /* State */

  const [focused, setFocused] = useState(false);

  /* Methods */

  const decrement = () => {
    triggerChange(parseValue(value) - 1);
  };

  const increment = () => {
    triggerChange(parseValue(value) + 1);
  };

  const parseValue = (val) => {
    const intValue = parseInt(val);

    if (Number.isInteger(intValue)) {
      return intValue;
    }

    return val;
  };

  const triggerChange = (newValue) => {
    onChange && onChange(newValue);
  };

  /* Events */

  const _onBlur = () => {
    setFocused(false);

    if (typeof value === 'undefined' || value === '') {
      setNativeValue(inputRef.current, 0);
    } else if (typeof value === 'number') {
      if (enforceMinValue && value < minValue) {
        setNativeValue(inputRef.current, minValue);
      } else if (enforceMaxValue && value > maxValue) {
        setNativeValue(inputRef.current, maxValue);
      }
    }
  };

  const _onChange = (e) => {
    triggerChange(parseValue(e.target.value));
  };

  const _onClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const _onFocus = () => {
    setFocused(true);

    if (value === 0) {
      setNativeValue(inputRef.current, '');
    }
  };

  /* Render */

  // Class
  let classNames = styles.stepper;
  if (focused) {
    classNames = `${classNames} ${styles.focused}`;
  }
  if (disabled) {
    classNames += ` ${styles.disabled}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  // Value
  const parsedValue = parseValue(value);
  const atOrBeyondMinValue = enforceMinValue && parsedValue <= minValue;
  const atOrBeyondMaxValue = enforceMaxValue && parsedValue >= maxValue;
  const reachedMaxValue = enforceMaxValue && parsedValue === maxValue;
  const reachedMinValue = enforceMinValue && parsedValue === minValue;

  return (
    <div className={classNames} onClick={_onClick}>
      {reachedMinValue && (
        <Text className={`${styles.valueLimitReached} ${styles.min}`} tint='notification'>
          {t('STEPPER_MIN')}
        </Text>
      )}
      <button className={styles.button} disabled={atOrBeyondMinValue || disableSubtract} onClick={decrement}>
        <Icon name={ICONS.MINUS.name} />
      </button>
      <input
        autoCapitalize='off'
        autoComplete='off'
        autoCorrect='off'
        spellCheck='off'
        className={styles.input}
        disabled={disabled}
        onBlur={_onBlur}
        onChange={_onChange}
        onFocus={_onFocus}
        ref={inputRef}
        type='number'
        value={parsedValue}
      />
      <button className={styles.button} disabled={atOrBeyondMaxValue || disableAdd} onClick={increment}>
        <Icon name={ICONS.PLUS.name} />
      </button>
      {reachedMaxValue && (
        <Text className={`${styles.valueLimitReached} ${styles.max}`} tint='notification'>
          {t('STEPPER_MAX')}
        </Text>
      )}
    </div>
  );
}

Stepper.propTypes = {
  className: PropTypes.string,
  disableAdd: PropTypes.bool,
  disableSubtract: PropTypes.bool,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
