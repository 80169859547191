import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import TabsContext from '../tabContext';

import styles from './styles.module.scss';

/**
 * Container for the tabs.
 *
 * Note: must be a descendant of a `TabsContextProvider`.
 *
 * @param {object} props
 * @param {Node} [props.children]
 * @param {string} [props.className] The container's class.
 */
export default function Tabs(props) {
  /* Variables */

  const { children, className, sections, ...otherProps } = props;
  const { activeTabId } = useContext(TabsContext);
  const [prevWidths, setPrevWidths] = useState(0);
  const [indexOfActiveTab, setIndexOfActiveTab] = useState(-1);

  /* Effects */

  useEffect(() => {
    const indexOfActiveTabCalc = sections?.findIndex(
      (section) => (section?.id?.toLowerCase() || section?.title?.toLowerCase()) === activeTabId.toLowerCase()
    );

    setIndexOfActiveTab(indexOfActiveTabCalc);
  }, [activeTabId, sections]);

  useEffect(() => {
    let prevWidthsSum = 0;

    let childrenArray;

    Array.isArray(children) ? (childrenArray = children) : (childrenArray = [children]);

    for (let i = 0; i <= indexOfActiveTab; i++) {
      if (i === indexOfActiveTab) {
        prevWidthsSum += document.getElementById(childrenArray[i]?.props?.tabId.toLowerCase())?.clientWidth / 2;
      } else {
        prevWidthsSum += document.getElementById(childrenArray[i]?.props?.tabId.toLowerCase())?.clientWidth;
      }
    }

    setPrevWidths(prevWidthsSum);
  }, [children, indexOfActiveTab, prevWidths, setPrevWidths]);

  /* Render */

  let classNames = styles.tabsContainer;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <div className={classNames} {...otherProps}>
      {children}
      {indexOfActiveTab !== -1 && (
        <div
          className={styles.tabUnderline}
          style={{
            transform: `translateX(calc(-50% + ${prevWidths}px))`,
          }}
        />
      )}
    </div>
  );
}

Tabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  sections: PropTypes.array,
};
