import PropTypes from 'prop-types';
import { useContext } from 'react';

import BaseIcon from 'OK/components/icon/base';
import ThemeContext from 'OK/util/context/theme';

export default function AlertIcon(props) {
  /* Variables */

  const { color, ...otherProps } = props;
  const theme = useContext(ThemeContext);

  /* Render */

  const _color = color || theme.colors.alert;

  return (
    <BaseIcon {...otherProps}>
      <circle cx='8' cy='8' r='8' fill={_color} />
      <g transform='translate(-65.166 -19.674)' style={{ isolation: 'isolate' }}>
        <path
          d='M74.271,29.729H72.047l-.4-7.519h3.025Zm-2.625,2.7a1.251,1.251,0,0,1,.441-1.1,1.788,1.788,0,0,1,1.063-.313,1.728,1.728,0,0,1,1.04.313,1.586,1.586,0,0,1,0,2.176,1.673,1.673,0,0,1-1.04.328,1.73,1.73,0,0,1-1.063-.328A1.257,1.257,0,0,1,71.646,32.434Z'
          fill='#fff'
        />
      </g>
    </BaseIcon>
  );
}

AlertIcon.propTypes = {
  color: PropTypes.string,
};
