import { NetworkStatus, useLazyQuery } from '@apollo/client';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Checkbox from 'OK/components/checkbox';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import InputWithDropdown from 'OK/components/input/withDropdown';
import Link, { LinkNoRedux } from 'OK/components/link';
import config from 'OK/config/app';
import { login } from 'OK/networking/auth';
import { hasSubscribedToNewsLetter } from 'OK/networking/users';
import { lockAction, resetLoginModal, verifyingOTPAction } from 'OK/state/account/actions';
import { showAuthModal } from 'OK/state/app/actions';
import { trackEvent } from 'OK/util/analytics';
import { DEBOUNCE_TIMING_MS_LONG } from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import { countryList } from 'OK/util/geolocation';
import useBrowserRenderOnly from 'OK/util/hooks/useBrowserRenderOnly';
import useI18n from 'OK/util/hooks/useI18n';
import { r } from 'OK/util/routing';

const phoneCodeForCountryIso = (iso, countries) => countries.find((country) => country.isoAlpha3 === iso).phoneCode;

export default function Login(props) {
  /* Variables */

  const { changeLoginMethod, className, mode, ...otherProps } = props;
  const accountState = useSelector((state) => state.account);
  const defaultCountryIso = useSelector((state) => state.account.preferences.region);
  const dispatch = useDispatch();
  const hCaptcha = useRef(null);
  const theme = useContext(ThemeContext);
  const renderForBrowser = useBrowserRenderOnly();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const { t, tHTML } = useI18n();

  /* State */

  const [captchaError, _setCaptchaError] = useState(null);
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const [captchaToken, _setCaptchaToken] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phoneCountry, setPhoneCountry] = useState(defaultCountryIso);
  const [username, setUsername] = useState('');
  const [usernameIsValid, setUsernameIsValid] = useState(undefined);
  const [newsletterSubscription, setNewsletterSubscription] = useState();
  const [showNewsletterSubscriptionCheckbox, setShowNewsletterSubscriptionCheckbox] = useState(false);
  const [accountExists, setAccountExists] = useState(false);

  /* API */

  const [hasSubscribedToNewsLetterAPI, hasSubscribedToNewsLetterAPIResult] = useLazyQuery(hasSubscribedToNewsLetter, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.response === null) {
        setAccountExists(false);
      } else {
        setAccountExists(true);
      }
    },
  });

  /* Effects */

  // This in needed for keeping the checkbox's state up to date
  useEffect(() => {
    if (usernameIsValid === undefined) {
      return;
    }
    if (!accountExists && usernameIsValid && hasSubscribedToNewsLetterAPIResult.networkStatus === NetworkStatus.ready) {
      setShowNewsletterSubscriptionCheckbox(true);
      return;
    } else {
      if (
        hasSubscribedToNewsLetterAPIResult?.data?.response?.newsletterSubscription === 'false' &&
        hasSubscribedToNewsLetterAPIResult.networkStatus === NetworkStatus.ready
      ) {
        setShowNewsletterSubscriptionCheckbox(true);
      } else {
        setShowNewsletterSubscriptionCheckbox(false);
      }
    }
  }, [
    accountExists,
    hasSubscribedToNewsLetterAPIResult?.data?.response?.newsletterSubscription,
    hasSubscribedToNewsLetterAPIResult.networkStatus,
    usernameIsValid,
  ]);

  /* Methods */

  const checkNewsletterSubscription = useCallback(
    (uname) => {
      mode == 'email' && hasSubscribedToNewsLetterAPI({ variables: { email: uname } });
    },
    [hasSubscribedToNewsLetterAPI, mode]
  );

  const validateUsername = useCallback(
    async (uname) => {
      const validator = (await import('validator')).default;

      const isValid = (await mode) === 'email' ? validator.isEmail(uname) : validator.isMobilePhone(uname);
      setUsernameIsValid(isValid);

      return isValid;
    },
    [mode]
  );

  const validateUsernameDebounced = useMemo(
    () =>
      debounce((uname) => {
        if (uname) {
          validateUsername(uname).then((res) => {
            res == true && checkNewsletterSubscription(uname);
          });
        }
      }, DEBOUNCE_TIMING_MS_LONG),
    [checkNewsletterSubscription, validateUsername]
  );

  const changeMode = useCallback(
    (newMode) => {
      changeLoginMethod(newMode);
      // Reset state
      setPhoneCountry(newMode === 'mobile' ? defaultCountryIso : null);
      setError(null);
      setUsername('');
      setUsernameIsValid(undefined);
      validateUsernameDebounced.cancel();
    },
    [changeLoginMethod, defaultCountryIso, validateUsernameDebounced]
  );

  const setCaptchaError = useCallback((e, message) => {
    if (e) {
      okerror('Captcha error: ', e);
    }
    _setCaptchaError(message);
  }, []);

  const setCaptchaToken = useCallback(
    (token) => {
      okdebug(`Setting captcha token to ${token}`);
      setCaptchaError(null, null);
      _setCaptchaToken(token);
      setCaptchaLoading(false);

      if (!token) {
        hCaptcha.current?.resetCaptcha();
      }
    },
    [setCaptchaError]
  );

  const setNewsletterCheck = useCallback((e) => {
    if (e.target.checked) {
      setNewsletterSubscription(true);
    } else {
      setNewsletterSubscription(false);
    }
  }, []);

  const triggerCaptcha = useCallback(() => {
    if (!captchaToken) {
      setCaptchaLoading(true);
      hCaptcha.current?.execute();
    }
  }, [captchaToken]);

  const tryLogin = useCallback(
    async (e) => {
      e && e.preventDefault();

      if (loading) {
        return;
      }

      const formattedUsername = username.toLowerCase();
      const unameIsValid = await validateUsername(formattedUsername);

      if (!unameIsValid) {
        setError(t(`INVALID_${mode.toUpperCase()}`));
        return;
      } else {
        setError(null);
      }

      if (!captchaToken) {
        setCaptchaError(null, t('ERROR_AGREE_TO_TERMS'));
        return;
      }

      setLoading(true);
      const code = mode === 'mobile' ? phoneCodeForCountryIso(phoneCountry, countryList) : undefined;
      try {
        const response = await login(formattedUsername, mode, code, captchaToken);
        if (response.success || response.error === 'USER_OTP_TIMEOUT') {
          // OTP requested successfully
          const { availableAt, attemptLeft, expiresAt } = response.responseData;
          const expiryDate = new Date(expiresAt);
          dispatch(
            verifyingOTPAction(
              formattedUsername,
              mode,
              attemptLeft,
              expiryDate,
              code,
              true,
              availableAt,
              newsletterSubscription
            )
          );
          trackEvent('Requested OTP');
        } else {
          setCaptchaToken(null);
          switch (response.error) {
            case 'ANTIBOT_TOKEN_INVALID_OR_MISSING':
              setCaptchaError('Captcha validation failed.', t(response.error ?? 'ERROR_GENERIC'));
              break;
            case 'USER_OTP_REACHED_LIMIT_TIMEOUT':
              dispatch(lockAction(new Date(response.responseData.availableAt)));
              break;
            default:
              setError(t(response.error ?? 'ERROR_GENERIC'));
              break;
          }
        }
      } catch (error) {
        okerror(error);
        setCaptchaToken(null);
        setError(t('ERROR_GENERIC'));
      } finally {
        setLoading(false);
      }
    },
    [
      captchaToken,
      dispatch,
      loading,
      mode,
      newsletterSubscription,
      phoneCountry,
      setCaptchaError,
      setCaptchaToken,
      t,
      username,
      validateUsername,
    ]
  );

  /* Events */

  const didChooseCountryCode = useCallback((countryIso) => {
    setPhoneCountry(countryIso);
  }, []);

  const didType = useCallback(
    (e) => {
      const newUsername = e.target.value;
      setUsername(newUsername);
      setError(null);
      setUsernameIsValid(undefined);
      validateUsernameDebounced(newUsername, mode);
    },
    [mode, validateUsernameDebounced]
  );

  const onUsernameBlur = useCallback(() => {
    validateUsernameDebounced.flush();
  }, [validateUsernameDebounced]);

  /* Render */

  const classNames = `${styles.container}${className ? ` ${className}` : ''}`;
  const authenticationError = error || accountState.authenticationError;

  let inputIcon;
  if (usernameIsValid === true) {
    inputIcon = <Icon name={ICONS.TICK.name} tint='creation' />;
  } else if (usernameIsValid === false) {
    inputIcon = <Icon name={ICONS.X.name} tint='alert' />;
  }

  return (
    <div className={classNames} fixedWidth={useDesktopLayout} {...otherProps}>
      <form onSubmit={tryLogin}>
        <div className={styles.header}>
          <h3>{accountState.isComingFromTryForFree ? t('SIGN_IN_OR_UP_TRIAL') : t('SIGN_IN_OR_UP')}</h3>
          <Button
            className={styles.actionButtonUpper}
            linkStyle
            onClick={() => {
              dispatch(showAuthModal(false));
              dispatch(resetLoginModal());
            }}
          >
            {t('CLOSE')}
          </Button>
        </div>
        <p className={styles.instructions}>{t('AUTH_SIGN_IN_CARD_DESCRIPTION')}</p>
        {mode === 'email' ? (
          <h5 className={styles.inputLabel}>
            {t('AUTH_GET_EMAIL_CODE_LABEL_1')}&nbsp;
            <Button className={styles.switchModeButton} linkStyle onClick={() => changeMode('mobile')}>
              {t('AUTH_GET_EMAIL_CODE_LABEL_2')}
            </Button>
          </h5>
        ) : (
          <h5 className={styles.inputLabel}>
            {t('AUTH_GET_SMS_CODE_LABEL_1')}&nbsp;
            <Button className={styles.switchModeButton} linkStyle onClick={() => changeMode('email')}>
              {t('AUTH_GET_SMS_CODE_LABEL_2')}
            </Button>
          </h5>
        )}
        <div className={styles.inputGroup}>
          {mode === 'email' ? (
            <Input
              className={styles.input}
              hasError={!!error}
              onBlur={onUsernameBlur}
              onChange={didType}
              placeholder={t('ENTER_EMAIL_ADDRESS')}
              showClearButton={false}
              showErrorIcon={false}
              type='email'
              value={username}
              withIcon={inputIcon}
            />
          ) : (
            <InputWithDropdown
              className={`${styles.input}`}
              dropdownClassName={styles.phoneCodeInput}
              dropdownLabel={t('YOUR_COUNTRY_CODE')}
              dropdownOptions={countryList
                .map(({ isoAlpha3, name, phoneCode }) => ({
                  displayLabel: `+${phoneCode}`,
                  label: `${name} (+${phoneCode})`,
                  value: isoAlpha3,
                }))
                .sort((a, b) => {
                  return a.label > b.label ? 1 : -1;
                })}
              dropdownValue={phoneCountry}
              hasError={!!error}
              onBlur={onUsernameBlur}
              onChange={didType}
              onDropdownChange={didChooseCountryCode}
              placeholder={t('ENTER_NUMBER')}
              showClearButton={false}
              showErrorIcon={false}
              type='tel'
              value={username}
              withIcon={inputIcon}
            />
          )}
        </div>
        {authenticationError && <p className={`${styles.errorMessage}`}>{authenticationError}</p>}
        <div className={styles.captcha}>
          {captchaLoading ? (
            <Icon height={32} name={ICONS.SPINNER.name} width={32} />
          ) : (
            <Checkbox checked={!!captchaToken} error={!!captchaError} onClick={triggerCaptcha} type='checkbox' />
          )}
          <p className={styles.captchaAgree}>
            {tHTML('AUTH_AGREE_TO_TERMS_1', {
              data: {
                terms: (
                  <LinkNoRedux tint='navigation' className={styles.captchaAgreeLink} href='/solutions/terms'>
                    {t('TERMS_OF_SERVICE_LOWERCASE')}
                  </LinkNoRedux>
                ),
              },
            })}
          </p>
          {renderForBrowser && (
            <HCaptcha
              id='captcha'
              onError={(e) => setCaptchaError(e, t('ANTIBOT_TOKEN_INVALID_OR_MISSING'))}
              onExpire={setCaptchaToken}
              onVerify={setCaptchaToken}
              reCaptchaCompat={false}
              ref={hCaptcha}
              sitekey={config.hCaptchaSiteKey}
              size='invisible'
            />
          )}
          {captchaError && <p className={`${styles.errorMessage} ${styles.captchaError}`}>{captchaError}</p>}
        </div>
        {mode == 'email' && showNewsletterSubscriptionCheckbox && (
          <div className={styles.captcha}>
            <Checkbox onClick={setNewsletterCheck} type='checkbox' />
            <p className={styles.captchaAgree}>{t('AUTH_SUBSCRIBE_FOR_NEWS')}</p>
          </div>
        )}
        {captchaError && <p className={`${styles.errorMessage} ${styles.captchaError}`}>{captchaError}</p>}
        <Button
          block
          className={styles.actionButton}
          disabled={loading}
          icon={`/icons/caret_${theme.name === 'dark' ? 'light' : 'dark'}.svg`}
          loading={loading}
          onClick={tryLogin}
        >
          {loading ? t('AUTH_BUTTON_LOGGING_IN') : t('AUTH_BUTTON_SIGN_IN')}
        </Button>
        <Link block className={styles.linkButton} href='/solutions/contact' withCaret>
          {t('REQUEST_ASSISTANCE')}
        </Link>
        <p className={styles.textXs}>
          {mode === 'mobile' && <>{t('SMS_RATES_APPLY')}&nbsp;</>}
          {tHTML('AUTH_TERMS_FINEPRINT', {
            data: {
              tos: (
                <LinkNoRedux className={styles.link} href={r('/solutions/terms')}>
                  {t('TERMS_OF_SERVICE_LOWERCASE')}
                </LinkNoRedux>
              ),
              privacy: (
                <LinkNoRedux className={styles.link} href={r('/solutions/terms')}>
                  {t('PRIVACY_POLICY_LOWERCASE')}
                </LinkNoRedux>
              ),
              cookie: (
                <LinkNoRedux className={styles.link} href={r('/solutions/terms')}>
                  {t('COOKIES_LOWERCASE')}
                </LinkNoRedux>
              ),
            },
          })}
          <br />
          <br />
        </p>
      </form>
    </div>
  );
}

Login.propTypes = {
  changeLoginMethod: PropTypes.func.isRequired,
  className: PropTypes.string,
  mode: PropTypes.string.isRequired,
};
