import { gql } from '@apollo/client';

import BaseInspectionAssetModel from './inspectionAsset/base';

export default class ProductInspectionAssetModel {
  static GRAPHQL_TYPE = 'ProductInspectionAsset';

  static fragmentName = 'Product_Inspection_Fragment';
  static fragment = gql`
    fragment ${ProductInspectionAssetModel.fragmentName} on ${ProductInspectionAssetModel.GRAPHQL_TYPE} {
      inspectionAsset {
        ...${BaseInspectionAssetModel.fragmentName}
      }
      order
      publishStatus
    }
    ${BaseInspectionAssetModel.fragment}
  `;
}
