import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import { Carousel, Slide } from 'OK/components/carousel';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import SiteContactViewCard from 'OK/components/siteContact/viewCard';
import useI18n from 'OK/util/hooks/useI18n';

export default function InspectionLogContactsSection(props) {
  /* Variables */

  const { className, inspectionLog, inspectionLogContactsSectionRef } = props;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const { t } = useI18n();

  /* State */

  /* Methods */

  /* Render */

  let classNames = styles.section;
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <ContentLayout className={classNames} ref={inspectionLogContactsSectionRef} pageContent>
      <TextLayout>
        <h3>{t('CONTACTS_SECTION')}</h3>
      </TextLayout>
      {inspectionLog.contactList.length ? (
        <Carousel
          className={styles.contactsCarouselOuter}
          fadeOutSides={useDesktopLayout}
          innerClassName={styles.contactsCarouselInner}
        >
          {inspectionLog.contactList.map((c) => (
            <Slide className={styles.contactSlide} key={c.id}>
              <SiteContactViewCard className={styles.contactCard} fixedWidth={false} contact={c} />
            </Slide>
          ))}
        </Carousel>
      ) : (
        <TextLayout>{t('NO_CONTACTS')}</TextLayout>
      )}
    </ContentLayout>
  );
}

InspectionLogContactsSection.propTypes = {
  className: PropTypes.string,
  inspectionLog: PropTypes.object.isRequired,
  inspectionLogContactsSectionRef: PropTypes.object,
  setPageMessage: PropTypes.func.isRequired,
  setPageError: PropTypes.func.isRequired,
};
