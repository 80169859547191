import PropTypes from 'prop-types';
import { useContext } from 'react';

import styles from './styles.module.scss';
import baseTint from './tints/base.module.scss';
import cardTint from './tints/card.module.scss';
import focusTint from './tints/focus.module.scss';
import midtoneTint from './tints/midtone.module.scss';

import UIContext from 'OK/util/context/ui';

export default function Tooltip(props) {
  const { bounce = false, children, className, containerClassName, position = 'above', tint } = props;

  const uiContext = useContext(UIContext);

  let containerClassNames = styles.tooltipContainer;

  let combinedClassName = styles.tooltip;

  let tintStyle;
  switch (uiContext) {
    case 'card':
      tintStyle = cardTint;
      break;
    case 'focus':
      tintStyle = focusTint;
      break;
    case 'midtone':
      tintStyle = midtoneTint;
      break;
    default:
      tintStyle = baseTint;
      break;
  }
  combinedClassName = `${combinedClassName} ${tintStyle.tooltip}`;

  switch (tint) {
    case 'alert':
    case 'creation':
    case 'navigation':
    case 'notification':
      combinedClassName = `${combinedClassName} ${tintStyle[tint]}`;
      break;
    default:
      break;
  }

  if (position === 'below') {
    combinedClassName = `${combinedClassName} ${styles.below} ${tintStyle.below}`;
    if (bounce) {
      containerClassNames = `${containerClassNames} ${styles.bounceDown}`;
    }
  } else {
    combinedClassName = `${combinedClassName} ${styles.above} ${tintStyle.above}`;
    if (bounce) {
      containerClassNames = `${containerClassNames} ${styles.bounceUp}`;
    }
  }

  if (containerClassName) {
    containerClassNames += ` ${containerClassName}`;
  }

  if (className) {
    combinedClassName = `${combinedClassName} ${className}`;
  }

  return (
    <div className={containerClassNames}>
      <div className={combinedClassName}>{children}</div>
    </div>
  );
}

Tooltip.propTypes = {
  bounce: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  position: PropTypes.oneOf(['above', 'below']),
  tint: PropTypes.string,
};
