import PropTypes from 'prop-types';
import { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import baseStyles from './tints/base.module.scss';
import cardStyles from './tints/card.module.scss';
import focusStyles from './tints/focus.module.scss';

import CardLayout from 'OK/components/layouts/card';
import UIContext from 'OK/util/context/ui';

const ArchiveCardContext = createContext({
  outerUIContext: 'base',
});

export { ArchiveCardContext };

/**
 * @typedef {Object} ArchiveCardProps
 * @prop {'alert'|'creation'|'navigation'|'notification'} [actionIndicatorTint] Show a thin indicator on the right side of
 * the card with a specific tint color.
 * @prop {Node} children
 * @prop {string} [className]
 */

/**
 * Base class for cards shown in Archive.
 *
 * @param {ArchiveCardProps} props
 *
 * @example
 * <ArchiveCard>
 *   <ArchiveCardMainSection>
 *     <ArchiveCardMediaGallery />
 *     <ArchiveCardMainContent>
 *       <ArchiveCardHeader />
 *       <ArchiveCardTitle />
 *     </ArchiveCardMainContent>
 *   </ArchiveCardMainSection>
 *   <ArchiveCardFooter>
 *     <ArchiveCardFooterRow />
 *   </ArchiveCardFooter>
 *   <ArchiveCardManagerOptions />
 * </ArchiveCard>
 */
export function ArchiveCard(props) {
  /* Variables */

  const {
    actionIndicatorTint,
    cardTint,
    children,
    className,
    fixedWidth = true,
    layoutOverride,
    ...otherProps
  } = props;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const uiContext = useContext(UIContext);

  /* Render */

  // UI context-specific classes
  const uiContextStyles = useMemo(() => {
    switch (uiContext) {
      case 'card':
        return cardStyles;
      case 'focus':
        return focusStyles;
      default:
        return baseStyles;
    }
  }, [uiContext]);

  // Classes
  const classNames = useMemo(() => {
    const list = [styles.card, uiContextStyles.card];

    if ((useDesktopLayout && layoutOverride !== 'mobile') || layoutOverride === 'desktop') {
      list.push(styles.desktop);
    }
    if (fixedWidth) {
      list.push(styles.fixedWidth);
    }
    if (cardTint) {
      list.push(cardTint, uiContextStyles[cardTint]);
    }
    if (className) {
      list.push(className);
    }

    return list.join(' ');
  }, [cardTint, className, fixedWidth, layoutOverride, uiContextStyles, useDesktopLayout]);

  return (
    <CardLayout className={classNames} fixedWidth={false} padded={false} {...otherProps}>
      <ArchiveCardContext.Provider value={{ cardTint, outerUIContext: uiContext }}>
        {children}
      </ArchiveCardContext.Provider>
      {actionIndicatorTint && (
        <div
          className={`${styles.actionIndicator} ${uiContextStyles.actionIndicator} ${uiContextStyles[actionIndicatorTint]}`}
        />
      )}
    </CardLayout>
  );
}

ArchiveCard.propTypes = {
  actionIndicatorTint: PropTypes.oneOf(['alert', 'creation', 'navigation', 'notification']),
  cardTint: PropTypes.oneOf(['alert', 'creation', 'navigation', 'notification']),
  children: PropTypes.node,
  className: PropTypes.string,
  fixedWidth: PropTypes.bool,
  layoutOverride: PropTypes.oneOf(['mobile', 'desktop']),
};

export { default as ArchiveCardFooter } from './footer';
export { default as ArchiveCardFooterRow } from './footerRow';
export { default as ArchiveCardHeader } from './header';
export { default as ArchiveCardMainContent } from './mainContent';
export { default as ArchiveCardMainSection } from './mainSection';
export { default as ArchiveCardMediaGallery } from './mediaGallery';
export { default as ArchiveCardOKID } from './okid';
export { default as ArchiveCardPricing } from './pricing';
export { default as ArchiveCardTestResults } from './testResults';
export { default as ArchiveCardTitle } from './title';
