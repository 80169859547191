import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import countries from 'OK/assets/countries.json';
import Button from 'OK/components/button';
import Input from 'OK/components/input';
import Select from 'OK/components/select';
import Text from 'OK/components/text';
import { createOrganisationMutationBasic, createOrganisationMutationTrial } from 'OK/networking/organisations';
import { getCurrentUserQuery } from 'OK/networking/users';
import { showCreatingNewOrganisation, showOnboarding } from 'OK/state/account/actions';
import { createOrganisationAction } from 'OK/state/onboarding/actions';
import getTimezoneUTCOffset from 'OK/util/functions/getTimezoneUTCOffset';
import { countryList } from 'OK/util/geolocation';
import useI18n from 'OK/util/hooks/useI18n';
import { languageByIso } from 'OK/util/i18n';
import SessionManager from 'OK/util/session';

function OrganisationCreatingModule() {
  const { t, tHTML } = useI18n();
  const defaultCountry = useSelector((state) => state.account.preferences.region);
  const appLanguageIso = useSelector((state) => state.account.preferences.language);
  const isComingFromUserPage = useSelector((state) => state.account.isComingFromUserPage);

  const dispatch = useDispatch();

  // State

  const [country, _setCountry] = useState(defaultCountry);
  const [orgName, setOrgName] = useState();
  const [formError, _setFormError] = useState({ field: null, message: null });
  const [loading, setLoading] = useState(false);

  // API

  const [createOrganisationBasicAPI] = useMutation(createOrganisationMutationBasic, {
    onError: (error) => {
      okerror('Create organisation error', error);
      setFormError('form', t('ERROR_GENERIC'));
    },
    onCompleted: (data) => {
      if (data?.organisation) {
        dispatch(createOrganisationAction(data.organisation));
        SessionManager.activeOrganisationId = data.organisation.id;
        SessionManager.completeOnboarding();
      } else {
        setFormError('form', t('ERROR_GENERIC'));
      }
    },
    refetchQueries: [{ query: getCurrentUserQuery }],
  });

  const [createOrganisationTrialAPI] = useMutation(createOrganisationMutationTrial, {
    onError: (error) => {
      okerror('Create organisation error', error);
      setFormError('form', t('ERROR_GENERIC'));
    },
    onCompleted: (data) => {
      if (data?.organisation) {
        dispatch(createOrganisationAction(data.organisation));
        SessionManager.activeOrganisationId = data.organisation.id;
        SessionManager.completeOnboarding();
      } else {
        setFormError('form', t('ERROR_GENERIC'));
      }
    },
    refetchQueries: [{ query: getCurrentUserQuery }],
  });

  // Event handlers

  const onFormChange = (field, value) => {
    if (formError.field === field) {
      setFormError(null, null);
    }

    switch (field) {
      case 'name':
        setOrgName(value);
        break;
      case 'country':
        setCountry(value);
        break;
      default:
        break;
    }
  };

  // Methods

  const setFormError = (field, message) => {
    _setFormError({ field, message });
  };

  const createOrganisationBasic = async () => {
    setFormError(null, null);
    const requiredFieldErrorMessage = t('EDIT_STEP_TEXT_CANNOT_BE_LEFT_BLANK');

    // Validation
    let errorField, errorMessage;
    if (!orgName) {
      errorField = 'name';
      errorMessage = requiredFieldErrorMessage;
    } else if (!country) {
      errorField = 'country';
      errorMessage = requiredFieldErrorMessage;
    }
    // Don't proceed if there's an error
    if (errorField) {
      okerror('Not submitting request due to error.', errorField, errorMessage);
      setFormError(errorField, errorMessage);
      return;
    }

    setLoading(true);

    const address = {
      countryCode: country,
    };

    const apiVariables = {
      address,
      name: orgName,
    };

    // Podio-related metadata
    const countryName = countries.find((c) => c.isoAlpha3 === country).name;
    const language = languageByIso(appLanguageIso);
    const timezoneUTCOffset = getTimezoneUTCOffset();
    apiVariables.address.country = countryName;
    apiVariables.languageName = language.name;
    apiVariables.languageIso = language.iso;
    apiVariables.timezone = timezoneUTCOffset;
    apiVariables.type = 'BASIC';

    createOrganisationBasicAPI({ variables: apiVariables }).finally(() => {
      setLoading(false);
    });
  };

  const createOrganisationTrial = async () => {
    setFormError(null, null);
    const requiredFieldErrorMessage = t('EDIT_STEP_TEXT_CANNOT_BE_LEFT_BLANK');

    // Validation
    let errorField, errorMessage;
    if (!orgName) {
      errorField = 'name';
      errorMessage = requiredFieldErrorMessage;
    } else if (!country) {
      errorField = 'country';
      errorMessage = requiredFieldErrorMessage;
    }
    // Don't proceed if there's an error
    if (errorField) {
      okerror('Not submitting request due to error.', errorField, errorMessage);
      setFormError(errorField, errorMessage);
      return;
    }

    setLoading(true);

    const address = {
      countryCode: country,
    };

    const apiVariables = {
      address,
      name: orgName,
    };

    // Podio-related metadata
    const countryName = countries.find((c) => c.isoAlpha3 === country).name;
    const language = languageByIso(appLanguageIso);
    const timezoneUTCOffset = getTimezoneUTCOffset();
    apiVariables.address.country = countryName;
    apiVariables.languageName = language.name;
    apiVariables.languageIso = language.iso;
    apiVariables.timezone = timezoneUTCOffset;

    createOrganisationTrialAPI({ variables: apiVariables }).finally(() => {
      setLoading(false);
    });
  };

  const setCountry = (c) => {
    _setCountry(c);
  };

  // Render

  const formFieldError = formError.message && <p className={styles.error}>{formError.message}</p>;

  return (
    <>
      <div className={styles.card}>
        <div className={styles.header}>
          <h3>{t('Try for free')}</h3>
          {!isComingFromUserPage && (
            <Button
              className={styles.actionButtonUpper}
              linkStyle
              onClick={() => {
                dispatch(showOnboarding(true));
                dispatch(showCreatingNewOrganisation(false));
              }}
            >
              {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_5_BUTTON_TEXT_3')}
            </Button>
          )}
        </div>
        <p className={styles.welcomeMessage}>{t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_1_NO_ORGANISATION_NOTICE')}</p>
        <Text style={{ marginBottom: 30 }} size='xs' bold>
          {tHTML('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_1_DESCRIPTION')}
        </Text>
      </div>
      <div>
        <div className={styles.inputField}>
          <h5 style={{ marginBottom: 10 }}>{t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_2_TEXT')}</h5>
          <Input
            hasError={formError.field === 'name'}
            onChange={(e) => onFormChange('name', e.target.value)}
            placeholder={t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_3_PLACEHOLDER_4')}
            type='text'
            value={orgName}
          />
          {formError.field === 'name' && formFieldError}
        </div>
        <div className={styles.inputFieldLast}>
          <h5 style={{ marginBottom: 10 }}>{t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_NOTICE_5')}</h5>
          <Select
            onChange={(c) => onFormChange('country', c)}
            options={countryList
              .map((c) => ({ value: c.isoAlpha3, label: c.name }))
              .sort((a, b) => {
                return a.label > b.label ? 1 : -1;
              })}
            value={country}
          />
          {formError.field === 'country' && formFieldError}
        </div>
        <Button
          style={{ marginBottom: 10 }}
          block
          disabled={loading}
          loading={loading}
          tint='creation'
          onClick={createOrganisationTrial}
          withCaret
        >
          {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_5_BUTTON_TEXT')}
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          block
          disabled={loading}
          loading={loading}
          onClick={createOrganisationBasic}
        >
          {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_5_BUTTON_TEXT_2')}
        </Button>
      </div>
      {!isComingFromUserPage && (
        <Button
          className={styles.actionButton}
          linkStyle
          iconPosition='left'
          onClick={() => {
            dispatch(showOnboarding(true));
            dispatch(showCreatingNewOrganisation(false));
          }}
          withCaret
        >
          {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_5_BUTTON_TEXT_3')}
        </Button>
      )}
      <Text style={!isComingFromUserPage ? { marginTop: 0 } : { marginTop: 30 }} size='xs'>
        {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_5_NOTICE')}
      </Text>
    </>
  );
}

export default OrganisationCreatingModule;
