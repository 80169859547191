export function dismissCookiesRequiredAlertAction() {
  return { type: DISMISS_COOKIES_REQUIRED_ALERT_ACTION };
}

export function dismissNotificationAction(notificationId) {
  return { type: DISMISS_NOTIFICATION_ACTION, data: { notificationId } };
}

export function dismissScannerAction() {
  return { type: DISMISS_SCANNER_ACTION };
}

export function resetScannedOKIDAction() {
  return { type: RESET_SCANNED_OKID_ACTION };
}

export function scannedOKIDAction(OKID) {
  return {
    type: SCANNED_OKID_ACTION,
    data: { OKID },
  };
}

export function search(searchQuery) {
  return {
    type: SEARCH,
    data: { searchQuery },
  };
}

export function setActiveSiteSection(activeSiteSection) {
  return {
    type: SET_ACTIVE_SITE_SECTION,
    data: { activeSiteSection },
  };
}

export function setUserPermitsCookies(permits) {
  return {
    type: SET_USER_PERMITS_COOKIES,
    data: { permits },
  };
}

export function showAuthModal(showAuthModal) {
  return { type: SHOW_AUTH_MODAL, data: { showAuthModal } };
}

export function showCookiesRequiredAlertAction() {
  return { type: SHOW_COOKIES_REQUIRED_ALERT_ACTION };
}

export function showNotificationAction(title, content) {
  return {
    type: SHOW_NOTIFICATION_ACTION,
    data: {
      notification: {
        content,
        createdAt: new Date(),
        dismissed: false,
        title,
      },
    },
  };
}

export function showPopup() {
  return {
    type: SHOW_POPUP,
  };
}

export function showScannerAction(onScan, instructions) {
  return {
    type: SHOW_SCANNER_ACTION,
    data: { onScan, instructions },
  };
}

export function updateMiscAppStateAction(stateUpdates) {
  return {
    type: MISC_APP_STATE_UPDATE,
    data: { stateUpdates },
  };
}

export function setLastVisitedSectionAction(section, path) {
  return {
    type: SET_LAST_VISITED_SECTION,
    data: { section, path },
  };
}

export function setBrowserHistoryAction(history) {
  return {
    type: SET_BROWSER_HISTORY,
    data: { history },
  };
}
export function setShowInPopup(showInPopup) {
  return {
    type: SHOW_IN_POPUP,
    data: { showInPopup },
  };
}
export function addHistory(entry) {
  return {
    type: ADD_HISTORY,
    data: { entry },
  };
}
export function clearHistory() {
  return {
    type: CLEAR_HISTORY,
  };
}
export function popHistory() {
  return {
    type: POP_HISTORY,
  };
}

// Action Types

export const DISMISS_COOKIES_REQUIRED_ALERT_ACTION = 'app/dismissCookiesRequiredAlert';
export const DISMISS_NOTIFICATION_ACTION = 'app/dismissNotification';
export const DISMISS_SCANNER_ACTION = 'app/dismissScanner';
export const MISC_APP_STATE_UPDATE = 'app/miscUpdate';
export const RESET_SCANNED_OKID_ACTION = 'app/resetScannedOKID';
export const SCANNED_OKID_ACTION = 'app/scannedOKID';
export const SEARCH = 'app/search';
export const SET_USER_PERMITS_COOKIES = 'app/userPermitsCookies';
export const SHOW_AUTH_MODAL = 'app/showAuthModal';
export const SHOW_COOKIES_REQUIRED_ALERT_ACTION = 'app/showCookiesRequiredAlert';
export const SHOW_NOTIFICATION_ACTION = 'app/showNotification';
export const SHOW_POPUP = 'app/showPopup';
export const SHOW_SCANNER_ACTION = 'app/showScanner';
export const SET_ACTIVE_SITE_SECTION = 'app/setActiveSiteSection';
export const SET_LAST_VISITED_SECTION = 'app/setLastVisitedSection';
export const SET_BROWSER_HISTORY = 'app/setBrowserHistory';
export const SHOW_IN_POPUP = 'app/showInPopup';
export const ADD_HISTORY = 'app/addHistory';
export const CLEAR_HISTORY = 'app/clearHistory';
export const POP_HISTORY = 'app/popHistory';
