import { gql } from '@apollo/client';

import UnversionedMediaAssetModel from '../unversionedMediaAsset';
import VersionedMediaAssetModel from '../versionedMediaAsset';

import BaseInspectionLogTestAssetMediaAssetModel from './base';

export default class InspectionLogTestAssetMediaAssetModel {
  static GRAPHQL_TYPE = BaseInspectionLogTestAssetMediaAssetModel.GRAPHQL_TYPE;

  static fragmentName = 'InspectionLogTestAssetMediaAsset_Fragment';
  static fragment = gql`
    fragment ${InspectionLogTestAssetMediaAssetModel.fragmentName} on ${InspectionLogTestAssetMediaAssetModel.GRAPHQL_TYPE} {
      ...${BaseInspectionLogTestAssetMediaAssetModel.fragmentName}
      unversionedMediaAsset {
        ...${UnversionedMediaAssetModel.fragmentName}
      }
      versionedMediaAsset {
        ...${VersionedMediaAssetModel.fragmentName}
      }
    }
    ${BaseInspectionLogTestAssetMediaAssetModel.fragment}
    ${UnversionedMediaAssetModel.fragment}
    ${VersionedMediaAssetModel.fragment}
  `;

  static fragmentNameOrder = 'InspectionLogTestAssetMediaAsset_Order_Fragment';
  static fragmentOrder = gql`
    fragment Order on ${InspectionLogTestAssetMediaAssetModel.GRAPHQL_TYPE} {
      order
    }
  `;
}
