import PropTypes from 'prop-types';

import styles from './styles.module.scss';

/**
 * @typedef {Object} ArchiveCardMainSectionProps
 * @prop {Node} children
 */

/**
 * Container for top of card.
 *
 * @param {ArchiveCardMainSectionProps} props
 */
export default function ArchiveCardMainSection(props) {
  const { children, className, ...otherProps } = props;

  // Classes
  let classNames = styles.mainSection;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <div className={classNames} {...otherProps}>
      {children}
    </div>
  );
}

ArchiveCardMainSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
