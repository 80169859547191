import PropTypes from 'prop-types';

import Button from '../button';
import Separator from '../separator';
import Text from '../text';

import styles from './styles.module.scss';

export default function LinkDisplayer(props) {
  const { className, isLast, title, subtitle, icon, onClickRemove, ...otherProps } = props;

  /* Render */

  let classNames = styles.orderDisplayer;

  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <div className={classNames} {...otherProps}>
      {icon && <img src={icon} alt='No image' width={48} height={48} />}
      <div>
        <Text className={styles.titleText} tint='navigation'>
          {title}
        </Text>
        <div className={styles.infoContainer}>
          <Text className={styles.subtitletext}>{subtitle}</Text>
          <Button className={styles.removeButton} linkStyle tint='alert' onClick={onClickRemove}>
            Remove
          </Button>
        </div>
        <div></div>
      </div>
      <Separator className={styles.separator} />
    </div>
  );
}

LinkDisplayer.propTypes = {
  className: PropTypes.string,
  isLast: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  onClickRemove: PropTypes.func,
};
