import PropTypes from 'prop-types';
import { forwardRef, useContext } from 'react';

import styles from './styles.module.scss';
import baseTint from './tints/base.module.scss';
import cardTint from './tints/card.module.scss';
import focusTint from './tints/focus.module.scss';
import midtoneTint from './tints/midtone.module.scss';

import UIContext from 'OK/util/context/ui';

/**
 *
 * @typedef {Object} TextProps
 * @prop {boolean} [bold=false] Show bold text.
 * @prop {Node} children
 * @prop {string} [className]
 * @prop {number} [lineLimit] Limit the number of lines to display. If text exceeds the line limit, an ellipsis will be
 * shown. See {@link https://css-tricks.com/line-clampin/#weird-webkit-flexbox-way}.
 * @prop {'md'|'sm'|'xs'} [size] Font size.
 * @prop {'alert'|'creation'|'navigation'|'notification'} [tint] Set the text color to a tint.
 */

/**
 * Component to display text.
 *
 * @param {TextProps} props
 */
const Text = forwardRef((props, forwardedRef) => {
  /* Variables */

  const { bold = false, children, className, lineLimit, size, tint, ...otherProps } = props;
  const uiContext = useContext(UIContext);

  /* Render */

  // Classes
  let classNames = styles.text;

  // Size
  switch (size) {
    case 'md':
    case 'sm':
    case 'xs':
      classNames = `${classNames} ${styles[size]}`;
      break;
    default:
      break;
  }

  // Tint
  let tintStyle;
  switch (uiContext) {
    case 'card':
      tintStyle = cardTint;
      break;
    case 'focus':
      tintStyle = focusTint;
      break;
    case 'midtone':
      tintStyle = midtoneTint;
      break;
    default:
      tintStyle = baseTint;
      break;
  }
  switch (tint) {
    case 'alert':
    case 'creation':
    case 'navigation':
    case 'notification':
      classNames = `${classNames} ${tintStyle[tint]}`;
      break;
    default:
      break;
  }

  if (bold) {
    classNames = `${classNames} ${styles.bold}`;
  }

  if (className) {
    classNames = `${classNames} ${className}`;
  }

  // Inline styles
  let inlineStyles = {};

  // Line limit
  if (lineLimit) {
    inlineStyles.display = '-webkit-box';
    inlineStyles.overflow = 'hidden';
    inlineStyles.WebkitBoxOrient = 'vertical';
    inlineStyles.WebkitLineClamp = lineLimit;
  }

  return (
    <p className={classNames} ref={forwardedRef} style={inlineStyles} {...otherProps}>
      {children}
    </p>
  );
});

Text.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  lineLimit: PropTypes.number,
  size: PropTypes.oneOf(['md', 'sm', 'xs']),
  tint: PropTypes.oneOf(['alert', 'creation', 'navigation', 'notification']),
};

export default Text;
