import { gql } from '@apollo/client';

import useI18n from 'OK/util/hooks/useI18n';

export default class LabelTemplateModel {
  static GRAPHQL_TYPE = 'LabelTemplate';

  static CORNERS = {
    NONE: 'NONE',
    MM_1: 'MM_1',
    MM_2: 'MM_2',
  };
  static EYELET = {
    NONE: 'NONE',
    INCLUDE: 'MM_3',
  };
  static MATERIAL = {
    CLEAR_STICKER: 'CLEAR_STICKER',
    KRAFT_PAPER: 'KRAFT_PAPER',
    METALLIC_STICKER: 'METALLIC_STICKER',
    PAPER: 'PAPER',
    TEXTILE: 'TEXTILE',
    THERMAL_TRANSFER: 'THERMAL_TRANSFER',
  };
  static SIZE = {
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE',
  };
  static TYPE = {
    ON_SURFACE: 'ON_SURFACE',
    STICKER: 'STICKER',
    TAG: 'TAG',
  };

  static fragmentName = 'LabelTemplate_Fragment';
  static fragment = gql`
    fragment ${LabelTemplateModel.fragmentName} on ${LabelTemplateModel.GRAPHQL_TYPE} {
      corners
      createdBy
      createdDate
      eyelet
      id
      languageCode
      lastModifiedBy
      lastModifiedDate
      material
      organisationId
      size
      type
      version
    }
  `;

  static dimensionsForSize = (size) => {
    switch (size) {
      case LabelTemplateModel.SIZE.SMALL:
        return {
          height: 9,
          width: 42,
        };
      case LabelTemplateModel.SIZE.MEDIUM:
        return {
          height: 54,
          width: 26,
        };
      case LabelTemplateModel.SIZE.LARGE:
        return {
          height: 95,
          width: 50,
        };
      default:
        return {
          height: 0,
          width: 0,
        };
    }
  };

  static previewImageUrl = (labelTemplate) => {
    let filename = `${labelTemplate.size.toLowerCase()}`;

    if (labelTemplate.material === LabelTemplateModel.MATERIAL.THERMAL_TRANSFER) {
      filename += '_direct';
    } else {
      if (labelTemplate.corners !== LabelTemplateModel.CORNERS.NONE) {
        filename += '_rounded';
      }
      if (labelTemplate.eyelet !== LabelTemplateModel.EYELET.NONE) {
        filename += '_eyelet';
      }

      switch (labelTemplate.material) {
        case LabelTemplateModel.MATERIAL.CLEAR_STICKER:
          filename += '_opaque';
          break;
        case LabelTemplateModel.MATERIAL.KRAFT_PAPER:
          filename += '_kraft';
          break;
        case LabelTemplateModel.MATERIAL.METALLIC_STICKER:
          filename += '_metallic';
          break;
        default:
          filename += '_paper';
          break;
      }

      switch (labelTemplate.type) {
        case LabelTemplateModel.TYPE.STICKER:
          filename += '_sticker';
          break;
        default:
          filename += '_tag';
          break;
      }
    }

    return `/img/label_previews/${filename}@2x.jpg`;
  };
}

export function LabelTemplateFeaturesString(props) {
  const { labelTemplate } = props;
  const { t } = useI18n();

  const hasRoundedCorners = labelTemplate.corners !== 'NONE';
  const hasEyelet = labelTemplate.eyelet !== 'NONE';

  if (hasRoundedCorners && hasEyelet) {
    return t('LABEL_ARCHIVE_CARD_FEATURES_CORNERS_EYELET');
  }
  if (hasRoundedCorners) {
    return t('LABEL_ARCHIVE_CARD_FEATURES_CORNERS');
  }
  if (hasEyelet) {
    return t('LABEL_ARCHIVE_CARD_FEATURES_EYELET');
  }
  return '';
}

export function LabelTemplateName(props) {
  const { labelTemplate } = props;
  const { t } = useI18n();

  return t(`LABEL_TEMPLATE_${labelTemplate.size}_${labelTemplate.material}_${labelTemplate.type}`);
}
