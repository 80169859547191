/* eslint-disable indent */

import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Accordion from 'OK/components/accordion';
import { Carousel, Slide } from 'OK/components/carousel';
import { Carousel as OldCarousel, Slide as OldSlide } from 'OK/components/carousel/old';
import Icon, { ICONS } from 'OK/components/icon';
import InvoiceCard from 'OK/components/invoice/card';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import { LinkNoRedux } from 'OK/components/link';
import Notice from 'OK/components/notice';
import Separator from 'OK/components/separator';
import { BrandSubscription, EnterpriseSubscription, FreeSubscription } from 'OK/components/subscription';
import Text from 'OK/components/text';
import Toggle from 'OK/components/toggle';
import OrganisationModel from 'OK/models/organisation';
import { setOrganisationSubscriptionTypeMutation } from 'OK/networking/organisations';
import UIContext from 'OK/util/context/ui';
import { formatDate } from 'OK/util/formatting';
import { encodeQueryParametersFromObject } from 'OK/util/functions/url';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';

export default function OrganisationPlanSection(props) {
  /* Variables */
  const { activeOrganisation, showSection, openSection } = props;
  const [authenticated, ,] = useAuthentication();

  const { ref } = props;
  const { t, tHTML, locale } = useI18n();
  const defaultContentWidth = useSelector((state) => state.app.defaultContentWidth);
  const router = useRouter();
  const scrollToSection = router.query.section;

  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const isOnTrial = activeOrganisation?.type === OrganisationModel.SUBSCRIPTION_TYPE.TRIAL;
  const isOnFreePlan = activeOrganisation?.type === OrganisationModel.SUBSCRIPTION_TYPE.FREE;
  const isOnBrandPlan =
    activeOrganisation?.type === OrganisationModel.SUBSCRIPTION_TYPE.ANNUAL ||
    activeOrganisation?.type === OrganisationModel.SUBSCRIPTION_TYPE.MONTHLY ||
    activeOrganisation?.type === OrganisationModel.SUBSCRIPTION_TYPE.TRIAL;
  const isOnEnterprisePlan = false;

  // State

  const [showAnnualPricing, setShowAnnualPricing] = useState(true);
  const [switchPlanError, setSwitchPlanError] = useState(null);
  const [switchingToPlan, setSwitchingToPlan] = useState(null);

  // Refs

  const billsSectionRef = useRef();
  const plansCarouselRef = useRef();
  const planSectionRef = useRef();
  const searchUsersInputRef = useRef();

  /* API */

  const [setOrganisationSubscriptionAPI] = useMutation(setOrganisationSubscriptionTypeMutation);

  /* Methods */

  const setSubscription = useCallback(
    (subscription) => {
      setSwitchPlanError(null);
      setSwitchingToPlan(subscription);
      setOrganisationSubscriptionAPI({
        variables: { type: subscription },
      })
        .catch(() => {
          setSwitchPlanError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setSwitchingToPlan(null);
        });
    },
    [setOrganisationSubscriptionAPI, t]
  );

  /* Effects */

  // Auto scroll to current subscription plan
  useLayoutEffect(() => {
    if (useMobileLayout) {
      if (isOnBrandPlan) {
        setTimeout(() => {
          plansCarouselRef.current.scrollToSlideAtIndex(1, true);
        }, 500);
      } else if (isOnEnterprisePlan) {
        setTimeout(() => {
          plansCarouselRef.current.scrollToSlideAtIndex(2, true);
        }, 500);
      }
    }
  }, [isOnBrandPlan, isOnEnterprisePlan, useMobileLayout]);

  // Auto scroll to section
  useEffect(() => {
    if (authenticated && activeOrganisation && scrollToSection && router.pathname === '/organisation/[OKID]') {
      let element;
      if (scrollToSection === 'billing') {
        element = billsSectionRef.current;
      } else if (scrollToSection === 'plan') {
        element = planSectionRef.current;
      }

      if (element) {
        // Timeout needed to prevent page re-scrolling to top
        setTimeout(() => {
          window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
        }, 250);
      }

      // Remove section query parameter
      const newUrlQuery = { ...router.query };
      delete newUrlQuery.section;
      const newUrlQueryString = encodeQueryParametersFromObject(newUrlQuery);
      if (newUrlQueryString.length) {
        router.replace(`/${router.pathname}?${newUrlQueryString}`);
      } else {
        router.replace(router.pathname);
      }
    }
  }, [activeOrganisation, authenticated, router, scrollToSection]);

  /* Render */

  const planName = isOnBrandPlan
    ? t('PLAN_NAME_BRAND')
    : isOnEnterprisePlan
    ? t('PLAN_NAME_ENTERPRISE')
    : t('PLAN_NAME_FREE');

  const logLifeNotice = (
    <Notice className={styles.logLifeNotice} extendSides={useMobileLayout}>
      <Text bold>{t('10_YEAR_LOG_LIFE')}</Text>
      <Text>{t('10_YEAR_LOG_LIFE_EXPLANATION')}</Text>
    </Notice>
  );

  if (!authenticated || !activeOrganisation) {
    return (
      <ContentLayout className={styles.identificationSection}>
        <TextLayout>
          <Icon height={40} name={ICONS.SPINNER.name} width={40} />
        </TextLayout>
      </ContentLayout>
    );
  }

  return (
    <>
      <Accordion
        className={styles.accordion}
        fixedWidth={false}
        onChangeOpen={openSection}
        headerClassName={styles.headerAccordion}
        hideSection
        open={showSection}
        title={
          <div className={styles.header}>
            <Icon className={styles.icon} name={ICONS.PLAN.name} height={24} width={24} />
            &nbsp;&nbsp;
            {t('PLAN')}
          </div>
        }
      >
        <ContentLayout className={styles.manageSection} ref={ref} pageContent>
          <UIContext.Provider value='base'>
            {useDesktopLayout && !isOnFreePlan && logLifeNotice}
            <Text bold className={styles.currentPlanStatus}>
              {isOnTrial
                ? t('PLAN_STATUS_TRIAL_WILL_END_ON_DATE', {
                    data: {
                      planName,
                      date: formatDate(activeOrganisation.trialEndDate, locale, { style: 'short' }),
                    },
                  })
                : t('PLAN_STATUS_CURRENTLY_ON_PLAN', {
                    data: {
                      planName,
                    },
                  })}
            </Text>
            {useMobileLayout && !isOnFreePlan && logLifeNotice}
            <Text className={styles.currentSubscriptionDescription}>
              {isOnFreePlan
                ? t('PLAN_STATUS_DESCRIPTION_FREE')
                : isOnTrial
                ? t('PLAN_STATUS_DESCRIPTION_PAID_TRIAL', {
                    data: {
                      planName,
                    },
                  })
                : t('PLAN_STATUS_DESCRIPTION_PAID', {
                    data: {
                      planName,
                    },
                  })}
            </Text>

            <div className={styles.annualPricingToggleSection}>
              <div className={styles.annualPricingToggleContainer}>
                <Text bold className={styles.annualPricingLabel}>
                  {t('HOMEPAGE_PRICING_SECTION_SHOW_ANNUAL_PRICING')}
                </Text>
                <Toggle
                  className={styles.annualPricingToggle}
                  checked={showAnnualPricing}
                  onChange={() => setShowAnnualPricing(!showAnnualPricing)}
                />
              </div>
            </div>
            <OldCarousel
              extendSides
              ref={plansCarouselRef}
              showSlideIndicators={useMobileLayout}
              sidePadding={10}
              slideSideMargin={10}
              slideWidth={useDesktopLayout ? 440 : defaultContentWidth}
            >
              <OldSlide className={styles.pricingSlide}>
                <FreeSubscription
                  showButton={false}
                  tooltipMessage={isOnFreePlan ? t('CURRENT_PLAN') : null}
                  tooltipTint={null}
                />
              </OldSlide>
              <OldSlide className={styles.pricingSlide}>
                <BrandSubscription
                  buttonLink='/solutions/contact'
                  buttonTint={isOnBrandPlan && isOnTrial ? 'creation' : 'navigation'}
                  buttonTitle={isOnBrandPlan && isOnTrial ? t('EXTEND_BEYOND_TRIAL') : t('CONTACT_US')}
                  onClickSubscribe={
                    isOnBrandPlan && isOnTrial
                      ? () => {
                          const pdfUrl = showAnnualPricing
                            ? activeOrganisation.paymentInstructionForBrandAnnualPDFURL
                            : activeOrganisation.paymentInstructionForBrandMonthlyPDFURL;
                          window.open(pdfUrl, '_blank');
                        }
                      : null
                  }
                  showAnnualPricing={showAnnualPricing}
                  showButton={!isOnBrandPlan || (isOnBrandPlan && isOnTrial)}
                  tooltipMessage={
                    isOnBrandPlan
                      ? t('CURRENT_PLAN')
                      : isOnFreePlan && showAnnualPricing
                      ? t('SUBSCRIPTION_CARD_SAVE_OVER_50')
                      : null
                  }
                  tooltipTint={isOnBrandPlan ? null : 'creation'}
                />
              </OldSlide>
              <OldSlide className={styles.pricingSlide}>
                <EnterpriseSubscription
                  buttonLink='/solutions/contact'
                  buttonTint={isOnEnterprisePlan && isOnTrial ? 'creation' : 'navigation'}
                  buttonTitle={isOnEnterprisePlan && isOnTrial ? t('EXTEND_BEYOND_TRIAL') : t('CONTACT_US')}
                  showAnnualPricing={showAnnualPricing}
                  showButton={!isOnEnterprisePlan || (isOnEnterprisePlan && isOnTrial)}
                  tooltipMessage={isOnEnterprisePlan ? t('CURRENT_PLAN') : null}
                  tooltipTint={null}
                />
              </OldSlide>
            </OldCarousel>
            <TextLayout>
              {switchPlanError && (
                <Text className={styles.pricingError} size='sm' tint='notification'>
                  {switchPlanError}
                </Text>
              )}
              <Text bold className={styles.pricingFinePrint} size='xs'>
                {t('PRICES_EXCLUDING_VAT')}
                <br />
                {tHTML('TERMS_APPLY_WITH_LINK', {
                  data: {
                    termsLink: (
                      <LinkNoRedux className={styles.termsLink} href='/solutions/terms'>
                        {t('TERMS_OF_SERVICE')}
                      </LinkNoRedux>
                    ),
                  },
                })}
              </Text>
              <Separator type='section' />
              <h4 ref={billsSectionRef}>Billing</h4>
            </TextLayout>
            {activeOrganisation?.metadata?.invoiceList?.length > 0 ? (
              <Carousel className={styles.invoicesCarousel} innerClassName={styles.carousel}>
                {activeOrganisation.metadata.invoiceList.map((i) => {
                  return (
                    <Slide className={styles.invoiceSlide} key={i.invoiceId}>
                      <InvoiceCard className={styles.invoiceCard} fixedWidth={false} invoice={i} />
                    </Slide>
                  );
                })}
              </Carousel>
            ) : (
              <TextLayout>
                <Text>No invoices.</Text>
              </TextLayout>
            )}
          </UIContext.Provider>
        </ContentLayout>
      </Accordion>
    </>
  );
}

OrganisationPlanSection.propTypes = {
  ref: PropTypes.any,
  activeOrganisation: PropTypes.object,
  openSection: PropTypes.func,
  showSection: PropTypes.bool,
};
