export function indexOfLinkedAssetInCacheList(list, assetId, assetKey, readField) {
  return list.findIndex((a) => {
    const listAsset = readField(assetKey, a);
    const listAssetId = readField('id', listAsset);
    return listAssetId === assetId;
  });
}

export function reorderAssetBackwardInList(list, assetWithNewOrder) {
  const originalAssetOrder = assetWithNewOrder.order + 1;
  const originalIndexBeforeMovedMedia = originalAssetOrder - 2;
  const updatedList = [...list];
  const [beforeMedia, movedMedia] = updatedList.splice(originalIndexBeforeMovedMedia, 2);
  const updatedMovedMedia = {
    ...movedMedia,
    order: assetWithNewOrder.order,
  };
  const updatedBeforeMedia = {
    ...beforeMedia,
    order: originalAssetOrder,
  };
  updatedList.splice(originalIndexBeforeMovedMedia, 0, updatedMovedMedia, updatedBeforeMedia);
  return updatedList;
}

export function reorderAssetForwardInList(list, assetWithNewOrder) {
  const originalAssetOrder = assetWithNewOrder.order - 1;
  const originalIndexOfMovedAsset = originalAssetOrder - 1;
  const updatedList = [...list];
  const [movedAsset, afterAsset] = updatedList.splice(originalIndexOfMovedAsset, 2);
  const updatedMovedAsset = {
    ...movedAsset,
    order: assetWithNewOrder.order,
  };
  const updatedAfterAsset = {
    ...afterAsset,
    order: originalAssetOrder,
  };
  updatedList.splice(originalIndexOfMovedAsset, 0, updatedAfterAsset, updatedMovedAsset);
  return updatedList;
}
