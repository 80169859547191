import { gql } from '@apollo/client';

import { request } from '..';
import { API_URL } from '../utils';

import OrganisationModel from 'OK/models/organisation';
import BaseOrganisationModel from 'OK/models/organisation/base';
import BaseUserDocumentModel from 'OK/models/userDocument/base';

export const EditUserDocumentPopupQuery = gql`
  query EditUserDocumentPopupQuery($documentAssetId: String!) {
    document: userDocumentAsset(documentAssetId: $documentAssetId) {
      ...${BaseUserDocumentModel.fragmentName}
      madeByOrganisation {
        ...${OrganisationModel.fragmentNameArchiveCard}
      }
      organisation {
        ...${BaseOrganisationModel.fragmentName}
      }

    }
  }
  ${BaseUserDocumentModel.fragment}
  ${BaseOrganisationModel.fragment}
  ${OrganisationModel.fragmentArchiveCard}

`;

export const changeUserDocumentTypeMutation = gql`
  mutation ChangeUserDocumentType($documentAssetId: String!, $documentType: EDocumentType!) {
    document: changeUserDocumentType(documentAssetId: $documentAssetId, documentType: $documentType) {
      id
      documentType
    }
  }
`;

export const addUserDocumentCoverLanguageMutation = gql`
  mutation AddUserDocumentCoverLanguage($documentAssetId: String!, $languageCode: EDocumentType!) {
    document: addUserDocumentCoverLanguage(documentAssetId: $documentAssetId, languageCode: $languageCode) {
      id
      coveredLanguageList
    }
  }
`;

export const removeUserDocumentCoverLanguageMutation = gql`
  mutation RemoveUserDocumentCoverLanguage($documentAssetId: String!, $languageCode: EDocumentType!) {
    document: removeUserDocumentCoverLanguage(documentAssetId: $documentAssetId, languageCode: $languageCode) {
      id
      coveredLanguageList
    }
  }
`;

export const addUserDocumentApplicableMarketMutation = gql`
  mutation AddUserDocumentApplicableMarket($documentAssetId: String!, $marketIso: EMarketCode!) {
    document: addUserDocumentApplicableMarket(documentAssetId: $documentAssetId, marketCode: $marketIso) {
      id
      applicableMarketList
    }
  }
`;

export const removeUserDocumentApplicableMarketMutation = gql`
  mutation RemoveUserDocumentApplicableMarket($documentAssetId: String!, $marketIso: EMarketCode!) {
    document: removeUserDocumentApplicableMarket(documentAssetId: $documentAssetId, marketCode: $marketIso) {
      id
      applicableMarketList
    }
  }
`;

export const setUserDocumentNumberMutation = gql`
  mutation SetUserDocumentNumber($documentAssetId: String!, $documentNumber: String!) {
    document: setUserDocumentNumber(documentAssetId: $documentAssetId, documentNumber: $documentNumber) {
      id
      documentNumber
    }
  }
`;

export const removeUserDocumentNumberMutation = gql`
  mutation RemoveUserDocumentNumber($documentAssetId: String!) {
    document: removeUserDocumentNumber(documentAssetId: $documentAssetId) {
      id
      documentNumber
    }
  }
`;

export const setUserDocumentIssueDateMutation = gql`
  mutation SetUserDocumentIssueDate($documentAssetId: String!, $issueDate: String!) {
    document: setUserDocumentIssueDate(documentAssetId: $documentAssetId, dateUTCString: $issueDate) {
      id
      issueDate
    }
  }
`;

export const removeUserDocumentIssueDateMutation = gql`
  mutation RemoveUserDocumentIssueDate($documentAssetId: String!) {
    document: removeUserDocumentIssueDate(documentAssetId: $documentAssetId) {
      id
      issueDate
    }
  }
`;

export const setUserDocumenValidFromMutation = gql`
  mutation SetUserDocumentValidFrom($documentAssetId: String!, $validFrom: String!) {
    document: setUserDocumentValidFrom(documentAssetId: $documentAssetId, dateUTCString: $validFrom) {
      id
      validFrom
    }
  }
`;

export const removeUserDocumenValidFromMutation = gql`
  mutation RemoveUserDocumentValidFrom($documentAssetId: String!) {
    document: removeUserDocumentValidFrom(documentAssetId: $documentAssetId) {
      id
      validFrom
    }
  }
`;

export const setUserDocumentValidUntilMutation = gql`
  mutation SetUserDocumentValidUntil($documentAssetId: String!, $validTill: String!) {
    document: setUserDocumentValidUntil(documentAssetId: $documentAssetId, dateUTCString: $validTill) {
      id
      validTill
    }
  }
`;

export const removeUserDocumentValidUntilMutation = gql`
  mutation RemoveUserDocumentValidUntil($documentAssetId: String!) {
    document: removeUserDocumentValidUntil(documentAssetId: $documentAssetId) {
      id
      validTill
    }
  }
`;

export const setUserDocumentMadeByOrganisationMutation = gql`
  mutation SetUserDocumentMadeByOrganisation($documentAssetId: String!, $organisationId: String!) {
    document: setUserDocumentMadeByOrganisation(
      documentAssetId: $documentAssetId
      madeByOrganisationId: $organisationId
    ) {
      id
      madeByOrganisationId
      madeByOrganisation {
        ...${OrganisationModel.fragmentNameArchiveCard}
      }
    }
  }
  ${OrganisationModel.fragmentArchiveCard}
`;

export const removeUserDocumentMadeByOrganisationMutation = gql`
  mutation RemoveUserDocumentMadeByOrganisation($documentAssetId: String!) {
    document: removeUserDocumentMadeByOrganisation(documentAssetId: $documentAssetId) {
      id
      madeByOrganisationId
      madeByOrganisation {
        ...${OrganisationModel.fragmentNameArchiveCard}
      }
    }
  }
  ${OrganisationModel.fragmentArchiveCard}
`;

export const addUserDocumentStandardMutation = gql`
  mutation AddUserDocumentStandard($documentAssetId: String!, $standardId: EUserDocumentStandard!) {
    document: addUserDocumentStandard(documentAssetId: $documentAssetId, userDocumentStandard: $standardId) {
      id
      userDocumentStandardList
    }
  }
`;

export const removeUserDocumentStandardMutation = gql`
  mutation RemoveUserDocumentStandard($documentAssetId: String!, $userDocumentStandard: EUserDocumentStandard!) {
    document: removeUserDocumentStandard(
      documentAssetId: $documentAssetId
      userDocumentStandard: $userDocumentStandard
    ) {
      id
      userDocumentStandardList
    }
  }
`;

export const setUserDocumentMetadataMutation = gql`
  mutation SetUserDocumentMetada($documentAssetId: String!, $key: String!, $value: String!) {
    document: setUserDocumentMetadata(documentAssetId: $documentAssetId, key: $key, value: $value) {
      id
      metadata
    }
  }
`;

export const removeUserDocumentMetadataMutation = gql`
  mutation RemoveUserDocumentMetada($documentAssetId: String!, $key: String!) {
    document: removeUserDocumentMetadata(documentAssetId: $documentAssetId, key: $key) {
      id
      metadata
    }
  }
`;

export const publishUserDocumentMutation = gql`
  mutation PublishUserDocument($documentId: String!) {
    document: publishUserDocument(documentAssetId: $documentId) {
      id
      publishStatus
    }
  }
`;

export const unpublishUserDocumentMutation = gql`
  mutation UnublishUserDocument($documentId: String!) {
    document: unpublishUserDocument(documentAssetId: $documentId) {
      id
      publishStatus
    }
  }
`;

export const publishUserDocumentAsOrganisationManagerMutation = gql`
  mutation PublishUserDocumentAsOrganisationManager($documentId: String!) {
    document: publishUserDocumentAsOrganisationManager(documentAssetId: $documentId) {
      id
      organisationPublishStatus
    }
  }
`;

export const unpublishUserDocumentAsOrganisationManagerMutation = gql`
  mutation UnublishUserDocumentAsOrganisationManager($documentId: String!) {
    document: unpublishUserDocumentAsOrganisationManager(documentAssetId: $documentId) {
      id
      organisationPublishStatus
    }
  }
`;

export const deleteUserDocumentAssetMutation = gql`
  mutation DeleteUserDocumentAsset($documentId: String!) {
    document: deleteUserDocumentAsset(documentAssetId: $documentId) {
      id
    }
  }
`;

/* Requests */

export async function getDocumentAssetSpecListRequestUserDocument() {
  return request(`${API_URL}/utility/document/user/getDocumentAssetSpecList`, {
    method: 'GET',
  });
}

export async function getDocumentAssetStandardListRequestUserDocument() {
  return request(`${API_URL}/utility/document/user/getDocumentAssetStandardList`, {
    method: 'GET',
  });
}

export async function replaceUserDocumentFileRequest(documentId, file) {
  const metadata = {
    filesize: file.size,
  };
  return request(`${API_URL}/utility/document/user/replaceUserDocumentAssetFileWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters: {
      userDocumentAssetId: documentId,
      userDocumentFile: file,
      metadataString: JSON.stringify(metadata),
    },
  });
}
