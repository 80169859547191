import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Grade from 'OK/components/grade';
import LinkToOrganisation from 'OK/components/link/linkToOrganisation';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import useI18n from 'OK/util/hooks/useI18n';

export default function OrganisationMiniSummary(props) {
  /* Variables */

  const { className, organisation = {}, renderAsLink = false, size = 'md', ...otherProps } = props;
  const { addressList, conformityPoint, name, reliabilityPointForPublishedLogs } = organisation;
  const { t } = useI18n();

  /* Render */

  // Main content
  const content = (
    <>
      <Text className={styles.organisationName} size={size}>
        {name}
        <Tag className={styles.organisationTag} size={size}>
          {addressList[0].countryCode}
        </Tag>
      </Text>
      <div>
        <Grade className={styles.grade} score={t('TBC')} size={size} type='sustainability' />
        <Grade className={styles.grade} score={reliabilityPointForPublishedLogs} size={size} type='reliability' />
        <Grade className={styles.grade} score={conformityPoint} size={size} type='conformity' />
      </div>
    </>
  );

  // Classes
  let classNames = className;

  // Render as link
  if (renderAsLink) {
    classNames = `${classNames} ${styles.organisationSummaryLink}`;

    return (
      <LinkToOrganisation className={classNames} organisation={organisation} {...otherProps}>
        {content}
      </LinkToOrganisation>
    );
  }

  // Normal render
  return (
    <div className={classNames} {...otherProps}>
      {content}
    </div>
  );
}

OrganisationMiniSummary.propTypes = {
  className: PropTypes.string,
  organisation: PropTypes.any.isRequired,
  renderAsLink: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
};
