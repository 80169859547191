/* Action types */

export const BEGIN_LOGIN_ACTION = 'onboarding/beginLogin';
export const CREATE_ORGANISATION_ACTION = 'organisations/create';
export const END_LOGIN_ACTION = 'onboarding/endLogin';
export const INCLUDE_TRIAL_ACTION = 'onboarding/includeTrial';
export const UNSET_TRIAL_ACTION = 'onboarding/unsetTrial';

/* Action creators */

export function beginLoginAction() {
  return { type: BEGIN_LOGIN_ACTION };
}

export function createOrganisationAction(organisation) {
  return { type: CREATE_ORGANISATION_ACTION, data: organisation };
}

export function endLoginAction() {
  return { type: END_LOGIN_ACTION };
}

export function includeTrialAction(plan) {
  return { type: INCLUDE_TRIAL_ACTION, data: plan };
}

export function unsetTrialAction() {
  return { type: UNSET_TRIAL_ACTION };
}
