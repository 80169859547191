import PropTypes from 'prop-types';
import { forwardRef, useContext, useMemo } from 'react';

import Input from '../index';

import styles from './styles.module.scss';

import ThemeContext from 'OK/util/context/theme';
import useI18n from 'OK/util/hooks/useI18n';

const SearchInput = forwardRef((props, ref) => {
  const {
    loading = false,
    onClickSearch,
    showClearButton: showClearButtonProp = true,
    placeholder,
    value,
    withScan = false,
    ...otherProps
  } = props;

  const theme = useContext(ThemeContext);
  const { t } = useI18n();

  let icon;
  if (loading) {
    icon = `/icons/spinner_${theme.name}.svg`;
  } else {
    icon = withScan ? (
      <button className={styles.searchButton} onClick={onClickSearch}>
        <img alt={t('IMG_ALT_SEARCH')} className={styles.searchIcon} src={`/icons/camera_${theme.name}.svg`} />
      </button>
    ) : (
      <button className={styles.searchButton} onClick={onClickSearch}>
        <img alt={t('IMG_ALT_SEARCH')} className={styles.searchIcon} src={`/icons/search_${theme.name}.svg`} />
      </button>
    );
  }

  const showClearButton = useMemo(() => {
    if (loading) {
      return false;
    }

    return showClearButtonProp && !!value;
  }, [loading, showClearButtonProp, value]);

  return (
    <Input
      enterKeyHint='search'
      placeholder={placeholder}
      ref={ref}
      rotateIcon={loading}
      showClearButton={showClearButton}
      value={value}
      withIcon={icon}
      {...otherProps}
    />
  );
});

SearchInput.propTypes = {
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  onClickSearch: PropTypes.func,
  showClearButton: PropTypes.bool,
  value: PropTypes.string,
  withScan: PropTypes.bool,
};

export default SearchInput;
