import { gql, useApolloClient } from '@apollo/client';
import { useMemo } from 'react';

import OrganisationModel from 'OK/models/organisation';
import hasPermission, { OBJECT_PERMISSIONS, PERMISSIONS, hasPermissionForObject } from 'OK/util/functions/permissions';

const activeOrganisationFragment = gql`
  fragment ActiveOrganisationPermissionsFragment on Organisation {
    id
    inviteUserList {
      id
    }
    permissionList
    type
    userList {
      id
    }
    userJoinRequestList {
      id
    }
  }
`;

/**
 * Check if a user is permitted to do something.
 *
 * Optionally pass an object to check if the user has permissions for that object.
 *
 * @param {string} permission - The permission to check.
 * @param {object} user - The user to check permissions for.
 * @param {string} activeOrganisationId - The current active organisation id.
 * @param {object} [object] - The object to check if the user has permissions for.
 *
 * @returns {boolean} Whether permission is granted.
 */
export default function usePermission(permission, user, activeOrganisationId, object) {
  const apolloClient = useApolloClient();

  const permissionGranted = useMemo(() => {
    if (permission && user) {
      const activeOrganisation = apolloClient.readFragment({
        fragment: activeOrganisationFragment,
        id: `${OrganisationModel.GRAPHQL_TYPE}:${activeOrganisationId}`,
        returnPartialData: true,
      });

      if (object) {
        return hasPermissionForObject(permission, user, activeOrganisation, object);
      }

      return hasPermission(permission, user, activeOrganisation);
    }

    return false;
  }, [activeOrganisationId, apolloClient, object, permission, user]);

  return permissionGranted;
}

// Re-export PERMISSIONS for convenience
export { OBJECT_PERMISSIONS, PERMISSIONS };
