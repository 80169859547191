import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import TestAssetModel from 'OK/models/testAsset';
import { baseTheme } from 'OK/styles/theme';

/**
 * Render an icon to indicate a test's severity level.
 *
 * @param {object} props
 * @param {boolean} [props.boxOnly] Render the box without the text indicating severity level.
 * @param {string} [props.className]
 * @param {'CRITICAL'|'MAJOR'|'MINOR'} props.level The test's severity level.
 */
export default function TestSeverityIcon(props) {
  /* Variables */

  const { boxOnly = false, className, iconSize = 10, level } = props;

  /* Render */

  // Classes
  let classNames = styles.testSeverityIcon;

  // Level
  let icon;
  switch (level) {
    case 'critical':
    case TestAssetModel.SEVERITY_LEVEL.CRITICAL:
      icon = ICONS.SEVERITY_LEVEL_CRITICAL.name;
      break;
    case 'major':
    case TestAssetModel.SEVERITY_LEVEL.MAJOR:
      icon = ICONS.SEVERITY_LEVEL_MAJOR.name;
      break;
    case 'minor':
    case TestAssetModel.SEVERITY_LEVEL.MINOR:
      icon = ICONS.SEVERITY_LEVEL_MINOR.name;
      break;
    default:
      okerror('TestSeverityIcon - Invalid level provided.');
      break;
  }

  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <span className={classNames}>
      {!boxOnly && <Icon color={baseTheme.colors.textAlternate} height={iconSize} name={icon} width={iconSize} />}
    </span>
  );
}

TestSeverityIcon.propTypes = {
  boxOnly: PropTypes.bool,
  className: PropTypes.string,
  iconSize: PropTypes.number,
  level: PropTypes.oneOf([
    TestAssetModel.SEVERITY_LEVEL.CRITICAL,
    TestAssetModel.SEVERITY_LEVEL.MAJOR,
    TestAssetModel.SEVERITY_LEVEL.MINOR,
  ]).isRequired,
};
