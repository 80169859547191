import { gql } from '@apollo/client';

import DocumentModel from 'OK/models/document';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import InspectionLogModel from 'OK/models/inspectionLog';
import ItemModel from 'OK/models/item';
import LabelModel from 'OK/models/label';
import MediaAssetModel from 'OK/models/mediaAsset';
import BaseMediaAssetModel, {
  AudioMediaAssetModel,
  ImageMediaAssetModel,
  VideoMediaAssetModel,
} from 'OK/models/mediaAsset/base';
import NoteModel from 'OK/models/note';
import BaseOrganisationModel from 'OK/models/organisation/base';
import ProductModel from 'OK/models/product';
import SiteModel from 'OK/models/site';
import TestAssetModel from 'OK/models/testAsset';
import BaseUserModel from 'OK/models/user/base';

/* Queries */

export const ArchiveSearchFilesQuery = gql`
  query ArchiveSearchFiles(
    $includeSharedAssets: Boolean
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
  ) {
    search(
      includeSharedAsset: $includeSharedAssets
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchSortType: $sort
    ) {
      resultList {
        dataId
        dataType
        documentAssetData {
          REFID
          conformityPoint
          coveredLanguageList
          documentName
          documentURL
          documentType
          id
          linkedProductList {
            id
          }
          organisation {
            name
            OKID
          }
        }
      }
      searchPaginationResultDataByDataType
    }
  }
`;

export const ArchiveSearchLabelsQuery = gql`
  query ArchiveSearchLabels(
    $includeSharedAssets: Boolean
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
  ) {
    search(
      includeSharedAsset: $includeSharedAssets
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchSortType: $sort
    ) {
      resultList {
        dataId
        dataType
        labelData {
          assetAccessPermission
          organisationId
      assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
          createdDate
      id
      REFID
      itemList {
        id
        OKID
        reliabilityPointForPublishedLogs
        product {
          conformityPoint
          name
          organisation {
            addressList {
              countryCode
            }
            id
          }
          REFID
        }
      }
      labelTemplate {
        corners
        eyelet
        id
        languageCode
        material
        size
        type
      }
      product {
        name
        organisation {
          name
          OKID
        }
        REFID
        productMediaAssetList {
            mediaAsset {
              audioData {
                ...${AudioMediaAssetModel.fragmentName}
              }
              id
              imageData {
                ...${ImageMediaAssetModel.fragmentName}
              }
              mediaType
              videoData {
                ...${VideoMediaAssetModel.fragmentName}
              }
            }
          }
      }
        }
      }
      searchPaginationResultDataByDataType
    }
  }
  ${AudioMediaAssetModel.fragment}
  ${ImageMediaAssetModel.fragment}
  ${VideoMediaAssetModel.fragment}
`;

export const ArchiveSearchMediaQuery = gql`
  query ArchiveSearchMedia(
    $includeSharedAssets: Boolean
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
  ) {
    search(includeSharedAsset: $includeSharedAssets, searchPaginationDataByDataType: $searchPaginationDataByDataType, searchSortType: $sort) {
      resultList {
        dataId
        dataType
        mediaAssetData {
          conformityPoint
          id
          mediaType
          REFID
          audioData {
            ...${AudioMediaAssetModel.fragmentName}
          }
          imageData {
            ...${ImageMediaAssetModel.fragmentName}
          }
          linkedProductList {
            id
          }
          organisation {
            name
            OKID
          }
          videoData {
            ...${VideoMediaAssetModel.fragmentName}
          }
        }
      }
      searchPaginationResultDataByDataType
    }
  }
  ${AudioMediaAssetModel.fragment}
  ${ImageMediaAssetModel.fragment}
  ${VideoMediaAssetModel.fragment}
`;

export const ArchiveSearchNotesQuery = gql`
  query ArchiveSearchNotes(
    $includeSharedAssets: Boolean
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
  ) {
    search(
      includeSharedAsset: $includeSharedAssets
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchSortType: $sort
    ) {
      resultList {
        dataId
        dataType
        noteAssetData {
          createdBy
          createdByUser {
            displayImage
            name
            OKID
          }
          REFID
          id
          textContentMap
          linkedProductList {
            id
          }
        }
      }
      searchPaginationResultDataByDataType
    }
  }
`;

export const ArchiveSearchInspectionsQuery = gql`
  query ArchiveSearchInspections(
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
  ) {
    search(searchPaginationDataByDataType: $searchPaginationDataByDataType, searchSortType: $sort) {
      resultList {
        dataId
        dataType
        inspectionAssetData {
          createdByUser {
            name
            OKID
          }
          criticalAQL
          id
          majorAQL
          minorAQL
          name
          organisationId
          publishedLogs
          REFID
          reliabilityPointForPublishedLogs
          inspectionTestAssetList {
            testAsset {
              testAssetMediaAssetList {
                mediaAsset {
                  id
                  mediaType
                  imageData {
                    ...${ImageMediaAssetModel.fragmentName}
                  }
                }
              }
            }
          }
        }
      }
      searchPaginationResultDataByDataType
    }
  }
  ${ImageMediaAssetModel.fragment}
`;

export const ArchiveSearchItemsQuery = gql`
  query ArchiveSearchItems(
    $includeSharedAssets: Boolean
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
  ) {
    search(includeSharedAsset: $includeSharedAssets, searchPaginationDataByDataType: $searchPaginationDataByDataType, searchSortType: $sort) {
      resultList {
        dataId
        dataType
        itemData {
          OKID
          reliabilityPointForPublishedLogs
          product {
            conformityPoint
            name
            organisation {
              addressList {
                countryCode
              }
              id
              name
              OKID
            }
            productMediaAssetList {
              mediaAsset {
                audioData {
                  ...${AudioMediaAssetModel.fragmentName}
                }
                id
                imageData {
                  ...${ImageMediaAssetModel.fragmentName}
                }
                mediaType
                videoData {
                  ...${VideoMediaAssetModel.fragmentName}
                }
              }
            }
          }
        }
      }
      searchPaginationResultDataByDataType
    }
  }
  ${AudioMediaAssetModel.fragment}
  ${ImageMediaAssetModel.fragment}
  ${VideoMediaAssetModel.fragment}
`;

export const ArchiveSearchLogsQuery = gql`
  query ArchiveSearchLogs(
    $includeSharedAssets: Boolean
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
  ) {
    search(
      includeSharedAsset: $includeSharedAssets
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchSortType: $sort
    ) {
      resultList {
        dataId
        dataType
        inspectionLogData {
          id
          assigneeList {
            active
            dueDate
            setByUserId
            setDate
            userId
          }
          confidenceLevel
          failed
          organisationId
          inspectedSize
          inspectionLogStage
          inspectionLogTestAssetList {
            unversionedTestAsset {
              whatToLookForMap
            }
          }
          inspectionAsset {
            id
            name
          }
          lastModifiedDate
          lotSize
          endDate
          REFID
          reliabilityPoint
          startDate
          inspectionLogNoteAssetList {
            id
          }
          item {
            product {
              publishStatus
              REFID
              productMediaAssetList {
                mediaAsset {
                  imageData {
                    imageURL
                  }
                }
              }
            }
          }
          organisation {
            addressList {
              countryCode
            }
          }
          site {
            siteMediaAssetList {
              mediaAsset {
                imageData {
                  imageURL
                }
              }
            }
          }
        }
      }
      searchPaginationResultDataByDataType
    }
  }
`;

export const ArchiveSearchOrdersQuery = gql`
  query ArchiveSearchOrders(
    $includeSharedAssets: Boolean
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
  ) {
    search(
      includeSharedAsset: $includeSharedAssets
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchSortType: $sort
    ) {
      resultList {
        dataId
        dataType
        orderData {
          id
          linkedInspectionLogList {
            id
          }
          name
          orderIdentifierList {
            id
            orderIdentifier
          }
          organisation {
            name
            OKID
          }
          organisationId
          REFID
        }
      }
      searchPaginationResultDataByDataType
    }
  }
`;

export const ArchiveSearchProductsQuery = gql`
  query ArchiveSearchProducts(
    $includeSharedAssets: Boolean
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
  ) {
    search(includeSharedAsset: $includeSharedAssets, searchPaginationDataByDataType: $searchPaginationDataByDataType, searchSortType: $sort) {
      resultList {
        dataId
        dataType
        productData {
          ...${ProductModel.fragmentNameFull}
        }
      }
      searchPaginationResultDataByDataType
    }
  }
  ${ProductModel.fragmentFull}

`;

export const ArchiveSearchSitesQuery = gql`
  query ArchiveSearchSites(
    $includeSharedAssets: Boolean
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
  ) {
    search(includeSharedAsset: $includeSharedAssets, searchPaginationDataByDataType: $searchPaginationDataByDataType, searchSortType: $sort) {
      resultList {
        dataId
        dataType
        siteData {
          ...${SiteModel.fragmentNameFull}
        }
      }
      searchPaginationResultDataByDataType
    }
  }
  ${SiteModel.fragmentFull}

`;

export const ArchiveSearchTestsQuery = gql`
  query ArchiveSearchTests(
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
  ) {
    search(searchPaginationDataByDataType: $searchPaginationDataByDataType, searchSortType: $sort) {
      resultList {
        dataId
        dataType
        testAssetData {
          createdByUser {
            name
            OKID
          }
          REFID
          reliabilityPointForPublishedLogs
          testSeverityLevel
          whatToLookForMap
          linkedInspectionAssetList {
            id
          }
          testAssetMediaAssetList {
            mediaAsset {
              id
              mediaType
              imageData {
                ...${ImageMediaAssetModel.fragmentName}
              }
            }
          }
        }
      }
      searchPaginationResultDataByDataType
    }
  }
  ${ImageMediaAssetModel.fragment}
`;

export const FileInputSearchQuery = gql`
  query FileInputSearch(
    $ignoreIdListByDataType: [IgnoreIdListByDataTypeInput]
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
    $searchString: String
  ) {
    search(
      ignoreIdListByDataType: $ignoreIdListByDataType
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchSortType: $sort
      searchString: $searchString
    ) {
      resultList {
        dataHighlights
        documentAssetData {
          REFID
          documentName
          id
        }
      }
      searchPaginationResultDataByDataType
    }
  }
`;

export const SearchOrganisationsQuery = gql`
  query Search(
    $ignoreIdListByDataType: [IgnoreIdListByDataTypeInput]
    $searchForAccreditationOrganisation: Boolean
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
    $searchString: String
  ) {
    search(
      ignoreIdListByDataType: $ignoreIdListByDataType
      searchForAccreditationOrganisation: $searchForAccreditationOrganisation
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchSortType: $sort
      searchString: $searchString
    ) {
      resultList {
        dataHighlights
        dataId
        dataType
        organisationData {
          addressList {
            countryCode
          }
          conformityPoint
          id
          name
          logoImageMediaAsset {
            id
            logoImageURL
          }
          OKID
          reliabilityPointForPublishedLogs
        }
      }
      searchPaginationResultDataByDataType
    }
  }
`;

export const SearchOrganisationsAndUsersQuery = gql`
  query Search(
    $ignoreIdListByDataType: [IgnoreIdListByDataTypeInput]
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $searchString: String
  ) {
    search(
      ignoreIdListByDataType: $ignoreIdListByDataType
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchString: $searchString
    ) {
      resultList {
        dataHighlights
        dataId
        dataType
        organisationData {
          addressList {
            countryCode
          }
          conformityPoint
          id
          name
          logoImageMediaAsset {
            id
            logoImageURL
          }
          OKID
          reliabilityPointForPublishedLogs
        }
        userData {
          id
          email
          mobile
          mobileCountryCode
          name
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      searchPaginationResultDataByDataType
    }
  }
`;

export const SearchUsersQuery = gql`
  query SearchUsers(
    $filterByRole: [ERole]
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $searchString: String
  ) {
    search(
      filterByRole: $filterByRole
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchString: $searchString
    ) {
      resultList {
        userData {
          id
          OKID
          name
        }
      }
      searchPaginationResultDataByDataType
    }
  }
`;

export const SearchAllUsersQuery = gql`
  query SearchUsers(
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $searchString: String
    $ignoreIdListByDataType: [IgnoreIdListByDataTypeInput]
  ) {
    search(
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchString: $searchString
      ignoreIdListByDataType: $ignoreIdListByDataType
    ) {
      resultList {
        userData {
          id
          OKID
          name
          avatar {
            sourceLightURL
            sourceDarkURL
          }
        }
      }
      searchPaginationResultDataByDataType
    }
  }
`;

export const searchQuery = gql`
  query Search(
    $appLanguageIso: ELanguageCode
    $requirePublished: Boolean
    $organisationIdList: [String]
    $userIdList: [String]
    $minimumConformityGrade: Int
    $minimumReliabilityGrade: Int
    $minimumSustainabilityGrade: Int
    $ignoreIdListByDataType: [IgnoreIdListByDataTypeInput]
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $sort: ESearchSortType
    $searchString: String
    $sourcingPlaceOfOriginList: [String]
  ) {
    search(
      appLanguageCode: $appLanguageIso
      editingMustBePublished: $requirePublished
      editingOrganisationIdList: $organisationIdList
      editingUserIdList: $userIdList
      gradingMinimumConformity: $minimumConformityGrade
      gradingMinimumReliability: $minimumReliabilityGrade
      gradingMinimumSustainability: $minimumSustainabilityGrade
      includeSharedAsset: true
      ignoreIdListByDataType: $ignoreIdListByDataType
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchSortType: $sort
      searchString: $searchString
      sourcingPlaceOfOriginList: $sourcingPlaceOfOriginList
    ) {
      resultList {
        dataHighlights
        dataId
        dataType
        documentAssetData {
          ...${DocumentModel.fragmentName}
        }
        inspectionAssetData {
          ...${InspectionAssetModel.fragmentName}
          linkedSiteList {
            OKID
          }
        }
        inspectionLogData {
          ...${InspectionLogModel.fragmentName}
          assigneeList {
            active
            dueDate
            setByUserId
            setDate
            userId
          }
          endDate
          site {
            OKID
          }
          startDate
        }
        itemData {
          ...${ItemModel.fragmentName}
        }
        labelData {
          ...${LabelModel.fragmentName}
        }
        mediaAssetData {
          ...${MediaAssetModel.fragmentName}
        }
        noteAssetData {
          ...${NoteModel.fragmentName}
        }
        orderData {
          id
          linkedInspectionLogList {
            id
          }
          name
          orderIdentifierList {
            id
            orderIdentifier
          }
          orderSiteList {
            site {
              OKID
            }
          }
          organisationId
          REFID
        }
        organisationData {
          ...${BaseOrganisationModel.fragmentName}
        }
        productData {
          ...${ProductModel.fragmentName}
          productMediaAssetList {
            mediaAsset {
              ...${BaseMediaAssetModel.fragmentName}
            }
            order
            publishStatus
          }
        }
        siteData {
          ...${SiteModel.fragmentName}
          siteInspectionAssetList {
            inspectionAsset {
              REFID
            }
          }
          siteMediaAssetList {
            mediaAsset {
              ...${BaseMediaAssetModel.fragmentName}
            }
            order
            publishStatus
          }
        }
        testAssetData {
          ...${TestAssetModel.fragmentName}
        }
        userData {
          ...${BaseUserModel.fragmentName}
        }
      }
      searchPaginationResultDataByDataType
    }
  }
  ${BaseOrganisationModel.fragment}
  ${BaseUserModel.fragment}
  ${DocumentModel.fragment}
  ${InspectionAssetModel.fragment}
  ${InspectionLogModel.fragment}
  ${MediaAssetModel.fragment}
  ${NoteModel.fragment}
  ${ProductModel.fragment}
  ${SiteModel.fragment}
  ${ItemModel.fragment}
  ${LabelModel.fragment}
  ${TestAssetModel.fragment}
`;
