import { gql } from '@apollo/client';

import BaseMediaAssetModel from '../mediaAsset/base';
import BaseOrganisationModel from '../organisation/base';
import BaseProductModel from '../product/base';

import BaseProductParentProductModel from './base';

export default class ProductParentProductModel {
  static GRAPHQL_TYPE = BaseProductParentProductModel.GRAPHQL_TYPE;

  static fragmentName = 'ProductParentProduct_Fragment';
  static fragment = gql`
    fragment ${ProductParentProductModel.fragmentName} on ${ProductParentProductModel.GRAPHQL_TYPE} {
      ...${BaseProductParentProductModel.fragmentName}
      parentProduct {
        ...${BaseProductModel.fragmentName}
        organisation {
          ...${BaseOrganisationModel.fragmentName}
        }
        productMediaAssetList {
          mediaAsset {
            ...${BaseMediaAssetModel.fragmentName}
          }
          order
          publishStatus
        }
      }
    }
    ${BaseProductParentProductModel.fragment}
    ${BaseProductModel.fragment}
    ${BaseOrganisationModel.fragment}
    ${BaseMediaAssetModel.fragment}
  `;
}
