import getUnixTime from 'date-fns/getUnixTime';

import config from 'OK/config/app';
import { getBrowserSessionValue } from 'OK/util/storage';

// Functions

// Currently, this will not work with nested keys.
export function convertObjectToQueryParams(obj) {
  const parametersArray = Object.entries(obj);
  if (!parametersArray.length) {
    return '';
  }

  let parametersString = '';
  for (const [key, val] of Object.entries(obj)) {
    // If there are already parameters, add an '&' before adding new parameters.
    if (parametersString.length) {
      parametersString = `${parametersString}&`;
    }
    const encodedKey = encodeURIComponent(key);
    const encodedVal = encodeURIComponent(val);
    parametersString = `${parametersString}${encodedKey}=${encodedVal}`;
  }

  return `?${parametersString}`;
}

export function formatToUTCUnixTimestampString(date) {
  return `${getUnixTime(date)}000`;
}

export function getRequestOptions() {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  const accessToken = getBrowserSessionValue('ACCESS_TOKEN');
  if (accessToken) {
    options.headers.Authorization = `Bearer ${accessToken}`;
  }

  return options;
}

// Variables

export const API_URL = config.api_url;
export const PDF_API_URL = config.pdf_api_url;
