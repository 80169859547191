import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function InlineIcon(props) {
  const { className, flip = false, ...otherProps } = props;

  let classNames = styles.icon;
  if (flip) {
    classNames = `${classNames} ${styles.flip}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return <img alt='' className={classNames} draggable='false' {...otherProps} />;
}

InlineIcon.propTypes = {
  className: PropTypes.string,
  flip: PropTypes.bool,
};
