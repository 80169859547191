import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import Text from 'OK/components/text';
import { formatPoints } from 'OK/util/formatting';

export default function Grade(props) {
  const { className, iconClassName, score, size = 'md', tint, type, ...otherProps } = props;

  /* Render */

  // Size
  let sizePx;
  switch (size) {
    case 'xs':
      sizePx = 12;
      break;
    case 'sm':
      sizePx = 14;
      break;
    default:
      sizePx = 16;
      break;
  }

  // Type
  let iconName;
  switch (type) {
    case 'conformity':
      iconName = ICONS.DOCUMENTATION_GRADE.name;
      break;
    case 'reliability':
      iconName = ICONS.RELIABILITY_GRADE.name;
      break;
    case 'sustainability':
      iconName = ICONS.SUSTAINABILITY_GRADE.name;
      break;
    default:
      okerror('Grade - Invalid type passed.');
      break;
  }

  // Classes
  let classNames = `${styles.container} ${styles[size]}`;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let iconClassNames = styles.icon;
  if (iconClassName) {
    iconClassNames += ` ${iconClassName}`;
  }

  return (
    <Text className={classNames} size={size} tint={tint} {...otherProps}>
      <Icon className={iconClassNames} height={sizePx} name={iconName} width={sizePx} />
      {typeof score === 'number' ? formatPoints(score) : score}
    </Text>
  );
}

Grade.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
  tint: PropTypes.oneOf(['alert', 'creation', 'navigation', 'notification']),
  type: PropTypes.oneOf(['conformity', 'reliability', 'sustainability']).isRequired,
};
