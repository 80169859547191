import NextImage from "next/legacy/image";
import PropTypes from 'prop-types';
import { useState } from 'react';

import styles from './styles.module.scss';

import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import FloatingButtonsContainer from 'OK/components/floatingButtons';
import { ICONS } from 'OK/components/icon';
import InspectionTestSelect from 'OK/components/test/select';
import InspectionTestTagSelect from 'OK/components/test/tagSelect';
import Text from 'OK/components/text';
import MediaAssetModel from 'OK/models/mediaAsset';
import useI18n from 'OK/util/hooks/useI18n';

/** Image cropping tool. */
const MediaEditorToolImageCrop = {
  Component: () => <div className={styles.fakeMedia}>CROP</div>,
  icon: ICONS.CROP.name,
  id: 'crop',
};

/** Delete media tool. */
const MediaEditorToolDelete = {
  Component: (props) => {
    const { onCancel, onComplete } = props;
    const { t, tHTML } = useI18n();

    return (
      <Alert
        buttons={
          <>
            <Button block className={styles.discardChangesButton} onClick={() => onComplete(null)} tint='alert'>
              {t('LOG_MEDIA_DELETE_ALERT_CONFIRM')}
            </Button>
            <Button block linkStyle onClick={onCancel}>
              {t('LOG_MEDIA_DELETE_ALERT_CANCEL')}
            </Button>
          </>
        }
        message={tHTML('LOG_MEDIA_DELETE_ALERT_MESSAGE')}
        title={t('LOG_MEDIA_DELETE_ALERT_TITLE')}
      />
    );
  },
  icon: ICONS.TRASH.name,
  iconTint: 'alert',
  id: 'delete',
};

MediaEditorToolDelete.Component.propTypes = {
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
};

/** Inspection sorting tool. */
const MediaEditorToolInspectionSort = {
  Component: (props) => {
    const { media, onComplete, testOptions } = props;
    const { t } = useI18n();

    const [tag, setTag] = useState('');
    const [testAssetId, setTestAssetId] = useState('');

    const complete = () => {
      const updatedMedia = {
        ...media,
      };
      // Move
      if (testAssetId) {
        if (testAssetId !== 'unsorted') {
          updatedMedia.testAssetId = testAssetId;
        } else {
          delete updatedMedia.testAssetId;
        }
      }
      // Tag
      if (tag) {
        if (tag !== 'untagged') {
          updatedMedia.tag = tag;
        } else {
          delete updatedMedia.tag;
        }
      }
      onComplete(updatedMedia);
    };

    let mediaComponent;
    switch (media.mediaType) {
      case MediaAssetModel.MEDIA_TYPE.IMAGE:
        mediaComponent = (
          <div className={styles.selectedMediaContainer}>
            <NextImage layout='fill' objectFit='cover' src={media.imageData.imageURL} />
          </div>
        );
        break;
      default:
        mediaComponent = <div className={styles.fakeMedia} />;
        break;
    }

    return (
      <div className={styles.toolContainer}>
        {mediaComponent}
        <div className={styles.toolUIContainer}>
          <Button className={styles.doneButton} linkStyle onClick={complete}>
            {t('DONE')}
          </Button>
          <Text bold className={styles.instructions} size='xs'>
            {t('SET_STEP_OR_TAG_FOR_MEDIA')}
          </Text>
        </div>
        <FloatingButtonsContainer>
          <InspectionTestSelect onChange={setTestAssetId} options={testOptions} value={testAssetId} />
          <InspectionTestTagSelect onChange={setTag} value={tag} />
        </FloatingButtonsContainer>
      </div>
    );
  },
  icon: ICONS.SORT.name,
  id: 'inspectionSort',
};

MediaEditorToolInspectionSort.Component.propTypes = {
  media: PropTypes.any,
  onComplete: PropTypes.func,
  testOptions: PropTypes.array,
};

export { MediaEditorToolImageCrop, MediaEditorToolDelete, MediaEditorToolInspectionSort };
