import { useContext } from 'react';

import styles from './styles.module.scss';

import ContentLayout from 'OK/components/layouts/content';
import Link from 'OK/components/link';
import ThemeContext from 'OK/util/context/theme';
import UIContext from 'OK/util/context/ui';
import useI18n from 'OK/util/hooks/useI18n';
import { r } from 'OK/util/routing';

export default function Footer() {
  const theme = useContext(ThemeContext);
  const { t } = useI18n();

  return (
    <ContentLayout className={styles.container} contentClassName={styles.contentContainer}>
      <UIContext.Provider value='midtone'>
        <div className={styles.mainContent}>
          <div className={styles.row}>
            <div className={styles.column1}>
              <Link className={styles.link} href={r('/')}>
                <img
                  alt={t('IMG_ALT_LOGO_OK')}
                  className={styles.logo}
                  height='40'
                  src={`/icons/logo_${theme.name}.svg`}
                  width='74'
                />
              </Link>
              <h6>{t('FOOTER_FOLLOW_ON_SOCIAL_MEDIA')}</h6>
              <div className={styles.socialIcons}>
                <Link className={styles.link} href='https://www.linkedin.com/company/oktrade/'>
                  <img
                    alt={t('IMG_ALT_LOGO_LINKEDIN')}
                    height='40'
                    src={`/icons/linkedin_icon_${theme.name}.svg`}
                    width='40'
                  />
                </Link>
                <Link className={styles.link} href='https://www.instagram.com/oktradeorg/'>
                  <img
                    alt={t('IMG_ALT_LOGO_INSTAGRAM')}
                    height='40'
                    src={`/icons/instagram_icon_${theme.name}.svg`}
                    width='40'
                  />
                </Link>
                <Link className={styles.link} href='https://x.com/oktradeorg'>
                  <img
                    alt={t('IMG_ALT_LOGO_TWITTER')}
                    height='40'
                    src={`/icons/twitter_icon_${theme.name}.svg`}
                    width='40'
                  />
                </Link>
                <Link className={styles.link} href='https://www.facebook.com/Okgrade-100933088557073/'>
                  <img
                    alt={t('IMG_ALT_LOGO_FACEBOOK')}
                    height='40'
                    src={`/icons/facebook_icon_${theme.name}.svg`}
                    width='40'
                  />
                </Link>
              </div>
            </div>
            <div className={styles.column2}>
              <h6>{t('HELP')}</h6>
              <Link className={styles.link} href='/solutions/features'>
                {t('LABEL_FIELD_FEATURES')} 
              </Link>
              <Link className={styles.link} href='/solutions/contact'>
                {t('PAGE_TITLE_CONTACT')}
              </Link>
            </div>
            <div className={styles.column2}>
              <h6>OK</h6>
              <Link className={styles.link} href='/solutions/about'>
                {t('PAGE_TITLE_ABOUT')}
              </Link>
              <Link className={styles.link} href='/solutions/news'>
                {t('NEWS_AND_EVENTS')} 
              </Link>
              <Link className={styles.link} href='/solutions/work-with-us'>
                {t('PAGE_TITLE_WORK_WITH_US')}
              </Link>
            </div>
            <div className={styles.column2}>
              <h6>{t('PAGE_GROUP_TITLE_LEGAL')}</h6>
              <Link className={styles.link} href='/solutions/terms'>
                {t('PAGE_TITLE_TERMS')}
              </Link>
            </div>
            <div className={styles.column2}>
              <h6>{t('MORE')}</h6>
              <Link className={styles.link} href='https://stats.uptimerobot.com/g6q6Dcq4WV' target='_blank'>
                {t('STATUS')} 
              </Link>
            </div>
          </div>
        </div>
        <div id='footerFineprintPortal' />
        <div className={styles.copyright}>
          <p>© 2023 OK Systems Limited</p>
        </div>
      </UIContext.Provider>
    </ContentLayout>
  );
}
