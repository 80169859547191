import { gql } from '@apollo/client';

import { request } from '..';
import { API_URL } from '../utils';

import InspectionLogTestAssetModel from 'OK/models/inspectionLogTestAsset';
import InspectionLogTestAssetMediaAssetModel from 'OK/models/inspectionLogTestAssetMediaAsset';
import UnversionedTestAssetModel from 'OK/models/unversionedTestAsset';
import getAudioMetadataFromFile from 'OK/util/functions/getAudioMetadataFromFile';
import getImageDimensionsFromFile from 'OK/util/functions/getImageDimensionsFromFile';
import getVideoMetadataFromFile from 'OK/util/functions/getVideoMetadataFromFile';

/* Mutations */

export const createInspectionLogTestAssetMutation = gql`
  mutation CreateInspectionLogTestAsset {
    inspectionLogTestAsset: addUnversionedInspectionLogTestAsset {
      ...${InspectionLogTestAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetModel.fragment}
`;

export const linkMediaAssetToInspectionLogTestAssetMutation = gql`
  mutation LinkMediaAssetToInspectionLogTestAsset($inspectionLogTestAssetId: String!, $mediaAssetId: String!) {
    inspectionLogTestAssetMediaAsset: versionAndLinkInspectionLogTestAssetMediaAsset(inspectionLogTestAssetId: $inspectionLogTestAssetId, mediaAssetId: $mediaAssetId) {
      ...${InspectionLogTestAssetMediaAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetMediaAssetModel.fragment}
`;

export const RecordValueForInspectionLogTestAssetMutation = gql`
  mutation RecordValueForInspectionLogTestAsset($inspectionLogTestAssetId: String!, $value: String!) {
    inspectionLogTestAsset: addInspectionLogTestAssetValue(
      inspectionLogTestAssetId: $inspectionLogTestAssetId
      value: $value
    ) {
      id
      failed
      inspectionLogTestAssetValueList {
        passed
        timestamp
        value
        valueId
      }
      passed
    }
  }
`;

export const RemoveConditionForUnversionedTestMutation = gql`
  mutation RemoveConditionForUnversionedTest(
    $inspectionLogTestAssetId: String!
    $conditionType: ETestAssetConditionType!
  ) {
    inspectionLogTestAsset: removeUnversionedInspectionLogTestAssetCondition(
      inspectionLogTestAssetId: $inspectionLogTestAssetId
      testAssetConditionType: $conditionType
    ) {
      ...${InspectionLogTestAssetModel.fragmentNameUnversionedConditions}
    }
  }
  ${InspectionLogTestAssetModel.fragmentUnversionedConditions}
`;

export const removeHowToFindItFromInspectionLogTestAssetMutation = gql`
  mutation RemoveHowToFindItOnInspectionLogTest($languageIso: ELanguageCode!, $inspectionLogTestAssetId: String!) {
    inspectionLogTestAsset: removeUnversionedInspectionLogTestAssetHowToFindIt(languageCode: $languageIso, inspectionLogTestAssetId: $inspectionLogTestAssetId) {
      ...${InspectionLogTestAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetModel.fragment}
`;

export const removeHowToFixItFromInspectionLogTestAssetMutation = gql`
  mutation RemoveHowToFixItOnInspectionLogTest($languageIso: ELanguageCode!, $inspectionLogTestAssetId: String!) {
    inspectionLogTestAsset: removeUnversionedInspectionLogTestAssetHowToFixIt(languageCode: $languageIso, inspectionLogTestAssetId: $inspectionLogTestAssetId) {
      ...${InspectionLogTestAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetModel.fragment}
`;

export const RemoveRecordedValueFromInspectionLogTestAssetMutation = gql`
  mutation RemoveRecordedValueFromInspectionLogTestAsset($inspectionLogTestAssetId: String!, $valueId: String!) {
    inspectionLogTestAsset: removeInspectionLogTestAssetValue(
      inspectionLogTestAssetId: $inspectionLogTestAssetId
      valueId: $valueId
    ) {
      id
      failed
      inspectionLogTestAssetValueList {
        passed
        timestamp
        value
        valueId
      }
      passed
    }
  }
`;

export const removeWhatToLookForFromInspectionLogTestAssetMutation = gql`
  mutation RemoveWhatToLookForOnInspectionLogTest($languageIso: ELanguageCode!, $inspectionLogTestAssetId: String!) {
    inspectionLogTestAsset: removeUnversionedInspectionLogTestAssetWhatToLookFor(languageCode: $languageIso, inspectionLogTestAssetId: $inspectionLogTestAssetId) {
      ...${InspectionLogTestAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetModel.fragment}
`;

export const reorderInspectionLogTestAssetMediaAssetBackwardMutation = gql`
  mutation ReorderInspectionLogTestAssetMediaAssetBackward($inspectionLogTestAssetId: String!, $mediaAssetId: String!) {
    inspectionLogTestAssetMediaAsset: decreaseOrderUnversionedInspectionLogTestAssetMediaAsset(inspectionLogTestAssetId: $inspectionLogTestAssetId, inspectionLogTestAssetMediaAssetId: $mediaAssetId) {
      ...${InspectionLogTestAssetMediaAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetMediaAssetModel.fragment}
`;

export const reorderInspectionLogTestAssetMediaAssetForwardMutation = gql`
  mutation ReorderInspectionLogTestAssetMediaAssetForward($inspectionLogTestAssetId: String!, $mediaAssetId: String!) {
    inspectionLogTestAssetMediaAsset: increaseOrderUnversionedInspectionLogTestAssetMediaAsset(inspectionLogTestAssetId: $inspectionLogTestAssetId, inspectionLogTestAssetMediaAssetId: $mediaAssetId) {
      ...${InspectionLogTestAssetMediaAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetMediaAssetModel.fragment}
`;

export const removeInspectionLogTestAssetMutation = gql`
  mutation RemoveInspectionLogTestAsset($inspectionLogTestAssetId: String!) {
    inspectionLogTestAsset: removeInspectionLogTestAsset(inspectionLogTestAssetId: $inspectionLogTestAssetId) {
      ...${InspectionLogTestAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetModel.fragment}
`;

export const RemoveMetadataForInspectionLogTestMutation = gql`
  mutation RemoveMetadataForInspectionLogTest($inspectionLogTestAssetId: String!, $key: String!) {
    inspectionLogTestAsset: removeInspectionLogTestAssetMetadata(
      inspectionLogTestAssetId: $inspectionLogTestAssetId
      key: $key
    ) {
      id
      metadata
    }
  }
`;

export const SetConditionForUnversionedTestMutation = gql`
  mutation SetConditionForUnversionedTest(
    $inspectionLogTestAssetId: String!
    $conditionType: ETestAssetConditionType!
    $conditionValue: String!
  ) {
    inspectionLogTestAsset: addOrUpdateUnversionedInspectionLogTestAssetCondition(
      inspectionLogTestAssetId: $inspectionLogTestAssetId
      testAssetConditionType: $conditionType
      testAssetConditionValue: $conditionValue
    ) {
      ...${InspectionLogTestAssetModel.fragmentNameUnversionedConditions}
    }
  }
  ${InspectionLogTestAssetModel.fragmentUnversionedConditions}
`;

export const setHowToFindItOnInspectionLogTestAssetMutation = gql`
  mutation SetHowToFindItOnInspectionLogTestAsset($languageIso: ELanguageCode!, $text: String!, $inspectionLogTestAssetId: String!) {
    inspectionLogTestAsset: setUnversionedInspectionLogTestAssetHowToFindIt(languageCode: $languageIso, languageText: $text, inspectionLogTestAssetId: $inspectionLogTestAssetId) {
      ...${InspectionLogTestAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetModel.fragment}
`;

export const setHowToFixItOnInspectionLogTestAssetMutation = gql`
  mutation SetHowToFixItOnInspectionLogTestAsset($languageIso: ELanguageCode!, $text: String!, $inspectionLogTestAssetId: String!) {
    inspectionLogTestAsset: setUnversionedInspectionLogTestAssetHowToFixIt(languageCode: $languageIso, languageText: $text, inspectionLogTestAssetId: $inspectionLogTestAssetId) {
      ...${InspectionLogTestAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetModel.fragment}
`;

export const SetIdentifiersEnabledForUnversionedTestMutation = gql`
  mutation SetIdentifiersEnabledForUnversionedTest($enableIdentifiers: Boolean!, $inspectionLogTestAssetId: String!) {
    inspectionLogTestAsset: setUnversionedInspectionLogTestAssetEnableIdentifiers(enableIdentifiers: $enableIdentifiers, inspectionLogTestAssetId: $inspectionLogTestAssetId) {
      id
      unversionedTestAsset {
        ...${UnversionedTestAssetModel.fragmentName}
      }
    }
  }
  ${UnversionedTestAssetModel.fragment}
`;

export const setInspectionLogTestAssetFailedCountMutation = gql`
  mutation SetInspectionLogTestAssetFailedCount($inspectionLogTestAssetId: String!, $count: Int!) {
    inspectionLogTestAsset: setInspectionLogTestAssetFailed(
      inspectionLogTestAssetId: $inspectionLogTestAssetId
      failed: $count
    ) {
      failed
      id
    }
  }
`;

export const setInspectionLogTestAssetFixedCountMutation = gql`
  mutation SetInspectionLogTestAssetFixedCount($inspectionLogTestAssetId: String!, $count: Int!) {
    inspectionLogTestAsset: setInspectionLogTestAssetFixed(
      inspectionLogTestAssetId: $inspectionLogTestAssetId
      fixed: $count
    ) {
      fixed
      id
    }
  }
`;

export const setInspectionLogTestAssetPassedCountMutation = gql`
  mutation SetInspectionLogTestAssetPassedCount($inspectionLogTestAssetId: String!, $count: Int!) {
    inspectionLogTestAsset: setInspectionLogTestAssetPassed(
      inspectionLogTestAssetId: $inspectionLogTestAssetId
      passed: $count
    ) {
      id
      passed
    }
  }
`;

export const setInspectionLogTestAssetToleratedCountMutation = gql`
  mutation SetInspectionLogTestAssetToleratedCount($inspectionLogTestAssetId: String!, $count: Int!) {
    inspectionLogTestAsset: setInspectionLogTestAssetTolerated(
      inspectionLogTestAssetId: $inspectionLogTestAssetId
      tolerated: $count
    ) {
      id
      tolerated
    }
  }
`;

export const SetMetadataForInspectionLogTestMutation = gql`
  mutation SetMetadataForInspectionLogTest($data: Object!, $inspectionLogTestAssetId: String!, $key: String!) {
    inspectionLogTestAsset: putInspectionLogTestAssetMetadata(
      data: $data
      inspectionLogTestAssetId: $inspectionLogTestAssetId
      key: $key
    ) {
      id
      metadata
    }
  }
`;

export const SetNotesEnabledForUnversionedTestMutation = gql`
  mutation SetNotesEnabledForUnversionedTest($enableNotes: Boolean!, $inspectionLogTestAssetId: String!) {
    inspectionLogTestAsset: setUnversionedInspectionLogTestAssetEnableNotes(
      enableNotes: $enableNotes
      inspectionLogTestAssetId: $inspectionLogTestAssetId
    ) {
      id
      unversionedTestAsset {
        ...${UnversionedTestAssetModel.fragmentName}
      }
    }
  }
  ${UnversionedTestAssetModel.fragment}
`;

export const setSeverityLevelForInspectionLogTestAssetMutation = gql`
  mutation SetSeverityLevelForInspectionLogTestAsset($inspectionLogTestAssetId: String!, $severityLevel: ETestSeverityLevel!) {
    inspectionLogTestAsset: setUnversionedInspectionLogTestAssetSeverityLevel(inspectionLogTestAssetId: $inspectionLogTestAssetId, testSeverityLevel: $severityLevel) {
      ...${InspectionLogTestAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetModel.fragment}
`;

export const SetTimeTrackingEnabledForUnversionedTestMutation = gql`
  mutation SetTimeTrackingEnabledForUnversionedTest($enableTimeTracking: Boolean!, $inspectionLogTestAssetId: String!) {
    inspectionLogTestAsset: setUnversionedInspectionLogTestAssetEnableTimeTracking(enableTimeTracking: $enableTimeTracking, inspectionLogTestAssetId: $inspectionLogTestAssetId) {
      id
      unversionedTestAsset {
        ...${UnversionedTestAssetModel.fragmentName}
      }
    }
  }
  ${UnversionedTestAssetModel.fragment}
`;

export const setWhatToLookForOnInspectionLogTestAssetMutation = gql`
  mutation SetWhatToLookForOnInspectionLogTestAsset($languageIso: ELanguageCode!, $text: String!, $inspectionLogTestAssetId: String!) {
    inspectionLogTestAsset: setUnversionedInspectionLogTestAssetWhatToLookFor(languageCode: $languageIso, languageText: $text, inspectionLogTestAssetId: $inspectionLogTestAssetId) {
      ...${InspectionLogTestAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetModel.fragment}
`;

export const unlinkInspectionLogTestAssetMediaAssetMutation = gql`
  mutation UnlinkInspectionLogTestAssetMediaAsset($inspectionLogTestAssetId: String!, $inspectionLogTestAssetMediaAssetId: String!) {
    inspectionLogTestAssetMediaAsset: removeUnversionedInspectionLogTestAssetMediaAsset(inspectionLogTestAssetId: $inspectionLogTestAssetId, inspectionLogTestAssetMediaAssetId: $inspectionLogTestAssetMediaAssetId) {
      ...${InspectionLogTestAssetMediaAssetModel.fragmentName}
    }
  }
  ${InspectionLogTestAssetMediaAssetModel.fragment}
`;

/* Requests */

export async function createInspectionLogTestAssetAudioMediaAssetRequest(inspectionLogTestAssetId, audioFile) {
  const { duration } = await getAudioMetadataFromFile(audioFile);
  const metadata = {
    duration,
    filesize: audioFile.size,
    mimeType: audioFile.type,
  };
  const parameters = {
    inspectionLogTestAssetId,
    audio: audioFile,
    metadataString: JSON.stringify(metadata),
  };
  return request(
    `${API_URL}/utility/inspection-log/media/addUnversionedInspectionLogTestAssetMediaAssetAudioWithMetadata`,
    {
      contentType: 'multipart/form-data',
      method: 'POST',
      parameters,
    }
  );
}

export async function createInspectionLogTestAssetImageMediaAssetRequest(
  inspectionLogTestAssetId,
  imageFile,
  originalImageFile
) {
  const { height, width } = await getImageDimensionsFromFile(imageFile);
  const metadata = {
    filesize: imageFile.size,
    height,
    mimeType: imageFile.type,
    width,
  };
  const parameters = {
    inspectionLogTestAssetId,
    image: imageFile,
    baseImage: originalImageFile,
    metadataString: JSON.stringify(metadata),
  };

  return request(
    `${API_URL}/utility/inspection-log/media/addUnversionedInspectionLogTestAssetMediaAssetImageWithMetadata`,
    {
      contentType: 'multipart/form-data',
      method: 'POST',
      parameters,
    }
  );
}

export async function createInspectionLogTestAssetVideoMediaAssetRequest(inspectionLogTestAssetId, videoFile) {
  const { duration, height, width } = await getVideoMetadataFromFile(videoFile);
  const metadata = {
    duration,
    filesize: videoFile.size,
    height,
    mimeType: videoFile.type,
    width,
  };
  const parameters = {
    inspectionLogTestAssetId,
    video: videoFile,
    metadataString: JSON.stringify(metadata),
  };
  return request(
    `${API_URL}/utility/inspection-log/media/addUnversionedInspectionLogTestAssetMediaAssetVideoWithMetadata`,
    {
      contentType: 'multipart/form-data',
      method: 'POST',
      parameters,
    }
  );
}
