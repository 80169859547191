import differenceInSeconds from 'date-fns/differenceInSeconds';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { clearSessionAction } from 'OK/state/account/actions';
import useI18n from 'OK/util/hooks/useI18n';

export default function Locked(props) {
  // Variables
  const { className, lockedUntil, loginMethod, ...otherProps } = props;
  const interval = useRef();
  const { t, tHTML } = useI18n();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);

  // State
  const [secondsLeft, setSecondsLeft] = useState(0);

  // Functions
  const checkIfLocked = useCallback(() => {
    const seconds = secondsUntilUnlocked();
    setSecondsLeft(seconds);

    if (seconds > 0) {
      return true;
    }

    clearInterval(interval.current);
    returnToLogin();
    return false;
  }, [returnToLogin, secondsUntilUnlocked]);
  const dispatch = useDispatch();
  const returnToLogin = useCallback(() => dispatch(clearSessionAction()), [dispatch]);
  const secondsUntilUnlocked = useCallback(() => differenceInSeconds(lockedUntil, new Date()), [lockedUntil]);

  // Effects
  useEffect(() => {
    const isLocked = checkIfLocked();

    if (interval.current) {
      clearInterval(interval.current);
    }

    if (isLocked) {
      interval.current = setInterval(() => {
        checkIfLocked();
      }, 1000);
    }

    const currentIntervalRef = interval.current;

    return function cleanup() {
      clearInterval(currentIntervalRef);
    };
  }, [checkIfLocked, lockedUntil]);

  const classNames = `${styles.container}${className ? ` ${className}` : ''}`;
  return (
    <>
      <div className={classNames} fixedWidth={useDesktopLayout} {...otherProps}>
        <h3>{t('TOO_MANY_FAILED_SIGN_IN_ATTEMPTS')}</h3>
        <p>
          {tHTML(
            loginMethod === 'email'
              ? 'ACCOUNT_TEMPORARILY_LOCKED_MESSAGE_EMAIL'
              : 'ACCOUNT_TEMPORARILY_LOCKED_MESSAGE_PHONE',
            {
              data: {
                reason: (
                  <span className={styles.inlineErrorText}>
                    {t('ACCOUNT_TEMPORARILY_LOCKED_REASON_FAILED_ATTEMPTS')}
                  </span>
                ),
              },
            }
          )}
        </p>
        <p>
          {tHTML('ACCOUNT_TEMPORARILY_LOCKED_SECONDS_LEFT', {
            data: {
              number: secondsLeft,
            },
          })}
        </p>
        <Button onClick={returnToLogin} linkStyle icon='/icons/caret_link.svg'>
          {t('RETURN_TO_SIGN_IN')}
        </Button>
      </div>
    </>
  );
}

Locked.propTypes = {
  className: PropTypes.string,
  lockedUntil: PropTypes.instanceOf(Date).isRequired,
  loginMethod: PropTypes.string,
};
