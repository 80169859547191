import { gql } from '@apollo/client';

export default class BaseInspectionLogTestAssetModel {
  static GRAPHQL_TYPE = 'InspectionLogTestAsset';

  static fragmentName = 'BaseInspectionLogTestAsset_Fragment';
  static fragment = gql`
    fragment ${BaseInspectionLogTestAssetModel.fragmentName} on ${BaseInspectionLogTestAssetModel.GRAPHQL_TYPE} {
      createdBy
      createdDate
      criticalAQL @client
      failed
      fixed
      id
      inspectionLogAssetSource
      inspectionOrganisationId
      lastModifiedBy
      lastModifiedDate
      lotCriticalFailure
      lotFixableProjection
      lotFixableProjectionPercent
      lotFixedPercent
      lotFlawlessProjection
      lotFlawlessProjectionPercent
      lotMajorFailure
      lotMinorFailure
      lotRejectedPercent
      lotTolerableProjection
      lotTolerableProjectionPercent
      lotUnfixableProjection
      lotUnfixableProjectionPercent
      majorAQL @client
      metadata
      minorAQL @client
      order
      organisationId
      passed
      rejected
      sampleCriticalFailures
      sampleCriticalInspected
      sampleMajorFailures
      sampleMajorInspected
      sampleMinorFailures
      sampleMinorInspected
      samplePassedAsFixedPercent
      samplePassedAsFlawlessPercent
      samplePassedAsToleratedPercent
      sampleRejectedPercent
      tolerated
      version
    }
  `;
}
