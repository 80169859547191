import { gql } from '@apollo/client';

import BaseInspectionAsset from '../inspectionAsset/base';
import MediaAssetModel from '../mediaAsset';
import TestAssetMediaAssetModel from '../testMediaAsset';

import BaseTestAsset from './base';

import localizeObject from 'OK/util/functions/localizeObject';

export default class TestAssetModel {
  static GRAPHQL_TYPE = BaseTestAsset.GRAPHQL_TYPE;

  /* Enums */

  static CONDITION_TYPES = {
    MAX_VALUE: 'MAX_VALUE',
    MIN_VALUE: 'MIN_VALUE',
  };
  static CONDITION_VALUE_TYPES = {
    NUMBER: 'NUMBER',
    STRING: 'STRING',
  };
  static SEVERITY_LEVEL = {
    CRITICAL: 'CRITICAL',
    MAJOR: 'MAJOR',
    MINOR: 'MINOR',
  };

  static fragmentName = 'TestAsset_Fragment';
  static fragment = gql`
    fragment ${TestAssetModel.fragmentName} on ${TestAssetModel.GRAPHQL_TYPE} {
      ...${BaseTestAsset.fragmentName}
      linkedInspectionAssetList {
        ...${BaseInspectionAsset.fragmentName}
        inspectionTestAssetList {
          order
          publishStatus
          testAsset {
            ...${BaseTestAsset.fragmentName}
          }
        }
      }
      testAssetConditionList {
        type
        value
        valueType
      }
      testAssetMediaAssetList {
        ...${TestAssetMediaAssetModel.fragmentName}
      }
    }
    ${BaseTestAsset.fragment}
    ${BaseInspectionAsset.fragment}
    ${TestAssetMediaAssetModel.fragment}
  `;

  static fragmentNameArchiveCard = 'TestAsset_ArchiveCard_Fragment';
  static fragmentArchiveCard = gql`
    fragment ${TestAssetModel.fragmentNameArchiveCard} on ${TestAssetModel.GRAPHQL_TYPE} {
      id
      linkedInspectionAssetList {
        id
      }
      REFID
      reliabilityPointForPublishedLogs
      testAssetMediaAssetList {
        mediaAsset {
          id
          imageData {
            imageURL
          }
          mediaType
        }
      }
      testSeverityLevel
      whatToLookForMap
    }
  `;

  static fragmentNameConditions = 'TestAsset_Conditions_Fragment';
  static fragmentConditions = gql`
    fragment ${TestAssetModel.fragmentNameConditions} on ${TestAssetModel.GRAPHQL_TYPE} {
      id
      testAssetConditionList {
        type
        value
        valueType
      }
    }
  `;

  static fragmentNameNotesEnabled = 'TestAsset_NotesEnabled_Fragment';
  static fragmentNotesEnabled = gql`
    fragment ${TestAssetModel.fragmentNameNotesEnabled} on ${TestAssetModel.GRAPHQL_TYPE} {
      id
      enableNotes
    }
  `;

  /* Helper methods */

  static conditionValueTypeForConditionType(conditionType) {
    switch (conditionType) {
      case TestAssetModel.CONDITION_TYPES.MAX_VALUE:
      case TestAssetModel.CONDITION_TYPES.MIN_VALUE:
        return this.CONDITION_VALUE_TYPES.NUMBER;
      default:
        throw new Error(`Invalid condition type: ${conditionType}`);
    }
  }

  static link(testAsset) {
    return `/archive/step/${testAsset.REFID}/edit`;
  }

  static previewPhoto(testAsset) {
    if (testAsset.testAssetMediaAssetList?.length) {
      const mediaAsset = testAsset.testAssetMediaAssetList.find(
        (tma) => tma.mediaAsset.mediaType === MediaAssetModel.MEDIA_TYPE.IMAGE
      )?.mediaAsset;
      if (mediaAsset) {
        return mediaAsset.imageData.imageURL;
      }
    }

    return null;
  }

  static titleForTest = (testAsset, locale) => {
    const { whatToLookForMap } = testAsset;
    const localizedWTLF = localizeObject(whatToLookForMap, locale, true);
    if (localizedWTLF?.text) {
      return localizedWTLF.text;
    }

    return 'Pending step';
  };
}
