import { gql } from '@apollo/client';

export default class BaseInspectionAssetModel {
  static GRAPHQL_TYPE = 'InspectionAsset';

  static fragmentName = 'BaseInspection_Asset_Fragment';
  static fragment = gql`
    fragment ${BaseInspectionAssetModel.fragmentName} on ${BaseInspectionAssetModel.GRAPHQL_TYPE} {
      REFID
      createdBy
      createdDate
      id
      lastModifiedBy
      lastModifiedDate
      organisationId
      status
      version
      criticalAQL
      criticalFailure
      criticalInspected
      majorAQL
      majorFailure
      majorInspected
      minorAQL
      minorFailure
      minorInspected
      name
      publishedLogs
      reliabilityPointForPublishedLogs
    }
  `;
}
