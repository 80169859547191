import { gql } from '@apollo/client';

export default class BaseInspectionLogFindingModel {
  static GRAPHQL_TYPE = 'InspectionLogFinding';

  static FIELD_POLICIES = {
    fields: {
      selected: {
        read(selected = false) {
          return selected;
        },
      },
    },
  };

  static TAG = {
    FAILED: 'FAILED',
    FIXED: 'FIXED',
    PASSED: 'PASSED',
    TOLERATED: 'TOLERATED',
  };

  static fragmentName = 'BaseInspectionLogFinding_Fragment';
  static fragment = gql`
    fragment ${BaseInspectionLogFindingModel.fragmentName} on ${BaseInspectionLogFindingModel.GRAPHQL_TYPE} {
      createdBy
      createdDate
      id
      inspectionLogId
      inspectionLogTestAssetId
      inspectionOrganisationId
      lastModifiedBy
      lastModifiedDate
      organisationId
      selected @client
      tagList
      version
    }
  `;
}
