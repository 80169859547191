import { polyfill } from 'mobile-drag-drop';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import styles from './styles.module.scss';

if (typeof window !== 'undefined') {
  polyfill();
  var supportsPassiveEventListeners = false;
  try {
    var opts = Object.defineProperty({}, 'passive', {
      // eslint-disable-next-line getter-return
      get: function () {
        supportsPassiveEventListeners = true;
      },
    });
    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
  } catch (e) {
    okerror('Error detecting passive event support', e);
  }
  window.addEventListener('touchmove', function () {}, supportsPassiveEventListeners ? { passive: false } : false);
}

/**
 * Manages draggable elements by
 *   a) displaying indicators where elements can be dropped, and
 *   b) re-ordering elements on drop events.
 *
 * @param {object} props
 * @param {any} props.children
 * @param {string} [props.className] The drop zone's class.
 * @param {(dropZoneId: string, draggedElementId: string, draggedElementNewIndex: number) => void} [props.onDrop]
 * Event handler for when a drop occurs. Passes the id of the drop zone, the id of the dragged element, and the
 * new index of the dragged element.
 * @param {string} props.id A unique identifier for the drop zone.
 */
export default function DropZone(props) {
  /* Variables */

  const { children, className, onDrop, id, ...otherProps } = props;
  const dropzoneRef = useRef();

  /* State */

  // The element being dragged
  const [draggedElement, setDraggedElement] = useState(null);
  // The index of the element being dragged
  const [draggedElementIndex, setDraggedElementIndex] = useState(-1);
  // The index where the element will be dropped, if dropped now
  const [dropIndex, setDropIndex] = useState(-1);
  // The y position (in px) where the drop indicator should be displayed
  const [dropIndicatorYPosition, setDropIndicatorYPosition] = useState(undefined);
  const [isDragging, setIsDragging] = useState(false);

  /* Methods */

  /**
   * Show the drop indicator.
   *
   * @param {Node} element The element to position the drop indicator relative to.
   * @param {('above' | 'below')} position Whether to display the drop indicator above or below the element.
   */
  const addDropIndicatorNextToElement = (element, position) => {
    const elementBounds = element.getBoundingClientRect();
    const dropZoneBounds = dropzoneRef.current.getBoundingClientRect();

    if (position === 'above') {
      const topOfElement = elementBounds.y - dropZoneBounds.y;

      const previousElement = getPreviousElement(element);
      let previousBottom;
      if (previousElement) {
        // Position the indicator between this element and the bottom of previous element.
        const previousElementBounds = previousElement.getBoundingClientRect();
        previousBottom = previousElementBounds.y + previousElementBounds.height - dropZoneBounds.y;
      } else {
        // Position the indicator between this element and the top of the drop zone.
        previousBottom = 0;
      }

      // Calculate the space in between
      const margin = topOfElement - previousBottom;
      // Add the drop indicator halfway between
      setDropIndicatorYPosition(topOfElement - margin / 2);
    } else {
      const bottomOfElement = elementBounds.y + elementBounds.height - dropZoneBounds.y;

      const nextElement = getNextElement(element);
      let nextTop;
      if (nextElement) {
        // Position the indicator between this element and the top of next element.
        const nextElementBounds = nextElement.getBoundingClientRect();
        nextTop = nextElementBounds.y - dropZoneBounds.y;
      } else {
        // Position the indicator between this element and the bottom of the drop zone.
        nextTop = dropZoneBounds.height;
      }

      // Calculate the space in between
      const margin = nextTop - bottomOfElement;
      // Add the drop indicator halfway between
      setDropIndicatorYPosition(bottomOfElement + margin / 2);
    }
  };

  /**
   * Returns whether the passed element is a child of the drop zone.
   *
   * @param {Node} element
   *
   * @returns {boolean}
   */
  const elementIsChildOfDropzone = (element) => dropzoneRef.current.contains(element);

  /**
   * Find the child of the drop zone that is closest to a Y position.
   *
   * @param {Number} y The Y position to measure distance from.
   *
   * @returns {[nearestChild: Node, nearestChildIndex: Number]}
   */
  const findChildNearestToYPosition = (y) => {
    let nearestElementDistance = 9999;
    let nearestChild;
    let nearestChildIndex = -1;
    for (let x = 0; x < dropzoneRef.current.children.length; x++) {
      const element = dropzoneRef.current.children[x];
      if (elementIsDropIndicator(element)) {
        // debug('I think element is drop indicator');
        continue;
      }

      const elementBounds = element.getBoundingClientRect();
      const elementTop = elementBounds.y;
      const elementBottom = elementTop + elementBounds.height;
      const distanceToTop = Math.abs(y - elementTop);
      const distanceToBottom = Math.abs(y - elementBottom);
      const distance = Math.min(distanceToTop, distanceToBottom);
      if (distance < nearestElementDistance) {
        nearestElementDistance = distance;
        nearestChild = element;
        nearestChildIndex = x;
      }
    }

    return [nearestChild, nearestChildIndex];
  };

  /**
   * Given an element, find an ancestor that is a direct child of the drop zone.
   *
   * @param {Node} element The element to search ancestors of.
   *
   * @returns {Node?} Will be null if the passed element isn't a decendant of the drop zone at all.
   */
  const findDirectChildOfDropzone = (element) => {
    if (!elementIsChildOfDropzone(element)) {
      // This element is not a descendant of the dropzone.
      return null;
    }

    const parent = element.parentNode;

    if (elementIsDropzone(parent)) {
      return element;
    }

    return findDirectChildOfDropzone(parent);
  };

  /**
   * Get the index position of an element if it is a direct child of the drop zone.
   *
   * @param {Node} element The element to find the index of.
   *
   * @returns {Number} -1 if not a direct child of the drop zone.
   */
  const indexOfChild = (element) => {
    const { children } = dropzoneRef.current;
    for (let x = 0; x < children.length; x++) {
      const child = children[x];
      if (child === element) {
        return x;
      }
    }

    return -1;
  };

  /**
   * Prevent default event handling.
   *
   * @param {Event} e The event.
   */
  const preventDefault = (e) => e.preventDefault();

  /** Remove the drop indicator. */
  const removeDropIndicator = () => {
    setDropIndicatorYPosition(undefined);
  };

  /**
   * Reposition the dragged element to where the user specified.
   *
   * @returns {number} The new index of the element.
   */
  const repositionDraggedElement = () => {
    const dropzone = dropzoneRef.current;
    // Current position of draggedElement
    const draggedElementCurrentIndex = indexOfChild(draggedElement);
    const dropAtIndex = draggedElementCurrentIndex < dropIndex ? dropIndex - 1 : dropIndex;

    // Remove element from original location
    draggedElement.parentNode.removeChild(draggedElement);

    const currentElementAtIndex = dropzone.children[dropAtIndex];
    if (currentElementAtIndex) {
      dropzone.insertBefore(draggedElement, currentElementAtIndex);
    } else {
      dropzone.appendChild(draggedElement);
    }

    return dropAtIndex;
  };

  /**
   * Stop propagation of an event. Also calls `preventDefault`.
   *
   * @param {Event} e The event.
   */
  const stopPropagation = (e) => {
    preventDefault(e);
    e.stopPropagation();
  };

  /* Events */

  const _onDragEnd = () => {
    setIsDragging(false);
  };

  const _onDragLeave = (e) => {
    const leavingToElement = e.nativeEvent.fromElement;
    if (!elementIsChildOfDropzone(leavingToElement)) {
      // If the user stops dragging over the drop zone, drops won't be handled. Remove the drop indicator so the user
      // doesn't get confused.
      removeDropIndicator();
    }
  };

  // Add or remove the drop indicator based on where the user is dragging.
  const _onDragOver = (e) => {
    preventDefault(e);

    if (!isDragging) {
      // Dragging elements over another drop zone is not currently supported.
      return;
    }

    const { target } = e;
    const dragYPosition = e.clientY;

    if (elementIsDropIndicator(target)) {
      // Don't handle drags over the drop indicator.
      return;
    }

    // Identify which element is being dragged over
    let dropzoneChild, dropzoneChildIndex;
    if (!elementIsDropzone(target)) {
      dropzoneChild = findDirectChildOfDropzone(target);
      dropzoneChildIndex = indexOfChild(dropzoneChild);
    } else {
      [dropzoneChild, dropzoneChildIndex] = findChildNearestToYPosition(dragYPosition);
    }

    if (dropzoneChild === draggedElement) {
      // Don't handle drags over itself.
      return;
    }

    // Determine which index a drop would occur
    const insertBefore = shouldInsertBeforeElement(dropzoneChild, dragYPosition);
    const willInsertAtSameIndex = insertBefore
      ? dropzoneChildIndex === draggedElementIndex + 1
      : dropzoneChildIndex === draggedElementIndex - 1;
    if (willInsertAtSameIndex) {
      // Dropping now won't update positioning, so remove the drop indicator.
      removeDropIndicator();
      return;
    }

    // Add the drop indicator
    const position = insertBefore ? 'above' : 'below';
    addDropIndicatorNextToElement(dropzoneChild, position);
    // Keep track of which index a drop should take place so handling the drop event is easier.
    setDropIndex(dropzoneChildIndex + (position === 'below' ? 1 : 0));
  };

  const _onDragStart = (e) => {
    setIsDragging(true);
    setDraggedElement(e.target);
    setDraggedElementIndex(indexOfChild(e.target));
  };

  const _onDrop = (e) => {
    const { target } = e;

    if (!isDragging) {
      // Dropping elements on another drop zone is not currently supported.
      return;
    }

    // Reset drop indication styles
    removeDropIndicator();

    // Determine whether to handle the drop
    const targetIsDropzone = elementIsDropzone(target);
    const dropzoneChild = targetIsDropzone ? null : findDirectChildOfDropzone(target);
    if (!targetIsDropzone) {
      if (dropzoneChild === draggedElement) {
        // If target is itself, cancel the drop
        return;
      }
    }

    const newIndex = repositionDraggedElement();

    if (onDrop) {
      // Trigger drop event handler
      const draggedId = e.dataTransfer.getData('text/dragged-id');
      onDrop(id, draggedId, newIndex);
    }

    // Reset drag state
    setDraggedElement(null);
    setDropIndex(-1);
  };

  /* Render */

  // Classes
  let classNames = `dropzone ${styles.dropZone}`;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <div
      className={classNames}
      onDragEnd={_onDragEnd}
      onDragEnter={preventDefault}
      onDragLeave={_onDragLeave}
      onDragOver={_onDragOver}
      onDragStart={_onDragStart}
      onDrop={_onDrop}
      ref={dropzoneRef}
      {...otherProps}
    >
      {children}
      {dropIndicatorYPosition !== undefined && (
        <div
          className={`dropIndicator ${styles.dropIndicator}`}
          onDragEnter={stopPropagation}
          onDragLeave={stopPropagation}
          onDragOver={stopPropagation}
          style={{ top: `${dropIndicatorYPosition}px` }}
        />
      )}
    </div>
  );
}

DropZone.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  onDrop: PropTypes.func,
};

/* Helper functions */

/**
 * Returns the next sibling of the passed element, if one exists. Doesn't consider the drop indicator to be a sibling.
 *
 * @param {Node} element The element to get the next sibling for.
 *
 * @returns {Node?}
 */
function getNextElement(element) {
  let nextElement = element.nextSibling;
  if (elementIsDropIndicator(nextElement)) {
    return nextElement.nextSibling;
  }

  return nextElement;
}

/**
 * Returns the previous sibling of the passed element, if one exists. Doesn't consider the drop indicator to be a sibling.
 *
 * @param {Node} element The element to get the previous sibling for.
 *
 * @returns {Node?}
 */
function getPreviousElement(element) {
  let previousElement = element.previousSibling;
  if (elementIsDropIndicator(previousElement)) {
    return previousElement.previousSibling;
  }

  return previousElement;
}

/**
 * Returns whether the passed element is the drop indicator.
 *
 * @param {Node} element The element to check.
 *
 * @returns {boolean}
 */
function elementIsDropIndicator(element) {
  return element?.classList.contains('dropIndicator') || false;
}

/**
 * Returns whether the passed element is the drop zone.
 *
 * @param {Node} element The element to check.
 *
 * @returns {boolean}
 */
function elementIsDropzone(element) {
  return element?.classList.contains('dropzone') || false;
}

/**
 * Whether a drop should occur before or after the targeted element.
 *
 * @param {*} targetElement The element to check drop position against.
 * @param {*} dropY The y position of the drop.
 *
 * @returns {boolean} True if dropping above the midway point of the target child.
 */
function shouldInsertBeforeElement(targetElement, dropY) {
  const elementBounds = targetElement.getBoundingClientRect();
  return dropY < elementBounds.y + elementBounds.height / 2;
}
