import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

const Toast = forwardRef((props, ref) => {
  const { className, children, tint = 'base', ...otherProps } = props;

  let classNames = styles.container;
  switch (tint) {
    case 'alert':
    case 'base':
      classNames = `${classNames} ${styles[tint]}`;
      break;
    default:
      classNames = `${classNames} ${styles.base}`;
      break;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }
  return (
    <div className={classNames} ref={ref} {...otherProps}>
      {children}
    </div>
  );
});

Toast.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  tint: PropTypes.oneOf(['alert', 'base']),
};

export default Toast;
