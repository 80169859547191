import { getCSSVariable } from './util';

const baseTheme = {
  colors: {
    brand: '#1a1a1a',
    brandAlternate: '#f4f4f4',
    alert: '#ea0029',
    creation: '#008656',
    navigation: '#0079d2',
    notification: '#857550',
  },
  // Fonts
  fonts: {
    normal: 'Noto Sans, sans-serif',
  },
  // Font sizing
  fontSizing: {
    fontSizeFocal: '2.5rem',
    lineHeightFocal: '3.75rem',
    fontSizePageHeader: '2rem',
    lineHeightPageHeader: '3rem',
    fontSizeCardHeader: '1.5rem',
    lineHeightCardHeader: '2.25rem',
    fontSizeSectionHeader: '1.25rem',
    lineHeightSectionHeader: '1.875rem',
    fontSizeSubsectionHeader: '1rem',
    lineHeightSubsectionHeader: '1.5rem',
    fontSizeMd: '1rem',
    lineHeightMd: '1.5rem',
    fontSizeSm: '0.875rem',
    lineHeightSm: '1.3rem',
    fontSizeXs: '0.75rem',
    lineHeightXs: '1.125rem',
  },
  // Sizing
  sizing: {
    buttonheight: 40,
    $cardWidth3ColumnDesktop: 287,
    $cardWidth4ColumnDesktop: 208,
    cardWidthDesktop: 287,
    cardWidthMobile: 315,
    mainMenuHeight: 70,
    mainMenuTabsHeight: 40 + 15 + 18,
    mainMenuTabsHeightPlusBottomSafeArea: () => 40 + 15 + 18 + getCSSVariable('--safeAreaInsetBottom'),
  },
  // Spacing
  spacing: {
    buttonMessageMargin: 5,
    buttonWithButtonMargin: 15,
    contentMarginXs: 5,
    contentMarginSm: 10,
    contentMarginMd: 20,
    contentMarginLg: 30,
    contentMarginXl: 40,
    contentMarginXxl: 50,
    contentMarginHuge: 100,
    contentMaxWidthDesktop: 1155,
    contentMaxWidthMobile: 500,
  },
  timing: {
    timingInstantMs: 100,
    timingShortMs: 250,
    timingMediumMs: 500,
    timingLongMs: 1000,
  },
};

const themeLight = {
  ...baseTheme,
  name: 'light',
  inverseName: 'dark',
  colors: {
    ...baseTheme.colors,
    contentBackground: '#ffffff',
    contentBackgroundCard: '#f4f4f4',
    contentBackgroundFocus: '#eaeaea',
    contentBackgroundRGBA: 'rgba(255, 255, 255, 1)',
    midtonesStatic: '#e2e2e2',
    midtonesText: '#454646',
    navigationMenuBackground: 'linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.95))',
    navigationMenuText: baseTheme.colors.brand,
    navigationMenuNoBackgroundText: baseTheme.colors.brandAlternate,
    shadow: 'rgba(0, 0, 0, 0.16)',
    text: baseTheme.colors.brand,
    textAlternate: baseTheme.colors.brandAlternate,
  },
};

const themeDark = {
  ...baseTheme,
  name: 'dark',
  inverseName: 'light',
  colors: {
    ...baseTheme.colors,
    contentBackground: '#010101',
    contentBackgroundCard: '#1a1a1a',
    contentBackgroundFocus: '#272727',
    contentBackgroundRGBA: 'rgba(1, 1, 1, 1)',
    midtonesStatic: '#454646',
    midtonesText: '#e2e2e2',
    navigationMenuBackground: 'linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.95))',
    navigationMenuText: baseTheme.colors.brandAlternate,
    navigationMenuTextNoBackground: baseTheme.colors.brandAlternate,
    shadow: 'rgba(255, 255, 255, 0.16)',
    text: baseTheme.colors.brandAlternate,
    textAlternate: baseTheme.colors.brand,
  },
};

export { baseTheme, themeLight, themeDark };
