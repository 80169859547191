import { gql } from '@apollo/client';

/* Mutations */

export const deleteInspectionLogFindingsMutation = gql`
  mutation DeleteInspectionLogFindings($inspectionLogFindingIdList: [String!]!) {
    inspectionLogFindings: deleteInspectionLogFinding(inspectionLogFindingIdList: $inspectionLogFindingIdList) {
      id
    }
  }
`;

export const setTestAssetForInspectionLogFindingsMutation = gql`
  mutation SetTestAssetForInspectionLogFindings($inspectionLogFindingIdList: [String!]!, $testAssetId: String!) {
    inspectionLogFindings: setInspectionLogFindingInspectionLogTestAssetId(
      inspectionLogFindingIdList: $inspectionLogFindingIdList
      inspectionLogTestAssetId: $testAssetId
    ) {
      id
      inspectionLogTestAssetId
    }
  }
`;

export const tagInspectionLogFindingsMutation = gql`
  mutation TagInspectionLogFindings($inspectionLogFindingIdList: [String!]!, $tag: EInspectionLogFindingTag!) {
    inspectionLogFindings: singleTagInspectionLogFinding(
      inspectionLogFindingIdList: $inspectionLogFindingIdList
      inspectionLogFindingTag: $tag
    ) {
      id
      tagList
    }
  }
`;

export const tagAndSetTestAssetForInspectionLogFindingsMutation = gql`
  mutation TagAndSetTestAssetForInspectionLogFindings(
    $inspectionLogFindingIdList: [String!]!
    $tag: EInspectionLogFindingTag
    $testAssetId: String
  ) {
    inspectionLogFindings: singleTagAndSetInspectionLogFindingInspectionLogTestAssetId(
      inspectionLogFindingIdList: $inspectionLogFindingIdList
      inspectionLogFindingTag: $tag
      inspectionLogTestAssetId: $testAssetId
    ) {
      id
      inspectionLogTestAssetId
      tagList
    }
  }
`;

export const unsetTestAssetForInspectionLogFindingsMutation = gql`
  mutation UnsetTestAssetForInspectionLogFindings($inspectionLogFindingIdList: [String!]!) {
    inspectionLogFindings: unsetInspectionLogFindingInspectionLogTestAssetId(
      inspectionLogFindingIdList: $inspectionLogFindingIdList
    ) {
      id
      inspectionLogTestAssetId
    }
  }
`;

export const untagInspectionLogFindingsMutation = gql`
  mutation UntagInspectionLogFindings($inspectionLogFindingIdList: [String!]!) {
    inspectionLogFindings: untagInspectionLogFinding(inspectionLogFindingIdList: $inspectionLogFindingIdList) {
      id
      tagList
    }
  }
`;
