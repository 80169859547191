import { useLazyQuery, useMutation } from '@apollo/client';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Accordion from 'OK/components/accordion';
import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import ButtonGroup from 'OK/components/buttonGroup';
import { Carousel, Slide } from 'OK/components/carousel';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import OrganisationArchiveCard from 'OK/components/organisation/archiveCard';
import Text from 'OK/components/text';
import ShareOKIDPopup from 'OK/modules/popups/shareOKID';
import { applyToJoinOrganisationMutation } from 'OK/networking/organisations';
import { SearchOrganisationsQuery } from 'OK/networking/search';
import {
  acceptOrganisationInvitationMutation,
  cancelOrganisationJoinRequestMutation,
  leaveOrganisationMutation,
  rejectOrganisationInvitationMutation,
} from 'OK/networking/users';
import { setComingFromUserPage, showCreatingNewOrganisation } from 'OK/state/account/actions';
import { showAuthModal, showScannerAction } from 'OK/state/app/actions';
import getStore from 'OK/state/store';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import UIContext from 'OK/util/context/ui';
import { formatOkid } from 'OK/util/formatting';
import { setNativeValue } from 'OK/util/functions';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';
import SessionManager from 'OK/util/session';

const ORGANISATION_SEARCH_MODE = {
  FILTER: 'FILTER',
  SEARCH: 'SEARCH',
};

export default function UserMembershipsSection(props) {
  /* Variables */

  const { teamsSectionRef, user } = props;
  const { t, tHTML } = useI18n();
  const [, , currentUser] = useAuthentication(() => false);
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const dispatch = useDispatch();
  const store = getStore();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const userOrganisations = useMemo(() => user?.organisationList ?? [], [user?.organisationList]);
  const userOrganisationsFullList = useMemo(() => {
    return [
      ...(user?.organisationInviteList ?? []),
      ...userOrganisations,
      ...(user?.joinRequestOrganisationList?.slice().reverse() ?? []),
    ];
  }, [user?.joinRequestOrganisationList, user?.organisationInviteList, userOrganisations]);
  const OKID = currentUser?.OKID ? formatOkid(currentUser.OKID) : null;
  const isCurrentUser = currentUser && user && currentUser.id === user.id;

  // State

  const [copiedOKID, setCopiedOKID] = useState(false);
  const [membershipsError, setMembershipsError] = useState(null);

  const [organisationSearchMode, setOrganisationSearchMode] = useState(ORGANISATION_SEARCH_MODE.SEARCH);
  const [organisationSearchString, setOrganisationSearchString] = useState('');
  const [showOKIDPopup, setShowOKIDPopup] = useState(false);
  const [showLeaveConfirm, setShowLeaveConfirm] = useState(false);
  const [idPassToLeave, setIdPassToLeave] = useState();
  const [membershipsAccordionOpen, setMembershipsAccordionOpen] = useState(true);
  const [membershipsAccordionEdit, setMembershipsAccordionEdit] = useState(false);

  // Refs

  const searchOrganisationsInputRef = useRef();

  /* API */

  const [acceptOrganisationInvitationAPI] = useMutation(acceptOrganisationInvitationMutation);
  const [applyToJoinOrganisationAPI] = useMutation(applyToJoinOrganisationMutation);
  const [cancelOrganisationJoinRequestAPI] = useMutation(cancelOrganisationJoinRequestMutation);
  const [leaveOrganisationAPI] = useMutation(leaveOrganisationMutation);
  const [rejectOrganisationInvitationAPI] = useMutation(rejectOrganisationInvitationMutation);
  const [searchAPI, searchAPIResult] = useLazyQuery(SearchOrganisationsQuery);

  /* Methods */

  const acceptOrganisationInvitation = useCallback(
    (organisationId, setIsLoading) => {
      setMembershipsError(null);
      setIsLoading(true);
      acceptOrganisationInvitationAPI({
        variables: { organisationId },
      })
        .catch(() => {
          setMembershipsError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [acceptOrganisationInvitationAPI, t]
  );

  const applyToJoinOrganisation = useCallback(
    (organisationId, setIsLoading) => {
      setMembershipsError(null);
      setIsLoading(true);
      applyToJoinOrganisationAPI({
        variables: { organisationId },
      })
        .then(() => {
          setOrganisationSearchString('');
        })
        .catch(() => {
          setMembershipsError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [applyToJoinOrganisationAPI, t]
  );

  const cancelJoinRequest = useCallback(
    (organisationId, setIsLoading) => {
      setMembershipsError(null);
      setIsLoading(true);
      cancelOrganisationJoinRequestAPI({
        variables: { organisationId },
      })
        .catch(() => {
          setMembershipsError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [cancelOrganisationJoinRequestAPI, t]
  );

  const chooseOrganisation = useCallback((organisationId) => {
    SessionManager.activeOrganisationId = organisationId;
  }, []);

  const leaveOrganisation = useCallback(
    (organisationId) => {
      leaveOrganisationAPI({
        variables: { organisationId },
      })
        .then(() => {
          setIdPassToLeave(null);
        })
        .catch(() => {
          setMembershipsError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          // setIsLoading(false);
          setShowLeaveConfirm(false);
        });
    },
    [leaveOrganisationAPI, t]
  );

  const promptLeaveOrganisation = useCallback((id) => {
    setShowLeaveConfirm(true);
    setIdPassToLeave(id);
  }, []);

  function dismissLeaveOrganisation() {
    setShowLeaveConfirm(false);
  }

  const rejectOrganisationInvitation = useCallback(
    (organisationId, setIsLoading) => {
      setMembershipsError(null);
      setIsLoading(true);
      rejectOrganisationInvitationAPI({
        variables: { organisationId },
      })
        .catch(() => {
          setMembershipsError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [rejectOrganisationInvitationAPI, t]
  );

  const onClickCreateOrganisation = useCallback(() => {
    setTimeout(() => {
      setTimeout(() => {
        store.dispatch(showAuthModal(true));
      }, 10);

      store.dispatch(setComingFromUserPage(true));
      store.dispatch(showCreatingNewOrganisation(true));
    }, 100);
  }, [store]);

  const searchOrganisationDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchAPI({
          variables: {
            ignoreIdListByDataType: [
              { dataType: 'ORGANISATION', ignoreIdList: userOrganisationsFullList.map((o) => o.id) },
            ],
            searchPaginationDataByDataType: [
              { dataType: 'ORGANISATION', searchPaginationData: { skip: 0, pageSize: 10 } },
            ],
            searchString,
            sort: 'RELEVANCE_DESC',
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchAPI, userOrganisationsFullList]
  );

  // Event handlers

  const onOrganisationSearch = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setOrganisationSearchString(newSearchString);

      if (organisationSearchMode === ORGANISATION_SEARCH_MODE.SEARCH && newSearchString.length >= 2) {
        searchOrganisationDebounced(newSearchString);
      }
    },
    [organisationSearchMode, searchOrganisationDebounced]
  );

  const onScanOrganisationOKID = useCallback((okid) => {
    // Not sure why timeout is required to make this work
    setTimeout(() => {
      setNativeValue(searchOrganisationsInputRef.current, okid);
      searchOrganisationsInputRef.current.focus();
    }, 0);
  }, []);

  const showOrganisationOKIDScanner = useCallback(() => {
    dispatch(showScannerAction(onScanOrganisationOKID));
  }, [dispatch, onScanOrganisationOKID]);

  /* Effects */

  // Reset copied state after 3 seconds
  useEffect(() => {
    let resettingTimeout;
    if (copiedOKID) {
      resettingTimeout = setTimeout(() => {
        setCopiedOKID(false);
      }, 3000);
    }

    return function () {
      clearTimeout(resettingTimeout);
    };
  }, [copiedOKID]);

  /* Render */

  const organisationSearchResults = useMemo(() => {
    let listedOrganisations;
    if (organisationSearchString.length > 0) {
      if (organisationSearchMode === ORGANISATION_SEARCH_MODE.FILTER) {
        const regex = new RegExp(organisationSearchString, 'i');
        listedOrganisations = userOrganisationsFullList.filter((o) => o.name.match(regex) || o.OKID.match(regex));
      } else {
        listedOrganisations = searchAPIResult.data?.search?.resultList?.map((r) => r.organisationData) ?? [];
      }
    } else {
      listedOrganisations = userOrganisationsFullList;
    }

    if (listedOrganisations.length > 0) {
      return (
        <>
          <Carousel
            className={styles.organisationCarouselContainer}
            fadeOutSides={useDesktopLayout}
            innerClassName={styles.organisationCarousel}
          >
            {listedOrganisations.map((o) => {
              const isActiveOrganisation = o.id === activeOrganisationId;
              return (
                <Slide className={styles.organisationSlide} key={o.id}>
                  {membershipsAccordionEdit ? (
                    <OrganisationArchiveCard
                      cardClassName={`${styles.resultCard} ${isActiveOrganisation ? styles.active : ''}`}
                      className={styles.organisationCard}
                      fixedWidth={useDesktopLayout}
                      mediaGalleryClassName={`${styles.resultMedia} ${isActiveOrganisation ? styles.active : ''}`}
                      onClickAcceptInvitation={acceptOrganisationInvitation}
                      onClickApplyToJoin={applyToJoinOrganisation}
                      onClickCancelApplication={cancelJoinRequest}
                      onClickLeave={promptLeaveOrganisation}
                      onClickMakeActiveOrganisation={chooseOrganisation}
                      onClickRejectInvitation={rejectOrganisationInvitation}
                      organisation={o}
                      showAccountOptions
                    />
                  ) : (
                    <OrganisationArchiveCard
                      cardClassName={`${styles.resultCard} ${isActiveOrganisation ? styles.active : ''}`}
                      className={styles.organisationCard}
                      fixedWidth={useDesktopLayout}
                      mediaGalleryClassName={`${styles.resultMedia} ${isActiveOrganisation ? styles.active : ''}`}
                      organisation={o}
                      showActiveTag
                    />
                  )}
                </Slide>
              );
            })}
          </Carousel>
        </>
      );
    }

    if (organisationSearchMode === ORGANISATION_SEARCH_MODE.SEARCH) {
      if (searchAPIResult.loading) {
        return (
          <TextLayout>
            <Icon height={40} name={ICONS.SPINNER.name} styles={{ display: 'block', marginBottom: 20 }} width={40} />
          </TextLayout>
        );
      }
    }

    return <Text>{t('NO_ORGANISATIONS')}</Text>;
  }, [
    acceptOrganisationInvitation,
    activeOrganisationId,
    applyToJoinOrganisation,
    cancelJoinRequest,
    chooseOrganisation,
    membershipsAccordionEdit,
    organisationSearchMode,
    organisationSearchString,
    promptLeaveOrganisation,
    rejectOrganisationInvitation,
    searchAPIResult.data?.search?.resultList,
    searchAPIResult.loading,
    t,
    useDesktopLayout,
    userOrganisationsFullList,
  ]);

  const accountContent = useMemo(() => {
    let organisation;
    if (idPassToLeave) {
      const organisationToLeave = userOrganisations.find((o) => {
        return o.id === idPassToLeave;
      });
      organisation = organisationToLeave?.name;
    }

    return (
      <>
        <Accordion
          className={`${styles.accordion} ${membershipsAccordionEdit && styles.edit}`}
          editSection
          fixedWidth={false}
          onChangeEdit={setMembershipsAccordionEdit}
          onChangeOpen={setMembershipsAccordionOpen}
          headerClassName={styles.headerAccordion}
          hideSection
          showEdit={isCurrentUser}
          open={membershipsAccordionOpen}
          edit={membershipsAccordionEdit}
          title={
            <div className={styles.header}>
              <Icon className={styles.icon} name={ICONS.PROFILE.name} height={24} width={24} />
              &nbsp;&nbsp;
              {t('ACCOUNT_SECTION_MEMBERSHIPS')}
            </div>
          }
          toggleButtonClassname={styles.toggleButton}
        >
          <ContentLayout className={styles.membershipsSection} ref={teamsSectionRef} pageContent>
            <UIContext.Provider value='card'>
              <Text className={styles.sectionDescription}>{t('ACCOUNT_SECTION_MEMBERSHIPS_DESCRIPTION')}</Text>
              <ButtonGroup buttonStyle='separate' className={styles.organisationSearchModes}>
                <button
                  active={organisationSearchMode === ORGANISATION_SEARCH_MODE.SEARCH}
                  onClick={() => setOrganisationSearchMode(ORGANISATION_SEARCH_MODE.SEARCH)}
                >
                  {t('SEARCH_FOR_NEW')}
                </button>
                <button
                  active={organisationSearchMode === ORGANISATION_SEARCH_MODE.FILTER}
                  onClick={() => setOrganisationSearchMode(ORGANISATION_SEARCH_MODE.FILTER)}
                >
                  {t('FILTER_CURRENT')}
                </button>
              </ButtonGroup>
              <Input
                accessoryButton={<Button icon={ICONS.CAMERA.name} onClick={showOrganisationOKIDScanner} linkStyle />}
                className={styles.organisationSearchInput}
                onChange={onOrganisationSearch}
                placeholder={
                  organisationSearchMode === ORGANISATION_SEARCH_MODE.SEARCH
                    ? t('ORGANISATION_SEARCH_PLACEHOLDER')
                    : t('ORGANISATION_FILTER_PLACEHOLDER')
                }
                ref={searchOrganisationsInputRef}
                value={organisationSearchString}
              />
              {membershipsError && (
                <Text className={styles.membershipsError} size='sm' tint='notification'>
                  {membershipsError}
                </Text>
              )}

              {organisationSearchResults}
              {isCurrentUser && membershipsAccordionEdit && (
                <Button
                  block={useMobileLayout}
                  buttonStyle
                  icon={ICONS.PLUS.name}
                  onClick={onClickCreateOrganisation}
                  tint='creation'
                  style={{ marginBottom: 0, marginTop: 20 }}
                >
                  {t('CREATE_NEW_ORGANISATION')}
                </Button>
              )}
            </UIContext.Provider>
          </ContentLayout>
        </Accordion>
        {showLeaveConfirm && (
          <Alert
            title={t('LEAVE_ORGANISATION_TITLE')}
            message={tHTML('LEAVE_ORGANISATION_QUESTION', {
              data: {
                organisation: organisation,
              },
            })}
            buttons={
              <>
                <Button
                  block
                  className={styles.abortInspectionButton}
                  onClick={() => leaveOrganisation(idPassToLeave)}
                  tint='alert'
                >
                  {t('LEAVE_ORGANISATION_CONFIRM')}
                </Button>
                <Button block linkStyle onClick={dismissLeaveOrganisation}>
                  {t('LEAVE_ORGANISATION_DISMISS')}
                </Button>
              </>
            }
          />
        )}
      </>
    );
  }, [
    idPassToLeave,
    isCurrentUser,
    leaveOrganisation,
    membershipsAccordionEdit,
    membershipsAccordionOpen,
    membershipsError,
    onClickCreateOrganisation,
    onOrganisationSearch,
    organisationSearchMode,
    organisationSearchResults,
    organisationSearchString,
    showLeaveConfirm,
    showOrganisationOKIDScanner,
    t,
    tHTML,
    teamsSectionRef,
    useMobileLayout,
    userOrganisations,
  ]);

  return (
    <>
      {accountContent}
      {showOKIDPopup && (
        <ShareOKIDPopup
          dismiss={() => setShowOKIDPopup(false)}
          instructions={t('SHARE_OKID_INSTRUCTIONS')}
          name={t('COWORKER')}
          OKID={OKID}
        />
      )}
    </>
  );
}

UserMembershipsSection.propTypes = {
  teamsSectionRef: PropTypes.string,
  user: PropTypes.object,
};
