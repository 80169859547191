import { useLazyQuery, useMutation } from '@apollo/client';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import SiteContactEditCard from 'OK/components/contactsSection/contactCard';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import ContentLayout from 'OK/components/layouts/content';
import SearchSuggestions from 'OK/components/searchSuggestions';
import SiteContactViewCard from 'OK/components/siteContact/viewCard';
import Text from 'OK/components/text';
import ContactModel from 'OK/models/contact';
import InspectionLogModel from 'OK/models/inspectionLog';
import OrderModel from 'OK/models/order';
import SiteModel from 'OK/models/site';
import {
  createManualContactMutation,
  linkUserAsContactMutation,
  unlinkAssetOnContactMutation,
} from 'OK/networking/contacts';
import { SearchAllUsersQuery } from 'OK/networking/search';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import { formatOkid } from 'OK/util/formatting';
import isAuthorised from 'OK/util/functions/isAuthorised';
import useAuthorisation from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';

export default function EditSitePageContactSection(props) {
  /* Variables */

  const { asset, editMode = false, sourceType, sourceId } = props;
  const { t } = useI18n();

  const authorisationLevel = useAuthorisation(asset);
  const contacts = asset?.contactList;
  const isManager = isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER);
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const theme = useContext(ThemeContext);
  let cacheId;
  if (sourceType == 'SITE') {
    cacheId = `${SiteModel.GRAPHQL_TYPE}:${asset?.id}`;
  } else if (sourceType == 'ORDER') {
    cacheId = `${OrderModel.GRAPHQL_TYPE}:${asset?.id}`;
  } else if (sourceType == 'INSPECTION_LOG') {
    cacheId = `${InspectionLogModel.GRAPHQL_TYPE}:${asset?.id}`;
  }
  // Refs

  const carouselRef = useRef();
  const searchContactsInputRef = useRef();

  /* State */

  const [filterContactsString] = useState('');
  const [searchString, setSearchString] = useState('');
  const [isCreatingNewContact, setIsCreatingNewContact] = useState(false);
  const [newContact, setNewContact] = useState(null);
  const [searchContactsFocused, setSearchContactsFocused] = useState(false);

  // APIs

  const [createManualContactAPI] = useMutation(createManualContactMutation);
  const [searchContactsAPI, searchContactsAPIResult] = useLazyQuery(SearchAllUsersQuery);
  const [linkContactAPI] = useMutation(linkUserAsContactMutation);
  const [unlinkContactAPI] = useMutation(unlinkAssetOnContactMutation);

  // Helpders

  {
    /* eslint-disable indent */
  }
  var assetKey = 'orderContact';
  sourceType === 'SITE'
    ? (assetKey = 'siteContact')
    : sourceType === 'ORDER'
    ? (assetKey = 'orderContact')
    : 'inspectionLogContact';
  {
    /* eslint-enable indent */
  }

  const indexOfContactInList = useCallback(
    (contactId, list, readField) => {
      return list.findIndex((pn) => {
        const listContact = readField(assetKey, pn);
        const listContactId = readField('id', listContact);
        return listContactId === contactId;
      });
    },
    [assetKey]
  );

  /* Methods */

  const searchContactsDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchContactsAPI({
          variables: {
            searchPaginationDataByDataType: [{ dataType: 'USER', searchPaginationData: { skip: 0, pageSize: 4 } }],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchContactsAPI]
  );

  const unlinkContact = useCallback(
    (contact) => {
      return unlinkContactAPI({
        variables: { contactId: contact.id, sourceId: sourceId, sourceType: sourceType },
        update: (cache, result) => {
          if (result?.data?.contact) {
            cache.modify({
              id: cacheId,
              fields: {
                contactList: (currentList, { readField }) => {
                  const { contact } = result.data;
                  const indexOfContact = indexOfContactInList(contact.id, currentList, readField);
                  if (indexOfContact > -1) {
                    const updatedList = [...currentList];
                    updatedList.splice(indexOfContact, 1);
                    return updatedList;
                  }
                },
              },
            });
          }
        },
      });
    },
    [cacheId, indexOfContactInList, sourceId, sourceType, unlinkContactAPI]
  );

  const cancelCreatingNote = () => {
    setIsCreatingNewContact(false);
    setNewContact(null);
  };

  const showCreateContactUI = () => {
    setIsCreatingNewContact(true);
    if (!newContact) {
      const newContact = ContactModel.mock();
      setNewContact(newContact);
      setTimeout(() => {
        carouselRef.current.scrollToSlideAtIndex(contacts.length ? contacts.length : 0);
      }, 100);
    }
  };

  const onSaveNewContact = (updatedContact) => {
    setIsCreatingNewContact(false);
    if (updatedContact.name) {
      const linkAssetIdListSorted = updatedContact?.contactLinkInputList.map((asset) => {
        return { sourceId: asset.dataId, sourceType: asset.dataType };
      });
      createManualContactAPI({
        variables: {
          sourceId: sourceId,
          sourceType: sourceType,
          role: updatedContact?.role,
          name: updatedContact?.name,
          phone: updatedContact?.phone,
          email: updatedContact?.email,
          contactLinkInputList: linkAssetIdListSorted,
        },
        update: (cache, result) => {
          if (result?.data?.contact) {
            const contactRef = cache.writeFragment({
              id: `${ContactModel.GRAPHQL_TYPE}:${result.data.contact.id}`,
              fragment: ContactModel.fragmentContacts,
              fragmentName: ContactModel.fragmentNameContacts,
              data: result.data.contact,
            });
            cache.modify({
              id: cacheId,
              fields: {
                contactList: (currentList) => {
                  return [...currentList, contactRef];
                },
              },
            });
          }
        },
      });
    }
  };

  const onChangeSearchString = useCallback(
    (e) => {
      setSearchString(e.target.value);

      if (searchString.length > 0) {
        searchContactsDebounced(searchString);
      }
    },
    [searchContactsDebounced, searchString]
  );

  let searchContactsSuggestions;
  if (searchString.length > 1 && searchContactsFocused) {
    searchContactsSuggestions =
      searchContactsAPIResult.data?.search?.resultList?.map((r) => {
        return {
          icon: r.userData?.avatar ? (
            theme.name === 'light' ? (
              <img src={r.userData?.avatar?.sourceLightURL} alt='No image' width='48' height='48' />
            ) : (
              <img src={r.userData?.avatar?.sourceDarkURL} alt='No image' width='48' height='48' />
            )
          ) : (
            <img src={`/img/empty_media_${theme.name}.svg`} alt='No image' width='48' height='48' />
          ),
          key: r.userData?.id,
          title: r.userData?.name ?? 'No name',
          subtitle: formatOkid(r.userData?.OKID),
        };
      }) ?? [];
  }

  const onSelectionContact = useCallback(
    (searchContactsSuggestions) => {
      const selectedContactId = searchContactsSuggestions;
      const selectedContact = searchContactsAPIResult.data?.search?.resultList?.find((contact) => {
        return contact.userData.id == selectedContactId;
      });
      linkContactAPI({
        variables: { sourceId: sourceId, sourceType: sourceType, userId: selectedContact.userData.id },
        update: (cache, result) => {
          if (result?.data?.contact) {
            const contactRef = cache.writeFragment({
              id: `${ContactModel.GRAPHQL_TYPE}:${result.data.contact.id}`,
              fragment: ContactModel.fragmentContacts,
              fragmentName: ContactModel.fragmentNameContacts,
              data: result.data.contact,
            });

            cache.modify({
              id: cacheId,
              fields: {
                contactList: (currentList) => {
                  return [...currentList, contactRef];
                },
              },
            });
          }
        },
      }).finally(() => {
        setTimeout(() => {
          carouselRef.current.scrollToSlideAtIndex(contacts.length);
        }, 50);
      });
      setSearchString('');
    },
    [cacheId, contacts?.length, linkContactAPI, searchContactsAPIResult.data?.search?.resultList, sourceId, sourceType]
  );

  /* Render */

  let visibleContacts;
  if (filterContactsString) {
    // Filter contacts
    visibleContacts = contacts.filter((c) => {
      const regex = new RegExp(filterContactsString, 'i');
      return regex.test(c.name) || regex.test(c.role) || regex.test(c.email) || regex.test(c.phone);
    });
  } else {
    // Show all contacts
    visibleContacts = contacts;
  }

  return (
    <div>
      {editMode ? (
        <ContentLayout pageContent>
          <div className={styles.sectionIntro}>
            {sourceType == 'SITE' && <Text>{t('CONTACTS_SECTION_DESCRIPTION_SITE_ASSET')}</Text>}
            {sourceType == 'ORDER' && <Text>{t('CONTACTS_SECTION_DESCRIPTION_ORDER_ASSET')}</Text>}
            {sourceType == 'INSPECTION_LOG' && <Text>{t('CONTACTS_SECTION_DESCRIPTION_INSPECTION_LOG_ASSET')}</Text>}
            {isManager ? (
              <div className={styles.searchInputContainer}>
                <SearchInput
                  className={styles.searchInput}
                  loading={searchContactsAPIResult.loading}
                  onBlur={() => setSearchContactsFocused(false)}
                  onChange={onChangeSearchString}
                  onFocus={() => setSearchContactsFocused(true)}
                  placeholder={t('CONTACTS_SECTION_SEARCH_PLACEHOLDER')}
                  ref={searchContactsInputRef}
                  value={searchString}
                />
                <Button className={styles.searchNewButton} linkStyle onClick={showCreateContactUI} tint='creation'>
                  {t('NEW')}
                </Button>
                <SearchSuggestions
                  accessoryViewRender={() => (
                    <Button
                      className={styles.searchResultButton}
                      icon='/icons/link_green.svg'
                      tint='creation'
                      linkStyle
                    >
                      {t('LINK')}
                    </Button>
                  )}
                  className={styles.searchResults}
                  highlightTerm={searchString}
                  onSuggestionClick={onSelectionContact}
                  showMoreResultsMessage={searchContactsSuggestions?.length > 4}
                  showNoResultsMessage={searchContactsSuggestions?.length === 0}
                  suggestions={searchContactsSuggestions?.slice(0, 4)}
                />
              </div>
            ) : null}
          </div>
          {visibleContacts?.length >= 0 ? (
            <Carousel
              className={styles.contactsCarouselOuter}
              fadeOutSides={useDesktopLayout}
              innerClassName={styles.contactsCarouselInner}
              ref={carouselRef}
            >
              {visibleContacts?.map((c) => (
                <Slide className={styles.contactSlide} key={c.id}>
                  <SiteContactEditCard
                    key={c.id}
                    className={styles.contactCard}
                    fixedWidth={false}
                    isAuthorisedToEdit={isManager}
                    onClickDelete={unlinkContact}
                    saved={c.id !== 'NEW'}
                    contact={c}
                    mode={'EDIT'}
                    type
                    linkDataType='ORDER'
                    sourceId={sourceId}
                    sourceType={sourceType}
                  />
                </Slide>
              ))}
              {!isCreatingNewContact ? (
                isManager ? (
                  <Slide className={styles.contactSlide}>
                    <button className={styles.addSlideButton} onClick={showCreateContactUI}>
                      <Icon className={styles.addSlideIcon} height={80} name={ICONS.PLUS_BIG_CIRCLE.name} width={80} />
                      {t('ADD_NEW_CONTACT')}
                    </button>
                  </Slide>
                ) : null
              ) : (
                <Slide className={styles.contactSlide} key={newContact.id}>
                  <SiteContactEditCard
                    key={newContact.id}
                    className={styles.contactCard}
                    isAuthorisedToEdit={isManager}
                    fixedWidth={false}
                    onClickDelete={cancelCreatingNote}
                    onTriggerSave={onSaveNewContact}
                    saved={false}
                    contact={newContact}
                    mode={'CREATE'}
                    linkDataType='ORDER'
                    sourceId={sourceId}
                    sourceType={sourceType}
                  />
                </Slide>
              )}
            </Carousel>
          ) : (
            <Text tint='notification'>{t('NO_CONTACTS')}</Text>
          )}
        </ContentLayout>
      ) : (
        <ContentLayout style={{ paddingTop: '30px' }} pageContent>
          {visibleContacts.length > 0 ? (
            <Carousel
              className={styles.contactsCarouselOuter}
              fadeOutSides={useDesktopLayout}
              innerClassName={styles.contactsCarouselInner}
            >
              {visibleContacts?.map((c) => (
                <Slide className={styles.contactSlide} key={c.id}>
                  <SiteContactViewCard className={styles.contactCard} fixedWidth={false} contact={c} />
                </Slide>
              ))}
            </Carousel>
          ) : (
            <Text>{t('NO_CONTACTS')}</Text>
          )}
        </ContentLayout>
      )}
    </div>
  );
}

EditSitePageContactSection.propTypes = {
  allowMinMaxBehavior: PropTypes.bool,
  editMode: PropTypes.bool,
  asset: PropTypes.object,
  sourceType: PropTypes.string,
  sourceId: PropTypes.string,
};
