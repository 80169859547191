/**
 * Get a basic type from a MIME type. Ex: image/jpeg -> image.
 *
 * @param {string} mimeType A MIME type.
 *
 * @returns {string}
 */
export default function simpleMIMEType(mimeType) {
  return mimeType.split('/')[0];
}
