import PropTypes from 'prop-types';

/**
 * @typedef {Object} ArchiveCardFooterProps
 * @prop {Node} children
 */

/**
 * Footer container.
 *
 * @param {ArchiveCardFooterProps} props
 */
export default function ArchiveCardFooter(props) {
  const { children, className, ...otherProps } = props;

  return (
    <div className={className} {...otherProps}>
      {children}
    </div>
  );
}

ArchiveCardFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
