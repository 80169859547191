import NextImage from "next/legacy/image";
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import CardLayout from 'OK/components/layouts/card';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import LabelTemplateModel, { LabelTemplateName } from 'OK/models/labelTemplate';
import useI18n from 'OK/util/hooks/useI18n';

export default function LabelTemplatePreviewCard(props) {
  /* Variables */

  const { active, className, labelTemplate, onClickMakeActive: onClickMakeActiveProp, ...otherProps } = props;
  const { corners, eyelet, languageCode, size } = labelTemplate;
  const { t } = useI18n();

  // State

  const [activeLoading, setActiveLoading] = useState(false);

  /* Methods */

  // Event handlers

  const onClickMakeActive = useCallback(() => {
    if (onClickMakeActiveProp) {
      onClickMakeActiveProp(labelTemplate.id, setActiveLoading);
    }
  }, [labelTemplate.id, onClickMakeActiveProp]);

  /* Render */

  const labelDimensions = LabelTemplateModel.dimensionsForSize(size);
  let showActiveIndication = false;

  const previewImageUrl = useMemo(() => LabelTemplateModel.previewImageUrl(labelTemplate), [labelTemplate]);

  let classNames = styles.container;
  if (active === true) {
    classNames = `${classNames} ${styles.active}`;
    showActiveIndication = true;
  } else if (active === false) {
    classNames = `${classNames} ${styles.inactive}`;
    showActiveIndication = true;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let activeIndication;
  if (showActiveIndication) {
    if (active) {
      activeIndication = (
        <Text bold className={styles.activeLabel}>
          {t('LABEL_TEMPLATE_PREVIEW_ACTIVE')}
        </Text>
      );
    } else {
      activeIndication = (
        <Button
          block
          className={styles.makeActiveButton}
          disabled={activeLoading}
          loading={activeLoading}
          onClick={onClickMakeActive}
          tint='navigation'
        >
          {t('LABEL_TEMPLATE_PREVIEW_MAKE_ACTIVE')}
        </Button>
      );
    }
  }

  return (
    <CardLayout className={classNames} padded={false} {...otherProps}>
      <div className={styles.photo}>
        <NextImage layout='fill' src={previewImageUrl} />
      </div>
      <div className={styles.details}>
        <Text bold className={styles.type}>
          <LabelTemplateName labelTemplate={labelTemplate} />
        </Text>
        <Text className={styles.dimensions}>
          {labelDimensions.width} x {labelDimensions.height} mm
        </Text>
        <Tag className={styles.textLanguage} size='sm'>
          {languageCode}
        </Tag>
        {corners !== 'NONE' && (
          <Text bold className={styles.spec} size='xs'>
            {t('LABEL_TEMPLATE_PREVIEW_WITH_ROUNDED_CORNERS', { data: { mm: t(corners) } })}
          </Text>
        )}
        {eyelet !== 'NONE' && (
          <Text bold className={styles.spec} size='xs'>
            {t('LABEL_TEMPLATE_PREVIEW_WITH_EYELET')}
          </Text>
        )}
        {activeIndication}
      </div>
    </CardLayout>
  );
}

LabelTemplatePreviewCard.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  labelTemplate: PropTypes.object.isRequired,
  onClickMakeActive: PropTypes.func,
};
