import { gql } from '@apollo/client';

export default class BaseProductChildProductModel {
  static GRAPHQL_TYPE = 'ProductChildProduct';

  static fragmentName = 'Base_ProductChildProduct_Fragment';
  static fragment = gql`
    fragment ${BaseProductChildProductModel.fragmentName} on ${BaseProductChildProductModel.GRAPHQL_TYPE} {
      order
      publishStatus
      quantity
      assetLinkStatus
    }
  `;
}
