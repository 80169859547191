import NextImage from "next/legacy/image";
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { ICONS } from 'OK/components/icon';
import InspectionLogLotResults, { InspectionLogLotResultsFineprint } from 'OK/components/inspectionLog/lotResults';
import InspectionLogSampleResults from 'OK/components/inspectionLog/sampleResults';
import CardLayout from 'OK/components/layouts/card';
import Tag from 'OK/components/tag';
import TestSeverityIcon from 'OK/components/test/severityIcon';
import Text from 'OK/components/text';
import InspectionLogTestAssetModel from 'OK/models/inspectionLogTestAsset';
import InspectionMediaGalleryPopup from 'OK/modules/popups/inspectionMediaGallery';
import { DURATION_PRECISION, DURATION_STYLE, formatDuration } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';
import useLocalizedObject from 'OK/util/hooks/useLocalizedObject';

/**
 * Display results of a test in inspection sample vs. lot.
 *
 * @param {object} props
 * @param {number} props.lotSize The lot size of the inspection this test is a part of.
 * @param {object} props.test The test to render results for.
 */
export default function TestResultCard(props) {
  /* Variables */

  const {
    enableEditingFindings = false,
    index,
    innerClassName,
    inspectionLogREFID,
    lotSize,
    sourceType,
    test,
    ...otherProps
  } = props;
  const {
    failed,
    fixed,
    inspectionLogFindingList = [],
    lotFixableProjection,
    lotFixableProjectionPercent,
    lotFixedPercent,
    lotFlawlessProjection,
    lotFlawlessProjectionPercent,
    lotRejectedPercent,
    lotTolerableProjection,
    lotTolerableProjectionPercent,
    lotUnfixableProjection,
    lotUnfixableProjectionPercent,
    passed,
    samplePassedAsFixedPercent,
    samplePassedAsFlawlessPercent,
    samplePassedAsToleratedPercent,
    sampleRejectedPercent,
    tolerated,
  } = test;
  const testAsset = test.versionedTestAsset ?? test.unversionedTestAsset;
  const metadata = test.metadata ?? {};
  const { t } = useI18n();

  // State

  const [renderFindingsPopup, setRenderFindingsPopup] = useState(false);

  /* Methods */

  const openFindingsPopup = useCallback(() => {
    setRenderFindingsPopup(true);
  }, []);

  /* Render */

  const localizedCriteria = useLocalizedObject(testAsset.whatToLookForMap, true).text;
  const sampleSize = passed + failed;
  const rejected = failed - fixed - tolerated;
  const finalLotSizeCount = lotSize - rejected;
  const previewImageUrl = InspectionLogTestAssetModel.firstImageMediaAssetUrlForTest(test);
  const useConditionalUI = testAsset.testAssetConditionList?.length > 0;
  const identifiers = metadata[InspectionLogTestAssetModel.METADATA_KEYS.IDENTIFIERS] ?? [];
  const trackedTime = metadata[InspectionLogTestAssetModel.METADATA_KEYS.TIME_TRACKING];

  let innerClassNames = styles.containerInner;
  if (innerClassName) {
    innerClassNames += ` ${innerClassName}`;
  }

  return (
    <>
      <CardLayout innerClassName={innerClassNames} {...otherProps}>
        <div>
          <div className={styles.media}>{previewImageUrl && <NextImage layout='fill' src={previewImageUrl} />}</div>
          <h4 className={styles.testHeader}>
            {t('NOTE_CARD_STEP_SEARCH_RESULT', { data: { number: index } })}{' '}
            <TestSeverityIcon className={styles.severityLevelIcon} level={testAsset.testSeverityLevel} />{' '}
            <span className={styles.severityLevelLabel}>{t(`STEP_PRIORITY_LEVEL_${testAsset.testSeverityLevel}`)}</span>
          </h4>
          <Text className={styles.whatToLookForPreview} lineLimit={2}>
            {localizedCriteria}
          </Text>
        </div>
        {inspectionLogFindingList.length > 0 && (
          <Button block icon={ICONS.MEDIA_GALLERY.name} onClick={openFindingsPopup} tint='navigation'>
            {t('STEP_VIEW_STEP_GALLERY')}
          </Button>
        )}
        <InspectionLogSampleResults
          className={styles.sampleResults}
          fixed={fixed}
          passed={passed}
          percentFixed={samplePassedAsFixedPercent}
          percentFlawless={samplePassedAsFlawlessPercent}
          percentRejected={sampleRejectedPercent}
          percentTolerated={samplePassedAsToleratedPercent}
          rejected={rejected}
          sampleSize={sampleSize}
          tolerated={tolerated}
          useConditionalUI={useConditionalUI}
        />
        <InspectionLogLotResults
          className={styles.lotResults}
          finalLotSize={finalLotSizeCount}
          fixed={fixed}
          initialLotSize={lotSize}
          percentFixed={lotFixedPercent}
          percentProjectedFixable={lotFixableProjectionPercent}
          percentProjectedFlawless={lotFlawlessProjectionPercent}
          percentProjectedTolerable={lotTolerableProjectionPercent}
          percentProjectedUnfixable={lotUnfixableProjectionPercent}
          percentRejected={lotRejectedPercent}
          projectedFixable={lotFixableProjection}
          projectedFlawless={lotFlawlessProjection}
          projectedTolerable={lotTolerableProjection}
          projectedUnfixable={lotUnfixableProjection}
          rejected={rejected}
          sourceType={sourceType}
          useConditionalUI={useConditionalUI}
        />
        {identifiers.length > 0 && (
          <div className={styles.identifiersSection}>
            <h4>{t('STEP_LOGGING_CARD_SECTION_HEADER_IDENTIFIERS')}</h4>
            <div>
              {identifiers.map((i) => (
                <Tag key={i} className={styles.identifierTag} size='sm'>
                  {i}
                </Tag>
              ))}
            </div>
          </div>
        )}
        {trackedTime > 0 && (
          <div className={styles.timeSpentSection}>
            <h4>{t('STEP_TIME_SPENT')}</h4>
            <Text>
              {formatDuration(trackedTime, t, { precision: DURATION_PRECISION.MINUTES, style: DURATION_STYLE.MEDIUM })}
            </Text>
          </div>
        )}
        <InspectionLogLotResultsFineprint />
      </CardLayout>
      {renderFindingsPopup && (
        <InspectionMediaGalleryPopup
          dismiss={() => setRenderFindingsPopup(false)}
          enableEditing={enableEditingFindings}
          inspectionLogREFID={inspectionLogREFID}
          limitToInspectionLogTestAssetId={test.id}
        />
      )}
    </>
  );
}

TestResultCard.propTypes = {
  enableEditingFindings: PropTypes.bool,
  index: PropTypes.number.isRequired,
  innerClassName: PropTypes.string,
  inspectionLogREFID: PropTypes.string,
  lotSize: PropTypes.number.isRequired,
  sourceType: PropTypes.string,
  test: PropTypes.object.isRequired,
};
