/** Share something. If possible, the native share UI will be used. Otherwise, it will copy to clipboard. */
export default async function share(resource, type, fallbackToClipboard = true) {
  const canShareNatively = hasNativeSharing();

  let nativeShareError;
  switch (type) {
    case SHARE_TYPE.STRING:
      if (canShareNatively) {
        try {
          await navigator.share({ text: resource });
          return SHARE_ACTION.NATIVE;
        } catch (e) {
          nativeShareError = e;
        }
      }

      if (fallbackToClipboard) {
        await copyTextToClipboard(resource);
        return SHARE_ACTION.CLIPBOARD;
      }

      if (nativeShareError) {
        throw nativeShareError;
      }

      throw new Error('Sharing strings is not supported.');
    case SHARE_TYPE.URL:
      if (canShareNatively) {
        try {
          await navigator.share({ url: resource });
          return SHARE_ACTION.NATIVE;
        } catch (e) {
          nativeShareError = e;
        }
      }

      if (fallbackToClipboard) {
        await copyTextToClipboard(resource);
        return SHARE_ACTION.CLIPBOARD;
      }

      if (nativeShareError) {
        throw nativeShareError;
      }

      throw new Error('Sharing URLs is not supported.');
    case SHARE_TYPE.FILES:
      await navigator.share({ files: resource });
      return SHARE_ACTION.NATIVE;
    default:
      throw new Error(`Invalid share type of ${type}:`, resource);
  }
}

export const SHARE_TYPE = {
  FILES: 'FILES',
  STRING: 'STRING',
  URL: 'URL',
};

export const SHARE_ACTION = {
  CLIPBOARD: 'CLIPBOARD',
  NATIVE: 'NATIVE',
};

export async function copyToClipboard(data, mimeType) {
  if (typeof navigator.clipboard.write !== 'undefined') {
    // eslint-disable-next-line no-undef
    const clipboardItem = new ClipboardItem({ [mimeType]: data });
    return await navigator.clipboard.write([clipboardItem]);
  }

  throw new Error('Cannot write arbitrary data to clipboard.');
}

export async function copyTextToClipboard(text) {
  return await navigator.clipboard.writeText(text);
}

export function hasNativeSharing() {
  return typeof navigator.share !== 'undefined';
}

export function localDownload(dataUrl, filename) {
  const link = document.createElement('a');
  link.download = filename;
  link.href = dataUrl;
  link.click();
}
