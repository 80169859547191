import { PDF_API_URL } from '../utils';

import appConfig from 'OK/config/app';
import { formatOkid } from 'OK/util/formatting';

let jsreport;
if (typeof window !== 'undefined') {
  jsreport = require('@jsreport/browser-client').default;
  jsreport.serverUrl = PDF_API_URL;
  jsreport.headers['Authorization'] = `Basic ${process.env.NEXT_PUBLIC_JSREPORT_AUTH_TOKEN}`;
}

export async function downloadPDFForLabel(label) {
  const { itemList, labelTemplate, REFID } = label;
  const { corners, eyelet, material, size, type } = labelTemplate;

  const parameters = {
    template: {
      name: 'multiple-labels-template',
      recipe: 'chrome-pdf',
    },
    data: {
      config: {
        material,
        qrcodePrefix: `https://${appConfig.domain}/`,
        REFID,
        roundedCorners: corners !== 'NONE',
        showEyelet: eyelet !== 'NONE',
        size,
        type,
      },
      items: itemList.map((i) => {
        return {
          data: {
            OKID: formatOkid(i.OKID),
          },
        };
      }),
    },
  };

  const report = await jsreport.render(parameters);
  const responseContentDisposition = report._response.headers.get('Content-Disposition').split('inline;filename=');
  const downloadFilename =
    responseContentDisposition.length === 2 ? responseContentDisposition[1] : `ok-label_${REFID}.pdf`;
  report.download(downloadFilename);
}
