import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function Radio(props) {
  /* Variables */

  const { children, className, inputClassName, ...otherProps } = props;

  /* Render */

  let classNames = styles.label;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let inputClassNames = styles.checkbox;
  if (inputClassNames) {
    inputClassNames = `${inputClassNames} ${inputClassName}`;
  }

  return (
    <label className={classNames}>
      <input className={inputClassNames} type='radio' {...otherProps} />
      <div>{children}</div>
    </label>
  );
}

Radio.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
};
