import { gql } from '@apollo/client';

import ItemModel from '../item';
import LabelTemplateModel from '../labelTemplate';
import BaseProductModel from '../product/base';

import BaseLabelModel from './base';

export default class LabelModel {
  static GRAPHQL_TYPE = 'Label';

  static fragmentName = 'Label_Fragment';
  static fragment = gql`
    fragment ${LabelModel.fragmentName} on ${LabelModel.GRAPHQL_TYPE} {
      ...${BaseLabelModel.fragmentName}
      itemList {
        ...${ItemModel.fragmentName}
      }
      labelTemplate {
        ...${LabelTemplateModel.fragmentName}
      }
      product {
        ...${BaseProductModel.fragmentName}
      }
    }
    ${BaseLabelModel.fragment}
    ${ItemModel.fragment}
    ${LabelTemplateModel.fragment}
    ${BaseProductModel.fragment}
  `;

  static link = (label) => {
    return `/archive/label/${label.REFID}/edit`;
  };
}
