import { gql } from '@apollo/client';

import { API_URL } from './utils';

import BaseOrganisationModel from 'OK/models/organisation/base';
import UserModel from 'OK/models/user';
import BaseUserDocumentModel from 'OK/models/userDocument/base';
import { request } from 'OK/networking';
import { getCookie } from 'OK/util/cookie';
import { PERSISTENT_STORAGE_KEY } from 'OK/util/enums/persistentStorageKeys';
import USER_METADATA_KEYS from 'OK/util/enums/userMetadataKeys';
import getTimezoneUTCOffset from 'OK/util/functions/getTimezoneUTCOffset';
import { formatLocaleAsLanguageIso } from 'OK/util/i18n';
import SessionManager from 'OK/util/session';
import { getPersistentValue } from 'OK/util/storage';

// GraphQL Queries

export const getCurrentUserQuery = gql`
  query GetCurrentUser {
    user {
      ...${UserModel.fragmentNameCurrentUser}
    }
  }
  ${UserModel.fragmentCurrentUser}
`;

export const getCurrentUserIdQuery = gql`
  query GetCurrentUserId {
    user {
      id
    }
  }
`;

export const getCurrentUserInProgressInspectionLogsQuery = gql`
  query GetCurrentUserInProgressInspectionLogs {
    user {
      ...${UserModel.fragmentNameCurrentUserInProgressInspectionLogs}
    }
  }
  ${UserModel.fragmentCurrentUserInProgressInspectionLogs}
`;

export const getCurrentUserActiveOrganisationDataQuery = gql`
  query GetCurrentUserActiveOrganisationData {
    user {
      ...${UserModel.fragmentNameCurrentUserActiveOrganisationData}
    }
  }
  ${UserModel.fragmentCurrentUserActiveOrganisationData}
`;

export const GetUserForArchiveCardQuery = gql`
  query GetUserForArchiveCard($userId: String!) {
    user: getUser(userId: $userId) {
      ...${UserModel.fragmentNameArchiveCard}
    }
  }
  ${UserModel.fragmentArchiveCard}
`;

export const getUserByOKIDQuery = gql`
  query GetUserByOKID($OKID: String!) {
    user: getUserWithOKID(OKID: $OKID) {
      id
      avatar {
        id
        sourceLightURL
        sourceDarkURL
      }
      email
      mobile
      mobileCountryCode
      name
      OKID
      organisationList {
        ...${BaseOrganisationModel.fragmentName}
      }
      reliabilityPointForPublishedLogs
      userBadgeList {
        documentURL
        issueDate
        issueDocument
        userBadge
      }
      userDocumentAssetList {
        ...${BaseUserDocumentModel.fragmentName}
        organisation {
        ...${BaseOrganisationModel.fragmentName}
        }
        madeByOrganisation {
          id
          conformityPoint
          logoImageMediaAsset {
          logoImageURL
           }
        name
        OKID
        reliabilityPointForPublishedLogs
      }
      }
    }
  }
  ${BaseUserDocumentModel.fragment}
  ${BaseOrganisationModel.fragment}


`;

export const getUserDocumentAssetListByOKIDQuery = gql`
  query GetUserDocumentAssetListByOKIDD($OKID: String!) {
    user: getUserWithOKID(OKID: $OKID) {
      id
      OKID
      userDocumentAssetList {
        ...${BaseUserDocumentModel.fragmentName}
        organisation {
        ...${BaseOrganisationModel.fragmentName}
        }
        madeByOrganisation {
          id
          conformityPoint
          logoImageMediaAsset {
          logoImageURL
           }
        name
        OKID
        reliabilityPointForPublishedLogs
      }
      }
    }
  }
  ${BaseUserDocumentModel.fragment}
  ${BaseOrganisationModel.fragment}


`;

// GraphQL Mutations

export const acceptOrganisationInvitationMutation = gql`
  mutation AcceptOrganisationInvitation($organisationId: String!) {
    user: acceptOrganisationUserInvite(organisationId: $organisationId) {
      id
      managedOrganisationList {
        ...${BaseOrganisationModel.fragmentName}
      }
      memberOrganisationList {
        ...${BaseOrganisationModel.fragmentName}
      }
      organisationList {
        ...${BaseOrganisationModel.fragmentName}
      }
      organisationInviteList {
        ...${BaseOrganisationModel.fragmentName}
      }
    }
  }
  ${BaseOrganisationModel.fragment}
`;

export const cancelOrganisationJoinRequestMutation = gql`
  mutation CancelOrganisationJoinRequest($organisationId: String!) {
    user: cancelJoinOrganisation(organisationId: $organisationId) {
      id
      joinRequestOrganisationList {
        ...${BaseOrganisationModel.fragmentName}
      }
    }
  }
  ${BaseOrganisationModel.fragment}
`;

export const getUserEmailOTPMutation = gql`
  mutation GetUserEmailOTP($email: String!) {
    response: getUserSetEmailOTP(email: $email)
  }
`;

export const getUserMobileOTPMutation = gql`
  mutation GetUserMobileOTP($mobile: String!, $mobileCountryCode: String!) {
    response: getUserSetMobileOTP(mobile: $mobile, mobileCountryCode: $mobileCountryCode)
  }
`;

export const hasSubscribedToNewsLetter = gql`
  query NewsletterSubscriptionCheck($email: String!) {
    response: hasSubscribedToNewsLetter(email: $email) {
      email
      newsletterSubscription
    }
  }
`;

export const leaveOrganisationMutation = gql`
  mutation LeaveOrganisation($organisationId: String!) {
    user: leaveOrganiation(organisationId: $organisationId) {
      id
      managedOrganisationList {
        ...${BaseOrganisationModel.fragmentName}
      }
      memberOrganisationList {
        ...${BaseOrganisationModel.fragmentName}
      }
      organisationList {
        ...${BaseOrganisationModel.fragmentName}
      }
    }
  }
  ${BaseOrganisationModel.fragment}
`;

export const removeUserEmailMutation = gql`
  mutation RemoveUserEmail {
    user: removeUserEmail {
      id
      email
    }
  }
`;

export const removeUserMetadataMutation = gql`
  mutation RemoveUserMetadata($key: String!) {
    user: removeUserMetadata(key: $key) {
      ...${UserModel.fragmentNameCurrentUser}
    }
  }
  ${UserModel.fragmentCurrentUser}
`;

export const removeUserMobileMutation = gql`
  mutation RemoveUserMobile {
    user: removeUserMobile {
      id
      mobile
      mobileCountryCode
    }
  }
`;

export const rejectOrganisationInvitationMutation = gql`
  mutation RejectOrganisationInvitation($organisationId: String!) {
    user: rejectOrganisationUserInvite(organisationId: $organisationId) {
      id
      organisationInviteList {
        ...${BaseOrganisationModel.fragmentName}
      }
    }
  }
  ${BaseOrganisationModel.fragment}
`;

export const setUserNameMutation = gql`
  mutation SetUserName($name: String!) {
    name: setUserName(name: $name) {
      id
      name
    }
  }
`;

export const removeUserNameMutatuion = gql`
  mutation RemoveUserName {
    name: removeUserName {
      id
      name
    }
  }
`;

export const setUserEmailMutation = gql`
  mutation SetUserEmail($email: String!, $otp: String!) {
    response: setUserEmail(email: $email, otp: $otp)
  }
`;

export const setUserMetadataMutation = gql`
  mutation SetUserMetadata($key: String!, $value: Object!) {
    user: setUserMetadata(key: $key, value: $value) {
      id
      metadata
    }
  }
`;

export const setUserMobileMutation = gql`
  mutation SetUserMobile($mobile: String!, $mobileCountryCode: String!, $otp: String!) {
    response: setUserMobile(mobile: $mobile, mobileCountryCode: $mobileCountryCode, otp: $otp)
  }
`;

export const subscribeToMarketingMutation = gql`
  mutation SubscribeToMarketing($channel: EContactChannel!, $email: String, $mobile: String) {
    response: subscribeToMarketing(channel: $channel, email: $email, mobile: $mobile)
  }
`;

export const unsubscribeFromMarketingMutation = gql`
  mutation UnsubscribeFromMarketing($channel: EContactChannel!, $email: String, $mobile: String) {
    response: unsubscribeFromMarketing(channel: $channel, email: $email, mobile: $mobile)
  }
`;

// API Requests

export async function applyToJoinOrganisation(id) {
  return await request(`${API_URL}/user/joinCompany`, {
    method: 'POST',
    parameters: { companyId: id },
  });
}

export async function getCurrentUserProfile() {
  return await request(`${API_URL}/user/profileData`, {
    method: 'GET',
  });
}

export async function createUserDocumentAssetRequest(file, documentType) {
  const metadata = {
    filesize: file.size,
  };
  const parameters = {
    documentFile: file,
    documentType,
    metadataString: JSON.stringify(metadata),
  };

  return request(`${API_URL}/utility/document/user/createUserDocumentAsset`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createUserDocumentAssetAsOrganisationManagerRequest(file, documentType, userId) {
  const metadata = {
    filesize: file.size,
  };
  const parameters = {
    documentFile: file,
    documentType,
    metadataString: JSON.stringify(metadata),
    userId,
  };

  return request(`${API_URL}/utility/document/user/createUserDocumentAssetAsOrganisationManager`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function registerUserViaEmailAndCreateOrganisationWithCampaignRequest(
  name,
  email,
  oneTimePassword,
  organisationName,
  organisationCountryIso,
  campaignId
) {
  const userCountryCode = getPersistentValue(PERSISTENT_STORAGE_KEY.REGION);
  const userLanguageIso = formatLocaleAsLanguageIso(getCookie('NEXT_LOCALE'));
  const userTimezoneUTCOffset = getTimezoneUTCOffset();
  const res = await request(`${API_URL}/user/registerNewUserForNewOrganisationForCampaign`, {
    addAuthorisationHeaders: false,
    method: 'POST',
    parameters: {
      campaignId,
      name,
      email,
      oneTimePassword,
      organisationName,
      organisationCountryCode: organisationCountryIso.toUpperCase(),
      countryCode: userCountryCode,
      languageCode: userLanguageIso,
      timezone: userTimezoneUTCOffset,
      metadata: {
        [USER_METADATA_KEYS.HAS_COMPLETED_ONBOARDING]: true,
      },
    },
  });

  if (res.success) {
    SessionManager.startSession(res.responseData.accessToken);
  }

  return res;
}

export async function registerUserViaEmailAndApplyToOrganisationWithCampaignRequest(
  name,
  email,
  oneTimePassword,
  organisationId,
  campaignId
) {
  const userCountryCode = getPersistentValue(PERSISTENT_STORAGE_KEY.REGION);
  const userLanguageIso = formatLocaleAsLanguageIso(getCookie('NEXT_LOCALE'));
  const userTimezoneUTCOffset = getTimezoneUTCOffset();
  const res = await request(`${API_URL}/user/registerNewUserForExistingOrganisationForCampaign`, {
    addAuthorisationHeaders: false,
    method: 'POST',
    parameters: {
      campaignId,
      name,
      email,
      oneTimePassword,
      organisationId,
      countryCode: userCountryCode,
      languageCode: userLanguageIso,
      timezone: userTimezoneUTCOffset,
      metadata: {
        [USER_METADATA_KEYS.HAS_COMPLETED_ONBOARDING]: true,
      },
    },
  });

  if (res.success) {
    SessionManager.startSession(res.responseData.accessToken);
  }

  return res;
}

export async function traceCurrentUserRegion() {
  okdebug('request', typeof request);
  return await request(`${API_URL}/utility/trace/get`, {
    method: 'GET',
  });
}

// API Response Codes

export const INVITATION_IS_PENDING = 'INVITATION_IS_PENDING';
export const JOIN_REQUEST_IS_PENDING = 'JOIN_REQUEST_IS_PENDING';
export const JOIN_REQUEST_SENT = 'JOIN_REQUEST_SENT';
export const SEARCH_USER_FOUND = 'USER_FOUND';
