import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { indexOfLinkedAssetInCacheList } from '../graphql/helpers';

import {
  linkOrganisationDocumentAssetMutation,
  publishOrganisationDocumentAssetMutation,
  unlinkOrganisationDocumentAssetMutation,
  unpublishOrganisationDocumentAssetMutation,
} from '.';

import BaseDocumentModel from 'OK/models/document/base';
import OrganisationModel from 'OK/models/organisation';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';

/* Hooks */

export function useLinkOrganisationDocumentAssetAPI() {
  const [linkOrganisationDocumentAssetAPI] = useMutation(linkOrganisationDocumentAssetMutation);

  const linkOrganisationDocumentAsset = useCallback(
    (documentAssetId, organisationId) => {
      return linkOrganisationDocumentAssetAPI({
        variables: {
          documentAssetId,
          organisationId,
        },
        update: (cache, result) => {
          if (result.data?.organisationDocument) {
            // Write DocumentAsset to cache
            const documentAssetRef = cache.writeFragment({
              id: `${BaseDocumentModel.GRAPHQL_TYPE}:${result.data.organisationDocument.documentAsset.id}`,
              fragment: BaseDocumentModel.fragment,
              fragmentName: BaseDocumentModel.fragmentName,
              data: result.data.organisationDocument.documentAsset,
            });

            // Add organisationDocumentAsset to organisation's list
            cache.modify({
              id: `${OrganisationModel.GRAPHQL_TYPE}:${organisationId}`,
              fields: {
                organisationDocumentAssetList: (currentList) => {
                  const organisationDocumentAsset = {
                    ...result.data.organisationDocument,
                    documentAsset: documentAssetRef,
                  };
                  return [...currentList, organisationDocumentAsset];
                },
              },
            });
          }
        },
      });
    },
    [linkOrganisationDocumentAssetAPI]
  );

  return linkOrganisationDocumentAsset;
}

export function useUnlinkOrganisationDocumentAssetAPI() {
  const apolloClient = useApolloClient();
  const [unlinkOrganisationDocumentAssetAPI] = useMutation(unlinkOrganisationDocumentAssetMutation);

  const unlinkOrganisationDocumentAsset = useCallback(
    (documentId, organisation) => {
      // Optimistic response
      const documentAsset = apolloClient.readFragment({
        id: `${BaseDocumentModel.GRAPHQL_TYPE}:${documentId}`,
        fragment: BaseDocumentModel.fragment,
        fragmentName: BaseDocumentModel.fragmentName,
      });

      const organisationDocument = organisation.organisationDocumentAssetList.find(
        (pd) => pd.documentAsset.id === documentId
      );
      const responseOrganisationDocument = {
        ...organisationDocument,
        documentAsset,
      };
      const optimisticResponse = {
        organisationDocument: responseOrganisationDocument,
      };

      // Call API
      return unlinkOrganisationDocumentAssetAPI({
        variables: {
          documentAssetId: documentId,
          organisationId: organisation.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.organisationDocument) {
            // Unlink from organisation's organisationDocumentAssetList
            cache.modify({
              id: `${OrganisationModel.GRAPHQL_TYPE}:${organisation.id}`,
              fields: {
                organisationDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfOrganisationDocument = indexOfLinkedAssetInCacheList(
                    currentList,
                    result.data.organisationDocument.documentAsset.id,
                    'documentAsset',
                    readField
                  );
                  updatedList.splice(indexOfOrganisationDocument, 1);
                  return updatedList;
                },
              },
            });

            // Unlink from document's linkedOrganisationList
            cache.modify({
              id: `${BaseDocumentModel.GRAPHQL_TYPE}:${documentId}`,
              fields: {
                linkedOrganisationList: (currentList, { readField }) => {
                  const indexOfOrganisation = currentList.findIndex((lp) => {
                    const linkedOrganisationId = readField('id', lp);
                    return linkedOrganisationId === organisation.id;
                  });
                  if (indexOfOrganisation > -1) {
                    const updatedList = [...currentList];
                    updatedList.splice(indexOfOrganisation);
                    return updatedList;
                  }

                  return currentList;
                },
              },
            });
          } else {
            okerror('Unlink document api did not respond with organisation document.');
          }
        },
      });
    },
    [apolloClient, unlinkOrganisationDocumentAssetAPI]
  );

  return unlinkOrganisationDocumentAsset;
}

export function usePublishOrganisationDocumentAPI(mutationOptions = {}) {
  const [publishDocumentAPI] = useMutation(publishOrganisationDocumentAssetMutation, mutationOptions);

  const publishDocument = useCallback(
    (organisation, documentId) => {
      // Optimistic response
      const organisationDocument = organisation.organisationDocumentAssetList.find(
        (pd) => pd.documentAsset.id === documentId
      );
      const optimisticResponse = {
        organisationDocument: {
          ...organisationDocument,
          publishStatus: PUBLISH_STATUS.PUBLISHED,
        },
      };

      // Call API
      return publishDocumentAPI({
        variables: {
          documentAssetId: documentId,
          organisationId: organisation.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.organisationDocument) {
            cache.modify({
              id: `${OrganisationModel.GRAPHQL_TYPE}:${organisation.id}`,
              fields: {
                organisationDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfOrganisationDocument = indexOfLinkedAssetInCacheList(
                    currentList,
                    result.data.organisationDocument.documentAsset.id,
                    'documentAsset',
                    readField
                  );
                  const currentOrganisationDocument = updatedList[indexOfOrganisationDocument];
                  const updatedOrganisationDocument = {
                    ...currentOrganisationDocument,
                    publishStatus: result.data.organisationDocument.publishStatus,
                  };
                  updatedList.splice(indexOfOrganisationDocument, 1, updatedOrganisationDocument);
                  return updatedList;
                },
              },
            });
          } else {
            okerror('Publish document api did not respond with organisation document.');
          }
        },
      });
    },
    [publishDocumentAPI]
  );

  return publishDocument;
}

export function useUnpublishOrganisationDocumentAPI(mutationOptions) {
  const [unpublishDocumentAPI] = useMutation(unpublishOrganisationDocumentAssetMutation, mutationOptions);

  const unpublishDocument = useCallback(
    (organisation, documentId) => {
      // Optimistic response
      const organisationDocument = organisation.organisationDocumentAssetList.find(
        (pd) => pd.documentAsset.id === documentId
      );
      const optimisticResponse = {
        organisationDocument: {
          ...organisationDocument,
          publishStatus: PUBLISH_STATUS.UNPUBLISHED,
        },
      };

      // Call API
      return unpublishDocumentAPI({
        variables: {
          documentAssetId: documentId,
          organisationId: organisation.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.organisationDocument) {
            cache.modify({
              id: `${OrganisationModel.GRAPHQL_TYPE}:${organisation.id}`,
              fields: {
                organisationDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfOrganisationDocument = indexOfLinkedAssetInCacheList(
                    currentList,
                    result.data.organisationDocument.documentAsset.id,
                    'documentAsset',
                    readField
                  );
                  const updatedOrganisationDocument = {
                    ...updatedList[indexOfOrganisationDocument],
                    publishStatus: result.data.organisationDocument.publishStatus,
                  };
                  updatedList.splice(indexOfOrganisationDocument, 1, updatedOrganisationDocument);
                  return updatedList;
                },
              },
            });
          } else {
            okerror('Unpublish document api did not respond with organisation document.');
          }
        },
      });
    },
    [unpublishDocumentAPI]
  );

  return unpublishDocument;
}
