import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import { Popup, PopupCloseButton, PopupContent, PopupButtonsGroup } from 'OK/components/popup';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import { setOrganisationNameMutation } from 'OK/networking/organisations';

export function EditOrganisationNamePopup(props) {
  const { dismiss, organisationName } = props;

  // APIs

  const [setOrganisationNameAPI, setOrganisationNameAPIResult] = useMutation(setOrganisationNameMutation);

  // States

  const [newOrganisationName, setNewOrganisationName] = useState(organisationName);
  const [nameError, setNameError] = useState(false);

  // Methods

  const onChangeOrganisationName = useCallback((e) => {
    const newValue = e.target.value;
    setNewOrganisationName(newValue);
  }, []);

  const saveOrganisationName = useCallback(() => {
    setOrganisationNameAPI({
      variables: {
        name: newOrganisationName,
      },
    }).catch(() => {
      setNameError('Name can not be empty');
    });
  }, [newOrganisationName, setOrganisationNameAPI]);

  // Effects

  // Hide name error toast after 5 seconds
  useEffect(() => {
    if (nameError) {
      setTimeout(() => {
        setNameError(false);
      }, 5000);
    }
  }, [nameError]);

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.container}>
        <div className={styles.header}>
          <h3 className={styles.textAvatar}>Edit details</h3>
          <div>
            <PopupCloseButton className={styles.buttonSave} linkStyle tint='navigation'>
              Done
            </PopupCloseButton>
          </div>
        </div>
        <h4>Identification</h4>
        <Text bold>Organisation's legal name</Text>
        <Input
          value={newOrganisationName}
          disabled={setOrganisationNameAPIResult.loading}
          onBlur={saveOrganisationName}
          onChange={onChangeOrganisationName}
          showClearButton={!setOrganisationNameAPIResult.loading}
          withIcon={setOrganisationNameAPIResult.loading ? <Icon name={ICONS.SPINNER.name} /> : null}
        />
        <Text className={styles.nameInput} bold size='sm'>
          Please write the full legal name to avoid any future issues, for example if account ownership verification is
          requested later.
        </Text>
        <PopupButtonsGroup>
          <PopupCloseButton tint='navigation'>Done</PopupCloseButton>
        </PopupButtonsGroup>
      </PopupContent>
      <FadeInOutTransition className={styles.toastContainer} appear in={!!nameError}>
        <Toast className={styles.shareToast} tint='alert'>
          {nameError}
        </Toast>
      </FadeInOutTransition>
    </Popup>
  );
}

EditOrganisationNamePopup.propTypes = {
  dismiss: PropTypes.func,
  organisationName: PropTypes.string,
};
