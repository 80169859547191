import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import Select from 'OK/components/select';
import Text from 'OK/components/text';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * Select element for choosing an inspection test.
 *
 * @param {object} props
 * @param {(newValue: string) => {}} props.onChange Event handler when the selected test changes.
 * @param {{label: string, value: string}[]} props.options Tests to choose from.
 * @param {string} props.value Current selection.
 */
export default function InspectionTestSelect(props) {
  const { onChange, options, value, ...otherProps } = props;
  const { t } = useI18n();
  return (
    <Select
      displayLabelRender={(selectedOption) => {
        switch (selectedOption?.value) {
          case null:
          case undefined:
          case '':
            return t('MOVE');
          case 'unsorted':
            return t('LOG_MEDIA_UNSORTED');
          default:
            return (
              <Text className={styles.selectLabel}>
                <Icon className={styles.selectLabelIcon} inline name={ICONS.TEST.name} />
                {selectedOption?.label}
              </Text>
            );
        }
      }}
      noValueLabel={t('DONT_MOVE')}
      onChange={onChange}
      options={options}
      value={value}
      {...otherProps}
    />
  );
}

InspectionTestSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
};
