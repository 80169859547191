import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import CommentsController from 'OK/components/comments/controller';
import Icon, { ICONS } from 'OK/components/icon';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import LinkToInspectionLog from 'OK/components/link/linkToLog';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import PageTitle from 'OK/components/pageTitle';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import Tooltip from 'OK/components/tooltip';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import InspectionLogAssemblySection from 'OK/modules/pages/archive/log/[refId]/assemblySection';
import InspectionLogBreakdownSection from 'OK/modules/pages/archive/log/[refId]/breakdownSection';
import InspectionLogContactsSection from 'OK/modules/pages/archive/log/[refId]/contactsSection';
import InspectionLogFilesSection from 'OK/modules/pages/archive/log/[refId]/filesSection';
import InspectionLogNotesSection from 'OK/modules/pages/archive/log/[refId]/notesSection';
import InspectionLogSummarySection from 'OK/modules/pages/archive/log/[refId]/summarySection';
import ShareAssetPopup from 'OK/modules/popups/share';
import { InspectionLogArchivePageQuery } from 'OK/networking/inspectionLogs';
import { showAuthModal } from 'OK/state/app/actions';
import { baseTheme } from 'OK/styles/theme';
import UIContext from 'OK/util/context/ui';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import isAuthorised from 'OK/util/functions/isAuthorised';
import { OBJECT_PERMISSIONS } from 'OK/util/functions/permissions';
import share, { SHARE_ACTION, SHARE_TYPE } from 'OK/util/functions/share';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';
import usePermission, { PERMISSIONS } from 'OK/util/hooks/usePermission';
import { getFirstMomentOfDate, getLastMomentOfDate } from 'OK/util/functions/date';

export default function LogPage(props) {
  /* Variables */

  const { REFIDasProp } = props;
  const { locale, t } = useI18n();
  const dispatch = useDispatch();
  const router = useRouter();
  const REFID = router.query.refId || REFIDasProp;
  const showInPopup = useSelector((state) => state.app.showInPopup);
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);

  // Essential data

  const getInspectionLogAPIResult = useQuery(InspectionLogArchivePageQuery, {
    variables: { REFID },
    skip: !REFID,
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (!data?.inspectionLog) {
        // Redirect to 404 if API doesn't respond with inspection log data
        router.replace('/404');
      }
    },
    onError: (error) => {
      if (error.message == 'INSPECTION_LOG_REFID_INVALID_OR_EMPTY') {
        router.replace('/404');
      }
      // Bypass 404 when API gives USER_ID_INVALID_OR_MISSING error to account for camera logs
      if (error.message !== 'USER_ID_INVALID_OR_MISSING') {
        // Redirect to 404 when API gives any other error
        if (!user) {
          dispatch(showAuthModal(true));
        }
      }
    },
  });
  const inspectionLog = getInspectionLogAPIResult.data?.inspectionLog;
  const inspectionLogSourceType = inspectionLog?.sourceType;

  // Log data

  const isOKQuality = false;

  // Misc

  const [, , user] = useAuthentication(() => false);
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const authorisationLevel = useAuthorisationLevel(inspectionLog);
  const hasManagerAuthorisation = isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER);
  const permissionToViewNotes = usePermission(PERMISSIONS.PUBLISHED_LOG_NOTES, user, activeOrganisationId);
  const hasPermissionToPostComments = usePermission(
    OBJECT_PERMISSIONS.POST_COMMENTS,
    user,
    activeOrganisationId,
    inspectionLog
  );
  const permissionToEditLog = usePermission(OBJECT_PERMISSIONS.EDIT_LOG, user, activeOrganisationId, inspectionLog);

  // State

  const [isHighlightsLog, setIsHighlightsLog] = useState(false);
  const [pageError, _setPageError] = useState(null);
  const [pageMessage, _setPageMessage] = useState(null);
  const [renderSharePopup, setRenderSharePopup] = useState(false);
  const [shareToastMessage, setShareToastMessage] = useState(null);
  const [showPageError, setShowPageError] = useState(false);
  const [showPageMessage, setShowPageMessage] = useState(false);
  const [highlightsLogLink, setHighlightsLogLink] = useState("");

  const setPageError = useCallback((error) => {
    _setPageError(error);

    if (error) {
      setShowPageError(true);
    }
  }, []);

  const setPageMessage = useCallback((message) => {
    _setPageMessage(message);

    if (message) {
      setShowPageMessage(true);
    }
  }, []);

  // Refs
  const breakdownSectionRef = useRef();
  const contactsSectionRef = useRef();
  const summarySectionRef = useRef();

  /* Methods */

  const closeSharePopup = useCallback(() => {
    setRenderSharePopup(false);
  }, []);

  const openSharePopup = useCallback(() => {
    setRenderSharePopup(true);
  }, []);

  const shareLog = useCallback(() => {
    const link = window.location.href;
    share(link, SHARE_TYPE.URL)
      .then((shareAction) => {
        if (shareAction === SHARE_ACTION.CLIPBOARD) {
          setShareToastMessage(t('COPIED_TO_CLIPBOARD'));
        }
      })
      .catch((e) => {
        okerror('Error sharing log.', e);
        setShareToastMessage(t('ERROR_GENERIC'));
      });
  }, [t]);

  /* Effects */

  // Hide share toast after 5 seconds
  useEffect(() => {
    if (shareToastMessage) {
      setTimeout(() => {
        setShareToastMessage(null);
      }, 5000);
    }
  }, [shareToastMessage]);

  // Hide page error after 5 seconds
  useEffect(() => {
    if (showPageError) {
      setTimeout(() => {
        setShowPageError(false);
      }, 5000);
    }
  }, [showPageError]);

  // Reset page error when hidden
  useEffect(() => {
    if (!showPageError) {
      setTimeout(() => {
        setPageError(null);
      }, baseTheme.timing.timingShortMs);
    }
  }, [setPageError, showPageError]);

  // Hide page message after 5 seconds
  useEffect(() => {
    if (showPageMessage) {
      setTimeout(() => {
        setShowPageMessage(false);
      }, 5000);
    }
  }, [showPageMessage]);

  // Reset page message when hidden
  useEffect(() => {
    if (!showPageMessage) {
      setTimeout(() => {
        setPageMessage(null);
      }, baseTheme.timing.timingShortMs);
    }
  }, [setPageMessage, showPageMessage]);

  useEffect(() => {
    const inspectionLog = getInspectionLogAPIResult.data?.inspectionLog;

    if (!inspectionLog) {
      return;
    }

    const createdDate = new Date(inspectionLog?.createdDate);
    const firstMomentOfCreatedDate = getFirstMomentOfDate(createdDate);
    const lastMomentOfCreatedDate = getLastMomentOfDate(createdDate);

    if (inspectionLog.inspectionLogTestAssetList[0].unversionedTestAsset.whatToLookForMap['EN'].text === "HIGHLIGHTS_LOG") {
      setIsHighlightsLog(true);
      setHighlightsLogLink(`/search?sortBy=RELEVANCE_DESC&enableKeywordsFilter=false&types=INSPECTION_LOG&startCreatedDate=${firstMomentOfCreatedDate}&finishCreatedDate=${lastMomentOfCreatedDate}`)
    } else {
      setIsHighlightsLog(false);
    }

  }, [getInspectionLogAPIResult]);

  /* Render */

  const disableActions = !inspectionLog;
  const renderAssemblySection = inspectionLogSourceType === 'PRODUCT' && hasManagerAuthorisation;
  const renderBreakdownSection = inspectionLogSourceType === 'PRODUCT';

  // Page sections
  const pageMenuSections = [
    { title: t('ARCHIVE_PAGE_SECTION_SUMMARY'), ref: summarySectionRef },
    { title: t('CONTACTS_SECTION'), ref: contactsSectionRef },
    { title: t('ARCHIVE_PAGE_SECTION_BREAKDOWN'), ref: breakdownSectionRef },
  ];

  let mainContent;
  if (!getInspectionLogAPIResult.called || getInspectionLogAPIResult.loading) {
    mainContent = (
      <UIContext.Provider value='card'>
        <ContentLayout className={styles.summarySection}>
          <TextLayout>
            <Icon height={40} name={ICONS.SPINNER.name} width={40} />
          </TextLayout>
        </ContentLayout>
      </UIContext.Provider>
    );
  } else if (inspectionLog) {
    const hasNotes = inspectionLog.inspectionLogNoteAssetList.length > 0;
    const hasDocuments = inspectionLog.inspectionLogDocumentAssetList.length > 0;
    const hasAlerts = inspectionLog.inspectionLogTestAssetList[0]?.inspectionLogFindingList?.length > 0;
    const sections = [];
    sections.push({
      id: 'summary',
      children: <InspectionLogSummarySection className={styles.section} inspectionLog={inspectionLog} />,
    });
    sections.push({
      id: 'contacts',
      children: (
        <InspectionLogContactsSection
          className={styles.section}
          inspectionLog={inspectionLog}
          inspectionLogContactsSectionRef={contactsSectionRef}
        />
      ),
    });
    if (permissionToViewNotes && hasNotes) {
      sections.push({
        id: 'notes',
        children: <InspectionLogNotesSection className={styles.section} inspectionLog={inspectionLog} isHighlightsLog={isHighlightsLog} highlightsLogLink={highlightsLogLink}/>,
      });
    }
    if (hasDocuments || hasAlerts) {
      sections.push({
        id: 'files',
        children: <InspectionLogFilesSection className={styles.section} inspectionLog={inspectionLog} />,
      });
    }
    if (renderAssemblySection) {
      sections.push({
        id: 'assembly',
        children: (
          <InspectionLogAssemblySection
            className={styles.section}
            inspectionLog={inspectionLog}
            setPageError={setPageError}
            setPageMessage={setPageMessage}
          />
        ),
      });
    }

    if (renderBreakdownSection) {
      sections.push({
        id: 'breakdown',
        children: (
          <InspectionLogBreakdownSection
            className={styles.section}
            breakdownSectionRef={breakdownSectionRef}
            inspectionLog={inspectionLog}
            REFID={REFID}
          />
        ),
      });
    }

    mainContent = sections.map((s, index) => {
      return (
        <UIContext.Provider key={s.id} value={index % 2 === 0 ? 'card' : 'base'}>
          {s.children}
        </UIContext.Provider>
      );
    });
  }

  const pageSubmenu = (
    <div className={styles.submenu}>
      <Text className={styles.submenuTitle}>{t('LOG')}</Text>
      <Tag className={styles.submenuRefId} size='sm'>
        {REFID}
      </Tag>
      <div className={styles.submenuButtonContainer}>
        {permissionToEditLog && (
          <LinkToInspectionLog
            className={styles.submenuButton}
            icon={ICONS.PENCIL.name}
            linkToEditPage
            inspectionLogAsset={inspectionLog}
          >
            {useDesktopLayout && t('EDIT')}
          </LinkToInspectionLog>
        )}
        <Button
          disabled={disableActions}
          icon={ICONS.SHARE.name}
          linkStyle
          onClick={hasManagerAuthorisation ? openSharePopup : shareLog}
        >
          {useDesktopLayout && t('SHARE')}
        </Button>
        <FadeInOutTransition appear in={!!shareToastMessage}>
          <Toast className={styles.shareToast}>{shareToastMessage}</Toast>
        </FadeInOutTransition>
      </div>
    </div>
  );

  const localizedNameForInspectionLog =
    inspectionLog && InspectionAssetModel.localizedNameForInspectionAsset(inspectionLog?.inspectionAsset, locale);

  const pageMenu = (
    <PageMenu
      assetName={localizedNameForInspectionLog}
      sections={pageMenuSections}
      title=' '
      showBackButton
      smartTabs
      submenu={pageSubmenu}
      submenuClassName={styles.pageSubmenu}
      verticalOffsetPx={70}
    />
  );
  return (
    <>
      <PageTitle>{REFID ? `${t('LOG')} - ${REFID}` : t('LOG')}</PageTitle>
      {showInPopup ? pageMenu : <Menu>{pageMenu}</Menu>}
      <div className={showInPopup ? styles.contentContainerPopup : styles.contentContainer}>
        <UIContext.Provider value='card'>
          <ContentLayout className={styles.headerSection} padTop={false} ref={summarySectionRef} pageContent>
            <TextLayout>
              <h2 className={styles.pageHeader}>
                {t('LOG')}{' '}
                <Tag className={styles.refId}>
                  {REFID}{' '}
                  {isOKQuality && (
                    <Tooltip className={styles.okQualityTooltip} position='below' tint='creation'>
                      Satisfies OK quality
                    </Tooltip>
                  )}
                </Tag>
              </h2>
            </TextLayout>
          </ContentLayout>
        </UIContext.Provider>
        {mainContent}
        {hasPermissionToPostComments && (
          <ContentLayout className={styles.commentSection} pageContent>
            <TextLayout>
              <h3 className={styles.commentSectionHeader}>{t('COMMENTS_SECTION_HEADER')}</h3>
              <CommentsController
                assetType='INSPECTION_LOG'
                assetId={inspectionLog?.id}
                assetOrganisationId={inspectionLog?.organisationId}
              />
            </TextLayout>
          </ContentLayout>
        )}
      </div>
      <FadeInOutTransition className={styles.toastContainer} in={showPageError}>
        <Toast tint='alert'>
          <p>{pageError}</p>
        </Toast>
      </FadeInOutTransition>
      <FadeInOutTransition className={styles.toastContainer} in={showPageMessage}>
        <Toast>
          <p>{pageMessage}</p>
        </Toast>
      </FadeInOutTransition>
      {renderSharePopup && (
        <ShareAssetPopup
          assetId={inspectionLog.id}
          asset={inspectionLog}
          assetType='INSPECTION_LOG'
          dismiss={closeSharePopup}
          sharedToList={inspectionLog.assetAccessPermissionList}
        />
      )}
    </>
  );
}

LogPage.layoutProps = {
  contentTopPadding: false,
  padFooter: false,
};

LogPage.propTypes = {
  REFIDasProp: PropTypes.string,
};

export async function getServerSideProps({ locale }) {
  const i18nProps = await serverSideTranslations(locale, ['common']);
  return { props: { ...i18nProps } };
}
