import PropTypes from 'prop-types';
import { useCallback } from 'react';

import Radio from '../radio';
import Text from '../text';

import styles from './styles.module.scss';

import useI18n from 'OK/util/hooks/useI18n';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';

// const PUBLISH_OPTIONS = {
//   PUBLIC: 'PUBLIC',
//   RESTRICTED: 'RESTRICTED',
//   INTERNAL: 'INTERNAL',
// };

export function PublishOptionCard(props) {
  const { className, assetPublishStatus, selected, publishOption, onClick, readOnly, ...otherProps } = props;

  const { t } = useI18n();

  // Methods

  const onClickHandler = useCallback(() => {
    if (!readOnly && onClick) {
      onClick();
    }
  }, [onClick, readOnly]);

  // Render
  let classNames = styles.container;
  if (className) {
    classNames += ` ${className}`;
  }
  if (!readOnly && selected) {
    classNames += ` ${styles.selected}`;
  }
  if (readOnly) {
    classNames += ` ${styles.readOnly}`;
  }

  let headerText;
  let bodyText;
  if (publishOption === PUBLISH_STATUS.PUBLISHED) {
    headerText = t('PUBLIC');
    bodyText = t('PUBLISH_OPTION_PUBLIC_DESCRIPTION');
  } else if (publishOption === PUBLISH_STATUS.RESTRICTED) {
    headerText = 'Restricted';
    bodyText = 'Accessible by access-specific links, and by invite.';
  } else if (publishOption === PUBLISH_STATUS.UNPUBLISHED) {
    headerText = t('INTERNAL');
    bodyText = t('PUBLISH_OPTION_INTERNAL_DESCRIPTION');
  }

  if (readOnly && assetPublishStatus !== publishOption) {
    return null;
  }

  return (
    <div className={classNames} onClick={onClickHandler} {...otherProps}>
      {!readOnly && <Radio className={styles.radio} inputClassName={styles.checkBox} checked={selected} />}
      <Text className={styles.text}>{headerText}</Text>
      <Text className={styles.text} size='sm'>
        {bodyText}
      </Text>
    </div>
  );
}

PublishOptionCard.propTypes = {
  className: PropTypes.string,
  assetPublishStatus: PropTypes.string,
  onClick: PropTypes.func,
  publishOption: PropTypes.string,
  readOnly: PropTypes.bool,
  selected: PropTypes.bool,
};
