import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
  ArchiveCardTitle,
} from 'OK/components/archiveCard';
import { ICONS } from 'OK/components/icon';
import Link from 'OK/components/link';
import MediaAssetModel from 'OK/models/mediaAsset';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import { formatNumber } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function ImageArchiveCard(props) {
  /* Variables */

  const { cardClassName, className, linkToArchivePage = true, mediaAsset, ...otherProps } = props;
  const { conformityPoint, imageData, linkedProductList, REFID } = mediaAsset;
  const { imageURL, metadata } = imageData;
  const { t } = useI18n();
  const dispatch = useDispatch();

  /* Render */

  const dimensions = useMemo(() => {
    if (metadata?.height && metadata?.width) {
      return `${metadata.height}x${metadata.width} px`;
    }

    return null;
  }, [metadata.height, metadata.width]);

  const linksMessage = useMemo(() => {
    const numberOfLinks = linkedProductList.length;
    if (numberOfLinks === 0) {
      return t('ARCHIVE_CARD_0_LINKS');
    }
    if (numberOfLinks === 1) {
      return t('ARCHIVE_CARD_1_LINK');
    }
    return t('ARCHIVE_CARD_X_LINKS', { data: { number: formatNumber(numberOfLinks) } });
  }, [linkedProductList.length, t]);

  const Container = linkToArchivePage ? Link : 'div';
  const containerProps = { className };
  if (linkToArchivePage) {
    containerProps.href = MediaAssetModel.link(mediaAsset);
    containerProps.onClick = (e) => {
      e.preventDefault();
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'MEDIA_ASSET', OKID: null },
        null,
        `/archive?refId=${REFID}&dataType=MEDIA_ASSET`
      );
      dispatch(setBrowserHistoryAction(history));
    };
  }

  return (
    <Container {...containerProps}>
      <ArchiveCard className={cardClassName} {...otherProps}>
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery
            imageUrl={imageURL}
            placeholderIconName={ICONS.IMAGE.name}
            refId={REFID}
            showOverlay
            showPlaceholderOverMedia
          />
          <ArchiveCardMainContent>
            <ArchiveCardHeader type={t('IMAGE')} />
            <ArchiveCardTitle>{dimensions}</ArchiveCardTitle>
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow conformityGrade={conformityPoint}>{linksMessage}</ArchiveCardFooterRow>
          <ArchiveCardFooterRow />
        </ArchiveCardFooter>
      </ArchiveCard>
    </Container>
  );
}

ImageArchiveCard.propTypes = {
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  linkToArchivePage: PropTypes.bool,
  mediaAsset: PropTypes.any,
};
