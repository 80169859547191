// Based on this GitHub comment: https://github.com/apollographql/apollo-client/issues/4150#issuecomment-500127694

import { useApolloClient } from '@apollo/client';
import { useCallback, useRef } from 'react';

/** Call an Apollo query with the option to cancel. */
export default function useCancellableApolloQuery(props = {}) {
  const { autoCancelOnNewRequest = true } = props;
  const apolloClient = useApolloClient();
  const observableQueryRef = useRef();

  /** Cancel the ongoing query. */
  const cancel = useCallback(() => {
    if (!observableQueryRef.current) {
      return;
    }

    observableQueryRef.current.unsubscribe();
    observableQueryRef.current = null;
  }, []);

  /** Make a new request. */
  const query = useCallback(
    (options = {}) => {
      if (autoCancelOnNewRequest) {
        cancel();
      }
      return new Promise((resolve, reject) => {
        const query = apolloClient.watchQuery({
          ...options,
        });
        const complete = () => {
          cancel();
        };
        observableQueryRef.current = query.subscribe({
          next: (result) => {
            resolve(result);
            complete();
          },
          error: (e) => {
            reject(e);
            complete();
          },
        });
      });
    },
    [apolloClient, autoCancelOnNewRequest, cancel]
  );

  return [query, cancel];
}
