import Router from 'next/router';

import { decodeQueryParametersToObject, encodeQueryParametersFromObject } from 'OK/util/functions/url';

/** Add a query parameter to the current URL. */
export function addURLQueryParam(paramName, value, routerOptions) {
  const currentQuery = decodeQueryParametersToObject(currentURLQueryString());
  currentQuery[paramName] = value;
  const newQueryString = encodeQueryParametersFromObject(currentQuery);
  const newURL = `${Router.pathname}?${newQueryString}`;
  Router.push(newURL, undefined, routerOptions);
}

/** Get the current URL query. */
export function currentURLQueryString() {
  const queryString = Router.asPath.replace(Router.pathname, '').replace('?', '');
  return queryString;
}

/** Remove a query parameter from the current URL. */
export function removeURLQueryParam(paramName, routerOptions) {
  const currentQuery = decodeQueryParametersToObject(currentURLQueryString());
  delete currentQuery[paramName];
  const newQueryString = encodeQueryParametersFromObject(currentQuery);
  let newURL = Router.pathname;
  if (newQueryString) {
    newURL += `?${newQueryString}`;
  }
  Router.push(newURL, undefined, routerOptions);
}

/*
  (Deprecated) Processes a relative url string and prepends the language code.

  This simply returns the route now, as the language code is handled by Next.js.
*/
function r(route) {
  // Get the current language.
  let processedRoute;

  if (route.length > 0) {
    // Ensure there is no leading slash in the passed route. This function will add the slash.
    let routeWithoutLeadingSlash = route;
    if (route.indexOf('/') === 0) {
      routeWithoutLeadingSlash = route.slice(1, route.length);
    }

    // Make sure the passed route wasn't simply "/"
    if (routeWithoutLeadingSlash.length > 0) {
      processedRoute = `/${routeWithoutLeadingSlash}`;
    }
  }

  if (!processedRoute) {
    // Empty route simply has the language in the url.
    processedRoute = '/';
  }

  return processedRoute;
}

/*
  (Deprecated) Shorthand function to call r() with dynamic = true.
  
  "dynamic" is no longer a parameter for r, so this is simply an alias for r.
 */
function rd(route) {
  return r(route);
}

function redirect(res, path) {
  res.writeHead(301, {
    Location: path,
  });
  res.end();
}

function redirectToHome(res) {
  redirect(res, r('/'));
}

function redirectToLogin(res) {
  redirect(res, r('/auth'));
}

export { r, rd, redirect, redirectToHome, redirectToLogin };
