// ==============================================================================
// Cookie Classification:
// 1) Duration - Session / Persistent
// 2) Provenance - First-Party / Third-Party
// 3) Purpose - Necessary / Preference / Statistics / Marketing
// ------------------------------------------------------------------------------
// @Source: https://gdpr.eu/cookies/
// @Date (YYYY-MM-DD): 2021-05-04
// ==============================================================================

import Cookies from 'js-cookie';

/* Constants */

const DURATION_PERSISTENT = 'persistent';
// eslint-disable-next-line no-unused-vars
const DURATION_SESSION = 'session';
const PROVENANCE_FIRSTPARTY = 'first-party';
// eslint-disable-next-line no-unused-vars
const PROVENANCE_THIRDPARTY = 'third-party';
const PURPOSE_NECESSARY = 'necessary';
// eslint-disable-next-line no-unused-vars
const PURPOSE_STATISTICS = 'statistics';

/* Cookie classifications */

const cookieClassificationList = {
  CONSENTED_COOKIES: {
    duration: DURATION_PERSISTENT,
    provenance: PROVENANCE_FIRSTPARTY,
    purpose: PURPOSE_NECESSARY,
  },
  REFRESH_TOKEN: {
    duration: DURATION_PERSISTENT,
    provenance: PROVENANCE_FIRSTPARTY,
    purpose: PURPOSE_NECESSARY,
  },
  NEXT_LOCALE: {
    duration: DURATION_PERSISTENT,
    provenance: PROVENANCE_FIRSTPARTY,
    purpose: PURPOSE_NECESSARY,
  },
};

/* Functions */

function checkForCookieConsent(cname) {
  const cookieClassification = cookieClassificationList[cname];
  if (cookieClassification === undefined) {
    return false;
  }
  if (cookieClassification.purpose === PURPOSE_NECESSARY) {
    return true;
  }
  // TODO: Add cookie consent logic or remove this
  const allowedCookiePurposeList = Cookies.get('CONSENTED_COOKIES');
  if (allowedCookiePurposeList && JSON.parse(allowedCookiePurposeList).includes(cookieClassification.purpose)) {
    return true;
  }
  return false;
}

export function setCookie(cname, cvalue) {
  if (!checkForCookieConsent(cname)) {
    return undefined;
  }

  const cookieOptions = {};
  const cookieClassification = cookieClassificationList[cname];

  if (cookieClassification.duration === DURATION_PERSISTENT) {
    // Set persistent cookies to expire in one year
    cookieOptions.expires = 365;
  }

  return Cookies.set(cname, cvalue, cookieOptions);
}

export function getCookie(cname) {
  if (!checkForCookieConsent(cname)) {
    return undefined;
  }
  return Cookies.get(cname);
}

export function removeCookie(cname) {
  return Cookies.remove(cname);
}
