import Head from 'next/head';
import NextRouter from 'next/router';
import PropTypes from 'prop-types';
import { Trans } from 'next-i18next';

import languages from 'OK/assets/languages.json';

const translableLanguages = languages.filter((l) => l.translatable);

function isLanguage(languageIso) {
  const iso = languageIso?.toUpperCase();
  return languages.findIndex((l) => l.iso === iso) !== -1;
}

function isTranslatableLanguage(language) {
  return translableLanguages.findIndex((l) => l.iso === language) !== -1;
}

export function formatLanguageIsoAsLocale(languageIso) {
  const [languageCode, regionCode] = languageIso.replace('_', '-').split('-');
  if (regionCode) {
    return `${languageCode.toLowerCase()}-${regionCode.toUpperCase()}`;
  }
  return languageCode.toLowerCase();
}

export function formatLocaleAsLanguageIso(locale) {
  return locale.replace('-', '_').toUpperCase();
}

/** i18n React component */
export function I18n(props) {
  return <Trans {...props} />;
}

/**
 * Prefix an `<img>` `src` with the i18n image path.
 *
 * @param {string} src
 *
 * @returns {string}
 */
export function i18nImageSrc(src, locale) {
  const filenameWithoutBasePath = src.replace(/^(\/img)?\//, '');
  // Temporary fix for English image path
  const i18nPath = `i18n/${locale}`;
  return `/img/${i18nPath}/${filenameWithoutBasePath}`;
}

/* Render i18n-specific styles */
export function I18nStyles(props) {
  const { locale } = props;
  let fontFaces = '';
  let fontPreloads = null;
  switch (locale) {
    case 'zh-CN':
      fontFaces = `
        @font-face {
          font-family: 'Noto Sans';
          font-style: normal;
          font-weight: normal;
          font-display: swap;
          src: local('Noto Sans'), url('/fonts/noto-sans/zh-CN/NotoSansSC-Regular.otf') format('opentype');
        }
        @font-face {
          font-family: 'Noto Sans';
          font-style: normal;
          font-weight: bold;
          font-display: swap;
          src: local('Noto Sans'), url('/fonts/noto-sans/zh-CN/NotoSansSC-Bold.otf') format('opentype');
        }
        @font-face {
          font-family: 'Noto Sans Mono';
          font-style: normal;
          font-weight: normal;
          font-display: swap;
          src: local('Noto Sans Mono'), url('/fonts/noto-sans-mono/zh-CN/NotoSansMonoCJKsc-Regular.otf') format('opentype');
        }
        @font-face {
          font-family: 'Noto Sans Mono';
          font-style: normal;
          font-weight: bold;
          font-display: swap;
          src: local('Noto Sans Mono'), url('/fonts/noto-sans-mono/zh-CN/NotoSansMonoCJKsc-Bold.otf') format('opentype');
        }
      `;
      fontPreloads = (
        <>
          <link
            rel='preload'
            href='/fonts/noto-sans/zh-CN/NotoSansSC-Regular.otf'
            as='font'
            type='font/otf'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            href='/fonts/noto-sans/zh-CN/NotoSansSC-Bold.otf'
            as='font'
            type='font/otf'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            href='/fonts/noto-sans-mono/notosansmono-regular-webfont.woff2'
            as='font'
            type='font/woff2'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            href='/fonts/noto-sans-mono/notosansmono-bold-webfont.woff2'
            as='font'
            type='font/woff2'
            crossOrigin='anonymous'
          />
        </>
      );
      break;
    case 'zh-HK':
      fontFaces = `
        @font-face {
          font-family: 'Noto Sans';
          font-style: normal;
          font-weight: normal;
          src: local(''), url('/fonts/noto-sans/zh-HK/NotoSansHK-Regular.otf') format('opentype');
        }
        @font-face {
          font-family: 'Noto Sans';
          font-style: normal;
          font-weight: bold;
          src: local(''), url('/fonts/noto-sans/zh-HK/NotoSansHK-Bold.otf') format('opentype');
        }
        @font-face {
          font-family: 'Noto Sans Mono';
          font-style: normal;
          font-weight: normal;
          src: url('/fonts/noto-sans-mono/zh-HK/NotoSansMonoCJKhk-Regular.otf') format('opentype');
        }
        @font-face {
          font-family: 'Noto Sans Mono';
          font-style: normal;
          font-weight: bold;
          src: url('/fonts/noto-sans-mono/zh-HK/NotoSansMonoCJKhk-Bold.otf') format('opentype');
        }
      `;
      fontPreloads = (
        <>
          <link
            rel='preload'
            href='/fonts/noto-sans/zh-HK/NotoSansHK-Regular.otf'
            as='font'
            type='font/otf'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            href='/fonts/noto-sans/zh-HK/NotoSansHK-Bold.otf'
            as='font'
            type='font/otf'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            href='/fonts/noto-sans-mono/notosansmono-regular-webfont.woff2'
            as='font'
            type='font/woff2'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            href='/fonts/noto-sans-mono/notosansmono-bold-webfont.woff2'
            as='font'
            type='font/woff2'
            crossOrigin='anonymous'
          />
        </>
      );
      break;
    default:
      fontFaces = `
        @font-face {
          font-family: 'Noto Sans';
          font-style: normal;
          font-weight: normal;
          font-display: swap;
          src: local('Noto Sans'), url('/fonts/noto-sans/noto-sans-v11-latin-regular.woff2') format('woff2'),
            url('/fonts/noto-sans/noto-sans-v11-latin-regular.woff') format('woff');
        }
        @font-face {
          font-family: 'Noto Sans';
          font-style: normal;
          font-weight: bold;
          font-display: swap;
          src: local('Noto Sans'), url('/fonts/noto-sans/noto-sans-v11-latin-700.woff2') format('woff2'),
            url('/fonts/noto-sans/noto-sans-v11-latin-700.woff') format('woff');
        }
        @font-face {
          font-family: 'Noto Sans';
          font-style: italic;
          font-weight: normal;
          font-display: swap;
          src: local('Noto Sans'), url('/fonts/noto-sans/noto-sans-v11-latin-italic.woff2') format('woff2'),
            url('/fonts/noto-sans/noto-sans-v11-latin-italic.woff') format('woff');
        }
        @font-face {
          font-family: 'Noto Sans';
          font-style: italic;
          font-weight: bold;
          font-display: swap;
          src: local('Noto Sans'), url('/fonts/noto-sans/noto-sans-v11-latin-700italic.woff2') format('woff2'),
            url('/fonts/noto-sans/noto-sans-v11-latin-700italic.woff') format('woff');
        }
        @font-face {
          font-family: 'Noto Sans Mono';
          font-style: normal;
          font-weight: normal;
          font-display: swap;
          src: local('Noto Sans Mono'), url('/fonts/noto-sans-mono/notosansmono-regular-webfont.woff2') format('woff2'),
            url('/fonts/noto-sans-mono/notosansmono-regular-webfont.woff') format('woff');
        }
        @font-face {
          font-family: 'Noto Sans Mono';
          font-style: normal;
          font-weight: bold;
          font-display: swap;
          src: local('Noto Sans Mono'), url('/fonts/noto-sans-mono/notosansmono-bold-webfont.woff2') format('woff2'),
            url('/fonts/noto-sans-mono/notosansmono-bold-webfont.woff') format('woff');
        }
      `;
      fontPreloads = (
        <>
          <link
            rel='preload'
            href='/fonts/noto-sans/noto-sans-v11-latin-regular.woff2'
            as='font'
            type='font/woff2'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            href='/fonts/noto-sans/noto-sans-v11-latin-700.woff2'
            as='font'
            type='font/woff2'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            href='/fonts/noto-sans/noto-sans-v11-latin-italic.woff2'
            as='font'
            type='font/woff2'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            href='/fonts/noto-sans/noto-sans-v11-latin-700italic.woff2'
            as='font'
            type='font/woff2'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            href='/fonts/noto-sans-mono/notosansmono-regular-webfont.woff2'
            as='font'
            type='font/woff2'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            href='/fonts/noto-sans-mono/notosansmono-bold-webfont.woff2'
            as='font'
            type='font/woff2'
            crossOrigin='anonymous'
          />
        </>
      );
      break;
  }
  return (
    <Head>
      {fontPreloads}
      <style>{fontFaces}</style>
    </Head>
  );
}
I18nStyles.propTypes = {
  locale: PropTypes.string.isRequired,
};

export function languageByIso(iso, useIsoAsPrefix = false) {
  let _iso = iso.toUpperCase();
  if (_iso === 'ZH_HANS') {
    _iso = 'ZH_CN';
  } else if (_iso === 'ZH_HANT') {
    _iso = 'ZH_HK';
  } else if (_iso === 'ZH-CN') {
    _iso = 'ZH_CN';
  } else if (_iso === 'ZH-HK') {
    _iso = 'ZH_HK';
  }
  return languages.find((l) => {
    if (!useIsoAsPrefix) {
      return l.iso === _iso;
    } else {
      return l.iso.startsWith(_iso);
    }
  });
}

export function languageForLocale(locale) {
  // First, see if the locale itself is a language code.
  if (isLanguage(locale)) {
    return languageByIso(locale);
  }

  // Second, see if the first part of the locale string is the language code.
  // This should usually be the case, but in some cases (like Chinese) it will not match.
  const localeComponents = locale.split('-');
  const langKey = localeComponents[0];
  if (isLanguage(langKey)) {
    return languageByIso(langKey);
  }

  // Find the first language that has a prefix matching the language key.
  return languageByIso(langKey, true);
}

export function setLocale(languageIso, i18n) {
  let validatedLanguage;
  if (typeof languageIso === 'string') {
    const formattedLanguage = languageIso.toUpperCase();
    if (!isTranslatableLanguage(formattedLanguage)) {
      // Try to map the passed language to a valid app language with English as the fallback
      const languageFromLocale = languageForLocale(formattedLanguage);
      if (languageFromLocale) {
        validatedLanguage = languageFromLocale.iso;
      }
    } else {
      // The language is a valid app langauge
      validatedLanguage = formattedLanguage;
    }
  }

  if (!validatedLanguage) {
    validatedLanguage = 'EN';
  }

  okdebug(`setLocale ${languageIso}`);
  const routerLocale = formatLanguageIsoAsLocale(languageIso);
  if (i18n.language !== routerLocale) {
    okdebug(`changing i18n locale from ${i18n.language} to ${routerLocale}`);
    i18n.changeLanguage(routerLocale);
  }

  if (typeof window !== 'undefined') {
    // Update url
    if (routerLocale !== NextRouter.locale) {
      okdebug(`changing Next locale from ${NextRouter.locale} to ${routerLocale}`);
      NextRouter.replace(NextRouter.pathname, NextRouter.asPath, { locale: routerLocale });
    }
  }
}

export { languages };
export { translableLanguages };
