import DateFnsFormatDate from 'date-fns/format';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { enUS, zhCN, zhHK } from 'date-fns/locale';
import round from 'lodash/round';

import roundPercentages from './functions/roundPercentages';

import { ICONS } from 'OK/components/icon';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import LabelTemplateModel, { LabelTemplateName } from 'OK/models/labelTemplate';
import useI18n from './hooks/useI18n';

function getDateFnsLocale(locale) {
  switch (locale) {
    case 'zh-CN':
      return zhCN;
    case 'zh-HK':
      return zhHK;
    default:
      return enUS;
  }
}

function getDateFormatForLocale(locale) {
  switch (locale) {
    case 'zh-CN':
      return 'yyyy年MMMdo';
    case 'zh-HK':
      return 'yyyy年M月d日';
    default:
      return 'dd MMM yyyy';
  }
}

export function formatDate(date, locale, options = {}) {
  const { style = 'long' } = options;

  let format;
  let transformToUpperCase = true;
  switch (style) {
    case 'long':
      format = `${getDateFormatForLocale(locale)} kk:mm O`;
      break;
    case 'inputValue':
      format = 'yyyy-LL-dd';
      transformToUpperCase = false;
      break;
    case 'dateTimeInputValue':
      // eslint-disable-next-line quotes
      format = "yyyy-LL-dd'T'HH:mm";
      transformToUpperCase = false;
      break;
    default:
      format = `${getDateFormatForLocale(locale)}`;
      break;
  }

  const dateFnsLocale = getDateFnsLocale(locale);
  let formattedDate = DateFnsFormatDate(date, format, { locale: dateFnsLocale });

  if (transformToUpperCase) {
    formattedDate = formattedDate.toUpperCase();
  }

  return formattedDate;
}

export function formatDateRelative(dateToFormat, dateToCompare, locale, options = {}) {
  const dateFnsLocale = getDateFnsLocale(locale);

  if (dateToCompare) {
    return formatDistanceStrict(dateToFormat, dateToCompare, { locale: dateFnsLocale, ...options });
  }

  return formatDistanceToNowStrict(dateToFormat, { locale: dateFnsLocale, ...options });
}

/** Precision option enum for `formatDuration`. */
export const DURATION_PRECISION = {
  HOURS: 'HOURS',
  MINUTES: 'MINUTES',
  SECONDS: 'SECONDS',
};

/** Style option enum for `formatDuration`. */
export const DURATION_STYLE = {
  SHORT: 'SHORT',
  MEDIUM: 'MEDIUM',
};

/**
 * Format a duration into a time string.
 *
 * @param {number} duration The duration in seconds.
 * @param {object} options Formatting options.
 * @param {"HOURS"|"MINUTES"|"SECONDS"} [options.precision="SECONDS"] Desired precision for formatting. Currently only applicable to `MEDIUM` formatting style.
 * @param {"SHORT"|"MEDIUM"} [options.style="SHORT"] Desired formatting style.
 *
 * Ex. SHORT: 1:30:29
 *
 * Ex. MEDIUM: 1 h 30 m 29 s
 *
 * @returns {string} The formatted duration.
 */
export function formatDuration(duration, t, options = {}) {
  const { precision = DURATION_PRECISION.SECONDS, style = DURATION_STYLE.SHORT } = options;

  function formatDurationComponent(time) {
    // Ensure single-digit time components show with a leading zero
    return time.toLocaleString('en-UK', { minimumIntegerDigits: 2 });
  }

  let runningDuration = duration;
  let formattedString = '';

  // Calculate hours
  const hours = runningDuration >= 3600 ? Math.floor(runningDuration / 3600) : 0;
  runningDuration -= hours * 3600;

  // Calculate minutes
  const minutes = runningDuration >= 60 ? Math.floor(runningDuration / 60) : 0;
  runningDuration -= minutes * 60;

  // Calculate seconds
  const seconds = round(runningDuration);

  // String format settings
  const shortStyle = style === DURATION_STYLE.SHORT;
  const separator = shortStyle ? ':' : ' ';

  // Add hours to string
  if (hours > 0) {
    formattedString = shortStyle ? `${hours}` : t('DURATION_COMPONENT_SHORT_HOUR', { data: { number: hours } });
  }

  // Stop if desired precision is hours
  if (style === DURATION_STYLE.MEDIUM && precision === DURATION_PRECISION.HOURS) {
    return formattedString;
  }

  // Add separator if necessary
  if (formattedString.length) {
    formattedString += separator;
  }

  // Add minutes to string
  const minutesNumber = `${formattedString.length ? formatDurationComponent(minutes) : minutes}`;
  formattedString += shortStyle
    ? minutesNumber
    : t('DURATION_COMPONENT_SHORT_MINUTE', { data: { number: minutesNumber } });

  // Stop if desired precision is minutes
  if (style === DURATION_STYLE.MEDIUM && precision === DURATION_PRECISION.MINUTES) {
    return formattedString;
  }

  // Add separator
  formattedString += separator;

  // Add seconds to string
  formattedString += shortStyle
    ? `${formatDurationComponent(seconds)}`
    : t('DURATION_COMPONENT_SHORT_SECOND', { data: { number: formatDurationComponent(seconds) } });

  return formattedString;
}

export function formatFilesize(filesize) {
  if (typeof filesize !== 'number') {
    return '0 B';
  }

  let runningFilesize;
  let filesizeUnit;
  if (filesize > 1000000000) {
    runningFilesize = round(filesize / 1000000000, 0);
    filesizeUnit = 'GB';
  } else if (filesize > 1000000) {
    runningFilesize = round(filesize / 1000000, 0);
    filesizeUnit = 'MB';
  } else if (filesize > 1000) {
    runningFilesize = round(filesize / 1000, 0);
    filesizeUnit = 'KB';
  } else {
    runningFilesize = filesize;
    filesizeUnit = 'B';
  }

  return `${runningFilesize} ${filesizeUnit}`;
}

/**
 * Formats a number as a string with commas every 3 digits.
 *
 * @param {number} number The number to format.
 *
 * @returns {string} The number with commas inserted every 3 digits.
 */
export function formatNumber(number, decimals = 0) {
  return number.toLocaleString('en-UK', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
}

/**
 * Format an OKID with dashes every 4 characters
 *
 * Example: 1A2B3C4D5E6F -> 1A2B-3C4D-5E6F
 *
 * @param {string} okid The OKID to format.
 *
 * @returns {string} A formatted OKID.
 */
export function formatOkid(okid) {
  let unformattedOkid = okid;
  if (unformattedOkid.match(/-/g)) {
    unformattedOkid = unformatOkid(unformattedOkid);
  }

  let formattedOkid = '';
  // Every 4 characters add a dash '-' unless it is the last set of 4 characters
  for (let x = 0; x < unformattedOkid.length; x++) {
    const indexPlus1 = x + 1;
    formattedOkid = `${formattedOkid}${unformattedOkid[x]}${
      indexPlus1 % 4 === 0 && indexPlus1 < unformattedOkid.length ? '-' : ''
    }`;
  }

  return formattedOkid;
}

/**
 * Format a percentage value as a string.
 *
 * @param {number} percentage The decimal value to format.
 * @param {object} [options] Formatting options.
 * @param {number} [options.decimals=1] The number of decimals to display in the percentage.
 * @param {boolean} [options.multiplyBy100=true] Multiple the percentage by 100.
 * @param {boolean} [options.showPercentSign=true] Include the percent sign (%) in the string.
 *
 * @returns {string}
 */
export function formatPercentage(percentage, options = {}) {
  const { decimals = 1, multiplyBy100 = true, showPercentSign = true } = options;
  let number = multiplyBy100 ? percentage * 100 : percentage;
  return `${number.toFixed(decimals)}${showPercentSign ? '%' : ''}`;
}

export function formatPoints(points) {
  if (points < 1000) {
    // Under 1,000
    return points.toFixed(0);
  }
  if (points < 10000) {
    // Under 10,000
    const str = (points / 1000).toFixed(1);
    if (str.charAt(str.length - 1) === 0) {
      // Don't show trailing 0
      return `${str.slice(0, 1)}k`;
    }

    return `${str}k`;
  }
  if (points < 1000000) {
    // Under 1,000,000
    return `${(points / 1000).toFixed(0)}k`;
  }
  if (points < 1000000000) {
    // Under 1,000,000,000
    return `${(points / 1000000).toFixed(0)}m`;
  }

  return '1b+';
}

/**
 * Format a URL.
 *
 * @param {string} url The url to format.
 * @param {object} [options] Formatting options.
 * @param {string} [options.protocol='https'] The protocol to use when formatting.
 * @param {boolean} [options.showProtocol=false] Whether to format the URL with the protocol.
 *
 * @returns {string} The formatted URL.
 */
export function formatUrl(url, options = {}) {
  const { protocol: _protocol = 'https', showProtocol = false } = options;
  const urlComponents = url.split('://');
  let address, protocol;
  if (urlComponents.length === 2) {
    // Url includes protocol
    address = urlComponents[1];
    protocol = urlComponents[0];
  } else {
    // Url doesn't include protocol
    address = urlComponents[0];
    protocol = _protocol;
  }

  if (showProtocol) {
    return `${protocol}://${address}`;
  }

  return address;
}

/**
 * Format an array of percentages.
 *
 * This runs `roundPercentages` on the percentages and then `formatPercentage` on each rounded percentage. However,
 * if any percentages erroneously get rounded to 100% while there are other non-zero percentages, those values will
 * be formatted as '99.9%' and '<0.1%' respectively.
 *
 * @param  {number[]} percentages Percentages to format.
 * @returns {number[]} The formatted percentages.
 */
export function roundAndFormatPercentages(...percentages) {
  const roundedPercentages = roundPercentages(...percentages);
  const has100Value = roundedPercentages.findIndex((p) => p === 100) > -1;
  const hasMinisculeValue = percentages.find((p) => p > 0 && p < 0.1);

  if (has100Value && hasMinisculeValue) {
    return roundedPercentages.map((p, index) => {
      if (p === 100) {
        return '99.9%';
      } else if (p === 0 && percentages[index] > 0) {
        return '<0.1%';
      }

      return formatPercentage(p, { multiplyBy100: false });
    });
  }

  return roundedPercentages.map((p) => formatPercentage(p, { multiplyBy100: false }));
}

/**
 * Remove dashes from an OKID
 *
 * Example: 1A2B-3C4D-5E6F -> 1A2B3C4D5E6F
 *
 * @param {string} okid The OKID to remove dashes from.
 *
 * @returns {string} An OKID without dashes.
 */
export function unformatOkid(okid) {
  return okid.replace(/-/g, '');
}

export function getArchiveAssetAttributeMappings(asset, assetType, locale) {
  let inspectionAssetName;
  let productName;
  let alertName;

  const { t } = useI18n();

  switch (assetType) {
    case 'product':
      if (typeof asset.name === 'object' && asset.name) {
        if (Object.keys(asset.name).length === 0) {
          productName = t('PRODUCT_ARCHIVE_CARD_UNNAMED');
        } else {
          productName = asset.name?.EN?.text;
        }
      } else {
        productName = t('PRODUCT_ARCHIVE_CARD_UNNAMED');
      }

      return {
        REFID: asset.REFID,
        SKUID: asset.SKUID,
        conformityPoint: asset.conformityPoint,
        icon: ICONS.PRODUCT.name,
        id: asset.id,
        name: productName,
        organisation: asset.organisation,
        productChildProductList: asset.productChildProductList,
        mediaAssetList: asset.productMediaAssetList?.map((pm) => pm.mediaAsset),
        reliabilityPointForPublishedLogs:
          asset.reliabilityPointForPublishedLogs === 0 ? 5 : asset.reliabilityPointForPublishedLogs,
      };
    case 'item':
      return {
        REFID: asset.OKID,
        SKUID: '',
        icon: ICONS.TAG.name,
        name:
          asset?.product?.name === undefined
            ? null
            : Object.keys(asset.product?.name).length === 0
              ? t('PRODUCT_ARCHIVE_CARD_UNNAMED') 
              : asset.product?.name?.EN?.text,
        organisation: asset.product?.organisation,
        conformityPoint: asset.product?.conformityPoint,
        mediaAssetList: asset.product?.productMediaAssetList?.map((pm) => pm.mediaAsset),
        reliabilityPointForPublishedLogs: asset.product?.reliabilityPointForPublishedLogs,
      };
    case 'site':
      return {
        REFID: asset.OKID,
        SKUID: asset.identifier,
        icon: ICONS.PRODUCT.name,
        name:
          asset?.name == undefined ? null : Object.keys(asset?.name).length === 0 ? t('PRODUCT_ARCHIVE_CARD_UNNAMED') : asset?.name?.EN?.text,
        organisation: asset?.organisation,
        conformityPoint: asset?.conformityPoint,
        mediaAssetList: asset.siteMediaAssetList?.map((pm) => pm.mediaAsset),
        reliabilityPointForPublishedLogs: asset.product?.reliabilityPointForPublishedLogs,
      };
    case 'order':
      return {
        REFID: asset.REFID,
        SKUID: asset.orderIdentifierList[0]?.orderIdentifier,
        icon: ICONS.CART.name,
        name:
          asset?.name === undefined ? null : Object.keys(asset?.name).length === 0 ? 'Unnamed' : asset?.name?.EN?.text,
        organisation: asset?.organisation,
      };
    case 'media': {
      const mediaMetadata =
        asset?.mediaType === 'IMAGE'
          ? asset?.imageData
          : asset?.mediaType === 'VIDEO'
            ? asset?.videoData
            : asset?.mediaType === 'AUDIO'
              ? asset?.audioData
              : {};

      return {
        REFID: asset.REFID,
        name:
          asset?.mediaType === 'IMAGE'
            ? mediaMetadata.imageName
            : asset?.mediaType === 'VIDEO'
              ? mediaMetadata.videoName
              : asset?.mediaType === 'AUDIO'
                ? mediaMetadata.audioName
                : null,
        conformityPoint: asset?.conformityPoint,
        icon:
          asset?.mediaType === 'IMAGE'
            ? ICONS.IMAGE.name
            : asset?.mediaType === 'VIDEO'
              ? ICONS.PLAY.name
              : asset?.mediaType === 'AUDIO'
                ? ICONS.AUDIO.name
                : null,
        mediaMetadata,
        mediaType: asset?.mediaType,
        organisation: asset?.organisation,
      };
    }
    case 'file':
      return {
        REFID: asset.REFID,
        icon: ICONS.DOCUMENT.name,
        name: asset?.documentName,
        organisation: asset?.organisation,
        conformityPoint: asset?.conformityPoint,
      };
    case 'note':
      return {
        REFID: asset.REFID,
        icon: ICONS.NOTES.name,
        name: asset?.textContentMap?.EN?.text,
        createdByUser: asset.createdByUser,
      };
    case 'label':
      return {
        REFID: asset.REFID,
        name: <LabelTemplateName labelTemplate={asset.labelTemplate} />,
        icon: ICONS.TAG.name,
        image: LabelTemplateModel.previewImageUrl(asset.labelTemplate),
        organisation: asset.product.organisation,
        createdDate: asset.createdDate,
      };
    case 'step':
      return {
        REFID: asset.REFID,
        createdByUser: asset.createdByUser,
        icon: ICONS.TEST.name,
        reliabilityPointForPublishedLogs: asset.product?.reliabilityPointForPublishedLogs,
        testSeverityLevel: asset.testSeverityLevel,
      };
    case 'workflow':
      return {
        REFID: asset.REFID,
        createdByUser: asset.createdByUser,
        icon: ICONS.INSPECTION.name,
        inspectionTestAssetList: asset.inspectionTestAssetList,
        name: asset?.name === null ? t('PRODUCT_ARCHIVE_CARD_UNNAMED') : asset.name?.EN?.text,
      };
    case 'log':
      inspectionAssetName = asset?.inspectionLogTestAssetList?.length > 0 && asset?.inspectionLogTestAssetList[0]?.unversionedTestAsset?.whatToLookForMap !== undefined ? asset.inspectionLogTestAssetList[0].unversionedTestAsset.whatToLookForMap?.EN?.text  : InspectionAssetModel.localizedNameForInspectionAsset(asset.inspectionAsset, locale);

      return {
        REFID: asset.REFID,
        createdByUser: asset.createdByUser,
        failed: asset.failed,
        icon: ICONS.INSPECTION_LOG.name,
        inspectedSize: asset.inspectedSize,
        inspector: asset.inspector,
        lotSize: asset.lotSize,
        name: inspectionAssetName,
      };
    case 'alert':
      alertName = asset?.imageData ? asset.imageData.imageName : asset?.videoData ? asset.videoData.videoName : asset.__typename === "StreamRecordingMediaFile" ? asset?.filename : null;

      return {
        name: alertName,
        image: asset?.imageData?.imageURL ?? null,
        downloadLink: asset.__typename === "StreamRecordingMediaFile" ? asset?.mediaURL : asset?.imageData?.imageURL ?? asset?.videoData?.videoURL,
      };
    default:
      return null;
  }
}

export function getArchiveViewSettingConfiguration(screenSize) {
  return {
    row: screenSize.width > 689 ? '1fr 1fr' : '1fr',
    grid: screenSize.width >= 1005 ? '1fr 1fr 1fr 1fr' : screenSize.width >= 690 ? '1fr 1fr 1fr' : '1fr 1fr',
    list: '1fr',
  };
}
