import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Tag from 'OK/components/tag';
import { countryList, marketList, regionList } from 'OK/util/geolocation';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * A component to search regions (countries). Initially shows a button, and when pressed, changes to a search input.
 *
 * @param {object} props
 * @param {string} [props.buttonTitle='Add region'] The title for the button.
 * @param {string} [props.className] The component's class.
 * @param {string[]} [props.excludedRegionISOs] Exclude regions from being searchable. Expects an array of region ISO
 * codes. To optimize performance, pass a stable reference to an array rather than an inline array.
 * @param {(iso: string) => {}} [props.onSelectedRegion] Event handler for when a region is selected.
 * @param {'country'|'market'|'region'} [props.regionType='country'] Type of regions to search.
 * @param {string} [props.searchPlaceholder='Find region'] Placeholder string for the search input.
 */
export default function RegionSearch(props) {
  /* Variables */

  const accountState = useSelector((state) => state.account);
  const { preferences } = accountState;
  const { t } = useI18n();

  const {
    buttonTitle = t('ADD_REGION'),
    className,
    defaultRenderMode = 'button',
    excludedRegionISOs = defaultExcludedRegionISOs,
    onSelectedRegion,
    regionType = 'country',
    searchPlaceholder = t('FIND_REGION'),
    selectedRegion,
  } = props;
  const searchInput = useRef();

  /* State */

  const [isFocused, setIsFocused] = useState(false);
  const [searchingRegion, setSearchingRegion] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  /* Methods */

  const resetSearchState = () => {
    setSearchingRegion(false);
    setSearchTerm('');
  };

  /* Event handlers */

  const onChoseRegion = (regionIso) => {
    if (onSelectedRegion) {
      onSelectedRegion(regionIso);
    }

    resetSearchState();
  };

  const onSearchedRegion = (e) => {
    setSearchTerm(e.target.value);
  };

  const onInputFocusIn = () => {
    setIsFocused(true);
  };

  const onInputFocusOut = () => {
    setIsFocused(false);
  };

  /* Effects */

  useEffect(() => {
    // Focus input when starting to search
    if (searchInput.current) {
      searchInput.current.focus();
    }
  }, [searchingRegion]);

  /* Render */

  let regions;
  switch (regionType) {
    case 'market':
      regions = marketList;
      break;
    case 'region':
      regions = regionList;
      break;
    default:
      regions = countryList;
      break;
  }

  // Only update search suggestions based on searchTerm and excludedRegionISOs
  const suggestions = useMemo(() => {
    // When app language is Swedish (SV), the user can search based on the following;
    // 1) 'es'        - ISO code
    // 2) 'spanska'   - Translated names based on app language
    // 3) 'espanol'   - Native name
    // 4) 'spanish'   - English name

    const region = preferences.region.toLowerCase();
    const searchRegex = new RegExp(searchTerm, 'i');

    return regions
      .filter((m) => !excludedRegionISOs.includes(m.isoAlpha3)) // Filter out any regions that should not be included
      .filter((m) => {
        return (
          searchRegex.test(m.isoAlpha3) ||
          searchRegex.test(t('GEOLOCATION_' + m.isoAlpha3.toUpperCase())) ||
          searchRegex.test(m.nativeName) ||
          searchRegex.test(m.name)
        );
      })
      .map((m) => {
        // Format regions for search suggestion
        var priority;
        if (region === m.isoAlpha3.toLowerCase()) {
          priority = 1;
        } else if (
          searchTerm.toLowerCase() === m.isoAlpha2.toLowerCase() ||
          searchTerm.toLowerCase() === m.isoAlpha3.toLowerCase()
        ) {
          priority = 2;
        } else if (searchRegex.test(t('GEOLOCATION_' + m.isoAlpha3.toUpperCase()))) {
          priority = 3;
        } else if (searchRegex.test(m.nativeName)) {
          priority = 4;
        } else if (searchRegex.test(m.name)) {
          priority = 5;
        } else {
          priority = 6;
        }
        return {
          key: m.isoAlpha3,
          title: m.name,
          priority,
          language: preferences.language, // Added to ensure that the list updates on app language changes
        };
      })
      .sort((a, b) => {
        // Matched ISO
        if (searchTerm.toLowerCase() === a.key.toLowerCase()) {
          return -1;
        }

        // Sort results by priority and matching title with searchTerm position in name
        if (a.priority > b.priority) {
          return 1;
        } else if (a.priority < b.priority) {
          return -1;
        } else {
          if (
            a.title.toLowerCase().indexOf(searchTerm.toLowerCase()) >
            b.title.toLowerCase().indexOf(searchTerm.toLowerCase())
          ) {
            return 1;
          } else if (
            a.title.toLowerCase().indexOf(searchTerm.toLowerCase()) <
            b.title.toLowerCase().indexOf(searchTerm.toLowerCase())
          ) {
            return -1;
          } else {
            if (a.title > b.title) return 1;
            else return -1;
          }
        }
      });
  }, [preferences.region, preferences.language, searchTerm, regions, excludedRegionISOs, t]);

  let component;
  if (searchingRegion) {
    component = (
      <>
        <SearchInput
          className={styles.searchInput}
          onCancel={resetSearchState}
          onChange={onSearchedRegion}
          onFocus={onInputFocusIn}
          onBlur={onInputFocusOut}
          placeholder={searchPlaceholder}
          ref={searchInput}
          showCancelOnFocus
          value={searchTerm}
        />
        {isFocused && (
          <div className={styles.searchSuggestionsContainer}>
            <SearchSuggestions
              accessoryViewRender={() => {
                return <Icon name={ICONS.PLUS.name} tint='creation' />;
              }}
              className={styles.searchSuggestions}
              highlightTerm={searchTerm}
              onSuggestionClick={onChoseRegion}
              showNoResultsMessage={!suggestions.length}
              suggestions={suggestions}
              titleRender={(suggestion) => {
                return (
                  <>
                    <Tag className={styles.isoTag} size='md'>
                      {suggestion.key}
                    </Tag>
                    <Highlighter
                      highlightClassName={styles.suggestionHighlight}
                      searchWords={[searchTerm]}
                      textToHighlight={suggestion.title}
                    />
                  </>
                );
              }}
            />
          </div>
        )}
      </>
    );
  } else {
    if (defaultRenderMode === 'select') {
      const label = selectedRegion
        ? regions.find((c) => c.isoAlpha3 === selectedRegion.toUpperCase()).name
        : t('SELECT_REGION');
      component = (
        <Button
          block
          className={styles.button}
          icon={<Icon className={styles.selectCaretIcon} name={ICONS.CARET.name} />}
          invert
          onClick={() => setSearchingRegion(true)}
        >
          {selectedRegion ? (
            <>
              <Tag className={styles.selectedLanguageTag}>{selectedRegion}</Tag>{' '}
            </>
          ) : null}
          {label}
        </Button>
      );
    } else {
      component = (
        <Button
          block
          className={styles.addButton}
          icon={<Icon name={ICONS.PLUS.name} tint='creation' />}
          invert
          onClick={() => setSearchingRegion(true)}
          tint='creation'
        >
          {buttonTitle}
        </Button>
      );
    }
  }

  return <div className={className}>{component}</div>;
}

RegionSearch.propTypes = {
  buttonTitle: PropTypes.string,
  className: PropTypes.string,
  defaultRenderMode: PropTypes.oneOf(['button', 'select']),
  excludedRegionISOs: PropTypes.arrayOf(PropTypes.string),
  onSelectedRegion: PropTypes.func,
  regionType: PropTypes.oneOf(['country', 'market', 'region']),
  searchPlaceholder: PropTypes.string,
  selectedRegion: PropTypes.string,
};

/* Helpers */

const defaultExcludedRegionISOs = [];
