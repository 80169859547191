import { ICONS } from 'OK/components/icon';
import { formatOkid } from 'OK/util/formatting';

export function suggestionObjectForUser(user, t) {
  return {
    icon: ICONS.PROFILE.name,
    key: user.id,
    title: t('COWORKER'),
    subtitle: formatOkid(user.OKID),
  };
}
