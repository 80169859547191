import { getDataURLFromFile, releaseDataURL } from './getDataURLFromFile';

export default async function getImageDimensionsFromFile(file) {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image();
      const imageDataUrl = getDataURLFromFile(file);
      image.onload = () => {
        resolve({ height: image.height, width: image.width });
        releaseDataURL(imageDataUrl);
      };
      image.src = imageDataUrl;
    } catch (e) {
      reject(e);
    }
  });
}
