import round from 'lodash/round';

/**
 * Format a duration into a timestamp (ex. 123 seconds -> 2:03)
 *
 * @param {Number} duration - Duration in seconds
 */
// TODO: Support hours
export default function getTimestamp(duration) {
  // Ensure duration is a whole number
  const roundedDuration = round(duration);

  if (roundedDuration < 0) {
    okwarn('getTimestamp called with a duration less than zero.');
    return '0:00';
  }

  // Format minutes (if applicable)
  let minutesString = '';
  let minutes = 0;
  if (roundedDuration > 59) {
    minutes = Math.floor(roundedDuration / 60);
    minutesString = `${minutes}`;
  } else {
    minutesString = '0';
  }

  // Format seconds
  // Format the remaining seconds that won't be displayed as minutes
  const seconds = roundedDuration - minutes * 60;
  let secondsString;
  if (seconds === 0) {
    secondsString = '00';
  } else if (seconds < 10) {
    secondsString = `0${seconds}`;
  } else {
    secondsString = `${seconds}`;
  }

  return `${minutesString}:${secondsString}`;
}
