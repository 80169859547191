import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import FormFieldI18n from 'OK/components/form/formFieldI18n';
import TextLayout from 'OK/components/layouts/content/text';
import Separator from 'OK/components/separator';
import Text from 'OK/components/text';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import { removeInspectionNameMutation, setInspectionNameMutation } from 'OK/networking/inspectionAssets';
import useI18n from 'OK/util/hooks/useI18n';

export default function EditWorkflowPageBasicsSection(props) {
  /* Variables */

  const { inspectionAsset } = props;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const { t } = useI18n();

  // State

  const [nameError, setNameError] = useState(null);

  const [removeInspectionNameAPI] = useMutation(removeInspectionNameMutation);
  const [setInspectionNameAPI] = useMutation(setInspectionNameMutation);
  /* Methods */

  // Event handlers

  const onBlurName = useCallback(
    (blurredLanguageIso, values) => {
      setNameError(null);
      const name = values.find((v) => v.languageIso === blurredLanguageIso)?.value;
      setInspectionNameAPI({
        variables: {
          inspectionAssetId: inspectionAsset.id,
          languageCode: blurredLanguageIso,
          languageText: name,
        },
      }).catch(() => {
        setNameError(t('ERROR_GENERIC'));
      });
    },
    [inspectionAsset, setInspectionNameAPI, t]
  );

  const onChangeName = useCallback(
    (changedLanguageIso, values) => {
      setNameError(null);
      if (values.findIndex((v) => v.languageIso === changedLanguageIso) === -1) {
        const updatedInspectionNameObject = { ...inspectionAsset.name };
        delete updatedInspectionNameObject[changedLanguageIso];
        const optimisticResponse = {
          inspectionAsset: {
            id: inspectionAsset.id,
            name: {
              ...updatedInspectionNameObject,
            },
            __typename: InspectionAssetModel.GRAPHQL_TYPE,
          },
        };
        removeInspectionNameAPI({
          variables: {
            inspectionAssetId: inspectionAsset.id,
            languageCode: changedLanguageIso,
          },
          optimisticResponse,
        }).catch(() => {
          setNameError(t('ERROR_GENERIC'));
        });
      }
    },
    [inspectionAsset, removeInspectionNameAPI, t]
  );

  /* Render */

  const inspectionAssetName = useMemo(() => {
    if (inspectionAsset?.name) {
      return Object.keys(inspectionAsset.name).map((languageIso) => {
        return { languageIso, value: inspectionAsset.name[languageIso].text };
      });
    }

    return [];
  }, [inspectionAsset?.name]);

  return (
    <div>
      <div className={styles.basicsSection}>
        {useDesktopLayout}
        <TextLayout>
          <div>
            <h3>{t('PRODUCT_FIELD_NAME')}</h3>
            <div className={styles.field}>
              <FormFieldI18n
                inputPlaceholder={t('PRODUCT_FIELD_NAME_PLACEHOLDER', { data: { language: '{{language}}' } })}
                onBlur={onBlurName}
                onChange={onChangeName}
                showWarningIfExceedsLength={35}
                values={inspectionAssetName}
              />
              {nameError && (
                <Text className={styles.nameError} size='sm' tint='notification'>
                  {nameError}
                </Text>
              )}
            </div>
          </div>
          <Separator type='section' />
        </TextLayout>
      </div>
    </div>
  );
}

EditWorkflowPageBasicsSection.propTypes = {
  inspectionAsset: PropTypes.object.isRequired,
};
