import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Footer from 'OK/components/footer';

export default function MainLayout(props) {
  const {
    children,
    contentBackgroundColor,
    contentClassName,
    contentTopPadding = true,

    padFooter = true,
    showFooter = true,
  } = props;

  let _contentClassName = styles.content;
  switch (contentTopPadding) {
    case false:
      break;
    case 'menuOnly':
      _contentClassName = `${_contentClassName} ${styles.padTopMenuOnly}`;
      break;
    default:
      _contentClassName = `${_contentClassName} ${styles.padTop}`;
  }
  if (showFooter && padFooter) {
    _contentClassName = `${_contentClassName} ${styles.padBottom}`;
  }
  if (contentClassName) {
    _contentClassName = `${_contentClassName} ${contentClassName}`;
  }

  return (
    <>
      <div className={_contentClassName}>{children}</div>
      {showFooter && <Footer />}
      {contentBackgroundColor && (
        <style jsx>{`
          .${styles.content} {
            background-color: ${contentBackgroundColor};
          }
        `}</style>
      )}
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  contentBackgroundColor: PropTypes.string,
  contentClassName: PropTypes.string,
  contentTopPadding: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disableBackgroundAtTop: PropTypes.bool,
  hideTabsOnMobile: PropTypes.bool,
  menuStyleWithoutBackground: PropTypes.string,
  padFooter: PropTypes.bool,
  showFooter: PropTypes.bool,
};
