import Head from 'next/head';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import styles from './styles.module.scss';

import Text from 'OK/components/text';

/**
 * Render brief and important messages in a popup.
 *
 * @param {object} props
 * @param {Node} [props.buttons] Actionable buttons to display below the message.
 * @param {string} [props.className] The alert container's class.
 * @param {string|Node} [props.message] Alert message.
 * @param {string|Node} [props.title] Alert title.
 */
export default function Alert(props) {
  const { buttons, className, message, title, ...otherProps } = props;

  let classNames = styles.container;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  const alert = (
    <>
      <Head>
        <style>{`
          body, html {
            overflow: hidden !important;
          }
        `}</style>
      </Head>
      <div className={classNames} {...otherProps}>
        <div className={styles.alert}>
          <h4 className={styles.title}>{title}</h4>
          <Text>{message}</Text>
          {buttons}
        </div>
      </div>
    </>
  );

  return createPortal(alert, document.getElementById('alertContainer'));
}

Alert.propTypes = {
  buttons: PropTypes.node,
  className: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
