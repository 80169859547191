import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import countries from 'OK/assets/countries.json';
import Button from 'OK/components/button';
import Input from 'OK/components/input';
import OrganisationArchiveCard from 'OK/components/organisation/archiveCard';
import Progressable from 'OK/components/progressable';
import Select from 'OK/components/select';
import Text from 'OK/components/text';
import { createOrganisationMutationTrial, setOrganisationTrialTypeMutation } from 'OK/networking/organisations';
import { getCurrentUserQuery } from 'OK/networking/users';
import { resetLoginModal } from 'OK/state/account/actions';
import { showAuthModal } from 'OK/state/app/actions';
import { createOrganisationAction } from 'OK/state/onboarding/actions';
import UIContext from 'OK/util/context/ui';
import authorisationForResource from 'OK/util/functions/authorisationForResource';
import getTimezoneUTCOffset from 'OK/util/functions/getTimezoneUTCOffset';
import { countryList } from 'OK/util/geolocation';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';
import { languageByIso } from 'OK/util/i18n';
import SessionManager from 'OK/util/session';

function TrialOrganisationModule() {
  const [, , currentUser] = useAuthentication();
  const { t } = useI18n();
  const defaultCountry = useSelector((state) => state.account.preferences.region);
  const appLanguageIso = useSelector((state) => state.account.preferences.language);

  const dispatch = useDispatch();

  useEffect(() => {
    const organisationList = currentUser?.organisationList?.filter(
      (o) =>
        o.type == 'BASIC' &&
        (authorisationForResource(currentUser, o.id, o) == 'MANAGER' ||
          authorisationForResource(currentUser, o.id, o) == 'OWNER')
    );

    setOrganisationsList(organisationList);
  }, [currentUser, currentUser?.organisationList]);

  // State

  const [country, _setCountry] = useState(defaultCountry);
  const [orgName, setOrgName] = useState('');
  const [formError, _setFormError] = useState({ field: null, message: null });
  const [organisationsList, setOrganisationsList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [trialError, setTrialError] = useState();
  const [subscribeLoading, setSubscribeLoading] = useState();

  // API

  const [createOrganisationAPI] = useMutation(createOrganisationMutationTrial, {
    onError: (error) => {
      okerror('Create organisation error', error);
      setFormError('form', t('ERROR_GENERIC'));
    },
    onCompleted: (data) => {
      if (data?.organisation) {
        dispatch(createOrganisationAction(data.organisation));
        SessionManager.activeOrganisationId = data.organisation.id;
        SessionManager.completeOnboarding();
      } else {
        setFormError('form', t('ERROR_GENERIC'));
      }
    },
    refetchQueries: [{ query: getCurrentUserQuery }],
  });

  const [setTrialForOrganisationAPI] = useMutation(setOrganisationTrialTypeMutation, {
    onCompleted: (data) => {
      if (data?.organisation) {
        SessionManager.completeOnboarding();
      } else {
        setTrialError(t('ERROR_GENERIC'));
      }
    },
    onError: (error) => {
      okerror('Error subscribing.', error);
      setTrialError(t('ERROR_GENERIC'));
    },
  });

  // Event handlers

  const onFormChange = (field, value) => {
    if (formError.field === field) {
      setFormError(null, null);
    }

    switch (field) {
      case 'name':
        setOrgName(value);
        break;
      case 'country':
        setCountry(value);
        break;
      default:
        break;
    }
  };

  // Effects

  useEffect(() => {
    if (trialError) {
      setTimeout(() => {
        setTrialError(false);
      }, 5000);
    }
  }, [trialError]);

  // Methods

  const setFormError = (field, message) => {
    _setFormError({ field, message });
  };

  const createOrganisationTrial = async () => {
    setFormError(null, null);
    const requiredFieldErrorMessage = t('EDIT_STEP_TEXT_CANNOT_BE_LEFT_BLANK');

    // Validation
    let errorField, errorMessage;
    if (!orgName) {
      errorField = 'name';
      errorMessage = requiredFieldErrorMessage;
    } else if (!country) {
      errorField = 'country';
      errorMessage = requiredFieldErrorMessage;
    }
    // Don't proceed if there's an error
    if (errorField) {
      okerror('Not submitting request due to error.', errorField, errorMessage);
      setFormError(errorField, errorMessage);
      return;
    }

    setLoading(true);

    const address = {
      countryCode: country,
    };

    const apiVariables = {
      address,
      name: orgName,
    };

    // Podio-related metadata
    const countryName = countries.find((c) => c.isoAlpha3 === country).name;
    const language = languageByIso(appLanguageIso);
    const timezoneUTCOffset = getTimezoneUTCOffset();
    apiVariables.address.country = countryName;
    apiVariables.languageName = language.name;
    apiVariables.languageIso = language.iso;
    apiVariables.timezone = timezoneUTCOffset;

    createOrganisationAPI({ variables: apiVariables }).finally(() => {
      setLoading(false);
    });
  };

  const setCountry = (c) => {
    _setCountry(c);
  };

  const setTrial = useCallback(
    (organisationId) => {
      setSubscribeLoading(true);

      SessionManager.activeOrganisationId = organisationId;

      setTrialForOrganisationAPI({ variables: { organisationType: 'TRIAL' } }).finally(() => {
        setSubscribeLoading(false);
      });
    },
    [setTrialForOrganisationAPI]
  );

  // Render

  const formFieldError = formError.message && <p className={styles.error}>{formError.message}</p>;

  return (
    <>
      <div className={styles.card}>
        <div className={styles.header}>
          <h3>
            {organisationsList?.length > 0
              ? t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_1_HAS_ORGANISATION_HEADER')
              : t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_1_NO_ORGANISATION_HEADER')}
          </h3>
          <Button
            className={styles.actionButtonUpper}
            linkStyle
            tint='alert'
            onClick={() => {
              dispatch(showAuthModal(false));
              dispatch(resetLoginModal());
            }}
          >
            {t('CANCEL')}
          </Button>
        </div>
        <p className={styles.welcomeMessage}>
          {organisationsList?.length > 0
            ? t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_1_HAS_ORGANISATION_NOTICE')
            : t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_1_NO_ORGANISATION_NOTICE')}
        </p>
      </div>
      {organisationsList?.length > 0 && (
        <Progressable inProgress={subscribeLoading}>
          <div style={{ marginBottom: 30 }}>
            {organisationsList?.map((o) => (
              // eslint-disable-next-line react/jsx-key
              <UIContext.Provider value='card'>
                <div className={styles.organisationCardContainer} onClick={() => setTrial(o.id)}>
                  <OrganisationArchiveCard
                    key={o.id}
                    className={styles.organisationCard}
                    fixedWidth={false}
                    layoutOverride='mobile'
                    linkToOrganisation={false}
                    organisation={o}
                    padded={false}
                  />
                </div>
              </UIContext.Provider>
            ))}
            <h4>or, add a new one</h4>
            <Text size='sm' tint='alert'>
              {trialError}
            </Text>
          </div>
        </Progressable>
      )}
      <div>
        <div className={styles.inputField}>
          <h5 style={{ marginBottom: 10 }}>{t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_2_TEXT')}</h5>
          <Input
            hasError={formError.field === 'name'}
            onChange={(e) => onFormChange('name', e.target.value)}
            placeholder={t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_3_PLACEHOLDER_4')}
            type='text'
            value={orgName}
          />
          {formError.field === 'name' && formFieldError}
        </div>
        <div className={styles.inputFieldLast}>
          <h5 style={{ marginBottom: 10 }}>{t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_NOTICE_5')}</h5>
          <Select
            onChange={(c) => onFormChange('country', c)}
            options={countryList
              .map((c) => ({ value: c.isoAlpha3, label: c.name }))
              .sort((a, b) => {
                return a.label > b.label ? 1 : -1;
              })}
            value={country}
          />
          {formError.field === 'country' && formFieldError}
        </div>
        <Button
          style={{ marginBottom: 10 }}
          block
          disabled={loading}
          loading={loading}
          tint='creation'
          onClick={createOrganisationTrial}
          withCaret
        >
          {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_5_BUTTON_TEXT')}
        </Button>
      </div>
      <Text style={{ marginTop: 30 }} size='xs'>
        {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_5_NOTICE')}
      </Text>
    </>
  );
}

export default TrialOrganisationModule;
