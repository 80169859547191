import { gql } from '@apollo/client';

export default class BaseOrganisationModel {
  static GRAPHQL_TYPE = 'Organisation';

  static fragmentName = 'BaseOrganisation_Fragment';
  static fragment = gql`
    fragment ${BaseOrganisationModel.fragmentName} on ${BaseOrganisationModel.GRAPHQL_TYPE} {
      OKID
      addressList {
        city
        countryCode
        line1
        line2
        postalCode
        province
        addressImageURL
        longitude
        latitude
      }
      createdBy
      createdDate
      id
      lastModifiedBy
      lastModifiedDate
      logoImageMediaAsset {
        baseLogoImageURL
        createdBy
        createdDate
        id
        lastModifiedBy
        lastModifiedDate
        logoImageURL
        version
      }
      metadata
      name
      publicEmail
      publicMobile
      publicMobileCountryCode
      status
      type
      version
      websiteURL
      conformityPoint
      criticalAQL
      criticalFailure
      criticalInspected
      majorAQL
      majorFailure
      majorInspected
      minorAQL
      minorFailure
      minorInspected
      publishedLogs
      reliabilityPointForAllLogs
      reliabilityPointForPublishedLogs
      sustainabilityPoint
      totalLogs
      paymentInstructionForBrandAnnualPDFURL
      paymentInstructionForBrandMonthlyPDFURL
      trialEndDate
    }
  `;
}
