import PropTypes from 'prop-types';
import { useContext } from 'react';

import styles from './styles.module.scss';
import baseStyles from './tints/base.module.scss';
import cardStyles from './tints/card.module.scss';
import focusStyles from './tints/focus.module.scss';
import midtoneStyles from './tints/midtone.module.scss';

import LoadingSpinner from 'OK/components/loadingSpinner';
import UIContext from 'OK/util/context/ui';

/**
 * A component to display an on / off value.
 *
 * @param {object} props
 * @param {boolean} [props.checked=false] Whether the toggle is checked / unchecked (on / off).
 * @param {string} [props.className] The toggle's class.
 * @param {boolean} [props.disabled=false] Disable the toggle.
 * @param {boolean} [props.loading=false] Show a loading indicator next to the toggle.
 * @param {function} [props.onChange] Event handler for when the toggle value changes.
 */
export default function Toggle(props) {
  /* Variables */

  const { checked = false, className, disabled = false, loading = false, onChange } = props;
  const uiContext = useContext(UIContext);

  /* Methods */

  /** Trigger change event with new value. */
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!disabled && onChange) {
      onChange(!checked);
    }
  };

  /* Render */

  let classNames = styles.toggle;
  if (checked) {
    classNames = `${classNames} ${styles.checked}`;
  }
  if (disabled) {
    classNames = `${classNames} ${styles.disabled}`;
  }
  switch (uiContext) {
    case 'card':
      classNames = `${classNames} ${cardStyles.toggle}`;
      if (checked) {
        classNames = `${classNames} ${cardStyles.checked}`;
      }
      break;
    case 'focus':
      classNames = `${classNames} ${focusStyles.toggle}`;
      if (checked) {
        classNames = `${classNames} ${focusStyles.checked}`;
      }
      break;
    case 'midtone':
      classNames = `${classNames} ${midtoneStyles.toggle}`;
      if (checked) {
        classNames = `${classNames} ${midtoneStyles.checked}`;
      }
      break;
    default:
      classNames = `${classNames} ${baseStyles.toggle}`;
      if (checked) {
        classNames = `${classNames} ${baseStyles.checked}`;
      }
      break;
  }

  return (
    <div className={className}>
      {loading && <LoadingSpinner className={styles.spinner} />}
      <div className={classNames} onClick={onClick}>
        <div className={styles.handle} />
      </div>
    </div>
  );
}

Toggle.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
};
