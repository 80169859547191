import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUserIdQuery } from '../users';

import { abortInspectionLogMutation } from '.';

import UserModel from 'OK/models/user';
import { setActiveInspectionLogId } from 'OK/state/account/actions';

export default function useAbortInspectionLogAPI() {
  /* Variables */

  const activeInspectionLogId = useSelector((state) => state.account.activeInspectionLogId);
  const currentUserAPIResult = useQuery(getCurrentUserIdQuery);
  const dispatch = useDispatch();
  const userId = currentUserAPIResult.data?.user?.id;

  /* API */

  const [abortInspectionLogAPI] = useMutation(abortInspectionLogMutation, {
    refetchQueries: ['GetCurrentUserInProgressInspectionLogs'],
    update: (cache, result) => {
      if (result.data?.inspectionLog.id && userId) {
        cache.modify({
          id: `${UserModel.GRAPHQL_TYPE}:${userId}`,
          fields: {
            inprogressInspectionLogList: (currentList) => {
              const indexOfInspectionLog = currentList.findIndex((l) => l.id === result.data.inspectionLog.id);
              if (indexOfInspectionLog > -1) {
                const updatedList = [...currentList];
                updatedList.splice(indexOfInspectionLog, 1);
                return updatedList;
              }

              return currentList;
            },
          },
        });
      }
    },
  });

  /* Method */

  const abortInspectionLog = useCallback(
    (inspectionLogId) => {
      if (activeInspectionLogId !== inspectionLogId) {
        dispatch(setActiveInspectionLogId(inspectionLogId));
      }

      return abortInspectionLogAPI().finally(() => {
        dispatch(setActiveInspectionLogId(null));
      });
    },
    [abortInspectionLogAPI, activeInspectionLogId, dispatch]
  );

  return abortInspectionLog;
}
