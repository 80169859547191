import PropTypes from 'prop-types';

import Link from '../';

export default function LinkToInspection(props) {
  const { children, inspectionAsset, ...otherProps } = props;
  const linkUrl = `/archive/workflow/${inspectionAsset.REFID}/edit`;

  return (
    <Link href={linkUrl} {...otherProps}>
      {children}
    </Link>
  );
}

LinkToInspection.propTypes = {
  children: PropTypes.node,
  inspectionAsset: PropTypes.shape({
    REFID: PropTypes.string.isRequired,
  }).isRequired,
};
