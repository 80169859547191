import { set } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
} from 'OK/components/archiveCard';
import ArchiveCardManagerOptions from 'OK/components/archiveCard/managerOptions';
import Button from 'OK/components/button';
import { ICONS } from 'OK/components/icon';
import Link from 'OK/components/link';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import UserModel from 'OK/models/user';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import { formatOkid } from 'OK/util/formatting';
import { OBJECT_PERMISSIONS } from 'OK/util/functions/permissions';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';
import usePermission from 'OK/util/hooks/usePermission';

export default function UserArchiveCard(props) {
  /* Variables */

  const {
    cardClassName,
    className,
    linkToPage = true,
    onClickApprove: onClickApproveProp,
    onClickCancelInvitation: onClickCancelInvitationProp,
    onClickChangeRole: onClickChangeRoleProp,
    onClickInvite: onClickInviteProp,
    onClickReject: onClickRejectProp,
    onClickRemove: onClickRemoveProp,
    role,
    showApproveButton = false,
    showCancelInvitationButton = false,
    showChangeRoleButton = false,
    showInviteButton = false,
    showManageOptions = false,
    showRejectButton = false,
    showRemoveButton = false,
    user,
    ...otherProps
  } = props;
  const { t } = useI18n();
  const dispatch = useDispatch();
  const isPlatformUser = typeof user?.OKID === 'string';
  const OKID = isPlatformUser ? user.OKID : null;
  const [, , currentUser] = useAuthentication(() => false);
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const hasPermissionToViewUserContactInfo = usePermission(
    OBJECT_PERMISSIONS.VIEW_USER_CONTACT_INFO,
    currentUser,
    activeOrganisationId,
    user
  );
  const email = hasPermissionToViewUserContactInfo ? user?.email : null;
  const mobile = hasPermissionToViewUserContactInfo ? `${user?.mobileCountryCode ?? ''} ${user?.mobile ?? ''}` : null;
  const reliabilityPointForPublishedLogs = hasPermissionToViewUserContactInfo
    ? user.reliabilityPointForPublishedLogs
    : null;

  // State

  const [isApproving, setIsApproving] = useState(false);
  const [isCancellingInvitation, setIsCancellingInvitation] = useState(false);
  const [isChangingRole, setIsChangingRole] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  /* Methods */

  const onClickApprove = useCallback(() => {
    if (onClickApproveProp) {
      onClickApproveProp(user.id, setIsApproving);
    }
  }, [onClickApproveProp, user.id]);

  const onClickCancelInvitation = useCallback(() => {
    if (onClickCancelInvitationProp) {
      onClickCancelInvitationProp(user.id, setIsCancellingInvitation);
    }
  }, [onClickCancelInvitationProp, user.id]);

  const onClickChangeRole = useCallback(() => {
    if (onClickChangeRoleProp) {
      onClickChangeRoleProp(user.id, setIsChangingRole);
    }
  }, [onClickChangeRoleProp, user.id]);

  const onClickInvite = useCallback(() => {
    if (onClickInviteProp) {
      onClickInviteProp(isPlatformUser ? user.id : user, setIsInviting);
    }
  }, [isPlatformUser, onClickInviteProp, user]);

  const onClickReject = useCallback(() => {
    if (onClickRejectProp) {
      onClickRejectProp(user.id, setIsRejecting);
    }
  }, [onClickRejectProp, user.id]);

  const onClickRemove = useCallback(() => {
    if (onClickRemoveProp) {
      onClickRemoveProp(user.id, setIsRemoving);
    }
  }, [onClickRemoveProp, user.id]);

  /* Render */

  let classNames = styles.card;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let Container = isPlatformUser && linkToPage ? Link : 'div';
  const containerProps = { className: classNames };
  if (isPlatformUser && linkToPage) {
    containerProps.href = UserModel.link(user);
    containerProps.onClick = (e) => {
      e.preventDefault();
      history.pushState({ isPopup: true, refId: null, dataType: 'USER', OKID: OKID }, null, UserModel.link(user));
      dispatch(setBrowserHistoryAction(history));
    };
  }

  return (
    <Container {...containerProps}>
      <ArchiveCard className={cardClassName} {...otherProps}>
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery placeholderIconName={ICONS.PROFILE.name} />
          <ArchiveCardMainContent>
            <ArchiveCardHeader type={t('INDIVIDUAL')} />
            {user?.name && !email && !mobile && <Text className={styles.name}>{user?.name}</Text>}
            <Text className={styles.email} size='sm'>
              {typeof user === 'string' ? user : email}
            </Text>
            <Text className={styles.mobile} size='sm'>
              {mobile}
            </Text>
            {role && (
              <Tag className={styles.roleTag} invert>
                {role}
              </Tag>
            )}
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow reliabilityGrade={reliabilityPointForPublishedLogs} />
          <ArchiveCardFooterRow okid={OKID && formatOkid(OKID)} />
        </ArchiveCardFooter>
        {showManageOptions && (
          <ArchiveCardManagerOptions>
            {showApproveButton && (
              <Button
                block
                className={styles.manageButton}
                disabled={isApproving}
                loading={isApproving}
                onClick={onClickApprove}
                tint='creation'
              >
                {t('APPROVE_ORGANISATION_JOIN_REQUEST')}
              </Button>
            )}
            {showChangeRoleButton && (
              <Button
                block
                className={styles.manageButton}
                disabled={isChangingRole}
                loading={isChangingRole}
                onClick={onClickChangeRole}
                tint='navigation'
              >
                {showInviteButton ? t('SET_USER_ROLE') : t('CHANGE_USER_ROLE')}
              </Button>
            )}
            {showCancelInvitationButton && (
              <Button
                block
                className={styles.manageButton}
                disabled={isCancellingInvitation}
                linkStyle
                loading={isCancellingInvitation}
                onClick={onClickCancelInvitation}
                tint='alert'
              >
                {t('CANCEL_ORGANISATION_INVITATION')}
              </Button>
            )}
            {showInviteButton && (
              <Button
                block
                className={styles.manageButton}
                disabled={isInviting}
                loading={isInviting}
                onClick={onClickInvite}
                tint='creation'
              >
                {t('INVITE')}
              </Button>
            )}
            {showRejectButton && (
              <Button
                block
                className={styles.manageButton}
                disabled={isRejecting}
                linkStyle
                loading={isRejecting}
                onClick={onClickReject}
                tint='alert'
              >
                {t('REJECT')}
              </Button>
            )}
            {showRemoveButton && (
              <Button
                block
                className={styles.manageButton}
                disabled={isRemoving}
                linkStyle
                loading={isRemoving}
                onClick={onClickRemove}
                tint='alert'
              >
                {t('REMOVE')}
              </Button>
            )}
          </ArchiveCardManagerOptions>
        )}
      </ArchiveCard>
    </Container>
  );
}

UserArchiveCard.propTypes = {
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  linkToPage: PropTypes.bool,
  onClickApprove: PropTypes.func,
  onClickCancelInvitation: PropTypes.func,
  onClickChangeRole: PropTypes.func,
  onClickInvite: PropTypes.func,
  onClickReject: PropTypes.func,
  onClickRemove: PropTypes.func,
  role: PropTypes.string,
  showApproveButton: PropTypes.bool,
  showCancelInvitationButton: PropTypes.bool,
  showChangeRoleButton: PropTypes.bool,
  showInviteButton: PropTypes.bool,
  showManageOptions: PropTypes.bool,
  showRejectButton: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};
