import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { indexOfLinkedAssetInCacheList } from '../graphql/helpers';

import {
  linkOrderDocumentMutation,
  publishOrderDocumentMutation,
  unlinkOrderDocumentMutation,
  unpublishOrderDocumentMutation,
} from '.';

import BaseDocumentModel from 'OK/models/document/base';
import OrderModel from 'OK/models/order';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';

export default function useLinkOrderDocumentAPI() {
  const [linkOrderDocumentAPI] = useMutation(linkOrderDocumentMutation);

  const linkOrderDocument = useCallback(
    (orderId, documentId) => {
      return linkOrderDocumentAPI({
        variables: {
          orderId,
          documentId,
        },
        update: (cache, result) => {
          if (result.data?.orderDocument) {
            // Write DocumentAsset to cache
            const documentAssetRef = cache.writeFragment({
              id: `${BaseDocumentModel.GRAPHQL_TYPE}:${result.data.orderDocument.documentAsset.id}`,
              fragment: BaseDocumentModel.fragment,
              fragmentName: BaseDocumentModel.fragmentName,
              data: result.data.orderDocument.documentAsset,
            });

            // Add OrderDocumentAsset to order's list
            cache.modify({
              id: `${OrderModel.GRAPHQL_TYPE}:${orderId}`,
              fields: {
                orderDocumentAssetList: (currentList) => {
                  const orderDocumentAsset = {
                    ...result.data.orderDocument,
                    documentAsset: documentAssetRef,
                  };
                  return [...currentList, orderDocumentAsset];
                },
              },
            });
          }
        },
      });
    },
    [linkOrderDocumentAPI]
  );

  return linkOrderDocument;
}

export function useUnlinkOrderDocumentAPI() {
  const apolloClient = useApolloClient();
  const [unlinkDocumentAPI] = useMutation(unlinkOrderDocumentMutation);

  const unlinkDocument = useCallback(
    (order, documentId) => {
      // Optimistic response
      const documentAsset = apolloClient.readFragment({
        id: `${BaseDocumentModel.GRAPHQL_TYPE}:${documentId}`,
        fragment: BaseDocumentModel.fragment,
        fragmentName: BaseDocumentModel.fragmentName,
      });
      const orderDocument = order.orderDocumentAssetList.find((pd) => pd.documentAsset.id === documentId);
      const responseOrderDocument = {
        ...orderDocument,
        documentAsset,
      };
      const optimisticResponse = {
        orderDocument: responseOrderDocument,
      };

      // Call API
      return unlinkDocumentAPI({
        variables: {
          documentId,
          orderId: order.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.orderDocument) {
            // Unlink from order's orderDocumentAssetList
            cache.modify({
              id: `${OrderModel.GRAPHQL_TYPE}:${order.id}`,
              fields: {
                orderDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfOrderDocument = indexOfLinkedAssetInCacheList(
                    currentList,
                    result.data.orderDocument.documentAsset.id,
                    'documentAsset',
                    readField
                  );
                  updatedList.splice(indexOfOrderDocument, 1);
                  return updatedList;
                },
              },
            });

            // Unlink from document's linkedOrderList
            cache.modify({
              id: `${BaseDocumentModel.GRAPHQL_TYPE}:${documentId}`,
              fields: {
                linkedOrderList: (currentList, { readField }) => {
                  const indexOfOrder = currentList.findIndex((lp) => {
                    const linkedOrderId = readField('id', lp);
                    return linkedOrderId === order.id;
                  });
                  if (indexOfOrder > -1) {
                    const updatedList = [...currentList];
                    updatedList.splice(indexOfOrder);
                    return updatedList;
                  }

                  return currentList;
                },
              },
            });
          } else {
            okerror('Unlink document api did not respond with order document.');
          }
        },
      });
    },
    [apolloClient, unlinkDocumentAPI]
  );

  return unlinkDocument;
}

export function usePublishOrderDocumentAPI(mutationOptions = {}) {
  const [publishDocumentAPI] = useMutation(publishOrderDocumentMutation, mutationOptions);

  const publishDocument = useCallback(
    (order, documentId) => {
      // Optimistic response
      const orderDocument = order.orderDocumentAssetList.find((pd) => pd.documentAsset.id === documentId);
      const optimisticResponse = {
        orderDocument: {
          ...orderDocument,
          publishStatus: PUBLISH_STATUS.PUBLISHED,
        },
      };

      // Call API
      return publishDocumentAPI({
        variables: {
          documentId,
          orderId: order.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.orderDocument) {
            cache.modify({
              id: `${OrderModel.GRAPHQL_TYPE}:${order.id}`,
              fields: {
                orderDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfOrderDocument = indexOfLinkedAssetInCacheList(
                    currentList,
                    result.data.orderDocument.documentAsset.id,
                    'documentAsset',
                    readField
                  );
                  const currentOrderDocument = updatedList[indexOfOrderDocument];
                  const updatedOrderDocument = {
                    ...currentOrderDocument,
                    publishStatus: result.data.orderDocument.publishStatus,
                  };
                  updatedList.splice(indexOfOrderDocument, 1, updatedOrderDocument);
                  return updatedList;
                },
              },
            });
          } else {
            okerror('Publish document api did not respond with order document.');
          }
        },
      });
    },
    [publishDocumentAPI]
  );

  return publishDocument;
}

export function useUnpublishOrderDocumentAPI(mutationOptions) {
  const [unpublishDocumentAPI] = useMutation(unpublishOrderDocumentMutation, mutationOptions);

  const unpublishDocument = useCallback(
    (order, documentId) => {
      // Optimistic response
      const orderDocument = order.orderDocumentAssetList.find((pd) => pd.documentAsset.id === documentId);
      const optimisticResponse = {
        orderDocument: {
          ...orderDocument,
          publishStatus: PUBLISH_STATUS.UNPUBLISHED,
        },
      };

      // Call API
      return unpublishDocumentAPI({
        variables: {
          documentId,
          orderId: order.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.orderDocument) {
            cache.modify({
              id: `${OrderModel.GRAPHQL_TYPE}:${order.id}`,
              fields: {
                orderDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfOrderDocument = indexOfLinkedAssetInCacheList(
                    currentList,
                    result.data.orderDocument.documentAsset.id,
                    'documentAsset',
                    readField
                  );
                  const updatedOrderDocument = {
                    ...updatedList[indexOfOrderDocument],
                    publishStatus: result.data.orderDocument.publishStatus,
                  };
                  updatedList.splice(indexOfOrderDocument, 1, updatedOrderDocument);
                  return updatedList;
                },
              },
            });
          } else {
            okerror('Unpublish document api did not respond with order document.');
          }
        },
      });
    },
    [unpublishDocumentAPI]
  );

  return unpublishDocument;
}
