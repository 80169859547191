import { gql } from '@apollo/client';

export default class ContactModel {
  static GRAPHQL_TYPE = 'Contact';
  static fragmentNameContacts = 'Contact_Fragment';
  static fragmentContacts = gql`
  fragment ${ContactModel.fragmentNameContacts} on ${ContactModel.GRAPHQL_TYPE} {
      id
      organisationId
      type
      userId
      user{
        OKID
        name
        email
        mobile
        mobileCountryCode
      }
      name
      role
      email
      phone
      publishStatus
      contactLinkList {
        orderData {
          id
          name
          REFID
        }
        siteData {
          id
          name
          OKID
          siteMediaAssetList {
            mediaAsset {
              id
              mediaType
              REFID
              imageData {
                imageURL
                imageName
                baseImageURL
              }
            }
            order
            publishStatus
          }
        }
        sourceId
        sourceType
      }

  }`;

  static mock = () => {
    return {
      id: 'NEW',
      organisationId: null,
      type: 'MANUAL',
      userId: null,
      user: null,
      name: '',
      role: 'CONTACT',
      email: '',
      phone: '',
      contactLinkInputList: [],
    };
  };
}
