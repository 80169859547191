import localizeObject from 'OK/util/functions/localizeObject';

/**
 * Return the best matching value in an object.
 *
 * Order of matching:
 * 1. value
 * 2. key
 *
 * @param {object} obj Values to match against.
 * @param {string} str String to attempt to match.
 * @param {boolean} fallbackToLocalizedValue If no match is found, return a localized value.
 */
export default function getMatchingValue(obj, str, locale, fallbackToLocalizedValue = false) {
  if (str) {
    const keyMatches = [];
    const valueMatches = [];
    const regex = new RegExp(str, 'i');

    // Check for matches in keys and values
    for (const [key, value] of Object.entries(obj)) {
      // First check if the value matches. If so, continue to next iteration
      const valueMatch = regex.exec(value);
      if (valueMatch) {
        valueMatches.push({ key, value, result: valueMatch });
        continue;
      }
      // If the value doesn't match, check if the key matches
      const keyMatch = regex.exec(key);
      if (keyMatch) {
        keyMatches.push({ key, value, result: keyMatch });
      }
    }

    // Check if a match was found in values
    if (valueMatches.length) {
      // If there's a match in the current locale, use it.
      const matchForCurrentLocale = valueMatches.find((m) => m.key === locale);
      if (matchForCurrentLocale) {
        return matchForCurrentLocale.value;
      }

      // Sort matches by value
      valueMatches.sort((a, b) => {
        // Prioritize by match position
        if (a.result.index !== b.result.index) {
          return a.result.index - b.result.index;
        }

        // Prioritize alphabetically
        return a.result.input < b.result.input ? -1 : 1;
      });

      // Return best match
      return valueMatches[0].value;
    }

    // Check if a match was found in keys
    if (keyMatches.length) {
      // If there's a match in the current locale, use it.
      const matchForCurrentLocale = keyMatches.find((m) => m.key === locale);
      if (matchForCurrentLocale) {
        return matchForCurrentLocale.value;
      }

      // Sort matches by key
      keyMatches.sort((a, b) => {
        // Prioritize by match position
        if (a.result.index !== b.result.index) {
          return a.result.index - b.result.index;
        }

        // Prioritize alphabetically
        return a.result.input < b.result.input ? -1 : 1;
      });

      // Return best match
      return keyMatches[0].value;
    }
  }

  if (fallbackToLocalizedValue) {
    // No direct match, so return localized value as fallback
    return localizeObject(obj, locale, true);
  }

  // No match
  return null;
}
