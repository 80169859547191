import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Accordion from 'OK/components/accordion';
import ButtonGroup from 'OK/components/buttonGroup';
import { Carousel, Slide } from 'OK/components/carousel';
import Icon, { ICONS } from 'OK/components/icon';
import ContentLayout from 'OK/components/layouts/content';
import ProductArchiveCard from 'OK/components/product/archiveCard';
import Text from 'OK/components/text';
import useI18n from 'OK/util/hooks/useI18n';

export default function OrganisationCatalogueSection(props) {
  const { organisation } = props;

  const { t } = useI18n();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);

  const [catalogueAccordionOpen, setCatalogueAccordionOpen] = useState(true);
  const [catalogueMode, setCatalogueMode] = useState('PRODUCTS');

  return (
    <>
      <Accordion
        className={styles.accordion}
        fixedWidth={false}
        onChangeOpen={setCatalogueAccordionOpen}
        headerClassName={styles.headerAccordion}
        hideSection
        open={catalogueAccordionOpen}
        title={
          <div className={styles.header}>
            <Icon className={styles.icon} name={ICONS.TWO_CIRCLES.name} height={24} width={24} />
            &nbsp;&nbsp;{t('CATALOGUE')}
          </div>
        }
      >
        <ContentLayout className={styles.manageSection} pageContent>
          <ButtonGroup buttonStyle='separate' className={styles.searchModeButtons}>
            <button active={catalogueMode === 'PRODUCTS'} onClick={() => setCatalogueMode('PRODUCTS')}>
              {t('PRODUCTS')}
            </button>
          </ButtonGroup>
          {organisation.productList?.length !== 0 ? (
            <Text>{t('CATALOGUE_SECTION_DESCRIPTION')}</Text>
          ) : (
            <Text tint='notification'>{t('NO_PRODUCTS')}</Text>
          )}

          <Carousel
            className={styles.productsCarouselContainer}
            fadeOutSides={useDesktopLayout}
            innerClassName={styles.productsCarousel}
          >
            {organisation.productList.map((p) => {
              return (
                <Slide className={styles.productSlide} key={p.id}>
                  <ProductArchiveCard
                    cardClassName={styles.productCard}
                    className={styles.productCardContainer}
                    linkToEditPage={false}
                    product={p}
                  />
                </Slide>
              );
            })}
          </Carousel>
        </ContentLayout>
      </Accordion>
    </>
  );
}

OrganisationCatalogueSection.propTypes = {
  organisation: PropTypes.object,
  ref: PropTypes.any,
};
