import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import AutoHeightAdjustingPopup from 'OK/components/popup/autoHeightAdjusting';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import OrganisationCreatingModule from 'OK/modules/account/create';
import PendingInvitationsModule from 'OK/modules/account/invitations';
import AccountOnboardingModule from 'OK/modules/account/onboarding';
import TrialOrganisationModule from 'OK/modules/account/pickOrganisation';
import AuthModule from 'OK/modules/auth';
import {
  resetLoginModal,
  showCreatingNewOrganisation,
  showInvitations as showInvite,
  showOnboarding as showOnboard,
  showTryForFree,
} from 'OK/state/account/actions';
import { showAuthModal } from 'OK/state/app/actions';
import getStore from 'OK/state/store';
import USER_METADATA_KEYS from 'OK/util/enums/userMetadataKeys';
import useAuthentication from 'OK/util/hooks/useAuthentication';

export default function AppAuthModulePopup(props) {
  const { dismiss } = props;
  const [, , currentUser] = useAuthentication(() => false);
  const { isComingFromTryForFree, showCreateOrganisation, showInvitations, showOnboarding, showTrialOrganisation } =
    useSelector((state) => state.account);
  const router = useRouter();
  const store = getStore();

  // State
  const [showTrial, setShowTrial] = useState(false);
  const [showCreate, setShowCreate] = useState(false);

  // Effects
  useEffect(() => {
    const closeModal = () => {
      dismiss();
    };
    router.events.on('routeChangeStart', closeModal);
  }, [dismiss, router.events]);

  // Effect is needed for re-trigerring the render of a specific section. FadeInOut tranistion causes render issues
  useEffect(() => {
    setShowTrial(showTrialOrganisation);
  }, [showTrialOrganisation]);

  // Effect is needed for re-trigerring the render of a specific section. FadeInOut tranistion causes render issues
  useEffect(() => {
    setShowCreate(showCreateOrganisation);
  }, [showCreateOrganisation]);

  // Methods
  const onCompleteInvitations = useCallback(() => {
    if (!isComingFromTryForFree) {
      if (!currentUser.metadata || currentUser.metadata[USER_METADATA_KEYS.HAS_COMPLETED_ONBOARDING] !== true) {
        store.dispatch(showInvite(false));
        store.dispatch(showOnboard(true));

        if (currentUser?.organisationList > 0) {
          store.dispatch(showAuthModal(false));
          store.dispatch(resetLoginModal());
        }
      } else {
        store.dispatch(showAuthModal(false));
        store.dispatch(resetLoginModal());
      }
    } else if (isComingFromTryForFree) {
      store.dispatch(showInvite(false));
      store.dispatch(showTryForFree(true));
    }
  }, [currentUser?.metadata, currentUser?.organisationList, isComingFromTryForFree, store]);

  const onClickAddOrganisation = useCallback(() => {
    store.dispatch(showCreatingNewOrganisation(true));
    store.dispatch(showOnboard(false));
  }, [store]);

  return (
    <AutoHeightAdjustingPopup dismiss={dismiss} childrenKey={null} className={styles.popupContent}>
      {!currentUser && <AuthModule />}
      <FadeInOutTransition in={showInvitations}>
        {currentUser && showInvitations && <PendingInvitationsModule onComplete={onCompleteInvitations} />}
      </FadeInOutTransition>
      <FadeInOutTransition in={showOnboarding}>
        {currentUser && showOnboarding && <AccountOnboardingModule onClickAddOrganisation={onClickAddOrganisation} />}
      </FadeInOutTransition>
      {currentUser && showTrialOrganisation && (
        <FadeInOutTransition in={showTrial}>
          <TrialOrganisationModule />
        </FadeInOutTransition>
      )}
      {currentUser && showCreateOrganisation && (
        <FadeInOutTransition in={showCreate}>
          <OrganisationCreatingModule />
        </FadeInOutTransition>
      )}
    </AutoHeightAdjustingPopup>
  );
}

AppAuthModulePopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
};
