/* Colors */

const brandColor = '#141515';

/* Functions */

const getCSSVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable);

/* Misc */

// Standard breakpoints

const breakpoints = {
  small: 375,
  medium: 690,
  large: 1005,
};

export {
  /* Colors */
  brandColor,
  /* Functions */
  getCSSVariable,
  /* Misc */
  breakpoints,
};
