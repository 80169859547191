import debounce from 'lodash/debounce';
import { Router, withRouter } from 'next/router';
import { withTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import styles from './styles.module.scss';

import AppSettings from 'OK/components/appSettings';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import ContentLayout from 'OK/components/layouts/content';
import Link from 'OK/components/link';
import { PopupMenu } from 'OK/components/popup/popupAsset';
import Tooltip from 'OK/components/tooltip';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import config from 'OK/config/app';
import UserModel from 'OK/models/user';
import AppAuthModulePopup from 'OK/modules/popups/authModulePopup';
import { ROUTES, SITE_SECTIONS } from 'OK/modules/router';
import {
  setComingFromTrial,
  setComingFromUserPage,
  showCreatingNewOrganisation,
  showInvitations,
  showOnboarding,
  showTryForFree,
} from 'OK/state/account/actions';
import {
  search as searchAction,
  setBrowserHistoryAction,
  showAuthModal,
  showCookiesRequiredAlertAction,
  showScannerAction,
  updateMiscAppStateAction,
} from 'OK/state/app/actions';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import { encodeQueryParametersFromObject } from 'OK/util/functions/url';

/**
 * The app's persistent menu.
 *
 * @param {object} props
 * @param {boolean} [props.disableBackgroundAtTop=false] Disable the menu background at the top of pages.
 * @param {boolean} [props.hideTabsOnMobile=false] Hide tabs in mobile layout.
 * @param {'light'|'dark'|'match'|'invert'} [props.menuStyleWithoutBackground='match'] Menu theme when background is
 * hidden.
 *
 */
class PopupMainMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchExpanded: false,
      searchQuery: '',
      renderCartTooltip: false,
      activeTab: undefined,
      activeTabId: undefined,
      activeTabPosition: {},
      height: window.innerHeight,
      width: window.innerWidth,
      initialFunctionCalled: false,
    };

    this.updateDimensions = this.updateDimensions.bind(this);

    this.actionsContainerRef = createRef();
    this.fadeCartTooltipTimeoutRef = createRef();
    this.searchInputRef = createRef();
    this.archiveTabRef = createRef();
    this.solutionsTabRef = createRef();
  }

  /* Component lifecycle */

  componentDidMount() {
    const { disableBackgroundAtTop = false, isScrolledToTop, showMenuBackground } = this.props;

    if (disableBackgroundAtTop && isScrolledToTop) {
      this.hideMenuBackground();
    } else if (!disableBackgroundAtTop && !showMenuBackground) {
      this.showMenuBackground();
    }

    this.syncSearchQuery(this.props);

    window.addEventListener('resize', this.updateDimensions);

    Router.events.on('routeChangeComplete', this.handleRouteChange);

    this.setState({
      activeTabPosition: this.archiveTabRef.current?.closest('#tab').getBoundingClientRect(),
      activeTab: this.archiveTabRef.current?.closest('#tab'),
      activeTabId: this.archiveTabRef.current?.closest('#tab').id,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { disableBackgroundAtTop = false, isScrolledToTop, showMenuBackground } = this.props;

    const disableBackgroundSettingChanged = prevProps.disableBackgroundAtTop !== disableBackgroundAtTop;
    const isScrolledToTopChanged = prevProps.isScrolledToTop !== isScrolledToTop;

    if (disableBackgroundSettingChanged || isScrolledToTopChanged) {
      // Determine new background state when disableBackgroundAtTop or isScrolledToTop has changed
      if (disableBackgroundAtTop && isScrolledToTop && showMenuBackground) {
        // Hide the menu background if shown
        this.hideMenuBackground();
      } else if (!showMenuBackground) {
        // Show the menu background if hidden
        this.showMenuBackground();
      }
    }

    this.syncSearchQuery(this.props);

    // Fade out Cart tooltip after 5 seconds
    if (prevState.renderCartTooltip === false && this.state.renderCartTooltip === true) {
      clearTimeout(this.fadeCartTooltipTimeoutRef.current);
      this.fadeCartTooltipTimeoutRef.current = setTimeout(() => {
        this.setState({ renderCartTooltip: false });
      }, 5000);
    }

    if (this.state.initialFunctionCalled === false) {
      this.setActiveMenuTab();
    }

    if (window.innerWidth !== this.state.width || window.innerHeight !== this.state.height) {
      this.setActiveMenuTab();
    }
  }

  /* Methods */

  expandSearchInput = () => {
    this.setState({ searchExpanded: true });
  };

  focusSearchInput = () => {
    this.searchInputRef.current.focus();
  };

  hideMenuBackground = () => {
    this.props.dispatch(updateMiscAppStateAction({ showMenuBackground: false }));
  };

  minimizeSearchInput = () => {
    this.setState({ searchExpanded: false });
  };

  search = (query, forceNavigation = false) => {
    if (config.features.search) {
      const { dispatch, router, searchQuery } = this.props;

      // Update Redux
      if (searchQuery !== query) {
        dispatch(searchAction(query));
      }

      // Update URL
      // By default only updates when already on the search page unless forceNavigation is true.
      const previousQuery = router.query.q ?? '';
      if ((router.pathname === '/search' && previousQuery !== query) || forceNavigation) {
        const newQuery = {
          ...router.query,
          q: query,
        };
        const url = `/search?${encodeQueryParametersFromObject(newQuery)}`;
        // Don't create new history entry when already on the search page
        this.updateURLDebounced(url, router.pathname === '/search');
      }
    }
  };

  setSearchQuery = (e) => {
    const newQuery = e.target.value.replace(/'/g, '');
    this.setState({ searchQuery: newQuery });
  };

  showCartTooltip = () => {
    this.setState({ renderCartTooltip: true });
  };

  showMenuBackground = () => {
    this.props.dispatch(updateMiscAppStateAction({ showMenuBackground: true }));
  };

  showLogOption = (okid) => {
    console.log(okid);
  };

  showScanner = () => {
    if (config.features.scanTab) {
      this.props.dispatch(showScannerAction());
    }
  };

  onClickAuth = () => {
    const { userIsAuthenticated, router, user } = this.props;

    userIsAuthenticated ? router.push(user ? UserModel.link(user) : '') : this.props.dispatch(showAuthModal(true));
  };

  onClickActiveTab = (e) => {
    const target = e.target.closest('#tab');

    if (e.target.closest('div').id == 'home') {
      this.setState({ activeTab: undefined });
    } else {
      this.setState({ activeTabPosition: target.getBoundingClientRect(), activeTab: target, activeTabId: target.id });
    }
  };

  onClickUserAndOrgIcon = (e) => {
    if (e.target.closest('div').id == 'userAndOrgIcon' || e.target.closest('img').id == 'userAndOrgIcon') {
      this.setState({
        activeTabPosition: this.archiveTabRef.current?.closest('#tab').getBoundingClientRect(),
        activeTab: this.archiveTabRef.current?.closest('#tab'),
        activeTabId: this.archiveTabRef.current?.closest('#tab').id,
      });
    }
  };

  onClickCloseAuth = () => {
    this.props.dispatch(showAuthModal(false));
    this.props.dispatch(showInvitations(false));
    this.props.dispatch(showCreatingNewOrganisation(false));
    this.props.dispatch(showOnboarding(false));
    this.props.dispatch(showTryForFree(false));
    this.props.dispatch(setComingFromTrial(false));
    this.props.dispatch(setComingFromUserPage(false));
  };

  submitSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.searchQuery) {
      this.search(this.state.searchQuery, true);
      this.searchInputRef.current?.blur();

      history.pushState({ isPopup: false, refId: null, dataType: null, OKID: null }, null);
      this.props.dispatch(setBrowserHistoryAction(history));
    }
  };

  /** Trigger searches on initial load or when Redux search changes. */
  syncSearchQuery = (props) => {
    const { router, searchQuery } = props;
    if (router.isReady) {
      if (searchQuery === undefined) {
        // Redux has no search query, so likely navigated directly to the search page via the address bar.
        const { q } = router.query;
        if (q !== searchQuery) {
          // Search with the URL query
          this.search(q);
        }
      } else {
        // Search with the redux query
        this.search(searchQuery);
      }
    }
  };

  updateURLDebounced = debounce((newURL, replace = false) => {
    const { router } = this.props;
    if (replace) {
      router.replace(newURL);
    } else {
      router.push(newURL);
    }
  }, DEBOUNCE_TIMING_MS_SHORT);

  // Event handlers

  onClickMessages = () => {
    if (this.props.userPermitsCookies !== '1') {
      this.props.dispatch(showCookiesRequiredAlertAction());
    }
  };

  onClickProfile = (e) => {
    e.preventDefault();
    history.pushState(
      { isPopup: true, refId: null, dataType: 'USER', OKID: this.props.user.OKID },
      null,
      UserModel.link(this.props.user)
    );
    this.props.dispatch(setBrowserHistoryAction(history));
  };

  onClickOrganisation = (e) => {
    e.preventDefault();
    const activeOrganisation = this.props.user?.organisationList?.find((o) => o.id === this.props.activeOrganisationId);
    history.pushState(
      { isPopup: true, refId: null, dataType: 'ORGANISATION', OKID: activeOrganisation.OKID },
      null,
      `/organisation/${activeOrganisation.OKID}`
    );
    this.props.dispatch(setBrowserHistoryAction(history));
  };

  updateDimensions() {
    setTimeout(() => {
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth,
        activeTabPosition: this.state?.activeTab?.getBoundingClientRect() ?? {},
      });
    }, 100);
  }

  setActiveMenuTab = () => {
    const { router } = this.props;

    if (router.pathname === '/') {
      this.setState({ activeTab: undefined });
    }
    setTimeout(() => {
      switch (true) {
        case router.pathname.includes(ROUTES.ARCHIVE()):
        case router.pathname.includes('/user'):
        case router.pathname.includes('/organisation'):
          this.setState({
            activeTabPosition: this.archiveTabRef.current?.closest('#tab').getBoundingClientRect(),
            activeTab: this.archiveTabRef.current?.closest('#tab'),
            activeTabId: this.archiveTabRef.current?.closest('#tab').id,
          });
          break;
        default:
          this.setState({
            activeTabPosition: this.archiveTabRef.current?.closest('#tab').getBoundingClientRect(),
            activeTab: this.archiveTabRef.current?.closest('#tab'),
            activeTabId: this.archiveTabRef.current?.closest('#tab').id,
          });
      }
    }, 100);

    this.setState({ initialFunctionCalled: true });
  };

  handleRouteChange = () => {
    const { router } = this.props;

    setTimeout(() => {
      switch (true) {
        case router.pathname.includes(ROUTES.ARCHIVE()):
        case router.pathname.includes('/user'):
        case router.pathname.includes('/organisation'):
          this.setState({
            activeTabPosition: this.archiveTabRef.current?.closest('#tab').getBoundingClientRect(),
            activeTab: this.archiveTabRef.current?.closest('#tab'),
            activeTabId: this.archiveTabRef.current?.closest('#tab').id,
          });
          break;
        // case router.pathname.includes('/solutions'):
        //   this.setState({
        //     activeTabPosition: this.solutionsTabRef.current?.closest('#tab').getBoundingClientRect(),
        //     activeTab: this.solutionsTabRef.current?.closest('#tab'),
        //     activeTabId: this.solutionsTabRef.current?.closest('#tab').id,
        //   });
        //   break;
        case ROUTES.HOME():
          this.setState({
            activeTab: undefined,
          });
      }
    }, 100);
  };

  /* Render */

  render() {
    const {
      activeOrganisationId,
      activeSiteSection,
      activeSiteSectionLastVisitedPaths,
      hideTabsOnMobile = false,
      isRestoringSession = false,
      isShowingStatusBanner,
      isScrollingPopup,
      menuStyleWithoutBackground = 'match',
      searchQuery = '',
      showAuthModal,
      showMenuBackground,
      t,
      useDesktopLayout,
      useMobileLayout,
      user,
      userIsAuthenticated,
    } = this.props;
    const theme = this.context;
    const showLoggedInView = userIsAuthenticated || isRestoringSession;
    const lastVisitedArchivePage = activeSiteSectionLastVisitedPaths[SITE_SECTIONS.ARCHIVE];
    const isInArchiveSection = activeSiteSection === SITE_SECTIONS.ARCHIVE;
    const lastMenuOption = showLoggedInView ? (
      <Link
        id={'tab'}
        onClick={() => {
          this.onClickActiveTab;
          history.pushState(
            { isPopup: false, refId: null, dataType: null, OKID: null },
            null,
            !isInArchiveSection && lastVisitedArchivePage ? lastVisitedArchivePage : ROUTES.ARCHIVE()
          );
          this.props.dispatch(setBrowserHistoryAction(history));
        }}
        className={`${styles.navigationButton} ${activeSiteSection === SITE_SECTIONS.ARCHIVE ? styles.active : ''}`}
        disableIfOffline={false}
        href={!isInArchiveSection && lastVisitedArchivePage ? lastVisitedArchivePage : ROUTES.ARCHIVE()}
      >
        <div className={styles.navigationIcon} ref={this.archiveTabRef}>
          <Icon
            height={22}
            name={ICONS.ARCHIVE.name}
            style={{ color: theme.name === 'dark' ? '#f4f4f4' : '#1a1a1a' }}
            width={22}
          />
        </div>
        <p className={styles.navigationLabel} style={{ color: theme.name === 'dark' ? '#f4f4f4' : '#1a1a1a' }}>
          {t('MAIN_MENU_BUTTON_ARCHIVE')}
        </p>
      </Link>
    ) : (
      <div className={styles.navigationButton} onClick={this.onClickAuth}>
        <div className={styles.navigationIcon}>
          <Icon height={22} name={ICONS.ACCESS_LG.name} tint='creation' width={22} />
        </div>
        <p className={styles.navigationLabel} style={{ color: '#008656' }}>
          {t('MAIN_MENU_BUTTON_ACCESS')}
        </p>
      </div>
    );

    // Menu theme
    let menuTheme;
    switch (menuStyleWithoutBackground) {
      case 'light':
      case 'dark':
        menuTheme = menuStyleWithoutBackground;
        break;
      case 'invert':
        menuTheme = theme.name === 'dark' ? 'light' : 'dark';
        break;
      default:
        // Match app theme
        menuTheme = theme.name;
        break;
    }

    // Determine where the profile button should link to
    let profileLink = ROUTES.HOME();
    if (config.features.login) {
      if (userIsAuthenticated || isRestoringSession) {
        profileLink = user ? UserModel.link(user) : '';
      } else {
        profileLink = ROUTES.AUTH();
      }
    }

    // Profile photo
    let accountProfilePhotoUrl;
    if (user?.avatar) {
      accountProfilePhotoUrl = theme.name === 'dark' ? user.avatar.sourceDarkURL : user.avatar.sourceLightURL;
    } else {
      accountProfilePhotoUrl = `/img/empty_user_${theme.name}.svg`;
    }

    // Access icon
    let accessIcon;
    if (userIsAuthenticated) {
      accessIcon = (
        <div
          className={styles.accountProfilePhoto}
          style={{ backgroundImage: `url(${accountProfilePhotoUrl})` }}
          id='userAndOrgIcon'
        />
      );
    } else if (isRestoringSession) {
      accessIcon = <Icon height={22} name={ICONS.SPINNER.name} width={22} />;
    } else {
      accessIcon = <Icon height={22} name={ICONS.ACCESS_LG.name} width={24} />;
    }

    // Organisation button
    let organisationLogoUrl;
    let organisationProfileUrl;
    if (activeOrganisationId) {
      const activeOrganisation = user?.organisationList?.find((o) => o.id === activeOrganisationId);
      if (activeOrganisation?.logoImageMediaAsset?.logoImageURL) {
        organisationLogoUrl = activeOrganisation.logoImageMediaAsset.logoImageURL;
      } else {
        organisationLogoUrl = `/img/empty_organisation_${theme.name}.svg`;
      }
      organisationProfileUrl = activeOrganisation ? `/organisation/${activeOrganisation.OKID}` : '';
    }

    // App settings theme
    let appSettingsTheme;
    if (!showMenuBackground) {
      appSettingsTheme = menuTheme;
    }

    // Classes
    let containerClassNames = `${styles.container} ${showMenuBackground ? styles.backgroundEnabled : ''}`;
    // Allow pages to override the normal theme for the menu when there's no background
    if (!showMenuBackground) {
      containerClassNames = `${containerClassNames} ${styles[`theme_override_${menuTheme}`]}`;
    }
    // Adjust for footer banner
    let tabsClassNames = styles.navigation;
    if (isShowingStatusBanner) {
      tabsClassNames += ` ${styles.withFooterBanner}`;
    }

    const logo = (
      <div
        className={styles.logoContainer}
        id={'home'}
        onClick={() => {
          this.onClickActiveTab;
          history.pushState({ isPopup: false, refId: null, dataType: null, OKID: null }, null, ROUTES.HOME());
          this.props.dispatch(setBrowserHistoryAction(history));
        }}
      >
        <Link aria-label='Logo' className={styles.logoLink} href={ROUTES.HOME()}>
          <Icon className={styles.logoIcon} height={19} name={ICONS.LOGO.name} width={33} />
        </Link>
      </div>
    );

    const lastVisitedSolutionsPage = activeSiteSectionLastVisitedPaths[SITE_SECTIONS.SOLUTIONS];
    const isInSolutionsSection = activeSiteSection === SITE_SECTIONS.SOLUTIONS;
    const tabs = (
      <ContentLayout className={tabsClassNames} contentClassName={styles.innerNavigationContainer}>
        <div
          style={{
            display: typeof this.state.activeTab != 'undefined' ? 'block' : 'none',
            position: 'absolute',
            top: useDesktopLayout ? this.state.activeTabPosition?.bottom : 2,
            left: this.state.activeTabPosition?.left,
            width: '40px',
            height: '2px',
            backgroundColor: useDesktopLayout ? (theme.name === 'dark' ? '#f4f4f4' : '#1d1d1d') : '#0079d2',
            transition: 'left 0.3s ease-in-out',
            transform: useDesktopLayout ? 'translateX(100%)' : 'translateX(50%)',
            borderRadius: '5px',
          }}
        />
        {useDesktopLayout && logo}
        <Link
          className={`${styles.navigationButton} ${isInSolutionsSection ? styles.active : ''}`}
          id={'tab'}
          onClick={() => {
            this.onClickActiveTab;
            history.pushState(
              { isPopup: false, refId: null, dataType: null, OKID: null },
              null,
              !isInSolutionsSection && lastVisitedSolutionsPage ? lastVisitedSolutionsPage : ROUTES.SOLUTIONS_FEATURES()
            );

            this.props.dispatch(setBrowserHistoryAction(history));
          }}
          disableIfOffline={false}
          href={
            !isInSolutionsSection && lastVisitedSolutionsPage ? lastVisitedSolutionsPage : ROUTES.SOLUTIONS_FEATURES()
          }
        >
          <div className={styles.navigationIcon} ref={this.solutionsTabRef}>
            <Icon height={22} name={ICONS.SOLUTIONS.name} width={22} />
          </div>
          <p className={styles.navigationLabel}>{t('MAIN_MENU_BUTTON_SOLUTIONS')}</p>
        </Link>
        <div className={`intercom ${styles.navigationButton}`} onClick={this.onClickMessages}>
          <div className={styles.navigationIcon}>
            <Icon height={22} name={ICONS.MESSAGES.name} width={22} />
            {!userIsAuthenticated && !isRestoringSession && <span className={styles.notificationBadge}>1</span>}
          </div>
          <p className={styles.navigationLabel}>{t('MAIN_MENU_BUTTON_SUPPORT')}</p>
          {config.features.messagesTooltip && !userIsAuthenticated && !isRestoringSession && (
            <div className={styles.tooltipContainer}>
              <Tooltip
                bounce
                className={`${styles.tooltip} ${styles.talkToUsTooltip}`}
                containerClassName={styles.tooltipInnerContainer}
                position={useDesktopLayout ? 'below' : 'above'}
                tint='navigation'
              >
                {t('TALK_TO_US_NOW')}
              </Tooltip>
            </div>
          )}
        </div>

        <div className={styles.navigationButton} onClick={this.showScanner}>
          <div className={styles.navigationIcon}>
            <Icon height={22} name={ICONS.SCAN.name} width={22} />
          </div>
          <p className={styles.navigationLabel}>{t('MAIN_MENU_BUTTON_SCAN')}</p>
        </div>
        <div className={styles.navigationButton} onClick={this.showCartTooltip}>
          <div className={styles.navigationIcon}>
            <Icon height={22} name={ICONS.CART.name} width={22} />
          </div>
          <p className={styles.navigationLabel}>{t('CART')}</p>
          <FadeInOutTransition className={styles.tooltipAnimationContainer} in={this.state.renderCartTooltip}>
            <div className={styles.tooltipContainer}>
              <Tooltip
                className={`${styles.tooltip} ${styles.cartTooltip}`}
                containerClassName={styles.tooltipInnerContainer}
                position={useDesktopLayout ? 'below' : 'above'}
                tint='navigation'
              >
                {t('COMING_SOON')}
              </Tooltip>
            </div>
          </FadeInOutTransition>
        </div>
        {lastMenuOption}
      </ContentLayout>
    );

    return (
      <PopupMenu>
        <div className={styles.menuWrap} style={{ top: isScrollingPopup ? -152 : 0 }}>
          <div className={containerClassNames} id='mainMenu'>
            {useDesktopLayout && tabs}
            <ContentLayout
              className={styles.actions}
              contentClassName={styles.innerActionContainer}
              ref={this.actionsContainerRef}
            >
              {useMobileLayout && logo}
              <div className={styles.sessionOptionsContainer}>
                <AppSettings className={styles.appSettings} themeOverride={appSettingsTheme} />
                {activeOrganisationId && (
                  <Link
                    className={styles.organisationButton}
                    href={organisationProfileUrl}
                    onClick={this.onClickOrganisation}
                  >
                    <img
                      alt='Organisation logo'
                      className={styles.organisationLogo}
                      src={organisationLogoUrl}
                      id='userAndOrgIcon'
                    />
                  </Link>
                )}
                {showLoggedInView && (
                  <Link className={styles.userButton} href={profileLink} onClick={this.onClickProfile}>
                    {accessIcon}
                  </Link>
                )}
              </div>
              <form className={styles.searchForm} onSubmit={this.submitSearch}>
                <div onClick={this.focusSearchInput}>
                  <SearchInput
                    className={`${styles.search} ${this.state.searchExpanded ? styles.expanded : ''}`}
                    onBlur={this.minimizeSearchInput}
                    onChange={this.setSearchQuery}
                    onFocus={this.expandSearchInput}
                    onClickSearch={this.submitSearch}
                    placeholder={t('SEARCH_PLACEHOLDER')}
                    ref={this.searchInputRef}
                    showCancelOnFocus
                    showClearButton={false}
                    withIcon={<Icon name={ICONS.SEARCH.name} onClick={this.focusSearchInput} />}
                    value={searchQuery}
                  />
                </div>
              </form>
            </ContentLayout>
          </div>
          {useMobileLayout && !hideTabsOnMobile && tabs}
          {showAuthModal && <AppAuthModulePopup dismiss={this.onClickCloseAuth} />}
        </div>
      </PopupMenu>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    Router.events.off('routeChangeComplete', this.handleRouteChange);
  }
}

PopupMainMenu.propTypes = {
  activeOrganisationId: PropTypes.string,
  activeSiteSection: PropTypes.string,
  activeSiteSectionLastVisitedPaths: PropTypes.object,
  disableBackgroundAtTop: PropTypes.bool,
  dispatch: PropTypes.func,
  hideTabsOnMobile: PropTypes.bool,
  isRestoringSession: PropTypes.bool,
  isScrolledToTop: PropTypes.bool,
  isScrollingPopup: PropTypes.bool,
  isShowingStatusBanner: PropTypes.bool,
  menuStyleWithoutBackground: PropTypes.oneOf(['light', 'dark', 'match', 'invert']),
  router: PropTypes.any.isRequired,
  searchQuery: PropTypes.string,
  showAuthModal: PropTypes.bool,
  showMenuBackground: PropTypes.bool,
  t: PropTypes.func,
  useDesktopLayout: PropTypes.bool,
  useMobileLayout: PropTypes.bool,
  user: PropTypes.any,
  userIsAuthenticated: PropTypes.bool.isRequired,
  userPermitsCookies: PropTypes.oneOf(['0', '1']),
};

PopupMainMenu.contextType = ThemeContext;

export default connect((state) => {
  const { app, account } = state;
  const { activeOrganisationId, authenticated, isRestoring, user } = account;
  const {
    activeSiteSection,
    activeSiteSectionLastVisitedPaths,
    isScrolledToTop,
    isScrollingPopup,
    isShowingStatusBanner,
    searchQuery,
    showAuthModal,
    showMenuBackground,
    useDesktopLayout,
    useMobileLayout,
    userPermitsCookies,
  } = app;

  return {
    activeOrganisationId,
    activeSiteSection,
    activeSiteSectionLastVisitedPaths,
    isRestoringSession: isRestoring,
    isScrolledToTop,
    isScrollingPopup,
    isShowingStatusBanner,
    searchQuery,
    showAuthModal,
    showMenuBackground,
    useDesktopLayout,
    useMobileLayout,
    user,
    userIsAuthenticated: authenticated,
    userPermitsCookies,
  };
})(withRouter(withTranslation()(PopupMainMenu)));
