import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import { LinkNoRedux } from 'OK/components/link';
import Notice from 'OK/components/notice';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import Radio from 'OK/components/radio';
import Select from 'OK/components/select';
import Text from 'OK/components/text';
import Toggle from 'OK/components/toggle';
import { updateUserPreferencesAction } from 'OK/state/account/actions';
import { setUserPermitsCookies } from 'OK/state/app/actions';
import { countryList } from 'OK/util/geolocation';
import useI18n from 'OK/util/hooks/useI18n';
import { formatLanguageIsoAsLocale, translableLanguages } from 'OK/util/i18n';

export default function AppSettingsPopUp(props) {
  /* Variables */

  const { dismiss } = props;
  const accountState = useSelector((state) => state.account);
  const { preferences } = accountState;
  const userPermitsCookies = useSelector((state) => state.app.userPermitsCookies);
  const { locale, setLocale, t, tHTML } = useI18n();

  const dispatch = useDispatch();

  /* Methods */

  /* Events */

  const onChangeAppearanceMode = useCallback(
    (e) => {
      const newAppearanceMode = e.target.value;

      dispatch(updateUserPreferencesAction({ appearanceMode: newAppearanceMode }));
    },
    [dispatch]
  );

  const onChangeLanguage = useCallback(
    (l) => {
      setLocale(l);
      dispatch(updateUserPreferencesAction({ language: l }));
    },
    [dispatch, setLocale]
  );

  const onChangeRegion = useCallback(
    (r) => {
      dispatch(updateUserPreferencesAction({ region: r }));
    },
    [dispatch]
  );

  const toggleCookiePermission = useCallback(
    (permit) => {
      dispatch(setUserPermitsCookies(permit ? '1' : '0'));
    },
    [dispatch]
  );

  /* Effects */

  /* Render */

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.popupContent}>
        <h2 className={styles.header}>{t('SETTINGS')}</h2>
        <PopupCloseButton className={styles.closeButton} linkStyle tint='navigation'>
          {t('CLOSE')}
        </PopupCloseButton>
        <div className={styles.popupColumnContainer}>
          <div className={styles.column}>
            <div className={styles.section}>
              <h4>{t('SETTINGS_SECTION_REGIONAL')}</h4>
              <div>
                <Text size='md' className={styles.headerWithSubtext}>
                  <strong>{t('LABEL_FIELD_LANGUAGE')}</strong>
                </Text>
                <Select
                  disableEmptySelection
                  onChange={onChangeLanguage}
                  options={translableLanguages.map((l) => ({
                    value: formatLanguageIsoAsLocale(l.iso),
                    label: l.name + ' - ' + l.nativeName,
                  }))}
                  value={preferences.language}
                />
              </div>
              <div className={styles.addMarginTop}>
                <Text size='md' className={styles.headerWithSubtext}>
                  <strong>{t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_NOTICE_5')}</strong>
                </Text>
                <Select
                  disableEmptySelection
                  onChange={onChangeRegion}
                  options={countryList.map((c) => ({ value: c.isoAlpha3, label: c.isoAlpha2 === locale.slice(-2) ? c.nativeName : c.name }))}
                  value={preferences.region}
                />
              </div>
            </div>
            <div className={styles.section}>
              <h4>{t('SETTINGS_SECTION_ACCESSIBILITY')}</h4>
              <div>
                <Text size='md' className={styles.headerWithSubtext}>
                  <strong>{t('SETTING_APPEARANCE')}</strong>
                </Text>
                <Text size='md'>{t('SETTING_APPEARANCE_DESCRIPTION')}</Text>
                <Radio
                  checked={preferences.appearanceMode === APPEARANCE_MODE_SYSTEM}
                  name='appearanceMode'
                  onChange={onChangeAppearanceMode}
                  value={APPEARANCE_MODE_SYSTEM}
                >
                  <Text size='md' className={styles.headerWithSubtext}>
                    {t('SETTING_APPEARANCE_OPTION_SYSTEM')}
                  </Text>
                  <Text size='sm'>{t('SETTING_APPEARANCE_OPTION_SYSTEM_DESCRIPTION')}</Text>
                </Radio>
                <Radio
                  checked={preferences.appearanceMode === APPEARANCE_MODE_LIGHT}
                  name='appearanceMode'
                  onChange={onChangeAppearanceMode}
                  value={APPEARANCE_MODE_LIGHT}
                >
                  <Text size='md' className={styles.headerWithSubtext}>
                    {t('SETTING_APPEARANCE_OPTION_LIGHT')}
                  </Text>
                  <Text size='sm'>{t('SETTING_APPEARANCE_OPTION_LIGHT_DESCRIPTION')}</Text>
                </Radio>
                <Radio
                  checked={preferences.appearanceMode === APPEARANCE_MODE_DARK}
                  name='appearanceMode'
                  onChange={onChangeAppearanceMode}
                  value={APPEARANCE_MODE_DARK}
                >
                  <Text size='md' className={styles.headerWithSubtext}>
                    {t('SETTING_APPEARANCE_OPTION_DARK')}
                  </Text>
                  <Text size='sm' style={{ margin: 0 }}>
                    {t('SETTING_APPEARANCE_OPTION_DARK_DESCRIPTION')}
                  </Text>
                </Radio>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <Notice className={styles.cookiesNotice}>
              <h4>{t('SETTINGS_SECTION_COOKIES')}</h4>
              <Text bold size='md'>
                {t('SETTINGS_SECTION_COOKIES_DESCRIPTION')}
              </Text>
              <div className={styles.toggleRow}>
                <Text size='md'>{t('SETTING_THIRD_PARTY_COOKIES')}</Text>
                <Toggle checked={userPermitsCookies === '1'} onChange={toggleCookiePermission} />
              </div>
              <Text>
                {tHTML('SETTING_THIRD_PARTY_COOKIES_DESCRIPTION', {
                  data: {
                    icon: <Icon inline name={ICONS.MESSAGES.name} />,
                  },
                })}
              </Text>
              <Text bold size='xs'>
                {tHTML('REVIEW_COOKIE_POLICY_FOR_INFO', {
                  data: {
                    policyLink: (
                      <LinkNoRedux className={styles.noticeLink} href='/solutions/terms'>
                        {t('LEGAL_TERMS_CARD_3_BOLD')}
                      </LinkNoRedux>
                    ),
                  },
                })}
              </Text>
            </Notice>
          </div>
        </div>
      </PopupContent>
      <PopupButtonsGroup>
        <PopupCloseButton tint='navigation'>{t('CLOSE')}</PopupCloseButton>
      </PopupButtonsGroup>
    </Popup>
  );
}

AppSettingsPopUp.propTypes = {
  dismiss: PropTypes.func.isRequired,
};

/* Helper variables */

const APPEARANCE_MODE_SYSTEM = 'auto';
const APPEARANCE_MODE_LIGHT = 'light';
const APPEARANCE_MODE_DARK = 'dark';
