import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import OpenCloseTransition from 'OK/components/transitions/openClose';
import { baseTheme } from 'OK/styles/theme';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * @typedef {object} AccordionProps
 * @prop {Node} children
 * @prop {string} className Class for the accordion.
 * @prop {string} contentClassName Class for the inner content container.
 * @prop {string} editButtonClassName Class for the edit button.
 * @prop {string} editIconClassname Class for the edit button icon.
 * @prop {string} headerClassName Class for the header.
 * @prop {boolean} hideSection Boolean for hide/show toggle.
 * @prop {(open: boolean) => {}} onChangeEdit Event handler for when edit is toggled.
 * @prop {(open: boolean) => {}} onChangeOpen Event handler for when open is toggled.
 * @prop {boolean} showEdit Boolean for showing the edit button.
 * @prop {boolean} open Open the accordion.
 * @prop {string|Node} title The title of the accordion.
 * @prop {string} toggleButtonClassname Class for the more/less toggle button.
 */

/**
 * Render content in a collapsible container.
 *
 * @type {React.FC<AccordionProps>}
 */
const Accordion = forwardRef((props, forwardedRef) => {
  /* Variables */

  const {
    children,
    className,
    contentClassName,
    edit = false,
    editButtonClassname,
    editIconClassname,
    headerClassName,
    hideSection = false,
    onChangeEdit,
    onChangeOpen,
    open = false,
    showEdit = false,
    showToggle = true,
    title,
    toggleButtonClassname,
  } = props;
  const { t } = useI18n();

  // State
  const [accordionOverflowY, setAccordionOverflowY] = useState('hidden');

  /* Methods */

  // Event handlers

  const onToggleOpen = () => {
    if (onChangeOpen) {
      onChangeOpen(!open);
    }

    if (open && edit) {
      onChangeEdit(!edit);
    }
  };

  const onToggleEdit = () => {
    if (onChangeEdit) {
      onChangeEdit(!edit);
    }
    if (!open && !edit) {
      onChangeOpen(!open);
    }
  };

  /* Effects */

  // Update overflow-y on open/close
  // On open: set to 'visible' after a short delay
  // On close: set to 'hidden'
  useEffect(() => {
    let updateAccordionOverflowYTimeout;
    if (open) {
      updateAccordionOverflowYTimeout = setTimeout(() => {
        setAccordionOverflowY('visible');
      }, baseTheme.timing.timingLongMs);
    } else {
      setAccordionOverflowY('hidden');
    }

    return function cleanup() {
      clearTimeout(updateAccordionOverflowYTimeout);
    };
  }, [open]);

  /* Render */

  // Accordion classes
  let classNames = `${styles.accordion} ${open ? styles.open : styles.closed}`;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  // Toggle button classes
  let toggleButtonClassnames = styles.toggleButton;
  if (toggleButtonClassname) {
    toggleButtonClassnames = `${toggleButtonClassnames} ${toggleButtonClassname}`;
  }

  // Edit button classes

  let editButtonClassnames = styles.editButton;
  if (editButtonClassname) {
    editButtonClassnames = `${editButtonClassnames} ${editButtonClassname}`;
  }

  // Toggle icon classes
  let iconClassNames = styles.toggleIcon;
  if (!open) {
    iconClassNames = `${iconClassNames} ${styles.closed}`;
  }

  // Edit icon classes
  let editIconClassnames = styles.editIcon;
  if (editIconClassname) {
    editIconClassnames = `${editIconClassnames} ${editIconClassname}`;
  }

  // Accordion classes
  let headerClassNames = `${styles.header}`;
  if (headerClassName) {
    headerClassNames = `${headerClassNames} ${headerClassName}`;
  }

  return (
    <div className={classNames} ref={forwardedRef} style={{ overflowY: accordionOverflowY }}>
      <div className={`${headerClassNames} ${hideSection && styles.headerHide}`} onClick={hideSection && onToggleOpen}>
        {typeof title === 'string' ? <h4 className={styles.title}>{title}</h4> : title}
        <div>
          {showEdit && (
            <Button
              className={editButtonClassnames}
              icon={<Icon className={editIconClassnames} name={edit ? ICONS.TICK.name : ICONS.PENCIL.name} />}
              linkStyle
              onClick={onToggleEdit}
            >
              {edit ? t('DONE') : t('EDIT')}
            </Button>
          )}
          {showToggle && (
            <Button
              className={toggleButtonClassnames}
              icon={<Icon className={iconClassNames} name={ICONS.CARET.name} />}
              linkStyle
              onClick={onToggleOpen}
            >
              {hideSection ? (open ? t('HIDE') : t('SHOW')) : open ? t('LESS') : t('MORE')}
            </Button>
          )}
        </div>
      </div>
      <OpenCloseTransition className={contentClassName} open={open}>
        {children}
      </OpenCloseTransition>
    </div>
  );
});

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  edit: PropTypes.bool,
  editButtonClassname: PropTypes.string,
  editIconClassname: PropTypes.string,
  headerClassName: PropTypes.string,
  hideSection: PropTypes.bool,
  onChangeEdit: PropTypes.func,
  onChangeOpen: PropTypes.func,
  open: PropTypes.bool,
  showEdit: PropTypes.bool,
  showToggle: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  toggleButtonClassname: PropTypes.string,
};

export default Accordion;
