import { gql } from '@apollo/client';

import InspectionAssetModel from 'OK/models/inspectionAsset';
import InspectionTestAssetModel from 'OK/models/inspectionTestAsset';
import BaseOrganisationModel from 'OK/models/organisation/base';
import TestAssetModel from 'OK/models/testAsset';

/* Queries */

export const InspectionAssetAdminArchivePageQuery = gql`
  query GetInspectionAssetByRefId($refId: String!) {
    inspectionAsset: inspectionAssetWithREFID(REFID: $refId) {
      id
      inspectionTestAssetList {
        testAsset {
          ...${TestAssetModel.fragmentNameArchiveCard}
        }
      }
      name
      organisationId
      reliabilityPointForPublishedLogs
      totalLogs
    }
  }
  ${TestAssetModel.fragmentArchiveCard}
`;

/* Mutations */

export const addThirdPartyInspectorMutation = gql`
  mutation AddThirdPartyInspector($inspectionAssetId: String!, $targetOrganisationId: String!) {
    inspectionAsset: addInspectionAssetInspectionOrganisation(inspectionAssetId: $inspectionAssetId, targetOrganisationId: $targetOrganisationId) {
      id
      inspectionOrganisationList {
        ...${BaseOrganisationModel.fragmentName}
      }
    }
  }
  ${BaseOrganisationModel.fragment}
`;

export const createInspectionTestMutation = gql`
  mutation CreateInspectionTest($inspectionAssetId: String!) {
    inspectionTestAsset: createInspectionTestAsset(inspectionAssetId: $inspectionAssetId) {
      ...${InspectionTestAssetModel.fragmentName}
    }
  }
  ${InspectionTestAssetModel.fragment}
`;

export const linkInspectionTestMutation = gql`
  mutation LinkInspectionTest($inspectionAssetId: String!, $testAssetId: String!) {
    inspectionTestAsset: linkInspectionTestAsset(inspectionAssetId: $inspectionAssetId, testAssetId: $testAssetId) {
      ...${InspectionTestAssetModel.fragmentName}
    }
  }
  ${InspectionTestAssetModel.fragment}
`;

export const removeThirdPartyInspectorFromInspectionMutation = gql`
  mutation RemoveThirdPartyInspectorFromInspection($inspectionAssetId: String!, $organisationId: String!) {
    inspectionAsset: removeInspectionAssetInspectionOrganisation(inspectionAssetId: $inspectionAssetId, targetOrganisationId: $organisationId) {
      ...${InspectionAssetModel.fragmentNameThirdPartyInspectors}
    }
  }
  ${InspectionAssetModel.fragmentThirdPartyInspectors}
`;

export const unlinkInspectionTestMutation = gql`
  mutation UnlinkInspectionTest($inspectionAssetId: String!, $testAssetId: String!) {
    inspectionTestAsset: unlinkInspectionTestAsset(inspectionAssetId: $inspectionAssetId, testAssetId: $testAssetId) {
      ...${InspectionTestAssetModel.fragmentName}
    }
  }
  ${InspectionTestAssetModel.fragment}
`;

export const updateOrderOfInspectionTestAssetMutation = gql`
  mutation UpdateOrderOfInspectionTestAsset($inspectionAssetId: String!, $testAssetId: String!, $targetOrder: Int!) {
    inspectionTestAsset: setOrderInspectionTestAsset(inspectionAssetId: $inspectionAssetId, testAssetId: $testAssetId, targetOrder: $targetOrder) {
      ...${InspectionTestAssetModel.fragmentName}
    }
  }
  ${InspectionTestAssetModel.fragment}
`;

export const createInspectionAssetMutation = gql`
  mutation createInspectionAsset {
    newAsset: createInspectionAsset {
      REFID
    }
  }
`;

export const setInspectionNameMutation = gql`
  mutation setInspectionName($inspectionAssetId: String!, $languageCode: ELanguageCode!, $languageText: String!) {
    inspectionAsset: setInspectionName(
      inspectionAssetId: $inspectionAssetId
      languageCode: $languageCode
      languageText: $languageText
    ) {
      id
      name
    }
  }
`;

export const removeInspectionNameMutation = gql`
  mutation removeInspectionName($inspectionAssetId: String!, $languageCode: ELanguageCode!) {
    inspectionAsset: removeInspectionName(inspectionAssetId: $inspectionAssetId, languageCode: $languageCode) {
      id
      name
    }
  }
`;
