import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  indexOfLinkedAssetInCacheList,
  reorderAssetBackwardInList,
  reorderAssetForwardInList,
} from '../graphql/helpers';

import {
  addPartMutation,
  linkProductDocumentMutation,
  linkProductInspectionMutation,
  linkProductMediaMutation,
  linkProductNoteMutation,
  publishProductDocumentMutation,
  publishProductInspectionMutation,
  publishProductNoteMutation,
  removePartMutation,
  reorderProductInspectionBackwardMutation,
  reorderProductInspectionForwardMutation,
  unlinkProductDocumentMutation,
  unlinkProductInspectionMutation,
  unlinkProductMediaMutation,
  unlinkProductNoteMutation,
  unpublishProductDocumentMutation,
  unpublishProductInspectionMutation,
  unpublishProductNoteMutation,
} from '.';

import BaseDocumentModel from 'OK/models/document/base';
import BaseInspectionAssetModel from 'OK/models/inspectionAsset/base';
import MediaAssetModel from 'OK/models/mediaAsset';
import BaseMediaAssetModel from 'OK/models/mediaAsset/base';
import NoteModel from 'OK/models/note';
import BaseNoteModel from 'OK/models/note/base';
import ProductModel from 'OK/models/product';
import ProductNoteModel from 'OK/models/productNote';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';

/* Hooks */

export function useLinkProductDocumentAPI() {
  const [linkProductDocumentAPI] = useMutation(linkProductDocumentMutation);

  const linkProductDocument = useCallback(
    (productId, documentId) => {
      return linkProductDocumentAPI({
        variables: {
          productId,
          documentId,
        },
        update: (cache, result) => {
          if (result.data?.productDocument) {
            // Write DocumentAsset to cache
            const documentAssetRef = cache.writeFragment({
              id: `${BaseDocumentModel.GRAPHQL_TYPE}:${result.data.productDocument.documentAsset.id}`,
              fragment: BaseDocumentModel.fragment,
              fragmentName: BaseDocumentModel.fragmentName,
              data: result.data.productDocument.documentAsset,
            });

            // Add ProductDocumentAsset to product's list
            cache.modify({
              id: `${ProductModel.GRAPHQL_TYPE}:${productId}`,
              fields: {
                productDocumentAssetList: (currentList) => {
                  const productDocumentAsset = {
                    ...result.data.productDocument,
                    documentAsset: documentAssetRef,
                  };
                  return [...currentList, productDocumentAsset];
                },
              },
            });
          }
        },
      });
    },
    [linkProductDocumentAPI]
  );

  return linkProductDocument;
}

export function useLinkProductInspectionAPI() {
  const [linkProductInspectionAPI, linkProductInspectionAPIResult] = useMutation(linkProductInspectionMutation);

  const linkProductInspection = useCallback(
    (productId, inspectionAssetId) => {
      return linkProductInspectionAPI({
        variables: {
          productId,
          inspectionAssetId,
        },
        update: (cache, result) => {
          if (result.data?.productInspectionAsset) {
            // Write InspectionAsset to cache
            const inspectionAssetRef = cache.writeFragment({
              id: `${BaseInspectionAssetModel.GRAPHQL_TYPE}:${result.data.productInspectionAsset.inspectionAsset.id}`,
              fragment: BaseInspectionAssetModel.fragment,
              fragmentName: BaseInspectionAssetModel.fragmentName,
              data: result.data.productInspectionAsset.inspectionAsset,
            });

            // Add ProductInspectionAsset to product's list
            cache.modify({
              id: `${ProductModel.GRAPHQL_TYPE}:${productId}`,
              fields: {
                productInspectionAssetList: (currentList) => {
                  const productInspectionAsset = {
                    ...result.data.productInspectionAsset,
                    inspectionAsset: inspectionAssetRef,
                  };
                  return [...currentList, productInspectionAsset];
                },
              },
            });
          }
        },
      });
    },
    [linkProductInspectionAPI]
  );

  return [linkProductInspection, linkProductInspectionAPIResult];
}

export function useLinkProductMediaAPI() {
  const [linkProductMediaAPI] = useMutation(linkProductMediaMutation);

  const linkProductMedia = useCallback(
    (productId, mediaAssetId) => {
      return linkProductMediaAPI({
        variables: {
          productId,
          mediaAssetId,
        },
        update: (cache, result) => {
          if (result.data?.productMedia) {
            // Write MediaAsset to cache
            const mediaAssetRef = cache.writeFragment({
              id: `${MediaAssetModel.GRAPHQL_TYPE}:${result.data.productMedia.mediaAsset.id}`,
              fragment: BaseMediaAssetModel.fragment,
              fragmentName: BaseMediaAssetModel.fragmentName,
              data: result.data.productMedia.mediaAsset,
            });

            // Add ProductMediaAsset to product's list
            cache.modify({
              id: `${ProductModel.GRAPHQL_TYPE}:${productId}`,
              fields: {
                productMediaAssetList: (currentList) => {
                  const productMediaAsset = {
                    ...result.data.productMedia,
                    mediaAsset: mediaAssetRef,
                  };
                  return [...currentList, productMediaAsset];
                },
              },
            });
          }
        },
      });
    },
    [linkProductMediaAPI]
  );

  return linkProductMedia;
}

export function useLinkProductNoteAPI() {
  const apolloClient = useApolloClient();
  const [linkProductNoteAPI] = useMutation(linkProductNoteMutation);

  const linkProductNote = useCallback(
    (productId, noteAssetId) => {
      const noteAsset = apolloClient.readFragment({
        id: `${BaseNoteModel.GRAPHQL_TYPE}:${noteAssetId}`,
        fragment: BaseNoteModel.fragment,
        fragmentName: BaseNoteModel.fragmentName,
      });
      let optimisticResponse;
      if (noteAsset) {
        optimisticResponse = {
          productNote: {
            noteAsset,
            publishStatus: PUBLISH_STATUS.UNPUBLISHED,
            __typename: ProductNoteModel.GRAPHQL_TYPE,
          },
        };
      }

      return linkProductNoteAPI({
        optimisticResponse,
        variables: {
          productId,
          noteAssetId,
        },
        update: (cache, result) => {
          if (result.data?.productNote) {
            // Write NoteAsset to cache
            const noteAssetRef = cache.writeFragment({
              id: `${BaseNoteModel.GRAPHQL_TYPE}:${result.data.productNote.noteAsset.id}`,
              fragment: BaseNoteModel.fragment,
              fragmentName: BaseNoteModel.fragmentName,
              data: result.data.productNote.noteAsset,
            });

            // Add ProductNoteAsset to product's list
            cache.modify({
              id: `${ProductModel.GRAPHQL_TYPE}:${productId}`,
              fields: {
                productNoteAssetList: (currentList) => {
                  const productNoteAsset = {
                    ...result.data.productNote,
                    noteAsset: noteAssetRef,
                  };
                  return [...currentList, productNoteAsset];
                },
              },
            });
          }
        },
      });
    },
    [apolloClient, linkProductNoteAPI]
  );

  return linkProductNote;
}

export function useLinkPartAPI() {
  const [linkPartAPI, linkPartAPIResult] = useMutation(addPartMutation);

  const linkPart = useCallback(
    (productId, partProductId) => {
      const partType = 'PART';
      return linkPartAPI({
        variables: {
          productId,
          partProductId,
          partType
        },
        update: (cache, result) => {
          cache.modify({
            id: `${ProductModel.GRAPHQL_TYPE}:${productId}`,
            fields: {
              productChildProductList: (currentList) => {
                return [...currentList, result.data.part];
              },
            },
          });
        },
      });
    },
    [linkPartAPI]
  );

  return [linkPart, linkPartAPIResult];
}

export function usePublishProductDocumentAPI(mutationOptions = {}) {
  const [publishDocumentAPI] = useMutation(publishProductDocumentMutation, mutationOptions);

  const publishDocument = useCallback(
    (product, documentId) => {
      // Optimistic response
      const productDocument = product.productDocumentAssetList.find((pd) => pd.documentAsset.id === documentId);
      const optimisticResponse = {
        productDocument: {
          ...productDocument,
          publishStatus: PUBLISH_STATUS.PUBLISHED,
        },
      };

      // Call API
      return publishDocumentAPI({
        variables: {
          documentId,
          productId: product.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.productDocument) {
            cache.modify({
              id: `${ProductModel.GRAPHQL_TYPE}:${product.id}`,
              fields: {
                productDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfProductDocument = indexOfLinkedAssetInCacheList(
                    currentList,
                    result.data.productDocument.documentAsset.id,
                    'documentAsset',
                    readField
                  );
                  const currentProductDocument = updatedList[indexOfProductDocument];
                  const updatedProductDocument = {
                    ...currentProductDocument,
                    publishStatus: result.data.productDocument.publishStatus,
                  };
                  updatedList.splice(indexOfProductDocument, 1, updatedProductDocument);
                  return updatedList;
                },
              },
            });
          } else {
            okerror('Publish document api did not respond with product document.');
          }
        },
      });
    },
    [publishDocumentAPI]
  );

  return publishDocument;
}

export function usePublishProductInspectionAPI() {
  const apolloClient = useApolloClient();
  const [publishProductInspectionAPI] = useMutation(publishProductInspectionMutation);

  const publishProductInspection = useCallback(
    (productId, inspectionAssetId) => {
      const productCacheId = `${ProductModel.GRAPHQL_TYPE}:${productId}`;

      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const product = apolloClient.readFragment({
        id: productCacheId,
        fragment: ProductModel.fragmentEdit,
        fragmentName: ProductModel.fragmentEditName,
      });
      const productInspectionAsset = product?.productInspectionAssetList?.find(
        (pia) => pia.inspectionAsset.id === inspectionAssetId
      );
      if (productInspectionAsset) {
        optimisticResponse = {
          productInspection: {
            ...productInspectionAsset,
            publishStatus: PUBLISH_STATUS.PUBLISHED,
          },
        };
      }

      // Call API
      return publishProductInspectionAPI({
        variables: {
          productId,
          inspectionAssetId,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: productCacheId,
            fields: {
              productInspectionAssetList: (currentList, { readField }) => {
                const indexOfProductInspection = indexOfLinkedAssetInCacheList(
                  currentList,
                  result.data.productInspection.inspectionAsset.id,
                  'inspectionAsset',
                  readField
                );
                const updatedProductInspection = {
                  ...currentList[indexOfProductInspection],
                  publishStatus: result.data.productInspection.publishStatus,
                };
                const updatedList = [...currentList];
                updatedList.splice(indexOfProductInspection, 1, updatedProductInspection);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, publishProductInspectionAPI]
  );

  return publishProductInspection;
}

export function usePublishProductNoteAPI() {
  const [publishProductNoteAPI] = useMutation(publishProductNoteMutation);

  const publishProductNote = useCallback(
    (product, noteId) => {
      // Optimistic response
      const productNote = product.productNoteAssetList.find((pn) => pn.noteAsset.id === noteId);
      const optimisticResponse = {
        productNote: {
          ...productNote,
          publishStatus: PUBLISH_STATUS.PUBLISHED,
        },
      };

      // Call API
      return publishProductNoteAPI({
        variables: {
          noteId,
          productId: product.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data?.productNote) {
            cache.modify({
              id: `${ProductModel.GRAPHQL_TYPE}:${product.id}`,
              fields: {
                productNoteAssetList: (currentList, { readField }) => {
                  const responseProductNote = result.data.productNote;
                  const indexOfProductNote = indexOfLinkedAssetInCacheList(
                    currentList,
                    responseProductNote.noteAsset.id,
                    'noteAsset',
                    readField
                  );
                  if (indexOfProductNote > -1) {
                    const productNote = {
                      ...currentList[indexOfProductNote],
                      publishStatus: responseProductNote.publishStatus,
                    };
                    const updatedList = [...currentList];
                    updatedList.splice(indexOfProductNote, 1, productNote);
                    return updatedList;
                  }

                  okerror(
                    `Product note ${responseProductNote.noteAsset.id} cannot be published on product ${product.id} because it is not linked.`
                  );
                },
              },
            });
          } else {
            okwarn('API did not return published product note');
          }
        },
      });
    },
    [publishProductNoteAPI]
  );

  return publishProductNote;
}

export function useReorderProductInspectionBackwardAPI() {
  const apolloClient = useApolloClient();
  const [reorderProductInspectionBackwardAPI] = useMutation(reorderProductInspectionBackwardMutation);

  const reorderProductInspectionBackward = useCallback(
    (productId, inspectionAssetId) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const product = apolloClient.readFragment({
        id: `${ProductModel.GRAPHQL_TYPE}:${productId}`,
        fragment: ProductModel.fragment,
        fragmentName: ProductModel.fragmentName,
      });
      const productInspectionAsset = product?.productInspectionAssetList?.find(
        (pia) => pia.inspectionAsset.id === inspectionAssetId
      );
      if (productInspectionAsset) {
        optimisticResponse = {
          productInspection: {
            ...productInspectionAsset,
            order: productInspectionAsset.order - 1,
          },
        };
      }

      // Call API
      return reorderProductInspectionBackwardAPI({
        variables: {
          productId,
          inspectionAssetId,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: `${ProductModel.GRAPHQL_TYPE}:${productId}`,
            fields: {
              productInspectionAssetList: (currentList) => {
                return reorderAssetBackwardInList(currentList, result.data.productInspection);
              },
            },
          });
        },
      });
    },
    [apolloClient, reorderProductInspectionBackwardAPI]
  );

  return reorderProductInspectionBackward;
}

export function useReorderProductInspectionForwardAPI() {
  const apolloClient = useApolloClient();
  const [reorderProductInspectionForwardAPI] = useMutation(reorderProductInspectionForwardMutation);

  const reorderProductInspectionForward = useCallback(
    (productId, inspectionAssetId) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const product = apolloClient.readFragment({
        id: `${ProductModel.GRAPHQL_TYPE}:${productId}`,
        fragment: ProductModel.fragment,
        fragmentName: ProductModel.fragmentName,
      });
      const productInspectionAsset = product?.productInspectionAssetList?.find(
        (pia) => pia.inspectionAsset.id === inspectionAssetId
      );
      if (productInspectionAsset) {
        optimisticResponse = {
          productInspection: {
            ...productInspectionAsset,
            order: productInspectionAsset.order + 1,
          },
        };
      }

      // Call API
      return reorderProductInspectionForwardAPI({
        variables: {
          productId,
          inspectionAssetId,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: `${ProductModel.GRAPHQL_TYPE}:${productId}`,
            fields: {
              productInspectionAssetList: (currentList) => {
                return reorderAssetForwardInList(currentList, result.data.productInspection);
              },
            },
          });
        },
      });
    },
    [apolloClient, reorderProductInspectionForwardAPI]
  );

  return reorderProductInspectionForward;
}

export function useRemovePartAPI() {
  const [removePartAPI] = useMutation(removePartMutation);

  const removePart = useCallback(
    (productId, partProductId) => {
      return removePartAPI({
        variables: {
          productId,
          partProductId,
        },
        update: (cache, result) => {
          cache.modify({
            id: `${ProductModel.GRAPHQL_TYPE}:${productId}`,
            fields: {
              productChildProductList: (currentList, { readField }) => {
                const indexOfPart = indexOfLinkedAssetInCacheList(
                  currentList,
                  result.data.part.childProduct.id,
                  'childProduct',
                  readField
                );
                const updatedList = [...currentList];
                updatedList.splice(indexOfPart, 1);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [removePartAPI]
  );

  return removePart;
}

export function useUnlinkProductDocumentAPI() {
  const apolloClient = useApolloClient();
  const [unlinkDocumentAPI] = useMutation(unlinkProductDocumentMutation);

  const unlinkDocument = useCallback(
    (product, documentId) => {
      // Optimistic response
      const documentAsset = apolloClient.readFragment({
        id: `${BaseDocumentModel.GRAPHQL_TYPE}:${documentId}`,
        fragment: BaseDocumentModel.fragment,
        fragmentName: BaseDocumentModel.fragmentName,
      });
      const productDocument = product.productDocumentAssetList.find((pd) => pd.documentAsset.id === documentId);
      const responseProductDocument = {
        ...productDocument,
        documentAsset,
      };
      const optimisticResponse = {
        productDocument: responseProductDocument,
      };

      // Call API
      return unlinkDocumentAPI({
        variables: {
          documentId,
          productId: product.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.productDocument) {
            // Unlink from product's productDocumentAssetList
            cache.modify({
              id: `${ProductModel.GRAPHQL_TYPE}:${product.id}`,
              fields: {
                productDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfProductDocument = indexOfLinkedAssetInCacheList(
                    currentList,
                    result.data.productDocument.documentAsset.id,
                    'documentAsset',
                    readField
                  );
                  updatedList.splice(indexOfProductDocument, 1);
                  return updatedList;
                },
              },
            });

            // Unlink from document's linkedProductList
            cache.modify({
              id: `${BaseDocumentModel.GRAPHQL_TYPE}:${documentId}`,
              fields: {
                linkedProductList: (currentList, { readField }) => {
                  const indexOfProduct = currentList.findIndex((lp) => {
                    const linkedProductId = readField('id', lp);
                    return linkedProductId === product.id;
                  });
                  if (indexOfProduct > -1) {
                    const updatedList = [...currentList];
                    updatedList.splice(indexOfProduct);
                    return updatedList;
                  }

                  return currentList;
                },
              },
            });
          } else {
            okerror('Unlink document api did not respond with product document.');
          }
        },
      });
    },
    [apolloClient, unlinkDocumentAPI]
  );

  return unlinkDocument;
}

export function useUnlinkProductInspectionAPI() {
  const apolloClient = useApolloClient();
  const [unlinkProductInspectionAPI, unlinkProductInspectionAPIResult] = useMutation(unlinkProductInspectionMutation);

  const unlinkProductInspection = useCallback(
    (productId, inspectionAssetId) => {
      const productCacheId = `${ProductModel.GRAPHQL_TYPE}:${productId}`;
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const product = apolloClient.readFragment({
        id: `${ProductModel.GRAPHQL_TYPE}:${productId}`,
        fragment: ProductModel.fragment,
        fragmentName: ProductModel.fragmentName,
      });
      const productInspectionAsset = product?.productInspectionAssetList?.find(
        (pia) => pia.inspectionAsset.id === inspectionAssetId
      );
      if (productInspectionAsset) {
        optimisticResponse = {
          productInspection: productInspectionAsset,
        };
      }

      // Call API
      return unlinkProductInspectionAPI({
        variables: {
          productId,
          inspectionAssetId,
        },
        optimisticResponse,
        update: (cache) => {
          cache.modify({
            id: productCacheId,
            fields: {
              productInspectionAssetList: (currentList, { readField }) => {
                const indexOfProductInspection = indexOfLinkedAssetInCacheList(
                  currentList,
                  inspectionAssetId,
                  'inspectionAsset',
                  readField
                );
                const updatedList = [...currentList];
                updatedList.splice(indexOfProductInspection, 1);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, unlinkProductInspectionAPI]
  );

  return [unlinkProductInspection, unlinkProductInspectionAPIResult];
}

export function useUnlinkProductMediaAPI(mutationOptions) {
  const apolloClient = useApolloClient();
  const [unlinkProductMediaAPI] = useMutation(unlinkProductMediaMutation, mutationOptions);

  const unlinkProductMedia = useCallback(
    (product, mediaAssetId) => {
      // Optimistic response
      const mediaAsset = apolloClient.readFragment({
        id: `${MediaAssetModel.GRAPHQL_TYPE}:${mediaAssetId}`,
        fragment: BaseMediaAssetModel.fragment,
        fragmentName: BaseMediaAssetModel.fragmentName,
      });
      const productMedia = product.productMediaAssetList.find((pm) => pm.mediaAsset.id === mediaAssetId);
      const responseProductMedia = {
        ...productMedia,
        mediaAsset: {
          ...mediaAsset,
        },
      };

      // Also update linked products list if present.
      // This is necessary when unlinking from the Media archive page.
      const mediaAssetWithLinkedProducts = apolloClient.readFragment({
        id: `${MediaAssetModel.GRAPHQL_TYPE}:${mediaAssetId}`,
        fragment: MediaAssetModel.fragment,
        fragmentName: MediaAssetModel.fragmentName,
      });
      if (mediaAssetWithLinkedProducts) {
        const indexOfLinkedProduct = mediaAssetWithLinkedProducts.linkedProductList.findIndex(
          (p) => p.id === product.id
        );
        const updatedLinkedProductList = [...mediaAssetWithLinkedProducts.linkedProductList];
        updatedLinkedProductList.splice(indexOfLinkedProduct, 1);
        responseProductMedia.mediaAsset.linkedProductList = updatedLinkedProductList;
      }

      const optimisticResponse = {
        productMedia: responseProductMedia,
      };

      // Call API
      return unlinkProductMediaAPI({
        variables: {
          mediaAssetId,
          productId: product.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          const unlinkedProductMedia = result.data.productMedia;
          cache.modify({
            id: `${ProductModel.GRAPHQL_TYPE}:${product.id}`,
            fields: {
              productMediaAssetList: (currentList, { readField }) => {
                const indexOfUnlinkedMedia = currentList.findIndex((pma) => {
                  const mediaAsset = readField('mediaAsset', pma);
                  const mediaAssetId = readField('id', mediaAsset);
                  return mediaAssetId === unlinkedProductMedia.mediaAsset.id;
                });
                const updatedList = [...currentList];
                updatedList.splice(indexOfUnlinkedMedia, 1);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, unlinkProductMediaAPI]
  );

  return unlinkProductMedia;
}

export function useUnlinkProductNoteAPI() {
  const [unlinkProductNoteAPI] = useMutation(unlinkProductNoteMutation);

  const unlinkProductNote = useCallback(
    (product, noteId) => {
      // Optimistic response
      const productNote = product.productNoteAssetList.find((pn) => pn.noteAsset.id === noteId);
      const optimisticResponse = {
        productNote,
      };

      // Call API
      return unlinkProductNoteAPI({
        variables: {
          noteId,
          productId: product.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data?.productNote) {
            // Remove from product list
            cache.modify({
              id: `${ProductModel.GRAPHQL_TYPE}:${product.id}`,
              fields: {
                productNoteAssetList: (currentList, { readField }) => {
                  const { productNote } = result.data;
                  const indexOfProductNote = indexOfLinkedAssetInCacheList(
                    currentList,
                    productNote.noteAsset.id,
                    'noteAsset',
                    readField
                  );
                  if (indexOfProductNote > -1) {
                    const updatedList = [...currentList];
                    updatedList.splice(indexOfProductNote, 1);
                    return updatedList;
                  }

                  okerror(
                    `Product note ${productNote.noteAsset.id} cannot be unlinked from product ${product.id} because it is not linked.`
                  );
                },
              },
            });
            cache.modify({
              id: `${NoteModel.GRAPHQL_TYPE}:${noteId}`,
              fields: {
                linkedProductList: (currentList, { readField }) => {
                  const { product } = result.data;
                  const indexOfProductNote = indexOfLinkedAssetInCacheList(
                    currentList,
                    productNote.noteAsset.id,
                    'productAsset',
                    readField
                  );
                  if (indexOfProductNote > -1) {
                    const updatedList = [...currentList];
                    updatedList.splice(indexOfProductNote, 1);
                    return updatedList;
                  }

                  okerror(
                    `Product note ${productNote.noteAsset.id} cannot be unlinked from product ${product.id} because it is not linked.`
                  );
                },
              },
            });
          } else {
            okwarn('API did not return unlinked product note');
          }
        },
      });
    },
    [unlinkProductNoteAPI]
  );

  return unlinkProductNote;
}

export function useUnpublishProductDocumentAPI(mutationOptions) {
  const [unpublishDocumentAPI] = useMutation(unpublishProductDocumentMutation, mutationOptions);

  const unpublishDocument = useCallback(
    (product, documentId) => {
      // Optimistic response
      const productDocument = product.productDocumentAssetList.find((pd) => pd.documentAsset.id === documentId);
      const optimisticResponse = {
        productDocument: {
          ...productDocument,
          publishStatus: PUBLISH_STATUS.UNPUBLISHED,
        },
      };

      // Call API
      return unpublishDocumentAPI({
        variables: {
          documentId,
          productId: product.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.productDocument) {
            cache.modify({
              id: `${ProductModel.GRAPHQL_TYPE}:${product.id}`,
              fields: {
                productDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfProductDocument = indexOfLinkedAssetInCacheList(
                    currentList,
                    result.data.productDocument.documentAsset.id,
                    'documentAsset',
                    readField
                  );
                  const updatedProductDocument = {
                    ...updatedList[indexOfProductDocument],
                    publishStatus: result.data.productDocument.publishStatus,
                  };
                  updatedList.splice(indexOfProductDocument, 1, updatedProductDocument);
                  return updatedList;
                },
              },
            });
          } else {
            okerror('Unpublish document api did not respond with product document.');
          }
        },
      });
    },
    [unpublishDocumentAPI]
  );

  return unpublishDocument;
}

export function useUnpublishProductInspectionAPI() {
  const apolloClient = useApolloClient();
  const [unpublishProductInspectionAPI] = useMutation(unpublishProductInspectionMutation);

  const unpublishProductInspection = useCallback(
    (productId, inspectionAssetId) => {
      const productCacheId = `${ProductModel.GRAPHQL_TYPE}:${productId}`;

      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const product = apolloClient.readFragment({
        id: productCacheId,
        fragment: ProductModel.fragmentEdit,
        fragmentName: ProductModel.fragmentEditName,
      });
      const productInspectionAsset = product?.productInspectionAssetList?.find(
        (pia) => pia.inspectionAsset.id === inspectionAssetId
      );
      if (productInspectionAsset) {
        optimisticResponse = {
          productInspection: {
            ...productInspectionAsset,
            publishStatus: PUBLISH_STATUS.UNPUBLISHED,
          },
        };
      }

      // Call API
      return unpublishProductInspectionAPI({
        variables: {
          productId,
          inspectionAssetId,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: productCacheId,
            fields: {
              productInspectionAssetList: (currentList, { readField }) => {
                const indexOfProductInspection = indexOfLinkedAssetInCacheList(
                  currentList,
                  result.data.productInspection.inspectionAsset.id,
                  'inspectionAsset',
                  readField
                );
                const updatedProductInspection = {
                  ...currentList[indexOfProductInspection],
                  publishStatus: result.data.productInspection.publishStatus,
                };
                const updatedList = [...currentList];
                updatedList.splice(indexOfProductInspection, 1, updatedProductInspection);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, unpublishProductInspectionAPI]
  );

  return unpublishProductInspection;
}

export function useUnpublishProductNoteAPI() {
  const [unpublishProductNoteAPI] = useMutation(unpublishProductNoteMutation);

  const unpublishProductNote = useCallback(
    (product, noteId) => {
      // Optimistic response
      const productNote = product.productNoteAssetList.find((pn) => pn.noteAsset.id === noteId);
      const optimisticResponse = {
        productNote: {
          ...productNote,
          publishStatus: PUBLISH_STATUS.UNPUBLISHED,
        },
      };

      // Call API
      return unpublishProductNoteAPI({
        variables: {
          noteId,
          productId: product.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data?.productNote) {
            cache.modify({
              id: `${ProductModel.GRAPHQL_TYPE}:${product.id}`,
              fields: {
                productNoteAssetList: (currentList, { readField }) => {
                  const responseProductNote = result.data.productNote;
                  const indexOfProductNote = indexOfLinkedAssetInCacheList(
                    currentList,
                    responseProductNote.noteAsset.id,
                    'noteAsset',
                    readField
                  );
                  if (indexOfProductNote > -1) {
                    const productNote = {
                      ...currentList[indexOfProductNote],
                      publishStatus: responseProductNote.publishStatus,
                    };
                    const updatedList = [...currentList];
                    updatedList.splice(indexOfProductNote, 1, productNote);
                    return updatedList;
                  }

                  okerror(
                    `Product note ${responseProductNote.noteAsset.id} cannot be published on product ${product.id} because it is not linked.`
                  );
                },
              },
            });
          } else {
            okwarn('API did not return published product note');
          }
        },
      });
    },
    [unpublishProductNoteAPI]
  );

  return unpublishProductNote;
}
