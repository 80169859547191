import { gql } from '@apollo/client';

import BaseDocumentModel from './document/base';

export default class OrganisationDocumentModel {
  static GRAPHQL_TYPE = 'OrganisationDocumentAsset';

  static fragmentName = 'OrganisationDocument_Fragment';
  static fragment = gql`
    fragment ${OrganisationDocumentModel.fragmentName} on ${OrganisationDocumentModel.GRAPHQL_TYPE} {
      assetLinkStatus
      documentAsset {
        ...${BaseDocumentModel.fragmentName}
      }
      publishStatus
    }
    ${BaseDocumentModel.fragment}
  `;

  static fragmentNamePublishError = 'OrganisationDocument_PublishError_Fragment';
  static fragmentPublishError = gql`
    fragment ${OrganisationDocumentModel.fragmentNamePublishError} on ${OrganisationDocumentModel.GRAPHQL_TYPE} {
      publishError @client
    }
  `;
}
