import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function TestLevelIndicator(props) {
  const { className, level } = props;

  /* Render */

  // Classes
  let classNames = styles.testLevelIndicator;

  // Level
  switch (level) {
    case 'critical':
    case 'major':
    case 'minor':
      classNames = `${classNames} ${styles[level]}`;
      break;
    default:
      break;
  }

  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return <span className={classNames} />;
}

TestLevelIndicator.propTypes = {
  className: PropTypes.string,
  level: PropTypes.oneOf(['critical', 'major', 'minor']).isRequired,
};
