import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import Text from 'OK/components/text';
import Toggle from 'OK/components/toggle';
import { dismissCookiesRequiredAlertAction, setUserPermitsCookies } from 'OK/state/app/actions';
import useI18n from 'OK/util/hooks/useI18n';

export default function CookiesRequiredAlert() {
  const userPermitsCookies = useSelector((state) => state.app.userPermitsCookies);

  const dispatch = useDispatch();
  const { t } = useI18n();

  const dismiss = useCallback(() => {
    dispatch(dismissCookiesRequiredAlertAction());
  }, [dispatch]);

  const toggleCookiePermission = useCallback(
    (permit) => {
      dispatch(setUserPermitsCookies(permit ? '1' : '0'));
    },
    [dispatch]
  );

  return (
    <Alert
      title={t('COOKIES_REQUIRED_ALERT_TITLE')}
      message={t('COOKIES_REQUIRED_ALERT_MESSAGE')}
      buttons={
        <>
          <div className={styles.toggleRow}>
            <Text size='md'>{t('SETTING_THIRD_PARTY_COOKIES')}</Text>
            <Toggle checked={userPermitsCookies === '1'} onChange={toggleCookiePermission} />
          </div>
          <Button block onClick={dismiss} tint='navigation'>
            {t('COOKIES_REQUIRED_ALERT_BUTTON')}
          </Button>
        </>
      }
    />
  );
}
