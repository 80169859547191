import { JWT_TOKEN_EXPIRED } from './auth';

import { convertObjectToQueryParams } from 'OK/networking/utils';
import getStore from 'OK/state/store';
import compressImage from 'OK/util/functions/compressImage';
import simpleMIMEType from 'OK/util/functions/simpleMIMEType';
import SessionManager from 'OK/util/session';

// Variables

const methodsWithBody = ['POST', 'PUT', 'DELETE', 'PATCH'];

// Pre-fetch

/** Add an `Authorization` header to the request with the contents of the session's access token. */
function addAuthorizationHeader(request) {
  if (request.addAuthorisationHeaders) {
    request.debug('Networking middleware - addAuthorizationHeader');
    const { accessToken, activeInspectionLogId, activeOrganisationId } = SessionManager;
    if (accessToken) {
      request.options.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (request.addActiveOrganisationIdHeader && activeOrganisationId) {
      request.options.headers['Authorization-Organization-Id'] = activeOrganisationId;
    }
    if (activeInspectionLogId) {
      request.options.headers['Authorization-Inspection-Log-Id'] = activeInspectionLogId;
    }
  }
}

/**
 * Add parameters to the request.
 *
 * Adds parameters as part of the url for GET requests and as part of the body for other types.
 */
async function addRequestParamaters(request) {
  request.debug('Networking middleware - addRequestParamaters');
  const { method } = request.options;
  if (methodsWithBody.includes(method)) {
    // Add parameters as body
    if (request.contentType === 'multipart/form-data') {
      const formData = new FormData();
      for (const [key, value] of Object.entries(request.parameters)) {
        if (value instanceof File) {
          if (request.compressImages && simpleMIMEType(value.type) === 'image') {
            // Compress images before uploading
            formData.append(key, await compressImage(value), value.name);
          } else {
            formData.append(key, value, value.name);
          }
        } else {
          formData.append(key, value);
        }
      }
      request.options.body = formData;
    } else {
      // Defaul to JSON
      request.options.body = JSON.stringify(request.parameters);
    }
  } else {
    // Add parameters to URL
    const urlParams = convertObjectToQueryParams(request.parameters);
    request.resource = `${request.resource}${urlParams}`;
  }
}

/**
 * Set basic `fetch` options.
 *
 * - Adds Content-Type header if the content type has been specified as JSON.
 * - Enables CORS.
 * - Passes through other options.
 */
function setBasicFetchOptions(request) {
  request.debug('Networking middleware - setBasicFetchOptions');
  const basicOptions = {
    headers: {},
    mode: 'cors',
  };

  switch (request.contentType) {
    case 'application/json':
      basicOptions.headers['Content-Type'] = request.contentType;
      break;
    default:
      break;
  }

  request.options = {
    ...basicOptions,
    ...request.options,
  };
}

async function waitForNetworkConnection() {
  return new Promise((resolve, reject) => {
    // Run request
    const runRequest = async () => {
      resolve();
    };

    if (navigator.onLine) {
      runRequest();
    } else {
      let networkCheckTimeout;

      // Run request and remove network status listeners
      const resumeRequest = () => {
        window.removeEventListener('online', resumeRequest);
        clearTimeout(networkCheckTimeout);
        runRequest();
      };
      // Cancel request
      const cancelRequest = () => {
        window.removeEventListener('online', resumeRequest);
        reject('No network connection.');
      };

      // Wait for network connection to run request
      okdebug('Waiting to run request when network connection resumes...');
      window.addEventListener('online', resumeRequest);

      // Cancel request if no network connection after 10 seconds
      networkCheckTimeout = setTimeout(() => {
        cancelRequest();
      }, 10000);
    }
  });
}

/**
 * Wait for session restoration before proceeding with request.
 *
 * Can be disabled by setting `request.waitWhileRestoringSession` to `false`.
 */
async function waitForSession(request) {
  return new Promise((resolve) => {
    const isRestoringSession = getStore().getState().account.isRestoring;
    if (!isRestoringSession || !request.waitWhileRestoringSession) {
      resolve();
      return;
    }

    const resume = () => {
      okdebug('Session restored, sending request.');
      window.removeEventListener('SESSION_RESTORED', resume);
      resolve();
    };

    okdebug('Waiting for session to be restored before sending request...');
    window.addEventListener('SESSION_RESTORED', resume);
  });
}

const preFetchMiddleware = [
  waitForNetworkConnection,
  waitForSession,
  addRequestParamaters,
  setBasicFetchOptions,
  addAuthorizationHeader,
];

// Post-fetch

/**
 * If the request fails due to an expired JWT token and `autoRefreshToken` is true for the request, automatically
 * refresh the token and retry the request.
 */
async function handleExpiredAccessToken(request) {
  request.debug('Networking middleware - handleExpiredAccessToken');
  if (request.responseData?.message === JWT_TOKEN_EXPIRED && request.autoRefreshToken) {
    if (!request.numberOfRetries) {
      request.debug('Token expired, refreshing session...');
      try {
        await SessionManager.renewSession();
        request.debug('Token renewed successfully. Retrying request..');
        await request.retry();
      } catch {
        okerror('Networking middleware: Could not renew user session. Logging user out...');
        SessionManager.logout();
      }
    } else {
      request.debug('Networking middleware: Token expired but number of retries exceeded. Logging user out...');
      SessionManager.logout();
    }
  }
}

const postFetchMiddleware = [handleExpiredAccessToken];

export { preFetchMiddleware, postFetchMiddleware };
