import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
  ArchiveCardTitle,
} from 'OK/components/archiveCard';
import ArchiveCardManagerOptions from 'OK/components/archiveCard/managerOptions';
import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import LinkToOrganisation from 'OK/components/link/linkToOrganisation';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import { formatOkid } from 'OK/util/formatting';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';

export default function OrganisationArchiveCard(props) {
  /* Variables */

  const {
    cardClassName,
    className,
    linkToOrganisation = true,
    mediaGalleryClassName,
    onClickAcceptInvitation: onClickAcceptInvitationProp,
    onClickApplyToJoin: onClickApplyToJoinProp,
    onClickCancelApplication: onClickCancelApplicationProp,
    onClickLeave: onClickLeaveProp,
    onClickMakeActiveOrganisation: onClickMakeActiveOrganisationProp,
    onClickRejectInvitation: onClickRejectInvitationProp,
    organisation,
    showAccountOptions = false,
    showActiveTag,
    ...otherProps
  } = props;
  const { t } = useI18n();
  const dispatch = useDispatch();
  const { conformityPoint, id, name, OKID, logoImageMediaAsset, reliabilityPointForPublishedLogs } = organisation;
  const [, , currentUser] = useAuthentication(() => false);
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const userOrganisations = currentUser?.organisationList ?? [];
  const isActiveOrganisation = id === activeOrganisationId;
  const isUserOrganisation = userOrganisations?.findIndex((o) => o.id === id) > -1;
  const isOwner = currentUser?.ownedOrganisationList?.findIndex((o) => o.id === id) > -1;
  const isAdmin = currentUser?.managedOrganisationList?.findIndex((o) => o.id === id) > -1;
  const isMember = currentUser?.organisationList?.findIndex((o) => o.id === id) > -1;
  const isCoworker = !isOwner && !isAdmin && isMember;
  const applied = currentUser?.joinRequestOrganisationList?.findIndex((o) => o.id === id) > -1;
  const invited = currentUser?.organisationInviteList?.findIndex((o) => o.id === id) > -1;

  const [isAcceptingInvitation, setIsAcceptingInvitation] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const [isCancellingApplication, setIsCancellingApplication] = useState(false);
  const [isLeavingOrganisation, setIsLeavingOrganisation] = useState(false);
  const [isRejectingInvitation, setIsRejectingInvitation] = useState(false);

  /* Event handlers */

  const onClickAcceptInvitation = useCallback(() => {
    onClickAcceptInvitationProp && onClickAcceptInvitationProp(id, setIsAcceptingInvitation);
  }, [id, onClickAcceptInvitationProp]);

  const onClickApplyToJoin = useCallback(() => {
    onClickApplyToJoinProp && onClickApplyToJoinProp(id, setIsApplying);
  }, [id, onClickApplyToJoinProp]);

  const onClickCancelApplication = useCallback(() => {
    onClickCancelApplicationProp && onClickCancelApplicationProp(id, setIsCancellingApplication);
  }, [id, onClickCancelApplicationProp]);

  const onClickLeave = useCallback(() => {
    onClickLeaveProp && onClickLeaveProp(id, setIsLeavingOrganisation);
  }, [id, onClickLeaveProp]);

  const onClickMakeActiveOrganisation = useCallback(() => {
    onClickMakeActiveOrganisationProp && onClickMakeActiveOrganisationProp(id);
  }, [id, onClickMakeActiveOrganisationProp]);

  const onClickRejectInvitation = useCallback(() => {
    onClickRejectInvitationProp && onClickRejectInvitationProp(id, setIsRejectingInvitation);
  }, [id, onClickRejectInvitationProp]);

  /* Render */

  let cardClassNames = styles.card;
  if (cardClassName) {
    cardClassNames = `${cardClassNames} ${cardClassName}`;
  }

  // Header tag
  const headerTag = useMemo(() => {
    if (isUserOrganisation) {
      return (
        <Tag invert>
          <Icon height={12} inline name={ICONS.ORGANISATION.name} width={12} style={{ top: -1 }} />
        </Tag>
      );
    }

    return null;
  }, [isUserOrganisation]);

  // Detail tag
  const detailTag = useMemo(() => {
    if (isOwner) {
      return (
        <Tag className={styles.tag} invert>
          {t('ROLE_OWNER')}
        </Tag>
      );
    }

    if (isAdmin) {
      return (
        <Tag className={styles.tag} invert>
          {t('ROLE_MANAGER')}
        </Tag>
      );
    }

    if (isCoworker) {
      return (
        <Tag className={styles.tag} invert>
          {t('ROLE_COWORKER')}
        </Tag>
      );
    }

    if (applied) {
      return (
        <Tag className={styles.tag} invert>
          {t('ORGANISATION_STATUS_APPLIED')}
        </Tag>
      );
    }

    return null;
  }, [applied, isAdmin, isCoworker, isOwner, t]);

  const Container = linkToOrganisation ? LinkToOrganisation : 'div';
  const containerProps = { className };
  if (linkToOrganisation) {
    containerProps.organisation = organisation;
    containerProps.onClick = (e) => {
      e.preventDefault();
      history.pushState(
        { isPopup: true, refId: null, dataType: 'ORGANISATION', OKID: organisation.OKID },
        null,
        `/organisation/${organisation.OKID}`
      );
      dispatch(setBrowserHistoryAction(history));
    };
  }

  return (
    <Container {...containerProps}>
      <ArchiveCard className={cardClassNames} innerClassName={styles.innerClassName} {...otherProps}>
        {showAccountOptions && isActiveOrganisation && (
          <Tag className={styles.activeTag} invert size='md' tint='navigation'>
            {t('ACTIVE')}
          </Tag>
        )}
        {showActiveTag && isActiveOrganisation && (
          <Tag className={styles.activeTag} invert size='md' tint='navigation'>
            {t('ACTIVE')}
          </Tag>
        )}
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery
            className={mediaGalleryClassName}
            imageUrl={logoImageMediaAsset?.logoImageURL}
            placeholderIconName={ICONS.ORGANISATION.name}
          />
          <ArchiveCardMainContent>
            <ArchiveCardHeader tag={headerTag} type={t('ORGANISATION')} />
            <ArchiveCardTitle>{name}</ArchiveCardTitle>
            {detailTag}
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow
            conformityGrade={conformityPoint}
            reliabilityGrade={reliabilityPointForPublishedLogs}
            sustainabilityGrade={t('TBC')}
          />
          <ArchiveCardFooterRow okid={formatOkid(OKID)} />
        </ArchiveCardFooter>
        {showAccountOptions && (
          <ArchiveCardManagerOptions className={styles.managerOptions}>
            {isMember && id !== activeOrganisationId && (
              <Button block className={styles.managerButton} onClick={onClickMakeActiveOrganisation} tint='navigation'>
                {t('MAKE_ACTIVE')}
              </Button>
            )}
            {applied && (
              <>
                <Text bold tint='notification'>
                  {t('AWAITING_ORGANISATION_APPROVAL')}
                </Text>
                <Button
                  block
                  className={styles.managerButton}
                  disabled={isCancellingApplication}
                  linkStyle
                  loading={isCancellingApplication}
                  onClick={onClickCancelApplication}
                  tint='alert'
                >
                  {t('CANCEL_ORGANISATION_JOIN_APPLICATION')}
                </Button>
              </>
            )}
            {invited && (
              <>
                <Button
                  block
                  className={styles.managerButton}
                  disabled={isAcceptingInvitation}
                  linkStyle
                  loading={isAcceptingInvitation}
                  onClick={onClickAcceptInvitation}
                  tint='navigation'
                >
                  {t('ACCEPT_ORGANISATION_JOIN_INVITATION')}
                </Button>
                <Button
                  block
                  className={styles.managerButton}
                  disabled={isRejectingInvitation}
                  linkStyle
                  loading={isRejectingInvitation}
                  onClick={onClickRejectInvitation}
                  tint='alert'
                >
                  {t('REJECT_ORGANISATION_JOIN_INVITATION')}
                </Button>
              </>
            )}
            {isMember && (
              <Button
                block
                className={`${styles.managerButton} ${styles.managerButtonLeave}`}
                disabled={isLeavingOrganisation}
                linkStyle
                loading={isLeavingOrganisation}
                onClick={onClickLeave}
                tint='alert'
              >
                {t('LEAVE_ORGANISATION')}
              </Button>
            )}
            {!isMember && !applied && !invited && (
              <Button
                block
                className={styles.managerButton}
                disabled={isApplying}
                loading={isApplying}
                onClick={onClickApplyToJoin}
                tint='navigation'
              >
                {t('APPLY_TO_JOIN_ORGANISATION')}
              </Button>
            )}
          </ArchiveCardManagerOptions>
        )}
      </ArchiveCard>
    </Container>
  );
}

OrganisationArchiveCard.propTypes = {
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  linkToOrganisation: PropTypes.bool,
  mediaGalleryClassName: PropTypes.string,
  onClickAcceptInvitation: PropTypes.func,
  onClickApplyToJoin: PropTypes.func,
  onClickMakeActiveOrganisation: PropTypes.func,
  onClickRejectInvitation: PropTypes.func,
  organisation: PropTypes.any,
  showAccountOptions: PropTypes.bool,
  showActiveTag: PropTypes.bool,
  onClickCancelApplication: PropTypes.func,
  onClickLeave: PropTypes.func,
};
