import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import Notice from 'OK/components/notice';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import Text from 'OK/components/text';
import UserRecognition from 'OK/components/userRecognition';
import UserBadgeModel from 'OK/models/userBadge';
import { formatDate } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function RecognitionPopup(props) {
  const { dismiss, recognition } = props;
  const { locale, t } = useI18n();

  const recognitionId = recognition.userBadge;
  const recognitionHasCertificate = recognition.issueDocument === true;
  const recognitionCertificateIsAvailable = typeof recognition.documentURL === 'string';
  const recognitionCertificateURL = recognition.documentURL;

  let certificateNotice;
  if (recognitionHasCertificate) {
    const notice = (
      <Notice className={styles.certificateNotice} extendSides>
        <Text bold size='md' tint='navigation'>
          {recognitionCertificateIsAvailable ? t('CERTIFICATE') : t('CERTIFICATE_PENDING')}
        </Text>
        {recognitionCertificateIsAvailable && <Icon name={ICONS.DOWNLOAD.name} tint='navigation' />}
      </Notice>
    );
    if (recognitionCertificateIsAvailable) {
      certificateNotice = (
        <a
          download={`${recognitionId}_CERTIFICATE.pdf`}
          href={recognitionCertificateURL}
          rel='noreferrer'
          target='_blank'
        >
          {notice}
        </a>
      );
    } else {
      certificateNotice = notice;
    }
  }

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.container}>
        <div className={styles.header}>
          <h3>{t('RECOGNITION_POPUP_HEADER')}</h3>
          <PopupCloseButton linkStyle>{t('CLOSE')}</PopupCloseButton>
        </div>
        <div className={styles.recognitionDetailsContainer}>
          <UserRecognition className={styles.recognitionImage} recognition={recognition} />
          <div>
            <Text bold className={styles.recognitionName}>
              {UserBadgeModel.badgePublicName(recognitionId, t)}
            </Text>
            <Text>
              <strong>{t('RECOGNITION_FIELD_ISSUED_ON')}:</strong>{' '}
              {formatDate(new Date(recognition.issueDate), locale, { style: 'short' })}
            </Text>
          </div>
        </div>
        <Text className={styles.description}>{UserBadgeModel.badgeDescription(recognitionId, t)}</Text>
        {certificateNotice}
      </PopupContent>
      <PopupButtonsGroup>
        <PopupCloseButton>{t('CLOSE')}</PopupCloseButton>
      </PopupButtonsGroup>
    </Popup>
  );
}

RecognitionPopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
  recognition: PropTypes.shape({
    documentURL: PropTypes.string,
    issueDate: PropTypes.number,
    issueDocument: PropTypes.bool,
    userBadge: PropTypes.string.isRequired,
  }).isRequired,
};
