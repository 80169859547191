import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import Text from 'OK/components/text';
import Toggle from 'OK/components/toggle';
import useI18n from 'OK/util/hooks/useI18n';

export default function ArchiveCardManagerOptions(props) {
  const {
    allowReorderLeft = false,
    allowReorderRight = false,
    children,
    className,
    error,
    onChangePublished,
    onClickReorder,
    onClickUnlink,
    published = false,
    showPublishOption,
    showUnlinkOption,
  } = props;
  const { t } = useI18n();

  let classNames = styles.managerOptions;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <div className={classNames}>
      {showPublishOption && (
        <div className={`${styles.managerOption} ${styles.publishOption}`}>
          <Text bold className={styles.managerOptionLabel}>
            {t('PRODUCT_ARCHIVE_CARD_PUBLISH')}
          </Text>
          <Toggle checked={published} onChange={onChangePublished} />
        </div>
      )}
      {children}
      {showUnlinkOption && (
        <div className={styles.managerOption}>
          <Button block icon={ICONS.UNLINK.name} onClick={onClickUnlink} tint='alert'>
            {t('PRODUCT_ARCHIVE_CARD_UNLINK')}
          </Button>
        </div>
      )}
      {error && (
        <Text className={styles.managerOptionsErrorMessage} size='sm' tint='notification'>
          {error}
        </Text>
      )}
      {(allowReorderLeft || allowReorderRight) && (
        <div className={styles.reorderFooter}>
          {allowReorderLeft && (
            <Button
              className={styles.reorderButton}
              icon={<Icon className={styles.reorderLeftIcon} name={ICONS.REORDER.name} />}
              iconPosition='left'
              linkStyle
              onClick={() => onClickReorder('left')}
            >
              {t('PRODUCT_ARCHIVE_CARD_REORDER_LEFT')}
            </Button>
          )}
          {allowReorderRight && (
            <Button
              className={`${styles.reorderButton} ${styles.reorderButtonRight}`}
              icon={ICONS.REORDER.name}
              linkStyle
              onClick={() => onClickReorder('right')}
            >
              {t('PRODUCT_ARCHIVE_CARD_REORDER_RIGHT')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

ArchiveCardManagerOptions.propTypes = {
  allowReorderLeft: PropTypes.bool,
  allowReorderRight: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.string,
  onChangePublished: PropTypes.func,
  onClickReorder: PropTypes.func,
  onClickUnlink: PropTypes.func,
  published: PropTypes.bool,
  showPublishOption: PropTypes.bool,
  showUnlinkOption: PropTypes.bool,
};
