import { gql } from '@apollo/client';

import ProductModel from 'OK/models/product';

/* Queries */

export const getNoteByRefIdQuery = gql`
  query GetNoteByRefId($refId: String!) {
    noteAsset: noteAssetWithREFID(REFID: $refId) {
      id
      assetAccessPermission
      assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      linkedProductList {
        ...${ProductModel.fragmentNameArchiveCard}
        assetAccessPermission
        assetAccessPermissionList {
          assetAccessPermission
          assetId
          assetType
          id
          sharedToAssetId
          sharedToAssetType
          sharedToOrganisation {
            id
            OKID
            name
            logoImageMediaAsset {
              logoImageURL
            }
          }
          sharedToUser {
            id
            OKID
            avatar {
              sourceDarkURL
              sourceLightURL
            }
          }
        }
        productNoteAssetList {
          noteAsset {
            id
            REFID
          }
          publishStatus
        }
      }
      organisationId
      textContentMap
    }
  }
  ${ProductModel.fragmentArchiveCard}
`;

/* Mutations */

export const deleteNoteMutation = gql`
  mutation DeleteNote($noteId: String!) {
    noteAsset: deleteNoteAsset(noteAssetId: $noteId) {
      id
    }
  }
`;

export const removeNoteTextLanguageMutation = gql`
  mutation RemoveNoteTextLanguage($noteId: String!, $languageIso: ELanguageCode!) {
    note: removeNoteAssetLanguageText(languageCode: $languageIso, noteAssetId: $noteId) {
      id
      textContentMap
    }
  }
`;

export const updateNoteTextMutation = gql`
  mutation UpdateNoteText($noteId: String!, $languageIso: ELanguageCode!, $text: String!) {
    note: setNoteAssetLanguageText(languageCode: $languageIso, languageText: $text, noteAssetId: $noteId) {
      id
      textContentMap
    }
  }
`;

export const createNoteAssetMutation = gql`
  mutation CreateNoteAsset($text: [LanguageTextInput!]!) {
    newNote: createNoteAsset(languageTextInputList: $text) {
      REFID
    }
  }
`;
