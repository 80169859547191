import { gql } from '@apollo/client';

import NoteModel from './note';
import BaseNoteModel from './note/base';

import PUBLISH_STATUS from 'OK/util/enums/publishStatus';

export default class ProductNoteModel {
  static GRAPHQL_TYPE = 'ProductNoteAsset';

  static fragmentName = 'ProductNote_Fragment';
  static fragment = gql`
    fragment ${ProductNoteModel.fragmentName} on ${ProductNoteModel.GRAPHQL_TYPE} {
      noteAsset {
        ...${BaseNoteModel.fragmentName}
      }
      publishStatus
    }
    ${BaseNoteModel.fragment}
  `;

  static mock = () => {
    return {
      noteAsset: NoteModel.mock(),
      publishStatus: PUBLISH_STATUS.UNPUBLISHED,
      __typename: ProductNoteModel.GRAPHQL_TYPE,
    };
  };
}
