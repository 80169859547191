import { gql } from '@apollo/client';

import TestAssetModel from 'OK/models/testAsset';
import TestAssetMediaAssetModel from 'OK/models/testMediaAsset';
import { request } from 'OK/networking';
import { API_URL } from 'OK/networking/utils';
import getAudioMetadataFromFile from 'OK/util/functions/getAudioMetadataFromFile';
import getImageDimensionsFromFile from 'OK/util/functions/getImageDimensionsFromFile';
import getVideoMetadataFromFile from 'OK/util/functions/getVideoMetadataFromFile';

/* Queries */

export const getTestAssetByRefIdQuery = gql`
  query GetTestAssetByRefId($refId: String!) {
    testAsset: testAssetWithREFID(REFID: $refId) {
      ...${TestAssetModel.fragmentName}
    }
  }
  ${TestAssetModel.fragment}
`;

/* Mutations */

export const linkMediaAssetToTestAssetMutation = gql`
  mutation ($testAssetId: String!, $mediaAssetId: String!) {
    testAssetMediaAsset: linkTestAssetMediaAsset(testAssetId: $testAssetId, mediaAssetId: $mediaAssetId) {
      ...${TestAssetMediaAssetModel.fragmentName}
    }
  }
  ${TestAssetMediaAssetModel.fragment}
`;

export const RemoveConditionForTestMutation = gql`
  mutation RemoveConditionForTest($conditionType: ETestAssetConditionType!, $testAssetId: String!) {
    testAsset: removeTestAssetCondition(testAssetConditionType: $conditionType, testAssetId: $testAssetId) {
      ...${TestAssetModel.fragmentNameConditions}
    }
  }
  ${TestAssetModel.fragmentConditions}
`;

export const removeHowToFindItFromTestAssetMutation = gql`
  mutation RemoveHowToFindItOnTest($languageIso: ELanguageCode!, $testAssetId: String!) {
    testAsset: removeTestAssetHowToFindIt(languageCode: $languageIso, testAssetId: $testAssetId) {
      id
      howToFindItMap
    }
  }
`;

export const removeHowToFixItFromTestAssetMutation = gql`
  mutation RemoveHowToFixItOnTest($languageIso: ELanguageCode!, $testAssetId: String!) {
    testAsset: removeTestAssetHowToFixIt(languageCode: $languageIso, testAssetId: $testAssetId) {
      id
      howToFixItMap
    }
  }
`;

export const removeWhatToLookForFromTestAssetMutation = gql`
  mutation RemoveWhatToLookForOnTest($languageIso: ELanguageCode!, $testAssetId: String!) {
    testAsset: removeTestAssetWhatToLookFor(languageCode: $languageIso, testAssetId: $testAssetId) {
      id
      whatToLookForMap
    }
  }
`;

export const reorderTestAssetMediaAssetBackwardMutation = gql`
  mutation ReorderTestAssetMediaAssetBackward($testAssetId: String!, $mediaAssetId: String!) {
    testAssetMediaAsset: decreaseOrderTestAssetMediaAsset(testAssetId: $testAssetId, mediaAssetId: $mediaAssetId) {
      ...${TestAssetMediaAssetModel.fragmentName}
    }
  }
  ${TestAssetMediaAssetModel.fragment}
`;

export const reorderTestAssetMediaAssetForwardMutation = gql`
  mutation ReorderTestAssetMediaAssetForward($testAssetId: String!, $mediaAssetId: String!) {
    testAssetMediaAsset: increaseOrderTestAssetMediaAsset(testAssetId: $testAssetId, mediaAssetId: $mediaAssetId) {
      ...${TestAssetMediaAssetModel.fragmentName}
    }
  }
  ${TestAssetMediaAssetModel.fragment}
`;

export const SetConditionForTestMutation = gql`
  mutation SetConditionForTest(
    $conditionType: ETestAssetConditionType!
    $conditionValue: String!
    $testAssetId: String!
  ) {
    testAsset: addOrUpdateTestAssetCondition(
      testAssetConditionType: $conditionType
      testAssetConditionValue: $conditionValue
      testAssetId: $testAssetId
    ) {
      ...${TestAssetModel.fragmentNameConditions}
    }
  }
  ${TestAssetModel.fragmentConditions}
`;

export const setHowToFindItOnTestAssetMutation = gql`
  mutation SetHowToFindItOnTestAsset($languageIso: ELanguageCode!, $text: String!, $testAssetId: String!) {
    testAsset: setTestAssetHowToFindIt(languageCode: $languageIso, languageText: $text, testAssetId: $testAssetId) {
      id
      howToFindItMap
    }
  }
`;

export const setHowToFixItOnTestAssetMutation = gql`
  mutation SetHowToFixItOnTestAsset($languageIso: ELanguageCode!, $text: String!, $testAssetId: String!) {
    testAsset: setTestAssetHowToFixIt(languageCode: $languageIso, languageText: $text, testAssetId: $testAssetId) {
      id
      howToFixItMap
    }
  }
`;

export const SetIdentifiersEnabledForTestMutation = gql`
  mutation SetIdentifiersEnabledForTest($enableIdentifiers: Boolean!, $testAssetId: String!) {
    testAsset: setTestAssetEnableIdentifiers(enableIdentifiers: $enableIdentifiers, testAssetId: $testAssetId) {
      id
      enableIdentifiers
    }
  }
`;

export const SetNotesEnabledForTestMutation = gql`
  mutation SetNotesEnabledForTest($enableNotes: Boolean!, $testAssetId: String!) {
    testAsset: setTestAssetEnableNotes(enableNotes: $enableNotes, testAssetId: $testAssetId) {
      id
      enableNotes
    }
  }
`;

export const setSeverityLevelForTestMutation = gql`
  mutation SetSeverityLevelForTest($testAssetId: String!, $severityLevel: ETestSeverityLevel!) {
    testAsset: setTestSeverityLevel(testAssetId: $testAssetId, testSeverityLevel: $severityLevel) {
      id
      testSeverityLevel
    }
  }
`;

export const SetTimeTrackingEnabledForTestMutation = gql`
  mutation SetTimeTrackingEnabledForTest($enableTimeTracking: Boolean!, $testAssetId: String!) {
    testAsset: setTestAssetEnableTimeTracking(enableTimeTracking: $enableTimeTracking, testAssetId: $testAssetId) {
      id
      enableTimeTracking
    }
  }
`;

export const setWhatToLookForOnTestAssetMutation = gql`
  mutation SetWhatToLookForOnTestAsset($languageIso: ELanguageCode!, $text: String!, $testAssetId: String!) {
    testAsset: setTestAssetWhatToLookFor(languageCode: $languageIso, languageText: $text, testAssetId: $testAssetId) {
      id
      whatToLookForMap
    }
  }
`;

export const unlinkTestAssetMediaAssetMutation = gql`
  mutation UnlinkTestAssetMediaAsset($testAssetId: String!, $mediaAssetId: String!) {
    testAssetMediaAsset: unlinkTestAssetMediaAsset(mediaAssetId: $mediaAssetId, testAssetId: $testAssetId) {
      ...${TestAssetMediaAssetModel.fragmentName}
    }
  }
  ${TestAssetMediaAssetModel.fragment}
`;

/* Requests */

export async function createTestAssetAudioMediaAssetRequest(testAssetId, audioFile) {
  const { duration } = await getAudioMetadataFromFile(audioFile);
  const metadata = {
    duration,
    filesize: audioFile.size,
    mimeType: audioFile.type,
  };
  const parameters = {
    testAssetId,
    audio: audioFile,
    metadataString: JSON.stringify(metadata),
  };
  return request(`${API_URL}/utility/media/createTestAudioMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createTestAssetImageMediaAssetRequest(testAssetId, imageFile, originalImageFile) {
  const { height, width } = await getImageDimensionsFromFile(imageFile);
  const metadata = {
    filesize: imageFile.size,
    height,
    mimeType: imageFile.type,
    width,
  };
  const parameters = {
    testAssetId,
    image: imageFile,
    baseImage: originalImageFile,
    metadataString: JSON.stringify(metadata),
  };

  return request(`${API_URL}/utility/media/createTestImageMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createTestAssetVideoMediaAssetRequest(testAssetId, videoFile) {
  const { duration, height, width } = await getVideoMetadataFromFile(videoFile);
  const metadata = {
    duration,
    filesize: videoFile.size,
    height,
    mimeType: videoFile.type,
    width,
  };
  const parameters = {
    testAssetId,
    video: videoFile,
    metadataString: JSON.stringify(metadata),
  };
  return request(`${API_URL}/utility/media/createTestVideoMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}
