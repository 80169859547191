import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUserQuery } from 'OK/networking/users';
import { setLoginRedirectUrlAction } from 'OK/state/account/actions';
import { showAuthModal } from 'OK/state/app/actions';
import isPlaceholderPath from 'OK/util/functions/isPlaceholderPath';
import useBrowserRenderOnly from 'OK/util/hooks/useBrowserRenderOnly';
import { r } from 'OK/util/routing';

/**
 * A React hook that returns the user's authentication status.
 *
 * This should be placed at the top of any pages which require authentication. The `authenticated` return value can be
 * used to conditionally render any sensitive content. At the moment, the user will never be authenticated on the
 * server, so no sensitive content will ever be sent to the client in SSR or SSG.
 *
 * @param {(authenticated: boolean, user: object?) => boolean} [onAuthStateDetermined] A callback function which runs
 * after the user's authenticated state has been determined. Use this function to do any custom handling based on the
 * user's authenticated state or role. The user will be redirected to login when not authenticated unless this function
 * returns `false`.
 *
 * @returns {[boolean, boolean, object]} [`authenticated`, `isRestoring`, `user`]
 */
export default function useAuthentication(onAuthStateDetermined) {
  const accountState = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const router = useRouter();
  const browserRenderReady = useBrowserRenderOnly();
  const { authenticated, isRestoring: isRestoringAccountState } = accountState;
  const getUserAPIResult = useQuery(getCurrentUserQuery, { skip: !authenticated });
  const user = getUserAPIResult.data?.user;

  const isRestoring =
    isRestoringAccountState || getUserAPIResult.loading || (authenticated && !getUserAPIResult.called);

  useEffect(() => {
    const redirect = async () => {
      // Store current url to redirect the user back after login.
      if (!isPlaceholderPath(router.asPath) && router.asPath !== '/auth' && router.asPath !== '/account') {
        dispatch(setLoginRedirectUrlAction(router.asPath));
      }
      dispatch(showAuthModal(true));
    };

    if (!browserRenderReady) {
      // Don't trigger redirect logic until after initial render. This is necessary because isRestoring is not set yet.
      return;
    }

    if (!isRestoring) {
      let redirectIfNotAuthenticated;

      if (onAuthStateDetermined) {
        redirectIfNotAuthenticated = onAuthStateDetermined(authenticated, user) !== false;
      }

      if (!authenticated && redirectIfNotAuthenticated) {
        redirect();
      }
    }
  }, [authenticated, browserRenderReady, dispatch, isRestoring, onAuthStateDetermined, router, user]);

  return [authenticated, isRestoring, user];
}
