import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Input from 'OK/components/input';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import Text from 'OK/components/text';
import { CreateSiteMutation } from 'OK/networking/sites';
import useI18n from 'OK/util/hooks/useI18n';
import { I18n } from 'OK/util/i18n';

export default function NewSitePopup(props) {
  /* Variables */

  const { dismiss, onCreateSite, onCreateSiteAndLink, link } = props;
  const { t } = useI18n();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const [createSite, createSiteResult] = useMutation(CreateSiteMutation, {
    onCompleted: (data) => {
      if (data?.site?.id && onCreateSite) {
        onCreateSite(data.site);
      }

      dismiss();
    },
  });

  /* State */

  const [internalId, setInternalId] = useState('');

  /* Methods */

  const create = () => {
    createSite({ variables: { identifier: internalId } });
  };

  const createAndLink = () => {
    onCreateSiteAndLink(internalId);
  };

  /* Events */

  function onInternalIdChange(e) {
    setInternalId(e.target.value);
  }

  /* Render */

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.container}>
        <div className={styles.headerRow}>
          <h3 className={styles.header}>{t('CREATE_SITE_POPUP_HEADER')}</h3>
          <PopupCloseButton className={styles.cancelButton} linkStyle tint='alert'>
            {t('CANCEL')}
          </PopupCloseButton>
          {useDesktopLayout && internalId && (
            <Button
              className={styles.headerCreateButton}
              loading={createSiteResult.loading}
              onClick={link ? createAndLink : create}
              tint='creation'
            >
              {t('CREATE_SITE_POPUP_CREATE_BUTTON')}
            </Button>
          )}
        </div>
        <h4>
          <I18n i18nKey='SITE_FIELD_IDENTIFIER_LABEL_ADMIN'>
            Identifier <span className={styles.unbold}>(optional)</span>
          </I18n>
        </h4>
        <Text className={styles.instructions}>{t('SITE_FIELD_IDENTIFIER_GUIDANCE_ADMIN')}</Text>
        <Text bold className={styles.label}>
          {t('SITE_FIELD_INTERNAL_ID_LABEL')}
        </Text>
        <Input
          className={styles.skuInput}
          onChange={onInternalIdChange}
          placeholder={t('SITE_FIELD_INTERNAL_ID_PLACEHOLDER')}
          value={internalId}
        />
      </PopupContent>
      <PopupButtonsGroup>
        <PopupCloseButton tint='alert'>{t('CANCEL')}</PopupCloseButton>
        {internalId && (
          <Button
            loading={createSiteResult.loading}
            onClick={link ? () => onCreateSiteAndLink(internalId) : create}
            tint='creation'
          >
            {t('CREATE_SITE_POPUP_CREATE_BUTTON')}
          </Button>
        )}
      </PopupButtonsGroup>
    </Popup>
  );
}

NewSitePopup.propTypes = {
  onCreateSite: PropTypes.func,
  onCreateSiteAndLink: PropTypes.func,
  dismiss: PropTypes.func,
  link: PropTypes.bool,
};
