import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import styles from './styles.module.scss';

import AutoHeightAdjustingPopup from 'OK/components/popup/autoHeightAdjusting';
import WebinarRegistration from 'OK/modules/popups/webinar/register';
import WebinarRegistrationComplete from 'OK/modules/popups/webinar/registrationComplete';
import { setComingFromTrial, showTryForFree } from 'OK/state/account/actions';
import { showAuthModal as show } from 'OK/state/app/actions';
import getStore from 'OK/state/store';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import { removeURLQueryParam } from 'OK/util/routing';

const STEP = {
  REGISTER: 'REGISTER',
  REGISTRATION_COMPLETE: 'REGISTRATION_COMPLETE',
};

export default function WebinarPopup(props) {
  /* Variables */

  const { webinarId } = props;
  const [, , currentUser] = useAuthentication(() => false);

  /* State */

  const [email, setEmail] = useState(null);
  const [step, setStep] = useState(STEP.REGISTER);

  /* Methods */

  const dismiss = useCallback(() => {
    removeURLQueryParam('webinarId', { scroll: false });
  }, []);

  /* Event handlers */

  const onClickRegisterAgain = useCallback(() => {
    setEmail(null);
    setStep(STEP.REGISTER);
  }, []);

  const onRegister = useCallback((registeredEmail) => {
    setEmail(registeredEmail);
    setStep(STEP.REGISTRATION_COMPLETE);
  }, []);

  const store = getStore();

  const onClickTrial = useCallback(() => {
    dismiss();

    setTimeout(() => {
      setTimeout(() => {
        store.dispatch(show(true));
      }, 10);

      store.dispatch(setComingFromTrial(true));
      if (currentUser) {
        store.dispatch(showTryForFree(true));
      }
    }, 100);
  }, [currentUser, dismiss, store]);

  /* Render */

  let ui;
  if (step === STEP.REGISTRATION_COMPLETE) {
    ui = (
      <WebinarRegistrationComplete
        email={email}
        onClickRegisterAgain={onClickRegisterAgain}
        onClickTrial={onClickTrial}
      />
    );
  } else {
    ui = <WebinarRegistration onRegister={onRegister} webinarId={webinarId} />;
  }

  return (
    <AutoHeightAdjustingPopup className={styles.popup} childrenKey={step} dismiss={dismiss}>
      {ui}
    </AutoHeightAdjustingPopup>
  );
}

WebinarPopup.propTypes = {
  webinarId: PropTypes.string.isRequired,
};
