import { ApolloProvider } from '@apollo/client';
import { differenceInDays } from 'date-fns';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router, { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import PopupInterface from 'OK/components/popupUI/popup';
import Text from 'OK/components/text';
import appConfig from 'OK/config/app';
import CookieAlert from 'OK/modules/alerts/cookie';
import CookiesRequiredAlert from 'OK/modules/alerts/cookiesRequired';
import AppMenus from 'OK/modules/appMenus';
import ScanResultsPopup from 'OK/modules/popups/scanResults';
import WebinarPopup from 'OK/modules/popups/webinar';
import OKRouter from 'OK/modules/router';
import { preload as ScanPreload } from 'OK/modules/scan';
import GraphQLClient from 'OK/networking/graphql/client';
import { resetCreatedOrganisationFlag } from 'OK/state/account/actions';
import { resetScannedOKIDAction, updateMiscAppStateAction } from 'OK/state/app/actions';
import { themeLight, themeDark, baseTheme } from 'OK/styles/theme';
import { breakpoints } from 'OK/styles/util';
import { initAnalytics, resetAnalyticsSession, trackError, trackRouteChange } from 'OK/util/analytics';
import { LAST_DISMISSED_PWA_BANNER_DATE, TIMING_DELAY_SHORT_MS } from 'OK/util/constants';
import InternationalizationContext from 'OK/util/context/internationalization';
import ThemeContext from 'OK/util/context/theme';
import { getCookie, setCookie } from 'OK/util/cookie';
import { PERSISTENT_STORAGE_KEY } from 'OK/util/enums/persistentStorageKeys';
import withI18n from 'OK/util/hoc/withI18n';
import { I18nStyles } from 'OK/util/i18n';
import SessionManager from 'OK/util/session';
import { getPersistentValue, setPersistentValue } from 'OK/util/storage';

// Polyfills
if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  if (!('IntersectionObserver' in window)) {
    // Polyfill IntersectionObserver
    require('intersection-observer');
  }

  if (!('ResizeObserver' in window)) {
    okdebug('polyfilling ResizeObserver');
    // Polyfill ResizeObserver
    window.ResizeObserver = require('resize-observer-polyfill/dist/ResizeObserver.global');
  }

  if (!('scrollBehavior' in document.documentElement.style)) {
    // Polyfill scrolling with behavior: 'smooth'
    const smoothScrollPolyfill = require('smoothscroll-polyfill');
    smoothScrollPolyfill.polyfill();
  }
}

// Dynamic imports
const FadeTransition = dynamic(() => import('OK/components/transitions/fadeUp'));
const Notification = dynamic(() => import('OK/components/notification'));
const Scan = dynamic(() => import('OK/modules/scan'));

// eslint-disable-next-line quotes
const intercomSetup = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/p1814irl';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

// eslint-disable-next-line quotes
const claritySetup = `(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "bx66f25fio");`;

class AppContainer extends Component {
  static getDerivedStateFromProps(props) {
    const { appState, screenSize } = props;

    const defaultContentWidth = getDefaultContentWidthForScreenWidth(screenSize.width);
    const [useDesktopLayout, useMobileLayout] = layoutFlagsForScreenSize(screenSize);

    return {
      defaultContentWidth,
      renderCookieAlert: appState.userPermitsCookies === null,
      useDesktopLayout,
      useMobileLayout,
    };
  }

  /* Lifecycle */

  constructor(props) {
    super(props);

    const { isBrowser, isInstalledAsPWA, isNavigatingBack, isServer, screenSize, userAgent } = props;
    const { isOnline } = props.appState;

    this.state = {
      isShowingPWABanner: false,
      locale: props.router.locale.toUpperCase(),
      region: 'HKG',
      statusBannerMessage: null,
      statusBannerTint: null,
      theme: 'light',
      topMenuYPosition: 0,
    };

    const [useDesktopLayout, useMobileLayout] = layoutFlagsForScreenSize(screenSize);

    // App context
    const appContext = {
      defaultContentWidth: getDefaultContentWidthForScreenWidth(screenSize.width),
      isBrowser,
      isInstalledAsPWA,
      isNavigatingBack,
      isOnline,
      isServer,
      screenSize,
      useDesktopLayout,
      useMobileLayout,
      userAgent,
    };

    props.dispatch(updateMiscAppStateAction(appContext));

    this.popupContainerRef = createRef();
  }

  componentDidMount() {
    const { appState, locale, preferences } = this.props;
    let { appearanceMode, region, language } = preferences;

    SessionManager.restoreSession();

    // Preload essential components for quick / offline use
    FadeTransition.render.preload();
    Notification.render.preload();
    Scan.render.preload();

    // Check whether we need to show the PWA installation banner after a short delay. The delay is necessary because
    // it takes a short amount of time for the browser to detect that the site is installable, so we don't want to
    // encourage the user to install before that is possible. It would be better to wait for a 'beforeinstallprompt'
    // event, but currently that's only available on Android / Chrome.
    this.showInstallPWABannerIfNeeded(TIMING_DELAY_SHORT_MS);

    // Set initial theme, region and language based on preference. If any change is triggered, it will re-render but before the browser
    // has done it's initial paint, so there will be no flicker. See
    // https://reactjs.org/docs/react-component.html#componentdidmount.
    this.syncThemeWithPreference(appearanceMode);
    this.syncRegionWithPreference(region);
    this.syncLocaleWithPreference(language);

    // Listen for theme changes
    try {
      // Browsers supporting current API
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.handleSystemThemeChange);
    } catch {
      try {
        // Browsers supporting older API (Safari)
        window.matchMedia('(prefers-color-scheme: dark)').addListener(this.handleSystemThemeChange);
      } catch (error) {
        okerror('Error listening for changes to color scheme.', error);
        trackError(error);
      }
    }

    this.setupPWAUpdateListener();

    Router.events.on('routeChangeComplete', this.handleRouteChange);

    if (appState.userPermitsCookies === '1') {
      setTimeout(() => {
        initAnalytics(locale);
      }, 500);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { appState, locale, preferences } = this.props;
    const { appearanceMode, region, language } = preferences;
    const { isOnline } = appState;

    // Handle network status changes
    if (isOnline !== prevProps.appState.isOnline) {
      this.showNetworkStatusBanner(isOnline);
    }

    if (prevProps.preferences.appearanceMode !== appearanceMode) {
      // Ensure theme is kept in-sync with user's preference
      this.syncThemeWithPreference(appearanceMode);
    }

    if (prevProps.preferences.region !== region) {
      // Ensure region is kept in-sync with user's preference
      this.syncRegionWithPreference(region);
    }

    if (prevProps.preferences.language !== language || this.state.locale !== language) {
      // Ensure language is kept in-sync with user's preference
      this.syncLocaleWithPreference(language);
    }

    if (
      prevProps.screenSize.height !== this.props.screenSize.height ||
      prevProps.screenSize.width !== this.props.screenSize.width ||
      prevState.defaultContentWidth !== this.state.defaultContentWidth ||
      prevState.useDesktopLayout !== this.state.useDesktopLayout ||
      prevState.useMobileLayout !== this.state.useMobileLayout
    ) {
      this.props.dispatch(
        updateMiscAppStateAction({
          defaultContentWidth: this.state.defaultContentWidth,
          screenSize: this.props.screenSize,
          useMobileLayout: this.state.useMobileLayout,
          useDesktopLayout: this.state.useDesktopLayout,
        })
      );
    }

    // Show PWA installation banner after user logs in
    if (!prevProps.appState.isLoggedIn && appState.isLoggedIn) {
      this.showInstallPWABannerIfNeeded();
    }

    if (prevProps.appState.userPermitsCookies !== appState.userPermitsCookies) {
      if (appState.userPermitsCookies === '1') {
        setTimeout(() => {
          initAnalytics(locale);
        }, 500);
      } else {
        setTimeout(() => {
          resetAnalyticsSession();
        }, 500);
      }
    }
  }

  componentWillUnmount() {
    try {
      // Browsers supporting current API
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.handleSystemThemeChange);
    } catch {
      try {
        // Browsers supporting older API (Safari)
        window.matchMedia('(prefers-color-scheme: dark)').removeListener(this.handleSystemThemeChange);
      } catch (error) {
        okerror('Error unlistening to changes to color scheme.', error);
        trackError(error);
      }
    }

    Router.events.off('routeChangeComplete', this.handleRouteChange);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const updatedPropKeys = [];
    const updatedStateKeys = [];

    Object.keys(nextProps).forEach((key) => {
      if (this.props[key] !== nextProps[key]) {
        updatedPropKeys.push(key);
      }
    });

    Object.keys(nextState).forEach((key) => {
      if (this.state[key] !== nextState[key]) {
        updatedStateKeys.push(key);
      }
    });

    // if (updatedStateKeys.length === 0 && updatedPropKeys.length === 2) {
    //   if (updatedPropKeys.includes('appState') && updatedPropKeys.includes('preferences')) {
    //     if (
    //       nextProps.appState.notifications === this.props.appState.notifications &&
    //       nextProps.appState.scanFunction === this.props.appState.scanFunction &&
    //       nextProps.appState.scanInstructions === this.props.appState.scanInstructions &&
    //       nextProps.appState.scanInstructionsLegacy === this.props.appState.scanInstructionsLegacy &&
    //       nextProps.appState.showScanner === this.props.appState.showScanner &&
    //       nextProps.preferences.appearanceMode === this.props.preferences.appearanceMode &&
    //       nextProps.preferences.region === this.props.preferences.region &&
    //       nextProps.preferences.language === this.props.preferences.language
    //     ) {
    //       // Ignore update because it makes no changes.
    //       okdebug('ignoring update', updatedPropKeys, updatedStateKeys);
    //       return false;
    //     }
    //   }
    // }

    return true;
  }

  /* Methods */

  /**
   * Dismiss the status banner.
   *
   * When on mobile, it will instead display the PWA installation banner if not already installed. Pass `true` to
   * disable this behavior and ensure the status banner is dismissed.
   *
   * @param {boolean} [force=false] Dismiss the banner without checking if the PWA installation banner should be shown.
   */
  dismissStatusBanner = async (force = false) => {
    // Show the PWA banner if necessary instead of clearing, unless `force` is true
    let showedInstallPWABanner = false;
    if (!force) {
      showedInstallPWABanner = await this.showInstallPWABannerIfNeeded();
    }

    // Dismiss the banner if the PWA installation banner was not shown
    if (!showedInstallPWABanner) {
      if (this.state.isShowingPWABanner) {
        // Remember the date of the user's decision to dismiss the banner
        setPersistentValue(LAST_DISMISSED_PWA_BANNER_DATE, new Date());
      }

      this.setState({ isShowingPWABanner: false });
      this.props.dispatch(updateMiscAppStateAction({ isShowingPWABanner: false }));
      this.props.dispatch(updateMiscAppStateAction({ isShowingStatusBanner: false }));
    }
  };

  handleRouteChange = (routeUrl) => {
    okdebug('route changed', routeUrl);
    if (this.props.appState.scannedOKID) {
      // Clear scanned OKID when user navigates to a different page
      this.props.dispatch(resetScannedOKIDAction());
    }
    if (this.props.appState.createdOrganisation) {
      // Reset createdOrganisation flag when navigating away from /onboarding
      if (!/\/account\/onboarding/.test(routeUrl)) {
        this.props.dispatch(resetCreatedOrganisationFlag());
      }
    }

    trackRouteChange(routeUrl);
  };

  /**
   * Set window.__OK_BROWSER_THEME__ to keep track of browser theme, and sync theme with browser if appearanceMode is
   * 'auto'.
   */
  handleSystemThemeChange = (e) => {
    window.__OK_BROWSER_THEME__ = e.matches ? 'dark' : 'light';

    const { appearanceMode } = this.props.preferences;
    if (appearanceMode === 'auto') {
      // Sync theme with system
      this.syncThemeWithPreference();
    }
  };

  setStatusBanner = (message, tint) => {
    this.setState({
      statusBannerMessage: message,
      statusBannerTint: tint,
    });
    this.props.dispatch(updateMiscAppStateAction({ isShowingStatusBanner: true }));
  };

  /** Listen for updates to the service worker and notify the user about installation (if slow). */
  setupPWAUpdateListener() {
    // const { dispatch } = this.props;
    navigator.serviceWorker?.ready.then(function (swr) {
      // Listen for an updated service worker
      swr.addEventListener('updatefound', () => {
        const newServiceWorker = swr.installing;
        okdebug('Installing new service worker...');

        /* // Notify the user about the installation if it takes longer than 5 seconds
        let notifiedAboutUpdate = false;
        const notifyAboutUpdate = () => {
          notifiedAboutUpdate = true;
          dispatch(showNotificationAction(t('OK_GRADE_UPDATING'), t('NEW_VERSION_INSTALLING')));
        };
        const updateFoundNotificationTimeout = setTimeout(notifyAboutUpdate, 5000);

        // During update, show a confirmation dialog before letting the user close the window
        const beforeUnloadHandler = (e) => {
          if (!notifiedAboutUpdate) {
            clearTimeout(updateFoundNotificationTimeout);
            notifyAboutUpdate();
          }

          e.preventDefault();
          const message = t('NEW_VERSION_INSTALLING');
          e.returnValue = message;
          return message;
        };
        window.addEventListener('beforeunload', beforeUnloadHandler); */

        // Listen for installation status changes
        newServiceWorker.addEventListener('statechange', (e) => {
          okdebug('New service worker state:', e.target.state);
          if (e.target.state === 'activated') {
            okdebug('New service worker installed and activated.');
            /* // No need to notify the user about the update if it finished quickly, so clear the timeout in case it
            // hasn't fired yet
            clearTimeout(updateFoundNotificationTimeout);

            // If we did notify the user about the installation, notify them that it is now complete
            if (notifiedAboutUpdate) {
              dispatch(showNotificationAction(t('OK_GRADE_UPDATED'), t('NEW_VERSION_INSTALLED')));
            }

            // After update, remove confirmation dialog for closing the window
            window.removeEventListener('beforeunload', beforeUnloadHandler); */
          }
        });
      });
    });
  }

  showInstallPWABanner = (delay = 0) =>
    new Promise((resolve) => {
      const { browser, os } = this.props.userAgent;
      let bannerMessage, bannerTint;
      if (os.name === 'Android') {
        switch (browser.name) {
          case 'Chrome':
            bannerMessage = this.props.tHTML('PROMOTE_PWA_INSTALLATION_BANNER_ANDROID_CHROME', {
              data: {
                icon1: <Icon inline name={ICONS.MENU_ANDROID.name} style={{ margin: '0 3px 0 4px' }} />,
              },
              returnAsHTML: true,
            });
            break;
          default:
            bannerMessage = this.props.t('PROMOTE_PWA_INSTALLATION_BANNER_ANDROID');
            break;
        }
      } else if (os.name === 'iOS') {
        switch (browser.name) {
          case 'Mobile Safari':
            bannerMessage = this.props.tHTML('PROMOTE_PWA_INSTALLATION_BANNER_IOS_SAFARI', {
              data: {
                icon1: <Icon inline name={ICONS.SHARE_IOS.name} />,
                icon2: <Icon inline name={ICONS.ADD_TO_HOMESCREEN_IOS.name} />,
              },
              returnAsHTML: true,
            });
            bannerTint = 'navigation';
            break;
          default:
            bannerMessage = this.props.t('PROMOTE_PWA_INSTALLATION_BANNER_IOS');
            break;
        }
      }

      if (bannerMessage) {
        setTimeout(() => {
          this.setStatusBanner(bannerMessage, bannerTint);
          this.setState({ isShowingPWABanner: true });
          this.props.dispatch(updateMiscAppStateAction({ isShowingPWABanner: true }));
          resolve(true);
        }, delay);
      } else {
        okwarn('Could not identify the OS & browser, so not showing PWA banner.');
        resolve(false);
      }
    });

  /**
   * Check if the PWA installation banner should be shown and, if so, show it. The criteria for showing the banner are:
   * - The user is on a mobile device.
   * - The user has not already installed the app as a PWA.
   *
   * @param {number} delay Delay (in ms) to show the banner after.
   *
   * @returns {boolean} Whether the PWA banner was shown.
   */
  showInstallPWABannerIfNeeded = async (delay = 0) => {
    const { isLoggedIn } = this.props.appState;

    if (appConfig.features.pwaBanner && isLoggedIn) {
      if (this.state.isShowingPWABanner) {
        // Already showing the PWA banner
        return true;
      }

      const lastDismissedDateString = getPersistentValue(LAST_DISMISSED_PWA_BANNER_DATE);
      if (lastDismissedDateString) {
        const lastDismissedDate = new Date(lastDismissedDateString);
        const dismissedDaysAgo = differenceInDays(lastDismissedDate, new Date());
        if (dismissedDaysAgo < 14) {
          // Don't show the PWA banner if the user dismissed it within the last 2 weeks
          return false;
        }
      }

      const { isInstalledAsPWA } = this.props;
      const { useMobileLayout } = this.state;
      const shouldShowBanner = useMobileLayout && !isInstalledAsPWA;
      let didShowBanner = false;

      if (shouldShowBanner) {
        try {
          didShowBanner = await this.showInstallPWABanner(delay);
        } catch (e) {
          okerror('Error showing PWA installation banner', e);
        }
      }
  
      return didShowBanner;
    }
  };

  showNetworkStatusBanner = (connected) => {
    this.setStatusBanner(
      connected ? this.props.t('APP_STATUS_CONNECTED') : this.props.t('APP_STATUS_CONNECTION_LOST'),
      connected ? 'creation' : 'notification'
    );

    if (connected) {
      // If connection is maintained for 5 seconds, hide banner
      setTimeout(() => {
        if (this.props.appState.isOnline) {
          this.dismissStatusBanner();
        }
      }, 5000);
    }
  };

  /**
   * Sync the app region with the user's preferred region.
   *
   * @param {('auto'|'light'|'dark')} [preferredAppearanceMode='auto'] The preferred appearance mode. Default: `'auto'`
   */
  syncThemeWithPreference = (preferredAppearanceMode = 'auto') => {
    let appearanceModeCookie = getPersistentValue(PERSISTENT_STORAGE_KEY.APPEARANCE_MODE);
    if (appearanceModeCookie !== preferredAppearanceMode) {
      // Sync the cookie with the preference
      setPersistentValue(PERSISTENT_STORAGE_KEY.APPEARANCE_MODE, preferredAppearanceMode);
    }

    const currentTheme = this.state.theme;

    // Determine the actual theme that should be used
    let desiredTheme;
    switch (preferredAppearanceMode) {
      case 'auto':
        desiredTheme = window.__OK_BROWSER_THEME__;
        break;
      case 'light':
      case 'dark':
        desiredTheme = preferredAppearanceMode;
        break;
      default:
        break;
    }

    if (currentTheme !== desiredTheme) {
      // Set the current theme to match the desired theme
      this.setState({ theme: desiredTheme });
    }
  };

  /**
   * Sync the app theme with the user's preferred region.
   */
  syncRegionWithPreference = (preferredRegion = 'HKG') => {
    const regionSetting = getPersistentValue(PERSISTENT_STORAGE_KEY.REGION);
    if (regionSetting !== preferredRegion) {
      // Sync the cookie with the preference
      setPersistentValue(PERSISTENT_STORAGE_KEY.REGION, preferredRegion);
    }

    if (this.state.region !== preferredRegion) {
      // Set the current region to match the desired theme
      this.setState({ region: preferredRegion });
    }
  };

  /**
   * Sync the app locale with the user's preferred locale.
   */
  syncLocaleWithPreference = (preferredLocale = 'en') => {
    const localeCookie = getCookie('NEXT_LOCALE');
    if (localeCookie !== preferredLocale) {
      // Sync the cookie with the preference
      setCookie('NEXT_LOCALE', preferredLocale);
    }

    const currentLocale = this.state.locale;

    let desiredLocale;
    const { router } = this.props;
    if (localeCookie && currentLocale !== preferredLocale) {
      desiredLocale = preferredLocale;
    }
    if (!localeCookie && currentLocale !== router.locale) {
      desiredLocale = router.locale;
      setCookie('NEXT_LOCALE', router.locale);
    }

    if (desiredLocale && currentLocale !== desiredLocale && router.isReady) {
      this.props.setLocale(desiredLocale);
      this.setState({ locale: desiredLocale });
    }
  };

  /* Events */

  onDragStart = (e) => {
    let shouldBlockDrag = false;

    if (this.state.useMobileLayout) {
      // Block dragging on some elements for mobile devices
      shouldBlockDrag = mobileUndraggableElements.includes(e.target.nodeName);
      if (shouldBlockDrag) {
        e.preventDefault();
      }
    }

    return !shouldBlockDrag;
  };

  /* Render */

  render() {
    const { appState, children, dispatch, router } = this.props;
    const { locale, region, renderCookieAlert, statusBannerMessage, statusBannerTint, theme } = this.state;
    const {
      isShowingStatusBanner,
      notifications,
      scanFunction,
      scanInstructions,
      scanInstructionsLegacy,
      scannedOKID,
      showCookiesRequiredAlert,
      showScanner,
      userPermitsCookies,
    } = appState;

    // Internationalization context
    const internationalizationContext = { region, locale };

    // Theme context
    const themeContext = theme === 'dark' ? themeDark : themeLight;

    const recentNotifications = notifications.filter((n) => !n.dismissed);

    // Container classes
    let containerClassNames = `theme_${themeContext.name}`;
    if (isShowingStatusBanner) {
      containerClassNames = `${containerClassNames} ${styles.withStatusBanner}`;
    }

    return (
      <InternationalizationContext.Provider value={internationalizationContext}>
        <ThemeContext.Provider value={themeContext}>
          <>
            <Head>
              <meta name='viewport' content='initial-scale=1.0, viewport-fit=cover, width=device-width' />
              {/* Global theme-dependant styles */}
              <style>
                {`
                  body {
                    background-color: ${themeContext.colors.contentBackground};
                    color: ${themeContext.colors.text};
                  }
                `}
              </style>
              {ScanPreload}
              {appConfig.features.analytics && userPermitsCookies === '1' && (
                <script dangerouslySetInnerHTML={{ __html: intercomSetup }} />
              )}
              {appConfig.features.analytics && userPermitsCookies === '1' && (
                <script dangerouslySetInnerHTML={{ __html: claritySetup }} />
              )}
            </Head>
            <I18nStyles locale={this.props.locale} />
            <ApolloProvider client={GraphQLClient}>
              <div
                id='app_container'
                className={containerClassNames}
                onDragStart={this.onDragStart}
                style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
              >
                <AppMenus />
                <PopupInterface>
                  <OKRouter>{children}</OKRouter>
                </PopupInterface>
                <Scan
                  in={showScanner}
                  instructions={scanInstructions}
                  instructionsLegacy={scanInstructionsLegacy}
                  onScan={scanFunction}
                />
                <TransitionGroup className={styles.notificationsContainer}>
                  {recentNotifications.map((n) => (
                    <FadeTransition key={`${n.id}`}>
                      <Notification notification={n} />
                    </FadeTransition>
                  ))}
                </TransitionGroup>
                <div
                  className={`${styles.statusBanner} ${statusBannerTint ? styles[statusBannerTint] : ''}`}
                  style={{ bottom: isShowingStatusBanner ? 0 : -54 }}
                >
                  <Text className={styles.message} size='xs'>
                    {statusBannerMessage}
                  </Text>
                  <button
                    aria-label='Dismiss app banner'
                    className={styles.dismissBannerButton}
                    onClick={() => this.dismissStatusBanner(true)}
                  >
                    <Icon className={styles.dismissBannerButtonIcon} name={ICONS.X_SMALL.name} />
                  </button>
                </div>
                <div id='popupContainer' ref={this.popupContainerRef}>
                  {scannedOKID && (
                    <ScanResultsPopup
                      dismiss={() => dispatch(resetScannedOKIDAction())}
                      OKID={scannedOKID}
                    />
                  )}
                </div>
                <div id='alertContainer' />
                {renderCookieAlert && <CookieAlert />}
                {showCookiesRequiredAlert && <CookiesRequiredAlert />}
                {router.query.webinarId && <WebinarPopup webinarId={router.query.webinarId} />}
              </div>
            </ApolloProvider>
          </>
        </ThemeContext.Provider>
      </InternationalizationContext.Provider>
    );
  }
}

export default withRouter(
  connect((state) => {
    const {
      isOnline,
      isShowingStatusBanner,
      notifications,
      scanFunction,
      scanInstructions,
      scanInstructionsLegacy,
      scannedOKID,
      showCookiesRequiredAlert,
      showScanner,
      userPermitsCookies,
    } = state.app;
    const { authenticated, createdOrganisation, isRestoring, preferences } = state.account;
    const { appearanceMode, region, language } = preferences;
    return {
      appState: {
        createdOrganisation,
        isLoggedIn: authenticated || isRestoring,
        isOnline,
        isShowingStatusBanner,
        notifications,
        scanFunction,
        scanInstructions,
        scanInstructionsLegacy,
        scannedOKID,
        showCookiesRequiredAlert,
        showScanner,
        userPermitsCookies,
      },
      preferences: {
        appearanceMode,
        region,
        language,
      },
    };
  })(withI18n(AppContainer))
);

AppContainer.propTypes = {
  appState: PropTypes.shape({
    createdOrganisation: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isOnline: PropTypes.bool.isRequired,
    isShowingStatusBanner: PropTypes.bool,
    notifications: PropTypes.array,
    scanFunction: PropTypes.func,
    scanInstructions: PropTypes.node,
    scanInstructionsLegacy: PropTypes.node,
    scannedOKID: PropTypes.string,
    showCookiesRequiredAlert: PropTypes.bool,
    showScanner: PropTypes.bool.isRequired,
    userPermitsCookies: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  isBrowser: PropTypes.bool.isRequired,
  isInstalledAsPWA: PropTypes.bool.isRequired,
  isNavigatingBack: PropTypes.bool.isRequired,
  isServer: PropTypes.bool.isRequired,
  locale: PropTypes.string,
  preferences: PropTypes.shape({
    appearanceMode: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  router: PropTypes.any.isRequired,
  screenSize: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  setLocale: PropTypes.func,
  t: PropTypes.func,
  tHTML: PropTypes.func,
  userAgent: PropTypes.object.isRequired,
};

/* Helper variables */

const mobileUndraggableElements = ['A', 'IMG'];

/* Helper functions */

/**  */
function getDefaultContentWidthForScreenWidth(screenWidth) {
  let maxScreenWidth;

  if (screenWidth > breakpoints.medium) {
    maxScreenWidth = baseTheme.spacing.contentMaxWidthDesktop;
  } else {
    maxScreenWidth = baseTheme.spacing.contentMaxWidthMobile;
  }

  const contentWidthWithoutPadding = Math.min(screenWidth, maxScreenWidth);
  const contentPadding = baseTheme.spacing.contentMarginLg * 2;
  return contentWidthWithoutPadding - contentPadding;
}

function layoutFlagsForScreenSize(screenSize) {
  const useDesktopLayout = screenSize.width >= breakpoints.medium;
  const useMobileLayout = !useDesktopLayout;

  return [useDesktopLayout, useMobileLayout];
}
