import { useEffect, useState } from 'react';

export default function useBrowserRenderOnly() {
  const [browserReady, setBrowserReady] = useState(false);

  useEffect(() => {
    setBrowserReady(true);
  }, []);

  return browserReady;
}
