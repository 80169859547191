import { forwardRef } from 'react';

import useFullscreenStatus from 'OK/util/hooks/useFullscreenStatus';

export default function withFullScreenStatus(Component) {
  return forwardRef((props, ref) => {
    const isFullScreen = useFullscreenStatus();
    return <Component isFullScreen={isFullScreen} forwardedRef={ref} {...props} />;
  });
}
