import { CREATE_ORGANISATION_ACTION } from '../onboarding/actions';

import {
  ACCOUNT_CLEAR_SESSION_ACTION,
  ACCOUNT_CLOSE_AUTH_MODAL,
  ACCOUNT_LOCK_ACTION,
  ACCOUNT_LOGIN_ACTION,
  ACCOUNT_RENEW_ACTION,
  ACCOUNT_RESET_CREATED_ORGANISATION_FLAG_ACTION,
  ACCOUNT_RESTORING_SESSION_ACTION,
  ACCOUNT_SESSION_START_ACTION,
  ACCOUNT_SET_ACTIVE_INSPECTION_LOG_ID,
  ACCOUNT_SET_ACTIVE_ORGANISATION_ID,
  ACCOUNT_SET_LOGIN_REDIRECT_URL,
  ACCOUNT_SET_COMING_FROM_TRIAL,
  ACCOUNT_SET_COMING_FROM_USER_PAGE,
  ACCOUNT_SHOW_CREATING_ORGANISATION,
  ACCOUNT_SHOW_INVITATATIONS,
  ACCOUNT_SHOW_ONBOARDING,
  ACCOUNT_SHOW_TRY_FOR_FREE,
  ACCOUNT_UPDATE_SESSION_USER_ACTION,
  ACCOUNT_UPDATE_USER_PREFERENCES_ACTION,
  ACCOUNT_VERIFYING_OTP_ACTION,
} from './actions';

import { getCookie } from 'OK/util/cookie';
import { PERSISTENT_STORAGE_KEY } from 'OK/util/enums/persistentStorageKeys';
import { getPersistentValue } from 'OK/util/storage';

const initialState = {
  activeInspectionLogId: null,
  activeOrganisationId:
    typeof window !== 'undefined' ? getPersistentValue(PERSISTENT_STORAGE_KEY.ACTIVE_ORGANISATION_ID) : null,
  allowNewOTPRequestDate: null,
  authenticated: false,
  authenticationError: null,
  createdOrganisation: false,
  isComingFromTryForFree: false,
  isComingFromUserPage: false,
  isLocked: false,
  isNewUser: false,
  isRestoring: false,
  isVerifying: false,
  loginRedirectUrl: null,
  showCreateOrganisation: null,
  showInvitations: null,
  showOnboarding: null,
  showTrialOrganisation: null,
  user: null,
  preferences: {
    region: typeof window !== 'undefined' ? getPersistentValue(PERSISTENT_STORAGE_KEY.REGION) ?? 'HKG' : 'HKG',
    language: getCookie('NEXT_LOCALE') || 'en',
    appearanceMode:
      typeof window !== 'undefined' ? getPersistentValue(PERSISTENT_STORAGE_KEY.APPEARANCE_MODE) ?? 'auto' : 'auto',
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_CLEAR_SESSION_ACTION: {
      const { authenticationError = null } = action.data;
      return {
        ...initialState,
        authenticationError,
        loginRedirectUrl: state.loginRedirectUrl,
        preferences: state.preferences,
      };
    }
    case ACCOUNT_LOGIN_ACTION: {
      const { accessToken } = action.data;
      return {
        ...state,
        accessToken,
        authenticated: true,
        authenticationError: null,
        isVerifying: false,
        verificationAttemptsRemaining: undefined,
        verificationCodeExpirationDate: undefined,
      };
    }
    case ACCOUNT_LOCK_ACTION: {
      const { lockedUntil } = action.data;
      return {
        ...initialState,
        preferences: state.preferences,
        authenticationError: null,
        isLocked: true,
        lockedUntil,
      };
    }
    case ACCOUNT_RENEW_ACTION: {
      const { accessToken } = action.data;
      return {
        ...state,
        accessToken,
        authenticated: true,
        authenticationError: null,
        isRestoring: false,
      };
    }
    case ACCOUNT_RESET_CREATED_ORGANISATION_FLAG_ACTION:
      return {
        ...state,
        createdOrganisation: false,
      };
    case ACCOUNT_SESSION_START_ACTION: {
      const { accessToken, user } = action.data;
      return {
        ...state,
        accessToken,
        authenticated: !!accessToken,
        authenticationError: null,
        isRestoring: false,
        user,
      };
    }
    case ACCOUNT_SET_ACTIVE_INSPECTION_LOG_ID: {
      return {
        ...state,
        activeInspectionLogId: action.data.inspectionLogId,
      };
    }
    case ACCOUNT_SET_ACTIVE_ORGANISATION_ID: {
      return {
        ...state,
        activeOrganisationId: action.organisationId,
      };
    }

    case ACCOUNT_SET_COMING_FROM_TRIAL:
      return {
        ...state,
        isComingFromTryForFree: action.data.isComingFromTryForFree,
      };
    case ACCOUNT_SET_COMING_FROM_USER_PAGE:
      return {
        ...state,
        isComingFromUserPage: action.data.isComingFromUserPage,
      };
    case ACCOUNT_SET_LOGIN_REDIRECT_URL:
      return {
        ...state,
        loginRedirectUrl: action.loginRedirectUrl,
      };
    case ACCOUNT_SHOW_CREATING_ORGANISATION:
      return {
        ...state,
        showCreateOrganisation: action.data.showCreateOrganisation,
      };

    case ACCOUNT_SHOW_INVITATATIONS:
      return {
        ...state,
        showInvitations: action.data.showInvitations,
      };
    case ACCOUNT_CLOSE_AUTH_MODAL:
      return {
        ...state,
        isComingFromTryForFree: false,
        showCreateOrganisation: false,
        showInvitations: false,
        showOnboarding: false,
        showTrialOrganisation: false,
      };
    case ACCOUNT_SHOW_ONBOARDING:
      return {
        ...state,
        showOnboarding: action.data.showOnboarding,
      };
    case ACCOUNT_SHOW_TRY_FOR_FREE:
      return {
        ...state,
        showTrialOrganisation: action.data.showTrialOrganisation,
      };
    case ACCOUNT_UPDATE_SESSION_USER_ACTION:
      return {
        ...state,
        user: action.data.user,
      };
    case ACCOUNT_RESTORING_SESSION_ACTION:
      return {
        ...state,
        authenticationError: null,
        isRestoring: true,
      };
    case ACCOUNT_UPDATE_USER_PREFERENCES_ACTION: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.data,
        },
      };
    }
    case ACCOUNT_VERIFYING_OTP_ACTION: {
      const {
        allowNewOTPRequestDate,
        attemptsRemaining,
        codeExpirationDate,
        countryCode,
        isNewUser = false,
        newsletterSubscription,
        username,
        usernameType,
      } = action.data;
      return {
        ...state,
        allowNewOTPRequestDate,
        authenticationError: null,
        countryCode,
        isNewUser,
        isVerifying: true,
        newsletterSubscription,
        username,
        usernameType,
        verificationAttemptsRemaining: attemptsRemaining,
        verificationCodeExpirationDate: codeExpirationDate,
      };
    }
    case CREATE_ORGANISATION_ACTION:
      return {
        ...state,
        createdOrganisation: true,
        user: {
          ...state.user,
          organisations: [...(state.user.organisations ?? []), action.data],
        },
      };
    default:
      return state;
  }
}

export default reducer;
export { initialState };
