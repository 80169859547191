import PropTypes from 'prop-types';
import { useId, useRef } from 'react';

import BaseIcon from 'OK/components/icon/base';

export default function SpinnerIcon(props) {
  /* Variables */

  const { color = 'currentColor', ...otherProps } = props;
  const uniqueId = useId();
  const gradientId = `linear-gradient-${uniqueId}`;
  const iconRef = useRef();

  /* State */

  /* Render */

  return (
    <BaseIcon ref={iconRef} spin {...otherProps}>
      <defs>
        <linearGradient id={gradientId} x1='0.846' y1='0.755' x2='-0.269' y2='0.084' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor={color} />
          <stop offset='0.019' stopColor={color} />
          <stop offset='0.136' stopColor={color} stopOpacity='0.812' />
          <stop offset='0.732' stopColor={color} stopOpacity='0' />
          <stop offset='1' stopColor={color} stopOpacity='0' />
        </linearGradient>
      </defs>
      <g id='spinner_light'>
        <path
          id='Path_1731'
          data-name='Path 1731'
          d='M14.241,10.007a23.94,23.94,0,0,1,7.036,16.964h8A31.9,31.9,0,0,0,19.9,4.343Z'
          transform='translate(34.723 5.029)'
          fill={`url(#${gradientId})`}
        />
        <path
          id='Path_1732'
          data-name='Path 1732'
          d='M34,58a24,24,0,0,1,0-48V2A32,32,0,1,0,66,34H58A24.029,24.029,0,0,1,34,58Z'
          transform='translate(-2 -2)'
          fill={color}
        />
      </g>
    </BaseIcon>
  );
}

SpinnerIcon.propTypes = {
  color: PropTypes.string,
};
