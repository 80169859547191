import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import SearchInputAsButton, { DISPLAY_MODE } from 'OK/components/input/search/asButton';
import MediaPicker from 'OK/components/mediaPicker';
import OrganisationArchiveCard from 'OK/components/organisation/archiveCard';
import { Popup, PopupContent, PopupButtonsGroup } from 'OK/components/popup';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import DocumentModel from 'OK/models/document';
import UserDocumentModel from 'OK/models/userDocument/index';
import LanguageSearch from 'OK/modules/languageSearch';
import RegionSearch from 'OK/modules/regionSearch';
import {
  addApplicableMarketToDocumentMutation,
  addCoveredLanguageToDocumentMutation,
  AddDocumentStandardMutation,
  EditFilePopupQuery,
  removeApplicableMarketFromDocumentMutation,
  removeCoveredLanguageFromDocumentMutation,
  RemoveDocumentMadeByOrganisationMutation,
  RemoveDocumentStandardMutation,
  RemoveValidFromMutation,
  RemoveValidUntilMutation,
  replaceDocumentFileRequest,
  SetDocumentMadeByOrganisationMutation,
  SetDocumentNumberMutation,
  SetDocumentTypeMutation,
  SetValidFromMutation,
  SetValidUntilMutation,
} from 'OK/networking/documents';
import { SearchOrganisationsQuery } from 'OK/networking/search';
import {
  addUserDocumentApplicableMarketMutation,
  addUserDocumentCoverLanguageMutation,
  addUserDocumentStandardMutation,
  changeUserDocumentTypeMutation,
  EditUserDocumentPopupQuery,
  removeUserDocumentApplicableMarketMutation,
  removeUserDocumentCoverLanguageMutation,
  removeUserDocumentIssueDateMutation,
  removeUserDocumentMadeByOrganisationMutation,
  removeUserDocumentStandardMutation,
  removeUserDocumentValidUntilMutation,
  removeUserDocumenValidFromMutation,
  replaceUserDocumentFileRequest,
  setUserDocumentIssueDateMutation,
  setUserDocumentMadeByOrganisationMutation,
  setUserDocumentNumberMutation,
  setUserDocumentValidUntilMutation,
  setUserDocumenValidFromMutation,
} from 'OK/networking/userDocuments/index';
import { formatToUTCUnixTimestampString } from 'OK/networking/utils';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import { formatDate } from 'OK/util/formatting';
import useDocumentStandards from 'OK/util/hooks/useDocumentStandards';
import useDocumentType from 'OK/util/hooks/useDocumentType';
import useI18n from 'OK/util/hooks/useI18n';
import useUserDocumentStandards from 'OK/util/hooks/useUserDocumentStandards';

export default function EditFilePopup(props) {
  /* Variables */

  const { dismiss, fileId, organisationMode, userMode } = props;
  const apolloClient = useApolloClient();
  const documentStandardsForProductFile = useDocumentStandards();
  const documentStandardsForUserFile = useUserDocumentStandards();
  const documentStandards = userMode ? documentStandardsForUserFile : documentStandardsForProductFile;
  const { t, locale } = useI18n();

  /* State */

  const [documentConfig, setDocumentConfig] = useState(null);
  const [documentNumberError, setDocumentNumberError] = useState(null);
  const [documentTypes, setDocumentTypes] = useState(null);
  const [languageError, setLanguageError] = useState(null);
  const [madeByError, setMadeByError] = useState(null);
  const [madeByInputFocused, setMadeByInputFocused] = useState(false);
  const [madeBySearch, setMadeBySearch] = useState('');
  const [marketError, setMarketError] = useState(null);
  const [standardsError, setStandardsError] = useState(null);
  const [standardsInputFocused, setStandardsInputFocused] = useState(false);
  const [standardsSearch, setStandardsSearch] = useState('');
  const [typeInputFocused, setTypeInputFocused] = useState(false);
  const [typeError, setTypeError] = useState(null);
  const [typeSearch, setTypeSearch] = useState('');
  const [issueDateError, setIssueDateError] = useState(null);
  const [validFromError, setValidFromError] = useState(null);
  const [validUntilError, setValidUntilError] = useState(null);

  /* Refs */

  const madeBySearchInputRef = useRef();
  const mediaPickerRef = useRef();
  const standardsSearchInputRef = useRef();

  /* API */

  const getUserFileResult = useQuery(EditUserDocumentPopupQuery, {
    skip: !fileId,
    variables: { documentAssetId: fileId },
  });

  const getFileResult = useQuery(EditFilePopupQuery, {
    skip: !fileId,
    variables: { documentAssetId: fileId },
  });

  const file = userMode ? getUserFileResult.data?.document : getFileResult.data?.file;

  const [addApplicableMarketAPI] = useMutation(addApplicableMarketToDocumentMutation, {
    onError: () => {
      setMarketError(t('ERROR_GENERIC'));
    },
  });
  const [addApplicableMarketUserDocumentAPI] = useMutation(addUserDocumentApplicableMarketMutation, {
    onError: () => {
      setMarketError(t('ERROR_GENERIC'));
    },
  });
  const [addCoveredLanguageAPI] = useMutation(addCoveredLanguageToDocumentMutation, {
    onError: () => {
      setLanguageError(t('ERROR_GENERIC'));
    },
  });
  const [addCoveredLanguageUserDocumentAPI] = useMutation(addUserDocumentCoverLanguageMutation, {
    onError: () => {
      setLanguageError(t('ERROR_GENERIC'));
    },
  });
  const [addStandardAPI] = useMutation(AddDocumentStandardMutation);
  const [addStandardUserDocumentAPI] = useMutation(addUserDocumentStandardMutation);
  const [removeApplicableMarketAPI] = useMutation(removeApplicableMarketFromDocumentMutation, {
    onError: () => {
      setMarketError(t('ERROR_GENERIC'));
    },
  });
  const [removeApplicableMarketUserDocumentAPI] = useMutation(removeUserDocumentApplicableMarketMutation, {
    onError: () => {
      setMarketError(t('ERROR_GENERIC'));
    },
  });
  const [removeCoveredLanguageAPI] = useMutation(removeCoveredLanguageFromDocumentMutation, {
    onError: () => {
      setLanguageError(t('ERROR_GENERIC'));
    },
  });
  const [removeCoveredLanguageUserDocumentAPI] = useMutation(removeUserDocumentCoverLanguageMutation, {
    onError: () => {
      setLanguageError(t('ERROR_GENERIC'));
    },
  });
  const [removeIssuingOrganisationAPI, removeIssuingOrganisationAPIResult] = useMutation(
    RemoveDocumentMadeByOrganisationMutation
  );
  const [removeIssuingOrganisationUserDocumentAPI, removeIssuingOrganisationUserDocumentAPIResult] = useMutation(
    removeUserDocumentMadeByOrganisationMutation
  );
  const [removeStandardAPI] = useMutation(RemoveDocumentStandardMutation);
  const [removeStandardUserDocumentAPI] = useMutation(removeUserDocumentStandardMutation);
  const [removeIssueDateUserDocuementAPI] = useMutation(removeUserDocumentIssueDateMutation);
  const [removeValidFromAPI] = useMutation(RemoveValidFromMutation);
  const [removeValidFromUserDocumentAPI] = useMutation(removeUserDocumenValidFromMutation);
  const [removeValidUntilAPI] = useMutation(RemoveValidUntilMutation);
  const [removeValidUntilUserDocumentAPI] = useMutation(removeUserDocumentValidUntilMutation);
  const [searchOrganisationAPI, searchOrganisationAPIResult] = useLazyQuery(SearchOrganisationsQuery);
  const [setDocumentNumberAPI] = useMutation(SetDocumentNumberMutation);
  const [setDocumentNumberUserDocumentAPI] = useMutation(setUserDocumentNumberMutation);
  const [setDocumentTypeAPI] = useMutation(SetDocumentTypeMutation);
  const [setDocumentTypeUserDocumentAPI] = useMutation(changeUserDocumentTypeMutation);
  const [setIssuingOrganisationAPI, setIssuingOrganisationAPIResult] = useMutation(
    SetDocumentMadeByOrganisationMutation
  );
  const [setIssuingOrganisationUserDocumentAPI, setIssuingOrganisationUserDocumentAPIResult] = useMutation(
    setUserDocumentMadeByOrganisationMutation
  );
  const [setIssueDateUserDocumentAPI] = useMutation(setUserDocumentIssueDateMutation);
  const [setValidFromAPI] = useMutation(SetValidFromMutation);
  const [setValidFromUserDocumentAPI] = useMutation(setUserDocumenValidFromMutation);
  const [setValidUntilAPI] = useMutation(SetValidUntilMutation);
  const [setValidUntilUserDocumentAPI] = useMutation(setUserDocumentValidUntilMutation);

  /* Methods */

  {
    /* eslint-disable indent */
  }

  const addLanguage = useCallback(
    (languageIso) => {
      setLanguageError(null);

      // Optimistic response
      const optimisticResponse = {
        document: {
          ...file,
          coveredLanguageList: [...file.coveredLanguageList, languageIso],
        },
      };

      // Call API
      userMode
        ? addCoveredLanguageUserDocumentAPI({
            variables: {
              documentAssetId: file.id,
              languageIso,
            },
            optimisticResponse,
          })
        : addCoveredLanguageAPI({
            variables: {
              documentId: file.id,
              languageIso,
            },
            optimisticResponse,
          });
    },
    [addCoveredLanguageAPI, addCoveredLanguageUserDocumentAPI, file, userMode]
  );

  const addMarket = useCallback(
    (marketIso) => {
      setMarketError(null);

      // Optimistic response
      const optimisticResponse = {
        document: {
          ...file,
          applicableMarketList: [...file.applicableMarketList, marketIso],
        },
      };

      userMode
        ? addApplicableMarketUserDocumentAPI({
            variables: {
              documentAssetId: file.id,
              marketIso,
            },
            optimisticResponse,
          })
        : addApplicableMarketAPI({
            variables: {
              documentId: file.id,
              marketIso,
            },
            optimisticResponse,
          });
    },
    [addApplicableMarketAPI, addApplicableMarketUserDocumentAPI, file, userMode]
  );

  const addStandard = useCallback(
    (standardId) => {
      setStandardsError(null);
      setStandardsSearch('');
      setStandardsInputFocused(false);
      standardsSearchInputRef.current.setDisplayMode(DISPLAY_MODE.BUTTON);
      (userMode
        ? addStandardUserDocumentAPI({
            variables: { documentAssetId: file.id, standardId },
            optimisticResponse: {
              document: {
                id: file.id,
                userDocumentStandardList: [...file?.userDocumentStandardList, standardId],
                __typename: UserDocumentModel.GRAPHQL_TYPE,
              },
            },
          })
        : addStandardAPI({
            variables: { documentId: file.id, standardId },
            optimisticResponse: {
              document: {
                id: file.id,
                documentStandardList: [...file?.documentStandardList, standardId],
                __typename: DocumentModel.GRAPHQL_TYPE,
              },
            },
          })
      ).catch(() => {
        setStandardsError(t('ERROR_GENERIC'));
      });
    },
    [
      addStandardAPI,
      addStandardUserDocumentAPI,
      file?.documentStandardList,
      file?.id,
      file?.userDocumentStandardList,
      t,
      userMode,
    ]
  );

  const removeIssuingOrganisation = useCallback(() => {
    setMadeByError(null);
    (userMode
      ? removeIssuingOrganisationUserDocumentAPI({
          variables: { documentAssetId: file.id },
        })
      : removeIssuingOrganisationAPI({
          variables: { documentId: file.id },
        })
    ).catch(() => {
      setMadeByError(t('ERROR_GENERIC'));
    });
  }, [file?.id, removeIssuingOrganisationAPI, removeIssuingOrganisationUserDocumentAPI, t, userMode]);

  const removeLanguage = useCallback(
    (languageIso) => {
      setLanguageError(null);

      // Optimistic response
      const indexOfLanguage = file.coveredLanguageList.findIndex((l) => l === languageIso);
      const updatedLanguageList = [...file.coveredLanguageList];
      updatedLanguageList.splice(indexOfLanguage, 1);
      const optimisticResponse = {
        document: {
          ...file,
          coveredLanguageList: updatedLanguageList,
        },
      };

      // Call API
      userMode
        ? removeCoveredLanguageUserDocumentAPI({
            variables: {
              documentAssetId: file.id,
              languageIso,
            },
            optimisticResponse,
          })
        : removeCoveredLanguageAPI({
            variables: {
              documentId: file.id,
              languageIso,
            },
            optimisticResponse,
          });
    },
    [file, removeCoveredLanguageAPI, removeCoveredLanguageUserDocumentAPI, userMode]
  );

  const removeMarket = useCallback(
    (marketIso) => {
      setMarketError(null);

      // Optimistic response
      const indexOfMarket = file.applicableMarketList.findIndex((l) => l === marketIso);
      const updatedMarketList = [...file.applicableMarketList];
      updatedMarketList.splice(indexOfMarket, 1);
      const optimisticResponse = {
        document: {
          ...file,
          applicableMarketList: updatedMarketList,
        },
      };

      // Call API
      userMode
        ? removeApplicableMarketUserDocumentAPI({
            variables: {
              documentAssetId: file.id,
              marketIso,
            },
            optimisticResponse,
          })
        : removeApplicableMarketAPI({
            variables: {
              documentId: file.id,
              marketIso,
            },
            optimisticResponse,
          });
    },
    [file, removeApplicableMarketAPI, removeApplicableMarketUserDocumentAPI, userMode]
  );

  const replaceFile = useCallback(
    (files) => {
      if (files.length) {
        const newFile = files[0];
        userMode
          ? replaceUserDocumentFileRequest(fileId, newFile).then((req) => {
              if (req.success) {
                // Update cache
                const updatedDocumentAsset = {
                  id: req.responseData.id,
                  documentName: req.responseData.documentName,
                  documentURL: req.responseData.documentURL,
                  lastModifiedBy: req.responseData.lastModifiedBy,
                  lastModifiedDate: req.responseData.lastModifiedDate,
                  metadata: req.responseData.metadata,
                  version: req.responseData.version,
                  __typename: UserDocumentModel.GRAPHQL_TYPE,
                };
                apolloClient.writeFragment({
                  id: apolloClient.cache.identify(updatedDocumentAsset),
                  fragment: UserDocumentModel.fragmentFile,
                  data: updatedDocumentAsset,
                });
              }
            })
          : replaceDocumentFileRequest(fileId, newFile).then((req) => {
              if (req.success) {
                // Update cache
                const updatedDocumentAsset = {
                  id: req.responseData.id,
                  documentName: req.responseData.documentName,
                  documentURL: req.responseData.documentURL,
                  lastModifiedBy: req.responseData.lastModifiedBy,
                  lastModifiedDate: req.responseData.lastModifiedDate,
                  metadata: req.responseData.metadata,
                  version: req.responseData.version,
                  __typename: DocumentModel.GRAPHQL_TYPE,
                };
                apolloClient.writeFragment({
                  id: apolloClient.cache.identify(updatedDocumentAsset),
                  fragment: DocumentModel.fragmentFile,
                  data: updatedDocumentAsset,
                });
              }
            });
      }
    },
    [apolloClient, fileId, userMode]
  );

  const removeStandard = useCallback(
    (typeId) => {
      setStandardsError(null);
      userMode
        ? removeStandardUserDocumentAPI({
            variables: { documentAssetId: file.id, userDocumentStandard: typeId },
            optimisticResponse: {
              document: {
                id: file.id,
                userDocumentStandardList: file?.userDocumentStandardList?.filter((s) => s.typeId !== typeId),
                __typename: UserDocumentModel.GRAPHQL_TYPE,
              },
            },
          })
        : removeStandardAPI({
            variables: { documentId: file.id, documentStandard: typeId },
            optimisticResponse: {
              document: {
                id: file.id,
                documentStandardList: file?.documentStandardList?.filter((s) => s.typeId !== typeId),
                __typename: DocumentModel.GRAPHQL_TYPE,
              },
            },
          }).catch(() => {
            setStandardsError(t('ERROR_GENERIC'));
          });
    },
    [
      file?.documentStandardList,
      file?.userDocumentStandardList,
      file?.id,
      removeStandardAPI,
      removeStandardUserDocumentAPI,
      t,
      userMode,
    ]
  );

  const searchIssuingOrganisations = useMemo(
    () =>
      debounce((searchString) => {
        searchOrganisationAPI({
          variables: {
            searchForAccreditationOrganisation: true,
            searchPaginationDataByDataType: [
              { dataType: 'ORGANISATION', searchPaginationData: { pageSize: 4, skip: 0 } },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchOrganisationAPI]
  );

  {
    /* eslint-disable indent*/
  }

  const setDocumentNumber = useMemo(
    () =>
      debounce((e) => {
        const documentNumber = e.target.value;
        (userMode
          ? setDocumentNumberUserDocumentAPI({
              variables: {
                documentAssetId: file.id,
                documentNumber,
              },
              optimisticResponse: {
                document: {
                  id: file.id,
                  documentNumber,
                  __typename: UserDocumentModel.GRAPHQL_TYPE,
                },
              },
            })
          : setDocumentNumberAPI({
              variables: {
                documentId: file.id,
                documentNumber,
              },
              optimisticResponse: {
                document: {
                  id: file.id,
                  documentNumber,
                  __typename: DocumentModel.GRAPHQL_TYPE,
                },
              },
            })
        ).catch((e) => {
          okdebug('Error setting document number.', e);
          setDocumentNumberError(t('ERROR_GENERIC'));
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [file?.id, setDocumentNumberAPI, setDocumentNumberUserDocumentAPI, t, userMode]
  );

  const setDocumentType = useCallback(
    (documentType) => {
      (userMode
        ? setDocumentTypeUserDocumentAPI({
            variables: {
              documentAssetId: file.id,
              documentType,
            },
            optimisticResponse: {
              document: {
                id: file.id,
                documentType,
                __typename: UserDocumentModel.GRAPHQL_TYPE,
              },
            },
          })
        : setDocumentTypeAPI({
            variables: {
              documentId: file.id,
              documentType,
            },
            optimisticResponse: {
              document: {
                id: file.id,
                documentType,
                __typename: DocumentModel.GRAPHQL_TYPE,
              },
            },
          })
      ).catch(() => {
        setTypeError(t('ERROR_GENERIC'));
      });
      setTypeInputFocused(false);
      setTypeSearch('');
    },
    [file?.id, setDocumentTypeAPI, setDocumentTypeUserDocumentAPI, t, userMode]
  );

  const setIssuingOrganisation = useCallback(
    (organisationId) => {
      setMadeByError(null);
      madeBySearchInputRef.current.setDisplayMode(DISPLAY_MODE.BUTTON);
      setMadeBySearch('');
      (userMode
        ? setIssuingOrganisationUserDocumentAPI({
            variables: {
              documentAssetId: file.id,
              organisationId,
            },
          })
        : setIssuingOrganisationAPI({
            variables: {
              documentId: file.id,
              organisationId,
            },
          })
      ).catch(() => {
        setMadeByError(t('ERROR_GENERIC'));
      });
    },
    [file?.id, setIssuingOrganisationAPI, setIssuingOrganisationUserDocumentAPI, t, userMode]
  );

  const removeDocumentType = useCallback(() => {
    setDocumentType(userMode ? 'USER_FILE' : organisationMode ? 'ORGANISATION_FILE' : 'PRODUCT_FILE');
  }, [organisationMode, setDocumentType, userMode]);

  const setIssueDate = useMemo(
    () =>
      debounce((e) => {
        const issueDate = e.target.value ? formatToUTCUnixTimestampString(new Date(e.target.value)) : null;

        if (issueDate) {
          setIssueDateUserDocumentAPI({
            variables: {
              documentAssetId: file.id,
              issueDate,
            },
            optimisticResponse: {
              document: {
                id: file.id,
                issueDate,
                __typename: UserDocumentModel.GRAPHQL_TYPE,
              },
            },
          }).catch(() => {
            setIssueDateError(t('ERROR_GENERIC'));
          });
        } else {
          removeIssueDateUserDocuementAPI({
            variables: {
              documentAssetId: file.id,
            },
            optimisticResponse: {
              document: {
                id: file.id,
                issueDate,
                __typename: UserDocumentModel.GRAPHQL_TYPE,
              },
            },
          }).catch(() => {
            setValidFromError(t('ERROR_GENERIC'));
          });
        }
      }, DEBOUNCE_TIMING_MS_SHORT),
    [file?.id, removeIssueDateUserDocuementAPI, setIssueDateUserDocumentAPI, t]
  );

  const setValidFrom = useMemo(
    () =>
      debounce((e) => {
        const validFrom = e.target.value ? formatToUTCUnixTimestampString(new Date(e.target.value)) : null;

        if (validFrom) {
          (userMode
            ? setValidFromUserDocumentAPI({
                variables: {
                  documentAssetId: file.id,
                  validFrom,
                },
                optimisticResponse: {
                  document: {
                    id: file.id,
                    validFrom,
                    __typename: UserDocumentModel.GRAPHQL_TYPE,
                  },
                },
              })
            : setValidFromAPI({
                variables: {
                  documentId: file.id,
                  validFrom,
                },
                optimisticResponse: {
                  document: {
                    id: file.id,
                    validFrom,
                    __typename: DocumentModel.GRAPHQL_TYPE,
                  },
                },
              })
          ).catch(() => {
            setValidFromError(t('ERROR_GENERIC'));
          });
        } else {
          (userMode
            ? removeValidFromUserDocumentAPI({
                variables: {
                  documentAssetId: file.id,
                },
                optimisticResponse: {
                  document: {
                    id: file.id,
                    validFrom,
                    __typename: UserDocumentModel.GRAPHQL_TYPE,
                  },
                },
              })
            : removeValidFromAPI({
                variables: {
                  documentId: file.id,
                },
                optimisticResponse: {
                  document: {
                    id: file.id,
                    validFrom,
                    __typename: DocumentModel.GRAPHQL_TYPE,
                  },
                },
              })
          ).catch(() => {
            setValidFromError(t('ERROR_GENERIC'));
          });
        }
      }, DEBOUNCE_TIMING_MS_SHORT),
    [
      file?.id,
      removeValidFromAPI,
      removeValidFromUserDocumentAPI,
      setValidFromAPI,
      setValidFromUserDocumentAPI,
      t,
      userMode,
    ]
  );

  const setValidUntil = useMemo(
    () =>
      debounce((e) => {
        const validTill = e.target.value ? formatToUTCUnixTimestampString(new Date(e.target.value)) : null;

        if (validTill) {
          (userMode
            ? setValidUntilUserDocumentAPI({
                variables: {
                  documentAssetId: file.id,
                  validTill,
                },
                optimisticResponse: {
                  document: {
                    id: file.id,
                    validTill,
                    __typename: UserDocumentModel.GRAPHQL_TYPE,
                  },
                },
              })
            : setValidUntilAPI({
                variables: {
                  documentId: file.id,
                  validTill,
                },
                optimisticResponse: {
                  document: {
                    id: file.id,
                    validTill,
                    __typename: DocumentModel.GRAPHQL_TYPE,
                  },
                },
              })
          ).catch(() => {
            setValidUntilError(t('ERROR_GENERIC'));
          });
        } else {
          (userMode
            ? removeValidUntilUserDocumentAPI({
                variables: {
                  documentAssetId: file.id,
                },
                optimisticResponse: {
                  document: {
                    id: file.id,
                    validTill,
                    __typename: UserDocumentModel.GRAPHQL_TYPE,
                  },
                },
              })
            : removeValidUntilAPI({
                variables: {
                  documentId: file.id,
                },
                optimisticResponse: {
                  document: {
                    id: file.id,
                    validTill,
                    __typename: DocumentModel.GRAPHQL_TYPE,
                  },
                },
              })
          ).catch(() => {
            setValidUntilError(t('ERROR_GENERIC'));
          });
        }
      }, DEBOUNCE_TIMING_MS_SHORT),
    [
      file?.id,
      removeValidUntilAPI,
      removeValidUntilUserDocumentAPI,
      setValidUntilAPI,
      setValidUntilUserDocumentAPI,
      t,
      userMode,
    ]
  );

  /* Event handlers */

  const onSearchIssuingOrganisations = useCallback(
    (e) => {
      const searchString = e.target.value;
      setMadeBySearch(searchString);

      if (searchString.length > 1) {
        searchIssuingOrganisations(searchString);
      }
    },
    [searchIssuingOrganisations]
  );

  const onSearchStandards = useCallback((e) => {
    setStandardsSearch(e.target.value);
  }, []);

  const onSearchType = useCallback((e) => {
    setTypeSearch(e.target.value);
  }, []);

  {
    /* eslint-enable indent*/
  }
  /* Effects */

  // Load document config
  useEffect(() => {
    async function loadConfig() {
      setDocumentConfig(
        userMode
          ? await UserDocumentModel.getConfigForType(file.documentType)
          : await DocumentModel.getConfigForType(file.documentType, organisationMode)
      );
      setDocumentTypes(userMode ? await UserDocumentModel.getTypes() : await DocumentModel.getTypes(organisationMode));
    }

    if (file) {
      loadConfig();
    }
  }, [file, organisationMode, userMode]);

  /* Render */

  const friendlyDocumentType = useDocumentType(file, { fullType: true });

  const fields = [];
  if (documentConfig) {
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.DOCUMENT_NUMBER : DocumentModel.FIELD_NAMES.DOCUMENT_NUMBER
      )
    ) {
      fields.push(
        <div key={userMode ? UserDocumentModel.FIELD_NAMES.DOCUMENT_NUMBER : DocumentModel.FIELD_NAMES.DOCUMENT_NUMBER}>
          <Text bold className={styles.fieldName}>
            {t('FILE_FIELD_DOCUMENT_NUMBER')}
          </Text>
          <Input
            className={styles.fieldValue}
            onChange={setDocumentNumber}
            placeholder={t('FILE_FIELD_DOCUMENT_NUMBER_PLACEHOLDER')}
            value={file.documentNumber}
          />
          {documentNumberError && (
            <Text className={styles.errorMessage} size='sm' tint='alert'>
              {documentNumberError}
            </Text>
          )}
        </div>
      );
    }
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.LANGUAGES : DocumentModel.FIELD_NAMES.LANGUAGES
      )
    ) {
      fields.push(
        <div key={userMode ? UserDocumentModel.FIELD_NAMES.LANGUAGES : DocumentModel.FIELD_NAMES.LANGUAGES}>
          <Text bold className={styles.fieldName}>
            {t('FILE_FIELD_LANGUAGES')}
          </Text>
          <div className={styles.fieldValue}>
            <div className={styles.tags}>
              {file.coveredLanguageList.map((languageIso) => (
                <Tag
                  className={styles.tag}
                  key={languageIso}
                  onClickRemove={removeLanguage}
                  removeable
                  size='sm'
                  uniqueId={languageIso}
                >
                  {languageIso}
                </Tag>
              ))}
            </div>
            <LanguageSearch
              addLanguageButtonTitle={t('NOTE_CARD_ADD_LANGUAGE')}
              excludeLanguages={file.coveredLanguageList}
              onSelectedLanguage={addLanguage}
            />
          </div>
          {languageError && (
            <Text className={styles.errorMessage} size='sm' tint='alert'>
              {languageError}
            </Text>
          )}
        </div>
      );
    }
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.APPLICABLE_MARKETS : DocumentModel.FIELD_NAMES.APPLICABLE_MARKETS
      )
    ) {
      fields.push(
        <div
          key={
            userMode ? UserDocumentModel.FIELD_NAMES.APPLICABLE_MARKETS : DocumentModel.FIELD_NAMES.APPLICABLE_MARKETS
          }
          className={styles.marketLabel}
        >
          <Text bold className={styles.fieldName}>
            {t('FILE_FIELD_MARKETS')}
          </Text>
          <div className={styles.fieldValue}>
            <div className={styles.tags}>
              {file.applicableMarketList.map((marketIso) => (
                <Tag
                  className={styles.tag}
                  key={marketIso}
                  onClickRemove={removeMarket}
                  removeable
                  size='sm'
                  uniqueId={marketIso}
                >
                  {marketIso}
                </Tag>
              ))}
            </div>
            <RegionSearch
              buttonTitle={t('ADD_APPLICABLE_MARKET')}
              className={styles.button}
              excludedRegionISOs={file.applicableMarketList}
              onSelectedRegion={addMarket}
              regionType='market'
            />
          </div>
          {marketError && (
            <Text className={styles.errorMessage} size='sm' tint='alert'>
              {marketError}
            </Text>
          )}
        </div>
      );
    }
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.MADE_BY_ORGANISATION : DocumentModel.FIELD_NAMES.MADE_BY_ORGANISATION
      )
    ) {
      let searchSuggestions;
      if (madeBySearch.length > 1) {
        searchSuggestions = searchOrganisationAPIResult.data?.search?.resultList.map((r) => {
          return {
            key: r.dataId,
            title: r.organisationData.name,
          };
        });
      }
      fields.push(
        <div
          key={
            userMode
              ? UserDocumentModel.FIELD_NAMES.MADE_BY_ORGANISATION
              : DocumentModel.FIELD_NAMES.MADE_BY_ORGANISATION
          }
        >
          <div className={styles.fieldName}>
            <Text bold>{t('FILE_FIELD_MADE_BY')}</Text>
            {file.madeByOrganisation && (
              <Button
                linkStyle
                loading={
                  userMode
                    ? removeIssuingOrganisationUserDocumentAPIResult.loading
                    : removeIssuingOrganisationAPIResult.loading
                }
                onClick={removeIssuingOrganisation}
                tint='alert'
              >
                {t('REMOVE')}
              </Button>
            )}
          </div>
          {file.madeByOrganisation ? (
            <OrganisationArchiveCard
              className={`${styles.fieldValue} ${styles.madeByOrganisationCard}`}
              layoutOverride='mobile'
              linkToOrganisation={false}
              organisation={file.madeByOrganisation}
            />
          ) : (
            <div className={styles.searchContainer}>
              <SearchInputAsButton
                buttonProps={{
                  loading: userMode
                    ? setIssuingOrganisationUserDocumentAPIResult.loading
                    : setIssuingOrganisationAPIResult.loading,
                }}
                className={`${styles.fieldValue} ${styles.searchInput}`}
                icon={ICONS.PLUS.name}
                inputProps={{
                  loading: searchOrganisationAPIResult.loading,
                  onBlur: () => {
                    setMadeByInputFocused(false);
                  },
                  onFocus: () => {
                    setMadeByInputFocused(true);
                  },
                }}
                onChange={onSearchIssuingOrganisations}
                placeholder={t('ADD_ISSUING_ORGANISATION')}
                ref={madeBySearchInputRef}
                tint='creation'
                value={madeBySearch}
              />
              {madeByInputFocused && (
                <SearchSuggestions
                  className={styles.searchSuggestions}
                  highlightTerm={madeBySearch}
                  onSuggestionClick={setIssuingOrganisation}
                  showMoreResultsMessage={
                    searchOrganisationAPIResult.data?.search?.searchPaginationResultDataByDataType?.ORGANISATION
                      ?.totalResults > searchOrganisationAPIResult.data?.search?.resultList?.length
                  }
                  showNoResultsMessage={searchOrganisationAPIResult.data?.search?.resultList?.length === 0}
                  suggestions={searchSuggestions}
                />
              )}
            </div>
          )}
          {madeByError && (
            <Text className={styles.errorMessage} size='sm' tint='alert'>
              {madeByError}
            </Text>
          )}
        </div>
      );
    }
    if (documentConfig.fields.includes(userMode && UserDocumentModel.FIELD_NAMES.ISSUE_DATE)) {
      let issueDateValue = '';
      if (file.issueDate) {
        issueDateValue = formatDate(new Date(parseInt(file.issueDate, 10)), locale, { style: 'inputValue' });
      }
      fields.push(
        <div key={UserDocumentModel.FIELD_NAMES.VALID_FROM}>
          <Text bold className={styles.fieldName}>
            {t('FILE_FIELD_ISSUE_DATE')}
          </Text>
          <Input
            className={styles.fieldValue}
            keepFocusOnClear={false}
            onChange={setIssueDate}
            placeholder={t('FILE_FIELD_ISSUE_DATE_PLACEHOLDER')}
            type='date'
            value={issueDateValue}
          />
          {issueDateError && (
            <Text className={styles.errorMessage} size='sm' tint='alert'>
              {issueDateError}
            </Text>
          )}
        </div>
      );
    }
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.VALID_FROM : DocumentModel.FIELD_NAMES.VALID_FROM
      )
    ) {
      let validFromValue = '';
      if (file.validFrom) {
        validFromValue = formatDate(new Date(parseInt(file.validFrom, 10)), locale, { style: 'inputValue' });
      }
      fields.push(
        <div key={userMode ? UserDocumentModel.FIELD_NAMES.VALID_FROM : DocumentModel.FIELD_NAMES.VALID_FROM}>
          <Text bold className={styles.fieldName}>
            {t('FILE_FIELD_VALID_FROM')}
          </Text>
          <Input
            className={styles.fieldValue}
            keepFocusOnClear={false}
            onChange={setValidFrom}
            placeholder={t('FILE_FIELD_VALID_FROM_PLACEHOLDER')}
            type='date'
            value={validFromValue}
          />
          {validFromError && (
            <Text className={styles.errorMessage} size='sm' tint='alert'>
              {validFromError}
            </Text>
          )}
        </div>
      );
    }
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.VALID_UNTIL : DocumentModel.FIELD_NAMES.VALID_UNTIL
      )
    ) {
      let validUntilValue = '';
      if (file.validTill) {
        validUntilValue = formatDate(new Date(parseInt(file.validTill, 10)), locale, { style: 'inputValue' });
      }
      fields.push(
        <div key={userMode ? UserDocumentModel.FIELD_NAMES.VALID_UNTIL : DocumentModel.FIELD_NAMES.VALID_UNTIL}>
          <Text bold className={styles.fieldName}>
            {t('FILE_FIELD_VALID_UNTIL')}
          </Text>
          <Input
            className={styles.fieldValue}
            keepFocusOnClear={false}
            onChange={setValidUntil}
            placeholder={t('FILE_FIELD_VALID_UNTIL_PLACEHOLDER')}
            type='date'
            value={validUntilValue}
          />
          {validUntilError && (
            <Text className={styles.errorMessage} size='sm' tint='alert'>
              {validUntilError}
            </Text>
          )}
        </div>
      );
    }
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.STANDARDS : DocumentModel.FIELD_NAMES.STANDARDS
      )
    ) {
      let searchSuggestions;
      if (standardsSearch.length) {
        const matchRegExp = new RegExp(standardsSearch, 'i');
        searchSuggestions = documentStandards
          .filter((s) => {
            if (!userMode && file.documentStandardList?.includes(s.typeId)) {
              return false;
            } else if (userMode && file.userDocumentStandardList?.includes(s.typeId)) {
              return false;
            }
            return s.title.match(matchRegExp);
          })
          .map((s) => {
            return {
              key: s.typeId,
              title: s.title,
            };
          });
      }
      fields.push(
        <div key={userMode ? UserDocumentModel.FIELD_NAMES.STANDARDS : DocumentModel.FIELD_NAMES.STANDARDS}>
          <Text bold className={styles.fieldName}>
            {t('FILE_FIELD_STANDARDS')}
          </Text>

          {/* eslint-disable indent */}
          <div>
            {userMode
              ? file.userDocumentStandardList?.map((standardId) => {
                  const filteredList = documentStandards.find((s2) => s2.typeId === standardId);
                  return (
                    <Tag
                      className={styles.tag}
                      key={standardId}
                      onClickRemove={() => removeStandard(filteredList.typeId)}
                      removeable
                      uniqueId={standardId}
                    >
                      {filteredList.title}
                    </Tag>
                  );
                })
              : file.documentStandardList?.map((standardId) => {
                  const filteredList = documentStandards.find((s2) => s2.typeId === standardId);
                  return (
                    <Tag
                      className={styles.tag}
                      key={standardId}
                      onClickRemove={() => removeStandard(filteredList.typeId)}
                      removeable
                      uniqueId={standardId}
                    >
                      {filteredList.title}
                    </Tag>
                  );
                })}
          </div>
          {/* eslint-enable indent */}

          <div className={styles.searchContainer}>
            <SearchInputAsButton
              className={`${styles.fieldValue} ${styles.searchInput}`}
              icon={ICONS.PLUS.name}
              inputProps={{
                onBlur: () => {
                  setStandardsInputFocused(false);
                },
                onFocus: () => {
                  setStandardsInputFocused(true);
                },
              }}
              onChange={onSearchStandards}
              placeholder={t('FILE_FIELD_STANDARDS_PLACEHOLDER')}
              ref={standardsSearchInputRef}
              tint='creation'
              value={standardsSearch}
            />
            {standardsInputFocused && (
              <SearchSuggestions
                className={styles.searchSuggestions}
                classNameContainerContent={styles.containerContent}
                highlightTerm={standardsSearch}
                onSuggestionClick={addStandard}
                showMoreResultsMessage={searchSuggestions?.length > 4}
                showNoResultsMessage={searchSuggestions?.length === 0}
                suggestions={searchSuggestions?.slice(0, 4)}
              />
            )}
          </div>
          {standardsError && (
            <Text className={styles.errorMessage} size='sm' tint='alert'>
              {standardsError}
            </Text>
          )}
        </div>
      );
    }
  }

  const main = file ? (
    <>
      <Text bold className={styles.fileName} tint='navigation'>
        <Icon name={ICONS.DOCUMENT.name} style={{ marginRight: 5, position: 'relative', top: -1 }} />
        &nbsp;
        {file.documentName}
      </Text>
      <div style={{ position: 'relative' }}>
        <Button block icon={ICONS.REPLACE.name} onClick={() => mediaPickerRef.current.open()} tint='navigation'>
          {t('REPLACE_FILE')}
        </Button>
        <MediaPicker invisible mediaTypes={['document']} onChange={replaceFile} ref={mediaPickerRef} />
      </div>
      {/* eslint-disable indent */}

      <div className={styles.fieldName}>
        <Text bold>{t('FILE_FIELD_TYPE')}</Text>
        {userMode ? (
          file.documentType !== 'USER_FILE' ? (
            <Button linkStyle onClick={removeDocumentType} tint='alert'>
              {t('REMOVE')}
            </Button>
          ) : null
        ) : file.documentType !== (organisationMode ? 'ORGANISATION_FILE' : 'PRODUCT_FILE') ? (
          <Button linkStyle onClick={removeDocumentType} tint='alert'>
            {t('REMOVE')}
          </Button>
        ) : null}
      </div>
      {/* eslint-enable indent */}
      {userMode ? (
        file.documentType !== 'USER_FILE' ? (
          <Text className={styles.fieldValue}>{friendlyDocumentType}</Text>
        ) : (
          <div className={styles.searchContainer}>
            <SearchInputAsButton
              className={`${styles.fieldValue} ${styles.searchInput}`}
              icon={ICONS.PLUS.name}
              inputProps={{
                onBlur: () => {
                  setTypeInputFocused(false);
                },
                onFocus: () => {
                  setTypeInputFocused(true);
                },
              }}
              onChange={onSearchType}
              placeholder={t('FILE_FIELD_TYPE_PLACEHOLDER')}
              tint='creation'
              value={typeSearch}
            />
            {typeInputFocused && documentTypes?.length > 0 && (
              <SearchSuggestions
                className={styles.searchSuggestions}
                highlightTerm={typeSearch}
                onSuggestionClick={setDocumentType}
                suggestions={documentTypes
                  .filter((type) => {
                    const regex = new RegExp(typeSearch, 'i');
                    return type.title.match(regex) || type.fullTitle.match(regex);
                  })
                  .map((type) => {
                    return {
                      key: type.typeId,
                      title: type.fullTitle,
                    };
                  })}
              />
            )}
          </div>
        )
      ) : file.documentType !== (organisationMode ? 'ORGANISATION_FILE' : 'PRODUCT_FILE') ? (
        <Text className={styles.fieldValue}>{friendlyDocumentType}</Text>
      ) : (
        <div className={styles.searchContainer}>
          <SearchInputAsButton
            className={`${styles.fieldValue} ${styles.searchInput}`}
            icon={ICONS.PLUS.name}
            inputProps={{
              onBlur: () => {
                setTypeInputFocused(false);
              },
              onFocus: () => {
                setTypeInputFocused(true);
              },
            }}
            onChange={onSearchType}
            placeholder={t('FILE_FIELD_TYPE_PLACEHOLDER')}
            tint='creation'
            value={typeSearch}
          />
          {typeInputFocused && documentTypes?.length > 0 && (
            <SearchSuggestions
              className={styles.searchSuggestions}
              highlightTerm={typeSearch}
              onSuggestionClick={setDocumentType}
              suggestions={documentTypes
                .filter((type) => {
                  const regex = new RegExp(typeSearch, 'i');
                  return type.title.match(regex) || type.fullTitle.match(regex);
                })
                .map((type) => {
                  return {
                    key: type.typeId,
                    title: type.fullTitle,
                  };
                })}
            />
          )}
        </div>
      )}
      {typeError && (
        <Text className={styles.errorMessage} size='sm' tint='alert'>
          {typeError}
        </Text>
      )}
      {fields}
    </>
  ) : (
    <Icon className={styles.loadingIcon} height={40} name={ICONS.SPINNER.name} width={40} />
  );

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.container}>
        <div className={styles.header}>
          <h3 className={styles.title}>{t('FILE')}</h3>
          {file?.REFID && (
            <Tag className={styles.refId} invert size='md'>
              {file.REFID}
            </Tag>
          )}
          <Button className={styles.closeButton} linkStyle onClick={dismiss}>
            {t('CLOSE')}
          </Button>
        </div>
        <Text>{t('FILE_POPUP_DESCRIPTION')}</Text>
        {main}
      </PopupContent>
      <PopupButtonsGroup className={styles.buttonContainer}>
        <Button className={styles.closeButtonBottom} onClick={dismiss} tint='navigation'>
          {t('CLOSE')}
        </Button>
      </PopupButtonsGroup>
    </Popup>
  );
}

EditFilePopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
  fileId: PropTypes.string,
  organisationMode: PropTypes.bool,
  userMode: PropTypes.bool,
};
