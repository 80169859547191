import { gql } from '@apollo/client';

import { AudioMediaAssetModel, ImageMediaAssetModel, VideoMediaAssetModel } from '../mediaAsset';

export default class UnversionedMediaAssetModel {
  static GRAPHQL_TYPE = 'UnversionedMediaAsset';

  static fragmentName = 'UnversionedMediaAsset_Fragment';
  static fragment = gql`
    fragment ${UnversionedMediaAssetModel.fragmentName} on ${UnversionedMediaAssetModel.GRAPHQL_TYPE} {
      audioData {
        ...${AudioMediaAssetModel.fragmentName}
      }
      imageData {
        ...${ImageMediaAssetModel.fragmentName}
      }
      mediaType
      videoData {
        ...${VideoMediaAssetModel.fragmentName}
      }
    }
    ${AudioMediaAssetModel.fragment}
    ${ImageMediaAssetModel.fragment}
    ${VideoMediaAssetModel.fragment}
  `;

  static fragmentNameMediaGalleryPopup = 'UnversionedMediaAsset_MediaGalleryPopup_Fragment';
  static fragmentMediaGalleryPopup = gql`
    fragment ${UnversionedMediaAssetModel.fragmentNameMediaGalleryPopup} on ${UnversionedMediaAssetModel.GRAPHQL_TYPE} {
      mediaType
      audioData {
        audioURL
      }
      imageData {
        imageURL
        imageName
        baseImageURL
      }
      videoData {
        videoURL
      }
    }
  `;
}
