import { gql } from '@apollo/client';

export default class BaseNoteModel {
  static GRAPHQL_TYPE = 'NoteAsset';

  static fragmentName = 'Base_NoteAsset_Fragment';
  static fragment = gql`
    fragment ${BaseNoteModel.fragmentName} on ${BaseNoteModel.GRAPHQL_TYPE} {
      REFID
      createdBy
      createdDate
      id
      lastModifiedBy
      lastModifiedDate
      organisationId
      status
      textContentMap
      version
    }
  `;
}
