import PropTypes from 'prop-types';
import { useContext } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import ThemeContext from 'OK/util/context/theme';
import useI18n from 'OK/util/hooks/useI18n';

export default function VideoMenuHeader(props) {
  const { className, onClickBack, onClickClose, title, ...otherProps } = props;
  const theme = useContext(ThemeContext);
  const { t } = useI18n();

  let classNames = styles.container;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <div className={classNames} {...otherProps}>
      {onClickBack && (
        <Button className={styles.backButton} iconPosition='left' linkStyle onClick={onClickBack} withCaret>
          {t('MENU_BUTTON_BACK')}
        </Button>
      )}
      <p>{title}</p>
      <button className={styles.closeButton} onClick={onClickClose} type='button'>
        <img alt={t('IMG_ALT_CLOSE_MENU')} src={`/icons/small_x_${theme.name}.svg`} />
      </button>
    </div>
  );
}

VideoMenuHeader.propTypes = {
  className: PropTypes.string,
  onClickBack: PropTypes.func,
  onClickClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
