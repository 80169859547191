import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import NextImage from 'next/legacy/image';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Alert from 'OK/components/alert';
import AudioPlayer from 'OK/components/audioPlayer';
import Button from 'OK/components/button';
import CommentsController from 'OK/components/comments/controller';
import Grade from 'OK/components/grade';
import Icon, { ICONS } from 'OK/components/icon';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import Link from 'OK/components/link';
import MediaPicker from 'OK/components/mediaPicker';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import PageTitle from 'OK/components/pageTitle';
import Progressable from 'OK/components/progressable';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import VideoPlayer from 'OK/components/videoPlayer';
import MediaAssetModel from 'OK/models/mediaAsset';
import { updateAudioMediaAsset, updateImageMediaAsset, updateVideoMediaAsset } from 'OK/models/mediaAsset/utils';
import ProductModel from 'OK/models/product';
import ArchiveLinksSection from 'OK/modules/archiveLinksSection';
import AddWorkPopup from 'OK/modules/popups/addWork';
import ShareAssetPopup from 'OK/modules/popups/share';
import { deleteMediaAssetMutation, getMediaAssetByRefIdQuery } from 'OK/networking/media';
import { useUnlinkProductMediaAPI } from 'OK/networking/products/hooks';
import UIContext from 'OK/util/context/ui';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import { formatDuration, formatFilesize } from 'OK/util/formatting';
import authorisationForResource from 'OK/util/functions/authorisationForResource';
import isAuthorised, { isAuthorisedViaShare } from 'OK/util/functions/isAuthorised';
import { OBJECT_PERMISSIONS } from 'OK/util/functions/permissions';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';
import usePermission from 'OK/util/hooks/usePermission';

export default function EditMediaPage(props) {
  /* Variables */

  const { REFIDasProp } = props;
  const { t, tHTML } = useI18n();

  // Essential data

  const [getMediaAssetAPI, getMediaAssetAPIResult] = useLazyQuery(getMediaAssetByRefIdQuery);
  const media = getMediaAssetAPIResult.data?.mediaAsset;

  // Misc

  const [authenticated, , currentUser] = useAuthentication();
  const authorisationLevel = useAuthorisationLevel(media);
  const router = useRouter();
  const REFID = router.query.refId || REFIDasProp;
  const screenSize = useSelector((state) => state.app.screenSize);
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const showInPopup = useSelector((state) => state.app.showInPopup);
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const organisation = currentUser?.organisationList.find((o) => o.id === activeOrganisationId);
  const isManager =
    authorisationForResource(currentUser, activeOrganisationId, organisation) == AUTHORISATION_LEVEL.MANAGER ||
    authorisationForResource(currentUser, activeOrganisationId, organisation) == AUTHORISATION_LEVEL.OWNER;
  const hasPermissionToEdit =
    isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER) ||
    (isAuthorisedViaShare(media?.assetAccessPermission, AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION) &&
      isManager);
  const hasPermissionToPostComments = usePermission(
    OBJECT_PERMISSIONS.POST_COMMENTS,
    currentUser,
    activeOrganisationId,
    media
  );

  // Refs

  const basicsSectionRef = useRef();
  const linksSectionRef = useRef();
  const mediaPickerRef = useRef();

  // State

  const [deleteToastMessage, setDeleteToastMessage] = useState(null);
  const [isEditingMedia, setIsEditingMedia] = useState(false);
  const [isUploadingNewMedia, setIsUploadingNewMedia] = useState(false);
  const [replaceMediaError, setReplaceMediaError] = useState(null);
  const [showAddWorkPopup, setShowAddWorkPopup] = useState(false);
  const [showConfirmDeleteAlert, setShowConfirmDeleteAlert] = useState(false);
  const [renderSharePopup, setRenderSharePopup] = useState(false);

  /* API */

  const [deleteMediaAssetAPI, deleteMediaAssetAPIResult] = useMutation(deleteMediaAssetMutation, {
    onCompleted: (data) => {
      if (data?.mediaAsset?.id) {
        router.push('/archive');
      } else {
        setDeleteToastMessage(t('ERROR_GENERIC'));
      }
    },
    onError: (errorData) => {
      if (errorData.graphQLErrors?.length) {
        const error = errorData.graphQLErrors[0];
        if (error.message === 'UTILITY_MEDIA_ASSET_HAS_EXISTING_LINKS') {
          setDeleteToastMessage(t('CANT_DELETE_MEDIA_WHILE_LINKED'));
          return;
        }
      }

      setDeleteToastMessage(t('ERROR_GENERIC'));
    },
  });

  const unlinkMediaFromProductAPI = useUnlinkProductMediaAPI();

  /* Methods */

  const closeSharePopup = useCallback(() => {
    setRenderSharePopup(false);
  }, []);

  function deleteMedia() {
    setShowConfirmDeleteAlert(false);

    deleteMediaAssetAPI({
      variables: {
        mediaAssetId: media.id,
      },
    });
  }

  function promptConfirmDelete() {
    setShowConfirmDeleteAlert(true);
  }

  const openSharePopup = useCallback(() => {
    setRenderSharePopup(true);
  }, []);

  const unlinkFromProduct = useCallback(
    (productId) => {
      const product = media.linkedProductList.find((p) => p.id === productId);
      unlinkMediaFromProductAPI(product, media.id);
    },
    [media?.id, media?.linkedProductList, unlinkMediaFromProductAPI]
  );

  /* Event handlers */

  const onPickNewMedia = useCallback(
    (files) => {
      if (!media) {
        return;
      }

      const newMediaFile = files[0];

      let request;
      switch (media.mediaType) {
        case MediaAssetModel.MEDIA_TYPE.AUDIO:
          request = updateAudioMediaAsset(media.id, newMediaFile);
          break;
        case MediaAssetModel.MEDIA_TYPE.IMAGE:
          request = updateImageMediaAsset(media.id, newMediaFile);
          break;
        case MediaAssetModel.MEDIA_TYPE.VIDEO:
          request = updateVideoMediaAsset(media.id, newMediaFile);
          break;
        default:
          okwarn(`Invalid media type ${media.mediaType} selected.`);
          return;
      }

      if (request) {
        setIsUploadingNewMedia(true);
        request
          .catch((e) => {
            okerror(e);
            setReplaceMediaError(t('ERROR_GENERIC'));
          })
          .finally(() => {
            setIsUploadingNewMedia(false);
          });
      }
    },
    [media, t]
  );

  /* Effects */

  // Load media asset data when authenticated and url query is available.
  useEffect(() => {
    if (REFID && authenticated) {
      getMediaAssetAPI({
        variables: {
          refId: REFID,
        },
      });
    }
  }, [REFID, authenticated, getMediaAssetAPI]);

  // Hide delete toast after 5 seconds
  useEffect(() => {
    if (deleteToastMessage) {
      setTimeout(() => {
        setDeleteToastMessage(false);
      }, 5000);
    }
  }, [deleteToastMessage]);

  // Redirect if not authorised
  useEffect(() => {
    const apiCompleted =
      getMediaAssetAPIResult.called &&
      (getMediaAssetAPIResult.networkStatus === NetworkStatus.ready ||
        getMediaAssetAPIResult.networkStatus === NetworkStatus.error);
    if (apiCompleted && !media) {
      router.replace('/404');
    }
  }, [getMediaAssetAPIResult.called, getMediaAssetAPIResult.networkStatus, media, router]);

  /* Render */

  const disableActions = !media || deleteMediaAssetAPIResult.loading;
  const previewSize = useMobileLayout ? screenSize.width - 100 : 400;

  let filename;
  let mediaPreview;
  let mediaUrl;
  let metadata;
  let acceptedNewMediaType;
  let resolution;
  switch (media?.mediaType) {
    case MediaAssetModel.MEDIA_TYPE.IMAGE:
      acceptedNewMediaType = 'photo';
      filename = media.imageData.imageName;
      mediaUrl = media.imageData.imageURL;
      mediaPreview = (
        <TextLayout>
          <div className={styles.mediaPreviewContainer} style={{ height: previewSize }}>
            <NextImage height={previewSize} src={mediaUrl} width={previewSize} />
          </div>
        </TextLayout>
      );
      metadata = media.imageData.metadata;
      resolution = `${metadata.width}x${metadata.height} px`;
      break;
    case MediaAssetModel.MEDIA_TYPE.VIDEO:
      acceptedNewMediaType = 'video';
      filename = media.videoData.videoName;
      mediaUrl = media.videoData.videoURL;
      metadata = media.videoData.metadata;
      mediaPreview = (
        <TextLayout>
          <div
            className={styles.mediaPreviewContainer}
            style={{
              height: previewSize,
              width: previewSize,
            }}
          >
            <VideoPlayer source={mediaUrl} sourceType={metadata.mimeType} galleryWidth={previewSize} />
          </div>
        </TextLayout>
      );
      resolution = `${metadata.width}x${metadata.height} px`;
      break;
    case MediaAssetModel.MEDIA_TYPE.AUDIO:
      acceptedNewMediaType = 'audio';
      filename = media.audioData.audioName;
      mediaUrl = media.audioData.audioURL;
      mediaPreview = (
        <TextLayout>
          <div
            className={styles.mediaPreviewContainer}
            style={{
              height: previewSize,
              width: previewSize,
            }}
          >
            <AudioPlayer source={mediaUrl} />
          </div>
        </TextLayout>
      );
      metadata = media.audioData.metadata;
      break;
    default:
      metadata = {};
      break;
  }

  const pageSubmenu = (
    <div className={styles.submenu}>
      <Text className={styles.submenuTitle}>
        {t('MEDIA')}{' '}
        <Tag className={styles.submenuRefId} size='sm'>
          {REFID}
        </Tag>
      </Text>
      <div className={styles.submenuButtons}>
        <Link
          className={styles.submenuButton}
          disabled={disableActions}
          download={filename}
          href={mediaUrl}
          icon={ICONS.DOWNLOAD.name}
          linkStyle
          target='_blank'
        >
          {useDesktopLayout && t('DOWNLOAD')}
        </Link>
        {hasPermissionToEdit && (
          <div className={`${styles.submenuButton} ${styles.submenuButtonContainer}`}>
            <Button icon={ICONS.SHARE.name} linkStyle onClick={openSharePopup}>
              {useDesktopLayout && t('SHARE')}
            </Button>
          </div>
        )}
        {hasPermissionToEdit && (
          <div className={`${styles.submenuButton} ${styles.submenuButtonContainer}`}>
            <Button
              disabled={disableActions}
              icon={ICONS.TRASH.name}
              linkStyle
              onClick={promptConfirmDelete}
              tint='alert'
            >
              {useDesktopLayout && t('DELETE')}
            </Button>
            <FadeInOutTransition appear in={!!deleteToastMessage}>
              <Toast className={styles.shareToast}>{deleteToastMessage}</Toast>
            </FadeInOutTransition>
          </div>
        )}
      </div>
      <Grade className={styles.reliabilityScore} score={media?.conformityPoint} type='conformity' />
    </div>
  );

  const mediaAssetName = media && MediaAssetModel.mediaAssetName(media);

  const pageMenu = (
    <PageMenu
      assetName={mediaAssetName}
      sections={[]}
      showBackButton
      smartTabs
      submenu={pageSubmenu}
      submenuClassName={styles.pageSubmenu}
      verticalOffsetPx={70}
    />
  );

  const mainContent = useMemo(() => {
    if (!getMediaAssetAPIResult.called || getMediaAssetAPIResult.loading) {
      return (
        <TextLayout>
          <Icon height={40} name={ICONS.SPINNER.name} width={40} />
        </TextLayout>
      );
    } else if (media) {
      return (
        <div className={styles.mediaInfoContainer}>
          <Progressable inProgress={isUploadingNewMedia}>
            {!isEditingMedia && mediaPreview}
            <TextLayout>
              <MediaPicker
                className={styles.mediaPicker}
                invisible
                mediaTypes={[acceptedNewMediaType]}
                onBeganEditingMedia={() => setIsEditingMedia(true)}
                onChange={onPickNewMedia}
                onEndedEditingMedia={() => setIsEditingMedia(false)}
                ref={mediaPickerRef}
                style={{
                  height: previewSize,
                  paddingTop: media?.mediaType !== MediaAssetModel.MEDIA_TYPE.VIDEO ? 0 : null,
                  width: previewSize,
                }}
              />
            </TextLayout>
          </Progressable>
          <TextLayout className={styles.mediaDetailsContainer}>
            <Text bold tint='navigation'>
              {filename}
            </Text>
            {hasPermissionToEdit && (
              <Button className={styles.replaceMediaButton} onClick={mediaPickerRef.current?.open} tint='navigation'>
                {t('REPLACE_MEDIA_TYPE', { data: { mediaType: t(media.mediaType) } })}
              </Button>
            )}
            {replaceMediaError && (
              <Text className={styles.replaceMediaError} size='sm' tint='alert'>
                {replaceMediaError}
              </Text>
            )}
            <div className={styles.field}>
              <Text bold className={styles.fieldHeader}>
                {t('FILE_FIELD_SIZE')}
              </Text>
              <Text className={styles.fieldValue}>{formatFilesize(metadata.filesize)}</Text>
            </div>
            {media.mediaType !== MediaAssetModel.MEDIA_TYPE.AUDIO && (
              <div className={styles.field}>
                <Text bold className={styles.fieldHeader}>
                  {t('MEDIA_FIELD_RESOLUTION')}
                </Text>
                <Text className={styles.fieldValue}>{resolution}</Text>
              </div>
            )}
            {media.mediaType !== MediaAssetModel.MEDIA_TYPE.IMAGE && (
              <div className={styles.field}>
                <Text bold className={styles.fieldHeader}>
                  {t('MEDIA_FIELD_LENGTH')}
                </Text>
                <Text className={styles.fieldValue}>{formatDuration(metadata.duration, t)}</Text>
              </div>
            )}
          </TextLayout>
        </div>
      );
    } else {
      return null;
    }
  }, [
    acceptedNewMediaType,
    filename,
    getMediaAssetAPIResult.called,
    getMediaAssetAPIResult.loading,
    hasPermissionToEdit,
    isEditingMedia,
    isUploadingNewMedia,
    media,
    mediaPreview,
    metadata.duration,
    metadata.filesize,
    onPickNewMedia,
    previewSize,
    replaceMediaError,
    resolution,
    t,
  ]);

  return (
    <>
      <PageTitle>{REFID ? `${t('MEDIA')} - ${REFID}` : t('MEDIA')}</PageTitle>
      {showInPopup ? pageMenu : <Menu>{pageMenu}</Menu>}
      <ContentLayout className={styles.basicsContainer} ref={basicsSectionRef} pageContent>
        <UIContext.Provider value='card'>
          <TextLayout>
            <h2 className={styles.header}>
              {t('MEDIA')} <Tag className={styles.refId}>{REFID}</Tag>
            </h2>
            {media?.mediaType && <h3 className={styles.documentType}>{t(media.mediaType)}</h3>}
          </TextLayout>
          {mainContent}
        </UIContext.Provider>
      </ContentLayout>
      {hasPermissionToEdit && (
        <ArchiveLinksSection
          availableLinkTypes={[ProductModel.GRAPHQL_TYPE]}
          className={styles.linksSection}
          disableActions={disableActions}
          linkedList={media.linkedProductList}
          linksFilter='ALL'
          linksType={ProductModel.GRAPHQL_TYPE}
          onClickUnlink={unlinkFromProduct}
          parentResource={media}
          parentType={MediaAssetModel.GRAPHQL_TYPE}
          ref={linksSectionRef}
        />
      )}
      {hasPermissionToPostComments && (
        <ContentLayout className={styles.commentSection} pageContent>
          <TextLayout>
            <h3 className={styles.commentSectionHeader}>{t('COMMENTS_SECTION_HEADER')}</h3>
            <CommentsController
              assetType='MEDIA_ASSET'
              assetId={media?.id}
              assetOrganisationId={media?.organisationId}
            />
          </TextLayout>
        </ContentLayout>
      )}
      {showConfirmDeleteAlert && (
        <Alert
          buttons={
            <>
              <Button block className={styles.deleteFileButton} onClick={deleteMedia} tint='alert'>
                {t('LOG_MEDIA_DELETE_ALERT_CONFIRM')}
              </Button>
              <Button block onClick={() => setShowConfirmDeleteAlert(false)} linkStyle>
                {t('LOG_MEDIA_DELETE_ALERT_CANCEL')}
              </Button>
            </>
          }
          message={tHTML('LOG_MEDIA_DELETE_ALERT_MESSAGE')}
          title={t('LOG_MEDIA_DELETE_ALERT_TITLE')}
        />
      )}
      {renderSharePopup && (
        <ShareAssetPopup
          assetId={media.id}
          assetType='MEDIA_ASSET'
          asset={media}
          dismiss={closeSharePopup}
          sharedToList={media.assetAccessPermissionList}
        />
      )}

      {showAddWorkPopup && (
        <AddWorkPopup
          dismiss={() => setShowAddWorkPopup(false)}
          data={media}
          preSelectedData={media}
          preSelectedDataType={'MEDIA_ASSET'}
        />
      )}
    </>
  );
}

EditMediaPage.layoutProps = {
  contentTopPadding: false,
  padFooter: false,
};

EditMediaPage.propTypes = {
  REFIDasProp: PropTypes.string,
};

export async function getServerSideProps({ locale }) {
  const i18nProps = await serverSideTranslations(locale, ['common']);
  return { props: { ...i18nProps } };
}
