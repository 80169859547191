import PropTypes from 'prop-types';
import { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import styles from './styles.module.scss';

import { Popup, PopupContent } from 'OK/components/popup';

const HEIGHT_TRANSITION_DURATION = 250;
const OPACITY_TRANSITION_DURATION = 150;

const AutoHeightAdjustingPopup = forwardRef((props, forwardedRef) => {
  /* Variables */

  const { children, childrenKey, className, dismiss, innerClassName, ...otherProps } = props;

  /* Refs */

  const contentRef = useRef();
  const innerRef = useRef();
  const popupRef = forwardedRef || innerRef;
  const userPermitsCookies = useSelector((state) => state.app.userPermitsCookies);
  const waitForCookieAcceptance = userPermitsCookies === null;

  /* State */

  const [contentPadding, setContentPadding] = useState({ top: 30, left: 30, bottom: 30, right: 30 });
  const [height, setHeight] = useState(0);

  /* Methods */

  /* Effects */

  // Update height of popup when children changes
  useLayoutEffect(() => {
    let resizeObserver;
    let contentEl;
    if (!waitForCookieAcceptance) {
      resizeObserver = new ResizeObserver((entries) => {
        const el = entries[0];
        const rect = el.contentRect;
        setHeight(rect.height);
      });
      contentEl = contentRef.current;
      resizeObserver.observe(contentEl);
    }

    return function () {
      if (resizeObserver) {
        resizeObserver.unobserve(contentEl);
      }
    };
  }, [waitForCookieAcceptance]);

  // Update vertical padding
  useLayoutEffect(() => {
    if (!popupRef.current || waitForCookieAcceptance) {
      return;
    }
    const css = getComputedStyle(popupRef.current);
    const padding = {
      top: parseInt(css.paddingTop, 10),
      bottom: parseInt(css.paddingBottom, 10),
      left: parseInt(css.paddingLeft, 10),
      right: parseInt(css.paddingRight, 10),
    };
    setContentPadding(padding);
  }, [childrenKey, popupRef, waitForCookieAcceptance]);

  /* Render */

  let classNames = styles.popup;
  if (className) {
    classNames += ` ${className}`;
  }

  let innerClassNames = styles.contentContainer;
  if (innerClassName) {
    innerClassNames += ` ${innerClassName}`;
  }

  if (waitForCookieAcceptance) {
    return null;
  }

  return (
    <Popup dismiss={dismiss}>
      <PopupContent
        className={classNames}
        ref={popupRef}
        style={{
          height: height + contentPadding.top + contentPadding.bottom,
          transitionDuration: `${HEIGHT_TRANSITION_DURATION}ms`,
        }}
        {...otherProps}
      >
        <div
          className={innerClassNames}
          ref={contentRef}
          style={{
            top: contentPadding.top,
            left: contentPadding.left,
            right: contentPadding.right,
          }}
        >
          <SwitchTransition mode='out-in'>
            <CSSTransition
              appear
              classNames={{
                appear: styles.exit,
                appearActive: styles.enter,
                appearDone: styles.enter,
                enter: styles.exit,
                enterActive: styles.enter,
                enterDone: styles.enter,
                exit: styles.exit,
                exitActive: styles.exit,
                exitDone: styles.exit,
              }}
              in
              key={childrenKey}
              timeout={OPACITY_TRANSITION_DURATION}
            >
              <div
                className={styles.transitionContainer}
                style={{ transitionDuration: `${OPACITY_TRANSITION_DURATION}ms` }}
              >
                {children}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </PopupContent>
    </Popup>
  );
});

AutoHeightAdjustingPopup.propTypes = {
  children: PropTypes.node,
  childrenKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  dismiss: PropTypes.func.isRequired,
  innerClassName: PropTypes.string,
};

export default AutoHeightAdjustingPopup;
