import { NetworkStatus, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import CommentsController from 'OK/components/comments/controller';
import Grade from 'OK/components/grade';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import SearchInputAsButton, { DISPLAY_MODE } from 'OK/components/input/search/asButton';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import LinkToFile from 'OK/components/link/linkToFile';
import MediaPicker from 'OK/components/mediaPicker';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import OrganisationArchiveCard from 'OK/components/organisation/archiveCard';
import PageTitle from 'OK/components/pageTitle';
import { GenericFilePreview, PDFPreview } from 'OK/components/pdf/preview';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import DocumentModel from 'OK/models/document';
import ProductModel from 'OK/models/product';
import ArchiveLinksSection from 'OK/modules/archiveLinksSection';
import LanguageSearch from 'OK/modules/languageSearch';
import AddWorkPopup from 'OK/modules/popups/addWork';
import ShareAssetPopup from 'OK/modules/popups/share';
import RegionSearch from 'OK/modules/regionSearch';
import {
  addApplicableMarketToDocumentMutation,
  addCoveredLanguageToDocumentMutation,
  AddDocumentStandardMutation,
  deleteDocumentAssetMutation,
  FileArchivePageQuery,
  removeApplicableMarketFromDocumentMutation,
  removeCoveredLanguageFromDocumentMutation,
  RemoveDocumentMadeByOrganisationMutation,
  RemoveDocumentStandardMutation,
  RemoveValidFromMutation,
  RemoveValidUntilMutation,
  replaceDocumentFileRequest,
  SetDocumentMadeByOrganisationMutation,
  SetDocumentNumberMutation,
  SetDocumentTypeMutation,
  SetValidFromMutation,
  SetValidUntilMutation,
} from 'OK/networking/documents';
import {
  usePublishProductDocumentAPI,
  useUnlinkProductDocumentAPI,
  useUnpublishProductDocumentAPI,
} from 'OK/networking/products/hooks';
import { SearchOrganisationsQuery } from 'OK/networking/search';
import { formatToUTCUnixTimestampString } from 'OK/networking/utils';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import UIContext from 'OK/util/context/ui';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import { formatDate, formatFilesize } from 'OK/util/formatting';
import authorisationForResource from 'OK/util/functions/authorisationForResource';
import getFileExtension from 'OK/util/functions/getFileExtension';
import isAuthorised, { isAuthorisedViaShare } from 'OK/util/functions/isAuthorised';
import { OBJECT_PERMISSIONS } from 'OK/util/functions/permissions';
import shareUtil, { SHARE_ACTION, SHARE_TYPE } from 'OK/util/functions/share';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useDocumentStandards from 'OK/util/hooks/useDocumentStandards';
import useDocumentType from 'OK/util/hooks/useDocumentType';
import useI18n from 'OK/util/hooks/useI18n';
import usePermission from 'OK/util/hooks/usePermission';

/* Helpers */

export default function FileEditPage(props) {
  /* Variables */

  const { REFIDasProp } = props;
  const { t, locale } = useI18n();

  // Essential data

  const [getDocument, getDocumentResult] = useLazyQuery(FileArchivePageQuery);
  const file = useMemo(() => {
    return getDocumentResult.data?.documentAsset;
  }, [getDocumentResult.data?.documentAsset]);

  // Misc

  const apolloClient = useApolloClient();
  const [authenticated, , currentUser] = useAuthentication();
  const authorisatonLevel = useAuthorisationLevel(file);
  const documentStandards = useDocumentStandards();
  const screenSize = useSelector((state) => state.app.screenSize);
  const router = useRouter();
  const REFID = router.query.refId || REFIDasProp;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const showInPopup = useSelector((state) => state.app.showInPopup);
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const organisation = currentUser?.organisationList.find((o) => o.id === activeOrganisationId);
  const isManager =
    authorisationForResource(currentUser, activeOrganisationId, organisation) == AUTHORISATION_LEVEL.MANAGER ||
    authorisationForResource(currentUser, activeOrganisationId, organisation) == AUTHORISATION_LEVEL.OWNER;
  const authorisationLevel = useAuthorisationLevel(file);
  const hasPermissionToEdit =
    isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER) ||
    (isAuthorisedViaShare(file?.assetAccessPermission, AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION) && isManager);
  const hasPermissionToPostComments = usePermission(
    OBJECT_PERMISSIONS.POST_COMMENTS,
    currentUser,
    activeOrganisationId,
    file
  );

  // Refs

  const basicsSectionRef = useRef();
  const linksSectionRef = useRef();
  const madeBySearchInputRef = useRef();
  const mediaPickerRef = useRef();
  const standardsSearchInputRef = useRef();

  // State

  const [deleteToastMessage, setDeleteToastMessage] = useState(null);
  const [documentConfig, setDocumentConfig] = useState(null);
  const [documentNumberError, setDocumentNumberError] = useState(null);
  const [documentTypes, setDocumentTypes] = useState(null);
  const [isReplacingFile, setIsReplacingFile] = useState(false);
  const [linksFilter, setLinksFilter] = useState('ALL');
  const [languageError, setLanguageError] = useState(null);
  const [madeByError, setMadeByError] = useState(null);
  const [madeByInputFocused, setMadeByInputFocused] = useState(false);
  const [madeBySearch, setMadeBySearch] = useState('');
  const [marketError, setMarketError] = useState(null);
  const [renderSharePopup, setRenderSharePopup] = useState(false);
  const [replaceFileError, setReplaceFileError] = useState(null);
  const [shareToastMessage, setShareToastMessage] = useState(false);
  const [showAddWorkPopup, setShowAddWorkPopup] = useState(false);
  const [showConfirmDeleteAlert, setShowConfirmDeleteAlert] = useState(false);
  const [standardsError, setStandardsError] = useState(null);
  const [standardsInputFocused, setStandardsInputFocused] = useState(false);
  const [standardsSearch, setStandardsSearch] = useState('');
  const [typeInputFocused, setTypeInputFocused] = useState(false);
  const [typeError, setTypeError] = useState(null);
  const [typeSearch, setTypeSearch] = useState('');
  const [validFromError, setValidFromError] = useState(null);
  const [validUntilError, setValidUntilError] = useState(null);

  /* API */

  const [addApplicableMarketAPI] = useMutation(addApplicableMarketToDocumentMutation, {
    onError: () => {
      setMarketError(t('ERROR_GENERIC'));
    },
  });
  const [addCoveredLanguageAPI] = useMutation(addCoveredLanguageToDocumentMutation, {
    onError: () => {
      setLanguageError(t('ERROR_GENERIC'));
    },
  });
  const [addStandardAPI] = useMutation(AddDocumentStandardMutation);
  const [deleteDocumentAPI, deleteDocumentAPIResult] = useMutation(deleteDocumentAssetMutation, {
    onCompleted: (data) => {
      if (data?.document?.id) {
        router.push('/archive');
      } else {
        setDeleteToastMessage(t('ERROR_GENERIC'));
      }
    },
    onError: (errorData) => {
      if (errorData.graphQLErrors?.length) {
        const error = errorData.graphQLErrors[0];
        if (error.message === 'UTILITY_DOCUMENT_ASSET_HAS_EXISTING_LINKS') {
          setDeleteToastMessage(t('FILE_ARCHIVE_PAGE_CANT_DELETE_WHILE_LINKED'));
          return;
        }
      }

      setDeleteToastMessage(t('ERROR_GENERIC'));
    },
  });
  const publishDocumentAPI = usePublishProductDocumentAPI({
    ignoreResults: true,
  });
  const [removeApplicableMarketAPI] = useMutation(removeApplicableMarketFromDocumentMutation, {
    onError: () => {
      setMarketError(t('ERROR_GENERIC'));
    },
  });
  const [removeCoveredLanguageAPI] = useMutation(removeCoveredLanguageFromDocumentMutation, {
    onError: () => {
      setLanguageError(t('ERROR_GENERIC'));
    },
  });
  const [removeStandardAPI] = useMutation(RemoveDocumentStandardMutation);
  const [removeIssuingOrganisationAPI, removeIssuingOrganisationAPIResult] = useMutation(
    RemoveDocumentMadeByOrganisationMutation
  );
  const [removeValidFromAPI] = useMutation(RemoveValidFromMutation);
  const [removeValidUntilAPI] = useMutation(RemoveValidUntilMutation);
  const [searchOrganisationAPI, searchOrganisationAPIResult] = useLazyQuery(SearchOrganisationsQuery);
  const [setDocumentNumberAPI] = useMutation(SetDocumentNumberMutation);
  const [setDocumentTypeAPI] = useMutation(SetDocumentTypeMutation);
  const [setIssuingOrganisationAPI, setIssuingOrganisationAPIResult] = useMutation(
    SetDocumentMadeByOrganisationMutation
  );
  const [setValidFromAPI] = useMutation(SetValidFromMutation);
  const [setValidUntilAPI] = useMutation(SetValidUntilMutation);
  const unlinkDocumentAPI = useUnlinkProductDocumentAPI();
  const unpublishDocumentAPI = useUnpublishProductDocumentAPI({
    ignoreResults: true,
  });

  /* Methods */

  function addLanguage(languageIso) {
    // Optimistic response
    const optimisticResponse = {
      document: {
        ...file,
        coveredLanguageList: [...file.coveredLanguageList, languageIso],
      },
    };

    // Call API
    addCoveredLanguageAPI({
      variables: {
        documentId: file.id,
        languageIso,
      },
      optimisticResponse,
    });
  }

  const addMarket = (marketIso) => {
    setMarketError(null);

    // Optimistic response
    const optimisticResponse = {
      document: {
        ...file,
        applicableMarketList: [...file.applicableMarketList, marketIso],
      },
    };
    addApplicableMarketAPI({
      variables: {
        documentId: file.id,
        marketIso,
      },
      optimisticResponse,
    });
  };

  const addStandard = useCallback(
    (standardId) => {
      setStandardsError(null);
      setStandardsSearch('');
      setStandardsInputFocused(false);
      standardsSearchInputRef.current.setDisplayMode(DISPLAY_MODE.BUTTON);
      addStandardAPI({
        variables: { documentId: file.id, standardId },
        optimisticResponse: {
          document: {
            id: file.id,
            documentStandardList: [...file.documentStandardList, standardId],
            __typename: DocumentModel.GRAPHQL_TYPE,
          },
        },
      }).catch(() => {
        setStandardsError(t('ERROR_GENERIC'));
      });
    },
    [addStandardAPI, file?.documentStandardList, file?.id, t]
  );

  const closeSharePopup = useCallback(() => {
    setRenderSharePopup(false);
  }, []);

  function deleteFile() {
    setShowConfirmDeleteAlert(false);

    deleteDocumentAPI({
      variables: {
        documentAssetId: file.id,
      },
    });
  }

  const openSharePopup = useCallback(() => {
    setRenderSharePopup(true);
  }, []);

  function promptConfirmDelete() {
    setShowConfirmDeleteAlert(true);
  }

  const publishOnProduct = useCallback(
    (productId) => {
      const product = file.linkedProductList.find((p) => p.id === productId);
      publishDocumentAPI(product, file.id);
    },
    [file?.id, file?.linkedProductList, publishDocumentAPI]
  );

  const removeIssuingOrganisation = useCallback(() => {
    setMadeByError(null);
    removeIssuingOrganisationAPI({
      variables: { documentId: file.id },
    }).catch(() => {
      setMadeByError(t('ERROR_GENERIC'));
    });
  }, [file?.id, removeIssuingOrganisationAPI, t]);

  function removeLanguage(languageIso) {
    // Optimistic response
    const indexOfLanguage = file.coveredLanguageList.findIndex((l) => l === languageIso);
    const updatedLanguageList = [...file.coveredLanguageList];
    updatedLanguageList.splice(indexOfLanguage, 1);
    const optimisticResponse = {
      document: {
        ...file,
        coveredLanguageList: updatedLanguageList,
      },
    };

    // Call API
    removeCoveredLanguageAPI({
      variables: {
        documentId: file.id,
        languageIso,
      },
      optimisticResponse,
    });
  }

  const removeMarket = (marketIso) => {
    setMarketError(null);

    // Optimistic response
    const indexOfMarket = file.applicableMarketList.findIndex((l) => l === marketIso);
    const updatedMarketList = [...file.applicableMarketList];
    updatedMarketList.splice(indexOfMarket, 1);
    const optimisticResponse = {
      document: {
        ...file,
        applicableMarketList: updatedMarketList,
      },
    };

    // Call API
    removeApplicableMarketAPI({
      variables: {
        documentId: file.id,
        marketIso,
      },
      optimisticResponse,
    });
  };

  const removeStandard = useCallback(
    (standardId) => {
      setStandardsError(null);
      const updatedStandardsList = file.documentStandardList.filter((s) => s !== standardId);
      removeStandardAPI({
        variables: { documentId: file.id, standardId },
        optimisticResponse: {
          document: {
            id: file.id,
            documentStandardList: updatedStandardsList,
            __typename: DocumentModel.GRAPHQL_TYPE,
          },
        },
      }).catch(() => {
        setStandardsError(t('ERROR_GENERIC'));
      });
    },
    [file?.documentStandardList, file?.id, removeStandardAPI, t]
  );

  async function replaceFile(newFile) {
    setIsReplacingFile(true);
    setReplaceFileError(null);
    try {
      const response = await replaceDocumentFileRequest(file.id, newFile);
      if (response.success) {
        const newDocumentAsset = {
          id: response.responseData.id,
          documentName: response.responseData.documentName,
          documentURL: response.responseData.documentURL,
          lastModifiedBy: response.responseData.lastModifiedBy,
          lastModifiedDate: response.responseData.lastModifiedDate,
          metadata: response.responseData.metadata,
          version: response.responseData.version,
          __typename: DocumentModel.GRAPHQL_TYPE,
        };

        // Update DocumentAsset in cache
        apolloClient.writeFragment({
          id: apolloClient.cache.identify(newDocumentAsset),
          fragment: DocumentModel.fragmentFile,
          data: newDocumentAsset,
        });
      } else {
        setReplaceFileError(t('ERROR_GENERIC'));
      }
    } catch (e) {
      okerror('Error replacing file', e);
      setReplaceFileError(t('ERROR_GENERIC'));
    } finally {
      setIsReplacingFile(false);
    }
  }

  const searchIssuingOrganisations = useMemo(
    () =>
      debounce((searchString) => {
        searchOrganisationAPI({
          variables: {
            searchForAccreditationOrganisation: true,
            searchPaginationDataByDataType: [
              { dataType: 'ORGANISATION', searchPaginationData: { pageSize: 4, skip: 0 } },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchOrganisationAPI]
  );

  const setDocumentNumber = useMemo(
    () =>
      debounce((e) => {
        const documentNumber = e.target.value;
        setDocumentNumberAPI({
          variables: {
            documentId: file.id,
            documentNumber,
          },
          optimisticResponse: {
            document: {
              id: file.id,
              documentNumber,
              __typename: DocumentModel.GRAPHQL_TYPE,
            },
          },
        }).catch((e) => {
          okdebug('Error setting document number.', e);
          setDocumentNumberError(t('ERROR_GENERIC'));
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [file?.id, setDocumentNumberAPI, t]
  );

  const setDocumentType = useCallback(
    (documentType) => {
      setDocumentTypeAPI({
        variables: {
          documentId: file.id,
          documentType,
        },
        optimisticResponse: {
          document: {
            id: file.id,
            documentType,
            __typename: DocumentModel.GRAPHQL_TYPE,
          },
        },
      }).catch(() => {
        setTypeError(t('ERROR_GENERIC'));
      });
      setTypeInputFocused(false);
      setTypeSearch('');
    },
    [file?.id, setDocumentTypeAPI, t]
  );

  const removeDocumentType = useCallback(() => {
    setDocumentType('PRODUCT_FILE');
  }, [setDocumentType]);

  const setIssuingOrganisation = useCallback(
    (organisationId) => {
      setMadeByError(null);
      madeBySearchInputRef.current.setDisplayMode(DISPLAY_MODE.BUTTON);
      setMadeBySearch('');
      setIssuingOrganisationAPI({
        variables: {
          documentId: file.id,
          organisationId,
        },
      }).catch(() => {
        setMadeByError(t('ERROR_GENERIC'));
      });
    },
    [file?.id, setIssuingOrganisationAPI, t]
  );

  const setValidFrom = useMemo(
    () =>
      debounce((e) => {
        const validFrom = e.target.value ? formatToUTCUnixTimestampString(new Date(e.target.value)) : null;
        const optimisticResponse = {
          document: {
            id: file.id,
            validFrom,
            __typename: DocumentModel.GRAPHQL_TYPE,
          },
        };
        if (validFrom) {
          setValidFromAPI({
            variables: {
              documentId: file.id,
              validFrom,
            },
            optimisticResponse,
          }).catch(() => {
            setValidFromError(t('ERROR_GENERIC'));
          });
        } else {
          removeValidFromAPI({
            variables: {
              documentId: file.id,
            },
            optimisticResponse,
          }).catch(() => {
            setValidFromError(t('ERROR_GENERIC'));
          });
        }
      }, DEBOUNCE_TIMING_MS_SHORT),
    [file?.id, removeValidFromAPI, setValidFromAPI, t]
  );

  const setValidUntil = useMemo(
    () =>
      debounce((e) => {
        const validTill = e.target.value ? formatToUTCUnixTimestampString(new Date(e.target.value)) : null;
        const optimisticResponse = {
          document: {
            id: file.id,
            validTill,
            __typename: DocumentModel.GRAPHQL_TYPE,
          },
        };
        if (validTill) {
          setValidUntilAPI({
            variables: {
              documentId: file.id,
              validTill,
            },
            optimisticResponse,
          }).catch(() => {
            setValidUntilError(t('ERROR_GENERIC'));
          });
        } else {
          removeValidUntilAPI({
            variables: {
              documentId: file.id,
            },
            optimisticResponse,
          }).catch(() => {
            setValidUntilError(t('ERROR_GENERIC'));
          });
        }
      }, DEBOUNCE_TIMING_MS_SHORT),
    [file?.id, removeValidUntilAPI, setValidUntilAPI, t]
  );

  function share() {
    const link = window.location.href;
    shareUtil(link, SHARE_TYPE.URL)
      .then((shareAction) => {
        if (shareAction === SHARE_ACTION.CLIPBOARD) {
          setShareToastMessage(t('COPIED_TO_CLIPBOARD'));
        }
      })
      .catch((e) => {
        okerror('Error triggering share ui', e);
        setShareToastMessage(t('FILE_ARCHIVE_PAGE_ERROR_SHARING_FILE'));
      });
  }

  const unlinkFromProduct = useCallback(
    (productId) => {
      const product = file.linkedProductList.find((p) => p.id === productId);
      unlinkDocumentAPI(product, file.id);
    },
    [file?.id, file?.linkedProductList, unlinkDocumentAPI]
  );

  const unpublishFromProduct = useCallback(
    (productId) => {
      const product = file.linkedProductList.find((p) => p.id === productId);
      unpublishDocumentAPI(product, file.id);
    },
    [file?.id, file?.linkedProductList, unpublishDocumentAPI]
  );

  // This piece of code is for future use of publishing and unpublishing files logic

  // const onClickChangeShareStatus = useCallback(
  //   (publishStatus) => {
  //     setPublishError(null);
  //     if (publishStatus === PUBLISH_STATUS.PUBLISHED) {
  //       const optimisticResponse = {
  //         site: {
  //           id: site?.id,
  //           publishStatus: PUBLISH_STATUS.PUBLISHED,
  //           __typename: SiteModel.GRAPHQL_TYPE,
  //         },
  //       };
  //       publishSiteAPI({
  //         variables: {
  //           siteId: site?.id,
  //         },
  //         optimisticResponse,
  //       }).catch(() => {
  //         setPublishError(t('ERROR_GENERIC'));
  //       });
  //     } else {
  //       const optimisticResponse = {
  //         site: {
  //           id: site?.id,
  //           publishStatus: PUBLISH_STATUS.UNPUBLISHED,
  //           __typename: SiteModel.GRAPHQL_TYPE,
  //         },
  //       };
  //       unpublishSiteAPI({
  //         variables: {
  //           siteId: site?.id,
  //         },
  //         optimisticResponse,
  //       }).catch(() => {
  //         setPublishError(t('ERROR_GENERIC'));
  //       });
  //     }
  //   },
  //   [publishSiteAPI, site?.id, t, unpublishSiteAPI]
  // );

  // Event handlers

  const onChangePublishedOnProduct = useCallback(
    (productId, published) => {
      if (published) {
        publishOnProduct(productId);
      } else {
        unpublishFromProduct(productId);
      }
    },
    [publishOnProduct, unpublishFromProduct]
  );

  const onSearchIssuingOrganisations = useCallback(
    (e) => {
      const searchString = e.target.value;
      setMadeBySearch(searchString);

      if (searchString.length > 1) {
        searchIssuingOrganisations(searchString);
      }
    },
    [searchIssuingOrganisations]
  );

  const onSearchStandards = useCallback((e) => {
    setStandardsSearch(e.target.value);
  }, []);

  const onSearchType = useCallback((e) => {
    setTypeSearch(e.target.value);
  }, []);

  function onSelectedReplacementFile(files) {
    replaceFile(files[0]);
  }

  /* Effects */

  // Load document when session and url query are ready
  useEffect(() => {
    if (REFID && authenticated) {
      getDocument({
        variables: {
          refId: REFID,
        },
      });
    }
  }, [REFID, authenticated, getDocument]);

  // Load document config
  useEffect(() => {
    async function loadConfig() {
      setDocumentConfig(await DocumentModel.getConfigForType(file.documentType));
      setDocumentTypes(await DocumentModel.getTypes());
    }

    if (file) {
      loadConfig();
    }
  }, [file]);

  // Hide share toast after 5 seconds
  useEffect(() => {
    if (shareToastMessage) {
      setTimeout(() => {
        setShareToastMessage(null);
      }, 5000);
    }
  }, [shareToastMessage]);

  // Hide delete toast after 5 seconds
  useEffect(() => {
    if (deleteToastMessage) {
      setTimeout(() => {
        setDeleteToastMessage(false);
      }, 5000);
    }
  }, [deleteToastMessage]);

  // Redirect to 404 if not authorized
  useEffect(() => {
    const apiCompleted =
      getDocumentResult.called &&
      (getDocumentResult.networkStatus === NetworkStatus.ready ||
        getDocumentResult.networkStatus === NetworkStatus.error);
    if (apiCompleted && !file) {
      router.replace('/404');
    }
  }, [authorisatonLevel, file, getDocumentResult.called, getDocumentResult.networkStatus, router]);

  /* Render */

  const availableLinkTypes = useMemo(() => {
    return [ProductModel.GRAPHQL_TYPE];
  }, []);
  const blockActions = !file || isReplacingFile || deleteDocumentAPIResult.loading;
  const friendlyDocumentType = useDocumentType(file, { fullType: true });
  const previewSize = useMobileLayout ? screenSize.width - 100 : 400;

  const pageSubmenu = (
    <div className={styles.submenu}>
      <Text className={styles.submenuTitle}>
        {t('FILE')}{' '}
        <Tag className={styles.submenuRefId} size='sm'>
          {file?.REFID || REFID}
        </Tag>
      </Text>
      <div className={styles.submenuButtons}>
        {file && (
          <LinkToFile
            className={styles.submenuButton}
            disabled={blockActions}
            file={file}
            icon={ICONS.DOWNLOAD.name}
            linkToDownload
          >
            {useDesktopLayout && t('DOWNLOAD')}
          </LinkToFile>
        )}
        <div className={`${styles.submenuButton} ${styles.submenuButtonContainer}`}>
          <Button
            disabled={blockActions}
            icon={ICONS.SHARE.name}
            linkStyle
            onClick={hasPermissionToEdit ? openSharePopup : share}
          >
            {useDesktopLayout && t('SHARE')}
          </Button>
          <FadeInOutTransition appear in={!!shareToastMessage}>
            <Toast className={styles.shareToast}>{shareToastMessage}</Toast>
          </FadeInOutTransition>
        </div>
        {hasPermissionToEdit && (
          <div className={`${styles.submenuButton} ${styles.submenuButtonContainer}`}>
            <Button
              disabled={blockActions}
              icon={ICONS.TRASH.name}
              linkStyle
              onClick={promptConfirmDelete}
              tint='alert'
            >
              {useDesktopLayout && t('DELETE')}
            </Button>
            <FadeInOutTransition appear in={!!deleteToastMessage}>
              <Toast className={styles.shareToast}>{deleteToastMessage}</Toast>
            </FadeInOutTransition>
          </div>
        )}
      </div>
      <Grade className={styles.reliabilityScore} score={file?.conformityPoint} type='conformity' />
    </div>
  );

  let fileDetails;
  if (!getDocumentResult.called || getDocumentResult.loading) {
    fileDetails = (
      <TextLayout>
        <Icon height={40} name={ICONS.SPINNER.name} width={40} />
      </TextLayout>
    );
  } else if (getDocumentResult.error) {
    fileDetails = (
      <TextLayout>
        <Text>
          {t('FILE_ARCHIVE_PAGE_LOADING_ERROR_PART_1')}{' '}
          <Button linkStyle onClick={() => router.reload()}>
            {t('FILE_ARCHIVE_PAGE_LOADING_ERROR_REFRESH')}
          </Button>{' '}
          {t('FILE_ARCHIVE_PAGE_LOADING_ERROR_PART_2')}
        </Text>
      </TextLayout>
    );
  } else if (!file) {
    fileDetails = null;
  } else {
    let fields = [];
    if (documentConfig) {
      if (documentConfig.fields.includes(DocumentModel.FIELD_NAMES.DOCUMENT_NUMBER)) {
        fields.push(
          <div key={DocumentModel.FIELD_NAMES.DOCUMENT_NUMBER}>
            <div className={styles.field}>
              <Text bold className={styles.fieldName}>
                {t('FILE_FIELD_DOCUMENT_NUMBER')}
              </Text>
              {hasPermissionToEdit ? (
                <Input
                  className={styles.fieldValue}
                  onChange={setDocumentNumber}
                  placeholder={t('FILE_FIELD_DOCUMENT_NUMBER_PLACEHOLDER')}
                  value={file.documentNumber}
                />
              ) : (
                <Text className={styles.fieldValue}>{file.documentNumber}</Text>
              )}
            </div>
            {documentNumberError && (
              <Text className={styles.errorMessage} size='sm' tint='alert'>
                {documentNumberError}
              </Text>
            )}
          </div>
        );
      }
      if (documentConfig.fields.includes(DocumentModel.FIELD_NAMES.LANGUAGES)) {
        fields.push(
          <div key={DocumentModel.FIELD_NAMES.LANGUAGES}>
            <div className={styles.field}>
              <Text bold className={styles.fieldName}>
                {t('FILE_FIELD_LANGUAGES')}{' '}
                {hasPermissionToEdit && <span className={styles.normalFontWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>}
              </Text>
              <div className={`${styles.fieldValue} ${styles.tags}`}>
                {file.coveredLanguageList.map((languageIso) => (
                  <Tag
                    className={styles.tag}
                    key={languageIso}
                    onClickRemove={removeLanguage}
                    removeable={hasPermissionToEdit}
                    size='sm'
                    uniqueId={languageIso}
                  >
                    {languageIso}
                  </Tag>
                ))}
              </div>
              {hasPermissionToEdit && (
                <LanguageSearch
                  addLanguageButtonTitle={t('NOTE_CARD_ADD_LANGUAGE')}
                  className={styles.button}
                  excludeLanguages={file.coveredLanguageList}
                  onSelectedLanguage={addLanguage}
                />
              )}
            </div>
            {languageError && (
              <Text className={styles.errorMessage} size='sm' tint='alert'>
                {languageError}
              </Text>
            )}
          </div>
        );
      }
      if (documentConfig.fields.includes(DocumentModel.FIELD_NAMES.APPLICABLE_MARKETS)) {
        fields.push(
          <div key={DocumentModel.FIELD_NAMES.APPLICABLE_MARKETS}>
            <div className={styles.field}>
              <Text bold className={styles.fieldName}>
                {t('FILE_FIELD_MARKETS')}{' '}
                {hasPermissionToEdit && <span className={styles.normalFontWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>}
              </Text>
              <div className={`${styles.fieldValue} ${styles.tags}`}>
                {file.applicableMarketList.map((marketIso) => (
                  <Tag
                    className={styles.tag}
                    key={marketIso}
                    onClickRemove={removeMarket}
                    removeable={hasPermissionToEdit}
                    size='sm'
                    uniqueId={marketIso}
                  >
                    {marketIso}
                  </Tag>
                ))}
              </div>
              {hasPermissionToEdit && (
                <RegionSearch
                  buttonTitle={t('ADD_APPLICABLE_MARKET')}
                  className={styles.button}
                  excludedRegionISOs={file.applicableMarketList}
                  onSelectedRegion={addMarket}
                  regionType='market'
                />
              )}
            </div>
            {marketError && (
              <Text className={styles.errorMessage} size='sm' tint='alert'>
                {marketError}
              </Text>
            )}
          </div>
        );
      }
      if (documentConfig.fields.includes(DocumentModel.FIELD_NAMES.MADE_BY_ORGANISATION)) {
        let searchSuggestions;
        if (madeBySearch.length > 1) {
          searchSuggestions = searchOrganisationAPIResult.data?.search?.resultList.map((r) => {
            return {
              key: r.dataId,
              title: r.organisationData.name,
            };
          });
        }
        fields.push(
          <div key={DocumentModel.FIELD_NAMES.MADE_BY_ORGANISATION}>
            <div className={styles.field}>
              <div className={styles.fieldHeader}>
                <Text bold className={styles.fieldName}>
                  {t('FILE_FIELD_MADE_BY')}
                </Text>
                {hasPermissionToEdit && file.madeByOrganisation && (
                  <Button
                    linkStyle
                    loading={removeIssuingOrganisationAPIResult.loading}
                    onClick={removeIssuingOrganisation}
                    tint='alert'
                  >
                    {t('REMOVE')}
                  </Button>
                )}
              </div>
              {file.madeByOrganisation ? (
                <OrganisationArchiveCard
                  className={styles.madeByOrganisationCard}
                  fixedWidth={false}
                  layoutOverride='mobile'
                  linkToOrganisation={false}
                  organisation={file.madeByOrganisation}
                />
              ) : hasPermissionToEdit ? (
                <div className={styles.searchContainer}>
                  <SearchInputAsButton
                    buttonProps={{
                      loading: setIssuingOrganisationAPIResult.loading,
                    }}
                    className={`${styles.fieldValue} ${styles.searchInput}`}
                    icon={ICONS.PLUS.name}
                    inputProps={{
                      loading: searchOrganisationAPIResult.loading,
                      onBlur: () => {
                        setMadeByInputFocused(false);
                      },
                      onFocus: () => {
                        setMadeByInputFocused(true);
                      },
                    }}
                    onChange={onSearchIssuingOrganisations}
                    placeholder={t('ADD_ISSUING_ORGANISATION')}
                    ref={madeBySearchInputRef}
                    tint='creation'
                    value={madeBySearch}
                  />
                  {madeByInputFocused && (
                    <SearchSuggestions
                      className={styles.searchSuggestions}
                      highlightTerm={madeBySearch}
                      onSuggestionClick={setIssuingOrganisation}
                      showMoreResultsMessage={
                        searchOrganisationAPIResult.data?.search?.searchPaginationResultDataByDataType?.ORGANISATION
                          ?.totalResults > searchOrganisationAPIResult.data?.search?.resultList?.length
                      }
                      showNoResultsMessage={searchOrganisationAPIResult.data?.search?.resultList?.length === 0}
                      suggestions={searchSuggestions}
                    />
                  )}
                </div>
              ) : null}
            </div>
            {madeByError && (
              <Text className={styles.errorMessage} size='sm' tint='alert'>
                {madeByError}
              </Text>
            )}
          </div>
        );
      }
      if (documentConfig.fields.includes(DocumentModel.FIELD_NAMES.VALID_FROM)) {
        fields.push(
          <div key={DocumentModel.FIELD_NAMES.VALID_FROM}>
            <div className={styles.field}>
              <Text bold className={styles.fieldName}>
                {t('FILE_FIELD_VALID_FROM')}
              </Text>
              {hasPermissionToEdit ? (
                <Input
                  className={styles.fieldValue}
                  keepFocusOnClear={false}
                  onChange={setValidFrom}
                  placeholder={t('FILE_FIELD_VALID_FROM_PLACEHOLDER')}
                  type='date'
                  value={
                    file.validFrom
                      ? formatDate(new Date(parseInt(file.validFrom, 10)), locale, { style: 'inputValue' })
                      : ''
                  }
                />
              ) : (
                <Text>{file.validFrom ? formatDate(file.validFrom, locale) : ''}</Text>
              )}
            </div>
            {validFromError && (
              <Text className={styles.errorMessage} size='sm' tint='alert'>
                {validFromError}
              </Text>
            )}
          </div>
        );
      }
      if (documentConfig.fields.includes(DocumentModel.FIELD_NAMES.VALID_UNTIL)) {
        fields.push(
          <div key={DocumentModel.FIELD_NAMES.VALID_UNTIL}>
            <div className={styles.field}>
              <Text bold className={styles.fieldName}>
                {t('FILE_FIELD_VALID_UNTIL')}
              </Text>
              {hasPermissionToEdit ? (
                <Input
                  className={styles.fieldValue}
                  keepFocusOnClear={false}
                  onChange={setValidUntil}
                  placeholder={t('FILE_FIELD_VALID_UNTIL_PLACEHOLDER')}
                  type='date'
                  value={
                    file.validTill
                      ? formatDate(new Date(parseInt(file.validTill, 10)), locale, { style: 'inputValue' })
                      : ''
                  }
                />
              ) : (
                <Text>{file.validTill ? formatDate(file.validTill, locale) : ''}</Text>
              )}
            </div>
            {validUntilError && (
              <Text className={styles.errorMessage} size='sm' tint='alert'>
                {validUntilError}
              </Text>
            )}
          </div>
        );
      }
      if (documentConfig.fields.includes(DocumentModel.FIELD_NAMES.STANDARDS)) {
        let searchSuggestions;
        if (standardsSearch.length) {
          const matchRegExp = new RegExp(standardsSearch, 'i');
          searchSuggestions = documentStandards
            .filter((s) => {
              if (file.documentStandardList?.includes(s.typeId)) {
                return false;
              }

              return s.title.match(matchRegExp);
            })
            .map((s) => {
              return {
                key: s.typeId,
                title: s.title,
              };
            });
        }
        fields.push(
          <div key={DocumentModel.FIELD_NAMES.STANDARDS}>
            <div className={styles.field}>
              <Text bold className={styles.fieldName}>
                {t('FILE_FIELD_STANDARDS')}
              </Text>
              <div>
                {file.documentStandardList?.map((standardId) => {
                  const title = documentStandards.find((s2) => s2.typeId === standardId).title;
                  return (
                    <Tag
                      className={styles.tag}
                      key={standardId}
                      onClickRemove={removeStandard}
                      removeable={hasPermissionToEdit}
                      uniqueId={standardId}
                    >
                      {title}
                    </Tag>
                  );
                })}
              </div>
              {hasPermissionToEdit && (
                <div className={styles.searchContainer}>
                  <SearchInputAsButton
                    className={`${styles.fieldValue} ${styles.searchInput}`}
                    icon={ICONS.PLUS.name}
                    inputProps={{
                      onBlur: () => {
                        setStandardsInputFocused(false);
                      },
                      onFocus: () => {
                        setStandardsInputFocused(true);
                      },
                    }}
                    onChange={onSearchStandards}
                    placeholder={t('FILE_FIELD_STANDARDS_PLACEHOLDER')}
                    ref={standardsSearchInputRef}
                    tint='creation'
                    value={standardsSearch}
                  />
                  {standardsInputFocused && (
                    <SearchSuggestions
                      className={styles.searchSuggestions}
                      highlightTerm={standardsSearch}
                      onSuggestionClick={addStandard}
                      showMoreResultsMessage={searchSuggestions?.length > 4}
                      showNoResultsMessage={searchSuggestions?.length === 0}
                      suggestions={searchSuggestions?.slice(0, 4)}
                    />
                  )}
                </div>
              )}
            </div>
            {standardsError && (
              <Text className={styles.errorMessage} size='sm' tint='alert'>
                {standardsError}
              </Text>
            )}
          </div>
        );
      }
    }
    const fileInfo = (
      <TextLayout className={styles.fileInfoContainer}>
        <Text bold className={styles.filename} tint='navigation'>
          {file.documentName}
        </Text>
        {hasPermissionToEdit && (
          <>
            <Button
              block
              className={styles.button}
              disabled={blockActions}
              icon={ICONS.REPLACE.name}
              loading={isReplacingFile}
              onClick={() => mediaPickerRef.current.open()}
              tint='navigation'
            >
              {t('REPLACE_FILE')}
            </Button>
            <MediaPicker
              invisible
              mediaTypes={['document']}
              onChange={onSelectedReplacementFile}
              ref={mediaPickerRef}
            />
            {replaceFileError && (
              <Text className={styles.errorMessage} size='sm' tint='alert'>
                {replaceFileError}
              </Text>
            )}
          </>
        )}
        <div className={styles.field}>
          <Text bold className={styles.fieldName}>
            {t('FILE_FIELD_SIZE')}
          </Text>
          <Text className={styles.fieldValue}>{formatFilesize(file.metadata.filesize)}</Text>
        </div>
        <div className={styles.field}>
          <div className={styles.fieldHeader}>
            <Text bold className={styles.fieldName}>
              {t('FILE_FIELD_TYPE')}
            </Text>
            {hasPermissionToEdit && file.documentType !== 'PRODUCT_FILE' && (
              <Button linkStyle onClick={removeDocumentType} tint='alert'>
                {t('REMOVE')}
              </Button>
            )}
          </div>
          {file.documentType !== 'PRODUCT_FILE' ? (
            <Text className={styles.fieldValue}>{friendlyDocumentType}</Text>
          ) : hasPermissionToEdit ? (
            <div className={styles.searchContainer}>
              <SearchInputAsButton
                className={`${styles.fieldValue} ${styles.searchInput}`}
                icon={ICONS.PLUS.name}
                inputProps={{
                  onBlur: () => {
                    setTypeInputFocused(false);
                  },
                  onFocus: () => {
                    setTypeInputFocused(true);
                  },
                }}
                onChange={onSearchType}
                placeholder={t('FILE_FIELD_TYPE_PLACEHOLDER')}
                tint='creation'
                value={typeSearch}
              />
              {typeInputFocused && documentTypes?.length > 0 && (
                <SearchSuggestions
                  className={styles.searchSuggestions}
                  highlightTerm={typeSearch}
                  onSuggestionClick={setDocumentType}
                  suggestions={documentTypes
                    .filter((type) => {
                      const regex = new RegExp(typeSearch, 'i');
                      return type.title.match(regex) || type.fullTitle.match(regex);
                    })
                    .map((type) => {
                      return {
                        key: type.typeId,
                        title: type.fullTitle,
                      };
                    })}
                />
              )}
            </div>
          ) : null}
        </div>
        {typeError && (
          <Text className={styles.errorMessage} size='sm' tint='alert'>
            {typeError}
          </Text>
        )}
        {fields}
      </TextLayout>
    );

    let filePreview;
    if (file) {
      const fileExtension = getFileExtension(file.documentName);
      switch (fileExtension) {
        case '.pdf':
          filePreview = (
            <PDFPreview className={styles.filePreviewContainer} fileUrl={file.documentURL} size={previewSize} />
          );
          break;
        default:
          filePreview = (
            <GenericFilePreview
              className={styles.filePreviewContainer}
              message={t('NO_PREVIEW_AVAILABLE')}
              size={previewSize}
            />
          );
          break;
      }
    }

    fileDetails = (
      <div className={styles.fileDetailsContainer}>
        <TextLayout>{filePreview}</TextLayout>
        {fileInfo}
      </div>
    );
  }

  const pageMenu = (
    <PageMenu
      assetName={file?.documentName}
      sections={[]}
      showBackButton
      smartTabs
      submenu={pageSubmenu}
      submenuClassName={styles.pageSubmenu}
      verticalOffsetPx={70}
    />
  );
  return (
    <>
      <PageTitle>{file?.documentName ? `${t('FILE')} - ${file.documentName}` : t('FILE')}</PageTitle>
      {showInPopup ? pageMenu : <Menu>{pageMenu}</Menu>}
      <ContentLayout className={styles.basicsContainer} ref={basicsSectionRef} pageContent>
        <UIContext.Provider value='card'>
          <TextLayout>
            <h2 className={styles.header}>
              {t('FILE')} <Tag className={styles.refId}>{file?.REFID || REFID}</Tag>
            </h2>
          </TextLayout>
          {fileDetails}
        </UIContext.Provider>
      </ContentLayout>
      {hasPermissionToEdit && file && (
        <ArchiveLinksSection
          availableLinkTypes={availableLinkTypes}
          className={styles.linksSection}
          disableActions={blockActions}
          linkedList={file.linkedProductList}
          linksFilter={linksFilter}
          linksType={ProductModel.GRAPHQL_TYPE}
          onChangePublished={onChangePublishedOnProduct}
          onClickLinksFilter={setLinksFilter}
          onClickUnlink={unlinkFromProduct}
          parentResource={file}
          ref={linksSectionRef}
        />
      )}
      {hasPermissionToPostComments && (
        <ContentLayout className={styles.commentSection} pageContent>
          <TextLayout>
            <h3 className={styles.commentSectionHeader}>{t('COMMENTS_SECTION_HEADER')}</h3>
            <CommentsController
              assetType='DOCUMENT_ASSET'
              assetId={file?.id}
              assetOrganisationId={file?.organisationId}
            />
          </TextLayout>
        </ContentLayout>
      )}
      {showConfirmDeleteAlert && (
        <Alert
          buttons={
            <>
              <Button block className={styles.deleteFileButton} onClick={deleteFile} tint='alert'>
                {t('DELETE_FILE_ALERT_CONFIRM')}
              </Button>
              <Button block onClick={() => setShowConfirmDeleteAlert(false)} linkStyle>
                {t('DELETE_FILE_ALERT_CANCEL')}
              </Button>
            </>
          }
          message={t('DELETE_FILE_ALERT_MESSAGE')}
          title={t('DELETE_FILE_ALERT_TITLE')}
        />
      )}
      {renderSharePopup && (
        <ShareAssetPopup
          asset={file}
          assetId={file.id}
          assetPublishStatus={file.publishStatus}
          assetType='DOCUMENT_ASSET'
          dismiss={closeSharePopup}
          sharedToList={file.assetAccessPermissionList}
        />
      )}
      {showAddWorkPopup && (
        <AddWorkPopup
          dismiss={() => setShowAddWorkPopup(false)}
          data={file}
          preSelectedData={file}
          preSelectedDataType={'DOCUMENT_ASSET'}
        />
      )}
    </>
  );
}

FileEditPage.layoutProps = {
  contentTopPadding: false,
  padFooter: false,
};

FileEditPage.propTypes = {
  REFIDasProp: PropTypes.string,
};

export async function getServerSideProps({ locale }) {
  const i18nProps = await serverSideTranslations(locale, ['common']);
  return { props: { ...i18nProps } };
}
