import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import AppSettings from 'OK/components/appSettings';
import Button from 'OK/components/button';
import ContentLayout from 'OK/components/layouts/content';
import { updateMiscAppStateAction } from 'OK/state/app/actions';
import UIContext from 'OK/util/context/ui';
import useI18n from 'OK/util/hooks/useI18n';

export default function FocusLayout(props) {
  const { t } = useI18n();
  const {
    backButtonLabelKey = 'MENU_BUTTON_BACK',
    backgroundImage,
    backgroundPosition,
    children,
    className,
    contentClassName,
    contentInnerClassName,
    customHeaderView,
    onBack,
    progressPercent = undefined,
    showAppSettings = false,
    showBackButton = false,
  } = props;

  // Variables
  const dispatch = useDispatch();
  const router = useRouter();
  const showProgress = typeof progressPercent !== 'undefined';
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const enableMenu = showBackButton || showProgress || showAppSettings;

  // Functions
  const back = () => {
    if (onBack) {
      onBack();
    } else {
      router.back();
    }
  };

  // Update app state to indicate that the main menu is not visible
  useEffect(() => {
    dispatch(updateMiscAppStateAction({ menuIsVisible: false, tabsAreVisible: false }));

    return function () {
      dispatch(updateMiscAppStateAction({ menuIsVisible: true, tabsAreVisible: useMobileLayout }));
    };
  }, [dispatch, useMobileLayout]);

  // Render

  let _customHeaderView;
  if (customHeaderView) {
    _customHeaderView = customHeaderView;
  } else if (showProgress) {
    const progressValue = parseInt(progressPercent);
    _customHeaderView = (
      <div className={styles.progressBar}>
        <div className={styles.currentProgress} style={{ width: progressValue }} />
      </div>
    );
  }

  let classNames = styles.container;
  if (className) {
    classNames += ` ${className}`;
  }

  let contentClassNames = styles.contentContainer;
  if (contentClassName) {
    contentClassNames += ` ${contentClassName}`;
  }

  let contentInnerClassNames = styles.contentInnerContainer;
  if (enableMenu) {
    contentInnerClassNames += ` ${styles.withMenu}`;
  }
  if (contentInnerClassName) {
    contentInnerClassNames += ` ${contentInnerClassName}`;
  }

  return (
    <div className={classNames}>
      <UIContext.Provider value='focus'>
        {enableMenu && (
          <ContentLayout
            alignContent='right'
            className={styles.menuContainer}
            contentClassName={styles.menu}
            flex
            noPadding
          >
            {showBackButton && (
              <Button className={styles.backButton} iconPosition='left' linkStyle onClick={back} withCaret>
                {t(backButtonLabelKey)}
              </Button>
            )}
            {_customHeaderView}
            {showAppSettings && <AppSettings className={styles.appSettings} />}
          </ContentLayout>
        )}
        <ContentLayout
          backgroundImage={backgroundImage}
          backgroundPosition={backgroundPosition}
          className={contentClassNames}
          contentClassName={contentInnerClassNames}
          noPadding
          padTop={false}
        >
          {children}
        </ContentLayout>
      </UIContext.Provider>
    </div>
  );
}

FocusLayout.propTypes = {
  backButtonLabelKey: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  contentInnerClassName: PropTypes.string,
  customHeaderView: PropTypes.node,
  onBack: PropTypes.func,
  progressPercent: PropTypes.number,
  showAppSettings: PropTypes.bool,
  showBackButton: PropTypes.bool,
};
