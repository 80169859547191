import {
  DISMISS_COOKIES_REQUIRED_ALERT_ACTION,
  DISMISS_NOTIFICATION_ACTION,
  DISMISS_SCANNER_ACTION,
  MISC_APP_STATE_UPDATE,
  RESET_SCANNED_OKID_ACTION,
  SCANNED_OKID_ACTION,
  SEARCH,
  SET_USER_PERMITS_COOKIES,
  SHOW_AUTH_MODAL,
  SHOW_COOKIES_REQUIRED_ALERT_ACTION,
  SHOW_NOTIFICATION_ACTION,
  SHOW_POPUP,
  SHOW_SCANNER_ACTION,
  SET_ACTIVE_SITE_SECTION,
  SET_LAST_VISITED_SECTION,
  SET_BROWSER_HISTORY,
  SHOW_IN_POPUP,
  ADD_HISTORY,
  CLEAR_HISTORY,
  POP_HISTORY,
} from './actions';

import { PERSISTENT_STORAGE_KEY } from 'OK/util/enums/persistentStorageKeys';
import { getPersistentValue, setPersistentValue } from 'OK/util/storage';

export const initialState = {
  activeSiteSection: null,
  activeSiteSectionLastVisitedPaths: {},
  archiveColumnCardWidth: 253.75,
  defaultContentWidth: 0,
  history: null,
  isBrowser: true,
  isInstalledAsPWA: false,
  isNavigatingBack: false,
  isOnline: true,
  isScrolledToTop: true,
  isScrollingPopup: false,
  isServer: false,
  isShowingPWABanner: false,
  isShowingStatusBanner: false,
  listenToScrolling: true,
  menuIsVisible: true,
  notifications: [],
  popupWidth: 1140,
  scanFunction: null,
  scanInstructions: null,
  scanInstructionsLegacy: null,
  scannedOKID: null,
  screenSize: { height: 667, width: 375 },
  searchQuery: undefined,
  showAuthModal: false,
  showCookiesRequiredAlert: false,
  showInPopup: false,
  showMenuBackground: true,
  showPopup: false,
  showScanner: false,
  lastSelectedArchiveSubTabId: null,
  tabsAreVisible: true,
  useDesktopLayout: false,
  useMobileLayout: true,
  userAgent: {},
  userPermitsCookies:
    typeof window !== 'undefined' ? getPersistentValue(PERSISTENT_STORAGE_KEY.USER_PERMITS_COOKIES) : '0',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DISMISS_COOKIES_REQUIRED_ALERT_ACTION:
      return {
        ...state,
        showCookiesRequiredAlert: false,
      };
    case DISMISS_NOTIFICATION_ACTION: {
      // Find the notification to dismiss and set its property 'dismissed' to true.
      const notificationIndex = state.notifications.findIndex((n) => n.id === action.data.notificationId);
      if (notificationIndex > -1) {
        const notification = state.notifications[notificationIndex];
        notification.dismissed = true;
        const notifications = [...state.notifications];
        notifications.splice(notificationIndex, 1, notification);
        return {
          ...state,
          notifications,
        };
      } else {
        return state;
      }
    }
    case DISMISS_SCANNER_ACTION:
      return {
        ...state,
        scanFunction: null,
        scanInstructions: null,
        scanInstructionsLegacy: null,
        showScanner: false,
      };
    case MISC_APP_STATE_UPDATE:
      return {
        ...state,
        ...action.data.stateUpdates,
      };
    case RESET_SCANNED_OKID_ACTION:
      return {
        ...state,
        scannedOKID: null,
      };
    case SCANNED_OKID_ACTION:
      return {
        ...state,
        scannedOKID: action.data.OKID,
      };
    case SEARCH:
      return {
        ...state,
        searchQuery: action.data.searchQuery,
      };
    case SET_USER_PERMITS_COOKIES:
      setPersistentValue(PERSISTENT_STORAGE_KEY.USER_PERMITS_COOKIES, action.data.permits);
      return {
        ...state,
        userPermitsCookies: action.data.permits,
      };
    case SHOW_COOKIES_REQUIRED_ALERT_ACTION:
      return {
        ...state,
        showCookiesRequiredAlert: true,
      };
    case SHOW_NOTIFICATION_ACTION: {
      const notification = {
        ...action.data.notification,
        id: state.notifications.length + 1,
      };
      return {
        ...state,
        notifications: [...state.notifications, notification],
      };
    }
    case SHOW_POPUP:
      return {
        ...state,
      };
    case SHOW_SCANNER_ACTION:
      return {
        ...state,
        scanFunction: action.data.onScan,
        scanInstructions: action.data.instructions,
        scanInstructionsLegacy: action.data.instructionsLegacy,
        showScanner: true,
      };
    case SET_ACTIVE_SITE_SECTION:
      return {
        ...state,
        activeSiteSection: action.data.activeSiteSection,
      };
    case SET_LAST_VISITED_SECTION:
      return {
        ...state,
        activeSiteSectionLastVisitedPaths: {
          ...state.activeSiteSectionLastVisitedPaths,
          [action.data.section]: action.data.path,
        },
      };
    case SHOW_AUTH_MODAL:
      return {
        ...state,
        showAuthModal: action.data.showAuthModal,
      };
    case SET_BROWSER_HISTORY:
      return {
        ...state,
        history: {
          ...state.history,
          state: action.data.history.state,
        },
      };
    case SHOW_IN_POPUP:
      return {
        ...state,
        showInPopup: action.data.showInPopup,
      };
    case ADD_HISTORY:
      return {
        ...state,
        entries: [...state.entries, action.data.entry],
      };
    case CLEAR_HISTORY:
      return {
        ...state,
        entries: [],
      };
    case POP_HISTORY:
      return {
        ...state,
        entries: state.entries.slice(0, state.entries.length - 1),
      };
    default:
      return state;
  }
}
