/**
 * Get the precision of a number.
 *
 * @param {number|string} number The number to evaluate.
 *
 * @returns {number} The precision of the number.
 */
export default function getPrecisionOfNumber(number) {
  // Validate number
  if (!isFinite(number)) {
    // Return 0 for invalid numbers.
    return 0;
  }

  const numberString = `${number}`;
  const numberComponentMatches = /^([0-9]+)\.?([0-9]*)$/.exec(numberString);
  if (!numberComponentMatches || numberComponentMatches.length <= 2) {
    // Return 0 if unable to identify components of number.
    return 0;
  }

  const decimals = numberComponentMatches[2];
  return decimals.length;
}
