/**
 * Evaluate if a string is a valid OKID. Matches organisation, coworker, and product OKID formats.
 *
 * @param {string} str The string to evaluate.
 */
export function isOKID(str) {
  return /(?!.+-$)^(([a-zA-Z0-9]){4}-?){1,3}$/.test(str);
}

/**
 * Evaluate if a string is a valid coworker OKID.
 *
 * @param {string} str The string to evaluate.
 */
export function isCoworkerOKID(str) {
  return /^([a-zA-Z0-9]){4}-([a-zA-Z0-9]){4}$/.test(str);
}

/**
 * Evaluate if a string is a valid organisation OKID.
 *
 * @param {string} str The string to evaluate.
 */
export function isOrganisationOKID(str) {
  return /^([a-zA-Z0-9]){4}$/.test(str);
}

/**
 * Evaluate if a string is a valid product OKID.
 *
 * @param {string} str The string to evaluate.
 */
export function isProductOKID(str) {
  return /^([a-zA-Z0-9]){4}-([a-zA-Z0-9]){4}-([a-zA-Z0-9]){4}$/.test(str);
}

export function parseOKID(str) {
  const matches = /(?:okgrade\.com|oktrade\.org).*\/((([a-zA-Z0-9]){4}-?){1,3})$/.exec(str);

  if (matches?.length > 1) {
    return matches[1].toUpperCase();
  }

  return null;
}
