import Request from 'OK/networking/models/request';

/** Makes a `fetch` request.
 *
 * @returns {(object | string)} The response data.
 *
 * @param {string} resource
 * @param {object} options
 *
 * @see `Request` class for full parameter documentation.
 *
 * @deprecated Use named export `request` for full access to the request object. This function only returns the
 * response data.
 */
export default async function deprecated_request(resource, options) {
  const req = new Request(resource, options);
  await req.run();
  return req.responseData;
}

/** Makes a `fetch` request.
 *
 * @returns {Request} The `Request` object.
 *
 * @param {string} resource
 * @param {object} options
 *
 * @see `Request` class for full parameter documentation.
 */
export async function request(resource, options) {
  const req = new Request(resource, options);
  await req.run();
  return req;
}
