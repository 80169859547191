import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Locked from './locked';
import Login from './login';
import Verify from './verify';

import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import { clearSessionAction } from 'OK/state/account/actions';

export default function AuthModule(props) {
  const accountState = useSelector((state) => state.account);

  const [loginMethod, setLoginMethod] = useState('email');
  const [showVerifying, setShowVerifying] = useState(null);
  const [showIsLocked, setShowIsLocked] = useState(null);
  const [showIsNewUser, setShowIsNewUser] = useState(null);

  // Functions
  const dispatch = useDispatch();
  const changeLoginMethod = (newMethod) => {
    setLoginMethod(newMethod);
    // Clear any login flow state
    dispatch(clearSessionAction());
  };

  // Effects

  useEffect(() => {
    setShowVerifying(accountState.isVerifying);
    setShowIsLocked(accountState.isLocked);
    setShowIsNewUser(!showVerifying && !showIsLocked && true);
  }, [accountState.isLocked, accountState.isNewUser, accountState.isVerifying, showIsLocked, showVerifying]);

  // Render

  if (accountState.isLocked) {
    const { lockedUntil } = accountState;
    return (
      <FadeInOutTransition in={showIsLocked}>
        <Locked lockedUntil={lockedUntil} loginMethod={loginMethod} {...props} />
      </FadeInOutTransition>
    );
  }

  if (accountState.isVerifying) {
    const {
      allowNewOTPRequestDate,
      countryCode,
      isNewUser,
      username,
      usernameType,
      verificationAttemptsRemaining,
      verificationCodeExpirationDate,
      newsletterSubscription,
    } = accountState;
    return (
      <FadeInOutTransition in={showVerifying}>
        <Verify
          allowNewOTPRequestDate={allowNewOTPRequestDate}
          attemptsRemaining={verificationAttemptsRemaining}
          changeLoginMethod={changeLoginMethod}
          countryCode={countryCode}
          expirationDate={verificationCodeExpirationDate}
          isNewUser={isNewUser}
          newsletterSubscription={newsletterSubscription}
          username={username}
          usernameType={usernameType}
          {...props}
        />
      </FadeInOutTransition>
    );
  }

  return (
    <FadeInOutTransition in={showIsNewUser}>
      <Login changeLoginMethod={changeLoginMethod} mode={loginMethod} {...props} />
    </FadeInOutTransition>
  );
}

AuthModule.propTypes = {
  onClose: PropTypes.func,
};
