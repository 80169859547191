import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import styles from './styles.module.scss';

import DraggingContext from 'OK/util/context/dragging';

/**
 * Enables dragging of an element.
 *
 * @param {object} props
 * @param {*} props.children
 * @param {string} [props.className] The draggable container's class.
 * @param {(id: string) => void} [props.onDragEnd] Event handler for when dragging ends. Passes the id of the
 * dragged element.
 * @param {(id: string) => void} [props.onDragStart] Event handler for when dragging starts. Passes the id of the
 * dragged element.
 * @param {string} props.id A unique identifer for the draggable element.
 */
export default function Draggable(props) {
  /* Variables */

  const { children, className, onDragEnd, onDragStart, id, ...otherProps } = props;
  const draggableRef = useRef();

  /* State */

  const [isBeingDragged, setIsBeingDragged] = useState(false);

  /* Events */

  const _onDragEnd = () => {
    setIsBeingDragged(false);

    if (onDragEnd) {
      onDragEnd(id);
    }
  };

  const _onDragStart = (e) => {
    if (e.target !== draggableRef.current) {
      // Prevent children from triggering a drag start.
      // img tags seem to be draggable by default.
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setIsBeingDragged(true);

    // Add drag & drop data
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.setData('text/dragged-id', id);

    if (onDragStart) {
      onDragStart(id);
    }
  };

  /* Render */

  // Classes
  let classNames = styles.container;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <DraggingContext.Provider value={isBeingDragged}>
      <div
        className={classNames}
        draggable
        onDragEnd={_onDragEnd}
        onDragStart={_onDragStart}
        ref={draggableRef}
        {...otherProps}
      >
        {children}
      </div>
    </DraggingContext.Provider>
  );
}

Draggable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
};
