import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import debounce from 'lodash/debounce';
import NextImage from 'next/legacy/image';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Bar as BarChart, Line as LineChart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import countries from 'OK/assets/countries.json';
import Button from 'OK/components/button';
import ButtonGroup from 'OK/components/buttonGroup';
import Chip from 'OK/components/chip';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInputAsButton, { DISPLAY_MODE } from 'OK/components/input/search/asButton';
import CardLayout from 'OK/components/layouts/card';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import Link from 'OK/components/link';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import PageTitle from 'OK/components/pageTitle';
import SearchSuggestions from 'OK/components/searchSuggestions';
import { suggestionObjectForProduct } from 'OK/components/searchSuggestions/util/product';
import { suggestionObjectForSite } from 'OK/components/searchSuggestions/util/site';
import { suggestionObjectForUser } from 'OK/components/searchSuggestions/util/user';
import Separator from 'OK/components/separator';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import ProductModel from 'OK/models/product';
import SiteModel from 'OK/models/site';
import OrganisationCatalogueSection from 'OK/modules/pages/organisation/[OKID]/catalogueSection';
import OrganisationDocumentationSection from 'OK/modules/pages/organisation/[OKID]/documentationSection';
import OrganisationLogHistorySection from 'OK/modules/pages/organisation/[OKID]/logHistorySection';
import OrganisationMembersSection from 'OK/modules/pages/organisation/[OKID]/membersSection';
import OrganisationPlanSection from 'OK/modules/pages/organisation/[OKID]/planSection';
import { EditOrganisationLogoPopup } from 'OK/modules/popups/editOrganisationLogo';
import { EditOrganisationInfoPopup } from 'OK/modules/popups/organisationInfo';
import { EditOrganisationNamePopup } from 'OK/modules/popups/organisationName';
import {
  AggregatedInspectionLogDataForOrganisationQuery,
  LatestInspectionLogsForOrganisationQuery,
} from 'OK/networking/inspectionLogs';
import { getOrganisationByIdManagerQuery, OrganisationArchivePageQuery } from 'OK/networking/organisations';
import { searchQuery } from 'OK/networking/search';
import {
  generateAreaChartConfig,
  generateBarChartConfig,
  generateDashedLineAnnotationConfig,
} from 'OK/util/chartjs/config';
import { formatChartDataForAggregatedLogs, formatChartDataForLatestLogs } from 'OK/util/chartjs/format';
import ChartJSHtmlLegendPlugin from 'OK/util/chartjs/htmlLegendPlugin';
import { LATEST_LOGS_CHART_TYPE } from 'OK/util/chartjs/types';
import {
  DEBOUNCE_TIMING_MS_SHORT,
  OK_QUALITY_AQL_CRITICAL_PERCENT,
  OK_QUALITY_AQL_MAJOR_PERCENT,
  OK_QUALITY_AQL_MINOR_PERCENT,
} from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import UIContext from 'OK/util/context/ui';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import { formatNumber, formatPercentage, formatOkid, formatPoints, formatUrl } from 'OK/util/formatting';
import authorisationForResource from 'OK/util/functions/authorisationForResource';
import isAuthorised, { isAuthorisedViaShare } from 'OK/util/functions/isAuthorised';
import share, { SHARE_ACTION, SHARE_TYPE } from 'OK/util/functions/share';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';
import { getPersistentValue, setPersistentValue } from 'OK/util/storage';

if (typeof window !== 'undefined') {
  Chart.register(...registerables, annotationPlugin, ChartJSHtmlLegendPlugin);
}

function InsufficientPerformanceDataNoLogsMessage() {
  const { t } = useI18n();
  return (
    <Text bold tint='notification' style={{ margin: 0 }}>
      {t('INSUFFICIENT_DATA_FOR_LOG_PERFORMANCE_CHART')}
    </Text>
  );
}

function InsufficientPerformanceDataForOptionsMessage() {
  const { t } = useI18n();
  return (
    <Text bold tint='notification' style={{ margin: 0 }}>
      {t('NO_DATA_FOR_CONFIGURED_LOG_PERFORMANCE_CHART')}
    </Text>
  );
}

export default function OrganisationPage(props) {
  /* Variables */

  const { OKIDasProp } = props;
  const { t, locale } = useI18n();
  const router = useRouter();
  const OKID = router.query.OKID || OKIDasProp;
  const theme = useContext(ThemeContext);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const menuIsVisible = useSelector((state) => state.app.menuIsVisible);
  const showInPopup = useSelector((state) => state.app.showInPopup);

  const AQL_CRITICAL = OK_QUALITY_AQL_CRITICAL_PERCENT * 100;
  const AQL_MAJOR = OK_QUALITY_AQL_MAJOR_PERCENT * 100;
  const AQL_MINOR = OK_QUALITY_AQL_MINOR_PERCENT * 100;
  const CHART_HEIGHT = 200;

  // State

  const [authenticated] = useAuthentication(() => false);
  const [chartCoworkerHighlight, setChartCoworkerHighlight] = useState(null);
  const [chartDisplayType, setChartDisplayType] = useState(LATEST_LOGS_CHART_TYPE.POINTS);
  const [chartFilterIncludeInternalLogs, setChartFilterIncludeInternalLogs] = useState(false);
  const [chartOptionShowQuantity, setChartOptionShowQuantity] = useState(false);
  const [chartProductHighlight, setChartProductHighlight] = useState(null);
  const [chartSiteHighlight, setChartSiteHighlight] = useState(null);
  const [showEditOrganisationName, setShowEditOrganisationName] = useState(false);
  const [showEditOrganisationInfo, setShowEditOrganisationInfo] = useState(false);
  const [showEditOrganisationLogo, setShowEditOrganisationLogo] = useState(false);
  const [renderShareToast, setRenderShareToast] = useState(false);
  const [shareToastMessage, setShareToastMessage] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [openConformityGradeBox, setOpenConformityGradeBox] = useState(false);
  const [searchCoworkersFocused, setSearchCoworkersFocused] = useState(false);
  const [searchCoworkersString, setSearchCoworkersString] = useState('');
  const [searchProductsFocused, setSearchProductsFocused] = useState(false);
  const [searchProductsString, setSearchProductsString] = useState('');
  const [searchSitesFocused, setSearchSitesFocused] = useState(false);
  const [searchSitesString, setSearchSitesString] = useState('');
  const [toggleSearchComparison, setToggleSearchComparison] = useState(false);
  const [showCover, setShowCover] = useState(true);
  const [showMembers, setShowMembers] = useState(true);
  const [showPlan, setShowPlan] = useState(true);
  const [showWork, setShowWork] = useState(true);

  useEffect(() => {
    let cachedCoverBoolean = false;
    let cachedMembersBoolean = false;
    let cachedPlanBoolean = false;
    let cachedWorkBoolean = false;

    const cachedCoverSetting = getPersistentValue('ORGANISATION_COVER_TOGGLE_VALUE');
    const cachedMembersSetting = getPersistentValue('MEMBERS_SECTION_TOGGLE_VALUE');
    const cachedPlanSetting = getPersistentValue('PLAN_SECTION_TOGGLE_VALUE');
    const cachedWorkSetting = getPersistentValue('WORK_SECTION_TOGGLE_VALUE');

    if (cachedCoverSetting) {
      cachedCoverBoolean = cachedCoverSetting === 'true' ? true : false;
    } else {
      cachedCoverBoolean = true;
    }
    if (cachedMembersSetting) {
      cachedMembersBoolean = cachedMembersSetting === 'true' ? true : false;
    } else {
      cachedMembersBoolean = true;
    }
    if (cachedPlanSetting) {
      cachedPlanBoolean = cachedPlanSetting === 'true' ? true : false;
    } else {
      cachedPlanBoolean = true;
    }
    if (cachedWorkSetting) {
      cachedWorkBoolean = cachedWorkSetting === 'true' ? true : false;
    } else {
      cachedWorkBoolean = true;
    }

    setShowCover(cachedCoverBoolean);
    setShowMembers(cachedMembersBoolean);
    setShowPlan(cachedPlanBoolean);
    setShowWork(cachedWorkBoolean);
  }, []);

  const showDataHighlight = chartProductHighlight !== null || chartCoworkerHighlight !== null;

  // Refs

  const basicsSectionRef = useRef();
  const catalogueSectionRef = useRef();
  const documentationSectionRef = useRef();
  const gradesSectionRef = useRef();
  const historySectionRef = useRef();
  const membersSectionRef = useRef();
  const planSectionRef = useRef();
  const searchCoworkersInputRef = useRef();
  const searchProductsInputRef = useRef();
  const searchSitesInputRef = useRef();

  /* API */

  const [getAggregatedLogDataAPI, getAggregatedLogDataAPIResult] = useLazyQuery(
    AggregatedInspectionLogDataForOrganisationQuery,
    { fetchPolicy: 'cache-and-network' }
  );
  const [getAggregatedLogDataForHighlightAPI, getAggregatedLogDataForHighlightAPIResult] = useLazyQuery(
    AggregatedInspectionLogDataForOrganisationQuery,
    { fetchPolicy: 'cache-and-network' }
  );
  const [getOrganisationAPI, getOrganisationAPIResult] = useLazyQuery(OrganisationArchivePageQuery, {
    context: {
      addActiveOrganisationIdHeader: false,
    },
  });

  const [getLatestLogsAPI, getLatestLogsAPIResult] = useLazyQuery(LatestInspectionLogsForOrganisationQuery);
  const [searchCoworkersAPI, searchCoworkersAPIResult] = useLazyQuery(searchQuery);
  const [searchProductsAPI, searchProductsAPIResult] = useLazyQuery(searchQuery);
  const [searchSitesAPI, searchSitesAPIResult] = useLazyQuery(searchQuery);

  const [, , currentUser] = useAuthentication(() => false);
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const getOrganisationByIdManagerAPIResult = useQuery(getOrganisationByIdManagerQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !authenticated || !activeOrganisationId,
    variables: {
      organisationId: activeOrganisationId,
    },
  });
  const activeOrganisation = getOrganisationByIdManagerAPIResult.data?.organisation;
  const organisation = getOrganisationAPIResult.data?.organisation;
  const latestLogs = useMemo(
    () => getLatestLogsAPIResult.data?.inspectionLogs ?? [],
    [getLatestLogsAPIResult.data?.inspectionLogs]
  );

  const authorisationLevel = useAuthorisationLevel(organisation);
  const hasManagementRights = isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER);

  const isManager =
    authorisationForResource(currentUser, activeOrganisationId, organisation) == AUTHORISATION_LEVEL.MANAGER ||
    authorisationForResource(currentUser, activeOrganisationId, organisation) == AUTHORISATION_LEVEL.OWNER;

  const isEditor = hasManagementRights && isManager;

  /* Effects */

  // Redirect if organisation not found
  useEffect(() => {
    const apiCompleted =
      getOrganisationAPIResult.called &&
      (getOrganisationAPIResult.networkStatus === NetworkStatus.ready ||
        getOrganisationAPIResult.networkStatus === NetworkStatus.error);
    if (apiCompleted && !organisation) {
      router.replace('/404');
    }
  }, [getOrganisationAPIResult.called, getOrganisationAPIResult.networkStatus, organisation, router]);

  // Load organisation data
  useEffect(() => {
    if (OKID) {
      getOrganisationAPI({ variables: { OKID } });
    }
  }, [OKID, getOrganisationAPI]);

  useEffect(() => {
    if (authenticated) {
      getLatestLogsAPI({
        variables: {
          includeInternalLogs: chartFilterIncludeInternalLogs,
          organisationId: organisation?.id,
        },
      });
      getAggregatedLogDataAPI({
        variables: {
          includeInternalLogs: chartFilterIncludeInternalLogs,
          organisationId: organisation?.id,
        },
      });
    }
    if (showDataHighlight) {
      let variables = {
        includeInternalLogs: chartFilterIncludeInternalLogs,
        organisationId: organisation?.id,
      };
      if (chartCoworkerHighlight) {
        variables.filterByInspectorIdList = [chartCoworkerHighlight.id];
      }
      if (chartProductHighlight) {
        variables.filterBySourceIdList = [chartProductHighlight.id];
      }
      if (chartSiteHighlight) {
        variables.filterBySourceIdList = [chartSiteHighlight.id];
      }
      getAggregatedLogDataForHighlightAPI({ variables });
    }
  }, [
    authenticated,
    chartCoworkerHighlight,
    chartFilterIncludeInternalLogs,
    chartProductHighlight,
    chartSiteHighlight,
    getAggregatedLogDataAPI,
    getAggregatedLogDataForHighlightAPI,
    getLatestLogsAPI,
    organisation,
    showDataHighlight,
  ]);

  // Hide share toast after 3 seconds
  useEffect(() => {
    if (renderShareToast) {
      setTimeout(() => {
        setRenderShareToast(false);
      }, 3000);
    }
  }, [renderShareToast]);

  // Reset share toast message after hidden
  useEffect(() => {
    if (!renderShareToast) {
      setTimeout(() => {
        setShareToastMessage(null);
      }, 500); // Wait for transition to finish
    }
  }, [renderShareToast]);

  /* Methods */

  const showShareToast = useCallback((message) => {
    setShareToastMessage(message);
    setRenderShareToast(true);
  }, []);

  const shareOrganisation = useCallback(() => {
    share(window.location.href, SHARE_TYPE.URL, true)
      .then((result) => {
        if (result === SHARE_ACTION.CLIPBOARD) {
          showShareToast(t('COPIED_TO_CLIPBOARD'));
        }
      })
      .catch(() => {
        showShareToast(t('ERROR_GENERIC'));
      });
  }, [showShareToast, t]);

  const scrollToGradesSection = useCallback(() => {
    const scrollTop = gradesSectionRef.current.offsetTop;
    window.scrollTo({ top: scrollTop, behavior: 'smooth' });
  }, []);

  const searchProductsDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchProductsAPI({
          variables: {
            searchPaginationDataByDataType: [
              {
                dataType: 'PRODUCT',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchProductsAPI]
  );

  const searchCoworkersDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchCoworkersAPI({
          variables: {
            searchPaginationDataByDataType: [
              {
                dataType: 'USER',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchCoworkersAPI]
  );

  const searchSitesDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchSitesAPI({
          variables: {
            searchPaginationDataByDataType: [
              {
                dataType: 'SITE',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchSitesAPI]
  );

  const onSearchCoworkers = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setSearchCoworkersString(newSearchString);

      if (newSearchString.length > 1) {
        searchCoworkersDebounced(newSearchString);
      }
    },
    [searchCoworkersDebounced]
  );

  const onSearchProducts = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setSearchProductsString(newSearchString);

      if (newSearchString.length > 1) {
        searchProductsDebounced(newSearchString);
      }
    },
    [searchProductsDebounced]
  );

  const onSearchSites = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setSearchSitesString(newSearchString);

      if (newSearchString.length > 1) {
        searchSitesDebounced(newSearchString);
      }
    },
    [searchSitesDebounced]
  );

  const toggleCover = useCallback((currentValue) => {
    setShowCover(currentValue);
    setPersistentValue('ORGANISATION_COVER_TOGGLE_VALUE', currentValue);
  }, []);

  const toggleMembers = useCallback((currentValue) => {
    setShowMembers(currentValue);
    setPersistentValue('MEMBERS_SECTION_TOGGLE_VALUE', currentValue);
  }, []);

  const togglePlan = useCallback((currentValue) => {
    setShowPlan(currentValue);
    setPersistentValue('PLAN_SECTION_TOGGLE_VALUE', currentValue);
  }, []);

  const toggleWork = useCallback((currentValue) => {
    setShowWork(currentValue);
    setPersistentValue('WORK_SECTION_TOGGLE_VALUE', currentValue);
  }, []);

  const filterByUser = useCallback(
    (userId) => {
      const user = searchCoworkersAPIResult.data.search.resultList.find((r) => r.userData.id === userId).userData;
      setChartCoworkerHighlight(user);
      setSearchCoworkersString('');
      searchCoworkersInputRef.current?.setDisplayMode(DISPLAY_MODE.BUTTON);
    },
    [searchCoworkersAPIResult.data?.search?.resultList]
  );

  const filterBySites = useCallback(
    (siteId) => {
      const site = searchSitesAPIResult.data.search.resultList.find((r) => r.siteData.id === siteId).siteData;
      setChartSiteHighlight(site);
      setSearchSitesString('');
      searchSitesInputRef.current?.setDisplayMode(DISPLAY_MODE.BUTTON);
    },
    [searchSitesAPIResult.data?.search?.resultList]
  );
  const filterByProduct = useCallback(
    (productId) => {
      const product = searchProductsAPIResult.data.search.resultList.find(
        (r) => r.productData.id === productId
      ).productData;
      setChartProductHighlight(product);
      setSearchProductsString('');
      searchProductsInputRef.current?.setDisplayMode(DISPLAY_MODE.BUTTON);
    },
    [searchProductsAPIResult.data?.search?.resultList]
  );

  /* Render */

  const apiError = getOrganisationAPIResult.error;
  const loadingPerformanceChartData =
    !getLatestLogsAPIResult.called ||
    getLatestLogsAPIResult.loading ||
    !getAggregatedLogDataAPIResult.called ||
    getAggregatedLogDataAPIResult.loading ||
    (showDataHighlight && !getAggregatedLogDataForHighlightAPIResult.called) ||
    getAggregatedLogDataForHighlightAPIResult.loading;

  let hasRecentLogsForProduct;
  if (!latestLogs.length || !chartProductHighlight) {
    hasRecentLogsForProduct = false;
  } else {
    hasRecentLogsForProduct = false;
    for (let x = 0; x < latestLogs.length; x++) {
      const log = latestLogs[x];
      if (log.productId === chartProductHighlight.id) {
        hasRecentLogsForProduct = true;
        break;
      }
    }
  }

  let hasRecentLogsForCoworker;
  if (!latestLogs.length || !chartCoworkerHighlight) {
    hasRecentLogsForCoworker = false;
  } else {
    hasRecentLogsForCoworker = false;
    for (let x = 0; x < latestLogs.length; x++) {
      const log = latestLogs[x];
      if (log.createdBy === chartCoworkerHighlight.id) {
        hasRecentLogsForCoworker = true;
        break;
      }
    }
  }

  const numberOfProducts = organisation?.productList?.length ?? 0;

  // Performance section
  let chartConfigs;
  if (
    latestLogs.length > 1 &&
    getAggregatedLogDataAPIResult.data?.aggregatedData &&
    (!showDataHighlight || getAggregatedLogDataForHighlightAPIResult.data?.aggregatedData)
  ) {
    const aggregatedLogData = getAggregatedLogDataAPIResult.data.aggregatedData;
    const aggregatedLogDataForHighlight = showDataHighlight
      ? getAggregatedLogDataForHighlightAPIResult.data?.aggregatedData
      : null;

    let annotations;
    let yValues;
    if (chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS) {
      yValues = [...latestLogs.map((l) => l.reliabilityPoint), aggregatedLogData.averageReliabilityPoint];
    } else {
      switch (chartDisplayType) {
        case LATEST_LOGS_CHART_TYPE.MINOR_AQL: {
          if (chartOptionShowQuantity) {
            yValues = [...latestLogs.map((l) => l.sampleMinorFailures), aggregatedLogData.sampleMinorAQL, 10];
          } else {
            annotations = {
              AQLLimitAnnotation: generateDashedLineAnnotationConfig(AQL_MINOR, { theme }),
            };
            yValues = [...latestLogs.map((l) => l.minorAQL), aggregatedLogData.sampleMinorAQL, AQL_MINOR];
          }
          break;
        }
        case LATEST_LOGS_CHART_TYPE.MAJOR_AQL: {
          if (chartOptionShowQuantity) {
            yValues = [...latestLogs.map((l) => l.sampleMajorFailures), aggregatedLogData.sampleMajorAQL, 10];
          } else {
            annotations = {
              AQLLimitAnnotation: generateDashedLineAnnotationConfig(AQL_MAJOR, { theme }),
            };
            yValues = [...latestLogs.map((l) => l.majorAQL), aggregatedLogData.sampleMajorAQL, AQL_MINOR];
          }
          break;
        }
        case LATEST_LOGS_CHART_TYPE.CRITICAL_AQL: {
          if (chartOptionShowQuantity) {
            yValues = [...latestLogs.map((l) => l.sampleCriticalFailures), aggregatedLogData.sampleCriticalAQL, 10];
          } else {
            annotations = {
              AQLLimitAnnotation: generateDashedLineAnnotationConfig(AQL_CRITICAL, { theme }),
            };
            yValues = [...latestLogs.map((l) => l.criticalAQL), aggregatedLogData.sampleCriticalAQL, AQL_MINOR];
          }
          break;
        }
        default:
          break;
      }
    }

    const maxYValue = Math.max(...yValues) * 1.05;

    let latestLogsChartData = formatChartDataForLatestLogs(latestLogs, t, {
      chartDisplayType,
      chartHeight: CHART_HEIGHT,
      dataBackgroundColorRGBA: 'rgba(80, 124, 133, 1)',
      highlightCoworkerId: chartCoworkerHighlight?.id,
      highlightProductId: chartProductHighlight?.id,
      showQuantity: chartOptionShowQuantity,
      theme,
    });
    let aggregatedChartData = formatChartDataForAggregatedLogs(aggregatedLogData, aggregatedLogDataForHighlight, t, {
      chartDisplayType,
      chartHeight: CHART_HEIGHT,
      dataBackgroundColorRGBA: 'rgba(80, 124, 133, 1)',
      showQuantity: chartOptionShowQuantity,
    });

    chartConfigs = {
      aggregatedChart: generateBarChartConfig(aggregatedChartData, {
        annotations,
        maxYValue,
        theme,
        tickFormatter: (tickValue) => {
          if (chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS) {
            return formatPoints(tickValue);
          }

          return chartOptionShowQuantity
            ? parseInt(tickValue, 10)
            : formatPercentage(tickValue, { multiplyBy100: false, showPercentSign: false });
        },
        tooltipLabelCallback: (tooltip) => {
          if (chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS || chartOptionShowQuantity) {
            return formatNumber(tooltip.parsed.y);
          }

          return formatPercentage(tooltip.parsed.y, { multiplyBy100: false });
        },
      }),
      latestLogsChart: generateAreaChartConfig(latestLogsChartData, {
        annotations,
        legendContainerId: 'chartLegendContainer',
        maxYValue,
        theme,
        tooltipLabelCallback: (tooltip) => {
          if (tooltip.dataset.hideTooltip) {
            return null;
          }

          if (typeof tooltip.parsed.y === 'undefined' || tooltip.parsed.y === null) {
            return t('NO_DATA');
          }

          if (chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS || chartOptionShowQuantity) {
            return formatNumber(tooltip.parsed.y);
          }

          return formatPercentage(tooltip.parsed.y, { multiplyBy100: false });
        },
      }),
    };
  }

  let chartSection;

  if (getLatestLogsAPIResult.data?.inspectionLogs.length <= 0) {
    chartSection = <InsufficientPerformanceDataNoLogsMessage />;
  } else {
    chartSection = (
      <>
        <div className={styles.chartFilters}>
          <div className={styles.chartFilterDropdowns}>
            <div className={styles.chartFilterSection}>
              <Text bold className={styles.chartFilterLabel}>
                {t('COWORKERS_OPTIONAL_PLURAL')}
              </Text>
              <div className={styles.searchContainer} style={{ zIndex: 2 }}>
                <SearchInputAsButton
                  buttonProps={{
                    icon: <Icon className={styles.selectCaretIcon} name={ICONS.CARET.name} />,
                  }}
                  className={styles.searchInput}
                  inputProps={{
                    loading: searchCoworkersAPIResult.loading,
                    onBlur: () => setSearchCoworkersFocused(false),
                    onChange: onSearchCoworkers,
                    onFocus: () => setSearchCoworkersFocused(true),
                    value: searchCoworkersString,
                  }}
                  onClickClear={() => setChartCoworkerHighlight(null)}
                  placeholder={t('FILTER_COWORKERS_PLACEHOLDER')}
                  ref={searchCoworkersInputRef}
                  selection={
                    chartCoworkerHighlight ? <Tag size='sm'>{formatOkid(chartCoworkerHighlight.OKID)}</Tag> : null
                  }
                />
                {searchCoworkersFocused && searchCoworkersString.length > 1 && (
                  <SearchSuggestions
                    className={styles.searchSuggestions}
                    onSuggestionClick={filterByUser}
                    showMoreResultsMessage={
                      searchCoworkersFocused &&
                      searchCoworkersAPIResult.data?.search?.searchPaginationResultDataByDataType?.USER?.totalResults >
                        searchCoworkersAPIResult.data?.search?.resultList.length
                    }
                    showNoResultsMessage={
                      searchCoworkersFocused && searchCoworkersAPIResult.data?.search?.resultList.length === 0
                    }
                    suggestions={searchCoworkersAPIResult.data?.search?.resultList?.map((r) =>
                      suggestionObjectForUser(r.userData, t)
                    )}
                  />
                )}
              </div>
              {chartCoworkerHighlight && !hasRecentLogsForCoworker && !loadingPerformanceChartData && (
                <Text className={styles.noRecentLogsMessage} size='sm' tint='notification'>
                  {t('NO_RECENT_LOGS_FOR_COWORKER')}
                </Text>
              )}
            </div>
            {toggleSearchComparison ? (
              <div className={styles.chartFilterSection}>
                <Text bold className={styles.chartFilterLabel}>
                  {t('PRODUCTS_OPTIONAL_PLURAL')}
                </Text>
                <div className={styles.searchContainer} style={{ zIndex: 2 }}>
                  <SearchInputAsButton
                    buttonProps={{
                      icon: <Icon className={styles.selectCaretIcon} name={ICONS.CARET.name} />,
                    }}
                    className={styles.searchInput}
                    inputProps={{
                      loading: searchProductsAPIResult.loading,
                      onBlur: () => setSearchProductsFocused(false),
                      onChange: onSearchProducts,
                      onFocus: () => setSearchProductsFocused(true),
                      value: searchProductsString,
                    }}
                    onClickClear={() => setChartProductHighlight(null)}
                    placeholder={t('FILTER_PRODUCTS_PLACEHOLDER')}
                    ref={searchProductsInputRef}
                    selection={
                      chartProductHighlight ? (
                        <>
                          <Tag size='sm'>{chartProductHighlight.REFID}</Tag>{' '}
                          {ProductModel.localizedNameForProduct(chartProductHighlight, locale)}
                        </>
                      ) : null
                    }
                  />
                  {searchProductsFocused && searchProductsString.length > 1 && (
                    <SearchSuggestions
                      className={styles.searchSuggestions}
                      onSuggestionClick={filterByProduct}
                      showMoreResultsMessage={
                        searchProductsFocused &&
                        searchProductsAPIResult.data?.search?.searchPaginationResultDataByDataType?.PRODUCT
                          ?.totalResults > searchProductsAPIResult.data?.search?.resultList.length
                      }
                      showNoResultsMessage={
                        searchProductsFocused && searchProductsAPIResult.data?.search?.resultList.length === 0
                      }
                      suggestions={searchProductsAPIResult.data?.search?.resultList?.map((r) =>
                        suggestionObjectForProduct(r.productData, locale)
                      )}
                    />
                  )}
                </div>
                {chartProductHighlight && !hasRecentLogsForProduct && !loadingPerformanceChartData && (
                  <Text className={styles.noRecentLogsMessage} size='sm' tint='notification'>
                    {t('NO_RECENT_LOGS_FOR_PRODUCT')}
                  </Text>
                )}
              </div>
            ) : (
              <div className={styles.chartFilterSection}>
                <Text bold className={styles.chartFilterLabel}>
                  {t('ARCHIVE_MENU_OPTION_SITES')}
                </Text>
                <div className={styles.searchContainer} style={{ zIndex: 2 }}>
                  <SearchInputAsButton
                    buttonProps={{
                      icon: <Icon className={styles.selectCaretIcon} name={ICONS.CARET.name} />,
                    }}
                    inputProps={{
                      loading: searchSitesAPIResult.loading,
                      onBlur: () => setSearchSitesFocused(false),
                      onChange: onSearchSites,
                      onFocus: () => setSearchSitesFocused(true),
                      value: searchSitesString,
                    }}
                    className={styles.searchInput}
                    onClickClear={() => setChartSiteHighlight(null)}
                    placeholder={t('FILTER_SITES_PLACEHOLDER')}
                    ref={searchSitesInputRef}
                    selection={
                      chartSiteHighlight ? (
                        <>
                          <Tag size='sm'>{formatOkid(chartSiteHighlight.OKID)}</Tag>{' '}
                          {SiteModel.localizedNameForSite(chartSiteHighlight, locale)}
                        </>
                      ) : null
                    }
                  />
                  {searchSitesFocused && searchSitesString.length > 1 && (
                    <SearchSuggestions
                      className={styles.searchSuggestions}
                      onSuggestionClick={filterBySites}
                      showMoreResultsMessage={
                        searchSitesFocused &&
                        searchCoworkersAPIResult.data?.search?.searchPaginationResultDataByDataType?.SITE
                          ?.totalResults > searchSitesAPIResult.data?.search?.resultList.length
                      }
                      showNoResultsMessage={
                        searchSitesFocused && searchSitesAPIResult.data?.search?.resultList.length === 0
                      }
                      suggestions={searchSitesAPIResult.data?.search?.resultList?.map((r) =>
                        suggestionObjectForSite(r.siteData, locale)
                      )}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <Button linkStyle onClick={() => setToggleSearchComparison(!toggleSearchComparison)}>
            Change to {toggleSearchComparison ? 'sites' : 'products'}
          </Button>
          <div>
            <div className={styles.chartFilterSection}>
              <Text bold>{t('INCLUDE')}</Text>
              <Chip
                className={styles.chartFilterChip}
                onChange={setChartFilterIncludeInternalLogs}
                selected={chartFilterIncludeInternalLogs}
              >
                {t('INTERNAL_LOGS')}
              </Chip>
            </div>
          </div>
          {chartConfigs ? (
            <>
              <div className={styles.chartHeaders}>
                <Text bold className={styles.latestLogsChartHeader}>
                  {latestLogs.length === 1
                    ? t('LATEST_LOGS_1')
                    : t('LATEST_LOGS_X', { data: { number: latestLogs.length } })}
                </Text>
                <Text bold>{t('TO_DATE')}</Text>
                {chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS ? (
                  <Icon className={styles.chartScaleIcon} name={ICONS.RELIABILITY_GRADE.name} />
                ) : (
                  <Text bold className={styles.chartScaleIcon}>
                    {chartOptionShowQuantity ? 'x' : '%'}
                  </Text>
                )}
              </div>
              <div className={styles.chartsContainer}>
                <div className={styles.latestLogsChartContainer}>
                  <LineChart data={chartConfigs.latestLogsChart.data} options={chartConfigs.latestLogsChart.options} />
                </div>
                <div className={styles.allLogsChartContainer}>
                  <BarChart data={chartConfigs.aggregatedChart.data} options={chartConfigs.aggregatedChart.options} />
                </div>
              </div>
              <div id='chartLegendContainer' />
              <ButtonGroup buttonStyle='separate' className={styles.chartDisplayTypeButtons}>
                <button active={chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS}>
                  {t('LOG_ARCHIVE_PAGE_POINTS')}
                </button>
              </ButtonGroup>
              {chartDisplayType !== LATEST_LOGS_CHART_TYPE.POINTS && !chartOptionShowQuantity && (
                <Text bold size='xs'>
                  <sup>1</sup> {t('LOG_QUALITY_LEVEL_FOOTNOTE_2')}
                </Text>
              )}
            </>
          ) : loadingPerformanceChartData ? (
            <Icon height={40} name={ICONS.SPINNER.name} width={40} />
          ) : (
            <InsufficientPerformanceDataForOptionsMessage />
          )}
        </div>
      </>
    );
  }

  let content;
  if (!getOrganisationAPIResult.called || getOrganisationAPIResult.loading) {
    content = (
      <ContentLayout className={`${styles.section} ${styles.placeholderSection}`} pageContent>
        <TextLayout>
          <Icon height={40} name={ICONS.SPINNER.name} width={40} />
        </TextLayout>
      </ContentLayout>
    );
  } else if (apiError) {
    content = (
      <ContentLayout className={styles.section} pageContent>
        <TextLayout>
          <Text>
            {t('ORGANISATION_PAGE_ERROR_LOADING_1')}&nbsp;
            <a href={window.location.href}>{t('ORGANISATION_PAGE_ERROR_LOADING_REFRESH')}</a>&nbsp;
            {t('ORGANISATION_PAGE_ERROR_LOADING_2')}
          </Text>
        </TextLayout>
      </ContentLayout>
    );
  } else {
    content = (
      <>
        {showCover ? (
          <div className={!showInPopup && styles.container}>
            <ContentLayout className={`${styles.section} ${styles.outerSection}`} pageContent></ContentLayout>
            <ContentLayout
              className={`${styles.section} ${styles.basicsSection}`}
              contentClassName={styles.basicsSectionContent}
              ref={basicsSectionRef}
              pageContent
            >
              <UIContext.Provider value='card'>
                <div className={styles.logoContainer}>
                  <div className={styles.editButtonContainer}>
                    {hasManagementRights && (
                      <Button
                        className={`${styles.nameEditButton}`}
                        icon={'/icons/admin_edit.svg'}
                        tint='secondary'
                        onClick={() => setShowEditOrganisationLogo(true)}
                      />
                    )}
                  </div>
                  <img
                    alt='organisation logo.'
                    className={styles.logo}
                    src={organisation?.logoImageMediaAsset?.logoImageURL ?? `/img/empty_media_${theme.name}.svg`}
                  />
                </div>
                <CardLayout fixedWidth={false} className={styles.organisationDetails}>
                  <div>
                    <div className={styles.nameField}>
                      <div className={styles.organisationName}>
                        <div className={styles.organisationNameText}>
                          {organisation?.name}&nbsp;&nbsp;
                          <Tag size='xs' className={styles.countryTag}>
                            {organisation.addressList[0].countryCode}
                          </Tag>
                        </div>
                      </div>
                      {hasManagementRights && (
                        <UIContext.Provider value='card'>
                          <Button
                            className={styles.nameEditButton}
                            icon={'/icons/admin_edit.svg'}
                            tint='secondary'
                            onClick={() => setShowEditOrganisationName(true)}
                          />
                        </UIContext.Provider>
                      )}
                    </div>
                    {}
                    <div className={styles.infoField}>
                      <Text className={styles.fieldDescription} bold>
                        {t('OKID')}
                      </Text>
                      <Text>{organisation?.OKID}</Text>
                    </div>
                    <div className={styles.infoField}>
                      <Text className={styles.fieldDescription} bold>
                        {t('RELIABILITY')}&nbsp;&nbsp;
                        <Icon inline name={ICONS.RELIABILITY_GRADE.name} />
                      </Text>
                      <Text>{formatPoints(organisation?.reliabilityPointForPublishedLogs ?? 0)}</Text>
                    </div>
                    <div className={styles.infoField}>
                      <Text className={styles.fieldDescription} bold>
                        {t('CONFORMITY')}&nbsp;&nbsp;
                        <Icon inline name={ICONS.DOCUMENTATION_GRADE.name} />{' '}
                      </Text>
                      <Text>{organisation?.conformityPoint ?? 0}</Text>
                    </div>
                  </div>
                  {useMobileLayout && (
                    <>
                      <Separator className={styles.desktopDetailSeparator} />
                    </>
                  )}
                </CardLayout>

                <div className={styles.organisationContactInfoContainer}>
                  {/* <img className={styles.organisationMap} width={287} height={200} src={'/img/map.png'} alt='No image' /> */}
                  <div className={styles.addressImageContainer}>
                    <NextImage
                      className={styles.addressImage}
                      layout='fill'
                      objectFit='cover'
                      src={organisation?.addressList[0]?.addressImageURL ?? `/img/empty_media_${theme.name}.svg`}
                    />
                  </div>
                  <CardLayout className={styles.contactInfoCard} fixedWidth={false}>
                    <div className={styles.nameField}>
                      <h4 className={styles.contactInfo}>{t('CONTACT_INFO')}</h4>
                      {hasManagementRights && (
                        <UIContext.Provider value='card'>
                          <Button
                            className={styles.nameEditButton}
                            icon={'/icons/admin_edit.svg'}
                            tint='secondary'
                            onClick={() => setShowEditOrganisationInfo(true)}
                          />
                        </UIContext.Provider>
                      )}
                    </div>
                    {organisation?.publicEmail && (
                      <>
                        <div className={styles.contactInfoItem}>
                          <Text bold className={styles.contactInfoLabel}>
                            {t('COMPANY_CONTACT_EMAIL')}
                          </Text>
                          <Link className={styles.contactInfoValue} href={`mailto:${organisation.publicEmail}`}>
                            {organisation.publicEmail}
                          </Link>
                        </div>
                      </>
                    )}
                    {organisation?.publicMobile && (
                      <>
                        <div className={styles.contactInfoItem}>
                          <Text bold className={styles.contactInfoLabel}>
                            {t('COMPANY_CONTACT_PHONE')}
                          </Text>
                          <Link
                            className={styles.contactInfoValue}
                            href={`tel:${organisation.publicMobileCountryCode}${organisation.publicMobile}`}
                          >
                            {organisation.publicMobileCountryCode} {organisation.publicMobile}
                          </Link>
                        </div>
                      </>
                    )}
                    {organisation?.websiteURL && (
                      <div className={styles.contactInfoItem}>
                        <Text bold className={styles.contactInfoLabel}>
                          {t('FIELD_WEBSITE')}
                        </Text>
                        <Link
                          className={styles.contactInfoValue}
                          href={formatUrl(organisation.websiteURL, { showProtocol: true })}
                          target='_blank'
                        >
                          {formatUrl(organisation.websiteURL)}
                        </Link>
                      </div>
                    )}
                    {organisation?.addressList &&
                      organisation.addressList.map((address, index) => (
                        <div className={styles.contactInfoItem} key={index}>
                          <Text bold className={styles.contactInfoLabel}>
                            {t('FIELD_ADDRESS')}
                          </Text>
                          <Text className={styles.contactInfoValue}>
                            {address.line1 && <span style={{ display: 'inline-block' }}>&nbsp;{address.line1},</span>}
                            {address.line2 && <span style={{ display: 'inline-block' }}>&nbsp;{address.line2},</span>}
                            {address.city && <span style={{ display: 'inline-block' }}>&nbsp;{address.city},</span>}
                            {address.postalCode && (
                              <span style={{ display: 'inline-block' }}>&nbsp;{address.postalCode},</span>
                            )}
                            {
                              <span style={{ display: 'inline-block' }}>
                                &nbsp;{countries.find((c) => c.isoAlpha3 === address.countryCode).name}
                              </span>
                            }
                          </Text>
                        </div>
                      ))}
                  </CardLayout>
                </div>
              </UIContext.Provider>
            </ContentLayout>
          </div>
        ) : null}
        {/* <div className={styles.section}>
          <ContentLayout>
            <TextLayout>
              <h4>{t('PERFORMANCE')}</h4>
              {chartSection}
            </TextLayout>
          </ContentLayout>
        </div> */}
        {/* eslint-disable indent */}
        <div className={styles.detailsContainer}>
          <PageMenu
            className={`${styles.sectionsSubmenuContainer} ${
              menuIsVisible && !showInPopup && styles.sectionsSubmenuContainerMenu
            }`}
            tabClassName={styles.tabClassName}
            sections={
              hasManagementRights
                ? [
                    { ref: historySectionRef, title: t('WORK'), icon: ICONS.COG.name },
                    {
                      ref: documentationSectionRef,
                      title: t('PRODUCT_SECTION_DOCUMENTATION'),
                      icon: ICONS.DOCUMENTATION.name,
                    },
                    { ref: catalogueSectionRef, title: t('CATALOGUE'), icon: ICONS.TWO_CIRCLES.name },
                    { ref: membersSectionRef, title: t('ACCOUNT_SECTION_MANAGE'), icon: ICONS.PROFILE.name },
                    { ref: planSectionRef, title: t('PLAN'), icon: ICONS.PLAN.name },

                    // { ref: gradesSectionRef, title: t('ARCHIVE_PAGE_SECTION_GRADES') },
                  ]
                : [
                    { ref: historySectionRef, title: t('WORK'), icon: ICONS.COG.name },
                    { ref: catalogueSectionRef, title: t('CATALOGUE'), icon: ICONS.TWO_CIRCLES.name },
                  ]
            }
            smartTabs
          />
          {/* </div> */}
          {/* eslint-enable indent */}
          <ContentLayout className={styles.sectionCard} pageContent>
            <OrganisationLogHistorySection
              sourceType='ORGANISATION'
              className={styles.dashboard}
              organisation={organisation}
              showSection={showWork}
              ref={historySectionRef}
              toggleSection={() => toggleWork(!showWork)}
            />
          </ContentLayout>
          <ContentLayout className={styles.sectionCard} ref={documentationSectionRef} pageContent>
            <OrganisationDocumentationSection organisation={activeOrganisation} isAuthorisedToEdit={isEditor} />
          </ContentLayout>
          <ContentLayout className={styles.sectionCard} ref={catalogueSectionRef} pageContent>
            <OrganisationCatalogueSection organisation={organisation} />
          </ContentLayout>
          {hasManagementRights && (
            <ContentLayout ref={membersSectionRef} className={styles.sectionCard} pageContent>
              <OrganisationMembersSection
                activeOrganisation={activeOrganisation}
                showSection={showMembers}
                openSection={() => toggleMembers(!showMembers)}
              />
            </ContentLayout>
          )}
          {hasManagementRights && (
            <ContentLayout ref={planSectionRef} className={styles.sectionCard} pageContent>
              <OrganisationPlanSection
                activeOrganisation={activeOrganisation}
                showSection={showPlan}
                openSection={() => togglePlan(!showPlan)}
              />
            </ContentLayout>
          )}
          {/* <div className={styles.section} ref={detailsSectionRef}>
            <ContentLayout>
              <TextLayout>
                <h3>{t('ARCHIVE_PAGE_SECTION_DETAILS')}</h3>
                <Text className={styles.sectionDescription}>{t('ORGANISATION_PAGE_DOCUMENTATION_DESCRIPTION')}</Text>
              </TextLayout>
              <div className={styles.subSection}>
                <TextLayout>
                  <h4>{t('PRODUCTS')}</h4>
                </TextLayout>
                {numberOfProducts > 0 ? (
                  <Carousel className={styles.productsCarouselContainer} fadeOutSides={useDesktopLayout}>
                    {organisation.productList.map((p) => (
                      <Slide className={styles.productSlide} key={p.id}>
                        <ProductArchiveCard className={styles.product} product={p} />
                      </Slide>
                    ))}
                  </Carousel>
                ) : (
                  <TextLayout>
                    <Text>{t('NO_PRODUCTS')}</Text>
                  </TextLayout>
                )}
              </div>
            </ContentLayout>
          </div> */}
          {/* <ContentLayout className={`${styles.section} ${styles.gradesSection}`} ref={gradesSectionRef}>
            <UIContext.Provider value='card'>
              <TextLayout>
                <h3>{t('ARCHIVE_PAGE_SECTION_GRADES')}</h3>
              </TextLayout>
              <div className={styles.subSection}>
                <div className={styles.gradeBoxesContainer}>
                  <BigGradeBox
                    className={styles.gradeBox}
                    onChangeOpen={setOpenSustainabilityGradeBox}
                    open={openSustainabilityGradeBox}
                    score={sustainabilityGrade}
                    showToggle={useMobileLayout}
                    type='SUSTAINABILITY'
                  />
                  {/ * <BigGradeBox
                  className={styles.gradeBox}
                  explanationCount={0}
                  onChangeOpen={setOpenConformityGradeBox}
                  open={openConformityGradeBox}
                  score={conformityGrade}
                  showToggle={useMobileLayout}
                  type='CONFORMITY'
                /> * /}
                  <BigGradeBox
                    className={styles.gradeBox}
                    explanationCount={inspectedCount}
                    onChangeOpen={setOpenReliabilityGradeBox}
                    open={openReliabilityGradeBox}
                    score={reliabilityGrade}
                    showToggle={useMobileLayout}
                    type='RELIABILITY'
                  />
                </div>
              </div>
              <div className={styles.subSection}>
                <TextLayout>
                  <h4>{t('VISUAL_SUPPLY_CHAIN')}</h4>
                </TextLayout>
                <div className={styles.visualSupplyChainPreview}>
                  <Text className={styles.visualSupplyChainComingSoonText}>{t('COMING_SOON_CAPS')}</Text>
                </div>
              </div>
            </UIContext.Provider>
          </ContentLayout> */}
        </div>
      </>
    );
  }

  const pageMenu = (
    <PageMenu
      showBackButton
      title={
        <>
          <FadeInOutTransition in={renderShareToast}>
            <Toast className={styles.shareToast}>{shareToastMessage}</Toast>
          </FadeInOutTransition>
          <Button className={styles.shareTab} icon={ICONS.SHARE.name} linkStyle onClick={shareOrganisation}>
            {t('SHARE')}
          </Button>
          {showCover ? (
            <Button className={styles.hideTab} icon={ICONS.WATCH.name} linkStyle onClick={() => toggleCover(false)}>
              {t('HIDE_COVER')}
            </Button>
          ) : (
            <Button className={styles.showTab} icon={ICONS.WATCH.name} linkStyle onClick={() => toggleCover(true)}>
              {t('SHOW_COVER')}
            </Button>
          )}
        </>
      }
      titlePosition='right'
    />
  );

  return (
    <>
      <PageTitle>
        {/* eslint-disable indent */}
        {organisation?.name
          ? t('PAGE_TITLE_ORGANISATION', {
              data: { organisationName: organisation.name },
            })
          : t('ORGANISATION')}
        {/* eslint-enable indent */}
      </PageTitle>
      {organisation && (showInPopup ? pageMenu : <Menu>{pageMenu}</Menu>)}
      {content}
      {showEditOrganisationName && (
        <EditOrganisationNamePopup
          organisationName={organisation?.name}
          dismiss={() => setShowEditOrganisationName(false)}
        />
      )}
      {showEditOrganisationInfo && (
        <EditOrganisationInfoPopup organisation={organisation} dismiss={() => setShowEditOrganisationInfo(false)} />
      )}
      {showEditOrganisationLogo && (
        <EditOrganisationLogoPopup organisation={organisation} dismiss={() => setShowEditOrganisationLogo(false)} />
      )}
    </>
  );
}

OrganisationPage.layoutProps = {
  contentClassName: styles.layout,
  contentTopPadding: false,
  padFooter: false,
};

OrganisationPage.propTypes = {
  OKIDasProp: PropTypes.string,
};

export async function getServerSideProps({ locale }) {
  const i18nProps = await serverSideTranslations(locale, ['common']);
  return { props: { ...i18nProps } };
}
