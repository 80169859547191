import { gql } from '@apollo/client';

import ContactModel from 'OK/models/contact';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import MediaAssetModel from 'OK/models/mediaAsset';
import OrganisationModel from 'OK/models/organisation';
import SiteModel from 'OK/models/site';
import SiteChildSiteModel from 'OK/models/siteChildSite';
import SiteDocumentModel from 'OK/models/siteDocument';
import SiteInspectionAssetModel from 'OK/models/siteInspection';
import SiteMediaAssetModel from 'OK/models/siteMediaAsset';
import SiteNoteModel from 'OK/models/siteNote';
import { request } from 'OK/networking';
import { API_URL } from 'OK/networking/utils';
import getAudioMetadataFromFile from 'OK/util/functions/getAudioMetadataFromFile';
import getImageDimensionsFromFile from 'OK/util/functions/getImageDimensionsFromFile';
import getVideoMetadataFromFile from 'OK/util/functions/getVideoMetadataFromFile';

/* Queries */

export const GetSiteForEditingWithOKIDQuery = gql`
  query GetSiteForEditingWithOKIDQuery($OKID: String!) {
    site: siteWithOKID(OKID: $OKID) {
      id
      assetAccessPermission
      conformityPoint
      identifier
      contactList {
        ...${ContactModel.fragmentNameContacts}
      }
      name
      OKID
      organisationId
      publishStatus
      reliabilityPointForPublishedLogs
      assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      siteChildSiteList {
        childSite {
          ...${SiteModel.fragmentNameArchiveCard}
        }
        publishStatus
        quantity
        assetLinkStatus
      }
      siteParentSiteList {
        ...${SiteModel.fragmentNameArchiveCard}
      }
      siteDocumentAssetList {
        documentAsset {
          id
          applicableMarketList
          coveredLanguageList
          documentName
          documentNumber
          documentStandardList
          documentType
          madeByOrganisation {
            ...${OrganisationModel.fragmentNameArchiveCard}
          }
          madeByOrganisationId
          REFID
          validFrom
          validTill
        }
        publishStatus
      }
      siteInspectionAssetList {
        inspectionAsset {
          ...${InspectionAssetModel.fragmentNameEditCard}
        }
        order
        publishStatus
      }
      siteMediaAssetList {
        mediaAsset {
          ...${MediaAssetModel.fragmentNameMediaGallery}
        }
        order
        publishStatus
      }
      siteNoteAssetList {
        noteAsset {
          id
          REFID
          textContentMap
        }
        publishStatus
      }
    }
  }
  ${MediaAssetModel.fragmentMediaGallery}
  ${SiteModel.fragmentArchiveCard}
  ${ContactModel.fragmentContacts}
  ${OrganisationModel.fragmentArchiveCard}
  ${InspectionAssetModel.fragmentEditCard}
`;

export const siteInfoPopupQuery = gql`
  query SiteInfoPopupQuery($id: String!) {
    site(siteId: $id) {
      id
      conformityPoint
      name
      OKID
      organisation {
        addressList {
          countryCode
        }
        conformityPoint
        name
        OKID
        reliabilityPointForPublishedLogs
      }
      siteChildSiteList {
        childSite {
          ...${SiteModel.fragmentNameArchiveCard}
        }
      }
      siteDocumentAssetList {
        documentAsset {
          id
          coveredLanguageList
          documentName
          documentType
          documentURL
          REFID
        }
      }
      siteMediaAssetList {
        mediaAsset {
          imageData {
            imageURL
          }
          mediaType
        }
      }
      siteNoteAssetList {
        noteAsset {
          id
          textContentMap
        }
      }
      reliabilityPointForPublishedLogs
    }
  }
  ${SiteModel.fragmentArchiveCard}
`;

export const getSiteByRefIdQuery = gql`
  query SiteEditPageQuery($refId: String!) {
    site: siteWithREFID(REFID: $refId) {
      id
      conformityPoint
      name
      organisationId
      siteChildSiteList {
        childSite {
          ...${SiteModel.fragmentNameArchiveCard}
        }
        publishStatus
        quantity
        assetLinkStatus
      }
      siteParentSiteList {
        ...${SiteModel.fragmentNameArchiveCard}
      }
      siteDocumentAssetList {
        documentAsset {
          id
          applicableMarketList
          coveredLanguageList
          documentName
          documentNumber
          documentStandardList
          documentType
          madeByOrganisation {
            ...${OrganisationModel.fragmentNameArchiveCard}
          }
          madeByOrganisationId
          REFID
          validFrom
          validTill
        }
        publishStatus
      }
      siteInspectionAssetList {
        inspectionAsset {
          ...${InspectionAssetModel.fragmentNameEditCard}
        }
        order
        publishStatus
      }
      siteMediaAssetList {
        mediaAsset {
          ...${MediaAssetModel.fragmentNameMediaGallery}
        }
        order
        publishStatus
      }
      siteNoteAssetList {
        noteAsset {
          id
          REFID
          textContentMap
        }
        publishStatus
      }
      publishStatus
      REFID
      reliabilityPointForPublishedLogs
      SKUID
    }
  }
  ${InspectionAssetModel.fragmentEditCard}
  ${MediaAssetModel.fragmentMediaGallery}
  ${SiteModel.fragmentArchiveCard}
  ${OrganisationModel.fragmentArchiveCard}
`;

export const GetAvailableWorkflowsForSiteQuery = gql`
  query GetAvailableWorkflowsForSite($OKID: String!) {
    site: siteWithOKID(OKID: $OKID) {
      id
      availableSiteInspectionList {
        inspectionAsset {
          id
          REFID
          name
          inspectionTestAssetList {
            order
            publishStatus
            testAsset {
              id
              REFID
            }
          }
        }
        order
        publishStatus
      }
    }
  }
`;

export const GetSiteForScanResultsQuery = gql`
  query GetSiteForScanResults($OKID: String!) {
    item: siteWithOKID(OKID: $OKID) {
      ...${SiteModel.fragmentNameScanResult}
    }
  }
  ${SiteModel.fragmentScanResult}
`;

export const GetSiteWithOKIDPublicArchivePageQuery = gql`
  query GetSiteWithOKIDPublicArchivePage($OKID: String!) {
    site: siteWithOKID(OKID: $OKID) {
      ...${SiteModel.fragmentNameFull}
      contactList {
        id
        organisationId
        type
        name
        role
        email
        phone
        user {
          id
          email
          mobile
          mobileCountryCode
          name
          OKID
        }
      }
    }
  }
  ${SiteModel.fragmentFull}

`;

/* Mutations */

export const CreateSiteMutation = gql`
  mutation CreateSite($identifier: String!) {
    site: createSite(identifier: $identifier) {
      id
      OKID
    }
  }
`;

export const CreateSiteAndLinkMutation = gql`
  mutation CreateSiteAndLink($orderId: String!, $siteIdentifier: String!) {
    site: createOrderSite(orderId: $orderId, siteIdentifier: $siteIdentifier) {
      site {
        id
        OKID
      }
    }
  }
`;

export const PublishSiteMutation = gql`
  mutation PublishSite($siteId: String!) {
    site: publishSite(siteId: $siteId) {
      id
      publishStatus
    }
  }
`;

export const UnpublishSiteMutation = gql`
  mutation UnpublishSite($siteId: String!) {
    site: unpublishSite(siteId: $siteId) {
      id
      publishStatus
    }
  }
`;

export const linkSiteMediaMutation = gql`
  mutation LinkSiteMedia($mediaAssetId: String!, $siteId: String!) {
    siteMedia: linkSiteMediaAsset(mediaAssetId: $mediaAssetId, siteId: $siteId) {
      ...${SiteMediaAssetModel.fragmentName}
    }
  }
  ${SiteMediaAssetModel.fragment}
`;

export const removeSiteNameForLanguageMutation = gql`
  mutation RemoveSiteNameForLanguage($siteId: String!, $languageIso: ELanguageCode!) {
    site: removeSiteName(languageCode: $languageIso, siteId: $siteId) {
      id
      name
    }
  }
`;

export const reorderSiteMediaBackwardMutation = gql`
  mutation ReorderSiteMediaBackward($mediaAssetId: String!, $siteId: String!) {
    siteMedia: decreaseOrderSiteMediaAsset(mediaAssetId: $mediaAssetId, siteId: $siteId) {
      ...${SiteMediaAssetModel.fragmentName}
    }
  }
  ${SiteMediaAssetModel.fragment}
`;

export const reorderSiteMediaForwardMutation = gql`
  mutation ReorderSiteMediaForward($mediaAssetId: String!, $siteId: String!) {
    siteMedia: increaseOrderSiteMediaAsset(mediaAssetId: $mediaAssetId, siteId: $siteId) {
      ...${SiteMediaAssetModel.fragmentName}
    }
  }
  ${SiteMediaAssetModel.fragment}
`;

export const setSiteIdentifierMutation = gql`
  mutation SetSiteIdentifier($siteId: String!, $identifier: String!) {
    site: setSiteIdentifier(siteId: $siteId, identifier: $identifier) {
      id
      identifier
    }
  }
`;

export const setSiteNameForLanguageMutation = gql`
  mutation SetSiteNameForLanguage($siteId: String!, $languageIso: ELanguageCode!, $name: String!) {
    site: setSiteName(languageCode: $languageIso, languageText: $name, siteId: $siteId) {
      id
      name
    }
  }
`;

export const unlinkSiteMediaMutation = gql`
  mutation UnlinkSiteMedia($mediaAssetId: String!, $siteId: String!) {
    siteMedia: unlinkSiteMediaAsset(mediaAssetId: $mediaAssetId, siteId: $siteId) {
      ...${SiteMediaAssetModel.fragmentName}
    }
  }
  ${SiteMediaAssetModel.fragment}
`;

export const acceptParentSiteRequestMutation = gql`
  mutation AcceptParentSiteRequest($siteId: String!, $parentSiteId: String!) {
    site: acceptParentSiteRequest(siteId: $siteId, parentSiteId: $parentSiteId) {
      id
      siteParentSiteList {
        ...${SiteModel.fragmentNameArchiveCard}
      }
    }
  }
  ${SiteModel.fragmentArchiveCard}
`;

export const addPartMutation = gql`
  mutation AddPart($siteId: String!, $partSiteId: String!, , $partType: EPartType!) {
    part: addChildSite(childSiteId: $partSiteId, siteId: $siteId, partType: $partType) {
      ...${SiteChildSiteModel.fragmentName}
    }
  }
  ${SiteChildSiteModel.fragment}
`;

export const createSiteInspectionMutation = gql`
  mutation CreateSiteInspection($siteId: String!) {
    siteInspectionAsset: createSiteInspectionAsset(siteId: $siteId) {
      inspectionAsset {
        ...${InspectionAssetModel.fragmentNameEditCard}
      }
      order
      publishStatus
    }
  }
  ${InspectionAssetModel.fragmentEditCard}
`;

export const createSiteNoteMutation = gql`
  mutation CreateSiteNote($siteId: String!, $published: EPublishStatus!, $text: [LanguageTextInput!]!) {
    siteNote: createSiteNoteAsset(languageTextInputList: $text, siteId: $siteId, publishStatus: $published) {
      ...${SiteNoteModel.fragmentName}
    }
  }
  ${SiteNoteModel.fragment}
`;

export const linkSiteDocumentMutation = gql`
  mutation LinkSiteDocument($documentId: String!, $siteId: String!) {
    siteDocument: linkSiteDocumentAsset(documentAssetId: $documentId, siteId: $siteId) {
      ...${SiteDocumentModel.fragmentName}
    }
  }
  ${SiteDocumentModel.fragment}
`;

export const linkSiteInspectionMutation = gql`
  mutation LinkSiteInspection($siteId: String!, $inspectionAssetId: String!) {
    siteInspectionAsset: linkSiteInspectionAsset(siteId: $siteId, inspectionAssetId: $inspectionAssetId) {
      ...${SiteInspectionAssetModel.fragmentName}
    }
  }
  ${SiteInspectionAssetModel.fragment}
`;

export const linkSiteNoteMutation = gql`
  mutation ($siteId: String!, $noteAssetId: String!) {
    siteNote: linkSiteNoteAsset(siteId: $siteId, noteAssetId: $noteAssetId) {
      ...${SiteNoteModel.fragmentName}
    }
  }
  ${SiteNoteModel.fragment}
`;

export const publishPartMutation = gql`
  mutation PublishPart($siteId: String!, $partSiteId: String!) {
    part: publishChildSite(siteId: $siteId, childSiteId: $partSiteId) {
      publishStatus
    }
  }
`;

export const publishSiteDocumentMutation = gql`
  mutation PublishSiteDocument($documentId: String!, $siteId: String!) {
    siteDocument: publishSiteDocumentAsset(documentAssetId: $documentId, siteId: $siteId) {
      ...${SiteDocumentModel.fragmentName}
    }
  }
  ${SiteDocumentModel.fragment}
`;

export const publishSiteInspectionMutation = gql`
  mutation PublishSiteInspection($siteId: String!, $inspectionAssetId: String!) {
    siteInspection: publishSiteInspectionAsset(siteId: $siteId, inspectionAssetId: $inspectionAssetId) {
      inspectionAsset {
        id
      }
      order
      publishStatus
    }
  }
`;

export const publishSiteNoteMutation = gql`
  mutation PublishSiteNote($noteId: String!, $siteId: String!) {
    siteNote: publishSiteNoteAsset(noteAssetId: $noteId, siteId: $siteId) {
      ...${SiteNoteModel.fragmentName}
    }
  }
  ${SiteNoteModel.fragment}
`;

export const rejectParentSiteRequestMutation = gql`
  mutation RejectParentSiteRequest($siteId: String!, $parentSiteId: String!) {
    site: rejectParentSiteRequest(siteId: $siteId, parentSiteId: $parentSiteId) {
      id
    }
  }
  ${SiteModel.fragmentArchiveCard}
`;

export const removeParentSiteMutation = gql`
  mutation RemoveParentSite($parentSiteId: String, $siteId: String!) {
    site: removeParentSite(parentSiteId: $parentSiteId, siteId: $siteId) {
      id
      siteParentSiteList {
        ...${SiteModel.fragmentNameArchiveCard}
      }
    }
  }
  ${SiteModel.fragmentArchiveCard}
`;

export const reorderPartBackwardMutation = gql`
  mutation ReorderPartBackward($siteId: String!, $partSiteId: String!) {
    part: decreaseOrderChildSite(siteId: $siteId, childSiteId: $partSiteId) {
      childSite {
        id
      }
      order
    }
  }
`;

export const reorderPartForwardMutation = gql`
  mutation ReorderPartForward($siteId: String!, $partSiteId: String!) {
    part: increaseOrderChildSite(siteId: $siteId, childSiteId: $partSiteId) {
      childSite {
        id
      }
      order
    }
  }
`;

export const reorderSiteInspectionBackwardMutation = gql`
  mutation ReorderSiteInspectionBackward($siteId: String!, $inspectionAssetId: String!) {
    siteInspection: decreaseOrderSiteInspectionAsset(siteId: $siteId, inspectionAssetId: $inspectionAssetId) {
      ...${SiteInspectionAssetModel.fragmentName}
    }
  }
  ${SiteInspectionAssetModel.fragment}
`;

export const reorderSiteInspectionForwardMutation = gql`
  mutation ReorderSiteInspectionForward($siteId: String!, $inspectionAssetId: String!) {
    siteInspection: increaseOrderSiteInspectionAsset(siteId: $siteId, inspectionAssetId: $inspectionAssetId) {
      ...${SiteInspectionAssetModel.fragmentName}
    }
  }
  ${SiteInspectionAssetModel.fragment}
`;

export const removePartMutation = gql`
  mutation RemovePart($siteId: String!, $partSiteId: String!) {
    part: removeChildSite(childSiteId: $partSiteId, siteId: $siteId) {
      ...${SiteChildSiteModel.fragmentName}
    }
  }
  ${SiteChildSiteModel.fragment}
`;

export const setPartQuantityMutation = gql`
  mutation SetPartQuantity($siteId: String!, $partSiteId: String!, $quantity: Int!) {
    part: setChildSiteQuantity(siteId: $siteId, childSiteId: $partSiteId, quantity: $quantity) {
      quantity
    }
  }
`;

export const unlinkSiteDocumentMutation = gql`
  mutation UnlinkSiteDocument($documentId: String!, $siteId: String!) {
    siteDocument: unlinkSiteDocumentAsset(documentAssetId: $documentId, siteId: $siteId) {
      ...${SiteDocumentModel.fragmentName}
    }
  }
  ${SiteDocumentModel.fragment}
`;

export const unlinkSiteInspectionMutation = gql`
  mutation UnlinkSiteInspection($siteId: String!, $inspectionAssetId: String!) {
    siteInspection: unlinkSiteInspectionAsset(siteId: $siteId, inspectionAssetId: $inspectionAssetId) {
      ...${SiteInspectionAssetModel.fragmentName}
    }
  }
  ${SiteInspectionAssetModel.fragment}
`;

export const unlinkSiteNoteMutation = gql`
  mutation UnlinkSiteNote($noteId: String!, $siteId: String!) {
    siteNote: unlinkSiteNoteAsset(noteAssetId: $noteId, siteId: $siteId) {
      ...${SiteNoteModel.fragmentName}
    }
  }
  ${SiteNoteModel.fragment}
`;

export const unpublishPartMutation = gql`
  mutation UnpublishPart($siteId: String!, $partSiteId: String!) {
    part: unpublishChildSite(siteId: $siteId, childSiteId: $partSiteId) {
      publishStatus
    }
  }
`;

export const unpublishSiteDocumentMutation = gql`
  mutation UnpublishSiteDocument($documentId: String!, $siteId: String!) {
    siteDocument: unpublishSiteDocumentAsset(documentAssetId: $documentId, siteId: $siteId) {
      ...${SiteDocumentModel.fragmentName}
    }
  }
  ${SiteDocumentModel.fragment}
`;

export const unpublishSiteInspectionMutation = gql`
  mutation UnpublishSiteInspection($siteId: String!, $inspectionAssetId: String!) {
    siteInspection: unpublishSiteInspectionAsset(siteId: $siteId, inspectionAssetId: $inspectionAssetId) {
      inspectionAsset {
        id
      }
      order
      publishStatus
    }
  }
`;

export const unpublishSiteNoteMutation = gql`
  mutation UnpublishSiteNote($noteId: String!, $siteId: String!) {
    siteNote: unpublishSiteNoteAsset(noteAssetId: $noteId, siteId: $siteId) {
      ...${SiteNoteModel.fragmentName}
    }
  }
  ${SiteNoteModel.fragment}
`;

export const linkUserAsSiteContactMutation = gql`
  mutation LinkUserAsSiteContact($siteId: String!, $userId: String!) {
    siteContact: linkUserAsSiteContact(siteId: $siteId, userId: $userId) {
      id
      organisationId
      siteId
      type
      userId
      user {
        OKID
        name
        mobile
        email
      }
      name
      role
      email
      phone
    }
  }
`;

export const unlinkUserAsSiteContactMutation = gql`
  mutation UnlinkUserAsSiteContact($siteId: String!, $userId: String!) {
    siteContact: unlinkUserAsSiteContact(siteId: $siteId, userId: $userId) {
      id
      organisationId
      siteId
      type
      userId
      name
      role
      email
      phone
    }
  }
`;

export const siteContactRoleListQuery = gql`
  query SiteContactRoleList {
    siteContactRoleList: siteContactRoleList
  }
`;

export const setContactNameMutation = gql`
  mutation SetContactName($name: String!, $siteContactId: String!) {
    siteContact: setContactName(name: $name, siteContactId: $siteContactId) {
      id
      siteId
      name
    }
  }
`;

export const setContactPhoneMutation = gql`
  mutation SetContactPhone($phone: String!, $siteContactId: String!) {
    siteContact: setContactPhone(phone: $phone, siteContactId: $siteContactId) {
      id
      siteId
      phone
    }
  }
`;

export const setContactRoleMutation = gql`
  mutation SetContactRole($role: ESiteContactRole!, $siteContactId: String!) {
    siteContact: setContactRole(role: $role, siteContactId: $siteContactId) {
      id
      siteId
      role
    }
  }
`;

export const setContactEmailMutation = gql`
  mutation SetContactEmail($email: String!, $siteContactId: String!) {
    siteContact: setContactEmail(email: $email, siteContactId: $siteContactId) {
      id
      siteId
      email
    }
  }
`;

export const linkOrderOnSiteContactMutation = gql`
  mutation LinkOrderOnSiteContact($siteContactId: String!, $orderId: String!) {
    linkedOrder: linkOrderOnSiteContact(siteContactId: $siteContactId, orderId: $orderId) {
      id
      siteContactOrderList {
        order {
          id
          name
          REFID
        }
      }
    }
  }
`;

export const unlinkOrderOnSiteContactMutation = gql`
  mutation UnlinkOrderOnSiteContact($siteContactId: String!, $orderId: String!) {
    unlinkedOrder: unlinkOrderOnSiteContact(siteContactId: $siteContactId, orderId: $orderId) {
      id
      siteContactOrderList {
        order {
          id
          name
          REFID
        }
      }
    }
  }
`;

export const searchSiteOrderListQuery = gql`
  query SearchSiteOrders(
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $searchString: String
    $ignoreIdListByDataType: [IgnoreIdListByDataTypeInput]
  ) {
    search(
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchString: $searchString
      ignoreIdListByDataType: $ignoreIdListByDataType
    ) {
      resultList {
        orderData {
          id
          name
          REFID
        }
      }
    }
  }
`;

/* Requests */

export async function createSiteAudioMediaAssetRequest(siteId, audioFile) {
  const { duration } = await getAudioMetadataFromFile(audioFile);
  const metadata = {
    duration,
    filesize: audioFile.size,
    mimeType: audioFile.type,
  };
  const parameters = {
    siteId,
    audio: audioFile,
    metadataString: JSON.stringify(metadata),
  };
  return request(`${API_URL}/utility/media/createSiteAudioMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

/**
 * Create a new document asset and link it to a site.
 *
 * @param {string} siteId
 * @param {File} file
 * @param {'DRAWING'|'MANUAL'|'PRODUCT_SHEET'} documentType
 */
export async function createSiteDocumentAssetRequest(siteId, file, documentType) {
  const metadata = {
    filesize: file.size,
  };
  const parameters = {
    documentFile: file,
    documentType,
    metadataString: JSON.stringify(metadata),
    siteId,
  };

  return request(`${API_URL}/utility/document/createSiteDocumentAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createSiteImageMediaAssetRequest(siteId, imageFile, originalImageFile) {
  const { height, width } = await getImageDimensionsFromFile(imageFile);
  const metadata = {
    filesize: imageFile.size,
    height,
    mimeType: imageFile.type,
    width,
  };
  const parameters = {
    siteId,
    image: imageFile,
    baseImage: originalImageFile,
    metadataString: JSON.stringify(metadata),
  };

  return request(`${API_URL}/utility/media/createSiteImageMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createSiteVideoMediaAssetRequest(siteId, videoFile) {
  const { duration, height, width } = await getVideoMetadataFromFile(videoFile);
  const metadata = {
    duration,
    filesize: videoFile.size,
    height,
    mimeType: videoFile.type,
    width,
  };
  const parameters = {
    siteId,
    video: videoFile,
    metadataString: JSON.stringify(metadata),
  };
  return request(`${API_URL}/utility/media/createSiteVideoMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

/* Types */

export const PRODUCT_TYPE = 'Site';

/* Status codes */

export const SKU_NOT_UNIQUE_ERROR = 'SKUID_NOT_UNIQUE';
