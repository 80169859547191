import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import ColumnCard from 'OK/components/archiveCard/ColumnCard';
import Button from 'OK/components/button';
import ButtonGroup from 'OK/components/buttonGroup';
import { Carousel, Slide } from 'OK/components/carousel';
import { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import ContentLayout from 'OK/components/layouts/content';
import MapObject from 'OK/components/map';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Text from 'OK/components/text';
import ProductModel from 'OK/models/product';
import { useLinkPartAPI, useRemovePartAPI } from 'OK/networking/products/hooks';
import { searchQuery } from 'OK/networking/search';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import useI18n from 'OK/util/hooks/useI18n';

export default function ProductPageCatalogueSection(props) {
  const { isEditor, product } = props;

  const { t, locale } = useI18n();
  const [catalogueMode, setCatalogueMode] = useState('parts');
  const [catalogueScope, setCatalogueScope] = useState('all');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [numberOfProducts, setNumberOfProducts] = useState(0);
  const [partsError, setPartsError] = useState(null);
  const [partsErrors, setPartsErrors] = useState([]);
  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);
  const [searchTerm, setSearchTerm] = useState('');
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);

  const [linkPartAPI] = useLinkPartAPI();
  const removePartAPI = useRemovePartAPI();

  const linkPart = useCallback(
    (partProductId) => {
      setPartsError(null);
      linkPartAPI(product.id, partProductId).catch((e) => {
        okdebug(e);
        let errorMessage;
        if (e.message === 'PRODUCT_CHILD_PRODUCT_LINK_EXIST_IN_SUPPLY_CHAIN') {
          errorMessage = t('ERROR_UPSTREAM_PRODUCT_CANNOT_BE_PART');
        } else {
          errorMessage = t('ERROR_GENERIC');
        }
        setPartsError(errorMessage);
      });
      setSearchTerm('');
    },
    [linkPartAPI, product.id, t]
  );

  const searchDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'PRODUCT',
                ignoreIdList: [product.id],
              },
            ],
            searchPaginationDataByDataType: [
              {
                dataType: 'PRODUCT',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [product.id, searchAPI]
  );

  const searchSuggestions = useMemo(() => {
    const filteredProductsIds = filteredProducts.map((p) =>
      p.__typename === 'ProductChildProduct' ? p.childProduct.id : p.parentProduct.id
    );

    return (
      searchAPIResult.data?.search?.resultList
        ?.map((r) => {
          const productResult = r.productData;
          const productName = productResult ? ProductModel.localizedNameForProduct(productResult, locale) : '';

          return {
            icon: ICONS.PRODUCT.name,
            key: productResult.id,
            subtitle: productResult.REFID,
            title: productName,
          };
        })
        .filter((p) => !filteredProductsIds.includes(p.key)) ?? []
    );
  }, [searchAPIResult.data?.search?.resultList, filteredProducts]);

  const removeErrorForPart = useCallback(
    (partProductId) => {
      const indexOfPartError = partsErrors.findIndex((e) => e.partProductId === partProductId);
      if (indexOfPartError > -1) {
        const updatedErrors = [...partsErrors];
        updatedErrors.splice(indexOfPartError);
        setPartsErrors(updatedErrors);
      }
    },
    [partsErrors]
  );

  const setErrorForPart = useCallback(
    (partProductId, message) => {
      let newError;
      const indexOfPartError = partsErrors.findIndex((e) => e.partProductId === partProductId);
      if (indexOfPartError > -1) {
        const error = partsErrors.splice(indexOfPartError)[0];
        newError = {
          ...error,
          message,
        };
      } else {
        newError = {
          partProductId,
          message,
        };
      }
      const updatedErrors = [...partsErrors, newError];
      setPartsErrors(updatedErrors);
    },
    [partsErrors]
  );

  const onClickUnlinkPart = useCallback(
    (partProductId) => {
      removeErrorForPart(partProductId);
      setPartsError(null);
      removePartAPI(product.id, partProductId).catch(() => {
        setErrorForPart(partProductId, t('ERROR_GENERIC'));
      });
    },
    [product.id, removeErrorForPart, removePartAPI, setErrorForPart, t]
  );

  const onSearch = useCallback(
    (e) => {
      const searchString = e.target.value;
      setSearchTerm(searchString);

      if (searchString.length > 1) {
        searchDebounced(searchString);
      }
    },
    [searchDebounced]
  );

  useEffect(() => {
    let numberOfProducts = 0;
    let parentAndChildProducts = [];

    if (product.productChildProductList?.length) {
      numberOfProducts += product.productChildProductList.length;
      parentAndChildProducts = parentAndChildProducts.concat(product.productChildProductList);
    }
    if (product.productParentProductList?.length) {
      numberOfProducts += product.productParentProductList.length;
      parentAndChildProducts = parentAndChildProducts.concat(product.productParentProductList);
    }

    setFilteredProducts(parentAndChildProducts);
    setNumberOfProducts(numberOfProducts);
  }, [product.productChildProductList, product.productParentProductList]);

  return (
    <>
      <MapObject startingProduct={product} />
      <ContentLayout>
        <Text bold style={{ fontSize: 20 }}>
          {t('PRODUCT_LISTINGS')}
        </Text>
        <Text style={{ marginBottom: 40, maxWidth: 590 }}>
          {t('PRODUCT_LISTINGS_DESCRIPTION_1')}
          <br></br>
          <br></br>
          {t('PRODUCT_LISTINGS_DESCRIPTION_2')}
        </Text>
        <Text bold style={{ marginBottom: 10 }}>
          {`${numberOfProducts} ${t('PRODUCTS')}`}  
        </Text>
        <SearchInput
          accessoryButton={
            <Button className={styles.searchNewButton} linkStyle tint='creation'>
              {t('NEW')}
            </Button>
          }
          className={styles.searchInput}
          loading={searchAPIResult.loading}
          onChange={onSearch}
          placeholder={t('SEARCH_PARTS_PLACEHOLDER')}
          value={searchTerm}
        />
        {isEditor && searchTerm.length > 1 && (
          <SearchSuggestions
            accessoryViewRender={() => (
              <Button className={styles.searchResultButton} icon='/icons/link_green.svg' tint='creation' linkStyle>
                {t('LINK')}
              </Button>
            )}
            className={styles.searchResults}
            highlightTerm={searchTerm}
            onSuggestionClick={linkPart}
            showMoreResultsMessage={searchSuggestions?.length > 4}
            showNoResultsMessage={searchSuggestions?.length === 0}
            suggestionContentClassName={styles.suggestionContent}
            suggestions={searchSuggestions}
            titleClassName={styles.searchSuggestion}
          />
        )}
        <ButtonGroup buttonStyle='separate' className={styles.productFilterButtons}>
          <button active={catalogueMode === 'parts'} onClick={() => setCatalogueMode('parts')}>
            {t('PARTS')}
          </button>
          <button active={catalogueMode === 'parent'} onClick={() => setCatalogueMode('parent')}>
            {t('USED_IN')} 
          </button>
          <button active={catalogueMode === 'child'} onClick={() => setCatalogueMode('child')}>
           {t('OFFERED_AT')} 
          </button>
        </ButtonGroup>
        <ButtonGroup
          buttonStyle='separate-outline'
          className={styles.productFilterButtons}
          style={{ marginBottom: 20 }}
        >
          <button active={catalogueScope === 'all'} onClick={() => setCatalogueScope('all')}>
           {t('ALL')} 
          </button>
          <button active={catalogueScope === 'internal'} onClick={() => setCatalogueScope('internal')}>
           {t('INTERNAL')} 
          </button>
          <button active={catalogueScope === 'external'} onClick={() => setCatalogueScope('external')}>
            {t('EXTERNAL')}
          </button>
        </ButtonGroup>
        <Carousel fadeOutSides={useDesktopLayout} innerClassName={styles.carousel} style={{ margin: '0px -20px' }}>
          {filteredProducts
            .filter((p) => {
              if (catalogueMode === 'parts') {
                return p;
              } else if (catalogueMode === 'parent') {
                return p.__typename === 'ProductParentProduct';
              } else if (catalogueMode === 'child') {
                return p.__typename === 'ProductChildProduct';
              }
            })
            .filter((p) => {
              let product = p.__typename === 'ProductChildProduct' ? p.childProduct : p.parentProduct;
              if (catalogueScope === 'all') {
                return p;
              } else if (catalogueScope === 'internal') {
                return product.organisation.id === activeOrganisationId;
              } else if (catalogueScope === 'external') {
                return product.organisation.id !== activeOrganisationId;
              }
            })
            .map((p) => (
              <Slide
                className={styles.carouselSlide}
                key={p.__typename === 'ProductChildProduct' ? p.childProduct.id : p.parentProduct.id}
              >
                <ColumnCard
                  asset={p.__typename === 'ProductChildProduct' ? p.childProduct : p.parentProduct}
                  assetType='product'
                  editMode={isEditor}
                  fixedColumn
                  onClickUnlink={
                    isEditor && p.__typename === 'ProductChildProduct'
                      ? () =>
                          onClickUnlinkPart(
                            p.__typename === 'ProductChildProduct' ? p.childProduct.id : p.parentProduct.id
                          )
                      : null
                  }
                  partsError={partsError}
                />
              </Slide>
            ))}
        </Carousel>
      </ContentLayout>
    </>
  );
}

ProductPageCatalogueSection.propTypes = {
  isEditor: PropTypes.bool,
  product: PropTypes.object.isRequired,
};
