import PropTypes from 'prop-types';
import { useLayoutEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import CardLayout from 'OK/components/layouts/card';
import Text from 'OK/components/text';
import OpenCloseTransition from 'OK/components/transitions/openClose';
import useI18n from 'OK/util/hooks/useI18n';

export default function RemarkLorealCard(props) {
  /* Variables */

  const { className, footerText, remark, showFullTextInitial, ...otherProps } = props;
  const { textContentMap } = remark;
  const languages = Object.keys(textContentMap);
  const textRef = useRef();
  const { t } = useI18n();

  // State

  const [limitText, setLimitText] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [showFullText, setShowFullText] = useState(showFullTextInitial);
  const [showOptionToExpandText, setShowOptionToExpandText] = useState(false);

  /* Methods */

  const checkIfNeedsOptionToExpandText = () => {
    if (textRef.current.scrollHeight > closedHeight) {
      setShowOptionToExpandText(true);
    } else {
      setShowOptionToExpandText(false);
    }
  };

  const onChangeLanguage = (newSelectedLanguage) => {
    setSelectedLanguage(newSelectedLanguage);
  };

  /* Effects */

  // Determine whether to show option to expand text
  useLayoutEffect(() => {
    checkIfNeedsOptionToExpandText();
  }, [selectedLanguage]);

  // Limit lines of text
  useLayoutEffect(() => {
    if (showFullText) {
      setLimitText(false);
    } else {
      setLimitText(true);
    }
  }, [showFullText]);

  /* Render */

  // Classes
  let classNames = styles.container;
  if (footerText) {
    classNames += ` ${styles.withFooter}`;
  }
  if (className) {
    classNames += ` ${className}`;
  }

  // Toggle button icon classes
  let toggleIconClassNames = styles.toggleButtonIcon;
  if (showFullText) {
    toggleIconClassNames = `${toggleIconClassNames} ${styles.open}`;
  }

  return (
    <CardLayout className={classNames} {...otherProps}>
      <OpenCloseTransition closedHeight={closedHeight} open={showFullText}>
        <Text className={styles.text} lineLimit={limitText ? 2 : undefined} ref={textRef}>
          <pre>{textContentMap[selectedLanguage].text}</pre>
        </Text>
      </OpenCloseTransition>
      {showOptionToExpandText && (
        <Button
          className={styles.toggleButton}
          linkStyle
          onClick={() => setShowFullText(!showFullText)}
          icon={<Icon className={toggleIconClassNames} name={ICONS.CARET.name} />}
        >
          {showFullText ? t('LESS') : t('MORE')}
        </Button>
      )}
      <div className={styles.languageButtons}>
        {languages.map((l) => {
          let buttonClassNames = styles.button;
          if (l === selectedLanguage) {
            buttonClassNames = `${buttonClassNames} ${styles.active}`;
          }
          return (
            <button className={buttonClassNames} key={l} onClick={() => onChangeLanguage(l)}>
              {l.slice(0, 2)}
            </button>
          );
        })}
      </div>
      {footerText && (
        <div className={styles.footer}>
          <Text bold size='xs'>
            {footerText}
          </Text>
        </div>
      )}
    </CardLayout>
  );
}

RemarkLorealCard.propTypes = {
  className: PropTypes.string,
  footerText: PropTypes.string,
  remark: PropTypes.object.isRequired,
  showFullTextInitial: PropTypes.bool,
};

/* Helpers */

const closedHeight = 46;
