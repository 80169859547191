import { gql } from '@apollo/client';

import BaseDocumentModel from '../document/base';
import InspectionAssetModel from '../inspectionAsset';
import { AudioMediaAssetModel, ImageMediaAssetModel, VideoMediaAssetModel } from '../mediaAsset';
import BaseMediaAssetModel from '../mediaAsset/base';
import NoteModel from '../note';
import BaseOrganisationModel from '../organisation/base';
import BaseProductModel from '../product/base';
import ProductInspectionAssetModel from '../productInspection';

import BaseItemModel from './base';

import { formatOkid } from 'OK/util/formatting';

export default class ItemModel {
  static GRAPHQL_TYPE = BaseItemModel.GRAPHQL_TYPE;

  static fragmentName = 'Item_Fragment';
  static fragment = gql`
    fragment ${ItemModel.fragmentName} on ${ItemModel.GRAPHQL_TYPE} {
      ...${BaseItemModel.fragmentName}
      availableProductInspectionList {
        ...${ProductInspectionAssetModel.fragmentName}
        inspectionAsset {
          ...${InspectionAssetModel.fragmentName}
        }
      }
      product {
        conformityPoint
        ...${BaseProductModel.fragmentName}
        organisation {
          ...${BaseOrganisationModel.fragmentName}
        }
        productDocumentAssetList {
          documentAsset {
            ...${BaseDocumentModel.fragmentName}
          }
          publishStatus
        }
        productMediaAssetList {
          mediaAsset {
            ...${BaseMediaAssetModel.fragmentName}
          }
          order
          publishStatus
        }
        productNoteAssetList {
          noteAsset {
            ...${NoteModel.fragmentName}
          }
          publishStatus
        }
      }
    }
    ${BaseItemModel.fragment}
    ${ProductInspectionAssetModel.fragment}
    ${InspectionAssetModel.fragment}
    ${BaseProductModel.fragment}
    ${BaseOrganisationModel.fragment}
    ${BaseDocumentModel.fragment}
    ${BaseMediaAssetModel.fragment}
    ${NoteModel.fragment}
  `;

  static fragmentNameAssemblyArchiveCard = 'Item_AssemblyArchiveCard_Fragment';
  static fragmentAssemblyArchiveCard = gql`
    fragment ${ItemModel.fragmentNameAssemblyArchiveCard} on ${ItemModel.GRAPHQL_TYPE} {
      id
      OKID
      reliabilityPointForPublishedLogs
      product {
        id
        conformityPoint
        name
        organisation {
          addressList {
            countryCode
          }
          id
        }
        productMediaAssetList {
          mediaAsset {
            id
            imageData {
              ...${ImageMediaAssetModel.fragmentName}
            }
            mediaType
          }
        }
      }
    }
    ${ImageMediaAssetModel.fragment}
  `;

  static fragmentNameAssemblyQuantities = 'Item_AssemblyQuantities_Fragment';
  static fragmentAssemblyQuantities = gql`
    fragment ${ItemModel.fragmentNameAssemblyQuantities} on ${ItemModel.GRAPHQL_TYPE} {
      id
      assemblyItemList {
        item {
          ...${ItemModel.fragmentNameAssemblyArchiveCard}
        }
        quantity
      }
    }
    ${ItemModel.fragmentAssemblyArchiveCard}
  `;

  static fragmentNameScanResult = 'Item_ScanResult_Fragment';
  static fragmentScanResult = gql`
    fragment ${ItemModel.fragmentNameScanResult} on ${ItemModel.GRAPHQL_TYPE} {
      id
      itemAlternateIdList
      OKID
      organisationId
      product {
        conformityPoint
        name
        reliabilityPointForPublishedLogs
        SKUID
        organisation {
          addressList {
            countryCode
          }
          conformityPoint
          name
          OKID
          reliabilityPointForPublishedLogs
        }
        productDocumentAssetList {
          documentAsset {
            coveredLanguageList
            documentName
            documentType
            documentURL
            id
            REFID
          }
        }
        productMediaAssetList {
          mediaAsset {
            mediaType
            imageData {
              ...${ImageMediaAssetModel.fragmentName}
            }
          }
        }
        productNoteAssetList {
          noteAsset {
            id
            textContentMap
          }
        }
      }
      reliabilityPointForPublishedLogs
      availableProductInspectionList {
        inspectionAsset {
          criticalAQL
          id
          majorAQL
          minorAQL
          organisationId
          publishedLogs
          REFID
          reliabilityPointForPublishedLogs
          inspectionTestAssetList {
            testAsset {
              testAssetMediaAssetList {
                mediaAsset {
                  mediaType
                  audioData {
                    ...${AudioMediaAssetModel.fragmentName}
                  }
                  imageData {
                    ...${ImageMediaAssetModel.fragmentName}
                  }
                  videoData {
                    ...${VideoMediaAssetModel.fragmentName}
                  }
                }
              }
            }
          }
        }
      }
    }
    ${AudioMediaAssetModel.fragment}
    ${ImageMediaAssetModel.fragment}
    ${VideoMediaAssetModel.fragment}
  `;

  static link(itemOrOKID) {
    let OKID;
    if (typeof itemOrOKID === 'string') {
      OKID = itemOrOKID;
    } else {
      OKID = itemOrOKID.OKID;
    }

    return `/${formatOkid(OKID)}`;
  }
}
