import { gql } from '@apollo/client';

import InspectionTestAssetModel from '../inspectionTestAsset';
import BaseOrganisationModel from '../organisation/base';

import BaseInspectionAssetModel from './base';

import localizeObject from 'OK/util/functions/localizeObject';

export default class InspectionAssetModel {
  static GRAPHQL_TYPE = BaseInspectionAssetModel.GRAPHQL_TYPE;

  static fragmentName = 'Inspection_Asset_Fragment';
  static fragment = gql`
    fragment ${InspectionAssetModel.fragmentName} on ${InspectionAssetModel.GRAPHQL_TYPE} {
      ...${BaseInspectionAssetModel.fragmentName}
      inspectionOrganisationList {
        ...${BaseOrganisationModel.fragmentName}
      }
      inspectionTestAssetList {
        ...${InspectionTestAssetModel.fragmentName}
      }
    }
    ${BaseInspectionAssetModel.fragment}
    ${BaseOrganisationModel.fragment}
    ${InspectionTestAssetModel.fragment}
  `;

  static fragmentNameEditCard = 'Inspection_EditCard_Fragment';
  static fragmentEditCard = gql`
    fragment ${InspectionAssetModel.fragmentNameEditCard} on ${InspectionAssetModel.GRAPHQL_TYPE} {
      id
      inspectionOrganisationList {
        id
        addressList {
          countryCode
        }
        logoImageMediaAsset {
          logoImageURL
        }
        name
      }
      inspectionTestAssetList {
        testAsset {
          id
          REFID
          testAssetMediaAssetList {
            mediaAsset {
              imageData {
                imageURL
              }
              mediaType
            }
          }
          testSeverityLevel
          whatToLookForMap
        }
      }
      name
      organisationId
      REFID
      reliabilityPointForPublishedLogs
    }
  `;

  static fragmentNameThirdPartyInspectors = 'Inspection_ThirdPartyInspectors_Fragment';
  static fragmentThirdPartyInspectors = gql`
    fragment ${InspectionAssetModel.fragmentNameThirdPartyInspectors} on ${InspectionAssetModel.GRAPHQL_TYPE} {
      id
      inspectionOrganisationList {
        id
        addressList {
          countryCode
        }
        logoImageMediaAsset {
          logoImageURL
        }
        name
      }
    }
  `;

  static localizedNameForInspectionAsset = (inspectionAsset, locale) => {
    return localizeObject(inspectionAsset?.name, locale, true)?.text ?? 'Unnamed';
  };
}
