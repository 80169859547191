import { useApolloClient, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import DocumentArchiveCard from 'OK/components/document/archiveCard';
import { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import InspectionArchiveCard from 'OK/components/inspection/archiveCard';
import InspectionLogArchiveCard from 'OK/components/inspectionLog/archiveCard';
import ItemArchiveCard from 'OK/components/item/archiveCard';
import LabelArchiveCard from 'OK/components/label/archiveCard';
import Link from 'OK/components/link';
import AudioArchiveCard from 'OK/components/media/audio/archiveCard';
import ImageArchiveCard from 'OK/components/media/image/archiveCard';
import VideoArchiveCard from 'OK/components/media/video/archiveCard';
import MediaPicker from 'OK/components/mediaPicker';
import OrderArchiveCard from 'OK/components/order/archiveCard';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import ProductArchiveCard from 'OK/components/product/archiveCard';
import RemarkArchiveCard from 'OK/components/remark/archiveCard';
import Select from 'OK/components/select';
import SiteArchiveCard from 'OK/components/site/archiveCard';
import TestArchiveCard from 'OK/components/test/archiveCard';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import config from 'OK/config/app';
import DocumentModel from 'OK/models/document';
import BaseDocumentModel from 'OK/models/document/base';
import InspectionLogModel from 'OK/models/inspectionLog';
import InspectionLogDocumentAssetModel from 'OK/models/inspectionLogDocumentAsset';
import MediaAssetModel from 'OK/models/mediaAsset';
import {
  addInspectionLogDocumentAssetOnExistingInspectionLog,
  addInspectionLogDocumentAssetOnNewInspectionLog,
  createInspectionLogDocumentAssetWithImageOnExistingInspectionLog,
  createInspectionLogDocumentAssetWithImageOnNewInspectionLog,
  setInspectionLogOrderOnSubmittedLogMutation,
  startInspectionLogBySourceMutation,
  submitInspectionLogMutation,
} from 'OK/networking/inspectionLogs';
import { searchQuery } from 'OK/networking/search';
import { setActiveInspectionLogId } from 'OK/state/account/actions';
import { showScannerAction } from 'OK/state/app/actions';
import simpleMIMEType from 'OK/util/functions/simpleMIMEType';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useCancellableApolloQuery from 'OK/util/hooks/useCancellableApolloQuery';
import useI18n from 'OK/util/hooks/useI18n';

const PAGE_SIZE = 3;

export default function AddWorkPopup(props) {
  const { data, dismiss, preSelectedData, preSelectedDataType } = props;
  const router = useRouter();
  const { t } = useI18n();
  const dispatch = useDispatch();
  const [authenticated, ,] = useAuthentication(() => false);
  const apolloClient = useApolloClient();

  const [currentDocument, setCurrentDocument] = useState({
    REFID: '',
    conformityPoint: 0,
    coveredLanguageList: [],
    documentType: 'RISK_ASSESSMENT_RECORD',
    id: '',
    linkedProductList: [],
    linkedSiteList: [],
    organisation: {},
    __typename: 'DocumentAsset',
  });
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [scannedSwitch, setScannedSwitch] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [searchPaginationDataByDataType, setSearchPaginationDataByDataType] = useState([
    { dataType: 'INSPECTION_ASSET', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
    { dataType: 'INSPECTION_LOG', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
    { dataType: 'ORDER', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
    { dataType: 'SITE', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
    { dataType: 'PRODUCT', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
    { dataType: 'LABEL', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
  ]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState([{ ...preSelectedData, dataType: preSelectedDataType }]);
  const [ selectedItem, setSelectedItem ] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [showStartNewLog, setShowStartNewLog] = useState(false);
  const [targetLog, setTargetLog] = useState(null);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [newDocumentType, setNewDocumentType] = useState('SCOPE_OF_WORKS');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const initialMediaPickerRef = useRef();
  const resultItemsRef = useRef([]);

  const [searchAPIQuery] = useCancellableApolloQuery();
  const [setInspectionLogOrderOnSubmittedLogAPI] = useMutation(setInspectionLogOrderOnSubmittedLogMutation);
  const [startInspectionLogWithoutDocumentAPI, startInspectionLogWithoutDocumentAPIResult] = useMutation(
    startInspectionLogBySourceMutation
  );
  const [submitInspectionLogAPI] = useMutation(submitInspectionLogMutation);

  const logCacheId = `${InspectionLogModel.GRAPHQL_TYPE}:${targetLog?.id}`;

  //   const file = useMemo(() => {
  //     return getDocumentResult.data?.documentAsset;
  //   }, [getDocumentResult.data?.documentAsset]);

  const ASSET_DATA_TYPES = useMemo(
    () => ['DOCUMENT_ASSET', 'ITEM', 'LABEL', 'MEDIA_ASSET', 'NOTE_ASSET', 'PRODUCT', 'TEST_ASSET'],
    []
  );

  const searchAPI = useCallback(
    (searchString, searchPaginationDataByDataType) => {
      const variables = {
        searchPaginationDataByDataType,
        sort: 'RELEVANCE_DESC',
      };
      // Keyword search
      if (searchString?.length) {
        variables.searchString = searchString;
      }

      return searchAPIQuery({
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        query: searchQuery,
        variables,
      });
    },
    [searchAPIQuery]
  );

  const startInspectionLogWithoutDocument = useCallback(() => {
    const targetInspectionAsset = selected.filter((item) => item.dataType === 'INSPECTION_ASSET')[0];
    const targetOrderAsset = selected.filter((item) => item.dataType === 'ORDER')[0];
    const targetSiteAsset = selected.filter((item) => item.dataType === 'SITE')[0];
    const targetItemAsset = selected.filter((item) => item.dataType === 'ITEM')[0];

    startInspectionLogWithoutDocumentAPI({
      variables: {
        inspectionAssetId: targetInspectionAsset?.id,
        orderId: targetOrderAsset?.id ?? '',
        sourceId: targetSiteAsset?.id ?? targetItemAsset?.id,
        sourceType: targetSiteAsset?.dataType ?? targetItemAsset?.dataType,
      },
    })
      .then((result) => {
        dispatch(setActiveInspectionLogId(result.data.inspectionLog.id));
        return submitInspectionLogAPI();
      })
      .then((result) => {
        dispatch(setActiveInspectionLogId(null));
        setTimeout(() => {
          router.push(`/archive/log/${result.data.inspectionLog.REFID}`);
        }, 1000);
      })
      .catch((e) => {
        if (e.message === 'INSPECTION_ASSET_LINK_DOES_NOT_EXIST') {
          setSearchError('A log could not be created because the workflow is not linked to the site.');
        } else {
          setSearchError(t('ERROR_GENERIC'));
        }
      });
  }, [selected, startInspectionLogWithoutDocumentAPI, dispatch, submitInspectionLogAPI, router, t]);

  function deleteFromSelected(index, dataType) {
    setSelected((current) => {
      return current.filter((_, i) => ![index].includes(i));
    });

    if (ASSET_DATA_TYPES.includes(dataType)) {
      const PAGINATION_DATA_TO_ADD = [
        { dataType: 'DOCUMENT_ASSET', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
        { dataType: 'ITEM', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
        { dataType: 'LABEL', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
        { dataType: 'MEDIA_ASSET', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
        { dataType: 'NOTE_ASSET', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
        { dataType: 'PRODUCT', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
        { dataType: 'TEST_ASSET', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
        { dataType: 'ORDER', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
      ];
      setSearchPaginationDataByDataType((current) => current.concat(PAGINATION_DATA_TO_ADD));
    } else if (dataType === 'INSPECTION_ASSET') {
      setSearchPaginationDataByDataType((current) => [
        ...current,
        { dataType: 'INSPECTION_ASSET', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
      ]);
      setSelectedWorkflow(null);
    } else if (dataType === 'SITE') {
      setSearchPaginationDataByDataType((current) => [
        ...current,
        { dataType: 'SITE', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
      ]);
      setSelectedSite(null);
    } else if (dataType === 'ORDER') {
      setSearchPaginationDataByDataType((current) => [
        ...current,
        { dataType: 'ORDER', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
      ]);
      setSelectedOrder(null);
    } else {
      setSearchPaginationDataByDataType((current) => [
        ...current,
        { dataType: 'INSPECTION_LOG', searchPaginationData: { pageSize: PAGE_SIZE, skip: 0 } },
      ]);
    }
  }

  const handleSearch = (e) => {
    const term = e.target.value;

    setSearchTerm(term);

    setLoading(true);
    setSearchError(null);

    searchAPI(term, searchPaginationDataByDataType)
      .then((result) => {
        if (result.data?.search?.resultList && result.data?.search?.searchPaginationResultDataByDataType) {
          // Initial load
          setResults(result.data.search.resultList);
        } else {
          setSearchError(t('ERROR_GENERIC'));
        }
      })
      .catch(() => {
        setSearchError(t('ERROR_GENERIC'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearchResultClick = (resultData, dataType, index) => {
    setSelected((current) => [...current, { ...resultData, dataType: dataType }]);
    setResults((current) => current.filter((result, i) => i !== index));

    if (dataType === 'INSPECTION_ASSET') {
      setSearchPaginationDataByDataType((current) =>
        current.filter((paginationData) => paginationData.dataType !== 'INSPECTION_ASSET')
      );
    } else if (dataType === 'SITE') {
      setSearchPaginationDataByDataType((current) =>
        current.filter((paginationData) => paginationData.dataType !== 'SITE')
      );
    } else if (dataType === 'ORDER') {
      setSearchPaginationDataByDataType((current) =>
        current.filter((paginationData) => paginationData.dataType !== 'ORDER')
      );
    } else if (dataType === 'PRODUCT') {
      setSearchPaginationDataByDataType((current) =>
        current.filter((paginationData) => !paginationData.dataType !== 'PRODUCT')
      );
    } else {
      setSearchPaginationDataByDataType((current) =>
        current.filter((paginationData) => !paginationData.dataType !== 'INSPECTION_LOG')
      );
    }
    setSearchTerm('');
  };

  const handleSearchResultLogClick = (index) => {
    for (const [i, resultItem] of resultItemsRef.current.entries()) {
      if (i === index) {
        continue;
      }

      const currentClassNames = resultItem?.children[0].className;

      if (currentClassNames?.includes('styles_selected')) {
        let classToDelete;

        for (let className of resultItem.children[0].classList.values()) {
          if (className.includes('styles_selected')) {
            classToDelete = className;
            break;
          }
        }
        resultItem.children[0].classList.remove(classToDelete);

        setTargetLog(null);
      }
    }

    const currentClassNames = resultItemsRef.current[index].children[0].className;

    if (currentClassNames.includes('styles_selected')) {
      let classToDelete;

      for (let className of resultItemsRef.current[index].children[0].classList.values()) {
        if (className.includes('styles_selected')) {
          classToDelete = className;
          break;
        }
      }
      resultItemsRef.current[index].children[0].classList.remove(classToDelete);
      setTargetLog(null);
    } else {
      resultItemsRef.current[index].children[0].className = `${currentClassNames} ${styles.selected}`;
      setTargetLog(results[index].inspectionLogData);
    }
    setSearchTerm('');
  };

  const saveDocumentFile = async (files) => {
    setFileData(files[0]);
  };

  const createNewInspectionLog = async () => {
    const targetInspectionAsset = selected.filter((item) => item.dataType === 'INSPECTION_ASSET')[0];
    const targetOrderAsset = selected.filter((item) => item.dataType === 'ORDER')[0];
    const targetSiteAsset = selected.filter((item) => item.dataType === 'SITE')[0];
    const targetItemAsset = selected.filter((item) => item.dataType === 'ITEM')[0];

    const mimeType = simpleMIMEType(fileData.type);

    setLoadingIndicator(true);

    let response;
    
    switch (mimeType) {
      case 'image':
        response = await createInspectionLogDocumentAssetWithImageOnNewInspectionLog(
          targetInspectionAsset.organisationId,
          newDocumentType,
          fileData,
          targetInspectionAsset.id,
          targetOrderAsset?.id ?? '',
          targetSiteAsset?.id ?? targetItemAsset?.id,
          targetSiteAsset?.dataType ?? targetItemAsset?.dataType
        );
        break;
      default:
        response = await addInspectionLogDocumentAssetOnNewInspectionLog(
          targetInspectionAsset.organisationId,
          newDocumentType,
          fileData,
          targetInspectionAsset.id,
          targetOrderAsset?.id ?? '',
          targetSiteAsset?.id ?? targetItemAsset?.id,
          targetSiteAsset?.dataType ?? targetItemAsset?.dataType

        );
        break;
    }

    if (response.success) {
      setLoadingIndicator(false);
      setTimeout(() => {
        router.push(`/archive/log/${response.responseData.refid}`);
      }, 1000);
    } else if (response.error) {
      setSearchError(t('ERROR_GENERIC'));
    }
  };

  const onScanSearchOKID = useCallback((okid) => {
    // Not sure why timeout is required to make this work
    setTimeout(() => {
      setSearchTerm(okid);
      setScannedSwitch(true);
    }, 0);
  }, []);

  const showScanner = () => {
    if (config.features.scanTab) {
      dispatch(showScannerAction(onScanSearchOKID));
    }
  };

  /* Effects */

  useEffect(() => {
    if (data === 'A4H6-J9KJ-MNXY') {
      setCurrentDocument({
        REFID: '',
        conformityPoint: 0,
        coveredLanguageList: [],
        documentType: 'RISK_ASSESSMENT_RECORD',
        id: '',
        linkedProductList: [],
        __typename: 'DocumentAsset',
      });
    }
  }, [data]);

  useEffect(() => {
    const targetInspectionAsset = selected.filter((item) => item.dataType === 'INSPECTION_ASSET')[0];
    const targetOrderAsset = selected.filter((item) => item.dataType === 'ORDER')[0];
    const targetSiteAsset = selected.filter((item) => item.dataType === 'SITE')[0];
    const targetItemAsset = selected.filter((item) => item.dataType === 'ITEM')[0];
    
    setSelectedItem(targetItemAsset);
    setSelectedSite(targetSiteAsset);
    setSelectedOrder(targetOrderAsset);
    setSelectedWorkflow(targetInspectionAsset);

    if ((selected.length > 0 && targetSiteAsset && targetInspectionAsset) || (selected.length > 0 && targetInspectionAsset)) {
      setShowStartNewLog(true);
    } else {
      setShowStartNewLog(false);
    }
  }, [selected]);

  useEffect(() => {
    const createOnExistingLog = async () => {
      const targetOrderAsset = selected.filter((item) => item.dataType === 'ORDER')[0];

      let targetLogRefId;
      let response;

      if (selected.length > 0 && (fileData || targetOrderAsset) && targetLog) {
        if (fileData) {
          const mimeType = simpleMIMEType(fileData.type);
          setLoadingIndicator(true);

          switch (mimeType) {
            case 'image':
              response = await createInspectionLogDocumentAssetWithImageOnExistingInspectionLog(
                newDocumentType,
                fileData,
                targetLog.id
              );

              break;
            default:
              response = await addInspectionLogDocumentAssetOnExistingInspectionLog(
                targetLog.id,
                fileData,
                newDocumentType
              );
              break;
          }

          if (response.success) {
            const documentAsset = {
              ...response.responseData,
              assetAccessPermission: null,
              coveredLanguageList: [],
              documentType: 'RISK_ASSESSMENT_RECORD',
            };

            const documentAssetRef = {
              id: apolloClient.cache.identify(documentAsset),
              fragment: BaseDocumentModel.fragment,
              fragmentName: BaseDocumentModel.fragmentName,
              data: documentAsset,
            };

            const documentAssetCached = {
              __typename: InspectionLogDocumentAssetModel.GRAPHQL_TYPE,
              documentAsset: documentAssetRef,
              inspectionLogAssetSource: 'INSPECTOR',
            };

            apolloClient.cache.modify({
              id: logCacheId,
              fields: {
                inspectionLogDocumentAssetList: (currentList = []) => {
                  return [...currentList, documentAssetCached];
                },
              },
            });

            targetLogRefId = response.responseData.refid;
          } else if (response.error) {
            setSearchError(t('ERROR_GENERIC'));
          }
        }

        if (targetOrderAsset) {
          setInspectionLogOrderOnSubmittedLogAPI({
            variables: { inspectionLogId: targetLog.id, orderId: targetOrderAsset.id },
          });
        }

        setLoadingIndicator(false);
        setTimeout(() => {
          router.push(`/archive/log/${targetOrderAsset ? targetLog.REFID : targetLogRefId}`);
        }, 1000);
      } else if (targetLog?.REFID) {
        // Go to log if clicked but not enough data to update
        router.push(`/archive/log/${targetLog.REFID}`);
      }
    };

    createOnExistingLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, fileData, targetLog]);

  useEffect(() => {
    authenticated &&
      searchAPI(searchTerm, searchPaginationDataByDataType)
        .then((result) => {
          if (result.data?.search?.resultList && result.data?.search?.searchPaginationResultDataByDataType) {
            // Initial load
            setResults(result.data.search.resultList);
          } else {
            setSearchError(t('ERROR_GENERIC'));
          }
        })
        .catch(() => {
          setSearchError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setLoading(false);
        });
  }, [authenticated, currentDocument, searchAPI, searchPaginationDataByDataType, searchTerm, t]);

  // Hide error message after 5 seconds
  useEffect(() => {
    if (searchError) {
      setTimeout(() => {
        setSearchError(false);
        setLoadingIndicator(false);
      }, 10000);
    }
  }, [searchError]);

  // Load document config
  useEffect(() => {
    async function loadConfig() {
      setDocumentTypes(await DocumentModel.getTypes());
    }

    loadConfig();
  }, []);

  // Don't show search results for same type as preselected data
  useEffect(() => {
    if (preSelectedDataType) {
      setSearchPaginationDataByDataType((current) =>
        current.filter((paginationData) => paginationData.dataType !== preSelectedDataType)
      );
    }
  }, [preSelectedDataType]);

  // effect for adding site to the selected list after QR code scan
  useEffect(() => {
    if (results.length === 1 && scannedSwitch) {
      const siteResult = results[0];
      handleSearchResultClick(siteResult.siteData, siteResult.dataType, 0);
      setScannedSwitch(false);
    }
  }, [scannedSwitch, results]);

  return (
    <>
      <Popup dismiss={dismiss}>
        <PopupContent className={styles.popupContainer}>
          <div className={styles.headerRow}>
            <h3 className={styles.header}>Log work</h3>
            <PopupCloseButton className={styles.cancelButton} linkStyle tint='alert'>
              {t('CANCEL')}
            </PopupCloseButton>
          </div>
          {authenticated ? (
            <>
              <>
                {!fileData && (
                  <>
                    <Text bold className={styles.newDocumentTypeLabel}>
                      Document type:
                    </Text>
                    <Select
                      className={styles.newDocumentTypeSelect}
                      disableEmptySelection
                      onChange={setNewDocumentType}
                      options={documentTypes.map((docType) => {
                        return {
                          label: t(`DOCUMENT_TYPE_${docType.typeId}`),
                          value: docType.typeId,
                        };
                      })}
                      value={newDocumentType}
                    />
                  </>
                )}
                <MediaPicker
                  className={styles.mediaPicker}
                  label={t('ADD_NEW_DOCUMENTATION')}
                  mediaTypes={['document', 'photo']}
                  onChange={saveDocumentFile}
                  ref={initialMediaPickerRef}
                  invisible={!fileData ? false : true}
                  promptToEditAfterPick={false}
                />
              </>
              {fileData && (
                <>
                  <DocumentArchiveCard
                    className={styles.resultCardContainer}
                    cardClassName={styles.resultCard}
                    documentAsset={{
                      ...currentDocument,
                      documentType: newDocumentType,
                    }}
                    fixedWidth={false}
                    linkToArchivePage={false}
                    includeFiles
                    filesMessage={'1 files'}
                    layoutOverride={'mobile'}
                  />
                  <Button
                    className={styles.replaceMediaButton}
                    onClick={initialMediaPickerRef.current?.open}
                    tint='navigation'
                  >
                    Replace
                  </Button>
                </>
              )}
              {selected?.map((asset, index) => {
                switch (asset.dataType) {
                  case 'SITE':
                    return (
                      <>
                        <SiteArchiveCard
                          className={styles.selectedResultCardContainer}
                          cardClassName={`${styles.resultCard} ${styles.selected}`}
                          fixedWidth={false}
                          linkToArchive={false}
                          site={asset}
                          layoutOverride={'mobile'}
                        />
                        <div className={styles.removeLinkContainer}>
                          <Button
                            className={styles.removeLink}
                            linkStyle
                            onClick={() => deleteFromSelected(index, asset.dataType)}
                            tint='alert'
                          >
                            Remove
                          </Button>
                        </div>
                      </>
                    );
                  case 'ORDER':
                    return (
                      <>
                        <OrderArchiveCard
                          className={styles.selectedResultCardContainer}
                          cardClassName={`${styles.resultCard} ${styles.selected}`}
                          fixedWidth={false}
                          linkToArchivePage={false}
                          order={asset}
                          layoutOverride={'mobile'}
                        />
                        <div className={styles.removeLinkContainer}>
                          <Button
                            className={styles.removeLink}
                            linkStyle
                            onClick={() => deleteFromSelected(index, asset.dataType)}
                            tint='alert'
                          >
                            Remove
                          </Button>
                        </div>
                      </>
                    );
                  case 'DOCUMENT_ASSET':
                    return (
                      <>
                        <DocumentArchiveCard
                          cardClassName={`${styles.resultCard} ${styles.selected}`}
                          className={styles.selectedResultCardContainer}
                          documentAsset={asset}
                          fixedWidth={false}
                          linkToArchivePage={false}
                          layoutOverride={'mobile'}
                        />
                        <div className={styles.removeLinkContainer}>
                          <Button
                            className={styles.removeLink}
                            linkStyle
                            onClick={() => deleteFromSelected(index, asset.dataType)}
                            tint='alert'
                          >
                            Remove
                          </Button>
                        </div>
                      </>
                    );
                  case 'INSPECTION_ASSET':
                    return (
                      <>
                        <InspectionArchiveCard
                          cardClassName={`${styles.resultCard} ${styles.selected}`}
                          className={styles.selectedResultCardContainer}
                          fixedWidth={false}
                          inspectionAsset={asset}
                          linkToArchivePage={false}
                          linkToInspection={false}
                          layoutOverride={'mobile'}
                        />
                        <div className={styles.removeLinkContainer}>
                          <Button
                            className={styles.removeLink}
                            linkStyle
                            onClick={() => deleteFromSelected(index, asset.dataType)}
                            tint='alert'
                          >
                            Remove
                          </Button>
                        </div>
                      </>
                    );
                  case 'ITEM':
                    return (
                      <>
                        <ItemArchiveCard
                          cardClassName={`${styles.resultCard} ${styles.selected}`}
                          className={styles.selectedResultCardContainer}
                          fixedWidth={false}
                          item={asset}
                          linkToArchivePage={false}
                          layoutOverride={'mobile'}
                        />
                        <Button block onClick={() => deleteFromSelected(index, asset.dataType)} tint='alert'>
                          Remove
                        </Button>
                      </>
                    );
                  case 'LABEL':
                    return <></>
                    return (
                      <>
                        <LabelArchiveCard
                          cardClassName={`${styles.resultCard} ${styles.selected}`}
                          className={styles.selectedResultCardContainer}
                          fixedWidth={false}
                          label={asset}
                          linkToLabel={false}
                          layoutOverride={'mobile'}
                        />
                        <Button
                          block
                          onClick={() => deleteFromSelected(index, asset.dataType)}
                          tint='alert'
                          style={{ marginTop: 20 }}
                        >
                          Remove
                        </Button>
                      </>
                    );
                  case 'MEDIA_ASSET': {
                    const mediaAsset = asset;
                    switch (mediaAsset.mediaType) {
                      case MediaAssetModel.MEDIA_TYPE.IMAGE:
                        return (
                          <>
                            <ImageArchiveCard
                              cardClassName={`${styles.resultCard} ${styles.selected}`}
                              className={styles.resultCardContainer}
                              fixedWidth={false}
                              linkToArchivePage={false}
                              mediaAsset={mediaAsset}
                              layoutOverride={'mobile'}
                            />
                            <Button block onClick={() => deleteFromSelected(index, asset.dataType)} tint='alert'>
                              Remove
                            </Button>
                          </>
                        );
                      case MediaAssetModel.MEDIA_TYPE.VIDEO:
                        return (
                          <>
                            <VideoArchiveCard
                              cardClassName={`${styles.resultCard} ${styles.selected}`}
                              className={styles.resultCardContainer}
                              fixedWidth={false}
                              linkToArchivePage={false}
                              mediaAsset={mediaAsset}
                              layoutOverride={'mobile'}
                            />
                            <Button block onClick={() => deleteFromSelected(index, asset.dataType)} tint='alert'>
                              Remove
                            </Button>
                          </>
                        );
                      case MediaAssetModel.MEDIA_TYPE.AUDIO:
                        return (
                          <>
                            <AudioArchiveCard
                              cardClassName={`${styles.resultCard} ${styles.selected}`}
                              className={styles.resultCardContainer}
                              fixedWidth={false}
                              linkToArchivePage={false}
                              mediaAsset={mediaAsset}
                              layoutOverride={'mobile'}
                            />
                            <Button block onClick={() => deleteFromSelected(index, asset.dataType)} tint='alert'>
                              Remove
                            </Button>
                          </>
                        );
                    }
                    return null;
                  }
                  case 'NOTE_ASSET':
                    return (
                      <>
                        <RemarkArchiveCard
                          cardClassName={`${styles.resultCard} ${styles.selected}`}
                          className={styles.resultCardContainer}
                          fixedWidth={false}
                          linkToArchivePage={false}
                          noteAsset={asset}
                          layoutOverride={'mobile'}
                        />
                        <Button block onClick={() => deleteFromSelected(index, asset.dataType)} tint='alert'>
                          Remove
                        </Button>
                      </>
                    );
                  case 'PRODUCT':
                    return (
                      <>
                        <ProductArchiveCard
                          cardClassName={`${styles.resultCard} ${styles.selected}`}
                          className={styles.resultCardContainer}
                          fixedWidth={false}
                          linkToArchivePage={false}
                          product={asset}
                          layoutOverride={'mobile'}
                        />
                        <Button block onClick={() => deleteFromSelected(index, asset.dataType)} tint='alert'>
                          Remove
                        </Button>
                      </>
                    );
                  case 'TEST_ASSET':
                    return (
                      <>
                        <TestArchiveCard
                          cardClassName={`${styles.resultCard} ${styles.selected}`}
                          className={styles.resultCardContainer}
                          fixedWidth={false}
                          linkToArchivePage={false}
                          testAsset={asset}
                          layoutOverride={'mobile'}
                        />
                        <Button block onClick={() => deleteFromSelected(index, asset.dataType)} tint='alert'>
                          Remove
                        </Button>
                      </>
                    );
                }
              })}
              <Text size='md' bold>
                Add workflow or log
              </Text>
              <SearchInput
                className={styles.search}
                onChange={handleSearch}
                onClickSearch={showScanner}
                placeholder={t('SEARCH_PLACEHOLDER')}
                //   ref={this.searchInputRef}
                showClearButton={true}
                value={searchTerm}
                withScan={true}
              />
              <div className={styles.resultCardsContainer}>
                {loading ? (
                  <Text size='md' bold>
                    {t('SEARCHING')}...
                  </Text>
                ) : results.length === 0 ? (
                  <Text size='md' bold>
                    {t('SEARCH_SUGGESTIONS_NO_RESULTS_FOUND')}
                  </Text>
                ) : (
                  results?.map((r, index) => {
                    switch (r.dataType) {
                      case 'ORDER': {
                        const isLinkedToCurrentSite =
                          selectedSite !== undefined &&
                          r.orderData.orderSiteList.filter((site) => site.site.OKID === selectedSite?.OKID).length !==
                            0;

                        if (isLinkedToCurrentSite) {
                          return (
                            <OrderArchiveCard
                              className={styles.resultCardContainer}
                              cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                              fixedWidth={false}
                              linkToArchivePage={false}
                              order={r.orderData}
                              onClick={() => handleSearchResultClick(r.orderData, r.dataType, index)}
                              layoutOverride={'mobile'}
                            />
                          );
                        }
                        break;
                      }
                      case 'SITE': {
                        const isLinkedToCurrentWorkflow =
                          selectedWorkflow !== undefined &&
                          r.siteData.siteInspectionAssetList.filter(
                            (workflow) => workflow.inspectionAsset.REFID === selectedWorkflow?.REFID
                          ).length !== 0;

                        if (isLinkedToCurrentWorkflow || selectedWorkflow === undefined) {
                          return (
                            <SiteArchiveCard
                              className={styles.resultCardContainer}
                              cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                              fixedWidth={false}
                              linkToArchive={false}
                              site={r.siteData}
                              onClick={() => handleSearchResultClick(r.siteData, r.dataType, index)}
                              layoutOverride={'mobile'}
                            />
                          );
                        }
                        break;
                      }

                      case 'DOCUMENT_ASSET':
                        return (
                          <DocumentArchiveCard
                            cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                            className={styles.resultCardContainer}
                            documentAsset={r.documentAssetData}
                            fixedWidth={false}
                            linkToArchivePage={false}
                            onClick={() => handleSearchResultClick(r.documentAssetData, r.dataType, index)}
                            layoutOverride={'mobile'}
                          />
                        );
                      case 'INSPECTION_ASSET': {
                        const isLinkedToCurrentSite =
                          selectedSite !== undefined &&
                          r.inspectionAssetData.linkedSiteList.filter((site) => site.OKID === selectedSite?.OKID)
                            .length !== 0;
                        const isLinkedToCurrentItem = selectedItem !== undefined &&
                            selectedItem.product?.productInspectionAssetList?.filter((inspectionAsset) => inspectionAsset.inspectionAsset.REFID === r?.inspectionAssetData?.REFID)
                            .length !== 0; 

                        if (isLinkedToCurrentSite || isLinkedToCurrentItem) {
                          return (
                            <InspectionArchiveCard
                              cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                              className={styles.resultCardContainer}
                              fixedWidth={false}
                              inspectionAsset={r.inspectionAssetData}
                              key={r.dataId}
                              linkToArchivePage={false}
                              linkToInspection={false}
                              onClick={() => handleSearchResultClick(r.inspectionAssetData, r.dataType, index)}
                              layoutOverride={'mobile'}
                            />
                          );
                        }
                        break;
                      }
                      case 'INSPECTION_LOG': {
                        const isLinkedToCurrentSite =
                          selectedSite !== undefined && r.inspectionLogData.site?.OKID === selectedSite?.OKID;
                        const isLinkedToCurrentOrder =
                          selectedOrder !== undefined &&
                          r.inspectionLogData.linkedOrder?.REFID === selectedOrder?.REFID;

                        if (isLinkedToCurrentSite && !isLinkedToCurrentOrder) {
                          return (
                            <div
                              ref={(element) => {
                                resultItemsRef.current[index] = element;
                              }}
                            >
                              <InspectionLogArchiveCard
                                cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                                className={styles.resultCardContainer}
                                fixedWidth={false}
                                inspectionLog={r.inspectionLogData}
                                key={r.dataId}
                                linkToArchivePage={false}
                                onClick={() => handleSearchResultLogClick(index)}
                                showLinkToProduct={false}
                                layoutOverride={'mobile'}
                              />
                            </div>
                          );
                        }
                        break;
                      }

                      case 'ITEM':
                        return (
                          <ItemArchiveCard
                            cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                            className={styles.resultCardContainer}
                            fixedWidth={false}
                            item={r.itemData}
                            key={r.dataId}
                            linkToArchive={false}
                            onClick={() => handleSearchResultClick(r.itemData, r.dataType, index)}
                            layoutOverride={'mobile'}
                          />
                        );
                      case 'LABEL':
                        return (
                          <LabelArchiveCard
                            cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                            className={styles.resultCardContainer}
                            fixedWidth={false}
                            key={r.dataId}
                            label={r.labelData}
                            linkToLabel={false}
                            onClick={() => handleSearchResultClick(r.labelData, r.dataType, index)}
                            layoutOverride={'mobile'}
                          />
                        );
                      case 'MEDIA_ASSET': {
                        const mediaAsset = r.mediaAssetData;
                        switch (mediaAsset.mediaType) {
                          case MediaAssetModel.MEDIA_TYPE.IMAGE:
                            return (
                              <ImageArchiveCard
                                cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                                className={styles.resultCardContainer}
                                fixedWidth={false}
                                key={r.dataId}
                                linkToArchivePage={false}
                                mediaAsset={mediaAsset}
                                onClick={() => handleSearchResultClick(mediaAsset, r.dataType, index)}
                                layoutOverride={'mobile'}
                              />
                            );
                          case MediaAssetModel.MEDIA_TYPE.VIDEO:
                            return (
                              <VideoArchiveCard
                                cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                                className={styles.resultCardContainer}
                                fixedWidth={false}
                                key={r.dataId}
                                linkToArchivePage={false}
                                mediaAsset={mediaAsset}
                                onClick={() => handleSearchResultClick(mediaAsset, r.dataType, index)}
                                layoutOverride={'mobile'}
                              />
                            );
                          case MediaAssetModel.MEDIA_TYPE.AUDIO:
                            return (
                              <AudioArchiveCard
                                cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                                className={styles.resultCardContainer}
                                fixedWidth={false}
                                key={r.dataId}
                                linkToArchivePage={false}
                                mediaAsset={mediaAsset}
                                onClick={() => handleSearchResultClick(mediaAsset, r.dataType, index)}
                                layoutOverride={'mobile'}
                              />
                            );
                        }
                        return null;
                      }
                      case 'NOTE_ASSET':
                        return (
                          <RemarkArchiveCard
                            cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                            className={styles.resultCardContainer}
                            fixedWidth={false}
                            key={r.dataId}
                            linkToArchivePage={false}
                            noteAsset={r.noteAssetData}
                            onClick={() => handleSearchResultClick(r.noteAssetData, r.dataType, index)}
                            layoutOverride={'mobile'}
                          />
                        );
                      case 'PRODUCT':
                        return (
                          <ProductArchiveCard
                            cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                            className={styles.resultCardContainer}
                            fixedWidth={false}
                            key={r.dataId}
                            linkToArchivePage={false}
                            product={r.productData}
                            onClick={() => handleSearchResultClick(r.productData, r.dataType, index)}
                            layoutOverride={'mobile'}
                          />
                        );
                      case 'TEST_ASSET':
                        return (
                          <TestArchiveCard
                            cardClassName={`${styles.resultCard} ${styles.searchResultWrapper}`}
                            className={styles.resultCardContainer}
                            fixedWidth={false}
                            key={r.dataId}
                            linkToArchivePage={false}
                            testAsset={r.testAssetData}
                            onClick={() => handleSearchResultClick(r.testAssetData, r.dataType, index)}
                            layoutOverride={'mobile'}
                          />
                        );
                      default:
                        return null;
                    }
                  })
                )}
              </div>
            </>
          ) : (
            <>
              <Text>{t('LOG_ADD_WORK_POPUP_SIGN_IN_ERROR')}</Text>
              <Link buttonStyle block tint='navigation' href='/auth'>
                {t('PAGE_TITLE_AUTH')}
              </Link>
            </>
          )}
        </PopupContent>
        <PopupButtonsGroup>
          {showStartNewLog && (
            <Button
              icon={ICONS.PLUS.name}
              onClick={fileData ? createNewInspectionLog : startInspectionLogWithoutDocument}
              tint='creation'
              loading={startInspectionLogWithoutDocumentAPIResult.loading || loadingIndicator}
            >
              Add new log
            </Button>
          )}
        </PopupButtonsGroup>
        <FadeInOutTransition className={styles.errorMessage} in={searchError}>
          <Toast className={styles.toastMessage} tint='alert'>
            <p>{searchError}</p>
          </Toast>
        </FadeInOutTransition>
      </Popup>
    </>
  );
}

AddWorkPopup.propTypes = {
  data: PropTypes.any,
  dismiss: PropTypes.bool,
  onAddWork: PropTypes.func,
  preSelectedData: PropTypes.object,
  preSelectedDataType: PropTypes.string,
};
