import trueTypeof from './trueTypeof';

import languages from 'OK/assets/languages.json';
import { languageForLocale } from 'OK/util/i18n';

/**
 * Recursively resolves objects with localized values to an appropriate value for the specified locale.
 *
 * Example:
 *
 * The function receives the following object:
 *
 * const obj = {
 *    color: {
 *      en: 'red',
 *      es: 'rojo',
 *    },
 *    type: 'color',
 * };
 *
 * If the current locale is Spanish, the function should return:
 *
 * const obj = {
 *    color: 'rojo',
 *    type: 'color',
 * };
 *
 * If the current locale is English, or `allowFallbacks` is `true`, it should return:
 *
 * const obj = {
 *    color: 'red',
 *    type: 'color',
 * };
 *
 * @param {object} object The object to localize
 * @param {string} locale The locale to resolve the object for. Defaults to the application's current locale.
 * @param {boolean} [allowFallbacks=false] If no value is present for the given `locale`, fallback to English or any other
 * present value. Defaults to `false`.
 */
export default function localizeObject(object, locale, allowFallbacks = false) {
  const languageIso = languageForLocale(locale).iso;

  const objectType = trueTypeof(object);
  if (objectType === '[object Array]') {
    // Localize each entry in the array.
    const localizedArray = object.map((o) => localizeObject(o, locale));
    return localizedArray;
  } else if (objectType === '[object Object]') {
    if (isLocalizable(object)) {
      // The object itself can be resolved
      const localizedValue = resolveLocalizedValue(object, languageIso, allowFallbacks);
      return localizedValue;
    }

    // Recursively localize each entry in the object
    const keys = Object.keys(object);
    const resolvedObject = {};
    for (let x = 0; x < keys.length; x++) {
      const key = keys[x];
      const value = object[key];
      if (isLocalizable(value)) {
        resolvedObject[key] = resolveLocalizedValue(value, languageIso, allowFallbacks);
      } else {
        resolvedObject[key] = localizeObject(value, locale);
      }
    }

    return resolvedObject;
  } else {
    return object;
  }
}

/**
 * Return the value matching the given language ISO code.
 *
 * @param {object} object The object to resolve. Keys must be language ISO codes.
 * @param {string} languageIso The language ISO code to resolve a value for.
 * @param {boolean} [allowFallbacks=false] In cases where no value is present for the given language ISO code, attempt
 * to fallback to English or any other present value. Defaults to `false`.
 */
function resolveLocalizedValue(object, languageIso, allowFallbacks = false) {
  // Return a localized value for the given language if it exists
  const localizedValue = object[languageIso];
  if (localizedValue !== undefined) {
    return localizedValue;
  }

  if (allowFallbacks) {
    // First fallback is English
    const englishValue = object.EN;
    if (englishValue) {
      return englishValue;
    }

    // Final fallback is the first available key
    const keys = Object.keys(object);
    if (keys.length) {
      const key = keys[0];
      return object[key];
    }
  }

  return undefined;
}

/** Returns `true` if the object only contains keys matching langauge ISO codes. */
function isLocalizable(object) {
  const objectType = trueTypeof(object);
  if (objectType !== '[object Object]') {
    return false;
  }

  const keys = Object.keys(object);
  for (let x = 0; x < keys.length; x++) {
    const key = keys[x];
    const keyIsLanguageISO = languages.findIndex((l) => l.iso === key) !== -1;
    if (!keyIsLanguageISO) {
      if (key !== 'ZH_HANS' && key !== 'ZH_HANT' && key !== 'ZH-CN' && key !== 'ZH-HK') {
        return false;
      }
    }
  }

  return true;
}
