/* Queries */

import { gql } from '@apollo/client';

import CommentModel from 'OK/models/comment';

export const GetCommentsForAssetQuery = gql`
  query GetCommentsForAsset(
    $page: Int!
    $size: Int!
    $sourceDataType: EDataType!
    $sourceId: String!
    $sourceOrganisationId: String!
    $threadPage: Int!
    $threadSize: Int!
  ) {
    commentsData: getCommentPagedResult (
      page: $page
      size: $size
      sourceDataType: $sourceDataType
      sourceId: $sourceId
      sourceOrganisationId: $sourceOrganisationId
    ) {
      commentListCount
      commentList {
        ...${CommentModel.fragmentNameSingleComment}
        commentThreadPagedResult(page: $threadPage, size: $threadSize) {
          commentListCount
          commentList {
            ...${CommentModel.fragmentNameSingleComment}
          }
        }
      }
    }
  }
  ${CommentModel.fragmentSingleComment}
`;

export const GetCommentThreadQuery = gql`
  query GetCommentThread($commentId: String!, $page: Int!, $size: Int!) {
    comment: getComment(commentId: $commentId) {
      id
      commentThreadPagedResult(page: $page, size: $size) {
        commentListCount
        commentList {
          ...${CommentModel.fragmentNameSingleComment}
        }
      }
    }
  }
  ${CommentModel.fragmentSingleComment}
`;

/* Mutations */

export const PostCommentOnAssetMutation = gql`
  mutation PostCommentOnAsset(
    $commentText: String!
    $sourceDataType: EDataType!
    $sourceId: String!
    $sourceOrganisationId: String!
  ) {
    comment: createComment(
      commentText: $commentText
      sourceDataType: $sourceDataType
      sourceId: $sourceId
      sourceOrganisationId: $sourceOrganisationId
    ) {
      ...${CommentModel.fragmentNameSingleCommentWithThread}
    }
  }
  ${CommentModel.fragmentSingleCommentWithThread}
`;

export const PostCommentOnCommentMutation = gql`
  mutation PostCommentOnComment(
    $commentText: String!
    $parentCommentId: String!
  ) {
    comment: createCommentOnComment(
      commentText: $commentText
      parentCommentId: $parentCommentId
    ) {
      ...${CommentModel.fragmentNameSingleCommentWithThread}
    }
  }
  ${CommentModel.fragmentSingleCommentWithThread}
`;

export const DeleteCommentMutation = gql`
  mutation DeleteComment(
    $commentId: String!
  ) {
    comment: deleteComment(
      commentId: $commentId
    ) {
      ...${CommentModel.fragmentNameSingleCommentWithThread}
    }
  }
  ${CommentModel.fragmentSingleCommentWithThread}
`;

export const CommentLikeMutation = gql`
  mutation CommentLike($commentId: String!) {
    like: likeComment(commentId: $commentId) {
      id
      likeCount
      likedByUser
    }
  }
`;

export const CommentUnlikeMutation = gql`
  mutation CommentUnlike($commentId: String!) {
    unlike: unlikeComment(commentId: $commentId) {
      id
      likeCount
      likedByUser
    }
  }
`;
