import PropTypes from 'prop-types';
import { cloneElement, isValidElement, useContext } from 'react';

import styles from './styles.module.scss';
import baseTint from './tints/base.module.scss';
import cardTint from './tints/card.module.scss';
import focusTint from './tints/focus.module.scss';
import midtoneTint from './tints/midtone.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import UIContext from 'OK/util/context/ui';

/**
 * The OK Grade default button component.
 * @param {object} props
 * @param {boolean} [props.block=false] Display the button as a block element. Internally uses `display: flex` when `true` and `display: inline-flex` when `false`.
 * @param {any} [props.children] The button's children to render.
 * @param {string} [props.className] The button's class.
 * @param {boolean} [props.disabled] Disable the button.
 * @param {string} [props.icon] A path to an icon that should be shown on the button.
 * @param {('left'|'right')} [props.iconPosition='right'] The position of the icon relative to the button title.
 * @param {boolean} [props.invert=false] Invert the color-scheme of the button. When `true`, the background color will change to white and become the text color.
 * @param {boolean} [props.linkStyle=false] Display the button as a Link.
 * @param {boolean} [props.loading=false] Show a loading spinner on the Button. Will replace any specified icon.
 * @param {('alert'|'creation'|'navigation'|'notification'|'secondary')} [props.tint] The tint color of the button.
 * @param {('button'|'submit'|'reset')} [props.type='button'] The `type` attribute for the button. See {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type}
 * @param {boolean} [props.withCaret=false] Convenience prop to set the icon to a caret.
 */
export default function Button(props) {
  const {
    block = false,
    children,
    className,
    disabled,
    icon,
    iconStyle,
    iconPosition = 'right',
    invert = false,
    linkStyle = false,
    loading = false,
    onClick,
    spanClassName,
    tint,
    toggled = false,
    toggleStyle = false,
    type = 'button',
    withCaret = false,
    ...otherProps
  } = props;

  const uiContext = useContext(UIContext);

  function _onClick(e) {
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();

      onClick(e);
    }
  }

  let tintStyle;
  switch (uiContext) {
    case 'card':
      tintStyle = cardTint;
      break;
    case 'focus':
      tintStyle = focusTint;
      break;
    case 'midtone':
      tintStyle = midtoneTint;
      break;
    default:
      tintStyle = baseTint;
      break;
  }

  let classNames = `${styles.all}`;
  if (block) {
    classNames = `${classNames} ${styles.block}`;
  }
  if (linkStyle) {
    classNames = `${classNames} ${styles.link} ${tintStyle.link}`;
  } else if (toggleStyle) {
    classNames = `${classNames} ${styles.toggle} ${tintStyle.toggle}`;
    if (toggled) {
      classNames += ` ${styles.toggled}`;
    }
  } else {
    classNames = `${classNames} ${styles.button} ${tintStyle.button}`;
  }
  if (invert) {
    classNames = `${classNames} ${styles.inverted} ${tintStyle.inverted}`;
  }
  if (loading) {
    classNames = `${classNames} ${styles.loading}`;
  }
  if (!children) {
    classNames = `${classNames} ${styles.iconOnly}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  switch (tint) {
    case 'alert':
    case 'creation':
    case 'navigation':
    case 'notification':
    case 'secondary':
      classNames = `${classNames} ${tintStyle[tint]}`;
      break;
    default:
      break;
  }

  let iconElement;
  let iconClassNames = `${iconStyle} ${styles.icon} ${styles[iconPosition]}`;
  if (loading) {
    iconElement = <Icon className={iconClassNames} name={ICONS.SPINNER.name} />;
  } else if (withCaret) {
    let linkStyles = styles.icon;
    if (iconPosition === 'right') {
      linkStyles = `${linkStyles} ${styles.right}`;
    } else {
      // Flip the caret when icon position should be on the left.
      linkStyles = `${linkStyles} ${styles.left} ${styles.flip}`;
    }

    iconElement = <Icon className={linkStyles} name={ICONS.CARET.name} />;
  } else if (icon) {
    if (ICONS[icon] !== undefined) {
      iconElement = <Icon className={`${iconStyle} ${styles.icon} ${styles[iconPosition]}`} name={icon} />;
    } else if (isValidElement(icon)) {
      if (icon.props.className) {
        iconClassNames = `${iconClassNames} ${icon.props.className}`;
      }
      iconElement = cloneElement(icon, { className: iconClassNames });
    } else {
      iconElement = <img alt='' className={`${iconStyle} ${styles.icon} ${styles[iconPosition]}`} src={icon} />;
    }
  }

  return (
    <>
      <button className={classNames} disabled={disabled} onClick={_onClick} type={type} {...otherProps}>
        {toggleStyle && <div className={`${styles.toggleActiveLayer} ${tintStyle.toggleActiveLayer}`} />}
        {(iconPosition === 'left' || iconPosition === 'center') && iconElement}
        {children && <span className={spanClassName}>{children}</span>}
        {iconPosition === 'right' && iconElement}
      </button>
    </>
  );
}

Button.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconStyle: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  invert: PropTypes.bool,
  linkStyle: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  spanClassName: PropTypes.string,
  toggled: PropTypes.bool,
  toggleStyle: PropTypes.bool,
  tint: PropTypes.string,
  type: PropTypes.string,
  withCaret: PropTypes.bool,
};
