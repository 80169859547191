import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { capitalize, debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import SearchInput from 'OK/components/input/search';
import CardLayout from 'OK/components/layouts/card';
import LinkDisplayer from 'OK/components/linkDisplayer';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Select from 'OK/components/select';
import Separator from 'OK/components/separator';
import Text from 'OK/components/text';
import Toggle from 'OK/components/toggle';
import OrderModel from 'OK/models/order';
import SiteModel from 'OK/models/site';
import {
  getContactRoleListQuery,
  linkAssetOnContactMutation,
  publishContactMutation,
  searchLinkAssetListQuery,
  searchLinkSiteListQuery,
  setContactEmailMutation,
  setContactNameMutation,
  setContactPhoneMutation,
  setContactRoleMutation,
  unlinkAssetOnContactMutation,
  unpublishContactMutation,
} from 'OK/networking/contacts';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import { formatOkid } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function SiteContactEditCard(props) {
  /* Variables */

  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const {
    className,
    isAuthorisedToEdit = false,
    mode,
    onClickDelete,
    onTriggerSave,
    saved = true,
    contact,
    sourceType,
    sourceId,
    linkDataType,
    ...otherProps
  } = props;
  const { t, locale } = useI18n();
  const typeOfContact = contact?.type;

  const [edited, setEdited] = useState(false);
  const [hasInputFocused, setHasInputFocused] = useState(false);
  const [contactIsValid, setContactIsValid] = useState(false);
  const [updatedContact, setUpdatedContact] = useState(contact);
  const [showError, setShowError] = useState(false);
  const [selectedRole, setSelectedRole] = useState(contact?.role);
  const [loading, setIsLoading] = useState(false);
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [newLinkAssetList, setNewLinkAssetList] = useState([]);
  const [searchLinkAssetString, setSearchLinkAssetString] = useState('');
  const [searchLinkSiteString, setSearchLinkSiteString] = useState('');
  const [searchLinkAssetFocused, setSearchLinkAssetFocused] = useState(false);
  const [searchLinkSiteFocused, setSearchLinkSiteFocused] = useState(false);
  const [published, setPublished] = useState(contact?.publishStatus == PUBLISH_STATUS.PUBLISHED);

  // APIs

  const [setContactNameAPI] = useMutation(setContactNameMutation);
  const [setContactRoleAPI] = useMutation(setContactRoleMutation);
  const [setContactPhoneAPI] = useMutation(setContactPhoneMutation);
  const [setContactEmailAPI] = useMutation(setContactEmailMutation);
  const contactRoleListQueryResult = useQuery(getContactRoleListQuery);
  const contactRoleList = contactRoleListQueryResult?.data?.contactRoleList;
  const [searchLinkAssetAPI, searchLinkAssetAPIResult] = useLazyQuery(searchLinkAssetListQuery);
  const [searchLinkSiteAPI, searchLinkSiteAPIResult] = useLazyQuery(searchLinkSiteListQuery);
  const [linkAssetAPI] = useMutation(linkAssetOnContactMutation);
  const [unlinkAssetAPI] = useMutation(unlinkAssetOnContactMutation);
  const [publishContactAPI] = useMutation(publishContactMutation);
  const [unpublishContactAPI] = useMutation(unpublishContactMutation);

  /* Methods */

  const deleteContact = useCallback(() => {
    setIsLoading(true);
    onClickDelete(contact).then(() => {
      setIsLoading(false);
    });
  }, [onClickDelete, contact]);

  const cancelContact = useCallback(() => {
    onClickDelete(contact);
  }, [onClickDelete, contact]);

  const trySave = useCallback(() => {
    if (contactIsValid && (mode == 'CREATE') & !hasInputFocused) {
      onTriggerSave(updatedContact);
      setEdited(false);
    }
  }, [hasInputFocused, onTriggerSave, contactIsValid, mode, updatedContact]);

  const setNameDebounced = useMemo(
    () =>
      debounce((newName) => {
        setContactNameAPI({
          variables: {
            name: newName,
            contactId: contact.id,
          },
        }).catch(() => {
          setShowError(t('ERROR_NAME'));
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [setContactNameAPI, contact, t]
  );

  const setEmailDebounced = useMemo(
    () =>
      debounce((newEmail) => {
        setContactEmailAPI({
          variables: {
            email: newEmail,
            contactId: contact.id,
          },
        }).catch(() => {
          setShowError(t('ERROR_GENERIC'));
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [setContactEmailAPI, contact, t]
  );

  const setPhoneDebounced = useMemo(
    () =>
      debounce((newPhone) => {
        setContactPhoneAPI({
          variables: {
            phone: newPhone,
            contactId: contact.id,
          },
        }).catch(() => {
          setShowError(t('ERROR_GENERIC'));
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [setContactPhoneAPI, contact, t]
  );

  const setRoleDebounced = useMemo(
    () =>
      debounce((newRole) => {
        setContactRoleAPI({
          variables: {
            role: newRole,
            contactId: contact.id,
          },
        }).catch(() => {
          setShowError(t('ERROR_GENERIC'));
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [setContactRoleAPI, contact, t]
  );

  const searchLinkAssetDebounced = useMemo(
    () =>
      debounce((searchLinkAssetString) => {
        searchLinkAssetAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'ORDER',
                ignoreIdList:
                  mode == 'CREATE'
                    ? [...updatedContact?.contactLinkInputList?.map((source) => source.dataId), sourceId]
                    : [...contact.contactLinkList.map((source) => source?.sourceId), sourceId],
              },
            ],
            searchPaginationDataByDataType: [{ dataType: 'ORDER', searchPaginationData: { pageSize: 4, skip: 0 } }],
            searchString: searchLinkAssetString,
            editingOrganisationIdList: [activeOrganisationId],
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [
      activeOrganisationId,
      contact.contactLinkList,
      mode,
      searchLinkAssetAPI,
      sourceId,
      updatedContact?.contactLinkInputList,
    ]
  );

  const searchLinkSiteDebounced = useMemo(
    () =>
      debounce((searchLinkSiteString) => {
        searchLinkSiteAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'SITE',
                ignoreIdList:
                  mode == 'CREATE'
                    ? [...updatedContact?.contactLinkInputList?.map((source) => source.dataId), sourceId]
                    : [...contact.contactLinkList.map((source) => source?.sourceId), sourceId],
              },
            ],
            searchPaginationDataByDataType: [{ dataType: 'SITE', searchPaginationData: { pageSize: 4, skip: 0 } }],
            searchString: searchLinkSiteString,
            editingOrganisationIdList: [activeOrganisationId],
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [
      activeOrganisationId,
      contact.contactLinkList,
      mode,
      searchLinkSiteAPI,
      sourceId,
      updatedContact?.contactLinkInputList,
    ]
  );
  /* Event handlers */

  const onInputBlurName = useCallback(() => {
    if (saved && typeOfContact !== 'LINKED') {
      setNameDebounced(newName);
    }
    setHasInputFocused(false);
  }, [newName, saved, setNameDebounced, typeOfContact]);

  const onInputBlurEmail = useCallback(() => {
    if (saved) {
      setEmailDebounced(newEmail);
    }
    setHasInputFocused(false);
  }, [newEmail, saved, setEmailDebounced]);

  const onInputBlurPhone = useCallback(() => {
    if (saved) {
      setPhoneDebounced(newPhone);
    }
    setHasInputFocused(false);
  }, [newPhone, saved, setPhoneDebounced]);

  const onInputFocus = useCallback(() => {
    // Track input focus with a slight delay to ensure it comes after input blur
    setTimeout(() => {
      setHasInputFocused(true);
    }, 100);
  }, []);

  const onSearchLinkAsset = useCallback(
    (e) => {
      setSearchLinkAssetString(e.target.value);

      if (searchLinkAssetString.length > 1) {
        searchLinkAssetDebounced(searchLinkAssetString);
      }
    },
    [searchLinkAssetDebounced, searchLinkAssetString]
  );

  const onSearchLinkSite = useCallback(
    (e) => {
      setSearchLinkSiteString(e.target.value);

      if (searchLinkSiteString.length > 1) {
        searchLinkSiteDebounced(searchLinkSiteString);
      }
    },
    [searchLinkSiteDebounced, searchLinkSiteString]
  );

  const onChangeEmail = useCallback(
    (e) => {
      setNewEmail(e.target.value);
      const updatedNewContact = { ...updatedContact, email: e.target.value };
      setUpdatedContact(updatedNewContact);
    },
    [updatedContact]
  );

  const onChangeName = useCallback(
    (e) => {
      setNewName(e.target.value);
      const updatedNewContact = { ...updatedContact, name: e.target.value };
      setUpdatedContact(updatedNewContact);
      newName.length > 0 ? setContactIsValid(true) : null;
      setShowError(false);
      setEdited(true);
    },
    [newName, updatedContact]
  );

  const onChangePhone = useCallback(
    (e) => {
      setNewPhone(e.target.value);
      const updatedNewContact = { ...updatedContact, phone: e.target.value };
      setUpdatedContact(updatedNewContact);
    },
    [updatedContact]
  );

  const onChangeRole = useCallback(
    (selectedRole) => {
      setSelectedRole(selectedRole);
      const updatedNewContact = { ...updatedContact, role: selectedRole };
      setUpdatedContact(updatedNewContact);
      if (saved) {
        setRoleDebounced(selectedRole);
      }
    },
    [saved, setRoleDebounced, updatedContact]
  );

  const onPublishToggle = useCallback(() => {
    if (!published) {
      setPublished(true);
      publishContactAPI({ variables: { contactId: contact?.id } });
    } else if (published) {
      setPublished(false);
      unpublishContactAPI({ variables: { contactId: contact?.id } });
    }
  }, [contact?.id, publishContactAPI, published, unpublishContactAPI]);

  let searchSuggestions;
  if (searchLinkAssetString.length > 1 && searchLinkAssetFocused && searchLinkAssetAPIResult !== undefined) {
    searchSuggestions =
      searchLinkAssetAPIResult.data?.search?.resultList?.map((r) => {
        return {
          key: r.orderData?.id,
          title: OrderModel.localizedNameForOrder(r.orderData, locale),
          subtitle: r.orderData?.REFID,
        };
      }) ?? [];
  }

  let searchSuggestionsSite;
  if (searchLinkSiteString.length > 1 && searchLinkSiteFocused && searchLinkAssetAPIResult !== undefined) {
    searchSuggestionsSite =
      searchLinkSiteAPIResult.data?.search?.resultList?.map((r) => {
        return {
          key: r.siteData?.id,
          title: SiteModel.localizedNameForSite(r.siteData, locale),
          subtitle: r.siteData?.OKID,
        };
      }) ?? [];
  }

  const onSelectionOrder = useCallback(
    (searchSuggestions) => {
      const selectedAssetId = searchSuggestions;
      const selectedAsset = searchLinkAssetAPIResult.data?.search?.resultList?.find((asset) => {
        return asset?.orderData?.id == selectedAssetId;
      });
      // setNewOrderList(searchOrderSuggestions);
      const updatedNewContact = {
        ...updatedContact,
        contactLinkInputList: [...newLinkAssetList, selectedAsset],
      };
      setNewLinkAssetList(updatedNewContact?.contactLinkInputList);
      setUpdatedContact(updatedNewContact);
      if (saved) {
        linkAssetAPI({
          variables: { contactId: contact?.id, sourceType: 'ORDER', sourceId: selectedAssetId },
        });
      }
      setSearchLinkAssetString('');
    },
    [
      searchLinkAssetAPIResult.data?.search?.resultList,
      updatedContact,
      newLinkAssetList,
      saved,
      linkAssetAPI,
      contact?.id,
    ]
  );

  const onSelectionSite = useCallback(
    (searchSuggestionsSite) => {
      const selectedAssetId = searchSuggestionsSite;
      const selectedAsset = searchLinkSiteAPIResult.data?.search?.resultList?.find((asset) => {
        return asset?.siteData?.id == selectedAssetId;
      });
      // setNewOrderList(searchOrderSuggestions);
      const updatedNewContact = {
        ...updatedContact,
        contactLinkInputList: [...newLinkAssetList, selectedAsset],
      };
      setNewLinkAssetList(updatedNewContact?.contactLinkInputList);
      setUpdatedContact(updatedNewContact);
      if (saved) {
        linkAssetAPI({
          variables: { contactId: contact?.id, sourceType: 'SITE', sourceId: selectedAssetId },
        });
      }
      setSearchLinkSiteString('');
    },
    [
      searchLinkSiteAPIResult.data?.search?.resultList,
      updatedContact,
      newLinkAssetList,
      saved,
      linkAssetAPI,
      contact?.id,
    ]
  );

  const onRemoveLinkAsset = useCallback(
    (assetId, dataType) => {
      unlinkAssetAPI({
        variables: { sourceType: dataType, sourceId: assetId, contactId: contact.id },
      });
    },
    [contact.id, unlinkAssetAPI]
  );

  const onRemoveOrderUnsaved = useCallback(
    (assetId) => {
      const newOrderList = updatedContact?.contactLinkInputList?.filter((asset) => {
        return assetId !== asset.sourceId ?? [];
      });
      const updatedNewContact = {
        ...updatedContact,
        contactLinkInputList: newOrderList,
      };
      setNewLinkAssetList(updatedNewContact?.contactLinkInputList);
      setUpdatedContact(updatedNewContact);
    },
    [updatedContact]
  );

  /* Effects */

  // Save when no more inputs are focused
  useEffect(() => {
    let saveTimeout;
    if (edited && !hasInputFocused) {
      // Wait before trying to save. This gives time for the user to switch between inputs and not trigger a save
      // prematurely. The wait time here needs to be longer than the wait time in the `onInputFocus` event handler.
      saveTimeout = setTimeout(() => {
        trySave();
      }, 100);
    }

    return function cleanup() {
      clearTimeout(saveTimeout);
    };
  }, [edited, hasInputFocused, trySave]);

  /* Render */

  let classNames = styles.card;
  if (!saved) {
    classNames += ` ${styles.unsaved}`;
  }
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <CardLayout className={classNames} {...otherProps}>
      <div className={styles.header}>
        <h4>{t('CONTACTS_SECTION_CONTACT_TITLE')}</h4>
        {!saved && (
          <Text bold size='sm' tint='notification'>
            {t('CONTACTS_SECTION_CONTACT_NOT_SAVED')}
          </Text>
        )}
      </div>
      <label className={styles.field}>
        <Text bold className={styles.label}>
          {t('CONTACTS_SECTION_CONTACT_OKID')}
        </Text>
        <Input
          className={styles.readOnlyInput}
          value={contact?.user?.OKID ? formatOkid(contact?.user?.OKID) : 'None'}
          readOnly
        />
      </label>
      <label className={styles.field}>
        <Text bold className={styles.label}>
          {t('CONTACTS_SECTION_CONTACT_NAME')}
        </Text>
        <Input
          className={typeOfContact == 'LINKED' && styles.readOnlyInput}
          onBlur={onInputBlurName}
          onFocus={onInputFocus}
          onChange={onChangeName}
          placeholder={typeOfContact == 'LINKED' ? '' : 'Enter a name'}
          value={typeOfContact == 'LINKED' ? contact?.user?.name : contact?.name}
          readOnly={isAuthorisedToEdit ? typeOfContact == 'LINKED' : true}
          disabled={!isAuthorisedToEdit && true}
        />
      </label>
      {showError && (
        <Text className={styles.error} size='sm' tint='notification'>
          {showError}
        </Text>
      )}
      <label className={styles.field}>
        <Text bold className={styles.label}>
          {t('CONTACTS_SECTION_CONTACT_ROLE')}
        </Text>
        {!isAuthorisedToEdit ? (
          <Text>{capitalize(contact.role)}</Text>
        ) : (
          contactRoleList && (
            <Select
              className={styles.select}
              options={contactRoleList.map((role) => ({
                label: t(role),
                value: role,
              }))}
              onChange={(selectedRole) => onChangeRole(selectedRole)}
              value={selectedRole}
              disableEmptySelection
            />
          )
        )}
      </label>
      <label className={styles.field}>
        <Text bold className={styles.label}>
          {t('CONTACTS_SECTION_CONTACT_EMAIL')}
          <span className={styles.normalWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
        </Text>
        <Input
          className={typeOfContact == 'LINKED' && styles.readOnlyInput}
          onBlur={onInputBlurEmail}
          onFocus={onInputFocus}
          onChange={onChangeEmail}
          placeholder={isAuthorisedToEdit ? (typeOfContact == 'LINKED' ? '' : 'Enter an email address') : ''}
          value={typeOfContact == 'LINKED' ? contact?.user?.email : contact?.email}
          readOnly={isAuthorisedToEdit ? typeOfContact == 'LINKED' : true}
          disabled={!isAuthorisedToEdit && true}
        />
      </label>
      <label className={styles.field}>
        <Text bold className={styles.label}>
          {t('CONTACTS_SECTION_CONTACT_PHONE')}
          <span className={styles.normalWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
        </Text>
        <Input
          className={typeOfContact == 'LINKED' ? styles.readOnlyInput : null}
          onBlur={onInputBlurPhone}
          onFocus={onInputFocus}
          onChange={onChangePhone}
          placeholder={isAuthorisedToEdit ? (typeOfContact == 'LINKED' ? '' : 'Enter a phone number') : ''}
          value={typeOfContact == 'LINKED' ? contact?.user?.mobile : contact?.phone}
          readOnly={isAuthorisedToEdit ? typeOfContact == 'LINKED' : true}
          disabled={!isAuthorisedToEdit && true}
        />
      </label>
      {/* {sourceType !== 'INSPECTION_LOG' && (
        <>
          <label className={styles.field}>
            <Text bold className={styles.label}>
              {t('CONTACTS_SECTION_CONTACT_ORDER')}
            </Text>
            <div className={`${styles.searchInputContainer} ${styles.searchInputContainerPrimary}`}>
              <SearchInput
                className={`${styles.searchInput} $${styles.searchInputPrimary}`}
                loading={searchLinkAssetAPIResult.loading}
                onBlur={() => setSearchLinkAssetFocused(false)}
                onChange={onSearchLinkAsset}
                onFocus={() => setSearchLinkAssetFocused(true)}
                placeholder={t('CONTACTS_SECTION_CONTACT_ADD_ORDER')}
                value={searchLinkAssetString}
              />
              <SearchSuggestions
                accessoryViewRender={() => (
                  <Button className={styles.searchResultButton} icon='/icons/plus_green.svg' linkStyle></Button>
                )}
                className={`${styles.searchResults} ${styles.searchResultsPrimary}`}
                highlightTerm={searchLinkAssetString}
                onSuggestionClick={onSelectionOrder}
                showMoreResultsMessage={searchSuggestions?.length > 4}
                showNoResultsMessage={searchSuggestions?.length === 0}
                suggestions={searchSuggestions?.slice(0, 4)}
              />
            </div>
            <div>
              {mode == 'CREATE'
                ? updatedContact?.contactLinkInputList
                    ?.filter((asset) => {
                      return asset.dataType == 'ORDER' && asset.dataId !== sourceId;
                    })
                    .map((asset) => {
                      return (
                        <LinkDisplayer
                          key={asset?.orderData.id}
                          title={OrderModel.localizedNameForOrder(asset?.orderData, locale)}
                          subtitle={asset?.orderData.REFID}
                          onClickRemove={() => onRemoveOrderUnsaved(asset?.orderData?.dataId)}
                        />
                      );
                    })
                : contact?.contactLinkList
                    ?.filter((asset) => {
                      return asset.sourceType == 'ORDER' && asset.sourceId !== sourceId;
                    })
                    .map((asset) => {
                      return (
                        <LinkDisplayer
                          key={asset?.orderData?.id}
                          title={OrderModel.localizedNameForOrder(asset?.orderData, locale)}
                          subtitle={asset?.orderData?.REFID}
                          onClickRemove={() => onRemoveLinkAsset(asset?.orderData?.id, 'ORDER')}
                        />
                      );
                    })}
            </div>
          </label>
          <label className={`${styles.field} ${styles.fieldLast}`}>
            <Text bold className={styles.label}>
              {t('CONTACTS_SECTION_CONTACT_SITE')}
            </Text>
            <div className={styles.searchInputContainer}>
              <SearchInput
                className={styles.searchInput}
                loading={searchLinkSiteAPIResult.loading}
                onBlur={() => setSearchLinkSiteFocused(false)}
                onChange={onSearchLinkSite}
                onFocus={() => setSearchLinkSiteFocused(true)}
                placeholder={t('CONTACTS_SECTION_CONTACT_ADD_SITE')}
                value={searchLinkSiteString}
              />
              <SearchSuggestions
                accessoryViewRender={() => (
                  <Button className={styles.searchResultButton} icon='/icons/plus_green.svg' linkStyle></Button>
                )}
                className={styles.searchResults}
                highlightTerm={searchLinkSiteString}
                onSuggestionClick={onSelectionSite}
                showMoreResultsMessage={searchSuggestionsSite?.length > 4}
                showNoResultsMessage={searchSuggestionsSite?.length === 0}
                suggestions={searchSuggestionsSite?.slice(0, 4)}
              />
            </div>
            <div className={styles.linkDisplayerContainer}>
              {mode == 'CREATE'
                ? updatedContact?.contactLinkInputList
                    ?.filter((asset) => {
                      return asset.dataType == 'SITE' && asset.dataId !== sourceId;
                    })
                    .map((asset) => {
                      return (
                        <LinkDisplayer
                          key={asset?.siteData.id}
                          title={OrderModel.localizedNameForOrder(asset?.siteData, locale)}
                          subtitle={formatOkid(asset?.siteData?.OKID)}
                          onClickRemove={() => onRemoveOrderUnsaved(asset?.siteData?.dataId)}
                        />
                      );
                    })
                : contact?.contactLinkList
                    ?.filter((asset) => {
                      return asset.sourceType == 'SITE' && asset.sourceId !== sourceId;
                    })
                    .map((asset) => {
                      return (
                        <LinkDisplayer
                          key={asset?.siteData?.id}
                          title={SiteModel.localizedNameForSite(asset?.siteData, locale)}
                          subtitle={formatOkid(asset?.siteData?.OKID)}
                          onClickRemove={() => onRemoveLinkAsset(asset?.siteData?.id, 'SITE')}
                        />
                      );
                    })}
            </div>
          </label>
        </>
      )} */}

      {isAuthorisedToEdit ? (
        <>
          <Separator type='subSection' className={styles.seperatorMain} />
          {mode == 'EDIT' && (
            <div className={styles.publishRow}>
              <p className={styles.publishLabel}>
                <strong>{t('PUBLISH')}</strong>
              </p>
              <Toggle checked={published} onChange={onPublishToggle} />
            </div>
          )}
          {typeOfContact == 'LINKED' ? (
            <Button
              block
              className={styles.deleteButton}
              icon={ICONS.UNLINK.name}
              onClick={deleteContact}
              loading={loading}
              disabled={loading}
              tint='alert'
            >
              {t('CONTACTS_SECTION_CONTACT_UNLINK')}
            </Button>
          ) : (
            <Button
              block
              className={styles.deleteButton}
              icon={saved ? ICONS.UNLINK.name : ICONS.TRASH.name}
              onClick={saved ? deleteContact : cancelContact}
              loading={saved && loading}
              disabled={saved && loading}
              tint='alert'
            >
              {saved ? t('CONTACTS_SECTION_CONTACT_UNLINK') : t('CONTACTS_SECTION_CONTACT_DELETE')}
            </Button>
          )}
        </>
      ) : null}
    </CardLayout>
  );
}

SiteContactEditCard.propTypes = {
  className: PropTypes.string,
  isAuthorisedToEdit: PropTypes.bool,
  mode: PropTypes.string,
  onClickDelete: PropTypes.func.isRequired,
  onTriggerSave: PropTypes.func,
  linkDataType: PropTypes.string,
  sourceType: PropTypes.string,
  sourceId: PropTypes.string,
  saved: PropTypes.bool,
  contact: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    role: PropTypes.string,
    type: PropTypes.string,
    publishStatus: PropTypes.string,
    contactLinkList: PropTypes.any,
    user: PropTypes.shape({
      name: PropTypes.string,
      mobile: PropTypes.string,
      email: PropTypes.string,
      OKID: PropTypes.string,
    }),
  }).isRequired,
};
