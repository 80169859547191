const AssetAccessPermission = {
  COMMENT_PERMISSION: 'COMMENT_PERMISSION',
  EDIT_PERMISSION: 'EDIT_PERMISSION',
  READ_PERMISSION: 'READ_PERMISSION',
  SHARED_WITH_COMMENT_PERMISSION: 'SHARED_WITH_COMMENT_PERMISSION',
  SHARED_WITH_EDIT_PERMISSION: 'SHARED_WITH_EDIT_PERMISSION',
  SHARED_WITH_READ_PERMISSION: 'SHARED_WITH_READ_PERMISSION',
};

export default AssetAccessPermission;
