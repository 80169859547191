import { NetworkStatus, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isFuture from 'date-fns/isFuture';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Checkbox from 'OK/components/checkbox';
import Countdown from 'OK/components/countdown';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import Link, { LinkNoRedux } from 'OK/components/link';
import { PopupButtonsGroup, PopupCloseButton } from 'OK/components/popup';
import Separator from 'OK/components/separator';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import appConfig from 'OK/config/app';
import { registerForWebinarMutation, requestWebinarRecording } from 'OK/networking/events';
import { getWebinarQuery } from 'OK/networking/news';
import { hasSubscribedToNewsLetter } from 'OK/networking/users';
import { DEBOUNCE_TIMING_MS_LONG } from 'OK/util/constants';
import { formatDate } from 'OK/util/formatting';
import shareUtil, { SHARE_ACTION, SHARE_TYPE } from 'OK/util/functions/share';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';

const SHARE_BUTTON_POSITION = {
  HEADER: 'HEADER',
  FLOATING: 'FLOATING',
};

export default function WebinarRegistration(props) {
  /* Variables */

  const { onRegister, webinarId } = props;
  const { t, tHTML, locale } = useI18n();
  const [, , currentUser] = useAuthentication(() => false);

  /* API */

  const getWebinarResult = useQuery(getWebinarQuery, {
    variables: { webinarId },
  });
  const webinar = getWebinarResult.data?.webinar;
  const noWebinarFound = !webinar && getWebinarResult.called && !getWebinarResult.loading;

  const [hasSubscribedToNewsLetterAPI, hasSubscribedToNewsLetterAPIResult] = useLazyQuery(hasSubscribedToNewsLetter, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.response === null) {
        setAccountExists(false);
      } else {
        setAccountExists(true);
      }
    },
  });

  const [registerAPI, registerAPIResult] = useMutation(registerForWebinarMutation);

  /* State */

  const [accountExists, setAccountExists] = useState(false);
  const [email, setEmail] = useState(currentUser?.email ? currentUser.email : '');
  const [emailInputError, setEmailInputError] = useState(null);
  const [emailIsValid, setEmailIsValid] = useState(currentUser?.email ? true : undefined);
  const [error, setError] = useState(null);
  const [marketingOptIn, setMarketingOptIn] = useState(false);

  const [showNewsletterSubscriptionCheckbox, setShowNewsletterSubscriptionCheckbox] = useState(false);

  const [shareToastMessage, setShareToastMessage] = useState(null);
  const [shareButtonPosition, setShareButtonPosition] = useState(null);

  const [recordingRequestEmail, setRecordingRequestEmail] = useState('');
  const [recordingRequestEmailError, setRecordingRequestEmailError] = useState(null);
  const [recordingRequestError, setRecordingRequestError] = useState(null);
  const [recordingRequestLoading, setRecordingRequestLoading] = useState(false);
  const [recordingRequestSubmitted, setRecordingRequestSubmitted] = useState(false);

  const isUpcomingWebinar = webinar ? isFuture(webinar.startDate) : false;

  /* Methods */

  const checkNewsletterSubscription = useCallback(
    (uname) => {
      hasSubscribedToNewsLetterAPI({ variables: { email: uname } });
    },
    [hasSubscribedToNewsLetterAPI]
  );

  const validateUsername = useCallback(async (email) => {
    const validator = (await import('validator')).default;

    const isValid = validator.isEmail(email);
    setEmailIsValid(isValid);

    return isValid;
  }, []);

  const validateUsernameDebounced = useMemo(
    () =>
      debounce((uname) => {
        if (uname) {
          validateUsername(uname).then((res) => {
            res == true && checkNewsletterSubscription(uname);
          });
        }
      }, DEBOUNCE_TIMING_MS_LONG),
    [checkNewsletterSubscription, validateUsername]
  );

  const register = useCallback(async () => {
    if (!email) {
      return;
    }

    registerAPI({
      variables: {
        email,
        marketingOptIn,
        webinarId,
      },
    })
      .then((result) => {
        if (result.data?.participant?.email === email) {
          if (typeof onRegister === 'function') {
            onRegister(email);
          }
        } else {
          setError(t('ERROR_GENERIC'));
        }
      })
      .catch(() => {
        setError(t('ERROR_GENERIC'));
      });
  }, [email, marketingOptIn, onRegister, registerAPI, t, webinarId]);

  const share = useCallback(
    (position) => {
      setShareButtonPosition(position);
      const link = `https://${appConfig.domain}?webinarId=${webinarId}&utm_source=website&utm_medium=share&utm_campaign=webinar`;
      shareUtil(link, SHARE_TYPE.URL, true)
        .then((shareAction) => {
          if (shareAction === SHARE_ACTION.CLIPBOARD) {
            setShareToastMessage(t('COPIED_TO_CLIPBOARD'));
          }
        })
        .catch((e) => {
          okerror(e);
          setShareToastMessage(t('ERROR_SHARING_PLEASE_TRY_AGAIN'));
        });
    },
    [t, webinarId]
  );

  /* Event handlers */

  const onClickReset = useCallback(() => {
    setEmailInputError(null);
    setEmail('');
    validateUsernameDebounced.cancel();
    setEmailIsValid(undefined);
    setShowNewsletterSubscriptionCheckbox(false);
  }, [validateUsernameDebounced]);

  const onChangeEmail = useCallback(
    (e) => {
      setEmailInputError(null);
      setEmail(e.target.value);
      setEmailIsValid(undefined);
      validateUsernameDebounced(e.target.value);
    },
    [validateUsernameDebounced]
  );

  const onChangeMarketingOptIn = useCallback((e) => {
    setMarketingOptIn(e.target.checked);
  }, []);

  const onChangeRecordingRequestEmail = useCallback((e) => {
    setRecordingRequestEmail(e.target.value);
  }, []);

  const onEmailBlur = useCallback(() => {
    validateUsernameDebounced.flush();
  }, [validateUsernameDebounced]);

  const submitRecordingRequest = useCallback(() => {
    if (!recordingRequestEmail) {
      setRecordingRequestEmailError(t('FIELD_PLACEHOLDER_ENTER_YOUR_EMAIL_ADDRESS'));
      return;
    }

    setRecordingRequestError(null);
    setRecordingRequestEmailError(null);
    setRecordingRequestLoading(true);
    requestWebinarRecording(recordingRequestEmail)
      .then((response) => {
        if (response.success) {
          setRecordingRequestSubmitted(true);
        } else {
          setRecordingRequestError(t('ERROR_GENERIC'));
        }
      })
      .catch(() => {
        setRecordingRequestError(t('ERROR_GENERIC'));
      })
      .finally(() => {
        setRecordingRequestLoading(false);
      });
  }, [recordingRequestEmail, t]);

  /* Effects */

  // This in needed for keeping the checkbox's state up to date
  useEffect(() => {
    if (emailIsValid === undefined) {
      return;
    }
    if (!accountExists && emailIsValid && hasSubscribedToNewsLetterAPIResult.networkStatus === NetworkStatus.ready) {
      setShowNewsletterSubscriptionCheckbox(true);
      return;
    } else {
      if (
        hasSubscribedToNewsLetterAPIResult?.data?.response?.newsletterSubscription === 'false' &&
        hasSubscribedToNewsLetterAPIResult.networkStatus === NetworkStatus.ready
      ) {
        setShowNewsletterSubscriptionCheckbox(true);
      } else {
        setShowNewsletterSubscriptionCheckbox(false);
      }
    }
  }, [
    accountExists,
    emailIsValid,
    hasSubscribedToNewsLetterAPIResult?.data?.response?.newsletterSubscription,
    hasSubscribedToNewsLetterAPIResult.networkStatus,
  ]);

  // Hide share toast after 5 seconds
  useEffect(() => {
    let timeout;
    if (shareButtonPosition) {
      timeout = setTimeout(() => {
        setShareButtonPosition(null);
      }, 5000);
    }

    return function () {
      clearTimeout(timeout);
    };
  }, [shareButtonPosition]);

  // Check the newsletter subscription list at initial load
  useEffect(() => {
    if (currentUser?.email) {
      hasSubscribedToNewsLetterAPI({ variables: { email: currentUser?.email } });
    }
  }, []);

  /* Render */

  const durationMinutes = webinar ? differenceInMinutes(webinar.endDate, webinar.startDate) : 0;

  const hosts = webinar?.webinarHostList;

  const hostsList =
    hosts?.map((host) => {
      const names = host.name.split(' ');
      return {
        hostFirstName: names.shift(),
        hostLastName: names.join(' '),
        key: host.id,
        organisation: host.organisation,
        hostImage: host.imageURL,
        jobTitle: host.jobTitle,
      };
    }) ?? [];

  let inputIcon;
  if (emailIsValid === true) {
    inputIcon = <Icon name={ICONS.TICK.name} tint='creation' />;
  } else if (emailIsValid === false) {
    inputIcon = <Icon name={ICONS.X.name} tint='alert' />;
  }

  return (
    <div>
      <div className={styles.popup}>
        <div className={styles.mediaContainer} style={{ backgroundImage: `url(${webinar?.imageURL})` }}>
          <div className={styles.headerContainer}>
            <div className={styles.titleContainer}>
              <h3>{t('WEBINAR')}</h3>
              <div className={styles.shareButtonHeaderContainer}>
                {!noWebinarFound && (
                  <Button
                    className={styles.shareButton}
                    icon={ICONS.SHARE.name}
                    linkStyle
                    onClick={() => share(SHARE_BUTTON_POSITION.HEADER)}
                  >
                    {t('SHARE')}
                  </Button>
                )}
                <FadeInOutTransition
                  appear
                  in={!!shareToastMessage && shareButtonPosition === SHARE_BUTTON_POSITION.HEADER}
                >
                  <Toast className={styles.shareToast}>{shareToastMessage}</Toast>
                </FadeInOutTransition>
              </div>
              <PopupCloseButton linkStyle>{t('CLOSE')}</PopupCloseButton>
            </div>
            {isUpcomingWebinar && <Countdown date={webinar.startDate} />}
          </div>
        </div>
        {webinar ? (
          <>
            <div className={styles.mainContainer}>
              <h3>{webinar.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: webinar.descriptionHTML }} />
              <Separator type='section' />
              <div className={styles.dateNotice} fixedWidth={false} padded={false}>
                <h4>
                  {t('WEBINAR')}
                  <Tag className={styles.durationTag} size='md'>
                    {t('X_MINUTES_SHORT', { data: { number: durationMinutes } })}
                  </Tag>
                </h4>
                <Text size='md'>{formatDate(webinar.startDate, locale)}</Text>
              </div>
              <h4>{t('HOSTED_BY')}</h4>
              {hostsList.length > 0 &&
                hostsList.map((host) => (
                  <div className={styles.hostContainer} key={host.key}>
                    <img alt='Photo of the webinar host.' className={styles.hostImage} src={host.hostImage} />
                    <div>
                      <Text className={styles.hostName}>
                        <strong>{host.hostFirstName}</strong> {host.hostLastName}
                      </Text>
                      <Text bold className={styles.hostOrganisation} size='sm'>
                        {host.organisation ?? ''}
                      </Text>
                      <Text className={styles.hostJobTitle} size='sm'>
                        {host.jobTitle}
                      </Text>
                    </div>
                  </div>
                ))}
            </div>
            {isUpcomingWebinar ? (
              <div className={styles.registerContainer}>
                <h3>{t('REGISTER_FOR_SPOT')}</h3>
                <Text>{t('WEBINAR_LINK_WILL_BE_SENT_TO_EMAIL')}</Text>
                <div>
                  <Text bold className={styles.registrationLabel}>
                    {t('FIELD_LABEL_YOUR_EMAIL_ADDRESS')}

                    {email ? (
                      <Button className={styles.editButton} linkStyle tint='navigation' onClick={onClickReset}>
                        {t('RESET')}
                      </Button>
                    ) : (
                      <div style={{ height: '44px' }}></div>
                    )}
                  </Text>
                  <Input
                    className={styles.registrationInput}
                    onBlur={onEmailBlur}
                    onChange={onChangeEmail}
                    placeholder={t('FIELD_PLACEHOLDER_ENTER_YOUR_EMAIL_ADDRESS')}
                    value={email}
                    showClearButton={false}
                    withIcon={inputIcon}
                  />
                  {emailInputError && (
                    <Text className={styles.inputMessage} size='sm' tint='notification'>
                      {emailInputError}
                    </Text>
                  )}
                </div>
                {showNewsletterSubscriptionCheckbox && (
                  <div className={styles.marketingOptInContainer}>
                    <Text bold className={styles.marketingOptInOptionalLabel} size='xs'>
                      {t('OPTIONAL')}
                    </Text>
                    <div className={styles.marketingOptInCheckboxContainer}>
                      <Checkbox
                        className={styles.marketingOptInCheckbox}
                        checked={marketingOptIn}
                        onChange={onChangeMarketingOptIn}
                      />
                      <Text className={styles.marketingOptInLabel} size='sm'>
                        {t('RECEIVE_NEWS_OFFERS')}
                      </Text>
                    </div>
                  </div>
                )}
                <Button
                  block
                  className={styles.registerButton}
                  disabled={registerAPIResult.loading || !emailIsValid}
                  loading={registerAPIResult.loading}
                  onClick={register}
                  withCaret
                >
                  {t('REGISTER')}
                </Button>
                {error && (
                  <Text className={styles.errorMessage} size='sm' tint='alert'>
                    {error}
                  </Text>
                )}
                <Text className={styles.fineprint} size='xs'>
                  {tHTML('PROCEEDING_AGREE_TO_TERMS_PRIVACY_COOKIE', {
                    data: {
                      tos: (
                        <strong>
                          <LinkNoRedux className={styles.inlineLink} href='/solutions/terms' target='_blank'>
                            {t('TERMS_OF_SERVICE_LOWERCASE')}
                          </LinkNoRedux>
                        </strong>
                      ),
                      privacy: (
                        <strong>
                          <LinkNoRedux className={styles.inlineLink} href='/solutions/terms' target='_blank'>
                            {t('PRIVACY_POLICY_LOWERCASE')}
                          </LinkNoRedux>
                        </strong>
                      ),
                      cookie: (
                        <strong>
                          <LinkNoRedux className={styles.inlineLink} href='/solutions/terms' target='_blank'>
                            {t('COOKIES_LOWERCASE')}
                          </LinkNoRedux>
                        </strong>
                      ),
                    },
                  })}
                </Text>
              </div>
            ) : (
              <div className={styles.registerContainer}>
                <h3>Watch the recording</h3>
                {webinar.eventRecordingURL ? (
                  <>
                    <Text>Missed this webinar? No problem! Click here to watch a recording:</Text>
                    <Link
                      block
                      buttonStyle
                      href={webinar.eventRecordingURL}
                      icon={<Icon height={12} name={ICONS.EXTERNAL_LINK.name} width={12} />}
                      tint='navigation'
                    >
                      Watch now
                    </Link>
                  </>
                ) : (
                  <>
                    {!recordingRequestSubmitted ? (
                      <>
                        <Text>{t('MISSED_WEBINAR_MESSAGE')}</Text>
                        <Text className={styles.inputLabel} bold>
                          {t('FIELD_LABEL_YOUR_EMAIL_ADDRESS')}
                        </Text>
                        <Input
                          className={styles.input}
                          onChange={onChangeRecordingRequestEmail}
                          placeholder={t('FIELD_PLACEHOLDER_ENTER_YOUR_EMAIL_ADDRESS')}
                          value={recordingRequestEmail}
                        />
                        {recordingRequestEmailError && (
                          <Text className={styles.requestRecordingError} size='sm' tint='alert'>
                            {recordingRequestEmailError}
                          </Text>
                        )}
                        <Button
                          className={styles.button}
                          disabled={recordingRequestLoading}
                          icon={ICONS.DOWNLOAD.name}
                          loading={recordingRequestLoading}
                          onClick={submitRecordingRequest}
                          tint='navigation'
                        >
                          {t('GET_RECORDING')}
                        </Button>
                        {recordingRequestError && (
                          <Text className={styles.requestRecordingError} size='sm' tint='alert'>
                            {recordingRequestError}
                          </Text>
                        )}
                        <Text size='xs'>
                          {tHTML('SOLUTIONS_BRIEF_POPUP_FINEPRINT', {
                            data: {
                              privacyPolicyLink: (
                                <LinkNoRedux href='/solutions/terms'>{t('PRIVACY_POLICY_LOWERCASE')}</LinkNoRedux>
                              ),
                            },
                          })}
                        </Text>
                      </>
                    ) : (
                      <Text>{t('WEBINAR_LINK_EMAILED_MESSAGE')}</Text>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        ) : noWebinarFound ? (
          <Text className={styles.noWebinarFoundMessage} tint='alert'>
            {t('NO_WEBINAR_FOUND')}
          </Text>
        ) : (
          <Icon className={styles.webinarLoadingSpinner} height={64} name={ICONS.SPINNER.name} width={64} />
        )}
      </div>
      <PopupButtonsGroup>
        <div className={styles.shareButtonFloatingContainer}>
          {!noWebinarFound && (
            <Button icon={ICONS.SHARE.name} onClick={() => share(SHARE_BUTTON_POSITION.FLOATING)} tint='navigation'>
              {t('SHARE')}
            </Button>
          )}
          <FadeInOutTransition
            appear
            in={!!shareToastMessage && shareButtonPosition === SHARE_BUTTON_POSITION.FLOATING}
          >
            <Toast className={styles.shareToast}>{shareToastMessage}</Toast>
          </FadeInOutTransition>
        </div>
        <PopupCloseButton tint='navigation'>{t('CLOSE')}</PopupCloseButton>
      </PopupButtonsGroup>
    </div>
  );
}

WebinarRegistration.propTypes = {
  onRegister: PropTypes.func,
  webinarId: PropTypes.string.isRequired,
};
