import { gql } from '@apollo/client';

import NoteModel from './note';
import BaseNoteModel from './note/base';

import PUBLISH_STATUS from 'OK/util/enums/publishStatus';

export default class SiteNoteModel {
  static GRAPHQL_TYPE = 'SiteNoteAsset';

  static fragmentName = 'SiteNote_Fragment';
  static fragment = gql`
    fragment ${SiteNoteModel.fragmentName} on ${SiteNoteModel.GRAPHQL_TYPE} {
      noteAsset {
        ...${BaseNoteModel.fragmentName}
      }
      publishStatus
    }
    ${BaseNoteModel.fragment}
  `;

  static mock = () => {
    return {
      noteAsset: NoteModel.mock(),
      publishStatus: PUBLISH_STATUS.UNPUBLISHED,
      __typename: SiteNoteModel.GRAPHQL_TYPE,
    };
  };
}
