import config from 'OK/config/app';

export async function getGeocodingCoordinatesRequest(search_text) {
  const searchString = Buffer.from(`${search_text}`, 'utf-8').toString();
  return fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchString}.json?access_token=${MAPBOX_TOKEN}`, {
    headers: {
      'Content-type': 'application/json',
      'Access-Control-Allow-Methods': 'GET',
    },
    method: 'GET',
  });
}

export async function getStaticMapImageRequest(longitude, latitude) {
  return fetch(
    `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/pin-s+555555(${longitude},${latitude})/${longitude},${latitude},2.0,0/300x200@2x?access_token=${MAPBOX_TOKEN}`,
    { method: 'GET' }
  );
}

// Variables
const MAPBOX_TOKEN = config.mapBox_token;
