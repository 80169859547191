import { ACCOUNT_CLEAR_SESSION_ACTION } from '../account/actions';

import {
  BEGIN_LOGIN_ACTION,
  CREATE_ORGANISATION_ACTION,
  END_LOGIN_ACTION,
  INCLUDE_TRIAL_ACTION,
  UNSET_TRIAL_ACTION,
} from './actions';

export const initialState = {
  includeTrialForPlan: null,
  isLoggingIn: false,
  organisation: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_CLEAR_SESSION_ACTION:
      return {
        ...state,
        organisation: null,
        includeTrialForPlan: null,
      };
    case BEGIN_LOGIN_ACTION:
      return {
        ...state,
        isLoggingIn: true,
      };
    case CREATE_ORGANISATION_ACTION: {
      const organisation = action.data;
      return {
        ...state,
        organisation,
      };
    }
    case END_LOGIN_ACTION:
      return {
        ...state,
        isLoggingIn: false,
        includeTrialForPlan: null,
      };
    case INCLUDE_TRIAL_ACTION:
      return {
        ...state,
        includeTrialForPlan: action.data,
      };
    case UNSET_TRIAL_ACTION:
      return {
        ...state,
        includeTrialForPlan: null,
      };
    default:
      return state;
  }
}
