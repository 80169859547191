// Variables

const LOG_LEVEL_DEBUG = 'DEBUG';
const LOG_LEVEL_ERROR = 'ERROR';
const LOG_LEVEL_INFO = 'INFO';
const LOG_LEVEL_UNDEFINED = 'UNDEFINED';
const LOG_LEVEL_WARNING = 'WARNING';

// Util functions

// Map a log level to it's numeric value.
function getLogLevelValue(logLevel) {
  switch (logLevel) {
    case LOG_LEVEL_ERROR:
      return 80;
    case LOG_LEVEL_WARNING:
      return 60;
    case LOG_LEVEL_INFO:
      return 40;
    case LOG_LEVEL_DEBUG:
      return 20;
    case LOG_LEVEL_UNDEFINED:
      return 0;
    default:
      // If an invalid / undefined log level is passed, return a value higher than all possible values
      // to ensure no messages will be accidentally output.
      return 1000;
  }
}

// We only want to output messages with an importance equal or greater than the env log level setting. This function
// returns true if the message log level equals or exceeds the app's log level setting.
function shouldOutputMessage(messageLogLevel = LOG_LEVEL_UNDEFINED) {
  if (typeof window === 'undefined' && process.env.LOG_SERVER_SIDE !== '1') {
    return false;
  }

  const appLogLevel = process.env.NEXT_PUBLIC_LOG_LEVEL;
  const appLogLevelValue = getLogLevelValue(appLogLevel);
  const messageLogLevelValue = getLogLevelValue(messageLogLevel);
  return messageLogLevelValue >= appLogLevelValue;
}

// Logging functions

function debug(...message) {
  if (shouldOutputMessage(LOG_LEVEL_DEBUG)) {
    console.debug(...message);
  }
}

function error(...message) {
  if (shouldOutputMessage(LOG_LEVEL_ERROR)) {
    console.error(...message);
  }
}

function info(...message) {
  if (shouldOutputMessage(LOG_LEVEL_INFO)) {
    console.info(...message);
  }
}

function warn(...message) {
  if (shouldOutputMessage(LOG_LEVEL_WARNING)) {
    console.warn(...message);
  }
}

export { debug, error, info, warn };
