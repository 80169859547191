import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import InlineIcon from 'OK/components/inlineIcon';
import Notice from 'OK/components/notice';
import Tag from 'OK/components/tag';
import { acceptOrganisationInvitationMutation, rejectOrganisationInvitationMutation } from 'OK/networking/users';
import { resetLoginModal, showInvitations } from 'OK/state/account/actions';
import { showAuthModal } from 'OK/state/app/actions';
import { SESSION_VAL_HAS_VIEWED_PENDING_INVITATIONS } from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';
import { setBrowserSessionValue } from 'OK/util/storage';

const INVITATION_TYPE = {
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT',
};

function PendingInvitationsModule(props) {
  /* Variables */

  const { onComplete } = props;
  const [authenticated, , user] = useAuthentication();
  const { t, tHTML } = useI18n();
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();

  // State

  const [listOfOrganisationInvitationsLoading, setListOfOrganisationInvitationsLoading] = useState([]);

  /* API */

  const [acceptInvitationAPI] = useMutation(acceptOrganisationInvitationMutation);
  const [rejectInvitationAPI] = useMutation(rejectOrganisationInvitationMutation);

  /* Methods */

  function acceptInvitationFromOrganisation(organisationId) {
    indicateLoadingForOrganisationId(organisationId, INVITATION_TYPE.ACCEPT);
    acceptInvitationAPI({
      variables: {
        organisationId,
      },
    }).finally(() => {
      stopLoadingForOrganisationId(organisationId, INVITATION_TYPE.ACCEPT);
    });

    if (user.organisationInviteList?.length == 0) {
      dispatch(showAuthModal(false));
      dispatch(resetLoginModal());
    }
  }

  function declineInvitationFromOrganisation(organisationId) {
    indicateLoadingForOrganisationId(organisationId, INVITATION_TYPE.REJECT);
    rejectInvitationAPI({
      variables: {
        organisationId,
      },
    }).finally(() => {
      stopLoadingForOrganisationId(organisationId, INVITATION_TYPE.REJECT);
    });
  }

  function indicateLoadingForOrganisationId(organisationId, type) {
    setListOfOrganisationInvitationsLoading([...listOfOrganisationInvitationsLoading, { organisationId, type }]);
  }

  function proceed() {
    dispatch(showInvitations(false));
    onComplete();
  }

  function stopLoadingForOrganisationId(organisationId, type) {
    setListOfOrganisationInvitationsLoading((currentList) => {
      const indexOfInvitation = currentList.findIndex((i) => i.organisationId === organisationId && i.type === type);
      if (indexOfInvitation > -1) {
        const updatedLoadingList = [...currentList];
        updatedLoadingList.splice(indexOfInvitation, 1);
        return updatedLoadingList;
      }

      return currentList;
    });
  }

  /* Effects */

  // Locally track that the user has viewed invitations so we don't keep showing this section during this session
  useEffect(() => {
    setBrowserSessionValue(SESSION_VAL_HAS_VIEWED_PENDING_INVITATIONS, '1');
  }, []);

  // If a user has no pending invitations or accepted/declined all invitations, then proceed to the next section
  useEffect(() => {
    if (user?.organisationInviteList.length == 0) {
      onComplete();
    }
  }, [onComplete, user?.organisationInviteList?.length]);

  /* Render */

  if (!authenticated || !user) {
    return null;
  }

  const invitations = user.organisationInviteList?.length ? (
    user.organisationInviteList.map((organisation) => {
      const invitationLoading = listOfOrganisationInvitationsLoading.find((i) => i.organisationId === organisation.id);
      const address = organisation.addressList[0];
      return (
        <div className={styles.invite} key={organisation.id}>
          <img
            alt={t('IMG_ALT_ORGANISATION_LOGO')}
            className={styles.companyLogo}
            src={organisation.logoImageMediaAsset?.logoImageURL ?? `/img/empty_media_${theme.name}.svg`}
          />
          <p className={styles.companyName}>
            <strong>{organisation.name}</strong>
            <Tag className={styles.companyCountry}>{address.countryCode}</Tag>
          </p>
          <Button
            className={styles.respondButton}
            disabled={invitationLoading?.type === INVITATION_TYPE.ACCEPT}
            linkStyle
            loading={invitationLoading?.type === INVITATION_TYPE.ACCEPT}
            onClick={() => acceptInvitationFromOrganisation(organisation.id)}
            tint='creation'
          >
            {t('ACCEPT')}
          </Button>
          <Button
            className={styles.respondButton}
            disabled={invitationLoading?.type === INVITATION_TYPE.REJECT}
            linkStyle
            loading={invitationLoading?.type === INVITATION_TYPE.REJECT}
            onClick={() => declineInvitationFromOrganisation(organisation.id)}
            tint='alert'
          >
            {t('DECLINE')}
          </Button>
        </div>
      );
    })
  ) : (
    <p>{t('ACCOUNT_PENDIND_INVITATION_TEXT')}</p>
  );

  return (
    <>
      <h3>{t('ACCOUNT_PENDIND_INVITATION_CARD')}</h3>
      <p>{t('ACCOUNT_PENDIND_INVITATION_STYLECARD_TEXT_1')}</p>
      <Notice extendSides>
        {tHTML('ACCOUNT_PENDIND_INVITATION_STYLECARD_EXTENDSIDES', {
          data: {
            icon: <InlineIcon src={`/icons/profile_${theme.name}.svg`} />,
          },
        })}
      </Notice>
      {invitations}
      <Button block onClick={proceed} withCaret>
        {t('ACCOUNT_PENDIND_INVITATION_STYLECARD_PROCESSBUTTON')}
      </Button>
    </>
  );
}

export default PendingInvitationsModule;

PendingInvitationsModule.propTypes = {
  onComplete: PropTypes.func,
};
