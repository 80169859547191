export function decodeQueryParametersToObject(url) {
  if (!url) {
    return {};
  }
  const urlComponents = url.split('&');
  const decodedObject = {};
  urlComponents.forEach((component) => {
    const [key, value] = component.split('=');
    decodedObject[key] = decodeURIComponent(value);
  });
  return decodedObject;
}

export function encodeQueryParametersFromObject(obj) {
  let queryParametersString = '';
  Object.keys(obj).forEach((objKey) => {
    if (queryParametersString !== '') {
      queryParametersString += '&';
    }
    const parameterValue = encodeURIComponent(obj[objKey]);
    queryParametersString += `${objKey}=${parameterValue}`;
  });
  return queryParametersString;
}

export function isOKPlatformURL(url) {
  return /^(?:https?:\/\/)?(?:[a-zA-Z]+\.)?(?:okgrade\.com|oktrade\.org)/.test(url);
}
