import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
  ArchiveCardTitle,
} from 'OK/components/archiveCard';
import ArchiveCardManagerOptions from 'OK/components/archiveCard/managerOptions';
import Icon, { ICONS } from 'OK/components/icon';
import Link from 'OK/components/link';
import Stepper from 'OK/components/stepper';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import ItemModel from 'OK/models/item';
import MediaAssetModel from 'OK/models/mediaAsset';
import ProductModel from 'OK/models/product';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import useI18n from 'OK/util/hooks/useI18n';

export default function ItemArchiveCard(props) {
  /* Variables */

  const {
    cardClassName,
    className,
    item,
    linkToArchive = true,
    onChangeQuantity,
    onClickUnlink,
    quantity,
    showUnlinkButton = false,
    ...otherProps
  } = props;
  const { product, reliabilityPointForPublishedLogs } = item;
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const isOwnedByActiveOrganisation = product ? product.organisation.id === activeOrganisationId : false;
  const { t, locale } = useI18n();
  const dispatch = useDispatch();

  /* Render */

  // Media
  const imageUrl = useMemo(() => {
    return product?.productMediaAssetList?.find((pm) => pm.mediaAsset.mediaType === MediaAssetModel.MEDIA_TYPE.IMAGE)
      ?.mediaAsset?.imageData?.imageURL;
  }, [product?.productMediaAssetList]);

  // Header tag
  let headerTag;
  if (isOwnedByActiveOrganisation) {
    headerTag = (
      <Tag block={false} invert>
        <Icon className={styles.myOrganisationTagIcon} height={12} inline name={ICONS.ORGANISATION.name} width={12} />
      </Tag>
    );
  } else {
    headerTag = <Tag block={false}>{product?.organisation.addressList[0].countryCode}</Tag>;
  }

  // Product name
  const productName = product ? ProductModel.localizedNameForProduct(product, locale) : null;

  // Options
  const hasQuantity = typeof quantity === 'number';
  const showOptions = showUnlinkButton || hasQuantity;

  const Container = linkToArchive ? Link : 'div';
  const containerProps = { className };
  if (linkToArchive) {
    containerProps.href = ItemModel.link(item);
    containerProps.onClick = (e) => {
      e.preventDefault();
      history.pushState({ isPopup: true, refId: null, dataType: 'ITEM', OKID: item.OKID }, null, ItemModel.link(item));
      dispatch(setBrowserHistoryAction(history));
    };
  }

  return (
    <Container {...containerProps}>
      <ArchiveCard className={cardClassName} {...otherProps}>
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery imageUrl={imageUrl} placeholderIconName={ICONS.TAG.name} />
          <ArchiveCardMainContent>
            <ArchiveCardHeader tag={headerTag} type={t('ITEM_ARCHIVE_CARD_HEADER')} />
            <ArchiveCardTitle>{productName}</ArchiveCardTitle>
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow
            conformityGrade={product ? product.conformityPoint : null}
            reliabilityGrade={reliabilityPointForPublishedLogs}
          />
          <ArchiveCardFooterRow okid={item.OKID} />
        </ArchiveCardFooter>
        {showOptions && (
          <ArchiveCardManagerOptions onClickUnlink={onClickUnlink} showUnlinkOption={showUnlinkButton}>
            {hasQuantity && (
              <div>
                <Text bold className={styles.quantityLabel}>
                  {t('ITEM_ARCHIVE_CARD_QUANTITY_LABEL')}
                </Text>
                <Stepper className={styles.quantityInput} minValue={1} onChange={onChangeQuantity} value={quantity} />
              </div>
            )}
          </ArchiveCardManagerOptions>
        )}
      </ArchiveCard>
    </Container>
  );
}

ItemArchiveCard.propTypes = {
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  linkToArchive: PropTypes.bool,
  onChangeQuantity: PropTypes.func,
  onClickUnlink: PropTypes.func,
  quantity: PropTypes.number,
  showUnlinkButton: PropTypes.bool,
};
