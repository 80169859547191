import { gql } from '@apollo/client';
import { nanoid } from 'nanoid';

import ContactModel from '../contact';
import BaseDocumentModel from '../document/base';
import InspectionAssetModel from '../inspectionAsset';
import BaseMediaAssetModel, {
  AudioMediaAssetModel,
  ImageMediaAssetModel,
  VideoMediaAssetModel,
} from '../mediaAsset/base';
import NoteModel from '../note';
import OrganisationModel from '../organisation';
import BaseOrganisationModel from '../organisation/base';
import SiteChildSiteModel from '../siteChildSite';

import BaseSiteModel from './base';

import MediaAssetModel from 'OK/models/mediaAsset';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import STATUS from 'OK/util/enums/status';
import { formatOkid } from 'OK/util/formatting';
import localizeObject from 'OK/util/functions/localizeObject';

export default class SiteModel {
  static GRAPHQL_TYPE = 'Site';

  static fragmentName = 'Site_Fragment';
  static fragment = gql`
    fragment ${SiteModel.fragmentName} on ${SiteModel.GRAPHQL_TYPE} {
      ...${BaseSiteModel.fragmentName}
      # organisation {
      #   ...BaseOrganisationModel.fragmentName
      # }
    }
    ${BaseSiteModel.fragment}
    # BaseOrganisationModel.fragment
  `;

  static fragmentNameFull = 'Site_Full_Fragment';
  static fragmentFull = gql`
    fragment ${SiteModel.fragmentNameFull} on ${SiteModel.GRAPHQL_TYPE} {
      ...${BaseSiteModel.fragmentName}
        assetAccessPermission 
          assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      organisationId
      organisation {
        ...${BaseOrganisationModel.fragmentName}
      }
      siteChildSiteList {
        ...${SiteChildSiteModel.fragmentName}
      }
      siteParentSiteList {
        ...${SiteModel.fragmentName}
        siteMediaAssetList {
          mediaAsset {
            ...${BaseMediaAssetModel.fragmentName}
          }
          order
          publishStatus
        }
      }
      siteDocumentAssetList {
        documentAsset {
          ...${BaseDocumentModel.fragmentName}
        }
        publishStatus
      }
      siteInspectionAssetList {
        inspectionAsset {
          ...${InspectionAssetModel.fragmentName}
        }
        order
        publishStatus
      }
      siteMediaAssetList {
        mediaAsset {
          ...${BaseMediaAssetModel.fragmentName}
        }
        order
        publishStatus
      }
      siteNoteAssetList {
        noteAsset {
          ...${NoteModel.fragmentName}
        }
        publishStatus
      }
    }
    ${BaseSiteModel.fragment}
    ${BaseOrganisationModel.fragment}
    ${SiteChildSiteModel.fragment}
    ${SiteModel.fragment}
    ${BaseDocumentModel.fragment}
    ${InspectionAssetModel.fragment}
    ${BaseMediaAssetModel.fragment}
    ${NoteModel.fragment}
  `;

  static fragmentNameFullPublic = 'Site_FullPublic_Fragment';
  static fragmentFullPublic = gql`
    fragment ${SiteModel.fragmentNameFullPublic} on ${SiteModel.GRAPHQL_TYPE} {
      ...${BaseSiteModel.fragmentName}
      # organisation {
      #   ...BaseOrganisationModel.fragmentName
      # }
      siteChildSiteList {
        ...${SiteChildSiteModel.fragmentName}
      }
      siteParentSiteList {
        ...${SiteModel.fragmentName}
        siteMediaAssetList {
          mediaAsset {
            ...${BaseMediaAssetModel.fragmentName}
          }
          order
          publishStatus
        }
      }
      siteDocumentAssetList {
        documentAsset {
          ...${BaseDocumentModel.fragmentName}
        }
        publishStatus
      }
      # siteInspectionAssetList {
      #   inspectionAsset {
      #     ...InspectionAssetModel.fragmentName
      #   }
      #   order
      #   publishStatus
      # }
      siteMediaAssetList {
        mediaAsset {
          ...${BaseMediaAssetModel.fragmentName}
        }
        order
        publishStatus
      }
      siteNoteAssetList {
        noteAsset {
          ...${NoteModel.fragmentName}
        }
        publishStatus
      }
    }
    ${BaseSiteModel.fragment}
    # BaseOrganisationModel.fragment
    ${SiteChildSiteModel.fragment}
    ${BaseDocumentModel.fragment}
    ${InspectionAssetModel.fragment}
    ${BaseMediaAssetModel.fragment}
    ${NoteModel.fragment}
  `;

  static fragmentNameArchiveCard = 'Site_ArchiveCard_Fragment';
  static fragmentArchiveCard = gql`
    fragment ${SiteModel.fragmentNameArchiveCard} on ${SiteModel.GRAPHQL_TYPE} {
      conformityPoint
      id
      identifier
      name
      OKID
      reliabilityPointForPublishedLogs,
      organisation {
        id
        addressList {
          countryCode
        }
        conformityPoint
        name
        reliabilityPointForPublishedLogs
      }
      siteChildSiteList {
        childSite {
          id
        }
      }
      siteMediaAssetList {
        mediaAsset {
          ...${MediaAssetModel.fragmentNameMediaGallery}
        }
      }
    }
    ${MediaAssetModel.fragmentMediaGallery}
  `;

  static fragmentNameArchiveCardOrder = 'Site_ArchiveCardOrder_Fragment';
  static fragmentArchiveCardOrder = gql`
    fragment ${SiteModel.fragmentNameArchiveCardOrder} on ${SiteModel.GRAPHQL_TYPE} {
      conformityPoint
      id
      identifier
      name
      OKID
      reliabilityPointForPublishedLogs,
      organisation {
        id
        addressList {
          countryCode
        }
        conformityPoint
        name
        reliabilityPointForPublishedLogs
      }
          siteMediaAssetList {
            mediaAsset {
              id
              mediaType
              REFID
              imageData {
                imageURL
                imageName
                baseImageURL
              }
            }
            order
            publishStatus
          }
        }
    
  `;

  static fragmentNamePartsList = 'Site_PartsList_Fragment';
  static fragmentPartsList = gql`
  fragment ${SiteModel.fragmentNamePartsList} on ${SiteModel.GRAPHQL_TYPE} {
    id
    siteChildSiteList {
      childSite {
        ...${SiteModel.fragmentNameArchiveCard}
      }
      publishStatus
      quantity
      status
    }
  }
  ${SiteModel.fragmentArchiveCard}
`;

  static fragmentNameArchiveCardWithDocumentPublishStatus = 'Site_ArchiveCardWithDocumentPublishStatus_Fragment';
  static fragmentArchiveCardWithDocumentPublishStatus = gql`
    fragment ${SiteModel.fragmentNameArchiveCardWithDocumentPublishStatus} on ${SiteModel.GRAPHQL_TYPE} {
      ...${SiteModel.fragmentNameArchiveCard}
      siteDocumentAssetList {
        documentAsset {
          id
        }
        publishStatus
      }
    }
    ${SiteModel.fragmentArchiveCard}
  `;

  static fragmentNameContacts = 'Site_Contacts_Fragment';
  static frgmentContacts = gql`
  fragment ${SiteModel.fragmentNameContacts} on ${SiteModel.GRAPHQL_TYPE} {
    contactList {
      id
      organisationId
      siteId
      type
      userId
      user {
        OKID
        name
        email
      }
      name
      role
      email
      phone
    }
  }`;

  static fragmentEditName = 'Site_Edit_Fragment';
  static fragmentEdit = gql`
  fragment ${SiteModel.fragmentEditName} on ${SiteModel.GRAPHQL_TYPE} {
      id
      assetAccessPermission
      conformityPoint
      identifier
      contactList {
        ...${ContactModel.fragmentNameContacts}
      }
      name
      OKID
      organisationId
      publishStatus
      reliabilityPointForPublishedLogs
      assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      siteChildSiteList {
        childSite {
          ...${SiteModel.fragmentNameArchiveCard}
        }
        publishStatus
        quantity
        status
      }
      siteParentSiteList {
        ...${SiteModel.fragmentNameArchiveCard}
      }

      siteDocumentAssetList {
        documentAsset {
          id
          applicableMarketList
          coveredLanguageList
          documentName
          documentNumber
          documentStandardList
          documentType
          madeByOrganisation {
            ...${OrganisationModel.fragmentNameArchiveCard}
          }
          madeByOrganisationId
          REFID
          validFrom
          validTill
        }
        publishStatus
      }
      siteInspectionAssetList {
        inspectionAsset {
          ...${InspectionAssetModel.fragmentNameEditCard}
        }
        order
        publishStatus
      }
      siteMediaAssetList {
        mediaAsset {
          ...${MediaAssetModel.fragmentNameMediaGallery}
        }
        order
        publishStatus
      }
      siteNoteAssetList {
        noteAsset {
          id
          REFID
          textContentMap
        }
        publishStatus
      }
  }
  ${MediaAssetModel.fragmentMediaGallery}
  ${SiteModel.fragmentArchiveCard}
  ${ContactModel.fragmentContacts}
  ${OrganisationModel.fragmentArchiveCard}
  ${InspectionAssetModel.fragmentEditCard}
  `;

  static fragmentNameScanResult = 'Item_ScanResult_Fragment';
  static fragmentScanResult = gql`
    fragment ${SiteModel.fragmentNameScanResult} on ${SiteModel.GRAPHQL_TYPE} {
      id
      conformityPoint
      identifier
      name
      OKID
      organisationId
      reliabilityPointForPublishedLogs
      organisation {
        addressList {
          countryCode
        }
        conformityPoint
        name
        OKID
        reliabilityPointForPublishedLogs
      }
      siteDocumentAssetList {
        documentAsset {
          coveredLanguageList
          documentName
          documentType
          documentURL
          id
          REFID
        }
      }
      siteMediaAssetList {
        mediaAsset {
          mediaType
          imageData {
            ...${ImageMediaAssetModel.fragmentName}
          }
        }
      }
      siteNoteAssetList {
        noteAsset {
          id
          textContentMap
        }
      }
      availableSiteInspectionList {
        inspectionAsset {
          criticalAQL
          id
          majorAQL
          minorAQL
          organisationId
          publishedLogs
          REFID
          name
          reliabilityPointForPublishedLogs
          inspectionTestAssetList {
            testAsset {
              testAssetMediaAssetList {
                mediaAsset {
                  mediaType
                  audioData {
                    ...${AudioMediaAssetModel.fragmentName}
                  }
                  imageData {
                    ...${ImageMediaAssetModel.fragmentName}
                  }
                  videoData {
                    ...${VideoMediaAssetModel.fragmentName}
                  }
                }
              }
            }
          }
        }
      }
    }
    ${AudioMediaAssetModel.fragment}
    ${ImageMediaAssetModel.fragment}
    ${VideoMediaAssetModel.fragment}
  `;

  static mock = {
    REFID: nanoid(6),
    SKUID: nanoid(8),
    createdBy: null,
    createdDate: `${new Date()}`,
    id: nanoid(),
    lastModifiedBy: null,
    lastModifiedDate: `${new Date()}`,
    name: {},
    organisation: {},
    siteDocumentAssetList: [],
    siteMediaAssetList: [],
    siteNoteAssetList: [],
    publishStatus: PUBLISH_STATUS.UNPUBLISHED,
    status: STATUS.ACTIVE,
    version: 0,
    __typename: SiteModel.GRAPHQL_TYPE,
  };

  static link = (siteOrOKID, edit = false) => {
    let OKID;
    if (typeof siteOrOKID === 'object') {
      OKID = siteOrOKID.OKID;
    } else {
      OKID = siteOrOKID;
    }

    if (typeof OKID !== 'string') {
      throw new Error('Cannot link to site without an OKID.');
    }

    let baseURL = `/archive/site/${formatOkid(OKID)}`;

    if (edit) {
      return `${baseURL}/edit`;
    }

    return baseURL;
  };

  static localizedNameForSite = (site, locale) => {
    return localizeObject(site.name, locale, true)?.text ?? 'Unnamed';
  };

  static previewImageUrl = (site) => {
    if (!site.siteMediaAssetList?.length) {
      return;
    }

    const imageMedia = site.siteMediaAssetList.find((m) => typeof m.mediaAsset.imageData?.imageURL === 'string');
    return imageMedia?.mediaAsset.imageData.imageURL;
  };
}
