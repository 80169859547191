import { useApolloClient, useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Checkbox from 'OK/components/checkbox';
import FormFieldI18n from 'OK/components/form/formFieldI18n';
import Icon, { ICONS } from 'OK/components/icon';
import InlineIcon from 'OK/components/inlineIcon';
import Input from 'OK/components/input';
import SearchInput from 'OK/components/input/search';
import TextLayout from 'OK/components/layouts/content/text';
import MediaGallery from 'OK/components/mediaGallery';
import Notice from 'OK/components/notice';
import Progressable from 'OK/components/progressable';
import Radio from 'OK/components/radio';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Separator from 'OK/components/separator';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import AppConfig from 'OK/config/app';
import InspectionLogTestAssetModel from 'OK/models/inspectionLogTestAsset';
import InspectionLogTestAssetMediaAssetModel from 'OK/models/inspectionLogTestAssetMediaAsset';
import MediaAssetModel, {
  AudioMediaAssetModel,
  ImageMediaAssetModel,
  VideoMediaAssetModel,
} from 'OK/models/mediaAsset';
import BaseMediaAssetModel from 'OK/models/mediaAsset/base';
import TestAssetModel from 'OK/models/testAsset';
import TestAssetMediaAssetModel from 'OK/models/testMediaAsset';
import UnversionedMediaAssetModel from 'OK/models/unversionedMediaAsset';
import VersionedMediaAssetModel from 'OK/models/versionedMediaAsset';
import { updateInspectionLogTestAssetImageMediaAssetRequest } from 'OK/networking/inspectionLogTestAssetMediaAssets';
import {
  createInspectionLogTestAssetAudioMediaAssetRequest,
  createInspectionLogTestAssetImageMediaAssetRequest,
  createInspectionLogTestAssetVideoMediaAssetRequest,
} from 'OK/networking/inspectionLogTestAssets';
import {
  useRemoveConditionFromInspectionLogTestAPI,
  useRemoveHowToFindItFromInspectionLogTestAPI,
  useRemoveHowToFixItFromInspectionLogTestAPI,
  useRemoveWhatToLookForFromInspectionLogTestAPI,
  useReorderInspectionLogTestAssetMediaAssetBackwardAPI,
  useReorderInspectionLogTestAssetMediaAssetForwardAPI,
  useSetConditionForInspectionLogTestAPI,
  useSetHowToFindItOnInspectionLogTestAPI,
  useSetHowToFixItOnInspectionLogTestAPI,
  useSetIdentifiersEnabledForInspectionLogTestAPI,
  useSetNotesEnabledForInspectionLogTestAPI,
  useSetSeverityLevelForInspectionLogTestAPI,
  useSetTimeTrackingEnabledForInspectionLogTestAPI,
  useSetWhatToLookForOnInspectionLogTestAPI,
  useUnlinkInspectionLogTestAssetMediaAssetAPI,
} from 'OK/networking/inspectionLogTestAssets/hooks';
import { updateImageMediaAssetRequest } from 'OK/networking/media';
import { searchQuery } from 'OK/networking/search';
import {
  createTestAssetAudioMediaAssetRequest,
  createTestAssetImageMediaAssetRequest,
  createTestAssetVideoMediaAssetRequest,
  getTestAssetByRefIdQuery,
} from 'OK/networking/testAssets';
import {
  useLinkMediaAssetAPI,
  useRemoveConditionForTestAPI,
  useRemoveHowToFindItFromTestAPI,
  useRemoveHowToFixItFromTestAPI,
  useRemoveWhatToLookForFromTestAPI,
  useReorderMediaAssetBackwardAPI,
  useReorderMediaAssetForwardAPI,
  useSetConditionForTestAPI,
  useSetHowToFindItOnTestAPI,
  useSetHowToFixItOnTestAPI,
  useSetIdentifiersEnabledForTestAPI,
  useSetNotesEnabledForTestAPI,
  useSetSeverityLevelForTestAPI,
  useSetTimeTrackingEnabledForTestAPI,
  useSetWhatToLookForOnTestAPI,
  useUnlinkTestAssetMediaAssetAPI,
} from 'OK/networking/testAssets/hooks';
import { trackError } from 'OK/util/analytics';
import { DEBOUNCE_TIMING_MS_LONG } from 'OK/util/constants';
import getPrecisionOfNumber from 'OK/util/functions/getPrecisionOfNumber';
import simpleMIMEType from 'OK/util/functions/simpleMIMEType';
import useI18n from 'OK/util/hooks/useI18n';

/* Helpers */

export const EDIT_TEST_PAGE_MODE = {
  DEFAULT_MODE: 'DEFAULT_MODE',
  VERSIONED_MODE: 'VERSIONED_MODE',
  UNVERSIONED_MODE: 'UNVERSIONED_MODE',
};

export default function EditTest(props) {
  /* Variables */

  const {
    desktopColumnClassName,
    inspectionLogTestAssetId,
    mode = EDIT_TEST_PAGE_MODE.DEFAULT_MODE,
    testRefId,
  } = props;
  const apolloClient = useApolloClient();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const mediaGalleryRef = useRef();
  const { t } = useI18n();
  const versionedMode = mode !== EDIT_TEST_PAGE_MODE.UNVERSIONED_MODE;

  // State

  const [howToFindItErrors, setHowToFindItErrors] = useState({});
  const [howToFixItErrors, setHowToFixItErrors] = useState({});
  const [identifiersEnabledError, setIdentifiersEnabledError] = useState(null);
  // const [limitLower, setLimitLower] = useState('');
  const [limitLowerError, setLimitLowerError] = useState(null);
  // const [limitUpper, setLimitUpper] = useState('');
  const [limitUpperError, setLimitUpperError] = useState(null);
  const [mediaEditingError, setMediaEditingError] = useState(null);
  const [mediaError, setMediaError] = useState(null);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [notesEnabledError, setNotesEnabledError] = useState(null);
  const [severityError, setSeverityError] = useState(null);
  const [timeTrackingEnabledError, setTimeTrackingEnabledError] = useState(null);
  const [whatToLookForErrors, setWhatToLookForErrors] = useState({});

  const [searchMediaFocused, setSearchMediaFocused] = useState(false);
  const [searchMediaString, setSearchMediaString] = useState('');

  /* API */

  const [getVersionedTestAssetAPI, getVersionedTestAssetResult] = useLazyQuery(getTestAssetByRefIdQuery);
  const inspectionLogTestAsset =
    !versionedMode &&
    apolloClient.cache.readFragment({
      id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${inspectionLogTestAssetId}`,
      fragment: InspectionLogTestAssetModel.fragment,
      fragmentName: InspectionLogTestAssetModel.fragmentName,
      optimistic: true,
      returnPartialData: true,
    });
  const testAsset = versionedMode
    ? getVersionedTestAssetResult.data?.testAsset
    : inspectionLogTestAsset?.unversionedTestAsset;
  const testAssetId = versionedMode ? testAsset?.id : inspectionLogTestAsset?.id;
  const upperLimitCondition =
    testAsset?.testAssetConditionList?.find((c) => c.type === TestAssetModel.CONDITION_TYPES.MAX_VALUE)?.value ?? '';
  const lowerLimitCondition =
    testAsset?.testAssetConditionList?.find((c) => c.type === TestAssetModel.CONDITION_TYPES.MIN_VALUE)?.value ?? '';

  // Versioned APIs
  const linkMediaAssetAPI = useLinkMediaAssetAPI();
  const removeConditionVersionedAPI = useRemoveConditionForTestAPI();
  const removeHowToFindItVersionedAPI = useRemoveHowToFindItFromTestAPI();
  const removeHowToFixItVersionedAPI = useRemoveHowToFixItFromTestAPI();
  const removeWhatToLookForVersionedAPI = useRemoveWhatToLookForFromTestAPI();
  const reorderMediaBackwardVersionedAPI = useReorderMediaAssetBackwardAPI();
  const reorderMediaForwardVersionedAPI = useReorderMediaAssetForwardAPI();
  const setConditionVersionedAPI = useSetConditionForTestAPI();
  const setHowToFindItVersionedAPI = useSetHowToFindItOnTestAPI();
  const setHowToFixItVersionedAPI = useSetHowToFixItOnTestAPI();
  const setIdentifiersEnabledVersionedAPI = useSetIdentifiersEnabledForTestAPI();
  const setNotesEnabledVersionedAPI = useSetNotesEnabledForTestAPI();
  const setSeverityLevelVersionedAPI = useSetSeverityLevelForTestAPI();
  const setTimeTrackingEnabledVersionedAPI = useSetTimeTrackingEnabledForTestAPI();
  const setWhatToLookForVersionedAPI = useSetWhatToLookForOnTestAPI();
  const unlinkMediaAssetVersionedAPI = useUnlinkTestAssetMediaAssetAPI();

  // Unversioned APIs
  const removeConditionUnversionedAPI = useRemoveConditionFromInspectionLogTestAPI();
  const removeHowToFindItUnversionedAPI = useRemoveHowToFindItFromInspectionLogTestAPI();
  const removeHowToFixItUnversionedAPI = useRemoveHowToFixItFromInspectionLogTestAPI();
  const removeWhatToLookForUnversionedAPI = useRemoveWhatToLookForFromInspectionLogTestAPI();
  const reorderMediaBackwardUnversionedAPI = useReorderInspectionLogTestAssetMediaAssetBackwardAPI();
  const reorderMediaForwardUnversionedAPI = useReorderInspectionLogTestAssetMediaAssetForwardAPI();
  const setConditionUnversionedAPI = useSetConditionForInspectionLogTestAPI();
  const setHowToFindItUnversionedAPI = useSetHowToFindItOnInspectionLogTestAPI();
  const setHowToFixItUnversionedAPI = useSetHowToFixItOnInspectionLogTestAPI();
  const setIdentifiersEnabledUnversionedAPI = useSetIdentifiersEnabledForInspectionLogTestAPI();
  const setNotesEnabledUnversionedAPI = useSetNotesEnabledForInspectionLogTestAPI();
  const setSeverityLevelUnversionedAPI = useSetSeverityLevelForInspectionLogTestAPI();
  const setTimeTrackingEnabledUnversionedAPI = useSetTimeTrackingEnabledForInspectionLogTestAPI();
  const setWhatToLookForUnversionedAPI = useSetWhatToLookForOnInspectionLogTestAPI();
  const unlinkMediaAssetUnversionedAPI = useUnlinkInspectionLogTestAssetMediaAssetAPI();

  const removeConditionAPI = versionedMode ? removeConditionVersionedAPI : removeConditionUnversionedAPI;
  const removeHowToFindItAPI = versionedMode ? removeHowToFindItVersionedAPI : removeHowToFindItUnversionedAPI;
  const removeHowToFixItAPI = versionedMode ? removeHowToFixItVersionedAPI : removeHowToFixItUnversionedAPI;
  const removeWhatToLookForAPI = versionedMode ? removeWhatToLookForVersionedAPI : removeWhatToLookForUnversionedAPI;
  const reorderMediaBackwardAPI = versionedMode ? reorderMediaBackwardVersionedAPI : reorderMediaBackwardUnversionedAPI;
  const reorderMediaForwardAPI = versionedMode ? reorderMediaForwardVersionedAPI : reorderMediaForwardUnversionedAPI;
  const setConditionAPI = versionedMode ? setConditionVersionedAPI : setConditionUnversionedAPI;
  const setHowToFindItAPI = versionedMode ? setHowToFindItVersionedAPI : setHowToFindItUnversionedAPI;
  const setHowToFixItAPI = versionedMode ? setHowToFixItVersionedAPI : setHowToFixItUnversionedAPI;
  const setIdentifiersEnabledAPI = versionedMode
    ? setIdentifiersEnabledVersionedAPI
    : setIdentifiersEnabledUnversionedAPI;
  const setNotesEnabledAPI = versionedMode ? setNotesEnabledVersionedAPI : setNotesEnabledUnversionedAPI;
  const setSeverityLevelAPI = versionedMode ? setSeverityLevelVersionedAPI : setSeverityLevelUnversionedAPI;
  const setTimeTrackingEnabledAPI = versionedMode
    ? setTimeTrackingEnabledVersionedAPI
    : setTimeTrackingEnabledUnversionedAPI;
  const setWhatToLookForAPI = versionedMode ? setWhatToLookForVersionedAPI : setWhatToLookForUnversionedAPI;
  const unlinkMediaAssetAPI = versionedMode ? unlinkMediaAssetVersionedAPI : unlinkMediaAssetUnversionedAPI;

  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);

  /* Methods */

  const openMediaPicker = () => {
    mediaGalleryRef.current.openMediaPicker();
  };

  const searchDebounced = useCallback(
    (searchString) => {
      searchAPI({
        variables: {
          ignoreIdListByDataType: [
            {
              dataType: 'MEDIA_ASSET',
              ignoreIdList: versionedMode ? testAsset?.testAssetMediaAssetList.map((tma) => tma.mediaAsset.id) : [],
            },
          ],
          searchPaginationDataByDataType: [
            {
              dataType: 'MEDIA_ASSET',
              searchPaginationData: { pageSize: 4, skip: 0 },
            },
          ],
          searchString,
        },
      });
    },
    [searchAPI, testAsset?.testAssetMediaAssetList, versionedMode]
  );

  const saveLowerLimitUpdate = useMemo(
    () =>
      debounce((limit) => {
        setLimitLowerError(null);

        let api;
        if (limit) {
          api = setConditionAPI(testAssetId, TestAssetModel.CONDITION_TYPES.MIN_VALUE, limit);
        } else {
          api = removeConditionAPI(testAssetId, TestAssetModel.CONDITION_TYPES.MIN_VALUE);
        }
        api.catch(() => {
          setLimitLowerError(t('ERROR_GENERIC'));
        });
      }, DEBOUNCE_TIMING_MS_LONG),
    [removeConditionAPI, setConditionAPI, t, testAssetId]
  );

  const saveUpperLimitUpdate = useMemo(
    () =>
      debounce((limit) => {
        setLimitUpperError(null);

        let api;
        if (limit) {
          api = setConditionAPI(testAssetId, TestAssetModel.CONDITION_TYPES.MAX_VALUE, limit);
        } else {
          api = removeConditionAPI(testAssetId, TestAssetModel.CONDITION_TYPES.MAX_VALUE);
        }
        api.catch(() => {
          setLimitUpperError(t('ERROR_GENERIC'));
        });
      }, DEBOUNCE_TIMING_MS_LONG),
    [removeConditionAPI, setConditionAPI, t, testAssetId]
  );

  const toggleIdentifiersEnabled = useCallback(
    (e) => {
      setIdentifiersEnabledError(null);
      setIdentifiersEnabledAPI(testAssetId, e.target.checked).catch(() => {
        setIdentifiersEnabledError(t('ERROR_GENERIC'));
      });
    },
    [setIdentifiersEnabledAPI, t, testAssetId]
  );

  const toggleNotesEnabled = useCallback(
    (e) => {
      setNotesEnabledError(null);
      setNotesEnabledAPI(testAssetId, e.target.checked).catch(() => {
        setNotesEnabledError(t('ERROR_GENERIC'));
      });
    },
    [setNotesEnabledAPI, t, testAssetId]
  );

  const toggleTimeTrackingEnabled = useCallback(
    (e) => {
      setTimeTrackingEnabledError(null);
      setTimeTrackingEnabledAPI(testAssetId, e.target.checked).catch(() => {
        setTimeTrackingEnabledError(t('ERROR_GENERIC'));
      });
    },
    [setTimeTrackingEnabledAPI, t, testAssetId]
  );

  function updateCacheWithNewInspectionLogTestAssetMediaAsset(newInspectionLogTestMediaAsset) {
    // Write InspectionLogTestAssetMediaAsset to cache
    const inspectionLogTestAssetMediaAsset = {
      ...newInspectionLogTestMediaAsset,
      __typename: InspectionLogTestAssetMediaAssetModel.GRAPHQL_TYPE,
    };
    if (newInspectionLogTestMediaAsset.versionedMediaAsset) {
      inspectionLogTestAssetMediaAsset.versionedMediaAsset = {
        ...newInspectionLogTestMediaAsset.versionedMediaAsset,
        __typename: VersionedMediaAssetModel.GRAPHQL_TYPE,
      };
      if (newInspectionLogTestMediaAsset.versionedMediaAsset.audioData) {
        inspectionLogTestAssetMediaAsset.versionedMediaAsset.audioData = {
          ...newInspectionLogTestMediaAsset.versionedMediaAsset.audioData,
          __typename: AudioMediaAssetModel.GRAPHQL_TYPE,
        };
      } else if (newInspectionLogTestMediaAsset.versionedMediaAsset.imageData) {
        inspectionLogTestAssetMediaAsset.versionedMediaAsset.imageData = {
          ...newInspectionLogTestMediaAsset.versionedMediaAsset.imageData,
          __typename: ImageMediaAssetModel.GRAPHQL_TYPE,
        };
      } else if (newInspectionLogTestMediaAsset.versionedMediaAsset.videoData) {
        inspectionLogTestAssetMediaAsset.versionedMediaAsset.videoData = {
          ...newInspectionLogTestMediaAsset.versionedMediaAsset.videoData,
          __typename: VideoMediaAssetModel.GRAPHQL_TYPE,
        };
      }
    }
    if (newInspectionLogTestMediaAsset.unversionedMediaAsset) {
      inspectionLogTestAssetMediaAsset.unversionedMediaAsset = {
        ...newInspectionLogTestMediaAsset.unversionedMediaAsset,
        __typename: UnversionedMediaAssetModel.GRAPHQL_TYPE,
      };
      if (newInspectionLogTestMediaAsset.unversionedMediaAsset.audioData) {
        inspectionLogTestAssetMediaAsset.unversionedMediaAsset.audioData = {
          ...newInspectionLogTestMediaAsset.unversionedMediaAsset.audioData,
          __typename: AudioMediaAssetModel.GRAPHQL_TYPE,
        };
      } else if (newInspectionLogTestMediaAsset.unversionedMediaAsset.imageData) {
        inspectionLogTestAssetMediaAsset.unversionedMediaAsset.imageData = {
          ...newInspectionLogTestMediaAsset.unversionedMediaAsset.imageData,
          __typename: ImageMediaAssetModel.GRAPHQL_TYPE,
        };
      } else if (newInspectionLogTestMediaAsset.unversionedMediaAsset.videoData) {
        inspectionLogTestAssetMediaAsset.unversionedMediaAsset.videoData = {
          ...newInspectionLogTestMediaAsset.unversionedMediaAsset.videoData,
          __typename: VideoMediaAssetModel.GRAPHQL_TYPE,
        };
      }
    }
    const inspectionLogTestAssetMediaAssetRef = apolloClient.cache.writeFragment({
      id: apolloClient.cache.identify(inspectionLogTestAssetMediaAsset),
      fragment: InspectionLogTestAssetMediaAssetModel.fragment,
      fragmentName: InspectionLogTestAssetMediaAssetModel.fragmentName,
      data: inspectionLogTestAssetMediaAsset,
    });

    // Add InspectionLogTestAssetMediaAsset to InspectionLogTestAsset
    apolloClient.cache.modify({
      id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
      fields: {
        inspectionLogTestAssetMediaAssetList: (currentList) => {
          return [...currentList, inspectionLogTestAssetMediaAssetRef];
        },
      },
    });
  }

  function updateCacheWithNewTestAssetMediaAsset(newTestMediaAsset) {
    // Write MediaAsset to cache
    const mediaAsset = {
      ...newTestMediaAsset.mediaAsset,
      REFID: newTestMediaAsset.mediaAsset.refid,
      __typename: BaseMediaAssetModel.GRAPHQL_TYPE,
    };
    switch (mediaAsset.mediaType) {
      case MediaAssetModel.MEDIA_TYPE.IMAGE:
        mediaAsset.imageData.__typename = ImageMediaAssetModel.GRAPHQL_TYPE;
        break;
      case MediaAssetModel.MEDIA_TYPE.VIDEO:
        mediaAsset.videoData.__typename = VideoMediaAssetModel.GRAPHQL_TYPE;
        break;
      case MediaAssetModel.MEDIA_TYPE.AUDIO:
        mediaAsset.audioData.__typename = AudioMediaAssetModel.GRAPHQL_TYPE;
        break;
    }
    const mediaAssetRef = apolloClient.cache.writeFragment({
      id: apolloClient.cache.identify(mediaAsset),
      fragment: BaseMediaAssetModel.fragment,
      fragmentName: BaseMediaAssetModel.fragmentName,
      data: mediaAsset,
    });

    // Add TestAssetMediaAsset to TestAsset
    const testAssetMediaAsset = {
      ...newTestMediaAsset,
      mediaAsset: mediaAssetRef,
      __typename: TestAssetMediaAssetModel.GRAPHQL_TYPE,
    };
    apolloClient.cache.modify({
      id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
      fields: {
        testAssetMediaAssetList: (currentList) => {
          return [...currentList, testAssetMediaAsset];
        },
      },
    });
  }

  function updateCacheWithUpdatedInspectionLogTestAssetMediaAsset(updatedInspectionLogTestAssetMediaAsset) {
    // Update MediaAsset in cache
    const inspectionLogTestAssetMediaAsset = {
      ...updatedInspectionLogTestAssetMediaAsset,
      __typename: InspectionLogTestAssetMediaAssetModel.GRAPHQL_TYPE,
    };
    if (updatedInspectionLogTestAssetMediaAsset.versionedMediaAsset) {
      inspectionLogTestAssetMediaAsset.versionedMediaAsset = {
        ...updatedInspectionLogTestAssetMediaAsset.versionedMediaAsset,
        imageData: {
          ...updatedInspectionLogTestAssetMediaAsset.versionedMediaAsset.imageData,
          __typename: ImageMediaAssetModel.GRAPHQL_TYPE,
        },
        __typename: VersionedMediaAssetModel.GRAPHQL_TYPE,
      };
    }
    if (updatedInspectionLogTestAssetMediaAsset.unversionedMediaAsset) {
      inspectionLogTestAssetMediaAsset.unversionedMediaAsset = {
        ...updatedInspectionLogTestAssetMediaAsset.unversionedMediaAsset,
        imageData: {
          ...updatedInspectionLogTestAssetMediaAsset.unversionedMediaAsset.imageData,
          __typename: ImageMediaAssetModel.GRAPHQL_TYPE,
        },
        __typename: UnversionedMediaAssetModel.GRAPHQL_TYPE,
      };
    }
    apolloClient.cache.writeFragment({
      id: apolloClient.cache.identify(inspectionLogTestAssetMediaAsset),
      fragment: InspectionLogTestAssetMediaAssetModel.fragment,
      fragmentName: InspectionLogTestAssetMediaAssetModel.fragmentName,
      data: inspectionLogTestAssetMediaAsset,
    });
  }

  function updateCacheWithUpdatedMediaAsset(updatedMediaAsset) {
    // Update MediaAsset in cache
    const mediaAsset = {
      ...updatedMediaAsset,
      imageData: {
        ...updatedMediaAsset.imageData,
        __typename: ImageMediaAssetModel.GRAPHQL_TYPE,
      },
      REFID: updatedMediaAsset.refid,
      __typename: BaseMediaAssetModel.GRAPHQL_TYPE,
    };
    apolloClient.cache.writeFragment({
      id: apolloClient.cache.identify(mediaAsset),
      fragment: BaseMediaAssetModel.fragment,
      fragmentName: BaseMediaAssetModel.fragmentName,
      data: mediaAsset,
    });
  }

  /* Events */

  const onBlurHowToFindIt = useCallback(
    (blurredLanguageIso, currentValues) => {
      // Clear error
      if (howToFindItErrors[blurredLanguageIso]) {
        const updatedMap = {
          ...howToFindItErrors,
        };
        delete updatedMap[blurredLanguageIso];
        setHowToFindItErrors(updatedMap);
      }

      const text = currentValues.find((v) => v.languageIso === blurredLanguageIso).value;
      if (text !== testAsset.howToFindItMap[blurredLanguageIso]?.text) {
        // Update if change
        setHowToFindItAPI(testAssetId, blurredLanguageIso, text).catch(() => {
          setHowToFindItErrors({
            ...howToFindItErrors,
            [blurredLanguageIso]: t('ERROR_GENERIC'),
          });
        });
      }
    },
    [howToFindItErrors, testAsset?.howToFindItMap, setHowToFindItAPI, t, testAssetId]
  );

  const onBlurHowToFixIt = useCallback(
    (blurredLanguageIso, currentValues) => {
      // Clear error
      if (howToFixItErrors[blurredLanguageIso]) {
        const updatedMap = {
          ...howToFixItErrors,
        };
        delete updatedMap[blurredLanguageIso];
        setHowToFixItErrors(updatedMap);
      }

      const text = currentValues.find((v) => v.languageIso === blurredLanguageIso).value;
      if (text !== testAsset.howToFixItMap[blurredLanguageIso]?.text) {
        // Update if change
        setHowToFixItAPI(testAssetId, blurredLanguageIso, text).catch(() => {
          setHowToFixItErrors({
            ...howToFixItErrors,
            [blurredLanguageIso]: t('ERROR_GENERIC'),
          });
        });
      }
    },
    [howToFixItErrors, testAsset?.howToFixItMap, setHowToFixItAPI, t, testAssetId]
  );

  const onBlurWhatToLookFor = useCallback(
    (blurredLanguageIso, currentValues) => {
      // Clear error
      if (whatToLookForErrors[blurredLanguageIso]) {
        const updatedMap = {
          ...whatToLookForErrors,
        };
        delete updatedMap[blurredLanguageIso];
        setWhatToLookForErrors(updatedMap);
      }

      const text = currentValues.find((v) => v.languageIso === blurredLanguageIso).value;
      if (text !== testAsset.whatToLookForMap[blurredLanguageIso]?.text) {
        if (text !== '' || blurredLanguageIso !== 'EN') {
          // Update if change
          setWhatToLookForAPI(testAssetId, blurredLanguageIso, text).catch(() => {
            setWhatToLookForErrors({
              ...whatToLookForErrors,
              [blurredLanguageIso]: t('ERROR_GENERIC'),
            });
          });
        } else {
          setWhatToLookForErrors({
            ...whatToLookForErrors,
            [blurredLanguageIso]: t('EDIT_STEP_TEXT_CANNOT_BE_LEFT_BLANK'),
          });
        }
      }
    },
    [setWhatToLookForAPI, t, testAsset?.whatToLookForMap, testAssetId, whatToLookForErrors]
  );

  const onChangeHowToFindIt = useCallback(
    (changedLanguageIso, newValues) => {
      const text = newValues.find((v) => v.languageIso === changedLanguageIso)?.value;
      if (!text && testAsset.howToFindItMap[changedLanguageIso]) {
        removeHowToFindItAPI(testAssetId, changedLanguageIso).catch(() => {
          setHowToFindItErrors({
            ...howToFindItErrors,
            [changedLanguageIso]: t('ERROR_GENERIC'),
          });
        });
      }
    },
    [testAsset?.howToFindItMap, removeHowToFindItAPI, t, testAssetId, howToFindItErrors]
  );

  const onChangeHowToFixIt = useCallback(
    (changedLanguageIso, newValues) => {
      const text = newValues.find((v) => v.languageIso === changedLanguageIso)?.value;
      if (!text && testAsset.howToFixItMap[changedLanguageIso]) {
        removeHowToFixItAPI(testAssetId, changedLanguageIso).catch(() => {
          setHowToFixItErrors({
            ...howToFixItErrors,
            [changedLanguageIso]: t('ERROR_GENERIC'),
          });
        });
      }
    },
    [testAsset?.howToFixItMap, removeHowToFixItAPI, t, testAssetId, howToFixItErrors]
  );

  const onChangeLowerLimit = useCallback(
    (e) => {
      setLimitLowerError(null);

      const limitString = e.target.value;
      if (!limitString.length) {
        // Remove value in cache
        if (versionedMode) {
          const cachedTestAsset = apolloClient.readFragment({
            id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
            fragment: TestAssetModel.fragmentConditions,
          });
          const updatedTestAsset = {
            ...cachedTestAsset,
            testAssetConditionList: cachedTestAsset.testAssetConditionList?.filter(
              (c) => c.type !== TestAssetModel.CONDITION_TYPES.MIN_VALUE
            ),
          };
          apolloClient.writeFragment({
            id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
            fragment: TestAssetModel.fragmentConditions,
            data: updatedTestAsset,
          });
        } else {
          const cachedTestAsset = apolloClient.readFragment({
            id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
            fragment: InspectionLogTestAssetModel.fragmentUnversionedConditions,
          });
          const updatedTestAsset = {
            ...cachedTestAsset,
            unversionedTestAsset: {
              ...cachedTestAsset.unversionedTestAsset,
              testAssetConditionList: cachedTestAsset.unversionedTestAsset.testAssetConditionList?.filter(
                (c) => c.type !== TestAssetModel.CONDITION_TYPES.MIN_VALUE
              ),
            },
          };
          apolloClient.writeFragment({
            id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
            fragment: InspectionLogTestAssetModel.fragmentUnversionedConditions,
            data: updatedTestAsset,
          });
        }

        // Remove value via API
        saveLowerLimitUpdate(null);
        return;
      }

      if (!isFinite(limitString)) {
        // Invalid value
        setLimitLowerError(t('EDIT_STEP_INVALID_LIMIT', { data: { limit: limitString } }));
        return;
      }

      // Set new value
      const conditionValue = limitString;

      // Update value in cache
      if (versionedMode) {
        const cachedTestAsset = apolloClient.readFragment({
          id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
          fragment: TestAssetModel.fragmentConditions,
        });
        const updatedTestAsset = {
          ...cachedTestAsset,
        };
        const newCondition = {
          type: TestAssetModel.CONDITION_TYPES.MIN_VALUE,
          value: conditionValue,
          valueType: TestAssetModel.conditionValueTypeForConditionType(TestAssetModel.CONDITION_TYPES.MIN_VALUE),
        };
        let updatedConditionList = cachedTestAsset.testAssetConditionList?.length
          ? [...cachedTestAsset.testAssetConditionList]
          : [];
        const existingConditionIndex = updatedConditionList.findIndex(
          (c) => c.type === TestAssetModel.CONDITION_TYPES.MIN_VALUE
        );
        if (existingConditionIndex > -1) {
          updatedConditionList.splice(existingConditionIndex, 1, newCondition);
        } else {
          updatedConditionList.push(newCondition);
        }
        updatedTestAsset.testAssetConditionList = updatedConditionList;
        apolloClient.writeFragment({
          id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
          fragment: TestAssetModel.fragmentConditions,
          data: updatedTestAsset,
        });
      } else {
        const cachedTestAsset = apolloClient.readFragment({
          id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
          fragment: InspectionLogTestAssetModel.fragmentUnversionedConditions,
        });
        const updatedTestAsset = {
          ...cachedTestAsset,
          unversionedTestAsset: {
            ...cachedTestAsset.unversionedTestAsset,
          },
        };
        const newCondition = {
          type: TestAssetModel.CONDITION_TYPES.MIN_VALUE,
          value: conditionValue,
          valueType: TestAssetModel.conditionValueTypeForConditionType(TestAssetModel.CONDITION_TYPES.MIN_VALUE),
        };
        let updatedConditionList = cachedTestAsset.unversionedTestAsset.testAssetConditionList?.length
          ? [...cachedTestAsset.unversionedTestAsset.testAssetConditionList]
          : [];
        const existingConditionIndex = updatedConditionList.findIndex(
          (c) => c.type === TestAssetModel.CONDITION_TYPES.MIN_VALUE
        );
        if (existingConditionIndex > -1) {
          updatedConditionList.splice(existingConditionIndex, 1, newCondition);
        } else {
          updatedConditionList.push(newCondition);
        }
        updatedTestAsset.unversionedTestAsset.testAssetConditionList = updatedConditionList;
        apolloClient.writeFragment({
          id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
          fragment: TestAssetModel.fragmentConditions,
          data: updatedTestAsset,
        });
      }

      // Ensure not above max precision due to backend limitation
      if (getPrecisionOfNumber(limitString) > AppConfig.number_max_precision) {
        setLimitLowerError(t('EDIT_STEP_MAX_PRECISION', { data: { number: AppConfig.number_max_precision } }));
        saveLowerLimitUpdate.cancel();
        return;
      }

      // Update value via API
      saveLowerLimitUpdate(conditionValue);
    },
    [apolloClient, saveLowerLimitUpdate, t, testAssetId, versionedMode]
  );

  const onChangeSeverity = (e) => {
    setSeverityError(null);
    const newSeverityLevel = e.target.value;
    setSeverityLevelAPI(testAssetId, newSeverityLevel).catch(() => {
      setSeverityError(t('ERROR_GENERIC'));
    });
  };

  const onChangeUpperLimit = useCallback(
    (e) => {
      setLimitUpperError(null);

      const limitString = e.target.value;
      if (!limitString.length) {
        // Remove value in cache
        if (versionedMode) {
          const cachedTestAsset = apolloClient.readFragment({
            id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
            fragment: TestAssetModel.fragmentConditions,
          });
          const updatedTestAsset = {
            ...cachedTestAsset,
            testAssetConditionList: cachedTestAsset.testAssetConditionList?.filter(
              (c) => c.type !== TestAssetModel.CONDITION_TYPES.MAX_VALUE
            ),
          };
          apolloClient.writeFragment({
            id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
            fragment: TestAssetModel.fragmentConditions,
            data: updatedTestAsset,
          });
        } else {
          const cachedTestAsset = apolloClient.readFragment({
            id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
            fragment: InspectionLogTestAssetModel.fragmentUnversionedConditions,
          });
          const updatedTestAsset = {
            ...cachedTestAsset,
            unversionedTestAsset: {
              ...cachedTestAsset.unversionedTestAsset,
              testAssetConditionList: cachedTestAsset.unversionedTestAsset.testAssetConditionList?.filter(
                (c) => c.type !== TestAssetModel.CONDITION_TYPES.MAX_VALUE
              ),
            },
          };
          apolloClient.writeFragment({
            id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
            fragment: InspectionLogTestAssetModel.fragmentUnversionedConditions,
            data: updatedTestAsset,
          });
        }

        // Remove value via API
        saveUpperLimitUpdate(null);
        return;
      }

      if (!isFinite(limitString)) {
        // Invalid value
        setLimitUpperError(t('EDIT_STEP_INVALID_LIMIT', { data: { limit: limitString } }));
        return;
      }

      // Set new value
      const conditionValue = limitString;

      // Update value in cache
      if (versionedMode) {
        const cachedTestAsset = apolloClient.readFragment({
          id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
          fragment: TestAssetModel.fragmentConditions,
        });
        const updatedTestAsset = {
          ...cachedTestAsset,
        };
        const newCondition = {
          type: TestAssetModel.CONDITION_TYPES.MAX_VALUE,
          value: conditionValue,
          valueType: TestAssetModel.conditionValueTypeForConditionType(TestAssetModel.CONDITION_TYPES.MAX_VALUE),
        };
        let updatedConditionList = cachedTestAsset.testAssetConditionList?.length
          ? [...cachedTestAsset.testAssetConditionList]
          : [];
        const existingConditionIndex = updatedConditionList.findIndex(
          (c) => c.type === TestAssetModel.CONDITION_TYPES.MAX_VALUE
        );
        if (existingConditionIndex > -1) {
          updatedConditionList.splice(existingConditionIndex, 1, newCondition);
        } else {
          updatedConditionList.push(newCondition);
        }
        updatedTestAsset.testAssetConditionList = updatedConditionList;
        apolloClient.writeFragment({
          id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
          fragment: TestAssetModel.fragmentConditions,
          data: updatedTestAsset,
        });
      } else {
        const cachedTestAsset = apolloClient.readFragment({
          id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
          fragment: InspectionLogTestAssetModel.fragmentUnversionedConditions,
        });
        const updatedTestAsset = {
          ...cachedTestAsset,
          unversionedTestAsset: {
            ...cachedTestAsset.unversionedTestAsset,
          },
        };
        const newCondition = {
          type: TestAssetModel.CONDITION_TYPES.MIN_VALUE,
          value: conditionValue,
          valueType: TestAssetModel.conditionValueTypeForConditionType(TestAssetModel.CONDITION_TYPES.MIN_VALUE),
        };
        let updatedConditionList = cachedTestAsset.unversionedTestAsset.testAssetConditionList?.length
          ? [...cachedTestAsset.unversionedTestAsset.testAssetConditionList]
          : [];
        const existingConditionIndex = updatedConditionList.findIndex(
          (c) => c.type === TestAssetModel.CONDITION_TYPES.MIN_VALUE
        );
        if (existingConditionIndex > -1) {
          updatedConditionList.splice(existingConditionIndex, 1, newCondition);
        } else {
          updatedConditionList.push(newCondition);
        }
        updatedTestAsset.unversionedTestAsset.testAssetConditionList = updatedConditionList;
        apolloClient.writeFragment({
          id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
          fragment: TestAssetModel.fragmentConditions,
          data: updatedTestAsset,
        });
      }

      // Ensure not above max precision due to backend limitation
      if (getPrecisionOfNumber(limitString) > AppConfig.number_max_precision) {
        setLimitUpperError(t('EDIT_STEP_MAX_PRECISION', { data: { number: AppConfig.number_max_precision } }));
        saveUpperLimitUpdate.cancel();
        return;
      }

      // Update value via API
      saveUpperLimitUpdate(conditionValue);
    },
    [apolloClient, saveUpperLimitUpdate, t, testAssetId, versionedMode]
  );

  const onChangeWhatToLookFor = useCallback(
    (changedLanguageIso, newValues) => {
      const text = newValues.find((v) => v.languageIso === changedLanguageIso)?.value;
      if (
        typeof text === 'undefined' &&
        testAsset.whatToLookForMap[changedLanguageIso] &&
        changedLanguageIso !== 'EN'
      ) {
        // Remove text
        removeWhatToLookForAPI(testAssetId, changedLanguageIso).catch(() => {
          setWhatToLookForErrors({
            ...whatToLookForErrors,
            [changedLanguageIso]: t('ERROR_GENERIC'),
          });
        });
      } else if (text === '') {
        if (changedLanguageIso === 'EN') {
          // Update text locally and warn that field cannot be blank
          if (versionedMode) {
            apolloClient.cache.modify({
              id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
              fields: {
                whatToLookForMap: (currentMap) => {
                  return {
                    ...currentMap,
                    EN: {
                      ...currentMap.EN,
                      text: '',
                    },
                  };
                },
              },
            });
          } else {
            apolloClient.cache.modify({
              id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
              fields: {
                unversionedTestAsset: (currentTestAsset) => {
                  return {
                    ...currentTestAsset,
                    whatToLookForMap: {
                      ...currentTestAsset.whatToLookForMap,
                      EN: {
                        ...currentTestAsset.whatToLookForMap.EN,
                        text: '',
                      },
                    },
                  };
                },
              },
            });
          }

          setWhatToLookForErrors({
            ...whatToLookForErrors,
            [changedLanguageIso]: t('EDIT_STEP_TEXT_CANNOT_BE_LEFT_BLANK'),
          });
        } else {
          // Update text
          onBlurWhatToLookFor(changedLanguageIso, newValues);
        }
      }
    },
    [
      apolloClient.cache,
      onBlurWhatToLookFor,
      removeWhatToLookForAPI,
      t,
      testAsset?.whatToLookForMap,
      testAssetId,
      versionedMode,
      whatToLookForErrors,
    ]
  );

  const onClickLinkMedia = (mediaAssetId) => {
    setMediaError(null);
    setSearchMediaString('');
    setMediaLoading(true);
    linkMediaAssetAPI(testAssetId, mediaAssetId)
      .then(() => {
        setTimeout(() => {
          mediaGalleryRef.current.scrollToIndex(testAsset.testAssetMediaAssetList.length);
        }, 50);
      })
      .catch(() => {
        setMediaError(t('ERROR_GENERIC'));
      })
      .finally(() => {
        setMediaLoading(false);
      });
  };

  const onClickReorderMedia = (mediaAssetId, direction) => {
    setMediaError(null);
    setMediaEditingError(null);

    if (direction === 'left') {
      reorderMediaBackwardAPI(testAssetId, mediaAssetId).catch(() => {
        setMediaEditingError(t('ERROR_GENERIC'));
      });
    } else {
      reorderMediaForwardAPI(testAssetId, mediaAssetId).catch(() => {
        setMediaEditingError(t('ERROR_GENERIC'));
      });
    }
  };

  const onClickUnlinkMedia = (mediaAssetId) => {
    setMediaEditingError(null);
    unlinkMediaAssetAPI(testAssetId, mediaAssetId)
      .then(() => {
        const mediaAssets = versionedMode
          ? testAsset?.testAssetMediaAssetList
          : inspectionLogTestAsset?.inspectionLogTestAssetMediaAssetList;
        const scrollToindex =
          mediaAssets.findIndex((m) => {
            return versionedMode ? m.mediaAsset.id === mediaAssetId : m.id === mediaAssetId;
          }) - 1;
        setTimeout(() => {
          mediaGalleryRef.current.scrollToIndex(scrollToindex);
        }, 50);
      })
      .catch(() => {
        setMediaEditingError(t('ERROR_GENERIC'));
      });
  };

  const onMediaPicked = async (files, originalFiles) => {
    setMediaError(null);
    setMediaEditingError(null);
    setMediaLoading(true);

    const file = files[0];
    const originalFile = originalFiles[0];
    const mediaType = simpleMIMEType(file.type).toUpperCase();
    let response;

    try {
      switch (mediaType) {
        case MediaAssetModel.MEDIA_TYPE.AUDIO:
          if (versionedMode) {
            response = await createTestAssetAudioMediaAssetRequest(testAssetId, file);
          } else {
            response = await createInspectionLogTestAssetAudioMediaAssetRequest(testAssetId, file);
          }
          break;
        case MediaAssetModel.MEDIA_TYPE.IMAGE:
          if (versionedMode) {
            response = await createTestAssetImageMediaAssetRequest(testAssetId, file, originalFile);
          } else {
            response = await createInspectionLogTestAssetImageMediaAssetRequest(testAssetId, file, originalFile);
          }
          break;
        case MediaAssetModel.MEDIA_TYPE.VIDEO:
          if (versionedMode) {
            response = await createTestAssetVideoMediaAssetRequest(testAssetId, file);
          } else {
            response = await createInspectionLogTestAssetVideoMediaAssetRequest(testAssetId, file);
          }
          break;
        default:
          okwarn('Unsupported media added with type:', mediaType);
      }

      if (response?.success) {
        if (versionedMode) {
          updateCacheWithNewTestAssetMediaAsset(response.responseData);
        } else {
          updateCacheWithNewInspectionLogTestAssetMediaAsset(response.responseData);
        }
        setTimeout(() => {
          const scrollToIndex = versionedMode
            ? testAsset?.testAssetMediaAssetList.length ?? 0
            : inspectionLogTestAsset?.inspectionLogTestAssetMediaAssetList.length ?? 0;
          mediaGalleryRef.current.scrollToIndex(scrollToIndex);
        }, 50);
      } else {
        throw new Error('API failed.');
      }
    } catch (e) {
      okerror('Error saving media to test asset.', e);
      setMediaError(t('ERROR_GENERIC'));
    } finally {
      setMediaLoading(false);
    }
  };

  const onMediaUpdated = async (mediaAssetId, file) => {
    setMediaError(null);
    setMediaEditingError(null);
    setMediaLoading(true);
    let response;
    try {
      let mediaType;
      if (versionedMode) {
        mediaType = testAsset.testAssetMediaAssetList.find((tma) => tma.mediaAsset.id === mediaAssetId).mediaAsset
          .mediaType;
      } else {
        const inspectionLogTestAssetMediaAsset = inspectionLogTestAsset.inspectionLogTestAssetMediaAssetList.find(
          (tma) => tma.id === mediaAssetId
        );
        mediaType = (
          inspectionLogTestAssetMediaAsset.versionedMediaAsset ?? inspectionLogTestAssetMediaAsset.unversionedMediaAsset
        ).mediaType;
      }

      switch (mediaType) {
        case MediaAssetModel.MEDIA_TYPE.IMAGE:
          if (versionedMode) {
            response = await updateImageMediaAssetRequest(mediaAssetId, file);
          } else {
            response = await updateInspectionLogTestAssetImageMediaAssetRequest(
              inspectionLogTestAsset.id,
              mediaAssetId,
              file
            );
          }
          break;
        default:
          okwarn('Unsupported media updated with type:', mediaType);
      }

      if (response.success) {
        if (versionedMode) {
          updateCacheWithUpdatedMediaAsset(response.responseData);
        } else {
          updateCacheWithUpdatedInspectionLogTestAssetMediaAsset(response.responseData);
        }
      } else {
        throw new Error('Update media api failed.');
      }
    } catch (error) {
      okerror('Error updating media.', error);
      setMediaError(t('ERROR_GENERIC'));
    } finally {
      setMediaLoading(false);
    }
  };

  const onSearchedMedia = (e) => {
    const newSearchString = e.target.value;
    setSearchMediaString(newSearchString);

    if (newSearchString.length > 1) {
      searchDebounced(newSearchString);
    }
  };

  /* Effects */

  // Get versioned test data when possible
  useEffect(() => {
    if (versionedMode && testRefId) {
      getVersionedTestAssetAPI({ variables: { refId: testRefId } });
    }
  }, [getVersionedTestAssetAPI, testRefId, versionedMode]);

  // // Sync lower limit state with live value
  // useEffect(() => {
  //   setLimitLower(lowerLimitCondition);
  // }, [lowerLimitCondition]);

  // // Sync upper limit state with live value
  // useEffect(() => {
  //   setLimitUpper(upperLimitCondition);
  // }, [upperLimitCondition]);

  /* Render */

  // Media search suggestions
  const mediaSearchResults = useMemo(() => {
    if (searchMediaFocused && searchMediaString.length > 1) {
      return (
        searchAPIResult.data?.search?.resultList?.map((r) => {
          const mediaAsset = r.mediaAssetData;
          // Icon
          let icon;
          let iconBackgroundImageUrl;
          switch (mediaAsset.mediaType) {
            case MediaAssetModel.MEDIA_TYPE.VIDEO:
              icon = <Icon className={styles.videoIcon} name={ICONS.PLAY.name} />;
              break;
            case MediaAssetModel.MEDIA_TYPE.AUDIO:
              icon = ICONS.AUDIO.name;
              break;
            default:
              icon = ICONS.IMAGE.name;
              iconBackgroundImageUrl = mediaAsset.imageData.imageURL;
              break;
          }

          // Highlight
          let highlightString;
          if (r.dataHighlights?.length) {
            const keys = r.dataHighlights[0].split('.');
            let data = mediaAsset;
            for (let keyIndex in keys) {
              const key = keys[keyIndex];
              data = data[key];
            }
            highlightString = data;
          }
          return {
            action: (
              <Button className={styles.searchSuggestionAction} icon={ICONS.LINK.name} linkStyle>
                Link
              </Button>
            ),
            highlightString,
            icon,
            iconBackgroundImageUrl,
            key: mediaAsset.id,
            title: t(mediaAsset.mediaType),
            subtitle: mediaAsset.REFID,
          };
        }) ?? []
      );
    }

    return [];
  }, [searchAPIResult.data?.search?.resultList, searchMediaFocused, searchMediaString.length, t]);

  const whatToLookForFieldValues = useMemo(() => {
    if (!testAsset?.whatToLookForMap) {
      return [];
    }

    return Object.keys(testAsset.whatToLookForMap).map((languageIso) => {
      return {
        warning: whatToLookForErrors[languageIso],
        languageIso,
        optional: languageIso !== 'EN',
        value: testAsset.whatToLookForMap[languageIso].text,
      };
    });
  }, [testAsset?.whatToLookForMap, whatToLookForErrors]);

  const howToFindItFieldValues = useMemo(() => {
    if (!testAsset?.howToFindItMap) {
      return [];
    }

    return Object.keys(testAsset.howToFindItMap).map((languageIso) => {
      return {
        warning: howToFindItErrors[languageIso],
        languageIso,
        optional: true,
        value: testAsset.howToFindItMap[languageIso].text,
      };
    });
  }, [testAsset?.howToFindItMap, howToFindItErrors]);

  const howToFixItFieldValues = useMemo(() => {
    if (!testAsset?.howToFixItMap) {
      return [];
    }
    return Object.keys(testAsset.howToFixItMap).map((languageIso) => {
      return {
        warning: howToFixItErrors[languageIso],
        languageIso,
        optional: true,
        value: testAsset.howToFixItMap[languageIso].text,
      };
    });
  }, [testAsset?.howToFixItMap, howToFixItErrors]);

  if (!testAsset) {
    return null;
  }

  const testSeverityLevel = versionedMode ? testAsset?.testSeverityLevel : testAsset?.testSeverityLevel;

  const severitySection = (
    <TextLayout className={styles.severityContainer}>
      <h4>{t('STEP_SECTION_NAME_PRIORITY')}</h4>
      <Radio
        checked={testSeverityLevel === TestAssetModel.SEVERITY_LEVEL.MINOR}
        className={styles.severityRadio}
        name='severityLevel'
        onChange={onChangeSeverity}
        value={TestAssetModel.SEVERITY_LEVEL.MINOR}
      >
        <Text className={styles.severityLevel}>
          <Tag block={false} className={styles.severityIcon} invert size='md' tint='navigation'>
            !
          </Tag>
          <strong>{t('STEP_PRIORITY_LEVEL_MINOR')}</strong>
        </Text>
        <Text className={styles.severityDescription} size='sm'>
          {t('EDIT_STEP_PRIORITY_MINOR_DESCRIPTION')}
        </Text>
      </Radio>
      <Radio
        checked={testSeverityLevel === TestAssetModel.SEVERITY_LEVEL.MAJOR}
        className={styles.severityRadio}
        name='severityLevel'
        onChange={onChangeSeverity}
        value={TestAssetModel.SEVERITY_LEVEL.MAJOR}
      >
        <Text className={styles.severityLevel}>
          <Tag block={false} className={styles.severityIcon} invert size='md' tint='notification'>
            !!
          </Tag>
          <strong>{t('STEP_PRIORITY_LEVEL_MAJOR')}</strong>
        </Text>
        <Text className={styles.severityDescription} size='sm'>
          {t('EDIT_STEP_PRIORITY_MAJOR_DESCRIPTION')}
        </Text>
      </Radio>
      <Radio
        checked={testSeverityLevel === TestAssetModel.SEVERITY_LEVEL.CRITICAL}
        className={styles.severityRadio}
        name='severityLevel'
        onChange={onChangeSeverity}
        value={TestAssetModel.SEVERITY_LEVEL.CRITICAL}
      >
        <Text className={styles.severityLevel}>
          <Tag block={false} className={styles.severityIcon} invert size='md' tint='alert'>
            !!!
          </Tag>
          <strong>{t('STEP_PRIORITY_LEVEL_CRITICAL')}</strong>
        </Text>
        <Text className={styles.severityDescription} size='sm'>
          {t('EDIT_STEP_PRIORITY_CRITICAL_DESCRIPTION')}
        </Text>
      </Radio>
      {severityError && (
        <Text className={styles.severityError} size='sm' tint='notification'>
          {severityError}
        </Text>
      )}
    </TextLayout>
  );

  const testDetailsSections = (
    <>
      <TextLayout>
        <h4>{t('STEP_SECTION_NAME_WHAT')}</h4>
        <Text>{t('EDIT_STEP_SECTION_DESCRIPTION_WHAT')}</Text>
        <Notice className={styles.notice} extendSides>
          <strong>{t('EDIT_STEP_NOTICE_WHAT')}</strong>
        </Notice>
        <FormFieldI18n
          inputPlaceholder={t('EDIT_STEP_PLACEHOLDER_WHAT', { data: { language: '{{language}}' } })}
          onBlur={onBlurWhatToLookFor}
          onChange={onChangeWhatToLookFor}
          useTextAreas
          values={whatToLookForFieldValues}
        />
        <Separator type='section' />
        <h4>
          {t('STEP_SECTION_NAME_LIMITS')} <span className={styles.normalWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
        </h4>
        <Text>{t('EDIT_STEP_SECTION_DESCRIPTION_LIMITS')}</Text>
        <Notice className={styles.notice} extendSides>
          <Text bold>{t('EDIT_STEP_NOTICE_LIMITS')}</Text>
        </Notice>
        <Text bold className={styles.fieldHeader}>
          {t('STEP_FIELD_NAME_UPPER_LIMIT')}{' '}
          <span className={styles.normalWeight}>{t('STEP_FIELD_NAME_LABEL_INCLUSIVE')}</span>
        </Text>
        <Input
          className={styles.field}
          onChange={onChangeUpperLimit}
          placeholder={t('EDIT_STEP_PLACEHOLDER_UPPER_LIMIT')}
          step='any'
          type='number'
          value={upperLimitCondition}
        />
        {limitUpperError && (
          <Text className={styles.errorMessage} size='sm' tint='alert'>
            {limitUpperError}
          </Text>
        )}
        <Text bold className={styles.fieldHeader}>
          {t('STEP_FIELD_NAME_LOWER_LIMIT')}{' '}
          <span className={styles.normalWeight}>{t('STEP_FIELD_NAME_LABEL_INCLUSIVE')}</span>
        </Text>
        <Input
          className={styles.field}
          onChange={onChangeLowerLimit}
          placeholder={t('EDIT_STEP_PLACEHOLDER_LOWER_LIMIT')}
          step='any'
          type='number'
          value={lowerLimitCondition}
        />
        {limitLowerError && (
          <Text className={styles.errorMessage} size='sm' tint='alert'>
            {limitLowerError}
          </Text>
        )}
        <Separator type='section' />
        <h4>
          {t('STEP_SECTION_NAME_HOW')} <span className={styles.normalWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
        </h4>
        <Text>{t('EDIT_STEP_SECTION_DESCRIPTION_HOW')}</Text>
        <FormFieldI18n
          inputPlaceholder={t('EDIT_STEP_PLACEHOLDER_HOW', { data: { language: '{{language}}' } })}
          onBlur={onBlurHowToFindIt}
          onChange={onChangeHowToFindIt}
          useTextAreas
          values={howToFindItFieldValues}
        />
        <Separator type='section' />
        <h4>
          {t('STEP_SECTION_NAME_FIX')} <span className={styles.normalWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
        </h4>
        <Text>{t('EDIT_STEP_SECTION_DESCRIPTION_FIX')}</Text>
        <FormFieldI18n
          inputPlaceholder={t('EDIT_STEP_PLACEHOLDER_FIX', { data: { language: '{{language}}' } })}
          onBlur={onBlurHowToFixIt}
          onChange={onChangeHowToFixIt}
          useTextAreas
          values={howToFixItFieldValues}
        />
      </TextLayout>
    </>
  );

  const mediaGallerySection = (
    <div className={styles.mediaGalleryContainer}>
      <TextLayout>
        <h4>
          {t('STEP_SECTION_NAME_MEDIA_GALLERY')}{' '}
          <span className={styles.normalWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
        </h4>
        <Text>{t('EDIT_STEP_SECTION_DESCRIPTION_MEDIA_GALLERY')}</Text>
      </TextLayout>
      <Progressable inProgress={mediaLoading}>
        <MediaGallery
          allowAddingMediaTypes={['photo', 'video', 'audio']}
          className={styles.mediaGallery}
          enableEditing={mode !== EDIT_TEST_PAGE_MODE.VERSIONED_MODE}
          media={
            versionedMode
              ? testAsset?.testAssetMediaAssetList.map((tma) => tma.mediaAsset)
              : inspectionLogTestAsset?.inspectionLogTestAssetMediaAssetList.map((tma) => tma)
          }
          onClickUnlink={onClickUnlinkMedia}
          onMediaFileUpdated={onMediaUpdated}
          onMediaReordered={onClickReorderMedia}
          onNewMediaSelected={onMediaPicked}
          ref={mediaGalleryRef}
          warning={mediaEditingError}
        />
        {versionedMode ? (
          <TextLayout>
            <div className={styles.searchContainer} style={{ zIndex: 2 }}>
              <SearchInput
                accessoryButton={
                  <Button linkStyle onClick={openMediaPicker} tint='creation'>
                    {t('NEW')}
                  </Button>
                }
                className={styles.searchInput}
                onBlur={() => setSearchMediaFocused(false)}
                onChange={onSearchedMedia}
                onFocus={() => setSearchMediaFocused(true)}
                placeholder={t('FIELD_PLACEHOLDER_FIND_MEDIA')}
                value={searchMediaString}
              />
              <SearchSuggestions
                accessoryViewRender={() => (
                  <Text className={styles.searchResultButton} tint='navigation'>
                    {t('LINK')}
                    <InlineIcon src='/icons/link_blue.svg' />
                  </Text>
                )}
                className={styles.searchResults}
                highlightTerm={searchMediaString}
                onSuggestionClick={onClickLinkMedia}
                showMoreResultsMessage={
                  searchMediaFocused &&
                  searchAPIResult.data?.search?.searchPaginationResultDataByDataType?.MEDIA_ASSET?.totalResults >
                    mediaSearchResults.length
                }
                showNoResultsMessage={
                  searchMediaFocused && searchMediaString.length > 1 && mediaSearchResults.length === 0
                }
                suggestions={mediaSearchResults}
                subtitleClassName={styles.searchResultOKID}
              />
              {mediaError && (
                <Text className={styles.mediaError} size='sm' tint='notification'>
                  {mediaError}
                </Text>
              )}
            </div>
          </TextLayout>
        ) : (
          <TextLayout>
            <Button
              block
              icon={ICONS.PLUS.name}
              onClick={() => {
                try {
                  mediaGalleryRef.current.openMediaPicker();
                } catch (e) {
                  trackError(e);
                }
              }}
              tint='creation'
            >
              {t('EDIT_STEP_ADD_NEW_MEDIA_BUTTON')}
            </Button>
          </TextLayout>
        )}
      </Progressable>
    </div>
  );

  const additionalOptionsSection = (
    <>
      <Separator className={styles.separator} type='section' />
      <div>
        <TextLayout>
          <h4>{t('STEP_SECTION_NAME_ADDITIONAL_OPTIONS')}</h4>
          <label className={styles.additionalOption}>
            <Checkbox
              checked={testAsset.enableIdentifiers === true}
              className={styles.optionCheckbox}
              onChange={toggleIdentifiersEnabled}
            />{' '}
            {t('STEP_FIELD_NAME_IDENTIFIERS')}
          </label>
          {identifiersEnabledError && (
            <Text className={styles.optionError} size='sm' tint='alert'>
              {identifiersEnabledError}
            </Text>
          )}
          <label className={styles.additionalOption}>
            <Checkbox
              checked={testAsset.enableNotes === true}
              className={styles.optionCheckbox}
              onChange={toggleNotesEnabled}
            />{' '}
            {t('STEP_FIELD_NAME_NOTES')}
          </label>
          {notesEnabledError && (
            <Text className={styles.optionError} size='sm' tint='alert'>
              {notesEnabledError}
            </Text>
          )}
          <label className={styles.additionalOption}>
            <Checkbox
              checked={testAsset.enableTimeTracking === true}
              className={styles.optionCheckbox}
              onChange={toggleTimeTrackingEnabled}
            />{' '}
            {t('STEP_FIELD_NAME_TIME_TRACKING')}
          </label>
          {timeTrackingEnabledError && (
            <Text className={styles.optionError} size='sm' tint='alert'>
              {timeTrackingEnabledError}
            </Text>
          )}
        </TextLayout>
      </div>
    </>
  );

  const mainlayout = (
    <div className={styles.layoutContainer}>
      <div className={`${styles.layoutColumn} ${desktopColumnClassName ?? ''}`}>
        {mediaGallerySection}
        <Separator className={styles.separator} type='section' />
        {severitySection}
        {useDesktopLayout && additionalOptionsSection}
        {useMobileLayout && <Separator className={styles.separator} type='section' />}
      </div>
      <div className={`${styles.layoutColumn} ${desktopColumnClassName ?? ''}`}>
        {testDetailsSections}
        {useMobileLayout && additionalOptionsSection}
      </div>
    </div>
  );

  return <div>{mainlayout}</div>;
}

EditTest.propTypes = {
  desktopColumnClassName: PropTypes.string,
  inspectionLogTestAssetId: PropTypes.string,
  mode: PropTypes.oneOf([EDIT_TEST_PAGE_MODE.VERSIONED_MODE, EDIT_TEST_PAGE_MODE.UNVERSIONED_MODE]),
  testRefId: PropTypes.string,
};
