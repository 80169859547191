import { useApolloClient, useMutation, useQuery } from '@apollo/client';
// import HCaptcha from '@hcaptcha/react-hcaptcha';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import AddWorkPopup from '../addWork';

import styles from './styles.module.scss';

import Accordion from 'OK/components/accordion';
import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import ButtonGroup from 'OK/components/buttonGroup';
import { Carousel, Slide } from 'OK/components/carousel';
import Checkbox from 'OK/components/checkbox';
import DocumentMinimalCard from 'OK/components/document/minimalCard';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import PhoneInput from 'OK/components/input/phoneInput';
import InspectionArchiveCard from 'OK/components/inspection/archiveCard';
import CardLayout from 'OK/components/layouts/card';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import LinkToItem from 'OK/components/link/linkToItem';
import MediaPicker from 'OK/components/mediaPicker';
import Notice from 'OK/components/notice';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import ProductInfoPopupSection from 'OK/components/product/infoPopupSection';
import RemarkLorealCard from 'OK/components/remark/lorealCard';
import Text from 'OK/components/text';
import TextArea from 'OK/components/textarea';
// import VideoPlayer from 'OK/components/videoPlayer';
// import config from 'OK/config/app';
import ProductModel from 'OK/models/product';
import SiteModel from 'OK/models/site';
import MultipleScanResultsPopup from 'OK/modules/popups/scanResults/multipleResults';
import { startInspectionLogBySourceMutation } from 'OK/networking/inspectionLogs';
import { GetItemForScanResultsQuery, getItemByAlternateIdQuery } from 'OK/networking/items';
import { GetSiteForScanResultsQuery } from 'OK/networking/sites';
import { getCurrentUserInProgressInspectionLogsQuery } from 'OK/networking/users';
import { trackError } from 'OK/util/analytics';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import { formatOkid, unformatOkid } from 'OK/util/formatting';
import isAuthorised from 'OK/util/functions/isAuthorised';
import { isOKID } from 'OK/util/functions/OKID';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisation from 'OK/util/hooks/useAuthorisationLevel';
import useBrowserRenderOnly from 'OK/util/hooks/useBrowserRenderOnly';
import useI18n from 'OK/util/hooks/useI18n';

export const SCAN_RESULTS_POPUP_SOURCE_TYPE = {
  DOCUMENT: 'DOCUMENT_ASSET',
  ITEM: 'ITEM',
  SITE: 'SITE',
};

export default function ScanResultsPopup(props) {
  /* Variables */

  const { dismiss, OKID, sourceType: sourceTypeProp } = props;
  const apolloClient = useApolloClient();
  const { locale, t, tHTML } = useI18n();

  // Other variables

  const [authenticated] = useAuthentication(() => false);
  const region = useSelector((state) => state.account.preferences.region);
  const renderForBrowser = useBrowserRenderOnly();
  const router = useRouter();
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const userAgent = useSelector((state) => state.app.userAgent);

  // State
  const [showAddWorkPopup, setShowAddWorkPopup] = useState(false);
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
  const [captchaError, setCaptchaError] = useState(null);
  const [captchaToken, _setCaptchaToken] = useState(null);
  const [documentationType, setDocumentationType] = useState(DOCUMENTATION_TYPE_MAP);
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [item, setItem] = useState(null);
  const [site, setSite] = useState(null);
  const [loading, setLoading] = useState(false);
  const [matchedItems, setMatchedItems] = useState(null);
  const [openGradingAccordion, setOpenGradingAccordion] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberCountry, setPhoneNumberCountry] = useState(region);
  const [showInProgressInspectionAlert, setShowInProgressInspectionAlert] = useState(false);
  const [startingInspectionAssetId, setStartingInspectionAssetId] = useState(null);
  const [sourceType, setSourceType] = useState(sourceTypeProp);

  const itemAuthorisationLevel = useAuthorisation(item);
  const siteAuthorisationLevel = useAuthorisation(site);
  const authorisationLevel =
    sourceType === SCAN_RESULTS_POPUP_SOURCE_TYPE.SITE ? siteAuthorisationLevel : itemAuthorisationLevel;
  const availableInspectionsList =
    sourceType === SCAN_RESULTS_POPUP_SOURCE_TYPE.SITE
      ? site?.availableSiteInspectionList
      : item?.availableProductInspectionList;
  const hasInspectionRights =
    isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.COWORKER) || availableInspectionsList?.length > 0;
  const product = item?.product;

  // Refs

  const hCaptcha = useRef();

  /* API */

  const getItemByAlternateID = useCallback(
    (alternateId) => {
      setLoading(true);
      apolloClient
        .query({
          fetchPolicy: 'network-only',
          query: getItemByAlternateIdQuery,
          variables: {
            alternateId,
          },
        })
        .then((result) => {
          if (!result.data?.items?.length) {
            router.replace('/404');
            return;
          }

          setSourceType(SCAN_RESULTS_POPUP_SOURCE_TYPE.ITEM);
          if (result.data.items.length > 1) {
            setMatchedItems(result.data.items);
          } else {
            setItem(result.data.items[0]);
          }
        })
        .catch((e) => {
          trackError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [apolloClient, router]
  );

  const getItemByOKID = useCallback(
    (okid) => {
      setLoading(true);
      apolloClient
        .query({
          fetchPolicy: 'network-only',
          query: GetItemForScanResultsQuery,
          variables: {
            OKID: unformatOkid(okid),
          },
        })
        .then((result) => {
          if (!result.data?.item) {
            getItemByAlternateID(okid);
            return;
          }
          
          router.push(`/${okid}`)

          // setSourceType(SCAN_RESULTS_POPUP_SOURCE_TYPE.ITEM);
          // setItem(result.data.item);
          // setLoading(false);
        })
        .catch((e) => {
          trackError(e);
          setLoading(false);
        });
    },
    [apolloClient, getItemByAlternateID, router]
  );

  const getSiteByOKID = useCallback(
    (okid) => {
      setLoading(true);
      apolloClient
        .query({
          fetchPolicy: 'network-only',
          query: GetSiteForScanResultsQuery,
          variables: {
            OKID: unformatOkid(okid),
          },
        })
        .then((result) => {
          if (!result.data?.item) {
            router.replace('/404');
            return;
          }

          setSourceType(SCAN_RESULTS_POPUP_SOURCE_TYPE.SITE);
          setSite(result.data.item);
          setLoading(false);
        })
        .catch((e) => {
          trackError(e);
          setLoading(false);
        });
    },
    [apolloClient, router]
  );

  const currentUserWithInProgressInspectionsAPIResult = useQuery(getCurrentUserInProgressInspectionLogsQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !authenticated,
  });
  const inProgressInspectionLogs = useMemo(() => {
    return currentUserWithInProgressInspectionsAPIResult.data?.user?.inprogressInspectionLogList ?? [];
  }, [currentUserWithInProgressInspectionsAPIResult.data?.user?.inprogressInspectionLogList]);

  const [startInspectionAPI] = useMutation(startInspectionLogBySourceMutation);

  /* Methods */

  const clearSelectedItem = useCallback(() => {
    setItem(null);
  }, []);

  const resumeInspection = useCallback(() => {
    setShowInProgressInspectionAlert(false);
    if (inProgressInspectionLogs.length) {
      router.push(`/archive/log/${inProgressInspectionLogs[0].REFID}/go`);
    }
  }, [inProgressInspectionLogs, router]);

  const startInspection = useCallback(
    (inspectionAssetId) => {
      if (!startingInspectionAssetId) {
        const inProgressInspectionLog = inProgressInspectionLogs.find(
          (l) => l.inspectionAsset.id === inspectionAssetId
        );
        if (inProgressInspectionLog) {
          // Resume inspection
          router.push(`/archive/log/${inProgressInspectionLog.REFID}/go`);
        } else if (inProgressInspectionLogs.length === 0) {
          setStartingInspectionAssetId(inspectionAssetId);
          // Start inspection
          startInspectionAPI({
            variables: {
              inspectionAssetId,
              sourceId: sourceType === SCAN_RESULTS_POPUP_SOURCE_TYPE.SITE ? site.id : item.id,
              sourceType,
            },
          })
            .then((result) => {
              if (result.data?.inspectionLog) {
                router.push(`/archive/log/${result.data.inspectionLog.REFID}/go`);
              } else {
                throw new Error('Response did not include inspection log data.');
              }
            })
            .catch((e) => {
              okerror('Error starting inspection.', e);
              if (e.message === 'INSPECTION_LOG_USER_HAS_INPROGRESS_INSPECTION_LOG') {
                // Re-fetch in progress inspections list so we can resume
                currentUserWithInProgressInspectionsAPIResult.refetch();
              }
            })
            .finally(() => {
              setStartingInspectionAssetId(null);
            });
        } else {
          setShowInProgressInspectionAlert(true);
        }
      }
    },
    [
      currentUserWithInProgressInspectionsAPIResult,
      inProgressInspectionLogs,
      item?.id,
      router,
      site?.id,
      sourceType,
      startInspectionAPI,
      startingInspectionAssetId,
    ]
  );

  const setCaptchaToken = (token) => {
    _setCaptchaToken(token);

    if (!token) {
      okdebug('resetting captcha');
      hCaptcha.current?.resetCaptcha();
    } else {
      okdebug('captcha token set to', token);
    }
  };

  const triggerCaptcha = () => {
    if (!captchaToken) {
      hCaptcha.current?.execute();
    }
  };

  // Event handlers

  const onChangePrivacyPolicyChecked = (e) => {
    setAcceptedPrivacyPolicy(e.target.checked);

    if (e.target.checked && !authenticated) {
      triggerCaptcha();
    }
  };

  /* Effects */

  // Load data
  useEffect(() => {
    const tryAll = async function () {
      try {
        // // Try loading site first
         const siteResult = await apolloClient.query({
           fetchPolicy: 'network-only',
           query: GetSiteForScanResultsQuery,
           variables: {
             OKID: unformatOkid(OKID),
           },
         });
        
         if (siteResult.data?.item) {
           router.push(`/archive/site/${unformatOkid(OKID)}`);
         }
      } catch {
        // Try loading item if error loading site
        getItemByOKID(OKID);
      }
    };

    if (sourceType === SCAN_RESULTS_POPUP_SOURCE_TYPE.ITEM) {
      // Load item
      if (isOKID(OKID)) {
        getItemByOKID(OKID);
      } else {
        getItemByAlternateID(OKID);
      }
    } else if (sourceType === SCAN_RESULTS_POPUP_SOURCE_TYPE.SITE) {
      // Load site
      getSiteByOKID(OKID);
    } else {
      // Try loading multiple types
      tryAll();
    }
  }, [OKID, apolloClient, getItemByAlternateID, getItemByOKID, getSiteByOKID, sourceType]);

  /* Render */
  const matchedMultipleItems = matchedItems?.length > 1;

  if (!item && matchedMultipleItems) {
    return <MultipleScanResultsPopup dismiss={dismiss} items={matchedItems} onSelectItem={setItem} />;
  }

  // Documentation
  let documentationSection;
  switch (documentationType) {
    case DOCUMENTATION_TYPE_FILES: {
      const documentList =
        sourceType === SCAN_RESULTS_POPUP_SOURCE_TYPE.SITE
          ? site?.siteDocumentAssetList
          : product?.productDocumentAssetList;
      documentationSection = documentList?.length ? (
        documentList.map((pda) => {
          return (
            <DocumentMinimalCard
              className={styles.productDocument}
              document={pda.documentAsset}
              key={pda.documentAsset.id}
              showDownloadButton
            />
          );
        })
      ) : (
        <TextLayout>
          <Text>{t('NO_FILES')}</Text>
        </TextLayout>
      );
      break;
    }
    case DOCUMENTATION_TYPE_NOTES: {
      const noteList =
        sourceType === SCAN_RESULTS_POPUP_SOURCE_TYPE.SITE ? site?.siteNoteAssetList : product?.productNoteAssetList;
      documentationSection = noteList?.length ? (
        <Carousel className={styles.productNotesCarousel}>
          {noteList.map((pna) => {
            return (
              <Slide className={styles.productNoteSlide} key={pna.noteAsset.id}>
                <RemarkLorealCard className={styles.productNote} remark={pna.noteAsset} />
              </Slide>
            );
          })}
        </Carousel>
      ) : (
        <TextLayout>
          <Text>{t('LOG_ARCHIVE_PAGE_NO_NOTES')}</Text>
        </TextLayout>
      );
      break;
    }
    case DOCUMENTATION_TYPE_MAP:
      documentationSection = (
        <div className={styles.mapComingSoon}>
          <Text>{t('COMING_SOON_CAPS')}</Text>
        </div>
      );
      break;
    default:
      documentationSection = (
        <TextLayout>
          <Text>{t('COMING_SOON')}</Text>
        </TextLayout>
      );
      break;
  }

  // Feedback form elements
  const phoneInput = (
    <label className={styles.phoneInputLabel}>
      <Text className={styles.label}>{t('YOUR_PHONE_OPTIONAL_LABEL', { returnAsHTML: true })}</Text>
      <PhoneInput
        country={phoneNumberCountry}
        number={phoneNumber}
        onChangeCountryCode={(newCountry) => setPhoneNumberCountry(newCountry)}
        onChangeNumber={(e) => setPhoneNumber(e.target.value)}
        validateInput
      />
    </label>
  );
  const emailInput = (
    <label>
      <Text className={styles.label}>{t('YOUR_EMAIL_OPTIONAL_LABEL', { returnAsHTML: true })}</Text>
      <Input onChange={(e) => setEmail(e.target.value)} placeholder={t('ENTER_EMAIL_ADDRESS')} value={email} />
    </label>
  );
  const mediaPicker = (
    <div className={styles.mediaPickerContainer}>
      <MediaPicker
        className={styles.mediaPicker}
        label={t('ADD_MEDIA')}
        mediaTypes={['photo', 'video', 'audio', 'document']}
      />
    </div>
  );

  return (
    <>
      {sourceType === SCAN_RESULTS_POPUP_SOURCE_TYPE.DOCUMENT ? (
        <AddWorkPopup data={OKID} dismiss={dismiss} />
      ) : sourceType === undefined ? (
        <></>
      ) : (
        <Popup dismiss={dismiss}>
          <PopupContent className={styles.popupContent}>
            {!loading ? (
              <>
                {product ? (
                  <ProductInfoPopupSection
                    actionButton={
                      item && (
                        <LinkToItem className={styles.detailedReportLink} item={item} withCaret>
                          {t('SEE_FULL_REPORT')}
                        </LinkToItem>
                      )
                    }
                    conformityPoints={product.conformityPoint}
                    linkToOrganisation
                    mediaAssetList={product.productMediaAssetList.map((pma) => pma.mediaAsset)}
                    name={ProductModel.localizedNameForProduct(product, locale)}
                    OKID={item?.OKID ?? OKID}
                    organisation={product.organisation}
                    reliabilityPoints={product.reliabilityPointForPublishedLogs}
                    SKUID={product.SKUID}
                  />
                ) : site ? (
                  <ProductInfoPopupSection
                    actionButton={
                      item && (
                        <LinkToItem className={styles.detailedReportLink} item={item} withCaret>
                          {t('SEE_FULL_REPORT')}
                        </LinkToItem>
                      )
                    }
                    conformityPoints={site.conformityPoint}
                    linkToOrganisation
                    mediaAssetList={site.siteMediaAssetList.map((sma) => sma.mediaAsset)}
                    name={SiteModel.localizedNameForSite(site, locale)}
                    OKID={OKID}
                    organisation={site.organisation}
                    reliabilityPoints={site.reliabilityPointForPublishedLogs}
                  />
                ) : (
                  <ContentLayout className={styles.noProductFoundContainer}>
                    <h3>{t('NO_PRODUCT_FOUND')}</h3>
                  </ContentLayout>
                )}
                {hasInspectionRights ? (
                  <CardLayout
                    innerClassName={styles.inspectionsSection}
                    fixedWidth={false}
                    padded={false}
                    rounded={false}
                  >
                    <TextLayout>
                      <h4>{t('INSPECTIONS_AVAILABLE')}</h4>
                    </TextLayout>
                    {availableInspectionsList?.length > 0 ? (
                      <div className={styles.availableInspections}>
                        <Carousel
                          className={styles.availableInspectionsCarousel}
                          innerClassName={styles.availableInspectionsCarouselInner}
                        >
                          {availableInspectionsList.map((pia) => {
                            return (
                              <Slide className={styles.availableInspectionSlide} key={pia.inspectionAsset.id}>
                                <InspectionArchiveCard
                                  actionIndicatorTint='creation'
                                  cardClassName={styles.availableInspectionCard}
                                  className={styles.availableInspection}
                                  fixedWidth={false}
                                  inspectionAsset={pia.inspectionAsset}
                                  linkToInspection={false}
                                  onClick={() => {
                                    if (currentUserWithInProgressInspectionsAPIResult.called) {
                                      startInspection(pia.inspectionAsset.id);
                                    }
                                  }}
                                  starting={startingInspectionAssetId === pia.inspectionAsset.id}
                                  style={{ opacity: currentUserWithInProgressInspectionsAPIResult.called ? 1 : 0.5 }}
                                />
                              </Slide>
                            );
                          })}
                        </Carousel>
                      </div>
                    ) : (
                      <TextLayout>
                        <Text style={{ margin: 0 }}>{t('NO_WORKFLOWS_AVAILABLE')}</Text>
                      </TextLayout>
                    )}
                  </CardLayout>
                ) : (
                  <ContentLayout
                    className={styles.gradingExplanationContainer}
                    contentClassName={styles.gradingExplanationContent}
                  >
                    <Accordion
                      contentClassName={styles.gradingAccordionContent}
                      onChangeOpen={(open) => setOpenGradingAccordion(open)}
                      open={openGradingAccordion}
                      title={t('HOW_GRADES_WORK')}
                      toggleButtonClassname={styles.gradingExplanationToggleButton}
                    >
                      <div className={styles.gradingExplanationIntroContainer}>
                        <div className={styles.gradingExplanationIntro}>
                          {tHTML('SCAN_RESULTS_GRADING_EXPLANATION_INTRO_HTML')}
                        </div>
                        {/* <VideoPlayer
                          className={styles.video}
                          cropToFillInPortraitFullscreen
                          simulateFullscreen={userAgent?.os?.name === 'iOS'}
                          source='https://oktrade.org/download/video/ok-tutorials-concept2.mp4'
                        /> */}
                      </div>
                      <div className={styles.gradeExplanations}>
                        <Text bold className={styles.gradingExplanationHeader}>
                          <Icon inline name={ICONS.RELIABILITY_GRADE.name} />
                          {t('RELIABILITY')}
                        </Text>
                        <Text className={styles.gradingExplanationParagraph} size='sm'>
                          {t('SCAN_RESULTS_GRADING_EXPLANATION_RELIABILITY')}
                        </Text>
                        <Text bold className={styles.gradingExplanationParagraph} size='xs'>
                          {t('SCAN_RESULTS_GRADING_EXPLANATION_RELIABILITY_FINEPRINT')}
                        </Text>
                        <Text bold className={styles.gradingExplanationHeader}>
                          <Icon inline name={ICONS.DOCUMENTATION_GRADE.name} />
                          {t('CONFORMITY')}
                        </Text>
                        <Text className={styles.gradingExplanationParagraph} size='sm'>
                          {t('SCAN_RESULTS_GRADING_EXPLANATION_CONFORMITY')}
                        </Text>
                        <Text bold className={styles.gradingExplanationHeader}>
                          <Icon inline name={ICONS.SUSTAINABILITY_GRADE.name} />
                          {t('SUSTAINABILITY')}
                        </Text>
                        <Text className={styles.gradingExplanationParagraph} size='sm'>
                          {t('SCAN_RESULTS_GRADING_EXPLANATION_SUSTAINABILITY')}
                        </Text>
                      </div>
                    </Accordion>
                  </ContentLayout>
                )}
                <div className={styles.documentationContainer}>
                  <TextLayout>
                    <h4>{t('PRODUCT_SECTION_DOCUMENTATION')}</h4>
                    <ButtonGroup className={styles.documentationTypeButtons}>
                      <button
                        active={documentationType === DOCUMENTATION_TYPE_MAP}
                        onClick={() => setDocumentationType(DOCUMENTATION_TYPE_MAP)}
                      >
                        <Icon name={ICONS.VISUAL_SUPPLY_CHAIN.name} />
                      </button>
                      <button
                        active={documentationType === DOCUMENTATION_TYPE_FILES}
                        onClick={() => setDocumentationType(DOCUMENTATION_TYPE_FILES)}
                      >
                        {t('FILES')}
                      </button>
                      <button
                        active={documentationType === DOCUMENTATION_TYPE_NOTES}
                        onClick={() => setDocumentationType(DOCUMENTATION_TYPE_NOTES)}
                      >
                        {t('NOTES')}
                      </button>
                    </ButtonGroup>
                  </TextLayout>
                  <div>{documentationSection}</div>
                </div>
                {!hasInspectionRights && (
                  <div className={styles.contactSection}>
                    <h4>{t('GET_IN_TOUCH')}</h4>
                    {tHTML('SCAN_FEEDBACK_INTRO_HTML')}
                    {useMobileLayout && mediaPicker}
                    <label>
                      <Text bold className={styles.label}>
                        {t('YOUR_FEEDBACK')}
                      </Text>
                      <TextArea
                        className={styles.feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder={t('ENTER_FEEDBACK')}
                        value={feedback}
                      />
                    </label>
                    {useMobileLayout ? (
                      <>
                        {phoneInput}
                        {emailInput}
                      </>
                    ) : (
                      <div className={styles.desktopContactAndMediaInputs}>
                        <div className={styles.contactInputs}>
                          {phoneInput}
                          {emailInput}
                        </div>
                        {mediaPicker}
                      </div>
                    )}
                    <Notice className={styles.feedbackNotice}>
                      <Text bold size='md'>
                        <label>
                          <Checkbox
                            className={styles.privacyPolicyCheckbox}
                            onChange={onChangePrivacyPolicyChecked}
                            value={acceptedPrivacyPolicy}
                          />
                          {t('I_ACCEPT_THE_{PRIVACY_POLICY}', {
                            data: {
                              link: (
                                <NextLink className={styles.privacyPolicyLink} href='/solutions/terms'>
                                  {t('PRIVACY_POLICY').toLowerCase()}
                                </NextLink>
                              ),
                            },
                            returnAsHTML: true,
                          })}
                        </label>
                      </Text>
                      <Text size='sm'>
                        {t('SCAN_FEEDBACK_FINEPRINT_LINE_1', {
                          data: {
                            organisationName:
                              sourceType === SCAN_RESULTS_POPUP_SOURCE_TYPE.SITE
                                ? site?.organisation.name
                                : product?.organisation.name,
                          },
                        })}
                      </Text>
                      <Text size='sm'>{t('SCAN_FEEDBACK_FINEPRINT_LINE_2')}</Text>
                    </Notice>
                    {/* {renderForBrowser && !authenticated && (
                      <HCaptcha
                        id='captcha'
                        onError={() => setCaptchaError('Please validate yourself again.')}
                        onExpire={setCaptchaToken}
                        onVerify={setCaptchaToken}
                        reCaptchaCompat={false}
                        ref={hCaptcha}
                        sitekey={config.hCaptchaSiteKey}
                        size='invisible'
                      />
                    )} */}
                    {captchaError && (
                      <Text className={styles.errorMessage} size='sm' tint='alert'>
                        {captchaError}
                      </Text>
                    )}
                    <Button block className={styles.feedbackButton} tint='creation' withCaret>
                      {t('SEND_FEEDBACK')}
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <ContentLayout>
                <Icon className={styles.loadingSpinner} height={40} name={ICONS.SPINNER.name} width={40} />
              </ContentLayout>
            )}
          </PopupContent>
          <PopupButtonsGroup>
            {matchedMultipleItems && <Button onClick={clearSelectedItem}>{t('BACK_TO_LIST')}</Button>}
            <PopupCloseButton tint='navigation'>{t('CLOSE')}</PopupCloseButton>
          </PopupButtonsGroup>
        </Popup>
      )}
      {showInProgressInspectionAlert && (
        <Alert
          title={t('WORKFLOW_IN_PROGRESS_ALERT_TITLE')}
          message={t('WORKFLOW_IN_PROGRESS_ALERT_MESSAGE')}
          buttons={
            <>
              <Button block onClick={resumeInspection} tint='navigation'>
                {t('WORKFLOW_IN_PROGRESS_ALERT_RESUME')}
              </Button>
              <Button block onClick={() => setShowInProgressInspectionAlert(false)}>
                {t('WORKFLOW_IN_PROGRESS_ALERT_BACK')}
              </Button>
            </>
          }
        />
      )}
    </>
  );
}

ScanResultsPopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
  OKID: PropTypes.string.isRequired,
  sourceType: PropTypes.oneOf([SCAN_RESULTS_POPUP_SOURCE_TYPE.ITEM, SCAN_RESULTS_POPUP_SOURCE_TYPE.SITE]),
};

/* Helpers */

const DOCUMENTATION_TYPE_FILES = 'files';
// eslint-disable-next-line no-unused-vars
const DOCUMENTATION_TYPE_LOGS = 'logs';
const DOCUMENTATION_TYPE_MAP = 'map';
const DOCUMENTATION_TYPE_NOTES = 'notes';
// eslint-disable-next-line no-unused-vars
const DOCUMENTATION_TYPE_PARTS = 'parts';
