import { gql } from '@apollo/client';

export default class BaseLabelModel {
  static GRAPHQL_TYPE = 'Label';

  static fragmentName = 'BaseLabel_Fragment';
  static fragment = gql`
    fragment ${BaseLabelModel.fragmentName} on ${BaseLabelModel.GRAPHQL_TYPE} {
      REFID
      createdBy
      createdDate
      id
      lastModifiedBy
      lastModifiedDate
      organisationId
      version
    }
  `;
}
