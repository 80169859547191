import { ICONS } from 'OK/components/icon';
import SiteModel from 'OK/models/site';

export function suggestionObjectForSite(site, locale) {

  return {
    icon: ICONS.PRODUCT.name,
    key: site?.id,
    title: SiteModel.localizedNameForSite(site, locale),
    subtitle: site.OKID,
  };
}
