import { gql } from '@apollo/client';

import { request } from '..';

import OrganisationModel from 'OK/models/organisation';
import BaseOrganisationModel from 'OK/models/organisation/base';
import OrganisationDocumentModel from 'OK/models/organisationDocument';
import ProductModel from 'OK/models/product';
import UserModel from 'OK/models/user';
import BaseUserModel from 'OK/models/user/base';
import { API_URL } from 'OK/networking/utils';

/* Queries */

export const getOrganisationByIdQuery = gql`
  query GetOrganisationById($organisationId: String!) {
    organisation: getOrganisation(organisationId: $organisationId) {
      ...${OrganisationModel.fragmentName}
    }
  }
  ${OrganisationModel.fragment}
`;

export const getOrganisationByIdManagerQuery = gql`
  query GetOrganisationById($organisationId: String!) {
    organisation: getOrganisation(organisationId: $organisationId) {
      ...${OrganisationModel.fragmentNameManager}
    }
  }
  ${OrganisationModel.fragmentManager}
`;

export const getOrganisationByOKIDQuery = gql`
  query GetOrganisationByOKID($OKID: String!) {
    organisation: getOrganisationWithOKID(OKID: $OKID) {
      ...${OrganisationModel.fragmentName}
    }
  }
  ${OrganisationModel.fragment}
`;

export const OrganisationArchivePageQuery = gql`
  query OrganisationArchivePage($OKID: String!) {
    organisation: getOrganisationWithOKID(OKID: $OKID) {
      id
      addressList {
        city 
        countryCode
        line1
        line2
        latitude
        longitude
        pinLocationLatitude
        pinLocationLongitude
        postalCode
        province
        addressImageURL

      }
      bio
      conformityPoint
      criticalInspected
      logoImageMediaAsset {
        id
        logoImageURL
      }
      majorInspected
      minorInspected
      name
      OKID
      productList {
        ...${ProductModel.fragmentNameArchiveCard}
      }
      publicEmail
      publicMobile
      publicMobileCountryCode
      reliabilityPointForPublishedLogs
      websiteURL
    }
  }
  ${ProductModel.fragmentArchiveCard}
`;

/* Mutations */

export const acceptUserJoinRequestMutation = gql`
  mutation AcceptUserJoinRequest($userId: String!) {
    organisation: acceptUserJoinRequest(userId: $userId) {
      id
      userJoinRequestList {
        ...${BaseUserModel.fragmentName}
      }
      userList {
        ...${BaseUserModel.fragmentName}
      }
    }
  }
  ${BaseUserModel.fragment}
`;

export const applyToJoinOrganisationMutation = gql`
  mutation ApplyToJoinOrganisation($organisationId: String!) {
    user: joinOrganisation(organisationId: $organisationId) {
      id
      joinRequestOrganisationList {
        ...${BaseOrganisationModel.fragmentName}
      }
    }
  }
  ${BaseOrganisationModel.fragment}
`;

export const cancelOrganisationUserInviteMutation = gql`
  mutation CancelOrganisationUserInvite($userId: String!) {
    organisation: cancelOrganisationUserInvite(userId: $userId) {
      id
      inviteUserList {
        ...${BaseUserModel.fragmentName}
      }
    }
  }
  ${BaseUserModel.fragment}
`;

export const createOrganisationMutationBasic = gql`
  mutation CreateOrganisation(
    $address: AddressInput
    $email: String
    $languageIso: ELanguageCode!
    $languageName: String!
    $logoMediaAssetId: String
    $name: String!
    $phoneNumber: String
    $phoneNumberCountryCode: String
    $timezone: String!
    $type: EOrganisationType!
    $website: String
  ) {
    organisation: createOrganisation(createOrganisationInput: {
      addressList: [$address]
      language: $languageName
      languageCode: $languageIso
      logoImageMediaAssetId: $logoMediaAssetId
      name: $name
      publicEmail: $email
      publicMobile: $phoneNumber
      publicMobileCountryCode: $phoneNumberCountryCode
      timezone: $timezone
      type: $type
      websiteURL: $website
    }) {
      ...${OrganisationModel.fragmentName}
    }
  }
  ${OrganisationModel.fragment}
`;

export const createOrganisationMutationTrial = gql`
  mutation CreateOrganisation(
    $address: AddressInput
    $email: String
    $languageIso: ELanguageCode!
    $languageName: String!
    $logoMediaAssetId: String
    $name: String!
    $phoneNumber: String
    $phoneNumberCountryCode: String
    $timezone: String!
    $website: String
  ) {
    organisation: createOrganisation(createOrganisationInput: {
      addressList: [$address]
      language: $languageName
      languageCode: $languageIso
      logoImageMediaAssetId: $logoMediaAssetId
      name: $name
      publicEmail: $email
      publicMobile: $phoneNumber
      publicMobileCountryCode: $phoneNumberCountryCode
      timezone: $timezone
      type: TRIAL
      websiteURL: $website
    }) {
      ...${OrganisationModel.fragmentName}
    }
  }
  ${OrganisationModel.fragment}
`;

export const inviteUserToOrganisationMutation = gql`
  mutation InviteUserToOrganisation($userId: String!) {
    organisation: inviteOrganisationUser(userId: $userId) {
      id
      inviteUserList {
        ...${BaseUserModel.fragmentName}
      }
    }
  }
  ${BaseUserModel.fragment}
`;

export const inviteUsersToOrganisationMutation = gql`
  mutation InviteUsersToOrganisation($invitations: [InviteUserInputItem]!) {
    organisation: inviteOrganisationUsers(inviteUserInput: {
      inviteUserInputItemList: $invitations
    }) {
      ...${OrganisationModel.fragmentName}
      inviteUserList {
        ...${UserModel.fragmentName}
      }
    }
  }
  ${OrganisationModel.fragment}
  ${UserModel.fragment}
`;

export const linkOrganisationDocumentAssetMutation = gql`
  mutation LinkOrganisationDocumentAsset($organisationId: String!, $documentAssetId: String!) {
    organisationDocument: linkOrganisationDocumentAsset(organisationId: $organisationId, documentAssetId: $documentAssetId) {

        assetLinkStatus
        documentAsset {
          id
          applicableMarketList
          coveredLanguageList
          documentName
          documentNumber
          documentStandardList
          documentType
          madeByOrganisation {
            ...${OrganisationModel.fragmentNameArchiveCard}
          }
          madeByOrganisationId
          REFID
          validFrom
          validTill
        }
        publishStatus
    }
  }
  ${OrganisationModel.fragmentArchiveCard}
`;

export const makeUserACoworkerMutation = gql`
  mutation MakeUserACoworker($userId: String!) {
    organisation: makeOrganisationManagerAUser(userId: $userId) {
      id
      managerList {
        ...${BaseUserModel.fragmentName}
      }
      userList {
        ...${BaseUserModel.fragmentName}
      }
      inviteUserList {
        ...${BaseUserModel.fragmentName}
      }
      inviteManagerList {
        ...${BaseUserModel.fragmentName}
      }
    }
  }
  ${BaseUserModel.fragment}
`;

export const makeUserAManagerMutation = gql`
  mutation MakeUserAManager($userId: String!) {
    organisation: makeOrganisationUserAManager(userId: $userId) {
      id
      managerList {
        ...${BaseUserModel.fragmentName}
      }
      userList {
        ...${BaseUserModel.fragmentName}
      }
      inviteUserList {
        ...${BaseUserModel.fragmentName}
      }
      inviteManagerList {
        ...${BaseUserModel.fragmentName}
      }
      
    }
  }
  ${BaseUserModel.fragment}
`;

export const makeUserAnOwnerMutation = gql`
  mutation MakeUserAnOwner($userId: String!) {
    organisation: makeOrganisationUserTheOwner(userId: $userId) {
      id
      managerList {
        ...${BaseUserModel.fragmentName}
      }
      userList {
        ...${BaseUserModel.fragmentName}
      }
    }
  }
  ${BaseUserModel.fragment}
`;

export const getOrganisationUserInviteeRoleListQuery = gql`
  query GetInviteeRoles {
    roles: getOrganisationUserInviteeRoleList
  }
`;

export const publishOrganisationDocumentAssetMutation = gql`
  mutation PublishOrganisationDocumentAsset($organisationId: String!, $documentAssetId: String!) {
    organisationDocument: publishOrganisationDocumentAsset(organisationId: $organisationId, documentAssetId: $documentAssetId) {
        assetLinkStatus
        documentAsset {
          id
          applicableMarketList
          coveredLanguageList
          documentName
          documentNumber
          documentStandardList
          documentType
          madeByOrganisation {
            ...${OrganisationModel.fragmentNameArchiveCard}
          }
          madeByOrganisationId
          REFID
          validFrom
          validTill
        }
        publishStatus
    }
  }
  ${OrganisationModel.fragmentArchiveCard}
  `;

export const setOrganisationUserInviteeRoleMutation = gql`
  mutation SetInviteeRole($userId: String!, $inviteeRole: OrganisationRole!) {
    setRole: setOrganisationUserInviteeRole(userId: $userId, inviteeRole: $inviteeRole) {
      id
      OKID
      name
      inviteUserList {
        id
        organisationList {
          id
        }
      }
      inviteManagerList {
        id
        organisationList {
          id
        }
      }
      userList {
        OKID
      }
      managerList {
        OKID
      }
      ownerList {
        OKID
      }
    }
  }
`;

export const removeOrganisationUserMutation = gql`
  mutation RemoveOrganisationUser($userId: String!) {
    organisation: removeOrganisationUser(userId: $userId) {
      id
      userList {
        ...${BaseUserModel.fragmentName}
      }
    }
  }
  ${BaseUserModel.fragment}
`;

export const rejectUserJoinRequestMutation = gql`
  mutation RejectUserJoinRequest($userId: String!) {
    organisation: rejectUserJoinRequest(userId: $userId) {
      id
      userJoinRequestList {
        ...${BaseUserModel.fragmentName}
      }
    }
  }
  ${BaseUserModel.fragment}
`;

export const setOrganisationAddressMutation = gql`
  mutation SetOrganisationAddress($address: AddressInput!) {
    organisation: setOrganisationAddress(address: $address) {
      id
      addressList {
        addressImageURL
        city
        countryCode
        line1
        line2
        latitude
        longitude
        pinLocationLatitude
        pinLocationLongitude
        postalCode
        province
      }
    }
  }
`;

export const setOrganisationEmailMutation = gql`
  mutation SetOrganisationEmail($email: String!) {
    organisation: setOrganisationEmail(email: $email) {
      id
      publicEmail
    }
  }
`;

export const setOrganisationMobileMutation = gql`
  mutation SetOrganisationMobile($mobile: String!, $mobileCountryCode: String!) {
    organisation: setOrganisationMobile(mobile: $mobile, mobileCountryCode: $mobileCountryCode) {
      id
      publicMobile
      publicMobileCountryCode
    }
  }
`;

export const setOrganisationNameMutation = gql`
  mutation SetOrganisationName($name: String!) {
    organisation: setOrganisationName(name: $name) {
      id
      name
    }
  }
`;

export const setOrganisationSubscriptionTypeMutation = gql`
  mutation SetOrganisationSubscriptionType($type: EOrganisationType!) {
    organisation: setOrganisationType(organisationType: $type) {
      id
      type
    }
  }
`;

export const setOrganisationTrialTypeMutation = gql`
  mutation SetOrganisationSubscriptionType($organisationType: EOrganisationType!) {
    organisation: setOrganisationType(organisationType: $organisationType) {
      id
      type
    }
  }
`;

export const setOrganisationWebsiteMutation = gql`
  mutation SetOrganisationWebsite($website: String!) {
    organisation: setOrganisationWebsite(website: $website) {
      id
      websiteURL
    }
  }
`;

export const unlinkOrganisationDocumentAssetMutation = gql`
  mutation UnlinkOrganisationDocumentAsset($organisationId: String!, $documentAssetId: String!) {
    organisationDocument: unlinkOrganisationDocumentAsset(organisationId: $organisationId, documentAssetId: $documentAssetId) {
        assetLinkStatus
        documentAsset {
          id
          applicableMarketList
          coveredLanguageList
          documentName
          documentNumber
          documentStandardList
          documentType
          madeByOrganisation {
            ...${OrganisationModel.fragmentNameArchiveCard}
          }
          madeByOrganisationId
          REFID
          validFrom
          validTill
        }
        publishStatus
    }
  }
  ${OrganisationModel.fragmentArchiveCard}
`;

export const unpublishOrganisationDocumentAssetMutation = gql`
  mutation UnpublishOrganisationDocumentAsset($organisationId: String!, $documentAssetId: String!) {
    organisationDocument: unpublishOrganisationDocumentAsset(organisationId: $organisationId, documentAssetId: $documentAssetId) {
        assetLinkStatus
        documentAsset {
          id
          applicableMarketList
          coveredLanguageList
          documentName
          documentNumber
          documentStandardList
          documentType
          madeByOrganisation {
            ...${OrganisationModel.fragmentNameArchiveCard}
          }
          madeByOrganisationId
          REFID
          validFrom
          validTill
        }
        publishStatus
    }
  }
  ${OrganisationModel.fragmentArchiveCard}
  `;

export async function createOrganisationDocumentAssetRequest(organisationId, file, documentType) {
  const metadata = {
    filesize: file.size,
  };
  const parameters = {
    documentFile: file,
    documentType,
    metadataString: JSON.stringify(metadata),
    organisationId,
  };

  return request(`${API_URL}/utility/document/createOrganisationDocumentAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}
