import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import Notice from 'OK/components/notice';
import RemarkLorealCard from 'OK/components/remark/lorealCard';
import Select from 'OK/components/select';
import Text from 'OK/components/text';
import useI18n from 'OK/util/hooks/useI18n';

export default function InspectionLogNotesSection(props) {
  /* Variables */

  const { className, isHighlightsLog, highlightsLogLink, inspectionLog } = props;
  const { t } = useI18n();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);

  /* State */

  const [filterNotesByStepId, setFilterNotesByStepId] = useState(null);

  /* Render */

  let classNames = styles.section;
  if (className) {
    classNames += ` ${className}`;
  }

  const visibleNotes = filterNotesByStepId
    ? inspectionLog.inspectionLogNoteAssetList.filter((n) => n.taggedInspectionLogTestAssetId === filterNotesByStepId)
    : inspectionLog.inspectionLogNoteAssetList;
  const linkableStepsForNotes = inspectionLog.inspectionLogTestAssetList
    .filter((t) => t.versionedTestAsset?.enableNotes || t.unversionedTestAsset?.enableNotes)
    .map((t) => ({ id: t.id, index: inspectionLog.inspectionLogTestAssetList.findIndex((s) => s.id === t.id) + 1 }));
  const hasNoteWithLinkedStep =
    inspectionLog.inspectionLogNoteAssetList.filter((n) => typeof n.taggedInspectionLogTestAssetId === 'string')
      .length > 0;

  const notesNotice = (
    <Notice className={styles.sectionNotice}>
      <Text bold>{t('LOG_ARCHIVE_PAGE_NOTES_NOTICE')}</Text>
    </Notice>
  );

  return (
    <ContentLayout className={classNames} pageContent>
      {useDesktopLayout && notesNotice}
      <TextLayout>
        <h3>{t('LOG_GO_PAGE_NOTES_SECTION_HEADER')}</h3>
        <Text className={styles.sectionDescription}>{t('LOG_ARCHIVE_PAGE_NOTES_SECTION_DESCRIPTION')}</Text>
        {hasNoteWithLinkedStep && (
          <Select
            className={styles.filterNotesSelect}
            noValueLabel={t('LOG_GO_PAGE_NOTES_SECTION_PLACEHOLDER_FILTER_NOTES')}
            onChange={setFilterNotesByStepId}
            options={linkableStepsForNotes.map((s) => {
              return {
                label: t('NOTE_CARD_STEP_SEARCH_RESULT', { data: { number: s.index } }),
                value: s.id,
              };
            })}
            value={filterNotesByStepId}
          />
        )}
      </TextLayout>
      {useMobileLayout && notesNotice}
      {visibleNotes.length ? (
        <>
          <Carousel className={styles.notesCarousel} fadeOutSides={useDesktopLayout}>
            {visibleNotes.map((n) => (
              /* eslint-disable indent */
              <Slide className={styles.noteSlide} key={n.id}>
                <RemarkLorealCard
                  className={styles.noteCard}
                  fixedWidth={false}
                  footerText={
                    n.taggedInspectionLogTestAssetId
                      ? t('NOTE_CARD_STEP_SEARCH_RESULT', {
                          data: {
                            number:
                              inspectionLog.inspectionLogTestAssetList.findIndex(
                                (t) => t.id === n.taggedInspectionLogTestAssetId
                              ) + 1,
                          },
                        })
                      : undefined
                  }
                  remark={n.unversionedNoteAsset}
                  showFullTextInitial={true}
                />
              </Slide>
              /* eslint-enable indent */
            ))}
          </Carousel>
          {isHighlightsLog && <a href={highlightsLogLink}><Button className={styles.highlightsLogButton} tint='navigation' withCaret>See logs for the day</Button></a>}
        </>
      ) : (
        <TextLayout>
          <Text>{t('LOG_ARCHIVE_PAGE_NO_NOTES')}</Text>
        </TextLayout>
      )}
    </ContentLayout>
  );
}

InspectionLogNotesSection.propTypes = {
  className: PropTypes.string,
  inspectionLog: PropTypes.object.isRequired,
  isHighlightsLog: PropTypes.bool,
  highlightsLogLink: PropTypes.string,
};
