import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import ButtonGroup from 'OK/components/buttonGroup';
import { Carousel, Slide } from 'OK/components/carousel';
import InlineIcon from 'OK/components/inlineIcon';
import SearchInput from 'OK/components/input/search';
import ContentLayout from 'OK/components/layouts/content';
import Notice from 'OK/components/notice';
import Progressable from 'OK/components/progressable';
import SiteArchiveCard from 'OK/components/site/archiveCard';
import Text from 'OK/components/text';
import SiteModel from 'OK/models/site';
import NewSitePopup from 'OK/modules/popups/newSite';
import { indexOfLinkedAssetInCacheList } from 'OK/networking/graphql/helpers';
import { searchQuery } from 'OK/networking/search';
import {
  usePublishPartAPI,
  useReorderPartBackwardAPI,
  useReorderPartForwardAPI,
  useSetPartQuantityAPI,
  useUnpublishPartAPI,
} from 'OK/networking/siteChildSite/hooks';
import {
  acceptParentSiteRequestMutation,
  rejectParentSiteRequestMutation,
  removeParentSiteMutation,
} from 'OK/networking/sites';
import { useLinkPartAPI, useRemovePartAPI } from 'OK/networking/sites/hooks';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import { formatOkid } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

const PART_FILTER_MODE = {
  SEARCH: 'SEARCH',
  FILTER_CURRENT: 'FILTER_CURRENT',
  FILTER_PARENTS: 'FILTER_PARENTS',
  FILTER_PARENT_REQEUSTS: 'FILTER_PARENT_REQEUSTS',
};

const stringMatchesSite = (matchString, site) => {
  const regex = new RegExp(matchString, 'i');
  const { OKID, identifier, name, organisation } = site;
  const formattedOKID = formatOkid(OKID);
  let nameMatches = false;
  Object.keys(name).forEach((languageIso) => {
    if (name[languageIso].text.match(regex)) {
      nameMatches = true;
    }
  });
  return (
    nameMatches ||
    formattedOKID.match(regex) ||
    OKID.match(regex) ||
    identifier.match(regex) ||
    organisation?.name.match(regex)
  );
};

export default function EditSitePagePartsSection(props) {
  /* Variables */

  const { editMode, site } = props;
  const apolloClient = useApolloClient();
  const parts = site.siteChildSiteList;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const theme = useContext(ThemeContext);
  const { t, tHTML } = useI18n();

  // State
  const [activePartsTab, setActivePartsTab] = useState(ACTIVE_PARTS_TAB.PARTS_FOR);
  const [partsError, setPartsError] = useState(null);
  const [partsErrors, setPartsErrors] = useState([]);
  const [partFilterMode, setPartFilterMode] = useState(PART_FILTER_MODE.SEARCH);
  const [searchTerm, setSearchTerm] = useState('');
  const [shouldShowNewSitePopup, setShouldShowNewSitePopup] = useState(false);

  // Refs

  const partsSliderRef = useRef();

  /* API */

  const [acceptPartOfRequestAPI] = useMutation(acceptParentSiteRequestMutation);
  const [linkPartAPI, linkPartAPIResult] = useLinkPartAPI();
  const publishPartAPI = usePublishPartAPI();
  const [rejectPartOfRequestAPI] = useMutation(rejectParentSiteRequestMutation);
  const removePartAPI = useRemovePartAPI();
  const [removeAsPartOfAPI] = useMutation(removeParentSiteMutation);
  const reorderPartBackwardAPI = useReorderPartBackwardAPI();
  const reorderPartForwardAPI = useReorderPartForwardAPI();
  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);
  const setPartQuantityAPI = useSetPartQuantityAPI();
  const unpublishPartAPI = useUnpublishPartAPI();

  /* Methods */

  const approveAsPartOf = useCallback(
    (parentSiteId) => {
      setPartsError(null);
      acceptPartOfRequestAPI({
        variables: {
          parentSiteId,
          siteId: site.id,
        },
      });
    },
    [site.id, acceptPartOfRequestAPI]
  );

  const linkPart = useCallback(
    (partSiteId) => {
      setPartsError(null);
      linkPartAPI(site.id, partSiteId)
        .then(() => {
          setTimeout(() => {
            partsSliderRef.current.scrollToSlideAtIndex(parts.length);
          }, 50);
        })
        .catch((e) => {
          okdebug(e);
          let errorMessage;
          if (e.message === 'PRODUCT_CHILD_PRODUCT_LINK_EXIST_IN_SUPPLY_CHAIN') {
            errorMessage = t('ERROR_UPSTREAM_PRODUCT_CANNOT_BE_PART');
          } else {
            errorMessage = t('ERROR_GENERIC');
          }
          setPartsError(errorMessage);
        });
    },
    [linkPartAPI, parts.length, site.id, t]
  );

  const rejectAsPartOf = useCallback(
    (parentSiteId) => {
      setPartsError(null);
      rejectPartOfRequestAPI({
        variables: {
          parentSiteId,
          siteId: site.id,
        },
      });
    },
    [site.id, rejectPartOfRequestAPI]
  );

  const removeAsPartOf = useCallback(
    (parentSiteId) => {
      setPartsError(null);
      removeAsPartOfAPI({
        variables: {
          parentSiteId,
          siteId: site.id,
        },
      });
    },
    [site.id, removeAsPartOfAPI]
  );

  const removeErrorForPart = useCallback(
    (partSiteId) => {
      const indexOfPartError = partsErrors.findIndex((e) => e.partSiteId === partSiteId);
      if (indexOfPartError > -1) {
        const updatedErrors = [...partsErrors];
        updatedErrors.splice(indexOfPartError);
        setPartsErrors(updatedErrors);
      }
    },
    [partsErrors]
  );

  const searchDebounced = useMemo(
    () =>
      debounce((searchString) => {
        setPartsError(null);
        searchAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'SITE',
                ignoreIdList: [site.id, ...parts.map((p) => p.childSite.id)],
              },
            ],
            searchPaginationDataByDataType: [
              {
                dataType: 'SITE',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [parts, site.id, searchAPI]
  );

  const setErrorForPart = useCallback(
    (partSiteId, message) => {
      let newError;
      const indexOfPartError = partsErrors.findIndex((e) => e.partSiteId === partSiteId);
      if (indexOfPartError > -1) {
        const error = partsErrors.splice(indexOfPartError)[0];
        newError = {
          ...error,
          message,
        };
      } else {
        newError = {
          partSiteId,
          message,
        };
      }
      const updatedErrors = [...partsErrors, newError];
      setPartsErrors(updatedErrors);
    },
    [partsErrors]
  );

  const setPartQuantity = useMemo(
    () =>
      debounce((partSiteId, quantity) => {
        setPartsError(null);
        setPartQuantityAPI(site.id, partSiteId, quantity).catch(() => {
          setErrorForPart(partSiteId, t('ERROR_GENERIC'));
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [site.id, setErrorForPart, setPartQuantityAPI, t]
  );

  const showNewSitePopup = useCallback(() => {
    setShouldShowNewSitePopup(true);
  }, []);

  // Events

  const onChangePartPublished = useCallback(
    (partSiteId, published) => {
      removeErrorForPart(partSiteId);
      setPartsError(null);
      if (published) {
        publishPartAPI(site.id, partSiteId).catch(() => {
          setErrorForPart(partSiteId, t('ERROR_GENERIC'));
        });
      } else {
        unpublishPartAPI(site.id, partSiteId).catch(() => {
          setErrorForPart(partSiteId, t('ERROR_GENERIC'));
        });
      }
    },
    [site.id, publishPartAPI, removeErrorForPart, setErrorForPart, t, unpublishPartAPI]
  );

  const onChangePartQuantity = useCallback(
    (partSiteId, quantity) => {
      removeErrorForPart(partSiteId);

      // Update cache
      apolloClient.cache.modify({
        id: `${SiteModel.GRAPHQL_TYPE}:${site.id}`,
        fields: {
          siteChildSiteList: (currentList, { readField }) => {
            const indexOfPart = indexOfLinkedAssetInCacheList(currentList, partSiteId, 'childSite', readField);
            const updatedPart = {
              ...currentList[indexOfPart],
              quantity,
            };
            const updatedList = [...currentList];
            updatedList.splice(indexOfPart, 1, updatedPart);
            return updatedList;
          },
        },
      });

      // Call API (debounced)
      setPartQuantity(partSiteId, quantity);
    },
    [apolloClient.cache, site.id, removeErrorForPart, setPartQuantity]
  );

  const onClickLinkPart = useCallback(
    (partSiteId) => {
      setSearchTerm('');
      linkPart(partSiteId);
    },
    [linkPart]
  );

  const onClickReorderPart = useCallback(
    (partSiteId, direction) => {
      removeErrorForPart(partSiteId);
      setPartsError(null);
      if (direction === 'left') {
        reorderPartBackwardAPI(site.id, partSiteId).catch(() => {
          setErrorForPart(partSiteId, t('ERROR_GENERIC'));
        });
      } else {
        reorderPartForwardAPI(site.id, partSiteId).catch(() => {
          setErrorForPart(partSiteId, t('ERROR_GENERIC'));
        });
      }
    },
    [site.id, removeErrorForPart, reorderPartBackwardAPI, reorderPartForwardAPI, setErrorForPart, t]
  );

  const onClickUnlinkPart = useCallback(
    (partSiteId) => {
      removeErrorForPart(partSiteId);
      setPartsError(null);
      removePartAPI(site.id, partSiteId).catch(() => {
        setErrorForPart(partSiteId, t('ERROR_GENERIC'));
      });
    },
    [site.id, removeErrorForPart, removePartAPI, setErrorForPart, t]
  );

  const onSearch = useCallback(
    (e) => {
      const searchString = e.target.value;
      setSearchTerm(searchString);

      if (partFilterMode === PART_FILTER_MODE.SEARCH && searchString.length > 1) {
        searchDebounced(searchString);
      }
    },
    [partFilterMode, searchDebounced]
  );

  /* Render */

  const partsNotice = (
    <Notice className={styles.partsNotice}>
      <p>
        <strong>Encourage suppliers to add parts to parts</strong> all the way back to the origin. A manager of the
        owning organisation will review and confirm the relationship each time, expanding your visual supply chain.
      </p>
      <p>
        {tHTML('PRODUCT_SECTION_PARTS_NOTICE_LINE_2', {
          data: {
            leftIcon: <InlineIcon flip src={`/icons/reorder_${theme.name}.svg`} />,
            rightIcon: <InlineIcon src={`/icons/reorder_${theme.name}.svg`} />,
          },
        })}
      </p>
    </Notice>
  );

  const partsResults = useMemo(() => {
    if (!searchTerm.length) {
      switch (partFilterMode) {
        case PART_FILTER_MODE.FILTER_PARENTS:
          return site.siteParentSiteList;
        case PART_FILTER_MODE.FILTER_PARENT_REQEUSTS:
          return site.sitePendingParentSiteRequestList;
        default:
          return parts;
      }
    }

    switch (partFilterMode) {
      case PART_FILTER_MODE.FILTER_CURRENT: {
        return parts.filter((p) => stringMatchesSite(searchTerm, p.childSite));
      }
      case PART_FILTER_MODE.FILTER_PARENTS: {
        return site.siteParentSiteList.filter((p) => stringMatchesSite(searchTerm, p));
      }
      case PART_FILTER_MODE.FILTER_PARENT_REQEUSTS: {
        return site.sitePendingParentSiteRequestList.filter((p) => stringMatchesSite(searchTerm, p));
      }
      default:
        return searchAPIResult.data?.search?.resultList.map((r) => r.siteData) ?? [];
    }
  }, [
    partFilterMode,
    parts,
    site.siteParentSiteList,
    site.sitePendingParentSiteRequestList,
    searchAPIResult.data?.search?.resultList,
    searchTerm,
  ]);

  const visibleParts = useMemo(() => {
    if (activePartsTab === ACTIVE_PARTS_TAB.PARTS_OF) {
      return site?.siteParentSiteList?.map((p) => p) ?? [];
    }

    return site?.siteChildSiteList?.map((part) => part.childSite) ?? [];
  }, [activePartsTab, site?.siteChildSiteList, site?.siteParentSiteList]);

  const partsDescription = useMemo(() => {
    if (visibleParts.length === 0) {
      if (activePartsTab === ACTIVE_PARTS_TAB.PARTS_OF) {
        return t('PRODUCT_PAGE_NOT_A_PART');
      }

      return t('PRODUCT_PAGE_HAS_NO_PARTS');
    }

    if (activePartsTab === ACTIVE_PARTS_TAB.PARTS_OF) {
      return t('PRODUCT_PAGE_PART_OF');
    }

    return t('PRODUCT_PAGE_HAS_PARTS');
  }, [activePartsTab, t, visibleParts.length]);
  return (
    <div>
      {editMode ? (
        <ContentLayout pageContent>
          <div style={{ paddingTop: 20 }}>
            {useDesktopLayout && partsNotice}
            <Text className={styles.sectionIntro}>{t('SITES_SECTION_PARTS_DESCRIPTION')}</Text>
            <ButtonGroup buttonStyle='separate' className={styles.partFilterButtons}>
              <button
                active={partFilterMode === PART_FILTER_MODE.SEARCH}
                onClick={() => setPartFilterMode(PART_FILTER_MODE.SEARCH)}
              >
                {t('SEARCH_FOR_NEW')}
              </button>
              <button
                active={partFilterMode === PART_FILTER_MODE.FILTER_CURRENT}
                onClick={() => setPartFilterMode(PART_FILTER_MODE.FILTER_CURRENT)}
              >
                {t('FILTER_CURRENT')}
              </button>
              <button
                active={partFilterMode === PART_FILTER_MODE.FILTER_PARENTS}
                onClick={() => setPartFilterMode(PART_FILTER_MODE.FILTER_PARENTS)}
              >
                {t('FILTER_PART_OF')}
              </button>
              <button
                active={partFilterMode === PART_FILTER_MODE.FILTER_PARENT_REQEUSTS}
                onClick={() => setPartFilterMode(PART_FILTER_MODE.FILTER_PARENT_REQEUSTS)}
              >
                {t('FILTER_PART_OF_REQUESTS')}
              </button>
            </ButtonGroup>
            <div className={styles.searchInputContainer}>
              <SearchInput
                accessoryButton={
                  partFilterMode === PART_FILTER_MODE.SEARCH || partFilterMode === PART_FILTER_MODE.FILTER_CURRENT ? (
                    <Button className={styles.searchNewButton} linkStyle onClick={showNewSitePopup} tint='creation'>
                      {t('NEW')}
                    </Button>
                  ) : null
                }
                className={styles.searchInput}
                loading={searchAPIResult.loading}
                onChange={onSearch}
                placeholder={t('SEARCH_PARTS_PLACEHOLDER')}
                value={searchTerm}
              />
            </div>
            {partsError && (
              <Text className={styles.errorMessage} size='sm' tint='alert'>
                {partsError}
              </Text>
            )}
          </div>
          {!useDesktopLayout && partsNotice}
          <Progressable
            inProgress={linkPartAPIResult.loading || rejectPartOfRequestAPI.loading}
            style={{ minHeight: linkPartAPIResult.loading ? '100px' : undefined }}
          >
            <div>
              <Carousel
                className={styles.carouselContainer}
                fadeOutSides={useDesktopLayout}
                innerClassName={styles.carousel}
                ref={partsSliderRef}
              >
                {/* eslint-disable indent */}
                {(partFilterMode === PART_FILTER_MODE.SEARCH && searchTerm.length === 0) ||
                partFilterMode === PART_FILTER_MODE.FILTER_CURRENT
                  ? partsResults.map((part, index) => {
                      return (
                        <Slide className={styles.partSlide} key={part.childSite.id}>
                          <SiteArchiveCard
                            allowReorderLeft={index !== 0}
                            allowReorderRight={index !== parts.length - 1}
                            cardClassName={styles.partCard}
                            className={styles.part}
                            fixedWidth={useDesktopLayout}
                            linkPublished={part.publishStatus === PUBLISH_STATUS.PUBLISHED}
                            managerOptionsError={partsErrors.find((e) => e.partSiteId === part.childSite.id)?.message}
                            onChangePublished={(newPublished) => onChangePartPublished(part.childSite.id, newPublished)}
                            onChangeQuantity={(newQuantity) => onChangePartQuantity(part.childSite.id, newQuantity)}
                            onClickReorder={(direction) => onClickReorderPart(part.childSite.id, direction)}
                            onClickUnlink={() => onClickUnlinkPart(part.childSite.id)}
                            site={part.childSite}
                            showPending={part.status === 'PENDING'}
                            showPublishOption={part.status === 'ACTIVE'}
                            showUnlinkOption
                            quantity={part.quantity}
                          />
                        </Slide>
                      );
                    })
                  : partsResults.map((p) => {
                      okdebug('partsResults p', p);
                      return (
                        <Slide className={styles.partSlide} key={p.id}>
                          <SiteArchiveCard
                            cardClassName={styles.partCard}
                            className={styles.part}
                            fixedWidth={useDesktopLayout}
                            onClickApprove={() => approveAsPartOf(p.id)}
                            onClickLink={() => onClickLinkPart(p.id)}
                            onClickReject={() => rejectAsPartOf(p.id)}
                            onClickUnlink={() => removeAsPartOf(p.id)}
                            site={p}
                            showApproveButton={partFilterMode === PART_FILTER_MODE.FILTER_PARENT_REQEUSTS}
                            showLinkButton={partFilterMode === PART_FILTER_MODE.SEARCH}
                            showRejectButton={partFilterMode === PART_FILTER_MODE.FILTER_PARENT_REQEUSTS}
                            showUnlinkOption={partFilterMode === PART_FILTER_MODE.FILTER_PARENTS}
                          />
                        </Slide>
                      );
                    })}
                {/* eslint-enable indent */}
                {partFilterMode === PART_FILTER_MODE.SEARCH && (
                  <Slide className={styles.partSlide}>
                    <button className={`${styles.addSlideButton} ${styles.addPartButton}`} onClick={showNewSitePopup}>
                      <img alt='' src='/icons/upload_big.svg' /> {t('ADD_NEW_PART')}
                    </button>
                  </Slide>
                )}
              </Carousel>
            </div>
          </Progressable>
          {shouldShowNewSitePopup && (
            <NewSitePopup
              dismiss={() => setShouldShowNewSitePopup(false)}
              onCreateSite={(newSite) => {
                linkPart(newSite.id);
              }}
            />
          )}
        </ContentLayout>
      ) : (
        <ContentLayout pageContent>
          <div style={{ paddingTop: 30 }}>
            <ButtonGroup className={styles.buttonGroup}>
              <button
                active={activePartsTab === ACTIVE_PARTS_TAB.PARTS_FOR}
                onClick={() => setActivePartsTab(ACTIVE_PARTS_TAB.PARTS_FOR)}
              >
                {t('PARTS_FOR_THIS')}
              </button>
              <button
                active={activePartsTab === ACTIVE_PARTS_TAB.PARTS_OF}
                onClick={() => setActivePartsTab(ACTIVE_PARTS_TAB.PARTS_OF)}
              >
                {t('PART_OF')}
              </button>
            </ButtonGroup>
            <Text className={styles.partsDescription}>{partsDescription}</Text>
            <Carousel
              className={styles.carouselContainer}
              fadeOutSides={useDesktopLayout}
              innerClassName={styles.carousel}
              ref={partsSliderRef}
            >
              {visibleParts.map((p, index) => {
                return (
                  <Slide
                    className={`${styles.partSlide} ${index !== visibleParts.length - 1 ? '' : styles.partSlideLast}`}
                    key={p?.id}
                  >
                    <SiteArchiveCard
                      cardClassName={styles.partCard}
                      className={styles.part}
                      linkToEditPage={false}
                      site={p}
                      fixedWidth={useDesktopLayout}
                    />
                  </Slide>
                );
              })}
            </Carousel>
          </div>
        </ContentLayout>
      )}
    </div>
  );
}

EditSitePagePartsSection.propTypes = {
  editMode: PropTypes.bool,
  site: PropTypes.object.isRequired,
};

// Helpers

const ACTIVE_PARTS_TAB = {
  PARTS_FOR: 'PARTS_FOR',
  PARTS_OF: 'PARTS_OF',
};
