import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { indexOfLinkedAssetInCacheList } from '../graphql/helpers';
import {
  publishPartMutation,
  reorderPartBackwardMutation,
  reorderPartForwardMutation,
  setPartQuantityMutation,
  unpublishPartMutation,
} from '../sites';

import SiteModel from 'OK/models/site';
import SiteChildSiteModel from 'OK/models/siteChildSite';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';

export function usePublishPartAPI() {
  const [publishPartAPI] = useMutation(publishPartMutation);

  const publishPart = useCallback(
    (siteId, partSiteId) => {
      const optimisticResponse = {
        part: {
          publishStatus: PUBLISH_STATUS.PUBLISHED,
          __typename: SiteChildSiteModel.GRAPHQL_TYPE,
        },
      };
      return publishPartAPI({
        variables: {
          siteId,
          partSiteId,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
            fields: {
              siteChildSiteList: (currentList, { readField }) => {
                const indexOfPart = indexOfLinkedAssetInCacheList(currentList, partSiteId, 'childSite', readField);
                const updatedPart = {
                  ...currentList[indexOfPart],
                  publishStatus: result.data.part.publishStatus,
                };
                const updatedList = [...currentList];
                updatedList.splice(indexOfPart, 1, updatedPart);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [publishPartAPI]
  );

  return publishPart;
}

export function useReorderPartBackwardAPI() {
  const apolloClient = useApolloClient();
  const [reorderPartBackwardAPI] = useMutation(reorderPartBackwardMutation);

  const reorderPartBackward = useCallback(
    (siteId, partSiteId) => {
      const site = apolloClient.readFragment({
        id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
        fragment: SiteModel.fragmentPartsList,
        fragmentName: SiteModel.fragmentNamePartsList,
      });
      const part = site.siteChildSiteList.find((p) => p.childSite.id === partSiteId);
      return reorderPartBackwardAPI({
        variables: {
          siteId,
          partSiteId,
        },
        optimisticResponse: {
          part: {
            childSite: {
              id: partSiteId,
              __typename: SiteModel.GRAPHQL_TYPE,
            },
            order: part.order,
            __typename: SiteChildSiteModel.GRAPHQL_TYPE,
          },
        },
        update: (cache, result) => {
          cache.modify({
            id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
            fields: {
              siteChildSiteList: (currentList, { readField }) => {
                const indexOfMovedPart = indexOfLinkedAssetInCacheList(currentList, partSiteId, 'childSite', readField);
                const indexOfBeforePart = indexOfMovedPart - 1;
                const updatedList = [...currentList];
                const [beforePart, movedPart] = updatedList.splice(indexOfBeforePart, 2);
                const updatedBeforePart = {
                  ...beforePart,
                  order: result.data.part.order + 1,
                };
                const updatedMovedPart = {
                  ...movedPart,
                  order: result.data.part.order,
                };
                updatedList.splice(indexOfBeforePart, 0, updatedMovedPart, updatedBeforePart);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, reorderPartBackwardAPI]
  );

  return reorderPartBackward;
}

export function useReorderPartForwardAPI() {
  const apolloClient = useApolloClient();
  const [reorderPartForwardAPI] = useMutation(reorderPartForwardMutation);

  const reorderPartForward = useCallback(
    (siteId, partSiteId) => {
      const site = apolloClient.readFragment({
        id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
        fragment: SiteModel.fragmentPartsList,
        fragmentName: SiteModel.fragmentNamePartsList,
      });
      const part = site.siteChildSiteList.find((p) => p.childSite.id === partSiteId);
      return reorderPartForwardAPI({
        variables: {
          siteId,
          partSiteId,
        },
        optimisticResponse: {
          part: {
            childSite: {
              id: partSiteId,
              __typename: SiteModel.GRAPHQL_TYPE,
            },
            order: part.order,
            __typename: SiteChildSiteModel.GRAPHQL_TYPE,
          },
        },
        update: (cache, result) => {
          cache.modify({
            id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
            fields: {
              siteChildSiteList: (currentList, { readField }) => {
                const indexOfMovedPart = indexOfLinkedAssetInCacheList(currentList, partSiteId, 'childSite', readField);
                const updatedList = [...currentList];
                const [movedPart, afterPart] = updatedList.splice(indexOfMovedPart, 2);
                const updatedMovedPart = {
                  ...movedPart,
                  order: result.data.part.order,
                };
                const updatedAfterPart = {
                  ...afterPart,
                  order: result.data.part.order - 1,
                };
                updatedList.splice(indexOfMovedPart, 0, updatedAfterPart, updatedMovedPart);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, reorderPartForwardAPI]
  );

  return reorderPartForward;
}

export function useSetPartQuantityAPI() {
  const [setPartQuantityAPI] = useMutation(setPartQuantityMutation);

  const setPartQuantity = useCallback(
    (siteId, partSiteId, quantity) => {
      const optimisticResponse = {
        part: {
          quantity,
          __typename: SiteChildSiteModel.GRAPHQL_TYPE,
        },
      };
      return setPartQuantityAPI({
        variables: {
          siteId,
          partSiteId,
          quantity,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
            fields: {
              siteChildSiteList: (currentList, { readField }) => {
                const indexOfPart = indexOfLinkedAssetInCacheList(currentList, partSiteId, 'childSite', readField);
                const updatedPart = {
                  ...currentList[indexOfPart],
                  quantity: result.data.part.quantity,
                };
                const updatedList = [...currentList];
                updatedList.splice(indexOfPart, 1, updatedPart);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [setPartQuantityAPI]
  );

  return setPartQuantity;
}

export function useUnpublishPartAPI() {
  const [unpublishPartAPI] = useMutation(unpublishPartMutation);

  const unpublishPart = useCallback(
    (siteId, partSiteId) => {
      const optimisticResponse = {
        part: {
          publishStatus: PUBLISH_STATUS.UNPUBLISHED,
          __typename: SiteChildSiteModel.GRAPHQL_TYPE,
        },
      };
      return unpublishPartAPI({
        variables: {
          siteId,
          partSiteId,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
            fields: {
              siteChildSiteList: (currentList, { readField }) => {
                const indexOfPart = indexOfLinkedAssetInCacheList(currentList, partSiteId, 'childSite', readField);
                const updatedPart = {
                  ...currentList[indexOfPart],
                  publishStatus: result.data.part.publishStatus,
                };
                const updatedList = [...currentList];
                updatedList.splice(indexOfPart, 1, updatedPart);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [unpublishPartAPI]
  );

  return unpublishPart;
}
