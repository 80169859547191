import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import { Carousel, Slide } from 'OK/components/carousel';
import InspectionLogArchiveCard from 'OK/components/inspectionLog/archiveCard';
import ContentLayout from 'OK/components/layouts/content';
import Progressable from 'OK/components/progressable';
import Text from 'OK/components/text';
import useI18n from 'OK/util/hooks/useI18n';

export default function EditSitePageWorkSection(props) {
  const { latestSiteInspectionLogsResult } = props;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const { t } = useI18n();

  const latestInspectionLogs = useMemo(() => {
    // Reverse logs because API returns them in date ascending order
    const logs = latestSiteInspectionLogsResult.data?.inspectionLogs ?? [];
    return [...logs].reverse();
  }, [latestSiteInspectionLogsResult.data]);

  return (
    <div>
      <ContentLayout style={{ paddingTop: 30 }} pageContent>
        <div className={styles.subSection}>
          <h4>{t('RECENT_LOGS')}</h4>
          <Text className={styles.sectionDescription}>
            {latestInspectionLogs.length > 0
              ? t('SITE_ARCHIVE_PAGE_RECENT_LOGS_DESCRIPTION')
              : t('NO_RECENT_WORK_LOGS')}
          </Text>

          <Progressable
            inProgress={latestSiteInspectionLogsResult.loading}
            style={{ minHeight: latestSiteInspectionLogsResult.loading ? 200 : 0 }}
          >
            {latestInspectionLogs.length > 0 && (
              <Carousel
                className={styles.recentInspectionsCarousel}
                innerClassName={styles.recentInspectionsCarouselInner}
                fadeOutSides={useDesktopLayout}
              >
                {latestInspectionLogs.map((log) => {
                  return (
                    <Slide key={log.id} className={styles.recentInspectionLogSlide}>
                      <InspectionLogArchiveCard
                        cardClassName={styles.recentInspectionLogCardInner}
                        className={styles.recentInspectionLogCard}
                        fixedWidth={useDesktopLayout}
                        inspectionLog={log}
                        showLinkToProduct={false}
                      />
                    </Slide>
                  );
                })}
              </Carousel>
            )}
          </Progressable>
        </div>
      </ContentLayout>
    </div>
  );
}

EditSitePageWorkSection.propTypes = {
  editMode: PropTypes.bool,
  latestSiteInspectionLogsResult: PropTypes.array,
};
