import PropTypes from 'prop-types';
import { useId, useRef } from 'react';

import BaseIcon from 'OK/components/icon/base';

export default function GamesIcon(props) {
  /* Variables */

  const { color, ...otherProps } = props;
  const uniqueId = useId();
  const clipPathId = `clip-path-${uniqueId}`;
  const iconRef = useRef();

  /* Render */

  return (
    <BaseIcon ref={iconRef} {...otherProps}>
      <defs>
        <clipPath id={`${clipPathId}`}>
          <rect id='SVGID' width='16' height='16' transform='translate(42 17)' fill={color} />
        </clipPath>
      </defs>
      <g transform='translate(-42 -17)' clipPath={`url(#${clipPathId})`}>
        <g>
          <path
            id='Path_11408'
            data-name='Path 11408'
            d='M50,20V17a7.979,7.979,0,0,0-6.126,2.859L46.189,21.8A4.969,4.969,0,0,1,50,20Z'
            fill={color}
            opacity='0.2'
          />
          <path
            id='Path_11409'
            data-name='Path 11409'
            d='M45,25a4.937,4.937,0,0,1,.267-1.538l-2.873-.933a7.948,7.948,0,0,0,.828,6.706l2.55-1.593A4.957,4.957,0,0,1,45,25Z'
            fill={color}
            opacity='0.2'
          />
          <path
            id='Path_11410'
            data-name='Path 11410'
            d='M50,30a4.962,4.962,0,0,1-2.93-.967L45.3,31.466a7.9,7.9,0,0,0,6.632,1.289l-.728-2.919A4.948,4.948,0,0,1,50,30Z'
            fill={color}
          />
          <path
            id='Path_11411'
            data-name='Path 11411'
            d='M54.733,23.462l2.873-.933A8.015,8.015,0,0,0,53,17.585L51.87,20.371A5,5,0,0,1,54.733,23.462Z'
            fill={color}
          />
          <path
            id='Path_11412'
            data-name='Path 11412'
            d='M54.965,25.347a4.975,4.975,0,0,1-2.035,3.685L54.7,31.466a7.98,7.98,0,0,0,3.274-5.909Z'
            fill={color}
          />
        </g>
      </g>
    </BaseIcon>
  );
}

GamesIcon.propTypes = {
  color: PropTypes.string,
};
