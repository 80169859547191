import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
  ArchiveCardTitle,
} from 'OK/components/archiveCard';
import { ICONS } from 'OK/components/icon';
import Link from 'OK/components/link';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import { formatNumber } from 'OK/util/formatting';
import { DocumentType } from 'OK/util/hooks/useDocumentType';
import useI18n from 'OK/util/hooks/useI18n';

export default function DocumentArchiveCard(props) {
  const {
    cardClassName,
    className,
    documentAsset,
    filesMessage = '',
    includeFiles = false,
    linkToArchivePage = true,
    ...otherProps
  } = props;
  const { conformityPoint, coveredLanguageList, linkedProductList, REFID } = documentAsset;
  const { t } = useI18n();
  const dispatch = useDispatch();

  const languageTags = useMemo(() => {
    const moreThan4Languages = coveredLanguageList.length > 4;
    const languages = moreThan4Languages ? coveredLanguageList.slice(0, 4) : coveredLanguageList;
    const tags = languages.map((languageIso) => (
      <Tag className={styles.tag} key={languageIso}>
        {languageIso}
      </Tag>
    ));
    if (moreThan4Languages) {
      const numberOfAdditionalLanguages = coveredLanguageList.length - 4;
      return (
        <>
          {tags}
          <Text className={styles.tagMoreLabel}>+ {numberOfAdditionalLanguages}</Text>
        </>
      );
    }

    return tags;
  }, [coveredLanguageList]);

  const linksMessage = useMemo(() => {
    const numberOfLinks = linkedProductList?.length;
    if (typeof numberOfLinks === 'undefined') {
      return null;
    }
    if (numberOfLinks === 0) {
      return t('ARCHIVE_CARD_0_LINKS');
    }
    if (numberOfLinks === 1) {
      return t('ARCHIVE_CARD_1_LINK');
    }
    return t('ARCHIVE_CARD_X_LINKS', { data: { number: formatNumber(numberOfLinks) } });
  }, [linkedProductList?.length, t]);

  const Container = linkToArchivePage ? Link : 'div';
  const containerProps = { className };
  if (linkToArchivePage) {
    containerProps.href = `/archive?refId=${REFID}&dataType=FILE`;
    containerProps.onClick = (e) => {
      e.preventDefault();
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'FILE', OKID: null },
        null,
        `/archive?refId=${REFID}&dataType=FILE`
      );
      dispatch(setBrowserHistoryAction(history));
    };
  }

  return (
    <Container {...containerProps}>
      <ArchiveCard className={cardClassName} {...otherProps}>
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery
            placeholderIconName={ICONS.DOCUMENT.name}
            documentAsset={documentAsset}
            refId={REFID}
          />
          <ArchiveCardMainContent className={styles.mainContent}>
            <ArchiveCardHeader type={t('DOCUMENT_ARCHIVE_CARD_TITLE')} />
            <ArchiveCardTitle className={styles.title}>
              <DocumentType documentAsset={documentAsset} fullType />
            </ArchiveCardTitle>
            <Text bold className={styles.tagRow} size='xs'>
              {languageTags}
            </Text>
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow conformityGrade={conformityPoint}>
            {includeFiles ? filesMessage : linksMessage}
          </ArchiveCardFooterRow>
          <ArchiveCardFooterRow />
        </ArchiveCardFooter>
      </ArchiveCard>
    </Container>
  );
}

DocumentArchiveCard.propTypes = {
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  documentAsset: PropTypes.object.isRequired,
  filesMessage: PropTypes.string,
  includeFiles: PropTypes.bool,
  linkToArchivePage: PropTypes.bool,
};
