import { useApolloClient, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Accordion from 'OK/components/accordion';
import { Carousel, Slide } from 'OK/components/carousel';
import Document from 'OK/components/document';
import DocumentViewCard from 'OK/components/document/viewCard';
import Icon, { ICONS } from 'OK/components/icon';
import ContentLayout from 'OK/components/layouts/content';
import MediaPicker from 'OK/components/mediaPicker';
import Notice from 'OK/components/notice';
import Progressable from 'OK/components/progressable';
import Text from 'OK/components/text';
import UserRecognition from 'OK/components/userRecognition';
import appConfig from 'OK/config/app';
import BaseUserModel from 'OK/models/user/base';
import UserDocumentModel from 'OK/models/userDocument/base';
import EditFilePopup from 'OK/modules/popups/file/edit';
import {
  usePublishUserDocumentAsOrganisationManagerAPI,
  useUnlinkUserDocumentAPI,
  useUnpublishUserDocumentAsOrganisationManagerAPI,
} from 'OK/networking/userDocuments/hooks';
import { createUserDocumentAssetRequest, getUserDocumentAssetListByOKIDQuery } from 'OK/networking/users';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import { unformatOkid } from 'OK/util/formatting';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';
import usePermission, { PERMISSIONS } from 'OK/util/hooks/usePermission';

export default function EditUserPublicPageDocumentationSection(props) {
  /* Variables */

  const { user, OKID } = props;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const [, , currentUser] = useAuthentication(() => false);
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const allowManageUserCertificates = usePermission(PERMISSIONS.USER_CERTIFICATE, currentUser, activeOrganisationId);
  const apolloClient = useApolloClient();
  const { t, tHTML } = useI18n();
  const userId = user?.id;

  const getUserResult = useQuery(getUserDocumentAssetListByOKIDQuery, {
    fetchPolicy: 'cache-and-network',
    context: {
      addActiveOrganisationIdHeader: true,
    },
    skip: !OKID,
    variables: {
      OKID: OKID ? unformatOkid(OKID) : null,
    },
  });

  const userDocuments = getUserResult.data?.user?.userDocumentAssetList;
  const badges = user?.userBadgeList ?? [];

  // Refs

  const documentsSliderRef = useRef();
  const mediaPickerRef = useRef();

  // State

  const [createDocumentError, setCreateDocumentError] = useState(null);
  const [isUploadingNewDocument, setIsUploadingNewDocument] = useState(false);
  const [newDocumentId, setNewDocumentId] = useState(null);
  const [userDocumentsErrors, setUserDocumentsErrors] = useState([]);
  const [documentationAccordionOpen, setDocumentationAccordionOpen] = useState(true);
  const [documentationAccordionEdit, setDocumentationAccordionEdit] = useState(false);

  /* API */

  const userCacheId = `${BaseUserModel.GRAPHQL_TYPE}:${userId}`;

  const publishUserDocumentAPI = usePublishUserDocumentAsOrganisationManagerAPI();
  const unlinkUserDocumentAPI = useUnlinkUserDocumentAPI();
  const unpublishUserDocumentAPI = useUnpublishUserDocumentAsOrganisationManagerAPI();

  /* Methods */

  const addErrorForDocument = (documentId, errorKey, errorMessage) => {
    const indexOfErrorsForDocument = userDocumentsErrors.findIndex((pde) => pde.documentId === documentId);
    let errorsForDocument;
    let updatedUserDocumentsErrors;
    if (indexOfErrorsForDocument > -1) {
      errorsForDocument = userDocumentsErrors[indexOfErrorsForDocument];
      errorsForDocument[errorKey] = errorMessage;
      updatedUserDocumentsErrors = [...userDocumentsErrors];
      updatedUserDocumentsErrors.splice(indexOfErrorsForDocument, 1, errorsForDocument);
    } else {
      errorsForDocument = {
        documentId,
        [errorKey]: errorMessage,
      };
      updatedUserDocumentsErrors = [...userDocumentsErrors, errorsForDocument];
    }

    setUserDocumentsErrors(updatedUserDocumentsErrors);
  };

  const closeEditFilePopup = useCallback(() => {
    setNewDocumentId(null);
  }, []);

  const createDocument = async (files) => {
    if (files.length > 0) {
      const file = files[0];
      setIsUploadingNewDocument(true);
      setCreateDocumentError(null);
      try {
        const response = await createUserDocumentAssetRequest(file, 'USER_FILE');
        if (response.success) {
          // Create DocumentAsset reference in cache
          const documentAsset = {
            ...response.responseData,
            documentStandardList: response.responseData.userDocumentStandardList,
            __typename: UserDocumentModel.GRAPHQL_TYPE,
          };
          const documentAssetRef = apolloClient.cache.writeFragment({
            id: apolloClient.cache.identify(documentAsset),
            fragment: UserDocumentModel.fragment,
            fragmentName: UserDocumentModel.fragmentName,
            data: documentAsset,
          });

          // Link new document to user in the cache
          apolloClient.cache.modify({
            id: userCacheId,
            fields: {
              userDocumentAssetList: (currentList = []) => {
                return [...currentList, documentAssetRef];
              },
            },
          });

          // Scroll to new document in slider
          setTimeout(() => {
            documentsSliderRef.current.scrollToSlideAtIndex(userDocuments.length);
          }, 50);

          setNewDocumentId(documentAsset.id);
        } else {
          okerror('Error creating document.');
          setCreateDocumentError(t(response.error ?? 'ERROR_GENERIC'));
        }
      } catch (e) {
        okerror('Error creating document', e);
        setCreateDocumentError(t('ERROR_GENERIC'));
      } finally {
        setIsUploadingNewDocument(false);
      }
    }
  };

  const openFilePicker = () => {
    mediaPickerRef.current.open();
  };

  const publishUserDocument = (documentId) => {
    // Clear publish related errors
    removeErrorForDocument(documentId, 'publishError');

    // Call API
    publishUserDocumentAPI(user, documentId).catch(() => {
      addErrorForDocument(documentId, 'publishError', t('UPDATE_FAILED_CONNECTION'));
    });
  };

  const removeErrorForDocument = (documentId, errorKey) => {
    const indexOfErrorsForDocument = userDocumentsErrors.findIndex((pde) => pde.documentId === documentId);
    if (indexOfErrorsForDocument > -1) {
      const errorsForDocument = {
        ...userDocumentsErrors[indexOfErrorsForDocument],
      };
      delete errorsForDocument[errorKey];
      const updatedUserDocumentsErrors = [...userDocumentsErrors];
      updatedUserDocumentsErrors.splice(indexOfErrorsForDocument, 1, errorsForDocument);
      setUserDocumentsErrors(updatedUserDocumentsErrors);
    }
  };

  const unlinkDocument = (documentId) => {
    // Clear unlink related errors
    removeErrorForDocument(documentId, 'unlinkError');

    // Call API
    unlinkUserDocumentAPI(user, documentId).catch(() => {
      addErrorForDocument(documentId, 'unlinkError', t('UPDATE_FAILED_CONNECTION'));
    });
  };

  const unpublishUserDocument = (documentId) => {
    // Clear publish related errors
    removeErrorForDocument(documentId, 'publishError');

    // Call API
    unpublishUserDocumentAPI(user, documentId).catch(() => {
      addErrorForDocument(documentId, 'publishError', t('UPDATE_FAILED_CONNECTION'));
    });
  };

  // Event handlers

  const onChangeUserDocumentPublished = (documentId, published) => {
    if (published) {
      publishUserDocument(documentId);
    } else {
      unpublishUserDocument(documentId);
    }
  };

  /* Render */

  const documentsNotice = (
    <Notice className={styles.sectionNotice}>
      <p>{tHTML('SUPPORTED_DOCUMENT_FORMATS')}</p>
      <Text tint='notification'>
        {t('DOCUMENT_MAX_FILESIZE', { data: { number: appConfig.fileSizeLimitsMB.document } })}
      </Text>
    </Notice>
  );

  return (
    <Accordion
      className={`${styles.accordion} ${documentationAccordionEdit && styles.edit}`}
      fixedWidth={false}
      onChangeOpen={setDocumentationAccordionOpen}
      onChangeEdit={setDocumentationAccordionEdit}
      headerClassName={styles.headerAccordion}
      hideSection
      open={documentationAccordionOpen}
      edit={documentationAccordionEdit}
      showEdit={allowManageUserCertificates}
      title={
        <div className={styles.header}>
          <Icon className={styles.icon} name={ICONS.DOCUMENTATION.name} height={24} width={24} />
          &nbsp;&nbsp;
          {t('PRODUCT_SECTION_DOCUMENTATION')}
        </div>
      }
      toggleButtonClassname={styles.toggleButton}
    >
      <ContentLayout className={styles.container} pageContent>
        <h3>{t('ARCHIVE_PAGE_SECTION_DETAILS')}</h3>
        <Text className={styles.sectionDescription}>{t('USER_ARCHIVE_PAGE_SECTION_DESCRIPTION_DETAILS')}</Text>
        <h4>{t('USER_ARCHIVE_PAGE_SECTION_RECOGNITIONS_HEADER')}</h4>
        {badges.length > 0 ? (
          badges.map((b) => <UserRecognition key={b.userBadge} openPopupOnClick recognition={b} />)
        ) : (
          <Text style={{ margin: 0 }}>{t('USER_ARCHIVE_PAGE_SECTION_RECOGNITIONS_NONE_MESSAGE')}</Text>
        )}

        <>
          <h4 className={styles.subSectionHeader}>
            {t('ACCOUNT_SECTION_CERTIFICATES')} <span className={styles.normalWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
          </h4>
          {useDesktopLayout && documentsNotice}
          <div className={styles.sectionIntro}>
            <p>{t('ACCOUNT_SECTION_CERTIFICATES_DESCRIPTION')}</p>
            <div className={styles.searchInputContainer}>
              <MediaPicker invisible mediaTypes={['document']} onChange={createDocument} ref={mediaPickerRef} />
            </div>
            {createDocumentError && (
              <Text className={styles.errorMessage} size='sm' tint='alert'>
                {createDocumentError}
              </Text>
            )}
          </div>

          {!useDesktopLayout && <div className={styles.notice}>{documentsNotice}</div>}
          <Progressable
            // inProgress={uploadingDocument || linkDocumentResult.loading || unlinkDocumentResult.loading}
            style={{ minHeight: '100px' }}
          >
            {documentationAccordionEdit ? (
              <Carousel
                className={styles.carouselContainer}
                innerClassName={styles.carousel}
                fadeOutSides={useDesktopLayout}
                ref={documentsSliderRef}
                snapToCenter
                snapToSlides={false}
                visibilityDivider={3}
              >
                {userDocuments?.map((pd) => {
                  const errorsForDocument = userDocumentsErrors.find((pde) => pde.documentId === pd.id);
                  return (
                    <Slide className={styles.documentSlide} key={pd.id}>
                      <Document
                        className={styles.document}
                        document={pd}
                        fixedWidth={false}
                        onClickUnlink={unlinkDocument}
                        onChangePublished={onChangeUserDocumentPublished}
                        publicView
                        publishError={errorsForDocument?.publishError}
                        published={pd.organisationPublishStatus === PUBLISH_STATUS.PUBLISHED}
                        unlinkButtonTitle={t('REMOVE')}
                        unlinkError={errorsForDocument?.unlinkError}
                        userId={currentUser?.id}
                        userMode
                      />
                    </Slide>
                  );
                })}

                <Slide className={styles.documentSlide}>
                  <button className={styles.addSlideButton} disabled={isUploadingNewDocument} onClick={openFilePicker}>
                    <Icon className={styles.addSlideIcon} height={80} name={ICONS.PLUS_BIG_CIRCLE.name} width={80} />{' '}
                    {t('ADD_NEW_DOCUMENTATION')}
                  </button>
                </Slide>
              </Carousel>
            ) : userDocuments?.length > 0 ? (
              <Carousel
                className={styles.carouselContainer}
                innerClassName={styles.carousel}
                fadeOutSides={useDesktopLayout}
                ref={documentsSliderRef}
                snapToCenter
                snapToSlides={false}
                visibilityDivider={3}
              >
                {userDocuments?.map((file) => (
                  <Slide className={styles.documentSlide} key={file.id}>
                    <DocumentViewCard className={styles.documentCard} fixedWidth={false} file={file} />
                  </Slide>
                ))}
              </Carousel>
            ) : (
              t('NO_FILES')
            )}
          </Progressable>
        </>

        {typeof newDocumentId === 'string' && (
          <EditFilePopup userMode dismiss={closeEditFilePopup} fileId={newDocumentId} />
        )}
      </ContentLayout>
    </Accordion>
  );
}

EditUserPublicPageDocumentationSection.propTypes = {
  user: PropTypes.object,
  OKID: PropTypes.string,
};
