import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentUserQuery } from 'OK/networking/users';
import authorisationForResource from 'OK/util/functions/authorisationForResource';
import useAuthentication from 'OK/util/hooks/useAuthentication';

/**
 * React hook which returns the current user's authorisation level for a given resource.
 *
 * @param {object} resource The data model to check authorisation level for.
 *
 * @returns {'USER'|'COWORKER'|'MANAGER'}
 */
export default function useAuthorisationLevel(resource) {
  const [authenticated] = useAuthentication(() => false);
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const getUserAPIResult = useQuery(getCurrentUserQuery, { skip: !authenticated });
  const user = getUserAPIResult.data?.user;

  const authorisationLevel = useMemo(() => {
    return authorisationForResource(user, activeOrganisationId, resource);
  }, [activeOrganisationId, resource, user]);

  return authorisationLevel;
}
