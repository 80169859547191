import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import Text from 'OK/components/text';
import { setUserPermitsCookies } from 'OK/state/app/actions';
import useI18n from 'OK/util/hooks/useI18n';

export default function CookieAlert() {
  const dispatch = useDispatch();
  const { t, tHTML } = useI18n();

  const acceptCookies = useCallback(() => {
    dispatch(setUserPermitsCookies('1'));
  }, [dispatch]);
  const rejectCookies = useCallback(() => {
    dispatch(setUserPermitsCookies('0'));
  }, [dispatch]);
  // const viewSettings = useCallback(() => {}, []);

  return (
    <Alert
      title={t('COOKIE_ALERT_TITLE')}
      message={
        <>
          <Text className={styles.explanation}>{t('COOKIE_ALERT_EXPLANATION')}</Text>
          <Text bold size='xs'>
            {tHTML('COOKIE_ALERT_CHANGE_PREFERENCES', {
              data: {
                icon: <Icon className={styles.inlineCogIcon} inline name={ICONS.COG.name} />,
              },
            })}
          </Text>
        </>
      }
      buttons={
        <div className={styles.buttonContainer}>
          <Button block onClick={acceptCookies} tint='creation'>
            {t('COOKIE_ALERT_ACCEPT')}
          </Button>
          <Button block className={styles.rejectButton} onClick={rejectCookies} tint='alert'>
            {t('COOKIE_ALERT_REJECT')}
          </Button>
          {/* <Button block linkStyle onClick={viewSettings}>
            Settings and policy
          </Button> */}
          <img alt='Mouse carrying a crossaint.' className={styles.mouse} src='/img/mouse_with_crossaint.png' />
        </div>
      }
    />
  );
}
