import { gql } from '@apollo/client';

import InspectionAssetModel from 'OK/models/inspectionAsset';
import MediaAssetModel, {
  AudioMediaAssetModel,
  ImageMediaAssetModel,
  VideoMediaAssetModel,
} from 'OK/models/mediaAsset';
import OrganisationModel from 'OK/models/organisation';
import ProductModel from 'OK/models/product';
import ProductChildProductModel from 'OK/models/productChildProduct';
import ProductDocumentModel from 'OK/models/productDocument';
import ProductInspectionAssetModel from 'OK/models/productInspection';
import ProductMediaAssetModel from 'OK/models/productMediaAsset';
import ProductNoteModel from 'OK/models/productNote';
import { request } from 'OK/networking';
import { API_URL } from 'OK/networking/utils';
import getAudioMetadataFromFile from 'OK/util/functions/getAudioMetadataFromFile';
import getImageDimensionsFromFile from 'OK/util/functions/getImageDimensionsFromFile';
import getVideoMetadataFromFile from 'OK/util/functions/getVideoMetadataFromFile';

/* Queries */

export const productInfoPopupQuery = gql`
  query ProductInfoPopupQuery($id: String!) {
    product(productId: $id) {
      id
      conformityPoint
      name
      organisation {
        addressList {
          countryCode
        }
        conformityPoint
        name
        OKID
        reliabilityPointForPublishedLogs
      }
      productChildProductList {
        childProduct {
          ...${ProductModel.fragmentNameArchiveCard}
        }
      }
      productDocumentAssetList {
        documentAsset {
          id
          coveredLanguageList
          documentName
          documentType
          documentURL
          REFID
        }
      }
      productMediaAssetList {
        mediaAsset {
          imageData {
            imageURL
          }
          mediaType
        }
      }
      productNoteAssetList {
        noteAsset {
          id
          textContentMap
        }
      }
      reliabilityPointForPublishedLogs
      SKUID
    }
  }
  ${ProductModel.fragmentArchiveCard}
`;

export const getProductByRefIdQuery = gql`
  query ProductEditPageQuery($refId: String!) {
    product: productWithREFID(REFID: $refId) {
      id
      assetAccessPermission
      conformityPoint
      name
      organisationId
      assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      productChildProductList {
        childProduct {
          ...${ProductModel.fragmentNameArchiveCard}
        }
        publishStatus
        quantity
        assetLinkStatus
      }
      productParentProductList {
        parentProduct {
          ...${ProductModel.fragmentNameArchiveCard}
        }
        publishStatus
        quantity
        assetLinkStatus
      }
      productDocumentAssetList {
        documentAsset {
          id
          applicableMarketList
          coveredLanguageList
          documentName
          documentNumber
          documentStandardList
          documentType
          madeByOrganisation {
            ...${OrganisationModel.fragmentNameArchiveCard}
          }
          madeByOrganisationId
          REFID
          validFrom
          validTill
        }
        publishStatus
      }
      productInspectionAssetList {
        inspectionAsset {
          ...${InspectionAssetModel.fragmentNameEditCard}
        }
        order
        publishStatus
      }
      productMediaAssetList {
        mediaAsset {
          ...${MediaAssetModel.fragmentNameMediaGallery}
        }
        order
        publishStatus
      }
      productNoteAssetList {
        noteAsset {
          id
          REFID
          textContentMap
        }
        publishStatus
      }
      publishStatus
      REFID
      reliabilityPointForPublishedLogs
      SKUID
    }
  }
  ${InspectionAssetModel.fragmentEditCard}
  ${MediaAssetModel.fragmentMediaGallery}
  ${ProductModel.fragmentArchiveCard}
  ${OrganisationModel.fragmentArchiveCard}
`;

export const getProductSearchQuery = gql`
query getProductSearch($refId: String!) {
  product: productWithREFID(REFID: $refId) {
    conformityPoint
          id
          name
          REFID
          reliabilityPointForPublishedLogs
          SKUID
          organisation {
            addressList {
              city
              countryCode
              line1
              line2
              postalCode
              province
            }
            conformityPoint
            id
            name
            reliabilityPointForPublishedLogs
          }
          productChildProductList {
            status
          }
          productMediaAssetList {
            mediaAsset {
              audioData {
                ...${AudioMediaAssetModel.fragmentName}
              }
              id
              imageData {
                ...${ImageMediaAssetModel.fragmentName}
              }
              mediaType
              videoData {
                ...${VideoMediaAssetModel.fragmentName}
              }
            }
          }
  }
}

  ${AudioMediaAssetModel.fragment}
  ${ImageMediaAssetModel.fragment}
  ${VideoMediaAssetModel.fragment}`;

export const getProductByRefIdPublicQuery = gql`
  query GetProductByRefId($refId: String!) {
    product: productWithREFID(REFID: $refId) {
      ...${ProductModel.fragmentNameFull}
    }
  }
  ${ProductModel.fragmentFull}
`;

/* Mutations */

export const acceptParentProductRequestMutation = gql`
  mutation AcceptParentProductRequest($productId: String!, $parentProductId: String!) {
    product: acceptParentProductRequest(productId: $productId, parentProductId: $parentProductId) {
      id
      productParentProductList {
        parentProduct {
          ...${ProductModel.fragmentNameArchiveCard}
        }
        order
        publishStatus
        quantity
        assetLinkStatus
      }
    }
  }
  ${ProductModel.fragmentArchiveCard}
`;

export const addPartMutation = gql`
  mutation AddPart($productId: String!, $partProductId: String!, $partType: EPartType!) {
    part: addChildProduct(childProductId: $partProductId, productId: $productId, partType: $partType) {
      ...${ProductChildProductModel.fragmentName}
    }
  }
  ${ProductChildProductModel.fragment}
`;

export const createProductMutation = gql`
  mutation CreateProduct($sku: String!) {
    product: createProduct(SKUID: $sku) {
      ...${ProductModel.fragmentName}
    }
  }
  ${ProductModel.fragment}
`;

export const createProductInspectionMutation = gql`
  mutation CreateProductInspection($productId: String!) {
    productInspectionAsset: createProductInspectionAsset(productId: $productId) {
      inspectionAsset {
        ...${InspectionAssetModel.fragmentNameEditCard}
      }
      order
      publishStatus
    }
  }
  ${InspectionAssetModel.fragmentEditCard}
`;

export const createProductAlternateIDMutation = gql`
  mutation CreateProductAlternateID($alternateId: String!, $productId: String!) {
    createProductAlternateId(alternateId: $alternateId, productId: $productId) {
      id
      alternateIds
    }
  }
`;

export const createProductNoteMutation = gql`
  mutation CreateProductNote($productId: String!, $published: EPublishStatus!, $text: [LanguageTextInput!]!) {
    productNote: createProductNoteAsset(languageTextInputList: $text, productId: $productId, publishStatus: $published) {
      ...${ProductNoteModel.fragmentName}
    }
  }
  ${ProductNoteModel.fragment}
`;

export const linkProductDocumentMutation = gql`
  mutation LinkProductDocument($documentId: String!, $productId: String!) {
    productDocument: linkProductDocumentAsset(documentAssetId: $documentId, productId: $productId) {
      ...${ProductDocumentModel.fragmentName}
    }
  }
  ${ProductDocumentModel.fragment}
`;

export const linkProductInspectionMutation = gql`
  mutation LinkProductInspection($productId: String!, $inspectionAssetId: String!) {
    productInspectionAsset: linkProductInspectionAsset(productId: $productId, inspectionAssetId: $inspectionAssetId) {
      ...${ProductInspectionAssetModel.fragmentName}
    }
  }
  ${ProductInspectionAssetModel.fragment}
`;

export const linkProductMediaMutation = gql`
  mutation LinkProductMedia($mediaAssetId: String!, $productId: String!) {
    productMedia: linkProductMediaAsset(mediaAssetId: $mediaAssetId, productId: $productId) {
      ...${ProductMediaAssetModel.fragmentName}
    }
  }
  ${ProductMediaAssetModel.fragment}
`;

export const linkProductNoteMutation = gql`
  mutation ($productId: String!, $noteAssetId: String!) {
    productNote: linkProductNoteAsset(productId: $productId, noteAssetId: $noteAssetId) {
      ...${ProductNoteModel.fragmentName}
    }
  }
  ${ProductNoteModel.fragment}
`;

export const publishPartMutation = gql`
  mutation PublishPart($productId: String!, $partProductId: String!) {
    part: publishChildProduct(productId: $productId, childProductId: $partProductId) {
      publishStatus
    }
  }
`;

export const publishProductMutation = gql`
  mutation PublishProduct($productId: String!) {
    product: publishProduct(productId: $productId) {
      id
      publishStatus
    }
  }
`;

export const publishProductDocumentMutation = gql`
  mutation PublishProductDocument($documentId: String!, $productId: String!) {
    productDocument: publishProductDocumentAsset(documentAssetId: $documentId, productId: $productId) {
      ...${ProductDocumentModel.fragmentName}
    }
  }
  ${ProductDocumentModel.fragment}
`;

export const publishProductInspectionMutation = gql`
  mutation PublishProductInspection($productId: String!, $inspectionAssetId: String!) {
    productInspection: publishProductInspectionAsset(productId: $productId, inspectionAssetId: $inspectionAssetId) {
      inspectionAsset {
        id
      }
      order
      publishStatus
    }
  }
`;

export const publishProductNoteMutation = gql`
  mutation PublishProductNote($noteId: String!, $productId: String!) {
    productNote: publishProductNoteAsset(noteAssetId: $noteId, productId: $productId) {
      ...${ProductNoteModel.fragmentName}
    }
  }
  ${ProductNoteModel.fragment}
`;

export const rejectParentProductRequestMutation = gql`
  mutation RejectParentProductRequest($productId: String!, $parentProductId: String!) {
    product: rejectParentProductRequest(productId: $productId, parentProductId: $parentProductId) {
      id
    }
  }
  ${ProductModel.fragmentArchiveCard}
`;

export const removeParentProductMutation = gql`
  mutation RemoveParentProduct($parentProductId: String, $productId: String!) {
    product: removeParentProduct(parentProductId: $parentProductId, productId: $productId) {
      id
      productParentProductList {
        parentProduct {
          ...${ProductModel.fragmentNameArchiveCard}
        }
        order
        publishStatus
        quantity
        assetLinkStatus
      }
    }
  }
  ${ProductModel.fragmentArchiveCard}
`;

export const removeProductAlternateIDMutation = gql`
  mutation RemoveProductAlternateID($alternateId: String!, $productId: String!) {
    removeProductAlternateId(alternateId: $alternateId, productId: $productId) {
      id
      alternateIds
    }
  }
`;

export const reorderPartBackwardMutation = gql`
  mutation ReorderPartBackward($productId: String!, $partProductId: String!) {
    part: decreaseOrderChildProduct(productId: $productId, childProductId: $partProductId) {
      childProduct {
        id
      }
      order
    }
  }
`;

export const reorderPartForwardMutation = gql`
  mutation ReorderPartForward($productId: String!, $partProductId: String!) {
    part: increaseOrderChildProduct(productId: $productId, childProductId: $partProductId) {
      childProduct {
        id
      }
      order
    }
  }
`;

export const reorderProductInspectionBackwardMutation = gql`
  mutation ReorderProductInspectionBackward($productId: String!, $inspectionAssetId: String!) {
    productInspection: decreaseOrderProductInspectionAsset(productId: $productId, inspectionAssetId: $inspectionAssetId) {
      ...${ProductInspectionAssetModel.fragmentName}
    }
  }
  ${ProductInspectionAssetModel.fragment}
`;

export const reorderProductInspectionForwardMutation = gql`
  mutation ReorderProductInspectionForward($productId: String!, $inspectionAssetId: String!) {
    productInspection: increaseOrderProductInspectionAsset(productId: $productId, inspectionAssetId: $inspectionAssetId) {
      ...${ProductInspectionAssetModel.fragmentName}
    }
  }
  ${ProductInspectionAssetModel.fragment}
`;

export const reorderProductMediaBackwardMutation = gql`
  mutation ReorderProductMediaBackward($mediaAssetId: String!, $productId: String!) {
    productMedia: decreaseOrderProductMediaAsset(mediaAssetId: $mediaAssetId, productId: $productId) {
      ...${ProductMediaAssetModel.fragmentName}
    }
  }
  ${ProductMediaAssetModel.fragment}
`;

export const reorderProductMediaForwardMutation = gql`
  mutation ReorderProductMediaForward($mediaAssetId: String!, $productId: String!) {
    productMedia: increaseOrderProductMediaAsset(mediaAssetId: $mediaAssetId, productId: $productId) {
      ...${ProductMediaAssetModel.fragmentName}
    }
  }
  ${ProductMediaAssetModel.fragment}
`;

export const removePartMutation = gql`
  mutation RemovePart($productId: String!, $partProductId: String!) {
    part: removeChildProduct(childProductId: $partProductId, productId: $productId) {
      ...${ProductChildProductModel.fragmentName}
    }
  }
  ${ProductChildProductModel.fragment}
`;

export const removeProductNameForLanguageMutation = gql`
  mutation RemoveProductNameForLanguage($productId: String!, $languageIso: ELanguageCode!) {
    product: removeProductName(languageCode: $languageIso, productId: $productId) {
      id
      name
    }
  }
`;

export const setPartQuantityMutation = gql`
  mutation SetPartQuantity($productId: String!, $partProductId: String!, $quantity: Int!) {
    part: setChildProductQuantity(productId: $productId, childProductId: $partProductId, quantity: $quantity) {
      quantity
    }
  }
`;

export const setProductAlternateIdAsPrimaryMutation = gql`
  mutation SetProductAlternateIdAsPrimary($alternateId: String!, $productId: String!) {
    product: setProductAlternateIdAsPrimary(alternateId: $alternateId, productId: $productId) {
      id
      alternateIds
    }
  }
`;

export const setProductNameForLanguageMutation = gql`
  mutation SetProductNameForLanguage($productId: String!, $languageIso: ELanguageCode!, $name: String!) {
    product: setProductName(languageCode: $languageIso, languageText: $name, productId: $productId) {
      id
      name
    }
  }
`;

export const setProductSKUMutation = gql`
  mutation SetProductSKU($productId: String!, $SKUID: String!) {
    product: setProductSKUID(productId: $productId, SKUID: $SKUID) {
      id
      SKUID
    }
  }
`;

export const unlinkProductDocumentMutation = gql`
  mutation UnlinkProductDocument($documentId: String!, $productId: String!) {
    productDocument: unlinkProductDocumentAsset(documentAssetId: $documentId, productId: $productId) {
      ...${ProductDocumentModel.fragmentName}
    }
  }
  ${ProductDocumentModel.fragment}
`;

export const unlinkProductInspectionMutation = gql`
  mutation UnlinkProductInspection($productId: String!, $inspectionAssetId: String!) {
    productInspection: unlinkProductInspectionAsset(productId: $productId, inspectionAssetId: $inspectionAssetId) {
      ...${ProductInspectionAssetModel.fragmentName}
    }
  }
  ${ProductInspectionAssetModel.fragment}
`;

export const unlinkProductMediaMutation = gql`
  mutation UnlinkProductMedia($mediaAssetId: String!, $productId: String!) {
    productMedia: unlinkProductMediaAsset(mediaAssetId: $mediaAssetId, productId: $productId) {
      ...${ProductMediaAssetModel.fragmentName}
    }
  }
  ${ProductMediaAssetModel.fragment}
`;

export const unlinkProductNoteMutation = gql`
  mutation UnlinkProductNote($noteId: String!, $productId: String!) {
    productNote: unlinkProductNoteAsset(noteAssetId: $noteId, productId: $productId) {
      noteAsset {
        id
      }
    }
  }
`;

export const unpublishPartMutation = gql`
  mutation UnpublishPart($productId: String!, $partProductId: String!) {
    part: unpublishChildProduct(productId: $productId, childProductId: $partProductId) {
      publishStatus
    }
  }
`;

export const unpublishProductMutation = gql`
  mutation UnpublishProduct($productId: String!) {
    product: unpublishProduct(productId: $productId) {
      id
      publishStatus
    }
  }
`;

export const unpublishProductDocumentMutation = gql`
  mutation UnpublishProductDocument($documentId: String!, $productId: String!) {
    productDocument: unpublishProductDocumentAsset(documentAssetId: $documentId, productId: $productId) {
      ...${ProductDocumentModel.fragmentName}
    }
  }
  ${ProductDocumentModel.fragment}
`;

export const unpublishProductInspectionMutation = gql`
  mutation UnpublishProductInspection($productId: String!, $inspectionAssetId: String!) {
    productInspection: unpublishProductInspectionAsset(productId: $productId, inspectionAssetId: $inspectionAssetId) {
      inspectionAsset {
        id
      }
      order
      publishStatus
    }
  }
`;

export const unpublishProductNoteMutation = gql`
  mutation UnpublishProductNote($noteId: String!, $productId: String!) {
    productNote: unpublishProductNoteAsset(noteAssetId: $noteId, productId: $productId) {
      ...${ProductNoteModel.fragmentName}
    }
  }
  ${ProductNoteModel.fragment}
`;

/* Requests */

/**
 * Create a new document asset and link it to a product.
 *
 * @param {string} productId
 * @param {File} file
 * @param {'DRAWING'|'MANUAL'|'PRODUCT_SHEET'} documentType
 */
export async function createProductDocumentAssetRequest(productId, file, documentType) {
  const metadata = {
    filesize: file.size,
  };
  const parameters = {
    documentFile: file,
    documentType,
    metadataString: JSON.stringify(metadata),
    productId,
  };

  return request(`${API_URL}/utility/document/createProductDocumentAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createDocumentAssetRequest(file, documentType) {
  const metadata = {
    filesize: file.size,
  };
  const parameters = {
    documentFile: file,
    documentType,
    metadataString: JSON.stringify(metadata),
  };

  return request(`${API_URL}/utility/document/createDocumentAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createProductAudioMediaAssetRequest(productId, audioFile) {
  const { duration } = await getAudioMetadataFromFile(audioFile);
  const metadata = {
    duration,
    filesize: audioFile.size,
    mimeType: audioFile.type,
  };
  const parameters = {
    productId,
    audio: audioFile,
    metadataString: JSON.stringify(metadata),
  };
  return request(`${API_URL}/utility/media/createProductAudioMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createProductImageMediaAssetRequest(productId, imageFile, originalImageFile) {
  const { height, width } = await getImageDimensionsFromFile(imageFile);
  const metadata = {
    filesize: imageFile.size,
    height,
    mimeType: imageFile.type,
    width,
  };
  const parameters = {
    productId,
    image: imageFile,
    baseImage: originalImageFile,
    metadataString: JSON.stringify(metadata),
  };

  return request(`${API_URL}/utility/media/createProductImageMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createProductVideoMediaAssetRequest(productId, videoFile) {
  const { duration, height, width } = await getVideoMetadataFromFile(videoFile);
  const metadata = {
    duration,
    filesize: videoFile.size,
    height,
    mimeType: videoFile.type,
    width,
  };
  const parameters = {
    productId,
    video: videoFile,
    metadataString: JSON.stringify(metadata),
  };
  return request(`${API_URL}/utility/media/createProductVideoMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

/* Types */

export const PRODUCT_TYPE = 'Product';

/* Status codes */

export const SKU_NOT_UNIQUE_ERROR = 'SKUID_NOT_UNIQUE';
