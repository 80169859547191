import captureVideoFrame from 'capture-video-frame';
import PropTypes from 'prop-types';
import { forwardRef, useMemo, useRef } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import VideoPlayer from 'OK/components/videoPlayer';
import { getDataURLFromFile } from 'OK/util/functions';

/**
 * Save video.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.saveButtonText] The text for the save button. Default will be "Set video".
 * @param {object} props.media The MediaAsset object.
 * @param {() => void} props.onCancel The cancel event handler.
 * @param {(coverPhoto: Blob)) => void} props.onSave The save event handler.
 */
const VideoEditor = forwardRef((props, forwardedRef) => {
  /* Variables */

  const { className, file, onCancel, onSave, saveButtonText, videoUrl: _videoUrl, ...otherProps } = props;
  const innerRef = useRef(null);
  const containerRef = forwardedRef || innerRef;
  const videoPlayerRef = useRef(null);
  const videoUrl = useMemo(() => {
    if (file) {
      return getDataURLFromFile(file);
    }

    return _videoUrl;
  }, [_videoUrl, file]);

  /* State */

  /* Methods */

  const confirmSave = async () => {
    try {
      if (onSave) {
        const frame = captureVideoFrame(videoPlayerRef.current.id, 'png');
        onSave(frame.blob);
      }
    } catch (e) {
      okerror('Error saving video.', e);
    }
  };

  /* Effects */

  /* Render */

  let classNames = styles.container;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  const _saveButtonText = saveButtonText || 'Set video';

  return (
    <div className={classNames} ref={containerRef} {...otherProps}>
      <VideoPlayer videoNodeRef={videoPlayerRef} className={className} source={videoUrl} hideMenuButtons />
      <div className={styles.overlay}>
        <Button className={styles.cancelButton} linkStyle onClick={onCancel} tint='alert'>
          Cancel
        </Button>
        <Button className={styles.confirmButton} onClick={confirmSave} tint='creation'>
          {_saveButtonText}
        </Button>
      </div>
    </div>
  );
});

VideoEditor.propTypes = {
  className: PropTypes.string,
  file: PropTypes.any,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  saveButtonText: PropTypes.string,
  videoUrl: PropTypes.string.isRequired,
};

export default VideoEditor;
