import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Input from 'OK/components/input';
import { Popup, PopupContent } from 'OK/components/popup';
import Text from 'OK/components/text';
import { removeUserNameMutatuion, setUserNameMutation } from 'OK/networking/users';
import { formatOkid } from 'OK/util/formatting';
import { copyTextToClipboard } from 'OK/util/functions/share';
import useI18n from 'OK/util/hooks/useI18n';

export default function SetUserNamePopup(props) {
  /* Variables */

  const { t } = useI18n();
  const { dismiss, header = t('ORGANISATION_FIELDS_GROUP_IDENTIFICATION'), user } = props;
  const OKID = user?.OKID ? formatOkid(user.OKID) : null;
  const name = user?.name ? user.name : null;

  // State

  const [newName, setNewName] = useState(null);
  const [copiedOKID, setCopiedOKID] = useState(false);

  /* API */

  const [removeUserNameAPI] = useMutation(removeUserNameMutatuion);
  const [setUserNameAPI] = useMutation(setUserNameMutation);

  /* Methods */

  const setUserName = useCallback(() => {
    if (newName) {
      setUserNameAPI({ variables: { name: newName } });
    } else {
      removeUserNameAPI();
    }
  }, [setUserNameAPI, newName, removeUserNameAPI]);

  const copyOKID = useCallback(() => {
    copyTextToClipboard(OKID)
      .then(() => {
        setCopiedOKID(true);
      })
      .catch(() => {
        okerror('Error copying OKID.');
      });
  }, [OKID]);
  // Event handlers

  const onChangeName = useCallback((e) => {
    setNewName(e.target.value);
  }, []);

  /* Render */

  const mainContent = (
    <>
      <h5 className={styles.label}>{t('YOUR_NAME')}</h5>
      <Input
        bold
        className={styles.nameInput}
        placeholder={t('USER_FIELD_PLACEHOLDER_NAME_ANOTHER')}
        value={name}
        onChange={onChangeName}
        onBlur={setUserName}
      ></Input>
      <h5 className={styles.label}>{t('YOUR_OKID')}</h5>
      <Text bold className={styles.okidInput}>
        {OKID}
      </Text>
      <Button className={styles.copyButton} onClick={copyOKID} tint='secondary'>
        {copiedOKID ? t('COPIED_OKID') : t('COPY_OKID')}
      </Button>
      <Text bold className={styles.fineprint} size='xs'>
        {t('USER_OKID_DESCRIPTION')}
      </Text>
    </>
  );

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.container}>
        <div className={styles.header}>
          <h3>{header}</h3>
          <Button linkStyle onClick={dismiss} tint='navigation'>
            {t('DONE')}
          </Button>
        </div>
        {mainContent}
      </PopupContent>
    </Popup>
  );
}

SetUserNamePopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
  header: PropTypes.string,
  user: PropTypes.object.isRequired,
};
