import roundPercentage from './roundPercentage';

/**
 * Round an array of percentages and ensure they sum up to 100%.
 *
 * Percentages will be rounded to 1 decimal place. Order will be preserved.
 *
 * @param  {number[]} percentages The percentages to round.
 * @returns {number[]} The rounded percentages.
 */
export default function roundPercentages(...percentages) {
  const formattedPercentages = percentages.map((percentage) => {
    // Round to 1 decimal place.
    const rounded = roundPercentage(percentage);
    return {
      original: percentage,
      rounded,
      // An integer version is required due to errors in how decimals are handled.
      integer: rounded * 10,
    };
  });

  // Check that percentages add up to 100% after rounding.
  const sum = formattedPercentages.reduce((sum, percentage) => percentage.integer + sum, 0);
  if (sum !== 1000) {
    // Percentages don't add up to 100%, so adjust the percentage with the largest rounding error by the difference.
    const difference = sum - 1000;
    let indexOfLargestRoundingError = -1;
    let largestRoundingError = 0;
    formattedPercentages.forEach((percentage, index) => {
      const roundedAsDecimal = percentage.rounded / 100;
      const roundingError = Math.abs(percentage.original - roundedAsDecimal);
      if (roundingError > largestRoundingError) {
        largestRoundingError = roundingError;
        indexOfLargestRoundingError = index;
      }
    });

    // Return rounded percentages (with the manually-adjusted percentage)
    const rounded = formattedPercentages.map((percentage, index) => {
      if (index === indexOfLargestRoundingError) {
        const adjusted = (percentage.integer - difference) / 10;
        return adjusted;
      }

      return percentage.rounded;
    });
    return rounded;
  }

  // Return rounded percentages (no adjustment necessary)
  const rounded = formattedPercentages.map((p) => p.rounded);
  return rounded;
}
