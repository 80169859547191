export default function getTimezoneUTCOffset() {
  const timezoneOffset = new Date().getTimezoneOffset();
  const hours = parseInt(timezoneOffset / 60, 10);
  const absoluteHours = Math.abs(hours);
  const hoursString = absoluteHours < 10 ? `0${absoluteHours}` : `${absoluteHours}`;
  const minutes = Math.abs(parseInt(timezoneOffset % 60, 10));
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const offsetString = `${hoursString}:${minutesString}`;

  if (hours < 0) {
    return `+${offsetString}`;
  } else {
    return `-${offsetString}`;
  }
}
