import { useApolloClient, useMutation } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import Icon, { ICONS } from 'OK/components/icon';
import ItemArchiveCard from 'OK/components/item/archiveCard';
import CardLayout from 'OK/components/layouts/card';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import Notice from 'OK/components/notice';
import Progressable from 'OK/components/progressable';
import Text from 'OK/components/text';
import ItemModel from 'OK/models/item';
import {
  addAssembledItemAsManagerMutation,
  getItemByOKIDForAssemblyQuery,
  removeAssembledItemAsManagerMutation,
  SetItemAssemblyQuantityAsManagerMutation,
} from 'OK/networking/items';
import { showScannerAction } from 'OK/state/app/actions';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import { unformatOkid } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function InspectionLogAssemblySection(props) {
  /* Variables */

  const { className, inspectionLog, setPageMessage, setPageError } = props;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const { t } = useI18n();
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();

  /* State */

  const [loadingScannedItem, setLoadingScannedItem] = useState(false);

  /* API */

  const [addAssembledItemAPI, addAssembledItemAPIResult] = useMutation(addAssembledItemAsManagerMutation);
  const [removeAssembledItemAPI, removeAssembledItemAPIResult] = useMutation(removeAssembledItemAsManagerMutation);
  const [setQuantityForAssembledItemAPI] = useMutation(SetItemAssemblyQuantityAsManagerMutation);

  /* Methods */

  const addAssembledItem = useCallback(
    (itemId) => {
      addAssembledItemAPI({
        variables: {
          itemId: inspectionLog.item.id,
          partItemId: itemId,
        },
      })
        .then(() => {
          setPageMessage(t('LOG_ADDED_ASSEMBLED_ITEM'));
        })
        .catch(() => {
          setPageError(t('LOG_ERROR_ADDING_ASSEMBLED_ITEM'));
        });
    },
    [addAssembledItemAPI, inspectionLog?.item?.id, setPageError, setPageMessage, t]
  );

  const removeAssembledItem = useCallback(
    (itemId) => {
      removeAssembledItemAPI({
        variables: {
          itemId: inspectionLog.item.id,
          partItemId: itemId,
        },
      }).catch(() => {
        setPageError(t('LOG_ERROR_REMOVING_ASSEMBLED_ITEM'));
      });
    },
    [inspectionLog?.item?.id, removeAssembledItemAPI, setPageError, t]
  );

  const saveQuantityForAssembledItem = useMemo(
    () =>
      debounce((assemblyItemId, quantity) => {
        setQuantityForAssembledItemAPI({
          variables: {
            itemId: inspectionLog.item.id,
            partItemId: assemblyItemId,
            quantity,
          },
        }).catch(() => {
          setPageError(t('LOG_ERROR_SAVING_ASSEMBLED_ITEM_QUANTITY'));
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [inspectionLog?.item?.id, setPageError, setQuantityForAssembledItemAPI, t]
  );

  const setQuantityForAssembledItem = useCallback(
    (assemblyItemId, quantity) => {
      // Update cache
      const assemblyItemList = [...inspectionLog.item.assemblyItemList];
      const updatedItemIndex = assemblyItemList.findIndex((ai) => ai.item.id === assemblyItemId);
      const updatedItem = {
        ...assemblyItemList[updatedItemIndex],
        quantity,
      };
      assemblyItemList.splice(updatedItemIndex, 1, updatedItem);
      apolloClient.writeFragment({
        id: apolloClient.cache.identify(inspectionLog.item),
        fragment: ItemModel.fragmentAssemblyQuantities,
        fragmentName: ItemModel.fragmentNameAssemblyQuantities,
        data: {
          id: inspectionLog.item.id,
          assemblyItemList,
          __typename: ItemModel.GRAPHQL_TYPE,
        },
      });

      // Save via API
      saveQuantityForAssembledItem(assemblyItemId, quantity);
    },
    [apolloClient, inspectionLog?.item, saveQuantityForAssembledItem]
  );

  const scanAssembledItem = useCallback(() => {
    dispatch(
      showScannerAction((OKID) => {
        const unformattedOKID = unformatOkid(OKID);

        // Check if scanned item is already in assembly list
        const itemAlreadyInList = inspectionLog.item.assemblyItemList.find((ai) => ai.item.OKID === unformattedOKID);
        if (itemAlreadyInList) {
          // This is a repeated scan of assembled item, so increment quantity by 1
          setQuantityForAssembledItem(itemAlreadyInList.item.id, itemAlreadyInList.quantity + 1);
          return;
        }

        // Get item details
        setLoadingScannedItem(true);
        apolloClient
          .query({ query: getItemByOKIDForAssemblyQuery, variables: { OKID: unformattedOKID } })
          .then((result) => {
            if (result.data?.item) {
              // Add new item to assembly list
              const item = result.data.item;
              addAssembledItem(item.id);
            }
          })
          .catch(() => {
            setPageError(t('LOG_ERROR_SCANNING_ITEM'));
          })
          .finally(() => {
            setLoadingScannedItem(false);
          });
      })
    );
  }, [
    addAssembledItem,
    apolloClient,
    dispatch,
    inspectionLog?.item?.assemblyItemList,
    setPageError,
    setQuantityForAssembledItem,
    t,
  ]);

  /* Render */

  let classNames = styles.section;
  if (className) {
    classNames += ` ${className}`;
  }

  const assemblyItemsLoading =
    loadingScannedItem || addAssembledItemAPIResult.loading || removeAssembledItemAPIResult.loading;

  const assemblyNotice = (
    <Notice className={styles.sectionNotice}>
      <Text bold>{t('LOG_ARCHIVE_PAGE_ASSEMBLY_NOTICE')}</Text>
    </Notice>
  );

  return (
    <ContentLayout className={classNames} pageContent>
      {useDesktopLayout && assemblyNotice}
      <TextLayout>
        <h3>{t('LOG_GO_PAGE_ASSEMBLY_SECTION_HEADER')}</h3>
        <Text className={styles.sectionDescription}>{t('LOG_ARCHIVE_PAGE_ASSEMBLY_SECTION_DESCRIPTION')}</Text>
      </TextLayout>
      {useMobileLayout && assemblyNotice}
      <Progressable inProgress={assemblyItemsLoading}>
        <Carousel className={styles.assemblyCarousel} fadeOutSides={useDesktopLayout}>
          {inspectionLog.item.assemblyItemList.map((ai) => (
            <Slide className={styles.itemSlide} key={ai.item.id}>
              <ItemArchiveCard
                className={styles.itemCard}
                fixedWidth={useDesktopLayout}
                item={ai.item}
                linkToArchive={false}
                onChangeQuantity={(newQuantity) => {
                  setQuantityForAssembledItem(ai.item.id, newQuantity);
                }}
                onClickUnlink={() => {
                  removeAssembledItem(ai.item.id);
                }}
                quantity={ai.quantity}
                showUnlinkButton
              />
            </Slide>
          ))}
          <Slide className={styles.itemSlide}>
            <CardLayout
              className={styles.scanSlideButton}
              fixedWidth={false}
              innerClassName={styles.scanSlideButtonInner}
              onClick={scanAssembledItem}
            >
              <Button
                className={styles.greenCircleButton}
                icon={<Icon height={32} name={ICONS.SCAN.name} width={32} />}
                tint='creation'
              />
              <Text bold>{t('SCAN_ITEM')}</Text>
            </CardLayout>
          </Slide>
        </Carousel>
      </Progressable>
    </ContentLayout>
  );
}

InspectionLogAssemblySection.propTypes = {
  className: PropTypes.string,
  inspectionLog: PropTypes.object.isRequired,
  setPageMessage: PropTypes.func.isRequired,
  setPageError: PropTypes.func.isRequired,
};
