import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import ItemArchiveCard from 'OK/components/item/archiveCard';
import TextLayout from 'OK/components/layouts/content/text';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import Text from 'OK/components/text';
import useI18n from 'OK/util/hooks/useI18n';

export default function MultipleScanResultsPopup(props) {
  const { dismiss, items, onSelectItem } = props;
  const { t } = useI18n();

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.container}>
        <TextLayout>
          <div className={styles.header}>
            <h3>{t('SELECT_AN_ITEM')}</h3>
            <Icon height={19} name={ICONS.LOGO.name} width={33} />
          </div>
          <Text className={styles.description}>{t('SCAN_MATCHED_MULTIPLE_ITEMS')}</Text>
        </TextLayout>
        {items.map((i) => (
          <ItemArchiveCard
            key={i.id}
            className={styles.card}
            item={i}
            layoutOverride='mobile'
            linkToArchive={false}
            onClick={() => onSelectItem(i)}
          />
        ))}
      </PopupContent>
      <PopupButtonsGroup>
        <PopupCloseButton tint='navigation'>{t('CLOSE')}</PopupCloseButton>
      </PopupButtonsGroup>
    </Popup>
  );
}

MultipleScanResultsPopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
  items: PropTypes.any.isRequired,
  onSelectItem: PropTypes.func,
};
