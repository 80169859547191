import OrganisationModel from 'OK/models/organisation';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import authorisationForResource from 'OK/util/functions/authorisationForResource';
import isAuthorised, { isAuthorisedViaShare } from 'OK/util/functions/isAuthorised';

/* Application */

export const PERMISSIONS = {
  CREATE_LABEL: 'CREATE_LABEL',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  SET_ITEM_ALTERNATE_ID: 'SET_ITEM_ALTERNATE_ID',
  LEAD_ASSIGN: 'LEAD_ASSIGN',
  PUBLISHED_LOG_ASSEMBLY: 'PUBLISHED_LOG_ASSEMBLY',
  PUBLISHED_LOG_NOTES: 'PUBLISHED_LOG_NOTES',
  SALES_DASHBOARD: 'SALES_DASHBOARD',
  USER_CERTIFICATE: 'USER_CERTIFICATE',
};

export const PERMISSION_MIN_AUTHORISATION_LEVELS = {
  [PERMISSIONS.CREATE_LABEL]: AUTHORISATION_LEVEL.MANAGER,
  [PERMISSIONS.CREATE_PRODUCT]: AUTHORISATION_LEVEL.MANAGER,
  [PERMISSIONS.LEAD_ASSIGN]: AUTHORISATION_LEVEL.USER,
  [PERMISSIONS.PUBLISHED_LOG_ASSEMBLY]: AUTHORISATION_LEVEL.MANAGER,
  [PERMISSIONS.PUBLISHED_LOG_NOTES]: AUTHORISATION_LEVEL.MANAGER,
  [PERMISSIONS.SALES_DASHBOARD]: AUTHORISATION_LEVEL.USER,
  [PERMISSIONS.SET_ITEM_ALTERNATE_ID]: AUTHORISATION_LEVEL.MANAGER,
  [PERMISSIONS.USER_CERTIFICATE]: AUTHORISATION_LEVEL.MANAGER,
};

function hasMinimumAuthorisationLevel(permission, user, activeOrganisation) {
  const minimumAuthorisationLevel = PERMISSION_MIN_AUTHORISATION_LEVELS[PERMISSIONS[permission]];
  if (!minimumAuthorisationLevel) {
    return false;
  }

  return isAuthorised(
    authorisationForResource(user, activeOrganisation.id, activeOrganisation),
    minimumAuthorisationLevel
  );
}

export default function hasPermission(permission, user, activeOrganisation) {
  if (!permission || !user) {
    okwarn('Insufficient data passed to function `hasPermission`.');
    return false;
  }

  switch (permission) {
    case PERMISSIONS.CREATE_LABEL:
      return hasPermissionToCreateLabel(user, activeOrganisation);
    case PERMISSIONS.CREATE_PRODUCT:
      return hasPermissionToCreateProduct(user, activeOrganisation);
    case PERMISSIONS.LEAD_ASSIGN:
      return hasPermissionToAssignLeads(user);
    case PERMISSIONS.SALES_DASHBOARD:
      return hasPermissionToAccessSalesDashbaord(user);
    case PERMISSIONS.SET_ITEM_ALTERNATE_ID:
      return hasPermissionToSetItemAlternateId(user, activeOrganisation);
    case PERMISSIONS.USER_CERTIFICATE:
      return hasPermissionToCreateUserCertificates(user, activeOrganisation);
    default:
      return hasMinimumAuthorisationLevel(permission, user, activeOrganisation);
  }
}

/* Object */

export const OBJECT_PERMISSIONS = {
  EDIT_LOG: 'EDIT_LOG',
  POST_COMMENTS: 'POST_COMMENTS',
  VIEW_ORGANISATION_INTERNAL_LOGS: 'VIEW_ORGANISATION_INTERNAL_LOGS',
  VIEW_USER_CONTACT_INFO: 'VIEW_USER_CONTACT_INFO',
};

export const OBJECT_PERMISSION_MIN_AUTHORISATION_LEVELS = {
  [OBJECT_PERMISSIONS.VIEW_ORGANISATION_INTERNAL_LOGS]: AUTHORISATION_LEVEL.MANAGER,
  [OBJECT_PERMISSIONS.VIEW_USER_CONTACT_INFO]: AUTHORISATION_LEVEL.MANAGER,
};

export function hasPermissionForObject(permission, user, activeOrganisation, object) {
  if (!permission || !user || !activeOrganisation || !object) {
    okwarn('Insufficient data passed to function `hasPermissionForObject`.');
    return false;
  }

  switch (permission) {
    case OBJECT_PERMISSIONS.EDIT_LOG:
      return hasPermissionToEditLog(user, activeOrganisation, object);
    case OBJECT_PERMISSIONS.POST_COMMENTS:
      return hasPermissionToPostCommentsOnAsset(user, activeOrganisation, object);
    case OBJECT_PERMISSIONS.VIEW_ORGANISATION_INTERNAL_LOGS:
      return hasPermissionToViewOrganisationInternalLogs(user, activeOrganisation, object);
    case OBJECT_PERMISSIONS.VIEW_USER_CONTACT_INFO:
      return hasPermissionToViewUserContactInfo(user, activeOrganisation, object?.id);
    default:
      return false;
  }
}

/* Basic permission helpers */

function hasPaidSubscriptionPermission(organisation) {
  return (
    organisation.type === OrganisationModel.SUBSCRIPTION_TYPE.ANNUAL ||
    organisation.type === OrganisationModel.SUBSCRIPTION_TYPE.MONTHLY ||
    organisation.type === OrganisationModel.SUBSCRIPTION_TYPE.TRIAL
  );
}

/* Feature permissions */

function hasPermissionToAccessSalesDashbaord(user) {
  return user.roleList.includes('SALES');
}

function hasPermissionToAssignLeads(user) {
  return user.roleList.includes('SALES_ADMIN');
}

function hasPermissionToCreateLabel(user, activeOrganisation) {
  if (!activeOrganisation || !hasPaidSubscriptionPermission(activeOrganisation)) {
    return false;
  }

  const authorisationLevel = authorisationForResource(user, activeOrganisation.id, activeOrganisation);
  return isAuthorised(authorisationLevel, PERMISSION_MIN_AUTHORISATION_LEVELS.CREATE_LABEL);
}

function hasPermissionToCreateProduct(user, activeOrganisation) {
  if (!activeOrganisation || !hasPaidSubscriptionPermission(activeOrganisation)) {
    return false;
  }

  const authorisationLevel = authorisationForResource(user, activeOrganisation.id, activeOrganisation);
  return isAuthorised(authorisationLevel, PERMISSION_MIN_AUTHORISATION_LEVELS.CREATE_PRODUCT);
}

function hasPermissionToSetItemAlternateId(user, activeOrganisation) {
  const authorisationLevel = authorisationForResource(user, activeOrganisation.id, activeOrganisation);
  const currentUserIsAuthorised = isAuthorised(
    authorisationLevel,
    PERMISSION_MIN_AUTHORISATION_LEVELS.SET_ITEM_ALTERNATE_ID
  );
  return (
    activeOrganisation?.permissionList?.includes(OrganisationModel.PERMISSIONS.ITEM_EXTERNAL_ID) &&
    currentUserIsAuthorised
  );
}

function hasPermissionToCreateUserCertificates(user, activeOrganisation) {
  if (!activeOrganisation) {
    return false;
  }

  const authorisationLevel = authorisationForResource(user, activeOrganisation.id, activeOrganisation);
  return isAuthorised(authorisationLevel, PERMISSION_MIN_AUTHORISATION_LEVELS.USER_CERTIFICATE);
}

/* Object permissions */

function hasPermissionToEditLog(currentUser, activeOrganisation, inspectionLog) {
  const authorisationLevel = authorisationForResource(currentUser, activeOrganisation.id, inspectionLog);
  if (isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER)) {
    // Managers can edit all organisation logs
    return true;
  }

  // Co-workers can only edit logs they worked on.
  return (
    inspectionLog.inspector?.id === currentUser.id || // is inspector
    inspectionLog.assigneeList?.findIndex((a) => a.userId === currentUser.id) > -1 || // is on assignee list
    inspectionLog.contactList?.findIndex((c) => c.userId === currentUser.id) > -1 // is on contact list
  );
}

/**
 * Check for permission to post comments on an asset.
 *
 * Permission is granted if:
 * a) the asset is owned by the active organisation
 * OR
 * b) the asset has been shared with permission to comment
 */
function hasPermissionToPostCommentsOnAsset(currentUser, activeOrganisation, asset) {
  return (
    asset.organisationId === activeOrganisation.id ||
    isAuthorisedViaShare(asset.assetAccessPermission, AssetAccessPermission.SHARED_WITH_COMMENT_PERMISSION)
  );
}

/**
 * Check if the current user has permission to view internal logs for an organisation.
 *
 * Permission is granted if the user is a manager of the organisation.
 *
 * @returns {boolean}
 */
function hasPermissionToViewOrganisationInternalLogs(currentUser, activeOrganisation, organisation) {
  return isAuthorised(
    authorisationForResource(currentUser, activeOrganisation.id, organisation),
    OBJECT_PERMISSION_MIN_AUTHORISATION_LEVELS.VIEW_ORGANISATION_INTERNAL_LOGS
  );
}

function hasPermissionToViewUserContactInfo(currentUser, activeOrganisation, userId) {
  const organisationAuthorisation = authorisationForResource(currentUser, activeOrganisation.id, activeOrganisation);
  const userIsMemberOfActiveOrganisation = activeOrganisation.userList?.findIndex((u) => u.id === userId) > -1;
  const pendingInvitations = activeOrganisation.inviteUserList ?? [];
  const pendingMemberships = activeOrganisation.userJoinRequestList ?? [];
  const userInvitationIsPending = pendingInvitations?.findIndex((u) => u.id === userId) > -1;
  const userMembershipPending = pendingMemberships?.findIndex((u) => u.id === userId) > -1;
  const currentUserIsAuthorised = isAuthorised(
    organisationAuthorisation,
    OBJECT_PERMISSION_MIN_AUTHORISATION_LEVELS[OBJECT_PERMISSIONS.VIEW_USER_CONTACT_INFO]
  );
  return (
    (userIsMemberOfActiveOrganisation || userInvitationIsPending || userMembershipPending) && currentUserIsAuthorised
  );
}
