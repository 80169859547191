import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import CardLayout from 'OK/components/layouts/card';
import Text from 'OK/components/text';
import { formatDate } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function DocumentViewCard(props) {
  const { file, ...otherProps } = props;
  const { locale, t } = useI18n();

  const {
    applicableMarketList = [],
    documentNumber,
    documentType,
    madeByOrganisation,
    userDocumentStandardList,
    issueDate,
    validFrom,
    validTill,
  } = file;

  return (
    <CardLayout {...otherProps}>
      <h4>{documentType === 'USER_CERTIFICATION' ? t('CERTIFICATE') : t('DOCUMENT_TYPE_USER_FILE')}</h4>
      <div className={styles.field}>
        <Text bold className={styles.label}>
          {t('FILE_FIELD_DOCUMENT_NUMBER')}
        </Text>
        <Text className={styles.value}>{documentNumber}</Text>
      </div>
      <div className={styles.field}>
        <Text bold className={styles.label}>
          {t('FILE_FIELD_MARKETS')}:
        </Text>
        {/* eslint-disable indent*/}
        <Text className={styles.value} tint=''>
          {applicableMarketList.length > 0
            ? applicableMarketList.map((m, index) => {
                let market = m.toUpperCase();
                if (index > 0) {
                  market = `, ${market}`;
                }
                return market;
              })
            : t('NOT_SET')}
        </Text>
        {/* eslint-ensable indent*/}
      </div>
      <div className={styles.field}>
        <Text bold className={styles.label}>
          {t('FILE_FIELD_MADE_BY')}:
        </Text>
        <Text className={styles.value} tint={madeByOrganisation?.name && 'navigation'}>
          {madeByOrganisation?.name ? madeByOrganisation?.name : t('NOT_SET')}
        </Text>
      </div>
      <div className={styles.field}>
        <Text bold className={styles.label}>
          {t('FILE_FIELD_ISSUE_DATE')}:
        </Text>
        <Text className={styles.value} tint=''>
          {issueDate ? formatDate(new Date(parseInt(issueDate, 10)), locale, { style: 'short' }) : t('NOT_SET')}
        </Text>
      </div>
      <div className={styles.field}>
        <Text bold className={styles.label}>
          {t('FILE_FIELD_VALID_FROM')}:
        </Text>
        <Text className={styles.value} tint=''>
          {validFrom ? formatDate(new Date(parseInt(validFrom, 10)), locale, { style: 'short' }) : t('NOT_SET')}
        </Text>
      </div>
      <div className={styles.field}>
        <Text bold className={styles.label}>
          {t('FILE_FIELD_VALID_UNTIL')}:
        </Text>
        <Text className={styles.value} tint=''>
          {validTill ? formatDate(new Date(parseInt(validTill, 10)), locale, { style: 'short' }) : t('NOT_SET')}
        </Text>
      </div>
      <div>
        <Text bold className={styles.label}>
          {t('FILE_FIELD_STANDARDS')}:
        </Text>
        {userDocumentStandardList?.map((standard, i) => (
          <Text key={i} className={styles.standardName}>
            {t(`DOCUMENT_STANDARD_${standard}`)}
          </Text>
        ))}
      </div>
    </CardLayout>
  );
}

DocumentViewCard.propTypes = {
  file: PropTypes.object,
};
