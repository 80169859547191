import { gql } from '@apollo/client';

import OrganisationModel from 'OK/models/organisation';
import ProductModel from 'OK/models/product';
import { request } from 'OK/networking';
import { API_URL } from 'OK/networking/utils';

/* Queries */

export const EditFilePopupQuery = gql`
  query EditFilePopup($documentAssetId: String!) {
    file: documentAsset(documentAssetId: $documentAssetId) {
      id
      applicableMarketList
      coveredLanguageList
      documentName
      documentNumber
      documentStandardList
      documentType
      documentURL
      madeByOrganisationId
      madeByOrganisation {
        ...${OrganisationModel.fragmentNameArchiveCard}
      }
      metadata
      organisationId
      REFID
      validFrom
      validTill
    }
  }
  ${OrganisationModel.fragmentArchiveCard}
`;

export const FileArchivePageQuery = gql`
  query FileArchivePage($refId: String!) {
    documentAsset: documentAssetWithREFID(REFID: $refId) {
      id
      assetAccessPermission
      assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      applicableMarketList
      conformityPoint
      coveredLanguageList
      documentName
      documentNumber
      documentStandardList
      documentType
      documentURL
      linkedProductList {
        assetAccessPermission
        assetAccessPermissionList {
          assetAccessPermission
          assetId
          assetType
          id
          sharedToAssetId
          sharedToAssetType
          sharedToOrganisation {
            id
            OKID
            name
            logoImageMediaAsset {
              logoImageURL
            }
          }
          sharedToUser {
            id
            OKID
            avatar {
              sourceDarkURL
              sourceLightURL
            }
          }
        }
        ...${ProductModel.fragmentNameArchiveCardWithDocumentPublishStatus}
        
      }
      madeByOrganisationId
      madeByOrganisation {
        ...${OrganisationModel.fragmentNameArchiveCard}
      }
      metadata
      organisationId
      REFID
      validFrom
      validTill
    }
  }
  ${ProductModel.fragmentArchiveCardWithDocumentPublishStatus}
  ${OrganisationModel.fragmentArchiveCard}
`;

/* Mutations */

export const addApplicableMarketToDocumentMutation = gql`
  mutation AddApplicableMarketToDocument($documentId: String!, $marketIso: EMarketCode!) {
    document: addApplicableMarket(documentAssetId: $documentId, marketCode: $marketIso) {
      id
      applicableMarketList
    }
  }
`;

export const addCoveredLanguageToDocumentMutation = gql`
  mutation AddCoveredLanguageToDocument($documentId: String!, $languageIso: ELanguageCode!) {
    document: addCoveredLanguage(documentAssetId: $documentId, languageCode: $languageIso) {
      id
      coveredLanguageList
    }
  }
`;

export const AddDocumentStandardMutation = gql`
  mutation AddDocumentStandard($documentId: String!, $standardId: EDocumentStandard!) {
    document: addDocumentStandard(documentAssetId: $documentId, documentStandard: $standardId) {
      id
      documentStandardList
    }
  }
`;

export const deleteDocumentAssetMutation = gql`
  mutation DeleteDocumentAsset($documentAssetId: String!) {
    document: deleteDocumentAsset(documentAssetId: $documentAssetId) {
      id
    }
  }
`;

export const removeApplicableMarketFromDocumentMutation = gql`
  mutation RemoveApplicableMarketFromDocument($documentId: String!, $marketIso: EMarketCode!) {
    document: removeApplicableMarket(documentAssetId: $documentId, marketCode: $marketIso) {
      id
      applicableMarketList
    }
  }
`;

export const removeCoveredLanguageFromDocumentMutation = gql`
  mutation RemoveCoveredLanguageFromDocument($documentId: String!, $languageIso: ELanguageCode!) {
    document: removeCoveredLanguage(documentAssetId: $documentId, languageCode: $languageIso) {
      id
      coveredLanguageList
    }
  }
`;

export const RemoveDocumentMadeByOrganisationMutation = gql`
  mutation RemoveDocumentMadeByOrganisation($documentId: String!) {
    document: removeMadeByOrganisation(documentAssetId: $documentId) {
      id
      madeByOrganisationId
      madeByOrganisation {
        ...${OrganisationModel.fragmentNameArchiveCard}
      }
    }
  }
  ${OrganisationModel.fragmentArchiveCard}
`;

export const RemoveDocumentStandardMutation = gql`
  mutation RemoveDocumentStandard($documentId: String!, $documentStandard: EDocumentStandard!) {
    document: removeDocumentStandard(documentAssetId: $documentId, documentStandard: $documentStandard) {
      id
      documentStandardList
    }
  }
`;

export const RemoveValidFromMutation = gql`
  mutation RemoveValidFrom($documentId: String!) {
    document: removeValidFrom(documentAssetId: $documentId) {
      id
      validFrom
    }
  }
`;

export const RemoveValidUntilMutation = gql`
  mutation RemoveValidUntil($documentId: String!) {
    document: removeValidUntil(documentAssetId: $documentId) {
      id
      validTill
    }
  }
`;

export const SetDocumentMadeByOrganisationMutation = gql`
  mutation SetDocumentMadeByOrganisation($documentId: String!, $organisationId: String!) {
    document: setMadeByOrganisation(documentAssetId: $documentId, madeByOrganisationId: $organisationId) {
      id
      madeByOrganisationId
      madeByOrganisation {
        ...${OrganisationModel.fragmentNameArchiveCard}
      }
    }
  }
  ${OrganisationModel.fragmentArchiveCard}
`;

export const SetDocumentNumberMutation = gql`
  mutation SetDocumentNumber($documentId: String!, $documentNumber: String!) {
    document: setDocumentNumber(documentAssetId: $documentId, documentNumber: $documentNumber) {
      id
      documentNumber
    }
  }
`;

export const SetDocumentTypeMutation = gql`
  mutation SetDocumentType($documentId: String!, $documentType: EDocumentType!) {
    document: changeDocumentType(documentAssetId: $documentId, documentType: $documentType) {
      id
      documentType
    }
  }
`;

export const SetValidFromMutation = gql`
  mutation SetValidFrom($validFrom: String!, $documentId: String!) {
    document: setValidFrom(dateUTCString: $validFrom, documentAssetId: $documentId) {
      id
      validFrom
    }
  }
`;

export const SetValidUntilMutation = gql`
  mutation SetValidUntil($validTill: String!, $documentId: String!) {
    document: setValidUntil(dateUTCString: $validTill, documentAssetId: $documentId) {
      id
      validTill
    }
  }
`;

/* Requests */

export async function getDocumentAssetSpecListRequest() {
  return request(`${API_URL}/utility/document/getDocumentAssetSpecList`, {
    method: 'GET',
  });
}

export async function getDocumentAssetStandardListRequest() {
  return request(`${API_URL}/utility/document/getDocumentAssetStandardList`, {
    method: 'GET',
  });
}

export async function replaceDocumentFileRequest(documentId, file) {
  const metadata = {
    filesize: file.size,
  };
  return request(`${API_URL}/utility/document/replaceDocumentAssetFileWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters: {
      documentAssetId: documentId,
      documentFile: file,
      metadataString: JSON.stringify(metadata),
    },
  });
}

export async function sendNewDocumentTypeFeedback(feedback) {
  return request(`${API_URL}/utility/feedback/newDocumentTypeFeedback`, {
    method: 'POST',
    parameters: { feedback },
  });
}
