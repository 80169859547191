import { useLazyQuery, useMutation } from '@apollo/client';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Text from 'OK/components/text';
import { applyToJoinOrganisationMutation } from 'OK/networking/organisations';
import { searchQuery } from 'OK/networking/search';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import { formatOkid } from 'OK/util/formatting';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';
import SessionManager from 'OK/util/session';

function AccountOnboardingModule(props) {
  const { onClickAddOrganisation } = props;
  const [, , currentUser] = useAuthentication();
  const { t, tHTML } = useI18n();
  const dispatch = useDispatch();

  const theme = useContext(ThemeContext);

  // State
  const [searchOrganisationFocused, setSearchOrganisationFocused] = useState(false);
  const [searchOrganisationString, setSearchOrganisationString] = useState();
  const [membershipsError, setMembershipsError] = useState(null);
  const [applicationMessage, setApplicationMessage] = useState(null);

  // API

  const [searchOrganisationAPI, searchOrganisationAPIResult] = useLazyQuery(searchQuery);
  const [applyToJoinOrganisationAPI] = useMutation(applyToJoinOrganisationMutation);

  // Event handlers

  const onSearchedOrganisation = (e) => {
    const searchTerm = e.target.value;
    setSearchOrganisationString(searchTerm);

    if (searchTerm.length > 1) {
      debouncedSearchOrganisations(searchTerm);
    }
  };

  // Effects

  useEffect(() => {
    if (membershipsError) {
      setTimeout(() => {
        setMembershipsError(false);
      }, 5000);
    }
  }, [membershipsError]);

  useEffect(() => {
    if (applicationMessage) {
      setTimeout(() => {
        setApplicationMessage(false);
      }, 5000);
    }
  }, [applicationMessage]);

  useEffect(() => {
    if (currentUser?.organisationList.length > 0) {
      SessionManager.completeOnboarding();
    }
  }, [currentUser?.organisationList?.length, dispatch]);

  // Methods

  const onClickJoinOrganisation = useCallback(
    (organisationId) => {
      setMembershipsError(null);
      applyToJoinOrganisationAPI({
        variables: { organisationId },
      })
        .then(() => {
          setSearchOrganisationString('');
          setApplicationMessage(t('ACCOUNT_ONBOARDING_REQUEST_SENT'));
        })
        .catch(() => {
          setSearchOrganisationString('');
          setMembershipsError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          SessionManager.completeOnboarding();
        });
    },
    [applyToJoinOrganisationAPI, t]
  );

  const onClickAddNewOrganisation = useCallback(() => {
    onClickAddOrganisation();
  }, [onClickAddOrganisation]);

  const debouncedSearchOrganisations = useMemo(
    () =>
      debounce((searchString) => {
        searchOrganisationAPI({
          variables: {
            searchPaginationDataByDataType: [
              { dataType: 'ORGANISATION', searchPaginationData: { pageSize: 4, skip: 0 } },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchOrganisationAPI]
  );

  // Render

  const organisationSearchResults = useMemo(() => {
    if (searchOrganisationFocused && searchOrganisationString?.length > 1) {
      return (
        searchOrganisationAPIResult.data?.search?.resultList?.map((r) => {
          const organisation = r.organisationData;
          const appliedOrganisation = currentUser?.joinRequestOrganisationList.find((o) => o.id == organisation.id);
          const alreadyApplied = appliedOrganisation?.id == organisation?.id;
          return {
            action: (
              <Text className={styles.searchResultButton} tint={alreadyApplied ? 'notification' : 'navigation'}>
                {alreadyApplied ? t('APPLIED') : t('JOIN')}
              </Text>
            ),
            icon: ICONS.ORGANISATION.name,
            key: organisation.id,
            title: organisation.name,
            subtitle: formatOkid(organisation.OKID),
          };
        }) ?? []
      );
    }

    return [];
  }, [
    searchOrganisationFocused,
    searchOrganisationString?.length,
    searchOrganisationAPIResult.data?.search?.resultList,
    currentUser?.joinRequestOrganisationList,
    t,
  ]);

  return (
    <>
      <div className={styles.card}>
        <div className={styles.header}>
          <h3>{t('ACCOUNT_ONBOARDING')}</h3>
          <Button className={styles.actionButtonUpper} linkStyle>
            {t('ACCOUNT_ONBOARDING_BUTTON_3')}
          </Button>
        </div>
        <p className={styles.welcomeMessage}>{t('ACCOUNT_ONBOARDING_TEXT_1')}</p>
        <div className={styles.kristoferContainer}>
          <img alt='Kristofer Thorsell' className={styles.kristofer} src='/img/kristofer_photo.png' />
          <img
            alt={t('IMG_ALT_KRISTOFER_SIGNATURE')}
            className={styles.signature}
            src={`/img/kristofer_signature_${theme.name}.png`}
          />
          <p>{tHTML('ACCOUNT_ONBOARDING_NAME', {})}</p>
          <p>{t('ACCOUNT_ONBOARDING_NAME_2')}</p>
        </div>
      </div>
      <div className={`${styles.card} ${styles.actionCard}`}>
        <Button style={{ marginBottom: 10 }} block tint='creation' onClick={onClickAddNewOrganisation} withCaret>
          {t('ACCOUNT_ONBOARDING_BUTTON_2')}
        </Button>
        <div className={styles.searchContainer}>
          <Input
            className={styles.searchInput}
            onBlur={() => setSearchOrganisationFocused(false)}
            onChange={onSearchedOrganisation}
            onFocus={() => setSearchOrganisationFocused(true)}
            placeholder={t('FIND_ORGANISATION_TO_JOIN')}
            value={searchOrganisationString}
          />
          <SearchSuggestions
            className={styles.searchResults}
            highlightTerm={searchOrganisationString}
            onSuggestionClick={onClickJoinOrganisation}
            showMoreResultsMessage={
              searchOrganisationFocused &&
              searchOrganisationAPIResult.data?.search?.searchPaginationResultDataByDataType?.MEDIA_ASSET
                ?.totalResults > organisationSearchResults.length
            }
            showNoResultsMessage={
              searchOrganisationFocused &&
              searchOrganisationString?.length > 1 &&
              organisationSearchResults.length === 0
            }
            suggestions={organisationSearchResults}
            subtitleClassName={styles.searchResultOKID}
          />
        </div>
        {membershipsError && (
          <Text className={styles.membershipsMessage} size='sm' tint='alert'>
            {membershipsError}
          </Text>
        )}
        {applicationMessage && (
          <Text className={styles.membershipsMessage} size='sm' tint='notification'>
            {applicationMessage}
          </Text>
        )}
        <Button className={styles.actionButton} block linkStyle onClick={() => SessionManager.completeOnboarding()}>
          {t('ACCOUNT_ONBOARDING_BUTTON_3')}
        </Button>
      </div>
    </>
  );
}

export default AccountOnboardingModule;

AccountOnboardingModule.propTypes = {
  onClickAddOrganisation: PropTypes.func.isRequired,
};
