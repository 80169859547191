import { captureException } from '@sentry/react';

import appConfig from 'OK/config/app';
import getStore from 'OK/state/store';

const analyticsFeatureFlagEnabled = appConfig.features.analytics;
let hasInitAnalytics = false;

async function getMixpanel() {
  return (await import('mixpanel-browser')).default;
}

/** Check if the user's Do Not Track setting is enabled. */
export function doNotTrackIsEnabled() {
  return navigator?.doNotTrack === '1';
}

export function identifyUser(user) {
  okdebug('identifyUser');
  const userPermitsCookies = getStore().getState().app.userPermitsCookies === '1';
  if (analyticsFeatureFlagEnabled && userPermitsCookies) {
    if (!hasInitAnalytics) {
      initAnalytics();
    }
    if (hasInitAnalytics) {
      try {
        okdebug('Identifying user.');
        // Update Intercom with user data
        window.Intercom('update', {
          user_id: user.id,
          email: user.email,
        });
        // Identify user by OKID for Microsoft Clarity
        window.clarity('identify', user.OKID);
      } catch (e) {
        okerror('Error identifying user.', e);
      }
    }
  }
}

/** Initialize Mixpanel */
export async function initAnalytics(locale) {
  okdebug('initAnalytics');
  const userPermitsCookies = getStore().getState().app.userPermitsCookies === '1';
  if (analyticsFeatureFlagEnabled && userPermitsCookies && !hasInitAnalytics) {
    let intercomLocale;
    switch (locale) {
      case 'zh-CN':
        intercomLocale = 'zh-CN';
        break;
      case 'zh-HK':
        intercomLocale = 'zh-HK';
        break;
      default:
        intercomLocale = locale;
        break;
    }
    try {
      okdebug('Setting up analytics.');
      const mixpanel = await getMixpanel();
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
        debug: appConfig.features.mixpanelDebugMode,
        disable_persistence: true,
        ignore_dnt: true, // We still respect DNT by only tracking events anonymously.
        opt_out_persistence_by_default: true,
      });
      window.Intercom('boot', {
        app_id: 'p1814irl',
        language_override: intercomLocale,
      });
      // Enable cookie-dependant features for Microsoft Clarity
      window.clarity('consent');
      hasInitAnalytics = true;
    } catch (e) {
      okerror('Error setting up analytics.', e);
    }
  } else {
    okdebug('Ignored call to setup analytics due to lack of user permission.');
  }
}

/**
 * Track an error.
 *
 * @param {Error|object} error The error to track.
 */
export function trackError(error, context) {
  captureException(error, context);
}

/**
 * Track an event.
 *
 * @param {string} eventName The name of the event.
 * @param {object} [properties] Event details.
 * @param {object} [options] Configure the track operation. See Mixpanel docs for options.
 * @param {() => {}} [callback] Code to run after tracking is complete.
 *
 * @returns {object|false} Tracking payload if successful, otherwise `false`.
 */
export async function trackEvent(eventName, properties, options, callback) {
  okdebug('trackEvent');
  const userPermitsCookies = getStore().getState().app.userPermitsCookies === '1';
  if (analyticsFeatureFlagEnabled && userPermitsCookies) {
    if (!hasInitAnalytics) {
      initAnalytics();
    }
    if (hasInitAnalytics) {
      try {
        okdebug('Tracking event:', eventName);
        const mixpanel = await getMixpanel();
        return mixpanel.track(eventName, properties, options, callback);
      } catch (e) {
        okerror('Error tracking event.', e);
      }
    }
  }

  return;
}

export function trackRouteChange(routeUrl) {
  okdebug('trackRouteChange');
  const userPermitsCookies = getStore().getState().app.userPermitsCookies === '1';
  if (analyticsFeatureFlagEnabled && userPermitsCookies) {
    if (!hasInitAnalytics) {
      initAnalytics();
    }
    if (hasInitAnalytics) {
      try {
        okdebug('Tracking route change:', routeUrl);
        window.Intercom('update');
      } catch (e) {
        okerror('Error tracking route change.', e);
      }
    }
  }
}

/** Reset the Mixpanel session. */
export async function resetAnalyticsSession() {
  okdebug('resetAnalyticsSession');
  // const userPermitsCookies = getStore().getState().app.userPermitsCookies === '1';
  if (analyticsFeatureFlagEnabled) {
    if (hasInitAnalytics) {
      try {
        okdebug('Resetting analytics.');
        const mixpanel = await getMixpanel();
        mixpanel.reset();
        window.Intercom('shutdown');
        hasInitAnalytics = false;
      } catch (e) {
        okerror('Error resetting analytics.', e);
      }
    }
  }
}
