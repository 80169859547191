import { render as ReactRender } from 'react-dom';

function getOrCreateLegendList(chart, id) {
  const legendContainer = document.getElementById(id);
  if (!legendContainer) {
    return null;
  }

  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
}

let ChartJSHtmlLegendPlugin;
if (typeof window !== 'undefined') {
  ChartJSHtmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const ul = getOrCreateLegendList(chart, options.containerID);
      if (!ul) {
        return;
      }

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginRight = '20px';

        const dataset = chart.data.datasets[item.datasetIndex];

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.display = 'inline-block';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.height = '10px';
        boxSpan.style.width = '10px';
        if (dataset.backgroundColorStart && dataset.backgroundColorFinish) {
          // Gradient
          boxSpan.style.background = `linear-gradient(${dataset.backgroundColorStart}, ${dataset.backgroundColorFinish})`;
        } else if (dataset.type === 'line' && dataset.borderDash?.length) {
          // Dashed line
          boxSpan.style.borderTopStyle = 'dashed';
          boxSpan.style.borderWidth = `${item.lineWidth}px`;
          boxSpan.style.height = `${item.lineWidth}px`;
          boxSpan.style.width = '25px';
        } else if (dataset.backgroundColor) {
          // Single color
          boxSpan.style.background = dataset.backgroundColor;
        }

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
        textContainer.style.fontSize = '14px';

        if (typeof item.text === 'string') {
          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);
        } else {
          ReactRender(item.text, textContainer);
        }

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };
}

export default ChartJSHtmlLegendPlugin;
