import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Supercluster from 'supercluster';

import Text from '../text';

import MapPopup from './MapPopup';
import styles from './styles.module.scss';

import config from 'OK/config/app';
import useI18n from 'OK/util/hooks/useI18n';

import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = config.mapBox_token;

const MapObject = (props) => {
  const { startingProduct } = props;

  const { t, tHTML } = useI18n();
  const mapContainer = useRef(null);

  const [allProducts, setAllProducts] = useState([]);
  const [center, setCenter] = useState([]);
  const [mapPopupInfo, setMapPopupInfo] = useState(null);
  const [openMapPopup, setOpenMapPopup] = useState(false);

  useEffect(() => {
    let allProducts = [];
    let finalAllProducts = [];

    if (
      startingProduct?.productChildProductList?.length === 0 &&
      startingProduct?.productParentProductList?.length === 0
    ) {
      setAllProducts([startingProduct]);
      setCenter([
        parseFloat(startingProduct?.organisation?.addressList[0]?.longitude ?? 0),
        parseFloat(startingProduct?.organisation?.addressList[0]?.latitude ?? 0),
      ]);
      return;
    }

    function recurseProduct(product, relatedProduct, type, id) {
      if (product?.productChildProductList?.length > 0) {
        product.productChildProductList.forEach((childProduct) => {
          recurseProduct(childProduct.childProduct, product, 'child', childProduct.childProduct.id + product.id);
        });
      }

      if (product?.productParentProductList?.length > 0) {
        product.productParentProductList.forEach((parentProduct) => {
          recurseProduct(parentProduct.parentProduct, product, 'parent', product.id + parentProduct.parentProduct.id);
        });
      }

      allProducts.push({
        product: product,
        relatedProduct: relatedProduct,
        type: type,
        id: id,
      });
    }

    recurseProduct(startingProduct);

    let childProductAddressesRecursive = allProducts.filter((product) => product?.type === 'child');
    let parentProductAddressesRecursive = allProducts.filter((product) => product?.type === 'parent');

    if (startingProduct.productChildProductList?.length > 0) {
      childProductAddressesRecursive.forEach((product) => {
        finalAllProducts.push(product);
      });
    }
    if (startingProduct.productParentProductList?.length > 0) {
      parentProductAddressesRecursive.forEach((product) => {
        finalAllProducts.push(product);
      });
    }

    setCenter([
      parseFloat(startingProduct?.organisation?.addressList[0]?.longitude ?? 0),
      parseFloat(startingProduct?.organisation?.addressList[0]?.latitude ?? 0),
    ]);

    setAllProducts(finalAllProducts);
  }, [startingProduct]);

  useEffect(() => {
    function haversineDistance(lat1, lon1, lat2, lon2) {
      // Convert degrees to radians
      function toRadians(degree) {
        return (degree * Math.PI) / 180;
      }

      const R = 6371; // Earth's radius in kilometers

      // Convert latitude and longitude to radians
      const radLat1 = toRadians(lat1);
      const radLon1 = toRadians(lon1);
      const radLat2 = toRadians(lat2);
      const radLon2 = toRadians(lon2);

      // Calculate the differences
      const deltaLat = radLat2 - radLat1;
      const deltaLon = radLon2 - radLon1;

      // Calculate the Haversine distance
      const a =
        Math.pow(Math.sin(deltaLat / 2), 2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(deltaLon / 2), 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = R * c;

      return distance;
    }

    if (allProducts.length === 0) {
      return;
    }

    let productsWithRelatedProducts = [];

    allProducts.forEach((product) => {
      let sampleObject = {
        product: {},
        productCoordinates: [],
        relatedProduct: {},
        relatedProductCoordinates: [],
        relationshipType: '',
      };

      if (product.product) {
        sampleObject.product = product.product;
        sampleObject.productCoordinates = [
          parseFloat(product.product.organisation.addressList[0].longitude),
          parseFloat(product.product.organisation.addressList[0].latitude),
        ];
        sampleObject.relatedProduct = product.relatedProduct;
        sampleObject.relatedProductCoordinates = [
          parseFloat(product.relatedProduct.organisation.addressList[0].longitude),
          parseFloat(product.relatedProduct.organisation.addressList[0].latitude),
        ];
        sampleObject.relationshipType = product.type;
        productsWithRelatedProducts.push(sampleObject);
      }
    });

    const allUniqueProductsWithContext = productsWithRelatedProducts
      .filter((product, index, self) => index === self.findIndex((t) => t.product.id === product.product.id))
      .filter((product) => product.product.id !== startingProduct.id);

    const markersWithContext = [
      {
        long: center[0],
        lat: center[1],
        type: 'starting',
        productInfo: startingProduct,
      },
    ].concat(
      allUniqueProductsWithContext.map((productRelationship) => ({
        long: productRelationship.productCoordinates[0],
        lat: productRelationship.productCoordinates[1],
        type: productRelationship.relationshipType,
        productInfo: productRelationship.product,
        relatedProductInfo: productRelationship.relatedProduct,
        relatedProductCoordinates: productRelationship.relatedProductCoordinates,
      }))
    );

    mapboxgl.accessToken = MAPBOX_TOKEN ?? '';
    const mapObject = new mapboxgl.Map({
      attributionControl: false,
      container: mapContainer.current,
      style: 'mapbox://styles/oksystems/ckuw6s0y00g1g17qgeslpig3h',
      center: center,
      maxZoom: 10,
      zoom: 1,
      dragRotate: false,
      projection: 'mercator',
    });

    const geojsonLinesData = productsWithRelatedProducts?.map((productRelationship) => ({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [productRelationship.productCoordinates[0], productRelationship.productCoordinates[1]],
          [productRelationship.relatedProductCoordinates[0], productRelationship.relatedProductCoordinates[1]],
        ],
      },
    }));

    const geojsonLines = {
      type: 'FeatureCollection',
      features: geojsonLinesData,
    };

    const geojsonPointsData =
      markersWithContext.map((marker) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [marker.long, marker.lat],
        },
        properties: {
          iconSize: [40, 40],
          pointType: marker.type,
          productInfo: marker.productInfo,
          relatedProductInfo: marker.relatedProductInfo,
          relatedProductCoordinates: marker.relatedProductCoordinates,
        },
      })) ?? [];

    let numberOfClusters = 1;
    let currentLineCoordinates = [];
    let childLineCoordinates = [];
    let normalLineCoordinates = [];
    let parentLineCoordinates = [];

    function updateLines(clusterData) {
      childLineCoordinates = [];
      normalLineCoordinates = [];
      parentLineCoordinates = [];

      const nonStartingClusters = clusterData.filter((cluster) => !cluster.properties.pointType.includes('starting'));

      const startingCluster = clusterData.filter((cluster) => cluster.properties.pointType.includes('starting'))[0];

      const possibleClusters = clusterData.map((cluster) => {
        return {
          coordinates: cluster.geometry.coordinates,
          pointCount: cluster.properties.point_count,
          pointType: cluster.properties.pointType,
        };
      });

      if (nonStartingClusters.length === 1) {
        nonStartingClusters.forEach((cluster) => {
          if (cluster.properties.pointType.includes('parent') && cluster.properties.pointType.includes('child')) {
            normalLineCoordinates.push({
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [startingCluster.geometry.coordinates, cluster.geometry.coordinates],
              },
            });
          } else if (cluster.properties.pointType.includes('parent')) {
            parentLineCoordinates.push({
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [startingCluster.geometry.coordinates, cluster.geometry.coordinates],
              },
            });
          } else {
            childLineCoordinates.push({
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [startingCluster.geometry.coordinates, cluster.geometry.coordinates],
              },
            });
          }
        });
      } else {
        nonStartingClusters.forEach((cluster) => {
          let clusterProductInfo = [];
          let relatedClusterProductInfo = [];
          let clusterRelationships = [];
          let isClusterPointCountMoreThanOne = cluster.properties.point_count > 1;

          if (cluster.properties.pointType.includes('-')) {
            clusterRelationships = cluster.properties.pointType.split('-');
          } else {
            clusterRelationships = [cluster.properties.pointType];
          }
          if (cluster.properties.productInfoData) {
            clusterProductInfo = [cluster.properties.productInfo, ...cluster.properties.productInfoData];
          } else {
            clusterProductInfo = [cluster.properties.productInfo];
          }
          if (cluster.properties.relatedProductInfoData) {
            relatedClusterProductInfo = [
              cluster.properties.relatedProductInfo,
              ...cluster.properties.relatedProductInfoData,
            ];
          } else {
            relatedClusterProductInfo = [cluster.properties.relatedProductInfo];
          }

          let childCounts = 0;
          let parentCounts = 0;
          let startingCounts = 0;

          clusterRelationships.forEach((relationship) => {
            if (relationship === 'child') {
              childCounts++;
            } else if (relationship === 'parent') {
              parentCounts++;
            } else if (relationship === 'starting') {
              startingCounts++;
            }
          });

          let isClusterDifferentTypes =
            (childCounts > 0 && parentCounts > 0) ||
            (childCounts > 0 && startingCounts > 0) ||
            (parentCounts > 0 && startingCounts > 0);
          let isClusterMultipleHitsAndDifferentTypes = isClusterPointCountMoreThanOne && isClusterDifferentTypes;

          for (let i = 0; i < clusterProductInfo.length; i++) {
            let relatedCoordinates = [
              parseFloat(relatedClusterProductInfo[i].organisation.addressList[0].longitude),
              parseFloat(relatedClusterProductInfo[i].organisation.addressList[0].latitude),
            ];

            let closestClusterCoordinates = [];
            let currentClosestDistance = 10000000;
            let currentClosestClusterPointCount = 1;

            possibleClusters.forEach((possibleCluster) => {
              let currentClusterDistance = haversineDistance(
                relatedCoordinates[1],
                relatedCoordinates[0],
                possibleCluster.coordinates[1],
                possibleCluster.coordinates[0]
              );

              if (currentClusterDistance < currentClosestDistance) {
                currentClosestDistance = currentClusterDistance;
                closestClusterCoordinates = possibleCluster.coordinates;
                currentClosestClusterPointCount = possibleCluster.pointCount;
              }
            });

            if (currentClosestClusterPointCount > 1 && isClusterMultipleHitsAndDifferentTypes) {
              normalLineCoordinates.push({
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: [closestClusterCoordinates, cluster.geometry.coordinates],
                },
              });
            } else if (clusterRelationships[i] === 'parent') {
              parentLineCoordinates.push({
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: [closestClusterCoordinates, cluster.geometry.coordinates],
                },
              });
            } else if (clusterRelationships[i] === 'child') {
              childLineCoordinates.push({
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: [closestClusterCoordinates, cluster.geometry.coordinates],
                },
              });
            } else {
              normalLineCoordinates.push({
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: [closestClusterCoordinates, cluster.geometry.coordinates],
                },
              });
            }
          }
        });
      }

      mapObject.getSource('line').setData({
        type: 'FeatureCollection',
        features: normalLineCoordinates,
      });

      mapObject.getSource('colored-childs').setData({
        type: 'FeatureCollection',
        features: childLineCoordinates.filter((line) => line !== undefined),
      });

      mapObject.getSource('colored-parents').setData({
        type: 'FeatureCollection',
        features: parentLineCoordinates.filter((line) => line !== undefined),
      });

      mapObject.moveLayer('line-layer', 'colored-parent-layer');
      mapObject.moveLayer('line-layer', 'colored-child-layer');
      mapObject.moveLayer('unclustered-point');
      mapObject.moveLayer('clusters-multiple');
      mapObject.moveLayer('cluster-count');
    }

    function updateClusters() {
      const clusterOptions = {
        radius: 40,
        maxZoom: 10,
        reduce: (accumulated, properties) => {
          if (!accumulated.productInfoData) {
            accumulated.productInfoData = [];
          }
          if (!accumulated.relatedProductInfoData) {
            accumulated.relatedProductInfoData = [];
          }
          if (!accumulated.relatedProductCoordinates) {
            accumulated.relatedProductCoordinates = [];
          }
          if (!accumulated.relationships) {
            accumulated.relationships = [];
          }

          if (properties.productInfo) {
            accumulated.productInfoData.push(properties.productInfo);
          }
          if (properties.relatedProductInfo) {
            accumulated.relatedProductInfoData.push(properties.relatedProductInfo);
          }
          if (properties.pointType) {
            accumulated.relationships.push(properties.pointType);
          }
          if (properties.relatedProductCoordinates && Array.isArray(properties.relatedProductCoordinates)) {
            accumulated.relatedProductCoordinates.push(properties.relatedProductCoordinates);
          }

          accumulated.pointType += `-${properties.pointType}`;
        },
      };

      const index = new Supercluster(clusterOptions).load(geojsonPointsData);
      const zoom = mapObject.getZoom();

      const wholeWorldBounds = [
        -180, // West longitude
        -90, // South latitude
        180, // East longitude
        90, // North latitude
      ];

      const bounds = wholeWorldBounds;
      const clusterData = index.getClusters(bounds, zoom);

      // assign ids if none
      clusterData.forEach((cluster, index) => {
        if (!cluster?.id) {
          cluster.properties.cluster_id = index;
        }
      });

      const source = mapObject.getSource('clusters');
      const isLineLayer =
        mapObject.getLayer('line-layer') !== undefined ||
        mapObject.getLayer('colored-child-layer') !== undefined ||
        mapObject.getLayer('colored-parent-layer') !== undefined;

      numberOfClusters = clusterData.length;

      if (source) {
        source.setData({ type: 'FeatureCollection', features: clusterData });
      }

      if (numberOfClusters > 0) {
        if (!isLineLayer) {
          mapObject.addSource('colored-childs', {
            type: 'geojson',
            data: [],
          });

          mapObject.addSource('colored-parents', {
            type: 'geojson',
            data: [],
          });

          mapObject.addLayer(
            {
              id: 'line-layer',
              type: 'line',
              source: 'line',
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': '#1A1A1A',
                'line-width': 3,
                'line-opacity': 0.5,
              },
            },
            'unclustered-point'
          );

          mapObject.addLayer({
            id: 'colored-child-layer',
            type: 'line',
            source: 'colored-childs',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#D04700',
              'line-width': 3,
              'line-opacity': 0.5,
            },
          });

          mapObject.addLayer({
            id: 'colored-parent-layer',
            type: 'line',
            source: 'colored-parents',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#4E7881',
              'line-width': 3,
              'line-opacity': 0.5,
            },
          });
        }
        try {
          updateLines(clusterData);
        } catch (error) {
          return;
        }
      }

      try {
        updateLines(clusterData);
      } catch (error) {
        return;
      }
    }

    if (mapObject) {
      mapObject.on('load', () => {
        const clusterOptions = {
          radius: 40,
          maxZoom: 10,
          reduce: (accumulated, properties) => {
            if (!accumulated.productInfoData) {
              accumulated.productInfoData = [];
            }
            if (!accumulated.relatedProductInfoData) {
              accumulated.relatedProductInfoData = [];
            }
            if (!accumulated.relatedProductCoordinates) {
              accumulated.relatedProductCoordinates = [];
            }
            if (!accumulated.relationships) {
              accumulated.relationships = [];
            }

            if (properties.productInfo) {
              accumulated.productInfoData.push(properties.productInfo);
            }
            if (properties.relatedProductInfo) {
              accumulated.relatedProductInfoData.push(properties.relatedProductInfo);
            }
            if (properties.pointType) {
              accumulated.relationships.push(properties.pointType);
            }
            if (properties.relatedProductCoordinates && Array.isArray(properties.relatedProductCoordinates)) {
              accumulated.relatedProductCoordinates.push(properties.relatedProductCoordinates);
            }

            accumulated.pointType += `-${properties.pointType}`;
          },
        };

        const index = new Supercluster(clusterOptions).load(geojsonPointsData);
        const zoom = mapObject.getZoom();
        const wholeWorldBounds = [
          -180, // West longitude
          -90, // South latitude
          180, // East longitude
          90, // North latitude
        ];
        const bounds = wholeWorldBounds;
        const clusterData = index.getClusters(bounds, zoom);

        numberOfClusters = clusterData.length;

        mapObject.addSource('clusters', {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: clusterData },
          cluster: false,
        });

        mapObject.loadImage('/img/childparentstarting.png', (error, image) => {
          if (error) {
            return;
          }

          mapObject.addImage('childparentstarting', image);
        });

        mapObject.loadImage('/img/childparent.png', (error, image) => {
          if (error) {
            return;
          }

          mapObject.addImage('childparent', image);
        });

        mapObject.loadImage('/img/parentstarting.png', (error, image) => {
          if (error) {
            return;
          }

          mapObject.addImage('parentstarting', image);
        });

        mapObject.loadImage('/img/childstarting.png', (error, image) => {
          if (error) {
            return;
          }

          mapObject.addImage('childstarting', image);
        });

        mapObject.loadImage('/img/child.png', (error, image) => {
          if (error) {
            return;
          }

          mapObject.addImage('child', image);
        });

        mapObject.loadImage('/img/parent.png', (error, image) => {
          if (error) {
            return;
          }

          mapObject.addImage('parent', image);
        });

        mapObject.addSource('line', {
          type: 'geojson',
          data: [],
        });

        mapObject.addSource('line-hidden', {
          type: 'geojson',
          data: currentLineCoordinates,
        });

        mapObject.addSource('static-lines', {
          type: 'geojson',
          data: geojsonLines,
        });

        mapObject.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'clusters',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': [
              'case',
              ['==', ['get', 'pointType'], 'parent'],
              '#507C85',
              ['==', ['get', 'pointType'], 'parent-2'],
              '#507C85',
              ['==', ['get', 'pointType'], 'child'],
              '#D04700',
              ['==', ['get', 'pointType'], 'child-2'],
              '#D04700',
              '#1A1A1A',
            ],
            'circle-radius': 20,
          },
        });

        mapObject.addLayer({
          id: 'clusters-multiple',
          type: 'symbol',
          source: 'clusters',
          filter: ['has', 'point_count'],
          layout: {
            'icon-image': [
              'case',
              [
                'all',
                ['in', 'child', ['get', 'pointType']],
                ['in', 'parent', ['get', 'pointType']],
                ['in', 'starting', ['get', 'pointType']],
                ['>', ['get', 'point_count'], 1],
              ],
              'childparentstarting',
              [
                'all',
                ['in', 'child', ['get', 'pointType']],
                ['in', 'starting', ['get', 'pointType']],
                ['>', ['get', 'point_count'], 1],
              ],
              'childstarting',
              [
                'all',
                ['in', 'parent', ['get', 'pointType']],
                ['in', 'starting', ['get', 'pointType']],
                ['>', ['get', 'point_count'], 1],
              ],
              'parentstarting',
              [
                'all',
                ['in', 'child', ['get', 'pointType']],
                ['in', 'parent', ['get', 'pointType']],
                ['>', ['get', 'point_count'], 1],
              ],
              'childparent',
              ['all', ['in', 'child', ['get', 'pointType']], ['>', ['get', 'point_count'], 1]],
              'child',
              ['all', ['in', 'parent', ['get', 'pointType']], ['>', ['get', 'point_count'], 1]],
              'parent',
              '',
            ],
            'icon-allow-overlap': true,
            'icon-size': 0.5,
          },
        });

        mapObject.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'clusters',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['Noto Sans HK Bold'],
            'text-size': 16,
          },
          paint: {
            'text-color': '#1A1A1A',
          },
        });

        mapObject.addControl(new mapboxgl.NavigationControl({ showCompass: false }));

        updateClusters();
      });

      mapObject.on('move', updateClusters);
      mapObject.on('zoom', updateClusters);
    }
    mapObject.on('mouseleave', 'unclustered-point', () => {
      mapObject.getCanvas().style.cursor = 'grab';

      // Trigger a repaint of the clusters layer
      mapObject.setPaintProperty('unclustered-point', 'circle-color', [
        'case',
        ['==', ['get', 'pointType'], 'parent'],
        '#507C85',
        ['==', ['get', 'pointType'], 'parent-2'],
        '#507C85',
        ['==', ['get', 'pointType'], 'child'],
        '#D04700',
        ['==', ['get', 'pointType'], 'child-2'],
        '#D04700',
        '#1A1A1A',
      ]);
    });

    mapObject.on('mouseenter', 'unclustered-point', (e) => {
      mapObject.getCanvas().style.cursor = 'pointer';

      // Get the cluster_id property from the hovered feature
      const hoveredClusterId = e.features[0].properties.cluster_id;

      // Trigger a repaint of the clusters layer
      mapObject.setPaintProperty('unclustered-point', 'circle-color', [
        'case',
        ['all', ['==', ['get', 'cluster_id'], ['literal', hoveredClusterId]], ['==', ['get', 'pointType'], 'parent']],
        '#72939A',
        ['all', ['==', ['get', 'cluster_id'], ['literal', hoveredClusterId]], ['==', ['get', 'pointType'], 'parent-2']],
        '#72939A',
        ['all', ['==', ['get', 'cluster_id'], ['literal', hoveredClusterId]], ['==', ['get', 'pointType'], 'child']],
        '#CE5A1E',
        ['all', ['==', ['get', 'cluster_id'], ['literal', hoveredClusterId]], ['==', ['get', 'pointType'], 'child-2']],
        '#CE5A1E',
        ['all', ['==', ['get', 'cluster_id'], ['literal', hoveredClusterId]], ['==', ['get', 'pointType'], 'starting']],
        '#767575',
        ['==', ['get', 'pointType'], 'parent'],
        '#507C85',
        ['==', ['get', 'pointType'], 'parent-2'],
        '#507C85',
        ['==', ['get', 'pointType'], 'child'],
        '#D04700',
        ['==', ['get', 'pointType'], 'child-2'],
        '#D04700',
        '#1A1A1A',
      ]);
    });

    mapObject.on('click', 'unclustered-point', (e) => {
      let archiveCardData = JSON.parse(e.features[0].properties.productInfo);

      if (Array.isArray(archiveCardData)) {
        archiveCardData = archiveCardData[0];

        if (archiveCardData.__typename === 'ProductChildProduct') {
          archiveCardData = archiveCardData.childProduct;
        }
      }

      setOpenMapPopup(true);
      setMapPopupInfo([archiveCardData]);
    });

    mapObject.on('mouseenter', 'clusters-multiple', () => {
      mapObject.getCanvas().style.cursor = 'pointer';
    });

    mapObject.on('mouseleave', 'clusters-multiple', () => {
      mapObject.getCanvas().style.cursor = 'grab';
    });

    mapObject.on('click', 'clusters-multiple', (e) => {
      let archiveCardDataMultiple = [];

      const productInfoData = JSON.parse(e.features[0].properties.productInfoData);

      if (e?.features[0]?.properties?.productInfo) {
        const productInfo = JSON.parse(e?.features[0]?.properties?.productInfo);

        archiveCardDataMultiple.push(productInfo);
      }

      productInfoData.forEach((archiveCardData) => {
        if (Array.isArray(archiveCardData)) {
          archiveCardData.forEach((dataPoint) => {
            archiveCardDataMultiple.push(dataPoint);
          });
        } else {
          archiveCardDataMultiple.push(archiveCardData);
        }
      });

      setOpenMapPopup(true);
      setMapPopupInfo(archiveCardDataMultiple.slice(0, e.features[0].properties.point_count));
    });
  }, [center, allProducts, startingProduct]);

  const mapObject = (
    <>
      <div className={styles.mapContainer}>
        <Text className={styles.mapTitle} bold size='lg'>
         {t('VISUAL_SUPPLY_CHAIN')} 
        </Text>
        <div
          id='mapbox-container'
          ref={mapContainer}
          style={{ borderRadius: 5, height: 400, margin: '0px -20px', zIndex: 1 }}
        ></div>
        <div className={styles.labelContent}>
          <div className={styles.labelContainer}>
            <div className={styles.label}>
              <div className={styles.labelCircle}></div>
              <Text className={styles.labelText} bold>
                {t('PRODUCT')}
              </Text>
            </div>
            <div className={styles.label}>
              <div className={styles.labelCircle} style={{ backgroundColor: '#D04700' }}></div>
              <Text className={styles.labelText}>
                {tHTML('UPSTREAM_TOWARDS_SUPPLIERS')} 
              </Text>
            </div>
            <div className={styles.label}>
              <div className={styles.labelCircle} style={{ backgroundColor: '#507C85' }}></div>
              <Text className={styles.labelText}>
                {tHTML('DOWNSTREAM_TOWARDS_CONSUMERS')} 
              </Text>
            </div>
            <div className={styles.label}>
              <img src='/img/multiple-hits-icon.png' alt='multiple hits icon' />
              <Text className={styles.labelText} bold>
                {t('MULTIPLE_HITS')}
              </Text>
            </div>
          </div>
        </div>
      </div>
      {openMapPopup && <MapPopup dismiss={() => setOpenMapPopup(false)} productInfoArray={mapPopupInfo} />}
    </>
  );

  return mapObject;
};

MapObject.propTypes = {
  startingProduct: PropTypes.object,
};

export default MapObject;
