import PropTypes from 'prop-types';
import { useContext, useState } from 'react';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import AppSettingsPopUp from 'OK/modules/popups/appSettings';
import InternationalizationContext from 'OK/util/context/internationalization';

export default function AppSettings(props) {
  const { className, themeOverride } = props;
  const internationalization = useContext(InternationalizationContext);

  const [showPopup, setShowPopup] = useState(false);

  let classNames = styles.regionButton;
  if (themeOverride) {
    classNames = `${classNames} ${styles[`theme_override_${themeOverride}`]}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  const language = internationalization.locale?.substring(0, 2).toUpperCase();
  const countryCode = internationalization.region;

  const onClickOpenAppSettings = () => {
    setShowPopup(true);
  };

  return (
    <>
      <button className={classNames} onClick={onClickOpenAppSettings}>
        <Icon className={styles.regionButtonIcon} height={20} name={ICONS.COG.name} width={20} />
        <div className={styles.region}>
          <p className={styles.language}>{language}</p>
          <p>{countryCode}</p>
        </div>
      </button>
      {showPopup && <AppSettingsPopUp dismiss={() => setShowPopup(false)} />}
    </>
  );
}

AppSettings.propTypes = {
  className: PropTypes.string,
  themeOverride: PropTypes.oneOf(['light', 'dark']),
};
