import { gql } from '@apollo/client';

export default class LogoImageMediaAssetModel {
  static GRAPHQL_TYPE = 'LogoImageMediaAsset';

  static fragmentName = 'LogoImageMediaAsset_Fragment';
  static fragment = gql`
    fragment ${LogoImageMediaAssetModel.fragmentName} on ${LogoImageMediaAssetModel.GRAPHQL_TYPE} {
      baseLogoImageURL
      createdBy
      createdDate
      id
      lastModifiedBy
      lastModifiedDate
      logoImageURL
      organisationId
      version
    }
  `;
}
