import PropTypes from 'prop-types';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Text from 'OK/components/text';
import ThemeContext from 'OK/util/context/theme';
import { setNativeValue } from 'OK/util/functions';
import useI18n from 'OK/util/hooks/useI18n';

/* eslint-disable react/display-name */
const Input = forwardRef((props, forwardedRef) => {
  const {
    accessoryButton,
    className,
    disabled = false,
    error,
    hasError = false,
    keepFocusOnClear = true,
    onBlur,
    onCancel,
    onChange,
    onClear,
    onFocus,
    placeholder,
    readOnly = false,
    rotateIcon = false,
    showCancelOnFocus = false,
    showClearButton = true,
    showErrorIcon = true,
    type,
    value,
    warning,
    withIcon,
    ...otherProps
  } = props;
  const _hasError = hasError || !!error;
  const innerRef = useRef();
  const ref = forwardedRef || innerRef;
  const theme = useContext(ThemeContext);
  const { t } = useI18n();

  // State
  const [isFocused, setIsFocused] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [_value, setValue] = useState(value);

  // Container classes
  let classNames = `${styles.container} ${className || ''} ${_hasError ? styles.error : ''}`;
  if (readOnly) {
    classNames = `${classNames} ${styles.readOnly}`;
  } else {
    if (isFocused) {
      classNames = `${classNames} ${styles.focused}`;
    } else if (isHovering) {
      classNames = `${classNames} ${styles.hovered}`;
    } else if (_value) {
      classNames = `${classNames} ${styles.hasValue}`;
    }
  }
  if (disabled) {
    classNames = `${classNames} ${styles.disabled}`;
  }

  // Input classes
  let inputClassNames = styles.input;
  if (type === 'date') {
    inputClassNames += ` ${styles.dateInput}`;
  }

  /* Handle events */

  // Clear by triggering change event with empty string
  const clearInput = (keepFocus = keepFocusOnClear) => {
    setNativeValue(ref.current, '');
    if (keepFocus) {
      ref.current.focus();
    }
  };

  const handleBlur = (e) => {
    // Unfocus asynchronously because the cancel button will not work correctly if the element is removed before it has
    // had a chance to fire the click event.
    setTimeout(() => {
      setIsFocused(false);

      if (onBlur) {
        onBlur(e);
      }
    }, 200);
  };

  // Trigger change event with new input value
  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    clearInput(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    clearInput();

    if (onClear) {
      onClear();
    }
  };

  const handleChange = (e) => {
    // See effect comments below for why we use React state in combination with an onChange prop event handler.
    setValue(e.target.value);

    if (onChange) {
      onChange(e);
    }
  };

  const handleFocus = (e) => {
    // Focus asychronously because we also blur asynchronously.
    setTimeout(() => {
      setIsFocused(true);

      if (onFocus) {
        onFocus(e);
      }
    }, 100);
  };

  // Icon
  let iconElement;
  if (_hasError && showErrorIcon) {
    // Show error icon
    iconElement = <img alt={t('IMG_ALT_INPUT_ERROR')} className={styles.icon} src='/icons/error_icon.svg' />;
  } else if (showClearButton && !readOnly && _value) {
    // Show clear button
    iconElement = (
      <button className={styles.iconButton} onClick={handleClear} tabIndex='-1' type='button'>
        <img alt={t('IMG_ALT_INPUT_CLEAR')} src={`/icons/small_x_${theme.name}.svg`} />
      </button>
    );
  } else if (withIcon) {
    // Show icon
    let iconClassName = styles.icon;
    if (rotateIcon) {
      iconClassName = `${iconClassName} ${styles.rotate}`;
    }
    if (typeof withIcon === 'string') {
      iconElement = <img alt='' className={iconClassName} src={withIcon} />;
    } else {
      iconElement = <span className={iconClassName}>{withIcon}</span>;
    }
  }

  // Effects

  /*
    Keep _value in sync with value from props.
    
    Input value is managed first by React state to keep UX good and performant. Issues arise if parent components are
    managing values asynchronously, for example with Apollo Client.

    See https://github.com/facebook/react/issues/955 and
    https://github.com/apollographql/apollo-client/issues/6827#issuecomment-673129661.
  */
  useEffect(() => {
    setValue(value);
  }, [value]);

  // Render

  const showCancelButton = isFocused && showCancelOnFocus;

  return (
    <>
      <div className={classNames} onMouseOut={() => setIsHovering(false)} onMouseOver={() => setIsHovering(true)}>
        <input
          autoCapitalize='off'
          autoComplete='off'
          autoCorrect='off'
          spellCheck='off'
          className={inputClassNames}
          disabled={disabled}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
          type={type}
          value={_value}
          {...otherProps}
        />
        {showCancelButton && (
          <Button className={styles.cancelButton} linkStyle onClick={handleCancel} tabIndex='-1'>
            {t('INPUT_CANCEL_BUTTON')}
          </Button>
        )}
        {!showCancelButton && accessoryButton && <div className={styles.accessoryButton}>{accessoryButton}</div>}
        {iconElement}
      </div>
      {(error || warning) && (
        <Text className={styles.errorMessage} size='sm' tint={error ? 'alert' : 'notification'}>
          {error || warning}
        </Text>
      )}
    </>
  );
});

export default Input;

Input.propTypes = {
  accessoryButton: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hasError: PropTypes.bool,
  keepFocusOnClear: PropTypes.bool,
  onBlur: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  rotateIcon: PropTypes.bool,
  showCancelOnFocus: PropTypes.bool,
  showClearButton: PropTypes.bool,
  showErrorIcon: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  withIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
