import PropTypes from 'prop-types';
import { memo, useContext, useEffect, useMemo, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Input from 'OK/components/input';
import Tag from 'OK/components/tag';
import TextArea from 'OK/components/textarea';
import LanguageSearch from 'OK/modules/languageSearch';
import ThemeContext from 'OK/util/context/theme';
import useI18n from 'OK/util/hooks/useI18n';
import { languageByIso } from 'OK/util/i18n';

/**
 * A form input field which contains values in multiple languages. Will render a text input field for each language
 * specified.
 *
 * @param {object} props
 * @param {string} [props.addLanguageButtonTitle='Add another language'] The text of the button to add another language
 * for the field.
 * @param {string} [props.className] The field's class.
 * @param {string} [props.inputPlaceholder] The placeholder for each input. If {language} is present in the string, it
 * will be replaced with the name of the language that the input represents.
 * @param {(blurredLanguageIso: string, values: object[]) => void} [props.onBlur] Event handler for when the field blurs.
 * @param {(changedLanguageIso: string, values: object[]) => void} [props.onChange] Event handler for when a value
 * changes. `values` is the updated array of values, and `changedLanguageIso` is the ISO code of the language that was
 * actually changed.
 * @param {(focusedLanguageIso: string) => void} [props.onFocus] Event handler for when a field is
 * focused.
 * @param {number} [props.showWarningIfExceedsLength] If a value exceeds this character length, a warning will be shown
 * that the value may be shortened on smaller screens.
 * @param {array} [props.values] The values of the field. Must be an array of objects containing the value in each
 * covered language. Objects should have 2 keys: `languageIso` for the language ISO code, and `value` for the text in
 * that language.
 */
export default function FormFieldI18n(props) {
  /* Variables */

  const { t } = useI18n();
  const {
    addLanguageButtonClassName,
    addLanguageButtonTitle = t('I18N_FIELD_ADD_LANGUAGE_BUTTON_DEFAULT'),
    className,
    disabled = false,
    inputClassName,
    inputContainerClassName,
    inputPlaceholder = '',
    onBlur,
    onChange,
    onFocus,
    showWarningIfExceedsLength,
    useTextAreas = false,
    values = [],
    ...otherProps
  } = props;

  // State

  // Use internal state after initial value is passed because usage with Apollo cache breaks correct positioning of the cursor.
  // See https://github.com/apollographql/apollo-client/issues/3338 and https://github.com/facebook/react/issues/955
  // If the ability to update values from parent is needed, consider adding an effect to sync them.
  const [valuesState, setValuesState] = useState(values);

  const languageIsos = valuesState.map((v) => v.languageIso);
  const languagesArray = useMemo(() => languageIsos.map((iso) => languageByIso(iso)), [languageIsos]);

  /* Methods */

  const addTranslation = (languageIso) => {
    // Only add the language if it isn't already present
    if (languageIsos.findIndex((iso) => iso === languageIso) >= 0) {
      okerror(`Language ${languageIso} is already present.`);
      return;
    }

    const language = languageByIso(languageIso);

    const value = { languageIso: language.iso, value: '' };
    const newValues = [...valuesState, value];
    triggerChange(newValues, languageIso);
  };

  const findIndexOfValueForLanguage = (languageIso) =>
    valuesState.findIndex((value) => value.languageIso.toLowerCase() === languageIso.toLowerCase());

  const removeTranslation = (languageIso) => {
    const languageIndex = findIndexOfValueForLanguage(languageIso);
    if (languageIndex === -1) {
      okerror(`Language ${languageIso} was not found.`);
      return;
    }

    const newValues = [...valuesState];
    newValues.splice(languageIndex, 1);

    triggerChange(newValues, languageIso);
  };

  const triggerChange = (newValues, changedLanguageIso) => {
    setValuesState(newValues);
    onChange && onChange(changedLanguageIso, newValues);
  };

  /* Events */

  const _onBlur = (languageIso) => {
    onBlur && onBlur(languageIso, valuesState);
  };

  const _onChange = (e, languageIso) => {
    const languageIndex = findIndexOfValueForLanguage(languageIso);
    if (languageIndex === -1) {
      okerror(`Invalid language ${languageIso}`, valuesState);
      return;
    }

    const value = { ...valuesState[languageIndex] };
    value.value = e.target.value;
    const newValues = [...valuesState];
    newValues.splice(languageIndex, 1, value);

    triggerChange(newValues, languageIso);
  };

  const onChoseLanguage = (languageIso) => addTranslation(languageIso);

  // Handling clicks on the label because otherwise clicks anywhere in the label will trigger the Remove button
  const onClickLabel = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const _onFocus = async (languageIso) => {
    onFocus && onFocus(languageIso);
  };

  /* Effects */

  // Sync updates from props to state
  useEffect(() => {
    setValuesState(values);
  }, [values]);

  /* Render */

  return (
    <div className={className} {...otherProps}>
      <div className={inputContainerClassName}>
        {valuesState.map((valueObj, index) => {
          const lang = languagesArray[index];
          return (
            <Field
              className={inputClassName}
              disabled={disabled}
              key={valueObj.languageIso}
              inputPlaceholder={inputPlaceholder}
              onBlur={_onBlur}
              onChange={_onChange}
              onClickLabel={onClickLabel}
              onFocus={_onFocus}
              onRemoveTranslation={removeTranslation}
              language={lang}
              showWarningIfExceedsLength={showWarningIfExceedsLength}
              useTextAreas={useTextAreas}
              valueObj={valueObj}
            />
          );
        })}
      </div>
      <LanguageSearch
        addLanguageButtonTitle={addLanguageButtonTitle}
        className={addLanguageButtonClassName}
        disabled={disabled}
        excludeLanguages={languageIsos}
        onSelectedLanguage={onChoseLanguage}
      />
    </div>
  );
}

FormFieldI18n.propTypes = {
  addLanguageButtonClassName: PropTypes.string,
  addLanguageButtonTitle: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  showWarningIfExceedsLength: PropTypes.number,
  useTextAreas: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      languageIso: PropTypes.string.isRequired,
      loading: PropTypes.bool,
      optional: PropTypes.bool,
      value: PropTypes.string.isRequired,
      warning: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    })
  ),
};

// The field component
const Field = memo((props) => {
  // TODO: optimize performance by using useCallback for the callbacks here so memoization can work correctly
  const {
    className,
    disabled = false,
    inputPlaceholder,
    onBlur,
    onChange,
    onClickLabel,
    onFocus,
    onRemoveTranslation,
    language,
    showWarningIfExceedsLength,
    useTextAreas,
    valueObj,
  } = props;
  const { error, loading = false, optional = true, value = '', warning } = valueObj;
  const { t, tHTML } = useI18n();
  const _inputPlaceholder = inputPlaceholder.replace('{{language}}', t(`LANG_${language.iso.toUpperCase()}`));
  const theme = useContext(ThemeContext);
  // const error = errors.find((e) => e.languageIso === languageIso);

  let classNames = styles.label;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <label className={classNames} key={language.iso} onClick={onClickLabel}>
      <div className={styles.labelText}>
        <Tag size='md'>{language.iso.slice(0, 2).toUpperCase()}</Tag>
        &nbsp;<strong>{t(`LANG_${language.iso.toUpperCase()}`)}</strong>
        {optional && (
          <Button
            className={styles.removeLanguageButton}
            disabled={disabled}
            linkStyle
            onClick={() => onRemoveTranslation(language.iso)}
            tint='alert'
          >
            {t('REMOVE')}
          </Button>
        )}
      </div>
      {useTextAreas ? (
        <TextArea
          className={styles.input}
          disabled={loading || disabled}
          error={error}
          key={language.iso}
          loading={loading}
          onBlur={() => onBlur(language.iso)}
          onChange={(e) => onChange(e, language.iso)}
          onFocus={() => onFocus(language.iso)}
          placeholder={_inputPlaceholder}
          value={value}
          warning={!error && warning}
        />
      ) : (
        <Input
          className={styles.input}
          disabled={loading || disabled}
          error={error}
          key={language.iso}
          onBlur={() => onBlur(language.iso)}
          onChange={(e) => onChange(e, language.iso)}
          onFocus={() => onFocus(language.iso)}
          placeholder={_inputPlaceholder}
          rotateIcon={loading}
          showClearButton={!loading}
          showErrorIcon={false}
          type='text'
          value={value}
          warning={!error && warning}
          withIcon={loading ? `/icons/spinner_${theme.name}.svg` : undefined}
        />
      )}
      {showWarningIfExceedsLength && value.length > showWarningIfExceedsLength && (
        <p className={styles.inputMessage}>{tHTML('I18N_FIELD_LENGTH_WARNING')}</p>
      )}
    </label>
  );
});

Field.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClickLabel: PropTypes.func,
  onFocus: PropTypes.func,
  onRemoveTranslation: PropTypes.func,
  language: PropTypes.shape({
    iso: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  showWarningIfExceedsLength: PropTypes.number,
  useTextAreas: PropTypes.bool.isRequired,
  valueObj: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    loading: PropTypes.bool,
    optional: PropTypes.bool,
    value: PropTypes.string.isRequired,
    warning: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
};
