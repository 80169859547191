import PropTypes from 'prop-types';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './styles.module.scss';

/**
 * Slide in / out an element from the side.
 *
 * @param {object} props
 * @param {boolean} [props.fromRightSide=true] Slide in/out from the right side.
 * @param {number} [props.sideOffsetPx=0] How much beyond the container bounds the transition element should go to be
 * fully hidden.
 */
export default function SlideInOutTransition(props) {
  const { children, fromRightSide = true, sideOffsetPx = 0, ...otherProps } = props;

  const transitionRef = useRef();

  const transitionStyles = {
    entering: {},
    entered: {},
    exiting: {},
    exited: {},
  };
  if (fromRightSide) {
    transitionStyles.entering = {
      position: 'absolute',
      left: 0,
    };
    transitionStyles.entered = {
      position: 'static',
      left: 0,
    };
    transitionStyles.exiting = {
      position: 'absolute',
      left: `calc(100% + ${sideOffsetPx}px)`,
    };
    transitionStyles.exited = {
      position: 'absolute',
      left: `calc(100% + ${sideOffsetPx}px)`,
    };
  } else {
    transitionStyles.entering = {
      position: 'absolute',
      right: 0,
    };
    transitionStyles.entered = {
      position: 'static',
      right: 0,
    };
    transitionStyles.exiting = {
      position: 'absolute',
      right: `calc(100% + ${sideOffsetPx}px)`,
    };
    transitionStyles.exited = {
      position: 'absolute',
      right: `calc(100% + ${sideOffsetPx}px)`,
    };
  }

  let classNames = styles.transitionContainer;
  if (fromRightSide) {
    classNames = `${classNames} ${styles.fromRight}`;
  } else {
    classNames = `${classNames} ${styles.fromLeft}`;
  }

  return (
    <div style={{ position: 'relative' }}>
      <CSSTransition nodeRef={transitionRef} timeout={300} {...otherProps}>
        {(transitionState) => (
          <div className={classNames} ref={transitionRef} style={transitionStyles[transitionState]}>
            {children}
          </div>
        )}
      </CSSTransition>
    </div>
  );
}

SlideInOutTransition.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fromRightSide: PropTypes.bool,
  sideOffsetPx: PropTypes.number,
};
