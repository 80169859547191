import { gql } from '@apollo/client';

import { request } from '..';
import { API_URL } from '../utils';

export const registerForWebinarMutation = gql`
  mutation RegisterForWebinar($email: String!, $marketingOptIn: Boolean, $name: String, $webinarId: String!) {
    participant: registerParticipantForWebinar(
      email: $email
      marketingOptInStatus: $marketingOptIn
      name: $name
      webinarId: $webinarId
    ) {
      email
    }
  }
`;

export const updateWebinarRegistrationMutation = gql`
  mutation UpdateWebinarRegistration(
    $companyName: String
    $countryCode: ECountryCode
    $email: String!
    $marketingOptInStatus: Boolean
    $province: String
    $webinarId: String!
  ) {
    participant: updateParticipantRegistrationForWebinar(
      companyName: $companyName
      countryCode: $countryCode
      email: $email
      marketingOptInStatus: $marketingOptInStatus
      province: $province
      webinarId: $webinarId
    ) {
      email
    }
  }
`;

export async function requestWebinarRecording(email) {
  const parameters = { email };

  const req = await request(`${API_URL}/utility/sales/webinar/requestRecording`, {
    addAuthorisationHeaders: false,
    method: 'POST',
    parameters,
  });
  return req;
}
