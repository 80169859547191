import { ICONS } from 'OK/components/icon';
import ProductModel from 'OK/models/product';

export function suggestionObjectForProduct(product, locale) {
  return {
    icon: ICONS.PRODUCT.name,
    key: product.id,
    title: ProductModel.localizedNameForProduct(product, locale),
    subtitle: product.REFID,
  };
}
