import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Bar as BarChart, Line as LineChart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import ButtonGroup from 'OK/components/buttonGroup';
import { Carousel, Slide } from 'OK/components/carousel';
import Chip from 'OK/components/chip';
import Grade from 'OK/components/grade';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import SearchInputAsButton, { DISPLAY_MODE } from 'OK/components/input/search/asButton';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import Notice from 'OK/components/notice';
import PageTitle from 'OK/components/pageTitle';
import Progressable from 'OK/components/progressable';
import SearchSuggestions from 'OK/components/searchSuggestions';
import { suggestionObjectForProduct } from 'OK/components/searchSuggestions/util/product';
import { suggestionObjectForUser } from 'OK/components/searchSuggestions/util/user';
import Separator from 'OK/components/separator';
import Tag from 'OK/components/tag';
import TestArchiveCard from 'OK/components/test/archiveCard';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import ProductModel from 'OK/models/product';
import EditWorkflowPageBasicsSection from 'OK/modules/pages/workflow/[refid]/edit/basicSection';
import TestPopup from 'OK/modules/test-popup';
import { InspectionAssetAdminArchivePageQuery } from 'OK/networking/inspectionAssets';
import {
  useCreateInspectionTestAssetAPI,
  useLinkInspectionTestAssetAPI,
  useUnlinkInspectionTestAssetAPI,
} from 'OK/networking/inspectionAssets/hooks';
import {
  AggregatedInspectionLogDataForInspectionQuery,
  LatestInspectionLogsForInspectionAssetQuery,
} from 'OK/networking/inspectionLogs';
import { searchQuery } from 'OK/networking/search';
import {
  generateAreaChartConfig,
  generateBarChartConfig,
  generateDashedLineAnnotationConfig,
} from 'OK/util/chartjs/config';
import { formatChartDataForAggregatedLogs, formatChartDataForLatestLogs } from 'OK/util/chartjs/format';
import ChartJSHtmlLegendPlugin from 'OK/util/chartjs/htmlLegendPlugin';
import { LATEST_LOGS_CHART_TYPE } from 'OK/util/chartjs/types';
import {
  DEBOUNCE_TIMING_MS_SHORT,
  OK_QUALITY_AQL_CRITICAL_PERCENT,
  OK_QUALITY_AQL_MAJOR_PERCENT,
  OK_QUALITY_AQL_MINOR_PERCENT,
} from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import UIContext from 'OK/util/context/ui';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import { formatNumber, formatOkid, formatPercentage, formatPoints } from 'OK/util/formatting';
import isAuthorised from 'OK/util/functions/isAuthorised';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';

if (typeof window !== 'undefined') {
  Chart.register(...registerables, annotationPlugin, ChartJSHtmlLegendPlugin);
}

const AQL_CRITICAL = OK_QUALITY_AQL_CRITICAL_PERCENT * 100;
const AQL_MAJOR = OK_QUALITY_AQL_MAJOR_PERCENT * 100;
const AQL_MINOR = OK_QUALITY_AQL_MINOR_PERCENT * 100;
const CHART_HEIGHT = 200;

function InsufficientPerformanceDataNoLogsMessage() {
  const { t } = useI18n();
  return (
    <Text bold tint='notification'>
      {t('INSUFFICIENT_DATA_FOR_LOG_PERFORMANCE_CHART')}
    </Text>
  );
}

function InsufficientPerformanceDataForOptionsMessage() {
  const { t } = useI18n();
  return (
    <Text bold tint='notification'>
      {t('NO_DATA_FOR_CONFIGURED_LOG_PERFORMANCE_CHART')}
    </Text>
  );
}

export default function EditInspectionPage(props) {
  /* Variables */

  const { REFIDasProp } = props;
  const { locale, t, tHTML } = useI18n();

  // Essential data

  const [getInspection, getInspectionResult] = useLazyQuery(InspectionAssetAdminArchivePageQuery);
  const inspectionAsset = getInspectionResult.data?.inspectionAsset;

  // Misc

  const [authenticated] = useAuthentication();
  const authorisationLevel = useAuthorisationLevel(inspectionAsset);
  const authorised = isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.COWORKER);
  const router = useRouter();
  const showInPopup = useSelector((state) => state.app.showInPopup);
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const REFID = router.query.refId || REFIDasProp;
  const theme = useContext(ThemeContext);

  // State

  const [chartCoworkerHighlight, setChartCoworkerHighlight] = useState(null);
  const [chartDisplayType, setChartDisplayType] = useState(LATEST_LOGS_CHART_TYPE.POINTS);
  const [chartFilterIncludeInternalLogs, setChartFilterIncludeInternalLogs] = useState(false);
  const [chartOptionShowQuantity, setChartOptionShowQuantity] = useState(false);
  const [chartProductHighlight, setChartProductHighlight] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [deleteToastMessage, setDeleteToastMessage] = useState(null);
  const [searchCoworkersFocused, setSearchCoworkersFocused] = useState(false);
  const [searchCoworkersString, setSearchCoworkersString] = useState('');
  const [searchProductsFocused, setSearchProductsFocused] = useState(false);
  const [searchProductsString, setSearchProductsString] = useState('');
  const [searchTestsString, setSearchTestsString] = useState('');
  const [testPopupTestRefId, setTestPopupTestRefId] = useState(null);
  const [testsError, setTestsError] = useState(null);

  const showDataHighlight = chartProductHighlight !== null || chartCoworkerHighlight !== null;

  // Refs

  const basicsSectionRef = useRef();
  const linksSectionRef = useRef();
  const searchCoworkersInputRef = useRef();
  const searchProductsInputRef = useRef();
  const testsCarouselRef = useRef();

  /* API */

  const [createInspectionTestAPI, createInspectionTestAPIResult] = useCreateInspectionTestAssetAPI();
  const [getAggregatedLogDataAPI, getAggregatedLogDataAPIResult] = useLazyQuery(
    AggregatedInspectionLogDataForInspectionQuery,
    { fetchPolicy: 'cache-and-network' }
  );
  const [getAggregatedLogDataForHighlightAPI, getAggregatedLogDataForHighlightAPIResult] = useLazyQuery(
    AggregatedInspectionLogDataForInspectionQuery,
    { fetchPolicy: 'cache-and-network' }
  );
  const [getLatestLogsAPI, getLatestLogsAPIResult] = useLazyQuery(LatestInspectionLogsForInspectionAssetQuery);
  const [linkTestAPI] = useLinkInspectionTestAssetAPI();
  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);
  const [searchCoworkersAPI, searchCoworkersAPIResult] = useLazyQuery(searchQuery);
  const [searchProductsAPI, searchProductsAPIResult] = useLazyQuery(searchQuery);
  const unlinkTestAPI = useUnlinkInspectionTestAssetAPI();

  const latestLogs = useMemo(
    () => getLatestLogsAPIResult.data?.inspectionLogs ?? [],
    [getLatestLogsAPIResult.data?.inspectionLogs]
  );

  /* Methods */

  const createTest = useCallback(() => {
    createInspectionTestAPI(inspectionAsset.id).then((result) => {
      if (result.data?.inspectionTestAsset) {
        setTestPopupTestRefId(result.data.inspectionTestAsset.testAsset.REFID);
        testsCarouselRef.current.scrollToSlideAtIndex(inspectionAsset.inspectionTestAssetList.length);
      }
    });
  }, [createInspectionTestAPI, inspectionAsset?.id, inspectionAsset?.inspectionTestAssetList?.length]);

  const filterByProduct = useCallback(
    (productId) => {
      const product = searchProductsAPIResult.data.search.resultList.find(
        (r) => r.productData.id === productId
      ).productData;
      setChartProductHighlight(product);
      setSearchProductsString('');
      searchProductsInputRef.current.setDisplayMode(DISPLAY_MODE.BUTTON);
    },
    [searchProductsAPIResult.data?.search?.resultList]
  );

  const filterByUser = useCallback(
    (userId) => {
      const user = searchCoworkersAPIResult.data.search.resultList.find((r) => r.userData.id === userId).userData;
      setChartCoworkerHighlight(user);
      setSearchCoworkersString('');
      searchCoworkersInputRef.current.setDisplayMode(DISPLAY_MODE.BUTTON);
    },
    [searchCoworkersAPIResult.data?.search?.resultList]
  );

  // eslint-disable-next-line no-unused-vars
  const promptConfirmDelete = useCallback(() => {}, []);

  const searchCoworkersDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchCoworkersAPI({
          variables: {
            searchPaginationDataByDataType: [
              {
                dataType: 'USER',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchCoworkersAPI]
  );

  const searchProductsDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchProductsAPI({
          variables: {
            searchPaginationDataByDataType: [
              {
                dataType: 'PRODUCT',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchProductsAPI]
  );

  const searchTestsDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchAPI({
          variables: {
            searchPaginationDataByDataType: [
              {
                dataType: 'TEST_ASSET',
                searchPaginationData: { pageSize: 10, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchAPI]
  );

  // Event handlers

  const onClickLinkTest = useCallback(
    (testAssetId, setLoading) => {
      setLoading(true);
      linkTestAPI(inspectionAsset.id, testAssetId)
        .then(() => {
          setSearchTestsString('');
        })
        .catch(() => {
          setTestsError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [inspectionAsset?.id, linkTestAPI, t]
  );

  const onClickUnlinkTest = useCallback(
    (testAssetId) => {
      unlinkTestAPI(inspectionAsset.id, testAssetId).catch(() => {
        setTestsError(t('ERROR_GENERIC'));
      });
    },
    [inspectionAsset?.id, t, unlinkTestAPI]
  );

  const onSearchCoworkers = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setSearchCoworkersString(newSearchString);

      if (newSearchString.length > 1) {
        searchCoworkersDebounced(newSearchString);
      }
    },
    [searchCoworkersDebounced]
  );

  const onSearchProducts = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setSearchProductsString(newSearchString);

      if (newSearchString.length > 1) {
        searchProductsDebounced(newSearchString);
      }
    },
    [searchProductsDebounced]
  );

  const onSearchTests = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setSearchTestsString(newSearchString);

      if (newSearchString.length > 1) {
        searchTestsDebounced(newSearchString);
      }
    },
    [searchTestsDebounced]
  );

  /* Effects */

  // Load inspection
  useEffect(() => {
    if (authenticated && REFID) {
      getInspection({ variables: { refId: REFID } });
    }
  }, [REFID, authenticated, getInspection]);

  // Redirect to 404 if not authorised
  useEffect(() => {
    const apiCompleted =
      getInspectionResult.called &&
      (getInspectionResult.networkStatus === NetworkStatus.ready ||
        getInspectionResult.networkStatus === NetworkStatus.error);
    if (apiCompleted && !inspectionAsset) {
      router.replace('/404');
    }
  }, [
    authorisationLevel,
    authorised,
    getInspectionResult.called,
    getInspectionResult.networkStatus,
    inspectionAsset,
    router,
  ]);

  // Get chart data
  useEffect(() => {
    if (authenticated && inspectionAsset?.id) {
      getLatestLogsAPI({
        variables: {
          includeInternalLogs: chartFilterIncludeInternalLogs,
          inspectionAssetId: inspectionAsset.id,
        },
      });
      getAggregatedLogDataAPI({
        variables: {
          includeInternalLogs: chartFilterIncludeInternalLogs,
          inspectionAssetId: inspectionAsset.id,
        },
      });
      if (chartCoworkerHighlight || chartProductHighlight) {
        let variables = {
          includeInternalLogs: chartFilterIncludeInternalLogs,
          inspectionAssetId: inspectionAsset.id,
        };
        if (chartCoworkerHighlight) {
          variables.filterByInspectorIdList = [chartCoworkerHighlight.id];
        }
        if (chartProductHighlight) {
          variables.filterByProductIdList = [chartProductHighlight.id];
        }
        getAggregatedLogDataForHighlightAPI({ variables });
      }
    }
  }, [
    authenticated,
    chartCoworkerHighlight,
    chartFilterIncludeInternalLogs,
    chartProductHighlight,
    getAggregatedLogDataAPI,
    getAggregatedLogDataForHighlightAPI,
    getLatestLogsAPI,
    inspectionAsset?.id,
  ]);

  /* Render */

  // eslint-disable-next-line no-unused-vars
  const disableActions = !inspectionAsset;
  const loadingPerformanceChartData =
    !getLatestLogsAPIResult.called ||
    getLatestLogsAPIResult.loading ||
    !getAggregatedLogDataAPIResult.called ||
    getAggregatedLogDataAPIResult.loading ||
    (showDataHighlight && !getAggregatedLogDataForHighlightAPIResult.called) ||
    getAggregatedLogDataForHighlightAPIResult.loading;
  const renderChartsSection = getLatestLogsAPIResult.called && getAggregatedLogDataAPIResult.called;

  const pageSections = [
    { ref: basicsSectionRef, title: t('ARCHIVE_PAGE_SECTION_BASICS') },
    { ref: linksSectionRef, title: t('ARCHIVE_PAGE_SECTION_LINKS') },
  ];

  const pageSubmenu = (
    <div className={styles.submenu}>
      <Text className={styles.submenuTitle}>
        {t('WORKFLOW_ARCHIVE_CARD_TITLE')}{' '}
        <Tag className={styles.submenuRefId} size='sm'>
          {REFID}
        </Tag>
      </Text>
      <div className={styles.submenuButtons}>
        <div className={`${styles.submenuButton} ${styles.submenuButtonContainer}`}>
          {/* <Button
            className={styles.submenuButton}
            disabled={disableActions}
            icon={ICONS.TRASH.name}
            linkStyle
            onClick={promptConfirmDelete}
            tint='alert'
          >
            {useDesktopLayout && 'Delete'}
          </Button> */}
          <FadeInOutTransition appear in={!!deleteToastMessage}>
            <Toast className={styles.shareToast}>{deleteToastMessage}</Toast>
          </FadeInOutTransition>
        </div>
      </div>
      <Grade
        className={styles.reliabilityScore}
        score={inspectionAsset?.reliabilityPointForPublishedLogs}
        type='reliability'
      />
    </div>
  );

  const hasRecentLogsForProduct = useMemo(() => {
    if (!latestLogs.length || !chartProductHighlight) {
      return false;
    }

    let hasRecentLogs = false;
    for (let x = 0; x < latestLogs.length; x++) {
      const log = latestLogs[x];
      if (log.productId === chartProductHighlight.id) {
        hasRecentLogs = true;
        break;
      }
    }

    return hasRecentLogs;
  }, [chartProductHighlight, latestLogs]);

  const hasRecentLogsForCoworker = useMemo(() => {
    if (!latestLogs.length || !chartCoworkerHighlight) {
      return false;
    }

    let hasRecentLogs = false;
    for (let x = 0; x < latestLogs.length; x++) {
      const log = latestLogs[x];
      if (log.createdBy === chartCoworkerHighlight.id) {
        hasRecentLogs = true;
        break;
      }
    }

    return hasRecentLogs;
  }, [chartCoworkerHighlight, latestLogs]);

  const chartConfigs = useMemo(() => {
    if (
      latestLogs.length > 1 &&
      getAggregatedLogDataAPIResult.data?.aggregatedData &&
      (!showDataHighlight || getAggregatedLogDataForHighlightAPIResult.data?.aggregatedData)
    ) {
      const aggregatedLogData = getAggregatedLogDataAPIResult.data.aggregatedData;
      const aggregatedLogDataForHighlight = showDataHighlight
        ? getAggregatedLogDataForHighlightAPIResult.data?.aggregatedData
        : null;

      let annotations;
      let yValues;
      if (chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS) {
        yValues = [...latestLogs.map((l) => l.reliabilityPoint), aggregatedLogData.averageReliabilityPoint];
      } else {
        switch (chartDisplayType) {
          case LATEST_LOGS_CHART_TYPE.MINOR_AQL: {
            if (chartOptionShowQuantity) {
              yValues = [...latestLogs.map((l) => l.sampleMinorFailures), aggregatedLogData.sampleMinorAQL, 10];
            } else {
              annotations = {
                AQLLimitAnnotation: generateDashedLineAnnotationConfig(AQL_MINOR, { theme }),
              };
              yValues = [...latestLogs.map((l) => l.minorAQL), aggregatedLogData.sampleMinorAQL, AQL_MINOR];
            }
            break;
          }
          case LATEST_LOGS_CHART_TYPE.MAJOR_AQL: {
            if (chartOptionShowQuantity) {
              yValues = [...latestLogs.map((l) => l.sampleMajorFailures), aggregatedLogData.sampleMajorAQL, 10];
            } else {
              annotations = {
                AQLLimitAnnotation: generateDashedLineAnnotationConfig(AQL_MAJOR, { theme }),
              };
              yValues = [...latestLogs.map((l) => l.majorAQL), aggregatedLogData.sampleMajorAQL, AQL_MINOR];
            }
            break;
          }
          case LATEST_LOGS_CHART_TYPE.CRITICAL_AQL: {
            if (chartOptionShowQuantity) {
              yValues = [...latestLogs.map((l) => l.sampleCriticalFailures), aggregatedLogData.sampleCriticalAQL, 10];
            } else {
              annotations = {
                AQLLimitAnnotation: generateDashedLineAnnotationConfig(AQL_CRITICAL, { theme }),
              };
              yValues = [...latestLogs.map((l) => l.criticalAQL), aggregatedLogData.sampleCriticalAQL, AQL_MINOR];
            }
            break;
          }
          default:
            break;
        }
      }

      const maxYValue = Math.max(...yValues) * 1.05;

      let latestLogsChartData = formatChartDataForLatestLogs(latestLogs, t, {
        chartDisplayType,
        chartHeight: CHART_HEIGHT,
        dataBackgroundColorRGBA: 'rgba(208, 71, 0, 1)',
        highlightCoworkerId: chartCoworkerHighlight?.id,
        highlightProductId: chartProductHighlight?.id,
        showQuantity: chartOptionShowQuantity,
        theme,
      });
      let aggregatedChartData = formatChartDataForAggregatedLogs(aggregatedLogData, aggregatedLogDataForHighlight, t, {
        chartDisplayType,
        chartHeight: CHART_HEIGHT,
        dataBackgroundColorRGBA: 'rgba(208, 71, 0, 1)',
        showQuantity: chartOptionShowQuantity,
      });

      return {
        aggregatedChart: generateBarChartConfig(aggregatedChartData, {
          annotations,
          maxYValue,
          theme,
          tickFormatter: (tickValue) => {
            if (chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS) {
              return formatPoints(tickValue);
            }

            return chartOptionShowQuantity
              ? parseInt(tickValue, 10)
              : formatPercentage(tickValue, { multiplyBy100: false, showPercentSign: false });
          },
          tooltipLabelCallback: (tooltip) => {
            if (chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS || chartOptionShowQuantity) {
              return formatNumber(tooltip.parsed.y);
            }

            return formatPercentage(tooltip.parsed.y, { multiplyBy100: false });
          },
        }),
        latestLogsChart: generateAreaChartConfig(latestLogsChartData, {
          annotations,
          legendContainerId: 'chartLegendContainer',
          maxYValue,
          theme,
          tooltipLabelCallback: (tooltip) => {
            if (tooltip.dataset.hideTooltip) {
              return null;
            }

            if (typeof tooltip.parsed.y === 'undefined' || tooltip.parsed.y === null) {
              return t('NO_DATA');
            }

            if (chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS) {
              return formatNumber(tooltip.parsed.y);
            }

            return chartOptionShowQuantity
              ? formatNumber(tooltip.parsed.y)
              : formatPercentage(tooltip.parsed.y, { multiplyBy100: false });
          },
        }),
      };
    }

    return null;
  }, [
    chartCoworkerHighlight,
    chartDisplayType,
    chartOptionShowQuantity,
    chartProductHighlight,
    getAggregatedLogDataAPIResult.data?.aggregatedData,
    getAggregatedLogDataForHighlightAPIResult.data?.aggregatedData,
    latestLogs,
    showDataHighlight,
    t,
    theme,
  ]);

  const testResults = useMemo(() => {
    if (searchTestsString.length > 1) {
      return (
        searchAPIResult.data?.search?.resultList
          ?.map((r) => r.testAssetData)
          .map((t) => {
            return (
              <Slide className={styles.testSlide} key={t.id}>
                <TestArchiveCard
                  className={styles.testCard}
                  onClickLink={onClickLinkTest}
                  testAsset={t}
                  showLinkButton
                />
              </Slide>
            );
          }) ?? []
      );
    }

    return (
      inspectionAsset?.inspectionTestAssetList.map((ita) => (
        <Slide className={styles.testSlide} key={ita.testAsset.id}>
          <TestArchiveCard
            className={styles.testCard}
            onClickUnlink={onClickUnlinkTest}
            testAsset={ita.testAsset}
            showUnlinkButton
          />
        </Slide>
      )) ?? []
    );
  }, [
    inspectionAsset?.inspectionTestAssetList,
    onClickLinkTest,
    onClickUnlinkTest,
    searchAPIResult.data?.search?.resultList,
    searchTestsString.length,
  ]);

  let mainContent;
  if (!getInspectionResult.called) {
    mainContent = (
      <TextLayout>
        <Icon height={40} name={ICONS.SPINNER.name} width={40} />
      </TextLayout>
    );
  } else if (getInspectionResult.error) {
    mainContent = (
      <TextLayout>
        <Text>
          {t('WORKFLOW_EDIT_PAGE_ERROR_LOADING_1')}&nbsp;
          <Button linkStyle onClick={() => router.reload()}>
            {t('PRODUCT_ADMIN_PAGE_ERROR_LOADING_REFRESH')}
          </Button>
          &nbsp;
          {t('PRODUCT_ADMIN_PAGE_ERROR_LOADING_2')}
        </Text>
      </TextLayout>
    );
  } else if (authorised && inspectionAsset) {
    mainContent = (
      <ContentLayout className={styles.section} ref={basicsSectionRef} pageContent>
        {inspectionAsset && (
          <>
            <TextLayout>
              {useDesktopLayout}
              <h2 className={styles.pageHeader}>{t('EDIT_PRODUCT')}</h2>
              {!useDesktopLayout}
            </TextLayout>
            <EditWorkflowPageBasicsSection inspectionAsset={inspectionAsset} />
          </>
        )}
      </ContentLayout>
    );
    let chartSection;
    if (inspectionAsset.totalLogs === 0) {
      chartSection = <InsufficientPerformanceDataNoLogsMessage />;
    } else {
      if (chartConfigs && chartConfigs.latestLogsChart.data.datasets[0].data.length <= 2) {
        chartConfigs.latestLogsChart.options.scales.x.max = 1.1;
      }

      chartSection = (
        <>
          <div className={styles.chartFilters}>
            <div className={styles.chartFilterDropdowns}>
              <div className={styles.chartFilterSection}>
                <Text bold className={styles.chartFilterLabel}>
                  {t('PRODUCTS_OPTIONAL_PLURAL')}
                </Text>
                <div className={styles.searchContainer} style={{ zIndex: 2 }}>
                  <SearchInputAsButton
                    buttonProps={{
                      icon: <Icon className={styles.selectCaretIcon} name={ICONS.CARET.name} />,
                    }}
                    className={styles.searchInput}
                    inputProps={{
                      loading: searchProductsAPIResult.loading,
                      onBlur: () => setSearchProductsFocused(false),
                      onChange: onSearchProducts,
                      onFocus: () => setSearchProductsFocused(true),
                      value: searchProductsString,
                    }}
                    onClickClear={() => setChartProductHighlight(null)}
                    placeholder={t('FILTER_PRODUCTS_PLACEHOLDER')}
                    ref={searchProductsInputRef}
                    selection={
                      chartProductHighlight ? (
                        <>
                          <Tag size='sm'>{chartProductHighlight.REFID}</Tag>{' '}
                          {ProductModel.localizedNameForProduct(chartProductHighlight, locale)}
                        </>
                      ) : null
                    }
                  />
                  {searchProductsFocused && searchProductsString.length > 1 && (
                    <SearchSuggestions
                      className={styles.searchSuggestions}
                      onSuggestionClick={filterByProduct}
                      showMoreResultsMessage={
                        searchProductsFocused &&
                        searchProductsAPIResult.data?.search?.searchPaginationResultDataByDataType?.PRODUCT
                          ?.totalResults > searchProductsAPIResult.data?.search?.resultList.length
                      }
                      showNoResultsMessage={
                        searchProductsFocused && searchProductsAPIResult.data?.search?.resultList.length === 0
                      }
                      suggestions={searchProductsAPIResult.data?.search?.resultList?.map((r) =>
                        suggestionObjectForProduct(r.productData, locale)
                      )}
                    />
                  )}
                </div>
                {chartProductHighlight && !hasRecentLogsForProduct && !loadingPerformanceChartData && (
                  <Text className={styles.noRecentLogsMessage} size='sm' tint='notification'>
                    {t('NO_RECENT_LOGS_FOR_PRODUCT')}
                  </Text>
                )}
              </div>
              <div className={styles.chartFilterSection}>
                <Text bold className={styles.chartFilterLabel}>
                  {t('COWORKERS_OPTIONAL_PLURAL')}
                </Text>
                <div className={styles.searchContainer}>
                  <SearchInputAsButton
                    buttonProps={{
                      icon: <Icon className={styles.selectCaretIcon} name={ICONS.CARET.name} />,
                    }}
                    className={styles.searchInput}
                    inputProps={{
                      loading: searchCoworkersAPIResult.loading,
                      onBlur: () => setSearchCoworkersFocused(false),
                      onChange: onSearchCoworkers,
                      onFocus: () => setSearchCoworkersFocused(true),
                      value: searchCoworkersString,
                    }}
                    onClickClear={() => setChartCoworkerHighlight(null)}
                    placeholder={t('FILTER_COWORKERS_PLACEHOLDER')}
                    ref={searchCoworkersInputRef}
                    selection={
                      chartCoworkerHighlight ? <Tag size='sm'>{formatOkid(chartCoworkerHighlight.OKID)}</Tag> : null
                    }
                  />
                  {searchCoworkersFocused && searchCoworkersString.length > 1 && (
                    <SearchSuggestions
                      className={styles.searchSuggestions}
                      onSuggestionClick={filterByUser}
                      showMoreResultsMessage={
                        searchCoworkersFocused &&
                        searchCoworkersAPIResult.data?.search?.searchPaginationResultDataByDataType?.USER
                          ?.totalResults > searchCoworkersAPIResult.data?.search?.resultList.length
                      }
                      showNoResultsMessage={
                        searchCoworkersFocused && searchCoworkersAPIResult.data?.search?.resultList.length === 0
                      }
                      suggestions={searchCoworkersAPIResult.data?.search?.resultList?.map((r) =>
                        suggestionObjectForUser(r.userData, t)
                      )}
                    />
                  )}
                </div>
                {chartCoworkerHighlight && !hasRecentLogsForCoworker && !loadingPerformanceChartData && (
                  <Text className={styles.noRecentLogsMessage} size='sm' tint='notification'>
                    {t('NO_RECENT_LOGS_FOR_COWORKER')}
                  </Text>
                )}
              </div>
            </div>
            <div>
              <div className={styles.chartFilterSection}>
                <Text bold>{t('INCLUDE')}</Text>
                <Chip
                  className={styles.chartFilterChip}
                  onChange={setChartFilterIncludeInternalLogs}
                  selected={chartFilterIncludeInternalLogs}
                >
                  {t('INTERNAL_LOGS')}
                </Chip>
                {chartDisplayType !== LATEST_LOGS_CHART_TYPE.POINTS && (
                  <Chip
                    className={styles.chartFilterChip}
                    onChange={setChartOptionShowQuantity}
                    selected={chartOptionShowQuantity}
                  >
                    {t('EXACT_QUANTITY')}
                  </Chip>
                )}
              </div>
            </div>
          </div>
          {chartConfigs ? (
            <>
              <div className={styles.chartHeaders}>
                <Text bold className={styles.latestLogsChartHeader}>
                  {latestLogs.length === 1
                    ? t('LATEST_LOGS_1')
                    : t('LATEST_LOGS_X', { data: { number: latestLogs.length } })}
                </Text>
                <Text bold>{t('TO_DATE')}</Text>
                {chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS ? (
                  <Icon className={styles.chartScaleIcon} name={ICONS.RELIABILITY_GRADE.name} />
                ) : (
                  <Text bold className={styles.chartScaleIcon}>
                    {chartOptionShowQuantity ? 'x' : '%'}
                  </Text>
                )}
              </div>
              <div className={styles.chartsContainer}>
                <div className={styles.latestLogsChartContainer}>
                  <LineChart data={chartConfigs.latestLogsChart.data} options={chartConfigs.latestLogsChart.options} />
                </div>
                <div className={styles.allLogsChartContainer}>
                  <BarChart data={chartConfigs.aggregatedChart.data} options={chartConfigs.aggregatedChart.options} />
                </div>
              </div>
              <div id='chartLegendContainer' />
              <ButtonGroup buttonStyle='separate' className={styles.chartDisplayTypeButtons}>
                <button
                  active={chartDisplayType === LATEST_LOGS_CHART_TYPE.POINTS}
                  onClick={() => setChartDisplayType(LATEST_LOGS_CHART_TYPE.POINTS)}
                >
                  {t('LOG_ARCHIVE_PAGE_POINTS')}
                </button>
                <button
                  active={chartDisplayType === LATEST_LOGS_CHART_TYPE.MINOR_AQL}
                  onClick={() => setChartDisplayType(LATEST_LOGS_CHART_TYPE.MINOR_AQL)}
                >
                  {t('MINOR')}
                </button>
                <button
                  active={chartDisplayType === LATEST_LOGS_CHART_TYPE.MAJOR_AQL}
                  onClick={() => setChartDisplayType(LATEST_LOGS_CHART_TYPE.MAJOR_AQL)}
                >
                  {t('MAJOR')}
                </button>
                <button
                  active={chartDisplayType === LATEST_LOGS_CHART_TYPE.CRITICAL_AQL}
                  onClick={() => setChartDisplayType(LATEST_LOGS_CHART_TYPE.CRITICAL_AQL)}
                >
                  {t('CRITICAL')}
                </button>
              </ButtonGroup>
              {chartDisplayType !== LATEST_LOGS_CHART_TYPE.POINTS && !chartOptionShowQuantity && (
                <Text bold size='xs'>
                  <sup>1</sup> {t('LOG_QUALITY_LEVEL_FOOTNOTE_2')}
                </Text>
              )}
            </>
          ) : loadingPerformanceChartData ? (
            <Icon height={40} name={ICONS.SPINNER.name} width={40} />
          ) : (
            <InsufficientPerformanceDataForOptionsMessage />
          )}
        </>
      );
    }
    mainContent = (
      <>
        <>
          {inspectionAsset && (
            <>
              {useDesktopLayout}
              <EditWorkflowPageBasicsSection inspectionAsset={inspectionAsset} />
            </>
          )}
        </>

        <TextLayout>
          <h3>{t('PERFORMANCE')}</h3>
          <Progressable inProgress={!renderChartsSection} style={{ minHeight: !renderChartsSection ? 100 : 0 }}>
            {chartSection}
          </Progressable>
          <Separator type='section' />
        </TextLayout>
        <TextLayout>
          <h4 style={{ marginBottom: 0 }}>
            {t('STEPS')} <span className={styles.normalFontWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
          </h4>
          <div className={styles.testsIntroContainer}>
            <div>
              <Text className={styles.testsIntroText}>{t('WORKFLOW_EDIT_PAGE_SECTION_STEPS_DESCRIPTION')}</Text>
              <SearchInput
                accessoryButton={
                  <Button
                    disabled={createInspectionTestAPIResult.loading}
                    linkStyle
                    loading={createInspectionTestAPIResult.loading}
                    onClick={createTest}
                    tint='creation'
                  >
                    {t('NEW')}
                  </Button>
                }
                className={styles.testSearchInput}
                loading={searchAPIResult.loading}
                onChange={onSearchTests}
                placeholder={t('SEARCH_STEPS_INPUT_PLACEHOLDER')}
                value={searchTestsString}
              />
              {testsError && (
                <Text className={styles.errorMessage} size='sm' tint='notification'>
                  {testsError}
                </Text>
              )}
            </div>
            <Notice className={styles.testsIntroNotice} extendSides={useMobileLayout}>
              {tHTML('WORKFLOW_EDIT_PAGE_SECTION_STEPS_NOTICE')}
            </Notice>
          </div>
        </TextLayout>
        <Carousel className={styles.testsCarouselContainer} fadeOutSides={useDesktopLayout} ref={testsCarouselRef}>
          {testResults}
        </Carousel>
      </>
    );
  } else {
    mainContent = null;
  }

  const localizedNameForInspectionAsset =
    inspectionAsset && InspectionAssetModel.localizedNameForInspectionAsset(inspectionAsset, locale);

  const pageMenu = (
    <PageMenu
      assetName={localizedNameForInspectionAsset}
      sections={pageSections}
      showBackButton
      smartTabs
      submenu={pageSubmenu}
      title=' '
    />
  );

  return (
    <>
      <PageTitle>
        {REFID ? `${t('WORKFLOW_ARCHIVE_CARD_TITLE')} - ${REFID}` : t('WORKFLOW_ARCHIVE_CARD_TITLE')}
      </PageTitle>
      {showInPopup ? pageMenu : <Menu>{pageMenu}</Menu>}
      <div className={styles.basicsContainer}>
        <ContentLayout ref={basicsSectionRef} pageContent>
          <UIContext.Provider value='card'>
            <TextLayout>
              <h2 className={styles.header}>
                {t('WORKFLOW_ARCHIVE_CARD_TITLE')} <Tag className={styles.refId}>{REFID}</Tag>
              </h2>
            </TextLayout>
          </UIContext.Provider>
        </ContentLayout>
        <div className={styles.testsSection}>
          <UIContext.Provider value='card'>
            <ContentLayout pageContent>{mainContent}</ContentLayout>
          </UIContext.Provider>
        </div>
      </div>
      {testPopupTestRefId && <TestPopup dismiss={() => setTestPopupTestRefId(null)} testRefId={testPopupTestRefId} />}
    </>
  );
}

EditInspectionPage.layoutProps = {
  contentClassName: styles.layoutContainer,
  contentTopPadding: false,
  padFooter: false,
};

EditInspectionPage.propTypes = {
  REFIDasProp: PropTypes.string,
};
export async function getServerSideProps({ locale }) {
  const i18nProps = await serverSideTranslations(locale, ['common']);
  return { props: { ...i18nProps } };
}
