import { gql } from '@apollo/client';

export const userAvatarListQuery = gql`
  query UserAvatarList {
    avatar: userAvatarList {
      id
      name
      skin
      priority
      sourceLightURL
      sourceDarkURL
    }
  }
`;

export const setUserAvatarMutation = gql`
  mutation SetUserAvatarMutation($avatarId: String!) {
    avatar: setUserAvatar(avatarId: $avatarId) {
      OKID
      id
      avatar {
        id
        name
        skin
        priority
        sourceLightURL
        sourceDarkURL
      }
    }
  }
`;

export const removeUserAvatarMutation = gql`
  mutation RemoveUserAvatar {
    avatar: removeUserAvatar {
      OKID
      id
      avatar {
        id
        name
        skin
        priority
        sourceLightURL
        sourceDarkURL
      }
    }
  }
`;
