import { gql } from '@apollo/client';

export default class VersionedTestAssetModel {
  static GRAPHQL_TYPE = 'VersionedTestAsset';

  static fragmentName = 'VersionedTestAsset_Fragment';
  static fragment = gql`
    fragment ${VersionedTestAssetModel.fragmentName} on ${VersionedTestAssetModel.GRAPHQL_TYPE} {
      REFID
      createdBy
      createdDate
      enableIdentifiers
      enableNotes
      enableTimeTracking
      howToFindItMap
      howToFixItMap
      id
      lastModifiedBy
      lastModifiedDate
      organisationId
      requireAllConditionToPass
      testAssetConditionList {
        type
        value
        valueType
      }
      testAssetId
      testSeverityLevel
      version
      versionDate
      whatToLookForMap
    }
  `;
}
