import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import ButtonGroup from 'OK/components/buttonGroup';
import Icon, { ICONS } from 'OK/components/icon';
import ItemArchiveCard from 'OK/components/item/archiveCard';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import LinkToItem from 'OK/components/link/linkToItem';
import LinkToSite from 'OK/components/link/linkToSite';
import OrganisationArchiveCard from 'OK/components/organisation/archiveCard';
import ProductArchiveCard from 'OK/components/product/archiveCard';
import Separator from 'OK/components/separator';
import SiteArchiveCard from 'OK/components/site/archiveCard';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import UserArchiveCard from 'OK/components/user/archiveCard';
import { getItemByIDQuery } from 'OK/networking/items';
import { formatDate, formatNumber, formatOkid, formatPoints } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

const LOG_RELATION_AUDITOR = 'AUDITOR';
const LOG_RELATION_BRAND = 'BRAND';
const LOG_RELATION_PRODUCT = 'PRODUCT';

export default function InspectionLogSummarySection(props) {
  /* Variables */

  const { className, inspectionLog } = props;
  const { locale, t, tHTML } = useI18n();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);

  // API

  const [getItemAPI, getItemAPIResult] = useLazyQuery(getItemByIDQuery, {
    errorPolicy: 'all',
  });
  const item = getItemAPIResult?.data?.item;

  // Inspection log data
  const createdById = inspectionLog?.createdBy;
  const inspectionLogSourceType = inspectionLog?.sourceType;
  const OKID = inspectionLogSourceType === 'PRODUCT' ? inspectionLog?.item?.OKID : inspectionLog?.site?.OKID;
  const inspectedFailed = inspectionLog?.failed ?? 0;
  const inspectedSize = inspectionLog?.inspectedSize ?? 0;
  const reliabilityPoints = inspectionLog.reliabilityPoint ?? 0;
  const assigneeData = inspectionLog.assigneeList?.length ? inspectionLog.assigneeList.find((a) => a.active) : null;
  const assigneeDueDate = assigneeData?.dueDate;

  /* State */

  const [activeLogRelation, setActiveLogRelation] = useState(LOG_RELATION_PRODUCT);

  // Effects

  // Load product data
  useEffect(() => {
    if (createdById) {
      getItemAPI({ variables: { itemId: createdById } });
    }
  }, [createdById, getItemAPI]);

  /* Render */

  let classNames = `${styles.section} ${styles.summarySection}`;
  if (className) {
    classNames += ` ${className}`;
  }

  // Log relation
  let logRelation;
  if (useMobileLayout) {
    switch (activeLogRelation) {
      case LOG_RELATION_PRODUCT:
        if (inspectionLogSourceType === 'PRODUCT' && inspectionLog?.item.product) {
          logRelation = (
            <ProductArchiveCard
              cardClassName={styles.logRelationCardInner}
              className={styles.logRelationCard}
              fixedWidth={useDesktopLayout}
              product={inspectionLog.item.product}
              linkToEditPage={false}
            />
          );
        } else if (inspectionLogSourceType === 'SITE' && inspectionLog?.site) {
          logRelation = (
            <SiteArchiveCard
              cardClassName={styles.logRelationCardInner}
              className={styles.logRelationCard}
              fixedWidth={useDesktopLayout}
              site={inspectionLog.site}
            />
          );
        }
        break;
      case LOG_RELATION_BRAND:
        if (inspectionLog?.organisation) {
          logRelation = (
            <OrganisationArchiveCard
              cardClassName={styles.logRelationCardInner}
              className={styles.logRelationCard}
              fixedWidth={useDesktopLayout}
              organisation={inspectionLog.organisation}
            />
          );
        }
        break;
      case LOG_RELATION_AUDITOR:
        if (inspectionLog?.inspector) {
          logRelation = (
            <UserArchiveCard
              cardClassName={styles.logRelationCardInner}
              className={styles.logRelationCard}
              fixedWidth={useDesktopLayout}
              user={inspectionLog?.inspector}
            />
          );
        } else if (createdById) {
          logRelation = (
            <ItemArchiveCard
              cardClassName={styles.logRelationCardInner}
              className={styles.logRelationCard}
              fixedWidth={useDesktopLayout}
              item={item}
            />
          );
        }
        break;
    }
  } else {
    logRelation = (
      <div className={styles.logRelationsContainer}>
        <div>
          {inspectionLogSourceType === 'PRODUCT' && (
            <>
              <h4>{t('PRODUCT')}</h4>
              {inspectionLog?.item.product && (
                <ProductArchiveCard
                  cardClassName={styles.logRelationCardInner}
                  className={styles.logRelationCard}
                  product={inspectionLog.item.product}
                  linkToEditPage={false}
                />
              )}
            </>
          )}
          {inspectionLogSourceType === 'SITE' && inspectionLog?.site && (
            <>
              <h4>{t('SITE')}</h4>
              <SiteArchiveCard
                cardClassName={styles.logRelationCardInner}
                className={styles.logRelationCard}
                site={inspectionLog.site}
              />
            </>
          )}
        </div>
        <div>
          <h4>{t('BRAND')}</h4>
          {inspectionLog?.organisation && (
            <OrganisationArchiveCard
              cardClassName={styles.logRelationCardInner}
              className={styles.logRelationCard}
              organisation={inspectionLog.organisation}
            />
          )}
        </div>
        <div>
          <h4>{t('CREATED_BY')}</h4>
          {inspectionLog?.inspector ? (
            <UserArchiveCard
              cardClassName={styles.logRelationCardInner}
              className={styles.logRelationCard}
              user={inspectionLog?.inspector}
            />
          ) : createdById && item ? (
            <ItemArchiveCard
              cardClassName={styles.logRelationCardInner}
              className={styles.logRelationCard}
              fixedWidth={useDesktopLayout}
              item={item}
              linkToArchive={false}
            />
          ) : null}
        </div>
      </div>
    );
  }

  const startDate = inspectionLog.startDate;
  const endDate = inspectionLog.endDate;
  const dueDate = assigneeDueDate ?? inspectionLog.endDate;

  return (
    <ContentLayout className={classNames} pageContent>
      <div className={styles.summaryContents}>
        <div className={styles.summaryColumn}>
          <TextLayout>
            <h3>{t('ARCHIVE_PAGE_SECTION_SUMMARY')}</h3>
            <h4>{t('LOG_ARCHIVE_PAGE_RESULT')}</h4>
            {OKID && (
              <>
                <Text bold className={styles.noticeHeader} size='md'>
                  {inspectionLogSourceType === 'PRODUCT'
                    ? inspectionLog.lotSize === 1
                      ? t('ITEM_OKID')
                      : t('ITEM_SET_OKID')
                    : t('SITE_OKID')}{' '}
                  <Icon inline name={ICONS.TAG.name} />
                </Text>
                <h4>
                  {inspectionLogSourceType === 'PRODUCT' ? (
                    <LinkToItem className={styles.okidLink} OKID={OKID}>
                      {formatOkid(OKID)}
                    </LinkToItem>
                  ) : (
                    <LinkToSite className={styles.okidLink} OKID={OKID}>
                      {formatOkid(OKID)}
                    </LinkToSite>
                  )}
                </h4>
              </>
            )}
            <Text bold className={styles.noticeHeader} size='md'>
              {t('STATUS')}
            </Text>
            <Text size='md'>{t(inspectionLog.inspectionLogStage ?? 'OPEN')}</Text>
            <Text bold className={styles.noticeHeader} size='md'>
              {t('LOG_ARCHIVE_PAGE_APPROVED')}
            </Text>
            <Text size='md'>
              {formatNumber(inspectedSize - inspectedFailed)} of {formatNumber(inspectedSize)}
            </Text>
            <Text bold className={styles.noticeHeader} size='md'>
              {t('LOG_ARCHIVE_PAGE_BASED_ON')}
            </Text>
            <Text size='md'>
              {t('WORKFLOW_ARCHIVE_CARD_X_STEPS', {
                data: { number: inspectionLog.inspectionLogTestAssetList.length },
              })}
            </Text>
            <Separator />
            <div className={styles.logMetaDataRow}>
              <div className={styles.logMetaDataHeader}>{t('LOG_ARCHIVE_PAGE_PLACE')}</div>
              {inspectionLog.inspectionOrganisation && (
                <div className={styles.logMetaDataValue}>
                  {t(`GEOLOCATION_${inspectionLog.inspectionOrganisation.addressList[0].countryCode}`)}{' '}
                  <Tag className={styles.placeTag} size='sm'>
                    {inspectionLog.inspectionOrganisation.addressList[0].countryCode}
                  </Tag>
                </div>
              )}
            </div>
            {inspectionLog.linkedOrder?.REFID && (
              <div className={styles.logMetaDataRow}>
                <div className={styles.logMetaDataHeader}>{t('LOG_ARCHIVE_PAGE_ORDER')}</div>
                <div className={styles.logMetaDataValue}>
                  <Tag className={`${styles.placeTag} ${styles.placeTagOrder}`} size='sm'>
                    {inspectionLog.linkedOrder.REFID}
                  </Tag>
                </div>
              </div>
            )}
            <div className={styles.logMetaDataRow}>
              <div className={styles.logMetaDataHeader}>{t('LOG_ARCHIVE_PAGE_TIME')}</div>
              <div className={styles.logMetaDataValue}>
                {inspectionLog.createdDate && formatDate(inspectionLog.createdDate, locale)}
              </div>
            </div>
            {startDate && (
              <div className={styles.logMetaDataRow}>
                <div className={styles.logMetaDataHeader}>{t('LOG_ARCHIVE_PAGE_START_TIME')}</div>
                <div className={styles.logMetaDataValue}>{formatDate(startDate, locale)}</div>
              </div>
            )}
            {endDate && (
              <div className={styles.logMetaDataRow}>
                <div className={styles.logMetaDataHeader}>{t('LOG_ARCHIVE_PAGE_END_TIME')}</div>
                <div className={styles.logMetaDataValue}>{formatDate(endDate, locale)}</div>
              </div>
            )}
            {!startDate && !endDate && dueDate && (
              <div className={styles.logMetaDataRow}>
                <div className={styles.logMetaDataHeader}>{t('DUE_DATE')}</div>
                <div className={styles.logMetaDataValue}>{formatDate(dueDate, locale)}</div>
              </div>
            )}
            <div className={styles.logMetaDataRow}>
              <div className={styles.logMetaDataHeader}>{t('LOG_ARCHIVE_PAGE_POINTS')}</div>
              <div className={styles.logMetaDataValue}>
                {/* eslint-disable indent */}
                {reliabilityPoints === 1
                  ? tHTML('RELIABILITY_POINTS_WITH_ICON_SINGULAR', {
                    data: {
                      icon: <Icon inline name={ICONS.RELIABILITY_GRADE.name} />,
                    },
                  })
                  : tHTML('RELIABILITY_POINTS_WITH_ICON_PLURAL', {
                    data: {
                      icon: <Icon inline name={ICONS.RELIABILITY_GRADE.name} />,
                      number: formatPoints(inspectionLog.reliabilityPoint),
                    },
                  })}
                {/* eslint-enable indent */}
              </div>
            </div>
            {useMobileLayout && (
              <ButtonGroup className={styles.logRelationButtons}>
                <button
                  active={activeLogRelation === LOG_RELATION_PRODUCT}
                  onClick={() => setActiveLogRelation(LOG_RELATION_PRODUCT)}
                >
                  {t('PRODUCT')}
                </button>
                <button
                  active={activeLogRelation === LOG_RELATION_BRAND}
                  onClick={() => setActiveLogRelation(LOG_RELATION_BRAND)}
                >
                  {t('BRAND')}
                </button>
                <button
                  active={activeLogRelation === LOG_RELATION_AUDITOR}
                  onClick={() => setActiveLogRelation(LOG_RELATION_AUDITOR)}
                >
                  {t('CREATED_BY')}
                </button>
              </ButtonGroup>
            )}
          </TextLayout>
        </div>
        {logRelation}
      </div>
    </ContentLayout>
  );
}

InspectionLogSummarySection.propTypes = {
  className: PropTypes.string,
  inspectionLog: PropTypes.object.isRequired,
};
