import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';

export default function isAuthorised(authorisationLevel, requiredAuthorisationLevel) {
  switch (requiredAuthorisationLevel) {
    case AUTHORISATION_LEVEL.OWNER:
      return authorisationLevel === AUTHORISATION_LEVEL.OWNER;
    case AUTHORISATION_LEVEL.MANAGER:
      return authorisationLevel === AUTHORISATION_LEVEL.OWNER || authorisationLevel === AUTHORISATION_LEVEL.MANAGER;
    case AUTHORISATION_LEVEL.COWORKER:
      return (
        authorisationLevel === AUTHORISATION_LEVEL.OWNER ||
        authorisationLevel === AUTHORISATION_LEVEL.MANAGER ||
        authorisationLevel === AUTHORISATION_LEVEL.COWORKER
      );
    case AUTHORISATION_LEVEL.USER:
      return (
        authorisationLevel === AUTHORISATION_LEVEL.OWNER ||
        authorisationLevel === AUTHORISATION_LEVEL.MANAGER ||
        authorisationLevel === AUTHORISATION_LEVEL.COWORKER ||
        authorisationLevel === AUTHORISATION_LEVEL.USER
      );
    case AUTHORISATION_LEVEL.PUBLIC:
      return (
        authorisationLevel === AUTHORISATION_LEVEL.OWNER ||
        authorisationLevel === AUTHORISATION_LEVEL.MANAGER ||
        authorisationLevel === AUTHORISATION_LEVEL.COWORKER ||
        authorisationLevel === AUTHORISATION_LEVEL.USER ||
        authorisationLevel === AUTHORISATION_LEVEL.PUBLIC
      );
    default:
      return false;
  }
}

export function isAuthorisedViaShare(sharedPermissionLevel, requiredPermissionLevel) {
  switch (requiredPermissionLevel) {
    case AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION:
      return sharedPermissionLevel === AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION;
    case AssetAccessPermission.SHARED_WITH_COMMENT_PERMISSION:
      return (
        sharedPermissionLevel === AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION ||
        sharedPermissionLevel === AssetAccessPermission.SHARED_WITH_COMMENT_PERMISSION
      );
    case AssetAccessPermission.SHARED_WITH_READ_PERMISSION:
      return (
        sharedPermissionLevel === AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION ||
        sharedPermissionLevel === AssetAccessPermission.SHARED_WITH_COMMENT_PERMISSION ||
        sharedPermissionLevel === AssetAccessPermission.SHARED_WITH_READ_PERMISSION
      );
  }
}
