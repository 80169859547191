import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

import styles from './styles.module.scss';

import Text from 'OK/components/text';

const FootnotesContext = createContext({
  counterStyle: 'default',
});

/**
 * Render an ordered list in small font, like footnotes. Expects `Footnote` children.
 *
 * @param {object} props
 * @param {string} props.children
 * @param {string} [props.className] The list's class.
 * @param {'numeric'|'alphabetic'} [props.numberingStyle] The list's numbering style. Supports numeric or alphabetic.
 */
export function Footnotes(props) {
  const { children, className, numberingStyle = 'numeric', ...otherProps } = props;

  let classNames = `${styles.footnotes} footnotes`;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let counterStyle;
  switch (numberingStyle) {
    case 'alphabetic':
      counterStyle = 'lower-alpha';
      break;
    default:
      counterStyle = 'decimal';
      break;
  }

  return (
    <FootnotesContext.Provider value={{ counterStyle }}>
      <ol className={classNames} {...otherProps}>
        {children}
      </ol>
    </FootnotesContext.Provider>
  );
}

Footnotes.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  numberingStyle: PropTypes.oneOf(['numeric', 'alphabetic']),
};

/**
 * A footnote item. Should be used as a child of `Footnotes`.
 *
 * @param {object} props
 * @param {string} props.children
 * @param {string} [props.className] The footnote's class.
 */
export function Footnote(props) {
  const { children, className, ...otherProps } = props;
  const { counterStyle } = useContext(FootnotesContext);

  let classNames = `${styles.footnote} footnote`;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <>
      <li className={classNames} {...otherProps}>
        <Text bold size='xs'>
          {children}
        </Text>
      </li>
      <style jsx>{`
        .footnote:before {
          content: counter(footnote-counter, ${counterStyle});
        }
      `}</style>
    </>
  );
}

Footnote.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
