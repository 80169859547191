import { gql, useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import { ICONS } from 'OK/components/icon';
import InspectionLogLotResults, { InspectionLogLotResultsFineprint } from 'OK/components/inspectionLog/lotResults';
import InspectionLogQualityLevelTable from 'OK/components/inspectionLog/qualityLevelTable';
import InspectionLogSampleResults from 'OK/components/inspectionLog/sampleResults';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import Separator from 'OK/components/separator';
import { Tab, Tabs, TabsContextProvider } from 'OK/components/tabs';
import TestResultCard from 'OK/components/test/resultCard';
import InspectionLogTestAssetModel from 'OK/models/inspectionLogTestAsset';
import InspectionMediaGalleryPopup from 'OK/modules/popups/inspectionMediaGallery';
import generateInspectionLogData from 'OK/util/functions/generateInspectionLogData';
import useI18n from 'OK/util/hooks/useI18n';

const BREAKDOWN_OVERALL = 'OVERALL';
const BREAKDOWN_TEST = 'TEST';

export default function InspectionLogBreakdownSection(props) {
  /* Variables */

  const { breakdownSectionRef, className, inspectionLog, REFID } = props;
  const { t } = useI18n();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const siteMode = inspectionLog.sourceType === 'SITE';
  const inspectionLogTestAssets = inspectionLog.inspectionLogTestAssetList;
  const apolloClient = useApolloClient();

  /* State */

  const [breakdown, setBreakdown] = useState(BREAKDOWN_OVERALL);
  const [renderLogFindingsPopup, setRenderLogFindingsPopup] = useState(false);

  /* Methods */

  const openLogFindings = useCallback(() => {
    setRenderLogFindingsPopup(true);
  }, []);

  // Generate per-test site log data
  useEffect(() => {
    if (!siteMode || breakdown !== BREAKDOWN_TEST) {
      // This effect is only applicable to site logs and when viewing by-test data
      return;
    }
    inspectionLogTestAssets.forEach((test) => {
      const updatedLogData = generateInspectionLogData(
        {
          fixed: test.fixed,
          flawless: test.passed,
          initialLotSize: test.passed + test.failed,
          rejected: test.failed - test.fixed - test.tolerated,
          sampleSize: test.passed + test.failed,
          tolerated: test.tolerated,
        },
        [test]
      );
      apolloClient.writeFragment({
        id: `${InspectionLogTestAssetModel.GRAPHQL_TYPE}:${test.id}`,
        fragment: gql`
          fragment Projections on ${InspectionLogTestAssetModel.GRAPHQL_TYPE} {
            lotCriticalFailure
            lotFixableProjection
            lotFixableProjectionPercent
            lotFixedPercent
            lotFlawlessProjection
            lotFlawlessProjectionPercent
            lotMajorFailure
            lotMinorFailure
            lotRejectedPercent
            lotTolerableProjection
            lotTolerableProjectionPercent
            lotUnfixableProjection
            lotUnfixableProjectionPercent
            sampleCriticalFailures
            sampleCriticalInspected
            sampleMajorFailures
            sampleMajorInspected
            sampleMinorFailures
            sampleMinorInspected
            samplePassedAsFixedPercent
            samplePassedAsFlawlessPercent
            samplePassedAsToleratedPercent
            sampleRejectedPercent
          }
        `,
        data: {
          lotCriticalFailure: updatedLogData.finalLotProjections.criticalFailures,
          lotFixableProjection: updatedLogData.finalLotProjections.projectedFixable,
          lotFixableProjectionPercent: updatedLogData.finalLotProjections.percentProjectedFixable,
          lotFixedPercent: updatedLogData.finalLotProjections.percentFixed,
          lotFlawlessProjection: updatedLogData.finalLotProjections.projectedFlawless,
          lotFlawlessProjectionPercent: updatedLogData.finalLotProjections.percentProjectedFlawless,
          lotMajorFailure: updatedLogData.finalLotProjections.majorFailures,
          lotMinorFailure: updatedLogData.finalLotProjections.minorFailures,
          lotRejectedPercent: updatedLogData.finalLotProjections.percentRejected,
          lotTolerableProjection: updatedLogData.finalLotProjections.projectedTolerable,
          lotTolerableProjectionPercent: updatedLogData.finalLotProjections.percentProjectedTolerable,
          lotUnfixableProjection: updatedLogData.finalLotProjections.projectedUnfixable,
          lotUnfixableProjectionPercent: updatedLogData.finalLotProjections.percentProjectedUnfixable,
          sampleCriticalFailures: updatedLogData.sampleResults.criticalFailures,
          sampleCriticalInspected: updatedLogData.sampleResults.criticalInspected,
          sampleMajorFailures: updatedLogData.sampleResults.majorFailures,
          sampleMajorInspected: updatedLogData.sampleResults.majorInspected,
          sampleMinorFailures: updatedLogData.sampleResults.minorFailures,
          sampleMinorInspected: updatedLogData.sampleResults.minorInspected,
          samplePassedAsFixedPercent: updatedLogData.sampleResults.percentFixed,
          samplePassedAsFlawlessPercent: updatedLogData.sampleResults.percentFlawless,
          samplePassedAsToleratedPercent: updatedLogData.sampleResults.percentTolerated,
          sampleRejectedPercent: updatedLogData.sampleResults.percentRejected,
        },
      });
    });
  }, [apolloClient, breakdown, inspectionLogTestAssets, siteMode]);

  /* Render */

  let classNames = `${styles.section} ${styles.breakdownSection}`;
  if (className) {
    classNames += ` ${className}`;
  }

  const inspectedSize = inspectionLog?.inspectedSize ?? 0;
  const {
    lotCriticalFailure,
    lotFixableProjection,
    lotFixableProjectionPercent,
    lotFixedPercent,
    lotFlawlessProjection,
    lotFlawlessProjectionPercent,
    lotMajorFailure,
    lotMinorFailure,
    lotRejectedPercent,
    lotSize,
    lotTolerableProjection,
    lotTolerableProjectionPercent,
    lotUnfixableProjection,
    lotUnfixableProjectionPercent,
    passedAsFixed,
    passedAsFlawless,
    passedAsTolerated,
    rejected,
    sampleCriticalFailures,
    sampleMajorFailures,
    sampleMinorFailures,
    samplePassedAsFixedPercent,
    samplePassedAsFlawlessPercent,
    samplePassedAsToleratedPercent,
    sampleRejectedPercent,
  } = inspectionLog;
  const finalLotSize = lotSize - rejected;
  const testsWithData = inspectionLog.inspectionLogTestAssetList.filter((t) => {
    const hasData = t.passed > 0 || t.failed > 0 || t.fixed > 0 || t.tolerated > 0;
    return hasData;
  });

  let breakdownContent;
  switch (breakdown) {
    case BREAKDOWN_OVERALL: {
      let siteLogData;
      let siteFlawlessCount;
      if (siteMode) {
        siteFlawlessCount = inspectionLogTestAssets.reduce((sum, t) => sum + t.passed, 0);
        const fullyFixedCount = inspectionLogTestAssets.reduce((sum, t) => sum + t.fixed, 0);
        const rejectedCount = inspectionLogTestAssets.reduce((sum, t) => sum + t.failed - t.fixed, 0);
        const finalLotSizeCount = siteFlawlessCount + fullyFixedCount;
        const toleratedCount = inspectionLog.passedAsTolerated;
        siteLogData = generateInspectionLogData(
          {
            finalLotSize: finalLotSizeCount,
            fixed: fullyFixedCount,
            flawless: siteFlawlessCount,
            initialLotSize: inspectionLogTestAssets.length,
            rejected: rejectedCount,
            sampleSize: siteFlawlessCount + fullyFixedCount + rejectedCount,
            tolerated: toleratedCount,
          },
          inspectionLogTestAssets
        );
      }
      const sampleResults = (
        <InspectionLogSampleResults
          className={styles.breakdownSampleResults}
          fixed={passedAsFixed}
          passed={passedAsFlawless}
          percentFixed={samplePassedAsFixedPercent}
          percentFlawless={samplePassedAsFlawlessPercent}
          percentRejected={sampleRejectedPercent}
          percentTolerated={samplePassedAsToleratedPercent}
          rejected={rejected}
          sampleSize={inspectedSize}
          tolerated={passedAsTolerated}
        />
      );
      const lotResults = (
        <>
          <InspectionLogLotResults
            className={styles.breakdownLotResults}
            finalLotSize={finalLotSize}
            fixed={passedAsFixed}
            initialLotSize={lotSize}
            percentFixed={siteMode ? siteLogData.sampleResults.percentFixed : lotFixedPercent}
            percentProjectedFixable={lotFixableProjectionPercent}
            percentProjectedFlawless={
              siteMode ? siteLogData.sampleResults.percentFlawless : lotFlawlessProjectionPercent
            }
            percentProjectedTolerable={lotTolerableProjectionPercent}
            percentProjectedUnfixable={lotUnfixableProjectionPercent}
            percentRejected={siteMode ? siteLogData.sampleResults.percentRejected : lotRejectedPercent}
            projectedFixable={lotFixableProjection}
            projectedFlawless={siteMode ? siteFlawlessCount : lotFlawlessProjection}
            projectedTolerable={lotTolerableProjection}
            projectedUnfixable={lotUnfixableProjection}
            rejected={rejected}
            sourceType={inspectionLog.sourceType}
          />
          <InspectionLogLotResultsFineprint />
        </>
      );
      const qualityTable = (
        <InspectionLogQualityLevelTable
          finalLotSize={finalLotSize}
          lotCriticalFailure={lotCriticalFailure}
          lotMajorFailure={lotMajorFailure}
          lotMinorFailure={lotMinorFailure}
          sampleSize={inspectedSize}
          sampleCriticalFailures={sampleCriticalFailures}
          sampleMajorFailures={sampleMajorFailures}
          sampleMinorFailures={sampleMinorFailures}
        />
      );
      const logGalleryButton = inspectionLog.inspectionLogFindingList.length > 0 && (
        <Button block icon={ICONS.MEDIA_GALLERY.name} onClick={openLogFindings} tint='navigation'>
          {t('LOG_VIEW_LOG_GALLERY')}
        </Button>
      );
      if (useMobileLayout) {
        breakdownContent = (
          <TextLayout>
            {logGalleryButton}
            {!siteMode && (
              <>
                {sampleResults}
                <Separator className={styles.separator} />
              </>
            )}
            {lotResults}
            <Separator className={styles.separator} />
            {qualityTable}
          </TextLayout>
        );
      } else {
        breakdownContent = (
          <div className={styles.breakdownContent}>
            <TextLayout className={styles.breakdownColumn}>
              {logGalleryButton}
              {lotResults}
            </TextLayout>
            <TextLayout className={styles.breakdownColumn}>
              {!siteMode && (
                <>
                  {sampleResults}
                  <Separator className={styles.separator} />
                </>
              )}
              {qualityTable}
            </TextLayout>
          </div>
        );
      }
      break;
    }
    case BREAKDOWN_TEST: {
      breakdownContent = (
        <Carousel
          className={styles.testsCarouselContainer}
          innerClassName={styles.testsCarousel}
          fadeOutSides={useDesktopLayout}
        >
          {testsWithData.map((t) => (
            <Slide className={styles.testSlide} key={t.id}>
              <TestResultCard
                className={styles.testResultCard}
                index={inspectionLog.inspectionLogTestAssetList.findIndex((ta) => ta.id === t.id) + 1}
                innerClassName={styles.testResultCardInner}
                inspectionLogREFID={REFID}
                lotSize={siteMode ? t.passed + t.failed : lotSize}
                sourceType={inspectionLog.sourceType}
                test={t}
              />
            </Slide>
          ))}
        </Carousel>
      );
      break;
    }
  }

  return (
    <div className={classNames}>
      <ContentLayout ref={breakdownSectionRef} pageContent>
        <TextLayout>
          <h3>{t('ARCHIVE_PAGE_SECTION_BREAKDOWN')}</h3>
        </TextLayout>
      </ContentLayout>
      {testsWithData.length > 0 && (
        <div className={styles.breakdownMenuContainer}>
          <ContentLayout pageContent>
            <TextLayout>
              <TabsContextProvider activeTabId={breakdown} onTabClick={setBreakdown}>
                <Tabs className={styles.breakdownTabs} sections={[{ id: BREAKDOWN_OVERALL }, { id: BREAKDOWN_TEST }]}>
                  <Tab className={styles.breakdownTab} tabId={BREAKDOWN_OVERALL}>
                    {t('LOG_BREAKDOWN_OVERALL')}
                  </Tab>
                  <Tab className={styles.breakdownTab} tabId={BREAKDOWN_TEST}>
                    {t('LOG_BREAKDOWN_BY_STEP')}
                  </Tab>
                </Tabs>
              </TabsContextProvider>
            </TextLayout>
          </ContentLayout>
        </div>
      )}
      <ContentLayout pageContent>{breakdownContent}</ContentLayout>
      {renderLogFindingsPopup && (
        <InspectionMediaGalleryPopup dismiss={() => setRenderLogFindingsPopup(false)} inspectionLogREFID={REFID} />
      )}
    </div>
  );
}

InspectionLogBreakdownSection.propTypes = {
  breakdownSectionRef: PropTypes.object,
  className: PropTypes.string,
  inspectionLog: PropTypes.object.isRequired,
  REFID: PropTypes.string,
};
