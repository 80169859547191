import useI18n from 'OK/util/hooks/useI18n';

export default function withI18n(Component) {
  function WrappedComponent(props) {
    const { i18nImageSrc, locale, localizeObject, setLocale, t, tHTML } = useI18n();

    return (
      <Component
        {...props}
        i18nImageSrc={i18nImageSrc}
        locale={locale}
        localizeObject={localizeObject}
        setLocale={setLocale}
        t={t}
        tHTML={tHTML}
      />
    );
  }

  return WrappedComponent;
}

export function removeI18nProps(props) {
  const otherProps = { ...props };
  delete otherProps.i18nImageSrc;
  delete otherProps.locale;
  delete otherProps.localizeObject;
  delete otherProps.setLocale;
  delete otherProps.t;
  delete otherProps.tHTML;
  return otherProps;
}
