import { gql } from '@apollo/client';

export default class BaseItemModel {
  static GRAPHQL_TYPE = 'Item';

  static fragmentName = 'BaseItem_Fragment';
  static fragment = gql`
    fragment ${BaseItemModel.fragmentName} on ${BaseItemModel.GRAPHQL_TYPE} {
      createdBy
      createdDate
      criticalAQL
      criticalFailure
      criticalInspected
      id
      lastModifiedBy
      lastModifiedDate
      majorAQL
      majorFailure
      majorInspected
      minorAQL
      minorFailure
      minorInspected
      OKID
      organisationId
      publishedLogs
      quantity
      reliabilityPointForAllLogs
      reliabilityPointForPublishedLogs
      totalLogs
      version
    }
  `;
}
