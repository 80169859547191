import { configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';

import reducer, { initialState } from './reducers';

/* Setup Redux enhancers */

// Sentry enhancer
const sentryReduxEnhancer = createReduxEnhancer();

const enhancers = [sentryReduxEnhancer];

/* Middleware */

/** Dispatch an event when the session is marked as restored. */
const dispatchEventWhenSessionRestored = ({ getState }) => {
  return (next) => {
    return (action) => {
      const beforeState = getState();
      const returnValue = next(action);
      const afterState = getState();
      if (beforeState.account.isRestoring === true && afterState.account.isRestoring === false) {
        window.dispatchEvent(new Event('SESSION_RESTORED'));
      }
      return returnValue;
    };
  };
};

class Redux {
  constructor() {
    this._store = null;

    this.setupStore();
  }

  setupStore() {
    this.store = configureStore({
      enhancers,
      middleware: (getDefaultMiddleware) => {
        return [...getDefaultMiddleware(), dispatchEventWhenSessionRestored];
      },
      preloadedState: initialState,
      reducer,
    });

    if (process.env.NODE_ENV !== 'production' && module.hot) {
      module.hot.accept('./reducers', () => this.store.replaceReducer(reducer));
    }
  }

  set store(store) {
    this._store = store;
  }

  get store() {
    return this._store;
  }
}

const state = new Redux();

export default function getStore() {
  return state.store;
}
