import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
  ArchiveCardTitle,
} from 'OK/components/archiveCard';
import InspectionLogStageSelect from 'OK/components/archiveCard/stageSelector';
import Icon, { ICONS } from 'OK/components/icon';
import Link from 'OK/components/link';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import InspectionLogModel from 'OK/models/inspectionLog';
import ProductModel from 'OK/models/product';
import SiteModel from 'OK/models/site';
import {
  SetInspectionLogStageAsAssigneeMutation,
  SetInspectionLogStageAsManagerMutation,
} from 'OK/networking/inspectionLogs';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import { formatDate, formatNumber } from 'OK/util/formatting';
import isAuthorised from 'OK/util/functions/isAuthorised';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';

export default function InspectionLogArchiveCard(props) {
  const {
    cardClassName,
    className,
    disabledStage,
    inspectionLog,
    linkToArchivePage = true,
    showLinkToProduct = true,
    ...otherProps
  } = props;
  const { failed, inspectedSize, inspector, inspectionAsset, lotSize, organisation, REFID, reliabilityPoint } =
    inspectionLog;
  const { t, tHTML, locale } = useI18n();
  const dispatch = useDispatch();
  const [, , currentUser] = useAuthentication(() => false);
  const assigneeData = inspectionLog.assigneeList?.length ? inspectionLog.assigneeList.find((a) => a.active) : null;
  const assigneeUserId = assigneeData?.userId;
  const assigneeDueDate = assigneeData?.dueDate;
  const dueDate = assigneeDueDate ?? inspectionLog.endDate;
  const currentUserIsAssignee = typeof assigneeUserId === 'string' && currentUser?.id === assigneeUserId;
  const authorisationLevel = useAuthorisationLevel(inspectionLog);
  const isManager = isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER);
  const isManagerOrAssignee = isManager || currentUserIsAssignee;

  const stage = inspectionLog.inspectionLogStage;

  const [setStageAsAssigneeAPI] = useMutation(SetInspectionLogStageAsAssigneeMutation);
  const [setStageAsManagerAPI] = useMutation(SetInspectionLogStageAsManagerMutation);

  const setStage = useCallback(
    (newStage) => {
      const optimisticResponse = {
        inspectionLog: {
          id: inspectionLog.id,
          inspectionLogStage: newStage,
          __typename: InspectionLogModel.GRAPHQL_TYPE,
        },
      };
      if (isManager) {
        setStageAsManagerAPI({
          variables: {
            inspectionLogId: inspectionLog.id,
            inspectionLogStage: newStage,
          },
          optimisticResponse,
        });
      } else {
        setStageAsAssigneeAPI({
          variables: {
            inspectionLogStage: newStage,
          },
          optimisticResponse,
        });
      }
    },
    [inspectionLog.id, isManager, setStageAsAssigneeAPI, setStageAsManagerAPI]
  );
  const approved = inspectedSize - failed;
  const countryTag = <Tag>{organisation.addressList[0].countryCode}</Tag>;

  const Container = linkToArchivePage ? Link : 'div';
  const containerProps = { className };
  if (linkToArchivePage) {
    containerProps.href = InspectionLogModel.link(inspectionLog);
    containerProps.onClick = (e) => {
      e.preventDefault();
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'INSPECTION_LOG' },
        null,
        InspectionLogModel.link(inspectionLog)
      );
      dispatch(setBrowserHistoryAction(history));
    };
  }

  let previewImageUrl;
  if (inspectionLog.item?.product) {
    previewImageUrl = ProductModel.previewImageUrl(inspectionLog.item.product);
  } else if (inspectionLog.site) {
    previewImageUrl = SiteModel.previewImageUrl(inspectionLog.site);
  }

  const name = useMemo(() => {
    return InspectionAssetModel.localizedNameForInspectionAsset(inspectionAsset, locale);
  }, [locale, inspectionAsset]);

  // Show start & end dates from metadata

  const startDate = inspectionLog?.startDate;
  const endDate = inspectionLog?.endDate;

  let dateString;
  if (startDate && endDate) {
    dateString = `${formatDate(startDate, locale, { style: 'short' })} - ${formatDate(endDate, locale, {
      style: 'short',
    })}`;
  } else if (endDate) {
    dateString = formatDate(endDate, locale, { style: 'short' });
  } else if (startDate) {
    dateString = formatDate(startDate, locale, { style: 'short' });
  }

  return (
    <Container {...containerProps}>
      <ArchiveCard className={cardClassName} {...otherProps}>
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery
            imageUrl={previewImageUrl}
            placeholderIconName={ICONS.INSPECTION_LOG.name}
            refId={REFID}
          />
          <ArchiveCardMainContent className={styles.content}>
            <ArchiveCardHeader className={styles.header} tag={countryTag} type={t('LOG_ARCHIVE_CARD_TITLE')} />
            <ArchiveCardTitle className={styles.nameText} lineLimit={false}>
              {name}
            </ArchiveCardTitle>
            <ArchiveCardTitle className={styles.dateText}>{dateString ?? t('NO_DATES_SPECIFIED')}</ArchiveCardTitle>
            <Text className={styles.approvedLabel} size='sm' tint='creation'>
              {inspectedSize !== 0 ? (
                tHTML('LOG_ARCHIVE_CARD_NUMBER_APPROVED', {
                  data: {
                    passed: formatNumber(approved),
                    sampled: formatNumber(inspectedSize),
                  },
                })
              ) : (
                <>&nbsp;</>
              )}
            </Text>
            <ArchiveCardTitle className={styles.selector}>
              <InspectionLogStageSelect
                className={styles.stageSelector}
                value={stage}
                onChange={setStage}
                caretPosition={!isManagerOrAssignee || disabledStage ? 'hide' : 'right'}
                disabled={!isManagerOrAssignee || (disabledStage && true)}
              />
            </ArchiveCardTitle>
            {/* {showLinkToProduct && inspectionLog.item?.product?.publishStatus === PUBLISH_STATUS.PUBLISHED && (
              <Text bold className={styles.productInfo} size='xs'>
                <Tag className={styles.productTag}>{inspectionLog.item.product.REFID}</Tag>
                <Link
                  className={styles.seeProductLink}
                  href={ProductModel.link(inspectionLog.item.product)}
                  icon={<Icon height={12} name={ICONS.CARET.name} width={12} />}
                >
                  {t('LOG_ARCHIVE_CARD_SEE_PRODUCT_LINK')}
                </Link>
              </Text>
            )} */}
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow reliabilityGrade={reliabilityPoint !== 0 ? reliabilityPoint : null}>
            {/* eslint-disable indent */}
            {lotSize === 1
              ? t('LOG_ARCHIVE_CARD_1_ITEM')
              : lotSize !== 0
              ? t('LOG_ARCHIVE_CARD_X_ITEMS', { data: { number: formatNumber(lotSize) } })
              : null}
            {/* eslint-enable indent */}
          </ArchiveCardFooterRow>
          <ArchiveCardFooterRow
            className={styles.okidFooter}
            reliabilityGrade={
              inspector?.reliabilityPointForPublishedLogs !== 0 ? inspector?.reliabilityPointForPublishedLogs : null
            }
            okid={inspector?.OKID}
            okidIcon='user'
            textTint='navigation'
          ></ArchiveCardFooterRow>
        </ArchiveCardFooter>
      </ArchiveCard>
    </Container>
  );
}

InspectionLogArchiveCard.propTypes = {
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  disabledStage: PropTypes.bool,
  inspectionLog: PropTypes.object.isRequired,
  linkToArchivePage: PropTypes.bool,
  showLinkToProduct: PropTypes.bool,
};
