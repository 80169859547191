/**
 * Get the first moment (millisecond) from a certain date, preserving the calendar date information (year, month, day).
 *
 * @param {Date} date
 * @returns {Date} New date at first moment.
 */
export function getFirstMomentOfDate(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
}

/**
 * Get the last moment (millisecond) from a certain date, preserving the calendar date information (year, month, day).
 *
 * @param {Date} date
 * @returns {Date} New date at last moment.
 */
export function getLastMomentOfDate(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
}
