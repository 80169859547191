import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

// Give text the additional base padding necessary in normal content layouts
const TextLayout = forwardRef((props, forwardedRef) => {
  const { children, className, ...otherProps } = props;

  let classNames = styles.container;

  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <div className={classNames} ref={forwardedRef} {...otherProps}>
      {children}
    </div>
  );
});

TextLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default TextLayout;
