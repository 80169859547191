import { gql } from '@apollo/client';

import MediaAssetModel from '../mediaAsset';
import OrganisationModel from '../organisation';
import BaseProductModel from '../product/base';

import BaseUserDocumentModel from './base';

import {
  getDocumentAssetSpecListRequestUserDocument,
  getDocumentAssetStandardListRequestUserDocument,
} from 'OK/networking/userDocuments';

export default class UserDocumentModel {
  static standards;
  static types;
  static get loadedConfig() {
    return typeof UserDocumentModel.standards === 'object' && typeof UserDocumentModel.types === 'object';
  }
  static loadingConfig = false;

  static GRAPHQL_TYPE = BaseUserDocumentModel.GRAPHQL_TYPE;

  static DOCUMENT_TYPE = {
    USER_CERTIFICATION: 'USER_CERTIFICATION',
  };

  static FIELD_NAMES = {
    APPLICABLE_MARKETS: 'APPLICABLE_MARKETS',
    DOCUMENT_NUMBER: 'DOCUMENT_NUMBER',
    LANGUAGES: 'LANGUAGES',
    MADE_BY_ORGANISATION: 'MADE_BY_ORGANISATION',
    STANDARDS: 'STANDARDS',
    ISSUE_DATE: 'ISSUE_DATE',
    VALID_FROM: 'VALID_FROM',
    VALID_UNTIL: 'VALID_UNTIL',
  };

  static fragmentName = 'Document_Fragment';
  static fragment = gql`
    fragment ${UserDocumentModel.fragmentName} on ${UserDocumentModel.GRAPHQL_TYPE} {
      ...${BaseUserDocumentModel.fragmentName}
      linkedProductList {
        ...${BaseProductModel.fragmentName}
        organisation {
          ...${OrganisationModel.fragmentName}
        }
        productDocumentAssetList {
          documentAsset {
            ...${BaseUserDocumentModel.fragmentName}
          }
          publishStatus
        }
        productMediaAssetList {
          mediaAsset {
            ...${MediaAssetModel.fragmentName}
          }
          order
          publishStatus
        }
      }
    }
    ${BaseUserDocumentModel.fragment}
    ${BaseProductModel.fragment}
    ${OrganisationModel.fragment}
    ${MediaAssetModel.fragment}
  `;

  static fragmentNameFile = 'Document_File_Fragment';
  static fragmentFile = gql`
    fragment ${UserDocumentModel.fragmentNameFile} on ${UserDocumentModel.GRAPHQL_TYPE} {
      id
      documentName
      documentURL
      lastModifiedBy
      lastModifiedDate
      metadata
      version
    }
  `;

  static downloadLink(documentAsset) {
    return documentAsset.documentURL;
  }

  static async getConfig() {
    return new Promise((resolve, reject) => {
      const success = () => {
        resolve({
          standards: UserDocumentModel.standards,
          types: UserDocumentModel.types,
        });
      };
      const error = () => {
        reject('Error loading document config.');
      };
      window.addEventListener('DOCUMENT_CONFIG_LOADED', success, { once: true });
      window.addEventListener('DOCUMENT_CONFIG_LOADING_ERROR', error, { once: true });
      UserDocumentModel.loadConfig();
    });
  }

  static async getConfigForType(documentType) {
    const config = await UserDocumentModel.getConfig();
    const typeConfig = config?.types?.find((c) => c.typeId === documentType);

    if (!typeConfig) {
      // Return generic type config
      return config.types?.find((c) => c.typeId === 'USER_FILE');
    }

    return typeConfig;
  }

  static async getStandards() {
    const config = await UserDocumentModel.getConfig();
    return config.standards;
  }

  static async getTypes() {
    const config = await UserDocumentModel.getConfig();
    const types = config.types
      .filter((c) => c.applicability === 'USER' && c.typeId !== 'USER_FILE')
      .map((c) => {
        return {
          fullTitle: c.fullTitle,
          title: c.title,
          typeId: c.typeId,
        };
      })
      .sort((t1, t2) => (t1.title > t2.title ? 1 : -1));
    return types;
  }

  static loadConfig() {
    const notifyLoaded = () => {
      window.dispatchEvent(new Event('DOCUMENT_CONFIG_LOADED'));
      UserDocumentModel.loadingConfig = false;
    };
    const notifyLoadError = () => {
      window.dispatchEvent(new Event('DOCUMENT_CONFIG_LOADING_ERROR'));
    };
    const checkIfLoaded = () => {
      if (UserDocumentModel.loadedConfig) {
        notifyLoaded();
      }
    };

    if (UserDocumentModel.loadingConfig) {
      // Config is already loading
      return;
    }

    if (UserDocumentModel.loadedConfig) {
      // Config already loaded
      notifyLoaded();
      return;
    }

    UserDocumentModel.loadingConfig = true;
    try {
      getDocumentAssetSpecListRequestUserDocument().then((req) => {
        if (req.success) {
          UserDocumentModel.types = req.responseData;
          checkIfLoaded();
        } else {
          notifyLoadError();
        }
      });

      getDocumentAssetStandardListRequestUserDocument().then((req) => {
        if (req.success) {
          UserDocumentModel.standards = req.responseData;
          checkIfLoaded();
        } else {
          notifyLoadError();
        }
      });
    } catch (e) {
      notifyLoadError();
    }
  }

  static link(documentAsset) {
    return `/archive/file/${documentAsset.REFID}/edit`;
  }
}
