/**
 * Get a temporary value.
 *
 * @param {string} key
 *
 * @returns {string}
 */
export function getBrowserSessionValue(key) {
  return sessionStorage.getItem(key);
}

/**
 * Remove a temporary value.
 *
 * @param {string} key
 */
export function removeBrowserSessionValue(key) {
  sessionStorage.removeItem(key);
}

/**
 * Set a temporary value. The value will persist until the user's browser session ends.
 *
 * @param {string} key
 * @param {string} value
 */
export function setBrowserSessionValue(key, value) {
  sessionStorage.setItem(key, value);
}

/**
 * Get a persistent value.
 *
 * @param {string} key
 *
 * @returns {string}
 */
export function getPersistentValue(key) {
  return localStorage.getItem(key);
}

/**
 * Remove a persistent value.
 *
 * @param {string} key
 */
export function removePersistentValue(key) {
  localStorage.removeItem(key);
}

/**
 * Set a persistent value. The value will persist indefinitely.
 *
 * @param {string} key
 * @param {string} value
 */
export function setPersistentValue(key, value) {
  localStorage.setItem(key, value);
}
