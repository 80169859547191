import PropTypes from 'prop-types';

import Link from '..';

import SiteModel from 'OK/models/site';

export default function LinkToSite(props) {
  const { children, OKID: OKIDProp, site, linkToEditPage = false, ...otherProps } = props;

  if (!OKIDProp && !site?.OKID) {
    okwarn('Cannot render LinkToSite because no OKID or item was provided.');
    return null;
  }

  const OKID = OKIDProp ?? site?.OKID;
  const url = SiteModel.link(OKID, linkToEditPage);

  return (
    <Link href={url} {...otherProps}>
      {children}
    </Link>
  );
}

LinkToSite.propTypes = {
  children: PropTypes.node,
  site: PropTypes.shape({
    OKID: PropTypes.string,
  }),
  linkToEditPage: PropTypes.bool,
  OKID: PropTypes.string,
};
