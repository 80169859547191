import { gql } from '@apollo/client';

import ItemModel from 'OK/models/item';
import ProductModel from 'OK/models/product';

/* Queries */

export const getItemByAlternateIdQuery = gql`
  query GetItemByAlternateId($alternateId: String!) {
    items: itemWithAlternateId(alternateId: $alternateId) {
      ...${ItemModel.fragmentNameScanResult}
    }
  }
  ${ItemModel.fragmentScanResult}
`;

export const getItemByIDQuery = gql`
  query GetItemByID($itemId: String!) {
    item: item(itemId: $itemId) {
      id
      criticalInspected
      itemAlternateIdList
      majorInspected
      minorInspected
      OKID
      product {
        ...${ProductModel.fragmentNameFull}
      }
      reliabilityPointForPublishedLogs
    }
  }
  ${ProductModel.fragmentFull}
`;

export const getItemByOKIDForAssemblyQuery = gql`
  query GetItemByOKID($OKID: String!) {
    item: itemWithOKID(OKID: $OKID) {
      id
      product {
        id
        name
      }
    }
  }
`;

export const getItemByOKIDQuery = gql`
  query GetItemByOKID($OKID: String!) {
    item: itemWithOKID(OKID: $OKID) {
      id
      criticalInspected
      itemAlternateIdList
      majorInspected
      minorInspected
      OKID
      itemConfiguration
      product {
        ...${ProductModel.fragmentNameFull}
      }
      reliabilityPointForPublishedLogs
    }
  }
  ${ProductModel.fragmentFull}

`;

export const GetItemForScanResultsQuery = gql`
  query GetItemForScanResults($OKID: String!) {
    item: itemWithOKID(OKID: $OKID) {
      ...${ItemModel.fragmentNameScanResult}
    }
  }
  ${ItemModel.fragmentScanResult}
`;

export const latestStreamingRecordings = gql`
  query latestStreamingRecordings($itemOKID: String!, $startDate: String!, $endDate: String) {
    latestStreamingRecordings(itemOKID: $itemOKID, startDate: $startDate, endDate: $endDate) {
      filename
      mediaURL
      createdDate
    }
  }
`;


/* Mutations */

export const addAssembledItemAsInspectorMutation = gql`
  mutation addAssembledItemOnInspectionLog($itemId: String!, $partItemId: String!) {
    item: addItemAssemblyUnderInspectionLog(itemId: $itemId, partItemId: $partItemId) {
      id
      assemblyItemList {
        item {
          ...${ItemModel.fragmentNameAssemblyArchiveCard}
        }
        quantity
      }
    }
  }
  ${ItemModel.fragmentAssemblyArchiveCard}
`;

export const addAssembledItemAsManagerMutation = gql`
  mutation addAssembledItemAsManager($itemId: String!, $partItemId: String!) {
    item: addItemAssembly(itemId: $itemId, partItemId: $partItemId) {
      id
      assemblyItemList {
        item {
          ...${ItemModel.fragmentNameAssemblyArchiveCard}
        }
        quantity
      }
    }
  }
  ${ItemModel.fragmentAssemblyArchiveCard}
`;

export const createItemAlternateIDMutation = gql`
  mutation CreateItemAlternateID($itemAlternateId: String!, $itemId: String!) {
    createItemAlternateId(itemAlternateId: $itemAlternateId, itemId: $itemId) {
      id
      itemAlternateIdList
    }
  }
`;

export const createItemsWithAlternateIDsMutation = gql`
  mutation CreateItemsWithAlternateIDs($itemAlternateIdList: [String]!, $productREFID: String!) {
    createMultipleItemWithAlternateId(itemAlternateIdList: $itemAlternateIdList, productREFID: $productREFID) {
      id
      itemAlternateId
      itemOKID
    }
  }
`;

export const removeAssembledItemAsInspectorMutation = gql`
  mutation removeAssembledItemAsInspector($itemId: String!, $partItemId: String!) {
    item: removeItemAssemblyUnderInspectionLog(itemId: $itemId, partItemId: $partItemId) {
      id
      assemblyItemList {
        item {
          ...${ItemModel.fragmentNameAssemblyArchiveCard}
        }
        quantity
      }
    }
  }
  ${ItemModel.fragmentAssemblyArchiveCard}
`;

export const removeAssembledItemAsManagerMutation = gql`
  mutation removeAssembledItemAsManager($itemId: String!, $partItemId: String!) {
    item: removeItemAssembly(itemId: $itemId, partItemId: $partItemId) {
      id
      assemblyItemList {
        item {
          ...${ItemModel.fragmentNameAssemblyArchiveCard}
        }
        quantity
      }
    }
  }
  ${ItemModel.fragmentAssemblyArchiveCard}
`;

export const removeItemAlternateIDMutation = gql`
  mutation RemoveItemAlternateID($itemAlternateId: String!, $itemId: String!) {
    removeItemAlternateId(itemAlternateId: $itemAlternateId, itemId: $itemId) {
      id
      itemAlternateIdList
    }
  }
`;

export const setItemAlternateIdAsPrimaryMutation = gql`
  mutation SetItemAlternateIdAsPrimary($itemAlternateId: String!, $itemId: String!) {
    item: setItemAlternateIdAsPrimary(itemAlternateId: $itemAlternateId, itemId: $itemId) {
      id
      itemAlternateIdList
    }
  }
`;

export const SetItemAssemblyQuantityAsInspectorMutation = gql`
  mutation SetItemAssemblyQuantityAsInspector($itemId: String!, $partItemId: String!, $quantity: Int!) {
    item: setItemAssemblyQuantityUnderInspectionLog(itemId: $itemId, partItemId: $partItemId, quantity: $quantity) {
      id
      assemblyItemList {
        item {
          ...${ItemModel.fragmentNameAssemblyArchiveCard}
        }
        quantity
      }
    }
  }
  ${ItemModel.fragmentAssemblyArchiveCard}
`;

export const SetItemAssemblyQuantityAsManagerMutation = gql`
  mutation SetItemAssemblyQuantityAsManager($itemId: String!, $partItemId: String!, $quantity: Int!) {
    item: setItemAssemblyQuantity(itemId: $itemId, partItemId: $partItemId, quantity: $quantity) {
      id
      assemblyItemList {
        item {
          ...${ItemModel.fragmentNameAssemblyArchiveCard}
        }
        quantity
      }
    }
  }
  ${ItemModel.fragmentAssemblyArchiveCard}
`;

export const UpdateAIConfigurationSettingsMutation = gql`
  mutation UpdateAIConfigurationSettings($itemId: String!, $aiDetectionConfigSettings: AIDetectionConfigSettingInput!) {
    item: updateAIConfigurationSettings(itemId: $itemId, aiDetectionConfigSettings: $aiDetectionConfigSettings) {
      aiDetectionConfigSettings {
        detectionType
        configurationSettings
      }
    }
  }
`;
