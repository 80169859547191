import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function Checkbox(props) {
  const { className, error = false, ...otherProps } = props;

  let classNames = styles.container;
  if (error) {
    classNames = `${classNames} ${styles.error}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }
  return (
    <label className={classNames}>
      <input
        className={styles.original}
        readOnly={typeof props.onChange === 'undefined'}
        type='checkbox'
        {...otherProps}
      />
      <span className={styles.checkbox} />
    </label>
  );
}

Checkbox.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
};
