export default class UserBadgeModel {
  static badgeDescription(badgeName, t) {
    return t(`USER_RECOGNITION_DESCRIPTION_${badgeName}`);
  }

  static badgeImageUrl(badgeName, density2x = false) {
    switch (badgeName) {
      case 'PLANT_A_TREE':
        if (density2x) {
          return '/img/badges/OneTreePlanted_badge@2x.png';
        }
        return '/img/badges/OneTreePlanted_badge.png';
      default:
        return null;
    }
  }

  static badgePublicName(badgeName, t) {
    return t(`USER_RECOGNITION_NAME_${badgeName}`);
  }
}
