import { gql } from '@apollo/client';

import BaseMediaAssetModel from '../mediaAsset/base';
import BaseOrganisationModel from '../organisation/base';
import BaseSiteModel from '../site/base';

import BaseSiteChildSiteModel from './base';

export default class SiteChildSiteModel {
  static GRAPHQL_TYPE = BaseSiteChildSiteModel.GRAPHQL_TYPE;

  static fragmentName = 'SiteChildSite_Fragment';
  static fragment = gql`
    fragment ${SiteChildSiteModel.fragmentName} on ${SiteChildSiteModel.GRAPHQL_TYPE} {
      ...${BaseSiteChildSiteModel.fragmentName}
      childSite {
        ...${BaseSiteModel.fragmentName}
        organisation {
          ...${BaseOrganisationModel.fragmentName}
        }
        siteMediaAssetList {
          mediaAsset {
            ...${BaseMediaAssetModel.fragmentName}
          }
          order
          publishStatus
        }
      }
    }
    ${BaseSiteChildSiteModel.fragment}
    ${BaseSiteModel.fragment}
    ${BaseOrganisationModel.fragment}
    ${BaseMediaAssetModel.fragment}
  `;
}
