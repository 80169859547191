import PropTypes from 'prop-types';
import { forwardRef, useRef, useState } from 'react';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import Text from 'OK/components/text';
import { setNativeValue } from 'OK/util/functions';

/**
 * Multi-line textarea input.
 *
 * @param {object} [props]
 * @param {string} [props.className] The component's class.
 * @param {boolean} [props.disabled=false] Disable the textarea.
 * @param {string} [props.error] Display an error below the textarea.
 * @param {boolean} [props.loading=false] Indicate the textarea is loading.
 * @param {(event: Event) => {}} [props.onBlur] Event handler when the textarea loses focus.
 * @param {(event: Event) => {}} [props.onChange] Event handler when the textarea value changes.
 * @param {(event: Event) => {}} [props.onFocus] Event handler when the textarea gains focus.
 * @param {boolean} [props.readOnly=false] Prevent editing of the textarea.
 * @param {string} [props.value] The text of the textarea.
 * @param {string} [props.warning] Display a warning below the textarea.
 */
const TextArea = forwardRef((props, forwardedRef) => {
  /* Variables */

  const {
    className,
    disabled,
    error,
    loading,
    onBlur,
    onChange,
    onFocus,
    readOnly,
    value,
    warning,
    ...otherProps
  } = props;
  const editable = !disabled && !readOnly;
  const innerRef = useRef();
  const textareaRef = forwardedRef || innerRef;

  /* State */

  const [focused, setFocused] = useState(false);

  /* Methods */

  const clear = (keepFocus = true) => {
    setNativeValue(textareaRef.current, '');
    if (keepFocus) {
      textareaRef.current.focus();
    }
  };

  /* Events */

  const _onBlur = (e) => {
    setFocused(false);

    onBlur && onBlur(e);
  };

  const _onChange = onChange;

  const _onFocus = (e) => {
    setFocused(true);

    onFocus && onFocus(e);
  };

  const onClear = () => {
    clear(focused);
  };

  /* Render */

  const hasValue = !!value;

  // Classes

  let textareaContainerClassNames = styles.textareaContainer;
  if (focused) {
    textareaContainerClassNames = `${textareaContainerClassNames} ${styles.focused}`;
  } else if (hasValue) {
    textareaContainerClassNames = `${textareaContainerClassNames} ${styles.hasValue}`;
  }
  if (disabled) {
    textareaContainerClassNames = `${textareaContainerClassNames} ${styles.disabled}`;
  } else if (readOnly) {
    textareaContainerClassNames = `${textareaContainerClassNames} ${styles.readOnly}`;
  }

  // Icon
  let icon;
  if (loading) {
    icon = <Icon className={styles.icon} height={16} name={ICONS.SPINNER.name} width={16} />;
  } else if (hasValue && editable) {
    icon = (
      <button className={`${styles.icon} ${styles.clearButton}`} onClick={onClear}>
        <Icon inline name={ICONS.X_SMALL.name} />
      </button>
    );
  }

  // Message
  let message;
  if (error) {
    message = (
      <Text className={`${styles.message} ${styles.error}`} size='sm' tint='alert'>
        {error}
      </Text>
    );
  } else if (warning) {
    message = (
      <Text className={`${styles.message} ${styles.warning}`} size='sm' tint='notification'>
        {warning}
      </Text>
    );
  }

  return (
    <div className={className}>
      <div className={textareaContainerClassNames}>
        <textarea
          className={styles.textarea}
          disabled={disabled}
          onBlur={_onBlur}
          onChange={_onChange}
          onFocus={_onFocus}
          readOnly={readOnly}
          ref={textareaRef}
          value={value}
          {...otherProps}
        />
        {icon}
      </div>
      {message}
    </div>
  );
});

TextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  warning: PropTypes.string,
};

export default TextArea;
