import PropTypes from 'prop-types';

import Link from '../link';

import styles from './styles.module.scss';

import Text from 'OK/components/text';

export function ProductCondensedArchiveCard({ product = { name: '', REFID: '', SKUID: '' } }) {
  const { name, REFID, SKUID } = product;

  return (
    <>
      <Link href={`/archive/product/${REFID}`} style={{ width: '100%' }}>
        <div className={styles.archiveCardContainer}>
          <div className={styles.assetType}>Product</div>
          <div className={styles.assetID}>{REFID}</div>
          <div className={styles.assetDescription}>
            <Text className={styles.assetName}>
              {name === undefined || Object.keys(name).length === 0 ? 'Unnamed' : name?.EN?.text}
            </Text>
          </div>
          <div className={styles.assetImage}>
            <img src='' alt='' />
          </div>
          <div className={styles.archiveCardBottomContainer}>{SKUID}</div>
        </div>
      </Link>
      <div style={{ width: 315, height: 40 }} />
    </>
  );
}

ProductCondensedArchiveCard.propTypes = {
  product: PropTypes.object,
};
