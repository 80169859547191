import { gql } from '@apollo/client';

import BaseDocumentModel from './document/base';

export default class ProductDocumentModel {
  static GRAPHQL_TYPE = 'ProductDocumentAsset';

  static fragmentName = 'ProductDocument_Fragment';
  static fragment = gql`
    fragment ${ProductDocumentModel.fragmentName} on ${ProductDocumentModel.GRAPHQL_TYPE} {
      documentAsset {
        ...${BaseDocumentModel.fragmentName}
      }
      publishError @client
      publishStatus
    }
    ${BaseDocumentModel.fragment}
  `;

  static fragmentNamePublishError = 'ProductDocument_PublishError_Fragment';
  static fragmentPublishError = gql`
    fragment ${ProductDocumentModel.fragmentNamePublishError} on ${ProductDocumentModel.GRAPHQL_TYPE} {
      publishError @client
    }
  `;
}
