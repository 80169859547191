import { gql } from '@apollo/client';
import { nanoid } from 'nanoid';

import BaseDocumentModel from '../document/base';
import BaseOrganisationModel from '../organisation/base';
import BaseProductModel from '../product/base';

import BaseMediaAssetModel, { AudioMediaAssetModel, ImageMediaAssetModel, VideoMediaAssetModel } from './base';

import STATUS from 'OK/util/enums/status';
import simpleMIMEType from 'OK/util/functions/simpleMIMEType';

export default class MediaAssetModel {
  /**
   * @param {object | File} dataOrFile A media asset object or a file to create one from.
   */
  constructor(dataOrFile) {
    let newMediaAsset;
    if (dataOrFile.REFID) {
      // Creating from API object
      newMediaAsset = dataOrFile;
    } else {
      // Creating from file
      newMediaAsset = MediaAssetModel._assetFromFile(dataOrFile);
    }

    this.REFID = newMediaAsset.REFID;
    this.audioData = newMediaAsset.audioData;
    this.createdBy = newMediaAsset.createdBy;
    this.createdDate = newMediaAsset.createdDate;
    this.id = newMediaAsset.id;
    this.imageData = newMediaAsset.imageData;
    this.lastModifiedBy = newMediaAsset.lastModifiedBy;
    this.lastModifiedDate = newMediaAsset.lastModifiedDate;
    this.mediaType = newMediaAsset.mediaType;
    this.status = newMediaAsset.status;
    this.version = newMediaAsset.version;
    this.videoData = newMediaAsset.videoData;
    this.__typename = newMediaAsset.__typename;
  }

  static GRAPHQL_TYPE = BaseMediaAssetModel.GRAPHQL_TYPE;
  static MEDIA_TYPE = {
    AUDIO: 'AUDIO',
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
  };

  static fragmentName = 'MediaAsset_Fragment';
  static fragment = gql`
    fragment ${MediaAssetModel.fragmentName} on ${MediaAssetModel.GRAPHQL_TYPE} {
      ...${BaseMediaAssetModel.fragmentName}
      linkedProductList {
        ...${BaseProductModel.fragmentName}
        organisation {
          ...${BaseOrganisationModel.fragmentName}
        }
        productDocumentAssetList {
          documentAsset {
            ...${BaseDocumentModel.fragmentName}
          }
          publishStatus
        }
        productMediaAssetList {
          mediaAsset {
            ...${BaseMediaAssetModel.fragmentName}
          }
          order
          publishStatus
        }
      }
    }
    ${BaseMediaAssetModel.fragment}
    ${BaseProductModel.fragment}
    ${BaseOrganisationModel.fragment}
    ${BaseDocumentModel.fragment}
  `;

  static fragmentNameMediaGallery = 'MediaAsset_MediaGallery_Fragment';
  static fragmentMediaGallery = gql`
    fragment ${MediaAssetModel.fragmentNameMediaGallery} on ${MediaAssetModel.GRAPHQL_TYPE} {
      id
      mediaType
      REFID
      audioData {
        audioURL
      }
      imageData {
        imageURL
        imageName
        baseImageURL
      }
      videoData {
        videoURL
      }
    }
  `;

  static _assetFromFile(file) {
    const mediaType = simpleMIMEType(file.type).toUpperCase();
    let asset = {
      REFID: nanoid(6),
      createdBy: null,
      createdDate: new Date(),
      id: nanoid(),
      lastModifiedBy: null,
      lastModifiedDate: new Date(),
      mediaType,
      status: STATUS.ACTIVE,
      version: 0,
      __typename: MediaAssetModel.GRAPHQL_TYPE,
    };

    // Add specific media type data
    switch (mediaType) {
      case MediaAssetModel.MEDIA_TYPE.AUDIO:
        asset.audioData = new AudioMediaAssetModel(file);
        break;
      case MediaAssetModel.MEDIA_TYPE.IMAGE:
        asset.imageData = new ImageMediaAssetModel(file);
        break;
      case MediaAssetModel.MEDIA_TYPE.VIDEO:
        asset.videoData = new VideoMediaAssetModel(file);
        break;
      default:
        break;
    }

    return asset;
  }

  static mediaAssetName(mediaAsset) {
    switch (mediaAsset.mediaType) {
      case MediaAssetModel.MEDIA_TYPE.AUDIO:
        return mediaAsset.audioData.audioName;
      case MediaAssetModel.MEDIA_TYPE.IMAGE:
        return mediaAsset.imageData.imageName;
      case MediaAssetModel.MEDIA_TYPE.VIDEO:
        return mediaAsset.videoData.videoName;
      default:
        return null;
    }
  }

  static link(mediaAsset) {
    return `/archive/media/${mediaAsset.REFID}/edit`;
  }
}

export { AudioMediaAssetModel, ImageMediaAssetModel, VideoMediaAssetModel };
