import { getDataURLFromFile, releaseDataURL } from './getDataURLFromFile';

export default function getAudioMetadataFromFile(file) {
  return new Promise((resolve, reject) => {
    try {
      const audio = document.createElement('audio');
      const audioDataUrl = getDataURLFromFile(file);
      audio.addEventListener('loadedmetadata', () => {
        resolve({ duration: audio.duration });
        releaseDataURL(audioDataUrl);
      });
      audio.src = audioDataUrl;
    } catch (e) {
      reject(e);
    }
  });
}
