import InspectionLogModel from 'OK/models/inspectionLog';
import OrganisationModel from 'OK/models/organisation';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';

/**
 * Check a user's authorisation level for a given resource.
 *
 * @param {object} user The user to check authorisation for.
 * @param {string} activeOrganisationId The active organisation id.
 * @param {object} resource The data model to check authorisation level for.
 *
 * @returns {'PUBLIC'|'USER'|'COWORKER'|'MANAGER'}
 */
export default function authorisationForResource(user, activeOrganisationId, resource) {
  if (!user || !resource) {
    return AUTHORISATION_LEVEL.PUBLIC;
  }

  const resourceIsOrganisation = resource.__typename === OrganisationModel.GRAPHQL_TYPE;
  if (resourceIsOrganisation) {
    // Return authorisation for this organisation
    return authorisationForOrganisation(user, activeOrganisationId, resource.id);
  }

  const resourceIsInspectionLog = resource.__typename === InspectionLogModel.GRAPHQL_TYPE;
  if (resourceIsInspectionLog) {
    // Return authorisation for this inspection log
    return authorisationForInspectionLog(user, activeOrganisationId, resource);
  }

  // Check if resource is owned by active organisation
  const resourceOrganisationId = resource.organisation?.id ?? resource.organisationId;
  if (resourceOrganisationId === activeOrganisationId) {
    // Return authorisation for the resource's organisation
    return authorisationForOrganisation(user, activeOrganisationId, resourceOrganisationId);
  }

  // Default to user level
  return AUTHORISATION_LEVEL.USER;
}

/**
 * Check a user's authorisation level for an organisation.
 *
 * @param {object} user The user to check authorisation for.
 * @param {string} activeOrganisationId The active organisation id.
 * @param {string} organisationId The id of the organisation to check authorisation for.
 *
 * @returns {'PUBLIC'|'USER'|'COWORKER'|'MANAGER'}
 */
function authorisationForOrganisation(user, activeOrganisationId, organisationId) {
  if (!user) {
    return AUTHORISATION_LEVEL.PUBLIC;
  }

  // If the active organisation matches, check for coworker or manager level.
  if (organisationId === activeOrganisationId) {
    const userIsOwnerOfOrganisation = user.ownedOrganisationList.findIndex((o) => o.id === organisationId) > -1;
    if (userIsOwnerOfOrganisation) {
      return AUTHORISATION_LEVEL.OWNER;
    }

    const userIsManagerOfOrganisation = user.managedOrganisationList.findIndex((o) => o.id === organisationId) > -1;
    if (userIsManagerOfOrganisation) {
      return AUTHORISATION_LEVEL.MANAGER;
    }

    return AUTHORISATION_LEVEL.COWORKER;
  }

  // Otherwise, return user level.
  return AUTHORISATION_LEVEL.USER;
}

function authorisationForInspectionLog(user, activeOrganisationId, inspectionLog) {
  if (inspectionLog.organisationId === activeOrganisationId) {
    return authorisationForOrganisation(user, activeOrganisationId, inspectionLog.organisationId);
  }

  if (
    inspectionLog.inspectionOrganisation?.id === activeOrganisationId ||
    inspectionLog.inspectionOrganisationId === activeOrganisationId
  ) {
    return AUTHORISATION_LEVEL.COWORKER;
  }

  return AUTHORISATION_LEVEL.USER;
}
