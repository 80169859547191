import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import FormFieldI18n from 'OK/components/form/formFieldI18n';
import Icon, { ICONS } from 'OK/components/icon';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import PageTitle from 'OK/components/pageTitle';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import NoteModel from 'OK/models/note';
import ProductModel from 'OK/models/product';
import ArchiveLinksSection from 'OK/modules/archiveLinksSection';
import AddWorkPopup from 'OK/modules/popups/addWork';
import ShareAssetPopup from 'OK/modules/popups/share';
import { deleteNoteMutation, getNoteByRefIdQuery } from 'OK/networking/notes';
import { useRemoveNoteTextAPI, useUpdateNoteTextAPI } from 'OK/networking/notes/hooks';
import {
  usePublishProductNoteAPI,
  useUnlinkProductNoteAPI,
  useUnpublishProductNoteAPI,
} from 'OK/networking/products/hooks';
import UIContext from 'OK/util/context/ui';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import isAuthorised from 'OK/util/functions/isAuthorised';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';

export default function EditNotePage(props) {
  /* Variables */

  const { REFIDasProp } = props;
  const { locale, t } = useI18n();

  // Essential data

  const [getNoteAsset, getNoteAssetResult] = useLazyQuery(getNoteByRefIdQuery);
  const note = useMemo(() => {
    return getNoteAssetResult.data?.noteAsset;
  }, [getNoteAssetResult.data?.noteAsset]);

  // Misc

  const [authenticated] = useAuthentication();
  const authorisationLevel = useAuthorisationLevel(note);
  const authorised = isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.COWORKER);
  const router = useRouter();
  const REFID = router.query.refId?.toUpperCase() || REFIDasProp;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const showInPopup = useSelector((state) => state.app.showInPopup);

  // Refs

  const basicsSectionRef = useRef();
  const linksSectionRef = useRef();

  // State

  const [deleteToastMessage, setDeleteToastMessage] = useState(null);
  const [showAddWorkPopup, setShowAddWorkPopup] = useState(false);
  const [showConfirmDeleteAlert, setShowConfirmDeleteAlert] = useState(false);
  const [renderSharePopup, setRenderSharePopup] = useState(false);
  const [textWarnings, setTextWarnings] = useState({});

  /* API */

  const [deleteNoteAssetAPI, deleteNoteAssetAPIResult] = useMutation(deleteNoteMutation, {
    onCompleted: (data) => {
      if (data?.noteAsset?.id) {
        router.push('/archive');
      } else {
        setDeleteToastMessage(t('ERROR_GENERIC'));
      }
    },
    onError: (errorData) => {
      if (errorData.graphQLErrors?.length) {
        const error = errorData.graphQLErrors[0];
        if (error.message === 'UTILITY_NOTE_ASSET_HAS_EXISTING_LINKS') {
          setDeleteToastMessage(t('CANT_DELETE_NOTE_WHILE_LINKED'));
          return;
        }
      }

      setDeleteToastMessage(t('ERROR_GENERIC'));
    },
  });
  const publishProductNoteAPI = usePublishProductNoteAPI();
  const removeNoteTextAPI = useRemoveNoteTextAPI(note);
  const unlinkProductNoteAPI = useUnlinkProductNoteAPI();
  const unpublishProductNoteAPI = useUnpublishProductNoteAPI();
  const updateNoteTextAPI = useUpdateNoteTextAPI(note);

  /* Methods */

  function deleteNote() {
    setShowConfirmDeleteAlert(false);

    deleteNoteAssetAPI({
      variables: {
        noteId: note.id,
      },
    });
  }

  function promptConfirmDelete() {
    setShowConfirmDeleteAlert(true);
  }

  // Event handlers

  const onBlurNote = useCallback(
    (languageIso, textValues) => {
      // Clear errors
      if (textWarnings[languageIso]) {
        setTextWarnings((currentWarnings) => {
          const updatedErrors = {
            ...currentWarnings,
          };
          delete updatedErrors[languageIso];
          return updatedErrors;
        });
      }

      const updatedTextValue = textValues.find((v) => v.languageIso === languageIso)?.value;
      if (note.textContentMap[languageIso]?.text !== updatedTextValue) {
        if (updatedTextValue) {
          updateNoteTextAPI(languageIso, updatedTextValue);
        } else {
          setTextWarnings({
            ...textWarnings,
            [languageIso]: t('NOTE_CARD_MISSING_TEXT_ERROR'),
          });
        }
      }
    },
    [note?.textContentMap, t, textWarnings, updateNoteTextAPI]
  );

  const onChangeNote = useCallback(
    (languageIso, textValues) => {
      // Handle text removal
      if (textValues.findIndex((v) => v.languageIso === languageIso) === -1) {
        removeNoteTextAPI(languageIso);
      }
    },
    [removeNoteTextAPI]
  );

  const onChangeProductNotePublished = useCallback(
    (productId, published) => {
      const product = note.linkedProductList.find((p) => p.id === productId);
      if (published) {
        publishProductNoteAPI(product, note.id);
      } else {
        unpublishProductNoteAPI(product, note.id);
      }
    },
    [note?.id, note?.linkedProductList, publishProductNoteAPI, unpublishProductNoteAPI]
  );

  const onClickUnlinkProductNote = useCallback(
    (productId) => {
      const product = note.linkedProductList.find((p) => p.id === productId);
      unlinkProductNoteAPI(product, note.id);
    },
    [note?.id, note?.linkedProductList, unlinkProductNoteAPI]
  );

  const openSharePopup = useCallback(() => {
    setRenderSharePopup(true);
  }, []);

  const closeSharePopup = useCallback(() => {
    setRenderSharePopup(false);
  }, []);

  /* Effects */

  // Redirect if not authorised
  useEffect(() => {
    const apiCompleted =
      getNoteAssetResult.called &&
      (getNoteAssetResult.networkStatus === NetworkStatus.ready ||
        getNoteAssetResult.networkStatus === NetworkStatus.error);
    if (apiCompleted && !(!!authorised || !!note)) {
      router.replace('/404');
    }
  }, [authorised, getNoteAssetResult.called, getNoteAssetResult.networkStatus, note, router]);

  // Load document when session and url query are ready
  useEffect(() => {
    if (REFID && authenticated) {
      getNoteAsset({
        variables: {
          refId: REFID,
        },
      });
    }
  }, [REFID, authenticated, getNoteAsset]);

  // Hide delete toast after 5 seconds
  useEffect(() => {
    if (deleteToastMessage) {
      setTimeout(() => {
        setDeleteToastMessage(false);
      }, 5000);
    }
  }, [deleteToastMessage]);

  /* Render */

  const disableActions = !note || deleteNoteAssetAPIResult.loading;

  const pageSubmenu = (
    <div className={styles.submenu}>
      <Text className={styles.submenuTitle}>
        {t('NOTE_CARD_HEADER')}{' '}
        <Tag className={styles.submenuRefId} size='sm'>
          {REFID}
        </Tag>
      </Text>
      <div className={styles.submenuButtons}>
        <div className={`${styles.submenuButton} ${styles.submenuButtonContainer}`}>
          <Button icon={ICONS.SHARE.name} linkStyle style={{ marginRight: 20 }} onClick={openSharePopup}>
            {useDesktopLayout && t('SHARE')}
          </Button>
          <Button
            className={styles.submenuButton}
            disabled={disableActions}
            icon={ICONS.TRASH.name}
            linkStyle
            onClick={promptConfirmDelete}
            tint='alert'
          >
            {useDesktopLayout && t('DELETE')}
          </Button>
          <FadeInOutTransition appear in={!!deleteToastMessage}>
            <Toast className={styles.shareToast}>{deleteToastMessage}</Toast>
          </FadeInOutTransition>
        </div>
      </div>
    </div>
  );

  const noteText = useMemo(() => {
    if (note?.textContentMap) {
      return Object.keys(note.textContentMap).map((languageIso) => ({
        languageIso,
        optional: languageIso !== 'EN',
        value: note.textContentMap[languageIso].text,
        warning: textWarnings[languageIso],
      }));
    }

    return [];
  }, [note?.textContentMap, textWarnings]);

  const noteFields = useMemo(() => {
    if (note) {
      return (
        <TextLayout>
          <FormFieldI18n
            addLanguageButtonClassName={styles.addLanguageButton}
            addLanguageButtonTitle={t('NOTE_CARD_ADD_LANGUAGE')}
            disabled={disableActions}
            inputClassName={styles.textInput}
            inputContainerClassName={styles.textInputsContainer}
            inputPlaceholder={t('NOTE_CARD_INPUT_PLACEHOLDER', { data: { language: '{{language}}' } })}
            onBlur={onBlurNote}
            onChange={onChangeNote}
            useTextAreas
            values={noteText}
          />
        </TextLayout>
      );
    } else {
      return null;
    }
  }, [disableActions, note, noteText, onBlurNote, onChangeNote, t]);

  const mainContent = useMemo(() => {
    if (!getNoteAssetResult.called || getNoteAssetResult.loading) {
      return (
        <TextLayout>
          <Icon height={40} name={ICONS.SPINNER.name} width={40} />
        </TextLayout>
      );
    } else if (authorised && note) {
      return (
        <>
          <TextLayout>
            <h3 className={styles.subHeader}>{t('NOTE_TEXT_CONTENT')}</h3>
          </TextLayout>
          {noteFields}
        </>
      );
    } else {
      return null;
    }
  }, [authorised, getNoteAssetResult.called, getNoteAssetResult.loading, note, noteFields, t]);

  const archiveLinksSection = note && (
    <ArchiveLinksSection
      availableLinkTypes={[ProductModel.GRAPHQL_TYPE]}
      disableActions={disableActions}
      linkedList={note.linkedProductList}
      linksFilter='ALL'
      linksType={ProductModel.GRAPHQL_TYPE}
      onChangePublished={onChangeProductNotePublished}
      onClickUnlink={onClickUnlinkProductNote}
      parentResource={note}
      parentType={NoteModel.GRAPHQL_TYPE}
      ref={linksSectionRef}
    />
  );

  const localizedNameForNote = note && NoteModel.previewText(note, locale);

  const pageMenu = (
    <PageMenu
      assetName={localizedNameForNote}
      sections={[]}
      showBackButton
      smartTabs
      submenu={pageSubmenu}
      submenuClassName={styles.pageSubmenu}
      verticalOffsetPx={70}
    />
  );

  return (
    <>
      <PageTitle>{REFID ? `${t('NOTE_CARD_HEADER')} - ${REFID}` : t('NOTE_CARD_HEADER')}</PageTitle>
      {showInPopup ? pageMenu : <Menu>{pageMenu}</Menu>}
      <ContentLayout className={styles.basicsContainer} ref={basicsSectionRef} pageContent>
        <UIContext.Provider value='card'>
          <TextLayout>
            <h2 className={styles.header}>
              {t('NOTE_CARD_HEADER')} <Tag className={styles.refId}>{REFID}</Tag>
            </h2>
          </TextLayout>
          {mainContent}
        </UIContext.Provider>
      </ContentLayout>
      {archiveLinksSection}
      {renderSharePopup && (
        <ShareAssetPopup
          assetId={note?.id}
          assetType='NOTE_ASSET'
          asset={note}
          dismiss={closeSharePopup}
          sharedToList={note?.assetAccessPermissionList}
        />
      )}
      {showConfirmDeleteAlert && (
        <Alert
          buttons={
            <>
              <Button block className={styles.deleteNoteButton} onClick={deleteNote} tint='alert'>
                {t('DELETE_NOTE_ALERT_CONFIRM')}
              </Button>
              <Button block onClick={() => setShowConfirmDeleteAlert(false)} linkStyle>
                {t('DELETE_NOTE_ALERT_CANCEL')}
              </Button>
            </>
          }
          message={t('DELETE_NOTE_ALERT_MESSAGE')}
          title={t('DELETE_NOTE_ALERT_TITLE')}
        />
      )}

      {showAddWorkPopup && (
        <AddWorkPopup
          dismiss={() => setShowAddWorkPopup(false)}
          data={note}
          preSelectedData={note}
          preSelectedDataType={'NOTE_ASSET'}
        />
      )}
    </>
  );
}

EditNotePage.layoutProps = {
  contentTopPadding: false,
  padFooter: false,
};

EditNotePage.propTypes = {
  REFIDasProp: PropTypes.string,
};
export async function getServerSideProps({ locale }) {
  const i18nProps = await serverSideTranslations(locale, ['common']);
  return { props: { ...i18nProps } };
}
