import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
  ArchiveCardTitle,
} from 'OK/components/archiveCard';
import ArchiveCardManagerOptions from 'OK/components/archiveCard/managerOptions';
import Button from 'OK/components/button';
import Grade from 'OK/components/grade';
import Icon, { ICONS } from 'OK/components/icon';
import LinkToProduct from 'OK/components/link/linkToProduct';
import Stepper from 'OK/components/stepper';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import ProductModel from 'OK/models/product';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import useI18n from 'OK/util/hooks/useI18n';

export default function ProductArchiveCard(props) {
  /* Variables */

  const {
    allowReorderLeft = false,
    allowReorderRight = false,
    cardClassName,
    className,
    onChangePublished,
    onChangeQuantity,
    onClickApprove,
    onClickLink,
    onClickReject,
    onClickReorder,
    onClickScan,
    onClickUnlink,
    linkPublished = false,
    linkToArchive = true,
    linkToEditPage = true,
    managerOptionsError,
    product,
    quantity,
    showApproveButton = false,
    showLinkButton = false,
    showPending = false,
    showPublishOption = false,
    showRejectButton = false,
    showScanItemButton = false,
    showUnlinkOption = false,
    ...otherProps
  } = props;
  const {
    conformityPoint,
    organisation,
    productMediaAssetList,
    productChildProductList,
    REFID,
    reliabilityPointForPublishedLogs,
    SKUID,
  } = product;
  const { t, locale } = useI18n();
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const isOwnedByActiveOrganisation = activeOrganisationId === organisation?.id;
  const dispatch = useDispatch();

  /* Render */

  // Tag
  let tag;
  if (isOwnedByActiveOrganisation) {
    tag = (
      <Tag block={false} invert>
        <Icon className={styles.myOrganisationTagIcon} height={12} inline name={ICONS.ORGANISATION.name} width={12} />
      </Tag>
    );
  } else if (organisation?.addressList?.length) {
    tag = <Tag block={false}>{organisation?.addressList[0].countryCode}</Tag>;
  }

  // Media
  const flattenedMedia = useMemo(() => {
    return productMediaAssetList?.map((pm) => pm.mediaAsset);
  }, [productMediaAssetList]);

  // Name
  const name = useMemo(() => {
    return ProductModel.localizedNameForProduct(product, locale) || t('PRODUCT_ARCHIVE_CARD_UNNAMED');
  }, [locale, product, t]);

  // Parts
  let partsString;
  if (productChildProductList?.length > 0) {
    partsString =
      productChildProductList.length === 1
        ? t('PRODUCT_ARCHIVE_CARD_1_PART')
        : t('PRODUCT_ARCHIVE_CARD_X_PARTS', { data: { number: productChildProductList.length } });
  }

  // Quantity options
  const showQuantity = typeof quantity !== 'undefined';
  const quantityEditable = typeof onChangeQuantity === 'function';
  const quantitySectionClassName = !quantityEditable ? styles.quantityFlex : null;

  const Container = linkToArchive ? LinkToProduct : 'div';
  const containerProps = { className };
  if (linkToArchive) {
    containerProps.product = product;
    containerProps.onClick = (e) => {
      e.preventDefault();
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'PRODUCT', OKID: null },
        null,
        `/archive/product/${REFID}`
      );
      dispatch(setBrowserHistoryAction(history));
    };
  }

  return (
    <Container {...containerProps}>
      <ArchiveCard className={cardClassName} {...otherProps}>
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery media={flattenedMedia} placeholderIconName={ICONS.PRODUCT.name} refId={REFID} />
          <ArchiveCardMainContent>
            <ArchiveCardHeader tag={tag} type={t('PRODUCT_ARCHIVE_CARD_HEADER')} />
            <ArchiveCardTitle>{name}</ArchiveCardTitle>
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow conformityGrade={conformityPoint} reliabilityGrade={reliabilityPointForPublishedLogs}>
            {partsString}
          </ArchiveCardFooterRow>
          <ArchiveCardFooterRow
            innerClassName={styles.organisationFooterRow}
            textTint={!isOwnedByActiveOrganisation ? 'navigation' : null}
          >
            {isOwnedByActiveOrganisation ? (
              SKUID
            ) : (
              <div className={styles.ownerStatsRow}>
                <Text bold className={styles.ownerOrganisationName} size='xs' tint='navigation'>
                  {organisation?.name}
                </Text>
                <div className={styles.ownerOrganisationGrades}>
                  <Grade
                    className={styles.ownerOrganisationGrade}
                    iconClassName={styles.ownerOrganisationGradeIcon}
                    score={t('TBC')}
                    size='xs'
                    tint='navigation'
                    type='sustainability'
                  />
                  <Grade
                    className={styles.ownerOrganisationGrade}
                    iconClassName={styles.ownerOrganisationGradeIcon}
                    score={organisation?.conformityPoint}
                    size='xs'
                    tint='navigation'
                    type='conformity'
                  />
                  <Grade
                    className={styles.ownerOrganisationGrade}
                    iconClassName={styles.ownerOrganisationGradeIcon}
                    score={organisation?.reliabilityPointForPublishedLogs}
                    size='xs'
                    tint='navigation'
                    type='reliability'
                  />
                </div>
              </div>
            )}
          </ArchiveCardFooterRow>
        </ArchiveCardFooter>
        {(allowReorderLeft ||
          allowReorderRight ||
          showQuantity ||
          showPublishOption ||
          showUnlinkOption ||
          showApproveButton ||
          showRejectButton ||
          showLinkButton ||
          showScanItemButton) && (
          <ArchiveCardManagerOptions
            allowReorderLeft={allowReorderLeft}
            allowReorderRight={allowReorderRight}
            error={managerOptionsError}
            onChangePublished={onChangePublished}
            onClickReorder={onClickReorder}
            onClickUnlink={onClickUnlink}
            published={linkPublished}
            showPublishOption={showPublishOption}
            showUnlinkOption={showUnlinkOption}
          >
            {showPending && (
              <div>
                <Text tint='notification'>{t('PRODUCT_ARCHIVE_CARD_AWAITING_CONFIRMATION')}</Text>
              </div>
            )}
            {showQuantity && (
              <div className={quantitySectionClassName}>
                <Text bold className={styles.quantityLabel}>
                  {t('PRODUCT_ARCHIVE_CARD_QUANTITY_LABEL')}
                </Text>
                {quantityEditable ? (
                  <Stepper className={styles.quantityInput} minValue={1} onChange={onChangeQuantity} value={quantity} />
                ) : (
                  <Text className={styles.quantityValue}>{quantity}</Text>
                )}
              </div>
            )}
            {showLinkButton && (
              <Button block className={styles.manageButton} onClick={onClickLink} tint='creation'>
                {t('PRODUCT_ARCHIVE_CARD_LINK')}
              </Button>
            )}
            {showApproveButton && (
              <Button block className={styles.manageButton} onClick={onClickApprove} tint='creation'>
                {t('PRODUCT_ARCHIVE_CARD_APPROVE')}
              </Button>
            )}
            {showRejectButton && (
              <Button block className={styles.manageButton} linkStyle onClick={onClickReject} tint='alert'>
                {t('PRODUCT_ARCHIVE_CARD_REJECT')}
              </Button>
            )}
            {showScanItemButton && (
              <Button
                block
                className={styles.manageButton}
                icon={ICONS.SCAN.name}
                onClick={onClickScan}
                tint='creation'
              >
                {t('PRODUCT_ARCHIVE_CARD_SCAN_ITEM_BUTTON')}
              </Button>
            )}
          </ArchiveCardManagerOptions>
        )}
      </ArchiveCard>
    </Container>
  );
}

ProductArchiveCard.propTypes = {
  allowReorderLeft: PropTypes.bool,
  allowReorderRight: PropTypes.bool,
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  linkPublished: PropTypes.bool,
  linkToArchive: PropTypes.bool,
  linkToEditPage: PropTypes.bool,
  managerOptionsError: PropTypes.string,
  onChangePublished: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  onClickApprove: PropTypes.func,
  onClickLink: PropTypes.func,
  onClickReject: PropTypes.func,
  onClickReorder: PropTypes.func,
  onClickScan: PropTypes.func,
  onClickUnlink: PropTypes.func,
  product: PropTypes.any,
  quantity: PropTypes.number,
  showApproveButton: PropTypes.bool,
  showLinkButton: PropTypes.bool,
  showPending: PropTypes.bool,
  showPublishOption: PropTypes.bool,
  showRejectButton: PropTypes.bool,
  showUnlinkOption: PropTypes.bool,
  showScanItemButton: PropTypes.bool,
};
