import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Input from 'OK/components/input';

/**
 * An input with a button inline to submit. Any undocumented props will be passed to the input component.
 *
 * @param {object} props
 * @param {('alert'|'creation'|'navigation'|'notification'|'secondary')} [props.buttonTint] The tint of the submit button.
 * @param {string} props.buttonTitle The title of the submit button.
 * @param {string} [props.className] The input's class.
 * @param {(event: Event) => void} [props.onSubmit] Event handler for when the input is submitted.
 */
export default function InputWithButton(props) {
  const { buttonTint, buttonTitle, className, onSubmit, ...otherProps } = props;

  let classNames = styles.inputWithButton;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  /* Events */

  const _onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onSubmit) {
      onSubmit(e);
    }
  };

  return (
    <form onSubmit={_onSubmit}>
      <div className={classNames}>
        <Input className={styles.input} {...otherProps} />
        <Button className={styles.button} inline tint={buttonTint} type='submit'>
          {buttonTitle}
        </Button>
      </div>
    </form>
  );
}

InputWithButton.propTypes = {
  buttonTint: PropTypes.oneOf(['alert', 'creation', 'navigation', 'notification', 'secondary']),
  buttonTitle: PropTypes.string.isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};
