import { gql } from '@apollo/client';

import MediaAssetModel from 'OK/models/mediaAsset';
import ProductModel from 'OK/models/product';
import { request } from 'OK/networking';
import { API_URL } from 'OK/networking/utils';
import getAudioMetadataFromFile from 'OK/util/functions/getAudioMetadataFromFile';
import getImageDimensionsFromFile from 'OK/util/functions/getImageDimensionsFromFile';
import getVideoMetadataFromFile from 'OK/util/functions/getVideoMetadataFromFile';

/* Queries */

export const getMediaAssetByIdQuery = gql`
  query GetMediaAssetById($mediaAssetId: String!) {
    mediaAsset(mediaAssetId: $mediaAssetId) {
      ...${MediaAssetModel.fragmentName}
      linkedProductList {
        ...${ProductModel.fragmentName}
      }
    }
  }
  ${MediaAssetModel.fragment}
  ${ProductModel.fragment}
`;

export const getMediaAssetByRefIdQuery = gql`
  query GetMediaAssetByRefId($refId: String!) {
    mediaAsset: mediaAssetWithREFID(REFID: $refId) {
      ...${MediaAssetModel.fragmentName}
      assetAccessPermission
      assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      linkedProductList {
        ...${ProductModel.fragmentName}
        assetAccessPermission
        assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      }
    }
  }
  ${MediaAssetModel.fragment}
  ${ProductModel.fragment}
`;

/* Mutations */

export const deleteMediaAssetMutation = gql`
  mutation DeleteMediaAsset($mediaAssetId: String!) {
    mediaAsset: deleteMediaAsset(mediaAssetId: $mediaAssetId) {
      ...${MediaAssetModel.fragmentName}
    }
  }
  ${MediaAssetModel.fragment}
`;

/* Requests */

export async function createAndSetLogoImageMediaAssetRequest(file) {
  const { height, width } = await getImageDimensionsFromFile(file);
  const metadata = {
    filesize: file.size,
    height,
    mimeType: file.type,
    width,
  };
  return request(`${API_URL}/utility/media/createAndSetOrganisationLogoImageMediaAssetWithMetaData`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters: {
      image: file,
      baseImage: file,
      metadataString: JSON.stringify(metadata),
    },
  });
}

export async function createLogoImageMediaAssetRequest(file) {
  const { height, width } = await getImageDimensionsFromFile(file);
  const metadata = {
    filesize: file.size,
    height,
    mimeType: file.type,
    width,
  };
  return request(`${API_URL}/utility/media/createLogoImageMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters: {
      image: file,
      baseImage: file,
      metadataString: JSON.stringify(metadata),
    },
  });
}

export async function deleteLogoImageMediaAssetRequest(mediaAssetId) {
  return request(`${API_URL}/utility/media/removeOrganisationLogoImageMediaAsset`, {
    method: 'GET',
    parameters: { mediaAssetId },
  });
}

export async function updateAudioMediaAssetRequest(mediaAssetId, file) {
  const { duration } = await getAudioMetadataFromFile(file);
  const metadata = {
    duration,
    filesize: file.size,
    mimeType: file.type,
  };
  return request(`${API_URL}/utility/media/updateAudioMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters: {
      audio: file,
      mediaAssetId,
      metadataString: JSON.stringify(metadata),
    },
  });
}

export async function updateImageMediaAssetRequest(mediaAssetId, file) {
  const { height, width } = await getImageDimensionsFromFile(file);
  const metadata = {
    filesize: file.size,
    height,
    mimeType: file.type,
    width,
  };
  return request(`${API_URL}/utility/media/updateImageMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters: {
      image: file,
      mediaAssetId,
      metadataString: JSON.stringify(metadata),
    },
  });
}

export async function updateLogoMediaAssetRequest(mediaAssetId, file) {
  const { height, width } = await getImageDimensionsFromFile(file);
  const metadata = {
    filesize: file.size,
    height,
    mimeType: file.type,
    width,
  };
  return request(`${API_URL}/utility/media/updateLogoImageMediaAsset`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters: {
      image: file,
      mediaAssetId,
      metadataString: JSON.stringify(metadata),
    },
  });
}

export async function updateVideoMediaAssetRequest(mediaAssetId, file) {
  const { duration, height, width } = await getVideoMetadataFromFile(file);
  const metadata = {
    duration,
    filesize: file.size,
    height,
    mimeType: file.type,
    width,
  };
  return request(`${API_URL}/utility/media/updateVideoMediaAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters: {
      mediaAssetId,
      metadataString: JSON.stringify(metadata),
      video: file,
    },
  });
}

export async function createOrUpdateAddressMedia(file) {
  const metadata = {
    filesize: file.size,
  };
  return request(`${API_URL}/utility/media/createOrUpdateAddressMediaUrl`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters: {
      image: file,
      metadataString: JSON.stringify(metadata),
    },
  });
}
