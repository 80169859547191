import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import { ResultRow, ResultTable } from 'OK/components/inspectionLog/resultTable';
import Text from 'OK/components/text';
import { formatPercentage as formatPercentageUtil, roundAndFormatPercentages } from 'OK/util/formatting';
import roundPercentage from 'OK/util/functions/roundPercentage';
import useI18n from 'OK/util/hooks/useI18n';

function formatPercentage(percentage) {
  if (percentage === '0.0%') {
    return '-';
  }

  return percentage;
}

export function InspectionLogLotResultsFineprint(props) {
  const { className, ...otherProps } = props;
  const { t } = useI18n();

  let classNames = styles.fineprint;
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <Text bold className={classNames} size='xs' {...otherProps}>
      {t('LOG_WORKER_FINEPRINT')}
    </Text>
  );
}

InspectionLogLotResultsFineprint.propTypes = {
  className: PropTypes.string,
};

export default function InspectionLogLotResults(props) {
  const {
    finalLotSize,
    fixed,
    initialLotSize,
    percentFixed,
    percentProjectedFixable,
    percentProjectedFlawless,
    percentProjectedTolerable,
    percentProjectedUnfixable,
    percentRejected,
    percentTestsSkipped,
    projectedFixable,
    projectedFlawless,
    projectedTolerable,
    projectedUnfixable,
    rejected,
    skippedTestsCount,
    sourceType,
    useConditionalUI = false,
    ...otherProps
  } = props;
  const { t } = useI18n();
  const siteMode = sourceType === 'SITE';

  const [
    percentFlawlessRounded,
    percentFixedRounded,
    percentFixableRounded,
    percentUnfixableRounded,
    percentTolerableRounded,
    percentRejectedRounded,
  ] = roundAndFormatPercentages(
    percentProjectedFlawless,
    percentFixed,
    percentProjectedFixable,
    percentProjectedUnfixable,
    percentProjectedTolerable,
    percentRejected
  );

  // Not OK
  let notOKCount;
  let percentNotOKRounded;
  if (siteMode) {
    notOKCount = rejected;
    percentNotOKRounded = percentRejectedRounded;
  } else if (useConditionalUI) {
    notOKCount = projectedUnfixable;
    percentNotOKRounded = percentUnfixableRounded;
  } else {
    notOKCount = projectedFixable + projectedUnfixable + projectedTolerable;
    percentNotOKRounded = formatPercentageUtil(
      roundPercentage(percentProjectedFixable + percentProjectedUnfixable + percentProjectedTolerable),
      { multiplyBy100: false }
    );
  }
  const percentOKRounded = formatPercentageUtil(roundPercentage(percentProjectedFlawless + percentFixed), {
    multiplyBy100: false,
  });
  const percentTestsSkippedRounded = percentTestsSkipped
    ? formatPercentageUtil(percentTestsSkipped, { multiplyBy100: false })
    : null;

  return (
    <div {...otherProps}>
      <h4 className={styles.header}>{t('LOG_ESTIMATION_OF_LOT')}</h4>
      <Text>{t('LOG_ESTIMATION_OF_LOT_DESCRIPTION')}</Text>
      <ResultTable>
        <tbody>
          <ResultRow addBottomBorder bold count={initialLotSize} type='INITIAL_LOT' />
          {!useConditionalUI && (
            <>
              <ResultRow
                count={projectedFlawless}
                indicatorTint={!siteMode && 'creation'}
                percentage={formatPercentage(percentFlawlessRounded)}
                type='FLAWLESS'
              />
              <ResultRow
                addBottomMargin
                count={fixed}
                indicatorTint={!siteMode && 'navigation'}
                percentage={formatPercentage(percentFixedRounded)}
                type='FIXED'
              />
            </>
          )}
          <ResultRow
            addBottomBorder={!useConditionalUI}
            bold
            count={useConditionalUI ? projectedFlawless : projectedFlawless + fixed}
            indicatorTint={siteMode && 'creation'}
            percentage={
              useConditionalUI ? formatPercentage(percentFlawlessRounded) : formatPercentage(percentOKRounded)
            }
            tint='creation'
            type='PASSED'
          />
          {!useConditionalUI && !siteMode && (
            <>
              <ResultRow
                count={projectedFixable}
                indicatorTint='default'
                percentage={formatPercentage(percentFixableRounded)}
                type='FIXABLE'
              />
              <ResultRow
                count={projectedUnfixable}
                indicatorTint='alert'
                percentage={formatPercentage(percentUnfixableRounded)}
                type='UNFIXABLE'
              />
              <ResultRow
                addBottomMargin
                count={projectedTolerable}
                indicatorTint='notification'
                percentage={formatPercentage(percentTolerableRounded)}
                type='TOLERABLE'
              />
            </>
          )}
          <ResultRow
            addBottomBorder
            bold
            count={notOKCount}
            indicatorTint={siteMode && 'alert'}
            percentage={formatPercentage(percentNotOKRounded)}
            tint='alert'
            type='FAILED'
          />
          {!siteMode && (
            <ResultRow
              addBottomMargin
              count={rejected}
              percentage={formatPercentage(percentRejectedRounded)}
              type='REJECTED'
            />
          )}
          <ResultRow addBottomBorder={siteMode} bold count={finalLotSize} type='FINAL_LOT' />
          {siteMode && (
            <ResultRow
              count={skippedTestsCount}
              percentage={formatPercentage(percentTestsSkippedRounded)}
              type='SKIPPED'
            />
          )}
        </tbody>
      </ResultTable>
    </div>
  );
}

InspectionLogLotResults.propTypes = {
  finalLotSize: PropTypes.number,
  fixed: PropTypes.number,
  initialLotSize: PropTypes.number,
  percentFixed: PropTypes.number,
  percentProjectedFixable: PropTypes.number,
  percentProjectedFlawless: PropTypes.number,
  percentProjectedTolerable: PropTypes.number,
  percentProjectedUnfixable: PropTypes.number,
  percentRejected: PropTypes.number,
  percentTestsSkipped: PropTypes.number,
  projectedFixable: PropTypes.number,
  projectedFlawless: PropTypes.number,
  projectedTolerable: PropTypes.number,
  projectedUnfixable: PropTypes.number,
  rejected: PropTypes.number,
  skippedTestsCount: PropTypes.number,
  sourceType: PropTypes.string.isRequired,
  useConditionalUI: PropTypes.bool,
};
