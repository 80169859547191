import { useLazyQuery, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import { ICONS } from 'OK/components/icon';
import ItemArchiveCard from 'OK/components/item/archiveCard';
import LabelArchiveCard from 'OK/components/label/archiveCard';
import LabelTemplatePreviewCard from 'OK/components/labelTemplate/previewCard';
import CardLayout from 'OK/components/layouts/card';
import TextLayout from 'OK/components/layouts/content/text';
import Notice from 'OK/components/notice';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent, PopupContext } from 'OK/components/popup';
import ProductArchiveCard from 'OK/components/product/archiveCard';
import Progressable from 'OK/components/progressable';
import Separator from 'OK/components/separator';
import Text from 'OK/components/text';
import SlideInOutTransition from 'OK/components/transitions/slideInOut';
import ItemModel from 'OK/models/item';
import LabelMakerOKIDSourceSelector, { SOURCE_TYPE } from 'OK/modules/labelMaker/okidSourceSelector';
import LabelMakerProductOrItemSelector from 'OK/modules/labelMaker/productOrItemSelector';
import LabelTemplateEditor from 'OK/modules/labelMaker/templateEditor';
import {
  createLabelForItemMutation,
  createLabelFromExistingLabelMutation,
  createLabelWithNewOKIDsMutation,
  setLabelTemplateMutation,
} from 'OK/networking/labels';
import {
  createLabelTemplateMutation,
  getOrganisationLabelTemplatesQuery,
  editLabelTemplateCornersMutation,
  editLabelTemplateEyeletMutation,
  editLabelTemplateMaterialMutation,
  editLabelTemplateSizeMutation,
  editLabelTemplateTypeMutation,
} from 'OK/networking/labelTemplates';
import { formatNumber, formatOkid } from 'OK/util/formatting';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';

export const LABEL_MAKER_MODE = {
  CREATE_LABEL: 'CREATE_LABEL',
  EDIT_LABEL: 'EDIT_LABEL',
};

const SECTIONS = {
  MAIN: 'MAIN',
  OKID_SOURCE: 'OKID_SOURCE',
  PRODUCT_OR_ITEM: 'PRODUCT_OR_ITEM',
  TEMPLATE_EDITOR: 'TEMPLATE_EDITOR',
};

export default function LabelMakerPopup(props) {
  /* Variables */

  const { label, mode = LABEL_MAKER_MODE.CREATE_LABEL, dismiss } = props;
  const [authenticated] = useAuthentication();
  const router = useRouter();
  const { t } = useI18n();

  // State

  const [activeLabelTemplateId, setActiveLabelTemplateId] = useState(label?.labelTemplate?.id);
  const [activeSection, _setActiveSection] = useState(SECTIONS.MAIN);
  const [creatingNewTemplate, setCreatingNewTemplate] = useState(false);
  const [error, setError] = useState(null);
  const [newOKIDCount, setNewOKIDCount] = useState(1);
  const [OKIDSourceType, setOKIDSourceType] = useState(null);
  const [rerunLabel, setRerunLabel] = useState(null);
  const [restoreScrollY, setRestoreScrollY] = useState(0);
  const [selectedProductOrItem, setSelectedProductOrItem] = useState(null);

  const onMainSection = activeSection === SECTIONS.MAIN;

  const setActiveSection = useCallback((newActiveSection) => {
    setError(null);
    if (newActiveSection !== SECTIONS.MAIN) {
      // Store scroll position to restore when returning to main section
      switch (newActiveSection) {
        case SECTIONS.OKID_SOURCE:
          setRestoreScrollY(okidSourceRef.current.offsetTop);
          break;
        case SECTIONS.PRODUCT_OR_ITEM:
          setRestoreScrollY(productOrItemSectionRef.current.offsetTop);
          break;
        default:
          setRestoreScrollY(0);
          break;
      }
    } else {
      setCreatingNewTemplate(false);
    }

    _setActiveSection(newActiveSection);
  }, []);

  // Refs

  const okidSourceRef = useRef();
  const popupRef = useRef();
  const productOrItemSectionRef = useRef();
  const templateCarouselRef = useRef();

  /* API */

  const [createLabelForItemAPI, createLabelForItemAPIResult] = useMutation(createLabelForItemMutation);
  const [createLabelFromExistingLabelAPI, createLabelFromExistingLabelAPIResult] = useMutation(
    createLabelFromExistingLabelMutation
  );
  const [createLabelWithNewOKIDsAPI, createLabelWithNewOKIDsAPIResult] = useMutation(createLabelWithNewOKIDsMutation);
  const [createTemplateAPI, createTemplateAPIResult] = useMutation(createLabelTemplateMutation, {
    refetchQueries: ['GetOrganisationLabelTemplates'],
  });
  const [editCornersAPI, editCornersAPIResult] = useMutation(editLabelTemplateCornersMutation);
  const [editEyeletAPI, editEyeletAPIResult] = useMutation(editLabelTemplateEyeletMutation);
  const [editMaterialAPI, editMaterialAPIResult] = useMutation(editLabelTemplateMaterialMutation);
  const [editSizeAPI, editSizeAPIResult] = useMutation(editLabelTemplateSizeMutation);
  const [editTypeAPI, editTypeAPIResult] = useMutation(editLabelTemplateTypeMutation);
  const [getLabelTemplatesAPI, getLabelTemplatesAPIResult] = useLazyQuery(getOrganisationLabelTemplatesQuery);
  const labelTemplates = useMemo(
    () => getLabelTemplatesAPIResult.data?.labelTemplates ?? [],
    [getLabelTemplatesAPIResult.data?.labelTemplates]
  );
  const [setActiveLabelTemplateAPI] = useMutation(setLabelTemplateMutation);

  /* Methods */

  const createLabel = useCallback(() => {
    let api;
    switch (OKIDSourceType) {
      case SOURCE_TYPE.NEW: {
        let productId;
        if (selectedProductOrItem.__typename === ItemModel.GRAPHQL_TYPE) {
          productId = selectedProductOrItem.product.id;
        } else {
          productId = selectedProductOrItem.id;
        }
        api = createLabelWithNewOKIDsAPI({
          variables: {
            labelTemplateId: activeLabelTemplateId,
            numberOfOKID: newOKIDCount,
            productId,
          },
        });
        break;
      }
      case SOURCE_TYPE.RERUN:
        api = createLabelFromExistingLabelAPI({
          variables: {
            labelId: rerunLabel.id,
            labelTemplateId: activeLabelTemplateId,
          },
        });
        break;
      case SOURCE_TYPE.ITEM:
        api = createLabelForItemAPI({
          variables: {
            itemId: selectedProductOrItem.id,
            labelTemplateId: activeLabelTemplateId,
            productId: selectedProductOrItem.product.id,
          },
        });
        break;
      default:
        if (!activeLabelTemplateId) {
          setError(t('LABEL_MAKER_POPUP_PLEASE_CREATE_TEMPLATE'));
        } else if (!selectedProductOrItem) {
          setError(t('LABEL_MAKER_POPUP_PLEASE_SELECT_PRODUCT'));
        } else {
          setError(t('LABEL_MAKER_POPUP_PLEASE_SELECT_OKID_SOURCE'));
        }
        return;
    }

    api
      .then((result) => {
        if (result.data?.label) {
          dismiss();
          router.push(`/archive?refId=${result.data.label.REFID}&dataType=LABEL`)
        } else {
          throw new Error('No label returned from API.');
        }
      })
      .catch((e) => {
        okerror('Error creating label.', e);
        setError(t('ERROR_GENERIC'));
      });
  }, [
    OKIDSourceType,
    activeLabelTemplateId,
    createLabelForItemAPI,
    createLabelFromExistingLabelAPI,
    createLabelWithNewOKIDsAPI,
    newOKIDCount,
    rerunLabel?.id,
    router,
    selectedProductOrItem,
    t,
  ]);

  const openEditTemplateSection = useCallback(
    (creatingTemplate = false) => {
      setActiveSection(SECTIONS.TEMPLATE_EDITOR);
      setCreatingNewTemplate(creatingTemplate);
    },
    [setActiveSection]
  );

  const saveTemplate = useCallback(
    async (template) => {
      setActiveSection(SECTIONS.MAIN);

      if (!creatingNewTemplate) {
        const editedTemplate = labelTemplates.find((lt) => lt.id === activeLabelTemplateId);
        if (template.type !== editedTemplate.type) {
          await editTypeAPI({
            variables: {
              labelTemplateId: editedTemplate.id,
              type: template.type,
            },
          });
        }
        if (template.size !== editedTemplate.size) {
          await editSizeAPI({
            variables: {
              labelTemplateId: editedTemplate.id,
              size: template.size,
            },
          });
        }
        if (template.material !== editedTemplate.material) {
          await editMaterialAPI({
            variables: {
              labelTemplateId: editedTemplate.id,
              material: template.material,
            },
          });
        }
        if (template.corners !== editedTemplate.corners) {
          await editCornersAPI({
            variables: {
              labelTemplateId: editedTemplate.id,
              corners: template.corners,
            },
          });
        }
        if (template.eyelet !== editedTemplate.eyelet) {
          await editEyeletAPI({
            variables: {
              labelTemplateId: editedTemplate.id,
              eyelet: template.eyelet,
            },
          });
        }
      } else {
        createTemplateAPI({
          variables: {
            corners: template.corners || 'NONE',
            eyelet: template.eyelet || 'NONE',
            languageIso: 'EN',
            material: template.material,
            size: template.size,
            type: template.type,
          },
        }).then((result) => {
          if (result.data?.labelTemplate?.id) {
            setActiveLabelTemplateId(result.data.labelTemplate.id);
          }
        });
      }
    },
    [
      activeLabelTemplateId,
      createTemplateAPI,
      creatingNewTemplate,
      editCornersAPI,
      editEyeletAPI,
      editMaterialAPI,
      editSizeAPI,
      editTypeAPI,
      labelTemplates,
      setActiveSection,
    ]
  );

  const setActiveLabelTemplate = useCallback(
    (labelTemplateId, setLoading) => {
      setLoading(true);
      setActiveLabelTemplateAPI({
        variables: {
          labelId: label.id,
          labelTemplateId,
        },
      }).finally(() => {
        setLoading(false);
      });
    },
    [label?.id, setActiveLabelTemplateAPI]
  );

  // Event handlers

  const onActiveSlideChanged = useCallback(
    (activeSlideIndexes) => {
      if (activeSlideIndexes.length) {
        const activeSlideIndex = activeSlideIndexes[0];
        setActiveLabelTemplateId(labelTemplates[activeSlideIndex].id);
      }
    },
    [labelTemplates]
  );

  const onSelectedProductOrItem = useCallback(
    (productOrItem) => {
      setSelectedProductOrItem(productOrItem);
      setActiveSection(SECTIONS.MAIN);
    },
    [setActiveSection]
  );

  const onSetOKIDSource = useCallback(
    (sourceType, sourceData) => {
      setOKIDSourceType(sourceType);

      switch (sourceType) {
        case SOURCE_TYPE.NEW:
          setNewOKIDCount(sourceData);
          break;
        case SOURCE_TYPE.RERUN:
          setRerunLabel(sourceData);
          break;
        case SOURCE_TYPE.ITEM:
          break;
      }

      setActiveSection(SECTIONS.MAIN);
    },
    [setActiveSection]
  );

  /* Effects */

  // Load templates
  useEffect(() => {
    if (authenticated) {
      getLabelTemplatesAPI()
        .then((result) => {
          if (label?.labelTemplate.id) {
            // Scroll to the label's selected template
            const indexOfActiveLabelTemplate = result.data?.labelTemplates.findIndex(
              (lt) => lt.id === label.labelTemplate.id
            );
            if (indexOfActiveLabelTemplate > -1) {
              setTimeout(() => {
                templateCarouselRef.current?.scrollToSlideAtIndex(indexOfActiveLabelTemplate, true);
              }, 0);
            }
          } else {
            // Auto-select first available template
            setActiveLabelTemplateId(result.data.labelTemplates[0].id);
          }
        })
        .catch((e) => {
          okdebug(e);
        });
    }
  }, [authenticated, getLabelTemplatesAPI, label?.labelTemplate.id]);

  // Restore scroll offset when returning to main section
  useEffect(() => {
    if (onMainSection && restoreScrollY) {
      // Restore scroll offset after slide in/out animation completes
      setTimeout(() => {
        popupRef.current.scrollTo({ behavior: 'smooth', top: restoreScrollY });
      }, 350);
    }
  }, [onMainSection, restoreScrollY]);

  // Scroll to latest slide when added
  useEffect(() => {
    templateCarouselRef.current?.scrollToSlideAtIndex(labelTemplates.length - 1);
  }, [labelTemplates.length]);

  /* Render */

  const creatingLabel =
    createLabelForItemAPIResult.loading ||
    createLabelFromExistingLabelAPIResult.loading ||
    createLabelWithNewOKIDsAPIResult.loading;
  const editingTemplate =
    editCornersAPIResult.loading ||
    editEyeletAPIResult.loading ||
    editMaterialAPIResult.loading ||
    editSizeAPIResult.loading ||
    editTypeAPIResult.loading;

  let dismissButtonText;
  let dismissButtonTint;
  let headerLabel;
  switch (mode) {
    case LABEL_MAKER_MODE.CREATE_LABEL:
      headerLabel = t('LABEL_MAKER_POPUP_HEADER_NEW_LABELS');
      dismissButtonTint = 'alert';
      if (activeSection === SECTIONS.MAIN) {
        dismissButtonText = t('CANCEL');
      }
      break;
    case LABEL_MAKER_MODE.EDIT_LABEL:
      headerLabel = t('LABEL_MAKER_POPUP_HEADER_EDIT_LABEL');
      dismissButtonTint = 'navigation';
      if (activeSection === SECTIONS.MAIN) {
        dismissButtonText = t('DONE');
      }
      break;
  }
  if (!dismissButtonText) {
    dismissButtonText = t('LABEL_MAKER_POPUP_GO_BACK');
  }

  const mainSection = useMemo(() => {
    let productOrItemCard;
    if (selectedProductOrItem) {
      if (selectedProductOrItem.__typename === ItemModel.GRAPHQL_TYPE) {
        productOrItemCard = (
          <ItemArchiveCard className={styles.productOrItemCard} layoutOverride='mobile' item={selectedProductOrItem} />
        );
      } else {
        productOrItemCard = (
          <ProductArchiveCard
            className={styles.productOrItemCard}
            layoutOverride='mobile'
            product={selectedProductOrItem}
          />
        );
      }
    } else if (activeLabelTemplateId) {
      productOrItemCard = <Text>{t('LABEL_MAKER_POPUP_NO_PRODUCT_SELECTED')}</Text>;
    } else {
      productOrItemCard = <Text>{t('LABEL_MAKER_POPUP_SELECT_TEMPLATE_FIRST')}</Text>;
    }

    let sourceType;
    if (OKIDSourceType) {
      switch (OKIDSourceType) {
        case SOURCE_TYPE.NEW:
          sourceType = (
            <CardLayout className={styles.sourceTypeCard} fixedWidth={false}>
              <Text bold>{t('LABEL_MAKER_POPUP_NEW_OKID_SOURCE')}</Text>
              <Notice className={styles.newOKIDsNotice} extendSides>
                {newOKIDCount === 1
                  ? t('LABEL_MAKER_POPUP_1_NEW_OKID')
                  : t('LABEL_MAKER_POPUP_X_NEW_OKIDS', { data: { number: formatNumber(newOKIDCount) } })}
              </Notice>
              <Text bold className={styles.newOKIDsFinePrint} size='xs'>
                {t('LABEL_MAKER_POPUP_NEW_OKID_FINEPRINT')}
              </Text>
            </CardLayout>
          );
          break;
        case SOURCE_TYPE.RERUN:
          sourceType = (
            <LabelArchiveCard
              className={styles.rerunLabelCard}
              label={rerunLabel}
              layoutOverride='mobile'
              linkToLabel={false}
            />
          );
          break;
        case SOURCE_TYPE.ITEM:
          sourceType = (
            <CardLayout className={styles.sourceTypeCard} fixedWidth={false}>
              <Text bold>{t('LABEL_MAKER_POPUP_OKID_SOURCE_ITEM')}</Text>
              <Notice className={styles.newOKIDsNotice} extendSides>
                {formatOkid(selectedProductOrItem.OKID)}
              </Notice>
            </CardLayout>
          );
          break;
      }
    } else if (selectedProductOrItem) {
      sourceType = <Text>{t('LABEL_MAKER_POPUP_NO_OKID_SOURCE_SELECTED')}</Text>;
    } else {
      sourceType = <Text>{t('LABEL_MAKER_POPUP_SELECT_PRODUCT_FIRST')}</Text>;
    }

    const productAndOKIDSection =
      mode === LABEL_MAKER_MODE.CREATE_LABEL ? (
        <>
          <TextLayout ref={productOrItemSectionRef}>
            <Separator type='section' />
            <div className={styles.sectionHeaderContainer}>
              <h4>{t('LABEL_MAKER_POPUP_SECTION_HEADER_PRODUCT')}</h4>
              {activeLabelTemplateId && (
                <Button linkStyle onClick={() => setActiveSection(SECTIONS.PRODUCT_OR_ITEM)} withCaret>
                  {t('EDIT')}
                </Button>
              )}
            </div>
            {productOrItemCard}
          </TextLayout>
          <TextLayout ref={okidSourceRef}>
            <Separator type='section' />
            <div className={styles.sectionHeaderContainer}>
              <h4>{t('LABEL_MAKER_POPUP_SECTION_HEADER_OKID_SOURCE')}</h4>
              {selectedProductOrItem && (
                <Button linkStyle onClick={() => setActiveSection(SECTIONS.OKID_SOURCE)} withCaret>
                  {t('EDIT')}
                </Button>
              )}
            </div>
            {sourceType}
            <Separator type='section' />
            <Text>{t('LABEL_MAKER_POPUP_DESCRIPTION')}</Text>
            <Button
              block
              className={styles.createButton}
              disabled={creatingLabel}
              loading={creatingLabel}
              onClick={createLabel}
              tint='creation'
            >
              {t('LABEL_MAKER_POPUP_CREATE_BUTTON')}
            </Button>
            {error && (
              <Text className={styles.createErrorMessage} size='sm' tint='alert'>
                {error}
              </Text>
            )}
            <Button className={styles.cancelButton} disabled={creatingLabel} linkStyle onClick={dismiss} tint='alert'>
              {t('LABEL_MAKER_POPUP_CANCEL_BUTTON')}
            </Button>
          </TextLayout>
        </>
      ) : (
        <TextLayout>
          <Separator type='section' />
          <Text>{t('LABEL_MAKER_POPUP_CANNOT_EDIT_ITEM_AND_SOURCE')}</Text>
        </TextLayout>
      );
    return (
      <div>
        <TextLayout className={styles.sectionHeaderContainer}>
          <h4>{t('LABEL_MAKER_POPUP_SECTION_HEADER_TEMPLATE')}</h4>
          {activeLabelTemplateId && (
            <Button
              disabled={editingTemplate}
              linkStyle
              loading={editingTemplate}
              onClick={() => openEditTemplateSection()}
              withCaret
            >
              {t('LABEL_MAKER_POPUP_EDIT_TEMPLATE')}
            </Button>
          )}
        </TextLayout>
        <Progressable inProgress={createTemplateAPIResult.loading}>
          {labelTemplates.length > 0 ? (
            <Carousel
              className={styles.templatesCarouselContainer}
              innerClassName={styles.templatesCarousel}
              onChangeVisibleSlides={onActiveSlideChanged}
              ref={templateCarouselRef}
              snapToCenter
              snapToSlides
            >
              {labelTemplates.map((lt) => {
                return (
                  <Slide className={styles.templateSlide} key={lt.id}>
                    <LabelTemplatePreviewCard
                      active={label ? lt.id === label.labelTemplate.id : null}
                      className={styles.templateCard}
                      fixedWidth={false}
                      labelTemplate={lt}
                      onClickMakeActive={setActiveLabelTemplate}
                    />
                  </Slide>
                );
              })}
            </Carousel>
          ) : (
            <TextLayout>
              <Text>{t('LABEL_MAKER_POPUP_NO_TEMPLATES')}</Text>
            </TextLayout>
          )}
        </Progressable>
        <TextLayout>
          <Button
            block
            disabled={createTemplateAPIResult.loading}
            loading={createTemplateAPIResult.loading}
            linkStyle
            icon={ICONS.PLUS.name}
            onClick={() => openEditTemplateSection(true)}
            tint='creation'
          >
            {t('LABEL_MAKER_POPUP_ADD_TEMPLATE_BUTTON')}
          </Button>
          {createTemplateAPIResult.error && (
            <Text className={styles.errorMessage} size='sm' tint='notification'>
              {t('ERROR_GENERIC')}
            </Text>
          )}
        </TextLayout>
        {productAndOKIDSection}
      </div>
    );
  }, [
    OKIDSourceType,
    activeLabelTemplateId,
    createLabel,
    createTemplateAPIResult.error,
    createTemplateAPIResult.loading,
    creatingLabel,
    error,
    dismiss,
    editingTemplate,
    label,
    labelTemplates,
    mode,
    newOKIDCount,
    onActiveSlideChanged,
    openEditTemplateSection,
    rerunLabel,
    selectedProductOrItem,
    setActiveLabelTemplate,
    setActiveSection,
    t,
  ]);

  return (
    <Popup dismiss={dismiss} ref={popupRef}>
      <PopupContext.Consumer>
        {({ dismiss }) => {
          return (
            <PopupContent className={styles.popupContent}>
              <TextLayout>
                <Button
                  className={styles.cancelHeaderButton}
                  disabled={creatingLabel}
                  linkStyle
                  onClick={onMainSection ? dismiss : () => setActiveSection(SECTIONS.MAIN)}
                  tint={onMainSection ? dismissButtonTint : 'navigation'}
                >
                  {dismissButtonText}
                </Button>
                <h3>{headerLabel}</h3>
              </TextLayout>
              <SlideInOutTransition fromRightSide={false} in={onMainSection} sideOffsetPx={30}>
                {mainSection}
              </SlideInOutTransition>
              <SlideInOutTransition in={activeSection === SECTIONS.TEMPLATE_EDITOR} sideOffsetPx={30}>
                <TextLayout>
                  <LabelTemplateEditor
                    labelTemplate={!creatingNewTemplate && labelTemplates.find((lt) => lt.id === activeLabelTemplateId)}
                    onCancel={() => setActiveSection(SECTIONS.MAIN)}
                    onSave={saveTemplate}
                  />
                </TextLayout>
              </SlideInOutTransition>
              <SlideInOutTransition in={activeSection === SECTIONS.PRODUCT_OR_ITEM} sideOffsetPx={30}>
                <TextLayout>
                  <LabelMakerProductOrItemSelector
                    header={t('LABEL_MAKER_POPUP_HEADER_SET_PRODUCT')}
                    onCancel={() => setActiveSection(SECTIONS.MAIN)}
                    onSave={onSelectedProductOrItem}
                  />
                </TextLayout>
              </SlideInOutTransition>
              <SlideInOutTransition in={activeSection === SECTIONS.OKID_SOURCE} sideOffsetPx={30}>
                <TextLayout>
                  <LabelMakerOKIDSourceSelector
                    linkedItemOKID={
                      selectedProductOrItem?.__typename === ItemModel.GRAPHQL_TYPE ? selectedProductOrItem.OKID : null
                    }
                    onCancel={() => setActiveSection(SECTIONS.MAIN)}
                    onSave={onSetOKIDSource}
                    filteredSelection={selectedProductOrItem}
                  />
                </TextLayout>
              </SlideInOutTransition>
            </PopupContent>
          );
        }}
      </PopupContext.Consumer>
      <PopupButtonsGroup>
        {onMainSection && (
          <PopupCloseButton disabled={creatingLabel} tint={dismissButtonTint}>
            {dismissButtonText}
          </PopupCloseButton>
        )}
      </PopupButtonsGroup>
    </Popup>
  );
}

LabelMakerPopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
  label: PropTypes.object,
  mode: PropTypes.oneOf([LABEL_MAKER_MODE.CREATE_LABEL, LABEL_MAKER_MODE.EDIT_LABEL]),
};
