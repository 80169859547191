import compareAsc from 'date-fns/compareAsc';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import CardLayout from 'OK/components/layouts/card';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import { formatDate, formatNumber } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function InvoiceCard(props) {
  const { className, invoice, ...otherProps } = props;
  const { invoiceDueDate, invoiceAmountUSD, invoiceURL, invoiceStatus } = invoice;
  const { locale } = useI18n();

  const download = useCallback(() => {
    window.open(invoiceURL, '_blank');
  }, [invoiceURL]);

  let isOverdue = false;
  const isPaid = invoiceStatus === 'PAID';
  if (invoiceStatus === 'PENDING') {
    isOverdue = compareAsc(new Date(), new Date(invoiceDueDate)) === 1;
  }

  let classNames = styles.container;
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <CardLayout className={classNames} onClick={download} {...otherProps}>
      <div className={styles.headerContainer}>
        <Text bold tint='navigation'>
          Invoice
        </Text>
        <Icon name={ICONS.DOWNLOAD.name} tint='navigation' />
      </div>
      <div className={styles.statusContainer}>
        {isPaid && (
          <Tag className={styles.statusTag} tint='creation'>
            Paid
          </Tag>
        )}
        {isOverdue && (
          <Tag className={styles.statusTag} tint='alert'>
            Past due
          </Tag>
        )}
      </div>
      <div className={styles.priceContainer}>
        <Text>{invoiceDueDate ? `Due ${formatDate(invoiceDueDate, locale, { style: 'short' })}` : 'No due date'}</Text>
        <Text bold>{formatNumber(invoiceAmountUSD)} USD</Text>
      </div>
    </CardLayout>
  );
}

InvoiceCard.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.shape({
    invoiceAmountUSD: PropTypes.number,
    invoiceDueDate: PropTypes.instanceOf(Date),
    invoicePublicId: PropTypes.string,
    invoiceStatus: PropTypes.string,
    invoiceURL: PropTypes.string,
  }).isRequired,
};
