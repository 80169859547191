import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState, useMemo } from 'react';

import styles from './styles.module.scss';

import FormFieldI18n from 'OK/components/form/formFieldI18n';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import { Popup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import Text from 'OK/components/text';
import SiteModel from 'OK/models/site';
import {
  removeSiteNameForLanguageMutation,
  setSiteIdentifierMutation,
  setSiteNameForLanguageMutation,
} from 'OK/networking/sites';
import { formatOkid } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function SiteInfoEditPopup(props) {
  const { dismiss, site, identifier } = props;

  const { t } = useI18n();

  const [nameError, setNameError] = useState(null);
  const [newIdentifier, setNewIdentifier] = useState('');
  const [identifierError, setIdentifierError] = useState(null);

  const [removeSiteNameAPI] = useMutation(removeSiteNameForLanguageMutation);
  const [setSiteNameAPI] = useMutation(setSiteNameForLanguageMutation);
  const [setSiteIdentifierAPI, setSiteIdentifierAPIResult] = useMutation(setSiteIdentifierMutation);

  const onBlurName = useCallback(
    (blurredLanguageIso, values) => {
      setNameError(null);
      const name = values.find((v) => v.languageIso === blurredLanguageIso)?.value;
      if (name !== site.name[blurredLanguageIso]?.text) {
        const optimisticResponse = {
          site: {
            id: site.id,
            name: {
              ...site.name,
              [blurredLanguageIso]: {
                ...site.name[blurredLanguageIso],
                text: name,
              },
            },
            __typename: SiteModel.GRAPHQL_TYPE,
          },
        };
        setSiteNameAPI({
          variables: {
            siteId: site.id,
            languageIso: blurredLanguageIso,
            name,
          },
          optimisticResponse,
        }).catch(() => {
          setNameError(t('ERROR_GENERIC'));
        });
      }
    },
    [setSiteNameAPI, site.id, site.name, t]
  );

  const onChangeName = useCallback(
    (changedLanguageIso, values) => {
      setNameError(null);
      if (values.findIndex((v) => v.languageIso === changedLanguageIso) === -1) {
        const updatedSiteNameObject = { ...site.name };
        delete updatedSiteNameObject[changedLanguageIso];
        const optimisticResponse = {
          site: {
            id: site.id,
            name: {
              ...updatedSiteNameObject,
            },
            __typename: SiteModel.GRAPHQL_TYPE,
          },
        };
        removeSiteNameAPI({
          variables: {
            siteId: site.id,
            languageIso: changedLanguageIso,
          },
          optimisticResponse,
        }).catch(() => {
          setNameError(t('ERROR_GENERIC'));
        });
      }
    },
    [removeSiteNameAPI, site.id, site.name, t]
  );

  const onChangeIdentifier = useCallback((e) => {
    setNewIdentifier(e.target.value);
  }, []);

  const saveIdentifier = useCallback(() => {
    if (newIdentifier.length && newIdentifier !== identifier) {
      setIdentifierError(null);
      setSiteIdentifierAPI({
        variables: {
          siteId: site.id,
          identifier: newIdentifier,
        },
      }).catch(() => {
        setIdentifierError(t('ERROR_GENERIC'));
      });
    } else if (!newIdentifier.length) {
      setNewIdentifier(identifier);
      setIdentifierError(t('PRODUCT_SKU_EMPTY_ERROR'));
    }
  }, [identifier, newIdentifier, setSiteIdentifierAPI, site?.id, t]);

  const siteName = useMemo(() => {
    if (site?.name) {
      return Object.keys(site.name).map((languageIso) => {
        return { languageIso, value: site.name[languageIso].text };
      });
    }

    return [];
  }, [site.name]);

  // Keep newSKU in sync with saved identifier
  useEffect(() => {
    setNewIdentifier(identifier);
  }, [identifier]);

  return (
    <Popup dismiss={dismiss}>
      <PopupContent>
        <div className={styles.header}>
          <h3 className={styles.textAvatar}>{t('EDIT_DETAILS')}</h3>
          <div>
            <PopupCloseButton className={styles.buttonSave} linkStyle tint='navigation'>
             {t('DONE')} 
            </PopupCloseButton>
          </div>
        </div>
        <h4>
          {t('PRODUCT_FIELD_NAME')} <span className={styles.normalWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
        </h4>
        <div className={styles.field}>
          <FormFieldI18n
            inputPlaceholder={t('PRODUCT_FIELD_NAME_PLACEHOLDER', { data: { language: '{{language}}' } })}
            onBlur={onBlurName}
            onChange={onChangeName}
            showWarningIfExceedsLength={35}
            values={siteName}
          />
          {nameError && (
            <Text className={styles.nameError} size='sm' tint='notification'>
              {nameError}
            </Text>
          )}
        </div>
        <label className={`${styles.label} ${styles.identifier}`}>
          <h4>{t('STEP_LOGGING_CARD_SECTION_HEADER_IDENTIFIERS')}</h4>
          <Text bold style={{ marginBottom: 0 }}>
            {t('SITE_FIELD_INTERNAL_ID_LABEL')}
          </Text>
          <Input
            className={`${styles.input} ${styles.monoInput}`}
            disabled={setSiteIdentifierAPIResult.loading}
            onBlur={saveIdentifier}
            onChange={onChangeIdentifier}
            placeholder={t('PRODUCT_FIELD_SKU_PLACEHOLDER')}
            showClearButton={newIdentifier.length > 0 && !setSiteIdentifierAPIResult.loading}
            type='text'
            value={newIdentifier}
            warning={setSiteIdentifierAPIResult.error ? t('UPDATE_FAILED_CONNECTION') : null}
            withIcon={setSiteIdentifierAPIResult.loading ? <Icon name={ICONS.SPINNER.name} /> : null}
          />
          {identifierError ? (
            <Text className={styles.skuErrorMessage} size='sm' tint='notification'>
              {identifierError}
            </Text>
          ) : null}
          <p className={styles.inputHelpText} style={{ marginBottom: 0 }}>
            {t('SITE_FIELD_INTERNAL_ID_GUIDANCE')}
          </p>
        </label>
        <label className={`${styles.label} ${styles.identifier}`}>
          <Text bold style={{ marginBottom: 0 }}>
            {t('OKID')}
          </Text>
          <Input className={`${styles.input} ${styles.monoInput}`} type='text' value={formatOkid(site.OKID)} readOnly />
          <p className={styles.inputHelpText} style={{ marginBottom: 0 }}>
            {t('SITE_FIELD_OKID_GUIDANCE')}
          </p>
        </label>
      </PopupContent>
    </Popup>
  );
}

SiteInfoEditPopup.propTypes = {
  dismiss: PropTypes.func,
  site: PropTypes.object.isRequired,
  identifier: PropTypes.string.isRequired,
};
