import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { createContext, forwardRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import UIContext from 'OK/util/context/ui';

/* Constants */

const FADE_OUT_TIMEOUT_MS = 250;

/* Context */

export const PopupContext = createContext({
  dismiss: () => {},
  render: true,
});

/* Helper functions */

const stopPropagation = (e) => e.stopPropagation();

/**
 * @typedef {object} PopupProps
 * @prop {function} dismiss Function to dismiss the popup.
 */

/**
 * Popup container that displays a fullscreen overlay. Should only contain 2 children:
 * `PopupButtonsGroup` and `PopupContent`.
 *
 * @type {React.FC<PopupProps>}
 */
export const Popup = forwardRef((props, forwardedRef) => {
  /* Variables */

  const { children, dismiss: dismissProp, dismissOnBackgroundClick = true } = props;
  const innerRef = useRef();
  const popupRef = forwardedRef || innerRef;
  const userPermitsCookies = useSelector((state) => state.app.userPermitsCookies);
  const waitForCookieAcceptance = userPermitsCookies === null;

  /* State */

  const [render, setRender] = useState(true);

  /* Methods */

  // Fade out and then call the dismiss function prop
  const dismiss = useCallback(
    (e) => {
      setRender(false);

      setTimeout(() => {
        dismissProp(e);
      }, FADE_OUT_TIMEOUT_MS);
    },
    [dismissProp]
  );

  /* Event handlers */

  const onClickBackground = useCallback(
    (e) => {
      if (dismissOnBackgroundClick) {
        dismiss(e);
      }
    },
    [dismiss, dismissOnBackgroundClick]
  );

  /* Effects */

  // Stop touch move propagation so the page behind the popup doesn't scroll on mobile devices.
  // Scrolling the page is already disabled by adding overflow: hidden CSS on the body.
  useEffect(() => {
    const popup = popupRef.current;
    const stopPropagation = (e) => {
      e.stopPropagation();
      return true;
    };
    popup?.addEventListener('touchmove', stopPropagation);

    return function () {
      popup.removeEventListener('touchmove', stopPropagation);
    };
  }, [popupRef]);

  /* Render */

  const popup = (
    <>
      <Head>
        <style>{`
          body, html {
            overflow: hidden !important;
          }
        `}</style>
      </Head>
      <CSSTransition
        appear
        classNames={{
          appear: styles.exit,
          appearActive: styles.enter,
          appearDone: styles.enter,
          enter: styles.exit,
          enterActive: styles.enter,
          enterDone: styles.enter,
          exit: styles.exit,
          exitActive: styles.exit,
          exitDone: styles.exit,
        }}
        in={render}
        mountOnEnter
        unmountOnExit
        timeout={FADE_OUT_TIMEOUT_MS}
      >
        <div className={styles.background} onClick={onClickBackground} ref={popupRef}>
          <PopupContext.Provider value={{ dismiss, render }}>
            {children}
            <div id='popupButtonsGroup' />
          </PopupContext.Provider>
        </div>
      </CSSTransition>
    </>
  );

  if (waitForCookieAcceptance) {
    return null;
  }

  return createPortal(popup, document.getElementById('popupContainer'));
});

Popup.propTypes = {
  children: PropTypes.node,
  dismiss: PropTypes.func.isRequired,
  dismissOnBackgroundClick: PropTypes.bool,
};

/** Buttons associated with the popup. Buttons will render fixed to the bottom of the screen. */
export function PopupButtonsGroup(props) {
  const { children: childrenProp, className } = props;

  const [portalElement, setPortalElement] = useState(null);

  // Get portal element after rendered
  useEffect(() => {
    const el = document.getElementById('popupButtonsGroup');
    if (el) {
      setPortalElement(el);
    }
  }, []);

  if (!portalElement) {
    return null;
  }

  let classNames = styles.buttonsContainer;
  if (className) {
    classNames += ` ${className}`;
  }

  const children = (
    <div className={classNames} onClick={stopPropagation}>
      {childrenProp}
    </div>
  );

  return createPortal(children, portalElement);
}

PopupButtonsGroup.propTypes = {
  children: PropTypes.node,
};

/** Button to close the popup. This is required so the popup closes with the desired effect. */
export const PopupCloseButton = forwardRef((props, forwardedRef) => {
  const { children, ...otherProps } = props;
  const { dismiss } = useContext(PopupContext);

  return (
    <Button onClick={dismiss} ref={forwardedRef} {...otherProps}>
      {children}
    </Button>
  );
});

PopupCloseButton.propTypes = {
  children: PropTypes.node,
};

/**
 * The actual contents of the popup. Children will render in the popup box.
 *
 * @param {object} props
 * @param {boolean} [props.reducedSidePadding=false] Reduce side padding.
 */
export const PopupContent = forwardRef((props, forwardedRef) => {
  const { children, className, reducedSidePadding = false, ...otherProps } = props;
  const { render } = useContext(PopupContext);

  // Classes

  let classNames = styles.popup;
  if (reducedSidePadding) {
    classNames = `${classNames} ${styles.reducedSidePadding}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }
  return (
    <CSSTransition
      appear
      classNames={{
        appear: styles.exit,
        appearActive: styles.enter,
        appearDone: styles.enter,
        enter: styles.exit,
        enterActive: styles.enter,
        enterDone: styles.enter,
        exit: styles.exit,
        exitActive: styles.exit,
        exitDone: styles.exit,
      }}
      in={render}
      timeout={FADE_OUT_TIMEOUT_MS}
    >
      <UIContext.Provider value='base'>
        <div className={classNames} onClick={stopPropagation} ref={forwardedRef} {...otherProps}>
          {children}
        </div>
      </UIContext.Provider>
    </CSSTransition>
  );
});

PopupContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  reducedSidePadding: PropTypes.bool,
};
