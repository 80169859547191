import PropTypes from 'prop-types';
import { useCallback, useContext, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { CommentsContext } from 'OK/components/comments/controller';
import CommentsThread from 'OK/components/comments/thread';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import ThemeContext from 'OK/util/context/theme';
import { formatDateRelative, formatOkid } from 'OK/util/formatting';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * UI component for rendering a comment.
 *
 * @param {object} props
 */
export default function Comment(props) {
  /* Variables */

  const { className, comment, replyTargetAssetId, replyTargetAssetType } = props;
  const { deleteComment, likeComment, unlikeComment, setInputTarget } = useContext(CommentsContext);
  const [, , currentUser] = useAuthentication(() => false);
  const { createdDate, commentText, organisation, parentCommentId, user, likedByUser, likeCount } = comment;
  const thread = comment.commentThreadPagedResult?.commentList;
  const threadCount = comment.commentThreadPagedResult?.commentListCount;
  const isAReply = typeof parentCommentId === 'string';
  const { locale, t } = useI18n();
  const theme = useContext(ThemeContext);

  /* State */

  const [error, setError] = useState(null);

  // Utils

  /* A function for converting formatted date into the right format eg. 4d, 3h, 49s...*/

  const timeAgoFunc = () => {
    const timeAgo = formatDateRelative(new Date(createdDate), null, locale, { addSuffix: false });
    const firstPartIndex = timeAgo.indexOf(' ');
    const firstPart = timeAgo.substr(0, firstPartIndex);
    const secondPart = timeAgo.substr(firstPartIndex + 1);
    const firstLetterOfSecondWord = secondPart.slice(0, 1);
    const timeAgoFinal = firstPart.concat(firstLetterOfSecondWord);
    return timeAgoFinal;
  };

  /* Event handlers */

  const onClickReply = useCallback(() => {
    setInputTarget('COMMENT', comment.id);
  }, [comment.id, setInputTarget]);

  const onClickLikeUnlike = useCallback(() => {
    setError(null);
    if (likedByUser == false) {
      likeComment(comment.id).catch(() => {
        setError(t('ERROR_GENERIC'));
      });
    } else if (likedByUser == true) {
      unlikeComment(comment.id).catch(() => {
        setError(t('ERROR_GENERIC'));
      });
    }
  }, [comment.id, likeComment, likedByUser, t, unlikeComment]);

  const onClickDelete = useCallback(() => {
    setError(null);
    deleteComment(replyTargetAssetType, replyTargetAssetId, comment.id).catch(() => {
      setError(t('ERROR_GENERIC'));
    });
  }, [comment.id, deleteComment, replyTargetAssetId, replyTargetAssetType, t]);

  /* Render */

  const postedByCurrentUser = comment.userId === currentUser?.id;

  // Container classes
  let classNames = styles.container;
  if (postedByCurrentUser) {
    classNames += ` ${styles.postedByCurrentUser}`;
  }
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <>
      <div className={classNames}>
        <div className={styles.header}>
          <img
            alt='No image'
            className={styles.imagePlaceholder}
            src={
              user.avatar
                ? theme.name == 'light'
                  ? user.avatar.sourceLightURL
                  : user.avatar.sourceDarkURL
                : `/img/empty_media_${theme.name}.svg`
            }
          />
          <div className={styles.commenterContainer}>
            <Text bold className={styles.commenterName} size='sm' tint='navigation'>
              {formatOkid(user.OKID)}
            </Text>
            <div className={styles.organisationInfoContainer}>
              <img
                src={
                  organisation.logoImageMediaAsset?.logoImageURL
                    ? organisation.logoImageMediaAsset.logoImageURL
                    : '/img/empty_media_light.svg'
                }
                alt='No image'
                className={styles.organisationImage}
              />
              <Text className={styles.organisationName} size='sm' tint='navigation'>
                {organisation.name}
              </Text>
            </div>
          </div>
        </div>
        <div className={styles.textContainer}>
          <Text>{commentText}</Text>
        </div>
        <div className={styles.actionsContainer}>
          <Button
            className={likedByUser ? `${styles.actionButtonLikeUser}` : `${styles.actionButtonLike}`}
            icon={likedByUser ? '/icons/unlike_icon.svg' : '/icons/like_icon.svg'}
            iconPosition='left'
            onClick={onClickLikeUnlike}
            tint={likedByUser ? 'navigation' : 'secondary'}
          >
            {likeCount > 0 ? likeCount : ''}
          </Button>
          {!isAReply && (
            <Button className={styles.actionButton} linkStyle onClick={onClickReply}>
              {t('COMMENT_BUTTON_REPLY')}
            </Button>
          )}
          {postedByCurrentUser && (
            <Button className={styles.actionButton} linkStyle onClick={onClickDelete}>
              {t('DELETE')}
            </Button>
          )}
          <div className={styles.informationContainer}>
            <Tag block className={styles.timeSinceTag}>
              {timeAgoFunc(createdDate)}
            </Tag>
          </div>
        </div>
        {error && (
          <Text className={styles.errorMessage} size='sm' tint='alert'>
            {error}
          </Text>
        )}
      </div>
      <CommentsThread assetType='COMMENT' assetId={comment.id} nested thread={thread} threadCount={threadCount} />
    </>
  );
}

Comment.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    commentText: PropTypes.string.isRequired,
    commentThreadPagedResult: PropTypes.shape({
      commentList: PropTypes.array,
      commentListCount: PropTypes.number,
    }),
    createdDate: PropTypes.number,
    likeCount: PropTypes.number,
    likedByUser: PropTypes.bool,
    organisation: PropTypes.object,
    organisationId: PropTypes.string,
    parentCommentId: PropTypes.string,
    user: PropTypes.object,
    userId: PropTypes.string,
  }).isRequired,
  replyTargetAssetId: PropTypes.string,
  replyTargetAssetType: PropTypes.string,
};
