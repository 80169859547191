import { gql } from '@apollo/client';

import BaseDocumentModel from './document/base';

export default class OrderDocumentModel {
  static GRAPHQL_TYPE = 'OrderDocumentAsset';

  static fragmentName = 'OrderDocument_Fragment';
  static fragment = gql`
    fragment ${OrderDocumentModel.fragmentName} on ${OrderDocumentModel.GRAPHQL_TYPE} {
      documentAsset {
        ...${BaseDocumentModel.fragmentName}
      }
      publishError @client
      publishStatus
    }
    ${BaseDocumentModel.fragment}
  `;

  static fragmentNamePublishError = 'OrderDocument_PublishError_Fragment';
  static fragmentPublishError = gql`
    fragment ${OrderDocumentModel.fragmentNamePublishError} on ${OrderDocumentModel.GRAPHQL_TYPE} {
      publishError @client
    }
  `;
}
