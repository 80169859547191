/* eslint-disable jsx-a11y/alt-text */
import PropTypes from 'prop-types';
import { useContext } from 'react';

import ThemeContext from 'OK/util/context/theme';
import addPixelDensityToFilename from 'OK/util/functions/addPixelDensityToFilename';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * Renders an `<img>` with some props for convenience. All native props are passed through to the underlying tag,
 * potentially with modification according to the configured props.
 *
 * @param {object} props
 * @param {boolean} [props.addSrcSet=false] Generate a `srcSet` based on `src`.
 * @param {boolean} [props.ensureSrcIncludesAppImagesBasePath=true] Ensure `src` includes the base path to app images.
 * @param {boolean} [props.localized=false] Whether the image is localized. If so, `src` will be passed through the `i18nImageSrc` function.
 * @param {boolean} [props.themed=false] Whether the image is themed. If so, `src` will be modified to include the current theme at the end of the filename.
 *
 * @returns {Node}
 */
export default function Image(props) {
  const {
    addSrcSet = false,
    ensureSrcIncludesAppImagesBasePath = true,
    localized = false,
    src: originalSrc,
    themed = false,
    ...otherProps
  } = props;
  const theme = useContext(ThemeContext);
  const { i18nImageSrc } = useI18n();

  // Modify `src` according to props
  let src = originalSrc;

  if (themed) {
    // Add theme to src before filetype and pixel density
    const suffixMatch = originalSrc.match(/(@[123]x)?\.[a-zA-Z]+$/);
    if (typeof suffixMatch?.index === 'number') {
      const filename = src.slice(0, suffixMatch.index);
      const suffix = suffixMatch[0];
      src = `${filename}_${theme.name}${suffix}`;
    }
  }

  if (localized) {
    // Add i18n path for src
    src = i18nImageSrc(src);
  }

  if (ensureSrcIncludesAppImagesBasePath) {
    const OKBaseImagePath = '/img';
    // Ensure src includes image base path
    const srcIncludesImgBasePath = new RegExp(`^${OKBaseImagePath}`).test(src);
    if (!srcIncludesImgBasePath) {
      const srcIncludesLeadingSlash = src.slice(0, 1) === '/';
      src = srcIncludesLeadingSlash ? `${OKBaseImagePath}${src}` : `${OKBaseImagePath}/${src}`;
    }
  }

  let srcSet;
  if (typeof props.srcSet === 'string') {
    // Keep original srcSet from props
    srcSet = props.srcSet;
  } else if (addSrcSet) {
    // Generate srcSet from src
    srcSet = `${src} 1x, ${addPixelDensityToFilename(src, '2x')} 2x`;
  }

  return <img src={src} srcSet={srcSet} {...otherProps} />;
}

Image.propTypes = {
  addSrcSet: PropTypes.bool,
  ensureSrcIncludesAppImagesBasePath: PropTypes.bool,
  localized: PropTypes.bool,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  themed: PropTypes.bool,
};
