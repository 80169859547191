import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PopupAssetInterface from './popupAssetUI';

import { setBrowserHistoryAction, setShowInPopup, updateMiscAppStateAction } from 'OK/state/app/actions';

export default function PopupInterface(props) {
  const { children } = props;

  const dispatch = useDispatch();

  const showInPopup = useSelector((state) => state.app.showInPopup);
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);

  const [popupUIScrollTop, setPopupUIScrollTop] = useState(0);

  const [windowHeight, setWindowHeight] = useState(0);
  const [assetData, setAssetData] = useState(null);
  const [firstPageLoad, setFirstPageLoad] = useState(false);

  const popupUIRef = useRef(null);
  const browserHistory = useSelector((state) => state.app.history);

  const router = useRouter();

  useEffect(() => {
    if (router.query.refId && router.query.dataType) {
      setFirstPageLoad(true);
    }
  }, [router.query.dataType, router.query.refId]);

  if (typeof window !== 'undefined') {
    window.onpopstate = function () {
      setAssetData(history.state);
      if (history?.state?.isPopup === true) {
        history?.state?.isPopup === true && dispatch(setShowInPopup(true));
      } else {
        history?.state?.isPopup !== true && dispatch(setShowInPopup(false));
      }
      if (router.query.refId && router.query.dataType) {
        router.push({ query: {} });
      }
    };
  }

  useEffect(() => {
    setWindowHeight(window.innerHeight);
    document
      .getElementById('popup')
      ?.setAttribute(
        'style',
        `height: ${
          useDesktopLayout && popupUIScrollTop > 0
            ? windowHeight - 10
            : !useDesktopLayout && popupUIScrollTop > 0
              ? windowHeight - 130
              : windowHeight - 78
        }px `
      );
  }, [popupUIScrollTop, showInPopup, useDesktopLayout, windowHeight]);

  // typeof window !== 'undefined' &&
  //   window.addEventListener('resize', () => {
  //     setWindowHeight(window.innerHeight);
  //   });

  useEffect(() => {
    if (popupUIScrollTop >= 1) {
      dispatch(updateMiscAppStateAction({ isScrollingPopup: true }));
    } else {
      dispatch(updateMiscAppStateAction({ isScrollingPopup: false }));
    }
  }, [dispatch, popupUIScrollTop]);

  const handlePopupUIContainerScroll = () => {
    setPopupUIScrollTop(popupUIRef?.current?.scrollTop);
  };

  useEffect(() => {
    setAssetData(history.state);
    if (history?.state?.isPopup === true) {
      dispatch(setShowInPopup(true));
    } else {
      dispatch(setShowInPopup(false));
    }
  }, [browserHistory, dispatch, router.query.dataType, router.query.refId]);

  useEffect(() => {
    if (router.query.refId && router.query.dataType) {
      history.pushState({ isPopup: true, refId: router.query.refId, dataType: router.query.dataType }, null);
      dispatch(setBrowserHistoryAction(history));
    }
  }, [dispatch, router.query.dataType, router.query.refId]);

  return (
    <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
      {children}
      {showInPopup && (
        <>
          <PopupAssetInterface
            onScroll={handlePopupUIContainerScroll}
            refs={popupUIRef}
            dataType={assetData?.dataType}
            REFID={assetData?.refId}
            OKID={assetData?.OKID || assetData?.refId}
            dismiss={() => {
              history.pushState(
                { isPopup: false, refId: null, dataType: null, OKID: null },
                null,
                firstPageLoad ? '/archive' : router.asPath
              );
              dispatch(setBrowserHistoryAction(history));
              setFirstPageLoad(false);
              dispatch(updateMiscAppStateAction({ isScrollingPopup: false }));
            }}
          />
        </>
      )}
    </div>
  );
}

PopupInterface.propTypes = {
  children: PropTypes.node,
};
