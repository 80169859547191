import { useMutation } from '@apollo/client';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import countries from 'OK/assets/countries.json';
import Button from 'OK/components/button';
import { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import PhoneInput from 'OK/components/input/phoneInput';
import { Popup, PopupContent } from 'OK/components/popup';
import Text from 'OK/components/text';
import {
  getUserEmailOTPMutation,
  getUserMobileOTPMutation,
  setUserEmailMutation,
  setUserMobileMutation,
} from 'OK/networking/users';
import useI18n from 'OK/util/hooks/useI18n';

const MODEEmail = {
  EMAIL: 'EMAIL',
  OTP: 'OTP',
};

const MODEMobile = {
  NUMBER: 'NUMBER',
  OTP: 'OTP',
};

export default function SetUserContactInfoPopup(props) {
  /* Variables */

  const { t, tHTML } = useI18n();
  const { dismiss, header = t('EDIT_DETAILS') } = props;
  const region = useSelector((state) => state.account.preferences.region);

  // State

  const [attemptDataMobile, setAttemptDataMobile] = useState(null);
  const [errorMobile, setErrorMobile] = useState(null);
  const [expiredMobile, setExpiredMobile] = useState(false);
  const [mobile, setMobile] = useState('');
  const [mobileCountryIsoCode, setMobileCountryIsoCode] = useState(region);
  const [modeMobile, setModeMobile] = useState(MODEMobile.NUMBER);
  const [otpMobile, setOTPMobile] = useState('');
  const [secondsLeftMobile, setSecondsLeftMobile] = useState(0);
  const [attemptDataEmail, setAttemptDataEmail] = useState(null);
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState(null);
  const [expiredEmail, setExpiredEmail] = useState(false);
  const [modeEmail, setModeEmail] = useState(MODEEmail.EMAIL);
  const [otpEmail, setOTPEmail] = useState('');
  const [secondsLeftEmail, setSecondsLeftEmail] = useState(0);

  /* API */

  const [getChangeEmailOTPAPI, getChangeEmailOTPAPIResult] = useMutation(getUserEmailOTPMutation);
  const [setEmailAPI, setEmailAPIResult] = useMutation(setUserEmailMutation, {
    refetchQueries: ['GetCurrentUser'],
  });
  const [getChangeMobileOTPAPI, getChangeMobileOTPAPIResult] = useMutation(getUserMobileOTPMutation);
  const [setMobileAPI, setMobileAPIResult] = useMutation(setUserMobileMutation, {
    refetchQueries: ['GetCurrentUser'],
  });

  /* Methods */

  const beginAttemptMobile = useCallback((newAttemptData) => {
    const secondsTillExpiration = differenceInSeconds(new Date(newAttemptData.expiresAt), new Date());
    setSecondsLeftMobile(secondsTillExpiration);
    setExpiredMobile(false);
    setAttemptDataMobile(newAttemptData);
    setOTPMobile('');
    setModeMobile(MODEMobile.OTP);
  }, []);

  const confirmChangeMobile = useCallback(() => {
    setErrorMobile(null);
    setMobileAPI({
      variables: {
        mobile,
        mobileCountryCode: `+${countries.find((c) => c.isoAlpha3 === mobileCountryIsoCode).phoneCode}`,
        otp: otpMobile,
      },
    })
      .then((result) => {
        if (result.data?.response?.verified) {
          dismiss();
        } else {
          setErrorMobile(t('ERROR_GENERIC'));
        }
      })
      .catch((e) => {
        if (
          e.message === 'USER_OTP_INCORRECT' &&
          e.graphQLErrors?.length &&
          e.graphQLErrors[0].extensions?.data?.attemptLeft > 0
        ) {
          const responseAttemptData = e.graphQLErrors[0].extensions.data;
          setAttemptDataMobile(responseAttemptData);
          setErrorMobile(t('ERROR_OTP_CODE_MISMATCH'));
        } else {
          setErrorMobile(t('ERROR_GENERIC'));
        }
      });
  }, [dismiss, mobile, mobileCountryIsoCode, otpMobile, setMobileAPI, t]);

  const requestCodeMobile = useCallback(() => {
    setErrorMobile(null);
    getChangeMobileOTPAPI({
      variables: {
        mobile,
        mobileCountryCode: `+${countries.find((c) => c.isoAlpha3 === mobileCountryIsoCode).phoneCode}`,
      },
    })
      .then((result) => {
        if (result.data?.response) {
          beginAttemptMobile(result.data.response);
        } else {
          setErrorMobile(t('ERROR_GENERIC'));
        }
      })
      .catch((e) => {
        if (e.message === 'USER_MOBILE_IS_ALREADY_IN_USE') {
          setErrorMobile(t('PHONE_ALREADY_IN_USE'));
        } else if (
          e.message === 'USER_OTP_TIMEOUT' &&
          e.graphQLErrors?.length &&
          e.graphQLErrors[0].extensions?.data?.attemptLeft > 0
        ) {
          const responseAttemptData = e.graphQLErrors[0].extensions.data;
          beginAttemptMobile(responseAttemptData);
        } else {
          setErrorMobile(t('ERROR_GENERIC'));
        }
      });
  }, [beginAttemptMobile, getChangeMobileOTPAPI, mobile, mobileCountryIsoCode, t]);

  const beginAttemptEmail = useCallback((newAttemptDataEmail) => {
    const secondsTillExpiration = differenceInSeconds(new Date(newAttemptDataEmail.expiresAt), new Date());
    setSecondsLeftEmail(secondsTillExpiration);
    setExpiredEmail(false);
    setAttemptDataEmail(newAttemptDataEmail);
    setOTPEmail('');
    setModeEmail(MODEEmail.OTP);
  }, []);

  const confirmChangeEmail = useCallback(() => {
    setErrorEmail(null);
    setEmailAPI({
      variables: {
        email,
        otpEmail,
      },
    })
      .then((result) => {
        if (result.data?.response?.verified) {
          dismiss();
        } else {
          setErrorEmail(t('ERROR_GENERIC'));
        }
      })
      .catch((e) => {
        if (
          e.message === 'USER_OTP_INCORRECT' &&
          e.graphQLErrors?.length &&
          e.graphQLErrors[0].extensions?.data?.attemptLeft > 0
        ) {
          const responseAttemptData = e.graphQLErrors[0].extensions.data;
          setAttemptDataEmail(responseAttemptData);
          setErrorEmail(t('ERROR_OTP_CODE_MISMATCH'));
        } else {
          setErrorEmail(t('ERROR_GENERIC'));
        }
      });
  }, [dismiss, email, otpEmail, setEmailAPI, t]);

  const requestCodeEmail = useCallback(() => {
    setErrorEmail(null);
    getChangeEmailOTPAPI({
      variables: {
        email,
      },
    })
      .then((result) => {
        if (result.data?.response) {
          beginAttemptEmail(result.data.response);
        } else {
          setErrorEmail(t('ERROR_GENERIC'));
        }
      })
      .catch((e) => {
        okerror(Object.keys(e));
        if (e.message === 'USER_EMAIL_IS_ALREADY_IN_USE') {
          setErrorEmail(t('EMAIL_ALREADY_IN_USE'));
        } else if (
          e.message === 'USER_OTP_TIMEOUT' &&
          e.graphQLErrors?.length &&
          e.graphQLErrors[0].extensions?.data?.attemptLeft > 0
        ) {
          const responseAttemptData = e.graphQLErrors[0].extensions.data;
          beginAttemptEmail(responseAttemptData);
        } else {
          setErrorEmail(t('ERROR_GENERIC'));
        }
      });
  }, [getChangeEmailOTPAPI, email, beginAttemptEmail, t]);

  // Event handlers

  const onChangeMobile = useCallback((e) => {
    const newValue = e.target.value;
    setMobile(newValue);
  }, []);

  const onChangeOTPMobile = useCallback((e) => {
    const newValue = e.target.value;
    setOTPMobile(newValue);
  }, []);

  const onChangeEmail = useCallback((e) => {
    const newValue = e.target.value;
    setEmail(newValue);
  }, []);

  const onChangeOTPEmail = useCallback((e) => {
    const newValue = e.target.value;
    setOTPEmail(newValue);
  }, []);

  /* Effects */

  // Update seconds left

  useEffect(() => {
    let secondsLeftInterval;
    if (!expiredMobile && attemptDataMobile?.expiresAt) {
      secondsLeftInterval = setInterval(() => {
        const secondsTillExpiration = differenceInSeconds(new Date(attemptDataMobile.expiresAt), new Date());
        if (secondsTillExpiration > 0) {
          setSecondsLeftMobile(secondsTillExpiration);
        } else {
          setExpiredMobile(true);
        }
      }, 1000);
    }

    return function () {
      clearInterval(secondsLeftInterval);
    };
  }, [attemptDataMobile?.expiresAt, expiredMobile]);

  useEffect(() => {
    let secondsLeftInterval;
    if (!expiredEmail && attemptDataEmail?.expiresAt) {
      secondsLeftInterval = setInterval(() => {
        const secondsTillExpiration = differenceInSeconds(new Date(attemptDataEmail.expiresAt), new Date());
        if (secondsTillExpiration > 0) {
          setSecondsLeftEmail(secondsTillExpiration);
        } else {
          setExpiredEmail(true);
        }
      }, 1000);
    }

    return function () {
      clearInterval(secondsLeftInterval);
    };
  }, [attemptDataEmail?.expiresAt, expiredEmail]);

  /* Render */

  const mainContentMobile =
    modeMobile === MODEMobile.OTP ? (
      <div>
        <Text>
          {tHTML('ENTER_CODE_SENT_TO', {
            data: {
              username: (
                <strong className={styles.username}>
                  +{countries.find((c) => c.isoAlpha3 === mobileCountryIsoCode).phoneCode} {mobile}
                </strong>
              ),
            },
          })}
          <br />
        </Text>
        <Text>
          {/* eslint-disable indent */}
          {expiredMobile
            ? tHTML('ERROR_VERIFY_ATTEMPT_EXPIRED')
            : tHTML('AUTH_ATTEMPTS_AND_SECONDS_LEFT', {
                data: {
                  attempts: attemptDataMobile.attemptLeft,
                  seconds: secondsLeftMobile,
                },
              })}
          {/* eslint-enable indent */}
        </Text>
        <Input
          autoComplete='one-time-code'
          className={`${styles.input} ${styles.codeInput}`}
          disabled={setMobileAPIResult.loading || expiredMobile}
          onChange={onChangeOTPMobile}
          showErrorIcon={false}
          type='tel'
          value={otpMobile}
        />
        {expiredMobile ? (
          <Button
            block
            className={styles.button}
            disabled={getChangeMobileOTPAPIResult.loading}
            icon={ICONS.CARET.name}
            loading={getChangeMobileOTPAPIResult.loading}
            onClick={requestCodeMobile}
            tint='navigation'
          >
            {t('REQUEST_NEW_CODE')}
          </Button>
        ) : (
          <Button
            block
            className={styles.button}
            disabled={setMobileAPIResult.loading || !otpMobile.length}
            icon={ICONS.CARET.name}
            loading={setMobileAPIResult.loading}
            onClick={confirmChangeMobile}
            tint='navigation'
          >
            {t('VERIFY_AND_ACTION', {
              data: {
                action: header.toLowerCase(),
              },
            })}
          </Button>
        )}
        {errorMobile ? (
          <Text className={styles.errorMessage} size='sm' tint='notification'>
            {errorMobile}
          </Text>
        ) : null}
      </div>
    ) : (
      <div>
        <Text>{t('VERIFY_OWNERSHIP_OF_PHONE_WITH_CODE')}</Text>
        <Text bold>{t('GET_CODE_BY_PHONE')}</Text>
        <PhoneInput
          className={styles.input}
          country={mobileCountryIsoCode}
          disabled={getChangeMobileOTPAPIResult.loading}
          number={mobile}
          onChangeCountryCode={setMobileCountryIsoCode}
          onChangeNumber={onChangeMobile}
        />
        <Button
          block
          className={styles.button}
          disabled={!mobile || !mobileCountryIsoCode || getChangeMobileOTPAPIResult.loading}
          icon={ICONS.CARET.name}
          loading={getChangeMobileOTPAPIResult.loading}
          onClick={requestCodeMobile}
          tint='navigation'
        >
          {t('REQUEST_A_CODE')}
        </Button>
        {errorMobile ? (
          <Text className={styles.errorMessage} size='sm' tint='notification'>
            {errorMobile}
          </Text>
        ) : null}
      </div>
    );

  const mainContentEmail =
    modeEmail === MODEEmail.OTP ? (
      <div>
        <Text>
          {t('ENTER_CODE_SENT_TO')}
          <br />
          <strong>{email}</strong>
        </Text>
        <Text>
          {/* eslint-disable indent */}
          {expiredEmail
            ? t('ERROR_VERIFY_ATTEMPT_EXPIRED')
            : tHTML('AUTH_ATTEMPTS_AND_SECONDS_LEFT', {
                data: {
                  attempts: attemptDataEmail.attemptLeft,
                  seconds: secondsLeftEmail,
                },
              })}
          {/* eslint-enable indent */}
        </Text>
        <Input
          autoComplete='one-time-code'
          className={`${styles.input} ${styles.codeInput}`}
          disabled={setEmailAPIResult.loading || expiredEmail}
          onChange={onChangeOTPEmail}
          showErrorIcon={false}
          type='tel'
          value={otpEmail}
        />
        {expiredEmail ? (
          <Button
            block
            className={styles.button}
            disabled={getChangeEmailOTPAPIResult.loading}
            icon={ICONS.CARET.name}
            loading={getChangeEmailOTPAPIResult.loading}
            onClick={requestCodeEmail}
            tint='navigation'
          >
            {t('REQUEST_NEW_CODE')}
          </Button>
        ) : (
          <Button
            block
            className={styles.button}
            disabled={setEmailAPIResult.loading || !otpEmail.length}
            icon={ICONS.CARET.name}
            loading={setEmailAPIResult.loading}
            onClick={confirmChangeEmail}
            tint='navigation'
          >
            {t('VERIFY_AND_ACTION', {
              data: {
                action: header.toLowerCase(),
              },
            })}
          </Button>
        )}
        {errorEmail ? (
          <Text className={styles.errorMessage} size='sm' tint='notification'>
            {errorEmail}
          </Text>
        ) : null}
      </div>
    ) : (
      <div>
        <Text>{t('VERIFY_OWNERSHIP_OF_EMAIL_WITH_CODE')}</Text>
        <Text bold>{t('GET_CODE_BY_EMAIL')}</Text>
        <Input
          className={styles.input}
          disabled={getChangeEmailOTPAPIResult.loading}
          onChange={onChangeEmail}
          placeholder={t('AUTH_ENTER_EMAIL_CODE')}
          value={email}
        />
        <Button
          block
          className={styles.button}
          disabled={!email || getChangeEmailOTPAPIResult.loading}
          icon={ICONS.CARET.name}
          loading={getChangeEmailOTPAPIResult.loading}
          onClick={requestCodeEmail}
          tint='navigation'
        >
          {t('REQUEST_A_CODE')}
        </Button>
        {errorEmail ? (
          <Text className={styles.errorMessage} size='sm' tint='notification'>
            {errorEmail}
          </Text>
        ) : null}
      </div>
    );

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.container}>
        <div className={styles.header}>
          <h3>{header}</h3>
          <Button linkStyle onClick={dismiss} tint='navigation'>
            {t('DONE')}
          </Button>
        </div>
        {mainContentEmail}
        <div style={{ marginTop: '30px' }}>{mainContentMobile}</div>
      </PopupContent>
    </Popup>
  );
}

SetUserContactInfoPopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
  header: PropTypes.string,
};
