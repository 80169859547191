import PropTypes from 'prop-types';

import TabsContext from '../tabContext';

/**
 * Provider for `TabsContext`. This component must be an anscestor of all other relevant tab components.
 *
 * @param {object} props
 * @param {string} props.activeTabId The tabId of the active tab.
 * @param {Node} [props.children]
 * @param {(tabId) => {}} props.onTabClick Event handler for when user selects a new tab.
 * @param {'button' | 'tag' | 'underline'} [props.tabStyle='underline'] Rendering style of the tabs.
 *
 * @example
 * <TabsContextProvider>
 *   <Tabs>
 *     <Tab tabId='1'>First tab</Tab>
 *     <Tab tabId='2'>Second tab</Tab>
 *   </Tabs>
 *   <TabSection tabId='1'>First section</TabSection>
 *   <TabSection tabId='2'>Second section</TabSection>
 * </TabsContextProvider>
 */
export default function TabsContextProvider(props) {
  const { activeTabId, children, onTabClick, renderLinks = false, tabStyle = 'underline' } = props;

  const setActiveTabId = (tabId) => {
    onTabClick && onTabClick(tabId);
  };

  const context = {
    activeTabId,
    renderLinks,
    setActiveTabId,
    tabStyle,
  };

  return <TabsContext.Provider value={context}>{children}</TabsContext.Provider>;
}

TabsContextProvider.propTypes = {
  activeTabId: PropTypes.string,
  children: PropTypes.node,
  onTabClick: PropTypes.func,
  renderLinks: PropTypes.bool,
  tabStyle: PropTypes.oneOf(['button', 'tag', 'underline']),
};
