import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';

import styles from './styles.module.scss';

import Grade from 'OK/components/grade';
import Icon, { ICONS } from 'OK/components/icon';
import Link from 'OK/components/link';
import LinkToItem from 'OK/components/link/linkToItem';
import OrganisationMiniSummary from 'OK/components/organisation/summary/mini';
import { PopupCloseButton } from 'OK/components/popup';
import Text from 'OK/components/text';
import MediaAssetModel from 'OK/models/mediaAsset';
import ThemeContext from 'OK/util/context/theme';
import { formatOkid } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

/**
 *
 * @param {object} props
 * @param {Node} [props.actionButton] Show a button below the product name.
 * @param {() => {}} [props.dismiss] Required if `showLogoInHeader` is `false`. A close button will be shown in the
 * header and this function will be passed as its `onClick`.
 * @param {boolean} [props.linkToOrganisation] Navigate to organisation page when organisation info is clicked.
 * @param {boolean} [props.showFindingsPerItem=false] Show findings / item.
 * @param {boolean} [props.showLogoInHeader=false] Show the logo in the header instead of a close button.
 */
export default function ProductInfoPopupSection(props) {
  const {
    actionButton,
    conformityPoints,
    dismiss,
    linkToOrganisation = false,
    mediaAssetList,
    name,
    OKID,
    organisation,
    reliabilityPoints,
    showFindingsPerItem = false,
    showLogoInHeader = false,
    SKUID,
    ...otherProps
  } = props;

  const theme = useContext(ThemeContext);
  const { t } = useI18n();

  /* Render */

  const firstProductImageUrl = useMemo(() => {
    const firstProductImageMediaAsset = mediaAssetList?.find((ma) => ma.mediaType === MediaAssetModel.MEDIA_TYPE.IMAGE);
    return firstProductImageMediaAsset?.imageData.imageURL ?? `/img/empty_media_${theme.name}.svg`;
  }, [mediaAssetList, theme.name]);

  const organisationInfo = (
    <OrganisationMiniSummary organisation={organisation} renderAsLink={linkToOrganisation} size='sm' />
  );

  return (
    <div {...otherProps}>
      <div className={styles.header}>
        <h3 className={styles.okid}>{formatOkid(OKID)}</h3>
        {showLogoInHeader ? (
          <Icon height={19} name={ICONS.LOGO.name} width={33} />
        ) : (
          <PopupCloseButton className={styles.closeButton} linkStyle>
            {t('CLOSE')}
          </PopupCloseButton>
        )}
      </div>
      <div className={styles.productDetailsContainer}>
        <img alt={t('IMG_ALT_PRODUCT_IMAGE')} className={styles.productImage} src={firstProductImageUrl} />
        <div className={styles.productDetails}>
          <div className={styles.productNameAndImageContainer}>
            <Text className={styles.productName}>{name}</Text>
            {actionButton}
          </div>
          <div className={styles.productStatsContainer}>
            <div className={`${styles.skuAndGrades} ${showFindingsPerItem ? styles.sideMargin : ''}`}>
              {SKUID && (
                <Text bold className={styles.sku} size='sm'>
                  {t('SKU')}: {SKUID}
                </Text>
              )}
              <div className={styles.productStats}>
                <Grade className={styles.grade} score={conformityPoints} size='sm' type='conformity' />
                <Grade className={styles.grade} score={reliabilityPoints} size='sm' type='reliability' />
              </div>
            </div>
            {showFindingsPerItem && (
              <div className={styles.findingsContainer}>
                <Text bold className={styles.findingsLabel} size='sm'>
                  Findings/item
                </Text>
                <Text className={styles.findings} size='sm'>
                  0 (see{' '}
                  <LinkToItem className={styles.logsLink} OKID={OKID}>
                    logs
                  </LinkToItem>
                  )
                </Text>
              </div>
            )}
          </div>
          {linkToOrganisation ? (
            <Link className={styles.organisationInfoLink}>{organisationInfo}</Link>
          ) : (
            <div>{organisationInfo}</div>
          )}
        </div>
      </div>
    </div>
  );
}

ProductInfoPopupSection.propTypes = {
  actionButton: PropTypes.node,
  conformityPoints: PropTypes.number,
  dismiss: PropTypes.func,
  linkToOrganisation: PropTypes.bool,
  mediaAssetList: PropTypes.array,
  name: PropTypes.string,
  OKID: PropTypes.string.isRequired,
  organisation: PropTypes.object,
  reliabilityPoints: PropTypes.number,
  showFindingsPerItem: PropTypes.bool,
  showLogoInHeader: PropTypes.bool,
  SKUID: PropTypes.string,
};
