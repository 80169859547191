import PropTypes from 'prop-types';

import Link from '..';

import { formatOkid } from 'OK/util/formatting';

export default function LinkToItem(props) {
  const { children, OKID: OKIDProp, item, ...otherProps } = props;

  if (!OKIDProp && !item?.OKID) {
    okwarn('Cannot render LinkToItem because no OKID or item was provided.');
    return null;
  }

  const OKID = OKIDProp ?? item?.OKID;

  return (
    <Link href={`/${formatOkid(OKID)}`} {...otherProps}>
      {children}
    </Link>
  );
}

LinkToItem.propTypes = {
  children: PropTypes.node,
  item: PropTypes.object,
  OKID: PropTypes.string,
};
