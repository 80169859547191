import PropTypes from 'prop-types';
import { forwardRef, useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import InspectionArchiveCard from 'OK/components/inspection/archiveCard';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import ProductArchiveCard from 'OK/components/product/archiveCard';
import { Tab, Tabs, TabsContextProvider } from 'OK/components/tabs';
import Text from 'OK/components/text';
import DocumentModel from 'OK/models/document';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import MediaAssetModel from 'OK/models/mediaAsset';
import NoteModel from 'OK/models/note';
import ProductModel from 'OK/models/product';
import TestAssetModel from 'OK/models/testAsset';
import { baseTheme } from 'OK/styles/theme';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import isAuthorised, { isAuthorisedViaShare } from 'OK/util/functions/isAuthorised';
import useAuthorisation from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';

function fileIsPublishedOnProduct(fileId, product) {
  const productDocumentAsset = product.productDocumentAssetList.find((pda) => pda.documentAsset.id === fileId);
  if (productDocumentAsset) {
    return productDocumentAsset.publishStatus === PUBLISH_STATUS.PUBLISHED;
  }

  return false;
}

function noteIsPublishedOnProduct(noteId, product) {
  const productNoteAsset = product.productNoteAssetList.find((pna) => pna.noteAsset.id === noteId);
  if (productNoteAsset) {
    return productNoteAsset.publishStatus === PUBLISH_STATUS.PUBLISHED;
  }

  return false;
}

function LinkCard(props) {
  const { linkedItem, linksType, onChangePublished, onClickUnlink, parentResource } = props;
  const authorisationLevel = useAuthorisation(linkedItem);
  const isManager = isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER);
  const hasEditPermission =
    isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER) ||
    isAuthorisedViaShare(linkedItem?.assetAccessPermission, AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION);

  const linkPublished = useMemo(() => {
    switch (parentResource.__typename) {
      case DocumentModel.GRAPHQL_TYPE:
        return fileIsPublishedOnProduct(parentResource.id, linkedItem);
      case NoteModel.GRAPHQL_TYPE:
        return noteIsPublishedOnProduct(parentResource.id, linkedItem);
      default:
        return false;
    }
  }, [parentResource.__typename, parentResource.id, linkedItem]);

  const card = useMemo(() => {
    switch (linksType) {
      case ProductModel.GRAPHQL_TYPE:
        return (
          <ProductArchiveCard
            className={styles.linkCard}
            linkPublished={linkPublished}
            onChangePublished={(published) => onChangePublished && onChangePublished(linkedItem.id, published)}
            onClickUnlink={() => onClickUnlink && onClickUnlink(linkedItem.id)}
            product={linkedItem}
            showPublishOption={
              isManager &&
              (parentResource.__typename === DocumentModel.GRAPHQL_TYPE ||
                parentResource.__typename === NoteModel.GRAPHQL_TYPE)
            }
            showUnlinkOption={hasEditPermission}
          />
        );
      case InspectionAssetModel.GRAPHQL_TYPE:
        return (
          <InspectionArchiveCard
            className={styles.linkCard}
            inspectionAsset={linkedItem}
            onClickUnlink={() => onClickUnlink && onClickUnlink(linkedItem.id)}
            showUnlinkOption={hasEditPermission}
          />
        );
      default:
        return null;
    }
  }, [
    hasEditPermission,
    isManager,
    linkPublished,
    linkedItem,
    linksType,
    onChangePublished,
    onClickUnlink,
    parentResource.__typename,
  ]);

  return card;
}

LinkCard.propTypes = {
  linkedItem: PropTypes.any,
  linksType: PropTypes.string,
  onChangePublished: PropTypes.func,
  onClickUnlink: PropTypes.func,
  parentResource: PropTypes.any,
};

const ArchiveLinksSection = forwardRef((props, forwardedRef) => {
  /* Variables */

  const {
    availableLinkTypes = [],
    carouselError,
    className,
    disableActions = false,
    linkedList = [],
    linksFilter,
    linksType,
    onChangePublished,
    onClickLinksFilter,
    onClickLinksType,
    onClickPublishAll,
    onClickUnlink,
    onClickUnpublishAll,
    parentResource,
    showSeeListWithSearchButton = false,
  } = props;
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const { t } = useI18n();

  /* Render */

  let classNames = styles.linksContainer;
  if (className) {
    classNames += ` ${className}`;
  }

  const linkCardWidth = useMobileLayout ? baseTheme.sizing.cardWidthMobile : baseTheme.sizing.$cardWidth4ColumnDesktop;

  const linksFilters = useMemo(() => {
    switch (linksType) {
      case ProductModel.GRAPHQL_TYPE:
        return ['ALL', 'PUBLISHED', 'INTERNAL'];
      default:
        return [];
    }
  }, [linksType]);
  const linksTypeText = useMemo(() => {
    switch (linksType) {
      case ProductModel.GRAPHQL_TYPE:
        return t('PRODUCTS');
      case InspectionAssetModel.GRAPHQL_TYPE:
        return t('INSPECTIONS');
      default:
        return '';
    }
  }, [linksType, t]);
  const linksTypeTextLowercase = useMemo(() => {
    switch (linksType) {
      case ProductModel.GRAPHQL_TYPE:
      case InspectionAssetModel.GRAPHQL_TYPE:
        return linksTypeText.toLowerCase();
      default:
        return '';
    }
  }, [linksType, linksTypeText]);

  const parentTypeText = useMemo(() => {
    switch (parentResource.__typename) {
      case MediaAssetModel.GRAPHQL_TYPE:
        return t('MEDIA');
      case TestAssetModel.GRAPHQL_TYPE:
        return t('TEST');
      default:
        return '';
    }
  }, [parentResource.__typename, t]);
  const showPublishOptions = false;

  return (
    <div className={classNames} ref={forwardedRef}>
      <ContentLayout pageContent>
        <TextLayout>
          <h3>{t('ARCHIVE_PAGE_SECTION_LINKS')}</h3>
        </TextLayout>
      </ContentLayout>
      <ContentLayout className={styles.linksTypeTabsContainer} pageContent>
        <TabsContextProvider activeTabId={linksType} onTabClick={onClickLinksType} tabStyle='underline'>
          <Tabs className={styles.linksTypeTabs} sections={availableLinkTypes}>
            {availableLinkTypes.map((lt) => {
              let label;
              switch (lt) {
                case ProductModel.GRAPHQL_TYPE:
                  label = t('PRODUCTS');
                  break;
                case InspectionAssetModel.GRAPHQL_TYPE:
                  label = t('INSPECTIONS');
                  break;
                default:
                  label = lt;
                  break;
              }
              return (
                <Tab className={styles.linksTypeTab} key={lt} tabId={lt}>
                  {label}
                </Tab>
              );
            })}
          </Tabs>
        </TabsContextProvider>
      </ContentLayout>
      <ContentLayout pageContent>
        <TextLayout>
          <h4>
            {t(`ARCHIVE_PAGE_LINKS_TYPE_${linksType}`)}{' '}
            <span className={styles.normalFontWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
          </h4>
          <Text className={styles.linksTypeDescription}>
            {t(`ARCHIVE_PAGE_LINKS_${linksType}_ON_${parentResource.__typename}`)}
          </Text>
          {/* <SearchInput
            className={styles.searchLinksInput}
            disabled={disableActions}
            placeholder={`Find ${linksTypeTextLowercase}`}
          /> */}
          {/* {linksFilters.length > 0 && (
            <TabsContextProvider activeTabId={linksFilter} onTabClick={onClickLinksFilter} tabStyle='tag'>
              <Tabs className={styles.linksFilterTabs}>
                {linksFilters.map((f) => (
                  <Tab key={f} tabId={f}>
                    {t(f)}
                  </Tab>
                ))}
              </Tabs>
            </TabsContextProvider>
          )} */}
        </TextLayout>
        {linkedList.length ? (
          <Carousel className={styles.carouselContainer} fadeOutSides innerClassName={styles.carousel}>
            {linkedList.map((l) => {
              return (
                <Slide className={styles.linkSlide} key={l.id}>
                  <LinkCard
                    linkedItem={l}
                    linksType={linksType}
                    onChangePublished={onChangePublished}
                    onClickUnlink={onClickUnlink}
                    parentResource={parentResource}
                  />
                </Slide>
              );
            })}
          </Carousel>
        ) : (
          <TextLayout>
            <Text>{t(`ARCHIVE_PAGE_NO_LINKS_${linksType}`)}</Text>
          </TextLayout>
        )}
        <TextLayout>
          {carouselError && (
            <Text size='sm' tint='notification'>
              {carouselError}
            </Text>
          )}
          {showSeeListWithSearchButton && (
            <Button block className={styles.button} disabled={disableActions} tint='navigation' withCaret>
              See list with search tools
            </Button>
          )}
          {showPublishOptions && (
            <>
              <Button
                block
                className={styles.bulkActionButton}
                disabled={disableActions}
                linkStyle
                onClick={onClickPublishAll}
              >
                Publish on all linked {linksTypeTextLowercase}
              </Button>
              <Button
                block
                className={styles.bulkActionButton}
                disabled={disableActions}
                linkStyle
                onClick={onClickUnpublishAll}
              >
                Unpublish on all linked {linksTypeTextLowercase}
              </Button>
            </>
          )}
        </TextLayout>
      </ContentLayout>
    </div>
  );
});

ArchiveLinksSection.propTypes = {
  availableLinkTypes: PropTypes.array,
  carouselError: PropTypes.string,
  className: PropTypes.string,
  disableActions: PropTypes.bool,
  linkedList: PropTypes.array,
  linksFilter: PropTypes.string,
  linksType: PropTypes.string,
  onChangePublished: PropTypes.func,
  onClickLinksFilter: PropTypes.func,
  onClickLinksType: PropTypes.func,
  onClickPublishAll: PropTypes.func,
  onClickUnlink: PropTypes.func,
  onClickUnpublishAll: PropTypes.func,
  parentResource: PropTypes.object,
  showSeeListWithSearchButton: PropTypes.bool,
};

export default ArchiveLinksSection;
