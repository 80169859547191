import { useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import Icon, { ICONS } from 'OK/components/icon';
import InlineIcon from 'OK/components/inlineIcon';
import SearchInput from 'OK/components/input/search';
import InspectionEditCard from 'OK/components/inspection/editCard';
import ContentLayout from 'OK/components/layouts/content';
import Notice from 'OK/components/notice';
import Progressable from 'OK/components/progressable';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Text from 'OK/components/text';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import { searchQuery } from 'OK/networking/search';
import { createSiteInspectionMutation } from 'OK/networking/sites';
import {
  useLinkSiteInspectionAPI,
  usePublishSiteInspectionAPI,
  useReorderSiteInspectionBackwardAPI,
  useReorderSiteInspectionForwardAPI,
  useUnlinkSiteInspectionAPI,
  useUnpublishSiteInspectionAPI,
} from 'OK/networking/sites/hooks';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import useI18n from 'OK/util/hooks/useI18n';

export default function EditSitePageInspectionsSection(props) {
  /* Variables */

  const { site } = props;
  const { locale, t, tHTML } = useI18n();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const siteInspectionAssets = site.siteInspectionAssetList;

  // State

  const [creationError, setCreationError] = useState(null);
  const [inspectionErrors, setInspectionErrors] = useState([]);
  const [searchInspectionFocused, setSearchInspectionFocused] = useState(false);
  const [searchInspectionPartnersFocused, setSearchInspectionPartnersFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Refs

  const inspectionSliderRef = useRef();

  /* API */

  const [createSiteInspectionAPI, createSiteInspectionAPIResult] = useMutation(createSiteInspectionMutation);
  const [linkSiteInspectionAPI, linkSiteInspectionAPIResult] = useLinkSiteInspectionAPI();
  const publishSiteInspectionAPI = usePublishSiteInspectionAPI();
  const reorderSiteInspectionBackwardAPI = useReorderSiteInspectionBackwardAPI();
  const reorderSiteInspectionForwardAPI = useReorderSiteInspectionForwardAPI();
  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);
  const [unlinkSiteInspectionAPI, unlinkSiteInspectionAPIResult] = useUnlinkSiteInspectionAPI();
  const unpublishSiteInspectionAPI = useUnpublishSiteInspectionAPI();

  /* Methods */

  const clearErrorForInspectionAssetId = useCallback(
    (inspectionAssetId) => {
      const indexOfInspectionError = inspectionErrors.findIndex((e) => e.inspectionAssetId === inspectionAssetId);
      if (indexOfInspectionError > -1) {
        const updatedErrorsList = [...inspectionErrors];
        updatedErrorsList.splice(indexOfInspectionError, 1);
        setInspectionErrors(updatedErrorsList);
      }
    },
    [inspectionErrors]
  );

  const createInspection = useCallback(() => {
    setCreationError(null);
    createSiteInspectionAPI({
      variables: { siteId: site.id },
      update: (cache, result) => {
        const { siteInspectionAsset } = result.data;

        // Write inspection asset to cache
        const inspectionAssetRef = cache.writeFragment({
          id: cache.identify(siteInspectionAsset.inspectionAsset),
          fragment: InspectionAssetModel.fragmentEditCard,
          fragmentName: InspectionAssetModel.fragmentNameEditCard,
          data: siteInspectionAsset.inspectionAsset,
        });

        // Add site inspection asset to site
        cache.modify({
          id: cache.identify(site),
          fields: {
            siteInspectionAssetList: (currentList) => {
              const cacheSiteInspectionAsset = {
                ...siteInspectionAsset,
                inspectionAsset: inspectionAssetRef,
              };
              return [...currentList, cacheSiteInspectionAsset];
            },
          },
        });
      },
    })
      .then(() => {
        // Scroll to new inspection in slider
        setTimeout(() => {
          inspectionSliderRef.current.scrollToSlideAtIndex(siteInspectionAssets.length);
        }, 0);
      })
      .catch(() => {
        setCreationError(t('ERROR_GENERIC'));
      });
  }, [createSiteInspectionAPI, site, siteInspectionAssets.length, t]);

  const searchDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'INSPECTION_ASSET',
                ignoreIdList: siteInspectionAssets.map((pi) => pi.inspectionAsset.id),
              },
            ],
            searchPaginationDataByDataType: [
              {
                dataType: 'INSPECTION_ASSET',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [siteInspectionAssets, searchAPI]
  );

  const setErrorForInspectionAssetId = useCallback(
    (inspectionAssetId, message) => {
      setInspectionErrors([...inspectionErrors, { inspectionAssetId, message }]);
    },
    [inspectionErrors]
  );

  // Event handlers

  const onBlurSearchInspectionPartners = useCallback(() => {
    setSearchInspectionPartnersFocused(false);
  }, []);

  const onChangeInspectionPublished = useCallback(
    (inspectionAssetId, published) => {
      clearErrorForInspectionAssetId(inspectionAssetId);

      if (published) {
        publishSiteInspectionAPI(site.id, inspectionAssetId).catch(() => {
          setErrorForInspectionAssetId(inspectionAssetId, t('ERROR_GENERIC'));
        });
      } else {
        unpublishSiteInspectionAPI(site.id, inspectionAssetId).catch(() => {
          setErrorForInspectionAssetId(inspectionAssetId, t('ERROR_GENERIC'));
        });
      }
    },
    [
      clearErrorForInspectionAssetId,
      site.id,
      publishSiteInspectionAPI,
      setErrorForInspectionAssetId,
      t,
      unpublishSiteInspectionAPI,
    ]
  );

  const onClickLinkInspection = useCallback(
    (inspectionAssetId) => {
      setSearchTerm('');
      linkSiteInspectionAPI(site.id, inspectionAssetId)
        .then(() => {
          setTimeout(() => {
            inspectionSliderRef.current.scrollToSlideAtIndex(siteInspectionAssets.length);
          }, 50);
        })
        .catch(() => {
          setCreationError(t('ERROR_GENERIC'));
        });
    },
    [linkSiteInspectionAPI, site.id, siteInspectionAssets.length, t]
  );

  const onClickUnlinkInspection = useCallback(
    (inspectionAssetId) => {
      unlinkSiteInspectionAPI(site.id, inspectionAssetId).catch(() => {
        setCreationError(t('ERROR_GENERIC'));
      });
    },
    [site.id, t, unlinkSiteInspectionAPI]
  );

  const onFocusSearchInspectionPartners = useCallback(() => {
    setSearchInspectionPartnersFocused(true);

    const searchedElements = document.getElementsByClassName(styles.carouselContainer);
    if (searchedElements.length) {
      const carouselElement = searchedElements[0];
      const osHostElement = carouselElement.children[0];
      osHostElement.children.forEach((child) => {
        if (child.classList.contains('os-content-glue')) {
          // TODO: find proper solution to overlayscrollbars page jump
          // The overlayscrollbars plugin causes the page to scroll whenever an input field within a card is focused.
          // The issue is made worse by the bottom margin & padding hack we are using so the search suggestions can
          // overflow outside the carousel. By restoring the height and bottom margin of the .os-content-glue element
          // the issue seems to be mostly remedied, although there is still a flicker. It's good enough for now until
          // a proper solution can be found.
          const glueHeight = child.clientHeight;
          const glueMarginBottom = child.style.marginBottom;

          // Set styles asynchronously so they take effect after overlayscrollbars
          setTimeout(() => {
            child.style.setProperty('max-height', `${glueHeight}px`, 'important');
            child.style.setProperty('margin-bottom', glueMarginBottom, 'important');
          }, 1);
        }
      });
    }
  }, []);

  const onReorderInspection = useCallback(
    (inspectionAssetId, direction) => {
      if (direction === 'left') {
        reorderSiteInspectionBackwardAPI(site.id, inspectionAssetId);
      } else {
        reorderSiteInspectionForwardAPI(site.id, inspectionAssetId);
      }
    },
    [site.id, reorderSiteInspectionBackwardAPI, reorderSiteInspectionForwardAPI]
  );

  const onSearch = useCallback(
    (e) => {
      const newSearchTerm = e.target.value;
      setSearchTerm(newSearchTerm);

      if (newSearchTerm.length > 1) {
        searchDebounced(newSearchTerm);
      }
    },
    [searchDebounced]
  );

  /* Render */

  const disableCreatingInspection = createSiteInspectionAPIResult.loading;

  let carouselContainerClassNames = styles.carouselContainer;
  if (searchInspectionPartnersFocused) {
    carouselContainerClassNames = `${carouselContainerClassNames} ${styles.withSearchPadding}`;
  }

  const inspectionsNotice = (
    <Notice className={styles.sectionNotice}>
      <Text>
        {tHTML('PRODUCT_SECTION_WORKFLOWS_NOTICE', {
          data: {
            icon: (
              <span style={{ display: 'inline-block' }}>
                <Icon inline name={ICONS.RELIABILITY_GRADE.name} />
              </span>
            ),
          },
        })}
      </Text>
    </Notice>
  );

  const searchSuggestions = useMemo(() => {
    if (searchInspectionFocused && searchTerm.length > 1) {
      return (
        searchAPIResult.data?.search?.resultList?.map((r) => {
          const inspectionAsset = r.inspectionAssetData;
          return {
            icon: ICONS.INSPECTION.name,
            key: inspectionAsset.id,
            title: InspectionAssetModel.localizedNameForInspectionAsset(inspectionAsset, locale),
            subtitle: inspectionAsset.REFID,
          };
        }) ?? []
      );
    }

    return [];
  }, [locale, searchAPIResult.data?.search?.resultList, searchInspectionFocused, searchTerm.length]);

  return (
    <div>
      <ContentLayout pageContent>
        <h4 className={styles.subSectionHeader}>
          {t('INSPECTIONS')} <span className={styles.normalWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
        </h4>
        {useDesktopLayout && inspectionsNotice}
        <div className={styles.sectionIntro}>
          <p>
            {tHTML('PRODUCT_SECTION_WORKFLOWS_DESCRIPTION', {
              data: { icon: <Icon inline name={ICONS.RELIABILITY_GRADE.name} /> },
            })}
          </p>
          <div className={styles.searchInputContainer}>
            <SearchInput
              className={styles.searchInput}
              loading={searchAPIResult.loading}
              onBlur={() => setSearchInspectionFocused(false)}
              onChange={onSearch}
              onFocus={() => setSearchInspectionFocused(true)}
              placeholder={t('WORKFLOWS_SEARCH_PLACEHOLDER')}
              value={searchTerm}
            />
            <Button
              className={styles.searchNewButton}
              disabled={disableCreatingInspection}
              linkStyle
              onClick={createInspection}
              tint='creation'
            >
              {t('NEW')}
            </Button>
            <SearchSuggestions
              accessoryViewRender={() => (
                <Text className={styles.searchResultButton} tint='navigation'>
                  {t('LINK')}
                  <InlineIcon src='/icons/link_blue.svg' />
                </Text>
              )}
              className={styles.searchResults}
              highlightTerm={searchTerm}
              onSuggestionClick={onClickLinkInspection}
              showMoreResultsMessage={
                searchInspectionFocused &&
                searchAPIResult.data?.search?.searchPaginationResultDataByDataType?.INSPECTION_ASSET?.totalResults >
                  searchSuggestions.length
              }
              showNoResultsMessage={searchInspectionFocused && searchTerm.length > 1 && searchSuggestions.length === 0}
              suggestions={searchSuggestions}
              subtitleClassName={styles.searchResultOKID}
            />
          </div>
          {creationError && (
            <Text className={styles.creationError} size='sm' tint='notification'>
              {creationError}
            </Text>
          )}
        </div>
        {!useDesktopLayout && inspectionsNotice}
        <Progressable
          inProgress={
            createSiteInspectionAPIResult.loading ||
            linkSiteInspectionAPIResult.loading ||
            unlinkSiteInspectionAPIResult.loading
          }
          style={{ minHeight: '100px' }}
        >
          <Carousel
            className={carouselContainerClassNames}
            innerClassName={styles.carousel}
            fadeOutSides={useDesktopLayout}
            ref={inspectionSliderRef}
          >
            {siteInspectionAssets.map((i, index) => {
              const allowReorderLeft = index !== 0;
              const allowReorderRight = index < siteInspectionAssets.length - 1;
              let reorderDirections;
              if (allowReorderLeft && allowReorderRight) {
                reorderDirections = 'both';
              } else if (allowReorderLeft) {
                reorderDirections = 'left';
              } else if (allowReorderRight) {
                reorderDirections = 'right';
              }
              return (
                <Slide className={styles.inspectionSlide} key={i.inspectionAsset.id}>
                  <InspectionEditCard
                    className={styles.inspection}
                    fixedWidth={false}
                    inspectionAsset={i.inspectionAsset}
                    onBlurSearchInspectionPartnersProp={onBlurSearchInspectionPartners}
                    onFocusSearchInspectionPartnersProp={onFocusSearchInspectionPartners}
                    publishError={inspectionErrors.find((e) => e.inspectionAssetId === i.inspectionAsset.id)?.message}
                    published={i.publishStatus === PUBLISH_STATUS.PUBLISHED}
                    onClickUnlink={onClickUnlinkInspection}
                    onClickReorder={onReorderInspection}
                    onChangePublish={onChangeInspectionPublished}
                    showReorder={reorderDirections}
                    unlinkButtonTitle={t('UNLINK_FROM_SITE')}
                  />
                </Slide>
              );
            })}
            <Slide className={styles.inspectionSlide}>
              <button className={styles.addSlideButton} disabled={disableCreatingInspection} onClick={createInspection}>
                <img alt='' src='/icons/upload_big.svg' /> {t('ADD_NEW_WORKFLOW')}
              </button>
            </Slide>
          </Carousel>
        </Progressable>
      </ContentLayout>
    </div>
  );
}

EditSitePageInspectionsSection.propTypes = {
  site: PropTypes.any.isRequired,
};
