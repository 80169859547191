const config = {
  api_url: process.env.NEXT_PUBLIC_API_URL,
  mapBox_token: process.env.NEXT_PUBLIC_MAPBOX_API,
  appVersion: process.env.NEXT_PUBLIC_APP_VERSION,
  domain: process.env.NEXT_PUBLIC_DOMAIN,
  emailHost: 'smtp.office365.com',
  emailPort: 587,
  // Enable features behind a flag
  features: {
    alertScannedCode: false,
    analytics: restrictFeatureToProductionBuilds(),
    archive: true,
    login: true,
    messagesTooltip: restrictFeatureToProductionBuilds(),
    mixpanelDebugMode: restrictFeatureToLocalOrDevelopment(),
    // pwaBanner: restrictFeatureToProductionBuilds(),
    pwaBanner: true,
    scanTab: true,
    search: true,
  },
  fileSizeLimitsMB: {
    audio: 10,
    document: 25,
    image: 5,
    video: 50,
  },
  hCaptchaSiteKey: process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY,
  leadsEmailTo: 'leads.307e9c85@sm.oksystems.podio.com',
  number_max_precision: 14,
  pdf_api_url: process.env.NEXT_PUBLIC_PDF_API_URL,
};

// Only return true for development environment
// eslint-disable-next-line no-unused-vars
function restrictFeatureToDevelopment() {
  return process.env.NEXT_PUBLIC_APP_ENV === 'development';
}

// Only return true for local environment
// eslint-disable-next-line no-unused-vars
function restrictFeatureToLocal() {
  return process.env.NEXT_PUBLIC_APP_ENV === 'local';
}

// Only return true for local and development environments
// eslint-disable-next-line no-unused-vars
function restrictFeatureToLocalOrDevelopment() {
  return ['local', 'development'].includes(process.env.NEXT_PUBLIC_APP_ENV);
}

// Only return true for production builds
// eslint-disable-next-line no-unused-vars
function restrictFeatureToProductionBuilds() {
  return process.env.NODE_ENV === 'production';
}

// Export config
export default config;
