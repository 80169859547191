import { useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import InlineIcon from 'OK/components/inlineIcon';
import SearchInput from 'OK/components/input/search';
import TextLayout from 'OK/components/layouts/content/text';
import Progressable from 'OK/components/progressable';
import SearchSuggestions from 'OK/components/searchSuggestions';
import SiteArchiveCard from 'OK/components/site/archiveCard';
import Text from 'OK/components/text';
import OrderModel from 'OK/models/order';
import OrderSiteModel from 'OK/models/orderSite';
import SiteModel from 'OK/models/site';
import NewSitePopup from 'OK/modules/popups/newSite';
import { addOrderSiteMutation, searchOrderSiteListQuery, removeOrderSiteMutation } from 'OK/networking/orders';
import { CreateSiteAndLinkMutation } from 'OK/networking/sites';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import { formatOkid } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function EditOrderPageSiteSection(props) {
  /* Variables */

  const { order } = props;
  const sites = order?.orderSiteList;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const { locale, t } = useI18n();
  const orderCachedId = `${OrderModel.GRAPHQL_TYPE}:${order?.id}`;
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);

  // Helpders

  const indexOfContactInList = (siteId, list, readField) => {
    return list.findIndex((pn) => {
      const listSite = readField('site', pn);
      const listSiteId = readField('id', listSite);
      return listSiteId === siteId;
    });
  };

  // State

  const [ordersError, setOrdersError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [shouldShowNewSitePopup, setShouldShowNewSitePopup] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const [enableUnlink, setEnableUnlink] = useState(true);

  // Refs

  const sitesSliderRef = useRef();

  /* API */

  const [linkOrderAPI, linkOrderAPIResult] = useMutation(addOrderSiteMutation);
  const [searchOrderAPI, searchOrderAPIResult] = useLazyQuery(searchOrderSiteListQuery);
  const [unlinkOrderAPI] = useMutation(removeOrderSiteMutation);
  const [createAndLinkSiteAPI] = useMutation(CreateSiteAndLinkMutation);

  /* Methods */

  const createSiteAndLink = useCallback(
    (internalId) => {
      createAndLinkSiteAPI({
        variables: { orderId: order?.id, siteIdentifier: internalId },
        update: (cache, result) => {
          if (result?.data?.site) {
            const siteRef = cache.writeFragment({
              id: `${SiteModel.GRAPHQL_TYPE}:${result.data.site.id}`,
              fragment: SiteModel.fragmentArchiveCardOrder,
              fragmentName: SiteModel.fragmentNameArchiveCardOrder,
              data: result.data.siteContact,
            });

            cache.modify({
              id: orderCachedId,
              fields: {
                orderSiteList: (currentList = []) => {
                  return [...currentList, siteRef];
                },
              },
            });
          }
        },
      })
        .catch((e) => {
          okdebug(e);
          let errorMessage;
          errorMessage = t('ERROR_GENERIC');
          setOrdersError(errorMessage);
        })
        .finally(() => {
          setTimeout(() => {
            sitesSliderRef.current.scrollToSlideAtIndex(sites.length);
          }, 150);
        });
      setShouldShowNewSitePopup(false);
    },
    [createAndLinkSiteAPI, order?.id, orderCachedId, sites?.length, t]
  );

  const linkOrder = useCallback(
    (siteId) => {
      setOrdersError(null);
      if (order && siteId) {
        setSearchTerm('');
        return linkOrderAPI({
          variables: { orderId: order?.id, siteId: siteId },

          update: (cache, result) => {
            if (result?.data?.site) {
              const siteRef = cache.writeFragment({
                id: `${SiteModel.GRAPHQL_TYPE}:${result.data.site.id}`,
                fragment: SiteModel.fragmentArchiveCardOrder,
                fragmentName: SiteModel.fragmentNameArchiveCardOrder,
                data: result.data.siteContact,
              });

              cache.modify({
                id: orderCachedId,
                fields: {
                  orderSiteList: (currentList = []) => {
                    return [...currentList, siteRef];
                  },
                },
              });
            }
          },
        })
          .catch((e) => {
            okdebug(e);
            let errorMessage;
            errorMessage = t('ERROR_GENERIC');
            setOrdersError(errorMessage);
          })
          .finally(() => {
            setTimeout(() => {
              sitesSliderRef.current.scrollToSlideAtIndex(sites.length);
            }, 150);
          });
      }
    },
    [linkOrderAPI, order, orderCachedId, sites?.length, t]
  );

  const unlinkOrder = useCallback(
    (siteId) => {
      setOrdersError(null);
      const optimisticResponse = {
        site: {
          site: {
            id: siteId,
            __typename: SiteModel.GRAPHQL_TYPE,
          },
          __typename: OrderSiteModel.GRAPHQL_TYPE,
        },
      };
      if (enableUnlink)
        return unlinkOrderAPI({
          variables: { orderId: order?.id, siteId: siteId },
          optimisticResponse,
          update: (cache, result) => {
            if (result?.data?.site) {
              cache.modify({
                id: orderCachedId,
                fields: {
                  orderSiteList: (currentList, { readField }) => {
                    const { site } = result.data.site;
                    const indexOfSite = indexOfContactInList(site.id, currentList, readField);
                    if (indexOfSite > -1) {
                      const updatedList = [...currentList];
                      updatedList.splice(indexOfSite, 1);
                      return updatedList;
                    }
                    return currentList;
                  },
                },
              });
            }
          },
        })
          .catch(() => {
            setEnableUnlink(false);
          })
          .finally(() => {
            setEnableUnlink(true);
          });
    },
    [enableUnlink, order?.id, orderCachedId, unlinkOrderAPI]
  );

  const searchDebounced = useMemo(
    () =>
      debounce((searchString) => {
        setOrdersError(null);
        searchOrderAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'SITE',
                ignoreIdList: [...sites.map((site) => site?.site?.id)],
              },
            ],
            searchPaginationDataByDataType: [
              {
                dataType: 'SITE',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
            editingOrganisationIdList: [activeOrganisationId],
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchOrderAPI, sites, activeOrganisationId]
  );

  const showNewSitePopup = useCallback(() => {
    setShouldShowNewSitePopup(true);
  }, []);

  // Events

  const onSearch = useCallback(
    (e) => {
      const searchString = e.target.value;
      setSearchTerm(searchString);

      if (searchString.length > 1) {
        searchDebounced(searchString);
      }
    },
    [searchDebounced]
  );

  /* Render */

  let searchSuggestions;
  if (searchFocused && searchTerm.length > 1) {
    searchSuggestions = searchOrderAPIResult?.data?.search?.resultList?.map((r) => {
      return {
        key: r.siteData?.id,
        title: SiteModel.localizedNameForSite(r.siteData, locale) ?? 'Unnamed',
        subtitle: formatOkid(r.siteData?.OKID),
      };
    });
  }

  return (
    <div>
      <TextLayout>
        <h4 className={styles.subSectionHeader}>
          {t('SITES')} <span className={styles.normalWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
        </h4>
        {useDesktopLayout}
        <div>
          <Text className={styles.sectionIntro}>{t('ORDER_SECTION_DESCRIPTION_SITES')}</Text>
          <div className={styles.searchInputContainer}>
            <SearchInput
              accessoryButton={
                <Button className={styles.searchNewButton} linkStyle onClick={showNewSitePopup} tint='creation'>
                  {t('NEW')}
                </Button>
              }
              className={styles.searchInput}
              loading={searchOrderAPIResult.loading}
              onBlur={() => setSearchFocused(false)}
              onChange={onSearch}
              onFocus={() => setSearchFocused(true)}
              placeholder={t('SEARCH_SITES_PLACEHOLDER')}
              value={searchTerm}
            />
            <SearchSuggestions
              accessoryViewRender={() => (
                <Text className={styles.searchResultButton} tint='creation'>
                  {t('LINK')}
                  <InlineIcon src='/icons/link_green.svg' />
                </Text>
              )}
              className={styles.searchSuggestions}
              highlightTerm={searchTerm}
              onSuggestionClick={linkOrder}
              suggestions={searchSuggestions}
              showNoResultsMessage={searchFocused && searchOrderAPIResult.data?.search?.resultList?.length === 0}
              showMoreResultsMessage={searchOrderAPIResult.data?.search?.resultList?.length > 4}
            />
          </div>
          {ordersError && (
            <Text className={styles.errorMessage} size='sm' tint='alert'>
              {ordersError}
            </Text>
          )}
        </div>
      </TextLayout>
      {!useDesktopLayout}
      <Progressable
        inProgress={linkOrderAPIResult.loading}
        style={{ minHeight: linkOrderAPIResult.loading ? '100px' : undefined }}
      >
        <div>
          <Carousel
            className={styles.carouselContainer}
            fadeOutSides={useDesktopLayout}
            innerClassName={styles.carousel}
            ref={sitesSliderRef}
          >
            {sites?.map((site) => {
              return (
                <Slide className={styles.partSlide} key={site.id}>
                  <SiteArchiveCard
                    cardClassName={styles.partCard}
                    className={styles.part}
                    fixedWidth={false}
                    onClickUnlink={() => unlinkOrder(site?.site?.id)}
                    showUnlinkOption
                    site={site?.site}
                  />
                </Slide>
              );
            })}
            <Slide className={styles.partSlide}>
              <button className={`${styles.addSlideButton} ${styles.addPartButton}`} onClick={showNewSitePopup}>
                <img alt='' src='/icons/upload_big.svg' /> {t('ADD_NEW_SITE')}
              </button>
            </Slide>
          </Carousel>
          {sites?.length > 1 && (
            <TextLayout>
              <Text bold className={styles.swipeSidewaysMessage} size='xs' style={{ marginBottom: 0 }}>
                {t('SWIPE_SIDEWAYS_TO_SEE_OTHER_SITES')}
              </Text>
            </TextLayout>
          )}
        </div>
      </Progressable>
      {shouldShowNewSitePopup && (
        <NewSitePopup
          dismiss={() => setShouldShowNewSitePopup(false)}
          onCreateSiteAndLink={(internalId) => createSiteAndLink(internalId)}
          link={true}
          linkAsset={order?.id}
        />
      )}
    </div>
  );
}

EditOrderPageSiteSection.propTypes = {
  order: PropTypes.object.isRequired,
};
