import { gql } from '@apollo/client';

export default class CommentModel {
  static GRAPHQL_TYPE = 'Comment';

  static DEFAULT_SIZE = 10;
  static DEFAULT_THREAD_SIZE = 5;

  static FIELD_POLICIES = {
    fields: {
      commentThreadPagedResult: {
        keyArgs: [],
        merge: (existing, incoming, { readField }) => {
          const existingCommentList = readField('commentList', existing) ?? [];
          const incomingCommentList = readField('commentList', incoming) ?? [];
          return {
            ...incoming,
            commentList: [...existingCommentList, ...incomingCommentList],
          };
        },
      },
    },
  };

  static fragmentNameSingleComment = 'Comment_SingleComment_Fragment';
  static fragmentSingleComment = gql`
    fragment ${CommentModel.fragmentNameSingleComment} on ${CommentModel.GRAPHQL_TYPE} {
      id
      commentText
      createdDate
      likeCount
      likedByUser
      organisation {
        name
        logoImageMediaAsset {
            logoImageURL
        }
      }
      organisationId 
      parentCommentId
      user {
        OKID
        avatar {
          id
          sourceDarkURL
          sourceLightURL
        }
      }
      userId
      
    }
  `;

  static fragmentNameCommentThread = 'Comment_CommentThread_Fragment';
  static fragmentCommentThread = gql`
    fragment ${CommentModel.fragmentNameCommentThread} on ${CommentModel.GRAPHQL_TYPE} {
      commentThreadPagedResult(page: 0, size: ${CommentModel.DEFAULT_THREAD_SIZE}) {
        commentListCount
        commentList {
          ...${CommentModel.fragmentNameSingleComment}
        }
      }
    }
    ${CommentModel.fragmentSingleComment}
  `;

  static fragmentNameSingleCommentWithThread = 'Comment_SingleCommentWithThread_Fragment';
  static fragmentSingleCommentWithThread = gql`
    fragment ${CommentModel.fragmentNameSingleCommentWithThread} on ${CommentModel.GRAPHQL_TYPE} {
      ...${CommentModel.fragmentNameSingleComment}
      ...${CommentModel.fragmentNameCommentThread}
    }
    ${CommentModel.fragmentSingleComment}
    ${CommentModel.fragmentCommentThread}
  `;
}
