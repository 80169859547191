import { gql } from '@apollo/client';

import UnversionedMediaAssetModel from '../unversionedMediaAsset';

import BaseInspectionLogFindingModel from './base';

export default class InspectionLogFindingModel {
  static GRAPHQL_TYPE = BaseInspectionLogFindingModel.GRAPHQL_TYPE;

  static TAG = BaseInspectionLogFindingModel.TAG;

  static fragmentName = 'InspectionLogFinding_Fragment';
  static fragment = gql`
    fragment ${InspectionLogFindingModel.fragmentName} on ${InspectionLogFindingModel.GRAPHQL_TYPE} {
      ...${BaseInspectionLogFindingModel.fragmentName}
      unversionedMediaAsset {
        ...${UnversionedMediaAssetModel.fragmentName}
      }
    }
    ${BaseInspectionLogFindingModel.fragment}
    ${UnversionedMediaAssetModel.fragment}
  `;

  static fragmentNameMediaGalleryPopup = 'InspectionLogFinding_MediaGalleryPopup_Fragment';
  static fragmentMediaGalleryPopup = gql`
    fragment ${InspectionLogFindingModel.fragmentNameMediaGalleryPopup} on ${InspectionLogFindingModel.GRAPHQL_TYPE} {
      id
      inspectionLogTestAssetId
      selected @client
      tagList
      unversionedMediaAsset {
        ...${UnversionedMediaAssetModel.fragmentNameMediaGalleryPopup}
      }
    }
    ${UnversionedMediaAssetModel.fragmentMediaGalleryPopup}
  `;
}
