import { gql } from '@apollo/client';

export default class BaseInspectionLogModel {
  static GRAPHQL_TYPE = 'InspectionLog';

  static fragmentName = 'BaseInspectionLog_Fragment';
  static fragment = gql`
    fragment ${BaseInspectionLogModel.fragmentName} on ${BaseInspectionLogModel.GRAPHQL_TYPE} {
      createdBy
      createdDate
      failed
      id
      inspectedSize
      inspectionOrganisationId
      inspectionLogStage
      itemId
      lastModifiedBy
      lastModifiedDate
      lotCriticalFailure
      lotFixableProjection
      lotFixableProjectionPercent
      lotFixedPercent
      lotFlawlessProjection
      lotFlawlessProjectionPercent
      lotMajorFailure
      lotMinorFailure
      lotRejectedPercent
      lotSize
      lotTolerableProjection
      lotTolerableProjectionPercent
      lotUnfixableProjection
      lotUnfixableProjectionPercent
      organisationId
      passedAsFixed
      passedAsFlawless
      passedAsTolerated
      REFID
      rejected
      reliabilityPoint
      sampleCriticalFailures
      sampleCriticalInspected
      sampleMajorFailures
      sampleMajorInspected
      sampleMinorFailures
      sampleMinorInspected
      samplePassedAsFixedPercent
      samplePassedAsFlawlessPercent
      samplePassedAsToleratedPercent
      sampleRejectedPercent
      sourceId
      sourceType
      status
      version
    }
  `;
}
