import round from 'lodash/round';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';

import VideoMenu from './components/menu';
import VideoMenuHeader from './components/menuHeader';
import VideoMenuItem from './components/menuItem';
import VideoMenuOption from './components/menuOption';
import styles from './styles.module.scss';

import InputWithButton from 'OK/components/input/withButton';
import Link from 'OK/components/link';
import ThemeContext from 'OK/util/context/theme';
import { formatDuration } from 'OK/util/formatting';
import getTimestamp from 'OK/util/functions/getTimestamp';
import limitNumberToRange from 'OK/util/functions/limitNumberToRange';
import withForwardedRef from 'OK/util/hoc/withForwardedRef';
import withFullScreenStatus from 'OK/util/hoc/withFullscreenStatus';
import withI18n from 'OK/util/hoc/withI18n';

/**
 * @typedef {Object} VideoPlayerProps
 * @prop {string} [className] The component's class.
 * @prop {boolean} [cropToFillInPortraitFullscreen=false] When playing in fullscreen and portrait orientation, crop the
 * video to fill the screen.
 * @prop {boolean} [hideMenuButtons] Don't show menu buttons.
 * @prop {boolean} [simulateFullscreen=false] When the user presses the fullscreen button, simulate fullscreen instead
 * of entering the system native fullscreen.
 * @prop {string} source The URL to the video file.
 * @prop {'video/mp4'} sourceType The MIME type of the video file.
 */

/**
 * @class
 * @extends Component<VideoPlayerProps, {}>
 * @prop {VideoPlayerProps} props
 */
class VideoPlayer extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      isInstalledAsPWA: PropTypes.bool,
      userAgent: PropTypes.any,
      screenSize: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
      }),
    }),
    className: PropTypes.string,
    cropToFillInPortraitFullscreen: PropTypes.bool,
    forwardedRef: PropTypes.any,
    isFullScreen: PropTypes.bool,
    hideMenuButtons: PropTypes.bool,
    simulateFullscreen: PropTypes.bool,
    source: PropTypes.string.isRequired,
    sourceType: PropTypes.oneOf(['video/mp4']),
    t: PropTypes.func,
    videoNodeRef: PropTypes.any,
    galleryWidth: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentTime: 0,
      galleryWidth: 0,
      hasEnded: false,
      hasStarted: false,
      hoverTime: 0,
      hoverTimeLeftPx: 10,
      hoverVolumeLevel: 0,
      isPiP: false,
      isPlaying: false,
      isSimulatingFullscreen: false,
      isUserActive: false,
      isUserChangingVolume: false,
      isUserScrubbing: false,
      percentBuffered: 0,
      percentPlayed: 0,
      pipAvailable: this.getPiPAvailable(),
      reportCopyrightViolation: false,
      reportEmail: '',
      reportError: '',
      reportOffensiveOrIllegal: false,
      scrubTime: 0,
      scrubTimeLeftPx: 0,
      speed: 1.0,
      videoDuration: undefined,
      videoSize: {
        height: 0,
        width: 0,
      },
      visibleMenu: undefined,
      volumeLevel: 0,
    };

    this.innerRef = createRef();
    this.innerVideoNodeRef = createRef();
  }

  get ref() {
    return this.props.forwardedRef || this.innerRef;
  }

  get videoNodeRef() {
    return this.props.videoNodeRef || this.innerVideoNodeRef;
  }

  /* Component lifecycle */

  async componentDidMount() {
    const { className, galleryWidth, source, sourceType = 'video/mp4', ...passedVideojsProps } = this.props;

    if (galleryWidth) {
      this.setState({ galleryWidth: galleryWidth });
    }

    const videojsProps = {
      ...passedVideojsProps,
      aspectRatio: '1:1',
      bigPlayButton: false,
      controlBar: false,
      errorDisplay: false,
      inactivityTimeout: 2000,
      loadingSpinner: false,
      posterImage: false,
      sources: [{ src: source, type: sourceType }],
      textTrackDisplay: false,
      textTrackSettings: false,
    };

    const videojs = (await import('video.js')).default;
    this.player = videojs(this.videoNodeRef.current, videojsProps, () => {
      okdebug('Video player ready', this.player);
      // Listen for events
      this.player.on('loadedmetadata', this.onLoadedMetadata);
      this.player.on('pause', this.onPause);
      this.player.on('play', this.onPlay);
      this.player.on('useractive', this.onUserActive);
      this.player.on('userinactive', this.onUserInactive);
      this.player.on('ended', this.onEnded);
      this.player.on('loadstart', this.onLoadProgress);
      this.player.on('progress', this.onLoadProgress);
      this.player.on('timeupdate', this.onCurrentTimeChanged);
      this.player.on('volumechange', this.onVolumeChanged);

      if (this.state.pipAvailable) {
        this.player.on('enterpictureinpicture', this.onEnterPictureInPicture);
        this.player.on('leavepictureinpicture', this.onExitPictureInPicture);
      }
    });
  }

  componentDidUpdate(prevProps) {
    // Update source
    if (prevProps.source !== this.props.source) {
      this.player.src({ src: this.props.source, type: this.props.sourceType });
    }

    // update gallery width
    if (prevProps.galleryWidth !== this.props.galleryWidth) {
      this.setState({ galleryWidth: this.props.galleryWidth });
    }
  }

  componentWillUnmount() {
    if (this.player) {
      // Unlisten from events
      this.player.off('loadedmetadata', this.onLoadedMetadata);
      this.player.off('pause', this.onPause);
      this.player.off('play', this.onPlay);
      this.player.off('useractive', this.onUserActive);
      this.player.off('userinactive', this.onUserInactive);
      this.player.off('ended', this.onEnded);
      this.player.off('loadstart', this.onLoadProgress);
      this.player.off('progress', this.onLoadProgress);
      this.player.off('timeupdate', this.onCurrentTimeChanged);
      this.player.off('volumechange', this.onVolumeChanged);

      if (this.state.pipAvailable) {
        this.player.off('enterpictureinpicture', this.onEnterPictureInPicture);
        this.player.off('leavepictureinpicture', this.onExitPictureInPicture);
      }

      // Destroy the player
      this.player.dispose();
    }
  }

  /* Methods */

  enterPictureInPicture = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.player.requestPictureInPicture();
  };

  fastForward = (time) => {
    this.seek(this.player.currentTime() + time);
  };

  getPercentProgressClicked = (clickX) => {
    const { isFullScreen } = this.props;
    const controlsRect = this.controlsNode.getBoundingClientRect();
    const clickPoint = clickX - (isFullScreen ? 50 : 0);
    const controlsWidth = controlsRect.width - (isFullScreen ? 100 : 0);
    const percent = (clickPoint - controlsRect.x) / controlsWidth;
    return limitNumberToRange(percent, 0, 1);
  };

  getPercentVolumeClicked = (clickX) => {
    const volumeBarRect = this.volumeNode.getBoundingClientRect();
    return limitNumberToRange((clickX - volumeBarRect.x) / volumeBarRect.width, 0, 1);
  };

  /**
   * Return whether to enable picture-in-picture.
   *
   * Picture-in-picture should be enable in browsers that support it. One exception is that Safari supports it but not
   * when installed as PWA.
   *
   * @returns {boolean}
   */
  getPiPAvailable = () => {
    return (
      !(this.props.appContext.isInstalledAsPWA && this.props.appContext.userAgent?.os?.name === 'iOS') &&
      typeof document !== 'undefined' &&
      document.pictureInPictureEnabled
    );
  };

  hideMenu = () => {
    this.setState({ visibleMenu: undefined });
    this.resetReportFields();
  };

  pause = () => {
    this.player.pause();
  };

  play = () => {
    this.player.play();
    this.setState({ hasEnded: false });
  };

  resetReportFields = () => {
    this.setState({ reportError: '', reportCopyrightViolation: false, reportOffensiveOrIllegal: false });
  };

  rewind = (time) => {
    this.seek(this.player.currentTime() - time);
  };

  seek = (time) => {
    this.player.currentTime(time);
    this.setState({ hasEnded: false });
  };

  setError = (error) => {
    this.setState({ reportError: error });
  };

  setFullscreen = (fullscreen) => {
    if (fullscreen) {
      okdebug('enter fullscreen');
      this.player.requestFullscreen();
    } else {
      okdebug('exit fullscreen');
      this.player.exitFullscreen();
    }
  };

  setHoverVolumeLevel = (volumeLevel) => {
    this.setState({ hoverVolumeLevel: volumeLevel * 100 });
  };

  setPercentPlayed = (percent) => {
    this.setState({ percentPlayed: percent });
  };

  setSpeed = (speed) => {
    this.player.playbackRate(speed);
    this.setState({ speed });
  };

  setVideoDimensions = (height, width) => {
    this.setState({ videoSize: { height, width } });
  };

  setVideoDuration = (duration) => {
    this.setState({ videoDuration: duration });
  };

  setVolumeLevel = (volumeLevel) => {
    this.player.volume(volumeLevel);

    // Unmute when user changes volume
    const muted = this.player.muted();
    if (muted) {
      this.player.muted(false);
    }

    this.setHoverVolumeLevel(volumeLevel);
  };

  showMenu = (menu = 'main') => {
    this.setState({ visibleMenu: menu });
    this.resetReportFields();
  };

  submitReport = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setError('');

    const { reportEmail } = this.state;

    const validator = (await import('validator')).default;
    if (!validator.isEmail(reportEmail)) {
      this.setError('Please enter a valid email address.');
      return;
    }

    okdebug('Submit report');
  };

  toggleMute = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const muted = this.player.muted();
    let mute = !muted;
    this.player.muted(mute);
  };

  togglePlayPause = () => {
    if (this.state.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  };

  toggleReportCopyrightViolation = () => {
    this.setState({ reportCopyrightViolation: !this.state.reportCopyrightViolation });
  };

  toggleReportOffensiveOrIllegal = () => {
    this.setState({ reportOffensiveOrIllegal: !this.state.reportOffensiveOrIllegal });
  };

  updatePercentBuffered = () => {
    const { videoDuration } = this.state;
    if (videoDuration) {
      const bufferedDuration = this.player.bufferedEnd();
      this.setState({ percentBuffered: (bufferedDuration / videoDuration) * 100 });
    } else {
      okwarn('Cannot calculate percent buffered because video duration is not set.');
    }
  };

  /* Event handlers */

  onClickFastForward = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.fastForward(10);
  };

  onClickMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.showMenu();
  };

  onClickMinMax = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { isFullScreen, simulateFullscreen } = this.props;
    if (simulateFullscreen) {
      this.setState({ isSimulatingFullscreen: !this.state.isSimulatingFullscreen });
    } else {
      this.setFullscreen(!isFullScreen);
    }
  };

  onClickRewind = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.rewind(10);
  };

  onCurrentTimeChanged = () => {
    const { isUserScrubbing, videoDuration } = this.state;
    const currentTime = this.player.currentTime();

    this.setState({ currentTime });

    // Don't update percent played while scrubbing because we want to show the scrub position instead
    if (!isUserScrubbing) {
      this.setPercentPlayed((currentTime / videoDuration) * 100);
    }
  };

  onEnded = () => {
    this.setState({ hasEnded: true });
  };

  onEnterPictureInPicture = () => {
    this.setState({ isPiP: true });
  };

  onEnterReportEmail = (e) => {
    const reportEmail = e.target.value;
    this.setState({ reportEmail });
    this.setError('');
  };

  onExitPictureInPicture = () => {
    this.setState({ isPiP: false });
  };

  onLoadedMetadata = () => {
    this.setVideoDimensions(this.player.videoHeight(), this.player.videoWidth());
    const videoDuration = this.player.duration();
    this.setVideoDuration(videoDuration);
    this.updatePercentBuffered();
    this.onVolumeChanged();
  };

  onLoadProgress = () => {
    this.updatePercentBuffered();
  };

  onPause = () => {
    this.setState({ isPlaying: false });
  };

  onPlay = () => {
    this.setState({ hasStarted: true, isPlaying: true });
  };

  onProgressClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { videoDuration } = this.state;
    const percentProgressClicked = this.getPercentProgressClicked(e.clientX);
    const seekToTime = percentProgressClicked * videoDuration;
    this.seek(seekToTime);
  };

  onProgressMouseDown = (e) => {
    this.setState({ isUserScrubbing: true });
    const percentProgressClicked = this.getPercentProgressClicked(e.clientX);
    this.setPercentPlayed(percentProgressClicked * 100);
  };

  onProgressMouseMove = (e) => {
    const { isFullScreen } = this.props;
    const { isUserScrubbing, videoDuration } = this.state;
    if (videoDuration) {
      const controlsRect = this.controlsNode.getBoundingClientRect();
      const percentProgressHovered = this.getPercentProgressClicked(e.clientX);
      const controlsWidth = controlsRect.width - (isFullScreen ? 100 : 0);
      const minLeft = isFullScreen ? 0 : 10;
      const maxLeft = isFullScreen ? controlsWidth - 50 : controlsWidth - 50 - 10;
      const hoverTimeLeftPx = limitNumberToRange(round(percentProgressHovered * controlsWidth - 25), minLeft, maxLeft);
      this.setState({ hoverTime: percentProgressHovered * videoDuration, hoverTimeLeftPx: hoverTimeLeftPx });

      if (isUserScrubbing) {
        // Update the played progress to where the user is scrubbing
        this.setPercentPlayed(percentProgressHovered * 100);
      }
    }
  };

  onProgressMouseUp = () => {
    this.setState({ isUserScrubbing: false });
  };

  onVolumeChanged = () => {
    const volumeLevel = round(this.player.volume() * 100);
    this.setState({ volumeLevel });
  };

  onVolumeClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const newVolumeLevel = this.getPercentVolumeClicked(e.clientX);
    this.setVolumeLevel(newVolumeLevel);
  };

  onVolumeMouseDown = (e) => {
    this.setState({ isUserChangingVolume: true });
    const newVolumeLevel = this.getPercentVolumeClicked(e.clientX);
    this.setVolumeLevel(newVolumeLevel);
  };

  onVolumeMouseMove = (e) => {
    const { isUserChangingVolume } = this.state;
    const volumeLevel = this.getPercentVolumeClicked(e.clientX);
    if (isUserChangingVolume) {
      this.setVolumeLevel(volumeLevel);
    } else {
      this.setHoverVolumeLevel(volumeLevel);
    }
  };

  onVolumeMouseUp = () => {
    this.setState({ isUserChangingVolume: false });
  };

  onUserActive = () => {
    this.setState({ isUserActive: true });
  };

  onUserInactive = () => {
    this.setState({ isUserActive: false, isUserChangingVolume: false, isUserScrubbing: false });
  };

  /* Render */

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  render() {
    // Props & state
    const { appContext, className, cropToFillInPortraitFullscreen = false, isFullScreen, t } = this.props;
    const {
      currentTime,
      galleryWidth,
      hasEnded,
      hasStarted,
      hoverTime,
      hoverTimeLeftPx,
      hoverVolumeLevel,
      isPiP,
      isPlaying,
      isSimulatingFullscreen,
      isUserActive,
      isUserChangingVolume,
      isUserScrubbing,
      pipAvailable,
      reportCopyrightViolation,
      reportEmail,
      reportError,
      reportOffensiveOrIllegal,
      speed,
      videoDuration,
      visibleMenu,
      volumeLevel,
    } = this.state;
    const fullscreenMode = isFullScreen || isSimulatingFullscreen;
    const muted = this.player?.muted() || false;

    this.player?.playsinline(true);

    // Container element classes
    let containerClassNames = styles.container;
    if (hasEnded) {
      containerClassNames = `${containerClassNames} ${styles.videoHasEnded}`;
    }
    if (hasStarted) {
      containerClassNames = `${containerClassNames} ${styles.videoHasStarted}`;
    }
    if (isUserActive) {
      containerClassNames = `${containerClassNames} ${styles.userIsActive}`;
    }
    if (isFullScreen) {
      containerClassNames = `${containerClassNames} ${styles.fullscreen}`;
    }
    if (isPlaying) {
      containerClassNames = `${containerClassNames} ${styles.videoIsPlaying}`;
    } else {
      containerClassNames = `${containerClassNames} ${styles.videoIsPaused}`;
    }
    if (isUserScrubbing) {
      containerClassNames = `${containerClassNames} ${styles.userIsScrubbing}`;
    }
    if (isUserChangingVolume) {
      containerClassNames = `${containerClassNames} ${styles.userIsChangingVolume}`;
    }
    if (visibleMenu) {
      containerClassNames = `${containerClassNames} ${styles.menuIsShown}`;
    }

    // Layout element classes
    let layoutClassNames = styles.layout;
    if (isSimulatingFullscreen) {
      layoutClassNames = `${layoutClassNames} ${styles.simulatedFullscreenLayout}`;
    }
    if (className) {
      layoutClassNames = `${layoutClassNames} ${className}`;
    }

    const layoutInlineStyles = {};
    if (isSimulatingFullscreen) {
      layoutInlineStyles.height = appContext.screenSize.height;
      layoutInlineStyles.width = appContext.screenSize.width;
    }

    // Setup video size and position
    const isLandscape = appContext.screenSize.width > appContext.screenSize.height;
    const { videoSize } = this.state;
    const videoAspectRatio = videoSize.height ? videoSize.width / videoSize.height : 1;
    const videoNeedsCustomPosition = videoAspectRatio !== 1;
    const videoContainerSize = this.ref.current ? this.ref.current.clientHeight : 315;
    const videoWidth = videoNeedsCustomPosition ? parseInt(videoAspectRatio * videoContainerSize) : '100%';
    const videoPositionLeft = videoNeedsCustomPosition ? -parseInt((videoWidth - videoContainerSize) / 2) : 0;

    // Configure icons based on state
    let minMaxButtonIcon, playPauseButtonIcon, volumeIcon;
    // Minimize / maximize icon
    if (fullscreenMode) {
      minMaxButtonIcon = 'minimize';
    } else {
      minMaxButtonIcon = 'maximize';
    }
    // Play / pause icon
    if (hasEnded && !isPlaying) {
      playPauseButtonIcon = 'replay';
    } else {
      playPauseButtonIcon = isPlaying ? 'pause_circle' : 'play_circle';
    }
    // Volume icon
    if (volumeLevel === 0 || muted) {
      volumeIcon = 'volume_off';
    } else {
      volumeIcon = 'volume_on';
    }

    // Setup instance styles
    const instanceStyles = [];
    if (videoNeedsCustomPosition && !(isFullScreen && !isSimulatingFullscreen) && !isPiP) {
      // Horizontally center the video inside the bounds of the component
      instanceStyles.push(
        <style key='centerHorizontally'>{`
          #${this.player.id_}_html5_api {
            left: ${videoPositionLeft}px;
            position: relative;
            width: ${videoNeedsCustomPosition ? `${videoWidth}px` : videoWidth};
          }
        `}</style>
      );
    }
    if (cropToFillInPortraitFullscreen && fullscreenMode && !isLandscape) {
      // Crop to fill the fullscreen view in portrait
      instanceStyles.push(
        <style key='cropPortraitFullscreen'>{`
          #${this.player.id_}_html5_api {
            height: 115%;
            position: relative;
            left: 50% !important;
            top: -7.5%;
            transform: translateX(-50%);
          }
        `}</style>
      );
    }
    if (isSimulatingFullscreen && isLandscape) {
      // Simulate fullscreen (landscape)
      instanceStyles.push(
        <style key='SimulateFullscreenLandscape'>{`
          #${this.player.id_}_html5_api {
            height: ${appContext.screenSize.height}px !important;
            width: ${appContext.screenSize.width}px !important;
            left: 0 !important;
          }
        `}</style>
      );
    }

    return (
      <>
        <Head>
          <link rel='preload' href='/icons/replay_light.svg' as='image' type='image/svg+xml' />
          <link rel='preload' href='/icons/fast_forward_light.svg' as='image' type='image/svg+xml' />
          <link rel='preload' href='/icons/rewind_light.svg' as='image' type='image/svg+xml' />
          <link rel='preload' href='/icons/maximize_light.svg' as='image' type='image/svg+xml' />
          <link rel='preload' href='/icons/minimize_light.svg' as='image' type='image/svg+xml' />
          <link rel='preload' href='/icons/report_light.svg' as='image' type='image/svg+xml' />
          <link rel='preload' href='/icons/share_blue.svg' as='image' type='image/svg+xml' />
          <link rel='preload' href='/icons/subtitles_light.svg' as='image' type='image/svg+xml' />
          <link rel='preload' href='/icons/volume_on_light.svg' as='image' type='image/svg+xml' />
          <link rel='preload' href='/icons/volume_off_light.svg' as='image' type='image/svg+xml' />
        </Head>
        <div className={layoutClassNames} ref={this.ref} style={layoutInlineStyles}>
          <div className={containerClassNames} onMouseOver={this.onUserActive} onMouseOut={this.onUserInactive}>
            <div className={styles.vjsContainer} data-vjs-player>
              <video ref={this.videoNodeRef} className='video-js' playsInline style={{ top: -galleryWidth }} />
              <div
                className={styles.videoControls}
                onClick={this.togglePlayPause}
                ref={(node) => (this.controlsNode = node)}
              >
                <div className={styles.videoMenuBackground} />
                {!this.props.hideMenuButtons && (
                  <div className={styles.videoMenuButtons}>
                    <button
                      className={`${styles.videoMenuButton} ${styles.videoMenuButtonMenu}`}
                      onClick={this.onClickMenu}
                    >
                      <img alt={t('IMG_ALT_MENU')} src={`/icons/subtitles_${this.context.name}.svg`} />
                      {t('MENU')}
                    </button>
                    {pipAvailable && (
                      <button className={styles.videoMenuButton} onClick={this.enterPictureInPicture}>
                        <img alt={t('IMG_ALT_PICTURE_IN_PICTURE')} src={`/icons/pip_${this.context.name}.svg`} />
                        {t('PICTURE_IN_PICTURE_ACRONYM')}
                      </button>
                    )}
                    <button className={styles.videoMenuButton} onClick={this.onClickMinMax}>
                      <img
                        alt={t(fullscreenMode ? 'IMG_ALT_MINIMIZE' : 'IMG_ALT_MAXIMIZE')}
                        src={`/icons/${minMaxButtonIcon}_${this.context.name}.svg`}
                      />
                      {fullscreenMode ? t('STEPPER_MIN') : t('STEPPER_MAX')}
                    </button>
                  </div>
                )}
                <div className={styles.playbackButtons}>
                  <button className={styles.rewindButton} onClick={this.onClickRewind}>
                    <img alt={t('IMG_ALT_REWIND')} src={`/icons/rewind_${this.context.name}.svg`} />
                  </button>
                  <button className={styles.playPauseButton} onClick={this.togglePlayPause}>
                    <img
                      alt={t(isPlaying ? 'IMG_ALT_PAUSE' : 'IMG_ALT_PLAY')}
                      src={`/icons/${playPauseButtonIcon}_${this.context.name}.svg`}
                    />
                  </button>
                  <button className={styles.fastForwardButton} onClick={this.onClickFastForward}>
                    <img alt={t('IMG_ALT_FAST_FORWARD')} src={`/icons/fast_forward_${this.context.name}.svg`} />
                  </button>
                </div>
                <div className={styles.volume}>
                  <button className={styles.volumeIcon} onClick={this.toggleMute}>
                    <img alt={t('IMG_ALT_VOLUME')} src={`/icons/${volumeIcon}_${this.context.name}.svg`} />
                  </button>
                  <div
                    className={styles.volumeBar}
                    onClick={this.onVolumeClick}
                    onMouseDown={this.onVolumeMouseDown}
                    onMouseMove={this.onVolumeMouseMove}
                    onMouseLeave={this.onVolumeMouseUp}
                    onMouseUp={this.onVolumeMouseUp}
                    ref={(node) => (this.volumeNode = node)}
                  >
                    <p
                      className={`${styles.tooltip} ${styles.volumeHoverValue}`}
                      style={{ left: `${hoverVolumeLevel - 23}px` }}
                    >
                      {round(hoverVolumeLevel)}%
                    </p>
                    <div className={styles.volumeLevel} style={{ width: `${muted ? 0 : volumeLevel}%` }} />
                  </div>
                </div>
                <p className={styles.playbackTimestamp}>
                  <strong>{getTimestamp(currentTime)}</strong>/{getTimestamp(videoDuration)}
                </p>
                {(hasEnded || !hasStarted) && (
                  <div className={styles.watchLabels}>
                    <p>
                      <strong>{hasEnded ? t('REWATCH_VIDEO') : t('WATCH_VIDEO')}</strong>
                    </p>
                    <p className={styles.duration}>
                      {this.state.videoDuration && formatDuration(this.state.videoDuration, t, { style: 'MEDIUM' })}
                    </p>
                  </div>
                )}
                <div
                  className={styles.progress}
                  onClick={this.onProgressClick}
                  onMouseDown={this.onProgressMouseDown}
                  onMouseMove={this.onProgressMouseMove}
                  onMouseUp={this.onProgressMouseUp}
                >
                  <p className={`${styles.tooltip} ${styles.hoverTimestamp}`} style={{ left: `${hoverTimeLeftPx}px` }}>
                    {getTimestamp(hoverTime)}
                  </p>
                  <div className={styles.progressBar}>
                    <div className={styles.loaded} style={{ width: `${this.state.percentBuffered}%` }} />
                    <div className={styles.played} style={{ width: `${this.state.percentPlayed}%` }} />
                  </div>
                </div>
              </div>
              <img
                alt={t('IMG_ALT_LOADING_SPINNER')}
                className={styles.loadingSpinner}
                src='/icons/spinner_light.svg'
              />
              {visibleMenu && (
                <>
                  {visibleMenu === 'main' && (
                    <VideoMenu className={`${styles.videoMenu} ${styles.mainMenu}`}>
                      <VideoMenuHeader onClickClose={this.hideMenu} title={t('MENU')} />
                      {/* <VideoMenuItem icon='/icons/caret_link.svg' tint='navigation' title='Captions' /> */}
                      {/* <VideoMenuItem icon='/icons/caret_link.svg' tint='navigation' title='Quality' /> */}
                      <VideoMenuItem
                        icon='/icons/caret_link.svg'
                        onClick={() => this.showMenu('speed')}
                        tint='navigation'
                        title={t('SPEED')}
                      />
                      {/* <VideoMenuItem onClick={() => this.showMenu('report')} tint='alert' title='Report' /> */}
                    </VideoMenu>
                  )}
                  {visibleMenu === 'speed' && (
                    <VideoMenu className={`${styles.videoMenu} ${styles.speedMenu}`}>
                      <VideoMenuHeader
                        onClickBack={() => this.showMenu('main')}
                        onClickClose={this.hideMenu}
                        title={t('SPEED')}
                      />
                      <VideoMenuOption enabled={speed === 2.0} onClick={() => this.setSpeed(2.0)} title='200%' />
                      <VideoMenuOption enabled={speed === 1.75} onClick={() => this.setSpeed(1.75)} title='175%' />
                      <VideoMenuOption enabled={speed === 1.5} onClick={() => this.setSpeed(1.5)} title='150%' />
                      <VideoMenuOption enabled={speed === 1.25} onClick={() => this.setSpeed(1.25)} title='125%' />
                      <VideoMenuOption enabled={speed === 1.0} onClick={() => this.setSpeed(1.0)} title='100%' />
                      <VideoMenuOption enabled={speed === 0.75} onClick={() => this.setSpeed(0.75)} title='75%' />
                      <VideoMenuOption enabled={speed === 0.5} onClick={() => this.setSpeed(0.5)} title='50%' />
                      <VideoMenuOption enabled={speed === 0.25} onClick={() => this.setSpeed(0.25)} title='25%' />
                    </VideoMenu>
                  )}
                  {visibleMenu === 'report' && (
                    <VideoMenu className={`${styles.videoMenu} ${styles.reportMenu}`}>
                      <form onSubmit={this.submitReport}>
                        <VideoMenuHeader
                          onClickBack={() => this.showMenu('main')}
                          onClickClose={this.hideMenu}
                          title={t('REPORT_QUESTION_MARK')}
                        />
                        <VideoMenuOption
                          enabled={reportCopyrightViolation}
                          onClick={this.toggleReportCopyrightViolation}
                          title='Violates copyright'
                          type='checkbox'
                        />
                        <VideoMenuOption
                          enabled={reportOffensiveOrIllegal}
                          onClick={this.toggleReportOffensiveOrIllegal}
                          title='Offensive or illegal'
                          type='checkbox'
                        />
                        <InputWithButton
                          buttonTint='navigation'
                          buttonTitle='Go'
                          className={styles.reportEmailInput}
                          onChange={this.onEnterReportEmail}
                          placeholder='Email (optional)'
                          value={reportEmail}
                        />
                        {reportError && <p className={styles.reportError}>{reportError}</p>}
                        <p className={styles.reportTerms}>
                          By/if providing an email, you accept our{' '}
                          <Link href='/solutions/terms' target='_blank'>
                            privacy policy
                          </Link>
                        </p>
                      </form>
                    </VideoMenu>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {instanceStyles}
      </>
    );
  }
}

VideoPlayer.contextType = ThemeContext;

export default connect((state) => {
  return {
    appContext: {
      isInstalledAsPWA: state.app.isInstalledAsPWA,
      userAgent: state.app.userAgent,
      screenSize: state.app.screenSize,
    },
  };
})(withForwardedRef(withFullScreenStatus(withI18n(VideoPlayer))));
