import PropTypes from 'prop-types';

import Icon, { ICONS } from '../icon';

import styles from './styles.module.scss';

import Select from 'OK/components/select';
import InspectionLogModel from 'OK/models/inspectionLog';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * Select element for tagging with an inspection test result.
 *
 * @param {object} props
 * @param {(newValue: string) => {}} props.onChange Event handler when the selected tag changes.
 * @param {string} props.value Current selection.
 */
export default function InspectionLogStageSelect(props) {
  const { onChange, value, ...otherProps } = props;
  const { t } = useI18n();

  let iconName;
  let iconTint;
  let iconColor;

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Select
      onClick={handleChange}
      className={styles.stageSelector}
      displayLabelRender={(selectedOption) => {
        switch (selectedOption?.value) {
          case InspectionLogModel.STAGE.REVIEWED:
            iconName = ICONS.LOG_REVIEWED.name;
            iconTint = 'creation';
            iconColor = null;
            break;
          case InspectionLogModel.STAGE.PENDING:
            iconName = ICONS.LOG_PENDING.name;
            iconTint = null;
            iconColor = '#767575';
            break;
          case InspectionLogModel.STAGE.OPEN:
            iconName = ICONS.LOG_OPEN.name;
            iconTint = 'notification';
            iconColor = null;
            break;
          case InspectionLogModel.STAGE.CLOSED:
            iconName = ICONS.LOG_CLOSED.name;
            iconTint = 'default';
            iconColor = null;
            break;
          case '':
          case null:
          case undefined:
            iconName = ICONS.LOG_OPEN.name;
            iconTint = 'notification';
            iconColor = null;
            break;
          default:
            return selectedOption?.label;
        }

        return (
          <>
            <Icon className={styles.stageIcon} name={iconName} tint={iconTint} color={iconColor} />
            {selectedOption?.label || 'Open'}
          </>
        );
      }}
      onChange={onChange}
      options={[
        { label: t(InspectionLogModel.STAGE.PENDING), value: InspectionLogModel.STAGE.PENDING },
        { label: t(InspectionLogModel.STAGE.OPEN), value: InspectionLogModel.STAGE.OPEN },
        { label: t(InspectionLogModel.STAGE.CLOSED), value: InspectionLogModel.STAGE.CLOSED },
        { label: t(InspectionLogModel.STAGE.REVIEWED), value: InspectionLogModel.STAGE.REVIEWED },
      ]}
      value={value}
      transparent
      disableEmptySelection
      {...otherProps}
    />
  );
}

InspectionLogStageSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
