import { gql } from '@apollo/client';

import BaseMediaAssetModel from '../mediaAsset/base';
import BaseOrganisationModel from '../organisation/base';
import BaseProductModel from '../product/base';

import BaseProductChildProductModel from './base';

export default class ProductChildProductModel {
  static GRAPHQL_TYPE = BaseProductChildProductModel.GRAPHQL_TYPE;

  static fragmentName = 'ProductChildProduct_Fragment';
  static fragment = gql`
    fragment ${ProductChildProductModel.fragmentName} on ${ProductChildProductModel.GRAPHQL_TYPE} {
      ...${BaseProductChildProductModel.fragmentName}
      childProduct {
        ...${BaseProductModel.fragmentName}
        organisation {
          ...${BaseOrganisationModel.fragmentName}
        }
        productMediaAssetList {
          mediaAsset {
            ...${BaseMediaAssetModel.fragmentName}
          }
          order
          publishStatus
        }
      }
    }
    ${BaseProductChildProductModel.fragment}
    ${BaseProductModel.fragment}
    ${BaseOrganisationModel.fragment}
    ${BaseMediaAssetModel.fragment}
  `;
}
