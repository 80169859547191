import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
  ArchiveCardTitle,
} from 'OK/components/archiveCard';
import ArchiveCardManagerOptions from 'OK/components/archiveCard/managerOptions';
import Button from 'OK/components/button';
import { ICONS } from 'OK/components/icon';
import Link from 'OK/components/link';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import TestAssetModel from 'OK/models/testAsset';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import { formatNumber } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function TestArchiveCard(props) {
  /* Variables */

  const {
    cardClassName,
    className,
    linkToArchivePage = true,
    onClickLink: onClickLinkProp,
    onClickUnlink: onClickUnlinkProp,
    showLinkButton = false,
    showUnlinkButton = false,
    testAsset,
    ...otherProps
  } = props;
  const { linkedInspectionAssetList, REFID, reliabilityPointForPublishedLogs, testSeverityLevel, whatToLookForMap } =
    testAsset;
  const { t, locale } = useI18n();
  const dispatch = useDispatch();

  // State

  const [isLinking, setIsLinking] = useState(false);

  /* Methods */

  // Event handlers

  const onClickLink = useCallback(() => {
    if (onClickLinkProp) {
      onClickLinkProp(testAsset.id, setIsLinking);
    }
  }, [onClickLinkProp, testAsset.id]);

  const onClickUnlink = useCallback(() => {
    if (onClickUnlinkProp) {
      onClickUnlinkProp(testAsset.id);
    }
  }, [onClickUnlinkProp, testAsset.id]);

  /* Render */

  const title = TestAssetModel.titleForTest(testAsset, locale);

  const languageTags = useMemo(() => {
    const coveredLanguageList = Object.keys(whatToLookForMap);
    const moreThan4Languages = coveredLanguageList.length > 4;
    const languages = moreThan4Languages ? coveredLanguageList.slice(0, 4) : coveredLanguageList;
    const tags = languages.map((languageIso) => (
      <Tag className={styles.titleLanguageTag} key={languageIso}>
        {languageIso}
      </Tag>
    ));
    if (moreThan4Languages) {
      const numberOfAdditionalLanguages = coveredLanguageList.length - 4;
      return (
        <>
          {tags}
          <Text className={styles.tagMoreLabel}>+ {numberOfAdditionalLanguages}</Text>
        </>
      );
    }

    return tags;
  }, [whatToLookForMap]);

  const Container = linkToArchivePage ? Link : 'div';
  const containerProps = { className };
  if (linkToArchivePage) {
    containerProps.href = TestAssetModel.link(testAsset);
    containerProps.onClick = (e) => {
      e.preventDefault();
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'TEST_ASSET', OKID: null },
        null,
        TestAssetModel.link(testAsset)
      );
      dispatch(setBrowserHistoryAction(history));
    };
  }

  return (
    <Container {...containerProps}>
      <ArchiveCard className={cardClassName} {...otherProps}>
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery
            imageUrl={TestAssetModel.previewPhoto(testAsset)}
            placeholderIconName={ICONS.TEST.name}
            refId={REFID}
            showOverlay
            showPlaceholderOverMedia
          />
          <ArchiveCardMainContent className={styles.content}>
            <ArchiveCardHeader type={t('STEP')} />
            <ArchiveCardTitle className={styles.title}>{title}</ArchiveCardTitle>
            <Text className={styles.tagRow} size='xs'>
              <Tag className={styles.testLevel} invert>
                {t(testSeverityLevel)}
              </Tag>
            </Text>
            <Text className={styles.tagRow} size='xs'>
              {languageTags}
            </Text>
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow reliabilityGrade={reliabilityPointForPublishedLogs}>
            {linkedInspectionAssetList.length === 1
              ? t('ARCHIVE_HEADER_1_WORKFLOW')
              : t('ARCHIVE_HEADER_X_WORKFLOWS', { data: { number: formatNumber(linkedInspectionAssetList.length) } })}
          </ArchiveCardFooterRow>
          <ArchiveCardFooterRow className={styles.pointsRow} />
        </ArchiveCardFooter>
        {(showLinkButton || showUnlinkButton) && (
          <ArchiveCardManagerOptions onClickUnlink={onClickUnlink} showUnlinkOption={showUnlinkButton}>
            {showLinkButton && (
              <Button
                block
                className={styles.manageButton}
                disabled={isLinking}
                loading={isLinking}
                onClick={onClickLink}
                tint='creation'
              >
                {t('LINK')}
              </Button>
            )}
          </ArchiveCardManagerOptions>
        )}
      </ArchiveCard>
    </Container>
  );
}

TestArchiveCard.propTypes = {
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  linkToArchivePage: PropTypes.bool,
  onClickLink: PropTypes.func,
  onClickUnlink: PropTypes.func,
  showLinkButton: PropTypes.bool,
  showUnlinkButton: PropTypes.bool,
  testAsset: PropTypes.object,
};
