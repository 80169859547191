

/**
 * Compress an image by setting either max filesize or max dimension. Defaults to limiting the iamge to 500KB.
 *
 * @param {File} image The image file.
 * @param {object} [options] Compression options.
 * @param {Number} [options.maxSizeMB] Compress by limiting filesize to this value (megabytes).
 * @param {Number} [options.maxWidthOrHeight] Compress by limiting width or height to this value (pixels).
 *
 * @returns {File} The compressed image.
 */
export default async function compressImage(image, options = {}) {
  const { maxSizeMB, maxWidthOrHeight } = options;

  const ImageCompression = (await import('browser-image-compression')).default;
  const compressedImage = await ImageCompression(image, {
    maxSizeMB,
    maxWidthOrHeight,
  });
  okdebug(`Compressed image from ${image.size} to ${compressedImage.size}`);

  return compressedImage;
}
