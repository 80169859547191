import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import datesAreEqual from 'date-fns/isSameDay';
import subtractFromDate from 'date-fns/sub';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ProductPageDocumentationSection from '../pages/archive/product/[refId]/documentationSection';
import ProductWorkSection from '../pages/archive/product/[refId]/workSection';
import ProductPageCatalogueSection from '../pages/archive/product/catalogueSection';
import AddWorkPopup from '../popups/addWork';
import ProductInfoEditPopup from '../popups/productInfoEdit';
import ShareAssetPopup from '../popups/share';
import ShareOKIDPopup from '../popups/shareOKID';

import styles from './styles.module.scss';

import Accordion from 'OK/components/accordion';
import AlternateIDsEditor from 'OK/components/alternateIdsEditor';
import Button from 'OK/components/button';
import { Slide, Carousel } from 'OK/components/carousel';
import CommentsController from 'OK/components/comments/controller';
import FloatingButtonsContainer from 'OK/components/floatingButtons';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import SearchInput from 'OK/components/input/search';
import CardLayout from 'OK/components/layouts/card';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import LinkToOrganisation from 'OK/components/link/linkToOrganisation';
import LoadingSpinner from 'OK/components/loadingSpinner';
import MediaGallery from 'OK/components/mediaGallery';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import Notice from 'OK/components/notice';
import PageTitle from 'OK/components/pageTitle';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Select from 'OK/components/select';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import Toggle from 'OK/components/toggle';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import appConfig from 'OK/config/app';
import MediaAssetModel from 'OK/models/mediaAsset';
import BaseMediaAssetModel, {
  AudioMediaAssetModel,
  ImageMediaAssetModel,
  VideoMediaAssetModel,
} from 'OK/models/mediaAsset/base';
import ProductModel from 'OK/models/product';
import ProductMediaAssetModel from 'OK/models/productMediaAsset';
import DatePickerPopup from 'OK/modules/popups/datePicker';
import { latestStreamingRecordings, UpdateAIConfigurationSettingsMutation } from 'OK/networking/items';
import { updateImageMediaAssetRequest } from 'OK/networking/media';
import { getOrganisationByOKIDQuery } from 'OK/networking/organisations';
import {
  createProductAudioMediaAssetRequest,
  createProductImageMediaAssetRequest,
  createProductVideoMediaAssetRequest,
  publishProductMutation,
  reorderProductMediaBackwardMutation,
  reorderProductMediaForwardMutation,
  unpublishProductMutation,
} from 'OK/networking/products';
import { useLinkProductMediaAPI, useUnlinkProductMediaAPI } from 'OK/networking/products/hooks';
import { searchQuery } from 'OK/networking/search';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import UIContext from 'OK/util/context/ui';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import { formatDate, formatOkid, formatPoints } from 'OK/util/formatting';
import authorisationForResource from 'OK/util/functions/authorisationForResource';
import isAuthorised, { isAuthorisedViaShare } from 'OK/util/functions/isAuthorised';
import { OBJECT_PERMISSIONS } from 'OK/util/functions/permissions';
import { getFirstMomentOfDate, getLastMomentOfDate } from 'OK/util/functions/date';
import simpleMIMEType from 'OK/util/functions/simpleMIMEType';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisation from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';
import usePermission from 'OK/util/hooks/usePermission';
import Slider from 'OK/components/slider';
import ColumnCard from 'OK/components/archiveCard/ColumnCard';

export default function ProductPage(props) {
  /* Variables */

  const { apiResult, item, OKID, product } = props;
  const productId = product.id;
  const productMediaAssets = product.productMediaAssetList;
  const { t, tHTML, locale } = useI18n();
  const [, , currentUser] = useAuthentication(() => false);
  const router = useRouter();
  const apolloClient = useApolloClient();
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const organisation = currentUser?.organisationList.find((o) => o.id === activeOrganisationId);
  const authorisationLevel = useAuthorisation(product);
  const itemMode = typeof item === 'object';
  const showInPopup = useSelector((state) => state.app.showInPopup);
  const theme = useContext(ThemeContext);
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const isManager =
    authorisationForResource(currentUser, activeOrganisationId, organisation) == AUTHORISATION_LEVEL.MANAGER ||
    authorisationForResource(currentUser, activeOrganisationId, organisation) == AUTHORISATION_LEVEL.OWNER;

  const isEditor =
    isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER) ||
    (isAuthorisedViaShare(product.assetAccessPermission, AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION) &&
      isManager);
  const hasPermissionToPostComments = usePermission(
    OBJECT_PERMISSIONS.POST_COMMENTS,
    currentUser,
    activeOrganisationId,
    product
  );
  const menuIsVisible = useSelector((state) => state.app.menuIsVisible);

  // State

  const [enableProductImageEdit, setEnableProductImageEdit] = useState(false);
  const [hasSelectedRange, setHasSelectedDateRange] = useState(true);
  const [isAlternateIdsOpen, setIsAlternateIdsOpen] = useState(false);
  const [isCatalogueSectionOpen, setIsCatalogueSectionOpen] = useState(true);
  const [isCommentsSectionOpen, setIsCommentsSectionOpen] = useState(true);
  const [isDocumentationSectionOpen, setIsDocumentationSectionOpen] = useState(true);
  const [isEditingMedia, setIsEditingMedia] = useState(false);
  const [isImageEditButtonVisible, setIsImageEditButtonVisible] = useState(itemMode ? false : true);
  // const [isNotesSectionOpen, setIsNotesSectionOpen] = useState(true);
  const [isWorkSectionOpen, setIsWorkSectionOpen] = useState(true);
  const [isWorkSectionVisible, setIsWorkSectionVisible] = useState(isEditor ? true : false);
  const [mediaGalleryError, setMediaGalleryError] = useState(null);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [mediaSearchInputFocused, setMediaSearchInputFocused] = useState(false);
  const [mediaSearchString, setMediaSearchString] = useState('');
  // const [notesAccordionEdit, setNotesAccordionEdit] = useState(false);
  const [publishStatus, setPublishStatus] = useState(itemMode ? item.product.publishStatus : product.publishStatus);
  const [shareToastMessage, setShareToastMessage] = useState(null);
  const [showAddWorkPopup, setShowAddWorkPopup] = useState(false);
  const [showAlternateIdsEditor, setShowAlternateIdsEditor] = useState(false);
  const [showOKIDPopup, setShowOKIDPopup] = useState(false);
  const [showProductInfoEditPopup, setShowProductInfoEditPopup] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [publishError, setPublishError] = useState(null);

  // STREAM RECORDINGS SEARCH
  
  const formatDateForLatestStreamRecordings = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Default values
  const filterEndDateDefault = getLastMomentOfDate(new Date()); // Today
  const filterStartDateDefault = getFirstMomentOfDate(subtractFromDate(filterEndDateDefault, { weeks: 1 })); // 1 week ago
  const [filterStartDate, setFilterStartDate] = useState(filterStartDateDefault);
  const [filterEndDate, setFilterEndDate] = useState(filterEndDateDefault);
  const [renderDatePicker, setRenderDatePicker] = useState(false);
  const [latestStreamingRecordingsAPI, latestStreamingRecordingsResult] = useLazyQuery(latestStreamingRecordings);

  const closeDatePicker = useCallback(() => {
    setRenderDatePicker(false);
  }, []);

  const showDatePicker = useCallback(() => {
    setRenderDatePicker(true);
  }, []);

  

  const onChangeDateRangeFilter = useCallback(
    (startDate, endDate) => {
      if (datesAreEqual(startDate, endDate)) {
        setHasSelectedDateRange(false);
      } else {
        setHasSelectedDateRange(true);
      }
      setFilterStartDate(startDate);
      setFilterEndDate(endDate);
      closeDatePicker();
    },
    [closeDatePicker]
  );
  
  const latestStreamingRecordingsDebounced = useMemo(
    () =>
      debounce((startDate, endDate) => {
        latestStreamingRecordingsAPI({
          variables: {
            itemOKID: itemMode ? item?.OKID : null,
            startDate,
            endDate,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [itemMode, item]
  );

  const formattedStartDate = formatDate(filterStartDate, locale, { style: 'short' });
  let formattedEndDate;
  if (hasSelectedRange) {
    formattedEndDate = formatDate(filterEndDate ?? filterStartDate, locale, { style: 'short' });
  }

  useEffect(() => {
    const formattedFilterStartDate = formatDate(Date.parse(filterStartDate), locale, {style: 'inputValue'});
    const formattedFilterEndDate = formatDate(Date.parse(filterEndDate ?? filterStartDate), locale, {style: 'inputValue'});

    if (itemMode) {
      latestStreamingRecordingsDebounced(formattedFilterStartDate, formattedFilterEndDate);
    }
  }, [filterStartDate, filterEndDate, itemMode]);

  // AI CAMERA CONFIGURATION
  const [isCamera, setIsCamera] = useState(itemMode ? item?.itemConfiguration : false);
  const [currentAICameraConfig, setCurrentAICameraConfig] = useState(itemMode ? item?.itemConfiguration?.aiDetectionConfiguration?.aiDetectionConfigSettings : null);
  const [currentDetectionTypeConfiguration, setCurrentDetectionTypeConfiguration] = useState(currentAICameraConfig ? currentAICameraConfig[0] : {});
  const [currentAICameraConfidenceLevel, setCurrentAICameraConfidenceLevel] = useState(currentAICameraConfig ? currentAICameraConfig[0].configurationSettings.CONFIDENCE_LEVEL : 80);
  const [currentSelectedDetectionType, setCurrentSelectedDetectionType] = useState("NO_HELMET_DETECTION");
  const [ isOn, setIsOn ] = useState(currentAICameraConfig ? currentAICameraConfig[0]?.configurationSettings?.ON_OFF === "ON" ? true : false : false);
  const [ isPriority, setIsPriority ] = useState(currentAICameraConfig ? currentAICameraConfig[0]?.configurationSettings?.PRIORITY === "HIGH" ? true : false : false);
  const [ isRunning, setIsRunning ] = useState(currentAICameraConfig ? currentAICameraConfig[0].configurationSettings.STATUS : "STOPPED");
  const [ zoneX1, setZoneX1 ] = useState(currentAICameraConfig && currentSelectedDetectionType === "WORK_AT_HEIGHT_DETECTION" ? currentAICameraConfig[4]?.configurationSettings?.ZONE_X1 : currentSelectedDetectionType === "DANGER_ZONE_DETECTION" ? currentAICameraConfig[5]?.configurationSettings?.ZONE_X1 : 0);
  const [zoneY1, setZoneY1] = useState(currentAICameraConfig && currentSelectedDetectionType === "WORK_AT_HEIGHT_DETECTION" ? currentAICameraConfig[4]?.configurationSettings?.ZONE_Y1 : currentSelectedDetectionType === "DANGER_ZONE_DETECTION" ? currentAICameraConfig[5]?.configurationSettings?.ZONE_Y1 : 0);
  const [zoneX2, setZoneX2] = useState(currentAICameraConfig && (currentSelectedDetectionType === "WORK_AT_HEIGHT_DETECTION" || currentSelectedDetectionType === "DANGER_ZONE_DETECTION") ? currentAICameraConfig[4]?.configurationSettings?.ZONE_X2 : currentSelectedDetectionType === "DANGER_ZONE_DETECTION" ? currentAICameraConfig[5]?.configurationSettings?.ZONE_X2 : 0);
  const [zoneY2, setZoneY2] = useState(currentAICameraConfig && currentSelectedDetectionType === "WORK_AT_HEIGHT_DETECTION" ? currentAICameraConfig[4]?.configurationSettings?.ZONE_Y2 : currentSelectedDetectionType === "DANGER_ZONE_DETECTION" ? currentAICameraConfig[5]?.configurationSettings?.ZONE_Y2 : 0);

  const [setAIConfigurationSettingsAPI] = useMutation(UpdateAIConfigurationSettingsMutation);

  const saveAIConfigurationSettingsForItem = useMemo(
    () =>
      debounce((itemId, aiConfigurationSettings) => {
        setAIConfigurationSettingsAPI({
          variables: {
            itemId: itemId,
            aiDetectionConfigSettings: aiConfigurationSettings,
          },
        }).catch(() => {
            console.error("Unable to save AI configuration settings for item");
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [setAIConfigurationSettingsAPI, t]
  );

  function processString(str) {
  // Replace underscores with spaces
  str = str.replace(/_/g, ' ');

  // Lowercase every letter for each word
  str = str.toLowerCase();

  // Uppercase the first letter for each word
  str = str.replace(/\b\w/g, function(match) {
    return match.toUpperCase();
  });

  return str;
}

  const detectionTypes = currentAICameraConfig ? currentAICameraConfig.map((config) => ({"label": processString(config.detectionType), "value": config.detectionType})) : [];
  
  const handleDetectionTypeChange = (e) => {
    setCurrentSelectedDetectionType(e);
    currentAICameraConfig.forEach((config) => {
      if (config.detectionType === e) {
        setCurrentDetectionTypeConfiguration(config);
        setCurrentAICameraConfidenceLevel(config.configurationSettings.CONFIDENCE_LEVEL);
        setIsOn(config.configurationSettings.ON_OFF === "ON" ? true : false);
        setIsPriority(config.configurationSettings.PRIORITY === "HIGH" ? true : false);
        setIsRunning(config.configurationSettings.STATUS);
        if (config.detectionType === "WORK_AT_HEIGHT_DETECTION" || config.detectionType === "DANGER_ZONE_DETECTION") {
          setZoneX1(config.configurationSettings.ZONE_X1);
          setZoneY1(config.configurationSettings.ZONE_Y1);
          setZoneX2(config.configurationSettings.ZONE_X2);
          setZoneY2(config.configurationSettings.ZONE_Y2);
        }
      }
    });
  }

  // Function to handle toggle for on/off
  const handleOnOffToggle = () => {
    setCurrentAICameraConfig((prevConfig) => {
      return prevConfig.map((config) => {
      if (config.detectionType === currentSelectedDetectionType) {
        return {
          ...config,
          configurationSettings: {
            ...config.configurationSettings,
            ON_OFF: isOn ? 'OFF' : 'ON',
            STATUS: isOn ? 'STOPPED' : 'RUNNING',
          },
        };
      }
      return config;
      });
    });

    setCurrentDetectionTypeConfiguration((prevConfig) => {
      const newConfig = {
        ...prevConfig,
        configurationSettings: {
          ...prevConfig.configurationSettings,
          ON_OFF: isOn ? 'OFF' : 'ON',
          STATUS: isOn ? 'STOPPED' : 'RUNNING',
       }
      };

      return newConfig;
    });

    setIsOn(!isOn);
    if (isOn) {
      setIsRunning("STOPPED");
    }
    else {
      setIsRunning("RUNNING");
    }
  }

  // Function to handle toggle for priority
  const handlePriorityToggle = () => {
   setCurrentAICameraConfig((prevConfig) => {
    return prevConfig.map((config) => {
      if (config.detectionType === currentSelectedDetectionType) {
        return {
          ...config,
          configurationSettings: {
            ...config.configurationSettings,
            PRIORITY: isPriority ? 'LOW' : 'HIGH',
          },
        };
      }
      return config;
    });
   });
   
   setCurrentDetectionTypeConfiguration((prevConfig) => {
    const newConfig = {
      ...prevConfig,
      configurationSettings: {
        ...prevConfig.configurationSettings,
        PRIORITY: isPriority ? 'LOW' : 'HIGH',
      }
    };
    return newConfig;
   });
   
   setIsPriority(!isPriority);
  }

  // Function to handle confidence level change
  const handleConfidenceLevelChange = (newConfidenceLevel) => {
    setCurrentAICameraConfig((prevConfig) => {
    return prevConfig.map((config) => {
      if (config.detectionType === currentSelectedDetectionType) {
        return {
          ...config,
          configurationSettings: {
            ...config.configurationSettings,
            CONFIDENCE_LEVEL: newConfidenceLevel.toString(),
          },
        };
      }
      return config;
    });
   });
   
   setCurrentDetectionTypeConfiguration((prevConfig) => {
    const newConfig = {
      ...prevConfig,
      configurationSettings: {
        ...prevConfig.configurationSettings,
        CONFIDENCE_LEVEL: newConfidenceLevel.toString(),
      }
    };
    return newConfig;
   });

   setCurrentAICameraConfidenceLevel(newConfidenceLevel);
  }

const handleZoneX1Change = (newZoneX1) => {
    newZoneX1 = newZoneX1.target.value;
    setCurrentAICameraConfig((prevConfig) => {
    return prevConfig.map((config) => {
      if (config.detectionType === currentSelectedDetectionType) {
        return {
          ...config,
          configurationSettings: {
            ...config.configurationSettings,
            ZONE_X1: newZoneX1.toString(),
          },
        };
      }
      return config;
    });
   });
   
   setCurrentDetectionTypeConfiguration((prevConfig) => {
    const newConfig = {
      ...prevConfig,
      configurationSettings: {
        ...prevConfig.configurationSettings,
            ZONE_X1: newZoneX1.toString(),

      }
    };
    return newConfig;
   });

    setZoneX1(newZoneX1);
  }

  const handleZoneX2Change = (newZoneX2) => {
    newZoneX2 = newZoneX2.target.value;
    setCurrentAICameraConfig((prevConfig) => {
    return prevConfig.map((config) => {
      if (config.detectionType === currentSelectedDetectionType) {
        return {
          ...config,
          configurationSettings: {
            ...config.configurationSettings,
            ZONE_X2: newZoneX2.toString(),
          },
        };
      }
      return config;
    });
   });
   
   setCurrentDetectionTypeConfiguration((prevConfig) => {
    const newConfig = {
      ...prevConfig,
      configurationSettings: {
        ...prevConfig.configurationSettings,
            ZONE_X2: newZoneX2.toString(),

      }
    };
    return newConfig;
   });

    setZoneX2(newZoneX2);
  }

  const handleZoneY1Change = (newZoneY1) => {
     newZoneY1 = newZoneY1.target.value;
    
    setCurrentAICameraConfig((prevConfig) => {
    return prevConfig.map((config) => {
      if (config.detectionType === currentSelectedDetectionType) {
        return {
          ...config,
          configurationSettings: {
            ...config.configurationSettings,
            ZONE_Y1: newZoneY1.toString(),
          },
        };
      }
      return config;
    });
   });
   
   setCurrentDetectionTypeConfiguration((prevConfig) => {
    const newConfig = {
      ...prevConfig,
      configurationSettings: {
        ...prevConfig.configurationSettings,
            ZONE_Y1: newZoneY1.toString(),

      }
    };
    return newConfig;
   });

    setZoneY1(newZoneY1);
  }

  const handleZoneY2Change = (newZoneY2) => {
    newZoneY2 = newZoneY2.target.value;
    
    setCurrentAICameraConfig((prevConfig) => {
    return prevConfig.map((config) => {
      if (config.detectionType === currentSelectedDetectionType) {
        return {
          ...config,
          configurationSettings: {
            ...config.configurationSettings,
            ZONE_Y2: newZoneY2.toString(),
          },
        };
      }
      return config;
    });
   });
   
   setCurrentDetectionTypeConfiguration((prevConfig) => {
    const newConfig = {
      ...prevConfig,
      configurationSettings: {
        ...prevConfig.configurationSettings,
            ZONE_Y2: newZoneY2.toString(),

      }
    };
    return newConfig;
   });

    setZoneY2(newZoneY2);
  }

  useEffect(() => {
    if (itemMode) {
      saveAIConfigurationSettingsForItem(item?.id, currentDetectionTypeConfiguration);
    }
  }, [currentDetectionTypeConfiguration]);
  

  // Refs

  const basicsSectionRef = useRef();
  const catalogueSectionRef = useRef();
  const commentsSectionRef = useRef();
  const detailsSectionRef = useRef();
  const documentationSectionRef = useRef();
  //const notesSectionRef = useRef();
  const workSectionRef = useRef();
  const mediaGalleryRef = useRef();

  /* API */

  const organisationData = useQuery(getOrganisationByOKIDQuery, {
    variables: {
      OKID: product.organisation.OKID,
    },
  });

  const organisationLogo = organisationData?.data?.organisation?.logoImageMediaAsset?.logoImageURL;

  const [publishProductAPI] = useMutation(publishProductMutation);
  const [unpublishProductAPI] = useMutation(unpublishProductMutation);

  const productCacheId = `${ProductModel.GRAPHQL_TYPE}:${productId}`;

  const linkMediaAPI = useLinkProductMediaAPI();
  const [reorderProductMediaBackwardAPI] = useMutation(reorderProductMediaBackwardMutation, {
    onError: () => {
      setMediaGalleryError(t('ERROR_GENERIC'));
    },
    update: (cache, result) => {
      if (result.data?.productMedia) {
        const { productMedia } = result.data;
        cache.modify({
          id: productCacheId,
          fields: {
            productMediaAssetList: (currentList) => {
              const oldOrder = productMedia.order + 1;
              const originalIndexBeforeMovedMedia = oldOrder - 2;
              const updatedList = [...currentList];
              const [beforeProductMedia, movedProductMedia] = updatedList.splice(originalIndexBeforeMovedMedia, 2);
              const updatedMovedProductMedia = {
                ...movedProductMedia,
                order: productMedia.order,
              };
              const updatedBeforeProductMedia = {
                ...beforeProductMedia,
                order: oldOrder,
              };
              updatedList.splice(originalIndexBeforeMovedMedia, 0, updatedMovedProductMedia, updatedBeforeProductMedia);
              return updatedList;
            },
          },
        });
      } else {
        okerror('Reorder media backward API did not return product media.');
        setMediaGalleryError(t('ERROR_GENERIC'));
      }
    },
  });
  const [reorderProductMediaForwardAPI] = useMutation(reorderProductMediaForwardMutation, {
    onError: () => {
      setMediaGalleryError(t('ERROR_GENERIC'));
    },
    update: (cache, result) => {
      if (result.data?.productMedia) {
        const { productMedia } = result.data;
        cache.modify({
          id: productCacheId,
          fields: {
            productMediaAssetList: (currentList) => {
              const oldOrder = productMedia.order - 1;
              const originalIndexOfMovedMedia = oldOrder - 1;
              const updatedList = [...currentList];
              const [movedProductMedia, afterProductMedia] = updatedList.splice(originalIndexOfMovedMedia, 2);
              const updatedMovedProductMedia = {
                ...movedProductMedia,
                order: productMedia.order,
              };
              const updatedAfterProductMedia = {
                ...afterProductMedia,
                order: oldOrder,
              };
              updatedList.splice(originalIndexOfMovedMedia, 0, updatedAfterProductMedia, updatedMovedProductMedia);
              return updatedList;
            },
          },
        });
      } else {
        okerror('Reorder media forward API did not return product media.');
        setMediaGalleryError(t('ERROR_GENERIC'));
      }
    },
  });
  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);
  const unlinkProductMediaAPI = useUnlinkProductMediaAPI({
    onError: () => {
      setMediaGalleryError(t('ERROR_GENERIC'));
    },
  });

  /* Methods */

  const shareProduct = useCallback(() => {
    setShowShareModal(true);
  }, []);

  const createProductAudioMediaAsset = useCallback(
    async (audioFile) => {
      setMediaGalleryError(null);
      setMediaLoading(true);
      try {
        const response = await createProductAudioMediaAssetRequest(productId, audioFile);
        if (response.success) {
          saveNewProductMediaAssetToCache(response.responseData);
          setTimeout(() => {
            mediaGalleryRef.current.scrollToIndex(productMediaAssets.length);
          }, 50);
        } else {
          setMediaGalleryError(t('ERROR_GENERIC'));
        }
      } catch (e) {
        okerror('Error adding audio to product.', e);
        setMediaGalleryError(t('ERROR_GENERIC'));
      } finally {
        setMediaLoading(false);
      }
    },
    [productId, productMediaAssets.length, saveNewProductMediaAssetToCache, t]
  );

  const createProductImageMediaAsset = useCallback(
    async (imageFile, originalImageFile) => {
      setMediaGalleryError(null);
      setMediaLoading(true);
      try {
        const response = await createProductImageMediaAssetRequest(productId, imageFile, originalImageFile);
        if (response.success) {
          saveNewProductMediaAssetToCache(response.responseData);
          setTimeout(() => {
            mediaGalleryRef.current.scrollToIndex(productMediaAssets.length);
          }, 50);
        } else {
          setMediaGalleryError(t('ERROR_GENERIC'));
        }
      } catch (e) {
        okerror('Error adding image to product.', e);
        setMediaGalleryError(t('ERROR_GENERIC'));
      } finally {
        setMediaLoading(false);
      }
    },
    [productId, productMediaAssets.length, saveNewProductMediaAssetToCache, t]
  );

  const createProductVideoMediaAsset = useCallback(
    async (videoFile) => {
      setMediaGalleryError(null);
      setMediaLoading(true);
      try {
        const response = await createProductVideoMediaAssetRequest(productId, videoFile);
        if (response.success) {
          saveNewProductMediaAssetToCache(response.responseData);
          setTimeout(() => {
            mediaGalleryRef.current.scrollToIndex(productMediaAssets.length);
          }, 50);
        } else {
          setMediaGalleryError(t('ERROR_GENERIC'));
        }
      } catch (e) {
        okerror('Error adding video to product.', e);
        setMediaGalleryError(t('ERROR_GENERIC'));
      } finally {
        setMediaLoading(false);
      }
    },
    [productId, productMediaAssets.length, saveNewProductMediaAssetToCache, t]
  );

  const reorderMediaBackward = useCallback(
    (mediaAssetId) => {
      setMediaGalleryError(null);

      // Optimistic response
      const reorderedProductMedia = productMediaAssets.find((pma) => pma.mediaAsset.id === mediaAssetId);
      const optimisticResponse = {
        productMedia: {
          ...reorderedProductMedia,
          order: reorderedProductMedia.order - 1,
        },
      };

      // Call API
      reorderProductMediaBackwardAPI({
        variables: {
          mediaAssetId,
          productId,
        },
        optimisticResponse,
      });
    },
    [productId, productMediaAssets, reorderProductMediaBackwardAPI]
  );

  const reorderMediaForward = useCallback(
    (mediaAssetId) => {
      setMediaGalleryError(null);

      // Optimistic response
      const reorderedProductMedia = productMediaAssets.find((pma) => pma.mediaAsset.id === mediaAssetId);
      const optimisticResponse = {
        productMedia: {
          ...reorderedProductMedia,
          order: reorderedProductMedia.order + 1,
        },
      };

      // Call API
      reorderProductMediaForwardAPI({
        variables: {
          mediaAssetId,
          productId,
        },
        optimisticResponse,
      });
    },
    [productId, productMediaAssets, reorderProductMediaForwardAPI]
  );

  const saveNewProductMediaAssetToCache = useCallback(
    (newProductMediaAsset) => {
      // Write MediaAsset to cache
      const mediaAsset = {
        ...newProductMediaAsset.mediaAsset,
        REFID: newProductMediaAsset.mediaAsset.refid,
        __typename: BaseMediaAssetModel.GRAPHQL_TYPE,
      };
      switch (mediaAsset.mediaType) {
        case MediaAssetModel.MEDIA_TYPE.IMAGE:
          mediaAsset.imageData.__typename = ImageMediaAssetModel.GRAPHQL_TYPE;
          break;
        case MediaAssetModel.MEDIA_TYPE.VIDEO:
          mediaAsset.videoData.__typename = VideoMediaAssetModel.GRAPHQL_TYPE;
          break;
        case MediaAssetModel.MEDIA_TYPE.AUDIO:
          mediaAsset.audioData.__typename = AudioMediaAssetModel.GRAPHQL_TYPE;
          break;
      }
      const mediaAssetRef = apolloClient.cache.writeFragment({
        id: apolloClient.cache.identify(mediaAsset),
        fragment: BaseMediaAssetModel.fragment,
        fragmentName: BaseMediaAssetModel.fragmentName,
        data: mediaAsset,
      });

      // Write ProductMediaAsset to cache
      const productMediaAsset = {
        ...newProductMediaAsset,
        mediaAsset: mediaAssetRef,
        __typename: ProductMediaAssetModel.GRAPHQL_TYPE,
      };
      apolloClient.cache.modify({
        id: productCacheId,
        fields: {
          productMediaAssetList: (currentList) => {
            return [...currentList, productMediaAsset];
          },
        },
      });
    },
    [apolloClient.cache, productCacheId]
  );

  const searchMediaDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchAPI({
          variables: {
            ignoreIdListByDataType: [
              { dataType: 'MEDIA_ASSET', ignoreIdList: productMediaAssets.map((pma) => pma.mediaAsset.id) },
            ],
            searchPaginationDataByDataType: [
              { dataType: 'MEDIA_ASSET', searchPaginationData: { skip: 0, pageSize: 4 } },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [productMediaAssets, searchAPI]
  );

  const unlinkMedia = useCallback(
    (mediaAssetId) => {
      setMediaGalleryError(null);

      // Call API
      unlinkProductMediaAPI(product, mediaAssetId);
    },
    [product, unlinkProductMediaAPI]
  );

  const updateImageMediaAsset = useCallback(
    async (mediaAssetId, file) => {
      setMediaGalleryError(null);
      setMediaLoading(true);
      try {
        const response = await updateImageMediaAssetRequest(mediaAssetId, file);
        if (response.success) {
          // Update MediaAsset in cache
          const mediaAsset = {
            ...response.responseData,
            imageData: {
              ...response.responseData.imageData,
              __typename: ImageMediaAssetModel.GRAPHQL_TYPE,
            },
            REFID: response.responseData.refid,
            __typename: MediaAssetModel.GRAPHQL_TYPE,
          };
          apolloClient.cache.writeFragment({
            id: apolloClient.cache.identify(mediaAsset),
            fragment: MediaAssetModel.fragment,
            fragmentName: MediaAssetModel.fragmentName,
            data: mediaAsset,
          });
        } else {
          setMediaGalleryError(t('ERROR_GENERIC'));
        }
      } catch (e) {
        okerror('Error updating image media asset.', e);
        setMediaGalleryError(t('ERROR_GENERIC'));
      } finally {
        setMediaLoading(false);
      }
    },
    [apolloClient.cache, t]
  );

  const onClickChangeShareStatus = useCallback(
    (publishStatus) => {
      setPublishError(null);
      if (publishStatus === PUBLISH_STATUS.PUBLISHED) {
        const optimisticResponse = {
          product: {
            id: product.id,
            publishStatus: PUBLISH_STATUS.PUBLISHED,
            __typename: ProductModel.GRAPHQL_TYPE,
          },
        };
        publishProductAPI({
          variables: {
            productId: product.id,
          },
          optimisticResponse,
        }).catch(() => {
          setPublishError(t('ERROR_GENERIC'));
        });
        setPublishStatus(PUBLISH_STATUS.PUBLISHED);
      } else {
        const optimisticResponse = {
          product: {
            id: product.id,
            publishStatus: PUBLISH_STATUS.UNPUBLISHED,
            __typename: ProductModel.GRAPHQL_TYPE,
          },
        };
        unpublishProductAPI({
          variables: {
            productId: product.id,
          },
          optimisticResponse,
        }).catch(() => {
          setPublishError(t('ERROR_GENERIC'));
        });
        setPublishStatus(PUBLISH_STATUS.UNPUBLISHED);
      }
    },
    [product?.id, publishProductAPI, t, unpublishProductAPI]
  );

  // Event handlers

  const onClickLinkMedia = useCallback(
    (mediaAssetId) => {
      setMediaLoading(true);
      setMediaGalleryError(null);
      setMediaSearchString('');
      linkMediaAPI(product.id, mediaAssetId)
        .then(() => {
          setTimeout(() => {
            mediaGalleryRef.current.scrollToIndex(productMediaAssets.length);
          }, 50);
        })
        .catch(() => {
          setMediaGalleryError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setMediaLoading(false);
        });
    },
    [linkMediaAPI, product.id, productMediaAssets.length, t]
  );

  const onClickUnlinkMedia = useCallback(
    (mediaAssetId) => {
      unlinkMedia(mediaAssetId);
    },
    [unlinkMedia]
  );

  const onMediaFileUpdated = useCallback(
    (mediaAssetId, file) => {
      const productMediaAsset = productMediaAssets.find((pma) => pma.mediaAsset.id === mediaAssetId);
      switch (productMediaAsset.mediaAsset.mediaType) {
        case MediaAssetModel.MEDIA_TYPE.IMAGE:
          updateImageMediaAsset(mediaAssetId, file);
          break;
        default:
          okwarn('Unsupported media updated with type:', productMediaAsset.mediaAsset.mediaType);
      }
    },
    [productMediaAssets, updateImageMediaAsset]
  );

  const onMediaReordered = useCallback(
    (mediaAssetId, direction) => {
      if (direction === 'left') {
        reorderMediaBackward(mediaAssetId);
      } else {
        reorderMediaForward(mediaAssetId);
      }
    },
    [reorderMediaBackward, reorderMediaForward]
  );

  const onNewMediaSelected = useCallback(
    (files, originalFiles) => {
      const file = files[0];
      const originalFile = originalFiles[0];
      const mediaType = simpleMIMEType(file.type).toUpperCase();
      switch (mediaType) {
        case MediaAssetModel.MEDIA_TYPE.AUDIO:
          createProductAudioMediaAsset(file);
          break;
        case MediaAssetModel.MEDIA_TYPE.IMAGE:
          createProductImageMediaAsset(file, originalFile);
          break;
        case MediaAssetModel.MEDIA_TYPE.VIDEO:
          createProductVideoMediaAsset(file);
          break;
        default:
          okwarn('Unsupported media added with type:', mediaType);
      }
    },
    [createProductAudioMediaAsset, createProductImageMediaAsset, createProductVideoMediaAsset]
  );

  const onSearchMedia = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setMediaSearchString(newSearchString);

      if (newSearchString.length > 1) {
        searchMediaDebounced(newSearchString);
      }
    },
    [searchMediaDebounced]
  );
  /* Effects */

  // Hide share toast after 5 seconds
  useEffect(() => {
    if (shareToastMessage) {
      setTimeout(() => {
        setShareToastMessage(null);
      }, 5000);
    }
  }, [shareToastMessage]);

  useEffect(() => {
    if (isEditor) {
      setIsWorkSectionVisible(true);
    } else {
      setIsWorkSectionVisible(false);
    }
  }, [authorisationLevel, isEditor, workSectionRef]);

  /* Render */

  const pointsObject = OKID ? item : product;
  const conformityGrade = formatPoints(product?.conformityPoint ?? 0);
  const productName = product ? ProductModel.localizedNameForProduct(product, locale) : '';
  const productMedia = product?.productMediaAssetList ?? [];
  const productFiles = product?.productDocumentAssetList ?? [];
  // const publishedProductNotes =
  //   product?.productNoteAssetList?.filter((pn) => pn.publishStatus === PUBLISH_STATUS.PUBLISHED) ?? [];
  // const allNotes = product?.productNoteAssetList ?? [];
  const reliabilityGrade = formatPoints(pointsObject?.reliabilityPointForPublishedLogs ?? 0);

  const floatingButton = (
    <Button icon={ICONS.PLUS.name} onClick={() => setShowAddWorkPopup(true)} tint='creation'>
      {useDesktopLayout ? t('SIMPLIFIED_WORKFLOW_POP_UP_ADD_WORK') : null}
    </Button>
  );

  let mediaSearchResults;
  if (mediaSearchInputFocused && mediaSearchString.length > 1) {
    mediaSearchResults = searchAPIResult.data?.search?.resultList
      ?.map((r) => {
        const mediaAsset = r.mediaAssetData;

        if (!mediaAsset) {
          return null;
        }

        // Icon
        let icon;
        let iconBackgroundImageUrl;
        switch (mediaAsset.mediaType) {
          case MediaAssetModel.MEDIA_TYPE.VIDEO:
            icon = <Icon className={styles.videoIcon} name={ICONS.PLAY.name} />;
            break;
          case MediaAssetModel.MEDIA_TYPE.AUDIO:
            icon = ICONS.AUDIO.name;
            break;
          default:
            icon = ICONS.IMAGE.name;
            iconBackgroundImageUrl = mediaAsset.imageData.imageURL;
            break;
        }

        // Highlight
        let highlightString;
        if (r.dataHighlights?.length) {
          const keys = r.dataHighlights[0].split('.');
          let data = mediaAsset;
          for (let keyIndex in keys) {
            const key = keys[keyIndex];
            data = data[key];
          }
          highlightString = data;
        }

        return {
          action: (
            <Button className={styles.searchSuggestionAction} icon={ICONS.LINK.name} linkStyle>
              {t('LINK')}
            </Button>
          ),
          highlightString,
          icon,
          iconBackgroundImageUrl,
          key: mediaAsset.id,
          title: t(mediaAsset.mediaType),
          subtitle: mediaAsset.REFID,
        };
      })
      .filter((r) => r !== null);
  }

  const pageMenu = (
    <PageMenu
      assetName={productName}
      showBackButton
      title={
        <>
          <div className={styles.submenuButtonContainer}>
            <Button className={styles.submenuButton} icon={ICONS.SHARE.name} linkStyle onClick={shareProduct}>
              {useDesktopLayout && t('SHARE')}
            </Button>
            <FadeInOutTransition in={!!shareToastMessage}>
              <Toast className={styles.shareToast}>{shareToastMessage}</Toast>
            </FadeInOutTransition>
          </div>
        </>
      }
      titlePosition='right'
    />
  );

  return (
    <>
      <PageTitle>
        {/* eslint-disable indent */}
        {OKID
          ? t('PAGE_TITLE_ITEM', {
              data: {
                productName,
                okid: OKID,
              },
            })
          : t('PAGE_TITLE_PRODUCT', { data: { productName } })}
        {/* eslint-enable indent */}
      </PageTitle>
      {showInPopup ? pageMenu : <Menu>{pageMenu}</Menu>}
      {/* eslint-disable indent*/}
      <PageMenu
        className={`${styles.sectionsSubmenuContainer} ${
          menuIsVisible && !showInPopup && styles.sectionsSubmenuContainerMenu
        }`}
        sections={
          isEditor
            ? [
                {
                  ref: documentationSectionRef,
                  title: t('PRODUCT_SECTION_DOCUMENTATION'),
                  icon: ICONS.DOCUMENTATION.name,
                },
                // { ref: notesSectionRef, title: t('NOTES'), icon: ICONS.NOTES.name },
                { ref: workSectionRef, title: t('WORK'), icon: ICONS.COG.name },
                { ref: catalogueSectionRef, title: t('CATALOGUE'), icon: ICONS.TWO_CIRCLES.name },
                { ref: commentsSectionRef, title: t('COMMENTS_SECTION_HEADER'), icon: ICONS.COMMENTS.name },
              ]
            : [
                {
                  ref: documentationSectionRef,
                  title: t('PRODUCT_SECTION_DOCUMENTATION'),
                  icon: ICONS.DOCUMENTATION.name,
                },
                // { ref: notesSectionRef, title: t('NOTES'), icon: ICONS.NOTES.name },
                { ref: catalogueSectionRef, title: t('CATALOGUE'), icon: ICONS.TWO_CIRCLES.name },
                { ref: commentsSectionRef, title: t('COMMENTS_SECTION_HEADER'), icon: ICONS.COMMENTS.name },
              ]
        }
        smartTabs
        tabClassName={styles.sectionsTab}
        tabsClassName={styles.sectionsSubmenu}
      />
      {/* eslint-enable indent*/}
      <ContentLayout
        className={`${styles.outerSection} ${showInPopup && styles.outerSectionPopup}`}
        pageContent
      ></ContentLayout>
      <ContentLayout
        className={`${styles.section} ${styles.basicsSection}`}
        contentClassName={styles.basicsSectionContent}
        ref={basicsSectionRef}
        pageContent
      >
        <UIContext.Provider value='card'>
          {product && (
            <>
              <div style={{ position: 'relative' }}>
                {isEditor && enableProductImageEdit && (
                  <Button
                    className={styles.mediaGalleryEditDone}
                    linkStyle
                    icon={<Icon name={ICONS.TICK.name} />}
                    onClick={() => {
                      setIsImageEditButtonVisible(true);
                      setEnableProductImageEdit(false);
                      setIsEditingMedia(false);
                    }}
                  >
                    {t('DONE')}
                  </Button>
                )}
                <div className={styles.productImageContainer}>
                  {isEditor && isImageEditButtonVisible && (
                    <div
                      className={
                        productMedia.length > 0
                          ? styles.productImageEditButtonContainer
                          : styles.productImageEditButtonContainerPlaceholder
                      }
                    >
                      <Button
                        className={styles.productImageEditButton}
                        icon={'/icons/admin_edit.svg'}
                        onClick={() => {
                          setIsImageEditButtonVisible(false);
                          setEnableProductImageEdit(true);
                        }}
                        tint='secondary'
                      />
                    </div>
                  )}

                  <MediaGallery
                    allowAddingMediaTypes={['photo', 'video', 'audio']}
                    className={styles.mediaGallery}
                    previewsClassName={styles.mediaGalleryPreview}
                    enableEditing={enableProductImageEdit}
                    media={productMedia.map((pm) => pm.mediaAsset)}
                    mediaLoading={mediaLoading}
                    showMediaDetails={enableProductImageEdit}
                    onChangeEditingStatus={setIsEditingMedia}
                    onClickUnlink={onClickUnlinkMedia}
                    onMediaFileUpdated={onMediaFileUpdated}
                    onMediaReordered={onMediaReordered}
                    onNewMediaSelected={onNewMediaSelected}
                    ref={mediaGalleryRef}
                    warning={mediaGalleryError}
                  />
                  {productMedia.length > 0 || isEditingMedia ? null : (
                    <div className={styles.mediaGalleryPlaceholder}>
                      <img
                        alt='No product media.'
                        className={styles.mediaGalleryPlaceholderImage}
                        src={`/img/empty_media_${theme.name}.svg`}
                      />
                      {mediaLoading && <LoadingSpinner className={styles.mediaLoadingSpinner} />}
                    </div>
                  )}
                  {enableProductImageEdit && (
                    <div className={styles.searchInputContainer}>
                      <SearchInput
                        className={styles.searchInput}
                        disabled={mediaLoading || isEditingMedia}
                        loading={searchAPIResult.loading}
                        onBlur={() => setMediaSearchInputFocused(false)}
                        onChange={onSearchMedia}
                        onFocus={() => setMediaSearchInputFocused(true)}
                        placeholder={t('MEDIA_GALLERY_SEARCH_PLACEHOLDER')}
                        value={mediaSearchString}
                      />
                      <Button
                        className={styles.searchNewButton}
                        disabled={mediaLoading || isEditingMedia}
                        linkStyle
                        onClick={() => mediaGalleryRef.current?.openMediaPicker()}
                        tint='creation'
                      >
                        {t('NEW')}
                      </Button>
                      <SearchSuggestions
                        className={styles.searchResults}
                        highlightTerm={mediaSearchString}
                        onSuggestionClick={onClickLinkMedia}
                        showNoResultsMessage={
                          mediaSearchInputFocused && mediaSearchString.length > 1 && mediaSearchResults?.length === 0
                        }
                        showMoreResultsMessage={
                          mediaSearchInputFocused &&
                          searchAPIResult.data?.search?.searchPaginationResultDataByDataType?.MEDIA_ASSET
                            ?.totalResults > mediaSearchResults?.length
                        }
                        subtitleClassName={styles.searchResultOKID}
                        suggestions={mediaSearchResults}
                      />
                      <Notice className={styles.supportedFileTypesNotice}>
                        <p>{tHTML('MEDIA_SUPPORTED_IMAGE_FORMATS')}</p>
                        <p>{tHTML('MEDIA_SUPPORTED_VIDEO_FORMATS')}</p>
                        <p>{tHTML('MEDIA_SUPPORTED_AUDIO_FORMATS')}</p>
                        <Text tint='notification'>
                          {t('MEDIA_FILESIZE_LIMITS', {
                            data: {
                              imageLimit: appConfig.fileSizeLimitsMB.image,
                              videoLimit: appConfig.fileSizeLimitsMB.video,
                              audioLimit: appConfig.fileSizeLimitsMB.audio,
                            },
                          })}
                        </Text>
                      </Notice>
                    </div>
                  )}
                </div>
              </div>
              <CardLayout
                fixedWidth={false}
                className={styles.productDetailsCard}
                innerClassName={styles.productDetailsCardContent}
              >
                {isEditor && isImageEditButtonVisible && (
                  <UIContext.Provider value='card'>
                    <div className={styles.productInfoEditButtonContainer}>
                      <Button
                        className={styles.productInfoEditButton}
                        icon={'/icons/admin_edit.svg'}
                        onClick={() => {
                          setShowProductInfoEditPopup(true);
                        }}
                        tint='secondary'
                      />
                    </div>
                  </UIContext.Provider>
                )}
                <div className={styles.productOrganisation}>
                  <div className={styles.productNameContainer}>
                    <h3 className={styles.productName}>{productName}</h3>
                  </div>
                  <LinkToOrganisation organisation={product.organisation}>
                    <img
                      className={styles.organisationLogo}
                      src={organisationLogo ? organisationLogo : `/img/empty_media_${theme.name}.svg`}
                      alt={'The organisation logo'}
                    />
                    <Text className={styles.organisationName} size='md'>
                      {product.organisation.name}
                      <Tag className={styles.organisationTag} size='md'>
                        {product.organisation?.addressList[0]?.countryCode}
                      </Tag>
                    </Text>
                  </LinkToOrganisation>
                  <div className={styles.productInformation}>
                    <div className={styles.productInformationRow}>
                      <Text bold>{itemMode ? 'OK ID ™' : t('OK_REFERENCE_CODE')}</Text>
                      <div>
                        <Text className={styles.textFieldButton}>
                          {itemMode ? formatOkid(item.OKID) : product.REFID}
                          {itemMode ? (
                            <Button
                              className={styles.qrButton}
                              icon='/icons/qr_scan.svg'
                              linkStyle
                              onClick={() => {
                                setShowOKIDPopup(true);
                              }}
                            >
                              {t('SHOW_QR')}
                            </Button>
                          ) : null}
                        </Text>
                      </div>
                    </div>

                    <>
                      <div className={styles.productInformationRow}>
                        <Text className={styles.itemInformationFieldText} bold>
                          {t('ALTERNATE_IDS')}
                        </Text>
                        <Text className={styles.itemInformationFieldText}>{item?.itemAlternateIdList?.length}</Text>
                      </div>
                      <div className={styles.productInformationRow} style={{ marginBottom: 20 }}>
                        {isEditor ? (
                          <div>
                            {showAlternateIdsEditor ? (
                              <Button
                                className={styles.qrButton}
                                icon={ICONS.TICK.name}
                                linkStyle
                                onClick={() => {
                                  setShowAlternateIdsEditor(false);
                                }}
                              >
                                {t('DONE')}
                              </Button>
                            ) : (
                              <Button
                                className={styles.qrButton}
                                icon={'/icons/admin_edit.svg'}
                                iconStyle={styles.editIcon}
                                linkStyle
                                onClick={() => {
                                  setShowAlternateIdsEditor(true);
                                }}
                              >
                                {t('EDIT')}
                              </Button>
                            )}
                            {showAlternateIdsEditor && (
                              <AlternateIDsEditor
                                asset={itemMode ? item : product}
                                assetType={itemMode ? 'item' : 'product'}
                              />
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {(itemMode && item?.itemAlternateIdList) || (!itemMode && product?.alternateIds) ? (
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Button
                              className={styles.qrButton}
                              icon={
                                <Icon
                                  className={`${styles.toggleIcon} ${isAlternateIdsOpen ? '' : styles.closed}`}
                                  name={ICONS.CARET.name}
                                />
                              }
                              linkStyle
                              onClick={() => {
                                setIsAlternateIdsOpen(!isAlternateIdsOpen);
                              }}
                            >
                              {t('SHOW')}
                            </Button>
                            {isAlternateIdsOpen && (
                              <div className={styles.alternateIdsContainer}>
                                {itemMode
                                  ? Object.keys(item?.itemAlternateIdList).map((key) => (
                                    <Text className={styles.alternateIdText} key={key}>
                                      {key}
                                    </Text>
                                  ))
                                  : Object.keys(product?.alternateIds)?.map((altId) => (
                                    <Text className={styles.alternateIdText} key={altId}>
                                      {altId}
                                    </Text>
                                  ))}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </>

                    <div className={styles.productInformationRow}>
                      <Text className={`${itemMode ? styles.itemInformationFieldText : ''}`} bold>
                        SKU
                      </Text>
                      <Text className={`${itemMode ? styles.itemInformationFieldText : ''}`}>{product?.SKUID}</Text>
                    </div>
                    {itemMode ? (
                      <div
                        className={styles.productInformationRow}
                        style={{ justifyContent: 'flex-end', marginBottom: 20 }}
                      >
                        <Button
                          className={styles.qrButton}
                          linkStyle
                          onClick={() => router.push(`/archive/product/${product.REFID}`)}
                        >
                          {t('VIEW_PRODUCT')}
                        </Button>
                      </div>
                    ) : null}
                    <div className={styles.productInformationRow}>
                      <Text className={styles.productInformationRowFieldWithIcon} bold>
                        {t('RELIABILITY')}
                        <Icon className={styles.productInformationFieldIcon} name={ICONS.RELIABILITY_GRADE.name} />
                      </Text>
                      <Text>{reliabilityGrade}</Text>
                    </div>
                    <div className={styles.productInformationRow}>
                      <Text className={styles.productInformationRowFieldWithIcon} bold>
                        {t('CONFORMITY')}
                        <Icon className={styles.productInformationFieldIcon} name={ICONS.DOCUMENTATION_GRADE.name} />
                      </Text>
                      <Text className={styles.productInformationRowFieldWithIcon}>{conformityGrade}</Text>
                    </div>
                    {itemMode ? (
                      isEditor ? (
                        <div className={styles.productInformationRow}>
                          <Text className={styles.productInformationRowFieldWithIconLast} bold>
                            {t('VISIBILITY')}
                          </Text>
                          <Text className={styles.productInformationRowFieldWithIconLast}>
                            {product.publishStatus === PUBLISH_STATUS.PUBLISHED ? t('PUBLIC') : t('PRIVATE')}
                          </Text>
                        </div>
                      ) : null
                    ) : (
                      <div className={styles.productInformationRow}>
                        <Text className={styles.productInformationRowFieldWithIconLast} bold>
                          {t('VISIBILITY')}
                        </Text>
                        <Text className={styles.productInformationRowFieldWithIconLast}>
                          {product.publishStatus === PUBLISH_STATUS.PUBLISHED ? t('PUBLIC') : t('PRIVATE')}
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              </CardLayout>
            </>
          )}
        </UIContext.Provider>
      </ContentLayout>
      <ContentLayout className={styles.detailsContainer} ref={detailsSectionRef} pageContent>
        {isCamera && <div className={styles.SectionCard} style={{ paddingTop: "20px" }}>
           <Accordion
            className={styles.accordion}
            headerClassName={styles.headerAccordion}
            hideSection
            open={true}
            title={
              <div className={styles.header}>
                <Icon className={styles.icon} name={ICONS.COG.name} height={24} width={24} />
                &nbsp;&nbsp;
                Settings
              </div>
            }
          >
            <div style={{padding: "20px",}}>
              <div style={{display: "flex", justifyContent: "space-between", flexDirection: "column", gap: 40}}>
            <Select className={styles.alarmSelector} disableEmptySelection options={detectionTypes} value={currentSelectedDetectionType} onChange={handleDetectionTypeChange}/>
                <div style={{ display: "flex", gap: 20 }}>
                  <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    Off/On
                    <Toggle checked={isOn} onChange={handleOnOffToggle} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    Low/High Priority
                    <Toggle checked={isPriority} onChange={handlePriorityToggle}/>
                  </div>
                </div>
              <div>
                Status: {isRunning} 
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
               Confidence
                <Slider showMinAndMaxValues showValueInTooltip min={50} max={100} value={currentAICameraConfidenceLevel} step={1} onChange={handleConfidenceLevelChange}/>
              </div>
                {(currentSelectedDetectionType === "WORK_AT_HEIGHT_DETECTION" || currentSelectedDetectionType === "DANGER_ZONE_DETECTION") && <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                  Set Zone
                  <div style={{ display: "flex", gap: 20 }}>
                    <div style={{ display: "flex", flexDirection: 'column', gap: 10 }}>
                      <span>X1</span>
                      <Input
                        onChange={handleZoneX1Change}
                        value={zoneX1}
                        className={styles.zoneInput}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: 'column', gap: 10 }}>
                      <span>Y1</span>
                      <Input
                        onChange={handleZoneY1Change}
                        value={zoneY1}
                        className={styles.zoneInput}
                      />  
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: 20 }}>
                    <div style={{ display: "flex", flexDirection: 'column', gap: 10 }}>
                      <span>X2</span>
                      <Input
                        onChange={handleZoneX2Change}
                        value={zoneX2}
                        className={styles.zoneInput}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: 'column', gap: 10 }}>
                      <span>Y2</span>
                      <Input
                        onChange={handleZoneY2Change}
                        value={zoneY2}
                        className={styles.zoneInput}
                      />
                    </div>
                  </div>                  
                </div>} 
              </div>
            </div>
          </Accordion>
        </div>
        }
        {(latestStreamingRecordingsResult.data?.latestStreamingRecordings?.length !== undefined) && <div className={styles.SectionCard} style={{ paddingTop: "20px" }}>
           <Accordion
            className={styles.accordion}
            headerClassName={styles.headerAccordion}
            hideSection
            open={true}
            title={
              <div className={styles.header}>
                <Icon className={styles.icon} name={ICONS.COG.name} height={24} width={24} />
                &nbsp;&nbsp;
               Latest Streaming Recordings 
              </div>
            }
          >
            <div style={{paddingLeft: "30px",paddingRight: "30px",}}>
              <div className={styles.selectedDateRangeContainer}>
                <Text bold className={styles.selectedDateRange}>
                  {hasSelectedRange ? `${formattedStartDate} - ${formattedEndDate}` : formattedStartDate}
                </Text>
                <Button linkStyle onClick={showDatePicker}>
                  {t('CHANGE')}
                </Button>
              </div>
              <Carousel className={styles.carouselContainer} innerClassName={styles.carousel} fadeOutSides={useDesktopLayout}>
                {latestStreamingRecordingsResult?.data !== undefined ? latestStreamingRecordingsResult?.data?.latestStreamingRecordings?.map((d) => {
                  return (
                    <Slide className={styles.fileSlide} key={d.id}>
                      <ColumnCard asset={d} assetType='alert' downloadButtonLink={d.mediaURL} fixedColumn/>
                    </Slide>
                  );
                }) : null}
              </Carousel>
            </div>
          </Accordion>
        </div>
        }
        {renderDatePicker && (
        <DatePickerPopup
          dismiss={closeDatePicker}
          initialStartDate={filterStartDate}
          initialEndDate={filterEndDate}
          onSave={onChangeDateRangeFilter}
        />
      )}
        <div className={styles.sectionCard}>
          <Accordion
            className={styles.accordion}
            ref={documentationSectionRef}
            headerClassName={styles.headerAccordion}
            hideSection
            onChangeOpen={setIsDocumentationSectionOpen}
            open={isDocumentationSectionOpen}
            title={
              <div className={styles.header}>
                <Icon className={styles.icon} name={ICONS.DOCUMENTATION.name} height={24} width={24} />
                &nbsp;&nbsp;
                {t('PRODUCT_SECTION_DOCUMENTATION')}
              </div>
            }
          >
            <ProductPageDocumentationSection
              apiResult={apiResult}
              isEditor={isEditor}
              product={product}
              productDocuments={product.productDocumentAssetList ?? []}
              productFiles={productFiles}
              productId={product.id}
            />
          </Accordion>
        </div>
        {/* <div className={styles.sectionCard}>
          <Accordion
            className={`${styles.accordion} ${notesAccordionEdit ? styles.highlightedAccordion : ''}`}
            edit={notesAccordionEdit}
            ref={notesSectionRef}
            headerClassName={styles.headerAccordion}
            hideSection
            onChangeEdit={setNotesAccordionEdit}
            onChangeOpen={setIsNotesSectionOpen}
            open={isNotesSectionOpen}
            showEdit={itemMode ? false : isEditor ? true : false}
            title={
              <div className={styles.header}>
                <Icon className={styles.icon} name={ICONS.NOTES.name} height={24} width={24} />
                &nbsp;&nbsp;
                {t('Notes')}
              </div>
            }
          >
            {notesAccordionEdit && isEditor ? (
              <EditProductPageNotesSection
                productNotes={allNotes}
                productDocuments={product.productDocumentAssetList ?? []}
                productId={product.id}
              />
            ) : publishedProductNotes?.length ? (
              <ContentLayout className={styles.sectionCardContent} pageContent>
                <Carousel
                  className={styles.notesCarousel}
                  innerClassName={styles.carousel}
                  fadeOutSides={useDesktopLayout}
                >
                  {publishedProductNotes.map((pn) => (
                    <Slide key={pn.noteAsset.id} className={styles.noteSlide}>
                      <RemarkLorealCard className={styles.note} fixedWidth={false} remark={pn.noteAsset} />
                    </Slide>
                  ))}
                </Carousel>
              </ContentLayout>
            ) : (
              <ContentLayout className={styles.sectionCardContent} pageContent>
                <Text>{t('LOG_ARCHIVE_PAGE_NO_NOTES')}</Text>
              </ContentLayout>
            )}
          </Accordion>
        </div> */}
        {isWorkSectionVisible ? (
          <div className={styles.sectionCard}>
            <Accordion
              className={styles.accordion}
              ref={workSectionRef}
              headerClassName={styles.headerAccordion}
              hideSection
              onChangeOpen={setIsWorkSectionOpen}
              open={isWorkSectionOpen}
              title={
                <div className={styles.header}>
                  <Icon className={styles.icon} name={ICONS.COG.name} height={24} width={24} />
                  &nbsp;&nbsp;
                  {t('WORK')}
                </div>
              }
            >
              <ProductWorkSection editMode={isEditor} itemMode={itemMode} product={product} />
            </Accordion>
          </div>
        ) : null}
        <div className={styles.sectionCard}>
          <Accordion
            className={styles.accordion}
            ref={catalogueSectionRef}
            headerClassName={styles.headerAccordion}
            hideSection
            onChangeOpen={setIsCatalogueSectionOpen}
            open={isCatalogueSectionOpen}
            title={
              <div className={styles.header}>
                <Icon className={styles.icon} name={ICONS.TWO_CIRCLES.name} height={24} width={24} />
                &nbsp;&nbsp;
                {t('CATALOGUE')}
              </div>
            }
          >
            <ProductPageCatalogueSection isEditor={isEditor} product={product} />
          </Accordion>
        </div>
        <div className={styles.sectionCard} style={{ paddingBottom: 30 }}>
          <Accordion
            className={styles.accordion}
            ref={commentsSectionRef}
            headerClassName={styles.headerAccordion}
            hideSection
            onChangeOpen={setIsCommentsSectionOpen}
            open={isCommentsSectionOpen}
            title={
              <div className={styles.header}>
                <Icon className={styles.icon} name={ICONS.COMMENTS.name} height={24} width={24} />
                &nbsp;&nbsp;
                {t('COMMENTS_SECTION_HEADER')}
              </div>
            }
          >
            <ContentLayout pageContent>
              {hasPermissionToPostComments && (
                <ContentLayout
                  className={styles.commentSection}
                  contentClassName={styles.commentSectionContent}
                  pageContent
                >
                  <TextLayout style={{ padding: 0 }}>
                    <CommentsController
                      assetType='PRODUCT'
                      assetId={product?.id}
                      assetOrganisationId={product?.organisationId}
                    />
                  </TextLayout>
                </ContentLayout>
              )}
            </ContentLayout>
          </Accordion>
        </div>
      </ContentLayout>
      {showProductInfoEditPopup && (
        <ProductInfoEditPopup
          dismiss={() => setShowProductInfoEditPopup(false)}
          product={product}
          SKUID={product.SKUID}
        />
      )}
      {showShareModal && (
        <ShareAssetPopup
          dismiss={() => setShowShareModal(false)}
          asset={product}
          assetId={itemMode ? item.id : product.id}
          assetType={itemMode ? 'ITEM' : 'PRODUCT'}
          assetPublishStatus={publishStatus}
          sharedToList={itemMode ? item.product.assetAccessPermissionList : product.assetAccessPermissionList}
          publishError={publishError}
          onClickChangeShareStatus={onClickChangeShareStatus}
        />
      )}
      {showOKIDPopup && (
        <ShareOKIDPopup
          dismiss={() => setShowOKIDPopup(false)}
          instructions={t('SHARE_OKID_INSTRUCTIONS')}
          name={t('ITEM')}
          OKID={item?.OKID}
          profilePhotoUrl={productMedia[0]?.mediaAsset?.imageData?.imageURL}
        />
      )}
      {itemMode && (
        <FloatingButtonsContainer className={showInPopup && styles.floatingButtons}>
          {floatingButton}
        </FloatingButtonsContainer>
      )}
      {showAddWorkPopup && (
        <AddWorkPopup
          dismiss={() => setShowAddWorkPopup(false)}
          data={itemMode ? item : product}
          preSelectedData={itemMode ? item : product}
          preSelectedDataType={itemMode ? 'ITEM' : 'PRODUCT'}
        />
      )}
    </>
  );
}

ProductPage.propTypes = {
  item: PropTypes.object,
  OKID: PropTypes.string,
  apiResult: PropTypes.any,
  product: PropTypes.object.isRequired,
};
