import NextImage from "next/legacy/image";
import PropTypes from 'prop-types';
import qrcode from 'qrcode';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import appConfig from 'OK/config/app';
import ItemModel from 'OK/models/item';
import OrganisationModel from 'OK/models/organisation';
import SiteModel from 'OK/models/site';
import UserModel from 'OK/models/user';
import { themeLight, themeDark } from 'OK/styles/theme';
import ThemeContext from 'OK/util/context/theme';
import { formatOkid } from 'OK/util/formatting';
import { isCoworkerOKID, isOrganisationOKID } from 'OK/util/functions/OKID';
import share, {
  copyTextToClipboard,
  copyToClipboard,
  hasNativeSharing,
  localDownload,
  SHARE_ACTION,
  SHARE_TYPE,
} from 'OK/util/functions/share';
import useI18n from 'OK/util/hooks/useI18n';

export default function ShareOKIDPopup(props) {
  /* Variables */

  const { dismiss, instructions, name, OKID, profilePhotoUrl } = props;
  const theme = useContext(ThemeContext);
  const { t } = useI18n();

  const OKIDUrl = useMemo(() => {
    if (isCoworkerOKID(OKID)) {
      return `https://${appConfig.domain}${UserModel.link(OKID)}`;
    }

    if (isOrganisationOKID(OKID)) {
      return `https://${appConfig.domain}${OrganisationModel.link(OKID)}`;
    }

    if (name == 'Site') {
      return `https://${appConfig.domain}${SiteModel.link(OKID)}`;
    }

    return `https://${appConfig.domain}${ItemModel.link(OKID)}`;
  }, [OKID, name]);

  // State

  const [QRCodeSrc, setQRCodeSrc] = useState(null);
  const [shareToastMessage, setShareToastMessage] = useState(null);
  const [renderShareToast, setRenderShareToast] = useState(false);

  const showShareToast = useCallback((message) => {
    setShareToastMessage(message);
    setRenderShareToast(true);
  }, []);

  /* Methods */

  const saveOKID = useCallback(() => {
    localDownload(QRCodeSrc, `${OKID}.png`);
  }, [OKID, QRCodeSrc]);

  const shareOKID = useCallback(async () => {
    let shared = false;
    if (hasNativeSharing()) {
      // Try natively sharing the image first.
      try {
        const blob = await (await fetch(QRCodeSrc)).blob();
        const file = new File([blob], `${OKID}.png`, { type: 'image/png' });
        const shareAction = await share([file], SHARE_TYPE.FILES);
        shared = shareAction === SHARE_ACTION.NATIVE;
      } catch (e) {
        okerror('Error sharing OKID image natively.', e);
      }
    }

    if (!shared) {
      // Try copying the OKID image to clipboard.
      try {
        const blob = await (await fetch(QRCodeSrc)).blob();
        await copyToClipboard(blob, 'image/png');
        shared = true;
        showShareToast(t('COPIED_QR_IMAGE'));
      } catch (e) {
        okerror('Error copying OKID image to clipboard.', e);
      }
    }

    if (!shared) {
      // Copy the OKID string to the clipboard.
      try {
        await copyTextToClipboard(OKID);
        showShareToast(t('COPIED_OKID'));
      } catch (e) {
        okerror('Error copying OKID text to clipboard.', e);
      }
    }
  }, [OKID, QRCodeSrc, showShareToast, t]);

  /* Effects */

  // Generate OKID QR code
  useEffect(() => {
    const color = {};
    if (theme.name === 'light') {
      color.light = themeLight.colors.contentBackground;
      color.dark = themeLight.colors.text;
    } else {
      color.light = themeDark.colors.contentBackground;
      color.dark = themeDark.colors.text;
    }
    qrcode.toDataURL(OKIDUrl, { color, errorCorrectionLevel: 'H', margin: 0, width: 275 }).then((QRCodeDataUrl) => {
      setQRCodeSrc(QRCodeDataUrl);
    });
  }, [OKID, OKIDUrl, theme.name]);

  // Hide share toast after 3 seconds
  useEffect(() => {
    if (renderShareToast) {
      setTimeout(() => {
        setRenderShareToast(false);
      }, 3000);
    }
  }, [renderShareToast]);

  // Reset share toast message after hidden
  useEffect(() => {
    if (!renderShareToast) {
      setTimeout(() => {
        setShareToastMessage(null);
      }, 500); // Reset after transition finishes
    }
  }, [renderShareToast]);

  /* Render */

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.content}>
        <div className={styles.menu}>
          <Button icon={ICONS.DOWNLOAD.name} linkStyle onClick={saveOKID}>
            {t('SAVE')}
          </Button>
          <div className={styles.shareButtonContainer}>
            <Button className={styles.shareButton} icon={ICONS.SHARE.name} linkStyle onClick={shareOKID}>
              {t('SHARE')}
            </Button>
            <FadeInOutTransition in={renderShareToast}>
              <Toast className={styles.shareToast}>{shareToastMessage}</Toast>
            </FadeInOutTransition>
          </div>
          <PopupCloseButton linkStyle>{t('CLOSE')}</PopupCloseButton>
        </div>
        <div className={styles.titleSection}>
          <div>
            <Text className={styles.title}>{name}</Text>
            <h2>{formatOkid(OKID)}</h2>
          </div>
          <div className={styles.profilePhotoContainer}>
            <NextImage layout='fill' src={profilePhotoUrl ?? `/img/empty_media_${theme.name}.svg`} />
          </div>
        </div>
        {QRCodeSrc ? (
          <>
            <img alt='OKID' className={styles.QRCode} draggable='false' src={QRCodeSrc} />
            <Text bold className={styles.scanInstructions} size='xs'>
              {instructions}
            </Text>
          </>
        ) : (
          <Icon className={styles.loadingSpinner} height={40} name={ICONS.SPINNER.name} width={40} />
        )}
      </PopupContent>
      <PopupButtonsGroup>
        <PopupCloseButton tint='navigation'>{t('CLOSE')}</PopupCloseButton>
      </PopupButtonsGroup>
    </Popup>
  );
}

ShareOKIDPopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
  instructions: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  OKID: PropTypes.string.isRequired,
  profilePhotoUrl: PropTypes.string,
};
