import { gql } from '@apollo/client';

import BaseDocumentModel from '../document/base';

export default class InspectionLogDocumentAssetModel {
  static GRAPHQL_TYPE = 'InspectionLogDocumentAsset';

  static fragmentName = 'InspectionLogDocumentAsset_Fragment';
  static fragment = gql`
    fragment ${InspectionLogDocumentAssetModel.fragmentName} on ${InspectionLogDocumentAssetModel.GRAPHQL_TYPE} {
      documentAsset {
        ...${BaseDocumentModel.fragmentName}
      }
      inspectionLogAssetSource
    }
    ${BaseDocumentModel.fragment}
  `;
}
