import debounce from 'lodash/debounce';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';

import styles from './styles.module.scss';

import MainMenu from 'OK/components/menus/main';
import { updateMiscAppStateAction } from 'OK/state/app/actions';
import { APP_TOP_MARKER_ELEMENT_ID, DEBOUNCE_TIMING_MS_INSTANT } from 'OK/util/constants';

const SCROLL_DISTANCE_TO_TRIGGER_CHANGE = 35;

class AppMenus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollYPositionBeforeScroll: 0,
      topMenuYPosition: 0,
    };

    this.appMenusRef = createRef();
  }

  componentDidMount() {
    this.enableScrollListener();
    this.setupScrolledToTopObserver();
    this.observeElementWithId(APP_TOP_MARKER_ELEMENT_ID);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.listenToScrolling !== this.props.listenToScrolling) {
      if (this.props.listenToScrolling) {
        this.enableScrollListener();
      } else {
        this.disableScrollListener();
      }
    }

    if (prevProps.useMobileLayout !== this.props.useMobileLayout) {
      this.props.dispatch(updateMiscAppStateAction({ tabsAreVisible: this.props.useMobileLayout }));
    }
  }

  componentWillUnmount() {
    this.disableScrollListener();
    this.unObserveElementWithId(APP_TOP_MARKER_ELEMENT_ID);
  }

  disableScrollListener = () => {
    document.body.onscroll = undefined;
  };

  enableScrollListener = () => {
    // Keep track of scroll position and show/hide the menu accordingly
    document.body.onscroll = this.trackTopMenuYPosition;
  };

  observeElementWithId(id) {
    const element = document.getElementById(id);
    if (element) {
      this.scrolledToTopObserver.observe(element);
    }
  }

  setupScrolledToTopObserver() {
    this.scrolledToTopObserver = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        const isScrolledToTop = entry.isIntersecting;
        if (this.props.isScrolledToTop !== isScrolledToTop) {
          this.props.dispatch(updateMiscAppStateAction({ isScrolledToTop }));
        }
      },
      { threshold: [0, 1] }
    );
  }

  trackTopMenuYPosition = () => {
    const { dispatch } = this.props;

    // When scrolling down, hide menu
    const menuHeight = this.appMenusRef.current.clientHeight;
    const { scrollYPositionBeforeScroll, topMenuYPosition } = this.state;

    this.trackTopMenuYPositionBeforeScroll();
    const currentScrollYPosition = document.documentElement.scrollTop;
    const scrollDistance = currentScrollYPosition - scrollYPositionBeforeScroll;
    if (
      currentScrollYPosition < menuHeight ||
      (scrollDistance < -SCROLL_DISTANCE_TO_TRIGGER_CHANGE && topMenuYPosition !== 0)
    ) {
      // When scrolling up or at the top of the page, show menu
      this.setState({ topMenuYPosition: 0 });
      if (!this.props.menuIsVisible) {
        dispatch(updateMiscAppStateAction({ menuIsVisible: true }));
      }
    } else if (scrollDistance > SCROLL_DISTANCE_TO_TRIGGER_CHANGE && topMenuYPosition === 0) {
      // Move up menu height + 2 to account for shadow
      this.setState({ topMenuYPosition: -menuHeight - 2 });
      if (this.props.menuIsVisible) {
        dispatch(updateMiscAppStateAction({ menuIsVisible: false }));
      }
    }
  };

  trackTopMenuYPositionBeforeScroll = debounce(
    () => {
      this.setState({ scrollYPositionBeforeScroll: document.documentElement.scrollTop });
    },
    DEBOUNCE_TIMING_MS_INSTANT,
    { leading: true, trailing: false }
  );

  unObserveElementWithId(id) {
    const element = document.getElementById(id);
    if (element) {
      this.scrolledToTopObserver.unobserve(element);
    }
  }

  render() {
    const { showMenuBackground, router } = this.props;
    const { topMenuYPosition } = this.state;

    let menuBackgroundClassNames = styles.menuBackgroundDiv;
    if (showMenuBackground) {
      menuBackgroundClassNames = `${menuBackgroundClassNames} ${styles.show}`;
    }

    return (
      <>
        <div>
          <div id='app_menus' ref={this.appMenusRef} style={{ top: topMenuYPosition }}>
            <div className={menuBackgroundClassNames} />
          </div>
          {typeof window !== 'undefined' && (
            <MainMenu
              contentTopPadding={router?.pathname == '/' && false}
              disableBackgroundAtTop={router?.pathname == '/' && true}
              menuStyleWithoutBackground={router?.pathname == '/' && 'dark'}
            />
          )}
        </div>
        <style jsx global>{`
          #app_menus {
            left: 0;
            pointer-events: none;
            position: fixed;
            right: 0;
            top: 0;
            transition: 250ms top;
            z-index: 100;
          }
        `}</style>
      </>
    );
  }
}

export default connect((state) => {
  const { isScrolledToTop, listenToScrolling, menuIsVisible, showMenuBackground, useMobileLayout } = state.app;
  return {
    isScrolledToTop,
    listenToScrolling,
    menuIsVisible,
    showMenuBackground,
    useMobileLayout,
  };
})(withRouter(AppMenus));

AppMenus.propTypes = {
  dispatch: PropTypes.func,
  isScrolledToTop: PropTypes.bool,
  listenToScrolling: PropTypes.bool,
  menuIsVisible: PropTypes.bool,
  showMenuBackground: PropTypes.bool,
  router: PropTypes.any,
  useMobileLayout: PropTypes.bool,
};
