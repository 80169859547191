/**
 * Accepts a number and restricts it's value to a certain range.
 * @param {Number} number - The number you want to process.
 * @param {Number} rangeLimit1 - One limit of the desired value range.
 * @param {Number} rangeLimit2 - The other limit of the desired value range.
 */
export default function limitNumberToRange(number, rangeLimit1, rangeLimit2) {
  let minValue, maxValue;
  if (rangeLimit1 < rangeLimit2) {
    minValue = rangeLimit1;
    maxValue = rangeLimit2;
  } else if (rangeLimit2 < rangeLimit1) {
    minValue = rangeLimit2;
    maxValue = rangeLimit1;
  } else {
    // Range is 0, so simply return it
    return rangeLimit1;
  }

  return Math.max(Math.min(number, maxValue), minValue);
}
