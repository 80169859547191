import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Select from 'OK/components/select';
import Text from 'OK/components/text';
import InspectionLogFindingModel from 'OK/models/inspectionLogFinding';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * Select element for tagging with an inspection test result.
 *
 * @param {object} props
 * @param {(newValue: string) => {}} props.onChange Event handler when the selected tag changes.
 * @param {string} props.value Current selection.
 */
export default function InspectionTestTagSelect(props) {
  const { onChange, value, ...otherProps } = props;
  const { t } = useI18n();

  return (
    <Select
      displayLabelRender={(selectedOption) => {
        let textTint;
        switch (selectedOption?.value) {
          case InspectionLogFindingModel.TAG.PASSED:
            textTint = 'creation';
            break;
          case InspectionLogFindingModel.TAG.FAILED:
            textTint = 'alert';
            break;
          case InspectionLogFindingModel.TAG.FIXED:
            textTint = 'navigation';
            break;
          case InspectionLogFindingModel.TAG.TOLERATED:
            textTint = 'notification';
            break;
          case '':
          case null:
          case undefined:
            return t('TAG');
          default:
            return selectedOption?.label;
        }

        return (
          <Text className={styles.selectLabel} tint={textTint}>
            {selectedOption.label}
          </Text>
        );
      }}
      noValueLabel={t('DONT_CHANGE_TAGS')}
      onChange={onChange}
      options={[
        { label: t('REMOVE_TAGS'), value: 'untagged' },
        { label: t('OK'), value: InspectionLogFindingModel.TAG.PASSED },
        { label: t('FAILED'), value: InspectionLogFindingModel.TAG.FAILED },
        { label: t('FIXED'), value: InspectionLogFindingModel.TAG.FIXED },
        { label: t('TOLERATED'), value: InspectionLogFindingModel.TAG.TOLERATED },
      ]}
      value={value}
      {...otherProps}
    />
  );
}

InspectionTestTagSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
