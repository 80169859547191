import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import Text from 'OK/components/text';
import { formatNumber } from 'OK/util/formatting';

/**
 * @typedef {Object} ArchiveCardPricingProps
 * @prop {string} [currency] The currency of the price. Defaults to `'USD'`.
 * @prop {number} price The price to display.
 * @prop {number} pricePerItem The price per item / unit.
 * @prop {boolean|string|number} stock Stock status. `true` will display `'In stock'`. `'low'` will display `'Low stock'`.
 * `false` or `0` will display `'No stock'`. Other values will be rendered as numbers.
 */

/**
 * Pricing for products / items.
 *
 * @param {ArchiveCardPricingProps} props
 */
export default function ArchiveCardPricing(props) {
  const { className, currency = 'USD', price, pricePerItem, stock, ...otherProps } = props;

  /* Methods */

  const getPriceUnits = (amount) => {
    const dollars = parseInt(amount, 10);
    const cents = (amount - dollars).toFixed(2).slice(1);

    return [dollars, cents];
  };

  /* Render */

  // Stock
  const renderStock = stock !== undefined;
  let stockLabel;
  let stockTint;
  if (renderStock) {
    switch (stock) {
      case true:
        stockLabel = 'In stock';
        stockTint = 'creation';
        break;
      case 'low':
        stockLabel = 'Low stock';
        stockTint = 'notification';
        break;
      case false:
      case 0:
        stockLabel = 'No stock';
        stockTint = 'alert';
        break;
      default:
        stockLabel = `${formatNumber(stock)}`;
        stockTint = 'creation';
        break;
    }
  }

  // Price
  const [dollars, cents] = getPriceUnits(price);
  const [unitDollars, unitCents] = getPriceUnits(pricePerItem);

  return (
    <div className={className} {...otherProps}>
      <div className={styles.priceRow}>
        <Text bold className={styles.currency} size='xs'>
          {!price && 'No price'}
          {price && `${currency} from`}
        </Text>
        <Text className={styles.price} tint='alert'>
          <span className={styles.dollars}>{formatNumber(dollars)}</span>
          <span className={styles.cents}>{cents}</span>
        </Text>
      </div>
      <div className={styles.stockRow}>
        {renderStock && (
          <Text bold className={styles.stock} size='xs' tint={stockTint}>
            <Icon inline name={ICONS.STOCK.name} height={12} width={12} /> {stockLabel}
          </Text>
        )}
        {pricePerItem && (
          <Text bold className={styles.itemPrice} size='xs'>
            {formatNumber(unitDollars)}
            {unitCents}/ea
          </Text>
        )}
      </div>
    </div>
  );
}

ArchiveCardPricing.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  price: PropTypes.number,
  pricePerItem: PropTypes.number,
  stock: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
};
