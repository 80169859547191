import { useApolloClient, useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import Document from 'OK/components/document';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import TextLayout from 'OK/components/layouts/content/text';
import MediaPicker from 'OK/components/mediaPicker';
import Notice from 'OK/components/notice';
import Progressable from 'OK/components/progressable';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Text from 'OK/components/text';
import appConfig from 'OK/config/app';
import DocumentModel from 'OK/models/document';
import OrderModel from 'OK/models/order';
import OrderDocumentModel from 'OK/models/orderDocument';
import EditFilePopup from 'OK/modules/popups/file/edit';
import { createOrderDocumentAssetRequest } from 'OK/networking/orders';
import useLinkOrderDocumentAPI, {
  usePublishOrderDocumentAPI,
  useUnlinkOrderDocumentAPI,
  useUnpublishOrderDocumentAPI,
} from 'OK/networking/orders/hooks';
import { searchQuery } from 'OK/networking/search';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import useI18n from 'OK/util/hooks/useI18n';

export default function EditOrderPageDocumentationSection(props) {
  /* Variables */

  const { order } = props;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const apolloClient = useApolloClient();
  const { t, tHTML } = useI18n();
  const orderId = order?.id;
  const orderDocuments = order?.orderDocumentAssetList;

  // Refs

  const documentsSliderRef = useRef();
  const mediaPickerRef = useRef();

  // State

  const [createDocumentError, setCreateDocumentError] = useState(null);
  const [isUploadingNewDocument, setIsUploadingNewDocument] = useState(false);
  const [newDocumentId, setNewDocumentId] = useState(null);
  const [orderDocumentsErrors, setOrderDocumentsErrors] = useState([]);
  const [searchDocumentsFocused, setSearchDocumentsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  /* API */

  const orderCacheId = `${OrderModel.GRAPHQL_TYPE}:${orderId}`;

  const linkDocumentAPI = useLinkOrderDocumentAPI();
  const publishDocumentAPI = usePublishOrderDocumentAPI();
  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);
  const unlinkDocumentAPI = useUnlinkOrderDocumentAPI();
  const unpublishDocumentAPI = useUnpublishOrderDocumentAPI();

  /* Methods */

  const addErrorForDocument = (documentId, errorKey, errorMessage) => {
    const indexOfErrorsForDocument = orderDocumentsErrors.findIndex((pde) => pde.documentId === documentId);
    let errorsForDocument;
    let updatedOrderDocumentsErrors;
    if (indexOfErrorsForDocument > -1) {
      errorsForDocument = orderDocumentsErrors[indexOfErrorsForDocument];
      errorsForDocument[errorKey] = errorMessage;
      updatedOrderDocumentsErrors = [...orderDocumentsErrors];
      updatedOrderDocumentsErrors.splice(indexOfErrorsForDocument, 1, errorsForDocument);
    } else {
      errorsForDocument = {
        documentId,
        [errorKey]: errorMessage,
      };
      updatedOrderDocumentsErrors = [...orderDocumentsErrors, errorsForDocument];
    }

    setOrderDocumentsErrors(updatedOrderDocumentsErrors);
  };

  const closeEditFilePopup = useCallback(() => {
    setNewDocumentId(null);
  }, []);

  const createDocument = async (files) => {
    if (files.length > 0) {
      const file = files[0];
      setIsUploadingNewDocument(true);
      setCreateDocumentError(null);
      try {
        const response = await createOrderDocumentAssetRequest(orderId, file, 'PRODUCT_FILE');
        if (response.success) {
          // Create DocumentAsset reference in cache
          const documentAsset = {
            ...response.responseData.documentAsset,
            REFID: response.responseData.documentAsset.refid,
            __typename: DocumentModel.GRAPHQL_TYPE,
          };
          const documentAssetRef = apolloClient.cache.writeFragment({
            id: apolloClient.cache.identify(documentAsset),
            fragment: DocumentModel.fragment,
            fragmentName: DocumentModel.fragmentName,
            data: documentAsset,
          });

          // Link new document to order in the cache
          const orderDocument = {
            ...response.responseData,
            documentAsset: documentAssetRef,
            __typename: OrderDocumentModel.GRAPHQL_TYPE,
          };
          apolloClient.cache.modify({
            id: orderCacheId,
            fields: {
              orderDocumentAssetList: (currentList = []) => {
                return [...currentList, orderDocument];
              },
            },
          });

          // Scroll to new document in slider
          setTimeout(() => {
            documentsSliderRef.current.scrollToSlideAtIndex(orderDocuments.length);
          }, 50);

          setNewDocumentId(documentAsset.id);
        } else {
          okerror('Error creating document.');
          setCreateDocumentError(t(response.error ?? 'ERROR_GENERIC'));
        }
      } catch (e) {
        okerror('Error creating document', e);
        setCreateDocumentError(t('ERROR_GENERIC'));
      } finally {
        setIsUploadingNewDocument(false);
      }
    }
  };

  const openFilePicker = () => {
    mediaPickerRef.current.open();
  };

  const publishDocument = (documentId) => {
    // Clear publish related errors
    removeErrorForDocument(documentId, 'publishError');

    // Call API
    publishDocumentAPI(order, documentId).catch(() => {
      addErrorForDocument(documentId, 'publishError', t('UPDATE_FAILED_CONNECTION'));
    });
  };

  const removeErrorForDocument = (documentId, errorKey) => {
    const indexOfErrorsForDocument = orderDocumentsErrors.findIndex((pde) => pde.documentId === documentId);
    if (indexOfErrorsForDocument > -1) {
      const errorsForDocument = {
        ...orderDocumentsErrors[indexOfErrorsForDocument],
      };
      delete errorsForDocument[errorKey];
      const updatedOrderDocumentsErrors = [...orderDocumentsErrors];
      updatedOrderDocumentsErrors.splice(indexOfErrorsForDocument, 1, errorsForDocument);
      setOrderDocumentsErrors(updatedOrderDocumentsErrors);
    }
  };

  const searchDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'DOCUMENT_ASSET',
                ignoreIdList: [...orderDocuments?.map((pd) => pd.documentAsset.id)],
              },
            ],
            searchPaginationDataByDataType: [
              {
                dataType: 'DOCUMENT_ASSET',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchAPI, orderDocuments]
  );

  const unlinkDocument = (documentId) => {
    // Clear unlink related errors
    removeErrorForDocument(documentId, 'unlinkError');

    // Call API
    unlinkDocumentAPI(order, documentId).catch(() => {
      addErrorForDocument(documentId, 'unlinkError', t('UPDATE_FAILED_CONNECTION'));
    });
  };

  const unpublishDocument = (documentId) => {
    // Clear publish related errors
    removeErrorForDocument(documentId, 'publishError');

    // Call API
    unpublishDocumentAPI(order, documentId).catch(() => {
      addErrorForDocument(documentId, 'publishError', t('UPDATE_FAILED_CONNECTION'));
    });
  };

  // Event handlers

  const onChangeDocumentPublished = (documentId, published) => {
    if (published) {
      publishDocument(documentId);
    } else {
      unpublishDocument(documentId);
    }
  };

  const onClickLinkDocument = useCallback(
    (documentAssetId) => {
      setSearchTerm('');
      linkDocumentAPI(order?.id, documentAssetId).then(() => {
        setTimeout(() => {
          documentsSliderRef.current.scrollToSlideAtIndex(orderDocuments.length);
        }, 50);
      });
    },
    [linkDocumentAPI, order?.id, orderDocuments?.length]
  );

  const onSearch = useCallback(
    (e) => {
      const searchString = e.target.value;
      setSearchTerm(searchString);

      if (searchString.length > 1) {
        searchDebounced(searchString);
      }
    },
    [searchDebounced]
  );

  /* Render */

  const documentsNotice = (
    <Notice className={styles.sectionNotice}>
      <p>{tHTML('SUPPORTED_DOCUMENT_FORMATS')}</p>
      <Text tint='notification'>
        {t('DOCUMENT_MAX_FILESIZE', { data: { number: appConfig.fileSizeLimitsMB.document } })}
      </Text>
    </Notice>
  );

  const searchSuggestions = useMemo(() => {
    if (searchDocumentsFocused && searchTerm.length > 1) {
      return (
        searchAPIResult.data?.search?.resultList?.map((r) => {
          const documentAsset = r.documentAssetData;
          return {
            icon: ICONS.DOCUMENT.name,
            key: documentAsset.id,
            title: documentAsset.documentName,
            subtitle: documentAsset.REFID,
          };
        }) ?? []
      );
    }

    return [];
  }, [searchAPIResult.data?.search?.resultList, searchDocumentsFocused, searchTerm.length]);

  return (
    <div>
      <TextLayout>
        <h4 className={styles.subSectionHeader}>
          {t('PRODUCT_SECTION_DOCUMENTATION')} <span className={styles.normalWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
        </h4>
        {useDesktopLayout && documentsNotice}
        <div className={styles.sectionIntro}>
          <p>{t('ORDER_SECTION_DOCUMENTATION_DESCRIPTION')}</p>
          <div className={styles.searchInputContainer}>
            <SearchInput
              className={styles.searchInput}
              loading={searchAPIResult.loading}
              onBlur={() => setSearchDocumentsFocused(false)}
              onChange={onSearch}
              onFocus={() => setSearchDocumentsFocused(true)}
              placeholder={t('DOCUMENTATION_SEARCH_PLACEHOLDER')}
              value={searchTerm}
            />
            <Button
              className={styles.searchNewButton}
              disabled={isUploadingNewDocument}
              loading={isUploadingNewDocument}
              linkStyle
              onClick={openFilePicker}
              tint='creation'
            >
              {t('NEW')}
            </Button>
            <SearchSuggestions
              accessoryViewRender={() => (
                <Button className={styles.searchResultButton} icon='/icons/link_blue.svg' linkStyle>
                  {t('LINK')}
                </Button>
              )}
              className={styles.searchResults}
              highlightTerm={searchTerm}
              onSuggestionClick={onClickLinkDocument}
              showNoResultsMessage={searchDocumentsFocused && searchTerm.length > 1 && searchSuggestions.length === 0}
              showMoreResultsMessage={
                searchDocumentsFocused &&
                searchAPIResult?.data?.search?.searchPaginationResultDataByDataType?.DOCUMENT_ASSET?.totalResults >
                  searchSuggestions.length
              }
              subtitleClassName={styles.searchResultOKID}
              suggestions={searchSuggestions}
            />
            <MediaPicker invisible mediaTypes={['document']} onChange={createDocument} ref={mediaPickerRef} />
          </div>
          {createDocumentError && (
            <Text className={styles.errorMessage} size='sm' tint='alert'>
              {createDocumentError}
            </Text>
          )}
        </div>
      </TextLayout>
      {!useDesktopLayout && documentsNotice}
      <Progressable
        // inProgress={uploadingDocument || linkDocumentResult.loading || unlinkDocumentResult.loading}
        style={{ minHeight: '100px' }}
      >
        <Carousel
          className={styles.carouselContainer}
          innerClassName={styles.carousel}
          fadeOutSides={useDesktopLayout}
          ref={documentsSliderRef}
        >
          {orderDocuments?.map((pd) => {
            const errorsForDocument = orderDocumentsErrors.find((pde) => pde.documentId === pd.documentAsset.id);
            return (
              <Slide className={styles.documentSlide} key={pd.documentAsset.id}>
                <Document
                  className={styles.document}
                  document={pd.documentAsset}
                  fixedWidth={false}
                  onClickUnlink={unlinkDocument}
                  onChangePublished={onChangeDocumentPublished}
                  publishError={errorsForDocument?.publishError}
                  published={pd.publishStatus === PUBLISH_STATUS.PUBLISHED}
                  unlinkButtonTitle={t('UNLINK_FROM_ORDER')}
                  unlinkError={errorsForDocument?.unlinkError}
                />
              </Slide>
            );
          })}
          <Slide className={styles.documentSlide}>
            <button className={styles.addSlideButton} disabled={isUploadingNewDocument} onClick={openFilePicker}>
              <Icon className={styles.addSlideIcon} height={80} name={ICONS.PLUS_BIG_CIRCLE.name} width={80} />{' '}
              {t('ADD_NEW_DOCUMENTATION')}
            </button>
          </Slide>
        </Carousel>
      </Progressable>
      <TextLayout>
        {orderDocuments?.length > 1 && (
          <Text bold className={styles.swipeSidewaysMessage} size='xs'>
            {t('SWIPE_SIDEWAYS_TO_SEE_OTHER_FILES')}
          </Text>
        )}
      </TextLayout>
      {typeof newDocumentId === 'string' && <EditFilePopup dismiss={closeEditFilePopup} fileId={newDocumentId} />}
    </div>
  );
}

EditOrderPageDocumentationSection.propTypes = {
  order: PropTypes.object.isRequired,
  orderDocuments: PropTypes.array.isRequired,
  orderId: PropTypes.string.isRequired,
};
