import { gql } from '@apollo/client';

import MediaAssetModel from '../mediaAsset';
import OrganisationModel from '../organisation';
import BaseProductModel from '../product/base';

import BaseDocumentModel from './base';

import { getDocumentAssetSpecListRequest, getDocumentAssetStandardListRequest } from 'OK/networking/documents';

export default class DocumentModel {
  static standards;
  static types;
  static get loadedConfig() {
    return typeof DocumentModel.standards === 'object' && typeof DocumentModel.types === 'object';
  }
  static loadingConfig = false;

  static GRAPHQL_TYPE = BaseDocumentModel.GRAPHQL_TYPE;

  static DOCUMENT_TYPE = {
    DRAWING: 'DRAWING',
    MANUAL: 'MANUAL',
    PRODUCT_SHEET: 'PRODUCT_SHEET',
  };

  static FIELD_NAMES = {
    APPLICABLE_MARKETS: 'APPLICABLE_MARKETS',
    DOCUMENT_NUMBER: 'DOCUMENT_NUMBER',
    LANGUAGES: 'LANGUAGES',
    MADE_BY_ORGANISATION: 'MADE_BY_ORGANISATION',
    STANDARDS: 'STANDARDS',
    VALID_FROM: 'VALID_FROM',
    VALID_UNTIL: 'VALID_UNTIL',
  };

  static fragmentName = 'Document_Fragment';
  static fragment = gql`
    fragment ${DocumentModel.fragmentName} on ${DocumentModel.GRAPHQL_TYPE} {
      ...${BaseDocumentModel.fragmentName}
      linkedProductList {
        ...${BaseProductModel.fragmentName}
        organisation {
          ...${OrganisationModel.fragmentName}
        }
        productDocumentAssetList {
          documentAsset {
            ...${BaseDocumentModel.fragmentName}
          }
          publishStatus
        }
        productMediaAssetList {
          mediaAsset {
            ...${MediaAssetModel.fragmentName}
          }
          order
          publishStatus
        }
      }
    }
    ${BaseDocumentModel.fragment}
    ${BaseProductModel.fragment}
    ${OrganisationModel.fragment}
    ${MediaAssetModel.fragment}
  `;

  static fragmentNameFile = 'Document_File_Fragment';
  static fragmentFile = gql`
    fragment ${DocumentModel.fragmentNameFile} on ${DocumentModel.GRAPHQL_TYPE} {
      id
      documentName
      documentURL
      lastModifiedBy
      lastModifiedDate
      metadata
      version
    }
  `;

  static downloadLink(documentAsset) {
    return documentAsset.documentURL;
  }

  static async getConfig() {
    return new Promise((resolve, reject) => {
      const success = () => {
        resolve({
          standards: DocumentModel.standards,
          types: DocumentModel.types,
        });
      };
      const error = () => {
        reject('Error loading document config.');
      };
      window.addEventListener('DOCUMENT_CONFIG_LOADED', success, { once: true });
      window.addEventListener('DOCUMENT_CONFIG_LOADING_ERROR', error, { once: true });
      DocumentModel.loadConfig();
    });
  }

  static async getConfigForType(documentType, organisationMode) {
    const config = await DocumentModel.getConfig();
    const typeConfig = config.types.find((c) => c.typeId === documentType);

    if (!typeConfig) {
      // Return generic type config
      return config.types.find((c) => c.typeId === (organisationMode ? 'ORGANISATION_FILE' : 'PRODUCT_FILE'));
    }

    return typeConfig;
  }

  static async getStandards() {
    const config = await DocumentModel.getConfig();
    return config.standards;
  }

  static async getTypes(organisationMode) {
    const config = await DocumentModel.getConfig();

    const types = config.types
      .filter(
        (c) =>
          c.applicability === (organisationMode === true ? 'ORGANISATION' : 'PRODUCT') &&
          c.typeId !== (organisationMode === true ? 'ORGANISATION_FILE' : 'PRODUCT_FILE')
      )
      .map((c) => {
        return {
          fullTitle: c.fullTitle.replace(/_/g, ' '),
          title: c.title,
          typeId: c.typeId,
        };
      })
      .sort((t1, t2) => (t1.title > t2.title ? 1 : -1));

    return types;
  }

  static loadConfig() {
    const notifyLoaded = () => {
      window.dispatchEvent(new Event('DOCUMENT_CONFIG_LOADED'));
      DocumentModel.loadingConfig = false;
    };
    const notifyLoadError = () => {
      window.dispatchEvent(new Event('DOCUMENT_CONFIG_LOADING_ERROR'));
    };
    const checkIfLoaded = () => {
      if (DocumentModel.loadedConfig) {
        notifyLoaded();
      }
    };

    if (DocumentModel.loadingConfig) {
      // Config is already loading
      return;
    }

    if (DocumentModel.loadedConfig) {
      // Config already loaded
      notifyLoaded();
      return;
    }

    DocumentModel.loadingConfig = true;
    try {
      getDocumentAssetSpecListRequest().then((req) => {
        if (req.success) {
          DocumentModel.types = req.responseData;
          checkIfLoaded();
        } else {
          notifyLoadError();
        }
      });
      getDocumentAssetStandardListRequest().then((req) => {
        if (req.success) {
          DocumentModel.standards = req.responseData;
          checkIfLoaded();
        } else {
          notifyLoadError();
        }
      });
    } catch (e) {
      notifyLoadError();
    }
  }

  static link(documentAsset) {
    return `/archive/file/${documentAsset.REFID}/edit`;
  }
}
