import { differenceInMilliseconds } from 'date-fns';
import Router from 'next/router';

import countries from 'OK/assets/countries.json';
import OrganisationModel from 'OK/models/organisation';
import UserModel from 'OK/models/user';
import { renewSession as renewSessionAPI } from 'OK/networking/auth';
import apolloClient from 'OK/networking/graphql/client';
import {
  getCurrentUserActiveOrganisationDataQuery,
  getCurrentUserQuery,
  removeUserMetadataMutation,
  setUserMetadataMutation,
  traceCurrentUserRegion,
} from 'OK/networking/users';
import {
  clearSessionAction,
  renewSessionAction,
  startSessionAction,
  restoringSession,
  setLoginRedirectUrlAction,
  setActiveOrganisationId,
  updateSessionUser,
  updateUserPreferencesAction,
  showInvitations,
  showOnboarding,
  resetLoginModal,
  showTryForFree,
} from 'OK/state/account/actions';
import { showAuthModal } from 'OK/state/app/actions';
import getStore from 'OK/state/store';
import { identifyUser, resetAnalyticsSession } from 'OK/util/analytics';
import { SESSION_VAL_HAS_VIEWED_PENDING_INVITATIONS } from 'OK/util/constants';
import { PERSISTENT_STORAGE_KEY } from 'OK/util/enums/persistentStorageKeys';
import USER_METADATA_KEYS from 'OK/util/enums/userMetadataKeys';
import {
  getBrowserSessionValue,
  getPersistentValue,
  removeBrowserSessionValue,
  removePersistentValue,
  setBrowserSessionValue,
  setPersistentValue,
} from 'OK/util/storage';

const SESSION_RENEW_SUCCESS = 'SESSION_RENEW_SUCCESS';
const SESSION_RENEW_FAILURE = 'SESSION_RENEW_FAILURE';
const refreshMsBeforeExpired = 30000; // We want to refresh tokens 30 sec before expiration

class SessionManager {
  constructor() {
    if (SessionManager._instance) {
      return SessionManager._instance;
    }

    okdebug('Instantiating SessionManager');
    SessionManager._instance = this;

    this._enableDebug = true;
    this._userQuerySubscription = null;

    if (typeof window !== 'undefined') {
      window.addEventListener('online', () => {
        this._networkReconnectHandler();
      });
    }
  }

  get accessToken() {
    return getBrowserSessionValue('ACCESS_TOKEN');
  }

  set accessToken(token) {
    if (token) {
      setBrowserSessionValue('ACCESS_TOKEN', token);

      this._setupRefreshTimer();
    } else {
      removeBrowserSessionValue('ACCESS_TOKEN');
    }
  }

  get activeInspectionLogId() {
    const store = getStore();
    const reduxState = store.getState();
    return reduxState.account.activeInspectionLogId;
  }

  get activeOrganisationId() {
    return getPersistentValue('ACTIVE_ORGANISATION_ID');
  }

  set activeOrganisationId(id) {
    const store = getStore();
    const reduxState = store.getState();

    // Update local storage
    if (id) {
      setPersistentValue('ACTIVE_ORGANISATION_ID', id);
    } else {
      removePersistentValue('ACTIVE_ORGANISATION_ID');
    }

    // Update Redux
    store.dispatch(setActiveOrganisationId(id));

    // Purge GraphQL cache to ensure no data is leaked when switching active organisations
    apolloClient.cache.evict({});
    apolloClient.cache.gc();

    if (this.isLoggedIn) {
      // Save update to user metadata if different
      let existingActiveOrganisationId;
      if (reduxState.account.user?.metadata) {
        existingActiveOrganisationId = reduxState.account.user?.metadata[USER_METADATA_KEYS.ACTIVE_ORGANISATION_ID];
      }
      if (existingActiveOrganisationId !== id) {
        if (id) {
          apolloClient.mutate({
            mutation: setUserMetadataMutation,
            variables: {
              key: USER_METADATA_KEYS.ACTIVE_ORGANISATION_ID,
              value: id,
            },
          });
        } else {
          apolloClient.mutate({
            mutation: removeUserMetadataMutation,
            variables: {
              key: USER_METADATA_KEYS.ACTIVE_ORGANISATION_ID,
            },
          });
        }
      }
    }
  }

  get isLoggedIn() {
    return getPersistentValue('LOGGED_IN') === '1';
  }

  set isLoggedIn(flag) {
    if (typeof flag === 'boolean') {
      setPersistentValue('LOGGED_IN', flag ? '1' : '0');
    } else {
      removePersistentValue('LOGGED_IN');
    }
  }

  _debug(...message) {
    if (this._enableDebug) {
      okdebug(...message);
    }
  }

  _msUntilTokenExpires(token) {
    const tokenComponents = token.split('.');
    const tokenPaylod = tokenComponents[1];
    const decodedPayload = JSON.parse(atob(tokenPaylod));
    const expiration = new Date(decodedPayload.exp * 1000); // exp is in seconds, so convert to milliseconds
    const msUntilExpired = differenceInMilliseconds(expiration, new Date());
    return msUntilExpired;
    // return 30;
  }

  _setupRefreshTimer() {
    if (this._tokenRefreshTimeout) {
      clearTimeout(this._tokenRefreshTimeout);
    }

    const refreshToken = () => {
      if (!navigator.onLine) {
        okdebug('Ignoring call to renew session due to no network connection.');
        return;
      }

      this.renewSession();
    };

    if (this.accessToken) {
      const msUntilExpired = this._msUntilTokenExpires(this.accessToken);
      this._debug(`Token expires in ${msUntilExpired / 1000} seconds`);
      if (msUntilExpired > 0) {
        const timeout = msUntilExpired > refreshMsBeforeExpired ? msUntilExpired - refreshMsBeforeExpired : 0;
        this._debug(`Refreshing token in ${timeout / 1000} seconds`);
        this._tokenRefreshTimeout = setTimeout(() => {
          refreshToken();
        }, timeout);
      } else {
        okerror('Token already expired');
        refreshToken();
      }
    }
  }

  _networkReconnectHandler() {
    this._setupRefreshTimer();
  }

  async completeOnboarding() {
    const store = getStore();

    await apolloClient.mutate({
      mutation: setUserMetadataMutation,
      variables: {
        key: USER_METADATA_KEYS.HAS_COMPLETED_ONBOARDING,
        value: true,
      },
    });

    const userResponse = await apolloClient.query({ fetchPolicy: 'network-and-cache', query: getCurrentUserQuery });

    let redirectUrl;

    if (userResponse.data?.user) {
      redirectUrl = UserModel.link(userResponse.data.user);
    }

    if (userResponse.data?.user) {
      const { user } = userResponse.data;
      const activeOrganisation = user.organisationList.find((o) => o.id === this.activeOrganisationId);
      if (activeOrganisation) {
        const userRole = OrganisationModel.roleForUser(user, activeOrganisation);
        if (
          userRole === OrganisationModel.ROLE.OWNER ||
          userRole === OrganisationModel.ROLE.MANAGER ||
          userRole === OrganisationModel.ROLE.COWORKER
        ) {
          redirectUrl = '/archive';
        }
      }
    }

    Router.push(redirectUrl);

    Router.events.on('routeChangeComplete', () => {
      store.dispatch(showAuthModal(false));
      store.dispatch(resetLoginModal());
    });
  }

  async startSession(accessToken) {
    if (!accessToken) {
      throw new Error('accessToken is required to start a session.');
    }

    this.accessToken = accessToken;

    const store = getStore();
    let user;
    try {
      // Get user data
      const response = await apolloClient.query({
        query: getCurrentUserQuery,
        context: { waitWhileRestoringSession: false },
      });
      if (response.data?.user) {
        user = response.data.user;

        // Set initial active organisation id
        let initialActiveOrganisationId;
        if (user.metadata && user.metadata[USER_METADATA_KEYS.ACTIVE_ORGANISATION_ID]) {
          initialActiveOrganisationId = user.metadata[USER_METADATA_KEYS.ACTIVE_ORGANISATION_ID];
        } else if (user.managedOrganisationList.length) {
          initialActiveOrganisationId = user.managedOrganisationList[0].id;
        } else if (user.organisationList.length) {
          initialActiveOrganisationId = user.organisationList[0].id;
        }
        if (initialActiveOrganisationId && initialActiveOrganisationId !== this.activeOrganisationId) {
          // Update active organisation id
          this.activeOrganisationId = initialActiveOrganisationId;
          // Load user data for active organisation
          const activeOrganisationUserDataResponse = await apolloClient.query({
            query: getCurrentUserActiveOrganisationDataQuery,
            fetchPolicy: 'network-only',
          });
          if (activeOrganisationUserDataResponse.data?.user) {
            user = {
              ...user,
              ...activeOrganisationUserDataResponse.data.user,
            };
          }
        }

        if (
          user.organisationInviteList.length &&
          getBrowserSessionValue(SESSION_VAL_HAS_VIEWED_PENDING_INVITATIONS) !== '1'
        ) {
          // Show user pending invitations
          store.dispatch(showInvitations(true));
        } else if (
          !user.metadata ||
          (user.metadata[USER_METADATA_KEYS.HAS_COMPLETED_ONBOARDING] !== true &&
            !store.getState().account.isComingFromTryForFree)
        ) {
          // Show user onboarding

          store.dispatch(showOnboarding(true));
        } else if (store.getState().account.isComingFromTryForFree) {
          // Show user free trial

          store.dispatch(showTryForFree(true));
        } else {
          store.dispatch(showAuthModal(false));
          store.dispatch(resetLoginModal());
          store.dispatch(resetLoginModal());
        }

        identifyUser(user);

        // Subscribe to user updates
        this._userQuerySubscription = apolloClient.watchQuery({ query: getCurrentUserQuery }).subscribe((result) => {
          if (result.data?.user) {
            const updatedUser = result.data.user;
            store.dispatch(updateSessionUser(updatedUser));

            // Handle potential changes to activeOrganisationId
            if (!this.activeOrganisationId && updatedUser.organisationList.length) {
              // Set active organisation to first in list if none is set already
              this.activeOrganisationId = updatedUser.organisationList[0].id;
            } else if (this.activeOrganisationId) {
              // Ensure user is a member of the active organisation. This should always be the case unless the user
              // just left or was removed from the organisation.
              const userIsInActiveOrganisation =
                updatedUser.organisationList.find((o) => o.id === this.activeOrganisationId) !== undefined;
              if (!userIsInActiveOrganisation) {
                const reduxState = store.getState();
                // Only update activeOrganisationId if the user has not just created an organisation. If they have, it
                // is expected that they will not appear to be in that organisation until the latest user data has had
                // a chance to load.
                if (!reduxState.account.createdOrganisation) {
                  if (updatedUser.organisationList.length) {
                    // Set active organisation to first in list
                    this.activeOrganisationId = updatedUser.organisationList[0].id;
                  } else {
                    // Remove active organisation id
                    this.activeOrganisationId = null;
                  }
                }
              }
            }

            // Handle changes to roles
            const accessTokenComponents = this.accessToken.split('.');
            if (accessTokenComponents.length === 3) {
              const decodedPayload = JSON.parse(atob(accessTokenComponents[1]));
              const tokenRoles = decodedPayload.Authorities;
              const userRoles = updatedUser.roleList;
              // Compare number of roles.
              const numberOfRolesDifferent = tokenRoles.length !== userRoles.length;
              let differentSetOfRoles = false;
              if (!numberOfRolesDifferent) {
                // Check that all roles from API are present in token.
                userRoles.forEach((role) => {
                  const tokenIncludesRole = tokenRoles.includes(role);
                  if (!tokenIncludesRole) {
                    differentSetOfRoles = true;
                  }
                });
              }
              if (numberOfRolesDifferent || differentSetOfRoles) {
                // Get new access token with updated roles.
                this.renewSession();
              }
            }
          }
        });
      } else {
        throw new Error('API returned no user data.');
      }
    } catch (e) {
      okerror('Session failed to start', e);
      return;
    }

    store.dispatch(startSessionAction(this.accessToken, user));
    this.isLoggedIn = true;

    this._debug('Session started');

    this.sessionStartRedirect(Router.pathname === '/auth');
  }

  endSession() {
    this.accessToken = null;
    this.isLoggedIn = false;
    this.activeOrganisationId = null;
    const store = getStore();
    store.dispatch(clearSessionAction());
    this._userQuerySubscription?.unsubscribe();
    apolloClient.clearStore();
    resetAnalyticsSession();
    this._debug('Session ended');
  }

  async renewSession() {
    if (!this._isRenewingSession) {
      // Ensure there's only 1 session renewal triggered at a time.
      this._isRenewingSession = true;

      // Call the renew API
      renewSessionAPI()
        .then((response) => {
          if (response.success) {
            this._debug('Renewed user session successfully.');
            const { accessToken } = response.responseData;
            // Store the new access token
            this.accessToken = accessToken;

            // Update Redux
            const store = getStore();
            const reduxState = store.getState();
            if (!reduxState.account.authenticated) {
              this.startSession(accessToken);
            } else {
              store.dispatch(renewSessionAction(accessToken));
            }

            // Notify listeners that the session has been renewed.
            window.dispatchEvent(new Event(SESSION_RENEW_SUCCESS));
          } else {
            this._debug('Could not renew user session', response.error);
            throw new Error(response.error);
          }
        })
        .catch((error) => {
          okerror('Error renewing user session', error);
          // End session and notify listeners that the session could not be renewed.
          this.endSession();
          window.dispatchEvent(new Event(SESSION_RENEW_FAILURE));
        })
        .finally(() => {
          // Signal that no session renewal is in progress.
          this._isRenewingSession = false;
        });
    }

    // Return a promise that resolves when a session renewal event is triggered.
    return new Promise((resolve, reject) => {
      window.addEventListener(
        SESSION_RENEW_SUCCESS,
        () => {
          resolve();
          window.removeEventListener(SESSION_RENEW_SUCCESS, resolve, { once: true });
          window.removeEventListener(SESSION_RENEW_FAILURE, reject, { once: true });
        },
        { once: true }
      );
      window.addEventListener(
        SESSION_RENEW_FAILURE,
        () => {
          reject();
          window.removeEventListener(SESSION_RENEW_SUCCESS, resolve, { once: true });
          window.removeEventListener(SESSION_RENEW_FAILURE, reject, { once: true });
        },
        { once: true }
      );
    });
  }

  async restoreSession() {
    const store = getStore();
    if (this.accessToken || this.isLoggedIn) {
      this._debug('Attempting to restore user session...');
      store.dispatch(restoringSession());

      if (this.accessToken) {
        const msUntilTokenExpires = this._msUntilTokenExpires(this.accessToken);
        if (msUntilTokenExpires > 0) {
          // Start session if token is not expired
          this.startSession(this.accessToken);
          return;
        }
      }

      // Renew session token
      this.renewSession();
    } else {
      const storedRegion = getPersistentValue(PERSISTENT_STORAGE_KEY.REGION);
      if (!storedRegion) {
        const traceRequest = await traceCurrentUserRegion();
        if (traceRequest.success === true) {
          const countryCodeISOAlpha2 = traceRequest.responseData.countryCodeISO;
          const country = countries.find((c) => c.isoAlpha2 === countryCodeISOAlpha2);
          if (country) {
            const countryCodeISOAlpha3 = country.isoAlpha3;
            setPersistentValue(PERSISTENT_STORAGE_KEY.REGION, countryCodeISOAlpha3);
            store.dispatch(updateUserPreferencesAction({ region: countryCodeISOAlpha3 }));
          }
        }
      }
    }
  }

  sessionStartRedirect(force = false) {
    const store = getStore();
    let loginRedirectUrl = store.getState().account.loginRedirectUrl;
    if (loginRedirectUrl || force) {
      // Redirect the user to a specific page upon session start
      Router.push(loginRedirectUrl ?? '/profile');
      store.dispatch(setLoginRedirectUrlAction(null));
    }
  }
}

const sessionManager = new SessionManager();

export default sessionManager;
