import NextImage from "next/legacy/image";
import PropTypes from 'prop-types';
import { useLayoutEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';
import { MediaEditorToolImageCrop, MediaEditorToolDelete } from './tools';

import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel/old';
import FloatingButtonsContainer from 'OK/components/floatingButtons';
import Icon, { ICONS } from 'OK/components/icon';
import { Popup, PopupContent } from 'OK/components/popup';
import MediaAssetModel from 'OK/models/mediaAsset';
import UIContext from 'OK/util/context/ui';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * Edit a list of media in a popup.
 *
 * @param {object} props
 * @param {() => {}} props.dismiss Function to dismiss the popup.
 * @param {array} props.media Array of media to edit.
 * @param {(media: object) => {}} props.mediaPreviewAccessoryViewRender Render an accessory view with each media preview.
 * @param {(updatedMedia: array) => {}} props.onCommitChanges Event handler when changes are committed.
 * @param {number} props.preselectMediaIndex Initially select media at this index.
 * @param {(media: object) => {}} props.thumbnailAccessoryViewRender Render an accessory view with each thumbnail.
 * @param {array} props.tools Editing tools to make available.
 */
export default function MediaEditorPopup(props) {
  /* Variables */

  const {
    committingChanges = false,
    dismiss,
    enableEditing = false,
    media: originalMedia,
    mediaPreviewAccessoryViewRender,
    onCommitChanges,
    preselectMediaIndex,
    thumbnailAccessoryViewRender,
    tools = [MediaEditorToolImageCrop, MediaEditorToolDelete],
  } = props;
  const { t, tHTML } = useI18n();
  const thumbnailsRef = useRef();

  // State
  const [currentToolId, setCurrentToolId] = useState(null);
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [media, setMedia] = useState(originalMedia);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(preselectMediaIndex);
  const [showCancelAlert, setShowCancelAlert] = useState(false);

  const selectedMedia = media[selectedMediaIndex];

  /* Methods */

  const cancelChanges = () => {
    dismiss();
  };

  const commitChanges = () => {
    onCommitChanges(media);
  };

  const onToolCancel = () => {
    setCurrentToolId(null);
  };

  const onToolComplete = (modifiedMedia) => {
    setCurrentToolId(null);
    storeUpdatedMedia(modifiedMedia);

    if (!hasMadeChanges) {
      setHasMadeChanges(true);
    }
  };

  const openTool = (toolId) => {
    setCurrentToolId(toolId);
  };

  const promptCancelChanges = () => {
    if (hasMadeChanges) {
      setShowCancelAlert(true);
    } else {
      cancelChanges();
    }
  };

  const scrollToMedia = (index, skipAnimation = false) => {
    thumbnailsRef.current?.scrollToSlideAtIndex(index, skipAnimation);
  };

  const selectMedia = (index) => {
    setSelectedMediaIndex(index);
    scrollToMedia(index);
  };

  const storeUpdatedMedia = (modifiedMedia) => {
    const updatedMediaArray = [...media];

    if (modifiedMedia) {
      // Save edited version
      const indexOfModifiedMedia = media.findIndex((m) => m.id === modifiedMedia.id);
      updatedMediaArray.splice(indexOfModifiedMedia, 1, modifiedMedia);
    } else {
      // Delete media
      const indexOfDeletedMedia = media.findIndex((m) => m.id === selectedMedia.id);
      updatedMediaArray.splice(indexOfDeletedMedia, 1);

      if (updatedMediaArray.length) {
        // Select a different media
        let newSelectedMedia;
        if (indexOfDeletedMedia < updatedMediaArray.length - 1) {
          newSelectedMedia = updatedMediaArray[indexOfDeletedMedia];
        } else {
          newSelectedMedia = updatedMediaArray[updatedMediaArray.length - 1];
        }
        selectMedia(newSelectedMedia.id);
      }
    }

    setMedia(updatedMediaArray);
  };

  /* Effects */

  useLayoutEffect(() => {
    // Keep thumbnails carousel scrolled to the selected media
    scrollToMedia(selectedMediaIndex, true);
  });

  /* Render */

  const CurrentTool = tools.find((t) => t.id === currentToolId);

  // Media / tool
  let mediaComponent;
  if (selectedMedia) {
    if (typeof CurrentTool?.Component === 'function') {
      mediaComponent = (
        <CurrentTool.Component
          media={selectedMedia}
          onCancel={onToolCancel}
          onComplete={onToolComplete}
          {...CurrentTool.props}
        />
      );
    } else {
      switch (selectedMedia.mediaType) {
        case MediaAssetModel.MEDIA_TYPE.IMAGE:
          mediaComponent = (
            <div className={styles.selectedMediaContainer}>
              <NextImage layout='fill' objectFit='cover' src={selectedMedia.imageData.imageURL} />
            </div>
          );
          break;
        default:
          mediaComponent = <div className={styles.fakeMedia} />;
          break;
      }
    }
  }

  return (
    <UIContext.Provider value='base'>
      <Popup dismiss={promptCancelChanges}>
        <PopupContent className={styles.popupContent}>
          <div className={styles.menu}>
            <Button
              className={styles.backButton}
              linkStyle
              onClick={promptCancelChanges}
              icon={<Icon className={styles.backIcon} name={ICONS.CARET.name} />}
            />
            {tools.map((t) => {
              const isActiveTool = t.id === currentToolId;
              const buttonClassNames = isActiveTool ? styles.activeToolButton : null;
              const iconTint = isActiveTool ? 'navigation' : t.iconTint ?? 'secondary';
              return (
                <Button
                  className={buttonClassNames}
                  icon={t.icon}
                  key={t.id}
                  linkStyle
                  onClick={() => openTool(t.id)}
                  tint={iconTint}
                />
              );
            })}
          </div>
          <div className={styles.mediaPreviewContainer}>
            {mediaComponent}
            {!CurrentTool && typeof mediaPreviewAccessoryViewRender === 'function'
              ? mediaPreviewAccessoryViewRender(selectedMedia)
              : null}
          </div>
          {!CurrentTool && (
            <FloatingButtonsContainer innerClassName={styles.floatingButtonsInner}>
              {enableEditing && (
                <Button
                  className={styles.floatingButton}
                  disabled={committingChanges}
                  icon={ICONS.X.name}
                  onClick={promptCancelChanges}
                  tint='alert'
                />
              )}
              <Carousel
                carouselClassName={styles.thumbnailsCarousel}
                className={styles.thumbnails}
                fadeOutSides
                ref={thumbnailsRef}
                showCustomScrollbar={false}
                showSlideIndicators={false}
                sidePadding={20}
                slideSideMargin={5}
                slideWidth={38}
              >
                {media.map((m, index) => {
                  let mediaClassNames = styles.thumbnail;
                  if (selectedMediaIndex === index) {
                    mediaClassNames = `${mediaClassNames} ${styles.active}`;
                  }
                  let thumbnailMedia;
                  switch (m.mediaType) {
                    case MediaAssetModel.MEDIA_TYPE.IMAGE:
                      thumbnailMedia = (
                        <NextImage
                          className={styles.thumbnailMedia}
                          layout='fill'
                          objectFit='cover'
                          src={m.imageData.imageURL}
                        />
                      );
                      break;
                    default:
                      break;
                  }
                  let accessoryView =
                    typeof thumbnailAccessoryViewRender === 'function' ? thumbnailAccessoryViewRender(m) : null;
                  return (
                    <Slide key={index}>
                      <div className={mediaClassNames} onClick={() => selectMedia(index)}>
                        {thumbnailMedia}
                      </div>
                      {accessoryView}
                    </Slide>
                  );
                })}
              </Carousel>
              {enableEditing && (
                <Button
                  className={styles.floatingButton}
                  disabled={committingChanges}
                  icon={ICONS.TICK.name}
                  loading={committingChanges}
                  onClick={commitChanges}
                  tint='navigation'
                />
              )}
            </FloatingButtonsContainer>
          )}
        </PopupContent>
      </Popup>
      {showCancelAlert && (
        <Alert
          buttons={
            <>
              <Button block className={styles.discardChangesButton} onClick={cancelChanges} tint='alert'>
                {t('MEDIA_EDITOR_CANCEL_ALERT_CONFIRM')}
              </Button>
              <Button block linkStyle onClick={() => setShowCancelAlert(false)}>
                {t('MEDIA_EDITOR_CANCEL_ALERT_CANCEL')}
              </Button>
            </>
          }
          message={tHTML('MEDIA_EDITOR_CANCEL_ALERT_MESSAGE')}
          title={t('MEDIA_EDITOR_CANCEL_ALERT_TITLE')}
        />
      )}
    </UIContext.Provider>
  );
}

MediaEditorPopup.propTypes = {
  committingChanges: PropTypes.bool,
  dismiss: PropTypes.func.isRequired,
  enableEditing: PropTypes.bool,
  media: PropTypes.array,
  mediaPreviewAccessoryViewRender: PropTypes.func,
  onCommitChanges: PropTypes.func.isRequired,
  preselectMediaIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  thumbnailAccessoryViewRender: PropTypes.func,
  tools: PropTypes.array,
};
