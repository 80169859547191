import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  addThirdPartyInspectorMutation,
  createInspectionTestMutation,
  linkInspectionTestMutation,
  removeThirdPartyInspectorFromInspectionMutation,
  unlinkInspectionTestMutation,
  updateOrderOfInspectionTestAssetMutation,
} from '.';

import InspectionAssetModel from 'OK/models/inspectionAsset';
import BaseInspectionAssetModel from 'OK/models/inspectionAsset/base';
import InspectionTestAssetModel from 'OK/models/inspectionTestAsset';
import BaseOrganisationModel from 'OK/models/organisation/base';
import TestAssetModel from 'OK/models/testAsset';
import BaseTestAsset from 'OK/models/testAsset/base';

/* Helpers */

function indexOfInspectionTestAssetInCachedList(list, testAssetId, readField) {
  return list.findIndex((pd) => {
    const listInspectionTestAsset = readField('testAsset', pd);
    const listInspectionTestAssetId = readField('id', listInspectionTestAsset);
    return listInspectionTestAssetId === testAssetId;
  });
}

/* Hooks */

export function useAddThirdPartyInspectorAPI() {
  const apolloClient = useApolloClient();

  const [addThirdPartyInspectorAPI] = useMutation(addThirdPartyInspectorMutation);

  const addThirdPartyInspector = useCallback(
    (inspectionAssetId, targetOrganisationId) => {
      let optimisticResponse;
      const inspectionAsset = apolloClient.readFragment({
        id: `${InspectionAssetModel.GRAPHQL_TYPE}:${inspectionAssetId}`,
        fragment: InspectionAssetModel.fragment,
        fragmentName: InspectionAssetModel.fragmentName,
      });
      const organisation = apolloClient.readFragment({
        id: `${BaseOrganisationModel.GRAPHQL_TYPE}:${targetOrganisationId}`,
        fragment: BaseOrganisationModel.fragment,
        fragmentName: BaseOrganisationModel.fragmentName,
      });
      if (inspectionAsset?.inspectionOrganisationList && organisation) {
        optimisticResponse = {
          inspectionAsset: {
            id: inspectionAssetId,
            inspectionOrganisationList: [...inspectionAsset.inspectionOrganisationList, organisation],
            __typename: InspectionAssetModel.GRAPHQL_TYPE,
          },
        };
      }

      return addThirdPartyInspectorAPI({
        optimisticResponse,
        variables: {
          inspectionAssetId,
          targetOrganisationId,
        },
      });
    },
    [addThirdPartyInspectorAPI, apolloClient]
  );

  return addThirdPartyInspector;
}

export function useCreateInspectionTestAssetAPI() {
  const [createInspectionTestAPI, createInspectionTestAPIResult] = useMutation(createInspectionTestMutation);

  const createInspectionTestAsset = useCallback(
    (inspectionAssetId) => {
      return createInspectionTestAPI({
        variables: {
          inspectionAssetId,
        },
        update: (cache, result) => {
          const { inspectionTestAsset } = result.data;

          // Write inspection test asset to cache
          const testAssetRef = cache.writeFragment({
            id: cache.identify(inspectionTestAsset.testAsset),
            fragment: TestAssetModel.fragment,
            fragmentName: TestAssetModel.fragmentName,
            data: inspectionTestAsset.testAsset,
          });

          // Add inspection test asset to inspection asset
          cache.modify({
            id: `${InspectionAssetModel.GRAPHQL_TYPE}:${inspectionAssetId}`,
            fields: {
              inspectionTestAssetList: (currentList) => {
                const cacheInspectionTestAsset = {
                  ...inspectionTestAsset,
                  testAsset: testAssetRef,
                };
                return [...currentList, cacheInspectionTestAsset];
              },
            },
          });
        },
      });
    },
    [createInspectionTestAPI]
  );

  return [createInspectionTestAsset, createInspectionTestAPIResult];
}

export function useLinkInspectionTestAssetAPI() {
  const [linkInspectionTestAPI, linkInspectionTestAPIResult] = useMutation(linkInspectionTestMutation);

  const linkInspectionTest = useCallback(
    (inspectionAssetId, testAssetId) => {
      return linkInspectionTestAPI({
        variables: {
          inspectionAssetId,
          testAssetId,
        },
        update: (cache, result) => {
          if (result.data?.inspectionTestAsset) {
            // Write TestAsset to cache
            const testAssetRef = cache.writeFragment({
              id: `${InspectionTestAssetModel.GRAPHQL_TYPE}:${result.data.inspectionTestAsset.testAsset.id}`,
              fragment: InspectionTestAssetModel.fragment,
              fragmentName: InspectionTestAssetModel.fragmentName,
              data: result.data.inspectionTestAsset.testAsset,
            });

            // Add InspectionTestAsset to inspection's list
            cache.modify({
              id: `${InspectionAssetModel.GRAPHQL_TYPE}:${inspectionAssetId}`,
              fields: {
                inspectionTestAssetList: (currentList) => {
                  const inspectionTestAsset = {
                    ...result.data.inspectionTestAsset,
                    testAsset: testAssetRef,
                  };
                  return [...currentList, inspectionTestAsset];
                },
              },
            });
          }
        },
      });
    },
    [linkInspectionTestAPI]
  );

  return [linkInspectionTest, linkInspectionTestAPIResult];
}

export function useRemoveThirdPartyInspectorAPI() {
  const apolloClient = useApolloClient();
  const [removeThirdPartyInspectorAPI] = useMutation(removeThirdPartyInspectorFromInspectionMutation);

  const removeThirdPartyInspector = useCallback(
    (inspectionAssetId, organisationId) => {
      const inspectionAsset = apolloClient.readFragment({
        id: `${InspectionAssetModel.GRAPHQL_TYPE}:${inspectionAssetId}`,
        fragment: InspectionAssetModel.fragmentThirdPartyInspectors,
        fragmentName: InspectionAssetModel.fragmentNameThirdPartyInspectors,
      });
      const updatedThirdPartyInspectorsList = [...inspectionAsset.inspectionOrganisationList];
      const indexOfOrganisation = updatedThirdPartyInspectorsList.findIndex((o) => o.id === organisationId);
      updatedThirdPartyInspectorsList.splice(indexOfOrganisation, 1);
      const optimisticResponse = {
        inspectionAsset: {
          ...inspectionAsset,
          inspectionOrganisationList: updatedThirdPartyInspectorsList,
        },
      };
      return removeThirdPartyInspectorAPI({
        variables: {
          inspectionAssetId,
          organisationId,
        },
        optimisticResponse,
      });
    },
    [apolloClient, removeThirdPartyInspectorAPI]
  );

  return removeThirdPartyInspector;
}

export function useUnlinkInspectionTestAssetAPI() {
  const apolloClient = useApolloClient();
  const [unlinkInspectionTestAssetAPI] = useMutation(unlinkInspectionTestMutation);

  const unlinkInspectionTestAsset = useCallback(
    (inspectionAssetId, testAssetId) => {
      const inspectionAssetCacheId = `${InspectionAssetModel.GRAPHQL_TYPE}:${inspectionAssetId}`;
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const inspectionAsset = apolloClient.readFragment({
        id: inspectionAssetCacheId,
        fragment: gql`
          fragment InspectionAssetWithTests on ${InspectionAssetModel.GRAPHQL_TYPE} {
            ...${BaseInspectionAssetModel.fragmentName}
            inspectionTestAssetList {
              order
              publishStatus
              testAsset {
                ...${BaseTestAsset.fragmentName}
              }
            }
          }
          ${BaseInspectionAssetModel.fragment}
          ${BaseTestAsset.fragment}
        `,
        fragmentName: 'InspectionAssetWithTests',
      });
      const inspectionTestAsset = inspectionAsset?.inspectionTestAssetList?.find(
        (ita) => ita.testAsset.id === testAssetId
      );
      if (inspectionTestAsset) {
        optimisticResponse = {
          inspectionTestAsset,
        };
      }

      // Call API
      return unlinkInspectionTestAssetAPI({
        variables: {
          inspectionAssetId,
          testAssetId,
        },
        optimisticResponse,
        update: (cache) => {
          // Remove test from inspection test lists
          cache.modify({
            id: inspectionAssetCacheId,
            fields: {
              inspectionTestAssetList: (currentList, { readField }) => {
                const indexOfTestAsset = indexOfInspectionTestAssetInCachedList(currentList, testAssetId, readField);
                if (indexOfTestAsset > -1) {
                  const updatedList = [...currentList];
                  updatedList.splice(indexOfTestAsset, 1);
                  return updatedList;
                }

                return currentList;
              },
            },
          });

          // Remove inspection from test linked inspections list
          cache.modify({
            id: `${TestAssetModel.GRAPHQL_TYPE}:${testAssetId}`,
            fields: {
              linkedInspectionAssetList: (currentList, { readField }) => {
                const indexOfInspectionAsset = currentList.findIndex((i) => {
                  const listInspectionAssetId = readField('id', i);
                  return inspectionAssetId === listInspectionAssetId;
                });
                if (indexOfInspectionAsset > -1) {
                  const updatedList = [...currentList];
                  updatedList.splice(indexOfInspectionAsset, 1);
                  return updatedList;
                }
              },
            },
          });
        },
      });
    },
    [apolloClient, unlinkInspectionTestAssetAPI]
  );

  return unlinkInspectionTestAsset;
}

export function useUpdateOrderOfInspectionTestAssetAPI() {
  const apolloClient = useApolloClient();
  const [updateOrderOfInspectionTestAssetAPI] = useMutation(updateOrderOfInspectionTestAssetMutation);

  const updateOrderOfInspectionTestAsset = useCallback(
    (inspectionAssetId, testAssetId, targetOrder) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const inspectionAsset = apolloClient.readFragment({
        id: `${InspectionAssetModel.GRAPHQL_TYPE}`,
        fragment: InspectionAssetModel.fragment,
        fragmentName: InspectionAssetModel.fragmentName,
      });
      const cachedInspectionTestAsset = inspectionAsset?.inspectionTestAssetList?.find(
        (ita) => ita.testAsset.id === testAssetId
      );
      if (cachedInspectionTestAsset) {
        optimisticResponse = {
          inspectionTestAsset: {
            ...cachedInspectionTestAsset,
            order: targetOrder,
          },
        };
      }

      // Call API
      return updateOrderOfInspectionTestAssetAPI({
        variables: {
          inspectionAssetId,
          testAssetId,
          targetOrder,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: `${InspectionAssetModel.GRAPHQL_TYPE}:${inspectionAssetId}`,
            fields: {
              inspectionTestAssetList: (currentList, { readField }) => {
                const { inspectionTestAsset } = result.data;
                const oldIndex = indexOfInspectionTestAssetInCachedList(currentList, testAssetId, readField);
                const newIndex = inspectionTestAsset.order - 1;
                const updatedList = [...currentList];
                const [cachedInspectionTestAsset] = updatedList.splice(oldIndex, 1);
                const insertIndex = newIndex > updatedList.length ? updatedList.length - 1 : newIndex;
                updatedList.splice(insertIndex, 0, cachedInspectionTestAsset);
                const reorderedList = updatedList.map((listInspectionTestAsset, index) => {
                  return {
                    ...listInspectionTestAsset,
                    order: index + 1,
                  };
                });
                return reorderedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, updateOrderOfInspectionTestAssetAPI]
  );

  return updateOrderOfInspectionTestAsset;
}
