import { gql } from '@apollo/client';
import { nanoid } from 'nanoid';

import UnversionedNoteAssetModel from '../unversionedNoteAsset';
import VersionedNoteAssetModel from '../versionedNoteAsset';

import BaseInspectionLogNoteAssetModel from './base';

export default class InspectionLogNoteAssetModel {
  static GRAPHQL_TYPE = BaseInspectionLogNoteAssetModel.GRAPHQL_TYPE;

  static fragmentName = 'InspectionLogNoteAsset_Fragment';
  static fragment = gql`
    fragment ${InspectionLogNoteAssetModel.fragmentName} on ${InspectionLogNoteAssetModel.GRAPHQL_TYPE} {
      ...${BaseInspectionLogNoteAssetModel.fragmentName}
      unversionedNoteAsset {
        ...${UnversionedNoteAssetModel.fragmentName}
      }
      versionedNoteAsset {
        ...${VersionedNoteAssetModel.fragmentName}
      }
    }
    ${BaseInspectionLogNoteAssetModel.fragment}
    ${UnversionedNoteAssetModel.fragment}
    ${VersionedNoteAssetModel.fragment}
  `;

  static fragmentNameLorealCard = 'InspectionLogNoteAsset_LorealCard_Fragment';
  static fragmentLorealCard = gql`
    fragment ${InspectionLogNoteAssetModel.fragmentNameLorealCard} on ${InspectionLogNoteAssetModel.GRAPHQL_TYPE} {
      id
      taggedInspectionLogTestAssetId
      unversionedNoteAsset {
        textContentMap
      }
    }
  `;

  static fragmentNameTaggedStep = 'InspectionLogNoteAsset_TaggedStep_Fragment';
  static fragmentTaggedStep = gql`
    fragment ${InspectionLogNoteAssetModel.fragmentNameTaggedStep} on ${InspectionLogNoteAssetModel.GRAPHQL_TYPE} {
      id
      taggedInspectionLogTestAssetId
    }
  `;

  static mock = () => {
    return {
      createdBy: null,
      createdDate: new Date(),
      id: nanoid(),
      inspectionLogAssetSource: 'INSPECTOR',
      inspectionOrganisationId: nanoid(),
      lastModifiedBy: null,
      lastModifiedDate: new Date(),
      order: 1,
      organisationId: nanoid(),
      unversionedNoteAsset: {
        textContentMap: {},
        __typename: UnversionedNoteAssetModel.GRAPHQL_TYPE,
      },
      version: 0,
      versionedNoteAsset: null,
      __typename: InspectionLogNoteAssetModel.GRAPHQL_TYPE,
    };
  };
}
