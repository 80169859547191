import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import InputWithDropdown from 'OK/components/input/withDropdown';
import { DEBOUNCE_TIMING_MS_LONG } from 'OK/util/constants';
import { countryList } from 'OK/util/geolocation';
import useI18n from 'OK/util/hooks/useI18n';

export default function PhoneInput(props) {
  /* Variables */

  const {
    country: countryProp,
    loading = false,
    number,
    onBlurCountryCode,
    onBlurNumber,
    onChangeCountryCode,
    onChangeNumber,
    validateInput = false,
    ...otherProps
  } = props;
  const { t } = useI18n();

  // Default country dropdown to region from app preferences
  const defaultCountryIso = useSelector((state) => state.account.preferences.region);
  const country = countryProp || defaultCountryIso;

  // State

  const [numberIsValid, setNumberIsValid] = useState(false);

  /* Methods */

  const validateNumber = async (uname) => {
    const validator = (await import('validator')).default;

    const isValid = validator.isMobilePhone(uname);
    setNumberIsValid(isValid);

    return isValid;
  };
  const validateNumberDebounced = useMemo(
    () =>
      debounce((num) => {
        if (num) {
          validateNumber(num);
        }
      }, DEBOUNCE_TIMING_MS_LONG),
    []
  );

  // Event handlers

  const _onChangeNumber = (e) => {
    if (onChangeNumber) {
      onChangeNumber(e);
    }
    if (validateInput) {
      validateNumberDebounced(e.target.value);
    }
  };

  /* Render */

  // Validation icon
  let validationIcon;
  if (loading) {
    validationIcon = <Icon name={ICONS.SPINNER.name} />;
  } else if (number && validateInput) {
    if (numberIsValid === true) {
      validationIcon = <Icon name={ICONS.TICK.name} tint='creation' />;
    } else {
      validationIcon = <Icon name={ICONS.X.name} tint='alert' />;
    }
  }

  return (
    <InputWithDropdown
      dropdownClassName={styles.select}
      dropdownLabel={t('YOUR_COUNTRY_CODE')}
      dropdownOptions={countryList
        .map(({ name, isoAlpha3, phoneCode }) => ({
          displayLabel: `+${phoneCode}`,
          label: `${name} (+${phoneCode})`,
          value: isoAlpha3,
        }))
        .sort((a, b) => {
          return a.label > b.label ? 1 : -1;
        })}
      dropdownValue={country}
      onBlur={onBlurNumber}
      onBlurDropdown={onBlurCountryCode}
      onChange={_onChangeNumber}
      onDropdownChange={onChangeCountryCode}
      placeholder={t('ENTER_NUMBER')}
      showClearButton={false}
      showErrorIcon={false}
      type='tel'
      value={number}
      withIcon={validationIcon}
      {...otherProps}
    />
  );
}

PhoneInput.propTypes = {
  country: PropTypes.string,
  loading: PropTypes.bool,
  number: PropTypes.string,
  onBlurCountryCode: PropTypes.func,
  onBlurNumber: PropTypes.func,
  onChangeCountryCode: PropTypes.func,
  onChangeNumber: PropTypes.func,
  validateInput: PropTypes.bool,
};
