import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Tag from '../tag';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import Text from 'OK/components/text';
import {
  createItemAlternateIDMutation,
  removeItemAlternateIDMutation,
  setItemAlternateIdAsPrimaryMutation,
} from 'OK/networking/items';
import {
  createProductAlternateIDMutation,
  removeProductAlternateIDMutation,
  setProductAlternateIdAsPrimaryMutation,
} from 'OK/networking/products';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';
import usePermission, { PERMISSIONS } from 'OK/util/hooks/usePermission';

const MODE = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
};

export default function AlternateIDsEditor(props) {
  /* Variables */

  const { asset, assetType } = props;
  const { t } = useI18n();
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const [, , user] = useAuthentication(() => false);
  const hasAlternateIDPermission = usePermission(PERMISSIONS.SET_ITEM_ALTERNATE_ID, user, activeOrganisationId);

  // Config setup
  const assetTypeMappings = useMemo(
    () => ({
      item: {
        alternateIdList: asset.itemAlternateIdList,
        createAlternateIdMutation: createItemAlternateIDMutation,
        removeAlternateIdMutation: removeItemAlternateIDMutation,
        setAlternateIdAsPrimaryMutation: setItemAlternateIdAsPrimaryMutation,
        mutationObject: (alternateId) => ({
          variables: {
            itemAlternateId: alternateId,
            itemId: asset.id,
          },
        }),
      },
      product: {
        alternateIdList: asset.alternateIds && Object.keys(asset.alternateIds),
        primaryAlternateId:
          asset.alternateIds &&
          Object.keys(asset.alternateIds).find((alternateId) => asset.alternateIds[alternateId] == true),
        createAlternateIdMutation: createProductAlternateIDMutation,
        removeAlternateIdMutation: removeProductAlternateIDMutation,
        setAlternateIdAsPrimaryMutation: setProductAlternateIdAsPrimaryMutation,
        mutationObject: (alternateId) => ({
          variables: {
            alternateId: alternateId,
            productId: asset.id,
          },
        }),
      },
    }),
    [asset.alternateIds, asset.id, asset.itemAlternateIdList]
  );

  /* API */

  const [saveNewAlternateIDAPI] = useMutation(assetTypeMappings[assetType].createAlternateIdMutation);
  const [removeAlternateIDAPI] = useMutation(assetTypeMappings[assetType].removeAlternateIdMutation);
  const [setAlternateIDAsPrimaryAPI] = useMutation(assetTypeMappings[assetType].setAlternateIdAsPrimaryMutation);

  /* State */

  const [error, setError] = useState(null);
  const [mode, setMode] = useState(MODE.VIEW);
  const [newAlternateID, setNewAlternateID] = useState('');

  /* Methods */

  const removeAlternateID = useCallback(
    (alternateID) => {
      setError(null);
      removeAlternateIDAPI(assetTypeMappings[assetType].mutationObject(alternateID)).catch((e) => {
        okerror('Error removing alternate ID:', e);
        setError(t('ERROR_GENERIC'));
      });
    },
    [assetType, assetTypeMappings, removeAlternateIDAPI, t]
  );

  const saveNewAlternateID = useCallback(() => {
    setError(null);
    saveNewAlternateIDAPI(assetTypeMappings[assetType].mutationObject(newAlternateID))
      .then(() => {
        setMode(MODE.VIEW);
        setNewAlternateID('');
      })
      .catch((e) => {
        okerror('Error saving alternate ID:', e);
        setError(t('ERROR_GENERIC'));
      });
  }, [assetType, assetTypeMappings, newAlternateID, saveNewAlternateIDAPI, t]);

  const setAlternateIDAsPrimary = useCallback(
    (alternateId) => {
      setError(null);
      setAlternateIDAsPrimaryAPI(assetTypeMappings[assetType].mutationObject(alternateId)).catch((e) => {
        okerror('Error setting alternate ID as primary:', e);
        setError(t('ERROR_GENERIC'));
      });
    },
    [assetType, assetTypeMappings, setAlternateIDAsPrimaryAPI, t]
  );

  const startEditing = useCallback(() => {
    setMode(MODE.EDIT);
  }, []);

  const stopEditing = useCallback(() => {
    setNewAlternateID('');
    setMode(MODE.VIEW);
  }, []);

  // Event handlers

  const onChangeNewAlternateID = useCallback((e) => {
    setNewAlternateID(e.target.value);
  }, []);

  return (
    <div>
      {assetType === 'item'
        ? Object.entries(assetTypeMappings[assetType].alternateIdList).map(([alternateId, isPrimary]) => (
          <div key={alternateId}>
            <Text className={styles.tag}>
              <Icon inline name={ICONS.TAG.name} /> {alternateId}
              {isPrimary && (
                <Tag className={styles.primaryTag}>{t('PRIMARY')}</Tag>
              )}
            </Text>
            {hasAlternateIDPermission && (
              <>
                <Button
                  className={styles.removeButton}
                  linkStyle
                  onClick={() => removeAlternateID(alternateId)}
                  tint='alert'
                >
                  {t('REMOVE')}
                </Button>
                {!isPrimary && (
                  <Button
                    className={styles.primaryButton}
                    linkStyle
                    onClick={() => setAlternateIDAsPrimary(alternateId)}
                    tint='navigation'
                  >
                    {t('SET_AS_PRIMARY')}
                  </Button>
                )}
              </>
            )}
          </div>
        ))
        : assetTypeMappings[assetType].alternateIdList?.map((alternateId) => (
          <div key={alternateId}>
            <Text className={styles.tag}>
              <Icon inline name={ICONS.TAG.name} /> {alternateId}
              {alternateId === assetTypeMappings[assetType].primaryAlternateId && (
                <Tag className={styles.primaryTag}>{t('PRIMARY')}</Tag>
              )}
            </Text>
            {hasAlternateIDPermission && (
              <>
                <Button
                  className={styles.removeButton}
                  linkStyle
                  onClick={() => removeAlternateID(alternateId)}
                  tint='alert'
                >
                  {t('REMOVE')}
                </Button>
                {alternateId !== assetTypeMappings[assetType].primaryAlternateId && (
                  <Button
                    className={styles.primaryButton}
                    linkStyle
                    onClick={() => setAlternateIDAsPrimary(alternateId)}
                    tint='navigation'
                  >
                    {t('SET_AS_PRIMARY')}
                  </Button>
                )}
              </>
            )}
          </div>
        ))}
      {hasAlternateIDPermission && (
        <div>
          {mode === MODE.EDIT && (
            <Input
              className={styles.input}
              onChange={onChangeNewAlternateID}
              placeholder={t('ENTER_ALTERNATE_ID_PLACEHOLDER')}
              value={newAlternateID}
            />
          )}
          <Button
            block
            className={styles.saveButton}
            icon={ICONS.PLUS.name}
            onClick={mode === MODE.EDIT ? saveNewAlternateID : startEditing}
            tint='creation'
          >
            {mode === MODE.EDIT ? t('SAVE_ALT_ID') : t('ADD_ALT_ID')}
          </Button>
          {mode === MODE.EDIT && (
            <Button block className={styles.cancelButton} onClick={stopEditing} tint='alert'>
              {t('CANCEL')}
            </Button>
          )}
          {error && (
            <Text className={styles.errorMessage} size='sm' tint='alert'>
              {error}
            </Text>
          )}
        </div>
      )}
    </div>
  );
}

AlternateIDsEditor.propTypes = {
  asset: PropTypes.object.isRequired,
  assetType: PropTypes.string.isRequired,
};
