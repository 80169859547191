import HCaptcha from '@hcaptcha/react-hcaptcha';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import OtpInput from 'OK/components/input/OTPInput';
import Link, { LinkNoRedux } from 'OK/components/link';
import Text from 'OK/components/text';
import config from 'OK/config/app';
import { login, verifyLoginCode } from 'OK/networking/auth';
import {
  lockAction,
  loginAction,
  clearSessionAction,
  verifyingOTPAction,
  resetLoginModal,
} from 'OK/state/account/actions';
import { showAuthModal } from 'OK/state/app/actions';
import { trackEvent } from 'OK/util/analytics';
import useBrowserRenderOnly from 'OK/util/hooks/useBrowserRenderOnly';
import useI18n from 'OK/util/hooks/useI18n';
import { r } from 'OK/util/routing';

export default function Verify(props) {
  /* Variables */

  const {
    allowNewOTPRequestDate,
    attemptsRemaining,
    changeLoginMethod,
    className,
    countryCode,
    expirationDate,
    isNewUser,
    newsletterSubscription,
    username,
    usernameType,
    ...otherProps
  } = props;
  const checkIsExpiredInterval = useRef();
  const dispatch = useDispatch();
  const hCaptcha = useRef();
  const inputRef = useRef();
  const renderForBrowser = useBrowserRenderOnly();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const { t, tHTML } = useI18n();

  // State

  const [allowNewOTPRequest, setAllowNewOTPRequest] = useState(false);
  const [captchaError, setCaptchaError] = useState(null);
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingNewCode, setLoadingNewCode] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(5 * 60);
  const [secondsTillNewOTPAllowed, setSecondsTillNewOTPAllowed] = useState(null);

  // Refs

  const captchaTokenRef = useRef();

  /* Methods */

  const checkIsExpired = useCallback(() => {
    if (attemptsRemaining === 0) {
      return true;
    }

    const timeLeft = (Date.now() - expirationDate.getTime()) * -1;
    const secondsTillExpiration = parseInt(timeLeft / 1000);
    if (secondsTillExpiration > 0) {
      setSecondsLeft(secondsTillExpiration);
    } else {
      expireToken();
      return true;
    }

    return false;
  }, [attemptsRemaining, expirationDate, expireToken]);

  const clearExpireCheckInterval = useCallback((interval) => {
    clearInterval(interval);
  }, []);

  const expireToken = useCallback(() => {
    setExpired(true);
    setError(t('ERROR_VERIFY_ATTEMPT_EXPIRED'));
    setSecondsLeft(0);
    if (checkIsExpiredInterval.current) {
      clearExpireCheckInterval(checkIsExpiredInterval.current);
    }
  }, [clearExpireCheckInterval, t]);

  const handleInput = (e) => {
    setCode(e);
    setError(null);
  };

  const triggerCaptcha = useCallback(() => {
    setCaptchaError(t('ANTIBOT_TOKEN_INVALID_OR_MISSING'));
    hCaptcha.current?.execute();
  }, [t]);

  const setCaptchaToken = useCallback((token) => {
    setCaptchaError(null);
    captchaTokenRef.current = token;

    if (!token) {
      hCaptcha.current?.resetCaptcha();
    }
  }, []);

  const requestNewCode = useCallback(async () => {
    if (!captchaTokenRef.current) {
      triggerCaptcha();
      return;
    }

    setError(null);
    setLoadingNewCode(true);
    try {
      const response = await login(username, usernameType, countryCode, captchaTokenRef.current);
      setLoadingNewCode(false);
      if (response.success || response.error === 'USER_OTP_TIMEOUT') {
        // OTP requested successfully
        const { availableAt, attemptLeft, expiresAt } = response.responseData;
        const expiryDate = new Date(expiresAt);
        dispatch(
          verifyingOTPAction(
            username,
            usernameType,
            attemptLeft,
            expiryDate,
            code,
            true,
            availableAt,
            newsletterSubscription
          )
        );
      } else {
        setCaptchaToken(null);
        switch (response.error) {
          case 'ANTIBOT_TOKEN_INVALID_OR_MISSING':
            setCaptchaError(t('ANTIBOT_TOKEN_INVALID_OR_MISSING'));
            break;
          case 'USER_OTP_REACHED_LIMIT_TIMEOUT':
            dispatch(lockAction(new Date(response.responseData.availableAt)));
            break;
          default:
            setError(t(response.error ?? 'ERROR_GENERIC'));
            break;
        }
      }
    } catch {
      setLoadingNewCode(false);
      setError(t('ERROR_GENERIC'));
    }
  }, [code, countryCode, dispatch, newsletterSubscription, setCaptchaToken, t, triggerCaptcha, username, usernameType]);

  const verifyCode = async (e) => {
    e && e.preventDefault();

    if (!code) {
      setError(t('VERIFY_EMPTY_CODE'));
      return;
    }

    setError(null);
    setLoading(true);
    try {
      const response = await verifyLoginCode(username, usernameType, code, countryCode, newsletterSubscription);
      if (response.success) {
        const { accessToken } = response.responseData;
        // Successful verification
        dispatch(loginAction(accessToken, false));
        trackEvent('OTP Verified');
      } else {
        switch (response.error) {
          case 'USER_OTP_INCORRECT':
          case 'USER_FAILED_REQUEST': {
            const { availableAt, attemptLeft, expiresAt } = response.responseData;
            dispatch(
              verifyingOTPAction(
                username,
                usernameType,
                attemptLeft,
                new Date(expiresAt),
                countryCode,
                isNewUser,
                availableAt,
                newsletterSubscription
              )
            );
            setError(t('ERROR_OTP_CODE_MISMATCH'));
            break;
          }
          case 'USER_OTP_EXPIRED':
            expireToken();
            break;
          case 'USER_OTP_REACHED_LIMIT_TIMEOUT':
            dispatch(lockAction(new Date(response.responseData.availableAt)));
            break;
          case 'USER_OTP_MISSING':
            dispatch(clearSessionAction(t('ERROR_GENERIC')));
            break;
          default:
            setError(t(response.error ?? 'ERROR_GENERIC'));
            break;
        }
      }
    } catch {
      setError(t('ERROR_GENERIC'));
    } finally {
      setLoading(false);
    }
  };

  // Event handlers

  const onCaptchaExpiration = useCallback(() => {
    setCaptchaToken(null);
  }, [setCaptchaToken]);

  const onCaptchaVerification = useCallback(
    (newToken) => {
      setCaptchaToken(newToken);
      requestNewCode();
    },
    [requestNewCode, setCaptchaToken]
  );

  /* Effects */

  // Expire the session at the appropriate time
  useEffect(() => {
    const isExpired = checkIsExpired();
    setExpired(isExpired);

    if (checkIsExpiredInterval.current) {
      clearExpireCheckInterval(checkIsExpiredInterval.current);
    }

    let currentInterval;
    if (!isExpired) {
      checkIsExpiredInterval.current = setInterval(() => {
        checkIsExpired();
      }, 1000);
      currentInterval = checkIsExpiredInterval.current;
    }

    return function cleanup() {
      if (currentInterval) {
        clearExpireCheckInterval(currentInterval);
      }
    };
  }, [checkIsExpired, clearExpireCheckInterval, expirationDate]);

  // Scroll to top on first load
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  // Focus input on first load
  useLayoutEffect(() => {
    inputRef.current.focus();
  }, []);

  // Allow requesting new OTP when backend indicates it is available
  useEffect(() => {
    let interval;
    // Allow requesting new OTP
    const allow = () => {
      setAllowNewOTPRequest(true);
      setSecondsTillNewOTPAllowed(0);
      clearInterval(interval);
    };

    if (allowNewOTPRequestDate) {
      const secTillAllowed = differenceInSeconds(new Date(allowNewOTPRequestDate), new Date());
      if (secTillAllowed > 0) {
        // Update UI to indicate seconds till allowed
        const updateSecondsTillAllowed = () => {
          const updatedSecTillAllowed = differenceInSeconds(new Date(allowNewOTPRequestDate), new Date());
          if (updatedSecTillAllowed > 0) {
            setSecondsTillNewOTPAllowed(updatedSecTillAllowed);
          } else {
            allow();
          }
        };
        // Show current seconds
        updateSecondsTillAllowed();
        // Check and update UI every second
        interval = setInterval(() => {
          updateSecondsTillAllowed();
        }, 1000);
      } else {
        // Allow immediately
        allow();
      }
    }

    return function () {
      clearInterval(interval);
      setAllowNewOTPRequest(false);
    };
  }, [allowNewOTPRequestDate]);

  /* Render */

  const classNames = `${styles.container}${className ? ` ${className}` : ''}`;

  let verifyButtonLabel;
  if (loading) {
    verifyButtonLabel = t('AUTH_BUTTON_VERIFYING');
  } else if (loadingNewCode) {
    verifyButtonLabel = t('AUTH_BUTTON_REQUESTING_OTP');
  } else {
    verifyButtonLabel = t('AUTH_BUTTON_VERIFY');
  }

  return (
    <div className={classNames} fixedWidth={useDesktopLayout} {...otherProps}>
      <form onSubmit={verifyCode}>
        <div className={styles.header}>
          <h3>{usernameType === 'mobile' ? t('AUTH_ENTER_SMS_CODE') : t('AUTH_ENTER_EMAIL_CODE')}</h3>
          <Button
            className={styles.actionButtonUpper}
            linkStyle
            tint='alert'
            onClick={() => {
              dispatch(showAuthModal(false));
              dispatch(resetLoginModal());
            }}
          >
            {t('CANCEL')}
          </Button>
        </div>
        <p>
          <div className={styles.username}>
            {tHTML('ENTER_CODE_SENT_TO', {
              data: {
                username: <strong>{usernameType === 'mobile' ? `+${countryCode}-${username}` : username}</strong>,
              },
            })}
          </div>
          &nbsp;
          <Button className={styles.changeUsernameButton} linkStyle onClick={() => changeLoginMethod(usernameType)}>
            {t('CHANGE')}
          </Button>
        </p>
        <p>
          {tHTML('AUTH_ATTEMPTS_AND_SECONDS_LEFT', {
            data: {
              attempts: attemptsRemaining,
              seconds: secondsLeft,
            },
          })}
        </p>
        <div className={styles.codeInput}>
          <OtpInput
            ref={inputRef}
            value={code}
            valueLength={6}
            onComplete={verifyCode}
            onChange={handleInput}
            disabled={expired}
            hasError={!!error}
          />
        </div>
        {error && (
          <Text className={styles.errorMessage} tint='alert'>
            {error}
          </Text>
        )}

        <Button
          block
          className={styles.actionButton}
          disabled={loading || expired}
          loading={loading}
          onClick={verifyCode}
          withCaret
        >
          {verifyButtonLabel}
        </Button>
        <Button
          block
          className={styles.linkButton}
          disabled={loadingNewCode || !allowNewOTPRequest}
          linkStyle
          loading={loadingNewCode}
          onClick={requestNewCode}
        >
          {usernameType === 'email' ? t('AUTH_GET_NEW_EMAIL_CODE') : t('AUTH_GET_NEW_SMS_CODE')}&nbsp;
          {secondsTillNewOTPAllowed > 0 ? `(${secondsTillNewOTPAllowed})` : ''}
        </Button>
        {renderForBrowser && (
          <HCaptcha
            id='captcha'
            onError={() => setCaptchaError(t('ANTIBOT_TOKEN_INVALID_OR_MISSING'))}
            onExpire={onCaptchaExpiration}
            onVerify={onCaptchaVerification}
            reCaptchaCompat={false}
            ref={hCaptcha}
            sitekey={config.hCaptchaSiteKey}
            size='invisible'
          />
        )}
        <Button
          className={styles.linkButton}
          linkStyle
          disabled={loading}
          onClick={() => {
            usernameType === 'email' ? changeLoginMethod('mobile') : changeLoginMethod('email');
          }}
        >
          {usernameType === 'email' ? t('AUTH_SWITCH_METHOD_SMS') : t('AUTH_SWITCH_METHOD_EMAIL')}
        </Button>
        <Link
          block
          className={styles.linkButton}
          href='/solutions/contact'
          withCaret
          style={{ justifyContent: 'space-between', width: '75%' }}
        >
          {t('REQUEST_ASSISTANCE')}
        </Link>
        <p className={`${styles.textXs} ${styles.fineprint}`}>
          {usernameType === 'mobile' && <>{t('SMS_RATES_APPLY')}&nbsp;</>}
          {tHTML('AUTH_TERMS_FINEPRINT', {
            data: {
              tos: (
                <LinkNoRedux className={styles.link} href={r('/solutions/terms')}>
                  {t('TERMS_OF_SERVICE_LOWERCASE')}
                </LinkNoRedux>
              ),
              privacy: (
                <LinkNoRedux className={styles.link} href={r('/solutions/terms')}>
                  {t('PRIVACY_POLICY_LOWERCASE')}
                </LinkNoRedux>
              ),
              cookie: (
                <LinkNoRedux className={styles.link} href={r('/solutions/terms')}>
                  {t('COOKIES_LOWERCASE')}
                </LinkNoRedux>
              ),
            },
          })}
        </p>
      </form>
    </div>
  );
}

Verify.propTypes = {
  allowNewOTPRequestDate: PropTypes.number.isRequired,
  attemptsRemaining: PropTypes.number.isRequired,
  changeLoginMethod: PropTypes.func.isRequired,
  className: PropTypes.string,
  countryCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expirationDate: PropTypes.instanceOf(Date).isRequired,
  isNewUser: PropTypes.bool.isRequired,
  newsletterSubscription: PropTypes.bool,
  username: PropTypes.string.isRequired,
  usernameType: PropTypes.string.isRequired,
};
