import { useApolloClient, useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import ColumnCard from 'OK/components/archiveCard/ColumnCard';
import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import LinkToFile from 'OK/components/link/linkToFile';
import MediaPicker from 'OK/components/mediaPicker';
import Notice from 'OK/components/notice';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Text from 'OK/components/text';
import appConfig from 'OK/config/app';
import DocumentModel from 'OK/models/document';
import ProductModel from 'OK/models/product';
import ProductDocumentModel from 'OK/models/productDocument';
import { createProductDocumentAssetRequest } from 'OK/networking/products';
import {
  useLinkProductDocumentAPI,
  usePublishProductDocumentAPI,
  useUnlinkProductDocumentAPI,
  useUnpublishProductDocumentAPI,
} from 'OK/networking/products/hooks';
import { searchQuery } from 'OK/networking/search';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import useI18n from 'OK/util/hooks/useI18n';

export default function ProductPageDocumentationSection(props) {
  const { apiResult, isEditor, product, productDocuments, productId, productFiles } = props;

  const apolloClient = useApolloClient();
  const { t, tHTML } = useI18n();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);

  const validProductFiles = productFiles.filter((pf) => pf?.documentAsset?.REFID !== null);

  // Refs

  const mediaPickerRef = useRef();

  // States

  const [createDocumentError, setCreateDocumentError] = useState(null);
  const [isUploadingNewDocument, setIsUploadingNewDocument] = useState(false);
  const [productDocumentsErrors, setProductDocumentsErrors] = useState([]);
  const [searchDocumentsFocused, setSearchDocumentsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // API

  const productCacheId = `${ProductModel.GRAPHQL_TYPE}:${productId}`;

  const linkDocumentAPI = useLinkProductDocumentAPI();
  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);
  const publishDocumentAPI = usePublishProductDocumentAPI();
  const unlinkDocumentAPI = useUnlinkProductDocumentAPI();
  const unpublishDocumentAPI = useUnpublishProductDocumentAPI();

  // Methods

  const addErrorForDocument = useCallback(
    (documentId, errorKey, errorMessage) => {
      const indexOfErrorsForDocument = productDocumentsErrors.findIndex((pde) => pde.documentId === documentId);
      let errorsForDocument;
      let updatedProductDocumentsErrors;
      if (indexOfErrorsForDocument > -1) {
        errorsForDocument = productDocumentsErrors[indexOfErrorsForDocument];
        errorsForDocument[errorKey] = errorMessage;
        updatedProductDocumentsErrors = [...productDocumentsErrors];
        updatedProductDocumentsErrors.splice(indexOfErrorsForDocument, 1, errorsForDocument);
      } else {
        errorsForDocument = {
          documentId,
          [errorKey]: errorMessage,
        };
        updatedProductDocumentsErrors = [...productDocumentsErrors, errorsForDocument];
      }

      setProductDocumentsErrors(updatedProductDocumentsErrors);
    },
    [productDocumentsErrors]
  );

  const createDocument = async (files) => {
    if (files.length > 0) {
      const file = files[0];
      setIsUploadingNewDocument(true);
      setCreateDocumentError(null);
      try {
        const response = await createProductDocumentAssetRequest(productId, file, 'PRODUCT_FILE');
        if (response.success) {
          // Create DocumentAsset reference in cache
          const documentAsset = {
            ...response.responseData.documentAsset,
            REFID: response.responseData.documentAsset.refid,
            __typename: DocumentModel.GRAPHQL_TYPE,
          };
          const documentAssetRef = apolloClient.cache.writeFragment({
            id: apolloClient.cache.identify(documentAsset),
            fragment: DocumentModel.fragment,
            fragmentName: DocumentModel.fragmentName,
            data: documentAsset,
          });

          // Link new document to product in the cache
          const productDocument = {
            ...response.responseData,
            documentAsset: documentAssetRef,
            __typename: ProductDocumentModel.GRAPHQL_TYPE,
          };
          apolloClient.cache.modify({
            id: productCacheId,
            fields: {
              productDocumentAssetList: (currentList = []) => {
                return [...currentList, productDocument];
              },
            },
          });
        } else {
          okerror('Error creating document.');
          setCreateDocumentError(t(response.error ?? 'ERROR_GENERIC'));
        }
      } catch (e) {
        okerror('Error creating document', e);
        setCreateDocumentError(t('ERROR_GENERIC'));
      } finally {
        setIsUploadingNewDocument(false);
      }
    }
  };

  const removeErrorForDocument = useCallback(
    (documentId, errorKey) => {
      const indexOfErrorsForDocument = productDocumentsErrors.findIndex((pde) => pde.documentId === documentId);
      if (indexOfErrorsForDocument > -1) {
        const errorsForDocument = {
          ...productDocumentsErrors[indexOfErrorsForDocument],
        };
        delete errorsForDocument[errorKey];
        const updatedProductDocumentsErrors = [...productDocumentsErrors];
        updatedProductDocumentsErrors.splice(indexOfErrorsForDocument, 1, errorsForDocument);
        setProductDocumentsErrors(updatedProductDocumentsErrors);
      }
    },
    [productDocumentsErrors]
  );

  const publishDocument = useCallback(
    (documentId) => {
      // Clear publish related errors
      removeErrorForDocument(documentId, 'publishError');

      // Call API
      publishDocumentAPI(product, documentId).catch(() => {
        addErrorForDocument(documentId, 'publishError', t('UPDATE_FAILED_CONNECTION'));
      });
    },
    [addErrorForDocument, product, publishDocumentAPI, removeErrorForDocument, t]
  );

  const unpublishDocument = useCallback(
    (documentId) => {
      // Clear publish related errors
      removeErrorForDocument(documentId, 'publishError');

      // Call API
      unpublishDocumentAPI(product, documentId).catch(() => {
        addErrorForDocument(documentId, 'publishError', t('UPDATE_FAILED_CONNECTION'));
      });
    },
    [addErrorForDocument, product, removeErrorForDocument, t, unpublishDocumentAPI]
  );

  const onChangeDocumentPublished = useCallback(
    (documentId, published) => {
      if (!published) {
        publishDocument(documentId);
      } else {
        unpublishDocument(documentId);
      }
      apiResult.refetch();
    },
    [apiResult, publishDocument, unpublishDocument]
  );

  const searchDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'DOCUMENT_ASSET',
                ignoreIdList: [...productDocuments.map((pd) => pd.documentAsset.id)],
              },
            ],
            searchPaginationDataByDataType: [
              {
                dataType: 'DOCUMENT_ASSET',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [productDocuments, searchAPI]
  );

  const onClickLinkDocument = useCallback(
    (documentAssetId) => {
      setSearchTerm('');
      linkDocumentAPI(product.id, documentAssetId);
    },
    [linkDocumentAPI, product.id]
  );

  const onSearch = useCallback(
    (e) => {
      const searchString = e.target.value;
      setSearchTerm(searchString);

      if (searchString.length > 1) {
        searchDebounced(searchString);
      }
    },
    [searchDebounced]
  );

  const openFilePicker = () => {
    mediaPickerRef.current.open();
  };

  const unlinkDocument = (documentId) => {
    // Clear unlink related errors
    removeErrorForDocument(documentId, 'unlinkError');

    // Call API
    unlinkDocumentAPI(product, documentId).catch(() => {
      addErrorForDocument(documentId, 'unlinkError', t('UPDATE_FAILED_CONNECTION'));
    });
  };

  const searchSuggestions = useMemo(() => {
    if (searchDocumentsFocused && searchTerm.length > 1) {
      return (
        searchAPIResult.data?.search?.resultList?.map((r) => {
          const documentAsset = r.documentAssetData;
          return {
            icon: ICONS.DOCUMENT.name,
            key: documentAsset.id,
            title: documentAsset.documentName,
            subtitle: documentAsset.REFID,
          };
        }) ?? []
      );
    }

    return [];
  }, [searchAPIResult.data?.search?.resultList, searchDocumentsFocused, searchTerm.length]);

  // Render

  const documentsNotice = (
    <Notice className={styles.sectionNotice}>
      <p>{tHTML('SUPPORTED_DOCUMENT_FORMATS')}</p>
      <Text tint='notification'>
        {t('DOCUMENT_MAX_FILESIZE', { data: { number: appConfig.fileSizeLimitsMB.document } })}
      </Text>
    </Notice>
  );

  return (
    <ContentLayout className={styles.sectionCardContent} pageContent>
      {isEditor ? (
        <TextLayout style={{ padding: 0 }}>
          {useDesktopLayout && documentsNotice}
          <div className={styles.sectionIntro}>
            <p>{t('PRODUCT_SECTION_DOCUMENTATION_DESCRIPTION')}</p>
            <div className={styles.searchInputContainer}>
              <SearchInput
                className={styles.searchInput}
                loading={searchAPIResult.loading}
                onBlur={() => setSearchDocumentsFocused(false)}
                onChange={onSearch}
                onFocus={() => setSearchDocumentsFocused(true)}
                placeholder={t('DOCUMENTATION_SEARCH_PLACEHOLDER')}
                value={searchTerm}
              />
              <Button
                className={styles.searchNewButton}
                disabled={isUploadingNewDocument}
                loading={isUploadingNewDocument}
                linkStyle
                onClick={openFilePicker}
                spanClassName={styles.searchNewButtonSpan}
                tint='creation'
              >
                {t('NEW')}
              </Button>
              <SearchSuggestions
                accessoryViewRender={() => (
                  <Button className={styles.searchResultButton} icon='/icons/link_blue.svg' linkStyle>
                    {t('LINK')}
                  </Button>
                )}
                className={styles.searchResults}
                highlightTerm={searchTerm}
                onSuggestionClick={onClickLinkDocument}
                showNoResultsMessage={searchDocumentsFocused && searchTerm.length > 1 && searchSuggestions.length === 0}
                showMoreResultsMessage={
                  searchDocumentsFocused &&
                  searchAPIResult?.data?.search?.searchPaginationResultDataByDataType?.DOCUMENT_ASSET?.totalResults >
                    searchSuggestions.length
                }
                subtitleClassName={styles.searchResultOKID}
                suggestions={searchSuggestions}
              />
              <MediaPicker invisible mediaTypes={['document']} onChange={createDocument} ref={mediaPickerRef} />
            </div>
            {createDocumentError && (
              <Text className={styles.errorMessage} size='sm' tint='alert'>
                {createDocumentError}
              </Text>
            )}
          </div>
        </TextLayout>
      ) : null}
      {validProductFiles.length > 0 ? (
        <Carousel
          className={styles.documentCarouselSecondary}
          innerClassName={styles.documentCarouselInnerSecondary}
          fadeOutSides={useDesktopLayout}
        >
          {validProductFiles.map((pf) => {
            return (
              <Slide key={pf.id} className={styles.documentSlideSecondary}>
                <LinkToFile
                  className={styles.cardContainer}
                  key={pf.documentAsset.id}
                  file={pf.documentAsset}
                  linkToDownload
                >
                  <ColumnCard
                    asset={pf.documentAsset}
                    assetType='file'
                    editMode={isEditor}
                    noLink
                    onChangeDocumentPublished={onChangeDocumentPublished}
                    onClickEdit
                    onClickUnlink={unlinkDocument}
                    product={product}
                    publishDocument={publishDocument}
                    published={pf.publishStatus === PUBLISH_STATUS.PUBLISHED}
                    unpublishDocument={unpublishDocument}
                  />
                </LinkToFile>
              </Slide>
            );
          })}
        </Carousel>
      ) : (
        <Text>{t('NO_FILES')}</Text>
      )}
    </ContentLayout>
  );
}

ProductPageDocumentationSection.propTypes = {
  apiResult: PropTypes.any,
  isEditor: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  productDocuments: PropTypes.array.isRequired,
  productFiles: PropTypes.array,
  productId: PropTypes.string.isRequired,
};
