import { gql } from '@apollo/client';

import { request } from '..';
import { API_URL } from '../utils';

import ContactModel from 'OK/models/contact';
import InspectionLogFindingModel from 'OK/models/inspectionLogFinding';
import InspectionLogNoteAssetModel from 'OK/models/inspectionLogNoteAsset';
import InspectionLogTestAssetModel from 'OK/models/inspectionLogTestAsset';
import ItemModel from 'OK/models/item';
import OrganisationModel from 'OK/models/organisation';
import ProductModel from 'OK/models/product';
import SiteModel from 'OK/models/site';
import UserModel from 'OK/models/user';
import getAudioMetadataFromFile from 'OK/util/functions/getAudioMetadataFromFile';
import getImageDimensionsFromFile from 'OK/util/functions/getImageDimensionsFromFile';
import getVideoMetadataFromFile from 'OK/util/functions/getVideoMetadataFromFile';

/* Queries */

export const AggregatedInspectionLogDataForInspectionQuery = gql`
  query AggregatedInspectionLogDataForInspection(
    $filterByInspectorIdList: [String]
    $filterBySourceIdList: [String]
    $includeInternalLogs: Boolean!
    $inspectionAssetId: String!
  ) {
    aggregatedData: aggregatedInspectionLogDataUnderInspectionAsset(
      filterByInspectorIdList: $filterByInspectorIdList
      filterBySourceIdList: $filterBySourceIdList
      includeInternalLogs: $includeInternalLogs
      inspectionAssetId: $inspectionAssetId
    ) {
      averageReliabilityPoint
      averageSampleCriticalFailures
      averageSampleMajorFailures
      averageSampleMinorFailures
      lotCriticalAQL
      lotMajorAQL
      lotMinorAQL
      sampleCriticalAQL
      sampleMajorAQL
      sampleMinorAQL
    }
  }
`;

export const AggregatedInspectionLogDataForOrganisationQuery = gql`
  query AggregatedInspectionLogDataForOrganisation(
    $filterByInspectorIdList: [String]
    $filterBySourceIdList: [String]
    $includeInternalLogs: Boolean!
  ) {
    aggregatedData: aggregatedInspectionLogDataUnderOrganisation(
      filterByInspectorIdList: $filterByInspectorIdList
      filterBySourceIdList: $filterBySourceIdList
      includeInternalLogs: $includeInternalLogs
    ) {
      averageReliabilityPoint
      averageSampleCriticalFailures
      averageSampleMajorFailures
      averageSampleMinorFailures
      lotCriticalAQL
      lotMajorAQL
      lotMinorAQL
      sampleCriticalAQL
      sampleMajorAQL
      sampleMinorAQL
    }
  }
`;

export const AggregatedInspectionLogDataForTestQuery = gql`
  query AggregatedInspectionLogDataForTest(
    $filterByInspectorIdList: [String]
    $filterBySourceIdList: [String]
    $includeInternalLogs: Boolean!
    $testAssetId: String!
  ) {
    aggregatedData: aggregatedInspectionLogDataUnderTestAsset(
      filterByInspectorIdList: $filterByInspectorIdList
      filterBySourceIdList: $filterBySourceIdList
      includeInternalLogs: $includeInternalLogs
      testAssetId: $testAssetId
    ) {
      averageReliabilityPoint
      averageSampleCriticalFailures
      averageSampleMajorFailures
      averageSampleMinorFailures
      lotCriticalAQL
      lotMajorAQL
      lotMinorAQL
      sampleCriticalAQL
      sampleMajorAQL
      sampleMinorAQL
    }
  }
`;

export const InspectionLogArchivePageQuery = gql`
  query InspectionLogArchivePage($REFID: String!) {
    inspectionLog: inspectionLogWithREFID(REFID: $REFID) {
      REFID
      id
      assigneeList {
        active
        dueDate
        setByUserId
        setDate
        userId
      }
      assetAccessPermission
      assetAccessPermissionList {
        assetAccessPermission
        assetId
        assetType
        id
        sharedToAssetId
        sharedToAssetType
        sharedToOrganisation {
          id
          OKID
          name
          logoImageMediaAsset {
            logoImageURL
          }
        }
        sharedToUser {
          id
          OKID
          avatar {
            sourceDarkURL
            sourceLightURL
          }
        }
      }
      contactList {
        ...${ContactModel.fragmentNameContacts}
      }
      createdBy
      createdDate
      endDate
      failed
      inspectedSize
      inspectionAsset {
        id
        name
      }
      inspectionLogStage
      lotCriticalFailure
      lotFixableProjection
      lotFixableProjectionPercent
      lotFixedPercent
      lotFlawlessProjection
      lotFlawlessProjectionPercent
      lotMajorFailure
      lotMinorFailure
      lotRejectedPercent
      lotSize
      lotTolerableProjection
      lotTolerableProjectionPercent
      lotUnfixableProjection
      lotUnfixableProjectionPercent
      passedAsFixed
      passedAsFlawless
      passedAsTolerated
      rejected
      reliabilityPoint
      sampleCriticalFailures
      sampleCriticalInspected
      sampleMajorFailures
      sampleMajorInspected
      sampleMinorFailures
      sampleMinorInspected
      samplePassedAsFixedPercent
      samplePassedAsFlawlessPercent
      samplePassedAsToleratedPercent
      sampleRejectedPercent
      startDate
      sourceId
      sourceType
      inspectionLogDocumentAssetList {
        documentAsset {
          id
          assetAccessPermission
          coveredLanguageList
          documentType
          documentURL
          organisationId
          REFID
        }
        inspectionLogAssetSource
      }
      inspectionLogFindingList {
        id
      }
      inspectionLogNoteAssetList {
        ...${InspectionLogNoteAssetModel.fragmentNameLorealCard}
      }
      inspectionLogTestAssetList {
        ...${InspectionLogTestAssetModel.fragmentNameResultCard}
      }
      inspectionOrganisation {
        addressList {
          countryCode
        }
      }
      inspector {
        ...${UserModel.fragmentNameArchiveCard}
      }
      item {
        id
        assemblyItemList {
          item {
            ...${ItemModel.fragmentNameAssemblyArchiveCard}
          }
          quantity
        }
        OKID
        product {
          ...${ProductModel.fragmentNameArchiveCard}
        }
      }
      linkedOrder {
        REFID
      }
      organisation {
        ...${OrganisationModel.fragmentNameArchiveCard}
      }
      organisationId
      site {
        ...${SiteModel.fragmentNameArchiveCard}
      }
    }
  }
  ${InspectionLogNoteAssetModel.fragmentLorealCard}
  ${InspectionLogTestAssetModel.fragmentResultCard}
  ${UserModel.fragmentArchiveCard}
  ${ItemModel.fragmentAssemblyArchiveCard}
  ${ProductModel.fragmentArchiveCard}
  ${OrganisationModel.fragmentArchiveCard}
  ${SiteModel.fragmentArchiveCard}
  ${ContactModel.fragmentContacts}

`;

export const InspectionLogMediaGalleryPopupQuery = gql`
  query InspectionLogMediaGalleryPopup($REFID: String!) {
    inspectionLog: inspectionLogWithREFID(REFID: $REFID) {
      id
      inspectionLogFindingList {
        ...${InspectionLogFindingModel.fragmentNameMediaGalleryPopup}
      }
      inspectionLogTestAssetList {
        id
        inspectionLogFindingList {
          ...${InspectionLogFindingModel.fragmentNameMediaGalleryPopup}
        }
      }
    }
  }
  ${InspectionLogFindingModel.fragmentMediaGalleryPopup}
`;

export const GetInspectionLogForGoPageQuery = gql`
  query GetInspectionLogForGoPage($REFID: String!) {
    inspectionLog: inspectionLogWithREFID(REFID: $REFID) {
      id
      assigneeList {
        active
        dueDate
        setByUserId
        setDate
        userId
      }
      inspectedSize
      contactList {
        ...${ContactModel.fragmentNameContacts}
      }
      lotSize
      endDate
      startDate
      organisationId
      passedAsFixed
      passedAsFlawless
      passedAsTolerated
      REFID
      rejected
      sourceId
      sourceType
      status
      inspectionLogStage
      inspectionLogDocumentAssetList {
        documentAsset {
          id
          applicableMarketList
          coveredLanguageList
          documentName
          documentNumber
          documentStandardList
          documentType
          madeByOrganisation {
            name
          }
          REFID
          validFrom
          validTill
        }
        inspectionLogAssetSource
      }
      inspectionLogFindingList {
        id
      }
      inspectionLogNoteAssetList {
        id
        taggedInspectionLogTestAssetId
        unversionedNoteAsset {
          textContentMap
        }
      }
      inspectionLogTestAssetList {
        ...${InspectionLogTestAssetModel.fragmentName}
      }
      inspectionOrganisation {
        id
        addressList {
          countryCode
        }
        conformityPoint
        name
        OKID
        reliabilityPointForPublishedLogs
      }
      inspectionOrganisationId
      inspector {
        OKID
        reliabilityPointForPublishedLogs
      }
      item {
        id
        assemblyItemList {
          item {
            ...${ItemModel.fragmentNameAssemblyArchiveCard}
          }
          quantity
        }
        OKID
        product {
          productChildProductList {
        childProduct {
          ...${ProductModel.fragmentNameArchiveCard}
        }
        publishStatus
        quantity
        assetLinkStatus
      }
          id
          conformityPoint
          name
          organisation {
            id
            addressList {
              countryCode
            }
          }
          
          productMediaAssetList {
            mediaAsset {
              imageData {
                imageURL
              }
            }
          }
        }
        reliabilityPointForPublishedLogs
      }
      site {
        id
        ...${SiteModel.fragmentNameArchiveCard}
      }
      linkedOrder {
        REFID
        id
        orderIdentifierList {
          id
          orderIdentifier
        }
      }

    }
  }
  ${InspectionLogTestAssetModel.fragment}
  ${ItemModel.fragmentAssemblyArchiveCard}
  ${ContactModel.fragmentContacts}
  ${SiteModel.fragmentArchiveCard}
  ${ProductModel.fragmentArchiveCard}
  
`;

export const GetInspectionLogsForOrganisationQuery = gql`
  query GetInspectionLogsForOrganisation(
    $endDateUTCString: String!
    $filterByInspectorIdList: [String]
    $filterBySourceIdList: [String]
    $filterBySourceTypeList: [EDataType]
    $includeInternalLogs: Boolean!
    $limit: Int!
    $organisationId: String!
    $skip: Int!
    $startDateUTCString: String!
  ) {
    result: filterInspectionLogsUnderOrganisation(
      endDateUTCString: $endDateUTCString
      filterByInspectorIdList: $filterByInspectorIdList
      filterBySourceIdList: $filterBySourceIdList
      filterBySourceTypeList: $filterBySourceTypeList
      includeInternalLogs: $includeInternalLogs
      limit: $limit
      organisationId: $organisationId
      skip: $skip
      startDateUTCString: $startDateUTCString
    ) {
      inspectionLogList {
        id
        endDate
        startDate
        assigneeList {
          active
          dueDate
          setByUserId
          setDate
          userId
        }
        failed
        inspectedSize
        lastModifiedDate
        lotSize
        REFID
        organisationId
        inspectionLogStage
        reliabilityPoint
        inspectionAsset {
          id
          name
        }
        inspectionLogDocumentAssetList {
          documentAsset {
            id
            REFID
            applicableMarketList
            conformityPoint
            coveredLanguageList
            createdBy
            createdDate
            documentName
            documentType
            documentURL
            linkedProductList {
              id
            }
            lastModifiedBy
            lastModifiedDate
            metadata
            organisationId
            status
            version
          }
        }
        inspectionLogNoteAssetList {
          id
        }
        inspector {
          OKID
          reliabilityPointForPublishedLogs
        }
        item {
          product {
            publishStatus
            REFID
            productMediaAssetList {
              mediaAsset {
                imageData {
                  imageURL
                }
              }
            }
          }
        }
        organisation {
          addressList {
            countryCode
          }
        }
        site {
          siteMediaAssetList {
            mediaAsset {
              imageData {
                imageURL
              }
            }
          }
        }
      }
      pageSize
      skip
      totalResults
    }
  }
`;

export const GetInspectionLogsForUserQuery = gql`
  query GetInspectionLogsForUser(
    $endDateUTCString: String!
    $filterByOrganisationIdList: [String]
    $filterBySourceIdList: [String]
    $filterBySourceTypeList: [EDataType]
    $includeInternalLogs: Boolean!
    $limit: Int!
    $userId: String!
    $skip: Int!
    $startDateUTCString: String!
  ) {
    result: filterInspectionLogsUnderUser(
      endDateUTCString: $endDateUTCString
      filterByOrganisationIdList: $filterByOrganisationIdList
      filterBySourceIdList: $filterBySourceIdList
      filterBySourceTypeList: $filterBySourceTypeList
      includeInternalLogs: $includeInternalLogs
      limit: $limit
      userId: $userId
      skip: $skip
      startDateUTCString: $startDateUTCString
    ) {
      inspectionLogList {
        id
        endDate
        startDate
        assigneeList {
          active
          dueDate
          setByUserId
          setDate
          userId
        }
        failed
        inspectedSize
        lastModifiedDate
        lotSize
        REFID
        organisationId
        inspectionLogStage
        reliabilityPoint
        inspectionAsset {
          id
          name
        }
        inspectionLogDocumentAssetList {
          documentAsset {
            id
            REFID
            applicableMarketList
            conformityPoint
            coveredLanguageList
            createdBy
            createdDate
            documentName
            documentType
            documentURL
            linkedProductList {
              id
            }
            lastModifiedBy
            lastModifiedDate
            metadata
            organisationId
            status
            version
          }
        }
        inspectionLogNoteAssetList {
          id
        }
        inspector {
          OKID
          reliabilityPointForPublishedLogs
        }
        item {
          product {
            publishStatus
            REFID
            productMediaAssetList {
              mediaAsset {
                imageData {
                  imageURL
                }
              }
            }
          }
        }
        organisation {
          addressList {
            countryCode
          }
        }
        site {
          siteMediaAssetList {
            mediaAsset {
              imageData {
                imageURL
              }
            }
          }
        }
      }
      pageSize
      skip
      totalResults
    }
  }
`;

export const GetNumberOfInspectionLogsPerDayForOrganisationQuery = gql`
  query GetNumberOfInspectionLogsPerDayForOrganisation(
    $endDateUTCString: String!
    $filterByInspectorIdList: [String]
    $filterBySourceIdList: [String]
    $filterBySourceTypeList: [EDataType]
    $includeInternalLogs: Boolean!
    $organisationId: String!
    $startDateUTCString: String!
  ) {
    inspectionLogHistory: getByDateInspectionLogCountUnderOrganisation(
      endDateUTCString: $endDateUTCString
      filterByInspectorIdList: $filterByInspectorIdList
      filterBySourceIdList: $filterBySourceIdList
      filterBySourceTypeList: $filterBySourceTypeList
      includeInternalLogs: $includeInternalLogs
      organisationId: $organisationId
      startDateUTCString: $startDateUTCString
    )
  }
`;

export const GetNumberOfInspectionLogsPerDayForUserQuery = gql`
  query GetNumberOfInspectionLogsPerDayForUser(
    $endDateUTCString: String!
    $filterByOrganisationIdList: [String]
    $filterBySourceIdList: [String]
    $filterBySourceTypeList: [EDataType]
    $includeInternalLogs: Boolean!
    $userId: String!
    $startDateUTCString: String!
  ) {
    inspectionLogHistory: getByDateInspectionLogCountUnderUser(
      endDateUTCString: $endDateUTCString
      filterByOrganisationIdList: $filterByOrganisationIdList
      filterBySourceIdList: $filterBySourceIdList
      filterBySourceTypeList: $filterBySourceTypeList
      includeInternalLogs: $includeInternalLogs
      userId: $userId
      startDateUTCString: $startDateUTCString
    )
  }
`;

export const LatestInspectionLogsForInspectionAssetQuery = gql`
  query LatestInspectionLogsForInspectionAsset(
    $filterByInspectorIdList: [String]
    $filterBySourceIdList: [String]
    $includeInternalLogs: Boolean!
    $inspectionAssetId: String!
  ) {
    inspectionLogs: latestInspectionLogsUnderInspectionAsset(
      filterByInspectorIdList: $filterByInspectorIdList
      filterBySourceIdList: $filterBySourceIdList
      includeInternalLogs: $includeInternalLogs
      inspectionAssetId: $inspectionAssetId
    ) {
      createdBy
      createdDate
      criticalAQL
      id
      majorAQL
      minorAQL
      reliabilityPoint
      sampleCriticalFailures
      sampleMajorFailures
      sampleMinorFailures
      sourceId
      sourceType
    }
  }
`;

export const LatestInspectionLogsForItemQuery = gql`
  query LatestInspectionLogsUnderItem(
    $filterByInspectorIdList: [String]
    $includeInternalLogs: Boolean!
    $itemId: String!
  ) {
    inspectionLogs: latestInspectionLogsUnderItem(
      filterByInspectorIdList: $filterByInspectorIdList
      includeInternalLogs: $includeInternalLogs
      itemId: $itemId
    ) {
      id
      endDate
      failed
      inspectedSize
      inspectionLogStage
      inspectionLogNoteAssetList {
        id
      }
      inspector {
        id
        OKID
        reliabilityPointForPublishedLogs
      }
      item {
        id
        product {
          id
          REFID
          productMediaAssetList {
            mediaAsset {
              imageData {
                imageURL
              }
            }
          }
        }
      }
      lastModifiedDate
      lotSize
      organisation {
        addressList {
          countryCode
        }
      }
      REFID
      reliabilityPoint
      startDate
    }
  }
`;

export const LatestInspectionLogsForOrganisationQuery = gql`
  query LatestInspectionLogsForOrganisation(
    $filterByInspectorIdList: [String]
    $filterBySourceIdList: [String]
    $includeInternalLogs: Boolean!
    $organisationId: String
  ) {
    inspectionLogs: latestInspectionLogsUnderOrganisation(
      filterByInspectorIdList: $filterByInspectorIdList
      filterBySourceIdList: $filterBySourceIdList
      includeInternalLogs: $includeInternalLogs
      organisationId: $organisationId
    ) {
      createdBy
      createdDate
      criticalAQL @client
      id
      majorAQL @client
      minorAQL @client
      reliabilityPoint
      sampleCriticalFailures
      sampleCriticalInspected
      sampleMajorFailures
      sampleMajorInspected
      sampleMinorFailures
      sampleMinorInspected
      sourceId
      sourceType
    }
  }
`;

export const LatestInspectionLogsForProductQuery = gql`
  query LatestInspectionLogsUnderProduct(
    $filterByInspectorIdList: [String]
    $includeInternalLogs: Boolean!
    $productId: String!
  ) {
    inspectionLogs: latestInspectionLogsUnderProduct(
      filterByInspectorIdList: $filterByInspectorIdList
      includeInternalLogs: $includeInternalLogs
      productId: $productId
    ) {
      id
      endDate
      failed
      inspectedSize
      inspectionLogStage
      inspectionLogNoteAssetList {
        id
      }
      inspector {
        id
        OKID
        reliabilityPointForPublishedLogs
      }
      item {
        id
        product {
          id
          REFID
          productMediaAssetList {
            mediaAsset {
              imageData {
                imageURL
              }
            }
          }
        }
      }
      lastModifiedDate
      lotSize
      organisation {
        addressList {
          countryCode
        }
      }
      REFID
      reliabilityPoint
      startDate
    }
  }
`;

export const LatestInspectionLogsForSiteQuery = gql`
  query LatestInspectionLogsUnderSite(
    $filterByInspectorIdList: [String]
    $includeInternalLogs: Boolean!
    $siteId: String!
  ) {
    inspectionLogs: latestInspectionLogsUnderSite(
      filterByInspectorIdList: $filterByInspectorIdList
      includeInternalLogs: $includeInternalLogs
      siteId: $siteId
    ) {
      id
      endDate
      failed
      inspectedSize
      inspectionLogStage
      inspectionAsset {
        id
        name
      }
      inspectionLogNoteAssetList {
        id
      }
      inspector {
        id
        OKID
        reliabilityPointForPublishedLogs
      }
      site {
        id
        OKID
        siteMediaAssetList {
          mediaAsset {
            imageData {
              imageURL
            }
          }
        }
      }
      startDate
      lastModifiedDate
      lotSize
      organisation {
        addressList {
          countryCode
        }
      }
      REFID
      reliabilityPoint
    }
  }
`;

export const LatestInspectionLogsForTestAssetQuery = gql`
  query LatestInspectionLogsForTestAsset(
    $filterByInspectorIdList: [String]
    $filterBySourceIdList: [String]
    $includeInternalLogs: Boolean!
    $testAssetId: String!
  ) {
    inspectionLogs: latestInspectionLogsUnderTestAsset(
      filterByInspectorIdList: $filterByInspectorIdList
      filterBySourceIdList: $filterBySourceIdList
      includeInternalLogs: $includeInternalLogs
      testAssetId: $testAssetId
    ) {
      createdBy
      createdDate
      criticalAQL @client
      id
      majorAQL @client
      minorAQL @client
      reliabilityPoint
      sampleCriticalFailures
      sampleCriticalInspected
      sampleMajorFailures
      sampleMajorInspected
      sampleMinorFailures
      sampleMinorInspected
      sourceId
      sourceType
    }
  }
`;

/* Mutations */

export const abortInspectionLogMutation = gql`
  mutation AbortInspectionLog {
    inspectionLog: abortInspectionLog {
      id
      status
    }
  }
`;

export const RemoveInspectionLogDocumentMutation = gql`
  mutation RemoveInspectionLogDocument($documentAssetId: String!) {
    inspectionLogDocumentAsset: removeInspectionLogDocumentAsset(documentAssetId: $documentAssetId) {
      documentAsset {
        id
      }
    }
  }
`;

export const RemoveInspectionLogDocumentAsManagerMutation = gql`
  mutation RemoveInspectionLogDocumentAsManager($inspectionLogId: String!, $documentAssetId: String!) {
    inspectionLogDocumentAsset: removeInspectionLogDocumentAssetAsOrganisationManager(
      inspectionLogId: $inspectionLogId
      documentAssetId: $documentAssetId
    ) {
      documentAsset {
        id
      }
    }
  }
`;

export const RemoveInspectionLogAssigneeDueDateMutation = gql`
  mutation RemoveInspectionLogAssigneeDueDate($assigneeUserId: String!, $inspectionLogId: String!) {
    inspectionLog: removeInspectionLogAssigneeDueDateAsManager(
      assigneeUserId: $assigneeUserId
      inspectionLogId: $inspectionLogId
    ) {
      id
      assigneeList {
        active
        dueDate
        setByUserId
        setDate
        userId
      }
    }
  }
`;

export const RemoveInspectionLogAssigneeAsAssigneeMutation = gql`
  mutation RemoveInspectionLogAssigneeAsAssignee {
    inspectionLog: removeInspectionLogAssigneeAsAssignee {
      id
      assigneeList {
        active
        dueDate
        setByUserId
        setDate
        userId
      }
    }
  }
`;

export const RemoveInspectionLogAssigneeAsManagerMutation = gql`
  mutation RemoveInspectionLogAssigneeAsManager($inspectionLogId: String!) {
    inspectionLog: removeInspectionLogAssigneeAsManager(inspectionLogId: $inspectionLogId) {
      id
      assigneeList {
        active
        dueDate
        setByUserId
        setDate
        userId
      }
    }
  }
`;

export const SetInspectionLogAssigneeAsAssigneeMutation = gql`
  mutation SetInspectionLogAssigneeAsAssignee($assigneeUserId: String!) {
    inspectionLog: setInspectionLogAssigneeAsAssignee(assigneeUserId: $assigneeUserId) {
      id
      assigneeList {
        active
        dueDate
        setByUserId
        setDate
        userId
      }
    }
  }
`;

export const SetInspectionLogAssigneeAsManagerMutation = gql`
  mutation SetInspectionLogAssigneeAsManager($assigneeUserId: String!, $inspectionLogId: String!) {
    inspectionLog: setInspectionLogAssigneeAsManager(
      assigneeUserId: $assigneeUserId
      inspectionLogId: $inspectionLogId
    ) {
      id
      assigneeList {
        active
        dueDate
        setByUserId
        setDate
        userId
      }
    }
  }
`;

export const SetInspectionLogAssigneeDueDateMutation = gql`
  mutation SetInspectionLogAssigneeDueDate($assigneeUserId: String!, $inspectionLogId: String!, $dueDate: DateTime!) {
    inspectionLog: setInspectionLogAssigneeDueDateAsManager(
      assigneeUserId: $assigneeUserId
      inspectionLogId: $inspectionLogId
      offsetDateTime: $dueDate
    ) {
      id
      assigneeList {
        active
        dueDate
        setByUserId
        setDate
        userId
      }
    }
  }
`;

export const setInspectionLogFixedCountMutation = gql`
  mutation SetInspectionLogFixedCount($count: Int!) {
    inspectionLog: setInspectionLogPassedAsFixed(passedAsFixed: $count) {
      id
      passedAsFixed
    }
  }
`;

export const setInspectionLogFlawlessCountMutation = gql`
  mutation SetInspectionLogFlawlessCount($count: Int!) {
    inspectionLog: setInspectionLogPassedAsFlawless(passedAsFlawless: $count) {
      id
      passedAsFlawless
    }
  }
`;

export const setInspectionLogItemOKIDMutation = gql`
  mutation SetInspectionLogItemOKID($OKID: String!) {
    inspectionLog: setInspectionItemOKID(itemOKID: $OKID) {
      id
      item {
        ...${ItemModel.fragmentName}
      }
    }
  }
  ${ItemModel.fragment}
`;

export const setInspectionLogLotSizeMutation = gql`
  mutation SetInspectionLogLotSize($lotSize: Int!) {
    inspectionLog: setInspectionLogLotSize(lotSize: $lotSize) {
      id
      lotSize
    }
  }
`;

export const setInspectionLogRejectedCountMutation = gql`
  mutation SetInspectionLogRejectedCount($count: Int!) {
    inspectionLog: setInspectionLogRejected(rejected: $count) {
      id
      rejected
    }
  }
`;

export const setInspectionLogSampleSizeMutation = gql`
  mutation SetInspectionLogSampleSize($sampleSize: Int!) {
    inspectionLog: setInspectionLogInspectedSize(inspectedSize: $sampleSize) {
      id
      inspectedSize
    }
  }
`;

export const SetInspectionLogStageAsAssigneeMutation = gql`
  mutation SetInspectionLogStageAsAssignee($inspectionLogStage: EInspectionLogStage!) {
    inspectionLog: setInspectionLogStageAsAssignee(inspectionLogStage: $inspectionLogStage) {
      id
      inspectionLogStage
    }
  }
`;

export const SetInspectionLogStageAsManagerMutation = gql`
  mutation SetInspectionLogStageAsManager($inspectionLogId: String!, $inspectionLogStage: EInspectionLogStage!) {
    inspectionLog: setInspectionLogStageAsManager(
      inspectionLogId: $inspectionLogId
      inspectionLogStage: $inspectionLogStage
    ) {
      id
      inspectionLogStage
    }
  }
`;

export const setInspectionLogStartDateMutation = gql`
  mutation SetInspectionLogStartDate($offsetDateTime: DateTime!) {
    offsetDateTime: setInspectionLogStartDate(offsetDateTime: $offsetDateTime) {
      id
      startDate
    }
  }
`;

export const setInspectionLogStartDateOnSubmittedLogMutation = gql`
  mutation SetInspectionLogStartDateOnSubmittedLog($inspectionLogId: String!, $offsetDateTime: DateTime!) {
    offsetDateTime: setInspectionLogStartDateOnSubmittedLog(
      inspectionLogId: $inspectionLogId
      offsetDateTime: $offsetDateTime
    ) {
      id
      startDate
    }
  }
`;

export const setInspectionLogEndDateMutation = gql`
  mutation SetInspectionLogEndDate($offsetDateTime: DateTime!) {
    offsetDateTime: setInspectionLogEndDate(offsetDateTime: $offsetDateTime) {
      id
      endDate
    }
  }
`;
export const setInspectionLogEndDateOnSubmittedLogMutation = gql`
  mutation setInspectionLogEndDateOnSubmittedLog($inspectionLogId: String!, $offsetDateTime: DateTime!) {
    offsetDateTime: setInspectionLogEndDateOnSubmittedLog(
      inspectionLogId: $inspectionLogId
      offsetDateTime: $offsetDateTime
    ) {
      id
      endDate
    }
  }
`;

export const removeInspectionLogStartDateMutation = gql`
  mutation RemoveInspectionLogStartDate {
    removeInspectionLog: removeInspectionLogStartDate {
      id
      startDate
    }
  }
`;
export const removeInspectionLogStartDateOnSubmittedLogMutation = gql`
  mutation RemoveInspectionLogStartDateOnSubmittedLog($inspectionLogId: String!) {
    removeInspectionLog: removeInspectionLogStartDateOnSubmittedLog(inspectionLogId: $inspectionLogId) {
      id
      startDate
    }
  }
`;

export const removeInspectionLogEndDateMutation = gql`
  mutation RemoveInspectionLogEndDate {
    removeInspectionLog: removeInspectionLogEndDate {
      id
      endDate
    }
  }
`;
export const removeInspectionLogEndDateOnSubmittedLogMutation = gql`
  mutation removeInspectionLogEndDateOnSubmittedLog($inspectionLogId: String!) {
    removeInspectionLog: removeInspectionLogEndDateOnSubmittedLog(inspectionLogId: $inspectionLogId) {
      id
      endDate
    }
  }
`;

export const setInspectionLogToleratedCountMutation = gql`
  mutation SetInspectionLogToleratedCount($count: Int!) {
    inspectionLog: setInspectionLogPassedAsTolerated(passedAsTolerated: $count) {
      id
      passedAsTolerated
    }
  }
`;

export const startInspectionLogBySourceMutation = gql`
  mutation StartInspectionLogBySource(
    $inspectionAssetId: String!
    $orderId: String
    $sourceId: String!
    $sourceType: EDataType!
  ) {
    inspectionLog: startInspectionLogBySource(
      inspectionAssetId: $inspectionAssetId
      orderId: $orderId
      sourceId: $sourceId
      sourceType: $sourceType
    ) {
      id
      REFID
      createdDate
    }
  }
`;

export const submitInspectionLogMutation = gql`
  mutation SubmitInspectionLog {
    inspectionLog: submitInspectionLog {
      id
      REFID
    }
  }
`;

export const searchForOrderForInspectionLogMutation = gql`
  query SearchForOrderForInspectionLog($appLanguageCode: ELanguageCode, $searchString: String!) {
    searchResults: searchForOrderForInspectionLog(appLanguageCode: $appLanguageCode, searchString: $searchString) {
      orderData {
        REFID
        id
        orderIdentifierList {
          id
          orderIdentifier
        }
      }
    }
  }
`;

export const searchForOrderForInspectionLogForSubmittedLogMutation = gql`
  query SearchForOrderForInspectionLogForSubmittedLog(
    $appLanguageCode: ELanguageCode
    $inspectionLogId: String!
    $searchString: String!
  ) {
    searchResults: searchForOrderForInspectionLogForSubmittedLog(
      appLanguageCode: $appLanguageCode
      inspectionLogId: $inspectionLogId
      searchString: $searchString
    ) {
      orderData {
        REFID
        id
        orderIdentifierList {
          id
          orderIdentifier
        }
      }
    }
  }
`;

export const setInspectionLogOrderMutation = gql`
  mutation SetInspectionLogOrder($orderId: String!) {
    setLogOrder: setInspectionLogOrder(orderId: $orderId) {
      id
      linkedOrder {
        id
        REFID
        orderIdentifierList {
          id
          orderIdentifier
        }
      }
    }
  }
`;

export const setInspectionLogOrderOnSubmittedLogMutation = gql`
  mutation SetInspectionLogOrderOnSubmittedLog($inspectionLogId: String!, $orderId: String!) {
    setLogOrder: setInspectionLogOrderOnSubmittedLog(inspectionLogId: $inspectionLogId, orderId: $orderId) {
      id
      linkedOrder {
        id
        REFID
        orderIdentifierList {
          id
          orderIdentifier
        }
      }
    }
  }
`;

export const removeInspectionLogOrderMutation = gql`
  mutation RemoveInspectionLogOrder($orderId: String!) {
    removeLogOrder: removeInspectionLogOrder(orderId: $orderId) {
      id
      linkedOrder {
        id
        REFID
      }
    }
  }
`;

export const removeInspectionLogOrderOnSubmittedLogMutation = gql`
  mutation RemoveInspectionLogOrderOnSubmittedLog($inspectionLogId: String!, $orderId: String!) {
    removeLogOrder: removeInspectionLogOrderOnSubmittedLog(inspectionLogId: $inspectionLogId, orderId: $orderId) {
      id
      linkedOrder {
        id
        REFID
      }
    }
  }
`;

/* Requests */

export async function addInspectionLogDocumentAssetOnExistingInspectionLog(inspectionLogId, file, documentType) {
  const metadata = {
    filesize: file.size,
  };

  const parameters = {
    documentFile: file,
    documentType,
    inspectionLogId,
    metadataString: JSON.stringify(metadata),
  };

  return request(`${API_URL}/utility/document/addInspectionLogDocumentAssetOnExistingInspectionLog`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function addInspectionLogDocumentAssetOnNewInspectionLog(
  organisationId,
  documentType,
  file,
  inspectionAssetId,
  orderId,
  sourceId,
  sourceType
) {
  const metadata = {
    filesize: file.size,
  };

  const parameters = {
    documentFile: file,
    documentType,
    inspectionAssetId,
    orderId,
    sourceId,
    sourceType,
    metadataString: JSON.stringify(metadata),
  };

  return request(`${API_URL}/utility/document/addInspectionLogDocumentAssetOnNewInspectionLog`, {
    contentType: 'multipart/form-data',
    headers: {
      'Authorization-Organization-Id': organisationId,
    },
    method: 'POST',
    parameters,
  });
}

/**
 * Create a new document asset and link it to an inspection log.
 *
 * @param {string} inspectionLogId
 * @param {File} file
 * @param {string} documentType
 */
export async function createInspectionLogDocumentAssetRequest(inspectionLogId, file, documentType) {
  const metadata = {
    filesize: file.size,
  };
  const parameters = {
    documentFile: file,
    documentType,
    metadataString: JSON.stringify(metadata),
  };

  return request(`${API_URL}/utility/document/createInspectionLogDocumentAssetWithMetadata`, {
    contentType: 'multipart/form-data',
    headers: {
      'Authorization-Inspection-Log-Id': inspectionLogId,
    },
    method: 'POST',
    parameters,
  });
}

export async function createInspectionLogDocumentAssetWithImageOnExistingInspectionLog(
  documentType,
  file,
  inspectionLogId
) {
  const metadata = {
    filesize: file.size,
  };

  const parameters = {
    imageFile: file,
    documentType,
    inspectionLogId,
    metadataString: JSON.stringify(metadata),
  };

  return request(`${API_URL}/utility/document/createInspectionLogDocumentAssetWithImageOnExistingInspectionLog`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createInspectionLogDocumentAssetWithImageOnNewInspectionLog(
  organisationId,
  documentType,
  file,
  inspectionAssetId,
  orderId,
  sourceId,
  sourceType
) {
  const parameters = {
    imageFile: file,
    documentType,
    inspectionAssetId,
    orderId,
    sourceId,
    sourceType,
  };

  return request(`${API_URL}/utility/document/createInspectionLogDocumentAssetWithImageOnNewInspectionLog`, {
    contentType: 'multipart/form-data',
    headers: {
      'Authorization-Organization-Id': organisationId,
    },

    method: 'POST',
    parameters,
  });
}

export async function createInspectionLogFindingAudioMediaAssetRequest(audioFile) {
  const { duration } = await getAudioMetadataFromFile(audioFile);
  const metadata = {
    filesize: audioFile.size,
    duration,
    mimeType: audioFile.type,
  };
  const parameters = {
    audio: audioFile,
    metadataString: JSON.stringify(metadata),
  };

  return request(`${API_URL}/utility/inspection-log/finding/media/createInspectionLogFindingAudioWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createInspectionLogFindingImageMediaAssetRequest(imageFile, originalImageFile) {
  const { height, width } = await getImageDimensionsFromFile(imageFile);
  const metadata = {
    filesize: imageFile.size,
    height,
    mimeType: imageFile.type,
    width,
  };
  const parameters = {
    image: imageFile,
    baseImage: originalImageFile,
    metadataString: JSON.stringify(metadata),
  };

  return request(`${API_URL}/utility/inspection-log/finding/media/createInspectionLogFindingImageWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createInspectionLogFindingVideoMediaAssetRequest(videoFile) {
  const { duration, height, width } = await getVideoMetadataFromFile(videoFile);
  const metadata = {
    duration,
    filesize: videoFile.size,
    height,
    mimeType: videoFile.type,
    width,
  };
  const parameters = {
    metadataString: JSON.stringify(metadata),
    video: videoFile,
  };

  return request(`${API_URL}/utility/inspection-log/finding/media/createInspectionLogFindingVideoWithMetadata`, {
    contentType: 'multipart/form-data',
    method: 'POST',
    parameters,
  });
}

export async function createAndSetTestAssetForInspectionLogFindingAudioMediaAssetRequest(
  audioFile,
  inspectionLogTestAssetId
) {
  const { duration } = await getAudioMetadataFromFile(audioFile);
  const metadata = {
    duration,
    filesize: audioFile.size,
    mimeType: audioFile.type,
  };
  const parameters = {
    audio: audioFile,
    inspectionLogTestAssetId,
    metadataString: JSON.stringify(metadata),
  };

  return request(
    `${API_URL}/utility/inspection-log/finding/media/createInspectionLogTestAssetFindingAudioWithMetadata`,
    {
      contentType: 'multipart/form-data',
      method: 'POST',
      parameters,
    }
  );
}

export async function createAndSetTestAssetForInspectionLogFindingImageMediaAssetRequest(
  imageFile,
  originalImageFile,
  inspectionLogTestAssetId
) {
  const { height, width } = await getImageDimensionsFromFile(imageFile);
  const metadata = {
    filesize: imageFile.size,
    height,
    mimeType: imageFile.type,
    width,
  };
  const parameters = {
    baseImage: originalImageFile,
    image: imageFile,
    inspectionLogTestAssetId,
    metadataString: JSON.stringify(metadata),
  };

  return request(
    `${API_URL}/utility/inspection-log/finding/media/createInspectionLogTestAssetFindingImageWithMetadata`,
    {
      contentType: 'multipart/form-data',
      method: 'POST',
      parameters,
    }
  );
}

export async function createAndSetTestAssetForInspectionLogFindingVideoMediaAssetRequest(
  videoFile,
  inspectionLogTestAssetId
) {
  const { duration, height, width } = await getVideoMetadataFromFile(videoFile);
  const metadata = {
    duration,
    filesize: videoFile.size,
    height,
    mimeType: videoFile.type,
    width,
  };
  const parameters = {
    inspectionLogTestAssetId,
    metadataString: JSON.stringify(metadata),
    video: videoFile,
  };

  return request(
    `${API_URL}/utility/inspection-log/finding/media/createInspectionLogTestAssetFindingVideoWithMetadata`,
    {
      contentType: 'multipart/form-data',
      method: 'POST',
      parameters,
    }
  );
}
