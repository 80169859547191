import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, {
  Children,
  cloneElement,
  createContext,
  createRef,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import { themeDark, themeLight } from 'OK/styles/theme';
import { DEBOUNCE_TIMING_MS_LONG, DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';

/**
 * @typedef {object} CarouselProps
 * @prop {('center'|'left'|'right')} [alignSlideIndicators='center'] The alignment for the slide indicators.
 * @prop {boolean} [autoPlay=false] Automatically progress through the slides. Set `autoPlayIntervalMs` to
 * configure the interval.
 * @prop {number} [autoPlayIntervalMs=5000] The interval (in milliseconds) at which the slides will
 * automatically progress when `autoPlay` is `true`. Defaults to 5000.
 * @prop {string} [carouselClassName] Class name for the inner carousel element.
 * @prop {*} children
 * @prop {string} [className] Class name for the container.
 * @prop {boolean} [extendSides=false] Extend the width of the Carousel out 30px on either side. Set to `true`
 * when rendering the Carousel inside a component with content padding.
 * @prop {boolean} [fadeOutSides=false] Fade slides out at the sides of the carousel.
 * @prop {Node} [footer] Elements to render below the carousel but above the slide indicators.
 * @prop {() => number[]} [onActiveSlideChanged] Event handler for when the active slide(s) changes. Returns an
 * array of active slide indexes.
 * @prop {function} [onClickAddSlide] Event handler for when the user clicks to add a slide.
 * @prop {boolean} [showAddSlideIndicator=false] Show button to add new slide with slide indicators.
 * @prop {boolean} [showSlideIndicators=true] Show active/inactive slide indicators below the Carousel.
 * @prop {boolean} [showSlideSeparators=false] Show separator lines between slides. If set to true, any value
 * passed to `slideSideMargin` will be divided by 2 and added on either side of the separators.
 * @prop {string} [slideIndicatorsClassname] Class name for the slide indicators container.
 * @prop {number} [sidePadding=0] The horizontal padding to account for with the carousel. Slides will be
 * inset by this amount from either side.
 * @prop {number} [slideSideMargin=0] The horizontal margin between each slide.
 * @prop {number} slideWidth The width of slides. Currently only supports slides of uniform width.
 * @prop {boolean} [snapSlides=true] Snap slides to center of Carousel.
 */

/**
 * A component that shows multiple components in a horizontal layout and allows for swiping between them.
 *
 * @type {React.FC<CarouselProps>}
 */
const Carousel = forwardRef((props, forwardedRef) => {
  /* Variables */

  const {
    alignSlideIndicators = 'center',
    autoPlay = false,
    autoPlayIntervalMs = 5000,
    carouselClassName,
    children,
    className,
    debugMode = false,
    extendSides = false,
    fadeOutSides = false,
    footer,
    onActiveSlideChanged,
    onClickAddSlide,
    showAddSlideIndicator = false,
    showCustomScrollbar = false,
    showSlideIndicators: showSlideIndicatorsProp = true,
    showSlideSeparators = false,
    slideIndicatorsClassname,
    sidePadding = 0,
    slideSideMargin: _slideSideMargin = 0,
    slideWidth,
    snapSlides: snapSlidesProp = true,
    ...otherProps
  } = props;
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const carouselRef = useRef(null);
  const containerRef = useRef();
  const showSlideIndicators = !showCustomScrollbar && showSlideIndicatorsProp;
  const slideSideMargin = showSlideSeparators ? parseInt(_slideSideMargin / 2, 10) : _slideSideMargin;
  const snapSlidesResetTimeout = useRef(null);

  /* State */

  const autoPlayIntervalRef = useRef();
  const [isHovering, setIsHovering] = useState(false);
  const [slideActiveStates, setSlideActiveStates] = useState([]);
  const [slides, setSlides] = useState([]);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [snapSlides, setSnapSlides] = useState(snapSlidesProp);
  const numberOfSlides = Children.count(children);

  /* Helpers */

  /**
   * It's easier to debug when you can switch logging on for an individual carousel instead of all carousels, so this wraps
   * `okdebug` with a check for the flag.
   */
  // eslint-disable-next-line no-unused-vars
  const debug = useCallback(
    (...message) => {
      if (debugMode) {
        okdebug(...message);
      }
    },
    [debugMode]
  );

  const slideIndexIsActive = useCallback(
    (index) => {
      const slide = slides[index];

      if (!slide?.ref?.current) {
        return false;
      }

      const slideRect = slide.ref.current.getBoundingClientRect();
      const carouselRect = carouselRef.current.getBoundingClientRect();
      // A slide is considered active if all the follow are true:
      // a) The slide's left-most point is visible within the carousel (accounting for the carousel's position)
      // b) The slide's right-most point is visible within the carousel (accounting for carousel's position and a
      //    possible slide separator border)
      const slideIsActive =
        slideRect.left - carouselRect.left >= 0 && slideRect.right - carouselRect.left - 1 <= carouselWidth;

      return slideIsActive;
    },
    [carouselWidth, slides]
  );

  /* Methods */

  const checkSlideActiveStates = useCallback(() => {
    let atLeastOneActiveSlide = false;
    const activeStates = slides.map((_, index) => {
      const slideIsActive = slideIndexIsActive(index);
      if (slideIsActive) {
        atLeastOneActiveSlide = true;
      }
      return slideIsActive;
    });

    if (!atLeastOneActiveSlide) {
      // Skip this check
      // This could happen when active slides are checked when not finished scrolling.
      return;
    }

    if (slideActiveStates.join(',') !== activeStates.join(',')) {
      setSlideActiveStates(activeStates);

      if (onActiveSlideChanged) {
        const activeIndexes = [];
        activeStates.forEach((active, index) => {
          if (active) {
            activeIndexes.push(index);
          }
        });
        onActiveSlideChanged(activeIndexes);
      }
    }
  }, [onActiveSlideChanged, slides, slideActiveStates, slideIndexIsActive]);

  const clearAutoPlayInterval = useCallback(() => {
    if (autoPlayIntervalRef.current) {
      clearInterval(autoPlayIntervalRef.current);
      autoPlayIntervalRef.current = null;
    }
  }, []);

  const scrollToSlide = useCallback(
    (slideIndex, immediate = false) => {
      if (!slideIndexIsActive(slideIndex)) {
        // If scrolling again with a pending snapSlides reset, cancel it.
        clearTimeout(snapSlidesResetTimeout.current);

        // Disable scroll snapping during animation so scrolling is smooth
        setSnapSlides(false);

        // Calculate new scrollLeft position
        let newScrollLeft = (slideWidth + slideSideMargin * 2) * slideIndex;

        // Adjust for mobile
        if (useMobileLayout && snapSlidesProp) {
          // Adjust to account for slide center point instead of slide left edge so snap position is equal
          newScrollLeft -= (carouselWidth - slideWidth) / 2 - sidePadding;
        }

        // Animate to new position
        carouselRef.current.scrollTo({ left: newScrollLeft, behavior: immediate ? 'instant' : 'smooth' });
      }
    },
    [slideIndexIsActive, slideWidth, slideSideMargin, useMobileLayout, snapSlidesProp, carouselWidth, sidePadding]
  );

  const setupAutoPlayInterval = useCallback(() => {
    clearAutoPlayInterval(); // Ensure we don't have multiple intervals running at once.
    autoPlayIntervalRef.current = setInterval(() => {
      const currentSlideIndex = slideActiveStates.findIndex((state) => state === true);
      if (currentSlideIndex > -1) {
        if (currentSlideIndex === numberOfSlides - 1) {
          scrollToSlide(0);
        } else {
          scrollToSlide(currentSlideIndex + 1);
        }
      }
    }, autoPlayIntervalMs);
  }, [autoPlayIntervalMs, clearAutoPlayInterval, numberOfSlides, scrollToSlide, slideActiveStates]);

  /* Events */

  const onBlur = () => {
    if (autoPlay) {
      setupAutoPlayInterval();
    }
    setIsHovering(false);
  };

  const onHover = () => {
    if (autoPlayIntervalRef.current) {
      clearAutoPlayInterval();
    }
    setIsHovering(true);
  };

  const onCarouselScrollDebounced = useMemo(
    () =>
      debounce(() => {
        checkSlideActiveStates();
      }, DEBOUNCE_TIMING_MS_SHORT),
    [checkSlideActiveStates]
  );
  const onCarouselScroll = (e) => {
    onCarouselScrollDebounced(e);
  };

  /* Effects */

  // Keep carouselWidth up-to-date
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const newWidth = carouselRef.current?.clientWidth;
    if (newWidth && newWidth !== carouselWidth) {
      setCarouselWidth(newWidth);
    }
  });

  useImperativeHandle(
    forwardedRef,
    () => ({
      scrollToSlideAtIndex: (index, immediate) => scrollToSlide(index, immediate),
      slideIndexIsActive,
    }),
    [scrollToSlide, slideIndexIsActive]
  );

  // Keep slides up-to-date
  useEffect(() => {
    setSlides(
      Children.map(children, (child, index) => {
        const ref = createRef();
        if (!child) {
          return null;
        }

        const clone = cloneElement(child, {
          isFirstSlide: index === 0,
          isLastSlide: index === Children.count(children) - 1,
          ref,
        });
        return clone;
      })
    );
  }, [children]);

  // Make sure active slide indicators are accurate
  useEffect(() => {
    checkSlideActiveStates();
  }, [checkSlideActiveStates]);

  // Setup autoplay interval and interaction listeners
  useEffect(() => {
    if (autoPlay) {
      if (!isHovering) {
        setupAutoPlayInterval();
      }
    }

    return clearAutoPlayInterval;
  }, [autoPlay, clearAutoPlayInterval, isHovering, setupAutoPlayInterval]);

  // Ensure snapSlides is kept in-sync with prop value.
  // This is necessary because snapSlides is disabled for smooth scrolling.
  useEffect(() => {
    let resetSnapSlidesTimeout;
    if (snapSlides !== snapSlidesProp) {
      resetSnapSlidesTimeout = setTimeout(() => {
        // Restore snapSlides to prop value after a short delay.
        // On Safari the carousel's scrollLeft is lost when this happens, so manually restore that value after
        // re-enabling snap slides.
        const scrollLeft = carouselRef.current.scrollLeft;
        setSnapSlides(snapSlidesProp);
        carouselRef.current.scrollLeft = scrollLeft;
      }, DEBOUNCE_TIMING_MS_LONG);
    }

    return function cleanup() {
      if (resetSnapSlidesTimeout) {
        clearTimeout(resetSnapSlidesTimeout);
      }
    };
  }, [debug, snapSlides, snapSlidesProp]);

  /* Render */

  let carouselClassNames = styles.carousel;
  if (snapSlides) {
    carouselClassNames = `${carouselClassNames} ${styles.snap}`;
  } else {
    carouselClassNames = `${carouselClassNames} ${styles.noSnap}`;
  }
  if (extendSides) {
    carouselClassNames = `${carouselClassNames} ${styles.extendSides}`;
  }
  if (fadeOutSides) {
    carouselClassNames = `${carouselClassNames} ${styles.fadeSides}`;
  }
  if (showCustomScrollbar) {
    carouselClassNames = `${carouselClassNames} ${styles.withCustomScrollbar}`;
  } else {
    carouselClassNames = `${carouselClassNames} ${styles.hideScrollbar}`;
  }
  if (carouselClassName) {
    carouselClassNames = `${carouselClassNames} ${carouselClassName}`;
  }

  let containerClassNames = styles.container;
  if (className) {
    containerClassNames = `${containerClassNames} ${className}`;
  }

  return (
    <div
      className={containerClassNames}
      onMouseEnter={onHover}
      onMouseLeave={onBlur}
      ref={containerRef}
      {...otherProps}
    >
      <CarouselContext.Provider
        value={{
          alignSlideIndicators,
          debugMode,
          extendSides,
          numberOfSlides,
          onClickAddSlide,
          showAddSlideIndicator,
          showSlideSeparators,
          sidePadding,
          slideSideMargin,
        }}
      >
        <div
          className={carouselClassNames}
          onScroll={onCarouselScroll}
          ref={carouselRef}
          style={{ scrollPadding: `0 ${sidePadding}px` }}
        >
          {slides}
        </div>
        {footer}
        {showSlideIndicators && (
          <SlideIndicators
            activeStates={slideActiveStates}
            className={slideIndicatorsClassname}
            onDotClicked={scrollToSlide}
          />
        )}
      </CarouselContext.Provider>
    </div>
  );
});

Carousel.propTypes = {
  alignSlideIndicators: PropTypes.oneOf(['center', 'left', 'right']),
  autoPlay: PropTypes.bool,
  autoPlayIntervalMs: PropTypes.number,
  carouselClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  debugMode: PropTypes.bool,
  extendSides: PropTypes.bool,
  fadeOutSides: PropTypes.bool,
  footer: PropTypes.node,
  onActiveSlideChanged: PropTypes.func,
  onClickAddSlide: PropTypes.func,
  showAddSlideIndicator: PropTypes.bool,
  showCustomScrollbar: PropTypes.bool,
  showSlideIndicators: PropTypes.bool,
  showSlideSeparators: PropTypes.bool,
  slideIndicatorsClassname: PropTypes.string,
  sidePadding: PropTypes.number,
  slideSideMargin: PropTypes.number,
  slideWidth: PropTypes.number.isRequired,
  snapSlides: PropTypes.bool,
};

export { Carousel };

/**
 * @typedef {object} SlideProps
 * @prop {*} children
 * @prop {string} [className] The slide class.
 * @prop {boolean} [fullWidth] Render as a full-width slide.
 * @prop {boolean} [isFirstSlide] Indicate this slide is the first one.
 * @prop {boolean} [isLastSlide] Indicate this slide is the last one.
 */

/**
 * A slide for the Carousel component.
 *
 * @type {React.FC<SlideProps>}
 */
const Slide = forwardRef((props, forwardedRef) => {
  const { children, className, fullWidth = false, isFirstSlide = false, isLastSlide = false, ...otherProps } = props;
  const innerRef = useRef();
  const ref = forwardedRef || innerRef;
  const { showSlideSeparators, sidePadding, slideSideMargin } = useContext(CarouselContext);
  const theme = useContext(ThemeContext);
  const themeStyles = theme.name === 'dark' ? themeDark : themeLight;

  // Classes
  let classNames = styles.slide;
  if (showSlideSeparators) {
    classNames = `${classNames} ${styles.withSlideSeparator}`;
  }
  if (fullWidth) {
    classNames = `${classNames} ${styles.fullWidth}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  // Inline styles
  const style = {};
  if (showSlideSeparators) {
    // Add slide margin as padding
    style.paddingLeft = slideSideMargin;
    style.paddingRight = slideSideMargin;

    if (!isFirstSlide) {
      // Add separator line to slide
      style.borderLeft = `1px solid ${themeStyles.colors.midtonesStatic}`;
    }

    if (isFirstSlide && isLastSlide) {
      // Ensure slide doesn't fall in carousel padding
      style.paddingLeft = sidePadding;
      style.paddingRight = sidePadding;
    } else if (isFirstSlide) {
      // Ensure first slide doesn't fall in carousel padding
      style.paddingLeft = sidePadding;
    } else if (isLastSlide) {
      // Ensure last slide doesn't fall in carousel padding
      style.paddingRight = sidePadding;
    }
  } else {
    // Add slide margin
    style.marginLeft = slideSideMargin;
    style.marginRight = slideSideMargin;

    if (isFirstSlide && isLastSlide) {
      // Ensure slide doesn't fall in carousel padding
      style.marginLeft = sidePadding;
      style.marginRight = sidePadding;
    } else if (isFirstSlide) {
      // Ensure first slide doesn't fall in carousel padding
      style.marginLeft = sidePadding;
    } else if (isLastSlide) {
      // Ensure last slide doesn't fall in carousel padding
      style.marginRight = sidePadding;
    }
  }

  return (
    <>
      <div className={classNames} ref={ref} style={style} {...otherProps}>
        {children}
      </div>
    </>
  );
});

Slide.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  isFirstSlide: PropTypes.bool,
  isLastSlide: PropTypes.bool,
};

export { Slide };

/**
 * Dots to indicate which slide is active and allow for navigation between slides by clicking.
 *
 * @param {object} props
 * @param {boolean[]} props.activeStates Array of boolean values indicating whether the slide at that index is active.
 * @param {string} [props.className] The class for the slide indicators container.
 * @param {function} [props.onDotClicked] A function to invoke when an indicator is clicked.
 */
export function SlideIndicators(props) {
  // Variables
  const { activeStates, className, onDotClicked } = props;
  const carouselContext = useContext(CarouselContext);
  const { alignSlideIndicators, numberOfSlides, onClickAddSlide, showAddSlideIndicator } = carouselContext;
  const _numberOfSlides = showAddSlideIndicator ? numberOfSlides - 1 : numberOfSlides;

  // Functions
  const onClick = (e) => {
    if (onDotClicked) {
      onDotClicked(parseInt(e.target.id));
    }
  };

  // Render

  if (!_numberOfSlides) {
    return null;
  }

  // Classes
  let classNames = styles.dots;
  switch (alignSlideIndicators) {
    case 'left':
    case 'right':
      classNames = `${classNames} ${styles[alignSlideIndicators]}`;
      break;
    default:
      break;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  // Number of dots
  const dots = [];
  for (let x = 0; x < _numberOfSlides; x++) {
    let dotClasses = styles.dotIndicator;
    if (activeStates[x]) {
      dotClasses = `${dotClasses} ${styles.active}`;
    }
    dots.push(<div className={dotClasses} id={x} key={x} onClick={onClick} />);
  }

  return (
    <>
      <div className={classNames}>
        {dots}
        {showAddSlideIndicator && <button className={styles.addSlideIndicator} onClick={onClickAddSlide} />}
      </div>
    </>
  );
}

SlideIndicators.propTypes = {
  activeStates: PropTypes.arrayOf(PropTypes.bool).isRequired,
  className: PropTypes.string,
  onDotClicked: PropTypes.func,
};

const CarouselContext = createContext({
  alignSlideIndicators: 'center',
  extendSides: false,
  numberOfSlides: 0,
  onClickAddSlide: () => {},
  showAddSlideIndicator: false,
  showSlideSeparators: false,
  slideSideMargin: 0,
});
