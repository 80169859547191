import { gql } from '@apollo/client';

export default class VersionedNoteAssetModel {
  static GRAPHQL_TYPE = 'VersionedNoteAsset';

  static fragmentName = 'VersionedNoteAsset_Fragment';
  static fragment = gql`
    fragment ${VersionedNoteAssetModel.fragmentName} on ${VersionedNoteAssetModel.GRAPHQL_TYPE} {
      REFID
      createdBy
      createdDate
      id
      lastModifiedBy
      lastModifiedDate
      noteAssetId
      organisationId
      textContentMap
      version
      versionDate
    }
  `;
}
