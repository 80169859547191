import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import styles from './styles.module.scss';

import Image from 'OK/components/image';
import UserBadgeModel from 'OK/models/userBadge';
import RecognitionPopup from 'OK/modules/popups/recognition';

export default function UserRecognition(props) {
  const { className, recognition, openPopupOnClick = false, ...otherProps } = props;
  const recognitionName = recognition.userBadge;
  const imageUrl = UserBadgeModel.badgeImageUrl(recognitionName);

  /* State */

  const [showPopup, setShowPopup] = useState(false);

  /* Methods */

  const dismissPopup = useCallback(() => {
    setShowPopup(false);
  }, []);

  /* Event handlers */

  const onClick = useCallback(() => {
    if (openPopupOnClick) {
      setShowPopup(true);
    }
  }, [openPopupOnClick]);

  /* Render */

  let classNames = styles.image;
  if (openPopupOnClick) {
    classNames += ` ${styles.clickable}`;
  }
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <>
      <Image
        addSrcSet
        alt=''
        className={classNames}
        height='54'
        onClick={onClick}
        src={imageUrl}
        width='54'
        {...otherProps}
      />
      {showPopup && <RecognitionPopup dismiss={dismissPopup} recognition={recognition} />}
    </>
  );
}

UserRecognition.propTypes = {
  className: PropTypes.string,
  openPopupOnClick: PropTypes.bool,
  recognition: PropTypes.shape({
    issueDate: PropTypes.number,
    userBadge: PropTypes.string.isRequired,
  }).isRequired,
};
