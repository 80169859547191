import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import CardLayout from 'OK/components/layouts/card';
import Link from 'OK/components/link';
import Text from 'OK/components/text';
import useI18n from 'OK/util/hooks/useI18n';

export default function SiteContactViewCard(props) {
  const { contact, ...otherProps } = props;
  const { t } = useI18n();

  const name = contact.user?.name ?? contact.name;
  const role = t(contact.role);

  const email = contact.user?.email ?? contact.email;
  const phone = contact.user?.mobile ? `${contact.user.mobileCountryCode}${contact.user.mobile}` : contact.phone;

  return (
    <CardLayout {...otherProps}>
      <h4>{t('CONTACTS_SECTION_CONTACT_TITLE')}</h4>
      <Text bold className={styles.label}>
        {t('CONTACTS_SECTION_CONTACT_NAME')}
      </Text>
      <Text className={styles.value}>{name}</Text>
      <Text bold className={styles.label}>
        {t('CONTACTS_SECTION_CONTACT_ROLE')}
      </Text>
      <Text className={styles.value}>{role}</Text>
      {email && (
        <>
          <Text bold className={styles.label}>
            {t('CONTACTS_SECTION_CONTACT_EMAIL')}
          </Text>
          <Link className={styles.value} href={`mailto:${email}`}>
            {email}
          </Link>
        </>
      )}
      {phone && (
        <>
          <Text bold className={styles.label}>
            {t('CONTACTS_SECTION_CONTACT_PHONE')}
          </Text>
          <Link className={styles.value} href={`tel:${phone}`}>
            {phone}
          </Link>
        </>
      )}
    </CardLayout>
  );
}

SiteContactViewCard.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone: PropTypes.string,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      mobile: PropTypes.string.isRequired,
      mobileCountryCode: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
