import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EditSitePageContactSection from '../pages/archive/site/[OKID]/edit/contactsSection';
import EditSitePageDocumentationSection from '../pages/archive/site/[OKID]/edit/documentationSection';
import EditSitePageInspectionsSection from '../pages/archive/site/[OKID]/edit/inspectionsSection';
import EditSitePageNotesSection from '../pages/archive/site/[OKID]/edit/notesSection';
import EditSitePagePartsSection from '../pages/archive/site/[OKID]/edit/partsSection';
import EditSitePageWorkSection from '../pages/archive/site/[OKID]/edit/workSection';
import ShareAssetPopup from '../popups/share';
import ShareOKIDPopup from '../popups/shareOKID';
import SiteInfoEditPopup from '../popups/siteInfoEdit';

import styles from './styles.module.scss';

import Accordion from 'OK/components/accordion';
import Button from 'OK/components/button';
import CommentsController from 'OK/components/comments/controller';
import FloatingButtonsContainer from 'OK/components/floatingButtons';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import CardLayout from 'OK/components/layouts/card';
import ContentLayout from 'OK/components/layouts/content';
import LinkToOrganisation from 'OK/components/link/linkToOrganisation';
import MediaGallery from 'OK/components/mediaGallery';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import Notice from 'OK/components/notice';
import PageTitle from 'OK/components/pageTitle';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import appConfig from 'OK/config/app';
import InspectionLogModel from 'OK/models/inspectionLog';
import MediaAssetModel from 'OK/models/mediaAsset';
import BaseMediaAssetModel, {
  AudioMediaAssetModel,
  ImageMediaAssetModel,
  VideoMediaAssetModel,
} from 'OK/models/mediaAsset/base';
import SiteModel from 'OK/models/site';
import SiteMediaAssetModel from 'OK/models/siteMediaAsset';
import AddWorkPopup from 'OK/modules/popups/addWork';
import ScanResultsPopup from 'OK/modules/popups/scanResults';
import {
  LatestInspectionLogsForSiteQuery,
  setInspectionLogEndDateMutation,
  SetInspectionLogStageAsAssigneeMutation,
  SetInspectionLogStageAsManagerMutation,
  setInspectionLogStartDateMutation,
  startInspectionLogBySourceMutation,
  submitInspectionLogMutation,
} from 'OK/networking/inspectionLogs';
import { updateImageMediaAssetRequest } from 'OK/networking/media';
import { getOrganisationByOKIDQuery } from 'OK/networking/organisations';
import { searchQuery } from 'OK/networking/search';
import {
  PublishSiteMutation,
  UnpublishSiteMutation,
  createSiteAudioMediaAssetRequest,
  createSiteImageMediaAssetRequest,
  createSiteVideoMediaAssetRequest,
  reorderSiteMediaBackwardMutation,
  reorderSiteMediaForwardMutation,
} from 'OK/networking/sites';
import { useLinkSiteMediaAPI, useUnlinkSiteMediaAPI } from 'OK/networking/sites/hooks';
import { setActiveInspectionLogId } from 'OK/state/account/actions';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import UIContext from 'OK/util/context/ui';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import { formatOkid, formatPoints } from 'OK/util/formatting';
import authorisationForResource from 'OK/util/functions/authorisationForResource';
import isAuthorised, { isAuthorisedViaShare } from 'OK/util/functions/isAuthorised';
import { OBJECT_PERMISSIONS } from 'OK/util/functions/permissions';
import simpleMIMEType from 'OK/util/functions/simpleMIMEType';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisation from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';
import usePermission from 'OK/util/hooks/usePermission';

export default function SitePage(props) {
  /* Variables */

  const { site } = props;
  const OKID = site?.OKID;
  const siteId = site?.id;
  const mediaAssets = site.siteMediaAssetList;
  const { t, tHTML, locale } = useI18n();
  const [, , currentUser] = useAuthentication(() => false);
  const authorisationLevel = useAuthorisation(site);
  const dispatch = useDispatch();
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const isShowingPWABanner = useSelector((state) => state.app.isShowingPWABanner);
  const showInPopup = useSelector((state) => state.app.showInPopup);
  const hasManagerAuthorisation = isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER);
  const organisation = currentUser?.organisationList.find((o) => o.id === activeOrganisationId);
  const theme = useContext(ThemeContext);
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const menuIsVisible = useSelector((state) => state.app.menuIsVisible);
  const isManager =
    authorisationForResource(currentUser, activeOrganisationId, organisation) == AUTHORISATION_LEVEL.MANAGER ||
    authorisationForResource(currentUser, activeOrganisationId, organisation) == AUTHORISATION_LEVEL.OWNER;
  const isEditor =
    hasManagerAuthorisation ||
    (isAuthorisedViaShare(site.assetAccessPermission, AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION) && isManager);
  const isScrollingPopup = useSelector((state) => state.app.isScrollingPopup);
  const hasPermissionToPostComments = usePermission(
    OBJECT_PERMISSIONS.POST_COMMENTS,
    currentUser,
    activeOrganisationId,
    site
  );
  const apolloClient = useApolloClient();
  const siteVisitInspectionAsset = site?.siteInspectionAssetList?.find(
    (inspectionAsset) => 'Site Visit' === inspectionAsset?.inspectionAsset?.name?.EN?.text
  );
  const siteVisitInspectionAssetId = siteVisitInspectionAsset ?  siteVisitInspectionAsset.inspectionAsset?.id : null;

  const siteVisitInspectionLog = currentUser?.inprogressInspectionLogList
    ? currentUser?.inprogressInspectionLogList.find((log) => log.inspectionAsset.id === siteVisitInspectionAssetId)
    : false;

  // State

  const [shareToastMessage, setShareToastMessage] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [renderScanResultsPopup, setShowScanResultsPopup] = useState(false);
  const [showAddWorkPopup, setShowAddWorkPopup] = useState(false);
  const [isImageEditButtonVisible, setIsImageEditButtonVisible] = useState(true);
  const [enableSiteImageEdit, setEnableSiteImageEdit] = useState(false);
  const [isEditingMedia, setIsEditingMedia] = useState(false);
  const [isCommentsSectionOpen, setIsCommentsSectionOpen] = useState(true);
  const [isContactsSectionOpen, setIsContactsSectionOpen] = useState(true);
  const [isDocumentationSectionOpen, setIsDocumentationSectionOpen] = useState(true);
  const [isNotesSectionOpen, setIsNotesSectionOpen] = useState(true);
  const [isPartsSectionOpen, setIsPartsSectionOpen] = useState(true);
  const [isUserCurrentlyVisitingSite, setIsUserCurrentlyVisitingSite] = useState(siteVisitInspectionLog ? true : false);
  const [isWorkSectionOpen, setIsWorkSectionOpen] = useState(true);
  const [showSiteInfoEditPopup, setShowSiteInfoEditPopup] = useState(false);
  const [notesAccordionEdit, setNotesAccordionEdit] = useState(false);
  const [documentationAccordionEdit, setDocumentationAccordionEdit] = useState(false);
  const [partsAccordionEdit, setPartsAccordionEdit] = useState(false);
  const [contactsAccordionEdit, setContactsAccordionEdit] = useState(false);
  const [workAccordionEdit, setWorkAccordionEdit] = useState(false);
  const [showOKIDPopup, setShowOKIDPopup] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [mediaGalleryError, setMediaGalleryError] = useState(null);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [mediaSearchInputFocused, setMediaSearchInputFocused] = useState(false);
  const [mediaSearchString, setMediaSearchString] = useState('');
  const [publishError, setPublishError] = useState(null);
  const [updatedSiteVisitInspectionLog, setUpdatedSiteVisitInspectionLog] = useState(
    siteVisitInspectionLog ? siteVisitInspectionLog : null
  );
  const [visitTimeElapsed, setVisitTimeElapsed] = useState('');
  const [visitIntervalIds, setVisitIntervalIds] = useState([]);
  // Refs

  const workSectionRef = useRef();
  const documentationSectionRef = useRef();
  const contactsSectionRef = useRef();
  const notesSectionRef = useRef();
  const partsSectionRef = useRef();
  const commentsSectionRef = useRef();
  const mediaGalleryRef = useRef();

  /* API */

  const organisationData = useQuery(getOrganisationByOKIDQuery, {
    variables: {
      OKID: site.organisation.OKID,
    },
  });

  const organisationLogo = organisationData?.data?.organisation?.logoImageMediaAsset?.logoImageURL;

  const latestSiteInspectionLogsResult = useQuery(LatestInspectionLogsForSiteQuery, {
    variables: {
      includeInternalLogs: true,
      siteId: site?.OKID === "X99KNMKLHML9" ? "65dd8ff726dab75762eab895" : site?.id,
    },
    skip: !site?.id,
  });

  const [publishSiteAPI] = useMutation(PublishSiteMutation);
  const [unpublishSiteAPI] = useMutation(UnpublishSiteMutation);

  const siteCacheId = `${SiteModel.GRAPHQL_TYPE}:${siteId}`;

  const linkMediaAPI = useLinkSiteMediaAPI();
  const [reorderSiteMediaBackwardAPI] = useMutation(reorderSiteMediaBackwardMutation, {
    onError: () => {
      setMediaGalleryError(t('ERROR_GENERIC'));
    },
    update: (cache, result) => {
      if (result.data?.siteMedia) {
        const { siteMedia } = result.data;
        cache.modify({
          id: siteCacheId,
          fields: {
            siteMediaAssetList: (currentList) => {
              const oldOrder = siteMedia.order + 1;
              const originalIndexBeforeMovedMedia = oldOrder - 2;
              const updatedList = [...currentList];
              const [beforeSiteMedia, movedSiteMedia] = updatedList.splice(originalIndexBeforeMovedMedia, 2);
              const updatedMovedSiteMedia = {
                ...movedSiteMedia,
                order: siteMedia.order,
              };
              const updatedBeforeSiteMedia = {
                ...beforeSiteMedia,
                order: oldOrder,
              };
              updatedList.splice(originalIndexBeforeMovedMedia, 0, updatedMovedSiteMedia, updatedBeforeSiteMedia);
              return updatedList;
            },
          },
        });
      } else {
        okerror('Reorder media backward API did not return site media.');
        setMediaGalleryError(t('ERROR_GENERIC'));
      }
    },
  });
  const [reorderSiteMediaForwardAPI] = useMutation(reorderSiteMediaForwardMutation, {
    onError: () => {
      setMediaGalleryError(t('ERROR_GENERIC'));
    },
    update: (cache, result) => {
      if (result.data?.siteMedia) {
        const { siteMedia } = result.data;
        cache.modify({
          id: siteCacheId,
          fields: {
            siteMediaAssetList: (currentList) => {
              const oldOrder = siteMedia.order - 1;
              const originalIndexOfMovedMedia = oldOrder - 1;
              const updatedList = [...currentList];
              const [movedSiteMedia, afterSiteMedia] = updatedList.splice(originalIndexOfMovedMedia, 2);
              const updatedMovedSiteMedia = {
                ...movedSiteMedia,
                order: siteMedia.order,
              };
              const updatedAfterSiteMedia = {
                ...afterSiteMedia,
                order: oldOrder,
              };
              updatedList.splice(originalIndexOfMovedMedia, 0, updatedAfterSiteMedia, updatedMovedSiteMedia);
              return updatedList;
            },
          },
        });
      } else {
        okerror('Reorder media forward API did not return product media.');
        setMediaGalleryError(t('ERROR_GENERIC'));
      }
    },
  });
  const [setInspectionLogEndDate] = useMutation(setInspectionLogEndDateMutation);
  const [setInspectionLogStageAsAssignee] = useMutation(SetInspectionLogStageAsAssigneeMutation);
  const [setInspectionLogStageAsManager] = useMutation(SetInspectionLogStageAsManagerMutation);
  const [setInspectionLogStartDate] = useMutation(setInspectionLogStartDateMutation);
  const [startInspectionLogSiteVisit, startInspectionLogSiteVisitResult] = useMutation(
    startInspectionLogBySourceMutation
  );
  const [submitInspectionLog] = useMutation(submitInspectionLogMutation);

  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);
  const unlinkSiteMediaAPI = useUnlinkSiteMediaAPI({
    onError: () => {
      setMediaGalleryError(t('ERROR_GENERIC'));
    },
  });

  /* Methods */

  const onClickChangeShareStatus = useCallback(
    (publishStatus) => {
      setPublishError(null);
      if (publishStatus === PUBLISH_STATUS.PUBLISHED) {
        const optimisticResponse = {
          site: {
            id: site?.id,
            publishStatus: PUBLISH_STATUS.PUBLISHED,
            __typename: SiteModel.GRAPHQL_TYPE,
          },
        };
        publishSiteAPI({
          variables: {
            siteId: site?.id,
          },
          optimisticResponse,
        }).catch(() => {
          setPublishError(t('ERROR_GENERIC'));
        });
      } else {
        const optimisticResponse = {
          site: {
            id: site?.id,
            publishStatus: PUBLISH_STATUS.UNPUBLISHED,
            __typename: SiteModel.GRAPHQL_TYPE,
          },
        };
        unpublishSiteAPI({
          variables: {
            siteId: site?.id,
          },
          optimisticResponse,
        }).catch(() => {
          setPublishError(t('ERROR_GENERIC'));
        });
      }
    },
    [publishSiteAPI, site?.id, t, unpublishSiteAPI]
  );

  const onClickSiteLeave = useCallback(() => {
    const currentDate = new Date();

    const siteVisitInspectionLogID = updatedSiteVisitInspectionLog
      ? updatedSiteVisitInspectionLog.id
      : siteVisitInspectionLog.id;

    dispatch(setActiveInspectionLogId(siteVisitInspectionLogID));

    setInspectionLogEndDate({
      variables: {
        offsetDateTime: currentDate,
      },
    });

    let setStageAPI;
    if (isManager) {
      setStageAPI = setInspectionLogStageAsManager({
        variables: {
          inspectionLogId: siteVisitInspectionLogID,
          inspectionLogStage: InspectionLogModel.STAGE.CLOSED,
        },
      });
    } else {
      setStageAPI = setInspectionLogStageAsAssignee({
        variables: {
          inspectionLogStage: InspectionLogModel.STAGE.CLOSED,
        },
      });
    }
    setStageAPI.then(() => {
      submitInspectionLog();
      setIsUserCurrentlyVisitingSite(false);
      setStartTime(null);
      setUpdatedSiteVisitInspectionLog(null);
      setVisitTimeElapsed("0h 0m 0s");
    });
  }, [updatedSiteVisitInspectionLog]);

  const onClickSiteVisit = () => {
    for (var i = 0; i < visitIntervalIds.length; i++) {
      clearInterval(visitIntervalIds[i]);
    }

    setVisitIntervalIds([]);
    startInspectionLogSiteVisit({
      variables: {
        inspectionAssetId: siteVisitInspectionAssetId,
        sourceId: siteId,
        sourceType: 'SITE',
      },
    }).then((result) => {
      setUpdatedSiteVisitInspectionLog(result.data.inspectionLog);
      const createdDate = new Date(result.data.inspectionLog.createdDate);
      const createdDateString = createdDate.toISOString();

      setInspectionLogStartDate({
        variables: {
          offsetDateTime: createdDateString,
        },
      }).then(() => {
        setIsUserCurrentlyVisitingSite(true);
        setStartTime(createdDateString);
      });
    });
  };

  const saveNewMediaAssetToCache = useCallback(
    (newMediaAsset) => {
      // Write MediaAsset to cache
      const mediaAsset = {
        ...newMediaAsset.mediaAsset,
        REFID: newMediaAsset.mediaAsset.refid,
        __typename: BaseMediaAssetModel.GRAPHQL_TYPE,
      };
      switch (mediaAsset.mediaType) {
        case MediaAssetModel.MEDIA_TYPE.IMAGE:
          mediaAsset.imageData.__typename = ImageMediaAssetModel.GRAPHQL_TYPE;
          break;
        case MediaAssetModel.MEDIA_TYPE.VIDEO:
          mediaAsset.videoData.__typename = VideoMediaAssetModel.GRAPHQL_TYPE;
          break;
        case MediaAssetModel.MEDIA_TYPE.AUDIO:
          mediaAsset.audioData.__typename = AudioMediaAssetModel.GRAPHQL_TYPE;
          break;
      }
      const mediaAssetRef = apolloClient.cache.writeFragment({
        id: apolloClient.cache.identify(mediaAsset),
        fragment: BaseMediaAssetModel.fragment,
        fragmentName: BaseMediaAssetModel.fragmentName,
        data: mediaAsset,
      });

      // Write SiteMediaAsset to cache
      apolloClient.cache.modify({
        id: siteCacheId,
        fields: {
          siteMediaAssetList: (currentList = []) => {
            const siteMediaAsset = {
              mediaAsset: mediaAssetRef,
              order: currentList.length + 1,
              publishStatus: PUBLISH_STATUS.UNPUBLISHED,
              __typename: SiteMediaAssetModel.GRAPHQL_TYPE,
            };
            return [...currentList, siteMediaAsset];
          },
        },
      });
    },
    [apolloClient.cache, siteCacheId]
  );

  const createSiteAudioMediaAsset = useCallback(
    async (audioFile) => {
      setMediaGalleryError(null);
      setMediaLoading(true);
      try {
        const response = await createSiteAudioMediaAssetRequest(siteId, audioFile);
        if (response.success) {
          saveNewMediaAssetToCache(response.responseData);
          setTimeout(() => {
            mediaGalleryRef.current.scrollToIndex(mediaAssets.length);
          }, 50);
        } else {
          setMediaGalleryError(t('ERROR_GENERIC'));
        }
      } catch (e) {
        okerror('Error adding audio to product.', e);
        setMediaGalleryError(t('ERROR_GENERIC'));
      } finally {
        setMediaLoading(false);
      }
    },
    [siteId, mediaAssets.length, saveNewMediaAssetToCache, t]
  );

  const createSiteImageMediaAsset = useCallback(
    async (imageFile, originalImageFile) => {
      setMediaGalleryError(null);
      setMediaLoading(true);
      try {
        const response = await createSiteImageMediaAssetRequest(siteId, imageFile, originalImageFile);
        if (response.success) {
          saveNewMediaAssetToCache(response.responseData);
          setTimeout(() => {
            mediaGalleryRef.current.scrollToIndex(mediaAssets.length);
          }, 50);
        } else {
          setMediaGalleryError(t('ERROR_GENERIC'));
        }
      } catch (e) {
        okerror('Error adding image to product.', e);
        setMediaGalleryError(t('ERROR_GENERIC'));
      } finally {
        setMediaLoading(false);
      }
    },
    [siteId, mediaAssets.length, saveNewMediaAssetToCache, t]
  );

  const createSiteVideoMediaAsset = useCallback(
    async (videoFile) => {
      setMediaGalleryError(null);
      setMediaLoading(true);
      try {
        const response = await createSiteVideoMediaAssetRequest(siteId, videoFile);
        if (response.success) {
          saveNewMediaAssetToCache(response.responseData);
          setTimeout(() => {
            mediaGalleryRef.current.scrollToIndex(mediaAssets.length);
          }, 50);
        } else {
          setMediaGalleryError(t('ERROR_GENERIC'));
        }
      } catch (e) {
        okerror('Error adding video to product.', e);
        setMediaGalleryError(t('ERROR_GENERIC'));
      } finally {
        setMediaLoading(false);
      }
    },
    [siteId, mediaAssets.length, saveNewMediaAssetToCache, t]
  );

  const reorderMediaBackward = useCallback(
    (mediaAssetId) => {
      setMediaGalleryError(null);

      // Optimistic response
      const reorderedSiteMedia = mediaAssets.find((sma) => sma.mediaAsset.id === mediaAssetId);
      const optimisticResponse = {
        siteMedia: {
          ...reorderedSiteMedia,
          order: reorderedSiteMedia.order - 1,
        },
      };

      // Call API
      reorderSiteMediaBackwardAPI({
        variables: {
          mediaAssetId,
          siteId,
        },
        optimisticResponse,
      });
    },
    [siteId, mediaAssets, reorderSiteMediaBackwardAPI]
  );

  const reorderMediaForward = useCallback(
    (mediaAssetId) => {
      setMediaGalleryError(null);

      // Optimistic response
      const reorderedSiteMedia = mediaAssets.find((sma) => sma.mediaAsset.id === mediaAssetId);
      const optimisticResponse = {
        siteMedia: {
          ...reorderedSiteMedia,
          order: reorderedSiteMedia.order + 1,
        },
      };

      // Call API
      reorderSiteMediaForwardAPI({
        variables: {
          mediaAssetId,
          siteId,
        },
        optimisticResponse,
      });
    },
    [siteId, mediaAssets, reorderSiteMediaForwardAPI]
  );

  const searchMediaDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchAPI({
          variables: {
            ignoreIdListByDataType: [
              { dataType: 'MEDIA_ASSET', ignoreIdList: mediaAssets.map((sma) => sma.mediaAsset.id) },
            ],
            searchPaginationDataByDataType: [
              { dataType: 'MEDIA_ASSET', searchPaginationData: { skip: 0, pageSize: 4 } },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [mediaAssets, searchAPI]
  );

  const unlinkMedia = useCallback(
    (mediaAssetId) => {
      setMediaGalleryError(null);

      // Call API
      unlinkSiteMediaAPI(site, mediaAssetId);
    },
    [site, unlinkSiteMediaAPI]
  );

  const updateImageMediaAsset = useCallback(
    async (mediaAssetId, file) => {
      setMediaGalleryError(null);
      setMediaLoading(true);
      try {
        const response = await updateImageMediaAssetRequest(mediaAssetId, file);
        if (response.success) {
          // Update MediaAsset in cache
          const mediaAsset = {
            ...response.responseData,
            imageData: {
              ...response.responseData.imageData,
              __typename: ImageMediaAssetModel.GRAPHQL_TYPE,
            },
            REFID: response.responseData.refid,
            __typename: MediaAssetModel.GRAPHQL_TYPE,
          };
          apolloClient.cache.writeFragment({
            id: apolloClient.cache.identify(mediaAsset),
            fragment: MediaAssetModel.fragment,
            fragmentName: MediaAssetModel.fragmentName,
            data: mediaAsset,
          });
        } else {
          setMediaGalleryError(t('ERROR_GENERIC'));
        }
      } catch (e) {
        okerror('Error updating image media asset.', e);
        setMediaGalleryError(t('ERROR_GENERIC'));
      } finally {
        setMediaLoading(false);
      }
    },
    [apolloClient.cache, t]
  );

  /* Methods */

  const dismissScanResultsPopup = useCallback(() => {
    setShowScanResultsPopup(false);
  }, []);

  const shareSite = useCallback(() => {
    setShowShareModal(true);
  }, []);

  const onClickLinkMedia = useCallback(
    (mediaAssetId) => {
      setMediaLoading(true);
      setMediaGalleryError(null);
      setMediaSearchString('');
      linkMediaAPI(site.id, mediaAssetId)
        .then(() => {
          setTimeout(() => {
            mediaGalleryRef.current.scrollToIndex(mediaAssets.length);
          }, 50);
        })
        .catch(() => {
          setMediaGalleryError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setMediaLoading(false);
        });
    },
    [linkMediaAPI, site.id, mediaAssets.length, t]
  );

  const onClickUnlinkMedia = useCallback(
    (mediaAssetId) => {
      unlinkMedia(mediaAssetId);
    },
    [unlinkMedia]
  );

  const onMediaFileUpdated = useCallback(
    (mediaAssetId, file) => {
      const siteMediaAsset = mediaAssets.find((sma) => sma.mediaAsset.id === mediaAssetId);
      switch (siteMediaAsset.mediaAsset.mediaType) {
        case MediaAssetModel.MEDIA_TYPE.IMAGE:
          updateImageMediaAsset(mediaAssetId, file);
          break;
        default:
          okwarn('Unsupported media updated with type:', siteMediaAsset.mediaAsset.mediaType);
      }
    },
    [mediaAssets, updateImageMediaAsset]
  );

  const onMediaReordered = useCallback(
    (mediaAssetId, direction) => {
      if (direction === 'left') {
        reorderMediaBackward(mediaAssetId);
      } else {
        reorderMediaForward(mediaAssetId);
      }
    },
    [reorderMediaBackward, reorderMediaForward]
  );

  const onNewMediaSelected = useCallback(
    (files, originalFiles) => {
      const file = files[0];
      const originalFile = originalFiles[0];
      const mediaType = simpleMIMEType(file.type).toUpperCase();
      switch (mediaType) {
        case MediaAssetModel.MEDIA_TYPE.AUDIO:
          createSiteAudioMediaAsset(file);
          break;
        case MediaAssetModel.MEDIA_TYPE.IMAGE:
          createSiteImageMediaAsset(file, originalFile);
          break;
        case MediaAssetModel.MEDIA_TYPE.VIDEO:
          createSiteVideoMediaAsset(file);
          break;
        default:
          okwarn('Unsupported media added with type:', mediaType);
      }
    },
    [createSiteAudioMediaAsset, createSiteImageMediaAsset, createSiteVideoMediaAsset]
  );

  const onSearchMedia = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setMediaSearchString(newSearchString);

      if (newSearchString.length > 1) {
        searchMediaDebounced(newSearchString);
      }
    },
    [searchMediaDebounced]
  );

  /* Effects */

  // Hide share toast after 5 seconds

  useEffect(() => {
    if (shareToastMessage) {
      setTimeout(() => {
        setShareToastMessage(null);
      }, 5000);
    }
  }, [shareToastMessage]);

  /* Render */

  let floatingButtonsBottom;

  if (useDesktopLayout) {
    floatingButtonsBottom = 0;
  } else {
    if (showInPopup) {
      if (isScrollingPopup) {
        floatingButtonsBottom = 0;
      } else {
        if (isShowingPWABanner) {
          floatingButtonsBottom = 100;
        }
        else {
          floatingButtonsBottom = 50; 
        }
      }
    } else {
      if (isShowingPWABanner) {
        floatingButtonsBottom = 120;
      }
      else {
        floatingButtonsBottom = 60; 
      }
    }
  }

  useEffect(() => {
    if (isUserCurrentlyVisitingSite && updatedSiteVisitInspectionLog) {
      //setStartTime(new Date());
      setStartTime(new Date(updatedSiteVisitInspectionLog.createdDate));
    } else {
      setStartTime(null);
    }
  }, [isUserCurrentlyVisitingSite, updatedSiteVisitInspectionLog]);
  
  useEffect(() => {
    function calculateTimeElapsed() {
      // Define the starting time
      // var startTime = new Date('2023-11-01T12:00:00'); // Replace with your own starting time

      // Get the current time
      var currentTime = new Date();

      // Calculate the time elapsed in milliseconds
      var timeElapsed = currentTime - startTime;

      // Convert milliseconds to days, hours, minutes, and seconds
      var seconds = Math.floor(timeElapsed / 1000) % 60;
      var minutes = Math.floor(timeElapsed / (1000 * 60)) % 60;
      var hours = Math.floor(timeElapsed / (1000 * 60 * 60)) % 24;

      setVisitTimeElapsed(hours + 'h ' + minutes + 'm ' + seconds + 's');
      return hours + 'h ' + minutes + 'm ' + seconds + 's';
    }

    if (startTime) {
      // Update the time elapsed every one second
      const initialVisitIntervalId = setInterval(calculateTimeElapsed, 1000);
      setVisitIntervalIds((prevArray) => [...prevArray, initialVisitIntervalId]);
    } else {
      for (var i = 0; i < visitIntervalIds.length; i++) {
        clearInterval(visitIntervalIds[i]);
      }

      setVisitIntervalIds([]);
    }
  }, [startTime]);

  useEffect(() => {
    if (visitIntervalIds.length > 1) {
      for (var i = 0; i < visitIntervalIds.length; i++) {
        if (i === 0) {
          clearInterval(visitIntervalIds[i]);
        }
      }
      setVisitIntervalIds([visitIntervalIds[1]]);
    }
  }, [updatedSiteVisitInspectionLog, visitIntervalIds]);
  
  let mediaSearchResults;
  if (mediaSearchInputFocused && mediaSearchString.length > 1) {
    mediaSearchResults = searchAPIResult.data?.search?.resultList?.map((r) => {
      const mediaAsset = r.mediaAssetData;
      // Icon
      let icon;
      let iconBackgroundImageUrl;
      switch (mediaAsset.mediaType) {
        case MediaAssetModel.MEDIA_TYPE.VIDEO:
          icon = <Icon className={styles.videoIcon} name={ICONS.PLAY.name} />;
          break;
        case MediaAssetModel.MEDIA_TYPE.AUDIO:
          icon = ICONS.AUDIO.name;
          break;
        default:
          icon = ICONS.IMAGE.name;
          iconBackgroundImageUrl = mediaAsset.imageData.imageURL;
          break;
      }

      // Highlight
      let highlightString;
      if (r.dataHighlights?.length) {
        const keys = r.dataHighlights[0].split('.');
        let data = mediaAsset;
        for (let keyIndex in keys) {
          const key = keys[keyIndex];
          data = data[key];
        }
        highlightString = data;
      }

      return {
        action: (
          <Button className={styles.searchSuggestionAction} icon={ICONS.LINK.name} linkStyle>
            {t('LINK')}
          </Button>
        ),
        highlightString,
        icon,
        iconBackgroundImageUrl,
        key: mediaAsset.id,
        title: t(mediaAsset.mediaType),
        subtitle: mediaAsset.REFID,
      };
    });
  }

  // Points
  const conformityGrade = formatPoints(site?.conformityPoint ?? 0);
  const reliabilityGrade = formatPoints(site?.reliabilityPointForPublishedLogs ?? 0);

  const siteName = site ? SiteModel.localizedNameForSite(site, locale) : '';
  const siteMedia = site?.siteMediaAssetList ?? [];

  const pageMenu = (
    <>
      <PageMenu
        assetName={siteName}
        showBackButton
        title={
          <>
            <div className={styles.submenuButtonContainer}>
              <Button className={styles.submenuButton} icon={ICONS.SHARE.name} linkStyle onClick={shareSite}>
                {useDesktopLayout && t('SHARE')}
              </Button>
              <FadeInOutTransition in={!!shareToastMessage}>
                <Toast className={styles.shareToast}>{shareToastMessage}</Toast>
              </FadeInOutTransition>
            </div>
          </>
        }
        titlePosition='right'
      />
    </>
  );

  const floatingButton = (
    <Button icon={ICONS.PLUS.name} onClick={() => setShowAddWorkPopup(true)} tint='creation'>
      {t('WORK')}
    </Button>
  );

  return (
    <>
      <PageTitle>
        {/* eslint-disable indent */}
        {t('PAGE_TITLE_ITEM', {
          data: {
            productName: siteName,
            okid: OKID,
          },
        })}
        {/* eslint-enable indent */}
      </PageTitle>
      {showInPopup ? pageMenu : <Menu>{pageMenu}</Menu>}
      {/* eslint-disable indent*/}

      <>
        <PageMenu
          className={`${styles.sectionsSubmenuContainer} ${menuIsVisible && !showInPopup && styles.sectionsSubmenuContainerMenu
            }`}
          sections={
            isEditor
              ? [
                { ref: workSectionRef, title: t('WORK'), icon: ICONS.COG.name },

                {
                  ref: documentationSectionRef,
                  title: t('PRODUCT_SECTION_DOCUMENTATION'),
                  icon: ICONS.DOCUMENTATION.name,
                },
                { ref: contactsSectionRef, title: t('CONTACTS_SECTION'), icon: ICONS.PROFILE.name },
                { ref: notesSectionRef, title: t('NOTES'), icon: ICONS.NOTES.name },
                { ref: partsSectionRef, title: t('PARTS'), icon: ICONS.PARTS.name },
                { ref: commentsSectionRef, title: t('COMMENTS_SECTION_HEADER'), icon: ICONS.COMMENTS.name },
              ]
              : [
                { ref: workSectionRef, title: t('WORK'), icon: ICONS.COG.name },

                {
                  ref: documentationSectionRef,
                  title: t('PRODUCT_SECTION_DOCUMENTATION'),
                  icon: ICONS.DOCUMENTATION.name,
                },
                { ref: contactsSectionRef, title: t('CONTACTS_SECTION'), icon: ICONS.PROFILE.name },
                { ref: notesSectionRef, title: t('NOTES'), icon: ICONS.NOTES.name },
                { ref: partsSectionRef, title: t('PARTS'), icon: ICONS.PARTS.name },
              ]
          }
          smartTabs
          submenu={isUserCurrentlyVisitingSite && startTime && <div class={styles.checkinIndicator}>
            <Text style={{ fontSize: 14, margin: 0 }}><b>Authorized visit</b></Text>
            <Text style={{ fontSize: 14, margin: 0 }}>{visitTimeElapsed}</Text>
          </div>}
          submenuClassName={styles.submenu}
          subMenuBarClassName={styles.subMenuContainer}
          tabClassName={styles.sectionsTab}
          tabsClassName={styles.sectionsSubmenu}
          topMenuClassName={styles.topMenuContainer}
        />
      </>

      {/* eslint-enable indent*/}

      <ContentLayout className={`${styles.outerSection} ${showInPopup && styles.outerSectionPopup}`}></ContentLayout>
      <ContentLayout
        className={`${styles.section} ${styles.basicsSection}`}
        contentClassName={styles.basicsSectionContent}
      >
        <UIContext.Provider value='card'>
          {site && (
            <>
              <div style={{ position: 'relative' }}>
                {isEditor && !isImageEditButtonVisible && (
                  <Button
                    className={styles.mediaGalleryEditDone}
                    linkStyle
                    icon={<Icon name={ICONS.TICK.name} />}
                    onClick={() => {
                      setIsImageEditButtonVisible(true);
                      setEnableSiteImageEdit(false);
                      setIsEditingMedia(false);
                    }}
                  >
                    {t('DONE')}
                  </Button>
                )}
                <div className={styles.productImageContainer}>
                  {isEditor && isImageEditButtonVisible && (
                    <div className={styles.productImageEditButtonContainer}>
                      <Button
                        className={styles.productImageEditButton}
                        icon={'/icons/admin_edit.svg'}
                        onClick={() => {
                          setIsImageEditButtonVisible(false);
                          setEnableSiteImageEdit(true);
                        }}
                        tint='secondary'
                      />
                    </div>
                  )}
                  <MediaGallery
                    allowAddingMediaTypes={['photo', 'video', 'audio']}
                    className={styles.mediaGallery}
                    enableEditing={enableSiteImageEdit}
                    media={siteMedia.map((sma) => sma.mediaAsset)}
                    showMediaDetails={enableSiteImageEdit}
                    previewsClassName={styles.mediaGalleryPreview}
                    onChangeEditingStatus={setIsEditingMedia}
                    onClickUnlink={onClickUnlinkMedia}
                    onMediaFileUpdated={onMediaFileUpdated}
                    onMediaReordered={onMediaReordered}
                    onNewMediaSelected={onNewMediaSelected}
                    ref={mediaGalleryRef}
                    warning={mediaGalleryError}
                  />
                  {siteMedia.length > 0 || isEditingMedia ? null : (
                    <div className={styles.mediaGalleryPlaceholder}>
                      <img
                        alt='No product media.'
                        className={styles.mediaGalleryPlaceholderImage}
                        src={`/img/empty_media_${theme.name}.svg`}
                      />
                    </div>
                  )}
                  {enableSiteImageEdit && (
                    <div className={styles.searchInputContainer}>
                      <SearchInput
                        className={styles.searchInput}
                        disabled={mediaLoading || isEditingMedia}
                        loading={searchAPIResult.loading}
                        onBlur={() => setMediaSearchInputFocused(false)}
                        onChange={onSearchMedia}
                        onFocus={() => setMediaSearchInputFocused(true)}
                        placeholder={t('MEDIA_GALLERY_SEARCH_PLACEHOLDER')}
                        value={mediaSearchString}
                      />
                      <Button
                        className={styles.searchNewButton}
                        disabled={mediaLoading || isEditingMedia}
                        linkStyle
                        onClick={() => mediaGalleryRef.current?.openMediaPicker()}
                        tint='creation'
                      >
                        {t('NEW')}
                      </Button>
                      <SearchSuggestions
                        className={styles.searchResults}
                        highlightTerm={mediaSearchString}
                        onSuggestionClick={onClickLinkMedia}
                        showNoResultsMessage={
                          mediaSearchInputFocused && mediaSearchString.length > 1 && mediaSearchResults?.length === 0
                        }
                        showMoreResultsMessage={
                          mediaSearchInputFocused &&
                          searchAPIResult.data?.search?.searchPaginationResultDataByDataType?.MEDIA_ASSET
                            ?.totalResults > mediaSearchResults?.length
                        }
                        subtitleClassName={styles.searchResultOKID}
                        suggestions={mediaSearchResults}
                      />
                      <Notice className={styles.supportedFileTypesNotice}>
                        <p>{tHTML('MEDIA_SUPPORTED_IMAGE_FORMATS')}</p>
                        <p>{tHTML('MEDIA_SUPPORTED_VIDEO_FORMATS')}</p>
                        <p>{tHTML('MEDIA_SUPPORTED_AUDIO_FORMATS')}</p>
                        <Text tint='notification'>
                          {t('MEDIA_FILESIZE_LIMITS', {
                            data: {
                              imageLimit: appConfig.fileSizeLimitsMB.image,
                              videoLimit: appConfig.fileSizeLimitsMB.video,
                              audioLimit: appConfig.fileSizeLimitsMB.audio,
                            },
                          })}
                        </Text>
                      </Notice>
                    </div>
                  )}
                </div>
              </div>

              <CardLayout
                fixedWidth={false}
                className={styles.productDetailsCard}
                innerClassName={styles.productDetailsCardContent}
              >
                {isEditor && isImageEditButtonVisible && (
                  <UIContext.Provider value='card'>
                    <div className={styles.productInfoEditButtonContainer}>
                      <Button
                        className={styles.productInfoEditButton}
                        icon={'/icons/admin_edit.svg'}
                        onClick={() => {
                          setShowSiteInfoEditPopup(true);
                        }}
                        tint='secondary'
                      />
                    </div>
                  </UIContext.Provider>
                )}
                <div className={styles.productOrganisation}>
                  <h3>{siteName}</h3>
                  <LinkToOrganisation organisation={site.organisation}>
                    <img
                      className={styles.organisationLogo}
                      src={organisationLogo ?? `/img/empty_media_${theme.name}.svg`}
                      alt="The product's organisation logo."
                    />
                    <Text className={styles.organisationName} size='md'>
                      {site.organisation.name}
                      <Tag className={styles.organisationTag} size='md'>
                        {site.organisation?.addressList[0]?.countryCode}
                      </Tag>
                    </Text>
                  </LinkToOrganisation>
                  <div className={styles.productInformation}>
                    <div className={styles.productInformationRow}>
                      <Text bold>{t('OKID')}</Text>
                      <div>
                        {formatOkid(site.OKID)}
                        <Button
                          className={styles.qrButton}
                          block
                          icon='/icons/qr_scan.svg'
                          linkStyle
                          onClick={() => setShowOKIDPopup(true)}
                        >
                          {t('SHOW_QR')}
                        </Button>
                      </div>
                    </div>
                    <div className={styles.productInformationRow}>
                      <Text bold>{t('SITE_FIELD_INTERNAL_ID_LABEL')}</Text>
                      <Text>{site?.identifier}</Text>
                    </div>
                    <div className={styles.productInformationRow}>
                      <Text className={styles.productInformationRowFieldWithIcon} bold>
                        {t('RELIABILITY')}
                        <Icon className={styles.productInformationFieldIcon} name={ICONS.RELIABILITY_GRADE.name} />
                      </Text>
                      <Text>{reliabilityGrade}</Text>
                    </div>
                    <div className={styles.productInformationRow}>
                      <Text className={styles.productInformationRowFieldWithIcon} bold>
                        {t('CONFORMITY')}
                        <Icon className={styles.productInformationFieldIcon} name={ICONS.DOCUMENTATION_GRADE.name} />
                      </Text>
                      <Text className={styles.productInformationRowFieldWithIcon}>{conformityGrade}</Text>
                    </div>
                    {isEditor && (
                      <div className={styles.productInformationRow}>
                        <Text className={styles.productInformationRowFieldWithIconLast} bold>
                          {t('VISIBILITY')}
                        </Text>
                        <Text className={styles.productInformationRowFieldWithIconLast}>
                          {site.publishStatus === PUBLISH_STATUS.PUBLISHED ? t('PUBLIC') : t('PRIVATE')}
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              </CardLayout>
            </>
          )}
        </UIContext.Provider>
      </ContentLayout>
      <ContentLayout className={styles.detailsContainer}>
        <div className={styles.sectionCard}>
          <Accordion
            className={`${styles.accordion} ${workAccordionEdit && styles.highlightedAccordion}`}
            ref={workSectionRef}
            edit={workAccordionEdit}
            headerClassName={styles.headerAccordion}
            hideSection
            onChangeEdit={setWorkAccordionEdit}
            onChangeOpen={setIsWorkSectionOpen}
            open={isWorkSectionOpen}
            showEdit={isEditor}
            title={
              <div className={styles.header}>
                <Icon className={styles.icon} name={ICONS.COG.name} height={24} width={24} />
                &nbsp;&nbsp;
                {t("WORK")}
              </div>
            }
          >
            {workAccordionEdit ? (
              <EditSitePageInspectionsSection site={site} />
            ) : (
              <EditSitePageWorkSection latestSiteInspectionLogsResult={latestSiteInspectionLogsResult} />
            )}
          </Accordion>
        </div>

        <div className={styles.sectionCard}>
          <Accordion
            className={`${styles.accordion} ${documentationAccordionEdit && styles.highlightedAccordion}`}
            ref={documentationSectionRef}
            edit={documentationAccordionEdit}
            headerClassName={styles.headerAccordion}
            hideSection
            onChangeEdit={setDocumentationAccordionEdit}
            onChangeOpen={setIsDocumentationSectionOpen}
            open={isDocumentationSectionOpen}
            showEdit={isEditor}
            title={
              <div className={styles.header}>
                <Icon className={styles.icon} name={ICONS.DOCUMENTATION.name} height={24} width={24} />
                &nbsp;&nbsp;
                {t('PRODUCT_SECTION_DOCUMENTATION')}
              </div>
            }
          >
            <EditSitePageDocumentationSection
              editMode={documentationAccordionEdit}
              site={site}
              isAuthorisedToEdit={isEditor}
            />
          </Accordion>
        </div>

        <div className={styles.sectionCard}>
          <Accordion
            className={`${styles.accordion} ${contactsAccordionEdit && styles.highlightedAccordion}`}
            ref={contactsSectionRef}
            edit={contactsAccordionEdit}
            headerClassName={styles.headerAccordion}
            hideSection
            onChangeEdit={setContactsAccordionEdit}
            onChangeOpen={setIsContactsSectionOpen}
            open={isContactsSectionOpen}
            showEdit={isEditor}
            title={
              <div className={styles.header}>
                <Icon className={styles.icon} name={ICONS.PROFILE.name} height={24} width={24} />
                &nbsp;&nbsp;
                {t('CONTACTS_SECTION')}
              </div>
            }
          >
            <EditSitePageContactSection
              asset={site}
              editMode={contactsAccordionEdit}
              sourceType='SITE'
              sourceId={site?.id}
              linkDataType='ORDER'
            />
          </Accordion>
        </div>

        <div className={styles.sectionCard}>
          <Accordion
            className={`${styles.accordion} ${notesAccordionEdit && styles.highlightedAccordion}`}
            edit={notesAccordionEdit}
            ref={notesSectionRef}
            headerClassName={styles.headerAccordion}
            hideSection
            onChangeEdit={setNotesAccordionEdit}
            onChangeOpen={setIsNotesSectionOpen}
            open={isNotesSectionOpen}
            showEdit={isEditor}
            title={
              <div className={styles.header}>
                <Icon className={styles.icon} name={ICONS.NOTES.name} height={24} width={24} />
                &nbsp;&nbsp;
                {t('NOTES')}
              </div>
            }
          >
            <EditSitePageNotesSection
              editMode={notesAccordionEdit}
              siteNotes={site.siteNoteAssetList ?? []}
              siteId={site.id}
            />
          </Accordion>
        </div>

        <div className={`${styles.sectionCard} ${!hasPermissionToPostComments && styles.sectionCardLast}`}>
          <Accordion
            className={`${styles.accordion} ${partsAccordionEdit && styles.highlightedAccordion}`}
            edit={partsAccordionEdit}
            ref={partsSectionRef}
            headerClassName={styles.headerAccordion}
            hideSection
            onChangeEdit={setPartsAccordionEdit}
            onChangeOpen={setIsPartsSectionOpen}
            open={isPartsSectionOpen}
            showEdit={isEditor}
            title={
              <div className={styles.header}>
                <Icon className={styles.icon} name={ICONS.PARTS.name} height={24} width={24} />
                &nbsp;&nbsp;
                {t('PARTS')}
              </div>
            }
          >
            <EditSitePagePartsSection editMode={partsAccordionEdit} site={site} />
          </Accordion>
        </div>
        {hasPermissionToPostComments && (
          <div className={`${styles.sectionCard} ${styles.sectionCardLast}`}>
            <Accordion
              className={styles.accordion}
              headerClassName={styles.headerAccordion}
              ref={commentsSectionRef}
              hideSection
              onChangeOpen={setIsCommentsSectionOpen}
              open={isCommentsSectionOpen}
              title={
                <div className={styles.header}>
                  <Icon className={styles.icon} name={ICONS.NOTES.name} height={24} width={24} />
                  &nbsp;&nbsp;
                  {t('COMMENTS_SECTION_HEADER')}
                </div>
              }
            >
              <ContentLayout style={{ paddingTop: 30 }}>
                <CommentsController assetType='SITE' assetId={site.id} assetOrganisationId={site.organisationId} />
              </ContentLayout>
            </Accordion>
          </div>
        )}
      </ContentLayout>
      <FloatingButtonsContainer
        className={showInPopup && styles.floatingButtons}
        style={{ bottom: floatingButtonsBottom }}
      >
        <Button
          onClick={isUserCurrentlyVisitingSite ? onClickSiteLeave : onClickSiteVisit}
          className={styles.checkinSystemButton}
          withCaret
        >
          <Text style={{ margin: 0 }}>{isUserCurrentlyVisitingSite ? t('LEAVE_ORGANISATION') : t('VISIT')}</Text>
        </Button>
        {floatingButton}
      </FloatingButtonsContainer>
      {showAddWorkPopup && (
        <AddWorkPopup
          dismiss={() => setShowAddWorkPopup(false)}
          data={site}
          preSelectedData={site}
          preSelectedDataType={'SITE'}
        />
      )}
      {renderScanResultsPopup && (
        <ScanResultsPopup dismiss={dismissScanResultsPopup} OKID={site.OKID} sourceType='SITE' />
      )}
      {showSiteInfoEditPopup && (
        <SiteInfoEditPopup site={site} identifier={site?.identifier} dismiss={() => setShowSiteInfoEditPopup(false)} />
      )}
      {showOKIDPopup && (
        <ShareOKIDPopup
          dismiss={() => setShowOKIDPopup(false)}
          instructions={t('SHARE_OKID_INSTRUCTIONS')}
          name={t('SITE')}
          OKID={OKID}
          profilePhotoUrl={SiteModel.previewImageUrl(site)}
        />
      )}
      {showShareModal && (
        <ShareAssetPopup
          dismiss={() => setShowShareModal(false)}
          asset={site}
          assetId={site.id}
          assetType='SITE'
          assetPublishStatus={site.publishStatus}
          sharedToList={site.assetAccessPermissionList}
          publishError={publishError}
          onClickChangeShareStatus={onClickChangeShareStatus}
        />
      )}
    </>
  );
}

SitePage.propTypes = {
  site: PropTypes.object.isRequired,
};
