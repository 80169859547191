import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { CommentsContext } from 'OK/components/comments/controller';
import { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import Text from 'OK/components/text';
import { baseTheme } from 'OK/styles/theme';
import useI18n from 'OK/util/hooks/useI18n';

/**
 *
 * @param {object} props
 * @param {boolean} [props.inProp=false] Whether to render visibly.
 * @param {string} props.replyTargetAssetId The id of the asset being replied to.
 * @param {string} props.replyTargetAssetType The type of the asset being replied to.
 */
export default function CommentsInput(props) {
  /* Variables */

  const { replyTargetAssetId, replyTargetAssetType, inProp = false, ...otherProps } = props;
  const { postComment, postCommentLoading } = useContext(CommentsContext);
  const { t } = useI18n();

  /* State */

  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');

  /* Event handlers */

  const onChangeMessage = useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  const onSubmit = useCallback(() => {
    setError(null);
    if (message) {
      postComment(message, replyTargetAssetType, replyTargetAssetId)
        .then(() => {
          setMessage('');
        })
        .catch(() => {
          setError(t('ERROR_GENERIC'));
        });
    }
  }, [replyTargetAssetId, replyTargetAssetType, message, postComment, t]);

  /* Effects */

  // Reset error if reply target changes
  useEffect(() => {
    setError(null);
  }, [replyTargetAssetId, replyTargetAssetType]);

  /* Render */

  return (
    <CSSTransition
      appear
      classNames={{
        appear: styles.exit,
        appearActive: styles.enter,
        appearDone: styles.enter,
        enter: styles.exit,
        enterActive: styles.enter,
        enterDone: styles.enter,
        exit: styles.exit,
        exitActive: styles.exit,
        exitDone: styles.exit,
      }}
      in={inProp}
      mountOnEnter
      unmountOnExit
      timeout={baseTheme.timing.timingShortMs}
    >
      <div {...otherProps}>
        <div className={styles.inputContainer}>
          <Input onChange={onChangeMessage} placeholder={t('COMMENT_INPUT_PLACEHOLDER')} value={message} />
          <Button
            className={styles.submitButton}
            icon={ICONS.SEND.name}
            loading={postCommentLoading}
            onClick={onSubmit}
            tint='creation'
            type='submit'
          >
            {t('COMMENT_BUTTON_POST')}
          </Button>
        </div>
        {error && (
          <Text className={styles.errorMessage} size='sm' tint='alert'>
            {error}
          </Text>
        )}
      </div>
    </CSSTransition>
  );
}

CommentsInput.propTypes = {
  inProp: PropTypes.bool,
  replyTargetAssetId: PropTypes.string.isRequired,
  replyTargetAssetType: PropTypes.string.isRequired,
};
