import LoadImage from 'blueimp-load-image';
import PropTypes from 'prop-types';
import { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';

import ImageCropper from 'OK/components/media/editors/imageCropper';
import VideoEditor from 'OK/components/media/editors/videoEditor';
import appConfig from 'OK/config/app';
import { trackError } from 'OK/util/analytics';
import ThemeContext from 'OK/util/context/theme';

// For some reason this import must come after the other imports or the component breaks
// eslint-disable-next-line import/order
import styles from './styles.module.scss';
import simpleMIMEType from 'OK/util/functions/simpleMIMEType';
import useI18n from 'OK/util/hooks/useI18n';

const acceptedAudioFileTypes = ['audio/mpeg'];
const acceptedDocumentFileTypes = [
  'application/msword', // .doc
  'application/pdf', // .pdf
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'text/csv', // .csv
  'text/plain', // .txt
  'text/rtf', // .rtf
];
const acceptedPhotoFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
const acceptedVideoFileTypes = ['video/mp4'];

/**
 * @typedef {object} MediaPickerProps
 * @prop {string} [className] The component's class.
 * @prop {boolean} [error=false] Indicate an error has occured.
 * @prop {number} [fileSizeLimitMb] Limit the size of files that can be selected. If not set, default file size limits
 * will be applied based on file type.
 * @prop {boolean} [invisible=false] Render the picker without UI. To show file selector in invisible mode, pass
 * a `ref` (see `ref` param).
 * @prop {string} [label] The name of the media being added. Will be shown under the upload image as
 * "Add {label}".
 * @prop {string[]} mediaTypes The type(s) of media that can be picked.
 * @prop {boolean} [multiple=false] Allow multiple files to be selected.
 * @prop {(fileType: string) => {}} [onBeganEditingMedia] Event handler when the user starts editing picked
 * media.
 * @prop {(file: File) => void} [onChange] Event handler when the file selection changes.
 * @prop {() => {}} [onEndedEditingMedia] Event handler when the user finishes editing picked media. This could
 * fire when the user picks and crops an image or picks and cancels cropping an image.
 * @prop {(error: Error) => void} [onError] Event handler when an error occurs.
 * @prop {*} [ref] Reference to the MediaPicker component. Exposes a ref with an `open` function to open the OS file
 * selection UI.
 */

/**
 * Component to select media files. Currently supports picking a single image file.
 *
 * @type {React.FC<MediaPickerProps>}
 */
const MediaPicker = forwardRef((props, forwardedRef) => {
  /* Variables */
  const {
    className,
    error = false,
    fileSizeLimitMb,
    invisible = false,
    label,
    mediaTypes,
    multiple = false,
    onBeganEditingMedia,
    onChange,
    onEndedEditingMedia,
    onError,
    promptToEditAfterPick = true,
    ...otherProps
  } = props;
  const inputRef = useRef();
  const theme = useContext(ThemeContext);
  const { t } = useI18n();

  /* Setup */

  // Configure exposed ref API
  useImperativeHandle(forwardedRef, () => ({
    open: () => {
      try {
        inputRef.current.click();
      } catch (e) {
        trackError(e);
      }
    },
  }));

  // Accepted file types
  let acceptedFileTypes = [];
  if (mediaTypes.includes('photo')) {
    acceptedFileTypes = [...acceptedFileTypes, ...acceptedPhotoFileTypes];
  }
  if (mediaTypes.includes('video')) {
    acceptedFileTypes = [...acceptedFileTypes, ...acceptedVideoFileTypes];
  }
  if (mediaTypes.includes('audio')) {
    acceptedFileTypes = [...acceptedFileTypes, ...acceptedAudioFileTypes];
  }
  if (mediaTypes.includes('document')) {
    acceptedFileTypes = [...acceptedFileTypes, ...acceptedDocumentFileTypes];
  }

  /* State */

  const [active, setActive] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [editingMedia, setEditingMedia] = useState(null);
  const [originalFiles, setOriginalFiles] = useState([]);

  /* Methods */

  const reset = () => {
    onEndedEditingMedia && onEndedEditingMedia();
    setEditingMedia(null);
  };

  const triggerChange = (files, originals = originalFiles) => {
    if (onChange) {
      onChange(files, originals);
    }

    // Reset input value so change events will be triggered even if the user selects the same file again.
    inputRef.current.value = null;
  };

  const triggerError = (error) => {
    okerror('MediaPicker error', error);
    if (onError) {
      onError(error);
    }
  };

  /* Events */

  const onBlur = () => {
    setHovering(false);
    setActive(false);
  };

  const onImageCropped = (croppedImageFile) => {
    reset();
    triggerChange([croppedImageFile]);
  };

  const onVideoSaved = (videoFile) => {
    reset();
    triggerChange([videoFile]);
  };

  const onFileAdded = async () => {
    const { files } = inputRef.current;

    if (files.length) {
      let processedFiles = [];
      for (let x = 0; x < files.length; x++) {
        const file = files[x];

        // Restrict files to accepted types
        const fileType = file.type;
        if (!acceptedFileTypes.includes(fileType)) {
          okerror(`Invalid file type ${fileType} passed to MediaPicker.`);
          triggerError('INVALID_FILETYPE');
          return;
        }

        const simpleFileType = simpleMIMEType(fileType);

        const fileSizeMb = file.size / 1000 / 1000;
        let maxFileSize;
        if (fileSizeLimitMb) {
          // Restrict file size based on prop
          maxFileSize = fileSizeLimitMb;
        } else {
          // Restrict file size based on file type
          switch (simpleFileType) {
            case 'audio':
            case 'image':
            case 'video':
              maxFileSize = appConfig.fileSizeLimitsMB[simpleFileType];
              break;
            default:
              maxFileSize = appConfig.fileSizeLimitsMB.document;
              break;
          }
        }
        if (fileSizeMb > maxFileSize) {
          triggerError('FILESIZE_LIMIT_EXCEEDED');
          return;
        }

        if (simpleFileType === 'image') {
          // Ensure correct orientation of images via blueimp-load-image
          const imageData = await LoadImage(file, {
            canvas: true,
            imageSmoothingEnabled: false,
            meta: true,
            maxHeight: 1000,
            maxWidth: 1000,
            orientation: true,
          });
          okdebug('blue imp imageData', imageData);
          const imageBlob = await new Promise((resolve) => imageData.image.toBlob(resolve));
          const processedImageFile = new File([imageBlob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          processedFiles.push(processedImageFile);
          // processedFiles.push(file);
        } else {
          processedFiles.push(file);
        }
      }

      setOriginalFiles(processedFiles);

      // If only adding 1 media, let the user edit it before triggering a change event
      if (processedFiles.length === 1) {
        const file = processedFiles[0];
        if (
          promptToEditAfterPick &&
          (acceptedPhotoFileTypes.includes(file.type) || acceptedVideoFileTypes.includes(file.type))
        ) {
          onBeganEditingMedia && onBeganEditingMedia(file.type);
          setEditingMedia(file);
          return;
        }
      }

      // Default to triggering a change event with the added file(s)
      triggerChange(processedFiles, processedFiles);
    }
  };

  const onHover = () => {
    setHovering(true);
  };

  /* Render */

  if (!acceptedFileTypes.length) {
    okerror('No valid media types were passed to MediaPicker. Valid types are: "photo", "video".');
    return null;
  }

  let containerClassNames = styles.container;
  if (error) {
    containerClassNames = `${containerClassNames} ${styles.error}`;
  }
  if (className) {
    containerClassNames = `${containerClassNames} ${className}`;
  }

  if (editingMedia) {
    const mediaType = simpleMIMEType(editingMedia.type).toUpperCase();
    // TODO: Edit other types of media
    if (mediaType === 'IMAGE') {
      // Edit the picked image
      return (
        <ImageCropper
          className={containerClassNames}
          file={editingMedia}
          onCancel={reset}
          onCrop={onImageCropped}
          {...otherProps}
        />
      );
    } else if (mediaType === 'VIDEO') {
      // Edit the picked video
      return (
        <VideoEditor
          className={containerClassNames}
          file={editingMedia}
          onCancel={reset}
          onSave={() => onVideoSaved(editingMedia)}
          {...otherProps}
        />
      );
    }
  }

  // File input component
  let inputClassnames = styles.fileInput;
  if (invisible) {
    inputClassnames = `${inputClassnames} ${styles.invisible}`;
  }
  const fileInput = (
    <input
      accept={acceptedFileTypes.join(',')}
      className={inputClassnames}
      onChange={onFileAdded}
      multiple={multiple}
      ref={inputRef}
      type='file'
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
    />
  );

  // In invisible mode, only render the file input
  if (invisible) {
    return fileInput;
  }

  const _label = label || `Add ${mediaTypes.join(' / ')}`;

  let iconModifer = '';
  if (active) {
    iconModifer = '_active';
  } else if (hovering) {
    iconModifer = '_hover';
  }
  let uploadIconSrc = `/icons/upload_big${iconModifer}${theme.name === 'dark' ? '_dark' : ''}.svg`;

  return (
    <div className={containerClassNames} onMouseOut={onBlur} onMouseOver={onHover} {...otherProps}>
      <div className={styles.layout}>
        <img alt={t('IMG_ALT_UPLOAD')} className={styles.uploadIcon} src={uploadIconSrc} />
        <p className={styles.label}>
          <strong>{_label}</strong>
        </p>
      </div>
      {fileInput}
    </div>
  );
});

MediaPicker.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  fileSizeLimitMb: PropTypes.number,
  label: PropTypes.string,
  invisible: PropTypes.bool,
  mediaTypes: PropTypes.arrayOf(PropTypes.oneOf(['photo', 'video', 'audio', 'document'])).isRequired,
  multiple: PropTypes.bool,
  onBeganEditingMedia: PropTypes.func,
  onChange: PropTypes.func,
  onEndedEditingMedia: PropTypes.func,
  onError: PropTypes.func,
  promptToEditAfterPick: PropTypes.bool,
};

export default MediaPicker;
