import useI18n from 'OK/util/hooks/useI18n';

function useDocumentType(documentAsset, options = {}) {
  const { fullType = false } = options;
  const { t } = useI18n();

  if (!documentAsset) {
    return t('FILE');
  }

  if (fullType) {
    return t(`DOCUMENT_TYPE_FULL_${documentAsset.documentType}`);
  }

  return t(`DOCUMENT_TYPE_${documentAsset.documentType}`);
}

export default useDocumentType;
export function DocumentType(props) {
  const { documentAsset, ...otherProps } = props;
  const type = useDocumentType(documentAsset, otherProps);
  return type;
}
