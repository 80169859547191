import { gql } from '@apollo/client';

export default class BaseProductModel {
  static GRAPHQL_TYPE = 'Product';

  static fragmentName = 'Base_Product_Fragment';
  static fragment = gql`
    fragment ${BaseProductModel.fragmentName} on ${BaseProductModel.GRAPHQL_TYPE} {
      REFID
      SKUID
      createdBy
      createdDate
      id
      lastModifiedBy
      lastModifiedDate
      name
      publishStatus
      status
      version
      conformityPoint
      criticalAQL
      criticalFailure
      criticalInspected
      majorAQL
      majorFailure
      majorInspected
      minorAQL
      minorFailure
      minorInspected
      publishedLogs
      reliabilityPointForAllLogs
      reliabilityPointForPublishedLogs
      totalLogs
    }
  `;
}
