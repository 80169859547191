import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LinkToFile from '../link/linkToFile';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import CardLayout from 'OK/components/layouts/card';
import Notice from 'OK/components/notice';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import Toggle from 'OK/components/toggle';
import DocumentModel from 'OK/models/document';
import UserDocumentModel from 'OK/models/userDocument';
import EditFilePopup from 'OK/modules/popups/file/edit';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import { formatDate } from 'OK/util/formatting';
import { isAuthorisedViaShare } from 'OK/util/functions/isAuthorised';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import { DocumentType } from 'OK/util/hooks/useDocumentType';
import useI18n from 'OK/util/hooks/useI18n';
import usePermission, { PERMISSIONS } from 'OK/util/hooks/usePermission';

/**
 * A component to display a document.
 *
 * @param {object} props
 * @param {string} [props.className] The component's class.
 * @param {object} props.document The document being displayed.
 * @param {boolean} [props.loadingPublish=false] Indicate the publish toggle is loading.
 * @param {{(documentRefId: string, published: boolean) => void}} [props.onChangePublished] Event handler for when a document is published / unpublished.
 * @param {{(documentRefId: string) => void}} [props.onClickUnlink] Event handler for when a document should be unlinked.
 * @param {string} [props.publishError] Indicate an error occured publishing / unpublishing the document.
 * @param {boolean} props.published Whether the document is published or not.
 * @param {string} [props.unlinkButtonTitle='Unlink'] The title of the button for unlinking the document.
 * @param {string} [props.unlinkError] Indicate an error occured unlinking the document.
 */
export default function Document(props) {
  /* Variables */

  const { t, locale } = useI18n();
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const [, , currentUser] = useAuthentication();
  const allowManageUserCertificates = usePermission(PERMISSIONS.USER_CERTIFICATE, currentUser, activeOrganisationId);

  const {
    className,
    document,
    isAuthorisedToEdit,
    loadingPublish = false,
    onChangePublished,
    onClickUnlink,
    organisationMode,
    publicView,
    publishError,
    published = false,
    showPublishOption = true,
    unlinkButtonTitle = t('NOTE_CARD_UNLINK_BUTTON_DEFAULT'),
    unlinkError,
    userId,
    userMode,
    ...otherProps
  } = props;
  const {
    applicableMarketList = [],
    coveredLanguageList = [],
    documentName,
    documentNumber,
    documentStandardList,
    documentType,
    madeByOrganisation,
    organisationPublishStatus,
    publishStatus,
    REFID,
    source,
    sourceId,
    userDocumentStandardList,
    issueDate,
    validFrom,
    validTill,
  } = document;

  const hasPermissionToEdit =
    isAuthorisedToEdit ||
    isAuthorisedViaShare(document?.assetAccessPermission, AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION);

  /* State */

  const [documentConfig, setDocumentConfig] = useState(null);
  const [renderEditFilePopup, setRenderEditFilePopup] = useState(false);

  /* Methods */

  const closeEditFilePopup = useCallback(() => {
    setRenderEditFilePopup(false);
  }, []);

  const openEditFilePopup = useCallback(() => {
    setRenderEditFilePopup(true);
  }, []);

  /* Event handlers */

  const onPublishToggled = (newValue) => {
    if (onChangePublished) {
      onChangePublished(document.id, newValue);
    }
  };

  const onUnlinkClicked = () => {
    if (onClickUnlink) {
      onClickUnlink(document.id);
    }
  };

  /* Effects */

  // Load document config
  useEffect(() => {
    async function loadConfig() {
      const config = userMode
        ? await UserDocumentModel.getConfigForType(documentType)
        : await DocumentModel.getConfigForType(documentType);
      setDocumentConfig(config);
    }

    loadConfig();
  }, [documentType, userMode]);

  /* Render */

  let fields = [];
  if (documentConfig) {
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.DOCUMENT_NUMBER : DocumentModel.FIELD_NAMES.DOCUMENT_NUMBER
      )
    ) {
      documentNumber &&
        fields.push(
          <div
            className={styles.field}
            key={userMode ? UserDocumentModel.FIELD_NAMES.DOCUMENT_NUMBER : DocumentModel.FIELD_NAMES.DOCUMENT_NUMBER}
          >
            <Text bold className={styles.fieldName}>
              {t('FILE_FIELD_DOCUMENT_NUMBER')}
            </Text>
            <Text className={styles.fieldValue}>{documentNumber}</Text>
          </div>
        );
    }
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.LANGUAGES : DocumentModel.FIELD_NAMES.LANGUAGES
      )
    ) {
      coveredLanguageList.length > 0 &&
        fields.push(
          <div
            className={styles.field}
            key={userMode ? UserDocumentModel.FIELD_NAMES.LANGUAGES : DocumentModel.FIELD_NAMES.LANGUAGES}
            a
          >
            <Text bold className={styles.fieldName}>
              {t('FILE_FIELD_LANGUAGES')}:
            </Text>
            <Text className={styles.fieldValue}>
              {/* eslint-disable indent */}
              {coveredLanguageList.map((l, index) => {
                let language = l.toUpperCase();
                if (index > 0) {
                  language = `, ${language}`;
                }
                return language;
              })}
              {/* eslint-enable indent */}
            </Text>
          </div>
        );
    }
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.APPLICABLE_MARKETS : DocumentModel.FIELD_NAMES.APPLICABLE_MARKETS
      )
    ) {
      applicableMarketList.length > 0 &&
        fields.push(
          <div className={styles.field} key={DocumentModel.FIELD_NAMES.APPLICABLE_MARKETS}>
            <Text bold className={styles.fieldName}>
              {t('FILE_FIELD_MARKETS')}:
            </Text>
            <Text className={styles.fieldValue}>
              {/* eslint-disable indent */}
              {applicableMarketList.map((m, index) => {
                let market = m.toUpperCase();
                if (index > 0) {
                  market = `, ${market}`;
                }
                return market;
              })}
              {/* eslint-enable indent */}
            </Text>
          </div>
        );
    }
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.MADE_BY_ORGANISATION : DocumentModel.FIELD_NAMES.MADE_BY_ORGANISATION
      )
    ) {
      madeByOrganisation?.name &&
        fields.push(
          <div
            className={styles.field}
            key={
              userMode
                ? UserDocumentModel.FIELD_NAMES.MADE_BY_ORGANISATION
                : DocumentModel.FIELD_NAMES.MADE_BY_ORGANISATION
            }
          >
            <Text bold className={styles.fieldName}>
              {t('FILE_FIELD_MADE_BY')}:
            </Text>
            <Text tint={madeByOrganisation?.name && 'navigation'} className={styles.fieldValue}>
              {madeByOrganisation?.name}
            </Text>
          </div>
        );
    }

    if (documentConfig.fields.includes(userMode && UserDocumentModel.FIELD_NAMES.ISSUE_DATE)) {
      issueDate &&
        fields.push(
          <div className={styles.field} key={userMode && UserDocumentModel.FIELD_NAMES.ISSUE_DATE}>
            <Text bold className={styles.fieldName}>
              {t('FILE_FIELD_ISSUE_DATE')}:
            </Text>
            <Text className={styles.fieldValue}>
              {formatDate(new Date(parseInt(issueDate, 10)), locale, { style: 'short' })}
            </Text>
          </div>
        );
    }
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.VALID_FROM : DocumentModel.FIELD_NAMES.VALID_FROM
      )
    ) {
      validFrom &&
        fields.push(
          <div
            className={styles.field}
            key={userMode ? UserDocumentModel.FIELD_NAMES.VALID_FROM : DocumentModel.FIELD_NAMES.VALID_FROM}
          >
            <Text bold className={styles.fieldName}>
              {t('FILE_FIELD_VALID_FROM')}:
            </Text>
            <Text className={styles.fieldValue}>
              {formatDate(new Date(parseInt(validFrom, 10)), locale, { style: 'short' })}
            </Text>
          </div>
        );
    }
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.VALID_UNTIL : DocumentModel.FIELD_NAMES.VALID_UNTIL
      )
    ) {
      validTill &&
        fields.push(
          <div
            className={styles.field}
            key={userMode ? UserDocumentModel.FIELD_NAMES.VALID_UNTIL : DocumentModel.FIELD_NAMES.VALID_UNTIL}
          >
            <Text bold className={styles.fieldName}>
              {t('FILE_FIELD_VALID_UNTIL')}:
            </Text>
            <Text className={styles.fieldValue}>
              {formatDate(new Date(parseInt(validTill, 10)), locale, { style: 'short' })}
            </Text>
          </div>
        );
    }
    if (
      documentConfig.fields.includes(
        userMode ? UserDocumentModel.FIELD_NAMES.STANDARDS : DocumentModel.FIELD_NAMES.STANDARDS
      )
    ) {
      fields.push(
        (documentStandardList?.length > 0 || userDocumentStandardList?.length > 0) && (
          <div
            className={userMode ? null : styles.field}
            key={userMode ? UserDocumentModel.FIELD_NAMES.STANDARDS : DocumentModel.FIELD_NAMES.STANDARDS}
          >
            <Text bold className={styles.fieldName}>
              {t('FILE_FIELD_STANDARDS')}:
            </Text>
            <Text className={styles.fieldValue}>{userMode ? null : documentStandardList?.length ?? 0}</Text>
            {userDocumentStandardList?.map((standard, i) => (
              <Text key={i} className={styles.standardName}>
                {t(`DOCUMENT_STANDARD_${standard}`)}
              </Text>
            ))}
          </div>
        )
      );
    }
  }

  let userInstructions;
  let showEdit;

  if (!publicView) {
    if (source === 'USER') {
      if (publishStatus === PUBLISH_STATUS.PUBLISHED) {
        if (organisationPublishStatus === PUBLISH_STATUS.UNPUBLISHED) {
          userInstructions = t('DOCUMENT_OWNER_GRANTED_ORGANISAION');
          showEdit = true;
        } else if (organisationPublishStatus === PUBLISH_STATUS.PUBLISHED) userInstructions = '';
        showEdit = true;
      } else if (publishStatus === PUBLISH_STATUS.UNPUBLISHED) {
        if (organisationPublishStatus === PUBLISH_STATUS.UNPUBLISHED) {
          userInstructions = t('DOCUMENT_OWNER_GRANT_ORGANISAION');
          showEdit = true;
        } else if (organisationPublishStatus === PUBLISH_STATUS.PUBLISHED) {
          userInstructions = t('DOCUMENT_OWNER_GRANT_ORGANISAION');
          showEdit = true;
        }
      }
    } else if (source === 'ORGANISATION') {
      if (publishStatus === PUBLISH_STATUS.PUBLISHED) {
        if (organisationPublishStatus === PUBLISH_STATUS.UNPUBLISHED) {
          userInstructions = t('DOCUMENT_OWNER_GRANTED_ORGANISAION');
          showEdit = true;
        } else if (organisationPublishStatus === PUBLISH_STATUS.PUBLISHED) userInstructions = '';
        showEdit = true;
      } else if (publishStatus === PUBLISH_STATUS.UNPUBLISHED) {
        if (organisationPublishStatus === PUBLISH_STATUS.UNPUBLISHED) {
          userInstructions = t('DOCUMENT_SHARED_INTERNALLY');
          showEdit = false;
        } else if (organisationPublishStatus === PUBLISH_STATUS.PUBLISHED) {
          userInstructions = t('DOCUMENT_ORGANISATION_GRANTED_YOU');
          showEdit = true;
        }
      }
    }
  } else if (publicView) {
    if (source === 'USER') {
      if (publishStatus === PUBLISH_STATUS.PUBLISHED) {
        if (organisationPublishStatus === PUBLISH_STATUS.UNPUBLISHED) {
          userInstructions = t('DOCUMENT_OWNER_GRANTED_YOU');
          showEdit = true;
        } else if (organisationPublishStatus === PUBLISH_STATUS.PUBLISHED) userInstructions = '';
        showEdit = true;
      } else if (publishStatus === PUBLISH_STATUS.UNPUBLISHED) {
        if (organisationPublishStatus === PUBLISH_STATUS.UNPUBLISHED) {
          userInstructions = t('DOCUMENT_OWNER_GRANT_ORGANISAION');
          showEdit = true;
        } else if (organisationPublishStatus === PUBLISH_STATUS.PUBLISHED) {
          userInstructions = t('DOCUMENT_OWNER_GRANTED_ORGANISAION');
          showEdit = true;
        }
      }
    } else if (source === 'ORGANISATION') {
      if (publishStatus === PUBLISH_STATUS.PUBLISHED) {
        if (organisationPublishStatus === PUBLISH_STATUS.UNPUBLISHED) {
          userInstructions = t('DOCUMENT_ORGANISATION_GRANTED_OWDER');
          showEdit = true;
        } else if (organisationPublishStatus === PUBLISH_STATUS.PUBLISHED) userInstructions = '';
        showEdit = true;
      } else if (publishStatus === PUBLISH_STATUS.UNPUBLISHED) {
        if (organisationPublishStatus === PUBLISH_STATUS.UNPUBLISHED) {
          userInstructions = t('DOCUMENT_ORGANISATION_GRANT_OWNER');
          showEdit = true;
        } else if (organisationPublishStatus === PUBLISH_STATUS.PUBLISHED) {
          userInstructions = t('DOCUMENT_ORGANISATION_GRANTED_OWNER');
          showEdit = true;
        }
      }
    }
  }

  let showRemove;
  if (source == 'USER') {
    if (sourceId == userId) {
      showRemove = true;
    } else {
      showRemove = false;
    }
  } else if (source === 'ORGANISATION') {
    if (sourceId == activeOrganisationId && allowManageUserCertificates) {
      showRemove = true;
    } else {
      showRemove = false;
    }
  }

  return (
    <CardLayout className={className} innerClassName={styles.containerInner} {...otherProps}>
      <div className={styles.header}>
        <h4 className={styles.title}>
          <DocumentType documentAsset={document} />
        </h4>
        <Tag className={styles.refId} size='sm'>
          {REFID}
        </Tag>
      </div>
      <Text>{userMode && userInstructions}</Text>
      <Notice className={styles.publishNotice} extendSides>
        {showPublishOption && (
          <div className={styles.publishRow}>
            <p className={styles.publishLabel}>
              <strong>{t('PUBLISH')}</strong>
            </p>
            {loadingPublish && <Icon className={styles.publishStatusIcon} name={ICONS.SPINNER.name} />}
            {publishError && !loadingPublish && (
              <Icon className={styles.publishStatusIcon} name={ICONS.ALERT.name} tint='notification' />
            )}
            <Toggle checked={published} disabled={loadingPublish} onChange={onPublishToggled} />
          </div>
        )}
        <Text className={styles.attachment} tint='navigation'>
          <Icon className={styles.attachmentIcon} name={ICONS.DOCUMENT.name} />
          {documentName !== '' ? documentName : t('PRODUCT_ARCHIVE_CARD_UNNAMED')}
        </Text>
      </Notice>
      {publishError && (
        <Text className={styles.publishError} size='sm' tint='notification'>
          {publishError}
        </Text>
      )}
      {fields}

      <div className={styles.manageButtons}>
        {userMode && showEdit && (
          <Button
            block
            className={styles.manageButton}
            icon={ICONS.PENCIL.name}
            onClick={openEditFilePopup}
            tint='navigation'
          >
            {t('EDIT')}
          </Button>
        )}
        {!userMode &&
          (hasPermissionToEdit ? (
            <Button
              block
              className={styles.manageButton}
              icon={ICONS.PENCIL.name}
              onClick={openEditFilePopup}
              tint='navigation'
            >
              {t('EDIT_FILE_DETAILS')}
            </Button>
          ) : null)}
        {userMode && (
          <LinkToFile className={styles.linkToDownload} file={document} linkToDownload>
            <Button block className={styles.manageButton} icon={ICONS.DOWNLOAD.name} tint='navigation'>
              {t('DOWNLOAD')}
            </Button>
          </LinkToFile>
        )}
        {userMode &&
          showRemove &&
          (isAuthorisedToEdit ? (
            <Button
              block
              className={styles.manageButton}
              icon={ICONS.TRASH.name}
              onClick={onUnlinkClicked}
              tint='alert'
            >
              {unlinkButtonTitle}
            </Button>
          ) : null)}
        {!userMode && (
          <Button block className={styles.manageButton} icon={ICONS.UNLINK.name} onClick={onUnlinkClicked} tint='alert'>
            {unlinkButtonTitle}
          </Button>
        )}
      </div>
      {unlinkError && (
        <Text className={styles.unlinkError} size='sm' tint='notification'>
          {unlinkError}
        </Text>
      )}
      {renderEditFilePopup && (
        <EditFilePopup
          dismiss={closeEditFilePopup}
          fileId={document.id}
          userMode={userMode ? true : false}
          organisationMode={organisationMode ? true : false}
        />
      )}
    </CardLayout>
  );
}

Document.propTypes = {
  className: PropTypes.string,
  document: PropTypes.object.isRequired,
  isAuthorisedToEdit: PropTypes.bool,
  loadingPublish: PropTypes.bool,
  onChangePublished: PropTypes.func,
  onClickUnlink: PropTypes.func,
  organisationMode: PropTypes.bool,
  organisationPublishStatus: PropTypes.bool,
  publicView: PropTypes.bool,
  published: PropTypes.bool,
  publishError: PropTypes.string,
  showPublishOption: PropTypes.bool,
  unlinkButtonTitle: PropTypes.string,
  unlinkError: PropTypes.string,
  userMode: PropTypes.bool,
  userDocumentStandardList: PropTypes.array,
  userId: PropTypes.string,
};
