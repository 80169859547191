import PropTypes from 'prop-types';

import styles from './styles.module.scss';

/**
 * @typedef {Object} ArchiveCardMainContentProps
 * @prop {Node} children
 * @prop {string} className
 */

/**
 * Container for main card content.
 *
 * @param {ArchiveCardMainContentProps} props
 */
export default function ArchiveCardMainContent(props) {
  const { children, className, ...otherProps } = props;

  // Classes
  let classNames = styles.mainContent;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <div className={classNames} {...otherProps}>
      {children}
    </div>
  );
}

ArchiveCardMainContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
