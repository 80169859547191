import { gql } from '@apollo/client';

import BaseMediaAssetModel from './mediaAsset/base';

export default class TestAssetMediaAssetModel {
  static GRAPHQL_TYPE = 'TestAssetMediaAsset';

  static fragmentName = 'TestAssetMediaAsset_Fragment';
  static fragment = gql`
    fragment ${TestAssetMediaAssetModel.fragmentName} on ${TestAssetMediaAssetModel.GRAPHQL_TYPE} {
      mediaAsset {
        ...${BaseMediaAssetModel.fragmentName}
      }
      order
      publishStatus
    }
    ${BaseMediaAssetModel.fragment}
  `;
}
