import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import isFuture from 'date-fns/isFuture';
import subtractFromDate from 'date-fns/sub';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import styles from './styles.module.scss';

import Text from 'OK/components/text';
import useI18n from 'OK/util/hooks/useI18n';

function Unit(props) {
  const { label, number } = props;

  return (
    <div className={styles.unitContainer}>
      <p className={styles.number}>{number}</p>
      <Text bold className={styles.label} size='sm'>
        {label}
      </Text>
    </div>
  );
}

Unit.propTypes = {
  label: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
};

export default function Countdown(props) {
  /* Variables */

  const { className, date, ...otherProps } = props;
  const { t } = useI18n();

  /* State */

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  /* Methods */

  const calculate = useCallback(() => {
    const now = new Date();
    const currentDays = Math.abs(differenceInDays(date, now));
    const dateMinusDays = subtractFromDate(date, { days: currentDays });
    const currentHours = Math.abs(differenceInHours(dateMinusDays, now));
    const dateMinusHours = subtractFromDate(dateMinusDays, { hours: currentHours });
    const currentMinutes = Math.abs(differenceInMinutes(dateMinusHours, now));
    const dateMinusMinutes = subtractFromDate(dateMinusHours, { minutes: currentMinutes });
    const currentSeconds = Math.abs(differenceInSeconds(dateMinusMinutes, now));

    setDays(currentDays);
    setHours(currentHours);
    setMinutes(currentMinutes);
    setSeconds(currentSeconds);
  }, [date]);

  /* Effects */

  // Update numbers
  useEffect(() => {
    let interval;

    if (isFuture(date)) {
      calculate();

      interval = setInterval(() => {
        calculate();
      }, 1000);
    }

    return function () {
      clearInterval(interval);
    };
  }, [calculate, date]);

  /* Render */

  let classNames = styles.container;
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <div className={classNames} {...otherProps}>
      <Unit number={days} label={days === 1 ? t('DAY_SINGULAR') : t('DAY_PLURAL')} />
      <Unit number={hours} label={hours === 1 ? t('HOUR_SINGULAR') : t('HOUR_PLURAL')} />
      <Unit number={minutes} label={minutes === 1 ? t('MINUTE_SINGULAR') : t('MINUTE_PLURAL')} />
      <Unit number={seconds} label={seconds === 1 ? t('SECOND_SINGULAR') : t('SECOND_PLURAL')} />
    </div>
  );
}

Countdown.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};
