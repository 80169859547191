import replaceAlphaInRGBA from '../functions/replaceAlphaInRGBA';

import { LATEST_LOGS_CHART_TYPE } from './types';

import { generateDataGradientBackground } from '.';

const POINT_HOVER_RADIUS = 6;

/* Generic chart formatting */

export function formatBarChartData(data, labels, backgroundColors) {
  const dataset = {
    backgroundColor: backgroundColors,
    data,
    fill: 'start',
  };

  return {
    datasets: [dataset],
    labels,
  };
}

/* Generic chart dataset formatting */

export function formatAreaChartDataset(data, label, options) {
  const { chartHeight, dataBackgroundColorRGBA, hideTooltip = false } = options;

  const backgroundColorStart = replaceAlphaInRGBA(dataBackgroundColorRGBA, '0.6');
  const backgroundColorFinish = replaceAlphaInRGBA(dataBackgroundColorRGBA, '0.2');
  const gradientBackground = generateDataGradientBackground(backgroundColorStart, backgroundColorFinish, chartHeight);
  const dataset = {
    backgroundColor: gradientBackground,
    backgroundColorFinish,
    backgroundColorStart,
    borderWidth: 0,
    data,
    fill: 'start',
    hideTooltip,
    label,
    pointBackgroundColor: 'transparent',
    pointBorderColor: 'transparent',
    pointHoverBackgroundColor: backgroundColorStart,
    pointHoverRadius: hideTooltip ? 0 : POINT_HOVER_RADIUS,
    pointRadius: 0,
    showLine: false,
  };

  return dataset;
}

export function formatLineChartDataset(data, label, options) {
  const { color, dashed = false, hideTooltip = false } = options;
  const dataset = {
    backgroundColor: color,
    borderColor: color,
    borderWidth: 2,
    data,
    hideTooltip,
    label,
    pointHoverRadius: hideTooltip ? 0 : POINT_HOVER_RADIUS,
    pointRadius: 0,
    type: 'line',
  };

  if (dashed) {
    dataset.borderDash = [4, 4];
  }

  return dataset;
}

/* Specific chart formatting */

export function formatChartDataForAggregatedLogs(data, highlightData, t, options) {
  const { chartDisplayType, chartHeight, dataBackgroundColorRGBA, showQuantity = false } = options;

  const valueForAggregatedData = (dataObj) => {
    switch (chartDisplayType) {
      case LATEST_LOGS_CHART_TYPE.MINOR_AQL:
        return showQuantity ? dataObj.averageSampleMinorFailures : dataObj.sampleMinorAQL;
      case LATEST_LOGS_CHART_TYPE.MAJOR_AQL:
        return showQuantity ? dataObj.averageSampleMajorFailures : dataObj.sampleMajorAQL;
      case LATEST_LOGS_CHART_TYPE.CRITICAL_AQL:
        return showQuantity ? dataObj.averageSampleCriticalFailures : dataObj.sampleCriticalAQL;
      default:
        return parseInt(dataObj.averageReliabilityPoint, 10);
    }
  };

  const dataValues = [valueForAggregatedData(data)];
  const labels = [t('ALL')];
  const gradientBackgroundColor = generateDataGradientBackground(
    replaceAlphaInRGBA(dataBackgroundColorRGBA, '0.6'),
    replaceAlphaInRGBA(dataBackgroundColorRGBA, '0.2'),
    chartHeight
  );
  const backgroundColors = [gradientBackgroundColor];

  if (highlightData) {
    const highlightBackgroundColor = replaceAlphaInRGBA(dataBackgroundColorRGBA, '1');
    backgroundColors.push(highlightBackgroundColor);
    dataValues.push(valueForAggregatedData(highlightData));
    labels.push(t('HIGHLIGHT'));
  }

  return formatBarChartData(dataValues, labels, backgroundColors);
}

export function formatChartDataForLatestLogs(inspectionLogs, t, options) {
  const {
    chartDisplayType,
    chartHeight,
    dataBackgroundColorRGBA,
    highlightCoworkerId,
    highlightProductId,
    showQuantity = false,
    theme,
  } = options;
  const highlightingCoworker = typeof highlightCoworkerId !== 'undefined' && highlightCoworkerId !== null;
  const highlightingProduct = typeof highlightProductId !== 'undefined' && highlightProductId !== null;

  const dataForInspectionLog = (inspectionLog, index, options = {}) => {
    const { productId, userId } = options;

    let y;
    if ((productId && inspectionLog.productId !== productId) || (userId && inspectionLog.createdBy !== userId)) {
      y = null;
    } else {
      switch (chartDisplayType) {
        case LATEST_LOGS_CHART_TYPE.MINOR_AQL:
          y = showQuantity ? inspectionLog.sampleMinorFailures : inspectionLog.minorAQL;
          break;
        case LATEST_LOGS_CHART_TYPE.MAJOR_AQL:
          y = showQuantity ? inspectionLog.sampleMajorFailures : inspectionLog.majorAQL;
          break;
        case LATEST_LOGS_CHART_TYPE.CRITICAL_AQL:
          y = showQuantity ? inspectionLog.sampleCriticalFailures : inspectionLog.criticalAQL;
          break;
        default:
          y = parseInt(inspectionLog.reliabilityPoint, 10);
          break;
      }
    }

    return {
      x: index,
      y,
    };
  };

  let datasets = [];
  if (chartDisplayType !== LATEST_LOGS_CHART_TYPE.POINTS && showQuantity === false) {
    // Add empty dataset for AQL Limit so it shows in the legend
    datasets.push(
      formatLineChartDataset(
        [],
        <>
          {t('LIMIT')} <sup>1</sup>
        </>,
        {
          color: theme.colors.text,
          dashed: true,
          hideTooltip: true,
        }
      )
    );
  }

  datasets.push(
    formatAreaChartDataset(
      inspectionLogs.map((log, index) => dataForInspectionLog(log, index)),
      t('ALL'),
      {
        chartHeight,
        dataBackgroundColorRGBA,
        hideTooltip: highlightingProduct || highlightingCoworker,
      }
    )
  );

  if (highlightingProduct || highlightingCoworker) {
    const highlightBackgroundColor = replaceAlphaInRGBA(dataBackgroundColorRGBA, '1');
    let highlightLabel;
    if (highlightingProduct && highlightingCoworker) {
      highlightLabel = t('SELECTION');
    } else if (highlightingCoworker) {
      highlightLabel = t('COWORKER');
    } else {
      highlightLabel = t('PRODUCT');
    }
    datasets.push(
      formatLineChartDataset(
        inspectionLogs.map((log, index) =>
          dataForInspectionLog(log, index, { productId: highlightProductId, userId: highlightCoworkerId })
        ),
        highlightLabel,
        {
          color: highlightBackgroundColor,
        }
      )
    );
  }

  // Chart data
  const chartData = { datasets };

  return chartData;
}
