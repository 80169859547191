import PropTypes from 'prop-types';
import { forwardRef, useRef } from 'react';

import styles from './styles.module.scss';

const BaseIcon = forwardRef((props, forwardedRef) => {
  const {
    children,
    className,
    flip,
    height = 16,
    inheritColor = true,
    inline = false,
    size,
    spin = false,
    viewBox = '0 0 16 16',
    width = 16,
    ...otherProps
  } = props;
  const innerRef = useRef();
  const ref = forwardedRef || innerRef;

  /* Render */

  // Size
  let _height = height;
  let _width = width;
  if (size) {
    switch (size) {
      case '2x':
        _height = height * 2;
        _width = width * 2;
        break;
      case '1.5x':
        _height = height * 1.5;
        _width = width * 1.5;
        break;
      default:
        break;
    }
  }

  // Classes
  let classNames = styles.icon;
  if (inheritColor) {
    classNames = `${classNames} ${styles.inheritColor}`;
  }
  if (flip) {
    classNames = `${classNames} ${styles.flip}`;
  }
  if (inline) {
    classNames = `${classNames} ${styles.inline}`;
  }
  if (spin) {
    classNames = `${classNames} ${styles.spin}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <svg
      className={classNames}
      height={_height}
      ref={ref}
      viewBox={viewBox}
      width={_width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...otherProps}
    >
      {children}
    </svg>
  );
});

BaseIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  flip: PropTypes.bool,
  height: PropTypes.number,
  inheritColor: PropTypes.bool,
  inline: PropTypes.bool,
  size: PropTypes.oneOf(['1x', '1.5x', '2x']),
  spin: PropTypes.bool,
  viewBox: PropTypes.string,
  width: PropTypes.number,
};

export default BaseIcon;
