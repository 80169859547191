import { gql } from '@apollo/client';

export default class BaseInspectionLogNoteAssetModel {
  static GRAPHQL_TYPE = 'InspectionLogNoteAsset';

  static fragmentName = 'BaseInspectionLogNoteAsset_Fragment';
  static fragment = gql`
    fragment ${BaseInspectionLogNoteAssetModel.fragmentName} on ${BaseInspectionLogNoteAssetModel.GRAPHQL_TYPE} {
      createdBy
      createdDate
      id
      inspectionLogAssetSource
      inspectionOrganisationId
      lastModifiedBy
      lastModifiedDate
      order
      organisationId
      taggedInspectionLogTestAssetId
      version
    }
  `;
}
