import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import styles from './styles.module.scss';

const RE_DIGIT = new RegExp(/^\d+$/);

const OTPInput = forwardRef((props, forwardedRef) => {
  const {
    value,
    valueLength,
    onChange,
    onComplete,
    className,
    disabled = false,
    error,
    hasError = false,
    readOnly = false,
  } = props;
  const _hasError = hasError || !!error;
  const innerRef = useRef();
  const ref = forwardedRef || innerRef;

  // State

  const [isFocused, setIsFocused] = useState(false);
  const [trial, setTrial] = useState(true);

  // Effects

  useEffect(() => {
    disabled && setIsFocused(null);
  }, [disabled]);

  useEffect(() => {
    if (value.split(' ')[0].length == 6 && trial == true) {
      setTrial(false);
      onComplete();
    }

    if (value.split(' ')[0].length !== 6) {
      setTrial(true);
    }
  }, [onComplete, trial, value]);

  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [value, valueLength]);

  // Event handlers

  const focusToNextInput = (target) => {
    const nextElementSibling = target.nextElementSibling;
    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const focusToPrevInput = (target) => {
    const previousElementSibling = target.previousElementSibling;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnChange = (e, idx) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputEl = target.nextElementSibling;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    }
  };

  const inputOnKeyDown = (e) => {
    const { key } = e;
    const target = e.target;

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || targetValue !== '') {
      return;
    }

    focusToPrevInput(target);
  };

  const inputOnFocus = (e, idx) => {
    const { target } = e;

    // keep focusing back until previous input
    // element has value
    const prevInputEl = target.previousElementSibling;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    setIsFocused(idx);

    target.setSelectionRange(0, target.value.length);
  };

  // Container classes
  let classNames = `${styles.container} ${className || ''} ${_hasError ? styles.error : ''}`;

  if (disabled) {
    classNames = `${classNames} ${styles.disabled}`;
  }

  return (
    <div className={styles.otpgroup}>
      {valueItems.map((digit, idx) => (
        <input
          key={idx}
          type='text'
          inputMode='numeric'
          autoComplete='one-time-code'
          disabled={disabled}
          pattern='\d{1}'
          maxLength={valueLength}
          className={`${classNames} ${isFocused == idx && styles.focused} ${digit != '' && styles.hasValue}`}
          value={digit}
          readOnly={readOnly}
          ref={ref}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={(e) => inputOnFocus(e, idx)}
        />
      ))}
    </div>
  );
});

export default OTPInput;

OTPInput.propTypes = {
  accessoryButton: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  valueLength: PropTypes.number.isRequired,
};
