import { NetworkStatus, useQuery } from '@apollo/client';
import NextImage from 'next/legacy/image';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import CardLayout from 'OK/components/layouts/card';
import ContentLayout from 'OK/components/layouts/content';
import Link from 'OK/components/link';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import PageTitle from 'OK/components/pageTitle';
import Separator from 'OK/components/separator';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import UserModel from 'OK/models/user';
import SetAvatarPopup from 'OK/modules/popups/avatarPicking';
import SetUserNamePopup from 'OK/modules/popups/organisationInfo/setName';
import SetUserContactInfoPopup from 'OK/modules/popups/organisationInfo/setUserContactInfo';
import ShareOKIDPopup from 'OK/modules/popups/shareOKID';
import EditUserPublicPageDocumentationSection from 'OK/modules/publicUserPage/documentationSection';
import UserLogHistorySection from 'OK/modules/publicUserPage/logHistory';
import UserMembershipsSection from 'OK/modules/publicUserPage/membershipsSection';
import { logout as logoutApi } from 'OK/networking/auth';
import { getUserByOKIDQuery } from 'OK/networking/users';
import ThemeContext from 'OK/util/context/theme';
import UIContext from 'OK/util/context/ui';
import { formatOkid, formatPoints, unformatOkid } from 'OK/util/formatting';
import share, { SHARE_ACTION, SHARE_TYPE } from 'OK/util/functions/share';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';
import usePermission, { PERMISSIONS } from 'OK/util/hooks/usePermission';

export default function UserPage(props) {
  /* Variables */

  const { OKIDasProp } = props;
  const { t } = useI18n();
  const router = useRouter();
  const OKID = router.query.okid || OKIDasProp;
  const theme = useContext(ThemeContext);
  const showInPopup = useSelector((state) => state.app.showInPopup);
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const [authenticated, , currentUser] = useAuthentication(() => false);
  const menuIsVisible = useSelector((state) => state.app.menuIsVisible);
  const hasSalesDashboardPermission = usePermission(PERMISSIONS.SALES_DASHBOARD, currentUser);

  // Essential data

  const skipQuery = !OKID;
  const getUserResult = useQuery(getUserByOKIDQuery, {
    context: {
      addActiveOrganisationIdHeader: false,
      addAuthorisationHeaders: true,
    },
    skip: skipQuery,
    variables: {
      OKID: OKID ? unformatOkid(OKID) : null,
    },
  });
  const user = getUserResult.data?.user;

  const isCurrentUser = currentUser && user && currentUser.id === user.id;

  // State

  const [renderShareToast, setRenderShareToast] = useState(false);
  const [shareToastMessage, setShareToastMessage] = useState(null);
  const [logoutError, setLogoutError] = useState(null);
  const [loggingOut, setLoggingOut] = useState(false);
  const [showAvatarPopup, setShowAvatarPopup] = useState(false);
  const [showSetUserNamePopup, setShowSetUserNamePopup] = useState(false);
  const [showSetUserContactInfoPopup, setShowSetUserContactInfoPopup] = useState(false);
  const [showShowOKIDPopup, setShowOKIDPopup] = useState(false);

  const showShareToast = useCallback((message) => {
    setShareToastMessage(message);
    setRenderShareToast(true);
  }, []);

  // Refs

  const basicsSectionRef = useRef();
  const historySectionRef = useRef();
  const documentationSectionRef = useRef();
  const membershipsSectionSectionRef = useRef();

  /* Methods */

  const logout = async () => {
    setLogoutError(false);
    setLoggingOut(true);
    try {
      await logoutApi();
    } catch (e) {
      okerror('Error logging out.', e);
      setLogoutError(t('ERROR_GENERIC'));
    } finally {
      setLoggingOut(false);
    }
  };

  const shareUser = useCallback(() => {
    share(window.location.href, SHARE_TYPE.URL, true)
      .then((result) => {
        if (result === SHARE_ACTION.CLIPBOARD) {
          showShareToast(t('COPIED_TO_CLIPBOARD'));
        }
      })
      .catch(() => {
        showShareToast(t('ERROR_GENERIC'));
      });
  }, [showShareToast, t]);

  /* Effects */

  // Reformat OKID if necessary
  useEffect(() => {
    if (!showInPopup && OKID) {
      const formattedOKID = formatOkid(OKID);
      if (formattedOKID !== OKID) {
        router.replace(UserModel.link(formattedOKID));
      }
    }
  }, [OKID, router, showInPopup]);

  // Hide share toast after 3 seconds
  useEffect(() => {
    if (renderShareToast) {
      setTimeout(() => {
        setRenderShareToast(false);
      }, 3000);
    }
  }, [renderShareToast]);

  // Reset share toast message after hidden
  useEffect(() => {
    if (!renderShareToast) {
      setTimeout(() => {
        setShareToastMessage(null);
      }, 500); // Wait for transition to finish
    }
  }, [renderShareToast]);

  // Redirect to 404 if no user found
  useEffect(() => {
    const apiCompleted =
      getUserResult.called &&
      !skipQuery &&
      (getUserResult.networkStatus === NetworkStatus.ready || getUserResult.networkStatus === NetworkStatus.error);
    if (apiCompleted && !user) {
      router.replace('/404');
    }
  }, [getUserResult.called, getUserResult.networkStatus, router, skipQuery, user]);

  const pageMenu = (
    <PageMenu
      showBackButton
      title={
        <div>
          <FadeInOutTransition in={renderShareToast}>
            <Toast className={styles.shareToast}>{shareToastMessage}</Toast>
          </FadeInOutTransition>
          <Button className={styles.submenuButton} icon={ICONS.SHARE.name} linkStyle onClick={shareUser}>
            {useDesktopLayout && t('SHARE')}
          </Button>
        </div>
      }
      titlePosition='right'
    />
  );

  return (
    <>
      <PageTitle>{OKID ? t('PAGE_TITLE_USER', { data: { okid: OKID } }) : t('COWORKER')}</PageTitle>

      {showInPopup ? pageMenu : <Menu>{pageMenu}</Menu>}
      <div className={!showInPopup && styles.container}>
        <ContentLayout pageContent className={`${styles.basicsContainer} ${styles.outerSection}`}></ContentLayout>

        <ContentLayout
          pageContent
          className={styles.basicsContainer}
          contentClassName={styles.basicsContainerInner}
          ref={basicsSectionRef}
        >
          <UIContext.Provider value='card'>
            <div style={{ position: 'relative' }}>
              {isCurrentUser && (
                <div className={styles.editButtonContainer}>
                  <Button
                    className={`${styles.nameEditButton}`}
                    icon={'/icons/admin_edit.svg'}
                    tint='secondary'
                    onClick={() => setShowAvatarPopup(true)}
                  />
                </div>
              )}
              <div className={styles.logoContainer}>
                <NextImage
                  className={styles.logo}
                  layout='fill'
                  objectFit='cover'
                  src={
                    user?.avatar
                      ? theme.name === 'dark'
                        ? user?.avatar?.sourceDarkURL
                        : user?.avatar?.sourceLightURL
                      : `/img/empty_media_${theme.name}.svg`
                  }
                />
              </div>
            </div>
            <CardLayout fixedWidth={false} className={styles.organisationDetails}>
              <div style={{ marginBottom: 10 }}>
                <div className={styles.nameField}>
                  <div className={styles.organisationName}>
                    <div className={styles.organisationNameText}>
                      <h3>{user?.name}</h3>
                    </div>
                  </div>
                  {isCurrentUser && (
                    <UIContext.Provider value='card'>
                      <Button
                        className={styles.nameEditButton}
                        icon={'/icons/admin_edit.svg'}
                        onClick={() => setShowSetUserNamePopup(true)}
                        tint='secondary'
                      />
                    </UIContext.Provider>
                  )}
                </div>
                <div className={styles.infoField}>
                  <Text className={styles.fieldDescription} bold>
                    {t('OKID')}
                  </Text>
                  {user?.OKID && (
                    <Text className={styles.textFieldButton}>
                      {formatOkid(user?.OKID)}
                      <Button
                        className={styles.qrButton}
                        icon='/icons/qr_scan.svg'
                        linkStyle
                        onClick={() => setShowOKIDPopup(true)}
                      >
                        {t('SHOW_QR')}
                      </Button>
                    </Text>
                  )}
                </div>

                <div className={styles.infoField}>
                  <Text className={styles.fieldDescription} bold>
                    {t('RELIABILITY')}&nbsp;&nbsp;
                    <Icon inline name={ICONS.RELIABILITY_GRADE.name} />
                  </Text>
                  <Text>{formatPoints(user?.reliabilityPointForPublishedLogs ?? 0)}</Text>
                </div>
              </div>
              {isCurrentUser && useMobileLayout && (
                <>
                  <Separator className={styles.desktopDetailSeparator} />
                </>
              )}
            </CardLayout>
            {isCurrentUser && (
              <div className={styles.organisationContactInfoContainer}>
                <CardLayout className={styles.contactInfoCard} fixedWidth={false}>
                  <div className={styles.nameField}>
                    <h4 className={styles.contactInfo}>{t('CONTACT_INFO')}</h4>
                    <UIContext.Provider value='card'>
                      <Button
                        className={styles.nameEditButton}
                        icon='/icons/admin_edit.svg'
                        tint='secondary'
                        onClick={() => setShowSetUserContactInfoPopup(true)}
                      ></Button>
                    </UIContext.Provider>
                  </div>
                  {user?.email && (
                    <>
                      <div className={styles.contactInfoItem}>
                        <Text bold className={styles.contactInfoLabel}>
                          {t('COMPANY_CONTACT_EMAIL')}
                        </Text>
                        <Link className={styles.contactInfoValue} href={`mailto:${user.email}`}>
                          {user.email}
                        </Link>
                      </div>
                    </>
                  )}
                  {user?.mobile && (
                    <>
                      <div className={styles.contactInfoItem}>
                        <Text bold className={styles.contactInfoLabel}>
                          {t('COMPANY_CONTACT_PHONE')}
                        </Text>
                        <Link className={styles.contactInfoValue} href={`tel:${user.mobileCountryCode}${user.mobile}`}>
                          {user.mobileCountryCode} {user.mobile}
                        </Link>
                      </div>
                    </>
                  )}
                  <Separator className={styles.contactInfoSeparator} />
                  {hasSalesDashboardPermission && (
                    <Link className={styles.salesDashboardLink} href='/account/sales' icon={ICONS.CARET.name}>
                      Sales dashboard
                    </Link>
                  )}
                  {authenticated && (
                    <>
                      <Button
                        className={styles.signOutButton}
                        linkStyle
                        block
                        tint='alert'
                        loading={loggingOut}
                        disabled={loggingOut}
                        onClick={logout}
                      >
                        {t('ACCOUNT_NAVIGATIONNOTICEBUTTON')}
                      </Button>
                      {logoutError && <p className={styles.errorMessage}>{logoutError}</p>}
                    </>
                  )}
                </CardLayout>
              </div>
            )}
          </UIContext.Provider>
        </ContentLayout>
      </div>
      {/* eslint-disable indent*/}
      <PageMenu
        className={`${styles.sectionsSubmenuContainer} ${
          menuIsVisible && !showInPopup && styles.sectionsSubmenuContainerMenu
        }`}
        tabClassName={styles.tabClassName}
        smartTabs
        sections={[
          { ref: historySectionRef, title: t('WORK'), icon: ICONS.COG.name },
          {
            ref: documentationSectionRef,
            title: t('PRODUCT_SECTION_DOCUMENTATION'),
            icon: ICONS.DOCUMENTATION.name,
          },
          {
            ref: membershipsSectionSectionRef,
            title: t('ACCOUNT_SECTION_MEMBERSHIPS'),
            icon: ICONS.PROFILE.name,
          },
        ]}
      />
      {/* eslint-enable indent*/}
      <ContentLayout pageContent ref={historySectionRef} className={styles.sectionCard}>
        <UserLogHistorySection flexHeader={true} user={user} />
      </ContentLayout>
      <ContentLayout pageContent ref={documentationSectionRef} className={styles.sectionCard}>
        <EditUserPublicPageDocumentationSection user={user} OKID={OKID} publicView />
      </ContentLayout>
      <ContentLayout pageContent ref={membershipsSectionSectionRef} className={styles.sectionCard}>
        <UserMembershipsSection user={user} />
      </ContentLayout>
      {showAvatarPopup && <SetAvatarPopup dismiss={() => setShowAvatarPopup(false)} />}
      {showSetUserNamePopup && <SetUserNamePopup dismiss={() => setShowSetUserNamePopup(false)} user={currentUser} />}
      {showSetUserContactInfoPopup && <SetUserContactInfoPopup dismiss={() => setShowSetUserContactInfoPopup(false)} />}
      {showShowOKIDPopup && (
        <ShareOKIDPopup
          dismiss={() => setShowOKIDPopup(false)}
          instructions={t('SHARE_OKID_INSTRUCTIONS')}
          name={t('COWORKER')}
          OKID={OKID}
          profilePhotoUrl={theme.name == 'dark' ? user?.avatar?.sourceDarkURL : user?.avatar?.sourceLightURL}
        />
      )}
    </>
  );
}

UserPage.layoutProps = {
  contentTopPadding: 'menuOnly',
  padFooter: false,
};

UserPage.propTypes = {
  OKIDasProp: PropTypes.string,
};
export async function getServerSideProps({ locale }) {
  const i18nProps = await serverSideTranslations(locale, ['common']);
  return { props: { ...i18nProps } };
}
