import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import NextImage from "next/legacy/image";
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './styles.module.scss';

import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import MediaPicker from 'OK/components/mediaPicker';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import Select from 'OK/components/select';
import Tag from 'OK/components/tag';
import InspectionTestSelect from 'OK/components/test/select';
import InspectionTestTagSelect from 'OK/components/test/tagSelect';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import InspectionLogFindingModel from 'OK/models/inspectionLogFinding';
import MediaAssetModel from 'OK/models/mediaAsset';
import MediaEditorPopup from 'OK/modules/popups/mediaEditor';
import { MediaEditorToolDelete, MediaEditorToolInspectionSort } from 'OK/modules/popups/mediaEditor/tools';
import {
  deleteInspectionLogFindingsMutation,
  setTestAssetForInspectionLogFindingsMutation,
  tagAndSetTestAssetForInspectionLogFindingsMutation,
  tagInspectionLogFindingsMutation,
  unsetTestAssetForInspectionLogFindingsMutation,
  untagInspectionLogFindingsMutation,
} from 'OK/networking/inspectionLogFindings';
import { InspectionLogMediaGalleryPopupQuery } from 'OK/networking/inspectionLogs';
import { baseTheme } from 'OK/styles/theme';
import useI18n from 'OK/util/hooks/useI18n';

const MODE_VIEW = 'VIEW';
const MODE_SORT = 'SORT';
const MODE_DELETE = 'DELETE';

export default function InspectionMediaGalleryPopup(props) {
  /* Variables */

  const {
    dismiss,
    enableEditing = false,
    inspectionLogREFID,
    limitToInspectionLogTestAssetId,
    onMediaPicked: onMediaPickedProp,
    uploading = false,
  } = props;
  const { t, tHTML } = useI18n();
  const getInspectionLogAPIResult = useQuery(InspectionLogMediaGalleryPopupQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { REFID: inspectionLogREFID },
  });
  const loadingInspectionLog = getInspectionLogAPIResult.loading;
  const inspectionLog = getInspectionLogAPIResult.data?.inspectionLog;

  const apolloClient = useApolloClient();

  // State

  const [committingChanges, setCommittingChanges] = useState(false);
  const [mode, setMode] = useState(MODE_VIEW);
  const [move, setMove] = useState('');
  const [popupError, _setPopupError] = useState(null);
  const [selectedFindingId, setSelectedFindingId] = useState(null);
  const [_selectedGroup, setSelectedGroup] = useState(limitToInspectionLogTestAssetId ?? 'unsorted');
  const selectedGroup = limitToInspectionLogTestAssetId ?? _selectedGroup;
  const [showConfirmDeleteAlert, setShowConfirmDeleteAlert] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);
  const [tag, setTag] = useState('');

  // Refs

  const mediaPickerRef = useRef();

  const visibleFindings = useMemo(() => {
    if (selectedGroup !== 'unsorted') {
      const inspectionLogTestAsset = inspectionLog?.inspectionLogTestAssetList.find((t) => t.id === selectedGroup);
      return inspectionLogTestAsset?.inspectionLogFindingList ?? [];
    }

    return inspectionLog?.inspectionLogFindingList?.filter((f) => f.inspectionLogTestAssetId === null) ?? [];
  }, [inspectionLog?.inspectionLogFindingList, inspectionLog?.inspectionLogTestAssetList, selectedGroup]);
  const selectedFindings = visibleFindings.filter((f) => f.selected === true);

  /* API */

  const [deleteFindingsAPI, deleteFindingsAPIResult] = useMutation(deleteInspectionLogFindingsMutation);
  const [setTestAssetForFindingsAPI, setTestAssetForFindingsAPIResult] = useMutation(
    setTestAssetForInspectionLogFindingsMutation
  );
  const [tagFindingsAPI, tagFindingsAPIResult] = useMutation(tagInspectionLogFindingsMutation);
  const [tagAndSetTestAssetForFindingsAPI, tagAndSetTestAssetForFindingsAPIResult] = useMutation(
    tagAndSetTestAssetForInspectionLogFindingsMutation
  );
  const [unsetTestAssetForFindingsAPI, unsetTestAssetForFindingsAPIResult] = useMutation(
    unsetTestAssetForInspectionLogFindingsMutation
  );
  const [untagFindingsAPI, untagFindingsAPIResult] = useMutation(untagInspectionLogFindingsMutation);

  /* Methods */

  const commitMediaChanges = (updatedFindingMedias) => {
    setCommittingChanges(true);

    const requestPromises = [];

    // Handle updates
    updatedFindingMedias.forEach((updatedFindingMedia) => {
      const currentFinding = visibleFindings.find((f) => f.id === updatedFindingMedia.id);
      const currentTag = currentFinding.tagList.length ? currentFinding.tagList[0] : null;
      const updatedTag = updatedFindingMedia.tag;
      const currentTestAssetId = currentFinding.inspectionLogTestAssetId;
      const updatedTestAssetId = updatedFindingMedia.testAssetId;
      const didUpdateTag = updatedTag !== currentTag;
      const didUpdateTestAssetId = updatedTestAssetId !== currentTestAssetId;

      let request;
      if (didUpdateTag && didUpdateTestAssetId) {
        okdebug('update tag and test to', updatedTag, updatedTestAssetId);
        request = tagAndSetTestAssetForFindingsAPI({
          variables: {
            inspectionLogFindingIdList: [currentFinding.id],
            tag: updatedTag || null,
            testAssetId: updatedTestAssetId || null,
          },
        });
      } else if (didUpdateTag) {
        okdebug('update tag to', updatedTag);
        if (updatedTag) {
          request = tagFindingsAPI({
            variables: {
              inspectionLogFindingIdList: [currentFinding.id],
              tag: updatedTag,
            },
          });
        } else {
          request = untagFindingsAPI({
            variables: {
              inspectionLogFindingIdList: [currentFinding.id],
            },
          });
        }
      } else if (didUpdateTestAssetId) {
        okdebug('update test to', updatedTestAssetId);
        if (updatedTestAssetId) {
          request = setTestAssetForFindingsAPI({
            variables: {
              inspectionLogFindingIdList: [currentFinding.id],
              testAssetId: updatedTestAssetId,
            },
          });
        } else {
          request = unsetTestAssetForFindingsAPI({
            variables: {
              inspectionLogFindingIdList: [currentFinding.id],
            },
          });
        }
      }

      if (request) {
        requestPromises.push(request);
      }
    });

    // Handle deletions
    const deletedFindings = visibleFindings.filter(
      (vf) => updatedFindingMedias.findIndex((uf) => uf.id === vf.id) === -1
    );
    if (deletedFindings.length) {
      okdebug('deleting findings', deletedFindings);
      const deletedFindingIds = deletedFindings.map((f) => f.id);
      requestPromises.push(
        deleteFindingsAPI({
          variables: {
            inspectionLogFindingIdList: deletedFindingIds,
          },
        })
      );
    }

    if (requestPromises.length) {
      // Wait for each API to finish, then go back to gallery view
      Promise.all(requestPromises)
        .then((responses) => {
          okdebug('APIs finished', responses);
          return apolloClient
            .refetchQueries({
              include: ['GetInspectionLogForGoPage'], // Re-fetch the whole inspection log because manually moving each finding is complex
            })
            .then(() => {
              setSelectedFindingId(null);
            });
        })
        .catch((e) => {
          okerror('Error updating findings.', e);
          setPopupError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setCommittingChanges(false);
        });
    } else {
      setSelectedFindingId(null);
      setCommittingChanges(false);
    }
  };

  const confirmDeleteSelectedFindings = () => {
    setShowConfirmDeleteAlert(true);
  };

  const deleteSelectedFindings = () => {
    setShowConfirmDeleteAlert(false);

    const inspectionLogFindingIdList = selectedFindings.map((f) => f.id);

    deleteFindingsAPI({
      variables: {
        inspectionLogFindingIdList,
      },
    })
      .then(() =>
        getInspectionLogAPIResult.refetch().then(() => {
          resetMode();
        })
      )
      .catch((e) => {
        okerror('Error deleting findings.', e);
        setPopupError(t('ERROR_GENERIC'));
      });
  };

  const openMediaPicker = useCallback(() => {
    mediaPickerRef.current.open();
  }, []);

  const setPopupError = (error) => {
    _setPopupError(error);

    if (error) {
      setShowPopupError(true);
    }
  };

  const sortSelectedFindings = () => {
    const inspectionLogFindingIdList = selectedFindings.map((f) => f.id);
    let request;
    if (move && tag) {
      request = tagAndSetTestAssetForFindingsAPI({
        variables: {
          inspectionLogFindingIdList,
          tag: tag === 'untagged' ? null : tag,
          testAssetId: move === 'unsorted' ? null : move,
        },
      });
    } else if (move) {
      if (move !== 'unsorted') {
        request = setTestAssetForFindingsAPI({
          variables: {
            inspectionLogFindingIdList,
            testAssetId: move,
          },
        });
      } else {
        request = unsetTestAssetForFindingsAPI({
          variables: {
            inspectionLogFindingIdList,
          },
        });
      }
    } else if (tag) {
      if (tag !== 'untagged') {
        request = tagFindingsAPI({
          variables: {
            inspectionLogFindingIdList,
            tag,
          },
        });
      } else {
        request = untagFindingsAPI({
          variables: {
            inspectionLogFindingIdList,
          },
        });
      }
    }

    if (request) {
      request
        .then(() =>
          apolloClient
            .refetchQueries({
              include: ['GetInspectionLogForGoPage'], // Re-fetch the whole inspection log because manually moving each finding is complex
            })
            .then(() => {
              resetMode();
            })
        )
        .catch((e) => {
          okerror('Error sorting findings.', e);
          setPopupError(t('ERROR_GENERIC'));
        });
    }
  };

  const resetMode = () => {
    toggleVisibleMediaSelected(false);
    setMode(MODE_VIEW);
    setMove('');
    setTag('');
    setShowConfirmDeleteAlert(false);
  };

  const setSelectedOnFinding = (finding, selected) => {
    apolloClient.writeFragment({
      id: `${InspectionLogFindingModel.GRAPHQL_TYPE}:${finding.id}`,
      fragment: gql`
        fragment Selected on ${InspectionLogFindingModel.GRAPHQL_TYPE} {
          selected
        }
      `,
      data: {
        selected,
      },
    });
  };

  const toggleVisibleMediaSelected = (selected) => {
    visibleFindings.forEach((finding) => setSelectedOnFinding(finding, selected));
  };

  // Event handlers

  const onClickMedia = (findingId) => {
    const indexOfFinding = visibleFindings.findIndex((m) => m.id === findingId);
    if (indexOfFinding > -1) {
      if (mode === MODE_SORT || mode === MODE_DELETE) {
        const finding = visibleFindings[indexOfFinding];
        setSelectedOnFinding(finding, !finding.selected);
      } else {
        setSelectedFindingId(findingId);
      }
    }
  };

  const onMediaPicked = useCallback(
    (files) => {
      onMediaPickedProp && onMediaPickedProp(files, selectedGroup);
    },
    [onMediaPickedProp, selectedGroup]
  );

  /* Effects */

  // Hide popup error after 5 seconds
  useEffect(() => {
    if (showPopupError) {
      setTimeout(() => {
        setShowPopupError(false);
      }, 5000);
    }
  }, [showPopupError]);

  // Reset popup error when hidden
  useEffect(() => {
    if (!showPopupError) {
      setTimeout(() => {
        setPopupError(null);
      }, baseTheme.timing.timingShortMs);
    }
  }, [showPopupError]);

  /* Render */

  const allVisibleFindingsSelected = selectedFindings.length === visibleFindings.length;
  const sortLoading =
    setTestAssetForFindingsAPIResult.loading ||
    tagFindingsAPIResult.loading ||
    tagAndSetTestAssetForFindingsAPIResult.loading ||
    unsetTestAssetForFindingsAPIResult.loading ||
    untagFindingsAPIResult.loading;
  const testOptions =
    [
      { label: t('LOG_MEDIA_UNSORTED'), value: 'unsorted' },
      ...(inspectionLog?.inspectionLogTestAssetList ?? []).map((test, index) => ({
        label: t('NOTE_CARD_STEP_SEARCH_RESULT', { data: { number: index + 1 } }),
        value: test.id,
      })),
    ] ?? [];

  // Media editor popup
  if (selectedFindingId !== null) {
    const sortTool = {
      ...MediaEditorToolInspectionSort,
      props: {
        testOptions: testOptions.filter((t) => t.value !== selectedGroup),
      },
    };
    return (
      <MediaEditorPopup
        committingChanges={committingChanges || loadingInspectionLog}
        dismiss={() => setSelectedFindingId(null)}
        enableEditing={enableEditing}
        media={visibleFindings.map((f) => {
          return {
            ...f.unversionedMediaAsset,
            id: f.id,
            tag: f.tagList.length ? f.tagList[0] : null,
            testAssetId: selectedGroup === 'unsorted' ? null : selectedGroup,
          };
        })}
        mediaPreviewAccessoryViewRender={(media) => {
          if (!media) {
            return null;
          }

          let tagTint;
          switch (media.tag) {
            case InspectionLogFindingModel.TAG.PASSED:
              tagTint = 'creation';
              break;
            case InspectionLogFindingModel.TAG.FAILED:
              tagTint = 'alert';
              break;
            case InspectionLogFindingModel.TAG.FIXED:
              tagTint = 'navigation';
              break;
            case InspectionLogFindingModel.TAG.TOLERATED:
              tagTint = 'notification';
              break;
            default:
              break;
          }

          if (media.tag) {
            return (
              <div className={styles.mediaPreviewAccessoryContainer}>
                <Tag className={styles.mediaPreviewTag} size='sm' tint={tagTint}>
                  {t(media.tag)}
                </Tag>
              </div>
            );
          }

          return null;
        }}
        onCommitChanges={commitMediaChanges}
        preselectMediaIndex={visibleFindings.findIndex((f) => f.id === selectedFindingId)}
        thumbnailAccessoryViewRender={(thumbnailMedia) => {
          let tagTint;
          switch (thumbnailMedia.tag) {
            case InspectionLogFindingModel.TAG.PASSED:
              tagTint = 'creation';
              break;
            case InspectionLogFindingModel.TAG.FAILED:
              tagTint = 'alert';
              break;
            case InspectionLogFindingModel.TAG.FIXED:
              tagTint = 'navigation';
              break;
            case InspectionLogFindingModel.TAG.TOLERATED:
              tagTint = 'notification';
              break;
            default:
              break;
          }
          return (
            <div>
              <Text bold className={styles.thumbnailTestNumber} size='xs'>
                {thumbnailMedia.testAssetId
                  ? (inspectionLog?.inspectionLogTestAssetList.findIndex((t) => t.id === thumbnailMedia.testAssetId) ??
                      0) + 1
                  : '—'}
              </Text>
              {tagTint && (
                <Icon
                  className={styles.thumbnailTagIndicator}
                  height={8}
                  name={ICONS.DOT.name}
                  tint={tagTint}
                  width={8}
                />
              )}
            </div>
          );
        }}
        tools={enableEditing ? [sortTool, MediaEditorToolDelete] : []}
      />
    );
  }

  let galleryClassNames = styles.gallery;

  // Modes
  let menuButtons;
  let floatingButtons;
  switch (mode) {
    case MODE_SORT:
      floatingButtons = (
        <>
          <InspectionTestSelect
            className={styles.floatingSelect}
            onChange={setMove}
            options={testOptions.filter((t) => t.value !== selectedGroup)}
            value={move}
          />
          <InspectionTestTagSelect className={styles.floatingSelect} onChange={setTag} value={tag} />
          <Button
            disabled={sortLoading || loadingInspectionLog}
            icon={ICONS.MOVE.name}
            loading={sortLoading || loadingInspectionLog}
            onClick={sortSelectedFindings}
            tint='navigation'
          />
        </>
      );
      galleryClassNames = `${galleryClassNames} ${styles.sortMode}`;
      menuButtons = (
        <>
          <Text>{t('LOG_MEDIA_X_SELECTED', { data: { number: selectedFindings.length } })}</Text>
          <Button
            className={styles.selectAllButton}
            linkStyle
            onClick={() => toggleVisibleMediaSelected(!allVisibleFindingsSelected)}
          >
            {allVisibleFindingsSelected ? t('DESELECT_ALL') : t('SELECT_ALL')}
          </Button>
          <Button linkStyle onClick={resetMode}>
            {t('CANCEL')}
          </Button>
        </>
      );
      break;
    case MODE_DELETE:
      floatingButtons = (
        <Button
          className={styles.deleteFloatingButton}
          disabled={deleteFindingsAPIResult.loading || loadingInspectionLog}
          icon={ICONS.TRASH.name}
          loading={deleteFindingsAPIResult.loading || loadingInspectionLog}
          onClick={confirmDeleteSelectedFindings}
          tint='alert'
        />
      );
      menuButtons = (
        <>
          <Text>{t('LOG_MEDIA_X_SELECTED', { data: { number: selectedFindings.length } })}</Text>
          <Button
            className={styles.selectAllButton}
            linkStyle
            onClick={() => toggleVisibleMediaSelected(!allVisibleFindingsSelected)}
          >
            {allVisibleFindingsSelected ? t('DESELECT_ALL') : t('SELECT_ALL')}
          </Button>
          <Button linkStyle onClick={resetMode}>
            {t('CANCEL')}
          </Button>
        </>
      );
      galleryClassNames = `${galleryClassNames} ${styles.deleteMode}`;
      break;
    default:
      floatingButtons = (
        <>
          {enableEditing && (
            <>
              <MediaPicker
                invisible
                mediaTypes={['photo', 'video', 'audio']}
                multiple
                onChange={onMediaPicked}
                promptToEditAfterPick={false}
                ref={mediaPickerRef}
              />
              <Button
                disabled={uploading || loadingInspectionLog}
                icon={ICONS.ADD_MEDIA.name}
                loading={uploading || loadingInspectionLog}
                onClick={openMediaPicker}
                tint='creation'
              />
            </>
          )}
          {!limitToInspectionLogTestAssetId && (
            <Select
              className={styles.floatingSelect}
              disableEmptySelection
              onChange={(newSelectedGroup) => setSelectedGroup(newSelectedGroup)}
              options={testOptions}
              value={selectedGroup}
            />
          )}
        </>
      );
      galleryClassNames = `${galleryClassNames} ${styles.viewMode}`;
      menuButtons = (
        <>
          <PopupCloseButton
            className={styles.closeButton}
            linkStyle
            icon={ICONS.X.name}
            iconPosition='left'
            tint='secondary'
          >
            {/* eslint-disable indent */}
            {selectedGroup === 'unsorted'
              ? t('LOG_MEDIA_UNSORTED')
              : t('NOTE_CARD_STEP_SEARCH_RESULT', {
                  data: {
                    number:
                      (inspectionLog?.inspectionLogTestAssetList.findIndex((t) => t.id === selectedGroup) ?? 0) + 1,
                  },
                })}
            {/* eslint-enable indent */}
          </PopupCloseButton>
          {enableEditing && (
            <>
              <Button icon={ICONS.SORT.name} linkStyle onClick={() => setMode(MODE_SORT)}>
                {t('SORT')}
              </Button>
              <Button
                className={styles.deleteButton}
                icon={ICONS.TRASH.name}
                linkStyle
                onClick={() => setMode(MODE_DELETE)}
                tint='alert'
              >
                {t('DELETE')}
              </Button>
            </>
          )}
        </>
      );
      break;
  }

  return (
    <>
      <Popup dismiss={dismiss}>
        <PopupContent className={styles.popupContent}>
          <div className={styles.buttonMenu}>{menuButtons}</div>
          <div className={galleryClassNames}>
            {visibleFindings.length ? (
              visibleFindings.map((f) => {
                const mediaClassNames = `${styles.media} ${f.selected === true ? styles.selected : ''}`;
                const mediaAsset = f.unversionedMediaAsset;

                let mediaPreview;
                switch (mediaAsset.mediaType) {
                  case MediaAssetModel.MEDIA_TYPE.AUDIO:
                    mediaPreview = (
                      <div className={styles.mediaGalleryPreview}>
                        <Icon className={styles.mediaGalleryPreviewIcon} name={ICONS.AUDIO.name} />
                      </div>
                    );
                    break;
                  case MediaAssetModel.MEDIA_TYPE.IMAGE:
                    mediaPreview = <NextImage layout='fill' objectFit='cover' src={mediaAsset.imageData.imageURL} />;
                    break;
                  case MediaAssetModel.MEDIA_TYPE.VIDEO:
                    mediaPreview = (
                      <div className={styles.mediaGalleryPreview}>
                        <Icon className={styles.mediaGalleryPreviewIcon} name={ICONS.PLAY.name} />
                      </div>
                    );
                    break;
                  default:
                    break;
                }

                let tag, tagIconTint;
                if (f.tagList.length) {
                  tag = f.tagList[0];
                  switch (tag) {
                    case InspectionLogFindingModel.TAG.PASSED:
                      tagIconTint = 'creation';
                      break;
                    case InspectionLogFindingModel.TAG.FAILED:
                      tagIconTint = 'alert';
                      break;
                    case InspectionLogFindingModel.TAG.FIXED:
                      tagIconTint = 'navigation';
                      break;
                    case InspectionLogFindingModel.TAG.TOLERATED:
                      tagIconTint = 'notification';
                      break;
                    default:
                      break;
                  }
                }

                return (
                  <div className={mediaClassNames} key={f.id} onClick={() => onClickMedia(f.id)}>
                    {mediaPreview}
                    {tag && (
                      <div className={styles.tagIcon}>
                        <Icon className={styles.dot} height={8} name={ICONS.DOT.name} tint={tagIconTint} width={8} />
                      </div>
                    )}
                  </div>
                );
              })
            ) : loadingInspectionLog ? (
              <Icon className={styles.loadingSpinner} height={40} name={ICONS.SPINNER.name} width={40} />
            ) : (
              <Text className={styles.noFindingsLabel}>{t('STEP_LOGGING_CARD_NO_MEDIA')}</Text>
            )}
          </div>
          <FadeInOutTransition className={styles.toastContainer} in={showPopupError}>
            <Toast tint='alert'>
              <p>{popupError}</p>
            </Toast>
          </FadeInOutTransition>
          <PopupButtonsGroup>
            <div className={styles.floatingButtonsContainer}>{floatingButtons}</div>
          </PopupButtonsGroup>
        </PopupContent>
      </Popup>
      {showConfirmDeleteAlert && (
        <Alert
          buttons={
            <>
              <Button block className={styles.discardChangesButton} onClick={deleteSelectedFindings} tint='alert'>
                {t('LOG_MEDIA_DELETE_ALERT_CONFIRM')}
              </Button>
              <Button block linkStyle onClick={resetMode}>
                {t('LOG_MEDIA_DELETE_ALERT_CANCEL')}
              </Button>
            </>
          }
          message={tHTML('LOG_MEDIA_DELETE_ALERT_MESSAGE')}
          title={t('LOG_MEDIA_DELETE_ALERT_TITLE')}
        />
      )}
    </>
  );
}

InspectionMediaGalleryPopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
  enableEditing: PropTypes.bool,
  inspectionLogREFID: PropTypes.string.isRequired,
  limitToInspectionLogTestAssetId: PropTypes.string,
  onMediaPicked: PropTypes.func,
  uploading: PropTypes.bool,
};
