import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState, useMemo } from 'react';

import styles from './styles.module.scss';

import FormFieldI18n from 'OK/components/form/formFieldI18n';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import Notice from 'OK/components/notice';
import { Popup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import Text from 'OK/components/text';
import Toggle from 'OK/components/toggle';
import ProductModel from 'OK/models/product';
import {
  removeProductNameForLanguageMutation,
  setProductNameForLanguageMutation,
  setProductSKUMutation,
} from 'OK/networking/products';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import useI18n from 'OK/util/hooks/useI18n';

export default function ProductInfoEditPopup(props) {
  const { dismiss, product, SKUID } = props;

  const { t, tHTML } = useI18n();
  const published = product?.publishStatus === PUBLISH_STATUS.PUBLISHED;

  const [nameError, setNameError] = useState(null);
  const [newSKU, setNewSKU] = useState('');
  const [publishError, setPublishError] = useState(null);
  const [skuError, setSKUError] = useState(null);

  const [removeProductNameAPI] = useMutation(removeProductNameForLanguageMutation);
  const [setProductNameAPI] = useMutation(setProductNameForLanguageMutation);
  const [setProductSKUAPI, setProductSKUAPIResult] = useMutation(setProductSKUMutation);

  const onBlurName = useCallback(
    (blurredLanguageIso, values) => {
      setNameError(null);
      const name = values.find((v) => v.languageIso === blurredLanguageIso)?.value;
      if (name !== product.name[blurredLanguageIso]?.text) {
        const optimisticResponse = {
          product: {
            id: product.id,
            name: {
              ...product.name,
              [blurredLanguageIso]: {
                ...product.name[blurredLanguageIso],
                text: name,
              },
            },
            __typename: ProductModel.GRAPHQL_TYPE,
          },
        };
        setProductNameAPI({
          variables: {
            productId: product.id,
            languageIso: blurredLanguageIso,
            name,
          },
          optimisticResponse,
        }).catch(() => {
          setNameError(t('ERROR_GENERIC'));
        });
      }
    },
    [product?.id, product?.name, setProductNameAPI, t]
  );

  const onChangeName = useCallback(
    (changedLanguageIso, values) => {
      setNameError(null);
      if (values.findIndex((v) => v.languageIso === changedLanguageIso) === -1) {
        const updatedProductNameObject = { ...product.name };
        delete updatedProductNameObject[changedLanguageIso];
        const optimisticResponse = {
          product: {
            id: product.id,
            name: {
              ...updatedProductNameObject,
            },
            __typename: ProductModel.GRAPHQL_TYPE,
          },
        };
        removeProductNameAPI({
          variables: {
            productId: product.id,
            languageIso: changedLanguageIso,
          },
          optimisticResponse,
        }).catch(() => {
          setNameError(t('ERROR_GENERIC'));
        });
      }
    },
    [product?.id, product?.name, removeProductNameAPI, t]
  );

  const onChangeSKU = useCallback((e) => {
    setNewSKU(e.target.value);
  }, []);

  const saveSKU = useCallback(() => {
    if (newSKU.length && newSKU !== SKUID) {
      setSKUError(null);
      setProductSKUAPI({
        variables: {
          productId: product.id,
          SKUID: newSKU,
        },
      }).catch(() => {
        setSKUError(t('ERROR_GENERIC'));
      });
    } else if (!newSKU.length) {
      setNewSKU(SKUID);
      setSKUError(t('PRODUCT_SKU_EMPTY_ERROR'));
    }
  }, [SKUID, newSKU, product.id, setProductSKUAPI, t]);

  const productName = useMemo(() => {
    if (product?.name) {
      return Object.keys(product.name).map((languageIso) => {
        return { languageIso, value: product.name[languageIso].text };
      });
    }

    return [];
  }, [product?.name]);

  // Keep newSKU in sync with saved SKUID
  useEffect(() => {
    setNewSKU(SKUID);
  }, [SKUID]);

  return (
    <Popup dismiss={dismiss}>
      <PopupContent>
        <div className={styles.header}>
          <h3 className={styles.textAvatar}>Edit details</h3>
          <div>
            <PopupCloseButton className={styles.buttonSave} linkStyle tint='navigation'>
              Done
            </PopupCloseButton>
          </div>
        </div>
        <h4>
          {t('PRODUCT_FIELD_NAME')} <span className={styles.normalWeight}>{t('OPTIONAL_FIELD_LABEL')}</span>
        </h4>
        <div className={styles.field}>
          <FormFieldI18n
            inputPlaceholder={t('PRODUCT_FIELD_NAME_PLACEHOLDER', { data: { language: '{{language}}' } })}
            onBlur={onBlurName}
            onChange={onChangeName}
            showWarningIfExceedsLength={35}
            values={productName}
          />
          {nameError && (
            <Text className={styles.nameError} size='sm' tint='notification'>
              {nameError}
            </Text>
          )}
        </div>
        <label className={`${styles.label} ${styles.identifier}`}>
          <h4>{t('SITE_FIELD_INTERNAL_ID_LABEL')}</h4>
          <Input
            className={`${styles.input} ${styles.monoInput}`}
            disabled={setProductSKUAPIResult.loading}
            onBlur={saveSKU}
            onChange={onChangeSKU}
            placeholder={t('PRODUCT_FIELD_SKU_PLACEHOLDER')}
            showClearButton={newSKU.length > 0 && !setProductSKUAPIResult.loading}
            type='text'
            value={newSKU}
            warning={setProductSKUAPIResult.error ? t('UPDATE_FAILED_CONNECTION') : null}
            withIcon={setProductSKUAPIResult.loading ? <Icon name={ICONS.SPINNER.name} /> : null}
          />
          {skuError ? (
            <Text className={styles.skuErrorMessage} size='sm' tint='notification'>
              {skuError}
            </Text>
          ) : null}
          <p className={styles.inputHelpText} style={{ marginBottom: 0 }}>
            {t('PRODUCT_FIELD_SKU_GUIDANCE')}
          </p>
        </label>
      </PopupContent>
    </Popup>
  );
}

ProductInfoEditPopup.propTypes = {
  dismiss: PropTypes.func,
  product: PropTypes.object.isRequired,
  SKUID: PropTypes.string.isRequired,
};
