import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client';
import NextImage from 'next/legacy/image';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import ItemArchiveCard from 'OK/components/item/archiveCard';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import PageTitle from 'OK/components/pageTitle';
import ProductArchiveCard from 'OK/components/product/archiveCard';
import Progressable from 'OK/components/progressable';
import { Tab, Tabs, TabsContextProvider } from 'OK/components/tabs';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import LabelTemplateModel, { LabelTemplateName } from 'OK/models/labelTemplate';
import AddWorkPopup from 'OK/modules/popups/addWork';
import LabelMakerPopup, { LABEL_MAKER_MODE } from 'OK/modules/popups/labelMaker';
import ShareAssetPopup from 'OK/modules/popups/share';
import { getLabelItemsQuery, LabelArchiveQuery } from 'OK/networking/labels';
import { downloadPDFForLabel } from 'OK/networking/pdfs';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import isAuthorised from 'OK/util/functions/isAuthorised';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';

const DELETE_CONFIRMATION_STRING = 'YES OK';
const LINKS_SECTION = {
  ITEMS: 'ITEMS',
  PRODUCT: 'PRODUCT',
};

export default function ArchiveLabelPage(props) {
  /* Essential data */

  const [getLabelAPI, getLabelAPIResult] = useLazyQuery(LabelArchiveQuery);
  const label = getLabelAPIResult.data?.label;

  /* Variables */

  const { REFIDasProp } = props;
  const [authenticated] = useAuthentication();
  const authorisationLevel = useAuthorisationLevel(label);
  const router = useRouter();
  const REFID = router.query.refId || REFIDasProp;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const { t } = useI18n();
  const showInPopup = useSelector((state) => state.app.showInPopup);

  // State

  const [selectedLinksSection, setSelectedLinksSection] = useState(LINKS_SECTION.ITEMS);
  const [deleteConfirmationString, setDeleteConfirmationString] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [renderSharePopup, setRenderSharePopup] = useState(false);
  const [showAddWorkPopup, setShowAddWorkPopup] = useState(false);
  const [showConfirmDeleteAlert, setShowConfirmDeleteAlert] = useState(false);
  const [showEditTemplatePopup, setShowEditTemplatePopup] = useState(false);

  // Refs

  const linksSectionRef = useRef();
  const templateSectionRef = useRef();

  /* API */

  const getLabelItemsResult = useQuery(getLabelItemsQuery, {
    skip: !label || !label?.itemList?.length || !REFID,
    variables: { REFID },
  });
  const labelItems = useMemo(() => {
    return getLabelItemsResult.data?.label?.itemList ?? [];
  }, [getLabelItemsResult.data?.label?.itemList]);

  /* Methods */

  const dismissConfirmDeleteAlert = useCallback(() => {
    setShowConfirmDeleteAlert(false);
    setDeleteConfirmationString('');
  }, []);

  const deleteLabel = useCallback(() => {
    okdebug('DELETE LABEL');
    dismissConfirmDeleteAlert();
  }, [dismissConfirmDeleteAlert]);

  const download = useCallback(async () => {
    setDownloading(true);
    try {
      await downloadPDFForLabel(label);
    } catch (e) {
      okerror('Error downloading', e);
    } finally {
      setDownloading(false);
    }
  }, [label]);

  const openEditTemplatePopup = useCallback(() => {
    setShowEditTemplatePopup(true);
  }, []);

  const openSharePopup = useCallback(() => {
    setRenderSharePopup(true);
  }, []);

  const closeSharePopup = useCallback(() => {
    setRenderSharePopup(false);
  }, []);

  // Event handlers

  const onChangeDeleteConfirmationString = useCallback((e) => {
    setDeleteConfirmationString(e.target.value);
  }, []);

  /* Effects */

  // Load label data
  useEffect(() => {
    if (authenticated && REFID) {
      getLabelAPI({ variables: { REFID } });
    }
  }, [REFID, authenticated, getLabelAPI]);

  // Redirect if unanauthorised or not found
  useEffect(() => {
    const apiCompleted =
      getLabelAPIResult.called &&
      (getLabelAPIResult.networkStatus === NetworkStatus.ready ||
        getLabelAPIResult.networkStatus === NetworkStatus.error);

    if (apiCompleted && !(!!isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.COWORKER) || !!label)) {
      router.replace('/404');
    }
  }, [authorisationLevel, getLabelAPIResult.called, getLabelAPIResult.networkStatus, label, router]);

  /* Render */

  const isSet = label?.itemList?.length > 1;

  const pageMenuSubmenu = (
    <>
      <Text className={styles.submenuTitle}>
        {isSet ? t('LABEL_SET') : t('LABEL')}
        <Tag className={styles.submenuRefId} size='sm'>
          {REFID}
        </Tag>
      </Text>
      {REFID && (
        <div className={styles.submenuButtons}>
          <Button icon={ICONS.SHARE.name} linkStyle style={{ marginRight: 20 }} onClick={openSharePopup}>
            {useDesktopLayout && t('SHARE')}
          </Button>
          <Button
            className={styles.submenuButton}
            disabled={downloading}
            icon={ICONS.DOWNLOAD.name}
            linkStyle
            loading={downloading}
            onClick={download}
          >
            {useDesktopLayout && t('DOWNLOAD')}
          </Button>
          {/* <Button
            className={styles.submenuButton}
            icon={ICONS.TRASH.name}
            linkStyle
            onClick={promptConfirmDelete}
            tint='alert'
          >
            {useDesktopLayout && 'Delete'}
          </Button> */}
        </div>
      )}
    </>
  );

  const mainContent = useMemo(() => {
    if (!REFID || !label) {
      return (
        <ContentLayout className={styles.templateContainer} pageContent>
          <TextLayout>
            <Icon height={40} name={ICONS.SPINNER.name} width={40} />
          </TextLayout>
        </ContentLayout>
      );
    } else {
      const { labelTemplate } = label;
      const { corners, eyelet, languageCode, size } = labelTemplate;
      const labelDimensions = LabelTemplateModel.dimensionsForSize(size);

      const hasCorners = corners !== LabelTemplateModel.CORNERS.NONE;
      const hasEyelet = eyelet !== LabelTemplateModel.EYELET.NONE;

      return (
        <ContentLayout className={styles.templateContainer} pageContent>
          <TextLayout>
            <h3>{t('LABEL_SECTION_TEMPLATE')}</h3>
            <div className={styles.templateInfoContainer}>
              <Progressable inProgress={downloading}>
                <div className={styles.preview} onClick={download}>
                  <NextImage layout='fill' src={LabelTemplateModel.previewImageUrl(labelTemplate)} />
                </div>
              </Progressable>
              <div className={styles.detailsContainer}>
                <Text bold>
                  <LabelTemplateName labelTemplate={labelTemplate} />
                </Text>
                <Button className={styles.editLableButton} onClick={openEditTemplatePopup} tint='navigation'>
                  {t('EDIT_LABEL')}
                </Button>
                <Text bold className={styles.detailHeader}>
                  {t('LABEL_FIELD_SIZE')}
                </Text>
                <Text>
                  {labelDimensions.width} x {labelDimensions.height} mm
                </Text>
                <Text bold className={styles.detailHeader}>
                  {t('LABEL_FIELD_LANGUAGE')}
                </Text>
                <Tag className={styles.languageTag} size='sm'>
                  {languageCode}
                </Tag>
                {(hasCorners || hasEyelet) && (
                  <>
                    <Text bold className={styles.detailHeader}>
                      {t('LABEL_FIELD_FEATURES')} {t('OPTIONAL_FIELD_LABEL')}
                    </Text>
                    {hasCorners && (
                      <Text className={styles.feature}>
                        {t('LABEL_TEMPLATE_PREVIEW_WITH_ROUNDED_CORNERS', { data: { mm: t(corners) } })}
                      </Text>
                    )}
                    {hasEyelet && <Text className={styles.feature}>{t('LABEL_TEMPLATE_PREVIEW_WITH_EYELET')}</Text>}
                  </>
                )}
              </div>
            </div>
          </TextLayout>
        </ContentLayout>
      );
    }
  }, [download, downloading, label, openEditTemplatePopup, REFID, t]);

  let linksSection;
  if (label) {
    let links;
    let linksHeader;
    switch (selectedLinksSection) {
      case LINKS_SECTION.PRODUCT:
        linksHeader = t('PRODUCTS');
        links = <ProductArchiveCard product={label.product} />;
        break;
      default:
        linksHeader = t('ITEMS');
        links = (
          <Carousel className={styles.itemsCarouselContainer} fadeOutSides={useDesktopLayout}>
            {labelItems.map((i) => {
              return (
                <Slide className={styles.itemSlide} key={i.id}>
                  <ItemArchiveCard className={styles.itemCard} item={i} />
                </Slide>
              );
            })}
          </Carousel>
        );
        break;
    }

    linksSection = (
      <>
        <ContentLayout className={styles.linksMenuContainer} ref={linksSectionRef} pageContent>
          <TextLayout>
            <h3>{t('ARCHIVE_PAGE_SECTION_LINKS')}</h3>
            <TabsContextProvider
              activeTabId={selectedLinksSection}
              onTabClick={setSelectedLinksSection}
              tabStyle='underline'
            >
              <Tabs
                className={styles.linksTabs}
                sections={[{ id: LINKS_SECTION.PRODUCT }, { id: LINKS_SECTION.ITEMS }]}
              >
                <Tab className={styles.linksTab} tabId={LINKS_SECTION.PRODUCT}>
                  {t('PRODUCTS')}
                </Tab>
                <Tab className={styles.linksTab} tabId={LINKS_SECTION.ITEMS}>
                  {t('ITEMS')}
                </Tab>
              </Tabs>
            </TabsContextProvider>
          </TextLayout>
        </ContentLayout>
        <ContentLayout pageContent>
          <TextLayout>
            <h4>{linksHeader}</h4>
            {getLabelItemsResult.loading && <Icon height={40} name={ICONS.SPINNER.name} width={40} />}
          </TextLayout>
          {links}
        </ContentLayout>
      </>
    );
  }

  const labelTemplateName = label && <LabelTemplateName labelTemplate={label?.labelTemplate} />;

  const pageMenu = (
    <PageMenu
      assetName={labelTemplateName}
      sections={[]}
      showBackButton
      smartTabs
      submenu={pageMenuSubmenu}
      submenuClassName={styles.pageSubmenu}
      verticalOffsetPx={70}
    />
  );

  return (
    <>
      <PageTitle>{REFID ? `${t('LABEL')} - ${REFID}` : t('LABEL')}</PageTitle>
      {showInPopup ? pageMenu : <Menu/>}
      <ContentLayout
        className={`${styles.headerContainer} ${showInPopup && styles.headerContainerPopup}`}
        ref={templateSectionRef}
        pageContent
      >
        <TextLayout>
          <h2 className={styles.pageHeader}>
            {isSet ? t('LABEL_SET') : t('LABEL')} <Tag className={styles.headerRefId}>{REFID}</Tag>
          </h2>
        </TextLayout>
      </ContentLayout>
      {mainContent}
      {linksSection}
      {showConfirmDeleteAlert && (
        <Alert
          title={`Are you sure you want to delete this ${isSet ? 'label set' : 'label'}?`}
          message={
            <>
              <Text>
                Deleting label(s) will not delete any OK ID ™, but might make it harder to find an OK ID ™ for future
                labels.
              </Text>
              <Text bold className={styles.confirmDeletionLabel}>
                Type &quot;YES OK&quot; to confirm
              </Text>
              <Input
                onChange={onChangeDeleteConfirmationString}
                placeholder={`Type: ${DELETE_CONFIRMATION_STRING}`}
                value={deleteConfirmationString}
              />
            </>
          }
          buttons={
            <>
              <Button
                block
                className={styles.deleteLabelButton}
                disabled={deleteConfirmationString !== DELETE_CONFIRMATION_STRING}
                onClick={deleteLabel}
                tint='alert'
              >
                Delete {isSet ? 'label set' : 'label'}
              </Button>
              <Button linkStyle onClick={dismissConfirmDeleteAlert}>
                No, continue editing
              </Button>
            </>
          }
        />
      )}
      {showEditTemplatePopup && (
        <LabelMakerPopup
          dismiss={() => setShowEditTemplatePopup(false)}
          label={label}
          mode={LABEL_MAKER_MODE.EDIT_LABEL}
        />
      )}
      {renderSharePopup && (
        <ShareAssetPopup
          assetId={label?.id}
          assetType='LABEL'
          asset={label}
          dismiss={closeSharePopup}
          sharedToList={label?.assetAccessPermissionList}
        />
      )}
      {showAddWorkPopup && (
        <AddWorkPopup
          dismiss={() => setShowAddWorkPopup(false)}
          data={label}
          preSelectedData={label}
          preSelectedDataType={'LABEL'}
        />
      )}
    </>
  );
}

ArchiveLabelPage.layoutProps = {
  contentTopPadding: false,
};

ArchiveLabelPage.propTypes = {
  REFIDasProp: PropTypes.string,
};
export async function getServerSideProps({ locale }) {
  const i18nProps = await serverSideTranslations(locale, ['common']);
  return { props: { ...i18nProps } };
}
