import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import { Carousel, Slide } from 'OK/components/carousel';
import CommentsController from 'OK/components/comments/controller';
import EditSitePageContactsSection from 'OK/components/contactsSection';
import FormFieldI18n from 'OK/components/form/formFieldI18n';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInputAsButton from 'OK/components/input/search/asButton';
import InspectionLogArchiveCard from 'OK/components/inspectionLog/archiveCard';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import WaitLayout from 'OK/components/layouts/wait';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import PageTitle from 'OK/components/pageTitle';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import OrderModel from 'OK/models/order';
import OrderIdentifier from 'OK/models/orderIdentifier';
import EditOrderPageDocumentationSection from 'OK/modules/pages/archive/order/documentationsSection';
import EditOrderPageSiteSection from 'OK/modules/pages/archive/order/siteSection';
import AddWorkPopup from 'OK/modules/popups/addWork';
import ShareAssetPopup from 'OK/modules/popups/share';
import {
  AddOrderIdentifierMutation,
  DeleteOrderMutation,
  GetOrderQuery,
  RemoveOrderIdentifierMutation,
  RemoveOrderNameMutation,
  SetOrderNameMutation,
} from 'OK/networking/orders';
import { OBJECT_PERMISSIONS } from 'OK/util/functions/permissions';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';
import usePermission from 'OK/util/hooks/usePermission';
import { I18n } from 'OK/util/i18n';

export default function EditOrderPage(props) {
  const { REFIDasProp } = props;
  const [, , user] = useAuthentication(() => false);
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const router = useRouter();
  const REFID = router.query.refId?.toUpperCase() || REFIDasProp;
  const { locale, t } = useI18n();
  const showInPopup = useSelector((state) => state.app.showInPopup);
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);

  /* State */

  const [deleteToastMessage, setDeleteToastMessage] = useState(null);
  const [identifierError, setIdentifierError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [newIdentifier, setNewIdentifier] = useState('');
  const [renderConfirmDeleteAlert, setRenderConfirmDeleteAlert] = useState(false);
  const [renderSharePopup, setRenderSharePopup] = useState(false);
  const [showAddWorkPopup, setShowAddWorkPopup] = useState(false);

  /* API */

  const getOrderResult = useQuery(GetOrderQuery, {
    // fetchPolicy: 'cache-and-network',
    onError: (e) => {
      if (e.message === 'ORDER_ID_INVALID_OR_MISSING') {
        // Not found, so redirect to 404
        router.replace('/404');
      }
    },
    variables: { REFID },
    skip: !REFID,
  });
  const [addOrderIdentifierAPI] = useMutation(AddOrderIdentifierMutation);
  const [deleteOrderAPI, deleteOrderAPIResult] = useMutation(DeleteOrderMutation);
  const [removeOrderIdentifierAPI] = useMutation(RemoveOrderIdentifierMutation);
  const [removeOrderNameAPI] = useMutation(RemoveOrderNameMutation);
  const [setOrderNameAPI] = useMutation(SetOrderNameMutation);

  // Order data
  const order = getOrderResult.data?.order;
  const identifiers = order?.orderIdentifierList;
  const linkedInspectionLogList = getOrderResult.data?.order?.linkedInspectionLogList;

  let orderedLinkedInspectionLogList = linkedInspectionLogList ? [...linkedInspectionLogList] : [];
  orderedLinkedInspectionLogList?.sort((a, b) => {
    return b.lastModifiedDate - a.lastModifiedDate;
  });

  // Comments permission data
  const hasPermissionToPostComments = usePermission(
    OBJECT_PERMISSIONS.POST_COMMENTS,
    user,
    activeOrganisationId,
    order
  );

  //Refs
  const logsSectionRef = useRef();
  const sitesSectionRef = useRef();
  const documentsSectionRef = useRef();
  const contactsSectionRef = useRef();

  const linksSections = [
    { ref: logsSectionRef, title: t('LOGS') },
    { ref: sitesSectionRef, title: t('SITES') },
    { ref: documentsSectionRef, title: t('DOCUMENTS') },
    { ref: contactsSectionRef, title: t('CONTACTS_SECTION') },
  ];
  /* Methods */

  const addIdentifier = useCallback(
    (identifier) => {
      setIdentifierError(null);
      setNewIdentifier('');

      if (identifiers?.findIndex((i) => i.orderIdentifier === identifier) > -1) {
        // Ignore because identifier already exists
        return;
      }

      const newIdentifierOptimistic = {
        id: 'NEW',
        orderIdentifier: identifier,
        __typename: OrderIdentifier.GRAPHQL_TYPE,
      };
      addOrderIdentifierAPI({
        variables: {
          orderId: order.id,
          identifier,
        },
        optimisticResponse: { identifier: newIdentifierOptimistic },
        update: (cache, result) => {
          if (!result.data?.identifier) {
            setIdentifierError(t('ERROR_GENERIC'));
            return;
          }

          const addedIdentifier = result.data.identifier;
          cache.writeFragment({
            id: `${OrderModel.GRAPHQL_TYPE}:${order.id}`,
            fragment: OrderModel.fragmentIdentifiers,
            data: {
              id: order.id,
              orderIdentifierList: identifiers?.length ? [...identifiers, addedIdentifier] : [addedIdentifier],
              __typename: OrderModel.GRAPHQL_TYPE,
            },
          });
        },
      }).catch(() => {
        setIdentifierError(t('ERROR_GENERIC'));
      });
    },
    [addOrderIdentifierAPI, identifiers, order?.id, t]
  );

  const deleteOrder = useCallback(() => {
    deleteOrderAPI({
      variables: {
        orderId: order?.id,
      },
    })
      .then(() => {
        router.push('/archive?section=orders');
      })
      .catch(() => {
        setDeleteToastMessage(t('ERROR_GENERIC'));
      });
  }, [deleteOrderAPI, order?.id, router, t]);

  const dismissConfirmDeleteAlert = useCallback(() => {
    setRenderConfirmDeleteAlert(false);
  }, []);

  const promptConfirmDelete = useCallback(() => {
    setRenderConfirmDeleteAlert(true);
  }, []);

  const removeIdentifer = useCallback(
    (identifierId) => {
      setIdentifierError(null);
      const updatedIdentifierList = [...identifiers];
      const indexToRemove = updatedIdentifierList.findIndex((i) => i.id === identifierId);
      const identifierText = updatedIdentifierList[indexToRemove].orderIdentifier;
      updatedIdentifierList.splice(indexToRemove, 1);
      removeOrderIdentifierAPI({
        variables: {
          identifierId,
        },
        optimisticResponse: {
          identifier: {
            id: identifierId,
            orderIdentifier: identifierText,
            __typename: OrderIdentifier.GRAPHQL_TYPE,
          },
        },
        update: (cache) => {
          cache.writeFragment({
            id: `${OrderModel.GRAPHQL_TYPE}:${order.id}`,
            fragment: OrderModel.fragmentIdentifiers,
            data: {
              id: order.id,
              orderIdentifierList: updatedIdentifierList,
              __typename: OrderModel.GRAPHQL_TYPE,
            },
          });
        },
      }).catch(() => {
        setIdentifierError(t('ERROR_GENERIC'));
      });
    },
    [identifiers, order?.id, removeOrderIdentifierAPI, t]
  );

  /* Event handlers */

  const onBlurName = useCallback(
    (blurredLanguageIso, values) => {
      setNameError(null);
      const name = values.find((v) => v.languageIso === blurredLanguageIso)?.value;
      if (name !== order.name[blurredLanguageIso]?.text) {
        // Update name in changed language
        const optimisticResponse = {
          order: {
            id: order.id,
            name: {
              ...order.name,
              [blurredLanguageIso]: {
                ...order.name[blurredLanguageIso],
                text: name,
              },
            },
            __typename: OrderModel.GRAPHQL_TYPE,
          },
        };
        setOrderNameAPI({
          variables: {
            orderId: order.id,
            languageIso: blurredLanguageIso,
            name,
          },
          optimisticResponse,
        }).catch(() => {
          setNameError(t('ERROR_GENERIC'));
        });
      }
    },
    [order?.id, order?.name, setOrderNameAPI, t]
  );

  const onChangeName = useCallback(
    (changedLanguageIso, values) => {
      setNameError(null);
      if (values.findIndex((v) => v.languageIso === changedLanguageIso) === -1) {
        // Remove name in changed language
        const updatedProductNameObject = { ...order.name };
        delete updatedProductNameObject[changedLanguageIso];
        const optimisticResponse = {
          order: {
            id: order.id,
            name: {
              ...updatedProductNameObject,
            },
            __typename: OrderModel.GRAPHQL_TYPE,
          },
        };
        removeOrderNameAPI({
          variables: {
            orderId: order.id,
            languageIso: changedLanguageIso,
          },
          optimisticResponse,
        }).catch(() => {
          setNameError(t('ERROR_GENERIC'));
        });
      }
    },
    [order?.id, order?.name, removeOrderNameAPI, t]
  );

  const onChangeNewIdentifier = useCallback((e) => {
    setNewIdentifier(e.target.value);
  }, []);

  const openSharePopup = useCallback(() => {
    setRenderSharePopup(true);
  }, []);

  const closeSharePopup = useCallback(() => {
    setRenderSharePopup(false);
  }, []);

  /* Effects */

  // Hide delete toast after 5 seconds
  useEffect(() => {
    if (deleteToastMessage) {
      setTimeout(() => {
        setDeleteToastMessage(false);
      }, 5000);
    }
  }, [deleteToastMessage]);

  /* Render */

  const initialLoading = getOrderResult.networkStatus === NetworkStatus.loading;

  let identifierSuggestions;
  if (newIdentifier.length) {
    identifierSuggestions = [
      {
        key: newIdentifier,
        title: newIdentifier,
        subtitle: t('ADD_NEW_IDENTIFIER'),
      },
    ];
  } else {
    identifierSuggestions = [];
  }

  let name;
  if (order?.name) {
    name = Object.keys(order.name).map((languageIso) => {
      return { languageIso, value: order.name[languageIso].text };
    });
  } else {
    name = [];
  }

  const localizedNameForOrder = order && OrderModel.localizedNameForOrder(order, locale);

  const pageMenu = (
    <PageMenu
      assetName={localizedNameForOrder}
      sections={[]}
      showBackButton
      smartTabs
      submenu={
        <div className={styles.submenu}>
          <Text className={styles.menuTitle}>
            {t('ORDER_ARCHIVE_CARD_HEADER')} <Tag>{REFID}</Tag>
          </Text>
          <div className={styles.deleteButtonContainer}>
            <Button icon={ICONS.SHARE.name} linkStyle style={{ marginRight: 20 }} onClick={openSharePopup}>
              {useDesktopLayout && t('SHARE')}
            </Button>
            <Button icon={ICONS.TRASH.name} linkStyle onClick={promptConfirmDelete} tint='alert'>
              {useDesktopLayout ? t('DELETE') : null}
            </Button>
            <FadeInOutTransition appear in={!!deleteToastMessage}>
              <Toast className={styles.shareToast}>{deleteToastMessage}</Toast>
            </FadeInOutTransition>
          </div>
        </div>
      }
      submenuClassName={styles.pageSubmenu}
      verticalOffsetPx={70}
    />
  );

  return (
    <>
      <PageTitle>{t('PAGE_TITLE_EDIT_ORDER')}</PageTitle>
      {showInPopup ? pageMenu : <Menu> {pageMenu} </Menu>}
      <ContentLayout className={styles.basicsContainer} style={{ paddingTop: showInPopup ? 30 : 176 }} pageContent>
        <TextLayout className={styles.header}>
          <h2>{t('ORDER_ARCHIVE_CARD_HEADER')}</h2>
          <Tag className={styles.headerRefId}>{REFID}</Tag>
        </TextLayout>
        {initialLoading && (
          <TextLayout>
            <Icon height={40} name={ICONS.SPINNER.name} width={40} />
          </TextLayout>
        )}
        <WaitLayout wait={initialLoading}>
          <div className={styles.basicsFieldsContainer}>
            <div className={styles.identifiersContainer}>
              <TextLayout>
                <h4>
                  <I18n i18nKey='ORDER_FIELD_IDENTIFIERS_ADMIN'>
                    Identifiers <span className={styles.unbold}>(optional)</span>
                  </I18n>
                </h4>
                {identifiers?.map((i) => (
                  <Tag
                    className={styles.identifierTag}
                    key={i.id}
                    onClickRemove={removeIdentifer}
                    removeable
                    size='sm'
                    uniqueId={i.id}
                  >
                    {i.orderIdentifier}
                  </Tag>
                ))}
                <div className={styles.searchContainer}>
                  <SearchInputAsButton
                    className={`${styles.blockButton} ${styles.searchInput}`}
                    icon={ICONS.PLUS.name}
                    inputProps={{
                      className: `${styles.input} ${styles.searchInput}`,
                    }}
                    invert
                    onChange={onChangeNewIdentifier}
                    placeholder={t('ADD_IDENTIFIER_PLACEHOLDER')}
                    tint='creation'
                    value={newIdentifier}
                  />
                  <SearchSuggestions
                    className={styles.searchSuggestions}
                    onSuggestionClick={addIdentifier}
                    suggestions={identifierSuggestions}
                  />
                </div>
                {identifierError && (
                  <Text className={styles.fieldError} size='sm' tint='alert'>
                    {identifierError}
                  </Text>
                )}
              </TextLayout>
            </div>
            <div className={styles.nameContainer}>
              <TextLayout>
                <h4>
                  <I18n i18nKey='ORDER_FIELD_NAME_ADMIN'>
                    Name <span className={styles.unbold}>(optional)</span>
                  </I18n>
                </h4>
                <FormFieldI18n
                  addLanguageButtonTitle={t('NOTE_CARD_ADD_LANGUAGE')}
                  addLanguageButtonClassName={styles.blockButton}
                  onBlur={onBlurName}
                  onChange={onChangeName}
                  inputPlaceholder={t('PRODUCT_FIELD_NAME_PLACEHOLDER')}
                  values={name}
                />
                {nameError && (
                  <Text className={styles.fieldError} size='sm' tint='alert'>
                    {nameError}
                  </Text>
                )}
              </TextLayout>
            </div>
          </div>
        </WaitLayout>
      </ContentLayout>
      <WaitLayout wait={initialLoading}>
        <div className={styles.linksContainer}>
          <ContentLayout pageContent>
            <TextLayout>
              <h3>{t('ARCHIVE_PAGE_SECTION_LINKS')}</h3>
            </TextLayout>
          </ContentLayout>
          <div className={styles.absoluteMenu}>
            <PageMenu
              className={styles.internalMenu}
              tabsClassName={styles.internalTabs}
              sections={linksSections}
              smartTabs
            />
          </div>
          <ContentLayout className={styles.linkSection} ref={logsSectionRef} pageContent>
            <TextLayout>
              <h4>{t('LOGS')}</h4>
              <Text className={styles.sectionDescription}>{t('ORDER_SECTION_DESCRIPTION_LINKS')}</Text>
              {!linkedInspectionLogList && <Text tint='notification'>{t('NO_LINKED_LOGS')}</Text>}
            </TextLayout>
            {linkedInspectionLogList && (
              <Carousel className={styles.recentInspectionsCarousel} fadeOutSides={useDesktopLayout}>
                {orderedLinkedInspectionLogList.map((log) => {
                  return (
                    <Slide key={log.id} className={styles.recentInspectionLogSlide}>
                      <InspectionLogArchiveCard
                        cardClassName={styles.recentInspectionLogCardInner}
                        className={styles.recentInspectionLogCard}
                        fixedWidth={useDesktopLayout}
                        inspectionLog={log}
                        showLinkToProduct={false}
                      />
                    </Slide>
                  );
                })}
              </Carousel>
            )}
          </ContentLayout>
          <ContentLayout ref={sitesSectionRef} pageContent>
            <EditOrderPageSiteSection order={order} />
          </ContentLayout>
          <ContentLayout ref={documentsSectionRef} pageContent>
            <EditOrderPageDocumentationSection order={order} />
          </ContentLayout>
          <ContentLayout ref={contactsSectionRef} pageContent>
            <EditSitePageContactsSection asset={order} sourceType='ORDER' sourceId={order?.id} linkDataType='SITE' />
          </ContentLayout>

          {showAddWorkPopup && (
            <AddWorkPopup
              dismiss={() => setShowAddWorkPopup(false)}
              data={order}
              preSelectedData={order}
              preSelectedDataType={'ORDER'}
            />
          )}
          {renderSharePopup && (
            <ShareAssetPopup
              assetId={order?.id}
              assetType='ORDER'
              dismiss={closeSharePopup}
              sharedToList={order?.assetAccessPermissionList}
            />
          )}

          {hasPermissionToPostComments && (
            <ContentLayout className={styles.commentSection} pageContent>
              <TextLayout>
                <h3 className={styles.commentSectionHeader}>{t('COMMENTS_SECTION_HEADER')}</h3>
                <CommentsController assetType='ORDER' assetId={order?.id} assetOrganisationId={order?.organisationId} />
              </TextLayout>
            </ContentLayout>
          )}
        </div>
      </WaitLayout>
      {renderConfirmDeleteAlert && (
        <Alert
          title={t('ORDER_DELETE_ALERT_TITLE')}
          message={
            <I18n i18nKey='ORDER_DELETE_ALERT_MESSAGE'>
              <strong>Are you sure</strong> you want to delete this order?
            </I18n>
          }
          buttons={
            <>
              <Button
                block
                className={styles.confirmDeleteButton}
                disabled={deleteOrderAPIResult.loading}
                loading={deleteOrderAPIResult.loading}
                onClick={deleteOrder}
                tint='alert'
              >
                {t('ORDER_DELETE_ALERT_BUTTON_CONFIRM')}
              </Button>
              <Button block onClick={dismissConfirmDeleteAlert} linkStyle style={{ margin: 0 }}>
                {t('ORDER_DELETE_ALERT_BUTTON_CANCEL')}
              </Button>
            </>
          }
        />
      )}
    </>
  );
}

EditOrderPage.layoutProps = { contentTopPadding: false, padFooter: false };

EditOrderPage.propTypes = {
  REFIDasProp: PropTypes.string,
};

export async function getServerSideProps({ locale }) {
  const i18nProps = await serverSideTranslations(locale, ['common']);
  return { props: { ...i18nProps } };
}
