import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon from 'OK/components/icon';
import ContentLayout from 'OK/components/layouts/content';
import { Tab, Tabs, TabsContextProvider } from 'OK/components/tabs';
import Text from 'OK/components/text';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import { updateMiscAppStateAction } from 'OK/state/app/actions';
import { baseTheme, themeLight, themeDark } from 'OK/styles/theme';
import { DEBOUNCE_TIMING_MS_INSTANT, TIMING_DELAY_SHORT_MS } from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * @typedef {object} PageMenuProps
 * @prop {string} [className]
 * @prop {boolean} [hideWhenScrolledToTop=false] Hide the page menu when scrolled to the top.
 * @prop {(activeSectionName: string) => {}} [onActiveSectionChange] Event handler for when the active section changes.
 * @prop {object[]} sections Sections of the page to display in the menu.
 * @prop {any} sections[].ref The React ref of the section.
 * @prop {string} sections[].title The display title of the section.
 * @prop {boolean} [showBackButton=false] Show a back button.
 * @prop {boolean} [smartTabs=false] Sections should scroll page when clicked. Also required for
 * `onActiveSectionChange` to fire.
 * @prop {Node} [submenu] Component(s) to render in a submenu. Will be rendered inside a menu bar.
 * @prop {string} [submenuClassName] Class name for the submenu content.
 * @prop {'light'|'dark'|'match'|'invert'} [themeWithBackgroundHidden='match'] Theme to apply when the menu
 * background is hidden.
 * @prop {Node} [title] Page title component. Will be rendered at the far left of the menu.
 * @prop {string} [titlePosition='left'] Position of the title.
 * @prop {number} [verticalOffsetPx] Offset in pixels of the menu from the top of the page. Defaults to the
 * height of the main menu.
 *
 */

/**
 * Page-specific menu to display under the main menu.
 *
 * @type {React.FC<PageMenuProps>}
 */
const PageMenu = forwardRef((props, forwardedRef) => {
  /* Variables */

  const {
    activeSectionTitle,
    assetName,
    className,
    hideWhenScrolledToTop = false,
    inlineStyle,
    onActiveSectionChange,
    sections = [],
    showBackButton = false,
    showSectionsInMenu = true,
    showSubmenu = true,
    smartTabs = false,
    submenu,
    subMenuBarClassName,
    submenuClassName,
    tabClassName,
    tabsClassName,
    themeWithBackgroundHidden = 'match',
    title,
    titleClassName,
    titlePosition = 'left',
    topMenuClassName,
    verticalOffsetPx = baseTheme.sizing.mainMenuHeight,
  } = props;
  const isScrolledToTop = useSelector((state) => state.app.isScrolledToTop);
  const showMenuBackground = useSelector((state) => state.app.showMenuBackground);
  const menuIsVisible = useSelector((state) => state.app.menuIsVisible);
  const showInPopup = useSelector((state) => state.app.showInPopup);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const dispatch = useDispatch();
  const innerRef = useRef();
  const ref = forwardedRef || innerRef;
  const router = useRouter();
  const themeContext = useContext(ThemeContext);
  const { t } = useI18n();
  const containerRef = useRef();

  /* State */

  const [, setIntersectionRatios] = useState(sections.map((s) => ({ sectionName: s.title, ratio: 0 })));
  const [_activeSectionTitle, _setActiveSectionTitle] = useState(
    activeSectionTitle ?? (sections.length > 0 ? sections[0].title : null)
  );
  // Check for window.history.length > 2 because length is already 2 on first page load
  const canGoBack = typeof window !== 'undefined' ? window.history.length > 2 : false;

  /* Methods */

  const goBack = useCallback(() => {
    if (canGoBack) {
      window.history.back();
    } else {
      router.replace('/');
    }
  }, [canGoBack, router]);

  const listenToScrollingDebounced = useMemo(
    () =>
      debounce(() => {
        dispatch(updateMiscAppStateAction({ listenToScrolling: true }));
      }, TIMING_DELAY_SHORT_MS),
    [dispatch]
  );

  const scrollToSection = (sectionTitle) => {
    const section = sections.find((s) => s.title === sectionTitle);

    let scrollTop = section.ref.current.offsetTop;

    const willScrollDown = scrollTop > window.scrollY;
    if (willScrollDown) {
      // Ignore scrolling so the menu isn't hidden during scroll
      dispatch(updateMiscAppStateAction({ listenToScrolling: false }));
    }

    // Adjust for height of self
    const selfHeight = containerRef.current.clientHeight;
    scrollTop -= selfHeight;

    if (menuIsVisible || !willScrollDown) {
      // Adjust for height of navigation menu because
      // a) Menu is visible, or
      // b) Menu will probably become visible after scrolling up
      const menuHeight = document.getElementById('app_menus').clientHeight;
      scrollTop -= menuHeight;
    }

    if (showInPopup) {
      document.querySelector('#popup').scrollTo({ top: scrollTop, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: scrollTop, behavior: 'smooth' });
    }

    // Re-enable scroll listening after debounce & delay
    listenToScrollingDebounced();
  };

  const setActiveSectionTitle = useCallback(
    (title) => {
      if (!activeSectionTitle) {
        _setActiveSectionTitle(title);
      }

      onActiveSectionChange && onActiveSectionChange(title);
    },
    [activeSectionTitle, onActiveSectionChange]
  );

  /* Effects */

  // Keep track of intersection ratios for each section
  useEffect(() => {
    // Add a threshhold for each percentage point
    function buildThresholdArray() {
      let thresholds = [];
      const numSteps = 100;

      for (let i = 1.0; i <= numSteps; i++) {
        const ratio = i / numSteps;
        thresholds.push(ratio);
      }

      thresholds.push(0);
      return thresholds;
    }
    let observer;

    if (smartTabs) {
      const setActiveSectionTitleDebounced = debounce((ratios) => {
        const sectionsSortedByVisibility = [...ratios].sort((a, b) => b.ratio - a.ratio);
        const newActiveSectionTitle = sectionsSortedByVisibility[0];
        setActiveSectionTitle(newActiveSectionTitle.sectionName);
      }, DEBOUNCE_TIMING_MS_INSTANT);

      observer = new IntersectionObserver(
        (entries) => {
          const newIntersectionRatios = entries.map((e) => {
            const sectionName = sections.find((s) => s.ref.current === e.target)?.title;
            return {
              sectionName,
              ratio: e.intersectionRatio,
            };
          });
          setIntersectionRatios((currentRatios) => {
            if (!currentRatios.length || sections.length !== currentRatios.length) {
              // Default to initial ratios
              return newIntersectionRatios;
            }

            // Replace entries with new ones
            const updatedRatios = [...currentRatios];
            newIntersectionRatios.forEach((ratioObj) => {
              const updatedRatioIndex = updatedRatios.findIndex((r) => r.sectionName === ratioObj.sectionName);
              if (updatedRatioIndex > -1) {
                updatedRatios.splice(updatedRatioIndex, 1, ratioObj);
              }
            });

            // Set active section to the most visible section
            setActiveSectionTitleDebounced(updatedRatios);

            return updatedRatios;
          });
        },
        {
          threshold: buildThresholdArray(),
        }
      );
      sections.forEach((s) => {
        if (observer && s.ref.current) {
          observer.observe(s.ref.current);
        }
      });
    }

    return function () {
      if (smartTabs) {
        sections.forEach((s) => {
          if (observer && s.ref.current) {
            observer.unobserve(s.ref.current);
          }
        });
      }
    };
  }, [sections, setActiveSectionTitle, smartTabs]);

 /* Render */

  // Customize ref interface
  useImperativeHandle(ref, () => ({
    scrollToSection,
    setActiveSectionTitle,
  }));

  const shouldHide = hideWhenScrolledToTop && isScrolledToTop;

  // Classes
  let containerClassNames = '';
  let submenuClassNames = styles.submenuContent;
  let themeOverride;
  if (!showMenuBackground) {
    switch (themeWithBackgroundHidden) {
      case 'light':
        themeOverride = themeLight;
        break;
      case 'dark':
        themeOverride = themeDark;
        break;
      case 'invert':
        themeOverride = themeContext.name === 'light' ? themeLight : themeDark;
        break;
      default:
        // Match the app's theme.
        break;
    }
  }

  if (themeOverride) {
    containerClassNames = `${containerClassNames} ${styles[`themeOverride${capitalize(themeOverride.name)}`]}`;
  }
  if (className) {
    containerClassNames = `${containerClassNames} ${className}`;
  }
  if (submenuClassName) {
    submenuClassNames = `${submenuClassNames} ${submenuClassName}`;
  }

  return (
    <ThemeContext.Provider value={themeOverride || themeContext}>
      <CSSTransition
        classNames={{
          appear: styles.containerAppear,
          appearActive: styles.containerAppearActive,
          appearDone: styles.containerAppearDone,
          enter: styles.containerEnter,
          enterActive: styles.containerEnterActive,
          enterDone: styles.containerEnterDone,
          exit: styles.containerExit,
          exitActive: styles.containerExitActive,
          exitDone: styles.containerExitDone,
        }}
        in={!shouldHide}
        mountOnEnter
        timeout={baseTheme.timing.timingShortMs}
      >
        <div className={containerClassNames} ref={containerRef} style={inlineStyle}>
          <div className={styles.menuBar}>
            <ContentLayout contentClassName={`${styles.topMenuContent} ${topMenuClassName}`}>
              {showBackButton && (
                <div className={showInPopup ? styles.titlePopup : styles.title}>
                  <Button
                    className={showInPopup ? styles.backButtonPopup : styles.backButton}
                    iconPosition='left'
                    linkStyle
                    onClick={goBack}
                    withCaret
                  >
                    {showInPopup ? null : canGoBack ? t('MENU_BUTTON_BACK') : ""}
                  </Button>
                  {showInPopup && <Text className={styles.assetName}>{assetName}</Text>}
                </div>
              )}
              {title && titlePosition === 'left' && (
                <div className={`${styles.title} ${styles.left} ${titleClassName ?? ''}`}>{title}</div>
              )}
              {showSectionsInMenu && (
                <TabsContextProvider
                  activeTabId={activeSectionTitle ?? _activeSectionTitle}
                  onTabClick={smartTabs ? scrollToSection : setActiveSectionTitle}
                >
                  <Tabs className={tabsClassName} sections={sections}>
                    {sections.map((s) => (
                      <Tab className={tabClassName} key={s.title} tabId={s.title}>
                        {s.icon && <Icon className={styles.tabIcon} name={s.icon} />}{' '}
                        {s.icon && useMobileLayout ? null : s.title}
                      </Tab>
                    ))}
                  </Tabs>
                </TabsContextProvider>
              )}
              {title && titlePosition === 'right' && (
                <div className={`${styles.title} ${styles.right} ${titleClassName ?? ''}`}>{title}</div>
              )}
            </ContentLayout>
          </div>
          {submenu && (
            <FadeInOutTransition appear in={showSubmenu}>
              <div
                className={`${styles.menuBar} ${subMenuBarClassName}`}
                style={{ height: showSubmenu ? 'auto' : 0, pointerEvents: showSubmenu ? 'all' : 'none' }}
              >
                <ContentLayout contentClassName={submenuClassNames}>{submenu}</ContentLayout>
              </div>
            </FadeInOutTransition>
          )}
        </div>
      </CSSTransition>
    </ThemeContext.Provider>
  );
});

PageMenu.propTypes = {
  activeSectionTitle: PropTypes.string,
  assetName: PropTypes.string,
  className: PropTypes.string,
  hideWhenScrolledToTop: PropTypes.bool,
  onActiveSectionChange: PropTypes.func,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      ref: PropTypes.any,
      title: PropTypes.string,
      icon: PropTypes.object,
    })
  ).isRequired,
  showBackButton: PropTypes.bool,
  showSectionsInMenu: PropTypes.bool,
  showSubmenu: PropTypes.bool,
  smartTabs: PropTypes.bool,
  submenu: PropTypes.node,
  subMenuBarClassName: PropTypes.string,
  submenuClassName: PropTypes.string,
  inlineStyle: PropTypes.object,
  tabClassName: PropTypes.string,
  tabsClassName: PropTypes.string,
  themeWithBackgroundHidden: PropTypes.oneOf(['light', 'dark', 'match', 'invert']),
  title: PropTypes.node,
  titleClassName: PropTypes.string,
  titlePosition: PropTypes.oneOf(['left', 'right']),
  topMenuClassName: PropTypes.string,
  verticalOffsetPx: PropTypes.number,
};

export default PageMenu;
