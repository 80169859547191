export function clearSessionAction(authenticationError) {
  return { type: ACCOUNT_CLEAR_SESSION_ACTION, data: { authenticationError } };
}

export function resetLoginModal() {
  return { type: ACCOUNT_CLOSE_AUTH_MODAL };
}

export function lockAction(lockedUntil) {
  return {
    type: ACCOUNT_LOCK_ACTION,
    data: { lockedUntil },
  };
}

export function loginAction(accessToken, isNewUser) {
  return {
    type: ACCOUNT_LOGIN_ACTION,
    data: {
      accessToken,
      isNewUser,
    },
  };
}

export function renewSessionAction(accessToken) {
  return { type: ACCOUNT_RENEW_ACTION, data: { accessToken } };
}

export function resetCreatedOrganisationFlag() {
  return { type: ACCOUNT_RESET_CREATED_ORGANISATION_FLAG_ACTION };
}

export function restoringSession() {
  return { type: ACCOUNT_RESTORING_SESSION_ACTION };
}

export function setActiveInspectionLogId(inspectionLogId) {
  return { type: ACCOUNT_SET_ACTIVE_INSPECTION_LOG_ID, data: { inspectionLogId } };
}

export function setActiveOrganisationId(organisationId) {
  return { type: ACCOUNT_SET_ACTIVE_ORGANISATION_ID, organisationId };
}

export function setComingFromTrial(isComingFromTryForFree) {
  return {
    type: ACCOUNT_SET_COMING_FROM_TRIAL,
    data: { isComingFromTryForFree },
  };
}

export function setComingFromUserPage(isComingFromUserPage) {
  return {
    type: ACCOUNT_SET_COMING_FROM_USER_PAGE,
    data: { isComingFromUserPage },
  };
}
export function setLoginRedirectUrlAction(loginRedirectUrl) {
  return {
    type: ACCOUNT_SET_LOGIN_REDIRECT_URL,
    loginRedirectUrl,
  };
}

export function showCreatingNewOrganisation(showCreateOrganisation) {
  return {
    type: ACCOUNT_SHOW_CREATING_ORGANISATION,
    data: { showCreateOrganisation },
  };
}

export function showInvitations(showInvitations) {
  return {
    type: ACCOUNT_SHOW_INVITATATIONS,
    data: { showInvitations },
  };
}

export function showOnboarding(showOnboarding) {
  return {
    type: ACCOUNT_SHOW_ONBOARDING,
    data: { showOnboarding },
  };
}

export function showTryForFree(showTrialOrganisation) {
  return {
    type: ACCOUNT_SHOW_TRY_FOR_FREE,
    data: { showTrialOrganisation },
  };
}

export function startSessionAction(accessToken, user) {
  return { type: ACCOUNT_SESSION_START_ACTION, data: { accessToken, user } };
}

export function verifyingOTPAction(
  username,
  usernameType,
  attemptsRemaining,
  codeExpirationDate,
  countryCode,
  isNewUser,
  allowNewOTPRequestDate,
  newsletterSubscription
) {
  return {
    type: ACCOUNT_VERIFYING_OTP_ACTION,
    data: {
      allowNewOTPRequestDate,
      attemptsRemaining,
      codeExpirationDate,
      countryCode,
      isNewUser,
      username,
      usernameType,
      newsletterSubscription,
    },
  };
}

export function updateSessionUser(user) {
  return {
    type: ACCOUNT_UPDATE_SESSION_USER_ACTION,
    data: { user },
  };
}

export function updateUserPreferencesAction(preference) {
  return {
    type: ACCOUNT_UPDATE_USER_PREFERENCES_ACTION,
    data: {
      ...preference,
    },
  };
}

// Action Types

export const ACCOUNT_CLEAR_SESSION_ACTION = 'account/clearSession';
export const ACCOUNT_CLOSE_AUTH_MODAL = 'account/closeLoginModal';
export const ACCOUNT_LOCK_ACTION = 'account/lockSession';
export const ACCOUNT_LOGIN_ACTION = 'account/login';
export const ACCOUNT_RENEW_ACTION = 'account/renewSession';
export const ACCOUNT_RESET_CREATED_ORGANISATION_FLAG_ACTION = 'account/resetCreatedOrganisationFlag';
export const ACCOUNT_RESTORING_SESSION_ACTION = 'account/restoringSession';
export const ACCOUNT_SESSION_START_ACTION = 'account/startSession';
export const ACCOUNT_SET_ACTIVE_INSPECTION_LOG_ID = 'account/setActiveInspectionLogId';
export const ACCOUNT_SET_ACTIVE_ORGANISATION_ID = 'account/setActiveOrganisationId';
export const ACCOUNT_SET_COMING_FROM_TRIAL = 'account/isComingFromTryForFree';
export const ACCOUNT_SET_COMING_FROM_USER_PAGE = 'account/isComingFromUserPage';
export const ACCOUNT_SET_LOGIN_REDIRECT_URL = 'account/setLoginRedirect';
export const ACCOUNT_SHOW_CREATING_ORGANISATION = 'account/showCreateOrganisation';
export const ACCOUNT_SHOW_INVITATATIONS = 'account/showInvitations';
export const ACCOUNT_SHOW_ONBOARDING = 'account/showOnboarding';
export const ACCOUNT_SHOW_TRY_FOR_FREE = 'account/showTryForFree';
export const ACCOUNT_UPDATE_SESSION_USER_ACTION = 'account/updateSessionUser';
export const ACCOUNT_UPDATE_USER_PREFERENCES_ACTION = 'account/updateUserPreferences';
export const ACCOUNT_VERIFYING_OTP_ACTION = 'account/verifyingOTP';
