import PropTypes from 'prop-types';
import { useMemo } from 'react';

export default function TextHighlighter(props) {
  const { highlightClassName, highlight, rtlEscapeHatch = false, text, ...otherProps } = props;

  const NonMatchTag = rtlEscapeHatch ? 'bdo' : 'span';

  const markup = useMemo(() => {
    let regex = new RegExp(highlight, 'i');
    let match = text.match(regex);
    if (match) {
      let before = text.slice(0, match.index);
      let matchText = text.slice(match.index, match.index + match[0].length);
      let after = text.slice(match.index + match[0].length, text.length);
      return (
        <>
          <NonMatchTag dir={rtlEscapeHatch ? 'ltr' : null}>{before}</NonMatchTag>
          <mark className={highlightClassName}>{matchText}</mark>
          <NonMatchTag dir={rtlEscapeHatch ? 'ltr' : null}>{after}</NonMatchTag>
        </>
      );
    }

    return text;
  }, [highlight, highlightClassName, rtlEscapeHatch, text]);

  return <span {...otherProps}>{markup}</span>;
}

TextHighlighter.propTypes = {
  highlightClassName: PropTypes.string,
  highlight: PropTypes.string.isRequired,
  rtlEscapeHatch: PropTypes.bool,
  text: PropTypes.string.isRequired,
};
