import { gql } from '@apollo/client';

import LabelTemplateModel from 'OK/models/labelTemplate';

/* Queries */

export const getOrganisationLabelTemplatesQuery = gql`
  query GetOrganisationLabelTemplates {
    labelTemplates: labelTemplateList {
      ...${LabelTemplateModel.fragmentName}
    }
  }
  ${LabelTemplateModel.fragment}
`;

/* Mutations */

export const createLabelTemplateMutation = gql`
  mutation CreateLabelTemplate(
    $corners: ELabelCorners!
    $eyelet: ELabelEyelet!
    $languageIso: ELanguageCode!
    $material: ELabelMaterial!
    $size: ELabelSize!
    $type: ELabelType!
  ) {
    labelTemplate: createLabelTemplate(
      corners: $corners
      eyelet: $eyelet
      languageCode: $languageIso
      material: $material
      size: $size
      type: $type
    ) {
      ...${LabelTemplateModel.fragmentName}
    }
  }
  ${LabelTemplateModel.fragment}
`;

export const editLabelTemplateCornersMutation = gql`
  mutation EditLabelTemplateCorners($labelTemplateId: String!, $corners: ELabelCorners!) {
    editLabelTemplateCorners(corners: $corners, labelTemplateId: $labelTemplateId) {
      id
      corners
    }
  }
`;

export const editLabelTemplateEyeletMutation = gql`
  mutation EditLabelTemplateEyelet($labelTemplateId: String!, $eyelet: ELabelEyelet!) {
    editLabelTemplateEyelet(eyelet: $eyelet, labelTemplateId: $labelTemplateId) {
      id
      eyelet
    }
  }
`;

export const editLabelTemplateLanguageMutation = gql`
  mutation EditLabelTemplateLanguage($labelTemplateId: String!, $languageIso: ELanguageCode!) {
    editLabelTemplateLanguageCode(languageCode: $languageIso, labelTemplateId: $labelTemplateId) {
      id
      languageCode
    }
  }
`;

export const editLabelTemplateMaterialMutation = gql`
  mutation EditLabelTemplateMaterial($labelTemplateId: String!, $material: ELabelMaterial!) {
    editLabelTemplateMaterial(material: $material, labelTemplateId: $labelTemplateId) {
      id
      material
    }
  }
`;

export const editLabelTemplateSizeMutation = gql`
  mutation EditLabelTemplateSize($labelTemplateId: String!, $size: ELabelSize!) {
    editLabelTemplateSize(size: $size, labelTemplateId: $labelTemplateId) {
      id
      size
    }
  }
`;

export const editLabelTemplateTypeMutation = gql`
  mutation EditLabelTemplateType($labelTemplateId: String!, $type: ELabelType!) {
    editLabelTemplateType(type: $type, labelTemplateId: $labelTemplateId) {
      id
      type
    }
  }
`;
