import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Draggable from 'OK/components/draggable';
import DropZone from 'OK/components/dropZone';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import SearchInput from 'OK/components/input/search';
import CardLayout from 'OK/components/layouts/card';
import Link from 'OK/components/link/index';
import Notice from 'OK/components/notice';
import Progressable from 'OK/components/progressable';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Tag from 'OK/components/tag';
import TestMiniCard from 'OK/components/test/miniCard';
import Text from 'OK/components/text';
import Toggle from 'OK/components/toggle';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import TestPopup from 'OK/modules/test-popup';
import {
  useAddThirdPartyInspectorAPI,
  useCreateInspectionTestAssetAPI,
  useLinkInspectionTestAssetAPI,
  useRemoveThirdPartyInspectorAPI,
  useUnlinkInspectionTestAssetAPI,
  useUpdateOrderOfInspectionTestAssetAPI,
} from 'OK/networking/inspectionAssets/hooks';
import { searchQuery } from 'OK/networking/search';
import { showScannerAction } from 'OK/state/app/actions';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import { formatOkid, formatPoints } from 'OK/util/formatting';
import { setNativeValue } from 'OK/util/functions';
import authorisationForResource from 'OK/util/functions/authorisationForResource';
import isAuthorised from 'OK/util/functions/isAuthorised';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * Displays an inspection.
 *
 * @param {object} props
 * @param {string} [props.className] The component's class.
 * @param {object} props.inspectionAsset The InspectionAsset to display.
 * @param {(inspectionAssetId: string, published: boolean) => void} [props.onChangePublish] Event handler for when the
 * inspection publish toggle is changed.
 * @param {(inspectionAssetId: string, reorderDirection: ('left'|'right')) => void} [props.onClickReorder] Event handler for when
 * the inspection should be reordered.
 * @param {(inspectionAssetId: string) => void} [props.onClickUnlink] Event handler for when the inspection should be unlinked.
 * @param {('left'|'right'|'both')} [props.showReorder] Show options to reorder the inspection in its list.
 * @param {string} [props.unlinkButtonTitle='Unlink'] The unlink button's title.
 */
export default function InspectionEditCard(props) {
  /* Variables */

  const { t, locale } = useI18n();
  const {
    className,
    inspectionAsset,
    onBlurSearchInspectionPartnersProp,
    onChangePublish,
    onClickReorder,
    onClickUnlink,
    onFocusSearchInspectionPartnersProp,
    publishError,
    published = false,
    showReorder,
    unlinkButtonTitle = t('NOTE_CARD_UNLINK_BUTTON_DEFAULT'),
    unlinkError,
    ...otherProps
  } = props;

  // Destructure inspection
  const thirdPartyInspectors = useMemo(() => {
    return inspectionAsset.inspectionOrganisationList.filter((o) => o.id !== inspectionAsset.organisationId);
  }, [inspectionAsset.inspectionOrganisationList, inspectionAsset.organisationId]);
  const inspectionTestsAssets = inspectionAsset.inspectionTestAssetList;
  const [, , currentUser] = useAuthentication();
  // Other variables
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const authorisationLevel = useAuthorisationLevel(inspectionAsset);
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const organisation = currentUser?.organisationList.find((o) => o.id === activeOrganisationId);

  const isManager =
    authorisationForResource(currentUser, activeOrganisationId, organisation) === AUTHORISATION_LEVEL.MANAGER ||
    authorisationForResource(currentUser, activeOrganisationId, organisation) === AUTHORISATION_LEVEL.OWNER;

  const hasPermissionToEdit =
    isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER) ||
    (inspectionAsset.assetAccessPermission == AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION && isManager);

  // State

  const [searchInspectionPartnerString, setSearchInspectionPartnerString] = useState('');
  const [searchInspectionPartnersFocused, setSearchInspectionPartnersFocused] = useState(false);
  const [searchTestsFocused, setSearchTestsFocused] = useState(false);
  const [searchTestsString, setSearchTestsString] = useState('');
  const [testError, setTestError] = useState(null);
  const [testPopupTestRefId, setTestPopupTestRefId] = useState(null);
  const [thirdPartyInspectorsError, setThirdPartyInspectorsError] = useState(null);

  // Refs

  const searchOrganisationsInputRef = useRef();

  /* API */

  const addThirdPartyInspectorAPI = useAddThirdPartyInspectorAPI();
  const [createInspectionTestAPI, createInspectionTestAPIResult] = useCreateInspectionTestAssetAPI();
  const [linkInspectionTestAPI, linkInspectionTestAPIResult] = useLinkInspectionTestAssetAPI();
  const removeThirdPartyInspectorAPI = useRemoveThirdPartyInspectorAPI();
  const [searchOrganisationAPI, searchOrganisationAPIResult] = useLazyQuery(searchQuery);
  const [searchTestAPI, searchTestAPIResult] = useLazyQuery(searchQuery);
  const unlinkInspectionTestAssetAPI = useUnlinkInspectionTestAssetAPI();
  const updateOrderOfInspectionTestAssetAPI = useUpdateOrderOfInspectionTestAssetAPI();

  const debouncedSearchInspectors = useMemo(
    () =>
      debounce((searchString) => {
        searchOrganisationAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'ORGANISATION',
                ignoreIdList: [...inspectionAsset.inspectionOrganisationList.map((o) => o.id)],
              },
            ],
            searchPaginationDataByDataType: [
              { dataType: 'ORGANISATION', searchPaginationData: { pageSize: 4, skip: 0 } },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [inspectionAsset.inspectionOrganisationList, searchOrganisationAPI]
  );

  const debouncedSearchTests = useMemo(
    () =>
      debounce((searchString) => {
        searchTestAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'TEST_ASSET',
                ignoreIdList: inspectionTestsAssets.map((it) => it.testAsset.id),
              },
            ],
            searchPaginationDataByDataType: [
              { dataType: 'TEST_ASSET', searchPaginationData: { pageSize: 4, skip: 0 } },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [inspectionTestsAssets, searchTestAPI]
  );

  /* Methods */

  const createTest = useCallback(() => {
    createInspectionTestAPI(inspectionAsset.id).then((result) => {
      if (result.data?.inspectionTestAsset) {
        setTestPopupTestRefId(result.data.inspectionTestAsset.testAsset.REFID);
      }
    });
  }, [createInspectionTestAPI, inspectionAsset?.id]);

  // Event handlers

  const onBlurSearchInspectionPartners = useCallback(
    (e) => {
      setSearchInspectionPartnersFocused(false);

      if (typeof onBlurSearchInspectionPartnersProp === 'function') {
        onBlurSearchInspectionPartnersProp(e);
      }
    },
    [onBlurSearchInspectionPartnersProp]
  );

  const onClickedAddThirdPartyInspector = (organisationId) => {
    setThirdPartyInspectorsError(null);
    setSearchInspectionPartnerString('');
    addThirdPartyInspectorAPI(inspectionAsset.id, organisationId).catch((e) => {
      okerror('Error adding third party inspector.', e);
      setThirdPartyInspectorsError(t('ERROR_GENERIC'));
    });
  };

  const onClickedRemoveThirdPartyInspector = (organisationId) => {
    setThirdPartyInspectorsError(null);
    removeThirdPartyInspectorAPI(inspectionAsset.id, organisationId).catch((e) => {
      okerror('error removing third party inspector', e);
      setThirdPartyInspectorsError(t('ERROR_GENERIC'));
    });
  };

  const onClickLinkTest = (testAssetId) => {
    setSearchTestsString('');
    setTestError(null);
    linkInspectionTestAPI(inspectionAsset.id, testAssetId).catch(() => {
      setTestError(t('ERROR_GENERIC'));
    });
  };

  const onFocusSearchInspectionPartners = useCallback(
    (e) => {
      setSearchInspectionPartnersFocused(true);

      if (typeof onFocusSearchInspectionPartnersProp === 'function') {
        onFocusSearchInspectionPartnersProp(e);
      }
    },
    [onFocusSearchInspectionPartnersProp]
  );

  const onPublishToggled = (newPublishedValue) => {
    if (onChangePublish) {
      onChangePublish(inspectionAsset.id, newPublishedValue);
    }
  };

  const onReorderClicked = (direction) => {
    if (onClickReorder) {
      onClickReorder(inspectionAsset.id, direction);
    }
  };

  const onReorderedTest = (_, testAssetId, newIndex) => {
    setTestError(null);
    updateOrderOfInspectionTestAssetAPI(inspectionAsset.id, testAssetId, newIndex + 1).catch(() => {
      setTestError(t('ERROR_GENERIC'));
    });
  };

  const onSearchInspectionPartner = (e) => {
    const searchTerm = e.target.value;
    setSearchInspectionPartnerString(searchTerm);

    if (searchTerm.length > 1) {
      debouncedSearchInspectors(searchTerm);
    }
  };

  const onSearchTests = (e) => {
    const searchString = e.target.value;
    setSearchTestsString(searchString);

    if (searchString.length > 1) {
      debouncedSearchTests(searchString);
    }
  };

  const onUnlinkClicked = () => {
    if (onClickUnlink) {
      onClickUnlink(inspectionAsset.id);
    }
  };

  const onUnlinkTest = (testAssetId) => {
    setTestError(null);
    unlinkInspectionTestAssetAPI(inspectionAsset.id, testAssetId).catch(() => {
      setTestError(t('ERROR_GENERIC'));
    });
  };

  const showOrganisationOKIDScanner = useCallback(() => {
    dispatch(
      showScannerAction((okid) => {
        // Not sure why timeout is required to make this work
        setTimeout(() => {
          setNativeValue(searchOrganisationsInputRef.current, okid);
          searchOrganisationsInputRef.current.focus();
        }, 0);
      })
    );
  }, [dispatch]);

  /* Render */

  const disableCreatingTest = createInspectionTestAPIResult.loading;

  let classNames = '';
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let reorderView;
  if (showReorder) {
    const showLeft = showReorder === 'left' || showReorder === 'both';
    const showRight = showReorder === 'right' || showReorder === 'both';
    reorderView = (
      <div className={styles.reorderView}>
        <Button
          className={`${styles.reorderButton} ${styles.left} ${showLeft ? '' : styles.hide}`}
          icon='/icons/reorder_blue.svg'
          iconPosition='left'
          linkStyle
          onClick={() => onReorderClicked('left')}
        >
          {t('MEDIA_GALLERY_REORDER_LEFT')}
        </Button>
        <p className={styles.reorderLabel}>{t('MEDIA_GALLERY_REORDER')}</p>
        <Button
          className={`${styles.reorderButton} ${styles.right} ${showRight ? '' : styles.hide}`}
          icon='/icons/reorder_blue.svg'
          linkStyle
          onClick={() => onReorderClicked('right')}
        >
          {t('MEDIA_GALLERY_REORDER_RIGHT')}
        </Button>
      </div>
    );
  }

  // Test search results
  const testSearchSuggestions = useMemo(() => {
    if (searchTestsFocused && searchTestsString.length > 1) {
      return (
        searchTestAPIResult.data?.search?.resultList?.map((r) => {
          const testAsset = r.testAssetData;
          return {
            icon: ICONS.TEST.name,
            key: testAsset.id,
            title: t('STEP'),
            subtitle: testAsset.REFID,
          };
        }) ?? []
      );
    }

    return [];
  }, [searchTestAPIResult.data?.search?.resultList, searchTestsFocused, searchTestsString.length, t]);

  // 3rd-party inspector search results
  const inspectorSearchSuggestions = useMemo(() => {
    if (searchInspectionPartnersFocused && searchInspectionPartnerString.length > 1) {
      return (
        searchOrganisationAPIResult.data?.search?.resultList?.map((r) => {
          const organisation = r.organisationData;
          return {
            icon: ICONS.ORGANISATION.name,
            key: organisation.id,
            title: organisation.name,
            subtitle: formatOkid(organisation.OKID),
          };
        }) ?? []
      );
    }

    return [];
  }, [
    searchOrganisationAPIResult.data?.search?.resultList,
    searchInspectionPartnerString.length,
    searchInspectionPartnersFocused,
  ]);

  const name = useMemo(() => {
    return (
      InspectionAssetModel.localizedNameForInspectionAsset(inspectionAsset, locale) || t('PRODUCT_ARCHIVE_CARD_UNNAMED')
    );
  }, [locale, inspectionAsset, t]);

  return (
    <CardLayout accessoryView={reorderView} className={classNames} {...otherProps}>
      <h4 className={styles.header}>{t('WORKFLOW_ARCHIVE_CARD_TITLE')}</h4>
      <Tag className={styles.refId} size='sm'>
        {inspectionAsset.REFID}
      </Tag>
      <Notice className={styles.publishNotice} extendSides>
        <div className={styles.publishRow}>
          <p className={styles.publishLabel}>
            <strong>{t('PUBLISH')}</strong>
          </p>
          <Toggle checked={published} className={styles.publishedToggle} onChange={onPublishToggled} />
        </div>
        {publishError && (
          <Text className={styles.publishError} size='sm' tint='notification'>
            {publishError}
          </Text>
        )}
        <Text bold className={styles.pointsHeader} size='sm'>
          {t('RELIABILITY_GRADE')}&nbsp;&nbsp;
          <Icon inline name={ICONS.RELIABILITY_GRADE.name} />{' '}
          <span className={styles.points}>{formatPoints(inspectionAsset.reliabilityPointForPublishedLogs ?? 0)}</span>
        </Text>
      </Notice>
      <Text className={styles.nameHeader} bold>
        {t('NAME_SINGLE')}
      </Text>
      <Text>{name}</Text>
      <div className={styles.section}>
        <Progressable inProgress={linkInspectionTestAPIResult.loading}>
          <Text bold>{t('STEPS_OPTIONAL_PLURAL')}</Text>
          {!inspectionTestsAssets.length && <Text>{t('WORKFLOW_SEARCH_OR_CREATE_STEP')}</Text>}
          <DropZone onDrop={onReorderedTest} id={inspectionAsset.id}>
            {inspectionTestsAssets.map((it) => {
              return (
                <Draggable className={styles.testContainer} key={it.testAsset.id} id={it.testAsset.id}>
                  <TestMiniCard className={styles.test} onClickUnlink={onUnlinkTest} testAsset={it.testAsset} />
                </Draggable>
              );
            })}
          </DropZone>
          <div className={styles.searchContainer} style={{ zIndex: 2 }}>
            <SearchInput
              accessoryButton={
                <Button
                  disabled={disableCreatingTest}
                  linkStyle
                  loading={createInspectionTestAPIResult.loading}
                  onClick={createTest}
                  tint='creation'
                >
                  {t('NEW')}
                </Button>
              }
              className={styles.searchInput}
              loading={searchTestAPIResult.loading}
              onBlur={() => setSearchTestsFocused(false)}
              onChange={onSearchTests}
              onFocus={() => setSearchTestsFocused(true)}
              placeholder={t('STEP_SEARCH_PLACEHOLDER')}
              value={searchTestsString}
            />
            <SearchSuggestions
              accessoryViewRender={() => (
                <Button className={styles.searchSuggestionButton} icon='/icons/link_blue.svg' linkStyle>
                  {t('LINK')}
                </Button>
              )}
              className={styles.searchSuggestions}
              highlightTerm={searchTestsString}
              onSuggestionClick={onClickLinkTest}
              showMoreResultsMessage={
                searchTestsFocused &&
                searchTestAPIResult.data?.search?.searchPaginationResultDataByDataType?.TEST_ASSET?.totalResults >
                  testSearchSuggestions.length
              }
              showNoResultsMessage={searchTestsFocused && testSearchSuggestions.length === 0}
              subtitleClassName={styles.refIdStyle}
              suggestions={testSearchSuggestions}
            />
          </div>
          {testError && (
            <Text className={styles.testError} size='sm' tint='notification'>
              {testError}
            </Text>
          )}
        </Progressable>
      </div>
      <div className={styles.section}>
        <Text bold>{t('3RD_PARTY_WORKERS')}</Text>
        {!thirdPartyInspectors.length && <Text>{t('WORKFLOWS_SEARCH_3RD_PARTY_WORKERS')}</Text>}
        {thirdPartyInspectors.length > 0 && (
          <div>
            {thirdPartyInspectors?.map((o) => (
              <div className={styles.organisation} key={o.id}>
                <img
                  alt={t('IMG_ALT_ORGANISATION_LOGO')}
                  className={styles.organisationPhoto}
                  src={o.logoImageMediaAsset?.logoImageURL || `/img/empty_media_${theme.name}.svg`}
                />
                <Text bold className={styles.organisationName} size='sm'>
                  {o.name}{' '}
                  {o.addressList?.length > 0 && (
                    <Tag className={styles.organisationCountryCode}>{o.addressList[0].countryCode}</Tag>
                  )}
                </Text>
                <Button
                  className={styles.revokeButton}
                  linkStyle
                  onClick={() => onClickedRemoveThirdPartyInspector(o.id)}
                  tint='alert'
                >
                  {t('REVOKE_AUTHORISATION')}
                </Button>
              </div>
            ))}
          </div>
        )}
        <div className={styles.searchContainer} style={{ zIndex: 1 }}>
          <Input
            accessoryButton={
              <Button
                className={styles.inputAccessoryButton}
                icon={ICONS.CAMERA.name}
                linkStyle
                onClick={showOrganisationOKIDScanner}
              />
            }
            className={styles.searchInput}
            // loading={searchOrganisationAPIResult.loading}
            onBlur={onBlurSearchInspectionPartners}
            onChange={onSearchInspectionPartner}
            onFocus={onFocusSearchInspectionPartners}
            placeholder={t('ADD_ORGANISATION')}
            ref={searchOrganisationsInputRef}
            value={searchInspectionPartnerString}
            warning={thirdPartyInspectorsError}
          />
          <SearchSuggestions
            accessoryViewRender={() => (
              <Button className={styles.searchSuggestionButton} icon='/icons/plus_green.svg' linkStyle tint='creation'>
                {t('ADD')}
              </Button>
            )}
            className={styles.searchSuggestions}
            highlightTerm={searchInspectionPartnerString}
            onSuggestionClick={onClickedAddThirdPartyInspector}
            showMoreResultsMessage={
              searchInspectionPartnersFocused &&
              searchOrganisationAPIResult.data?.search?.searchPaginationResultDataByDataType?.ORGANISATION
                ?.totalResults > inspectorSearchSuggestions.length
            }
            showNoResultsMessage={
              searchInspectionPartnersFocused &&
              searchInspectionPartnerString.length > 1 &&
              inspectorSearchSuggestions.length === 0
            }
            subtitleClassName={styles.refIdStyle}
            suggestions={inspectorSearchSuggestions}
          />
        </div>
      </div>
      <div className={styles.section}>
        <Button
          block
          className={styles.unlinkButton}
          icon='/icons/unlink_dark.svg'
          onClick={onUnlinkClicked}
          tint='alert'
        >
          {unlinkButtonTitle}
        </Button>
        <div className={styles.links}>
          {hasPermissionToEdit && (
            <Link iconPosition='right' linkStyle href={`/archive/workflow/${inspectionAsset.REFID}/edit`} withCaret>
              {t('WORKFLOW_ARCHIVE_CARD_EDIT_WORKFLOW')}
            </Link>
          )}
        </div>
        {unlinkError && (
          <Text className={styles.unlinkError} size='sm' tint='notification'>
            {unlinkError}
          </Text>
        )}
      </div>
      {testPopupTestRefId && <TestPopup dismiss={() => setTestPopupTestRefId(null)} testRefId={testPopupTestRefId} />}
    </CardLayout>
  );
}

InspectionEditCard.propTypes = {
  className: PropTypes.string,
  inspectionAsset: PropTypes.any,
  hasPermissionToEdit: PropTypes.bool,
  onBlurSearchInspectionPartnersProp: PropTypes.func,
  onChangePublish: PropTypes.func,
  onClickReorder: PropTypes.func,
  onClickUnlink: PropTypes.func,
  onFocusSearchInspectionPartnersProp: PropTypes.func,
  published: PropTypes.bool,
  publishError: PropTypes.string,
  showReorder: PropTypes.oneOf(['left', 'right', 'both']),
  unlinkButtonTitle: PropTypes.string,
  unlinkError: PropTypes.string,
};
