import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  indexOfLinkedAssetInCacheList,
  reorderAssetBackwardInList,
  reorderAssetForwardInList,
} from '../graphql/helpers';

import {
  addPartMutation,
  linkSiteDocumentMutation,
  linkSiteInspectionMutation,
  linkSiteMediaMutation,
  linkSiteNoteMutation,
  publishSiteDocumentMutation,
  publishSiteInspectionMutation,
  publishSiteNoteMutation,
  removePartMutation,
  reorderSiteInspectionBackwardMutation,
  reorderSiteInspectionForwardMutation,
  unlinkSiteDocumentMutation,
  unlinkSiteInspectionMutation,
  unlinkSiteMediaMutation,
  unlinkSiteNoteMutation,
  unpublishSiteDocumentMutation,
  unpublishSiteInspectionMutation,
  unpublishSiteNoteMutation,
} from '.';

import BaseDocumentModel from 'OK/models/document/base';
import BaseInspectionAssetModel from 'OK/models/inspectionAsset/base';
import MediaAssetModel from 'OK/models/mediaAsset';
import BaseMediaAssetModel from 'OK/models/mediaAsset/base';
import BaseNoteModel from 'OK/models/note/base';
import SiteModel from 'OK/models/site';
import SiteNoteModel from 'OK/models/siteNote';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';

/* Hooks */

export function useLinkSiteDocumentAPI() {
  const [linkSiteDocumentAPI] = useMutation(linkSiteDocumentMutation);

  const linkSiteDocument = useCallback(
    (siteId, documentId) => {
      return linkSiteDocumentAPI({
        variables: {
          siteId,
          documentId,
        },
        update: (cache, result) => {
          if (result.data?.siteDocument) {
            // Write DocumentAsset to cache
            const documentAssetRef = cache.writeFragment({
              id: `${BaseDocumentModel.GRAPHQL_TYPE}:${result.data.siteDocument.documentAsset.id}`,
              fragment: BaseDocumentModel.fragment,
              fragmentName: BaseDocumentModel.fragmentName,
              data: result.data.siteDocument.documentAsset,
            });

            // Add SiteDocumentAsset to site's list
            cache.modify({
              id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
              fields: {
                siteDocumentAssetList: (currentList) => {
                  const siteDocumentAsset = {
                    ...result.data.siteDocument,
                    documentAsset: documentAssetRef,
                  };
                  return [...currentList, siteDocumentAsset];
                },
              },
            });
          }
        },
      });
    },
    [linkSiteDocumentAPI]
  );

  return linkSiteDocument;
}

export function useLinkSiteInspectionAPI() {
  const [linkSiteInspectionAPI, linkSiteInspectionAPIResult] = useMutation(linkSiteInspectionMutation);

  const linkSiteInspection = useCallback(
    (siteId, inspectionAssetId) => {
      return linkSiteInspectionAPI({
        variables: {
          siteId,
          inspectionAssetId,
        },
        update: (cache, result) => {
          if (result.data?.siteInspectionAsset) {
            // Write InspectionAsset to cache
            const inspectionAssetRef = cache.writeFragment({
              id: `${BaseInspectionAssetModel.GRAPHQL_TYPE}:${result.data.siteInspectionAsset.inspectionAsset.id}`,
              fragment: BaseInspectionAssetModel.fragment,
              fragmentName: BaseInspectionAssetModel.fragmentName,
              data: result.data.siteInspectionAsset.inspectionAsset,
            });

            // Add SiteInspectionAsset to site's list
            cache.modify({
              id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
              fields: {
                siteInspectionAssetList: (currentList) => {
                  const siteInspectionAsset = {
                    ...result.data.siteInspectionAsset,
                    inspectionAsset: inspectionAssetRef,
                  };
                  return [...currentList, siteInspectionAsset];
                },
              },
            });
          }
        },
      });
    },
    [linkSiteInspectionAPI]
  );

  return [linkSiteInspection, linkSiteInspectionAPIResult];
}

export function useLinkSiteMediaAPI() {
  const [linkSiteMediaAPI] = useMutation(linkSiteMediaMutation);

  const linkSiteMedia = useCallback(
    (siteId, mediaAssetId) => {
      return linkSiteMediaAPI({
        variables: {
          siteId,
          mediaAssetId,
        },
        update: (cache, result) => {
          if (result.data?.siteMedia) {
            // Write MediaAsset to cache
            const mediaAssetRef = cache.writeFragment({
              id: `${MediaAssetModel.GRAPHQL_TYPE}:${result.data.siteMedia.mediaAsset.id}`,
              fragment: BaseMediaAssetModel.fragment,
              fragmentName: BaseMediaAssetModel.fragmentName,
              data: result.data.siteMedia.mediaAsset,
            });

            // Add SiteMediaAsset to site's list
            cache.modify({
              id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
              fields: {
                siteMediaAssetList: (currentList) => {
                  const siteMediaAsset = {
                    ...result.data.siteMedia,
                    mediaAsset: mediaAssetRef,
                  };
                  return [...currentList, siteMediaAsset];
                },
              },
            });
          }
        },
      });
    },
    [linkSiteMediaAPI]
  );

  return linkSiteMedia;
}

export function useLinkSiteNoteAPI() {
  const apolloClient = useApolloClient();
  const [linkSiteNoteAPI] = useMutation(linkSiteNoteMutation);

  const linkSiteNote = useCallback(
    (siteId, noteAssetId) => {
      const noteAsset = apolloClient.readFragment({
        id: `${BaseNoteModel.GRAPHQL_TYPE}:${noteAssetId}`,
        fragment: BaseNoteModel.fragment,
        fragmentName: BaseNoteModel.fragmentName,
      });
      let optimisticResponse;
      if (noteAsset) {
        optimisticResponse = {
          siteNote: {
            noteAsset,
            publishStatus: PUBLISH_STATUS.UNPUBLISHED,
            __typename: SiteNoteModel.GRAPHQL_TYPE,
          },
        };
      }

      return linkSiteNoteAPI({
        optimisticResponse,
        variables: {
          siteId,
          noteAssetId,
        },
        update: (cache, result) => {
          if (result.data?.siteNote) {
            // Write NoteAsset to cache
            const noteAssetRef = cache.writeFragment({
              id: `${BaseNoteModel.GRAPHQL_TYPE}:${result.data.siteNote.noteAsset.id}`,
              fragment: BaseNoteModel.fragment,
              fragmentName: BaseNoteModel.fragmentName,
              data: result.data.siteNote.noteAsset,
            });

            // Add SiteNoteAsset to site's list
            cache.modify({
              id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
              fields: {
                siteNoteAssetList: (currentList) => {
                  const siteNoteAsset = {
                    ...result.data.siteNote,
                    noteAsset: noteAssetRef,
                  };
                  return [...currentList, siteNoteAsset];
                },
              },
            });
          }
        },
      });
    },
    [apolloClient, linkSiteNoteAPI]
  );

  return linkSiteNote;
}

export function useLinkPartAPI() {
  const [linkPartAPI, linkPartAPIResult] = useMutation(addPartMutation);

  const linkPart = useCallback(
    (siteId, partSiteId) => {
      return linkPartAPI({
        variables: {
          siteId,
          partSiteId,
        },
        update: (cache, result) => {
          cache.modify({
            id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
            fields: {
              siteChildSiteList: (currentList) => {
                return [...currentList, result.data.part];
              },
            },
          });
        },
      });
    },
    [linkPartAPI]
  );

  return [linkPart, linkPartAPIResult];
}

export function usePublishSiteDocumentAPI(mutationOptions = {}) {
  const [publishDocumentAPI] = useMutation(publishSiteDocumentMutation, mutationOptions);

  const publishDocument = useCallback(
    (site, documentId) => {
      // Optimistic response
      const siteDocument = site.siteDocumentAssetList.find((pd) => pd.documentAsset.id === documentId);
      const optimisticResponse = {
        siteDocument: {
          ...siteDocument,
          publishStatus: PUBLISH_STATUS.PUBLISHED,
        },
      };

      // Call API
      return publishDocumentAPI({
        variables: {
          documentId,
          siteId: site.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.siteDocument) {
            cache.modify({
              id: `${SiteModel.GRAPHQL_TYPE}:${site.id}`,
              fields: {
                siteDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfSiteDocument = indexOfLinkedAssetInCacheList(
                    currentList,
                    result.data.siteDocument.documentAsset.id,
                    'documentAsset',
                    readField
                  );
                  const currentSiteDocument = updatedList[indexOfSiteDocument];
                  const updatedSiteDocument = {
                    ...currentSiteDocument,
                    publishStatus: result.data.siteDocument.publishStatus,
                  };
                  updatedList.splice(indexOfSiteDocument, 1, updatedSiteDocument);
                  return updatedList;
                },
              },
            });
          } else {
            okerror('Publish document api did not respond with site document.');
          }
        },
      });
    },
    [publishDocumentAPI]
  );

  return publishDocument;
}

export function usePublishSiteInspectionAPI() {
  const apolloClient = useApolloClient();
  const [publishSiteInspectionAPI] = useMutation(publishSiteInspectionMutation);

  const publishSiteInspection = useCallback(
    (siteId, inspectionAssetId) => {
      const siteCacheId = `${SiteModel.GRAPHQL_TYPE}:${siteId}`;

      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const site = apolloClient.readFragment({
        id: siteCacheId,
        fragment: SiteModel.fragmentEdit,
        fragmentName: SiteModel.fragmentEditName,
      });
      const siteInspectionAsset = site?.siteInspectionAssetList?.find(
        (pia) => pia.inspectionAsset.id === inspectionAssetId
      );
      if (siteInspectionAsset) {
        optimisticResponse = {
          siteInspection: {
            ...siteInspectionAsset,
            publishStatus: PUBLISH_STATUS.PUBLISHED,
          },
        };
      }

      // Call API
      return publishSiteInspectionAPI({
        variables: {
          siteId,
          inspectionAssetId,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: siteCacheId,
            fields: {
              siteInspectionAssetList: (currentList, { readField }) => {
                const indexOfSiteInspection = indexOfLinkedAssetInCacheList(
                  currentList,
                  result.data.siteInspection.inspectionAsset.id,
                  'inspectionAsset',
                  readField
                );
                const updatedSiteInspection = {
                  ...currentList[indexOfSiteInspection],
                  publishStatus: result.data.siteInspection.publishStatus,
                };
                const updatedList = [...currentList];
                updatedList.splice(indexOfSiteInspection, 1, updatedSiteInspection);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, publishSiteInspectionAPI]
  );

  return publishSiteInspection;
}

export function usePublishSiteNoteAPI() {
  const [publishSiteNoteAPI] = useMutation(publishSiteNoteMutation);

  const publishSiteNote = useCallback(
    (site, noteId) => {
      // Optimistic response
      const siteNote = site.siteNoteAssetList.find((pn) => pn.noteAsset.id === noteId);
      const optimisticResponse = {
        siteNote: {
          ...siteNote,
          publishStatus: PUBLISH_STATUS.PUBLISHED,
        },
      };

      // Call API
      return publishSiteNoteAPI({
        variables: {
          noteId,
          siteId: site.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data?.siteNote) {
            cache.modify({
              id: `${SiteModel.GRAPHQL_TYPE}:${site.id}`,
              fields: {
                siteNoteAssetList: (currentList, { readField }) => {
                  const responseSiteNote = result.data.siteNote;
                  const indexOfSiteNote = indexOfLinkedAssetInCacheList(
                    currentList,
                    responseSiteNote.noteAsset.id,
                    'noteAsset',
                    readField
                  );
                  if (indexOfSiteNote > -1) {
                    const siteNote = {
                      ...currentList[indexOfSiteNote],
                      publishStatus: responseSiteNote.publishStatus,
                    };
                    const updatedList = [...currentList];
                    updatedList.splice(indexOfSiteNote, 1, siteNote);
                    return updatedList;
                  }

                  okerror(
                    `Site note ${responseSiteNote.noteAsset.id} cannot be published on site ${site.id} because it is not linked.`
                  );
                },
              },
            });
          } else {
            okwarn('API did not return published site note');
          }
        },
      });
    },
    [publishSiteNoteAPI]
  );

  return publishSiteNote;
}

export function useReorderSiteInspectionBackwardAPI() {
  const apolloClient = useApolloClient();
  const [reorderSiteInspectionBackwardAPI] = useMutation(reorderSiteInspectionBackwardMutation);

  const reorderSiteInspectionBackward = useCallback(
    (siteId, inspectionAssetId) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const site = apolloClient.readFragment({
        id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
        fragment: SiteModel.fragment,
        fragmentName: SiteModel.fragmentName,
      });
      const siteInspectionAsset = site?.siteInspectionAssetList?.find(
        (pia) => pia.inspectionAsset.id === inspectionAssetId
      );
      if (siteInspectionAsset) {
        optimisticResponse = {
          siteInspection: {
            ...siteInspectionAsset,
            order: siteInspectionAsset.order - 1,
          },
        };
      }

      // Call API
      return reorderSiteInspectionBackwardAPI({
        variables: {
          siteId,
          inspectionAssetId,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
            fields: {
              siteInspectionAssetList: (currentList) => {
                return reorderAssetBackwardInList(currentList, result.data.siteInspection);
              },
            },
          });
        },
      });
    },
    [apolloClient, reorderSiteInspectionBackwardAPI]
  );

  return reorderSiteInspectionBackward;
}

export function useReorderSiteInspectionForwardAPI() {
  const apolloClient = useApolloClient();
  const [reorderSiteInspectionForwardAPI] = useMutation(reorderSiteInspectionForwardMutation);

  const reorderSiteInspectionForward = useCallback(
    (siteId, inspectionAssetId) => {
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const site = apolloClient.readFragment({
        id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
        fragment: SiteModel.fragment,
        fragmentName: SiteModel.fragmentName,
      });
      const siteInspectionAsset = site?.siteInspectionAssetList?.find(
        (pia) => pia.inspectionAsset.id === inspectionAssetId
      );
      if (siteInspectionAsset) {
        optimisticResponse = {
          siteInspection: {
            ...siteInspectionAsset,
            order: siteInspectionAsset.order + 1,
          },
        };
      }

      // Call API
      return reorderSiteInspectionForwardAPI({
        variables: {
          siteId,
          inspectionAssetId,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
            fields: {
              siteInspectionAssetList: (currentList) => {
                return reorderAssetForwardInList(currentList, result.data.siteInspection);
              },
            },
          });
        },
      });
    },
    [apolloClient, reorderSiteInspectionForwardAPI]
  );

  return reorderSiteInspectionForward;
}

export function useRemovePartAPI() {
  const apolloClient = useApolloClient();
  const [removePartAPI] = useMutation(removePartMutation);

  const removePart = useCallback(
    (siteId, partSiteId) => {
      const site = apolloClient.readFragment({
        id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
        fragment: SiteModel.fragmentPartsList,
        fragmentName: SiteModel.fragmentNamePartsList,
      });
      const part = site.siteChildSiteList.find((p) => p.childSite.id === partSiteId);
      const optimisticResponse = { part };
      return removePartAPI({
        variables: {
          siteId,
          partSiteId,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
            fields: {
              siteChildSiteList: (currentList, { readField }) => {
                const indexOfPart = indexOfLinkedAssetInCacheList(
                  currentList,
                  result.data.part.childSite.id,
                  'childSite',
                  readField
                );
                const updatedList = [...currentList];
                updatedList.splice(indexOfPart, 1);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, removePartAPI]
  );

  return removePart;
}

export function useUnlinkSiteDocumentAPI() {
  const apolloClient = useApolloClient();
  const [unlinkDocumentAPI] = useMutation(unlinkSiteDocumentMutation);

  const unlinkDocument = useCallback(
    (site, documentId) => {
      // Optimistic response
      const documentAsset = apolloClient.readFragment({
        id: `${BaseDocumentModel.GRAPHQL_TYPE}:${documentId}`,
        fragment: BaseDocumentModel.fragment,
        fragmentName: BaseDocumentModel.fragmentName,
      });
      const siteDocument = site.siteDocumentAssetList.find((pd) => pd.documentAsset.id === documentId);
      const responseSiteDocument = {
        ...siteDocument,
        documentAsset,
      };
      const optimisticResponse = {
        siteDocument: responseSiteDocument,
      };

      // Call API
      return unlinkDocumentAPI({
        variables: {
          documentId,
          siteId: site.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.siteDocument) {
            // Unlink from site's siteDocumentAssetList
            cache.modify({
              id: `${SiteModel.GRAPHQL_TYPE}:${site.id}`,
              fields: {
                siteDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfSiteDocument = indexOfLinkedAssetInCacheList(
                    currentList,
                    result.data.siteDocument.documentAsset.id,
                    'documentAsset',
                    readField
                  );
                  updatedList.splice(indexOfSiteDocument, 1);
                  return updatedList;
                },
              },
            });

            // Unlink from document's linkedSiteList
            cache.modify({
              id: `${BaseDocumentModel.GRAPHQL_TYPE}:${documentId}`,
              fields: {
                linkedSiteList: (currentList, { readField }) => {
                  const indexOfSite = currentList.findIndex((lp) => {
                    const linkedSiteId = readField('id', lp);
                    return linkedSiteId === site.id;
                  });
                  if (indexOfSite > -1) {
                    const updatedList = [...currentList];
                    updatedList.splice(indexOfSite);
                    return updatedList;
                  }

                  return currentList;
                },
              },
            });
          } else {
            okerror('Unlink document api did not respond with site document.');
          }
        },
      });
    },
    [apolloClient, unlinkDocumentAPI]
  );

  return unlinkDocument;
}

export function useUnlinkSiteInspectionAPI() {
  const apolloClient = useApolloClient();
  const [unlinkSiteInspectionAPI, unlinkSiteInspectionAPIResult] = useMutation(unlinkSiteInspectionMutation);

  const unlinkSiteInspection = useCallback(
    (siteId, inspectionAssetId) => {
      const siteCacheId = `${SiteModel.GRAPHQL_TYPE}:${siteId}`;
      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const site = apolloClient.readFragment({
        id: `${SiteModel.GRAPHQL_TYPE}:${siteId}`,
        fragment: SiteModel.fragment,
        fragmentName: SiteModel.fragmentName,
      });
      const siteInspectionAsset = site?.siteInspectionAssetList?.find(
        (pia) => pia.inspectionAsset.id === inspectionAssetId
      );
      if (siteInspectionAsset) {
        optimisticResponse = {
          siteInspection: siteInspectionAsset,
        };
      }

      // Call API
      return unlinkSiteInspectionAPI({
        variables: {
          siteId,
          inspectionAssetId,
        },
        optimisticResponse,
        update: (cache) => {
          cache.modify({
            id: siteCacheId,
            fields: {
              siteInspectionAssetList: (currentList, { readField }) => {
                const indexOfSiteInspection = indexOfLinkedAssetInCacheList(
                  currentList,
                  inspectionAssetId,
                  'inspectionAsset',
                  readField
                );
                const updatedList = [...currentList];
                updatedList.splice(indexOfSiteInspection, 1);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, unlinkSiteInspectionAPI]
  );

  return [unlinkSiteInspection, unlinkSiteInspectionAPIResult];
}

export function useUnlinkSiteMediaAPI(mutationOptions) {
  const apolloClient = useApolloClient();
  const [unlinkSiteMediaAPI] = useMutation(unlinkSiteMediaMutation, mutationOptions);

  const unlinkSiteMedia = useCallback(
    (site, mediaAssetId) => {
      // Optimistic response
      const mediaAsset = apolloClient.readFragment({
        id: `${MediaAssetModel.GRAPHQL_TYPE}:${mediaAssetId}`,
        fragment: BaseMediaAssetModel.fragment,
        fragmentName: BaseMediaAssetModel.fragmentName,
      });
      const siteMedia = site.siteMediaAssetList.find((sm) => sm.mediaAsset.id === mediaAssetId);
      const responseSiteMedia = {
        ...siteMedia,
        mediaAsset: {
          ...mediaAsset,
        },
      };

      // Also update linked sites list if present.
      // This is necessary when unlinking from the Media archive page.
      const mediaAssetWithLinkedSites = apolloClient.readFragment({
        id: `${MediaAssetModel.GRAPHQL_TYPE}:${mediaAssetId}`,
        fragment: MediaAssetModel.fragment,
        fragmentName: MediaAssetModel.fragmentName,
      });
      if (mediaAssetWithLinkedSites) {
        const indexOfLinkedSite = mediaAssetWithLinkedSites.linkedSiteList.findIndex((s) => s.id === site.id);
        const updatedLinkedSiteList = [...mediaAssetWithLinkedSites.linkedSiteList];
        updatedLinkedSiteList.splice(indexOfLinkedSite, 1);
        responseSiteMedia.mediaAsset.linkedSiteList = updatedLinkedSiteList;
      }

      const optimisticResponse = {
        siteMedia: responseSiteMedia,
      };

      // Call API
      return unlinkSiteMediaAPI({
        variables: {
          mediaAssetId,
          siteId: site.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          const unlinkedSiteMedia = result.data.siteMedia;
          cache.modify({
            id: `${SiteModel.GRAPHQL_TYPE}:${site.id}`,
            fields: {
              siteMediaAssetList: (currentList, { readField }) => {
                const indexOfUnlinkedMedia = currentList.findIndex((sma) => {
                  const mediaAsset = readField('mediaAsset', sma);
                  const mediaAssetId = readField('id', mediaAsset);
                  return mediaAssetId === unlinkedSiteMedia.mediaAsset.id;
                });
                const updatedList = [...currentList];
                updatedList.splice(indexOfUnlinkedMedia, 1);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, unlinkSiteMediaAPI]
  );

  return unlinkSiteMedia;
}

export function useUnlinkSiteNoteAPI() {
  const [unlinkSiteNoteAPI] = useMutation(unlinkSiteNoteMutation);

  const unlinkSiteNote = useCallback(
    (site, noteId) => {
      // Optimistic response
      const siteNote = site.siteNoteAssetList.find((pn) => pn.noteAsset.id === noteId);
      const optimisticResponse = {
        siteNote,
      };

      // Call API
      return unlinkSiteNoteAPI({
        variables: {
          noteId,
          siteId: site.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data?.siteNote) {
            // Remove from site list
            cache.modify({
              id: `${SiteModel.GRAPHQL_TYPE}:${site.id}`,
              fields: {
                siteNoteAssetList: (currentList, { readField }) => {
                  const { siteNote } = result.data;
                  const indexOfSiteNote = indexOfLinkedAssetInCacheList(
                    siteNote.noteAsset.id,
                    currentList,
                    'noteAsset',
                    readField
                  );
                  if (indexOfSiteNote > -1) {
                    const updatedList = [...currentList];
                    updatedList.splice(indexOfSiteNote, 1);
                    return updatedList;
                  }

                  okerror(
                    `Site note ${siteNote.noteAsset.id} cannot be unlinked from site ${site.id} because it is not linked.`
                  );
                },
              },
            });
          } else {
            okwarn('API did not return unlinked site note');
          }
        },
      });
    },
    [unlinkSiteNoteAPI]
  );

  return unlinkSiteNote;
}

export function useUnpublishSiteDocumentAPI(mutationOptions) {
  const [unpublishDocumentAPI] = useMutation(unpublishSiteDocumentMutation, mutationOptions);

  const unpublishDocument = useCallback(
    (site, documentId) => {
      // Optimistic response
      const siteDocument = site.siteDocumentAssetList.find((pd) => pd.documentAsset.id === documentId);
      const optimisticResponse = {
        siteDocument: {
          ...siteDocument,
          publishStatus: PUBLISH_STATUS.UNPUBLISHED,
        },
      };

      // Call API
      return unpublishDocumentAPI({
        variables: {
          documentId,
          siteId: site.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.siteDocument) {
            cache.modify({
              id: `${SiteModel.GRAPHQL_TYPE}:${site.id}`,
              fields: {
                siteDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfSiteDocument = indexOfLinkedAssetInCacheList(
                    currentList,
                    result.data.siteDocument.documentAsset.id,
                    'documentAsset',
                    readField
                  );
                  const updatedSiteDocument = {
                    ...updatedList[indexOfSiteDocument],
                    publishStatus: result.data.siteDocument.publishStatus,
                  };
                  updatedList.splice(indexOfSiteDocument, 1, updatedSiteDocument);
                  return updatedList;
                },
              },
            });
          } else {
            okerror('Unpublish document api did not respond with site document.');
          }
        },
      });
    },
    [unpublishDocumentAPI]
  );

  return unpublishDocument;
}

export function useUnpublishSiteInspectionAPI() {
  const apolloClient = useApolloClient();
  const [unpublishSiteInspectionAPI] = useMutation(unpublishSiteInspectionMutation);

  const unpublishSiteInspection = useCallback(
    (siteId, inspectionAssetId) => {
      const siteCacheId = `${SiteModel.GRAPHQL_TYPE}:${siteId}`;

      // Generate optimistic response if sufficient data in the cache to do so
      let optimisticResponse;
      const site = apolloClient.readFragment({
        id: siteCacheId,
        fragment: SiteModel.fragmentEdit,
        fragmentName: SiteModel.fragmentEditName,
      });
      const siteInspectionAsset = site?.siteInspectionAssetList?.find(
        (pia) => pia.inspectionAsset.id === inspectionAssetId
      );
      if (siteInspectionAsset) {
        optimisticResponse = {
          siteInspection: {
            ...siteInspectionAsset,
            publishStatus: PUBLISH_STATUS.UNPUBLISHED,
          },
        };
      }

      // Call API
      return unpublishSiteInspectionAPI({
        variables: {
          siteId,
          inspectionAssetId,
        },
        optimisticResponse,
        update: (cache, result) => {
          cache.modify({
            id: siteCacheId,
            fields: {
              siteInspectionAssetList: (currentList, { readField }) => {
                const indexOfSiteInspection = indexOfLinkedAssetInCacheList(
                  currentList,
                  result.data.siteInspection.inspectionAsset.id,
                  'inspectionAsset',
                  readField
                );
                const updatedSiteInspection = {
                  ...currentList[indexOfSiteInspection],
                  publishStatus: result.data.siteInspection.publishStatus,
                };
                const updatedList = [...currentList];
                updatedList.splice(indexOfSiteInspection, 1, updatedSiteInspection);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [apolloClient, unpublishSiteInspectionAPI]
  );

  return unpublishSiteInspection;
}

export function useUnpublishSiteNoteAPI() {
  const [unpublishSiteNoteAPI] = useMutation(unpublishSiteNoteMutation);

  const unpublishSiteNote = useCallback(
    (site, noteId) => {
      // Optimistic response
      const siteNote = site.siteNoteAssetList.find((pn) => pn.noteAsset.id === noteId);
      const optimisticResponse = {
        siteNote: {
          ...siteNote,
          publishStatus: PUBLISH_STATUS.UNPUBLISHED,
        },
      };

      // Call API
      return unpublishSiteNoteAPI({
        variables: {
          noteId,
          siteId: site.id,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data?.siteNote) {
            cache.modify({
              id: `${SiteModel.GRAPHQL_TYPE}:${site.id}`,
              fields: {
                siteNoteAssetList: (currentList, { readField }) => {
                  const responseSiteNote = result.data.siteNote;
                  const indexOfSiteNote = indexOfLinkedAssetInCacheList(
                    currentList,
                    responseSiteNote.noteAsset.id,
                    'noteAsset',
                    readField
                  );
                  if (indexOfSiteNote > -1) {
                    const siteNote = {
                      ...currentList[indexOfSiteNote],
                      publishStatus: responseSiteNote.publishStatus,
                    };
                    const updatedList = [...currentList];
                    updatedList.splice(indexOfSiteNote, 1, siteNote);
                    return updatedList;
                  }

                  okerror(
                    `Site note ${responseSiteNote.noteAsset.id} cannot be published on site ${site.id} because it is not linked.`
                  );
                },
              },
            });
          } else {
            okwarn('API did not return published site note');
          }
        },
      });
    },
    [unpublishSiteNoteAPI]
  );

  return unpublishSiteNote;
}
