import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import CardLayout from 'OK/components/layouts/card';
import LinkToFile from 'OK/components/link/linkToFile';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import isAuthorised, { isAuthorisedViaShare } from 'OK/util/functions/isAuthorised';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useDocumentType from 'OK/util/hooks/useDocumentType';
import useI18n from 'OK/util/hooks/useI18n';

export default function DocumentMinimalCard(props) {
  const {
    containerClassName,
    linkContainerClassName,
    document,
    showAsCard = false,
    showDownloadButton = false,
    size = 'md',
    ...otherProps
  } = props;
  const { coveredLanguageList } = document;
  const { t } = useI18n();
  const documentType = useDocumentType(document);
  const hasSharedPermissions = isAuthorisedViaShare(
    document.assetAccessPermission,
    AssetAccessPermission.SHARED_WITH_READ_PERMISSION
  );
  const authorisationLevel = useAuthorisationLevel(document);
  const canViewDocumentPage = hasSharedPermissions || isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.COWORKER);

  const minimalUI = (
    <div {...otherProps}>
      {showDownloadButton && !canViewDocumentPage && (
        <LinkToFile className={styles.downloadButton} file={document} linkToDownload>
          <Icon name={ICONS.DOWNLOAD.name} />
        </LinkToFile>
      )}
      <Text bold size={size} tint='navigation'>
        {documentType}
      </Text>
      <div className={styles.languageTags}>
        {coveredLanguageList.length ? (
          coveredLanguageList.map((languageIso) => (
            <Tag className={styles.languageTag} key={languageIso} size='xs'>
              {languageIso}
            </Tag>
          ))
        ) : (
          <Tag className={`${styles.languageTag} ${styles.unilingual}`} size='xs'>
            {t('UNILINGUAL')}
          </Tag>
        )}
      </div>
    </div>
  );

  if (showAsCard) {
    const cardUI = (
      <CardLayout className={containerClassName} fixedWidth={false}>
        {minimalUI}
      </CardLayout>
    );

    if (showDownloadButton || canViewDocumentPage) {
      return (
        <LinkToFile
          className={linkContainerClassName}
          key={document.id}
          file={document}
          linkToDownload={!canViewDocumentPage}
        >
          {cardUI}
        </LinkToFile>
      );
    }

    return cardUI;
  }

  return minimalUI;
}

DocumentMinimalCard.propTypes = {
  containerClassName: PropTypes.string,
  document: PropTypes.object.isRequired,
  linkContainerClassName: PropTypes.string,
  showAsCard: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
};
