import { gql } from '@apollo/client';

export default class UnversionedTestAssetModel {
  static GRAPHQL_TYPE = 'UnversionedTestAsset';

  static fragmentName = 'UnversionedTestAsset_Fragment';
  static fragment = gql`
    fragment ${UnversionedTestAssetModel.fragmentName} on ${UnversionedTestAssetModel.GRAPHQL_TYPE} {
      enableIdentifiers
      enableNotes
      enableTimeTracking
      howToFindItMap
      howToFixItMap
      requireAllConditionToPass
      testAssetConditionList {
        type
        value
        valueType
      }
      testSeverityLevel
      whatToLookForMap
    }
  `;
}
