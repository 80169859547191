import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Tag from 'OK/components/tag';
import ThemeContext from 'OK/util/context/theme';
import getMatchingValue from 'OK/util/functions/getMatchingValue';
import useI18n from 'OK/util/hooks/useI18n';
import { languageByIso, languages as allLanguages } from 'OK/util/i18n';

/**
 * A component to search languages. Initially shows a button, and when pressed, changes to a search input.
 *
 * @param {object} props
 * @param {string} [props.addLanguageButtonTitle='Add language'] The title for the button.
 * @param {string} [props.className] The component's class.
 * @param {'button'|'select'} [props.defaultRenderMode='button'] Render mode when not searching.
 * @param {string[]} [props.excludeLanguages] Exclude languages from being searchable. Expects an array of language ISO codes.
 * @param {string[]} [props.languages] Languages to search. Expects an array of langauge ISO codes. Default will be all languages.
 * @param {function} [props.onSelectedLanguage] Event handler for when a language is selected.
 * @param {string} [props.selectedLanguage] The currently selected language. Used when `defaultRenderMode` is `'select'`.
 */
export default function LanguageSearch(props) {
  /* Variables */

  const { t, locale: currentLocale } = useI18n();
  const {
    addLanguageButtonTitle = t('LANGUAGE_SEARCH_ADD_LANGUAGE_BUTTON_DEFAULT'),
    className,
    defaultRenderMode = 'button',
    disabled = false,
    excludeLanguages: _excludeLanguages,
    languages: _languages,
    onSelectedLanguage,
    selectedLanguage,
  } = props;

  // Prop defaults
  const excludeLanguages = useMemo(() => _excludeLanguages ?? [], [_excludeLanguages]);
  const langauges = useMemo(() => _languages ?? allLanguages.map((l) => l.iso), [_languages]);

  const accountState = useSelector((state) => state.account);
  const { preferences } = accountState;
  const searchInput = useRef();
  const theme = useContext(ThemeContext);

  /* State */

  const [isFocused, setIsFocused] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchingLanguage, setSearchingLanguage] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  /* Methods */

  const resetSearchState = () => {
    setSearchingLanguage(false);
    setSearchTerm('');
  };

  /* Event handlers */

  const onChoseLanguage = (languageIso) => {
    if (onSelectedLanguage) {
      onSelectedLanguage(languageIso);
    }

    resetSearchState();
  };

  const onSearchedLanguage = (e) => {
    setSearchTerm(e.target.value);
  };

  const onInputFocusIn = () => {
    setIsFocused(true);
  };

  const onInputFocusOut = () => {
    setIsFocused(false);
  };

  /* Effects */

  useEffect(() => {
    // Focus input when starting to search
    if (searchInput.current) {
      searchInput.current.focus();
    }
  }, [searchingLanguage]);

  /* Render */

  // Only update search suggestions based on searchTerm and excludedLanguages
  useMemo(() => {
    // When app language is Swedish (SV), the user can search based on the following;
    // 1) 'es'        - ISO code
    // 2) 'spanska'   - Translated names based on app language
    // 3) 'espanol'   - Native name
    // 4) 'spanish'   - English name

    const lang = preferences.language.toUpperCase();
    const searchRegex = new RegExp(searchTerm, 'i');

    const languagesData = langauges.map((l) => languageByIso(l));
    const filteredLanguages = languagesData
      .filter((l) => !excludeLanguages.includes(l.iso)) // Filter out any languages that should not be included
      .filter((l) => {
        return (
          searchRegex.test(l.iso) ||
          searchRegex.test(t('LANG_' + l.iso.toUpperCase())) ||
          searchRegex.test(l.nativeName) ||
          searchRegex.test(l.name)
        );
      })
      .map((l) => {
        const localizedLangName = t('LANG_' + l.iso.toUpperCase());
        let priority;
        if (lang === l.iso.toUpperCase()) {
          priority = 1;
        } else if (searchTerm.toUpperCase() === l.iso.toUpperCase()) {
          priority = 2;
        } else if (searchRegex.test(localizedLangName)) {
          priority = 3;
        } else if (searchRegex.test(l.nativeName)) {
          priority = 4;
        } else if (searchRegex.test(l.name)) {
          priority = 5;
        } else {
          priority = 6;
        }
        const currentLocaleISO = languageByIso(currentLocale).iso;
        const possibleTitles = {
          [currentLocaleISO]: localizedLangName,
          [l.iso]: l.nativeName,
          ['EN']: l.name,
        };
        return {
          key: l.iso,
          title: getMatchingValue(possibleTitles, searchTerm, currentLocaleISO, true),
          priority,
        };
      })

      .sort((a, b) => {
        // Matched ISO
        if (searchTerm.toUpperCase() === a.key.toUpperCase()) {
          return -1;
        }

        // Sort results by priority and matching title with searchTerm position in name
        if (a.priority > b.priority) {
          return 1;
        } else if (a.priority < b.priority) {
          return -1;
        } else {
          if (
            a.title.toUpperCase().indexOf(searchTerm.toUpperCase()) >
            b.title.toUpperCase().indexOf(searchTerm.toUpperCase())
          ) {
            return 1;
          } else if (
            a.title.toUpperCase().indexOf(searchTerm.toUpperCase()) <
            b.title.toUpperCase().indexOf(searchTerm.toUpperCase())
          ) {
            return -1;
          } else {
            if (a.title > b.title) return 1;
            else return -1;
          }
        }
      });
    setSearchSuggestions(filteredLanguages);
  }, [preferences.language, searchTerm, langauges, excludeLanguages, t, currentLocale]);

  let content;

  if (searchingLanguage) {
    content = (
      <>
        <SearchInput
          className={styles.searchInput}
          disabled={disabled}
          onCancel={resetSearchState}
          onChange={onSearchedLanguage}
          onFocus={onInputFocusIn}
          onBlur={onInputFocusOut}
          placeholder={t('LANGUAGE_SEARCH_PLACEHOLDER_FIND_LANGUAGE')}
          ref={searchInput}
          showCancelOnFocus
          value={searchTerm}
        />
        {isFocused && (
          <div className={styles.searchSuggestionsContainer}>
            <SearchSuggestions
              accessoryViewRender={() => {
                return <img alt={t('IMG_ALT_ADD_LANGUAGE')} src={`/icons/plus_${theme.name}.svg`} />;
              }}
              className={styles.searchSuggestions}
              highlightTerm={searchTerm}
              onSuggestionClick={onChoseLanguage}
              showNoResultsMessage={!searchSuggestions.length}
              suggestions={searchSuggestions}
              titleRender={(suggestion) => (
                <>
                  <Tag className={styles.isoTag} size='md'>
                    {suggestion.key.slice(0, 2).toUpperCase()}
                  </Tag>
                  <Highlighter
                    highlightClassName={styles.suggestionHighlight}
                    searchWords={[searchTerm]}
                    textToHighlight={suggestion.title}
                  />
                </>
              )}
            />
          </div>
        )}
      </>
    );
  } else {
    switch (defaultRenderMode) {
      case 'select': {
        const selectedLanguageCapitalized = selectedLanguage?.toUpperCase();
        const label = selectedLanguage
          ? t(`LANG_${selectedLanguageCapitalized}`)
          : t('LANGUAGE_SEARCH_SELECT_LANGUAGE');
        content = (
          <Button
            block
            className={styles.button}
            disabled={disabled}
            icon={<Icon className={styles.selectCaretIcon} name={ICONS.CARET.name} />}
            invert
            onClick={() => setSearchingLanguage(true)}
          >
            {selectedLanguage ? (
              <>
                <Tag className={styles.selectedLanguageTag}>{selectedLanguageCapitalized.slice(0, 2)}</Tag>{' '}
              </>
            ) : null}
            {label}
          </Button>
        );
        break;
      }
      default:
        content = (
          <Button
            block
            className={styles.button}
            disabled={disabled}
            icon={<Icon name={ICONS.PLUS.name} />}
            invert
            onClick={() => setSearchingLanguage(true)}
            tint='creation'
          >
            {addLanguageButtonTitle}
          </Button>
        );
        break;
    }
  }

  return <div className={className}>{content}</div>;
}

LanguageSearch.propTypes = {
  addLanguageButtonTitle: PropTypes.string,
  className: PropTypes.string,
  defaultRenderMode: PropTypes.oneOf(['button', 'select']),
  disabled: PropTypes.bool,
  excludeLanguages: PropTypes.arrayOf(PropTypes.string),
  languages: PropTypes.arrayOf(PropTypes.string),
  onSelectedLanguage: PropTypes.func,
  selectedLanguage: PropTypes.string,
};
