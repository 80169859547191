import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { removeNoteTextLanguageMutation, updateNoteTextMutation } from '.';

function useRemoveNoteTextAPI(note) {
  const [removeNoteTextAPI] = useMutation(removeNoteTextLanguageMutation);

  const removeNoteText = useCallback(
    (languageIso) => {
      const updatedTextContentMap = {
        ...note.textContentMap,
      };
      delete updatedTextContentMap[languageIso];
      const optimisticResponse = {
        note: {
          ...note,
          textContentMap: {
            ...updatedTextContentMap,
          },
        },
      };

      removeNoteTextAPI({
        variables: {
          noteId: note.id,
          languageIso,
        },
        optimisticResponse,
      });
    },
    [note, removeNoteTextAPI]
  );

  return removeNoteText;
}

function useUpdateNoteTextAPI(note) {
  const [updateNoteTextAPI] = useMutation(updateNoteTextMutation);

  const updateNoteText = useCallback(
    (languageIso, updatedText) => {
      const optimisticResponse = {
        note: {
          ...note,
          textContentMap: {
            ...note.textContentMap,
            [languageIso]: {
              text: updatedText,
            },
          },
        },
      };

      updateNoteTextAPI({
        variables: {
          noteId: note.id,
          languageIso,
          text: updatedText,
        },
        optimisticResponse,
      });
    },
    [note, updateNoteTextAPI]
  );

  return updateNoteText;
}

export { useRemoveNoteTextAPI, useUpdateNoteTextAPI };
