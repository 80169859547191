/* Strings */

export const APP_TOP_MARKER_ELEMENT_ID = 'topMarker';
export const LAST_DISMISSED_PWA_BANNER_DATE = 'LAST_DISMISSED_PWA_BANNER_DATE';
export const SESSION_VAL_HAS_VIEWED_PENDING_INVITATIONS = 'HAS_VIEWED_PENDING_INVITATIONS';
export const TEST_LOG_TYPE_FAILED = 'FAILED';
export const TEST_LOG_TYPE_FIXED = 'FIXED';
export const TEST_LOG_TYPE_FLAWLESS = 'FLAWLESS';
export const TEST_LOG_TYPE_FULLY_FIXED = 'FULLY_FIXED';
export const TEST_LOG_TYPE_INSPECTED = 'SAMPLED';
export const TEST_LOG_TYPE_LOT_SIZE = 'LOT_SIZE';
export const TEST_LOG_TYPE_PASSED = 'PASSED';
export const TEST_LOG_TYPE_REJECTED = 'REJECTED';
export const TEST_LOG_TYPE_TOLERATED = 'TOLERATED';

/* Timing */

export const DEBOUNCE_TIMING_MS_INSTANT = 100;
export const DEBOUNCE_TIMING_MS_SHORT = 250;
export const DEBOUNCE_TIMING_MS_MEDIUM = 500;
export const DEBOUNCE_TIMING_MS_LONG = 1000;
export const TIMING_DELAY_SHORT_MS = 1000;

/* Quality */

export const OK_QUALITY_AQL_CRITICAL_PERCENT = 0.001;
export const OK_QUALITY_AQL_MAJOR_PERCENT = 0.025;
export const OK_QUALITY_AQL_MINOR_PERCENT = 0.04;

/* Links */

export const CALENDLY_LINK = 'https://calendly.com/oktrade/demo';
