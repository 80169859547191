import { gql } from '@apollo/client';
import { nanoid } from 'nanoid';

import BaseMediaAssetModel from '../mediaAsset/base';
import OrganisationDocumentModel from '../organisationDocument';
import ProductModel from '../product';
import BaseUserModel from '../user/base';

import BaseOrganisationModel from './base';

import STATUS from 'OK/util/enums/status';
import { formatOkid } from 'OK/util/formatting';

export default class OrganisationModel {
  static GRAPHQL_TYPE = BaseOrganisationModel.GRAPHQL_TYPE;

  static PERMISSIONS = {
    ITEM_EXTERNAL_ID: 'ITEM_EXTERNAL_ID',
  };

  static ROLE = {
    APPLIED: 'APPLIED',
    COWORKER: 'COWORKER',
    INVITED_COWORKER: 'INVITED_COWORKER',
    INVITED_MANAGER: 'INVITED_MANAGER',
    MANAGER: 'MANAGER',
    NONE: 'NONE',
    OWNER: 'OWNER',
  };

  static SUBSCRIPTION_TYPE = {
    ANNUAL: 'PAID_YEARLY',
    FREE: 'BASIC',
    MONTHLY: 'PAID_MONTHLY',
    TRIAL: 'TRIAL',
  };

  static PLAN_TYPE = {
    BRAND: 'BRAND',
    FREE: 'FREE',
  };

  static fragmentName = 'Organisation_Fragment';
  static fragment = gql`
    fragment ${OrganisationModel.fragmentName} on ${OrganisationModel.GRAPHQL_TYPE} {
      ...${BaseOrganisationModel.fragmentName}
      productList {
        ...${ProductModel.fragmentName}
        productMediaAssetList {
          mediaAsset {
            ...${BaseMediaAssetModel.fragmentName}
          }
          order
          publishStatus
        }
      }
    }
    ${BaseOrganisationModel.fragment}
    ${ProductModel.fragment}
    ${BaseMediaAssetModel.fragment}
  `;

  static fragmentNameLogo = 'Organisation_Logo_Fragment';
  static fragmentLogo = gql`
    fragment ${OrganisationModel.fragmentNameLogo} on ${OrganisationModel.GRAPHQL_TYPE} {
      logoImageMediaAsset {
        baseLogoImageURL
        createdBy
        createdDate
        id
        lastModifiedBy
        lastModifiedDate
        logoImageURL
        version
      }
    }
  `;

  static fragmentNameManager = 'Organisation_Manager_Fragment';
  static fragmentManager = gql`
    fragment ${OrganisationModel.fragmentNameManager} on ${OrganisationModel.GRAPHQL_TYPE} {
      ...${BaseOrganisationModel.fragmentName}
      inviteUserList {
        ...${BaseUserModel.fragmentName}
      }
      inviteManagerList {
        ...${BaseUserModel.fragmentName}
      }
      managerList {
        ...${BaseUserModel.fragmentName}
      }
      ownerList {
        ...${BaseUserModel.fragmentName}
      }
      userList {
        ...${BaseUserModel.fragmentName}
      }
      userJoinRequestList {
        ...${BaseUserModel.fragmentName}
      }
      organisationDocumentAssetList {
      ...${OrganisationDocumentModel.fragmentName}
      }
    }
    ${BaseOrganisationModel.fragment}
    ${BaseUserModel.fragment}
    ${OrganisationDocumentModel.fragment}
  `;

  static fragmentNameMember = 'Organisation_Member_Fragment';
  static fragmentMember = gql`
  fragment ${OrganisationModel.fragmentNameMember} on ${OrganisationModel.GRAPHQL_TYPE} {
    ...${BaseOrganisationModel.fragmentName}
    inviteUserList {
      ...${BaseUserModel.fragmentName}
    }
    managerList {
      ...${BaseUserModel.fragmentName}
    }
    ownerList {
      ...${BaseUserModel.fragmentName}
    }
    userList {
      ...${BaseUserModel.fragmentName}
    }
    userJoinRequestList {
      ...${BaseUserModel.fragmentName}
    }
  }
  ${BaseOrganisationModel.fragment}
  ${BaseUserModel.fragment}
`;

  static fragmentNameArchiveCard = 'Organisation_ArchiveCard_Fragment';
  static fragmentArchiveCard = gql`
    fragment ${OrganisationModel.fragmentNameArchiveCard} on ${OrganisationModel.GRAPHQL_TYPE} {
      id
      conformityPoint
      logoImageMediaAsset {
        logoImageURL
      }
      name
      OKID
      reliabilityPointForPublishedLogs
    }
  `;

  static mock = {
    OKID: 'AB12',
    addressList: [
      {
        city: 'city',
        countryCode: 'HKG',
        line1: 'Line 1',
        line2: null,
        postalCode: null,
        province: null,
      },
    ],
    createdBy: null,
    createdDate: `${new Date()}`,
    id: nanoid(),
    lastModifiedBy: null,
    lastModifiedDate: `${new Date()}`,
    logoImageMediaAsset: null,
    metadata: {},
    name: 'Mock Organisation',
    publicEmail: 'mock@mock.com',
    publicMobile: '12345678',
    publicMobileCountryCode: '+852',
    status: STATUS.ACTIVE,
    type: 'PAID_YEARLY',
    version: 0,
    websiteURL: null,
  };

  static link(organisationOrOKID) {
    let OKID;
    if (typeof organisationOrOKID === 'string') {
      OKID = organisationOrOKID;
    } else {
      OKID = organisationOrOKID.OKID;
    }

    return `/organisation/${formatOkid(OKID)}`;
  }

  static roleForUser(user, organisation) {
    if (organisation.ownerList?.findIndex((u) => u.id === user.id) > -1) {
      return OrganisationModel.ROLE.OWNER;
    }

    if (organisation.managerList?.findIndex((u) => u.id === user.id) > -1) {
      return OrganisationModel.ROLE.MANAGER;
    }

    if (organisation.userList?.findIndex((u) => u.id === user.id) > -1) {
      return OrganisationModel.ROLE.COWORKER;
    }

    if (organisation.inviteManagerList?.findIndex((u) => u.id === user.id) > -1) {
      return OrganisationModel.ROLE.INVITED_MANAGER;
    }

    if (organisation.inviteUserList?.findIndex((u) => u.id === user.id) > -1) {
      return OrganisationModel.ROLE.INVITED_COWORKER;
    }

    if (organisation.userJoinRequestList?.findIndex((u) => u.id === user.id) > -1) {
      return OrganisationModel.ROLE.APPLIED;
    }

    return OrganisationModel.ROLE.NONE;
  }
}
