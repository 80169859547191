import { gql } from '@apollo/client';

export default class BaseInspectionLogTestAssetMediaAssetModel {
  static GRAPHQL_TYPE = 'InspectionLogTestAssetMediaAsset';

  static fragmentName = 'BaseInspectionLogTestAssetMediaAsset_Fragment';
  static fragment = gql`
    fragment ${BaseInspectionLogTestAssetMediaAssetModel.fragmentName} on ${BaseInspectionLogTestAssetMediaAssetModel.GRAPHQL_TYPE} {
      createdBy
      createdDate
      id
      inspectionLogAssetSource
      inspectionOrganisationId
      lastModifiedBy
      lastModifiedDate
      order
      organisationId
      version
    }
  `;
}
