import PropTypes from 'prop-types';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import FormFieldI18n from 'OK/components/form/formFieldI18n';
import { ICONS } from 'OK/components/icon';
import InlineIcon from 'OK/components/inlineIcon';
import SearchInputAsButton, { DISPLAY_MODE } from 'OK/components/input/search/asButton';
import CardLayout from 'OK/components/layouts/card';
import Notice from 'OK/components/notice';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import Toggle from 'OK/components/toggle';
import { useRemoveNoteTextAPI, useUpdateNoteTextAPI } from 'OK/networking/notes/hooks';
import ThemeContext from 'OK/util/context/theme';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import authorisationForResource from 'OK/util/functions/authorisationForResource';
import isAuthorised, { isAuthorisedViaShare } from 'OK/util/functions/isAuthorised';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * A component to display a remark.
 *
 * @param {object} props
 * @param {string} [props.className] The remark's class.
 * @param {(remarkRefId: string, textValues: array) => void} [props.onChange] Event handler for when the remark text
 * changes.
 * The second argument of the function is the array of text values passed from `FormFieldI18n`'s onChange event.
 * @param {(remarkRefId: string) => void} [props.onClickUnlink] Event handler for when a remark should be unlinked.
 * @param {(remarkRefId: string, published: boolean) => void} [props.onPublishChange] Event handler for when a remark
 * is published / unpublished.
 * @param {(remarkRefId: string) => void} [props.onSave] Event handler for when the remark is saved. This will trigger
 * when the field blurs and only if there is a value for English.
 * @param {object} props.remark The remark to display.
 * @param {boolean} props.remark.published Whether the remark is published.
 * @param {string} [props.remark.refId] The refId of the remark.
 * @param {array} props.remark.text An array of objects containing the text of the remark in each covered language.
 * Objects should have 2 keys: `languageIso` for the language ISO code, and `value` for the text in
 * that language.
 * @param {string} [props.unlinkButtonTitle='Unlink'] The title of the button for unlinking the remark.
 *
 * **Note:** English (en) is required.
 */
export default function Remark(props) {
  /* Variables */

  const { t } = useI18n();
  const {
    className,
    disableAPI = false,
    errors,
    linkableSteps,
    linkedStepIndex,
    note = {},
    noteId: _noteId,
    onChange,
    onClickLinkStep,
    onClickUnlink,
    onClickUnlinkStep: onClickUnlinkStepProp,
    onPublishChange,
    onSave,
    published = false,
    requireEnglish = true,
    showPublish = true,
    unlinkButtonTitle = t('NOTE_CARD_UNLINK_BUTTON_DEFAULT'),
    unsaved = false,
    ...otherProps
  } = props;
  const { REFID, textContentMap = {} } = note;
  const noteId = _noteId ?? note.id;
  const theme = useContext(ThemeContext);
  const englishText = textContentMap.EN?.text;
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const [, , currentUser] = useAuthentication();
  const organisation = currentUser?.organisationList.find((o) => o.id === activeOrganisationId);

  const isManager =
    authorisationForResource(currentUser, activeOrganisationId, organisation) == AUTHORISATION_LEVEL.MANAGER ||
    authorisationForResource(currentUser, activeOrganisationId, organisation) == AUTHORISATION_LEVEL.OWNER;

  const authorisationLevel = useAuthorisationLevel(note);

  const hasPermissionToEdit =
    isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER) ||
    (isAuthorisedViaShare(document?.assetAccessPermission, AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION) &&
      isManager);

  // State

  const [localTextErrors, setLocalTextErrors] = useState({});
  const [linkedStepError, setLinkedStepError] = useState(null);
  const [stepsSearchFocused, setStepsSearchFocused] = useState(false);
  const [stepsSearchString, setStepsSearchString] = useState('');

  const text = useMemo(() => {
    return textFromMap(
      textContentMap,
      {
        ...localTextErrors,
        ...errors,
      },
      requireEnglish
    );
  }, [errors, localTextErrors, requireEnglish, textContentMap]);

  // Refs

  const searchStepsInputRef = useRef();

  /* Methods */

  // API

  const removeNoteTextAPI = useRemoveNoteTextAPI(note);
  const updateNoteTextAPI = useUpdateNoteTextAPI(note);

  // General

  /** Validate the remark and trigger a save event if no errors are found. */
  const trySave = useCallback(
    (changedLanguageIso, textValue) => {
      // Only try to save if there has been a change
      // or if disableAPI is true (to support unsaved remark editing)
      if (textContentMap[changedLanguageIso]?.text !== textValue || disableAPI) {
        if (textValue !== '') {
          // Text changed to valid value
          if (!disableAPI) {
            updateNoteTextAPI(changedLanguageIso, textValue);
          }

          if (onSave) {
            // Trigger event handler
            onSave(noteId, changedLanguageIso, textValue);
          }
        } else if (typeof textContentMap[changedLanguageIso]?.text !== 'undefined') {
          // Text changed to empty

          // Show error
          const updatedTextErrors = {
            ...localTextErrors,
            [changedLanguageIso]: t('NOTE_CARD_MISSING_TEXT_ERROR'),
          };
          setLocalTextErrors(updatedTextErrors);
        } else {
          // Ignore change to empty text if text was never set
        }
      }
    },
    [disableAPI, localTextErrors, noteId, onSave, t, textContentMap, updateNoteTextAPI]
  );

  /* Events */

  const onClickStepSuggestion = useCallback(
    (stepId) => {
      setLinkedStepError(null);
      searchStepsInputRef.current.setDisplayMode(DISPLAY_MODE.BUTTON);
      setStepsSearchString('');
      setStepsSearchFocused(false);
      onClickLinkStep(noteId, stepId, setLinkedStepError);
    },
    [noteId, onClickLinkStep]
  );

  const onClickUnlinkStep = useCallback(() => {
    setLinkedStepError(null);
    onClickUnlinkStepProp(noteId, setLinkedStepError);
  }, [noteId, onClickUnlinkStepProp]);

  const onFieldEndEditing = useCallback(
    (blurredLanguageIso, textValues) => {
      // Clear errors
      if (localTextErrors[blurredLanguageIso]) {
        const updatedErrors = {
          ...localTextErrors,
        };
        delete updatedErrors[blurredLanguageIso];
        setLocalTextErrors(updatedErrors);
      }

      const blurredTextValue = textValues.find((v) => v.languageIso === blurredLanguageIso).value;
      trySave(blurredLanguageIso, blurredTextValue);
    },
    [localTextErrors, trySave]
  );

  const onPublishToggled = useCallback(
    (newValue) => {
      if (onPublishChange) {
        onPublishChange(noteId, newValue);
      }
    },
    [noteId, onPublishChange]
  );

  const onSearchSteps = useCallback((e) => {
    const searchString = e.target.value;
    setStepsSearchString(searchString);
  }, []);

  const onTextChange = useCallback(
    (changedLanguageIso, newTextValues) => {
      const updatedLanguageIso = changedLanguageIso.toUpperCase();

      if (!disableAPI) {
        // Update note in cache
        const updatedText = newTextValues.find((t) => t.languageIso.toUpperCase() === updatedLanguageIso)?.value;
        if (typeof updatedText === 'undefined') {
          removeNoteTextAPI(updatedLanguageIso);
        }
      }

      if (onChange) {
        onChange(noteId, changedLanguageIso, newTextValues);
      }
    },
    [disableAPI, noteId, onChange, removeNoteTextAPI]
  );

  const onUnlinkClicked = useCallback(() => {
    if (onClickUnlink) {
      onClickUnlink(noteId);
    }
  }, [noteId, onClickUnlink]);

  /* Render */

  const hasLinkableSteps = linkableSteps?.length > 0;
  const hasLinkedStep = typeof linkedStepIndex === 'number';

  // Classes
  let classNames = '';
  if (unsaved) {
    classNames = `${classNames} ${styles.unsaved}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  // Link steps
  let stepSuggestions;
  if (stepsSearchFocused) {
    if (stepsSearchString.length) {
      stepSuggestions = linkableSteps.filter((s) => {
        const matchString = `(step)? ?${s.index}`;
        const stepSearchRegex = new RegExp(matchString, 'i');
        const matches = stepSearchRegex.test(stepsSearchString);
        return matches;
      });
    } else {
      stepSuggestions = linkableSteps;
    }
  }

  return (
    <CardLayout className={classNames} {...otherProps}>
      <h4 className={styles.header}>{t('NOTE_CARD_HEADER')}</h4>
      {REFID && REFID !== 'NEW' && (
        <Tag className={styles.refId} size='sm'>
          {REFID}
        </Tag>
      )}
      {showPublish && (
        <Notice className={styles.publishNotice} extendSides>
          <div className={styles.publishRow}>
            <p className={styles.publishLabel}>
              <strong>{t('PUBLISH')}</strong>
            </p>
            <Toggle disabled={unsaved} checked={published} onChange={onPublishToggled} />
          </div>
          <p className={styles.attachment}>
            <InlineIcon className={styles.attachmentIcon} src={`/icons/remark_${theme.name}.svg`} />
            {englishText}
          </p>
        </Notice>
      )}
      {hasPermissionToEdit && (
        <div className={styles.section}>
          <FormFieldI18n
            addLanguageButtonTitle={t('NOTE_CARD_ADD_LANGUAGE')}
            inputPlaceholder={t('NOTE_CARD_INPUT_PLACEHOLDER', { data: { language: '{{language}}' } })}
            onBlur={onFieldEndEditing}
            onChange={onTextChange}
            useTextAreas
            values={text}
          />
        </div>
      )}
      <div className={styles.section}>
        {hasLinkableSteps && !hasLinkedStep && (
          <div className={styles.searchContainer}>
            <SearchInputAsButton
              className={`${styles.searchInput} ${styles.linkStepButton}`}
              icon={ICONS.PLUS.name}
              inputProps={{
                onBlur: () => setStepsSearchFocused(false),
                onChange: onSearchSteps,
                onFocus: () => setStepsSearchFocused(true),
                value: stepsSearchString,
              }}
              placeholder={t('NOTE_CARD_LINK_TO_STEP_BUTTON')}
              ref={searchStepsInputRef}
              tint='creation'
            />
            <SearchSuggestions
              className={styles.searchSuggestions}
              onSuggestionClick={onClickStepSuggestion}
              suggestions={stepSuggestions?.map((s) => {
                return {
                  key: s.id,
                  title: t('NOTE_CARD_STEP_SEARCH_RESULT', { data: { number: s.index } }),
                };
              })}
            />
          </div>
        )}
        {hasLinkedStep && (
          <Button
            block
            className={styles.linkStepButton}
            icon={ICONS.UNLINK.name}
            linkStyle
            onClick={onClickUnlinkStep}
            tint='alert'
          >
            {t('NOTE_CARD_UNLINK_FROM_STEP_X', { data: { number: linkedStepIndex + 1 } })}
          </Button>
        )}
        {linkedStepError && (
          <Text className={styles.linkStepErrorMessage} size='sm' tint='alert'>
            {linkedStepError}
          </Text>
        )}
        {unsaved ? (
          <Button block className={styles.unlinkButton} linkStyle onClick={onUnlinkClicked} tint='alert'>
            {t('NOTE_CARD_CANCEL_ADDING_NOTE')}
          </Button>
        ) : (
          <Button
            block
            className={styles.unlinkButton}
            icon={`/icons/unlink_${theme.name === 'light' ? 'dark' : 'light'}.svg`}
            onClick={onUnlinkClicked}
            tint='alert'
          >
            {unlinkButtonTitle}
          </Button>
        )}
      </div>
    </CardLayout>
  );
}

Remark.propTypes = {
  className: PropTypes.string,
  disableAPI: PropTypes.bool,
  errors: PropTypes.object,
  linkableSteps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  linkedStepIndex: PropTypes.number,
  note: PropTypes.shape({
    REFID: PropTypes.string,
    textContentMap: PropTypes.object,
  }).isRequired,
  noteId: PropTypes.string,
  onChange: PropTypes.func,
  onClickLinkStep: PropTypes.func,
  onClickUnlink: PropTypes.func,
  onClickUnlinkStep: PropTypes.func,
  onPublishChange: PropTypes.func,
  onSave: PropTypes.func,
  published: PropTypes.bool,
  requireEnglish: PropTypes.bool,
  showPublish: PropTypes.bool,
  unlinkButtonTitle: PropTypes.string,
  unsaved: PropTypes.bool,
};

/* Helper methods */

/** Generate FormFieldI18n text object from Note text map and error object. */
function textFromMap(map, errors, requireEnglish) {
  return Object.keys(map).map((languageIso) => {
    return {
      languageIso,
      optional: !requireEnglish || languageIso !== 'EN',
      value: map[languageIso].text,
      warning: errors[languageIso],
    };
  });
}
