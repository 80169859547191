import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import Text from 'OK/components/text';

/**
 * @typedef {Object} ArchiveCardOKIDProps
 * @prop {Node} children
 * @prop {string} className
 * @prop {'tag'|'user'} [icon='tag'] The icon to display. Defaults to `'tag'`.
 */

/**
 * The asset's OKID.
 *
 * @param {ArchiveCardOKIDProps} props
 */
export default function ArchiveCardOKID(props) {
  /* Variables */

  const { children, className, icon = 'tag', ...otherProps } = props;

  /* Render */

  // Classes
  let classNames = styles.okid;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  // Icon
  let iconName;
  switch (icon) {
    case 'user':
      iconName = ICONS.PROFILE.name;
      break;
    default:
      iconName = ICONS.TAG.name;
      break;
  }

  return (
    <Text bold className={classNames} size='xs' {...otherProps}>
      <Icon className={styles.okidIcon} height={12} inline name={iconName} width={12} />
      {children}
    </Text>
  );
}

ArchiveCardOKID.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.oneOf(['tag', 'user']),
};
