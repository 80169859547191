import PropTypes from 'prop-types';
import { useContext } from 'react';

import AlertIcon from './alert';
import BaseIcon from './base';
import GamesIcon from './games';
import ICONS from './icons';
import SpinnerIcon from './spinner';

import ThemeContext from 'OK/util/context/theme';

export default function Icon(props) {
  const { color, name, tint, ...otherProps } = props;
  const theme = useContext(ThemeContext);

  const icon = ICONS[name];
  if (!icon) {
    okerror(`[icon]: There is no icon named ${name}.`);
    return null;
  }

  /* Render */

  // Icon props
  const iconProps = { ...icon };

  // Pass correct color for tint
  // TODO: Adapt for UI context
  switch (tint) {
    case 'alert':
    case 'creation':
    case 'navigation':
    case 'notification':
      iconProps.color = theme.colors[tint];
      break;
    default:
      iconProps.color = color;
      break;
  }

  // Special icons which use their own component
  switch (name) {
    case ICONS.ALERT.name:
      return <AlertIcon {...iconProps} {...otherProps} />;
    case ICONS.GAMES.name:
      return <GamesIcon {...iconProps} {...otherProps} />;
    case ICONS.SPINNER.name:
      return <SpinnerIcon {...iconProps} {...otherProps} />;
    default:
      return (
        <BaseIcon {...iconProps} {...otherProps}>
          <path d={icon.d} fill={iconProps.color} />
        </BaseIcon>
      );
  }
}

Icon.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  tint: PropTypes.oneOf(['alert', 'creation', 'default', 'navigation', 'notification']),
};

// Export here for convenience
export { ICONS };
