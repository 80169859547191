import { combineReducers } from '@reduxjs/toolkit';

import account, { initialState as initialAccountState } from 'OK/state/account/reducer';
import app, { initialState as initialAppState } from 'OK/state/app/reducer';
import onboarding, { initialState as initialOnboardingState } from 'OK/state/onboarding/reducer';

const initialState = {
  account: initialAccountState,
  app: initialAppState,
  onboarding: initialOnboardingState,
};

export default combineReducers({ account, app, onboarding });
export { initialState };
