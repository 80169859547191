import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Text from 'OK/components/text';

/**
 * @typedef {Object} ArchiveCardTitleProps
 * @prop {Node} children
 */

/**
 * The title of the asset.
 *
 * @param {ArchiveCardTitleProps} props
 */
export default function ArchiveCardTitle(props) {
  const { children, className, lineLimit = 2, ...otherProps } = props;

  // Classes
  let classNames = styles.title;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <Text className={classNames} lineLimit={lineLimit} size='sm' {...otherProps}>
      {children}
    </Text>
  );
}

ArchiveCardTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  lineLimit: PropTypes.number,
};
