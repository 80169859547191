import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { debug, error, info, warn } from 'OK/util/logger';

/* Code that must be declared before everything else */

// Setup global debug variables
global.okdebug = debug;
global.okerror = error;
global.okinfo = info;
global.okwarn = warn;
