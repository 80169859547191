import round from 'lodash/round';

import TestAssetModel from 'OK/models/testAsset';

export default function generateInspectionLogData(inspectionCounts, tests) {
  const { fixed, flawless, initialLotSize, rejected, sampleSize, tolerated } = inspectionCounts;

  // Sample percentages
  let percentSampleFlawless = 0;
  let percentSampleFixed = 0;
  let percentSampleTolerated = 0;
  let percentSampleRejected = 0;
  if (sampleSize) {
    percentSampleFlawless = flawless / sampleSize;
    percentSampleFixed = fixed / sampleSize;
    percentSampleTolerated = tolerated / sampleSize;
    percentSampleRejected = rejected / sampleSize;
  }

  // Lot projections
  let projectedFlawless = 0;
  let percentProjectedFlawless = 0;
  let projectedTolerable = 0;
  let percentProjectedTolerable = 0;
  let projectedFixable = 0;
  let percentProjectedFixable = 0;
  let projectedUnfixable = 0;
  let percentProjectedUnfixable = 0;
  let percentLotFixed = 0;
  let percentLotRejected = 0;
  if (initialLotSize && sampleSize) {
    projectedFlawless = round(percentSampleFlawless * initialLotSize);
    percentProjectedFlawless = projectedFlawless / initialLotSize;
    projectedTolerable = round(percentSampleTolerated * initialLotSize);
    percentProjectedTolerable = projectedTolerable / initialLotSize;
    projectedFixable = round(percentSampleFixed * initialLotSize) - fixed;
    percentProjectedFixable = projectedFixable / initialLotSize;
    projectedUnfixable = round(percentSampleRejected * initialLotSize) - rejected;
    percentProjectedUnfixable = projectedUnfixable / initialLotSize;
    percentLotFixed = fixed / initialLotSize;
    percentLotRejected = rejected / initialLotSize;
  }

  // Tests by severity level
  const criticalTests = tests.filter((test) => {
    const testAsset = test.versionedTestAsset ?? test.unversionedTestAsset;
    return testAsset.testSeverityLevel === TestAssetModel.SEVERITY_LEVEL.CRITICAL;
  });
  const majorTests = tests.filter((test) => {
    const testAsset = test.versionedTestAsset ?? test.unversionedTestAsset;
    return testAsset.testSeverityLevel === TestAssetModel.SEVERITY_LEVEL.MAJOR;
  });
  const minorTests = tests.filter((test) => {
    const testAsset = test.versionedTestAsset ?? test.unversionedTestAsset;
    return testAsset.testSeverityLevel === TestAssetModel.SEVERITY_LEVEL.MINOR;
  });

  // Counts per test severity level
  const criticalInspectedCount = Math.max(...criticalTests.map((t) => t.passed + t.failed));
  const criticalFailureCount = criticalTests.reduce((sum, test) => sum + test.failed, 0);
  const majorInspectedCount = Math.max(...majorTests.map((t) => t.passed + t.failed));
  const majorFailureCount = majorTests.reduce((sum, test) => sum + test.failed, 0);
  const minorInspectedCount = Math.max(...minorTests.map((t) => t.passed + t.failed));
  const minorFailureCount = minorTests.reduce((sum, test) => sum + test.failed, 0);

  // Projected failure counts per test
  const projectedCriticalFailures = criticalTests
    .map((t) => projectedLotFailuresForTest(t, initialLotSize))
    .reduce((sum, count) => sum + count, 0);
  const projectedMajorFailures = majorTests
    .map((t) => projectedLotFailuresForTest(t, initialLotSize))
    .reduce((sum, count) => sum + count, 0);
  const projectedMinorFailures = minorTests
    .map((t) => projectedLotFailuresForTest(t, initialLotSize))
    .reduce((sum, count) => sum + count, 0);

  // Skipped tests
  const skippedTests = tests.filter((t) => t.passed + t.failed === 0);
  const percentTestsSkipped = (skippedTests.length / tests.length) * 100;

  // AQL
  // Sample quality rates are determined by number of fixed and rejected
  const sampleResults = {
    criticalFailures: criticalFailureCount,
    criticalInspected: criticalInspectedCount,
    majorFailures: majorFailureCount,
    majorInspected: majorInspectedCount,
    minorFailures: minorFailureCount,
    minorInspected: minorInspectedCount,
    percentFlawless: percentSampleFlawless * 100,
    percentFixed: percentSampleFixed * 100,
    percentTolerated: percentSampleTolerated * 100,
    percentRejected: percentSampleRejected * 100,
    skippedTestsCount: skippedTests.length,
    percentTestsSkipped,
  };
  const finalLotProjections = {
    criticalFailures: projectedCriticalFailures,
    majorFailures: projectedMajorFailures,
    minorFailures: projectedMinorFailures,
    projectedFlawless,
    percentProjectedFlawless: percentProjectedFlawless * 100,
    projectedTolerable,
    percentProjectedTolerable: percentProjectedTolerable * 100,
    projectedFixable,
    percentProjectedFixable: percentProjectedFixable * 100,
    projectedUnfixable,
    percentProjectedUnfixable: percentProjectedUnfixable * 100,
    percentFixed: percentLotFixed * 100,
    percentRejected: percentLotRejected * 100,
  };

  const projectedLotSize =
    projectedFlawless + fixed + projectedFixable + projectedUnfixable + projectedTolerable + rejected;
  if (projectedLotSize !== initialLotSize) {
    // Due to rounding, sometimes the projected counts may not add up to the actual lot size. In this case,
    // adjust the largest count by the difference so numbers add up correctly.
    const difference = projectedLotSize - initialLotSize;
    const projectedCountsSortedAsc = [
      { count: projectedFlawless, type: 'FLAWLESS' },
      { count: projectedFixable, type: 'FIXABLE' },
      { count: projectedUnfixable, type: 'UNFIXABLE' },
      { count: projectedTolerable, type: 'TOLERATED' },
    ].sort((a, b) => a.count - b.count);
    const largestProjectedCount = projectedCountsSortedAsc.lastItem;
    switch (largestProjectedCount.type) {
      case 'FLAWLESS':
        finalLotProjections.projectedFlawless -= difference;
        finalLotProjections.percentProjectedFlawless = (finalLotProjections.projectedFlawless / initialLotSize) * 100;
        break;
      case 'FIXABLE':
        finalLotProjections.projectedFixable -= difference;
        finalLotProjections.percentProjectedFixable = (finalLotProjections.projectedFixable / initialLotSize) * 100;
        break;
      case 'UNFIXABLE':
        finalLotProjections.projectedUnfixable -= difference;
        finalLotProjections.percentProjectedUnfixable = (finalLotProjections.projectedUnfixable / initialLotSize) * 100;
        break;
      case 'TOLERATED':
        finalLotProjections.projectedTolerable -= difference;
        finalLotProjections.percentProjectedTolerable = (finalLotProjections.projectedTolerable / initialLotSize) * 100;
        break;
    }
  }

  return {
    finalLotProjections,
    sampleResults,
  };
}

function projectedLotFailuresForTest(test, initialLotSize) {
  // To keep numbers consistent with individual test counts, the projection is calculated and rounded
  // at each individual category and then summed together.
  const rejected = test.failed - test.fixed - test.tolerated;
  const testSampleSize = test.passed + test.failed;

  if (testSampleSize === 0) {
    return 0;
  }

  const fixable = round((test.fixed / testSampleSize) * initialLotSize) - test.fixed;
  const unfixable = round((rejected / testSampleSize) * initialLotSize) - rejected;
  const tolerable = round((test.tolerated / testSampleSize) * initialLotSize);
  const projectedFailures = fixable + unfixable + tolerable;
  return projectedFailures;
}
