import { gql } from '@apollo/client';

export default class BaseSiteChildSiteModel {
  static GRAPHQL_TYPE = 'SiteChildSite';

  static fragmentName = 'Base_SiteChildSite_Fragment';
  static fragment = gql`
    fragment ${BaseSiteChildSiteModel.fragmentName} on ${BaseSiteChildSiteModel.GRAPHQL_TYPE} {
      assetLinkStatus
      order
      publishStatus
      quantity
    }
  `;
}
