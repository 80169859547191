import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import ColumnCard from 'OK/components/archiveCard/ColumnCard';
import Button from 'OK/components/button';
import ButtonGroup from 'OK/components/buttonGroup';
import { Carousel, Slide } from 'OK/components/carousel';
import Icon, { ICONS } from 'OK/components/icon';
import InlineIcon from 'OK/components/inlineIcon';
import SearchInput from 'OK/components/input/search';
import InspectionEditCard from 'OK/components/inspection/editCard';
import ContentLayout from 'OK/components/layouts/content';
import Notice from 'OK/components/notice';
import Progressable from 'OK/components/progressable';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Text from 'OK/components/text';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import { LatestInspectionLogsForItemQuery, LatestInspectionLogsForProductQuery } from 'OK/networking/inspectionLogs';
import { createProductInspectionMutation } from 'OK/networking/products';
import {
  useLinkProductInspectionAPI,
  usePublishProductInspectionAPI,
  useReorderProductInspectionBackwardAPI,
  useReorderProductInspectionForwardAPI,
  useUnlinkProductInspectionAPI,
  useUnpublishProductInspectionAPI,
} from 'OK/networking/products/hooks';
import { searchQuery } from 'OK/networking/search';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import useI18n from 'OK/util/hooks/useI18n';

export default function ProductWorkSection(props) {
  const { editMode, item, itemMode, product } = props;

  // Variables

  const { locale, t, tHTML } = useI18n();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const productInspectionAssets = product.productInspectionAssetList;

  // State

  const [activeLogsTab, setActiveLogsTab] = useState(itemMode ? ACTIVE_LOGS_TAB.ITEM : ACTIVE_LOGS_TAB.PRODUCT);
  const [creationError, setCreationError] = useState(null);
  const [inspectionErrors, setInspectionErrors] = useState([]);
  const [searchInspectionFocused, setSearchInspectionFocused] = useState(false);
  const [searchInspectionPartnersFocused, setSearchInspectionPartnersFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Refs

  const inspectionSliderRef = useRef();

  // API

  const [createProductInspectionAPI, createProductInspectionAPIResult] = useMutation(createProductInspectionMutation);
  const [linkProductInspectionAPI, linkProductInspectionAPIResult] = useLinkProductInspectionAPI();
  const publishProductInspectionAPI = usePublishProductInspectionAPI();
  const [unlinkProductInspectionAPI, unlinkProductInspectionAPIResult] = useUnlinkProductInspectionAPI();
  const reorderProductInspectionBackwardAPI = useReorderProductInspectionBackwardAPI();
  const reorderProductInspectionForwardAPI = useReorderProductInspectionForwardAPI();
  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);
  const unpublishProductInspectionAPI = useUnpublishProductInspectionAPI();

  const disableCreatingInspection = createProductInspectionAPIResult.loading;

  const latestItemInspectionLogsResult = useQuery(LatestInspectionLogsForItemQuery, {
    variables: {
      includeInternalLogs: true,
      itemId: item?.id,
    },
    skip: !item?.id,
  });

  const latestProductInspectionLogsResult = useQuery(LatestInspectionLogsForProductQuery, {
    variables: {
      includeInternalLogs: true,
      productId: product?.id,
    },
    skip: !product?.id,
  });

  const latestInspectionLogs = useMemo(() => {
    // Reverse logs because API returns them in date ascending order
    const data =
      activeLogsTab === ACTIVE_LOGS_TAB.ITEM
        ? latestItemInspectionLogsResult.data
        : latestProductInspectionLogsResult.data;
    const logs = data?.inspectionLogs ?? [];
    return [...logs].reverse();
  }, [activeLogsTab, latestItemInspectionLogsResult.data, latestProductInspectionLogsResult.data]);

  // Methods

  const clearErrorForInspectionAssetId = useCallback(
    (inspectionAssetId) => {
      const indexOfInspectionError = inspectionErrors.findIndex((e) => e.inspectionAssetId === inspectionAssetId);
      if (indexOfInspectionError > -1) {
        const updatedErrorsList = [...inspectionErrors];
        updatedErrorsList.splice(indexOfInspectionError, 1);
        setInspectionErrors(updatedErrorsList);
      }
    },
    [inspectionErrors]
  );

  const createInspection = useCallback(() => {
    setCreationError(null);
    createProductInspectionAPI({
      variables: { productId: product.id },
      update: (cache, result) => {
        const { productInspectionAsset } = result.data;

        // Write inspection asset to cache
        const inspectionAssetRef = cache.writeFragment({
          id: cache.identify(productInspectionAsset.inspectionAsset),
          fragment: InspectionAssetModel.fragmentEditCard,
          fragmentName: InspectionAssetModel.fragmentNameEditCard,
          data: productInspectionAsset.inspectionAsset,
        });

        // Add product inspection asset to product
        cache.modify({
          id: cache.identify(product),
          fields: {
            productInspectionAssetList: (currentList) => {
              const cacheProductInspectionAsset = {
                ...productInspectionAsset,
                inspectionAsset: inspectionAssetRef,
              };
              return [...currentList, cacheProductInspectionAsset];
            },
          },
        });
      },
    }).catch(() => {
      setCreationError(t('ERROR_GENERIC'));
    });
  }, [createProductInspectionAPI, product, t]);

  const onClickLinkInspection = useCallback(
    (inspectionAssetId) => {
      setSearchTerm('');
      linkProductInspectionAPI(product.id, inspectionAssetId).catch(() => {
        setCreationError(t('ERROR_GENERIC'));
      });
    },
    [linkProductInspectionAPI, product.id, t]
  );

  const searchDebounced = useMemo(
    () =>
      debounce((searchString) => {
        searchAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'INSPECTION_ASSET',
                ignoreIdList: productInspectionAssets.map((pi) => pi.inspectionAsset.id),
              },
            ],
            searchPaginationDataByDataType: [
              {
                dataType: 'INSPECTION_ASSET',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [productInspectionAssets, searchAPI]
  );

  const onSearch = useCallback(
    (e) => {
      const newSearchTerm = e.target.value;
      setSearchTerm(newSearchTerm);

      if (newSearchTerm.length > 1) {
        searchDebounced(newSearchTerm);
      }
    },
    [searchDebounced]
  );

  const searchSuggestions = useMemo(() => {
    if (searchInspectionFocused && searchTerm.length > 1) {
      return (
        searchAPIResult.data?.search?.resultList?.map((r) => {
          const inspectionAsset = r.inspectionAssetData;
          return {
            icon: ICONS.INSPECTION.name,
            key: inspectionAsset.id,
            title: InspectionAssetModel.localizedNameForInspectionAsset(inspectionAsset, locale),
            subtitle: inspectionAsset.REFID,
          };
        }) ?? []
      );
    }

    return [];
  }, [locale, searchAPIResult.data?.search?.resultList, searchInspectionFocused, searchTerm.length]);

  const setErrorForInspectionAssetId = useCallback(
    (inspectionAssetId, message) => {
      setInspectionErrors([...inspectionErrors, { inspectionAssetId, message }]);
    },
    [inspectionErrors]
  );

  // Event handlers

  const onBlurSearchInspectionPartners = useCallback(() => {
    setSearchInspectionPartnersFocused(false);
  }, []);

  const onClickUnlinkInspection = useCallback(
    (inspectionAssetId) => {
      unlinkProductInspectionAPI(product.id, inspectionAssetId).catch(() => {
        setCreationError(t('ERROR_GENERIC'));
      });
    },
    [product.id, t, unlinkProductInspectionAPI]
  );

  const onChangeInspectionPublished = useCallback(
    (inspectionAssetId, published) => {
      clearErrorForInspectionAssetId(inspectionAssetId);

      if (published) {
        publishProductInspectionAPI(product.id, inspectionAssetId).catch(() => {
          setErrorForInspectionAssetId(inspectionAssetId, t('ERROR_GENERIC'));
        });
      } else {
        unpublishProductInspectionAPI(product.id, inspectionAssetId).catch(() => {
          setErrorForInspectionAssetId(inspectionAssetId, t('ERROR_GENERIC'));
        });
      }
    },
    [
      clearErrorForInspectionAssetId,
      product.id,
      publishProductInspectionAPI,
      setErrorForInspectionAssetId,
      t,
      unpublishProductInspectionAPI,
    ]
  );

  const onFocusSearchInspectionPartners = useCallback(() => {
    setSearchInspectionPartnersFocused(true);

    const searchedElements = document.getElementsByClassName(styles.carouselContainer);
    if (searchedElements.length) {
      const carouselElement = searchedElements[0];
      const osHostElement = carouselElement.children[0];
      osHostElement.children.forEach((child) => {
        if (child.classList.contains('os-content-glue')) {
          // TODO: find proper solution to overlayscrollbars page jump
          // The overlayscrollbars plugin causes the page to scroll whenever an input field within a card is focused.
          // The issue is made worse by the bottom margin & padding hack we are using so the search suggestions can
          // overflow outside the carousel. By restoring the height and bottom margin of the .os-content-glue element
          // the issue seems to be mostly remedied, although there is still a flicker. It's good enough for now until
          // a proper solution can be found.
          const glueHeight = child.clientHeight;
          const glueMarginBottom = child.style.marginBottom;

          // Set styles asynchronously so they take effect after overlayscrollbars
          setTimeout(() => {
            child.style.setProperty('max-height', `${glueHeight}px`, 'important');
            child.style.setProperty('margin-bottom', glueMarginBottom, 'important');
          }, 1);
        }
      });
    }
  }, []);

  const onReorderInspection = useCallback(
    (inspectionAssetId, direction) => {
      if (direction === 'left') {
        reorderProductInspectionBackwardAPI(product.id, inspectionAssetId);
      } else {
        reorderProductInspectionForwardAPI(product.id, inspectionAssetId);
      }
    },
    [product.id, reorderProductInspectionBackwardAPI, reorderProductInspectionForwardAPI]
  );

  // Render

  const inspectionsNotice = (
    <Notice className={styles.sectionNotice}>
      <Text>
        {tHTML('PRODUCT_SECTION_WORKFLOWS_NOTICE', {
          data: {
            icon: (
              <span style={{ display: 'inline-block' }}>
                <Icon inline name={ICONS.RELIABILITY_GRADE.name} />
              </span>
            ),
          },
        })}
      </Text>
    </Notice>
  );

  let carouselContainerClassNames = styles.carouselContainer;
  if (searchInspectionPartnersFocused) {
    carouselContainerClassNames = `${carouselContainerClassNames} ${styles.withSearchPadding}`;
  }

  return (
    <ContentLayout style={{ paddingTop: 20 }} pageContent>
      {useDesktopLayout && inspectionsNotice}
      {editMode ? (
        <>
          <div className={styles.sectionIntro}>
            <p>
              {tHTML('PRODUCT_SECTION_WORKFLOWS_DESCRIPTION', {
                data: { icon: <Icon inline name={ICONS.RELIABILITY_GRADE.name} /> },
              })}
            </p>
            <div className={styles.searchInputContainer}>
              <SearchInput
                className={styles.searchInput}
                loading={searchAPIResult.loading}
                onBlur={() => setSearchInspectionFocused(false)}
                onChange={onSearch}
                onFocus={() => setSearchInspectionFocused(true)}
                placeholder={t('WORKFLOWS_SEARCH_PLACEHOLDER')}
                value={searchTerm}
              />
              <Button
                className={styles.searchNewButton}
                disabled={disableCreatingInspection}
                linkStyle
                onClick={createInspection}
                tint='creation'
              >
                {t('NEW')}
              </Button>
              <SearchSuggestions
                accessoryViewRender={() => (
                  <Text className={styles.searchResultButton} tint='navigation'>
                    {t('LINK')}
                    <InlineIcon src='/icons/link_blue.svg' />
                  </Text>
                )}
                className={styles.searchResults}
                highlightTerm={searchTerm}
                onSuggestionClick={onClickLinkInspection}
                showMoreResultsMessage={
                  searchInspectionFocused &&
                  searchAPIResult.data?.search?.searchPaginationResultDataByDataType?.INSPECTION_ASSET?.totalResults >
                    searchSuggestions.length
                }
                showNoResultsMessage={
                  searchInspectionFocused && searchTerm.length > 1 && searchSuggestions.length === 0
                }
                suggestions={searchSuggestions}
                subtitleClassName={styles.searchResultOKID}
              />
            </div>
            {creationError && (
              <Text className={styles.creationError} size='sm' tint='notification'>
                {creationError}
              </Text>
            )}
          </div>
          <Progressable
            inProgress={
              createProductInspectionAPIResult.loading ||
              linkProductInspectionAPIResult.loading ||
              unlinkProductInspectionAPIResult.loading
            }
            style={{ minHeight: '100px' }}
          >
            <Carousel
              className={carouselContainerClassNames}
              innerClassName={styles.carousel}
              fadeOutSides={useDesktopLayout}
              ref={inspectionSliderRef}
            >
              {productInspectionAssets.map((i, index) => {
                const allowReorderLeft = index !== 0;
                const allowReorderRight = index < productInspectionAssets.length - 1;
                let reorderDirections;
                if (allowReorderLeft && allowReorderRight) {
                  reorderDirections = 'both';
                } else if (allowReorderLeft) {
                  reorderDirections = 'left';
                } else if (allowReorderRight) {
                  reorderDirections = 'right';
                }
                return (
                  <Slide className={styles.inspectionSlide} key={i.inspectionAsset.id}>
                    <InspectionEditCard
                      className={styles.inspection}
                      fixedWidth={false}
                      inspectionAsset={i.inspectionAsset}
                      onBlurSearchInspectionPartnersProp={onBlurSearchInspectionPartners}
                      onFocusSearchInspectionPartnersProp={onFocusSearchInspectionPartners}
                      publishError={inspectionErrors.find((e) => e.inspectionAssetId === i.inspectionAsset.id)?.message}
                      published={i.publishStatus === PUBLISH_STATUS.PUBLISHED}
                      onClickUnlink={onClickUnlinkInspection}
                      onClickReorder={onReorderInspection}
                      onChangePublish={onChangeInspectionPublished}
                      showReorder={reorderDirections}
                      unlinkButtonTitle={t('UNLINK_FROM_PRODUCT')}
                    />
                  </Slide>
                );
              })}
              <Slide className={styles.inspectionSlide}>
                <button
                  className={styles.addSlideButton}
                  disabled={disableCreatingInspection}
                  onClick={createInspection}
                >
                  <img alt='' src='/icons/upload_big.svg' /> {t('ADD_NEW_WORKFLOW')}
                </button>
              </Slide>
            </Carousel>
          </Progressable>
        </>
      ) : null}
      {!useDesktopLayout && inspectionsNotice}
      <>
        <Text className={styles.sectionDescription}>
          {latestInspectionLogs.length > 0 ? <>{t('READ_RECENT_WORK_LOGS')}</> : <>{t('NO_RECENT_WORK_LOGS')}</>}
        </Text>
        {itemMode && (
          <ButtonGroup className={styles.buttonGroup}>
            <button
              active={activeLogsTab === ACTIVE_LOGS_TAB.ITEM}
              onClick={() => setActiveLogsTab(ACTIVE_LOGS_TAB.ITEM)}
            >
              {t('ITEM')}
            </button>
            <button
              active={activeLogsTab === ACTIVE_LOGS_TAB.PRODUCT}
              onClick={() => setActiveLogsTab(ACTIVE_LOGS_TAB.PRODUCT)}
            >
              {t('PRODUCT')}
            </button>
          </ButtonGroup>
        )}
        <Progressable
          inProgress={latestProductInspectionLogsResult.loading}
          style={{ minHeight: latestProductInspectionLogsResult.loading ? 200 : 0 }}
        >
          {latestInspectionLogs.length > 0 && (
            <Carousel
              className={styles.recentInspectionsCarousel}
              fadeOutSides={useDesktopLayout}
              innerClassName={styles.recentInspectionsCarouselInner}
            >
              {latestInspectionLogs.map((log) => {
                return (
                  <Slide key={log.id} className={styles.recentInspectionLogSlide}>
                    <ColumnCard asset={log} assetType='log' key={log.id} />
                  </Slide>
                );
              })}
            </Carousel>
          )}
        </Progressable>
      </>
    </ContentLayout>
  );
}

ProductWorkSection.propTypes = {
  editMode: PropTypes.bool,
  item: PropTypes.object,
  itemMode: PropTypes.bool,
  product: PropTypes.object.isRequired,
};

/* Helpers */

const ACTIVE_LOGS_TAB = {
  ITEM: 'ITEM',
  PRODUCT: 'PRODUCT',
};
