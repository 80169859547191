import PropTypes from 'prop-types';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { crop as cropImage, getDataURLFromFile } from 'OK/util/functions';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * Crop images.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.cropButtonText] The text for the crop button. Default will be "Set image".
 * @param {object} props.media The MediaAsset object.
 * @param {() => void} props.onCancel The cancel event handler.
 * @param {(mediaAsset) => void} props.onCrop The crop event handler.
 */
export default function ImageCropper(props) {
  /* Variables */

  const {
    className,
    cropButtonText,
    file,
    fileName: _fileName,
    fileType: _fileType,
    imageUrl: _imageUrl,
    onCancel,
    onCrop,
    ...otherProps
  } = props;
  const { t } = useI18n();
  const containerRef = useRef(null);
  const imgRef = useRef(null);
  const maxZoom = 10;
  const fileName = file ? file.name : _fileName;
  const fileType = file ? file.type : _fileType;
  const imageUrl = useMemo(() => {
    if (file) {
      return getDataURLFromFile(file);
    }

    return _imageUrl;
  }, [_imageUrl, file]);

  /* State */

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedArea, setCroppedArea] = useState(null);
  const [cropSize, setCropSize] = useState({ height: 100, width: 100 });
  const [fadeOverlay, setFadeOverlay] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [minZoom, setMinZoom] = useState(1);
  const [zoom, setZoom] = useState(1);

  /* Methods */

  const croppedAreaUpdated = useCallback((_, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);
  const cropFinalized = async () => {
    try {
      const croppedImage = await cropImage(imageUrl, croppedArea, undefined, fileType, fileName);
      if (onCrop) {
        onCrop(croppedImage);
      }
    } catch (e) {
      okerror('Error cropping image.', e);
    }
  };

  /* Effects */

  // Set correct crop size and zoom
  useLayoutEffect(() => {
    // Set crop size to equal the size of the container
    const containerRect = containerRef.current.getBoundingClientRect();
    setCropSize({ height: containerRect.height, width: containerRect.width });

    const img = imgRef.current;
    img.onload = () => {
      // Set min and initial zoom so crop tool is full width / height
      let mZoom;
      if (img.width > img.height) {
        // Image width is greater than height, so limit min zoom so height will fill crop tool
        mZoom = img.width / img.height;
      } else if (img.height > img.width) {
        // Image height is greater than width, so limit min zoom so width will fill crop tool
        mZoom = img.height / img.width;
      } else if (containerRect.width > img.width) {
        // Image width is smaller than crop tool, so limit min zoom to make image fill crop tool
        mZoom = containerRect.width / img.width;
      } else if (containerRect.height > img.height) {
        // Image height is smaller than crop tool, so limit min zoom to make image fill crop tool
        mZoom = containerRect.height / img.height;
      } else {
        // Image is sufficiently larger than crop tool, so default to min zoom of 1
        mZoom = 1;
      }

      setZoom(mZoom);
      setMinZoom(mZoom);
      setImgLoaded(true);
    };
  }, [containerRef, imgRef]);

  /* Render */

  let classNames = styles.container;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  const _cropButtonText = cropButtonText || t('CONFIRM_IMAGE_EDIT');

  return (
    <div className={classNames} ref={containerRef} {...otherProps}>
      <img alt={t('IMG_ALT_IMAGE')} ref={imgRef} src={imageUrl} style={{ display: 'none' }} />
      {imgLoaded && (
        <Cropper
          aspect={1}
          crop={crop}
          cropSize={cropSize}
          image={imageUrl}
          maxZoom={maxZoom}
          minZoom={minZoom}
          onCropChange={(c) => setCrop(c)}
          onCropComplete={croppedAreaUpdated}
          onInteractionStart={() => setFadeOverlay(true)}
          onInteractionEnd={() => setFadeOverlay(false)}
          onZoomChange={(z) => setZoom(z)}
          showGrid={false}
          style={{ cropAreaStyle: { border: 'none', boxShadow: 'none' } }}
          zoom={zoom}
        />
      )}
      <div className={styles.overlay} style={{ opacity: fadeOverlay ? 0.1 : 1 }}>
        <div className={styles.zoomContainer}>
          <img alt={t('IMG_ALT_ZOOM_IN')} src='/icons/zoom_in.svg' />
          <input
            className={styles.zoomSlider}
            max={maxZoom}
            min={minZoom}
            onChange={(e) => setZoom(e.target.value)}
            onMouseDown={() => setFadeOverlay(true)}
            onMouseUp={() => setFadeOverlay(false)}
            step={0.1}
            type='range'
            value={zoom}
          />
          <img alt={t('IMG_ALT_ZOOM_OUT')} src='/icons/zoom_out.svg' />
        </div>
        <Button className={styles.cancelButton} linkStyle onClick={onCancel} tint='alert'>
          Cancel
        </Button>
        <p className={styles.instructions}>
          Pan or zoom to crop
          <br />
          to desired view
        </p>
        <Button className={styles.confirmButton} onClick={cropFinalized} tint='creation'>
          {_cropButtonText}
        </Button>
      </div>
    </div>
  );
}

ImageCropper.propTypes = {
  className: PropTypes.string,
  cropButtonText: PropTypes.string,
  file: PropTypes.any,
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  imageUrl: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onCrop: PropTypes.func.isRequired,
};
