import { gql } from '@apollo/client';

import ContactModel from 'OK/models/contact';

export const getContactRoleListQuery = gql`
  query GetContactRoleList {
    contactRoleList: getContactRoleList
  }
`;

export const createManualContactMutation = gql`
mutation CreateManualContact($contactLinkInputList: [ContactLinkInput], $email: String, $name: String!, $phone: String, $role: EContactRole!, $sourceId: String!, $sourceType: EDataType!) {
    contact: createManualContact(contactLinkInputList: $contactLinkInputList, email: $email, name: $name, phone: $phone, role: $role, sourceId: $sourceId, sourceType: $sourceType) {
        ...${ContactModel.fragmentNameContacts}
    }
}
${ContactModel.fragmentContacts}`;

export const linkUserAsContactMutation = gql`
mutation LinkUserAsContact($sourceId: String!, $sourceType: EDataType!, $userId: String!) {
    contact: linkUserAsContact(sourceId: $sourceId, sourceType: $sourceType, userId: $userId) {
        ...${ContactModel.fragmentNameContacts}
    }
}
${ContactModel.fragmentContacts}`;

export const setContactNameMutation = gql`
  mutation SetContactName($name: String!, $contactId: String!) {
    contact: setContactName(name: $name, contactId: $contactId) {
      id
      name
    }
  }
`;

export const setContactPhoneMutation = gql`
  mutation SetContactPhone($phone: String!, $contactId: String!) {
    contact: setContactPhone(phone: $phone, contactId: $contactId) {
      id
      phone
    }
  }
`;

export const setContactRoleMutation = gql`
  mutation SetContactRole($role: EContactRole!, $contactId: String!) {
    contact: setContactRole(role: $role, contactId: $contactId) {
      id
      role
    }
  }
`;

export const setContactEmailMutation = gql`
  mutation SetContactEmail($email: String!, $contactId: String!) {
    contact: setContactEmail(email: $email, contactId: $contactId) {
      id
      email
    }
  }
`;

export const publishContactMutation = gql`
  mutation PublishContact($contactId: String!) {
    contact: publishContact(contactId: $contactId) {
      id
      publishStatus
    }
  }
`;

export const unpublishContactMutation = gql`
  mutation UnpublishContact($contactId: String!) {
    contact: unpublishContact(contactId: $contactId) {
      id
      publishStatus
    }
  }
`;

export const linkAssetOnContactMutation = gql`
mutation LinkAssetOnContact($contactId: String!, $sourceType: EDataType!, $sourceId: String! ) {
    contact: linkAssetOnContact(contactId: $contactId, sourceType: $sourceType, sourceId: $sourceId) {
        ...${ContactModel.fragmentNameContacts}
    }
}
${ContactModel.fragmentContacts}`;

export const unlinkAssetOnContactMutation = gql`
mutation UnlinkAssetOnContact($contactId: String!, $sourceType: EDataType!, $sourceId: String! ) {
    contact: unlinkAssetOnContact(contactId: $contactId, sourceType: $sourceType, sourceId: $sourceId) {
        ...${ContactModel.fragmentNameContacts}
    }
}
${ContactModel.fragmentContacts}`;

export const searchLinkAssetListQuery = gql`
  query SearchSiteOrders(
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $searchString: String
    $ignoreIdListByDataType: [IgnoreIdListByDataTypeInput]
    $editingOrganisationIdList: [String]
  ) {
    search(
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchString: $searchString
      ignoreIdListByDataType: $ignoreIdListByDataType
      editingOrganisationIdList: $editingOrganisationIdList
    ) {
      resultList {
        dataType
        dataId
        orderData {
          id
          name
          REFID
        }
        siteData {
          id
          name
          OKID
        }
      }
    }
  }
`;

export const searchLinkSiteListQuery = gql`
  query SearchSiteOrders(
    $searchPaginationDataByDataType: [SearchPaginationDataByDataTypeInput]
    $searchString: String
    $ignoreIdListByDataType: [IgnoreIdListByDataTypeInput]
    $editingOrganisationIdList: [String]
  ) {
    search(
      searchPaginationDataByDataType: $searchPaginationDataByDataType
      searchString: $searchString
      ignoreIdListByDataType: $ignoreIdListByDataType
      editingOrganisationIdList: $editingOrganisationIdList
    ) {
      resultList {
        dataType
        dataId
        orderData {
          id
          name
          REFID
        }
        siteData {
          id
          name
          OKID
          siteMediaAssetList {
            mediaAsset {
              id
              mediaType
              REFID
              imageData {
                imageURL
                imageName
                baseImageURL
              }
            }
            order
            publishStatus
          }
        }
      }
    }
  }
`;
