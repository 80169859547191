import { API_URL } from './utils';

import countries from 'OK/assets/countries.json';
import { request } from 'OK/networking';
import { getCookie } from 'OK/util/cookie';
import { PERSISTENT_STORAGE_KEY } from 'OK/util/enums/persistentStorageKeys';
import getTimezoneUTCOffset from 'OK/util/functions/getTimezoneUTCOffset';
import { formatLocaleAsLanguageIso, languageByIso } from 'OK/util/i18n';
import SessionManager from 'OK/util/session';
import { getPersistentValue } from 'OK/util/storage';

// API Requests

async function login(username, usernameType, countryCode, antibotToken) {
  const loginType = usernameType.toUpperCase();

  const parameters = {
    antibotToken,
    loginType,
  };

  switch (loginType) {
    case 'EMAIL':
      parameters.email = username;
      break;
    case 'MOBILE':
      parameters.mobile = username;
      parameters.mobileCountryCode = `+${countryCode}`;
      break;
    default:
      throw new Error('Invalid login type', loginType);
  }

  const req = await request(`${API_URL}/user/auth/requestLoginOTP`, {
    addAuthorisationHeaders: false,
    method: 'POST',
    parameters,
  });
  return req;
}

async function logout() {
  const req = await request(`${API_URL}/user/auth/logout`, {
    autoRefreshToken: false,
    method: 'GET',
  });

  if (req.success) {
    SessionManager.endSession();
  }

  return req;
}

async function regenerateLoginCode(username, usernameType, countryCode) {
  const parameters = {
    username,
    type: usernameType,
  };

  // Set optional parameters
  if (countryCode) {
    parameters.countryCode = `+${countryCode}`;
  }

  const req = await request(`${API_URL}/auth/regenerate`, {
    method: 'POST',
    parameters,
  });
  return req.responseData;
}

async function renewSession() {
  const req = await request(`${API_URL}/user/auth/renewAccessToken`, {
    addAuthorisationHeaders: false,
    autoRefreshToken: false,
    method: 'GET',
    maxRetries: 2,
    waitWhileRestoringSession: false,
  });
  return req;
}

async function requestEmailVerificationOTP(email, antibotToken) {
  return await request(`${API_URL}/user/auth/requestEmailVerificationOTP`, {
    addAuthorisationHeaders: false,
    method: 'POST',
    parameters: {
      loginType: 'EMAIL',
      email,
      antibotToken,
    },
  });
}

async function verifyLoginCode(username, usernameType, oneTimePassword, countryPhoneCode, newsletterSubscription) {
  const loginType = usernameType.toUpperCase();

  const countryCode = getPersistentValue(PERSISTENT_STORAGE_KEY.REGION);
  const countryName = countries.find((c) => c.isoAlpha3 === countryCode).name;
  const language = languageByIso(formatLocaleAsLanguageIso(getCookie('NEXT_LOCALE')));
  const timezoneUTCOffset = getTimezoneUTCOffset();
  const parameters = {
    oneTimePassword,
    loginType,
    country: countryName,
    countryCode,
    language: language.name,
    languageCode: language.iso,
    newsletterSubscription: newsletterSubscription,
    timezone: timezoneUTCOffset,
  };

  switch (loginType) {
    case 'EMAIL':
      parameters.email = username;
      break;
    case 'MOBILE':
      parameters.mobile = username;
      parameters.mobileCountryCode = `+${countryPhoneCode}`;
      break;
    default:
      throw new Error('Invalid loginType:', loginType);
  }

  const req = await request(`${API_URL}/user/auth/loginWithOTP`, {
    addAuthorisationHeaders: false,
    method: 'POST',
    parameters,
  });

  if (req.success) {
    SessionManager.startSession(req.responseData.accessToken);
  }

  return req;
}

// Response statuses
const CAPTCHA_FAILED = 'ANTIBOT_VERIFICATION_FAILED';
const LOGIN_STATUS_BLOCKED_DUE_TO_MULTIPLE_TRIES = 'USER_BLOCKED_FOR_MULTIPLE_TRY';
const LOGIN_STATUS_CODE_ALREADY_SENT_BY_EMAIL = 'VERIFICATION_CODE_ALREADY_SENT_FOR_LOGIN_BY_EMAIL';
const LOGIN_STATUS_CODE_ALREADY_SENT_BY_MOBILE = 'VERIFICATION_CODE_ALREADY_SENT_FOR_LOGIN_BY_MOBILE';
const LOGIN_STATUS_CODE_SENT_AGAIN_BY_EMAIL = 'VERIFICATION_CODE_ONCE_AGAIN_SENT_FOR_LOGIN_BY_EMAIL';
const LOGIN_STATUS_CODE_SENT_AGAIN_BY_MOBILE = 'VERIFICATION_CODE_ONCE_AGAIN_SENT_FOR_LOGIN_BY_MOBILE';
const LOGIN_STATUS_CODE_SENT_EMAIL = 'VERIFICATION_CODE_SENT_FOR_LOGIN_EMAIL';
const LOGIN_STATUS_CODE_SENT_MOBILE = 'VERIFICATION_CODE_SENT_FOR_LOGIN_MOBILE';
const LOGOUT_STATUS_SUCCESS = 'USER_LOGOUT';
const JWT_TOKEN_EXPIRED = 'JWT token is expired';
const REFRESH_TOKEN_INVALID = 'REFRESH_TOKEN_INVALID';
const REFRESH_TOKEN_UPDATED = 'REFRESH_TOKEN_UPDATED';
// TODO: Handle this error case
const VERIFY_STATUS_CODE_ALREADY_USED = 'VERIFICATION_CODE_ALREADY_VERIFIED_FOR_LOGIN';
const VERIFY_STATUS_EXPIRED_CODE = 'VERIFICATION_CODE_EXPIRED';
const VERIFY_STATUS_INVALID_CODE = 'TOKEN_INVALID';
const VERIFY_STATUS_NO_CODE_FOR_USER = 'NO_CODE_ASSOCIATED_WITH_USER';
const VERIFY_STATUS_SUCCESS = 'REFRESH_TOKEN_CREATED';

export {
  // API Requests
  login,
  logout,
  regenerateLoginCode,
  renewSession,
  requestEmailVerificationOTP,
  verifyLoginCode,
  // Response statuses
  CAPTCHA_FAILED,
  LOGIN_STATUS_BLOCKED_DUE_TO_MULTIPLE_TRIES,
  LOGIN_STATUS_CODE_ALREADY_SENT_BY_EMAIL,
  LOGIN_STATUS_CODE_ALREADY_SENT_BY_MOBILE,
  LOGIN_STATUS_CODE_SENT_AGAIN_BY_EMAIL,
  LOGIN_STATUS_CODE_SENT_AGAIN_BY_MOBILE,
  LOGIN_STATUS_CODE_SENT_EMAIL,
  LOGIN_STATUS_CODE_SENT_MOBILE,
  LOGOUT_STATUS_SUCCESS,
  JWT_TOKEN_EXPIRED,
  REFRESH_TOKEN_INVALID,
  REFRESH_TOKEN_UPDATED,
  VERIFY_STATUS_CODE_ALREADY_USED,
  VERIFY_STATUS_EXPIRED_CODE,
  VERIFY_STATUS_INVALID_CODE,
  VERIFY_STATUS_NO_CODE_FOR_USER,
  VERIFY_STATUS_SUCCESS,
};
