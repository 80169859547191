import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function Separator(props) {
  /* Variables */

  const { className, type = 'subSection', ...otherProps } = props;

  /* Render */

  let classNames = styles.separator;
  switch (type) {
    case 'section':
      classNames = `${classNames} ${styles.section}`;
      break;
    default:
      classNames = `${classNames} ${styles.subSection}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return <div className={classNames} {...otherProps} />;
}

Separator.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['section', 'subSection']),
};
