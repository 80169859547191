import { gql } from '@apollo/client';

import BaseOrganisationModel from '../organisation/base';
import BaseUserDocumentModel from '../userDocument/base';

export default class BaseUserModel {
  static GRAPHQL_TYPE = 'User';

  static fragmentName = 'BaseUser_Fragment';
  static fragment = gql`
    fragment ${BaseUserModel.fragmentName} on ${BaseUserModel.GRAPHQL_TYPE} {
      OKID
      avatar {
        id
        name
        skin
        priority
        sourceLightURL
        sourceDarkURL
      }
      createdBy
      createdDate
      email
      id
      lastModifiedBy
      lastModifiedDate
      metadata
      mobile
      mobileCountryCode
      name
      roleList
      status
      version
      publishedLogs
      reliabilityPointForAllLogs
      reliabilityPointForPublishedLogs
      totalLogs
      userDocumentAssetList {
        ...${BaseUserDocumentModel.fragmentName}
        organisation {
        ...${BaseOrganisationModel.fragmentName}
        }
        madeByOrganisation {
          id
          conformityPoint
          logoImageMediaAsset {
          logoImageURL
           }
        name
        OKID
        reliabilityPointForPublishedLogs
      }
      }
    }
    ${BaseUserDocumentModel.fragment}
    ${BaseOrganisationModel.fragment}
  `;
}
