import { gql } from '@apollo/client';

import localizeObject from 'OK/util/functions/localizeObject';

export default class OrderModel {
  static GRAPHQL_TYPE = 'Order';

  static fragmentIdentifiersName = 'Order_Identifiers_Fragment';
  static fragmentIdentifiers = gql`
    fragment ${OrderModel.fragmentIdentifiersName} on ${OrderModel.GRAPHQL_TYPE} {
      id
      orderIdentifierList {
        id
        orderIdentifier
      }
    }
  `;

  static link(order) {
    return `/archive/order/${order.REFID}/edit`;
  }

  static localizedNameForOrder = (order, locale) => {
    return localizeObject(order.name, locale, true)?.text ?? 'Unnamed';
  };
}
