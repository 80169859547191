import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import useBrowserRenderOnly from 'OK/util/hooks/useBrowserRenderOnly';

export default function Menu(props) {
  const { children } = props;
  const renderForBrowser = useBrowserRenderOnly();

  if (!renderForBrowser) {
    return null;
  }

  const menu = <div className={styles.menu}>{children}</div>;

  return createPortal(menu, document.getElementById('app_menus'));
}
