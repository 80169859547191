import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

/**
 * Render buttons fixed to the bottom of the screen.
 *
 * @param {object} props
 * @param {Node} [props.children] The buttons or elements to render.
 * @param {string} [props.className] The container's class.
 * @param {string} [props.innerClassName] The inner container's class.
 */
export default function FloatingButtonsContainer(props) {
  const { children, className, innerClassName, ...otherProps } = props;

  const isShowingStatusBanner = useSelector((state) => state.app.isShowingStatusBanner);
  const tabsAreVisible = useSelector((state) => state.app.tabsAreVisible);

  const stopPropagation = (e) => e.stopPropagation();

  let classNames = styles.container;

  if (tabsAreVisible) {
    classNames = `${classNames} ${styles.withMenuOffset}`;
  }
  if (isShowingStatusBanner) {
    classNames = `${classNames} ${styles.withStatusBannerOffset}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let innerClassNames = styles.innerContainer;
  if (innerClassName) {
    innerClassNames = `${innerClassNames} ${innerClassName}`;
  }

  return (
    <div className={classNames} onClick={stopPropagation} {...otherProps}>
      <div className={innerClassNames}>{children}</div>
    </div>
  );
}

FloatingButtonsContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
};
