import { preFetchMiddleware, postFetchMiddleware } from 'OK/networking/middleware';
import { trackError } from 'OK/util/analytics';

/** Wrapper class to make `fetch` requests. */
class Request {
  /**
   * Create a `Request`.
   *
   * @param {string} resource The url of the request.
   * @param {object} [options] Options for the request.
   * @param {boolean} [options.autoRefreshToken=true] If the request fails due to an expired JWT token, automatically
   * refresh the token and retry the request.
   * @param {string} [options.contentType='application/json'] The content type of the request body.
   * @param {object} [options.parameters={}] Request parameters.
   */
  constructor(resource, options) {
    // Parse class options and use other options for the request
    const {
      addActiveOrganisationIdHeader = true,
      addAuthorisationHeaders = true,
      autoRefreshToken = true,
      compressImages = true,
      contentType = 'application/json',
      maxRetries = 0,
      parameters = {},
      parseResponseAs = 'auto',
      waitWhileRestoringSession = true,
      ...otherOptions
    } = options;

    // Configuration
    this.addActiveOrganisationIdHeader = addActiveOrganisationIdHeader;
    this.addAuthorisationHeaders = addAuthorisationHeaders;
    this.autoRefreshToken = autoRefreshToken;
    this.compressImages = compressImages;
    this.contentType = contentType;
    this.maxRetries = maxRetries;
    this.parseResponseAs = parseResponseAs;
    this.waitWhileRestoringSession = waitWhileRestoringSession;

    // Store original values for retries
    this._options = otherOptions;
    this._resource = resource;

    // State values
    this.enableDebug = false;
    this.error = null;
    this.numberOfRetries = 0;
    this.options = otherOptions;
    this.parameters = parameters;
    this.resource = resource;
    this.response = null;
    this.responseData = null;
    this.success = false;
  }

  debug(...message) {
    if (this.enableDebug) {
      okdebug(...message);
    }
  }

  /** Make the request. */
  async fetch() {
    const that = this;
    function recordError(error, additionalContext = {}) {
      trackError(error, {
        tags: {
          area: 'API',
        },
        extra: {
          parameters: that.parameters,
          resource: that.resource,
          ...additionalContext,
        },
      });
    }

    this.debug('Fetching...');
    try {
      const opts = {
        ...this.options,
        credentials: 'include',
      };
      this.debug('Fetch this.options', opts);
      this.response = await fetch(this.resource, opts);

      // Determine response parsing method
      let parseResponseAs;
      if (this.parseResponseAs === 'auto') {
        const responseContentType = this.response.headers.get('Content-Type');
        // Read the response as json if possible
        if (responseContentType.includes('application/json')) {
          parseResponseAs = 'json';
        } else {
          parseResponseAs = 'text';
        }
      } else {
        parseResponseAs = this.parseResponseAs;
      }

      // Parse response
      if (parseResponseAs === 'json') {
        const responseJson = await this.response.json();
        this.responseData = responseJson?.data;
        this.success = responseJson?.success ?? false;
        this.error = responseJson?.error;
      } else if (parseResponseAs === 'blob') {
        this.responseData = await this.response.blob();
        this.success = this.response.status === 200;
        this.error = null;
      } else {
        this.responseData = await this.response.text();
        this.success = this.response.status === 200;
        this.error = null;
      }

      if (!this.success) {
        if (this.shouldRetry()) {
          return await this.retry();
        }

        // Log API errors
        const apiError = new Error(`API error at ${this.resource}`);
        recordError(apiError, {
          responseData: this.responseData,
          status: this.response.status,
        });
      }
    } catch (error) {
      okerror('Fetch error:', error);

      if (this.shouldRetry()) {
        return await this.retry();
      }

      // Log API error
      recordError(error);
    }
  }

  /** Run post-request middleware. */
  async postFetch() {
    await this.runMiddleware(postFetchMiddleware);
  }

  /** Run pre-request middleware. */
  async preFetch() {
    await this.runMiddleware(preFetchMiddleware);
  }

  /** Retry the request. */
  async retry() {
    this.debug(`Retrying request ${this._resource}`);
    this.numberOfRetries += 1;
    // Reset and try again
    this.options = this._options;
    this.resource = this._resource;
    this.response = null;
    this.responseData = null;
    await this.run();
    this.debug('Finished retrying request');
  }

  /** Make the request and run middleware. */
  async run() {
    await this.preFetch();
    await this.fetch();
    await this.postFetch();
  }

  /** Run request middleware. */
  async runMiddleware(middlewares) {
    for (const middleware of middlewares) {
      await middleware(this);
    }
  }

  /** Determine if the request should be retried. */
  shouldRetry() {
    return (
      this.numberOfRetries < this.maxRetries && // Ensure we haven't already hit the retry limit.
      (!this.response || (this.response.status >= 500 && this.response.status <= 599)) // Retry only for certain types of request failures, such as network or server errors.
    );
  }
}

export default Request;
