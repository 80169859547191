import PropTypes from 'prop-types';

import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from '../popup';

import { ProductCondensedArchiveCard } from './ProductCondensedArchiveCard';
import styles from './styles.module.scss';



export default function MapPopup(props) {
  const { dismiss, productInfoArray } = props;

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.mapPopupContainer}>
        {productInfoArray.map((productInfo, index) => (
          <>
            <ProductCondensedArchiveCard
              product={productInfo.__typename === 'ProductChildProduct' ? productInfo.childProduct : productInfo}
              key={`${productInfo.SKUID}+${index}`}
            />
            <br />
          </>
        ))}
      </PopupContent>
      <PopupButtonsGroup>
        <PopupCloseButton>Close</PopupCloseButton>
      </PopupButtonsGroup>
    </Popup>
  );
}

MapPopup.propTypes = {
  dismiss: PropTypes.func,
  productInfoArray: PropTypes.any,
};
