import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function VideoMenuItem(props) {
  const { className, icon, tint, title, ...otherProps } = props;

  let classNames = styles.container;
  switch (tint) {
    case 'alert':
    case 'navigation':
      classNames = `${classNames} ${styles[tint]}`;
      break;
    default:
      break;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <button className={classNames} {...otherProps}>
      <p>{title}</p>
      {icon && <img alt='' src={icon} />}
    </button>
  );
}

VideoMenuItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  tint: PropTypes.oneOf(['alert', 'navigation']),
  title: PropTypes.string.isRequired,
};
