import PropTypes from 'prop-types';
import { forwardRef, useContext } from 'react';

import styles from './styles.module.scss';

import UIContext from 'OK/util/context/ui';

const CardLayout = forwardRef((props, forwardedRef) => {
  const {
    accessoryView,
    children,
    className,
    contextOverride,
    fixedWidth = true,
    innerClassName,
    padded = true,
    rounded = true,
    ...otherProps
  } = props;

  const uiContext = useContext(UIContext);

  // Invert back to base context if card is nested inside another card or if the context should be overriden
  const invert = contextOverride ? contextOverride === 'base' : uiContext === 'card';

  // Classes

  let classNames = styles.card;
  if (fixedWidth) {
    classNames = `${classNames} ${styles.fixedWidth}`;
  }
  if (padded) {
    classNames = `${classNames} ${styles.padded}`;
  }
  if (rounded) {
    classNames = `${classNames} ${styles.rounded}`;
  }
  if (invert) {
    classNames = `${classNames} ${styles.invert}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let innerClassNames = styles.children;
  if (innerClassName) {
    innerClassNames = `${innerClassNames} ${innerClassName}`;
  }

  return (
    <UIContext.Provider value={invert ? 'base' : 'card'}>
      <div className={classNames} ref={forwardedRef} {...otherProps}>
        <div className={innerClassNames}>{children}</div>
        {accessoryView && <div className={styles.accessoryView}>{accessoryView}</div>}
      </div>
    </UIContext.Provider>
  );
});

CardLayout.propTypes = {
  accessoryView: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  contextOverride: PropTypes.oneOf(['base']),
  fixedWidth: PropTypes.bool,
  innerClassName: PropTypes.string,
  padded: PropTypes.bool,
  rounded: PropTypes.bool,
};

export default CardLayout;
