import PropTypes from 'prop-types';
import { useContext } from 'react';

import styles from './styles.module.scss';
import baseStyles from './tints/base.module.scss';
import cardStyles from './tints/card.module.scss';

import UIContext from 'OK/util/context/ui';

/**
 * A badge-like component which can be toggled.
 *
 * @param {object} props
 * @param {Node} props.children
 * @param {string} [props.className]
 * @param {(selected: bool) => {}} [props.onChange] Event handler when the chip is toggled.
 * @param {boolean} [props.selected=false] Mark the chip as selected.
 *
 */
export default function Chip(props) {
  const { children, className, onChange, selected = false, ...otherProps } = props;
  const uiContext = useContext(UIContext);

  const onClick = () => {
    if (onChange) {
      onChange(!selected);
    }
  };

  let tintStyles;
  switch (uiContext) {
    case 'card':
      tintStyles = cardStyles;
      break;
    default:
      tintStyles = baseStyles;
      break;
  }

  let classNames = `${styles.chip} ${tintStyles.chip}`;
  if (selected) {
    classNames = `${classNames} ${styles.selected} ${tintStyles.selected}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <div className={classNames} onClick={onClick} {...otherProps}>
      {!selected && <span className={styles.unselectedBorder} />}
      {children}
    </div>
  );
}

Chip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
};
