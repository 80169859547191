import PropTypes from 'prop-types';
import { cloneElement } from 'react';

import styles from './styles.module.scss';

import Text from 'OK/components/text';

/**
 * @typedef {Object} ArchiveCardHeaderProps
 * @prop {Node} [tag] Display a tag.
 * @prop {string} type The type of asset being displayed.
 */

/**
 * First row of main content.
 *
 * @param {ArchiveCardHeaderProps} props
 */
export default function ArchiveCardHeader(props) {
  const { className, tag, type, title = type, titleTint, ...otherProps } = props;

  let headerTag;
  if (tag) {
    let tagClassname = styles.headerTag;
    if (tag.props.className) {
      tagClassname = `${tagClassname} ${tag.props.className}`;
    }
    headerTag = cloneElement(tag, { className: tagClassname });
  }

  // Classes
  let classNames = styles.header;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <div className={classNames} {...otherProps}>
      <Text bold className={styles.type} size='xs' tint={titleTint}>
        {title}
      </Text>
      {headerTag}
    </div>
  );
}

ArchiveCardHeader.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.node,
  title: PropTypes.string,
  titleTint: PropTypes.oneOf(['alert', 'creation', 'navigation', 'notification']),
  type: PropTypes.string,
};
