/* eslint-disable indent */

import { useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import styles from './styles.module.scss';

import Accordion from 'OK/components/accordion';
import Alert from 'OK/components/alert';
import Button from 'OK/components/button';
import ButtonGroup from 'OK/components/buttonGroup';
import { Carousel, Slide } from 'OK/components/carousel';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import Text from 'OK/components/text';
import UserArchiveCard from 'OK/components/user/archiveCard';
import OrganisationModel from 'OK/models/organisation';
import ShareOKIDPopup from 'OK/modules/popups/shareOKID';
import {
  acceptUserJoinRequestMutation,
  cancelOrganisationUserInviteMutation,
  inviteUsersToOrganisationMutation,
  inviteUserToOrganisationMutation,
  makeUserACoworkerMutation,
  makeUserAManagerMutation,
  makeUserAnOwnerMutation,
  rejectUserJoinRequestMutation,
  removeOrganisationUserMutation,
} from 'OK/networking/organisations';
import { SearchUsersQuery } from 'OK/networking/search';
import { showScannerAction } from 'OK/state/app/actions';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import { formatOkid, unformatOkid } from 'OK/util/formatting';
import { setNativeValue } from 'OK/util/functions';
import isAuthorised from 'OK/util/functions/isAuthorised';
import { isOKID } from 'OK/util/functions/OKID';
import { encodeQueryParametersFromObject } from 'OK/util/functions/url';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';

const SEARCH_MODE = {
  FILTER_CURRENT: 'FILTER_CURRENT',
  SEARCH_NEW: 'SEARCH_NEW',
};

function roleLabelForRole(role, t) {
  switch (role) {
    case OrganisationModel.ROLE.COWORKER:
    case OrganisationModel.ROLE.MANAGER:
    case OrganisationModel.ROLE.OWNER:
      return t(`ROLE_${role}`);
    case OrganisationModel.ROLE.APPLIED:
      return t('APPLIED_TO_JOIN_ORGANISATION');
    case OrganisationModel.ROLE.INVITED_COWORKER:
      return t('INVITED_AS_COWORKER');
    case OrganisationModel.ROLE.INVITED_MANAGER:
      return t('INVITED_AS_MANAGER');
    case 'ORGANISATION_MANAGER':
      return t('INVITE_AS_MANAGER');
    case 'ORGANISATION_COWORKER':
      return t('INVITE_AS_COWORKER');
    default:
      return null;
  }
}

function roleLabelForRoleIndication(role, t) {
  switch (role) {
    case OrganisationModel.ROLE.COWORKER:
    case OrganisationModel.ROLE.MANAGER:
    case OrganisationModel.ROLE.OWNER:
      return t(`ROLE_${role}`);
    case OrganisationModel.ROLE.APPLIED:
      return t('APPLIED_TO_JOIN_ORGANISATION');
    case OrganisationModel.ROLE.INVITED_COWORKER:
      return t('ROLE_COWORKER');
    case OrganisationModel.ROLE.INVITED_MANAGER:
      return t('ROLE_MANAGER');
    case 'ORGANISATION_MANAGER':
      return t('ROLE_MANAGER');
    case 'ORGANISATION_COWORKER':
      return t('ROLE_COWORKER');
    default:
      return null;
  }
}

function RoleLabelForRole(props) {
  const { role } = props;
  const { t } = useI18n();

  return roleLabelForRole(role, t);
}

export default function OrganisationMembersSection(props) {
  /* Variables */
  const { activeOrganisation, openSection, showSection } = props;
  const [authenticated, , currentUser] = useAuthentication();

  const { ref } = props;
  const { t, tHTML } = useI18n();
  const authorisationlevel = useAuthorisationLevel(activeOrganisation);
  const dispatch = useDispatch();
  const router = useRouter();
  const scrollToSection = router.query.section;
  const isOwner = isAuthorised(authorisationlevel, AUTHORISATION_LEVEL.OWNER);
  const OKID = activeOrganisation?.OKID ? formatOkid(activeOrganisation.OKID) : null;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const fullUsersList = useMemo(() => {
    if (!activeOrganisation) {
      return [];
    }

    return [
      ...activeOrganisation.userJoinRequestList,
      ...activeOrganisation.userList,
      ...activeOrganisation.inviteUserList,
    ];
  }, [activeOrganisation]);

  // State

  const [changeRoleError, setChangeRoleError] = useState(null);
  const [changeRoleUser, setChangeRoleUser] = useState(null);
  const [changeInviteeRoleUser, setChangeInviteeRoleUser] = useState(null);

  const [inviteeRole, setInviteeRole] = useState('ORGANISATION_COWORKER');

  const [manageError, setManageError] = useState(null);

  const [showOKIDPopup, setShowOKIDPopup] = useState(false);
  const [userSearchMode, setUserSearchMode] = useState(SEARCH_MODE.SEARCH_NEW);
  const [userSearchString, setUserSearchString] = useState('');

  // Refs

  const billsSectionRef = useRef();
  const planSectionRef = useRef();
  const searchUsersInputRef = useRef();

  /* API */

  const [acceptUserJoinRequestAPI] = useMutation(acceptUserJoinRequestMutation);
  const [cancelInvitationAPI] = useMutation(cancelOrganisationUserInviteMutation);
  const [inviteNewUsersAPI] = useMutation(inviteUsersToOrganisationMutation);
  const [inviteUserAPI] = useMutation(inviteUserToOrganisationMutation);
  const [makeUserACoworkerAPI] = useMutation(makeUserACoworkerMutation);
  const [makeUserAManagerAPI] = useMutation(makeUserAManagerMutation);
  const [makeUserAnOwnerAPI] = useMutation(makeUserAnOwnerMutation);
  const [rejectUserJoinRequestAPI] = useMutation(rejectUserJoinRequestMutation);
  const [removeUserAPI] = useMutation(removeOrganisationUserMutation);
  const [searchUsersAPI, searchUsersAPIResult] = useLazyQuery(SearchUsersQuery);

  /* Methods */

  const acceptUserJoinRequest = useCallback(
    (userId, setLoading) => {
      setManageError(null);
      setLoading(true);
      acceptUserJoinRequestAPI({ variables: { userId } })
        .catch(() => {
          setManageError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [acceptUserJoinRequestAPI, t]
  );

  const cancelInvitation = useCallback(
    (userId, setLoading) => {
      setLoading(true);
      cancelInvitationAPI({
        variables: { userId },
      }).finally(() => {
        setLoading(false);
      });
    },
    [cancelInvitationAPI]
  );

  const changeUserRole = useCallback(
    (userId) => {
      setChangeRoleUser(
        activeOrganisation.userList.find((u) => u.id === userId) ||
          activeOrganisation.inviteUserList.find((u) => u.id === userId)
      );
    },
    [activeOrganisation?.inviteUserList, activeOrganisation?.userList]
  );

  const changeInviteeRole = useCallback(() => {
    setChangeInviteeRoleUser(true);
  }, []);

  const inviteNewUser = useCallback(
    (user, setLoading) => {
      setLoading(true);
      inviteNewUsersAPI({
        variables: {
          invitations: [{ email: user, inviteType: 'EMAIL', inviteeRole: inviteeRole }],
        },
      })
        .then(() => {
          setUserSearchString('');
          setInviteeRole('ORGANISATION_COWORKER');
        })
        .catch(() => {
          setManageError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [inviteNewUsersAPI, inviteeRole, t]
  );

  const inviteUser = useCallback(
    (userId, setLoading) => {
      setManageError(null);
      setLoading(true);
      inviteUserAPI({ variables: { userId } })
        .then(() => {
          setUserSearchString('');
        })
        .catch(() => {
          setManageError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [inviteUserAPI, t]
  );

  const rejectUserJoinRequest = useCallback(
    (userId, setLoading) => {
      setManageError(null);
      setLoading(true);
      rejectUserJoinRequestAPI({ variables: { userId } })
        .catch(() => {
          setManageError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [rejectUserJoinRequestAPI, t]
  );

  const removeUser = useCallback(
    (userId, setLoading) => {
      setManageError(null);
      setLoading(true);
      removeUserAPI({ variables: { userId } })
        .catch(() => {
          setManageError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [removeUserAPI, t]
  );

  const searchUsersDebounced = useMemo(
    () =>
      debounce((searchString) => {
        const searchQuery = isOKID(searchString) ? unformatOkid(searchString) : searchString;
        searchUsersAPI({
          variables: {
            ignoreIdListByDataType: [{ dataType: 'USER', ignoreIdList: fullUsersList.map((u) => u.id) }],
            searchPaginationDataByDataType: [
              {
                dataType: 'USER',
                searchPaginationData: { pageSize: 10, skip: 0 },
              },
            ],
            searchString: searchQuery,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [fullUsersList, searchUsersAPI]
  );

  const setUserRole = useCallback(
    (newRole, setLoading) => {
      setLoading(true);
      let request;
      if (newRole === OrganisationModel.ROLE.OWNER) {
        request = makeUserAnOwnerAPI({ variables: { userId: changeRoleUser.id } });
      } else if (newRole === OrganisationModel.ROLE.MANAGER) {
        request = makeUserAManagerAPI({ variables: { userId: changeRoleUser.id } });
      } else {
        request = makeUserACoworkerAPI({ variables: { userId: changeRoleUser.id } });
      }

      request
        .then(() => {
          setChangeRoleUser(null);
        })
        .catch(() => {
          setChangeRoleError(t('ERROR_GENERIC'));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [changeRoleUser?.id, makeUserACoworkerAPI, makeUserAManagerAPI, makeUserAnOwnerAPI, t]
  );

  const setInviteRole = useCallback((role) => {
    setInviteeRole(role);
    setChangeInviteeRoleUser(false);
  }, []);

  // Event handlers

  const onScanUserOKID = useCallback((okid) => {
    // Not sure why timeout is required to make this work
    setTimeout(() => {
      setNativeValue(searchUsersInputRef.current, okid);
      searchUsersInputRef.current.focus();
    }, 0);
  }, []);

  const onSearchUsers = useCallback(
    (e) => {
      setManageError(null);
      const newSearchString = e.target.value;
      setUserSearchString(newSearchString);

      if (userSearchMode === SEARCH_MODE.SEARCH_NEW && newSearchString.length > 1) {
        searchUsersDebounced(newSearchString);
      }
    },
    [searchUsersDebounced, userSearchMode]
  );

  const showUserOKIDScanner = useCallback(() => {
    dispatch(showScannerAction(onScanUserOKID));
  }, [dispatch, onScanUserOKID]);

  /* Effects */

  // Auto scroll to section
  useEffect(() => {
    if (authenticated && activeOrganisation && scrollToSection && router.pathname === '/organisation/[OKID]') {
      let element;
      if (scrollToSection === 'billing') {
        element = billsSectionRef.current;
      } else if (scrollToSection === 'plan') {
        element = planSectionRef.current;
      }

      if (element) {
        // Timeout needed to prevent page re-scrolling to top
        setTimeout(() => {
          window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
        }, 250);
      }

      // Remove section query parameter
      const newUrlQuery = { ...router.query };
      delete newUrlQuery.section;
      const newUrlQueryString = encodeQueryParametersFromObject(newUrlQuery);
      if (newUrlQueryString.length) {
        router.replace(`/${router.pathname}?${newUrlQueryString}`);
      } else {
        router.replace(router.pathname);
      }
    }
  }, [activeOrganisation, authenticated, router, scrollToSection]);

  /* Render */

  const userResultsList = useMemo(() => {
    if (userSearchMode === SEARCH_MODE.SEARCH_NEW && userSearchString.length) {
      return searchUsersAPIResult.data?.search?.resultList?.map((r) => r.userData) ?? [];
    } else if (userSearchMode === SEARCH_MODE.FILTER_CURRENT && userSearchString.length) {
      const regex = new RegExp(userSearchString, 'i');
      return fullUsersList.filter((u) => {
        let OKIDMatches = false;
        if (u.OKID) {
          OKIDMatches = u.OKID.match(regex) || formatOkid(u.OKID).match(regex);
        }
        return OKIDMatches || u.email?.match(regex) || u.mobile?.match(regex);
      });
    }

    return fullUsersList;
  }, [fullUsersList, searchUsersAPIResult.data?.search?.resultList, userSearchMode, userSearchString]);

  const showInviteNewUser = userSearchString.length && validator.isEmail(userSearchString);

  if (!authenticated || !activeOrganisation) {
    return (
      <ContentLayout className={styles.identificationSection}>
        <TextLayout>
          <Icon height={40} name={ICONS.SPINNER.name} width={40} />
        </TextLayout>
      </ContentLayout>
    );
  }

  return (
    <>
      <Accordion
        className={styles.accordion}
        fixedWidth={false}
        onChangeOpen={openSection}
        headerClassName={styles.headerAccordion}
        hideSection
        open={showSection}
        title={
          <div className={styles.header}>
            <Icon className={styles.icon} name={ICONS.PROFILE.name} height={24} width={24} />
            &nbsp;&nbsp;
            {t('ACCOUNT_SECTION_MANAGE')}
          </div>
        }
      >
        <ContentLayout className={styles.manageSection} ref={ref} pageContent>
          <h4>{t('ACCOUNT_SECTION_INDIVIDUALS')}</h4>
          <Text className={styles.sectionDescription}>{tHTML('ACCOUNT_SECTION_INDIVIDUALS_DESCRIPTION')}</Text>
          <ButtonGroup buttonStyle='separate' className={styles.searchModeButtons}>
            <button
              active={userSearchMode === SEARCH_MODE.SEARCH_NEW}
              onClick={() => setUserSearchMode(SEARCH_MODE.SEARCH_NEW)}
            >
              {t('SEARCH_FOR_NEW')}
            </button>
            <button
              active={userSearchMode === SEARCH_MODE.FILTER_CURRENT}
              onClick={() => setUserSearchMode(SEARCH_MODE.FILTER_CURRENT)}
            >
              {t('FILTER_CURRENT')}
            </button>
          </ButtonGroup>
          <Input
            accessoryButton={
              <Button icon={ICONS.CAMERA.name} linkStyle onClick={showUserOKIDScanner} tint='navigation' />
            }
            className={styles.searchInput}
            onChange={onSearchUsers}
            onClear={() => setInviteeRole('ORGANISATION_COWORKER')}
            placeholder={t('USER_SEARCH_PLACEHOLDER')}
            ref={searchUsersInputRef}
            value={userSearchString}
          />
          {manageError && (
            <Text className={styles.manageErrorMessage} size='sm' tint='notification'>
              {manageError}
            </Text>
          )}

          {userResultsList.length || showInviteNewUser ? (
            <Carousel
              className={styles.usersCarouselContainer}
              fadeOutSides={useDesktopLayout}
              innerClassName={styles.usersCarousel}
            >
              {showInviteNewUser && (
                <Slide className={styles.userSlide} key={userSearchString}>
                  <UserArchiveCard
                    cardClassName={styles.userCard}
                    className={styles.userCardContainer}
                    fixedWidth={useDesktopLayout}
                    onClickInvite={inviteNewUser}
                    onClickChangeRole={changeInviteeRole}
                    role={<RoleLabelForRole role={inviteeRole} />}
                    showManageOptions
                    showInviteButton
                    showChangeRoleButton
                    user={userSearchString}
                  />
                </Slide>
              )}
              {userResultsList.map((u) => {
                const roleCheckUser = u?.id === currentUser?.id ? currentUser : u;
                const role = OrganisationModel.roleForUser(roleCheckUser, activeOrganisation);
                const roleLabel = <RoleLabelForRole role={role} />;
                let showApproveButton = false;
                let showCancelInvitationButton = false;
                let showChangeRoleButton = false;
                let showInviteButton = false;
                let showRemoveButton = false;
                let showRejectButton = false;
                // Add management buttons
                if (isAuthorised(authorisationlevel, AUTHORISATION_LEVEL.MANAGER)) {
                  switch (role) {
                    case OrganisationModel.ROLE.COWORKER:
                    case OrganisationModel.ROLE.MANAGER:
                      showChangeRoleButton = u.id !== currentUser?.id;
                      showRemoveButton = u.id !== currentUser?.id;
                      break;
                    case OrganisationModel.ROLE.OWNER:
                      break;
                    case OrganisationModel.ROLE.APPLIED:
                      showApproveButton = true;
                      showRejectButton = true;
                      break;
                    case OrganisationModel.ROLE.INVITED_COWORKER:
                      showCancelInvitationButton = true;
                      showChangeRoleButton = true;
                      break;
                    case OrganisationModel.ROLE.INVITED_MANAGER:
                      showCancelInvitationButton = true;
                      showChangeRoleButton = true;
                      break;
                    default:
                      showInviteButton = true;
                      break;
                  }
                }
                return (
                  <Slide className={styles.userSlide} key={u.id}>
                    <UserArchiveCard
                      cardClassName={styles.userCard}
                      className={styles.userCardContainer}
                      fixedWidth={useDesktopLayout}
                      onClickApprove={acceptUserJoinRequest}
                      onClickCancelInvitation={cancelInvitation}
                      onClickChangeRole={changeUserRole}
                      onClickInvite={inviteUser}
                      onClickReject={rejectUserJoinRequest}
                      onClickRemove={removeUser}
                      role={roleLabel}
                      showApproveButton={showApproveButton}
                      showCancelInvitationButton={showCancelInvitationButton}
                      showChangeRoleButton={showChangeRoleButton}
                      showInviteButton={showInviteButton}
                      showManageOptions
                      showRemoveButton={showRemoveButton}
                      showRejectButton={showRejectButton}
                      user={u}
                    />
                  </Slide>
                );
              })}
            </Carousel>
          ) : (
            <TextLayout>
              <Text>{searchUsersAPIResult.loading ? t('SEARCHING_FOR_USERS') : t('NO_USERS_FOUND')}</Text>
            </TextLayout>
          )}
        </ContentLayout>
      </Accordion>

      {showOKIDPopup && (
        <ShareOKIDPopup
          dismiss={() => setShowOKIDPopup(false)}
          instructions={t('SCAN_TO_APPLY_OR_VIEW_ORG')}
          name={activeOrganisation?.name}
          OKID={OKID}
          profilePhotoUrl={activeOrganisation?.logoImageMediaAsset?.logoImageURL}
        />
      )}
      {changeRoleUser && (
        <ChangeRoleAlert
          allowSettingOwner={isOwner}
          error={changeRoleError}
          dismiss={() => setChangeRoleUser(null)}
          onChange={setUserRole}
          user={changeRoleUser}
          organisation={activeOrganisation}
        />
      )}
      {changeInviteeRoleUser && (
        <ChangeInviteeRoleAlert
          allowSettingOwner={isOwner}
          dismiss={() => setChangeInviteeRoleUser(null)}
          onChange={setInviteRole}
          role={inviteeRole}
        />
      )}
    </>
  );
}

OrganisationMembersSection.propTypes = {
  activeOrganisation: PropTypes.object,
  ref: PropTypes.any,
  openSection: PropTypes.func,
  showSection: PropTypes.bool,
};

function ChangeRoleAlert(props) {
  const { allowSettingOwner = false, dismiss, error, onChange, user, organisation } = props;
  const { t, tHTML } = useI18n();
  const role = OrganisationModel.roleForUser(user, organisation);
  const allowSettingManager =
    role !== OrganisationModel.ROLE.MANAGER &&
    role !== OrganisationModel.ROLE.OWNER &&
    role !== OrganisationModel.ROLE.INVITED_MANAGER;

  const allowSettingCoworker =
    role !== OrganisationModel.ROLE.COWORKER &&
    role !== OrganisationModel.ROLE.OWNER &&
    role !== OrganisationModel.ROLE.INVITED_COWORKER;

  const invitedUser = role == OrganisationModel.ROLE.INVITED_COWORKER || role == OrganisationModel.ROLE.INVITED_MANAGER;

  // States
  const [isMakingCoworker, setIsMakingCoworker] = useState(false);
  const [isMakingManager, setIsMakingManager] = useState(false);
  const [isMakingOwner, setIsMakingOwner] = useState(false);

  return (
    <Alert
      title={t('CHANGE_USER_ROLE')}
      message={
        <>
          {tHTML('CURRENT_ROLE', {
            data: {
              role: roleLabelForRoleIndication(role, t),
            },
          })}
          {error && (
            <Text size='sm' tint='notification'>
              {error}
            </Text>
          )}
        </>
      }
      buttons={
        <>
          {allowSettingCoworker && (
            <Button
              block
              className={styles.changeRoleButton}
              disabled={isMakingCoworker}
              loading={isMakingCoworker}
              onClick={() => onChange(OrganisationModel.ROLE.COWORKER, setIsMakingCoworker)}
              tint='navigation'
            >
              {t('CHANGE_ROLE_COWORKER')}
            </Button>
          )}
          {allowSettingManager && (
            <Button
              block
              className={styles.changeRoleButton}
              disabled={isMakingManager}
              loading={isMakingManager}
              onClick={() => onChange(OrganisationModel.ROLE.MANAGER, setIsMakingManager)}
              tint='navigation'
            >
              {t('CHANGE_ROLE_MANAGER')}
            </Button>
          )}
          {allowSettingOwner && !invitedUser && (
            <Button
              block
              className={styles.changeRoleButton}
              disabled={isMakingOwner}
              loading={isMakingOwner}
              onClick={() => onChange(OrganisationModel.ROLE.OWNER, setIsMakingOwner)}
              tint='navigation'
            >
              {t('CHANGE_ROLE_OWNER')}
            </Button>
          )}
          <Button block className={styles.changeRoleButton} linkStyle onClick={dismiss} tint='alert'>
            {t('CANCEL')}
          </Button>
        </>
      }
    />
  );
}

ChangeRoleAlert.propTypes = {
  allowSettingOwner: PropTypes.bool,
  dismiss: PropTypes.func,
  error: PropTypes.string,
  onChange: PropTypes.func,
  user: PropTypes.object,
  organisation: PropTypes.object,
};

function ChangeInviteeRoleAlert(props) {
  const { dismiss, error, onChange, role } = props;
  const { t, tHTML } = useI18n();
  const allowSettingManager = role !== 'ORGANISATION_MANAGER';

  const allowSettingCoworker = role !== 'ORGANISATION_COWORKER';

  return (
    <Alert
      title={t('SET_USER_ROLE')}
      message={
        <>
          {tHTML('INVITE_AS', {
            data: {
              role: roleLabelForRoleIndication(role, t),
            },
          })}
          {error && (
            <Text size='sm' tint='notification'>
              {error}
            </Text>
          )}
        </>
      }
      buttons={
        <>
          {allowSettingCoworker && (
            <Button
              block
              className={styles.changeRoleButton}
              onClick={() => onChange('ORGANISATION_COWORKER')}
              tint='navigation'
            >
              {t('CHANGE_ROLE_COWORKER')}
            </Button>
          )}
          {allowSettingManager && (
            <Button
              block
              className={styles.changeRoleButton}
              onClick={() => onChange('ORGANISATION_MANAGER')}
              tint='navigation'
            >
              {t('CHANGE_ROLE_MANAGER')}
            </Button>
          )}

          <Button block className={styles.changeRoleButton} linkStyle onClick={dismiss} tint='alert'>
            {t('CANCEL')}
          </Button>
        </>
      }
    />
  );
}

ChangeInviteeRoleAlert.propTypes = {
  allowSettingOwner: PropTypes.bool,
  dismiss: PropTypes.func,
  error: PropTypes.string,
  role: PropTypes.string,
  onChange: PropTypes.func,
  user: PropTypes.object,
  organisation: PropTypes.object,
};
