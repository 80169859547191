import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';

/**
 * A component intended to display short snippets of text with a small box around it.
 *
 * @param {object} props
 * @param {boolean} [props.block=true] Display the tag as a block element.
 * @param {any} props.children The tag contents.
 * @param {string} [props.className] The class for the tag.
 * @param {boolean} [props.invert=false] Whether to invert the color scheme for the tag. In light theme will result in a dark background and vice-versa.
 * @param {(tag: string) => {}} [props.onClickRemove] Event handler for when the user clicks to remove the tag. Will
 * pass the tag contents or `uniqueId` if present.
 * @param {boolean} [props.removeable=false] Allow the tag to be removed.
 * @param {string} [props.size='xs'] The font size for the tag. Can be 'xs', 'sm', or 'md' and corresponds to the application's standard font sizes.
 * @param {('alert'|'creation'|'navigation'|'notification')} [props.tint] The tint color to use for the tag.
 * @param {string} [props.uniqueId] A unique id for the tag. If present, it will be passed to `onClickRemove` instead
 * of the tag contents.
 */
export default function Tag(props) {
  /* Variables */

  const {
    block = false,
    children,
    className,
    invert = false,
    loading = false,
    onClickRemove,
    removeable = false,
    size = 'xs',
    tint,
    uniqueId,
    ...otherProps
  } = props;

  /* Events */

  const onClickedRemove = () => {
    if (onClickRemove) {
      // Tags are generally used to display short strings. Pass back the children so parent components can identify
      // which tag should be removed.
      onClickRemove(uniqueId || children);
    }
  };

  /* Render */

  const willDisplayIcon = loading || removeable;

  // Setup classes
  let classNames = styles.container;
  if (block) {
    classNames = `${classNames} ${styles.block}`;
  } else {
    classNames = `${classNames} ${styles.inlineBlock}`;
  }
  if (invert) {
    classNames = `${classNames} ${styles.inverted}`;
  }
  switch (size) {
    case 'sm':
    case 'md':
      classNames = `${classNames} ${styles[size]}`;
      break;
    default:
      classNames = `${classNames} ${styles.xs}`;
      break;
  }
  switch (tint) {
    case 'alert':
    case 'creation':
    case 'navigation':
    case 'notification':
      classNames = `${classNames} ${styles[tint]}`;
      break;
    default:
      break;
  }
  if (willDisplayIcon) {
    classNames = `${classNames} ${styles.withIcon}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <span className={classNames} {...otherProps}>
      {children}
      {loading && <Icon className={styles.loadingSpinner} height={12} name={ICONS.SPINNER.name} width={12} />}
      {removeable && !loading && (
        <button className={styles.removeTagButton} onClick={onClickedRemove}>
          <Icon height={14} name={ICONS.X.name} tint='alert' width={14} />
        </button>
      )}
    </span>
  );
}

Tag.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  invert: PropTypes.bool,
  loading: PropTypes.bool,
  onClickRemove: PropTypes.func,
  removeable: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
  tint: PropTypes.oneOf(['alert', 'creation', 'navigation', 'notification']),
  uniqueId: PropTypes.string,
};
