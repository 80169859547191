import PropTypes from 'prop-types';
import { useContext } from 'react';

import styles from './styles.module.scss';

import ThemeContext from 'OK/util/context/theme';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * A component to play audio files.
 *
 * @param {object} props
 * @param {string} [props.className] The audio player's class.
 * @param {string} props.source The URL to the audio file.
 */
export default function AudioPlayer(props) {
  const { className, source } = props;
  const theme = useContext(ThemeContext);
  const { t } = useI18n();

  return (
    <div className={`${styles.layout} ${className || ''}`}>
      <div className={styles.container}>
        <img alt={t('IMG_ALT_WAVEFORM')} className={styles.icon} src={`/icons/audio_${theme.name}.svg`} />
        <audio className={styles.player} controls src={source} />
      </div>
    </div>
  );
}

AudioPlayer.propTypes = {
  className: PropTypes.string,
  source: PropTypes.string.isRequired,
};
