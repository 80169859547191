import { gql } from '@apollo/client';
import { nanoid } from 'nanoid';

import BaseProductModel from '../product/base';

import BaseNoteModel from './base';

import STATUS from 'OK/util/enums/status';
import localizeObject from 'OK/util/functions/localizeObject';

export default class NoteModel {
  static GRAPHQL_TYPE = 'NoteAsset';

  static fragmentName = 'NoteAsset_Fragment';
  static fragment = gql`
    fragment ${NoteModel.fragmentName} on ${NoteModel.GRAPHQL_TYPE} {
      ...${BaseNoteModel.fragmentName}
      linkedProductList {
        ...${BaseProductModel.fragmentName}
      }
    }
    ${BaseNoteModel.fragment}
    ${BaseProductModel.fragment}
  `;

  static mock = () => {
    return {
      REFID: '',
      createdBy: null,
      createdDate: new Date(),
      id: nanoid(),
      lastModifiedBy: null,
      lastModifiedDate: new Date(),
      organisationId: nanoid(),
      status: STATUS.ACTIVE,
      textContentMap: {},
      version: 0,
      __typename: NoteModel.GRAPHQL_TYPE,
    };
  };

  static link(noteAsset) {
    return `/archive/note/${noteAsset.REFID}/edit`;
  }

  static previewText(noteAsset, locale) {
    const { textContentMap } = noteAsset;
    const previewText = localizeObject(textContentMap, locale, true);
    return previewText.text;
  }
}
