import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import styles from './styles.module.scss';

import countries from 'OK/assets/countries.json';
import provinces from 'OK/assets/provinces.json';
import Accordion from 'OK/components/accordion';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import PhoneInput from 'OK/components/input/phoneInput';
import { Popup, PopupCloseButton, PopupContent, PopupButtonsGroup } from 'OK/components/popup';
import Select from 'OK/components/select';
import Text from 'OK/components/text';
import Toast from 'OK/components/toast';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import { getGeocodingCoordinatesRequest, getStaticMapImageRequest } from 'OK/networking/maps';
import { createOrUpdateAddressMedia } from 'OK/networking/media';
import {
  setOrganisationAddressMutation,
  setOrganisationEmailMutation,
  setOrganisationMobileMutation,
  setOrganisationWebsiteMutation,
} from 'OK/networking/organisations';
import localizeObject from 'OK/util/functions/localizeObject';
import useI18n from 'OK/util/hooks/useI18n';

function countryRequiresProvinces(countryCode) {
  if (!countryCode) {
    return false;
  }

  return countries.find((c) => c.isoAlpha3 === countryCode)?.hideProvinces !== true;
}

export function EditOrganisationInfoPopup(props) {
  const { dismiss, organisation } = props;
  const { locale, t } = useI18n();

  // APIs

  const [setOrganisationAddressAPI, setOrganisationAddressAPIResult] = useMutation(setOrganisationAddressMutation);
  const [setOrganisationEmailAPI, setOrganisationEmailAPIResult] = useMutation(setOrganisationEmailMutation);
  const [setOrganisationMobileAPI, setOrganisationMobileAPIResult] = useMutation(setOrganisationMobileMutation);
  const [setOrganisationWebsiteAPI, setOrganisationWebsiteAPIResult] = useMutation(setOrganisationWebsiteMutation);

  // States

  const [newOrganisationAddress, setNewOrganisationAddress] = useState(organisation?.addressList[0]);
  const [newOrganisationEmail, setNewOrganisationEmail] = useState(organisation?.publicEmail);
  const [newOrganisationMobile, setNewOrganisationMobile] = useState(organisation?.publicMobile);
  const [newOrganisationMobileCountryCode, setNewOrganisationMobileCountryCode] = useState(
    organisation?.publicMobileCountryCode
  );
  const [newOrganisationWebsite, setNewOrganisationWebsite] = useState(organisation?.websiteURL);
  const [addressError, setAddressError] = useState(null);
  const [contactAccordionOpen, setContactAccordionOpen] = useState(true);
  const [addressAccordionOpen, setAddressAccordionOpen] = useState(true);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);

  // Methods
  const onChangeOrganisationAddress = useCallback(
    (newValue, fieldKey) => {
      const newAddress = {
        ...newOrganisationAddress,
        [fieldKey]: newValue,
      };
      if (fieldKey === 'countryCode') {
        // Check if we should remove province as well
        if (!countryRequiresProvinces(newValue)) {
          newAddress.province = null;
        }
      }

      setNewOrganisationAddress(newAddress);
    },
    [newOrganisationAddress]
  );

  const onChangeOrganisationEmail = useCallback((e) => {
    const newValue = e.target.value;
    setNewOrganisationEmail(newValue);
  }, []);

  const onChangeOrganisationMobile = useCallback((e) => {
    const newValue = e.target.value;
    setNewOrganisationMobile(newValue);
  }, []);

  const onChangeOrganisationMobileCountryCode = useCallback((newValue) => {
    const newMobileCountryCode = `+${countries.find((c) => c.isoAlpha3 === newValue).phoneCode}`;
    setNewOrganisationMobileCountryCode(newMobileCountryCode);
  }, []);

  const onChangeOrganisationWebsite = useCallback((e) => {
    const newValue = e.target.value;
    setNewOrganisationWebsite(newValue);
  }, []);

  const updateAddressImage = useCallback(
    async (file) => {
      try {
        setAddressError(null);
        const response = await createOrUpdateAddressMedia(file);
        if (response.responseData.imageURL) {
          return response.responseData.imageURL;
        }
        if (!response.success) {
          setAddressError(t('ERROR_GENERIC'));
          return;
        }
      } catch (e) {
        setAddressError(t('ERROR_GENERIC'));
      } finally {
        setAddressError(false);
      }
    },
    [t]
  );

  const getOrganisationAddressImage = useCallback(
    async (coordinates) => {
      setAddressError(null);

      try {
        const responseSecond = await getStaticMapImageRequest(coordinates[0], coordinates[1]);

        if (!responseSecond.ok) {
          setAddressError(t('ERROR_GENERIC'));
        } else if (responseSecond.ok) {
          const imageBlob = await responseSecond.blob();
          const myFile = new File([imageBlob], 'map.jpeg', {
            type: imageBlob.type,
          });
          return myFile;
        }
      } catch (e) {
        setAddressError(t('ERROR_GENERIC'));
      }
    },
    [t]
  );

  const saveOrganisationAddress = useCallback(async () => {
    try {
      const coordinatesRes = await getGeocodingCoordinatesRequest(
        newOrganisationAddress.city || countries.find((c) => c.isoAlpha3 === newOrganisationAddress.countryCode).name
      );
      const coordinatesJson = await coordinatesRes.json();
      const coordinates = [
        coordinatesJson.features[0].center[0].toString(),
        coordinatesJson.features[0].center[1].toString(),
      ];

      setLongitude(coordinates[0]);
      setLatitude(coordinates[1]);

      const newAddress = {
        ...newOrganisationAddress,
      };
      delete newAddress.__typename;

      const image = await getOrganisationAddressImage(coordinates);
      const myFile = await updateAddressImage(image);

      await setOrganisationAddressAPI({
        variables: {
          address: {
            ...newAddress,
            longitude: coordinates[0],
            latitude: coordinates[1],
            addressImageURL: myFile,
            country: countries.find((c) => c.isoAlpha3 === newOrganisationAddress.countryCode).name,
          },
        },
      });
    } catch (e) {
      setAddressError(t('ERROR_GENERIC'));
    }
  }, [
    getOrganisationAddressImage,
    latitude,
    longitude,
    newOrganisationAddress,
    setOrganisationAddressAPI,
    t,
    updateAddressImage,
  ]);

  const saveOrganisationEmail = useCallback(() => {
    setOrganisationEmailAPI({
      variables: {
        email: newOrganisationEmail,
      },
    });
  }, [newOrganisationEmail, setOrganisationEmailAPI]);

  const saveOrganisationMobile = useCallback(() => {
    if (newOrganisationMobile || newOrganisationMobileCountryCode) {
      setOrganisationMobileAPI({
        variables: {
          mobile: newOrganisationMobile,
          mobileCountryCode: newOrganisationMobileCountryCode,
        },
      });
    }
  }, [newOrganisationMobile, newOrganisationMobileCountryCode, setOrganisationMobileAPI]);

  const saveOrganisationWebsite = useCallback(() => {
    setOrganisationWebsiteAPI({
      variables: {
        website: newOrganisationWebsite,
      },
    });
  }, [newOrganisationWebsite, setOrganisationWebsiteAPI]);

  // Effects

  // Hide name error toast after 5 seconds
  useEffect(() => {
    if (addressError) {
      setTimeout(() => {
        setAddressError(false);
      }, 5000);
    }
  }, [addressError]);

  // Render
  const showProvinceInput = countryRequiresProvinces(newOrganisationAddress.countryCode);
  const provinceOptions = useMemo(() => {
    if (!showProvinceInput || !newOrganisationAddress.countryCode) {
      return [];
    }

    const rawProvinces = provinces[newOrganisationAddress.countryCode];
    const localizedProvinces = localizeObject(rawProvinces, locale);
    // Sort provinces alphabetically
    return localizedProvinces
      .sort((p1, p2) => {
        if (p1.name < p2.name) {
          return -1;
        } else if (p1.name > p2.name) {
          return 1;
        }
        return 0;
      })
      .map((p) => ({ value: p.iso, label: p.name }));
  }, [locale, newOrganisationAddress.countryCode, showProvinceInput]);

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.container}>
        <div className={styles.header}>
          <h3 className={styles.textAvatar}>Edit details</h3>
          <div>
            <PopupCloseButton className={styles.buttonSave} linkStyle tint='navigation'>
              Done
            </PopupCloseButton>
          </div>
        </div>
        <Accordion
          fixedWidth={false}
          onChangeOpen={setContactAccordionOpen}
          open={contactAccordionOpen}
          title={t('COMPANY_CONTACT')}
        >
          <div className={styles.field}>
            <h5 className={styles.fieldName}>
              {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_3_NOTICE_3')}{' '}
              <span className={styles.unbold}>{t('OPTIONAL_FIELD_LABEL')}</span>
            </h5>
            <Input
              className={styles.settingsInput}
              disabled={setOrganisationEmailAPIResult.loading}
              onBlur={saveOrganisationEmail}
              onChange={onChangeOrganisationEmail}
              showClearButton={!setOrganisationEmailAPIResult.loading}
              placeholder='Enter email address'
              value={newOrganisationEmail}
              withIcon={setOrganisationEmailAPIResult.loading ? <Icon name={ICONS.SPINNER.name} /> : null}
            />
          </div>
          <div className={styles.field}>
            <h5 className={styles.fieldName}>
              {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_3_NOTICE_1')}{' '}
              <span className={styles.unbold}>{t('OPTIONAL_FIELD_LABEL')}</span>
            </h5>
            <PhoneInput
              country={
                countries.find((c) => c.phoneCode == newOrganisationMobileCountryCode?.replace('+', ''))?.isoAlpha3
              }
              disabled={setOrganisationMobileAPIResult.loading}
              loading={setOrganisationMobileAPIResult.loading}
              number={newOrganisationMobile}
              onBlurCountryCode={saveOrganisationMobile}
              onBlurNumber={saveOrganisationMobile}
              onChangeCountryCode={onChangeOrganisationMobileCountryCode}
              onChangeNumber={onChangeOrganisationMobile}
            />
          </div>
          <div className={styles.field}>
            <h5 className={styles.fieldName}>
              {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_3_NOTICE_2')}{' '}
              <span className={styles.unbold}>{t('OPTIONAL_FIELD_LABEL')}</span>
            </h5>
            <Input
              disabled={setOrganisationWebsiteAPIResult.loading}
              onBlur={saveOrganisationWebsite}
              onChange={onChangeOrganisationWebsite}
              showClearButton={!setOrganisationWebsiteAPIResult.loading}
              placeholder='Enter website address'
              value={newOrganisationWebsite}
              withIcon={setOrganisationWebsiteAPIResult.loading ? <Icon name={ICONS.SPINNER.name} /> : null}
            />
          </div>
        </Accordion>
        <Accordion
          className={`${styles.settingsCard} ${styles.contactCard}`}
          fixedWidth={false}
          onChangeOpen={setAddressAccordionOpen}
          open={addressAccordionOpen}
          title={t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_FROMSECTIONHEADER')}
        >
          <div className={styles.field}>
            <h5 className={styles.fieldName}>
              {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_NOTICE_1')}{' '}
              <span className={styles.unbold}>{t('OPTIONAL_FIELD_LABEL')}</span>
            </h5>
            <Input
              className={styles.settingsInput}
              disabled={setOrganisationAddressAPIResult.loading}
              onBlur={saveOrganisationAddress}
              onChange={(e) => onChangeOrganisationAddress(e.target.value, 'line1')}
              placeholder={t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_PLACEHOLDER')}
              showClearButton={!setOrganisationAddressAPIResult.loading}
              value={newOrganisationAddress.line1 ?? ''}
              withIcon={setOrganisationAddressAPIResult.loading ? <Icon name={ICONS.SPINNER.name} /> : null}
            />
          </div>
          <div className={styles.field}>
            <h5 className={styles.fieldName}>{t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_NOTICE_2')}</h5>
            <Input
              className={styles.settingsInput}
              disabled={setOrganisationAddressAPIResult.loading}
              onBlur={saveOrganisationAddress}
              onChange={(e) => onChangeOrganisationAddress(e.target.value, 'line2')}
              placeholder={t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_PLACEHOLDER_2')}
              showClearButton={!setOrganisationAddressAPIResult.loading}
              value={newOrganisationAddress.line2 ?? ''}
              withIcon={setOrganisationAddressAPIResult.loading ? <Icon name={ICONS.SPINNER.name} /> : null}
            />
          </div>
          <div className={styles.field}>
            <h5 className={styles.fieldName}>
              {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_NOTICE_3')}{' '}
              <span className={styles.unbold}>{t('OPTIONAL_FIELD_LABEL')}</span>
            </h5>
            <Input
              className={styles.settingsInput}
              disabled={setOrganisationAddressAPIResult.loading}
              onBlur={saveOrganisationAddress}
              onChange={(e) => onChangeOrganisationAddress(e.target.value, 'postalCode')}
              placeholder={t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_PLACEHOLDER_3')}
              showClearButton={!setOrganisationAddressAPIResult.loading}
              value={newOrganisationAddress.postalCode ?? ''}
              withIcon={setOrganisationAddressAPIResult.loading ? <Icon name={ICONS.SPINNER.name} /> : null}
            />
          </div>
          <div className={styles.field}>
            <h5 className={styles.fieldName}>
              {t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_NOTICE_4')}
              <span className={styles.unbold}>{t('OPTIONAL_FIELD_LABEL')}</span>
            </h5>

            <Input
              className={styles.settingsInput}
              disabled={setOrganisationAddressAPIResult.loading}
              onBlur={saveOrganisationAddress}
              onChange={(e) => onChangeOrganisationAddress(e.target.value, 'city')}
              placeholder={t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_PLACEHOLDER_4')}
              showClearButton={!setOrganisationAddressAPIResult.loading}
              value={newOrganisationAddress.city ?? ''}
              withIcon={setOrganisationAddressAPIResult.loading ? <Icon name={ICONS.SPINNER.name} /> : null}
            />
          </div>
          <div className={styles.field}>
            <h5 className={styles.fieldName}>{t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_NOTICE_5')}</h5>
            <Select
              className={styles.settingsInput}
              onBlur={saveOrganisationAddress}
              disabled={setOrganisationAddressAPIResult.loading}
              onChange={(newValue) => onChangeOrganisationAddress(newValue, 'countryCode')}
              options={countries
                .map((c) => ({ value: c.isoAlpha3, label: c.name }))
                .sort((a, b) => {
                  return a.label > b.label ? 1 : -1;
                })}
              value={newOrganisationAddress.countryCode ?? ''}
            />
          </div>
          {showProvinceInput && (
            <div className={styles.field}>
              <h5 className={styles.fieldName}>{t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_NOTICE_6')}</h5>
              <Select
                className={styles.settingsInput}
                disabled={setOrganisationAddressAPIResult.loading}
                onBlur={saveOrganisationAddress}
                onChange={(newValue) => onChangeOrganisationAddress(newValue, 'province')}
                options={provinceOptions}
                value={newOrganisationAddress.province ?? ''}
              />
            </div>
          )}
          {addressError && (
            <Text className={styles.addressErrorMessage} size='sm' tint='notification'>
              {addressError}
            </Text>
          )}
        </Accordion>
        <PopupButtonsGroup>
          <PopupCloseButton tint='navigation'>Done</PopupCloseButton>
        </PopupButtonsGroup>
      </PopupContent>
      <FadeInOutTransition className={styles.toastContainer} appear in={!!addressError}>
        <Toast className={styles.shareToast} tint='alert'>
          {addressError}
        </Toast>
      </FadeInOutTransition>
    </Popup>
  );
}

EditOrganisationInfoPopup.propTypes = {
  dismiss: PropTypes.func,
  organisation: PropTypes.object,
};
