import VimeoPlayer from '@vimeo/player';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { ICONS } from 'OK/components/icon';
import useI18n from 'OK/util/hooks/useI18n';

const VimeoVideoPlayerPopup = forwardRef((props, forwardedRef) => {
  /* Variables */

  const { children, dismiss: dismissProp, id, playFullscreen = false, ...otherProps } = props;
  const { t } = useI18n();

  // Refs

  const vimeoPlayerRef = useRef();

  /* Methods */

  const pause = () => {
    if (!vimeoPlayerRef.current) {
      return;
    }

    vimeoPlayerRef.current.pause().catch((e) => {
      okerror('Could not pause video.', e);
    });
  };

  const play = () => {
    if (!vimeoPlayerRef.current) {
      return;
    }

    vimeoPlayerRef.current.play().catch((e) => {
      okerror('Could not play video.', e);
    });
  };

  const requestFullscreen = async () => {
    if (!vimeoPlayerRef.current) {
      return;
    }

    const isFullscreen = await vimeoPlayerRef.current.getFullscreen();
    if (isFullscreen) {
      return;
    }

    vimeoPlayerRef.current.requestFullscreen().catch((e) => {
      okerror('Could not request fullscreen.', e);
    });
  };

  const resetPlayback = async () => {
    if (!vimeoPlayerRef.current) {
      return;
    }

    const currentTime = await vimeoPlayerRef.current.getCurrentTime();
    if (currentTime !== 0) {
      vimeoPlayerRef.current
        .setCurrentTime(0)
        .then(() => {
          pause();
        })
        .catch((e) => {
          okerror('Could not set playback time.', e);
        });
    }
  };

  const dismiss = () => {
    resetPlayback();
    dismissProp();
  };

  /* Effects */

  // Setup Vimeo player
  useEffect(() => {
    const vimeoPlayer = new VimeoPlayer(id);
    vimeoPlayerRef.current = vimeoPlayer;

    const onPlay = () => {
      if (playFullscreen) {
        requestFullscreen();
      }
    };

    vimeoPlayer.on('play', onPlay);

    return function () {
      vimeoPlayer.off('play', onPlay);
    };
  }, [id, playFullscreen]);

  /* Render */

  // Expose play / pause methods
  useImperativeHandle(forwardedRef, () => ({
    pause,
    play,
  }));

  return (
    <div className={styles.container} onClick={dismiss} {...otherProps}>
      <div className={styles.contentContainer}>
        {children}
        <Button className={styles.closeVideoButton} icon={ICONS.X.name} onClick={dismiss} tint='navigation'>
          {t('CLOSE')}
        </Button>
      </div>
    </div>
  );
});

VimeoVideoPlayerPopup.propTypes = {
  autoplay: PropTypes.bool,
  children: PropTypes.node,
  dismiss: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  playFullscreen: PropTypes.bool,
};

export default VimeoVideoPlayerPopup;
