import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import ButtonGroup from 'OK/components/buttonGroup';
import { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import LabelArchiveCard from 'OK/components/label/archiveCard';
import Notice from 'OK/components/notice';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Stepper from 'OK/components/stepper';
import Text from 'OK/components/text';
import { LabelTemplateName } from 'OK/models/labelTemplate';
import { searchQuery } from 'OK/networking/search';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import { formatNumber, formatOkid } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export const SOURCE_TYPE = {
  ITEM: 'ITEM',
  NEW: 'NEW',
  RERUN: 'RERUN',
};

export default function LabelMakerOKIDSourceSelector(props) {
  /* Variables */

  const { filteredSelection, linkedItemOKID, onCancel, onSave: onSaveProp } = props;
  const { t, tHTML } = useI18n();

  // State

  const [newOKIDCount, setNewOKIDCount] = useState(1);
  const [rerunLabel, setRerunLabel] = useState(null);
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [selectedSourceType, setSelectedSourceType] = useState(SOURCE_TYPE.NEW);

  /* API */

  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);

  /* Methods */

  const searchDebounced = useMemo(
    () =>
      debounce((searchStr) => {
        searchAPI({
          variables: {
            searchPaginationDataByDataType: [
              {
                dataType: 'LABEL',
                searchPaginationData: {
                  pageSize: 4,
                  skip: 0,
                },
              },
            ],
            searchString: searchStr,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [searchAPI]
  );

  // Event handlers

  const onSave = useCallback(() => {
    if (onSaveProp) {
      switch (selectedSourceType) {
        case SOURCE_TYPE.NEW:
          onSaveProp(selectedSourceType, newOKIDCount);
          break;
        case SOURCE_TYPE.RERUN:
          onSaveProp(selectedSourceType, rerunLabel);
          break;
        case SOURCE_TYPE.ITEM:
          onSaveProp(selectedSourceType, linkedItemOKID);
      }
    }
  }, [linkedItemOKID, newOKIDCount, onSaveProp, rerunLabel, selectedSourceType]);

  const onSearch = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setSearchString(newSearchString);

      if (newSearchString.length > 1) {
        searchDebounced(newSearchString);
      }
    },
    [searchDebounced]
  );

  const onSelectLabel = useCallback(
    (labelId) => {
      const label = searchAPIResult.data?.search?.resultList?.find((r) => r.dataId === labelId)?.labelData;
      setRerunLabel(label);
      setSearchString('');
    },
    [searchAPIResult.data]
  );

  /* Render */

  const sourceEditor = useMemo(() => {
    switch (selectedSourceType) {
      case SOURCE_TYPE.NEW:
        return (
          <>
            <h5>{t('LABEL_MAKER_POPUP_OKID_SOURCE_NEW_HEADER')}</h5>
            <Text>{t('LABEL_MAKER_POPUP_OKID_SOURCE_NEW_DESCRIPTION')}</Text>
            <Stepper
              className={styles.newOKIDsStepper}
              maxValue={1000}
              minValue={1}
              onChange={setNewOKIDCount}
              value={newOKIDCount}
            />
            <Notice extendSides>
              {tHTML('LABEL_MAKER_POPUP_OKID_SOURCE_NEW_NOTICE', {
                data: {
                  rerun: (
                    <Button
                      className={styles.noticeTextButton}
                      linkStyle
                      onClick={() => setSelectedSourceType(SOURCE_TYPE.RERUN)}
                    >
                      {t('LABEL_MAKER_POPUP_OKID_SOURCE_BUTTON_RERUN')}
                    </Button>
                  ),
                },
              })}
            </Notice>
          </>
        );
      case SOURCE_TYPE.RERUN: {
        let id;
        filteredSelection.__typename == 'Item' ? (id = filteredSelection.product.id) : (id = filteredSelection.id);
        let searchSuggestions;
        if (searchFocused && searchString.length > 1) {
          searchSuggestions =
            searchAPIResult.data?.search?.resultList
              .filter((r) => r?.labelData?.product.id == id)
              .map((r) => {
                const label = r.labelData;
                return {
                  icon: ICONS.TAG.name,
                  key: label.id,
                  title: <LabelTemplateName labelTemplate={label.labelTemplate} />,
                  subtitle: label.REFID,
                };
              }) ?? [];
        } else {
          searchSuggestions = [];
        }
        return (
          <>
            <h5>{t('LABEL_MAKER_POPUP_OKID_SOURCE_RERUN_HEADER')}</h5>
            <Text>{t('LABEL_MAKER_POPUP_OKID_SOURCE_RERUN_DESCRIPTION')}</Text>
            <div className={styles.searchContainer}>
              <SearchInput
                className={styles.searchLabelsInput}
                loading={searchAPIResult.loading}
                onBlur={() => setSearchFocused(false)}
                onChange={onSearch}
                onFocus={() => setSearchFocused(true)}
                placeholder={t('LABEL_MAKER_POPUP_FIND_LABEL_PLACEHOLDER')}
                value={searchString}
              />
              <SearchSuggestions
                className={styles.labelSearchSuggestions}
                highlightTerm={searchString}
                onSuggestionClick={onSelectLabel}
                showMoreResultsMessage={searchFocused && 4 < searchSuggestions.length}
                showNoResultsMessage={searchFocused && searchString.length > 1 && searchSuggestions.length === 0}
                subtitleClassName={styles.labelSearchSuggestionSubtitle}
                suggestions={searchSuggestions}
              />
            </div>
            {rerunLabel && (
              <LabelArchiveCard
                className={styles.rerunLabelCard}
                label={rerunLabel}
                layoutOverride='mobile'
                linkToLabel={false}
              />
            )}
          </>
        );
      }
      case SOURCE_TYPE.ITEM:
        return (
          <>
            <h5>{t('LABEL_MAKER_POPUP_OKID_SOURCE_ITEM_HEADER')}</h5>
            <Notice className={styles.OKIDNotice} extendSides>
              {formatOkid(linkedItemOKID)}
            </Notice>
          </>
        );
    }
  }, [
    filteredSelection,
    linkedItemOKID,
    newOKIDCount,
    onSearch,
    onSelectLabel,
    rerunLabel,
    searchAPIResult.data?.search?.resultList,
    searchAPIResult.loading,
    searchFocused,
    searchString,
    selectedSourceType,
    t,
    tHTML,
  ]);
  const makeLabelsButtonText = useMemo(() => {
    switch (selectedSourceType) {
      case SOURCE_TYPE.NEW:
        return newOKIDCount === 1
          ? t('LABEL_MAKER_POPUP_BUTTON_MAKE_1_NEW_LABEL')
          : t('LABEL_MAKER_POPUP_BUTTON_MAKE_X_NEW_LABELS', { data: { number: formatNumber(newOKIDCount) } });
      case SOURCE_TYPE.RERUN:
        return t('LABEL_MAKER_POPUP_BUTTON_MAKE_RERUN_LABELS');
      case SOURCE_TYPE.ITEM:
        return t('LABEL_MAKER_POPUP_BUTTON_MAKE_ITEM_LABELS');
    }
  }, [newOKIDCount, selectedSourceType, t]);

  return (
    <div>
      <h4>{t('LABEL_MAKER_POPUP_OKID_SOURCE_HEADER')}</h4>
      <Text>{t('LABEL_MAKER_POPUP_OKID_SOURCE_DESCRIPTION')}</Text>
      <ButtonGroup className={styles.sourceTypeButtons}>
        <button active={selectedSourceType === SOURCE_TYPE.NEW} onClick={() => setSelectedSourceType(SOURCE_TYPE.NEW)}>
          {t('NEW')}
        </button>
        <button
          active={selectedSourceType === SOURCE_TYPE.RERUN}
          onClick={() => setSelectedSourceType(SOURCE_TYPE.RERUN)}
        >
          {t('LABEL_MAKER_POPUP_OKID_SOURCE_BUTTON_RERUN')}
        </button>
        {linkedItemOKID && (
          <button
            active={selectedSourceType === SOURCE_TYPE.ITEM}
            onClick={() => setSelectedSourceType(SOURCE_TYPE.ITEM)}
          >
            {t('LABEL_MAKER_POPUP_OKID_SOURCE_BUTTON_ITEM')}
          </button>
        )}
      </ButtonGroup>
      {sourceEditor}
      <Button
        block
        className={styles.makeLabelsButton}
        disabled={selectedSourceType === SOURCE_TYPE.RERUN && !rerunLabel}
        onClick={onSave}
      >
        {makeLabelsButtonText}
      </Button>
      <Button className={styles.cancelButton} linkStyle onClick={onCancel}>
        {t('LABEL_MAKER_POPUP_BUTTON_DISCARD_CHANGES')}
      </Button>
    </div>
  );
}

LabelMakerOKIDSourceSelector.propTypes = {
  filteredSelection: PropTypes,
  linkedItemOKID: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};
