import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Link from '..';

import DocumentModel from 'OK/models/document';

export default function LinkToFile(props) {
  /* Variables */

  const { children, linkToDownload = false, file, ...otherProps } = props;
  const { documentName } = file;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);

  /* Render */

  const href = linkToDownload ? DocumentModel.downloadLink(file) : DocumentModel.link(file);

  let downloadProps = {};
  if (linkToDownload) {
    downloadProps.target = '_blank';

    if (useDesktopLayout) {
      // Allow direct-download for desktop
      downloadProps.download = documentName;
    }
  }

  return (
    <Link href={href} {...downloadProps} {...otherProps}>
      {children}
    </Link>
  );
}

LinkToFile.propTypes = {
  children: PropTypes.node,
  file: PropTypes.object.isRequired,
  linkToDownload: PropTypes.bool,
};
