import DocumentModel from 'OK/models/document';
import InspectionAssetModel from 'OK/models/inspectionAsset';
import InspectionLogModel from 'OK/models/inspectionLog';
import ItemModel from 'OK/models/item';
import LabelModel from 'OK/models/label';
import MediaAssetModel from 'OK/models/mediaAsset';
import NoteModel from 'OK/models/note';
import OrderModel from 'OK/models/order';
import OrganisationModel from 'OK/models/organisation';
import ProductModel from 'OK/models/product';
import SiteModel from 'OK/models/site';
import TestAssetModel from 'OK/models/testAsset';
import UserModel from 'OK/models/user';

export default function assetTypeToGraphQLType(assetType) {
  const types = {
    DOCUMENT_ASSET: DocumentModel.GRAPHQL_TYPE,
    INSPECTION_ASSET: InspectionAssetModel.GRAPHQL_TYPE,
    INSPECTION_LOG: InspectionLogModel.GRAPHQL_TYPE,
    ITEM: ItemModel.GRAPHQL_TYPE,
    LABEL: LabelModel.GRAPHQL_TYPE,
    MEDIA_ASSET: MediaAssetModel.GRAPHQL_TYPE,
    NOTE_ASSET: NoteModel.GRAPHQL_TYPE,
    ORDER: OrderModel.GRAPHQL_TYPE,
    ORGANISATION: OrganisationModel.GRAPHQL_TYPE,
    PRODUCT: ProductModel.GRAPHQL_TYPE,
    SITE: SiteModel.GRAPHQL_TYPE,
    TEST_ASSET: TestAssetModel.GRAPHQL_TYPE,
    USER: UserModel.GRAPHQL_TYPE,
  };
  return types[assetType];
}
