import { gql } from '@apollo/client';

import BaseMediaAssetModel, {
  AudioMediaAssetModel,
  ImageMediaAssetModel,
  VideoMediaAssetModel,
} from 'OK/models/mediaAsset/base';
import apolloClient from 'OK/networking/graphql/client';
import {
  updateAudioMediaAssetRequest,
  updateImageMediaAssetRequest,
  updateVideoMediaAssetRequest,
} from 'OK/networking/media';

export function updateAudioMediaAsset(mediaId, newMediaFile) {
  return updateAudioMediaAssetRequest(mediaId, newMediaFile).then((req) => {
    const response = req.responseData;
    apolloClient.writeFragment({
      id: `${BaseMediaAssetModel.GRAPHQL_TYPE}:${mediaId}`,
      fragment: gql`
          fragment UpdateAudioMediaAsset on ${BaseMediaAssetModel.GRAPHQL_TYPE} {
            audioData {
              ...${AudioMediaAssetModel.fragmentName}
            }
          }
          ${AudioMediaAssetModel.fragment}
        `,
      fragmentName: 'UpdateAudioMediaAsset',
      data: {
        audioData: {
          audioName: response.audioData.audioName,
          audioURL: response.audioData.audioURL,
          metadata: response.audioData.metadata,
          __typename: AudioMediaAssetModel.GRAPHQL_TYPE,
        },
      },
    });
  });
}

export function updateImageMediaAsset(mediaId, newMediaFile) {
  return updateImageMediaAssetRequest(mediaId, newMediaFile).then((req) => {
    const response = req.responseData;
    apolloClient.writeFragment({
      id: `${BaseMediaAssetModel.GRAPHQL_TYPE}:${mediaId}`,
      fragment: gql`
          fragment UpdateImageMediaAsset on ${BaseMediaAssetModel.GRAPHQL_TYPE} {
            imageData {
              ...${ImageMediaAssetModel.fragmentName}
            }
          }
          ${ImageMediaAssetModel.fragment}
        `,
      fragmentName: 'UpdateImageMediaAsset',
      data: {
        imageData: {
          baseImageURL: response.imageData.baseImageURL,
          imageName: response.imageData.imageName,
          imageURL: response.imageData.imageURL,
          metadata: response.imageData.metadata,
          __typename: ImageMediaAssetModel.GRAPHQL_TYPE,
        },
      },
    });
  });
}

export function updateVideoMediaAsset(mediaId, newMediaFile) {
  return updateVideoMediaAssetRequest(mediaId, newMediaFile).then((req) => {
    const response = req.responseData;
    apolloClient.writeFragment({
      id: `${BaseMediaAssetModel.GRAPHQL_TYPE}:${mediaId}`,
      fragment: gql`
          fragment UpdateVideoMediaAsset on ${BaseMediaAssetModel.GRAPHQL_TYPE} {
            videoData {
              ...${VideoMediaAssetModel.fragmentName}
            }
          }
          ${VideoMediaAssetModel.fragment}
        `,
      fragmentName: 'UpdateVideoMediaAsset',
      data: {
        videoData: {
          metadata: response.videoData.metadata,
          videoName: response.videoData.videoName,
          videoURL: response.videoData.videoURL,
          __typename: VideoMediaAssetModel.GRAPHQL_TYPE,
        },
      },
    });
  });
}
