import PropTypes from 'prop-types';
import { useCallback, useContext } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Text from 'OK/components/text';
import ThemeContext from 'OK/util/context/theme';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import { formatOkid } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function SharedAccessCard(props) {
  /* Variables */

  const theme = useContext(ThemeContext);
  const {
    className,
    imageUrl = `/img/empty_media_${theme.name}.svg`,
    name,
    OKID,
    onClickChangePermissionLevel: onClickChangePermissionLevelProp,
    onClickRemove: onClickRemoveProp,
    sharedAccessId,
    sharedAccessLevel,
    sharedToAssetType,
    ...otherProps
  } = props;
  const { t } = useI18n();

  /* Event handlers */

  const onClickChangePermissionLevel = useCallback(
    (newPermissionLevel) => {
      onClickChangePermissionLevelProp(sharedAccessId, newPermissionLevel);
    },
    [onClickChangePermissionLevelProp, sharedAccessId]
  );

  const onClickRemove = useCallback(() => {
    onClickRemoveProp(sharedAccessId);
  }, [onClickRemoveProp, sharedAccessId]);

  /* Render */

  // Current permission
  const hasViewPermission = sharedAccessLevel === AssetAccessPermission.SHARED_WITH_READ_PERMISSION;
  const hasCommentPermission = sharedAccessLevel === AssetAccessPermission.SHARED_WITH_COMMENT_PERMISSION;
  const hasEditPermission = sharedAccessLevel === AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION; // Not necessary

  // Classes
  let classNames = styles.container;
  if (className) {
    classNames += ` ${className}`;
  }

  let permissionButtonClassName = styles.permissionButton;
  let permissionButtonActiveClassNames = `${permissionButtonClassName} ${styles.active}`;

  let imageClassNames = styles.image;
  if (sharedToAssetType === 'USER') {
    imageClassNames += ` ${styles.user}`;
  } else {
    imageClassNames += ` ${styles.organisation}`;
  }

  return (
    <div className={classNames} padded={false} {...otherProps}>
      <div className={styles.topRow}>
        <div className={styles.sharedToInfo}>
          <img alt='Logo or image.' className={imageClassNames} src={imageUrl} />
          <div className={styles.sharedToNameContainer}>
            <Text className={styles.name}>{name}</Text>
            <Text className={styles.okid}>{formatOkid(OKID)}</Text>
          </div>
        </div>
        <Button className={styles.removeButton} linkStyle onClick={onClickRemove} tint='alert'>
          {t('REMOVE')}
        </Button>
      </div>
      <div>
        <Button
          className={hasViewPermission ? permissionButtonActiveClassNames : permissionButtonClassName}
          onClick={() => onClickChangePermissionLevel(AssetAccessPermission.SHARED_WITH_READ_PERMISSION)}
          tint={hasViewPermission ? 'navigation' : 'default'}
        >
          {t('SHARED_PERMISSION_LEVEL_VIEWER')}
        </Button>
        <Button
          className={hasCommentPermission ? permissionButtonActiveClassNames : permissionButtonClassName}
          onClick={() => onClickChangePermissionLevel(AssetAccessPermission.SHARED_WITH_COMMENT_PERMISSION)}
          tint={hasCommentPermission ? 'navigation' : 'default'}
        >
          {t('SHARED_PERMISSION_LEVEL_COMMENTER')}
        </Button>
        {/* Hide editor button until feature is ready. */}
        <Button
          className={hasEditPermission ? permissionButtonActiveClassNames : permissionButtonClassName}
          onClick={() => onClickChangePermissionLevel(AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION)}
          tint={hasEditPermission ? 'navigation' : 'default'}
        >
          Editor
        </Button>
      </div>
      <div className={styles.borderBottom}></div>
    </div>
  );
}

SharedAccessCard.propTypes = {
  className: PropTypes.string,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  OKID: PropTypes.string,
  onClickChangePermissionLevel: PropTypes.func,
  onClickRemove: PropTypes.func,
  sharedAccessId: PropTypes.string,
  sharedAccessLevel: PropTypes.string,
  sharedToAssetType: PropTypes.string,
};
