import { makeReference, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { createContext, useCallback, useState } from 'react';

import CommentsThread from 'OK/components/comments/thread';
import CommentModel from 'OK/models/comment';
import {
  CommentLikeMutation,
  CommentUnlikeMutation,
  GetCommentsForAssetQuery,
  GetCommentThreadQuery,
  PostCommentOnAssetMutation,
  PostCommentOnCommentMutation,
  DeleteCommentMutation,
} from 'OK/networking/comments';

/**
 * @typedef {object} CommentsContext
 * @prop {string} inputTargetType Asset type of the current reply target. This will determine where to display the input next to.
 * @prop {string} inputTargetId Asset id of the current reply target.
 * @prop {(targetType: string, targetId: string) => {}} setInputTarget Update the reply target, which will move the input.
 *
 */

/**
 * @type {CommentsContext}
 */
const defualtCommentsContext = {
  inputTargetType: null,
  inputTargetId: null,
  setInputTarget: () => {},
};

/** React context for a comment section. */
export const CommentsContext = createContext(defualtCommentsContext);

/**
 * Wrapper component to provide CommentsContext to a comments section. Also handles all business logic.
 *
 * @param {object} props
 * @param {string} props.assetType The type of asset the comments are on.
 * @param {string} props.assetId The id of the asset the comments are on.
 * @param {string} props.className The class for the container div.
 */
export default function CommentsController(props) {
  /* Variables */

  const { assetType, assetId, assetOrganisationId, className } = props;

  /* State */

  const [inputTargetId, setInputTargetId] = useState(assetId);
  const [inputTargetType, setInputTargetType] = useState(assetType);

  /* API */

  const getCommentsForAssetResult = useQuery(GetCommentsForAssetQuery, {
    variables: {
      page: 0,
      size: CommentModel.DEFAULT_SIZE,
      sourceDataType: assetType,
      sourceId: assetId,
      sourceOrganisationId: assetOrganisationId,
      threadPage: 0,
      threadSize: CommentModel.DEFAULT_THREAD_SIZE,
    },
  });

  const [getCommentThreadAPI, getCommentThreadAPIResult] = useLazyQuery(GetCommentThreadQuery);

  const [commentLikeAPI] = useMutation(CommentLikeMutation);
  const [commentUnlikeAPI] = useMutation(CommentUnlikeMutation);
  const [postCommentOnAssetAPI, postCommentOnAssetAPIResult] = useMutation(PostCommentOnAssetMutation);
  const [postCommentOnCommentAPI, postCommentOnCommentAPIResult] = useMutation(PostCommentOnCommentMutation);
  const [deleteCommentAPI] = useMutation(DeleteCommentMutation);

  const comments = getCommentsForAssetResult.data?.commentsData?.commentList;
  const totalCommentsCount = getCommentsForAssetResult.data?.commentsData?.commentListCount;

  /* Methods */

  const setInputTarget = useCallback((targetType, targetId) => {
    setInputTargetType(targetType);
    setInputTargetId(targetId);
  }, []);

  const likeComment = useCallback(
    (commentId) => {
      return commentLikeAPI({
        variables: {
          commentId: commentId,
        },
      });
    },
    [commentLikeAPI]
  );

  const unlikeComment = useCallback(
    (commentId) => {
      return commentUnlikeAPI({
        variables: {
          commentId: commentId,
        },
      });
    },
    [commentUnlikeAPI]
  );

  const postComment = useCallback(
    async (commentText, assetTargetType, assetTargetId) => {
      if (assetTargetType !== 'COMMENT') {
        return postCommentOnAssetAPI({
          variables: {
            commentText,
            sourceDataType: assetType,
            sourceId: assetId,
            sourceOrganisationId: assetOrganisationId,
          },
          update: (cache, result) => {
            const fieldName = `getCommentPagedResult:{"sourceDataType":"${assetType}","sourceId":"${assetId}"}`;
            const newComment = result.data.comment;
            cache.modify({
              fields: {
                [fieldName]: (currentCommentsResult) => {
                  const newCommentReference = makeReference(`${CommentModel.GRAPHQL_TYPE}:${newComment.id}`);
                  const newCommentsList = [...currentCommentsResult.commentList];
                  newCommentsList.push(newCommentReference);
                  const updatedCommentsResult = {
                    ...currentCommentsResult,
                    commentList: newCommentsList,
                    commentListCount: currentCommentsResult.commentListCount + 1,
                  };
                  return updatedCommentsResult;
                },
              },
            });
          },
        });
      } else {
        return postCommentOnCommentAPI({
          variables: {
            commentText,
            parentCommentId: assetTargetId,
          },
          update: (cache, result) => {
            const newComment = result.data.comment;
            const newCommentReference = makeReference(`${CommentModel.GRAPHQL_TYPE}:${newComment.id}`);
            cache.modify({
              id: `${CommentModel.GRAPHQL_TYPE}:${assetTargetId}`,
              fields: {
                commentThreadPagedResult: (currentResult) => {
                  const updatedResult = {
                    ...currentResult,
                    commentList: [...currentResult.commentList, newCommentReference],
                    commentListCount: currentResult.commentListCount + 1,
                  };
                  return updatedResult;
                },
              },
            });
          },
        });
      }
    },
    [assetId, assetOrganisationId, assetType, postCommentOnAssetAPI, postCommentOnCommentAPI]
  );

  const deleteComment = useCallback(
    async (assetTargetType, assetTargetId, commentId) => {
      return deleteCommentAPI({
        variables: {
          commentId: commentId,
        },
        update: (cache, result) => {
          const deletedComment = result.data.comment;
          if (assetTargetType !== 'COMMENT') {
            // Update logic for deleting comment from root asset
            const fieldName = `getCommentPagedResult:{"sourceDataType":"${assetType}","sourceId":"${assetId}"}`;
            cache.modify({
              fields: {
                [fieldName]: (currentCommentsResult, { readField }) => {
                  const newCommentList = currentCommentsResult.commentList.filter((commentRef) => {
                    return readField('id', commentRef) != deletedComment.id;
                  });
                  const updatedCommentsResult = {
                    ...currentCommentsResult,
                    commentList: newCommentList,
                    commentListCount: currentCommentsResult.commentListCount - 1,
                  };
                  return updatedCommentsResult;
                },
              },
            });
          } else {
            // Update logic for deleting comment from comment thread
            cache.modify({
              id: `${CommentModel.GRAPHQL_TYPE}:${assetTargetId}`,
              fields: {
                commentThreadPagedResult: (currentResult, { readField }) => {
                  const newCommentList = currentResult.commentList.filter((commentRef) => {
                    return readField('id', commentRef) != deletedComment.id;
                  });
                  const updatedResult = {
                    ...currentResult,
                    commentList: newCommentList,
                    commentListCount: currentResult.commentListCount - 1,
                  };
                  return updatedResult;
                },
              },
            });
          }
        },
      }).then(() => {
        if (inputTargetType === 'COMMENT' && inputTargetId === commentId) {
          // Deleting the current reply target, so reset reply target to root asset
          setInputTarget(assetType, assetId);
        }
      });
    },
    [assetId, assetType, deleteCommentAPI, inputTargetId, inputTargetType, setInputTarget]
  );

  const loadPageOfCommentThread = useCallback(
    (targetAssetType, targetAssetId, page) => {
      if (targetAssetType === 'COMMENT') {
        const variables = {
          commentId: targetAssetId,
          page,
          size: CommentModel.DEFAULT_THREAD_SIZE,
        };
        // Call the original API function first and the fetchMore method for all subsequent times according to Apollo's requirement.
        if (getCommentThreadAPIResult.called) {
          return getCommentThreadAPIResult.fetchMore({ variables });
        } else {
          return getCommentThreadAPI({ variables });
        }
      } else {
        return getCommentsForAssetResult.fetchMore({
          variables: { page },
        });
      }
    },
    [getCommentThreadAPI, getCommentThreadAPIResult, getCommentsForAssetResult]
  );

  /* Render */

  const context = {
    deleteComment,
    inputTargetId,
    inputTargetType,
    likeComment,
    loadPageOfCommentThread,
    postComment,
    postCommentLoading: postCommentOnAssetAPIResult.loading || postCommentOnCommentAPIResult.loading,
    rootAssetId: assetId,
    rootAssetType: assetType,
    setInputTarget,
    unlikeComment,
  };

  return (
    <CommentsContext.Provider value={context}>
      <div className={className}>
        <CommentsThread assetType={assetType} assetId={assetId} thread={comments} threadCount={totalCommentsCount} />
      </div>
    </CommentsContext.Provider>
  );
}

CommentsController.propTypes = {
  assetId: PropTypes.string.isRequired,
  assetOrganisationId: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  className: PropTypes.string,
  rootAssetId: PropTypes.string,
  rootAssetType: PropTypes.string,
};
