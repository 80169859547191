import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  deleteUserDocumentAssetMutation,
  publishUserDocumentAsOrganisationManagerMutation,
  publishUserDocumentMutation,
  unpublishUserDocumentAsOrganisationManagerMutation,
  unpublishUserDocumentMutation,
} from '.';

import BaseUserModel from 'OK/models/user/base';
import UserDocumentModel from 'OK/models/userDocument';
import BaseUserDocumentModel from 'OK/models/userDocument/base';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';

export function useUnlinkUserDocumentAPI() {
  const apolloClient = useApolloClient();
  const [unlinkUserDocumentAPI] = useMutation(deleteUserDocumentAssetMutation);

  const unlinkUserDocument = useCallback(
    (user, documentId) => {
      // Optimistic response
      const documentAsset = apolloClient.readFragment({
        id: `${BaseUserDocumentModel.GRAPHQL_TYPE}:${documentId}`,
        fragment: BaseUserDocumentModel.fragment,
        fragmentName: BaseUserDocumentModel.fragmentName,
      });
      const userDocument = user.userDocumentAssetList.find((pd) => pd.id === documentId);
      const responseUserDocument = {
        ...userDocument,
        documentAsset,
      };
      const optimisticResponse = {
        document: responseUserDocument,
      };

      // Call API
      return unlinkUserDocumentAPI({
        variables: {
          documentId,
        },
        optimisticResponse,
        update: (cache, result) => {
          if (result.data.document) {
            // Unlink from user's userDocumentAssetList
            cache.modify({
              id: `${BaseUserModel.GRAPHQL_TYPE}:${user.id}`,
              fields: {
                userDocumentAssetList: (currentList, { readField }) => {
                  const updatedList = [...currentList];
                  const indexOfUserDocument = updatedList.findIndex((userDocumentAsset) => {
                    const documentId = readField('id', userDocumentAsset);
                    return documentId === result.data.document.id;
                  });
                  updatedList.splice(indexOfUserDocument, 1);
                  return updatedList;
                },
              },
            });
          } else {
            okerror('Unlink document api did not respond with file document.');
          }
        },
      });
    },
    [apolloClient, unlinkUserDocumentAPI]
  );

  return unlinkUserDocument;
}

export function usePublishUserDocumentAPI(mutationOptions = {}) {
  const [publishUserDocumentAPI] = useMutation(publishUserDocumentMutation, mutationOptions);

  const publishUserDocument = useCallback(
    (user, documentId) => {
      // Optimistic response
      const userDocument = user.userDocumentAssetList.find((pd) => pd.id === documentId);
      const optimisticResponse = {
        document: {
          id: userDocument.id,
          publishStatus: PUBLISH_STATUS.PUBLISHED,
          __typename: UserDocumentModel.GRAPHQL_TYPE,
        },
      };

      // Call API
      return publishUserDocumentAPI({
        variables: {
          documentId,
        },
        optimisticResponse,
      });
    },
    [publishUserDocumentAPI]
  );

  return publishUserDocument;
}

export function usePublishUserDocumentAsOrganisationManagerAPI(mutationOptions = {}) {
  const [publishUserDocumentAPI] = useMutation(publishUserDocumentAsOrganisationManagerMutation, mutationOptions);

  const publishUserDocument = useCallback(
    (user, documentId) => {
      // Optimistic response
      const userDocument = user.userDocumentAssetList.find((pd) => pd.id === documentId);
      const optimisticResponse = {
        document: {
          id: userDocument.id,
          organisationPublishStatus: PUBLISH_STATUS.PUBLISHED,
          __typename: UserDocumentModel.GRAPHQL_TYPE,
        },
      };

      // Call API
      return publishUserDocumentAPI({
        variables: {
          documentId,
        },
        optimisticResponse,
      });
    },
    [publishUserDocumentAPI]
  );

  return publishUserDocument;
}

export function useUnpublishUserDocumentAPI(mutationOptions) {
  const [unpublishUserDocumentAPI] = useMutation(unpublishUserDocumentMutation, mutationOptions);

  const unpublishUserDocument = useCallback(
    (user, documentId) => {
      // Optimistic response
      const userDocument = user.userDocumentAssetList.find((pd) => pd.id === documentId);
      const optimisticResponse = {
        document: {
          id: userDocument.id,
          publishStatus: PUBLISH_STATUS.UNPUBLISHED,
          __typename: UserDocumentModel.GRAPHQL_TYPE,
        },
      };

      // Call API
      return unpublishUserDocumentAPI({
        variables: {
          documentId,
        },
        optimisticResponse,
      });
    },
    [unpublishUserDocumentAPI]
  );

  return unpublishUserDocument;
}

export function useUnpublishUserDocumentAsOrganisationManagerAPI(mutationOptions) {
  const [unpublishUserDocumentAPI] = useMutation(unpublishUserDocumentAsOrganisationManagerMutation, mutationOptions);

  const unpublishUserDocument = useCallback(
    (user, documentId) => {
      // Optimistic response
      const userDocument = user.userDocumentAssetList.find((pd) => pd.id === documentId);
      const optimisticResponse = {
        id: userDocument.id,
        organisationPublishStatus: PUBLISH_STATUS.UNPUBLISHED,
        __typename: UserDocumentModel.GRAPHQL_TYPE,
      };

      // Call API
      return unpublishUserDocumentAPI({
        variables: {
          documentId,
        },
        optimisticResponse,
      });
    },
    [unpublishUserDocumentAPI]
  );

  return unpublishUserDocument;
}
