import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
  ArchiveCardTitle,
} from 'OK/components/archiveCard';
import { ICONS } from 'OK/components/icon';
import Link from 'OK/components/link';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import OrderModel from 'OK/models/order';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import { formatNumber } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';
import useLocalizedObject from 'OK/util/hooks/useLocalizedObject';

export default function OrderArchiveCard(props) {
  /* Variables */

  const { cardClassName, className, linkToArchivePage = true, order, ...otherProps } = props;
  const { orderIdentifierList, linkedInspectionLogList, name, REFID } = order;
  const { t } = useI18n();
  const dispatch = useDispatch();
  const identifierTags = useMemo(() => {
    const tags = orderIdentifierList?.map((i) => (
      <Tag className={styles.tag} key={i.id}>
        {i.orderIdentifier}
      </Tag>
    ));
    return tags;
  }, [orderIdentifierList]);

  const linksMessage = useMemo(() => {
    const numberOfLinks = linkedInspectionLogList?.length ?? 0;
    if (numberOfLinks === 0) {
      return t('ARCHIVE_CARD_0_LINKS');
    }
    if (numberOfLinks === 1) {
      return t('ARCHIVE_CARD_1_LINK');
    }
    return t('ARCHIVE_CARD_X_LINKS', { data: { number: formatNumber(numberOfLinks) } });
  }, [linkedInspectionLogList?.length, t]);

  /* Render */

  const Container = linkToArchivePage ? Link : 'div';
  const containerProps = { className };
  if (linkToArchivePage) {
    containerProps.href = OrderModel.link(order);
    containerProps.onClick = (e) => {
      e.preventDefault();
      history.pushState({ isPopup: true, refId: REFID, dataType: 'ORDER', OKID: null }, '', OrderModel.link(order));
      dispatch(setBrowserHistoryAction(history));
    };
  }

  const localizedName = useLocalizedObject(name, true)?.text;

  return (
    <Container {...containerProps}>
      <ArchiveCard className={cardClassName} {...otherProps}>
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery placeholderIconName={ICONS.ORDER.name} refId={REFID} />
          <ArchiveCardMainContent className={styles.content}>
            <ArchiveCardHeader type={t('ORDER_ARCHIVE_CARD_HEADER')} />
            <ArchiveCardTitle className={styles.title} lineLimit={2}>
              {localizedName}
            </ArchiveCardTitle>
            <Text className={styles.tagRow} size='xs'>
              {identifierTags}
            </Text>
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow>{linksMessage}</ArchiveCardFooterRow>
          <ArchiveCardFooterRow />
        </ArchiveCardFooter>
      </ArchiveCard>
    </Container>
  );
}

OrderArchiveCard.propTypes = {
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  linkToArchivePage: PropTypes.bool,
  order: PropTypes.object.isRequired,
};
