import PropTypes from 'prop-types';
import { Document as PDFDocument, Page as PDFPage, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

import styles from './styles.module.scss';

import Text from 'OK/components/text';
import useI18n from 'OK/util/hooks/useI18n';

export function PDFPreview(props) {
  const { className, fileUrl, size, ...otherProps } = props;
  const { t } = useI18n();

  let classNames = styles.filePreviewContainer;
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <div className={classNames} style={{ height: size }} {...otherProps}>
      <PDFDocument
        error={<GenericFilePreview message={t('PDF_PREVIEW_FAILED_TO_LOAD')} size={size} />}
        file={fileUrl}
        loading={<div />}
      >
        <PDFPage pageNumber={1} width={size} />
      </PDFDocument>
    </div>
  );
}

PDFPreview.propTypes = {
  className: PropTypes.string,
  fileUrl: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export function GenericFilePreview(props) {
  const { className, message, size, ...otherProps } = props;

  let classNames = `${styles.filePreviewContainer} ${styles.noPreview}`;
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <div className={classNames} style={{ height: size }} {...otherProps}>
      <Text>{message}</Text>
    </div>
  );
}

GenericFilePreview.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};
