import NextImage from "next/legacy/image";
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import baseStyles from './tints/base.module.scss';
import cardStyles from './tints/card.module.scss';

import { ArchiveCardContext } from 'OK/components/archiveCard';
import Icon, { ICONS } from 'OK/components/icon';
import MediaGallery from 'OK/components/mediaGallery';
import { PDFPreview } from 'OK/components/pdf/preview';
import Text from 'OK/components/text';
import getFileExtension from 'OK/util/functions/getFileExtension';

/**
 *
 * @typedef {Object} ArchiveCardMediaGalleryProps
 * @prop {number|string} [awards] The number of awards achieved.
 * @prop {string} [className]
 * @prop {string} [imageUrl] Direct URL to an image. Use this to show a single image rather than a gallery.
 * @prop {array} [media] Array of MediaAssets.
 * @prop {string} [placeholderIconName] An icon to display if no media exists. Can also be shown over media by setting
 * `showPlaceholderOverMedia`.
 * @prop {string} [refId] The asset's ref id.
 * @prop {boolean} [showOverlay=false] Show an overlay over the media.
 * @prop {boolean} [showPlaceholderOverMedia=false] Show placeholder icon over media. If `true`, `placeholderIconName`
 * must be set.
 */

/**
 * Display asset media or a placeholder icon. Can also display a badge for ref id and awards.
 *
 * @param {ArchiveCardMediaGalleryProps} props
 */
export default function ArchiveCardMediaGallery(props) {
  /* Variables */

  const {
    awards,
    className,
    documentAsset,
    imageUrl,
    media,
    placeholderIconName,
    refId,
    showOverlay = false,
    showPlaceholderOverMedia = false,
    ...otherProps
  } = props;
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const { outerUIContext } = useContext(ArchiveCardContext);
  const showGallery = media?.length > 0;
  const hasMedia = showGallery || imageUrl;
  const showPlaceholder = (!hasMedia && placeholderIconName) || showPlaceholderOverMedia;
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);

  // Refs

  const innerContainerRef = useRef();

  /* State */

  const [filePreviewSize, setFilePreviewSize] = useState(useMobileLayout ? 130 : 287);

  /* Effects */

  // Determine file preview size
  useEffect(() => {
    if (documentAsset) {
      setFilePreviewSize(innerContainerRef.current.clientWidth);
    }
  }, [documentAsset]);

  /* Render */

  // Badge
  let badge;
  if (refId || awards) {
    if (useDesktopLayout) {
      // Desktop displays separate badges
      badge = (
        <>
          {refId && (
            <Text bold className={`${styles.badge} ${styles.refIdBadge}`} size='xs'>
              <span className={styles.refId}>{refId}</span>
            </Text>
          )}
          {awards && (
            <Text bold className={`${styles.badge} ${styles.awardsBadge}`} size='xs'>
              <span className={styles.awards}>
                <Icon height={12} name={ICONS.TROPHY.name} width={12} />
                {awards}
              </span>
            </Text>
          )}
        </>
      );
    } else {
      // Mobile displays one unified badge
      let badgeClasses = styles.badge;
      if (refId) {
        badgeClasses = `${badgeClasses} ${styles.refIdBadge}`;
      }
      if (awards) {
        badgeClasses = `${badgeClasses} ${styles.awardsBadge}`;
      }
      badge = (
        <Text bold className={badgeClasses} size='xs' {...otherProps}>
          {refId && <span className={styles.refId}>{refId}</span>}
          {awards && (
            <span className={styles.awards}>
              <Icon height={12} name={ICONS.TROPHY.name} width={12} />
              {awards}
            </span>
          )}
        </Text>
      );
    }
  }

  // Classes
  let classNames = styles.mediaGalleryContainer;

  // UI context-specific classes
  switch (outerUIContext) {
    case 'card':
      classNames = `${classNames} ${cardStyles.mediaGalleryContainer}`;
      break;
    default:
      classNames = `${classNames} ${baseStyles.mediaGalleryContainer}`;
      break;
  }

  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let filePreview;
  if (documentAsset) {
    const fileExtension = getFileExtension(documentAsset.documentURL);
    switch (fileExtension) {
      case '.pdf':
        filePreview = (
          <PDFPreview className={styles.pdfPreview} fileUrl={documentAsset.documentURL} size={filePreviewSize} />
        );
        break;
      default:
        null;
        break;
    }
  }

  return (
    <div className={classNames}>
      <div className={styles.mediaGalleryInnerContainer} ref={innerContainerRef}>
        {showGallery && (
          <MediaGallery
            className={styles.mediaGallery}
            media={media}
            previewsClassName={styles.mediaGalleryPreviews}
            showMediaDetails={false}
            showThumbnails={false}
          />
        )}
        {imageUrl && <NextImage layout='fill' objectFit='cover' objectPosition='center center' src={imageUrl} />}
        {documentAsset && filePreview}
        {showOverlay && <div className={styles.mediaOverlay} />}
        {showPlaceholder && !filePreview && (
          <Icon className={styles.mediaPlaceholderIcon} height={40} name={placeholderIconName} width={40} />
        )}
      </div>
      {badge}
    </div>
  );
}

ArchiveCardMediaGallery.propTypes = {
  awards: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  documentAsset: PropTypes.object,
  imageUrl: PropTypes.string,
  media: PropTypes.array,
  placeholderIconName: PropTypes.string,
  refId: PropTypes.string,
  showOverlay: PropTypes.bool,
  showPlaceholderOverMedia: PropTypes.bool,
};
