import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import TestLevelIndicator from 'OK/components/test/levelIndicator';
import Text from 'OK/components/text';

/**
 *
 * @typedef {Object} ArchiveCardTestResultsProps
 * @prop {string} critical Percentage of critical issues found.
 * @prop {boolean} [hasRemarks=false] Indicate the presence of remarks.
 * @prop {string} major Percentage of major issues found.
 * @prop {string} minor Percentage of minor issues found.
 */

/**
 * Display test results.
 *
 * @param {ArchiveCardTestResultsProps} props
 */
export default function ArchiveCardTestResults(props) {
  const { className, critical, hasRemarks = false, hideTestResults, major, minor, ...otherProps } = props;

  let classNames = styles.testResults;
  if (className) {
    classNames += ` ${className}`;
  } else if (hideTestResults) {
    classNames += ` ${className} ${styles.zeroOpacity}`;
  }
  return (
    <Text bold className={classNames} size='xs' {...otherProps}>
      <span className={styles.testResult}>
        <TestLevelIndicator className={styles.testLevelColor} level='minor' />
        {minor}
      </span>
      <span className={styles.testResult}>
        <TestLevelIndicator className={styles.testLevelColor} level='major' />
        {major}
      </span>
      <span className={styles.testResult}>
        <TestLevelIndicator className={styles.testLevelColor} level='critical' />
        {critical}
      </span>
      {hasRemarks && <Icon className={styles.testRemarksIcon} height={12} name={ICONS.REMARK.name} width={12} />}
    </Text>
  );
}

ArchiveCardTestResults.propTypes = {
  className: PropTypes.string,
  critical: PropTypes.string.isRequired,
  hasRemarks: PropTypes.bool,
  hideTestResults: PropTypes.bool,
  major: PropTypes.string.isRequired,
  minor: PropTypes.string.isRequired,
};
