import PropTypes from 'prop-types';
import { useContext } from 'react';

import styles from './styles.module.scss';
import baseStyles from './tints/base.module.scss';
import cardStyles from './tints/card.module.scss';

import { ArchiveCardContext, ArchiveCardOKID } from 'OK/components/archiveCard';
import Icon, { ICONS } from 'OK/components/icon';
import Text from 'OK/components/text';
import { formatOkid, formatPoints } from 'OK/util/formatting';

/**
 * @typedef {Object} ArchiveCardFooterRowProps
 * @prop {string} [backgroundTint] The tint color of the background. Cannot be used together with `textTint`.
 * @prop {Node} [children]
 * @prop {string} [conformityGrade] Conformity grade.
 * @prop {string} [okid] OKID.
 * @prop {'tag'|'user'} [okidIcon] Icon to show for the OKID.
 * @prop {string} [reliabilityGrade] Reliability grade.
 * @prop {string} [sustainabilityGrade] Sustainability grade.
 * @prop {string} [textTint] The tint color of the text. Cannot be used together with `backgroundTint`.
 */

/**
 * Footer row.
 *
 * @param {ArchiveCardFooterRowProps} props
 */
export default function ArchiveCardFooterRow(props) {
  /* Variables */

  const {
    backgroundTint,
    children,
    className,
    conformityGrade,
    innerClassName,
    okid,
    okidIcon,
    points,
    reliabilityGrade,
    sustainabilityGrade,
    textTint,
    ...otherProps
  } = props;
  const { outerUIContext } = useContext(ArchiveCardContext);

  /* Render */

  // Classes
  let classNames = styles.footerRow;

  // UI context-specific styles
  switch (outerUIContext) {
    case 'card':
      classNames = `${classNames} ${cardStyles.footerRow}`;
      break;
    default:
      classNames = `${classNames} ${baseStyles.footerRow}`;
      break;
  }

  // Background tint
  if (backgroundTint) {
    switch (backgroundTint) {
      case 'navigation':
        classNames = `${classNames} ${styles.backgroundTintNavigation}`;
        break;
      default:
        break;
    }
  } else if (textTint) {
    switch (textTint) {
      case 'navigation':
        classNames = `${classNames} ${styles.textTintNavigation}`;
        break;
      default:
        break;
    }
  }

  if (className) {
    classNames = `${classNames} ${className}`;
  }

  // Grade rendering logic
  // To preserve identical position of each grade across all cards, some grades need to be rendered as an empty space
  // even if there's no value.
  const hasConfirmityGrade = typeof conformityGrade === 'number' || typeof conformityGrade === 'string';
  const hasReliabilityGrade = typeof reliabilityGrade === 'number' || typeof reliabilityGrade === 'string';
  const hasSustainabilityGrade = typeof sustainabilityGrade === 'number' || typeof sustainabilityGrade === 'string';
  const renderSustainabilityGrade = hasSustainabilityGrade;
  const renderConformityGrade = renderSustainabilityGrade || hasConfirmityGrade;
  const renderReliabilityGrade = renderConformityGrade || hasReliabilityGrade;

  // Text
  let innerClassNames = styles.footerText;
  if (innerClassName) {
    innerClassNames = `${innerClassNames} ${innerClassName}`;
  }
  let footerText;
  if (okid) {
    footerText = (
      <ArchiveCardOKID className={innerClassNames} icon={okidIcon}>
        {formatOkid(okid)}
      </ArchiveCardOKID>
    );
  } else {
    footerText = <div className={innerClassNames}>{children}</div>;
  }

  return (
    <div className={classNames} {...otherProps}>
      {footerText}
      <div className={styles.grades}>
        {renderSustainabilityGrade && (
          <Text bold className={styles.grade} size='xs'>
            {hasSustainabilityGrade && (
              <>
                <Icon className={styles.gradeIcon} name={ICONS.SUSTAINABILITY_GRADE.name} height={12} width={12} />
                <span className={styles.gradeScore}>{sustainabilityGrade}</span>
              </>
            )}
          </Text>
        )}
        {renderConformityGrade && (
          <Text bold className={styles.grade} size='xs'>
            {hasConfirmityGrade && (
              <>
                <Icon className={styles.gradeIcon} name={ICONS.DOCUMENTATION_GRADE.name} height={12} width={12} />
                <span className={styles.gradeScore}>{formatPoints(conformityGrade)}</span>
              </>
            )}
          </Text>
        )}
        {renderReliabilityGrade && (
          <Text bold className={styles.grade} size='xs'>
            {hasReliabilityGrade && reliabilityGrade > 0 && (
              <>
                <Icon className={styles.gradeIcon} name={ICONS.RELIABILITY_GRADE.name} height={12} width={12} />
                <span className={styles.gradeScore}>{formatPoints(reliabilityGrade)}</span>
              </>
            )}
          </Text>
        )}
        {typeof points !== 'undefined' && (
          <Text bold className={styles.grade} size='xs'>
            <Icon className={styles.gradeIcon} name={ICONS.RATE.name} height={12} width={12} />
            <span className={styles.gradeScore}>{points}</span>
          </Text>
        )}
      </div>
    </div>
  );
}

ArchiveCardFooterRow.propTypes = {
  backgroundTint: PropTypes.oneOf(['navigation']),
  children: PropTypes.node,
  className: PropTypes.string,
  conformityGrade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  innerClassName: PropTypes.string,
  okid: PropTypes.string,
  okidIcon: PropTypes.string,
  points: PropTypes.number,
  reliabilityGrade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sustainabilityGrade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textTint: PropTypes.oneOf(['navigation']),
};
