import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Tag from 'OK/components/tag';
import TestSeverityIcon from 'OK/components/test/severityIcon';
import Text from 'OK/components/text';
import MediaAssetModel from 'OK/models/mediaAsset';
import TestAssetModel from 'OK/models/testAsset';
import TestPopup from 'OK/modules/test-popup';
import useI18n from 'OK/util/hooks/useI18n';

/**
 * Render inspection test in small card format.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {object} props.inspectionTestAsset The InspectionTestAsset object.
 * @param {(inspectionTestAssetRefId: string) => void} [props.onClickUnlink] Event handler for when the unlink button
 * is clicked.
 * @param {object} props.productInspectionTest The ProductInspectionTest object.
 */
export default function TestMiniCard(props) {
  /* Variables */

  const { className, testAsset, onClickUnlink, ...otherProps } = props;
  const { testSeverityLevel, REFID } = testAsset;
  const { t, locale } = useI18n();

  const title = useMemo(() => {
    return TestAssetModel.titleForTest(testAsset, locale);
  }, [locale, testAsset]);

  // State

  const [showPopup, setShowPopup] = useState(false);

  /* Methods */

  const openEditTestPopup = () => {
    setShowPopup(true);
  };

  /* Events */

  const onUnlinkClicked = (e) => {
    e.stopPropagation();

    if (onClickUnlink) {
      onClickUnlink(testAsset.id);
    }
  };

  /* Render */

  // Classes
  let classNames = styles.card;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let testCardImage = undefined;
  if (testAsset.testAssetMediaAssetList?.length) {
    let mediaAsset = testAsset.testAssetMediaAssetList.find(
      (tma) =>
        tma.mediaAsset.mediaType === MediaAssetModel.MEDIA_TYPE.IMAGE ||
        tma.mediaAsset.mediaType === MediaAssetModel.MEDIA_TYPE.VIDEO
    )?.mediaAsset;
    if (mediaAsset && mediaAsset.mediaType === MediaAssetModel.MEDIA_TYPE.IMAGE) {
      testCardImage = mediaAsset.imageData.imageURL;
    } /*  else if (mediaAsset && mediaAsset.mediaType === MediaAssetModel.MEDIA_TYPE.VIDEO) {
      testCardImage = mediaAsset.metadata?.displayImage;
    } */
  }

  return (
    <div className={classNames} onClick={openEditTestPopup} {...otherProps}>
      <div className={styles.media}>
        <img alt='' className={styles.reorderIcon} draggable='false' src='/icons/reorder_dark.svg' />
        {testCardImage && (
          <img alt={t('IMG_ALT_TEST_PHOTO')} className={styles.testCardPhoto} draggable='false' src={testCardImage} />
        )}
      </div>
      <div className={styles.info}>
        <Text bold className={styles.title} size='sm'>
          {title}
        </Text>
        <div className={styles.tags}>
          <TestSeverityIcon level={testSeverityLevel} />
          <Tag size='xs'>{REFID}</Tag>
          <Button
            className={styles.unlinkButton}
            icon='/icons/unlink_red.svg'
            linkStyle
            onClick={onUnlinkClicked}
            tint='alert'
          >
            {t('NOTE_CARD_UNLINK_BUTTON_DEFAULT')}
          </Button>
        </div>
      </div>
      {showPopup && <TestPopup dismiss={() => setShowPopup(false)} testRefId={testAsset.REFID} />}
    </div>
  );
}

TestMiniCard.propTypes = {
  className: PropTypes.string,
  onClickUnlink: PropTypes.func,
  testAsset: PropTypes.any,
};
