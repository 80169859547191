import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import {
  ArchiveCard,
  ArchiveCardFooter,
  ArchiveCardFooterRow,
  ArchiveCardHeader,
  ArchiveCardMainContent,
  ArchiveCardMainSection,
  ArchiveCardMediaGallery,
  ArchiveCardTitle,
} from 'OK/components/archiveCard';
import { ICONS } from 'OK/components/icon';
import Link from 'OK/components/link';
import Tag from 'OK/components/tag';
import Text from 'OK/components/text';
import NoteModel from 'OK/models/note';
import { setBrowserHistoryAction } from 'OK/state/app/actions';
import { formatNumber } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function RemarkArchiveCard(props) {
  /* Variables */

  const { cardClassName, className, linkToArchivePage = true, noteAsset, ...otherProps } = props;
  const { linkedProductList, REFID, textContentMap } = noteAsset;
  const { t, locale } = useI18n();
  const dispatch = useDispatch();

  const languageTags = useMemo(() => {
    const coveredLanguageList = Object.keys(textContentMap);
    const moreThan4Languages = coveredLanguageList.length > 4;
    const languages = moreThan4Languages ? coveredLanguageList.slice(0, 4) : coveredLanguageList;
    const tags = languages.map((languageIso) => (
      <Tag className={styles.tag} key={languageIso}>
        {languageIso}
      </Tag>
    ));
    if (moreThan4Languages) {
      const numberOfAdditionalLanguages = coveredLanguageList.length - 4;
      return (
        <>
          {tags}
          <Text className={styles.tagMoreLabel}>+ {numberOfAdditionalLanguages}</Text>
        </>
      );
    }

    return tags;
  }, [textContentMap]);

  const linksMessage = useMemo(() => {
    const numberOfLinks = linkedProductList.length;
    if (numberOfLinks === 0) {
      return t('ARCHIVE_CARD_0_LINKS');
    }
    if (numberOfLinks === 1) {
      return t('ARCHIVE_CARD_1_LINK');
    }
    return t('ARCHIVE_CARD_X_LINKS', { data: { number: formatNumber(numberOfLinks) } });
  }, [linkedProductList.length, t]);

  /* Render */

  const Container = linkToArchivePage ? Link : 'div';
  const containerProps = { className };
  if (linkToArchivePage) {
    containerProps.href = `/archive?refId=${REFID}&dataType=NOTE_ASSET`;
    containerProps.onClick = (e) => {
      e.preventDefault();
      history.pushState(
        { isPopup: true, refId: REFID, dataType: 'NOTE_ASSET', OKID: null },
        null,
        `/archive?refId=${REFID}&dataType=NOTE_ASSET`
      );
      dispatch(setBrowserHistoryAction(history));
    };
  }

  return (
    <Container {...containerProps}>
      <ArchiveCard className={cardClassName} {...otherProps}>
        <ArchiveCardMainSection>
          <ArchiveCardMediaGallery placeholderIconName={ICONS.REMARK.name} refId={REFID} />
          <ArchiveCardMainContent className={styles.content}>
            <ArchiveCardHeader type={t('NOTE_CARD_HEADER')} />
            <ArchiveCardTitle className={styles.title} lineLimit={3}>
              {NoteModel.previewText(noteAsset, locale)}
            </ArchiveCardTitle>
            <Text className={styles.tagRow} size='xs'>
              {languageTags}
            </Text>
          </ArchiveCardMainContent>
        </ArchiveCardMainSection>
        <ArchiveCardFooter>
          <ArchiveCardFooterRow>{linksMessage}</ArchiveCardFooterRow>
          <ArchiveCardFooterRow />
        </ArchiveCardFooter>
      </ArchiveCard>
    </Container>
  );
}

RemarkArchiveCard.propTypes = {
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  linkToArchivePage: PropTypes.bool,
  noteAsset: PropTypes.object.isRequired,
};
