import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function VideoMenu(props) {
  const { children, className } = props;

  let classNames = styles.menu;
  if (className) {
    classNames = `${classNames} ${className}`;
  }
  return <div className={classNames}>{children}</div>;
}

VideoMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
