import PropTypes from 'prop-types';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './styles.module.scss';

import { baseTheme } from 'OK/styles/theme';

export default function FadeInOutTransition(props) {
  const {
    appear = false,
    children,
    className,
    fadeDuration = baseTheme.timing.timingShortMs,
    style,
    ...otherProps
  } = props;

  const nodeRef = useRef();

  let effectContainerClassNames = styles.effectContainer;
  if (className) {
    effectContainerClassNames = `${effectContainerClassNames} ${className}`;
  }

  return (
    <CSSTransition
      appear={appear}
      classNames={{
        appear: appear ? styles.appear : undefined,
        appearDone: appear ? styles.appearDone : undefined,
        enter: styles.enter,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitDone: styles.exitDone,
      }}
      nodeRef={nodeRef}
      timeout={{
        enter: fadeDuration,
        exit: fadeDuration,
      }}
      style={{
        transition: `opacity ${fadeDuration}ms ease`,
      }}
      {...otherProps}
    >
      <div className={effectContainerClassNames} ref={nodeRef} style={style}>
        {children}
      </div>
    </CSSTransition>
  );
}

FadeInOutTransition.propTypes = {
  appear: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fadeDuration: PropTypes.number,
  style: PropTypes.object,
};
