import { makeReference, useApolloClient } from '@apollo/client';
import NextImage from 'next/image';
import PropTypes from 'prop-types';
import { useCallback, useContext, useRef, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import TextLayout from 'OK/components/layouts/content/text';
import MediaPicker from 'OK/components/mediaPicker';
import { Popup, PopupCloseButton, PopupContent, PopupButtonsGroup } from 'OK/components/popup';
import Progressable from 'OK/components/progressable';
import Text from 'OK/components/text';
import LogoImageMediaAssetModel from 'OK/models/logoImageMediaAsset';
import OrganisationModel from 'OK/models/organisation';
import {
  createAndSetLogoImageMediaAssetRequest,
  deleteLogoImageMediaAssetRequest,
  updateLogoMediaAssetRequest,
} from 'OK/networking/media';
import ThemeContext from 'OK/util/context/theme';
import useI18n from 'OK/util/hooks/useI18n';

export function EditOrganisationLogoPopup(props) {
  const { dismiss, organisation } = props;
  const { t } = useI18n();
  const apolloClient = useApolloClient();
  const theme = useContext(ThemeContext);
  const hasLogo = typeof organisation?.logoImageMediaAsset?.logoImageURL !== 'undefined';

  // Refs

  const logoMediaPickerRef = useRef();

  // APIs

  // Statese

  const [isCroppingLogo, setIsCroppingLogo] = useState(false);
  const [logoChangesError, setLogoChangesError] = useState(null);
  const [logoChangesLoading, setLogoChangesLoading] = useState(false);

  // Methods

  const addLogo = useCallback(
    async (logoFile) => {
      try {
        setLogoChangesLoading(true);
        setLogoChangesError(null);
        const response = await createAndSetLogoImageMediaAssetRequest(logoFile);
        if (!response.success) {
          setLogoChangesError(t('ERROR_GENERIC'));
          return;
        }
        const logoMediaAsset = {
          ...response.responseData,
          __typename: LogoImageMediaAssetModel.GRAPHQL_TYPE,
        };

        // Write logo to cache
        apolloClient.writeFragment({
          id: `${LogoImageMediaAssetModel.GRAPHQL_TYPE}:${logoMediaAsset.id}`,
          fragment: LogoImageMediaAssetModel.fragment,
          data: logoMediaAsset,
        });

        // Add logo reference to organisation
        apolloClient.cache.modify({
          id: `${OrganisationModel.GRAPHQL_TYPE}:${organisation.id}`,
          fields: {
            logoImageMediaAsset: () => makeReference(`${LogoImageMediaAssetModel.GRAPHQL_TYPE}:${logoMediaAsset.id}`),
          },
        });
      } catch (e) {
        setLogoChangesError(t('ERROR_GENERIC'));
      } finally {
        setLogoChangesLoading(false);
      }
    },
    [organisation?.id, apolloClient, t]
  );

  const replaceLogo = useCallback(
    async (logoFile) => {
      try {
        setLogoChangesLoading(true);
        setLogoChangesError(null);
        const response = await updateLogoMediaAssetRequest(organisation.logoImageMediaAsset?.id, logoFile);

        if (!response.success) {
          setLogoChangesError(t('ERROR_GENERIC'));
          return;
        }
        const logoMediaAsset = {
          ...response.responseData,
          __typename: LogoImageMediaAssetModel.GRAPHQL_TYPE,
        };
        apolloClient.writeFragment({
          id: `${LogoImageMediaAssetModel.GRAPHQL_TYPE}:${logoMediaAsset.id}`,
          fragment: LogoImageMediaAssetModel.fragment,
          data: logoMediaAsset,
        });
      } catch (e) {
        setLogoChangesError(t('ERROR_GENERIC'));
      } finally {
        setLogoChangesLoading(false);
      }
    },
    [organisation.logoImageMediaAsset?.id, apolloClient, t]
  );

  const deleteLogo = useCallback(async () => {
    try {
      setLogoChangesLoading(true);
      setLogoChangesError(null);
      const response = await deleteLogoImageMediaAssetRequest(organisation.logoImageMediaAsset?.id);
      if (!response.success) {
        setLogoChangesError(t('ERROR_GENERIC'));
        return;
      }
      // Remove logo from organisation
      apolloClient.writeFragment({
        id: `${OrganisationModel.GRAPHQL_TYPE}:${organisation.id}`,
        fragment: OrganisationModel.fragmentLogo,
        data: {
          logoImageMediaAsset: null,
          __typename: OrganisationModel.GRAPHQL_TYPE,
        },
      });

      // Purge logo from cache
      apolloClient.cache.evict({
        id: `${LogoImageMediaAssetModel.GRAPHQL_TYPE}:${organisation.logoImageMediaAsset.id}`,
      });
    } catch (e) {
      setLogoChangesError(t('ERROR_GENERIC'));
    } finally {
      setLogoChangesLoading(false);
    }
  }, [organisation?.id, organisation?.logoImageMediaAsset?.id, apolloClient, t]);

  const onSelectNewLogoFile = useCallback(
    (files) => {
      const newLogoFile = files[0];
      if (hasLogo) {
        replaceLogo(newLogoFile);
      } else {
        addLogo(newLogoFile);
      }
    },
    [addLogo, hasLogo, replaceLogo]
  );

  // Effects

  // Hide name error toast after 5 seconds

  // Render

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.container}>
        <div className={styles.header}>
          <h3 className={styles.textAvatar}>Edit details</h3>
          <div>
            <PopupCloseButton className={styles.buttonSave} linkStyle tint='navigation'>
              Done
            </PopupCloseButton>
          </div>
        </div>
        <div className={styles.profilePhotoContainer}>
          {!isCroppingLogo && (
            <Progressable className={styles.profilePhoto} inProgress={logoChangesLoading}>
              <NextImage
                layout='fill'
                src={organisation?.logoImageMediaAsset?.logoImageURL ?? `/img/empty_media_${theme.name}.svg`}
              />
            </Progressable>
          )}
          <TextLayout>
            {logoChangesError && (
              <Text className={styles.profilePhotoErrorMessage} size='sm' tint='alert'>
                {logoChangesError}
              </Text>
            )}
            {/* {hasLogo && (
              <Button block className={styles.profilePhotoButton} disabled={logoChangesLoading} tint='navigation'>
                Edit logo
              </Button>
            )} */}
            {!isCroppingLogo && (
              <div className={styles.profilePhotoButton}>
                <Button
                  disabled={logoChangesLoading}
                  linkStyle
                  onClick={() => logoMediaPickerRef.current?.open()}
                  tint='navigation'
                  style={{ margin: 0 }}
                >
                  {hasLogo ? t('REPLACE_LOGO') : t('ADD_LOGO')}
                </Button>
              </div>
            )}
            {hasLogo && !isCroppingLogo && (
              <Button
                className={styles.profilePhotoButton}
                disabled={logoChangesLoading}
                linkStyle
                onClick={deleteLogo}
                tint='alert'
              >
                {t('DELETE_LOGO')}
              </Button>
            )}
          </TextLayout>
          <MediaPicker
            mediaTypes={['photo']}
            invisible
            onBeganEditingMedia={() => setIsCroppingLogo(true)}
            onChange={onSelectNewLogoFile}
            onEndedEditingMedia={() => setIsCroppingLogo(false)}
            // promptToEditAfterPick={false}
            ref={logoMediaPickerRef}
          />
        </div>
        <PopupButtonsGroup>
          <PopupCloseButton tint='navigation'>Done</PopupCloseButton>
        </PopupButtonsGroup>
      </PopupContent>
    </Popup>
  );
}

EditOrganisationLogoPopup.propTypes = {
  dismiss: PropTypes.func,
  organisation: PropTypes.object,
};
