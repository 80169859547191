import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import Text from 'OK/components/text';
import { formatNumber } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export function ResultRow(props) {
  const {
    addBottomBorder = false,
    addBottomMargin = false,
    bold = false,
    count,
    indicatorTint,
    percentage,
    tint,
    type,
  } = props;
  const { t } = useI18n();

  let className = styles.row;
  if (addBottomMargin) {
    className = `${className} ${styles.withBottomMargin}`;
  } else if (addBottomBorder) {
    className = `${className} ${styles.withBottomBorder}`;
  }

  return (
    <>
      <tr className={className}>
        <td className={styles.typeColumn} colSpan={indicatorTint ? '1' : '2'}>
          <Text bold={bold} className={styles.text} tint={tint}>
            {t(type)}
          </Text>
        </td>
        {indicatorTint && (
          <td className={styles.indicatorColumn}>
            <Icon height={12} name={ICONS.DOT.name} tint={indicatorTint} width={12} />
          </td>
        )}
        <td className={styles.countColumn}>
          <Text bold={bold} className={styles.text} tint={tint}>
            {count ? formatNumber(count) : '-'}
          </Text>
        </td>
        <td className={styles.percentageColumn}>
          <Text bold={bold} className={styles.text} tint={tint}>
            {percentage}
          </Text>
        </td>
      </tr>
      {addBottomBorder && (
        <tr className={styles.spacer}>
          <td className={styles.typeColumn} colSpan='2' />
          <td className={styles.countColumn} />
          <td className={styles.percentageColumn} />
        </tr>
      )}
    </>
  );
}

ResultRow.propTypes = {
  addBottomBorder: PropTypes.bool,
  addBottomMargin: PropTypes.bool,
  bold: PropTypes.bool,
  count: PropTypes.number.isRequired,
  indicatorTint: PropTypes.string,
  percentage: PropTypes.string,
  tint: PropTypes.oneOf(['alert', 'creation', 'default', 'navigation', 'notification']),
  type: PropTypes.string.isRequired,
};

export function ResultTable(props) {
  const { children, className, ...otherProps } = props;

  let classNames = styles.resultTable;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <table className={classNames} {...otherProps}>
      {children}
    </table>
  );
}

ResultTable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export function SpacerRow(props) {
  const { addBottomBorder = false, addBottomMargin = false } = props;

  let className = styles.row;
  if (addBottomMargin) {
    className = `${className} ${styles.withBottomMargin}`;
  } else if (addBottomBorder) {
    className = `${className} ${styles.withBottomBorder}`;
  }

  return (
    <>
      <tr className={className}>
        <td className={styles.spacerRowTD} colSpan='2'>
          &nbsp;
        </td>
        <td className={styles.countColumn}>&nbsp;</td>
        <td className={styles.spacerRowTD}>&nbsp;</td>
      </tr>
      {addBottomBorder && (
        <tr className={styles.spacer}>
          <td className={styles.typeColumn} colSpan='2' />
          <td className={styles.countColumn} />
          <td className={styles.percentageColumn} />
        </tr>
      )}
    </>
  );
}

SpacerRow.propTypes = {
  addBottomBorder: PropTypes.bool,
  addBottomMargin: PropTypes.bool,
};
