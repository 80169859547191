import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import PopupMainMenu from 'OK/components/menus/popupMenu';
import { PopupAsset, PopupContentAsset } from 'OK/components/popup/popupAsset';
import PublicProductPage from 'OK/modules/publicProductPage';
import PublicSitePage from 'OK/modules/publicSitePage';
import { getItemByOKIDQuery } from 'OK/networking/items';
import { getProductByRefIdPublicQuery } from 'OK/networking/products';
import { GetSiteWithOKIDPublicArchivePageQuery } from 'OK/networking/sites';
import FileEditPage from 'OK/pages/archive/file/[refId]/edit';
import ArchiveLabelPage from 'OK/pages/archive/label/[refId]/edit';
import LogPage from 'OK/pages/archive/log/[refId]';
import EditMediaPage from 'OK/pages/archive/media/[refId]/edit';
import EditNotePage from 'OK/pages/archive/note/[refId]/edit';
import EditOrderPage from 'OK/pages/archive/order/[refId]/edit';
import EditTestPage from 'OK/pages/archive/step/[refId]/edit';
import EditInspectionPage from 'OK/pages/archive/workflow/[refId]/edit';
import OrganisationPage from 'OK/pages/organisation/[OKID]';
import UserPage from 'OK/pages/user/[okid]';
import { unformatOkid } from 'OK/util/formatting';

export default function PopupAssetInterface(props) {
  const { dismiss, dataType, OKID, REFID, refs, ...otherProps } = props;

  const [getProductAPI, getProductAPIResult] = useLazyQuery(getProductByRefIdPublicQuery, {
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
  });

  const [getSiteAPI, getSiteAPIResult] = useLazyQuery(GetSiteWithOKIDPublicArchivePageQuery, {
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
  });

  const [getItemAPI, getItemAPIResult] = useLazyQuery(getItemByOKIDQuery, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  let asset = null;

  useEffect(() => {
    if (REFID || OKID) {
      switch (dataType) {
        case 'PRODUCT':
          getProductAPI({
            variables: {
              refId: REFID,
            },
          });
          break;
        case 'SITE':
          getSiteAPI({
            variables: { OKID: OKID ? unformatOkid(OKID) : null },
          });
          break;
        case 'ITEM':
          getItemAPI({ variables: { OKID: OKID ? unformatOkid(OKID) : null } });
      }
    }
  }, [OKID, REFID, dataType, getItemAPI, getProductAPI, getSiteAPI]);

  switch (dataType) {
    case 'PRODUCT':
      asset = getProductAPIResult.data?.product;
      break;
    case 'SITE':
      asset = getSiteAPIResult.data?.site;
      break;
    case 'ITEM':
      asset = getItemAPIResult.data?.item;
      break;
    case 'ORDER':
      asset = 'ORDER';
      break;
    case 'MEDIA_ASSET':
      asset = 'MEDIA_ASSET';
      break;
    case 'FILE':
      asset = 'FILE';
      break;
    case 'NOTE_ASSET':
      asset = 'NOTE_ASSET';
      break;
    case 'LABEL':
      asset = 'LABEL';
      break;
    case 'TEST_ASSET':
      asset = 'TEST_ASSET';
      break;
    case 'INSPECTION_ASSET':
      asset = 'INSPECTION_ASSET';
      break;
    case 'INSPECTION_LOG':
      asset = 'INSPECTION_LOG';
      break;
    case 'USER':
      asset = 'USER';
      break;
    case 'ORGANISATION':
      asset = 'ORGANISATION';
  }

  const mainContent = useMemo(() => {
    if (!asset) return <Icon className={styles.loadingSpinner} height={40} name={ICONS.SPINNER.name} width={40} />;
    else {
      switch (dataType) {
        case 'PRODUCT':
          return <PublicProductPage product={asset} />;
        case 'SITE':
          return <PublicSitePage site={asset} />;
        case 'ITEM':
          return <PublicProductPage product={asset?.product} item={asset} />;
        case 'ORDER':
          return <EditOrderPage REFIDasProp={REFID} />;
        case 'MEDIA_ASSET':
          return <EditMediaPage REFIDasProp={REFID} />;
        case 'FILE':
          return <FileEditPage REFIDasProp={REFID} />;
        case 'NOTE_ASSET':
          return <EditNotePage REFIDasProp={REFID} />;
        case 'LABEL':
          return <ArchiveLabelPage REFIDasProp={REFID} />;
        case 'TEST_ASSET':
          return <EditTestPage REFIDasProp={REFID} />;
        case 'INSPECTION_ASSET':
          return <EditInspectionPage REFIDasProp={REFID} />;
        case 'INSPECTION_LOG':
          return <LogPage REFIDasProp={REFID} />;
        case 'USER':
          return <UserPage OKIDasProp={OKID} />;
        case 'ORGANISATION':
          return <OrganisationPage OKIDasProp={OKID} />;
      }
    }
  }, [OKID, REFID, asset, dataType]);

  /* Render */

  return (
    <>
      <PopupAsset dismiss={dismiss}>
        <PopupMainMenu />
        <PopupContentAsset refs={refs} {...otherProps}>
          {mainContent}
        </PopupContentAsset>
      </PopupAsset>
    </>
  );
}

PopupAssetInterface.propTypes = {
  dataType: PropTypes.string,
  dismiss: PropTypes.func,
  OKID: PropTypes.string,
  REFID: PropTypes.string,
  refs: PropTypes.object,
};
