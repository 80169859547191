import Head from 'next/head';
import PropTypes from 'prop-types';

export default function PageTitle(props) {
  const { children } = props;
  return (
    <Head>
      <title>{children} | OK</title>
    </Head>
  );
}

PageTitle.propTypes = {
  children: PropTypes.string.isRequired,
};
