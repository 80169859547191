import { gql } from '@apollo/client';

export default class UnversionedNoteAssetModel {
  static GRAPHQL_TYPE = 'UnversionedNoteAsset';

  static fragmentName = 'UnversionedNoteAsset_Fragment';
  static fragment = gql`
    fragment ${UnversionedNoteAssetModel.fragmentName} on ${UnversionedNoteAssetModel.GRAPHQL_TYPE} {
      textContentMap
    }
  `;
}
