import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import CardLayout from 'OK/components/layouts/card';
import Link from 'OK/components/link';
import Text from 'OK/components/text';
import Tooltip from 'OK/components/tooltip';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import useI18n from 'OK/util/hooks/useI18n';

// Base subscription component
const Subscription = forwardRef((props, forwardedRef) => {
  const { children, className, ...otherProps } = props;

  let classNames = styles.tier;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <CardLayout className={classNames} fixedWidth={false} ref={forwardedRef} {...otherProps}>
      {children}
    </CardLayout>
  );
});

Subscription.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

/* Specific subscriptions */

export function CoworkerSubscription(props) {
  const { t, tHTML } = useI18n();
  const {
    buttonDisabled = false,
    buttonLink = '/auth',
    buttonLoading = false,
    buttonTitle = t('SUBSCRIPTION_CARD_GET_STARTED_BUTTON'),
    fineprint = (
      <Text bold className={styles.finePrint} size='xs'>
        {t('SUBSCRIPTION_CARD_RENEW_MONTHLY_FOR_TEMP_PROJECTS')}
      </Text>
    ),
    onClickSubscribe,
    tooltipMessage,
    tooltipTint,
    ...otherProps
  } = props;

  const renderButtonAsLink = typeof onClickSubscribe !== 'function';
  const ButtonComponent = renderButtonAsLink ? Link : Button;

  return (
    <Subscription {...otherProps}>
      {tooltipMessage ? (
        <Tooltip className={styles.supplierTooltip} tint={tooltipTint}>
          {tooltipMessage}
        </Tooltip>
      ) : null}
      <h3>{t('SUBSCRIPTION_CARD_BRAND_MONTHLY_HEADER')}</h3>
      <p>{tHTML('SUBSCRIPTION_CARD_BRAND_DESCRIPTION')}</p>
      <div className={styles.priceContainer}>
        <h2 className={styles.price}>995</h2>
        <div>
          <p>
            <strong>{t('SUBSCRIPTION_CARD_CURRENCY_DISCOUNTED')} 995</strong>
          </p>
          <p>{t('SUBSCRIPTION_CARD_BILLED_MONTHLY')}</p>
        </div>
      </div>
      <ul className={styles.features}>
        <li>
          <strong>{t('SUBSCRIPTION_CARD_ALL_FREE_FEATURES')}</strong>
        </li>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_LABELS_POINTS_ANALYTICS')}</li>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_PLAN_DO_INVITE_WORKFLOWS')}</li>
      </ul>
      <ButtonComponent
        block
        buttonStyle
        className={styles.button}
        disabled={buttonDisabled}
        href={renderButtonAsLink ? buttonLink : null}
        loading={buttonLoading}
        onClick={onClickSubscribe}
        tint='navigation'
        withCaret
      >
        {buttonTitle}
      </ButtonComponent>
      {fineprint}
    </Subscription>
  );
}

export function SupplierSubscription(props) {
  /* Variables */

  const { t, tHTML } = useI18n();
  const {
    buttonDisabled = false,
    buttonLink = '/auth',
    buttonLoading = false,
    buttonTitle = t('SUBSCRIPTION_CARD_SAVE_OVER_50'),
    fineprint = (
      <Text bold className={styles.finePrint} size='xs'>
        {t('SUBSCRIPTION_CARD_NO_PAYMENT_UNTIL_TRIAL_END')}
      </Text>
    ),
    onClickSubscribe,
    tooltipMessage = t('SUBSCRIPTION_CARD_30_DAY_COMMITMENT_FREE'),
    tooltipTint = 'creation',
    ...otherProps
  } = props;

  // State

  const [appeared, setAppeared] = useState(false);

  // Refs

  const ref = useRef();
  const visibleObserverRef = useRef();

  /* Effects */

  // Mark the component as having appeared after it is at least 50% visible
  useEffect(() => {
    const element = ref.current;
    const visibleObserver = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setAppeared(true);
        }
      },
      { threshold: [0.5] }
    );
    visibleObserverRef.current = visibleObserver;
    visibleObserver.observe(element);

    return function cleanup() {
      visibleObserver.unobserve(element);
    };
  }, []);

  /* Render */

  const renderButtonAsLink = typeof onClickSubscribe !== 'function';
  const ButtonComponent = renderButtonAsLink ? Link : Button;

  return (
    <Subscription ref={ref} {...otherProps}>
      {tooltipMessage ? (
        <FadeInOutTransition in={appeared}>
          <Tooltip bounce className={styles.supplierTooltip} tint={tooltipTint}>
            {tooltipMessage}
          </Tooltip>
        </FadeInOutTransition>
      ) : null}
      <h3>{t('SUBSCRIPTION_CARD_BRAND_ANNUALLY_HEADER')}</h3>
      <p>{tHTML('SUBSCRIPTION_CARD_BRAND_DESCRIPTION')}</p>
      <div className={styles.priceContainer}>
        <h2 className={`${styles.price} ${styles.discountPrice}`}>495</h2>
        <div>
          <p>
            <strong>
              {t('SUBSCRIPTION_CARD_CURRENCY_DISCOUNTED')}&nbsp;
              <span className={styles.fullPrice}>
                995 <span className={styles.strike} />
              </span>
            </strong>
          </p>
          <p>{t('SUBSCRIPTION_CARD_BILLED_ANNUALLY')}</p>
        </div>
      </div>
      <ul className={styles.features}>
        <li>
          <strong>{t('SUBSCRIPTION_CARD_ALL_FREE_FEATURES')}</strong>
        </li>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_LABELS_POINTS_ANALYTICS')}</li>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_PLAN_DO_INVITE_WORKFLOWS')}</li>
      </ul>
      <ButtonComponent
        block
        buttonStyle
        className={styles.button}
        disabled={buttonDisabled}
        href={renderButtonAsLink ? buttonLink : null}
        loading={buttonLoading}
        onClick={onClickSubscribe}
        tint='creation'
        withCaret
      >
        {buttonTitle}
      </ButtonComponent>
      {fineprint}
    </Subscription>
  );
}

export function TraderSubscription(props) {
  const { t, tHTML } = useI18n();
  const {
    buttonDisabled = false,
    buttonLink = '/auth',
    buttonLoading = false,
    buttonTitle = t('SUBSCRIPTION_CARD_GET_STARTED_BUTTON'),
    fineprint = (
      <Text bold className={styles.finePrint} size='xs'>
        {t('SUBSCRIPTION_CARD_FINEPRINT_NO_COMMITMENT')}
      </Text>
    ),
    onClickSubscribe,
    tooltipMessage,
    tooltipTint,
    ...otherProps
  } = props;

  const renderButtonAsLink = typeof onClickSubscribe !== 'function';
  const ButtonComponent = renderButtonAsLink ? Link : Button;

  return (
    <Subscription {...otherProps}>
      {tooltipMessage ? (
        <Tooltip className={styles.supplierTooltip} tint={tooltipTint}>
          {tooltipMessage}
        </Tooltip>
      ) : null}
      <h3>{t('SUBSCRIPTION_CARD_PLAN_NAME_FREE')}</h3>
      <p>{tHTML('SUBSCRIPTION_CARD_FREE_DESCRIPTION')}</p>
      <div className={styles.priceContainer}>
        <h2 className={styles.price}>0</h2>
        <div>
          <p>
            <strong>USD</strong>
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
      <ul className={styles.features}>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_UNLIMITED_COWORKERS')}</li>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_ORGANISATION_PAGE')}</li>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_WORKFLOWS_BY_INVITE')}</li>
      </ul>
      <ButtonComponent
        block
        buttonStyle
        className={styles.button}
        disabled={buttonDisabled}
        href={renderButtonAsLink ? buttonLink : null}
        loading={buttonLoading}
        onClick={onClickSubscribe}
        tint='navigation'
        withCaret
      >
        {buttonTitle}
      </ButtonComponent>
      {fineprint}
    </Subscription>
  );
}

const specificSubscriptionPropTypes = {
  buttonDisabled: PropTypes.bool,
  buttonLink: PropTypes.string,
  buttonLoading: PropTypes.bool,
  buttonTitle: PropTypes.string,
  fineprint: PropTypes.node,
  onClickSubscribe: PropTypes.func,
  tooltipMessage: PropTypes.string,
  tooltipTint: PropTypes.string,
};

CoworkerSubscription.propTypes = specificSubscriptionPropTypes;
SupplierSubscription.propTypes = specificSubscriptionPropTypes;
TraderSubscription.propTypes = specificSubscriptionPropTypes;

export function FreeSubscription(props) {
  const { t, tHTML } = useI18n();
  const {
    buttonDisabled = false,
    buttonLink = '/auth',
    buttonLoading = false,
    buttonTint = 'navigation',
    buttonTitle = t('SUBSCRIPTION_CARD_GET_STARTED_BUTTON'),
    fineprint,
    onClickSubscribe,
    showButton = true,
    tooltipMessage,
    tooltipTint,
    ...otherProps
  } = props;

  const renderButtonAsLink = typeof onClickSubscribe !== 'function';
  const ButtonComponent = renderButtonAsLink ? Link : Button;

  return (
    <Subscription {...otherProps}>
      {tooltipMessage ? (
        <Tooltip className={styles.supplierTooltip} tint={tooltipTint}>
          {tooltipMessage}
        </Tooltip>
      ) : null}
      <h3>{t('SUBSCRIPTION_CARD_PLAN_NAME_FREE')}</h3>
      <p>{tHTML('SUBSCRIPTION_CARD_FREE_DESCRIPTION')}</p>
      <div className={styles.priceContainer}>
        <h2 className={styles.price}>0</h2>
        <div>
          <p>
            <strong>USD</strong>
          </p>
        </div>
      </div>
      <ul className={styles.features}>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_UNLIMITED_USERS_PRODUCTS')}</li>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_ORGANISATION_PRODUCT_PAGES')}</li>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_WORKFLOWS_BY_INVITE')}</li>
      </ul>
      {showButton && (
        <ButtonComponent
          block
          buttonStyle
          className={styles.button}
          disabled={buttonDisabled}
          href={renderButtonAsLink ? buttonLink : null}
          loading={buttonLoading}
          onClick={onClickSubscribe}
          tint={buttonTint}
          withCaret
        >
          {buttonTitle}
        </ButtonComponent>
      )}
      {fineprint}
    </Subscription>
  );
}

export function BrandSubscription(props) {
  const { t, tHTML } = useI18n();
  const {
    buttonDisabled = false,
    buttonLink = '/auth',
    buttonLoading = false,
    buttonTint = 'navigation',
    buttonTitle = t('SUBSCRIPTION_CARD_GET_STARTED_BUTTON'),
    fineprint,
    onClickSubscribe,
    showAnnualPricing = true,
    showButton = true,
    tooltipMessage,
    tooltipTint,
    ...otherProps
  } = props;

  const renderButtonAsLink = typeof onClickSubscribe !== 'function';
  const ButtonComponent = renderButtonAsLink ? Link : Button;

  return (
    <Subscription {...otherProps}>
      {tooltipMessage ? (
        <Tooltip bounce className={styles.supplierTooltip} tint={tooltipTint}>
          {tooltipMessage}
        </Tooltip>
      ) : null}
      <h3>{t('SUBSCRIPTION_CARD_PLAN_NAME_BRAND')}</h3>
      <p>{tHTML('SUBSCRIPTION_CARD_BRAND_DESCRIPTION')}</p>
      <div className={styles.priceContainer}>
        <h2 className={styles.price}>{showAnnualPricing ? '495' : '995'}</h2>
        <div>
          <p>
            <strong>{t('SUBSCRIPTION_CARD_CURRENCY_MONTHLY')}</strong>
          </p>
          <p>{showAnnualPricing ? t('SUBSCRIPTION_CARD_BILLED_ANNUALLY') : t('SUBSCRIPTION_CARD_BILLED_MONTHLY')}</p>
        </div>
      </div>
      <ul className={styles.features}>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_GENERATE_OK_LABELS')}</li>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_ADD_DOCUMENTATION')}</li>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_PLAN_DO_INVITE_WORKFLOWS')}</li>
      </ul>
      {showButton && (
        <ButtonComponent
          block
          buttonStyle
          className={styles.button}
          disabled={buttonDisabled}
          href={renderButtonAsLink ? buttonLink : null}
          loading={buttonLoading}
          onClick={onClickSubscribe}
          tint={buttonTint}
          withCaret
        >
          {buttonTitle}
        </ButtonComponent>
      )}
      {fineprint}
      <Text className={styles.finePrint} size='xs'>
        <p>
          <b>{t('SUBSCRIPTION_CARD_FINEPRINT_COMMITMENT_FREE_NO_RENEWAL')}</b>
        </p>
        <p>
          {showAnnualPricing
            ? t('SUBSCRIPTION_CARD_PRICE_PER_YEAR_IF_RENEWED', { data: { price: '5,940' } })
            : t('SUBSCRIPTION_CARD_PRICE_PER_MONTH_IF_RENEWED', { data: { price: '995' } })}
        </p>
      </Text>
    </Subscription>
  );
}

export function EnterpriseSubscription(props) {
  const { t, tHTML } = useI18n();
  const {
    buttonDisabled = false,
    buttonLink = '/auth',
    buttonLoading = false,
    buttonTint = 'navigation',
    buttonTitle = t('SUBSCRIPTION_CARD_GET_STARTED_BUTTON'),
    fineprint,
    onClickSubscribe,
    showAnnualPricing = true,
    showButton = true,
    tooltipMessage,
    tooltipTint,
    ...otherProps
  } = props;

  const renderButtonAsLink = typeof onClickSubscribe !== 'function';
  const ButtonComponent = renderButtonAsLink ? Link : Button;

  return (
    <Subscription {...otherProps}>
      {tooltipMessage ? (
        <Tooltip className={styles.supplierTooltip} tint={tooltipTint}>
          {tooltipMessage}
        </Tooltip>
      ) : null}
      <h3>{t('SUBSCRIPTION_CARD_PLAN_NAME_ENTERPRISE')}</h3>
      <p>{tHTML('SUBSCRIPTION_CARD_ENTERPRISE_DESCRIPTION')}</p>
      <div className={styles.priceContainer}>
        <h2 className={styles.price}>{showAnnualPricing ? '1,995' : '3,995'}</h2>
        <div>
          <p>
            <strong>{t('SUBSCRIPTION_CARD_CURRENCY_MONTHLY')}</strong>
          </p>
          <p>{showAnnualPricing ? t('SUBSCRIPTION_CARD_BILLED_ANNUALLY') : t('SUBSCRIPTION_CARD_BILLED_MONTHLY')}</p>
        </div>
      </div>
      <ul className={styles.features}>
        <li>
          <strong>{t('SUBSCRIPTION_CARD_ALL_FREE_BRAND_FEATURES')}</strong>
        </li>
        <li>{t('SUBSCRIPTION_CARD_FEATURE_SHARE_FEATURES')}</li>
      </ul>
      {showButton && (
        <ButtonComponent
          block
          buttonStyle
          className={styles.button}
          disabled={buttonDisabled}
          href={renderButtonAsLink ? buttonLink : null}
          loading={buttonLoading}
          onClick={onClickSubscribe}
          tint={buttonTint}
          withCaret
        >
          {buttonTitle}
        </ButtonComponent>
      )}
      {fineprint}
      <Text className={styles.finePrint} size='xs'>
        <p>
          <b>{t('SUBSCRIPTION_CARD_FINEPRINT_COMMITMENT_FREE_NO_RENEWAL')}</b>
        </p>
        <p>
          {showAnnualPricing
            ? t('SUBSCRIPTION_CARD_PRICE_PER_YEAR_IF_RENEWED', { data: { price: '23,940' } })
            : t('SUBSCRIPTION_CARD_PRICE_PER_MONTH_IF_RENEWED', { data: { price: '3,995' } })}
        </p>
      </Text>
    </Subscription>
  );
}
