import dateIsAfter from 'date-fns/isAfter';
import dateIsBefore from 'date-fns/isBefore';
import datesAreEqual from 'date-fns/isSameDay';
import lastDayOfYear from 'date-fns/lastDayOfYear';
import { enUS, zhCN } from 'date-fns/locale';
import startOfYear from 'date-fns/startOfYear';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
registerLocale('en', enUS);
// TODO: Only do this on-demand when language is not English
registerLocale('zh-CN', zhCN);

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import Text from 'OK/components/text';
import { formatDate } from 'OK/util/formatting';
import { getFirstMomentOfDate, getLastMomentOfDate } from 'OK/util/functions/date';
import useI18n from 'OK/util/hooks/useI18n';

function dateSelectionIsRange(date1, date2) {
  return !datesAreEqual(date1, date2);
}

export default function DatePickerPopup(props) {
  /* Variables */

  const {
    dataIndicators = [],
    dataIndicatorsDescription,
    dismiss,
    getDataIndicatorsForDateRange,
    initialEndDate: initialEndDateProp,
    initialStartDate: initialStartDateProp,
    onSave,
  } = props;
  const { locale, t } = useI18n();

  /* State */

  // Default values

  const initialStartDate = useRef(getFirstMomentOfDate(initialStartDateProp ?? new Date()));
  const initialEndDate = useRef(getLastMomentOfDate(initialEndDateProp ?? initialStartDate.current));

  const [hasChanged, setHasChanged] = useState(false);
  const [startDate, setStartDate] = useState(initialStartDate.current);
  const [endDate, setEndDate] = useState(initialEndDate.current);

  // Computed state
  const hasSelectedRange = dateSelectionIsRange(startDate, endDate);
  const firstDataIndicatorDate = dataIndicators.length ? dataIndicators[0].date : null;
  const lastDataIndicatorDate = dataIndicators.length ? dataIndicators[dataIndicators.length - 1].date : null;
  const supportsDataIndicators = typeof getDataIndicatorsForDateRange === 'function';

  /* Methods */

  const getDataIndicatorsIncludingDate = useCallback(
    (date) => {
      if (!supportsDataIndicators) {
        return;
      }

      getDataIndicatorsForDateRange(getFirstMomentOfDate(startOfYear(date)), getLastMomentOfDate(lastDayOfYear(date)));
    },
    [getDataIndicatorsForDateRange, supportsDataIndicators]
  );

  const reset = useCallback(() => {
    setStartDate(initialStartDate.current);
    setEndDate(initialEndDate.current);
    setHasChanged(false);
  }, []);

  const save = useCallback(
    (startDateParam, endDateParam) => {
      if (typeof onSave !== 'function') {
        return;
      }

      // Save dates from parameters if passed, otherwise save from state
      const startDateToSave = startDateParam ?? startDate;
      const endDateToSave = endDateParam ?? endDate;

      if (dateSelectionIsRange(startDateToSave, endDateToSave)) {
        onSave(startDateToSave, endDateToSave);
      } else {
        onSave(startDateToSave);
      }
    },
    [endDate, onSave, startDate]
  );

  /* Event handlers */

  const onChange = useCallback(
    (dates) => {
      const [start, end] = dates;
      const newStartDate = getFirstMomentOfDate(start);
      const newEndDate = end ? getLastMomentOfDate(end) : null;
      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setHasChanged(true);

      if (newEndDate) {
        // Immediately save when selection is complete
        save(newStartDate, newEndDate);
      }
    },
    [save]
  );

  const onChangeMonth = useCallback(
    (date) => {
      if (!supportsDataIndicators) {
        return;
      }
      if (
        !firstDataIndicatorDate ||
        dateIsBefore(date, firstDataIndicatorDate) ||
        !lastDataIndicatorDate ||
        dateIsAfter(date, lastDataIndicatorDate)
      ) {
        getDataIndicatorsIncludingDate(date);
      }
    },
    [firstDataIndicatorDate, getDataIndicatorsIncludingDate, lastDataIndicatorDate, supportsDataIndicators]
  );

  /* Effects */

  // Initial load of data indicators
  useEffect(() => {
    if (!supportsDataIndicators) {
      return;
    }
    getDataIndicatorsIncludingDate(initialStartDate.current);
  }, [getDataIndicatorsIncludingDate, supportsDataIndicators]);

  /* Render */

  const formattedStartDate = startDate ? formatDate(startDate, locale, { style: 'short' }) : '';
  let formattedEndDate;
  if (hasSelectedRange) {
    formattedEndDate = endDate ? formatDate(endDate, locale, { style: 'short' }) : '';
  }

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.popup}>
        <Text bold>{hasSelectedRange ? `${formattedStartDate} - ${formattedEndDate}` : formattedStartDate}</Text>
        <ReactDatePicker
          formatWeekDay={(date) => {
            return date.slice(0, 3);
          }}
          weekDayClassName={() => {
            return locale === 'zh-CN' ? styles.reducedFont : undefined;
          }}
          selected={null}
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          onMonthChange={onChangeMonth}
          selectsRange
          inline
          locale={locale}
          disabledKeyboardNavigation
          renderDayContents={(dayOfMonth, date) => {
            const hasData = dataIndicators.find((i) => datesAreEqual(i.date, date))?.hasData ?? false;
            return (
              <>
                {dayOfMonth}
                {hasData && <span className={styles.hasDataIndicator} />}
              </>
            );
          }}
        />
        {dataIndicatorsDescription && (
          <div className={styles.legend}>
            <Text size='sm'>
              <span className={styles.sampleDataIndicator} /> {dataIndicatorsDescription}
            </Text>
          </div>
        )}
      </PopupContent>
      <PopupButtonsGroup>
        {!hasChanged && <PopupCloseButton tint='navigation'>{t('CLOSE')}</PopupCloseButton>}
        {hasChanged && (
          <>
            <Button onClick={reset} tint='alert'>
              {t('RESET')}
            </Button>
            {endDate && (
              <Button onClick={save} tint='navigation'>
                {t('SET')}
              </Button>
            )}
          </>
        )}
      </PopupButtonsGroup>
    </Popup>
  );
}

DatePickerPopup.propTypes = {
  dataIndicators: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date).isRequired,
      hasData: PropTypes.bool.isRequired,
    })
  ),
  dataIndicatorsDescription: PropTypes.string,
  dismiss: PropTypes.func.isRequired,
  getDataIndicatorsForDateRange: PropTypes.func,
  initialEndDate: PropTypes.instanceOf(Date),
  initialStartDate: PropTypes.instanceOf(Date),
  onSave: PropTypes.func,
};
