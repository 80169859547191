import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import UIContext from 'OK/util/context/ui';

export default function Notice(props) {
  const { children, className, extendSides = false, ...otherProps } = props;

  let classNames = `${styles.container} ${className || ''}`;
  if (extendSides) {
    classNames = `${classNames} ${styles.extendSides}`;
  }

  return (
    <UIContext.Provider value='midtone'>
      <div className={classNames} {...otherProps}>
        {children}
      </div>
    </UIContext.Provider>
  );
}

Notice.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  extendSides: PropTypes.bool,
};
