// Adapted from https://github.com/Darth-Knoppix/example-react-fullscreen/blob/master/src/utils/useFullscreenStatus.js

import { useEffect, useState } from 'react';

const isBrowser = typeof document !== 'undefined';
const doc = isBrowser ? document : {};
const fullscreenElementProp = getBrowserFullscreenElementProp(doc);
const isCurrentlyFullscreen = () => {
  if (fullscreenElementProp) {
    return doc[fullscreenElementProp] !== null;
  }

  return false;
};

/**
 * React Hook that returns whether a fullscreen element is present.
 */
export default function useFullscreenStatus() {
  const [isFullscreen, setIsFullscreen] = useState(isCurrentlyFullscreen());

  useEffect(() => {
    const handleFullscreenChange = () => {
      const currentIsFullscreen = isCurrentlyFullscreen();
      setIsFullscreen(currentIsFullscreen);
    };

    doc.addEventListener('fullscreenchange', handleFullscreenChange);
    doc.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    doc.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      doc.removeEventListener('fullscreenchange', handleFullscreenChange);
      doc.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      doc.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  });

  if (!isBrowser) {
    return false;
  }

  return isFullscreen;
}

function getBrowserFullscreenElementProp(doc) {
  let prop;
  if (typeof doc.fullscreenElement !== 'undefined') {
    prop = 'fullscreenElement';
  } else if (typeof doc.mozFullScreenElement !== 'undefined') {
    prop = 'mozFullScreenElement';
  } else if (typeof doc.msFullscreenElement !== 'undefined') {
    prop = 'msFullscreenElement';
  } else if (typeof doc.webkitFullscreenElement !== 'undefined') {
    prop = 'webkitFullscreenElement';
  } else {
    okerror('fullscreenElement is not supported by this browser.');
  }

  return prop;
}
