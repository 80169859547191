import mergeObjects from 'lodash/merge';

import ChartJSHtmlLegendPlugin from './htmlLegendPlugin';

export function generateAreaChartConfig(data, options) {
  const {
    annotations,
    legendContainerId,
    maxYValue,
    minYValue = 0,
    theme,
    tooltipLabelCallback,
    ...otherOptions
  } = options;

  const { datasets } = data;
  // Set max X range to max number of data points (minimum 2).
  const maxXValue = Math.max(...datasets.map((d) => d.data.length - 1), 2) + 0.5;

  const config = {
    data,
    options: {
      interaction: {
        axis: 'x',
        intersect: false,
        mode: 'index',
      },
      layout: {
        autoPadding: false,
        padding: {
          bottom: 9,
          top: 17,
        },
      },
      maintainAspectRatio: false,
      plugins: {
        htmlLegend: {
          containerID: legendContainerId,
        },
        legend: {
          display: false,
          labels: {
            color: theme.colors.text,
          },
        },
        tooltip: {
          backgroundColor: theme.colors.text,
          bodyColor: theme.colors.textAlternate,
          bodyFont: {
            family: 'Noto Sans',
            lineHeight: 1,
            size: 14,
            weight: 'bold',
          },
          callbacks: {
            label: tooltipLabelCallback,
            title: () => null,
          },
          caretPadding: 16,
          caretSize: 0,
          displayColors: false,
          filter: (element, index, arr, data) => {
            const dataset = data.datasets[element.datasetIndex];
            return !dataset.hideTooltip;
          },
          padding: 5,
          titleMarginBottom: 0,
          yAlign: 'bottom',
        },
      },
      scales: {
        x: {
          grid: {
            borderColor: theme.colors.text,
            borderWidth: 2,
            display: false,
          },
          max: maxXValue,
          ticks: {
            display: false,
          },
          type: 'linear',
        },
        y: {
          grid: {
            drawBorder: false,
            drawTicks: false,
            color: theme.colors.contentBackgroundFocus,
          },
          min: minYValue,
          ticks: {
            backdropPadding: 0,
            count: 10,
            display: false,
            padding: 0,
            showLabelBackdrop: false,
            z: -1,
          },
        },
      },
      spanGaps: true,
    },
    plugins: [ChartJSHtmlLegendPlugin],
  };

  if (annotations) {
    config.options.plugins.annotation = { annotations };
  }

  if (maxYValue) {
    config.options.scales.y.max = maxYValue;
  }

  return mergeObjects(config, otherOptions);
}

export function generateBarChartConfig(data, options) {
  const {
    annotations,
    maxYValue,
    minYValue = 0,
    theme,
    tickFormatter = (tickValue) => tickValue,
    tooltipLabelCallback,
    ...otherOptions
  } = options;

  const config = {
    data,
    options: {
      barThickness: 20,
      interaction: {
        axis: 'x',
        intersect: false,
        mode: 'index',
      },
      layout: {
        autoPadding: false,
      },
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: theme.colors.text,
          bodyColor: theme.colors.textAlternate,
          bodyFont: {
            lineHeight: 1,
            family: 'Noto Sans',
            size: 14,
            weight: 'bold',
          },
          callbacks: {
            label: tooltipLabelCallback,
            title: () => null,
          },
          caretPadding: 16,
          caretSize: 0,
          displayColors: false,
          padding: 5,
          titleMarginBottom: 0,
          yAlign: 'bottom',
        },
      },
      scales: {
        x: {
          grid: {
            borderColor: theme.colors.text,
            borderWidth: 2,
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          grid: {
            color: theme.colors.contentBackgroundFocus,
            drawBorder: false,
            drawTicks: false,
          },
          max: maxYValue,
          min: minYValue,
          offset: false,
          position: 'right',
          ticks: {
            callback: (tickValue, index) => {
              if (index % 2 === 1) {
                return tickFormatter(tickValue);
              }

              return '';
            },
            crossAlign: 'far',
            count: 10,
            color: theme.colors.text,
            font: {
              family: 'Noto Sans',
              weight: 'bold',
              size: 12,
            },
            padding: 10,
          },
        },
      },
    },
  };

  if (annotations) {
    config.options.plugins.annotation = { annotations };
  }

  if (maxYValue) {
    config.options.scales.y.max = maxYValue;
  }

  return mergeObjects(config, otherOptions);
}

export function generateDashedLineAnnotationConfig(value, options) {
  const { theme } = options;

  return {
    borderColor: theme.colors.text,
    borderDash: [4, 4],
    borderWidth: 2,
    scaleID: 'y',
    type: 'line',
    value,
  };
}
