import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import Checkbox from 'OK/components/checkbox';

export default function VideoMenuOption(props) {
  const { className, enabled = false, title, type, ...otherProps } = props;

  let classNames = styles.container;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let accessoryView;
  if (type === 'checkbox') {
    accessoryView = <Checkbox checked={enabled} className={styles.checkbox} />;
  } else {
    accessoryView = <div className={styles.radio}>{enabled && <div className={styles.filled} />}</div>;
  }

  return (
    <button className={classNames} type='button' {...otherProps}>
      {accessoryView}
      <p className={styles.label}>{title}</p>
    </button>
  );
}

VideoMenuOption.propTypes = {
  className: PropTypes.string,
  enabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
};
