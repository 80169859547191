import PropTypes from 'prop-types';
import { useContext } from 'react';

import styles from './styles.module.scss';

import ThemeContext from 'OK/util/context/theme';
import useI18n from 'OK/util/hooks/useI18n';

export default function LoadingSpinner(props) {
  const { className, ...otherProps } = props;
  const { t } = useI18n();

  const theme = useContext(ThemeContext);
  const src = `/icons/spinner_${theme.name}.svg`;

  let classNames = styles.spin;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return <img alt={t('IMG_ALT_LOADING_SPINNER')} className={classNames} src={src} {...otherProps} />;
}

LoadingSpinner.propTypes = {
  className: PropTypes.string,
};
