import PropTypes from 'prop-types';

import Link from '../';

import ProductModel from 'OK/models/product';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import isAuthorised from 'OK/util/functions/isAuthorised';
import useAuthorisation from 'OK/util/hooks/useAuthorisationLevel';

export default function LinkToProduct(props) {
  const { children, linkToEditPage = false, product, ...otherProps } = props;
  const authorisationLevel = useAuthorisation(product);
  const linkUrl = ProductModel.link(product, {
    edit: linkToEditPage && isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER),
  });
  
  return (
    <Link href={linkUrl} {...otherProps}>
      {children}
    </Link>
  );
}

LinkToProduct.propTypes = {
  children: PropTypes.node,
  linkToEditPage: PropTypes.bool,
  product: PropTypes.shape({
    REFID: PropTypes.string.isRequired,
  }).isRequired,
};
