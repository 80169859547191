import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import ItemArchiveCard from 'OK/components/item/archiveCard';
import ProductArchiveCard from 'OK/components/product/archiveCard';
import SearchSuggestions from 'OK/components/searchSuggestions';
import Text from 'OK/components/text';
import ProductModel from 'OK/models/product';
import { searchQuery } from 'OK/networking/search';
import { showScannerAction } from 'OK/state/app/actions';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import { formatOkid } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function LabelMakerProductOrItemSelector(props) {
  /* Variables */

  const { header, onCancel, onSave: onSaveProp } = props;
  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const dispatch = useDispatch();
  const { t, locale } = useI18n();

  // State

  const [searchFocused, setSearchFocused] = useState(false);
  const [searchString, _setSearchString] = useState('');
  const [selection, setSelection] = useState(null);

  /* API */

  const [searchAPI, searchAPIResult] = useLazyQuery(searchQuery);
  const totalResults =
    (searchAPIResult.data?.search?.searchPaginationResultDataByDataType?.ITEM?.totalResults ?? 0) +
    (searchAPIResult.data?.search?.searchPaginationResultDataByDataType?.PRODUCT?.totalResults ?? 0);

  /* Methods */

  const searchDebounced = useMemo(
    () =>
      debounce((searchStr) => {
        searchAPI({
          variables: {
            organisationIdList: [activeOrganisationId],
            searchPaginationDataByDataType: [
              { dataType: 'ITEM', searchPaginationData: { skip: 0, pageSize: 4 } },
              { dataType: 'PRODUCT', searchPaginationData: { skip: 0, pageSize: 4 } },
            ],
            searchString: searchStr,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [activeOrganisationId, searchAPI]
  );

  const setSearchString = useCallback(
    (newSearchString) => {
      _setSearchString(newSearchString);

      if (newSearchString.length > 1) {
        searchDebounced(newSearchString);
      }
    },
    [searchDebounced]
  );

  const openScanner = useCallback(() => {
    dispatch(
      showScannerAction((okid) => {
        setSearchString(okid);
      })
    );
  }, [dispatch, setSearchString]);

  // Event handlers

  const onSave = useCallback(() => {
    if (selection && onSaveProp) {
      let selectionModel;
      switch (selection.dataType) {
        case 'PRODUCT':
          selectionModel = selection.productData;
          break;
        case 'ITEM':
          selectionModel = selection.itemData;
          break;
      }
      onSaveProp(selectionModel);
    }
  }, [onSaveProp, selection]);

  const onSearch = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setSearchString(newSearchString);
    },
    [setSearchString]
  );

  const onSelection = useCallback(
    (newSelectionId) => {
      const newSelection = searchAPIResult.data?.search?.resultList?.find((r) => r.dataId === newSelectionId);
      setSelection(newSelection);
      setSearchString('');
    },
    [searchAPIResult.data?.search?.resultList, setSearchString]
  );

  /* Render */

  const searchSuggestions = useMemo(() => {
    if (searchFocused && searchString.length > 1) {
      return (
        searchAPIResult.data?.search?.resultList?.map((r) => {
          if (r.dataType === 'ITEM') {
            const item = r.itemData;
            return {
              key: item.id,
              icon: ICONS.TAG.name,
              title: ProductModel.localizedNameForProduct(item.product, locale),
              subtitle: formatOkid(item.OKID),
            };
          }

          const product = r.productData;
          return {
            key: product.id,
            icon: ICONS.PRODUCT.name,
            title: ProductModel.localizedNameForProduct(product, locale),
            subtitle: product.REFID,
          };
        }) ?? []
      );
    }

    return [];
  }, [locale, searchAPIResult.data?.search?.resultList, searchFocused, searchString.length]);

  const selectionCard = useMemo(() => {
    if (selection) {
      switch (selection.dataType) {
        case 'PRODUCT':
          return (
            <ProductArchiveCard
              className={styles.selectionCard}
              layoutOverride='mobile'
              product={selection.productData}
            />
          );
        case 'ITEM':
          return <ItemArchiveCard className={styles.selectionCard} item={selection.itemData} layoutOverride='mobile' />;
      }
    }

    return null;
  }, [selection]);

  return (
    <div>
      <h4>{header}</h4>
      <Text>{t('LABEL_MAKER_POPUP_SET_PRODUCT_DESCRIPTION')}</Text>
      <div className={styles.searchContainer}>
        <Input
          accessoryButton={<Button icon={ICONS.CAMERA.name} linkStyle onClick={openScanner} />}
          className={styles.input}
          loading={searchAPIResult.loading}
          onBlur={() => setSearchFocused(false)}
          onChange={onSearch}
          onFocus={() => setSearchFocused(true)}
          placeholder={t('LABEL_MAKER_POPUP_FIND_PRODUCT_PLACEHOLDER')}
          showClearButton={!searchAPIResult.loading}
          value={searchString}
          withIcon={searchAPIResult.loading ? <Icon name={ICONS.SPINNER.name} /> : null}
        />
        <SearchSuggestions
          className={styles.searchSuggestions}
          highlightTerm={searchString}
          onSuggestionClick={onSelection}
          showMoreResultsMessage={searchFocused && totalResults > searchSuggestions.length}
          showNoResultsMessage={searchFocused && searchString.length > 1 && searchSuggestions.length === 0}
          suggestions={searchSuggestions}
        />
      </div>
      {selectionCard}
      {selection && (
        <Button block className={styles.saveButton} onClick={onSave}>
          {t(selection.dataType === 'ITEM' ? 'LABEL_MAKER_POPUP_BASE_ON_ITEM' : 'LABEL_MAKER_POPUP_BASE_ON_PRODUCT')}
        </Button>
      )}
      <Button linkStyle onClick={onCancel}>
        {t('LABEL_MAKER_POPUP_BUTTON_DISCARD_CHANGES')}
      </Button>
    </div>
  );
}

LabelMakerProductOrItemSelector.propTypes = {
  header: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};
