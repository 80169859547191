import { gql } from '@apollo/client';

export default class BaseProductParentProductModel {
  static GRAPHQL_TYPE = 'ProductParentProduct';

  static fragmentName = 'Base_ProductParentProduct_Fragment';
  static fragment = gql`
    fragment ${BaseProductParentProductModel.fragmentName} on ${BaseProductParentProductModel.GRAPHQL_TYPE} {
      assetLinkStatus
      order
      publishStatus
      quantity
    }
  `;
}
