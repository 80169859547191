import { makeReference, useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { ICONS } from 'OK/components/icon';
import SearchInput from 'OK/components/input/search';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import { PublishOptionCard } from 'OK/components/publishOptionCard';
import SearchSuggestions from 'OK/components/searchSuggestions';
import SharedAccessCard from 'OK/components/sharedAccessCard';
import Text from 'OK/components/text';
import Tooltip from 'OK/components/tooltip';
import FadeInOutTransition from 'OK/components/transitions/fadeInOut';
import OrganisationModel from 'OK/models/organisation';
import UserModel from 'OK/models/user';
import {
  RemoveSharedPermissionMutation,
  ShareAssetMutation,
  UpdateSharedPermissionLevelMutation,
} from 'OK/networking/assetSharing';
import { SearchOrganisationsQuery } from 'OK/networking/search';
import { DEBOUNCE_TIMING_MS_SHORT } from 'OK/util/constants';
import AssetAccessPermission from 'OK/util/enums/assetAccessPermission';
import AUTHORISATION_LEVEL from 'OK/util/enums/authorisationLevel';
import PUBLISH_STATUS from 'OK/util/enums/publishStatus';
import { formatOkid } from 'OK/util/formatting';
import assetTypeToGraphQLType from 'OK/util/functions/assetTypeToGraphQLType';
import isAuthorised from 'OK/util/functions/isAuthorised';
import share, { SHARE_ACTION, SHARE_TYPE } from 'OK/util/functions/share';
import useAuthorisationLevel from 'OK/util/hooks/useAuthorisationLevel';
import useI18n from 'OK/util/hooks/useI18n';

const LINK_TYPES = {
  COMMENT: 'COMMENT',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};

export default function ShareAssetPopup(props) {
  /* Variables */

  const {
    asset,
    assetId,
    assetPublishStatus,
    assetType,
    dismiss,
    publishError,
    sharedToList = [],
    onClickChangeShareStatus,
  } = props;

  const activeOrganisationId = useSelector((state) => state.account.activeOrganisationId);
  const { t } = useI18n();

  const authorisationLevel = useAuthorisationLevel(asset);
  const isInternalEditor = isAuthorised(authorisationLevel, AUTHORISATION_LEVEL.MANAGER);
  const isEditor =
    isInternalEditor || asset?.assetAccessPermission == AssetAccessPermission.SHARED_WITH_EDIT_PERMISSION;

  /* State */

  const [shareButtonMessage, setShareButtonMessage] = useState(null);
  const [renderShareButtonMessage, setRenderShareButtonMessage] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchString, setSearchString] = useState('');

  /* API */

  const [removeSharedAccessAPI] = useMutation(RemoveSharedPermissionMutation);
  const [searchAPI, searchAPIResult] = useLazyQuery(SearchOrganisationsQuery);
  const [shareAPI] = useMutation(ShareAssetMutation);
  const [updateSharedPermissionAPI] = useMutation(UpdateSharedPermissionLevelMutation);

  /* Methods */

  const searchDebounced = useMemo(
    () =>
      debounce((search) => {
        return searchAPI({
          variables: {
            ignoreIdListByDataType: [
              {
                dataType: 'ORGANISATION',
                ignoreIdList: [
                  ...sharedToList.filter((s) => s.sharedToAssetType === 'ORGANISATION').map((s) => s.sharedToAssetId),
                  activeOrganisationId,
                ],
              },
            ],
            searchPaginationDataByDataType: [
              {
                dataType: 'ORGANISATION',
                searchPaginationData: { pageSize: 4, skip: 0 },
              },
            ],
            searchString: search,
          },
        });
      }, DEBOUNCE_TIMING_MS_SHORT),
    [activeOrganisationId, searchAPI, sharedToList]
  );

  const shareLink = useCallback(() => {
    share(window.location.href, SHARE_TYPE.URL, true)
      .then((shareAction) => {
        if (shareAction === SHARE_ACTION.CLIPBOARD) {
          setShareButtonMessage('Copied');
          setRenderShareButtonMessage(true);
        }
      })
      .catch();
  }, []);

  /* Event handlers */

  const onChangeSearchString = useCallback(
    (e) => {
      const newSearchString = e.target.value;
      setSearchString(newSearchString);

      if (newSearchString.length > 1) {
        searchDebounced(newSearchString);
      }
    },
    [searchDebounced]
  );

  const onClickChangePermissionLevel = useCallback(
    (sharedAccessId, newPermissionLevel) => {
      return updateSharedPermissionAPI({
        variables: {
          permissionLevel: newPermissionLevel,
          assetAccessPermissionId: sharedAccessId,
        },
        optimisticResponse: {
          sharedPermission: {
            assetAccessPermission: newPermissionLevel,
            id: sharedAccessId,
            __typename: 'AssetAccessPermission',
          },
        },
      });
    },
    [updateSharedPermissionAPI]
  );

  const onClickRemoveSharedPermission = useCallback(
    (sharedAccessId) => {
      return removeSharedAccessAPI({
        variables: {
          assetAccessPermissionId: sharedAccessId,
        },
        optimisticResponse: {
          sharedPermission: {
            id: sharedAccessId,
            __typename: 'AssetAccessPermission',
          },
        },
        update: (cache, result) => {
          const graphQLType = assetTypeToGraphQLType(assetType);
          cache.modify({
            id: `${graphQLType}:${assetId}`,
            fields: {
              assetAccessPermissionList: (currentList, { readField }) => {
                const updatedList = [...currentList];
                const indexToRemove = updatedList.findIndex(
                  (p) => readField('id', p) === result.data.sharedPermission.id
                );
                updatedList.splice(indexToRemove, 1);
                return updatedList;
              },
            },
          });
        },
      });
    },
    [assetId, assetType, removeSharedAccessAPI]
  );

  const onSelectToShare = useCallback(
    (key) => {
      const [sharedToAssetType, sharedToAssetId] = key.split(':');
      const permissionLevel = AssetAccessPermission.SHARED_WITH_READ_PERMISSION;
      const optimisticResponse = {
        sharedPermission: {
          assetAccessPermission: permissionLevel,
          assetId,
          assetType,
          id: 'OPTIMISTIC',
          sharedToAssetId,
          sharedToAssetType,
          __typename: 'AssetAccessPermission',
        },
      };
      const sharedToAsset = searchAPIResult.data.search.resultList.find(
        (r) => r.dataType === sharedToAssetType && r.dataId === sharedToAssetId
      );
      if (sharedToAssetType === 'ORGANISATION') {
        optimisticResponse.sharedPermission.sharedToOrganisation = {
          id: sharedToAssetId,
          OKID: sharedToAsset.organisationData.OKID,
          name: sharedToAsset.organisationData.name,
          logoImageMediaAsset: sharedToAsset.organisationData.logoImageMediaAsset,
          __typename: OrganisationModel.GRAPHQL_TYPE,
        };
        optimisticResponse.sharedPermission.sharedToUser = null;
      } else {
        optimisticResponse.sharedPermission.sharedToUser = {
          id: sharedToAssetId,
          OKID: sharedToAsset.userData.OKID,
          avatar: sharedToAsset.userData.avatar,
          __typename: UserModel.GRAPHQL_TYPE,
        };
        optimisticResponse.sharedPermission.sharedToOrganisation = null;
      }
      setSearchString('');
      return shareAPI({
        variables: {
          assetOrganisationId: asset.organisationId,
          currentAccessPermission: asset.assetAccessPermission || '',
          permissionLevel,
          assetId,
          assetType,
          sharedToAssetId,
          sharedToAssetType,
        },
        optimisticResponse,
        update: (cache, result) => {
          const graphQLType = assetTypeToGraphQLType(assetType);
          cache.modify({
            id: `${graphQLType}:${assetId}`,
            fields: {
              assetAccessPermissionList: (currentList = []) => {
                const newSharedPermissionRef = makeReference(
                  `AssetAccessPermission:${result.data.sharedPermission.id}`
                );
                const updatedList = [...currentList, newSharedPermissionRef];
                return updatedList;
              },
            },
          });
        },
      });
    },
    [
      asset?.assetAccessPermission,
      asset?.organisationId,
      assetId,
      assetType,
      searchAPIResult?.data?.search?.resultList,
      shareAPI,
    ]
  );

  /* Effects */

  // Hide share button message
  useEffect(() => {
    if (shareButtonMessage) {
      setTimeout(() => {
        setRenderShareButtonMessage(false);
      }, 5000);
    }
  }, [renderShareButtonMessage, shareButtonMessage]);

  /* Render */

  // Share link options
  const shareLinkOptions = [];

  shareLinkOptions.push({ label: 'Public commenting', value: LINK_TYPES.COMMENT });

  shareLinkOptions.push({ label: 'Public viewing', value: LINK_TYPES.VIEW });

  shareLinkOptions.push({ label: 'Public editing', value: LINK_TYPES.EDIT });

  // Search suggestions
  let searchSuggestions;
  if (searchFocused && searchString.length > 1 && typeof searchAPIResult.data?.search?.resultList !== 'undefined') {
    searchSuggestions = searchAPIResult.data?.search?.resultList?.map((r) => {
      let icon;
      let title;
      let subtitle;

      if (r.dataType === 'ORGANISATION') {
        icon = r.organisationData.logoImageMediaAsset?.logoImageURL ? (
          <img
            alt='Logo'
            className={styles.searchSuggestionImage}
            src={r.organisationData.logoImageMediaAsset.logoImageURL}
          />
        ) : (
          ICONS.ORGANISATION.name
        );
        title = r.organisationData.name;
        subtitle = formatOkid(r.organisationData.OKID);
      }

      return {
        icon,
        key: `${r.dataType}:${r.dataId}`,
        title,
        subtitle,
      };
    });
  } else {
    searchSuggestions = [];
  }

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.popup}>
        <div className={styles.header}>
          <h3>{t('SHARE')}</h3>
          <PopupCloseButton linkStyle>{t('CLOSE')}</PopupCloseButton>
        </div>
        <div className={styles.sectionContainer} style={{ marginTop: 30 }}>
          <Button block tint='navigation' onClick={shareLink}>
           {t('SHARE_BY_LINK')} 
          </Button>
          <FadeInOutTransition appear in={renderShareButtonMessage}>
            <Tooltip className={`${styles.linkActionTooltip} `} position={'below'}>
              {shareButtonMessage}
            </Tooltip>
          </FadeInOutTransition>
        </div>
        {assetPublishStatus && (
          <div style={{ marginTop: 30 }}>
            {publishError && (
              <Text size='sm' tint='notification'>
                {publishError}
              </Text>
            )}
            <h4>{t('GENERAL_ACCESS')}</h4>
            <PublishOptionCard
              className={styles.publishOptionCard}
              assetPublishStatus={assetPublishStatus}
              selected={assetPublishStatus === PUBLISH_STATUS.PUBLISHED}
              publishOption={PUBLISH_STATUS.PUBLISHED}
              onClick={() => onClickChangeShareStatus(PUBLISH_STATUS.PUBLISHED)}
              readOnly={!isInternalEditor}
            />
            <PublishOptionCard
              className={styles.publishOptionCard}
              assetPublishStatus={assetPublishStatus}
              selected={assetPublishStatus === PUBLISH_STATUS.UNPUBLISHED}
              publishOption={PUBLISH_STATUS.UNPUBLISHED}
              onClick={() => onClickChangeShareStatus(PUBLISH_STATUS.UNPUBLISHED)}
              readOnly={!isInternalEditor}
            />
            {/* Uncomment this card when 'RESTRICTED' option is available  */}
            {/* <PublishOptionCard
              className={styles.publishOptionCard}
              selected={assetPublishStatus === PUBLISH_STATUS.RESTRICTED}
              publishOption={PUBLISH_OPTIONS.RESTRICTED}
            /> */}
          </div>
        )}
        {isEditor && (
          <div style={{ marginTop: 30 }}>
            {/* <h4>{t('SHARE_ASSET_POPUP_SHARE_BY_INVITATION_HEADER')}</h4> */}
            <h4>{t('INVITATIONS_TO_ACCESS')}</h4>

            <Text className={styles.description}>{t('SHARE_ASSET_POPUP_SHARE_BY_INVITATION_DESCRIPTION')}</Text>
            <div className={styles.searchContainer}>
              <SearchInput
                className={styles.searchInput}
                onBlur={() => setSearchFocused(false)}
                onChange={onChangeSearchString}
                onFocus={() => setSearchFocused(true)}
                placeholder={t('ORGANISATION_SEARCH_PLACEHOLDER')}
                value={searchString}
              />
              <SearchSuggestions
                className={styles.searchSuggestions}
                highlightTerm={searchString}
                onSuggestionClick={onSelectToShare}
                showMoreResultsMessage={
                  searchAPIResult.data?.search?.searchPaginationResultDataByDataType?.ORGANISATION?.totalResults >
                    searchAPIResult.data?.search?.searchPaginationResultDataByDataType?.ORGANISATION?.pageResults ||
                  searchAPIResult.data?.search?.searchPaginationResultDataByDataType?.USER?.totalResults >
                    searchAPIResult.data?.search?.searchPaginationResultDataByDataType?.USER?.pageResults
                }
                showNoResultsMessage={searchAPIResult.data?.search?.resultList?.length === 0}
                suggestions={searchSuggestions}
              />
            </div>
            {sharedToList
              .filter((s) => s.sharedToOrganisation !== null)
              .map((s) => {
                const name = s.sharedToOrganisation?.name;

                const OKID = s.sharedToOrganisation?.OKID;
                /* eslint-disable indent */
                const imageUrl = s.sharedToOrganisation?.logoImageMediaAsset?.logoImageURL;
                return (
                  <SharedAccessCard
                    className={styles.sharedAccessCard}
                    fixedWidth={false}
                    imageUrl={imageUrl}
                    key={s.id}
                    name={name}
                    OKID={OKID}
                    onClickChangePermissionLevel={onClickChangePermissionLevel}
                    onClickRemove={onClickRemoveSharedPermission}
                    sharedAccessId={s.id}
                    sharedAccessLevel={s.assetAccessPermission}
                    sharedToAssetType={s.sharedToAssetType}
                  />
                );
              })}
            {isEditor && (
              <Text className={styles.sharedFinePrint} size='xs'>
                {t('SHARE_ASSET_PERMISSION_PRIORITY_DESCRIPTION')}
              </Text>
            )}
          </div>
        )}
      </PopupContent>
      <PopupButtonsGroup>
        <PopupCloseButton tint='navigation'>{t('CLOSE')}</PopupCloseButton>
      </PopupButtonsGroup>
    </Popup>
  );
}

ShareAssetPopup.propTypes = {
  asset: PropTypes.object,
  assetId: PropTypes.string.isRequired,
  assetPublishStatus: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  commentLink: PropTypes.string,
  dismiss: PropTypes.func.isRequired,
  editLink: PropTypes.string,
  internalEditor: PropTypes.bool,
  onClickChangeShareStatus: PropTypes.func,
  publishError: PropTypes.string,
  sharedToList: PropTypes.array,
  viewLink: PropTypes.string,
};
